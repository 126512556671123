import Types from "./types";
import { AppointmentService } from "../../service";
// import { getCustomerOffersMoq } from "../../utils/mocApi";
import {updateApiNoAccess} from "../../components/hoc-access-controller/actions";
const fetchCustomerOfferDetails = (appointmentId) => dispatch => {
  return AppointmentService.getCustomerOfferDetails(appointmentId)
    .then(response => {
      // response = getCustomerOffersMoq();
      const { data: { detail } } = response;
      dispatch(fetchCustomerOfferSuccess(detail));
      return detail;
    })
    .catch((error) => {
      dispatch(updateApiNoAccess(error));
      dispatch(fetchCustomerOfferFailure(error));
    });
};

const fetchCustomerOfferSuccess = (data) => ({
  type: Types.FETCH_CUSTOMER_OFFER_SUCCESS,
  data
});
const fetchCustomerOfferFailure = (data) => ({
  type: Types.FETCH_CUSTOMER_OFFER_FAILURE,
  data
});

export {
  fetchCustomerOfferDetails
};
