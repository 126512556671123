import React, { useState, useEffect } from "react";
import styles from "./quote-popup.module.css";
import H2 from "../h2/component";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from "../shared/button/component";
import PaiSlider from "../pai-slider";
import IncrementalTextbox from "../incremental-textbox/component";
import priceFormatter from "../../utils/helpers/price-formatter";
import { getFromLocalStorage, isPllUser } from "../../utils/helper";
import { LOCAL_STORAGE_KEYS } from "../../utils/app-constants";
import { showToastMessages } from "../../utils/helper";
import calculateProbablity, { PROBABLITY_TYPES } from "../../utils/raise-pai/calculate-probablity";
import paiInfo from "../../utils/raise-pai/pai-info";

const useStyles = makeStyles(theme => {
  return {
    textField: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      fontSize: '10px',
      width: '100%'
    }
  };
});

const RaisePaiPopup = ({ negotiations, close, paiRequestConnect, setCurrentPopupConnect, resetListing, customerPage }) => {
  const {
    currentAppointmentData,
    showSlider,
    minPrice,
    maxPrice,
    pendingCount,
    domain,
    defaultValues,
    sliderData,
    calculatedc24Quote,
    priceAcceptance,
    optimalIncrease,
    step
  } = paiInfo(negotiations);
  const { customerName, make, model, year, auctionId } = currentAppointmentData;
  const [closurePrice, setClosurePrice] = useState(calculatedc24Quote);
  const [values, setValues] = useState(defaultValues.slice());
  const [update, setUpdate] = useState(defaultValues.slice());
  const [probablityType, setProbablityType] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(true);
  const [inputText, setInputText] = useState("");
  const [inputTextError, setInputTextError] = useState(false);
  const [inputTextErrorMessage, setInputTextErrorMessage] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const classes = useStyles();
  let isPll = isPllUser();
  useEffect(() => {
    setClosurePrice(optimalIncrease > 0 ? calculatedc24Quote + optimalIncrease : minPrice);
    if (showSlider) {
      setProbablityType(calculateProbablity(priceAcceptance, optimalIncrease, sliderData));
      setIsSubmitted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeClick = (type) => {
    const newPrice = type === "increment" ? closurePrice + step : closurePrice - step;
    setClosurePrice(newPrice);
    setProbablityType(calculateProbablity(priceAcceptance, newPrice - calculatedc24Quote, sliderData));
    setValues([newPrice]);
  };

  const onUpdateSlider = update => {
    const currentValue = update[0];
    setProbablityType(calculateProbablity(priceAcceptance, currentValue - calculatedc24Quote, sliderData));
    setUpdate(update);
    setClosurePrice(currentValue);
  };

  const onChangeSlider = values => {
    setValues(values);
  };

  const onChangeText = (e) => {
    const value = e.target.value && e.target.value.replace(/,/g, '') ? priceFormatter(Number(e.target.value.replace(/,/g, ''))) : "";
    const cPrice = Number(e.target.value.replace(/,/g, ''));
    setClosurePrice(cPrice);
    setInputText(value);

    if (cPrice % 1000 !== 0) {
      setInputTextError(true);
      setInputTextErrorMessage("Price must be in multiple of 1000s");
    } else if (cPrice < minPrice) {
      setInputTextError(true);
      setInputTextErrorMessage(`Price cannot be less than ${minPrice}`);
    } else if (cPrice > maxPrice) {
      setInputTextError(true);
      setInputTextErrorMessage(`Price cannot be greater than ${maxPrice}`);
    } else {
      setInputTextError(false);
      setIsSubmitted(false);
      setInputTextErrorMessage("");
    }
  };

  const onSubmitRaisePai = () => {
    setIsSubmitted(true);
    setisLoading(true);
    const {
      appointmentId,
      carId,
      storeId,
      calculatedc24Quote,
      auctionId
    } = currentAppointmentData;
    const loginData = JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA) || "null");
    if (!loginData && !loginData.email) { showToastMessages("Please login again.", false); }

    // const payload = {
    //     "appointment_id": appointmentId,
    //     "carId": carId,
    //     "auction_id": auctionId,
    //     "store_id": storeId,
    //     "current_c24quote": calculatedc24Quote,
    //     "requested_c24quote": closurePrice,
    //     "userEmail": loginData.email,
    //     "reqType": "paiRequest",
    //     "c24Diff": closurePrice - calculatedc24Quote,
    //     "probability": !!probablityType ? probablityType : ""
    // };
    const payload = {
      "appointment_id": appointmentId,
      "carId": carId,
      "auction_id": auctionId,
      "store_id": storeId,
      "c24quote": calculatedc24Quote,
      "current_c24quote": calculatedc24Quote,
      "requested_c24quote": closurePrice,
      "userEmail": loginData.email,
      "request_type": "RAISE_PAI",
      "is_pll": isPll,
      "c24Diff": closurePrice - calculatedc24Quote,
      "probability": !!probablityType ? probablityType : "",

    };
    paiRequestConnect(payload)
      .then((resp) => {
        showToastMessages(resp && resp.message ? resp.message : resp);
        setCurrentPopupConnect(null, null);
        setisLoading(false);
        resetListing();
      })
      .catch((resp) => {
        showToastMessages(resp.message, false);
        setIsSubmitted(false);
        setisLoading(false);
      });
  };

  return (
    <div className={styles.popupWrap}>
      <H2 text="Raise PAI" />
      <ul className={styles.customerDesc}>
        <li>{customerName}</li>
        <li>{`${make} ${model}`}</li>
        <li>{year}</li>
      </ul>
      <p className={styles.text}>C24 QUOTE</p>
      <p className={styles.priceText}>{priceFormatter(calculatedc24Quote)}</p>
      {showSlider && <p className={styles.clousrePrice}>Guaranteed Closure Price</p>}
      {showSlider &&
      <IncrementalTextbox
        value={priceFormatter(closurePrice)}
        onChangeClick={onChangeClick}
      />
      }
      {!showSlider &&
      <div>
        <TextField
          error={inputTextError}
          label="Guaranteed Closure Price"
          className={classes.textField}
          margin="dense"
          variant="outlined"
          onChange={onChangeText}
          value={inputText}
          inputProps={{
            maxLength: 9,
          }}
          InputLabelProps={{ style: {} }}
        />
      </div>
      }
      {showSlider && <div>
        <PaiSlider
          domain={domain}
          onChange={onChangeSlider}
          onUpdate={onUpdateSlider}
          values={values}
          update={update}
          step={step}
          sliderData={sliderData}
          prices={priceAcceptance}
        />
        <React.Fragment>
          {probablityType === PROBABLITY_TYPES.HIGH.key && <p className={styles.greenText}>{PROBABLITY_TYPES.HIGH.label}</p>}
          {probablityType === PROBABLITY_TYPES.MEDIUM.key && <p className={styles.orengeText}>{PROBABLITY_TYPES.MEDIUM.label}</p>}
          {probablityType === PROBABLITY_TYPES.LOW.key && <p className={styles.redText}>{PROBABLITY_TYPES.LOW.label}</p>}
          {probablityType === PROBABLITY_TYPES.VERY_LOW.key && <p className={styles.redText}>{PROBABLITY_TYPES.VERY_LOW.label}</p>}
        </React.Fragment>
      </div>}
      {inputTextError && <p style={{ color: '#f44336', fontSize: 12, margin: 0, paddingBottom: 5, }}>{inputTextErrorMessage}</p>}
      {!showSlider && <p className={styles.textLabel}>Please enter between {minPrice} & {maxPrice}</p>}
      <div className={styles.getQuote}>
        <Button
          isLoading={isLoading}
          disabled={inputTextError || isSubmitted}
          onClick={onSubmitRaisePai}
          classNames="blueButton"
          ctaText="Submit" />
        <button className={styles.closeModal} onClick={close}>CANCEL</button>
      </div>
      <p className={styles.textPai}>{pendingCount} PAI request remaining</p>
    </div>
  );
};

export default RaisePaiPopup;
