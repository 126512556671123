/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import styles from "./otp-confirm-popup.module.scss";
import H2 from "../h2/component";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from "../shared/button/component";
import priceFormatter from "../../utils/helpers/price-formatter";
import { isC2CUser, getFromLocalStorage, showToastMessages, onNumberKeyPress } from "../../utils/helper";
import { LOCAL_STORAGE_KEYS, ERROR_MESSAGE } from "../../utils/app-constants";
import { AppointmentService } from "../../service";
import InputText from "../shared/input-text/component";

const useStyles = makeStyles(theme => {
    return {
        textField: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1),
            fontSize: '10px',
            width: '100%'
        }
    };
});

const OtpConfirmPopup = ({ onClose, detail, listPrice, reload, appointmentId }) => {
    // const { appointmentId } = appointMentData;
    const { make, model, variant, year, customerName, customerPhone, exophone } = detail;

    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState(false);
    const validateOtp = () => {
        if (otp.length === 4) {
            setOtpError(false);
            return true;
        } else {
            setOtpError(true);
            return false;
        }
    };

    const onOtpChange = (e) => {
        const { target: { value } } = e;
        setOtp(value);
    };
    const onCloseList = (flag) => {
        onClose(flag);
    };

    const ListCar = () => {
        const isValid = validateOtp();
        if (isValid) {
            const localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
            const loggedInEmail = localData ? JSON.parse(localData)[LOCAL_STORAGE_KEYS.EMAIL] : "";
            const actualRole = localData ? JSON.parse(localData)[LOCAL_STORAGE_KEYS.ORIGIN_ROLE] : "";

            const payload = {
                "appointmentId": appointmentId,
                "listingPrice": listPrice,
                "userEmail": loggedInEmail,
                "userActualRole": actualRole,
                "prevListingPrice": 0,
                "otp": otp
            };
            AppointmentService.c2cList(payload)
                .then((resp) => {
                    const { data: { title } } = resp;
                    let msg = resp && resp.data ? title : "Listing Price updated successfully";
                    showToastMessages(msg);

                    if (!reload) {
                        onClose(true);
                    }
                    else {
                        setTimeout(() => {
                            window.location.reload();
                        }, 2000);
                    }

                })
                .catch((resp) => {
                    showToastMessages(resp.title, false);
                });
        }
    };

    const SendOtp = () => {
        const payload = {
            "appointmentId": appointmentId,
            "listingPrice": listPrice,
        };
        AppointmentService.sendListCarOtp(payload)
            .then((resp) => {
                const { data: { detail, title } } = resp;
                showToastMessages(title, true);
            })
            .catch((resp) => {
                const { detail: { message } } = resp;
                showToastMessages(message, false);
            });
    };
    const classes = useStyles();
    return (
      <div className={styles.otpConfirmPopup}>
        <H2 text="Enter OTP to Confirm" />
        <p className={styles.txt}> {customerName}| {model} | {year}</p>
        <div className={styles.lists}>
          <ul>
            <li>
              <span>Customer Name</span>
              <p>{customerName}</p>
            </li>
            <li>
              <span>Listing Price</span>
              <p>{priceFormatter(listPrice)}</p>
            </li>
            <li>
              <span>Phone No</span>
              <p>{customerPhone}</p>
            </li>
            <li>
              <span>VN </span>
              <p>{exophone}</p>
            </li>
            <li>
              <span></span>
              <p className={styles.sendOtp} onClick={SendOtp}>Send OTP Again</p>
            </li>
          </ul>
        </div>
        <div className={styles.inputWrapper}>
          <InputText
                    text="Enter OTP"
                    value={otp}
                    placeholder="_ _ _ _"
                    maxLength={4}
                    onChange={onOtpChange}
                    onKeyPress={onNumberKeyPress}
                />
          {otpError &&
            <span className={styles.errorText}>{ERROR_MESSAGE.OTP}</span>
                }
        </div>
        <div className={styles.ctaWrapper}>
          <Button classNames="blueButton" ctaText={"Submit"} onClick={ListCar} />
          <Button classNames="transparentBtn" ctaText={"Cancel"} onClick={() => onCloseList(false)} />
        </div>
      </div>
    );
};

export default OtpConfirmPopup;
