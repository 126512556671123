import React, { useState, useEffect } from "react";
import styles from "./sell-online-filter.module.css";
import { SELL_ONLINE_FILTERS } from "../../utils/app-constants";
import { getUpdatedFilterCounts, isC2CUser } from "../../utils/helper";

const SellOnlineFilter = ({ updateFilterQueryConnect, appointments, sellOnlineFilter, resetRangeByKeyConnect }) => {
  const { expiryCount = 0} = appointments || {};
  const { filterQuery } = sellOnlineFilter || {};
  const [selectedFilter, setSelectedFilter] = useState('');
  const [filtersCount, setFiltersCount] = useState(Object.assign({}, expiryCount));

  let isC2c = isC2CUser();
    
  useEffect(() => {
    return () => {
      updateFilterQueryConnect(null);
    };
  }, []);

  useEffect(() => {
    const slctdFilter = !!selectedFilter ? Object.values(SELL_ONLINE_FILTERS).find(item => item.key === selectedFilter).key : null;
    const newFiltersCount = getUpdatedFilterCounts(filtersCount, expiryCount, slctdFilter, !selectedFilter);
    setFiltersCount(newFiltersCount);
  }, [expiryCount]);

  const onFilterSelect = (selectedKey, selectedValue) => {
    const query = `&expires_in=${selectedValue}`;
    if (query !== filterQuery) {
      setSelectedFilter(selectedKey);
      updateFilterQueryConnect(query);
      if (isC2c) {
        const range = {
          from: "",
          to: "",
        };
        resetRangeByKeyConnect("range", range);
      }
    }
  };
  return (

    <ul>
      {
        Object.values(SELL_ONLINE_FILTERS).map((item) => {
          return (
            <li key={item.key} onClick={() => { onFilterSelect(item.key, item.value); }} className={selectedFilter === item.key ? styles.selected : ''}> {item.label} ({filtersCount[item.key]}) </li>
          );
        })}
    </ul>
  );
};

export default SellOnlineFilter;