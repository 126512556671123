import React from "react";
import styles from "./congratulations-self-appointment.module.scss";
import Store from "../../components/images/store.svg";
import CarIcon from "./images/carIcon.svg";
import LocationIcon from "./images/locatonIcon.svg";
import {APP_SOURCE} from "../../utils/app-constants";
const CongratulationsSelfAppointment = ({
  welComeObj,
  checkInStep
}) => {
  const { appointment, make, model, year, variant, location } = !!welComeObj ? welComeObj : {};
  return (
    <div className={styles.congratulationsSelfAppointment}>
      <div className={styles.mainImage}>
        <img src={Store} alt="store" />
      </div>
      <p className={styles.checkinText}>Congratulations!</p>
      {checkInStep === 1 && <p className={styles.subText}> You have been successfully Checked-In.</p>}
      {checkInStep !== 1 && <p className={styles.subText}> Thank you for visiting us.</p>}
      <p className={styles.subText}> Our RA will get in touch with you shortly.</p>
      {checkInStep === 1 && <div className={styles.appid}>
        <p className={styles.id}>
          <span>App. ID : {appointment}</span>
        </p>
        <div className={styles.storeList}>
          <ul>
            <li>
              <img src={CarIcon} alt={APP_SOURCE.CAR} />
              {/* <p>Ford Eco Sport, 2018, Petrol</p> */}
              <p>{make} {model}, {year}, {variant}</p>
            </li>
            <li>
              <img src={LocationIcon} alt="location" />
              <p>{location}</p>
            </li>
          </ul>
        </div>
      </div>
      }
    </div>
  );
};

export default CongratulationsSelfAppointment;
