import React, { useState, useEffect } from "react";
// import TimeAgo from "react-timeago";
import styles from "./check-in-date-time.module.css";
import { hasTimeElapsed, getTimeDifference } from "../../utils/helper";
import { NUMBERS, PENDING } from "../../utils/app-constants";
import CountdownTimer from "../shared/countdown-timer";
import HourTimer from "../shared/hour-timer";
import MinuteTimer from "../shared/minute-timer";
import DayTimer from "../shared/day-timer";

const CheckInDateTime = ({ rowDetails, appointments }) => {

  const { checkInDatetime, inspection } = rowDetails;
  const { data } = appointments;
  const [hasElapsed, setHasElapsed] = useState(false);

  useEffect(() => {
    let interval = setInterval(() => {
      const checkTime = NUMBERS.SIXTY;
      const hasReachedLimit = hasTimeElapsed(checkInDatetime, checkTime);
      if (hasReachedLimit) {
        setHasElapsed(hasReachedLimit);
        clearInterval(interval);
        //updatePriorityCountByKeyConnect(APPOINTMENT_TYPES_STRING.VISITED);
      };
    }, 1000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className={`${styles.time} ${hasElapsed ? styles.lapse : ''}`}>
      <CountdownTimer direction="forward" initialTime={getTimeDifference(new Date(checkInDatetime), new Date())}>
        <DayTimer />{`days: `}
        <HourTimer /> {`hrs: `}
        <MinuteTimer /> min
      </CountdownTimer>
    </div>
  // <TimeAgo className={hasElapsed ? styles.lapse : ''} date={new Date(checkInDatetime)}></TimeAgo>
  );
};

export default CheckInDateTime;
