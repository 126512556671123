/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import styles from "./car-factors.module.scss";
import { getUid } from "../../utils/helper";
import { trackLMSEvents } from "../../utils/helpers/tracking-events";

const CarFactors = ({ customerCharges, customerOffer, appointmentId }) => {

    const { charges, cashbacks, cepBreaker } = customerCharges;
    const { productionStatus, modelPopularity, oemKmpl, maintenanceCost, sparePartService } = !!cepBreaker ? cepBreaker : {};
    let showControl = !!cepBreaker ? true : false;
    if (showControl && appointmentId > 0) {
        trackLMSEvents("ls_CarGuruAvaialble", {
            eventLabel: appointmentId,
            lsRAID: getUid(),
            Timestamp: new Date()
        });
    }
    const getMarkBasedOnText = (cepBreakType, labelText) => {
        let className = "exclamation";
        switch (cepBreakType) {
            case "productionStatus":
                if (labelText === "Latest Model, In Production") {
                    className = "exclamationGreen";
                }
                else {
                    className = "exclamation";
                }
                break;
            case "modelPopularity":
                if (labelText === "High") {
                    className = "exclamationGreen";
                }
                else {
                    className = "exclamation";
                }
                break;
            case "oemKmpl":
                if (parseInt(labelText) > 15) {
                    className = "exclamationGreen";
                }
                else {
                    className = "exclamation";
                }
                break;
            case "maintenanceCost":
                if (labelText === "Low Maintenance Cost") {
                    className = "exclamationGreen";
                }
                else {
                    className = "exclamation";
                }
                break;
            case "sparePartService":
                if (labelText === "Easily Available Spare Parts & Service") {
                    className = "exclamationGreen";
                }
                else {
                    className = "exclamation";
                }
                break;
            default:
                break;
        }
        return className;
    };
    return (
      <>
        {showControl &&
        <div className={styles.factorsWrapper}>

          <p className={styles.content}>Factors which Affect the price of your car</p>
          <div className={styles.marketWrapper}>
            {(!!productionStatus || !!modelPopularity) &&
            <div className={styles.marketBox}>
              <h3>Market Conditions</h3>
              <div className={styles.exclamationDataWrapper}>
                {!!productionStatus && <div className={styles.exclamationWrapper}>
                  <span className={`${styles[getMarkBasedOnText("productionStatus", productionStatus)]}`}>!</span>
                  <p>{productionStatus}</p>
                </div>
                                    }
                {!!modelPopularity && <div className={styles.exclamationWrapper}>
                  <span className={`${styles[getMarkBasedOnText("modelPopularity", modelPopularity)]}`}>!</span>
                  <p>{modelPopularity} Model Popularity</p>
                </div>
                                    }
              </div>
            </div>
                        }
            {(!!maintenanceCost || !!sparePartService) &&
            <div className={styles.marketBox}>
              <h3>Car Running Costs</h3>
              <div className={styles.exclamationDataWrapper}>
                {!!maintenanceCost && <div className={styles.exclamationWrapper}>
                  <span className={`${styles[getMarkBasedOnText("maintenanceCost", maintenanceCost)]}`}>!</span>
                  <p>{maintenanceCost}</p>
                </div>
                                    }
                {!!sparePartService && <div className={styles.exclamationWrapper}>
                  <span className={`${styles[getMarkBasedOnText("sparePartService", sparePartService)]}`}>!</span>
                  <p>{sparePartService}</p>
                </div>
                                    }
              </div>

            </div>
                        }
            {!!oemKmpl &&
            <div className={`${styles.marketBox} ${styles.marketBoxFixed}`}>
              <h3>Car Performance</h3>
              <div className={styles.exclamationDataWrapper}>
                <div className={styles.exclamationWrapper}>
                  <span className={`${styles[getMarkBasedOnText("oemKmpl", oemKmpl)]}`}>!</span>
                  <p>{oemKmpl} KMPL</p>
                </div>
              </div>
            </div>
                        }
          </div>
        </div>
            }
      </>
    );
};

export default CarFactors;
