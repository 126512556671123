import React, { useState } from "react";
import styles from "./car-price-offer.module.scss";
import priceFormatter from "../../../utils/helpers/price-formatter";
import dealerLogo from "../../common/images/dealer-logo.svg";
import smsInfo from "../../common/images/check-completed.svg";
import alerm_on from "../../common/images/alarm_on.svg";
import { LeadService } from "../../service";
import { APP_CONSTANTS } from "../../common/app-constants";
import {onBackHome, santiziePrice} from "../../common/utility";
import { NUMBERS } from "../../../utils/app-constants";
const CarPriceOffer = ({
  leadDetail,
}) => {
  const {b2bDealer, phone, narrowPriceMin: minPrice, narrowPriceMax: maxPrice} = leadDetail || {};
  const {ucmName, centreName, dealerSmsCounter, dealerSmsLeftCounter } = b2bDealer || {};
  const [smsLeftCounter, setSmsLeftCounter] = useState (dealerSmsLeftCounter);
  const [smsCounter, setSmsCounter] = useState(dealerSmsCounter);
  const [smsSent, setSmsSent] = useState(false);
  const [smsSending, setSmsSending] = useState(false);
  const onSendSMS = () => {
    if (smsLeftCounter < NUMBERS.ONE) {
      window.open(`tel:${phone}`);
    } else {
      setSmsSending(true);
      LeadService.sendSMS(leadDetail.token).then((resp)=>{
        const {dealerSmsCounter, dealerSmsLeftCounter} = resp.detail || {};
        setSmsCounter(dealerSmsCounter);
        setSmsLeftCounter(dealerSmsLeftCounter);
        setSmsSent(true);
      }).catch(()=>{
        setSmsSent(false);
      }).finally(()=>{
        setSmsSending(false);
      });
    }
  };
  return (
    <>
      <div className={styles.wrapper}>
        <ul>
          {!!smsSent && <li>
            <div className={styles.smsinfo}>
              <img src={smsInfo} alt={'counter'}/>
              <span>SMS has been sent to the dealer</span>
            </div>
          </li>}
          <li>
            <div className={styles.priceWrap}>
              <div className={styles.congratulations}>
                <span>Final price will be between</span>
              </div>
              <strong className={styles.price}>₹ {`${santiziePrice(minPrice)} - ${santiziePrice(maxPrice, true)}`}</strong>
              {/* {gs_eligible_car === APP_CONSTANTS.YES && <div className={styles.gsInfo}>
                                <img src={smsInfo} alt={'counter'}/>
                                <span>Best price</span>
                            </div>} */}
            </div>
          </li>
          <li>
            <div className={styles.serviceCharge}>
              <div className={styles.header}>
                <p>Service charge will be deducted from the final price</p>
                <span>- ₹ {priceFormatter(APP_CONSTANTS.DEFAULT_SERVICE_CHARGE)}</span>
              </div>
              <p className={styles.body}>
                Our team takes care of the complete selling process. This is a nominal fee we charge to cover the costs.
              </p>
            </div>
          </li>
          <li>
            <div className={styles.smsCounter}>
              {smsCounter > NUMBERS.ONE ? 
                <div className={styles.attempt}>
                  <img src={alerm_on} alt={'alerm'}/>
                  <span>{`${smsLeftCounter > 0 ? `${smsLeftCounter} ${smsLeftCounter === NUMBERS.ONE ? 'Attempt': 'Attempts'}`: 'No Attempts'} left`}</span>
                </div> : 
                <div className={styles.smsinfoattempt}>
                  <img src={smsInfo} alt={'counter'}/>
                </div>
              }
              <div className={styles.attemptInfo}>
                The price acceptance link has been sent to the dealer on SMS & Whats app for approval.
              </div>
              <div className={styles.buttonWrap}>
                <span onClick={onSendSMS} disabled={smsSending}>{`${smsLeftCounter > 0 ? "RESEND SMS TO DEALER" : "CONTACT DEALER"}`}</span>
              </div>
              {dealerSmsCounter === NUMBERS.ONE && <span className={styles.info}>Please ask the dealer to accept the price</span>}
            </div>
          </li>
          <li>
            <div className={styles.dealerDetails}>
              <img src={dealerLogo} alt="logo"/>
              <div className={styles.dealerInfo}>
                <span>{centreName}</span>
                <p>{ucmName}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div className={styles.homeButton}>
        <span onClick={onBackHome}>GO TO HOMEPAGE</span>
      </div>
    </>
  );
};

export default CarPriceOffer;
