import { createTypes } from "reduxsauce";

export default createTypes(
  `
    UPDATE_HEADER_ROW_FILTER_QUERY
`,
  {
    prefix: "tableheaderwithcontrol/"
  }
);
