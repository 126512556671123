/* eslint-disable indent */
import React from "react";
import Button from "../shared/button";
import { POPUP_BUTTONS } from "../../utils/app-constants";
import styles from "./warning-popup.module.scss";

const WarningPopup = ({
  warningMsg,
  onClose
}) => {
  const onPopUpClose = (flag) => {
    onClose(flag);
  };
  return (
    <div className={styles.warningPopup}>
      <p className={styles.error}>
        {!!warningMsg ? warningMsg : ""}
      </p>
      <div>
        
        <Button classNames="cancelCta" ctaText={POPUP_BUTTONS.BACK} onClick={() => onPopUpClose(false)} />
        <Button classNames="saveCta" ctaText={POPUP_BUTTONS.SUBMIT} onClick={() => onPopUpClose(true)} />
      </div>
    </div>
  );
};

export default WarningPopup;
