import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Negotiation from "./component";
import withLayout from "../../components/layout/component";
import { resetAppointmentByKey } from "../appointment/actions";
import {resetApiNoAccess} from "../../components/hoc-access-controller/actions";
const mapStateToProps = ({
  lostLeadsearch,
  sidebar,
  negotiations
}) => ({
  lostLeadsearch, //combined reducer
  sidebar,
  negotiations
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  resetAppointmentByKeyConnect: resetAppointmentByKey,
  resetApiNoAccessConnect: resetApiNoAccess
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withLayout(Negotiation));
