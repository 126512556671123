import {  LeadService, VehicleService } from "../../service";
import Types from "./types";
import { NUMBERS } from "../../../utils/app-constants";
import { SO_QUESTION_KEY, TRANSMISSION_TYPE, FULE_TYPE } from "../../common/app-constants";

const fetchLeadDetail = (token) => dispatch => {
  return new Promise((resolve, reject)=> {
    return LeadService.getLeadDetails(token)
      .then(response => {
        dispatch(fetchLeadDetailSuccess(response?.detail));
        resolve(response?.detail);
      })
      .catch(error => {
        dispatch(fetchLeadDetailFailure(error));
        reject(error);
      });
  });
};

const getSOQuestionsByLead = (questions, lead) => {
  let keysToFilter = [];
  if (lead.fuel_type.toLowerCase() === FULE_TYPE.PETROL || lead.fuel_type.toLowerCase() === FULE_TYPE.CNG) {
    if (lead.transmission_type === TRANSMISSION_TYPE.MT) {
      keysToFilter = [SO_QUESTION_KEY.AutoGear];
    } else if (lead.transmission_type === TRANSMISSION_TYPE.AMT || lead.transmission_type === TRANSMISSION_TYPE.AT) {
      keysToFilter = [SO_QUESTION_KEY.ManualGear];
    }
  } else if (lead.fuel_type.toLowerCase() === FULE_TYPE.DIESEL) {
    if (lead.transmission_type === TRANSMISSION_TYPE.MT) {
      keysToFilter = [SO_QUESTION_KEY.CngLpgFitment, SO_QUESTION_KEY.CngLpgEndorsed, SO_QUESTION_KEY.AutoGear];
    } else if (lead.transmission_type === TRANSMISSION_TYPE.AMT || lead.transmission_type === TRANSMISSION_TYPE.AT) {
      keysToFilter = [SO_QUESTION_KEY.CngLpgFitment, SO_QUESTION_KEY.CngLpgEndorsed, SO_QUESTION_KEY.ManualGear];
    }
  }
  if (keysToFilter.length > NUMBERS.ZERO) {
    questions = questions.filter(elem => !keysToFilter.includes(elem.key));
  }
  return questions;
};
const fetchSaleonlineQuestions = (version, lead) => dispatch => {
  return new Promise((resolve, reject)=> {
    return VehicleService.getSOQuestions(version)
      .then(response => {
        const questions = getSOQuestionsByLead(response?.detail?.questions, lead);
        let quesrionsSortByDisplayOrder = questions.sort((a,b)=> a.displayOrder - b.displayOrder);
        dispatch(fetchSaleonlineQuestionsSuccess(quesrionsSortByDisplayOrder));
        resolve(response?.detail?.questions);
      })
      .catch(error => {
        dispatch(fetchSaleonlineQuestionsFailure(error));
        reject(error);
      });
  });
};

const fetchLeadDetailSuccess = (data) => ({
  type: Types.FETCH_LEAD_DETAIL_SUCCESS,
  data
});

const fetchLeadDetailFailure = (error) => ({
  type: Types.FETCH_LEAD_DETAIL_FAILURE,
  error
});

const fetchSaleonlineQuestionsSuccess = (data) => ({
  type: Types.FETCH_SALEONLINE_QUESTIONS_SUCCESS,
  data
});

const fetchSaleonlineQuestionsFailure = (error) => ({
  type: Types.FETCH_SALEONLINE_QUESTIONS_FAILURE,
  error
});

const resetLeadDetail = () => ({
  type: Types.REST_LEAD_DETAIL
});

const resetSaleonlineQuestions = () => ({
  type: Types.REST_SALEONLINE_QUESTIONS
});

const updateAnsweredQuestions = ({key, value}) => ({
  type: Types.UPDATE_USER_ANSWERED_QUESTION,
  key,
  value
});
const LoadingLeadDetail = ({flag}) => ({
  type: Types.LOADING_LEAD_DETAIL,
  flag
});

const updateSaleonlineQuestions = (data) => ({
  type: Types.UPDATE_SALEONLINE_QUESTIONS,
  data
});

const updateOfferPrice = (price) => ({
  type: Types.UPDATE_OFFER_PRICE,
  price
});

export {
  fetchLeadDetail,
  resetLeadDetail,
  fetchSaleonlineQuestions,
  updateAnsweredQuestions,
  resetSaleonlineQuestions,
  LoadingLeadDetail,
  updateSaleonlineQuestions,
  updateOfferPrice,
};
