import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Popup from "./component";
import {resetApiNoAccess} from "../hoc-access-controller/actions";
const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  ResetApiNoAccessConnect: resetApiNoAccess
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Popup);
