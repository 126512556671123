import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CustomerPartnersViewed from "./component";

const mapStateToProps = ({ customerOffer }) => ({ customerOffer });

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPartnersViewed);
