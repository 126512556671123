/* eslint-disable indent */
import React from "react";
import styles from "./payment-delivery-status.module.scss";
import DoneIcon from "../../components/images/tokenDone.svg";
import WaitingIcon from "../../components/images/waitingIcon.svg";
import PaymentFailed from "../../components/images/paymentFailed.svg";
import { NUMBERS } from "../../utils/app-constants";

const PaymentDeliveryStatus = ({ rowDetails }) => {
    const { paymentStatusSection, dealerTokenStatus} = rowDetails || {};
    const { stage1, stage2, stage2Success, loan } = paymentStatusSection || {};
    const getStatusClass = (status) => {
        let imgClass = "";
        if (status.includes("Failed")){
            imgClass = "paymentFailed";
        }
        else if (status.includes("Done")){
            imgClass = "paymentDone";
        }
        else if (status.includes("Waiting")){
            imgClass = "WaitingIcon";
        }
        return imgClass;
    };

    const getImageSrc = (status) => {
        let src = WaitingIcon;
        let lowerCaseStatus = status.toLowerCase();
        if (lowerCaseStatus.includes("failed")){
            src = PaymentFailed;
        }
        else if (lowerCaseStatus.includes("done") || lowerCaseStatus.includes("submitted") || lowerCaseStatus.includes("approved") || lowerCaseStatus.includes("raised")){
            src = DoneIcon;
        }
        return src;
    };
    
    return (
      <div className={styles.paymentDeliveryStatus}>
        <div className={styles.tokanStatus}>
          <ul>
            {(!!stage1 && !!stage2) && <li className={stage2Success ? styles.deliveryBorder : styles.deliveryBorderFailed}>
              <img src={getImageSrc(stage1)} alt={stage1} />
              <h4 >{stage1}</h4>
            </li>
                    }
            {!!stage2 && <li>
              <img src={getImageSrc(stage2)} alt={stage2} />
              <h4 className={`${styles[getStatusClass(stage2)]}`}>{stage2}</h4>
            </li>
                    }
          </ul>
        </div>
        {!!loan && !!loan.stage1 && <div className={styles.loan}>
          <ul>
            <li>
              <h3>Loan detected</h3>
            </li>
            {!!loan && !!loan.stage1 && loan.stage1.toLowerCase().includes("equifax pending") && !!loan.stage2Success && <li>
              <p className={styles.pending}>Equifax Pending</p>
            </li> }
          </ul>
            {/* <ul>
                    <li>
                        <p className={styles.lcrReceived}>LCR Pending</p>
                    </li>
                    <li>
                        <p className={styles.pending}>Equifax Pending</p>
                    </li>
                </ul> */}
        </div>
            }
        {(!!loan && !!loan.stage1 && !!loan.stage2) && <div className={styles.loanStatus}>
          <div className={styles.tokanStatus}>
            <ul>
              {(!!loan.stage1 && !!loan.stage2) && <li className={loan.stage2Success ? styles.deliveryBorder : styles.deliveryBorderFailed}>
                <img src={getImageSrc(loan.stage1)} alt={loan.stage1} />
                <h4 >{loan.stage1}</h4>
                </li>
                        }
              {!!loan.stage2 && <li>
                <img src={getImageSrc(loan.stage2)} alt={loan.stage2} />
                <h4 className={`${styles[getStatusClass(loan.stage2)]}`}>{loan.stage2}</h4>
                </li>
                        }
            </ul>
          </div>
        </div>
            }
        <div>
          {dealerTokenStatus === NUMBERS.ONE && <p className={styles.tokenPending}>
            <span>Dealer Token Pending</span>
            </p>}
          {dealerTokenStatus === NUMBERS.TWO &&<p className={styles.tokenReceived}>
            <span>Dealer Token Received</span>
            </p>}
        </div>
      </div>
    );
};

export default PaymentDeliveryStatus;
