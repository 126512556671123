import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Reason from "./component";

const mapStateToProps = ({
  lostLeads
}) => ({lostLeads});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Reason);
