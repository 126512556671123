import React,{useState, useEffect} from "react";
import LabelWithText from "../../../../components/shared/label-with-text/component";
import H2 from "../../../../components/h2/component";
import styles from "./view-cc-remarks-popup.module.scss";
import moment from "moment";
import {AppointmentService} from '../../../../service';
const ViewCCRemarksPopup = (props) => {
	const [dataRemarks, setDataRemarks] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	useEffect(() => {
		if (!!props?.data?.ticketId) {
			setIsLoading(true);
			AppointmentService.getZendeskTicketComments(props?.data?.ticketId).then((resp)=> {
				const {detail=[]} = resp || {};
				setDataRemarks(detail);
			}).catch(()=>{
				setDataRemarks([]);
			}).finally(()=>{
				setIsLoading(false);
			});
		}
	}, []);
	return (
		<div className={styles.viewccremarkswrap}>
			<H2 text="CC Remarks" />
			<div className={styles.infowrapper}>
				<LabelWithText 
					label={"Appointment Id"}
					text={props?.data?.appointmentId}
				/>
				<LabelWithText 
					label={"Region"}
					text={props?.data?.region}
				/>
				<LabelWithText 
					label={"Branch"}
					text={props?.data?.branch}
				/>
				<LabelWithText 
					label={"Date Modified"}
					text={moment(props?.data?.updatedDate).format('ll')}
				/>
			</div>
			{!isLoading && <div className={styles.remarkswrapper}>
				<span className={styles.title}>Description</span>
				{dataRemarks.map((item, index)=> (
					<div className={styles.callLogCard} key={`row-${index}`}>
						<p className={styles.datetimeinfo}>{moment(item?.created_at).format('lll')}</p>
						<div className={styles.remarksInfo}>{item?.body}</div>
					</div>
				))
				}
				
			</div>}
		</div>
	);
};

export default ViewCCRemarksPopup;