import React from "react";
import styles from "./status.module.css";
import IconRoundArrowGreen from "../shared/icon-round-arrow-green/component";
import IconRoundArrowOrange from "../shared/icon-round-arrow-orange/component";
import IconRoundArrowRed from "../shared/icon-round-arrow-red/component";

const AcceptanceStatus = ({ status, showStatus }) => {
  const states = {
    high: {
      text: "HIGH PAI ACCEPTANCE",
      style: styles.statusWrapGreen,
      textStyle: styles.statusTextGreen,
      icon: IconRoundArrowGreen
    },
    medium: {
      text: "MEDIUM PAI ACCEPTANCE",
      style: styles.statusWrapOrange,
      textStyle: styles.statusTextOrange,
      icon: IconRoundArrowOrange
    },
    low: {
      text: "LOW PAI ACCEPTANCE",
      style: styles.statusWrapRed,
      textStyle: styles.statusTextRed,
      icon: IconRoundArrowRed
    },
    '': {
      text: "LOW PAI ACCEPTANCE",
      style: styles.statusWrapRed,
      textStyle: styles.statusTextRed,
      icon: IconRoundArrowRed
    },
  };
  const Icon = states[status].icon;

  return (
    showStatus === 1 && <div className={states[status].style}>
      <Icon />
      <p className={states[status].textStyle}>{states[status].text}</p>
    </div>

  );
};

export default AcceptanceStatus;
