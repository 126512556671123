import React, { useEffect, useState } from "react";
import styles from "./question-progressbar.module.scss";
import FilledIcon from "./images/filledIcon.png";
import Button from "../../../../components/shared/button/component";
import { NUMBERS } from "../../../../utils/app-constants";
import { BUTTON_STYLES } from "../../../common/app-constants";

const QuestionProgressBar = ({
  questionKey,
  totalSteps,
  currentStepIndex,
  onConfirm,
  setCurrentQuestionIndex,
  userResponse
}) => {
  const width = `${(currentStepIndex + 1) / totalSteps * NUMBERS.HUNDRED}%`;
  const [isPrevEnable, setIsPrevEnable] = useState(false);
  const [isNextEnable, setIsNextEnable] = useState(false);
  const [isConfirmButtonVisible, setIsConfirmButtonVisible] = useState(false);
  const [isConfirmButtonEnabled, setIsConfirmButtonEnabled] = useState(false);

  const onConfirmClick = () => {
    setIsConfirmButtonEnabled();
    onConfirm();
  };
  useEffect(()=> {
    const answered = Object.keys(userResponse).length > 0 ?  Object.keys(userResponse).length - NUMBERS.ONE : 0;
    setIsNextEnable(false);
    setIsPrevEnable(false);
    if (currentStepIndex === NUMBERS.ZERO) {
      if (answered > NUMBERS.ZERO) {
        setIsNextEnable(true);
      }
    } else {
      setIsPrevEnable(true);
      if (answered > NUMBERS.ZERO && currentStepIndex < totalSteps - NUMBERS.ONE) {
        if (answered > currentStepIndex) {
          setIsNextEnable(true);
        }
      } 
      setIsConfirmButtonVisible(currentStepIndex === totalSteps - NUMBERS.ONE);
      if (userResponse[questionKey] && currentStepIndex === totalSteps - NUMBERS.ONE) {
        setIsConfirmButtonEnabled(Object.keys(userResponse[questionKey]).length > 0);
      }
    }
  },[totalSteps, currentStepIndex, userResponse, questionKey]);

  return (
    <div className={styles.progressWrap}>
      <div className={styles.flexItems}>
        <div className={styles.leftColumn}>
          <span className={styles.count}>{`${(currentStepIndex + 1)} of ${totalSteps} answered`}</span>
          <div className={styles.meter}>
            <span style={{ width }}><span className={styles.progress} /></span>
          </div>
        </div>
        <div className={styles.flexItems}>
          {!!isPrevEnable ?
            <span className={`${styles.arrowWrap} ${styles.active}`}>
              <img src={FilledIcon} onClick={()=> setCurrentQuestionIndex(currentStepIndex - NUMBERS.ONE)} alt="prev" />
            </span>
                        : <span className={styles.arrowWrap}>
                          <img src={FilledIcon} alt="prev"/>
                        </span>
          }
          {!!isNextEnable ?
            <span className={`${styles.arrowWrap} ${styles.active}`}>
              <img src={FilledIcon}  className={styles.next} onClick={()=> setCurrentQuestionIndex(currentStepIndex + NUMBERS.ONE)} alt="next" />
            </span>
                        : <span className={styles.arrowWrap}>
                          <img src={FilledIcon} className={styles.next} alt="next" />
                        </span>
          }
        </div>
      </div>
      {isConfirmButtonVisible && <div className={styles.buttonWrap}>
        <Button 
          disabled={!isConfirmButtonEnabled} 
          onClick={onConfirmClick} 
          ctaText={"CONFIRM"} 
          classNames={isConfirmButtonEnabled ? BUTTON_STYLES.DEFAULT : BUTTON_STYLES.DISABLED}
        />
      </div>}
    </div>
  );
};

export default QuestionProgressBar;
