import React, {useRef, useState} from "react";
import styles from "./reschedule-appointment-popup.module.scss";
import H2 from "../h2/component";
import Button from "../shared/button/component";
import { LOCAL_STORAGE_KEYS, MAP_TYPE} from "../../utils/app-constants";
import { getFromLocalStorage, showToastMessages } from "../../utils/helper";
import AutoComplete from "../../utils/auto-complete";
import OnlineSearch from "../shared/search/online";
import { googlePlacesOptions } from "../../utils/auto-complete/search-options";
import { AppointmentService } from "../../service";
import _debounce from 'lodash/debounce';

const RescheduleAppointmentPopup = ({appointmentId, onClose, rowDetails, index}) => {
  const changeLocationRef = useRef();
  const mapType = getFromLocalStorage(LOCAL_STORAGE_KEYS.MAP_TYPE_ACTIVE);
  const [availableLocations, setAvailableLocations] = useState([]);
  const [availableZone, setAvailableZone] = useState(null);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState('');
  const [selectedCityId, setSelectedCityId] = useState('');
  const [selectedZoneId, setSelectedZoneId] = useState('');
  const [availableLocationDateSlots, setAvailableLocationDateSlots] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [availableLocationDateTimeSlots, setAvailableLocationDateTimeSlots] = useState([]);
  const [selectedTimeslot, setSelectedTimeslot] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [selectedAppointmentId, setSelectedAppointmentId] = useState(appointmentId);
    
  const loadSuggestedOptions = React.useCallback(
    _debounce((inputValue, callback) => {
      googlePlacesOptions(inputValue, mapType).then(options => callback(options));
    }, 1000),
    []
  );

  const resetData = () => {
    setAvailableLocationDateTimeSlots(null);
    setSelectedTimeslot(null);
    setAvailableSlots(null);
    setAvailableZone(null);
    setAvailableLocations(null);
    setSelectedLocationId();
    setSelectedCityId();
    setSelectedZoneId();
    setAvailableLocationDateSlots();
    setSelectedDate();
    setSelectedTimeslot();
  };
  const onLocationSelection = async (item) => {
    let lat = item.lat;
    let lng = item.lng;

    if (mapType === MAP_TYPE.GOOGLE){
      const response = await AutoComplete.getInstance(mapType).getGeoCode({
        placeId: item.value }, "place");
      const coords = response[0].geometry.location;
      lat = coords.lat();
      lng = coords.lng();
    }
    setSearchText(item.label);
    resetData();
    AppointmentService.getFetchLocation(lat, lng)
      .then(response => {
        const { data: { detail : {locations, slots, zone } } } = response;
        setAvailableLocations(locations);
        setAvailableSlots(slots);
        setAvailableZone(zone);
      }).catch(error => {
        console.log(error);
      });
  };
  const onSelectNearestStore = (locationId,cityId,zoneId) => {
    setSelectedLocationId(locationId);
    setSelectedCityId(cityId);
    setSelectedZoneId(zoneId);
    const selectedLocationSlot = Object.values(availableSlots).filter(item => item.id === locationId);
    const availableDates = !!selectedLocationSlot && Object.values(selectedLocationSlot)[0].date_slot;
    setAvailableLocationDateSlots(availableDates);
    setAvailableLocationDateTimeSlots(null);
    setSelectedTimeslot(null);
  };
  const onSelectedDateAvailableTimeSlot = (date) => {
    setSelectedDate(date);
    const availableTimeSlots = Object.values(availableLocationDateSlots).filter(item => item.date === date);
    const selectedDateTimeSlots = Object.values(availableTimeSlots[0].time);
    var timeSlots = [];
    for (const c of selectedDateTimeSlots){
      for (let [key, value] of Object.entries(c)){
        timeSlots.push({key,value});
      }
    }
    setAvailableLocationDateTimeSlots(timeSlots);
    setSelectedTimeslot(null);
  };
  const onSelectedTimeslots = (time) => {
    setSelectedTimeslot(time);
  };
  const onRescheduleAppointment = () => {
    const payload = {
      appointment_id: selectedAppointmentId,
      city_id: selectedCityId,
      store_id: selectedLocationId,
      zone_id: selectedZoneId,
      appointment_date: selectedDate,
      appointment_time: selectedTimeslot
    };
    AppointmentService.postRescheduleAppointment(payload)
      .then(response => {
        console.log(response);
        const { data: { detail} } = response;
        showToastMessages(detail);
      }).catch(error => {
        const { detail } = error;
        showToastMessages(detail.message, false);
      });
    setTimeout(()=> {
      onClose();
    }, 2000);
  };
  const showTimeslot = (index) => {
    const {value: {time}, key} = availableLocationDateTimeSlots[index] || '';
    return (
      <div className={`${styles.time} ${selectedTimeslot ===  key ? styles.active : ''}`} onClick={()=> onSelectedTimeslots(key)}>
        <h3>{time}</h3>
      </div>
    );
  };
  return (
    <>
      <div className={styles.rescheduleAppointmentPopup}>
        <div className={styles.heading}>
          <H2 text={"Reschedule Appointment"} />
        </div>
                
        <div className={styles.inputWrap}>
          <OnlineSearch
            placeholder="Search your Location/Pincode"
            onChange={onLocationSelection}
            getOptions={loadSuggestedOptions}
            overrideSearchRef={changeLocationRef}
            customDefaultValue = {searchText}
          />
        </div>
        <p className={styles.title}>Nearest Store</p>
        <div className={styles.storesWrapper}>
          {!!availableZone &&
          <div className={`${styles.store} ${selectedLocationId === availableZone.location_id ? styles.active : ''}`}  onClick={()=> onSelectNearestStore(availableZone.location_id, availableZone.city_id, availableZone.zone_id)}>
            <h3>{`${availableZone.zone_name}@${availableZone.home_inspection_cost}`}</h3>
            <p>{availableZone.available_location}</p>
          </div>
          }
          {!!availableLocations && Object.values(availableLocations).map((item) => {
            return (
              <div className={`${styles.store} ${selectedLocationId === item.location_id ? styles.active : ''}`} onClick={()=> onSelectNearestStore(item.location_id, item.city_id,'')}>
                <h3>{item.location_name}</h3>
                <p>{`${item.address1}, ${item.address2}, ${item.city_name} - ${item.pincode}`}</p>
              </div>
            );
          })}
        </div>
        {!!availableLocationDateSlots && <p className={styles.title}>Preferred date</p> &&
        <div className={styles.dateWrapper}>
          {
            !!availableLocationDateSlots && Object.values(availableLocationDateSlots).map((item) => {
              return (
                <div className={`${styles.date} ${selectedDate === item.date ? styles.active : ''}`} onClick={()=> onSelectedDateAvailableTimeSlot(item.date)}>
                  <h3>{item.value}</h3>
                </div>
              );
            })
          }
        </div>
        }
        {!!availableLocationDateTimeSlots && <p className={styles.title}>Preferred time</p> &&
        <>
          <div className={styles.timeWrapper}>
            {Object.keys(availableLocationDateTimeSlots).map((index) => showTimeslot(index))}
          </div>
          <div className={styles.ctaWrapper}>
            <Button classNames={"saveCta"} ctaText={"RESCHEDULE"} onClick = {onRescheduleAppointment} />
          </div>
        </>
        }
      </div>
    </>
  );
};

export default RescheduleAppointmentPopup;
