import React from "react";
import styles from "./appointment-detail-card.module.scss";
import CustomerDetail from "../hi-customer-detail";
import PaymentPrStatus from "../payment-pr-status";
import PaymentDeliveryStatus from "../payment-delivery-status";
import PaymentVehicleStatus from "../payment-vehicle-status";
const AppointmentDetailCard = ({rowDetails, appointmentState, index }) => {
  return (
    <div className={styles.appointmentDetailCard}>
      <div className={styles.appointmentCard}>
        <div className={styles.customerDetail}>
          <CustomerDetail rowDetails={rowDetails} index={index} appointmentState={appointmentState} isPPRevamp/>
        </div>
        <div className={styles.prStatus}>
          <PaymentPrStatus rowDetails={rowDetails} />
        </div>
        <div className={styles.deliveryPaymentLoans}>
          <PaymentDeliveryStatus rowDetails={rowDetails} />
        </div>
        <div className={styles.vehicleStatus}>
          <PaymentVehicleStatus rowDetails={rowDetails}/>
        </div>
      </div>
    </div>
  );
};

export default AppointmentDetailCard;
