import Types from "./types";
import { AppointmentService } from "../../service";
import { getRATokenDetailsMock } from "../../utils/mocApi";

const quack = () => ({
  type: Types.QUACK
});

const fetchRaTokenData = (appointmentId) => dispatch => {
  // const response = getRATokenDetailsMock();
  // const { detail } = response;
  // dispatch(fetchRaTokenDataSuccess(detail));
  return new Promise((resolve, reject) => {
    return AppointmentService.getPrKyc(appointmentId)
      .then(({ data }) => {
        const { detail } = data;
        dispatch(fetchRaTokenDataSuccess(detail));
        resolve(detail.data);
      })
      .catch(error => {
        // dispatch(fetchCarDataFailure(error));
        reject(error);
      });
  });
};

const fetchRaTokenDataSuccess = (data) => ({
  type: Types.FETCH_RA_TOKEN_SUCCESS,
  data
});

export {
  quack,
  fetchRaTokenData
};
