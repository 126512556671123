import React from "react";
import H2 from "../h2/component";
import CarouselInspectionReport from "../carousel-inspection-report";
import CarSpecifications from "../car-specifications";
import  styles  from "./car-detail.module.css";
import popups from "../../utils/popup-constants";
import Logo from "../logo/component";

const CarDetailPopup = ({ currentCarDetails, negotiations }) => {
  const { currentPopup } = negotiations;
  return (
    <div>
      { currentPopup === popups.CAR_SPECS && <H2 text={"Inspection Report"}/> }
      { currentPopup === popups.CAR_SPECS_CUSTOMER_VIEW && <Logo src={Logo} alt="logo" /> }
      <div className={styles.inspectionWrap}>
        <div className={currentPopup === popups.CAR_SPECS_CUSTOMER_VIEW ? styles.negotiationGallery : styles.carButtonGallery}>
          <CarouselInspectionReport currentCarDetails={currentCarDetails} />
        </div>
        <CarSpecifications currentCarDetails={currentCarDetails} />
      </div>
    </div>
  );
};

export default CarDetailPopup;
