import React, { useState, useEffect } from "react";
import styles from "./accept-price-pll-popup.module.scss";
import H2 from "../h2/component";
import Button from "../shared/button/component";
import { makeStyles } from '@material-ui/core/styles';
import priceFormatter from "../../utils/helpers/price-formatter";
import { redirectToUrl, showToastMessages, getFromLocalStorage } from "../../utils/helper";
import { LOCAL_STORAGE_KEYS } from "../../utils/app-constants";
import { StylesContext } from "@material-ui/styles/StylesProvider";
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => {
  return {
    textField: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      fontSize: '10px',
      width: '100%'
    }
  };
});

const AcceptPricePllPopup = (
  {
    negotiations,
    close,
    resetListing,
    setCurrentPopupConnect,
    setSuggestedC24QuoteConnect,
    updateSuggestedQuoteConnect,
    acceptOcbQuoteConnect
  }
) => {
  const { data, currentAppointmentIndex, currentSuggestedC24Quote } = negotiations;
  const currentAppointmentData = data[currentAppointmentIndex] || {};
  const { carId, customerName, make, model, year, c24Quote, appointmentId, adminUrl, sortOrder, negotiated_price, auctionId } = currentAppointmentData;
  const [inputText, setInputText] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const classes = useStyles();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [generatePr, setGeneratePr] = useState(false);

  useEffect(() => {
    setSuggestedC24QuoteConnect(negotiated_price);
    setInputText(priceFormatter(negotiated_price));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onChangeText = (e) => {
    const value = e.target.value && e.target.value.replace(/,/g, '') ? priceFormatter(Number(e.target.value.replace(/,/g, ''))) : "";
    const cPrice = Number(e.target.value.replace(/,/g, ''));
    setSuggestedC24QuoteConnect(cPrice);
    setInputText(value);
  };

  const onSubmitAcceptOcb = () => {
    setIsSubmitted(true);
    setisLoading(true);
    const loginData = JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA) || "null");
    if (!loginData && !loginData.email) { showToastMessages("Please login again.", false); }

    const payload = {
      "appointmentId": appointmentId,
      "carId": carId,
      "status": "ACCEPTED",
      "c24Quote": c24Quote,
      "user": loginData.email,
      "auction_id": auctionId
    };
    acceptOcbQuoteConnect(payload)
      .then(() => {
        setGeneratePr(true);
        resetListing();
        setIsSubmitted(false);
        setisLoading(false);
      })
      .catch((resp) => {
        setGeneratePr(false);
        showToastMessages(resp, false);
        resetListing();
        setIsSubmitted(false);
        setisLoading(false);
      });

  };
  const onSubmitSuggestedQuote = () => {
    setIsSubmitted(true);
    setisLoading(true);

    const payload = {
      "appointmentId": appointmentId,
      "c24BuyingQuote": currentSuggestedC24Quote
    };

    updateSuggestedQuoteConnect(payload)
      .then(() => {
        redirectToUrl(adminUrl);
        setIsSubmitted(false);
        setisLoading(false);
        resetListing();
        close();
      })
      .catch((resp) => {
        showToastMessages(resp, false);
        setIsSubmitted(false);
        setisLoading(false);
      });
  };
  return (
    <>
      {!generatePr && <div className={styles.acceptPriceWrapper}>
        <H2 text={"accept price - PLL"} />
        <p className={styles.priceText}>Are you sure you want to accept this price & stop OCB?</p>
        <div className={styles.buttonWrapper}>
          <Button ctaText={"Yes"}
            isLoading={isLoading}
            disabled={isSubmitted}
            onClick={onSubmitAcceptOcb}
            classNames="blueButton" />
          <Button ctaText={"cancel"} onClick={close} classNames="cancelCta" />
        </div>
      </div>}

      {generatePr && <div className={styles.popupWrap}>
        <H2 text="GENERATE PR" />
        <ul className={styles.customerDesc}>
          <li>{customerName}</li>
          <li>{`${make} ${model}`}</li>
          <li>{year}</li>
        </ul>

        <div>
          <TextField
            label="Guaranteed Closure Price"
            className={classes.textField}
            margin="dense"
            variant="outlined"
            onChange={onChangeText}
            value={inputText}
            inputProps={{
              maxLength: 9,
            }}
            InputLabelProps={{ style: {} }}
          />
        </div>
        <div className={styles.getQuote}>
          <Button
            isLoading={isLoading}
            disabled={isSubmitted}
            onClick={onSubmitSuggestedQuote}
            classNames="blueButton"
            ctaText="Submit" />
          <Button classNames="cancelCta" onClick={close} ctaText="CANCEL" />
        </div>
      </div>}

    </>
  );
};

export default AcceptPricePllPopup;
