import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  isLoading: true,
  columns: [],
  page: 0,
  data: [],
  error: null,
  total: 0,
  make: "",
  model: "",
  year: ""
};

export const fetchsimilarCars = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};

export const fetchsimilarCarsSuccess = (state = INITIAL_STATE, { data }) => {
  const { make, model, year, result } = data;
  return { ...state, make: make, model: model, year: year, data: [...state.data, ...result], isLoading: false, error: null };
};

export const fetchsimilarCarsFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, error, make: "", model: "", year: "", data: [], isLoading: false };
};

export const HANDLERS = {
  [Types.FETCH_SIMILARCARS_SUCCESS]: fetchsimilarCarsSuccess,
  [Types.FETCH_SIMILARCARS_FAILURE]: fetchsimilarCarsFailure

};

export default createReducer(INITIAL_STATE, HANDLERS);
