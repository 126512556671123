export const KM_RANGE = [
	{
		value: "5000",
		label: "Upto 10,000 Km",
	},
	{
		value: "15000",
		label: "10,000 - 20,000 Km",
	},
	{
		value: "30000",
		label: "20,000 - 40,000 Km",
	},
	{
		value: "50000",
		label: "40,000 - 70,000 Km",
	},
	{
		value: "80000",
		label: "70,000 - 1,00,000 Km",
	},
	{
		value: "110000",
		label: "1,00,000 - 1,20,000 km",
	},
	{
		value: "130000",
		label: "Above 1,20,000 km",
	},
];

export const SO_QUESTION_VERSION = {
	VERSION1: "v1",
	VERSION2: "v2",
	VERSION3: "v3",
};

export const SO_QUESTION_KEY = {
	OwnerNumber: "owner_number",
	IsAccidental: "isaccidental",
	HasRust: "hasrust",
	TowingRequired: "towing_required",
	AutoGear: "autoGear",
	ManualGear: "manualGear",
	HasScratches: "hasScratches",
	HasDents: "hasDents",
	HasDamages: "hasDamages",
	PastDamages: "pastDamages",
	CarStart: "carStart",
	Odometer: "odometer",
	TyresReplaced: "tyresReplaced",
	RCAvailability: "rcAvailability",
	CngLpgFitment: "cngLpgFitment",
	CngLpgEndorsed: "cngLpgEndorsed",
	Insurance_type: "insurance_type",
	IsUnderHypothecationDocumentDetails: "isUnderhypothecation_documentDetails",
	OdometerReading: "odometer_reading",
	AdditionalIssues: "additionalIssues",
};

export const SO_QUESTION_INPUT_TYPE = {
	BUTTON: "button",
	TEXT: "textbox",
	CHECKBOX: "checkbox",
};

export const SO_SCREEN_TYPES = {
	GS_NO_ELIGIBLE: "NOT_ELIGIBLE",
	SO_OFFER_PRICE: "SO_OFFER_PRICE",
	SO_NO_OFFER_PRICE: "SO_NO_OFFER_PRICE",
	SO_QUESTIONS: "SO_QUESTIONS",
	SO_OFFER_PRICE_ANALYZER: "SO_OFFER_PRICE_ANALYZER",
	SO_CJ_OFFER_PRICE: "SO_CJ_OFFER_PRICE",
	APPOINTMENT_SUCCESS: "APPOINTMENT_SUCCESS",
	NA: "",
};

export const APP_CONSTANTS = {
	RESCHEDULE_APPOINETMENT: "Reschedule Appointment",
	YES: "yes",
	NO: "no",
	RESUME: "RESUME",
	SEND_PRICE: "SEND PRICE",
	BOOK_INSPECTION: "Book Inspection",
	TOTAL_KMS_THRESHOLD: 30000,
	SUBMIT: "submit",
	REJECTED: "Rejected",
	ACCEPTED: "Accepted",
	OKTA_TOKEN: "okta-token-storage",
	appType: "b2b",
	DEFAULT_SERVICE_CHARGE: 8850,
	LEAD_SOURCE: "Dealer_leads",
	REVISE_PRICE: "Revise price",
	CONTACT_DEALER: "Contact dealer",
	RC_STATUS: "Rc status",
	LEAD_PROCURED: "Procured",
	LEAD_INSPECTION_BOOKED: "Inspection Booked",
	LEAD_PRICE_ACCEPTED: "Price Accepted",
	LEAD_PRICE_REJECTED: "Price Rejected",
	LEAD_PENDING_PRICE_ACCEPTANCE: "Pending Price Acceptance",
	LEAD_INCOMPLETE: "Lead incomplete",
	NA: "NA",
	LF: "LF",
	AF: "AF",
	ENTER_ADDRESS: "Enter address",
	SEARCH_LOCATION: "Search location",
	INSPECTION_IN_PROGRESS: "View Details",
	SOURCE: "b2b-ncd-narrow",
	NO_ROLES_KEY: "f47c3841-9a6d-4bfe-b2c9-88d91c8f2a73",
	UNAUTHORIZED_LOGIN_KEY: "6fd11258-7e25-4b9c-bac1-23f46b32a810",
	DEFAULT_LOGIN_KEY: "a8c57f9b-36d5-4d8e-af5c-92e8bb7f6e1c",
};

export const APPOINTMENT_STATUS = {
	CREATED: "created",
	CAPTURED: "appointmentcaptured",
	ACCEPTED: "accepted",
	REJECTED: "rejected",
};

export const REJECT_PRICE_REASON_KEYS = {
	LOWER_PRICE: "CAR_SOLD_TO_OTHER_BUYER",
	DONT_WANT_PAY_SERVICE: "DONT_WANT_SELL_CAR_NOW",
	NOT_INTERESTED: "PRICE_QUOTE_LOWER",
	OTHER: "OTHERS",
};

export const REJECT_PRICE_REASONS = [
	{
		key: REJECT_PRICE_REASON_KEYS.LOWER_PRICE,
		label: "Car sold to other buyer",
	},
	{
		key: REJECT_PRICE_REASON_KEYS.DONT_WANT_PAY_SERVICE,
		label: "Do not want to sell the car now",
	},
	{
		key: REJECT_PRICE_REASON_KEYS.NOT_INTERESTED,
		label: "Price quote is lower than expected",
	},
	{ key: REJECT_PRICE_REASON_KEYS.OTHER, label: "Others" },
];

export const BUTTON_STYLES = {
	DEFAULT: "selfCheckinCta",
	DISABLED: "deactiveCta",
};

export const DEALER_LEAD_STATUS = {
	OFFER_PRICE: "OFFER_PRICE",
	ACCEPTING_PRICE: "ACCEPTING_PRICE",
	REJECTING_PRICE: "REJECTING_PRICE",
	POST_ACCEPTED: "POST_ACCEPTED",
	POST_REJECTED: "POST_REJECTED",
};

export const USER_ROLES = {
	RA: "B2B_USER_RA",
	CJ: "B2B_USER_CJ",
	ADMIN: "ADMIN",
};

export const USER_ROLES_PATH = {
	ADMIN: [
		"/b2b/leads",
		"/b2b",
		"/b2b/lead",
		"/b2b/lead/detail",
		"/b2b/login",
		"/implicit",
	],
	CJ: ["/b2b", "/b2b/lead", "/b2b/lead/detail", "/b2b/login", "/implicit"],
};

export const LS_KEYS = {
	DISPLAY_NAME: "display_name",
	DISPLAY_EMAIL: "display_email",
	DISPLAY_ROLE: "display_role",
	ORGANIZATION_NAME: "organization",
	USER_INFO: "b2b-user-info",
};

export const SORT_ORDER = {
	NEWEST_FIRST: "asc",
	OLDEST_FIRST: "desc",
};

export const FILTER_KEYS = {
	LEAD_INCOMPLETE: "LEAD_INCOMPLETE",
	PENDING_PRICE_ACCEPTANCE: "PENDING_PRICE_ACCEPTANCE",
	PRICE_ACCEPTED: "PRICE_ACCEPTED",
	PRICE_REJECTED: "PRICE_REJECTED",
	INSPECTION_BOOKED: "INSPECTION_BOOKED",
	PROCURED: "PROCURED",
};

export const FILTER_DROPDOWN_SOURCE = [
	{ value: FILTER_KEYS.LEAD_INCOMPLETE, label: "Lead Incomplete" },
	{
		value: FILTER_KEYS.PENDING_PRICE_ACCEPTANCE,
		label: "Pending Price Acceptance",
	},
	{ value: FILTER_KEYS.PRICE_ACCEPTED, label: "Price Accepted" },
	{ value: FILTER_KEYS.PRICE_REJECTED, label: "Price Rejected" },
	{ value: FILTER_KEYS.INSPECTION_BOOKED, label: "Inspection Booked" },
	{ value: FILTER_KEYS.PROCURED, label: "Procured" },
	{ value: "", label: "All" },
];

export const TRANSMISSION_TYPE = {
	MT: "MT",
	AT: "AT",
	AMT: "AMT",
};

export const FULE_TYPE = {
	PETROL: "petrol",
	DIESEL: "diesel",
	CNG: "cng",
	ELECTRIC: "electric",
};

export const OFFER_PRICE_PAGE_TYPE = {
	NARROW_PRICE_PAGE: "narrow",
	ASSURED_PRICE_PAGE: "assured",
	WEBQUOTE_PRICE_PAGE: "web_quote",
};
