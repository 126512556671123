import React, { useState, useRef } from "react";
import styles from "./dealer-reject-price.module.scss";
import ErrorIcon from '../not-generate-offer/images/not-found.svg';
import { BUTTON_STYLES, REJECT_PRICE_REASONS, REJECT_PRICE_REASON_KEYS } from "../../common/app-constants";
import Textarea from "../../../components/shared/textarea/component";
import Button from "../../../components/shared/button/component";
import { NUMBERS } from "../../../utils/app-constants";
const DealerRejctPrice = ({
  onConfirmRejectCallback,
  isSubmit
}) => {
  const [selectedReason, setSelectedReason] = useState('');
  const [additionalComments, setAdditionalComments] = useState('');
  const [isError, setIsError] = useState(false);
  const onRejectionButtonClick = (reason) => {
    if (!reason) {
      setIsError(true);
    } else {
      setIsError(false);
      onConfirmRejectCallback(reason);
    }
  };

  return (
    <div className={styles.sorryComponent}>
      <div className={styles.wrapper}>
        <h4 className={styles.heading}>We’re sorry to see you go.</h4>
        <img  src={ErrorIcon} alt="reject" />
      </div>
      <div className={styles.reasonArea}>
        <span className={styles.title}>Please tell us the reason for your rejection</span>
        <ul className={styles.listTab}>
          {REJECT_PRICE_REASONS.map((option, index)=> {
            return (
              <li key={index} onClick={()=> { setSelectedReason(option); setIsError(false);}}  className={selectedReason.key === option.key ? `${styles.tab} ${styles.active}` : styles.tab}>
                {option.label}
              </li>
            );
          })}
        </ul>
        {selectedReason.key === REJECT_PRICE_REASON_KEYS.OTHER && (
          <div className={styles.additionalCommentsTitle}>
            <h3>Additional Comments</h3>
            <Textarea 
              placeholder='Reason...'
              value={additionalComments}
              onChange={(e) => { setAdditionalComments(e.target.value); setIsError(false);}}
              maxLength={NUMBERS.NINETY}
            />
          </div>
        )}
        {isError && <span className={styles.error}>Rejection reason is mandatory</span>}
      </div>
            
      <div className={styles.confirmBtn}>
        <Button 
          ctaText={'CONFIRM'} 
          classNames={BUTTON_STYLES.DEFAULT} 
          onClick={()=> onRejectionButtonClick(selectedReason.key === REJECT_PRICE_REASON_KEYS.OTHER ? additionalComments : selectedReason.label)}
          disabled={isSubmit}
        />
      </div>
    </div>
  );
};

export default DealerRejctPrice;
