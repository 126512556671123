import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { resetPLLByKey } from "../pll-store-list/actions";
import { updatePllAllocationQuery } from "./actions";
import PllAllocationFilters from "./component";

const mapStateToProps = ({
  pllAllocationFilter,
  pllStores
}) => ({
  pllAllocationFilter,
  pllStores
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updatePllAllocationQueryConnect : updatePllAllocationQuery,
  resetPLLByKeyConnectConnect: resetPLLByKey
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PllAllocationFilters);
