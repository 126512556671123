import Types from "./types";

const resetGlobalSearch = () => ({
  type: Types.RESET_GLOBAL_SEARCH
});

const resetGlobalSearchByKey = (key, value) => ({
  type: Types.RESET_GLOBAL_SEARCH_BY_KEY,
  data: { key, value }
});

export {
  resetGlobalSearch,
  resetGlobalSearchByKey
};
