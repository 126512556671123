import React from "react";
import View from "../images/view.svg";
import styles from "./pending-holdback.module.css";
import { NO_HOLDBACKS } from '../../utils/app-constants';
import { redirectToUrl } from '../../utils/helper';
import { Link } from "react-router-dom";
const PendingHoldback = ({ rowDetails }) => {
  const { pendingHoldback, adminUrl } = rowDetails;
  return (
    <div className={styles.view}>
      <span className={ styles.modleName } title={ pendingHoldback }>{ pendingHoldback ? pendingHoldback:NO_HOLDBACKS }</span>
      {
        pendingHoldback !== NO_HOLDBACKS &&
        <span>
          <Link to="#" onClick={() => { redirectToUrl(adminUrl); }} >
            <img src={View} alt="" />
          </Link>
        </span>
      }
    </div>
  );
};

export default PendingHoldback;
