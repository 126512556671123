import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  isLoading: true,
  evaluatorTokens: {},
  retailView: [],
  evaluatorView: [],
  error: null
};

export const fetchEvaluatorTokens =(state = INITIAL_STATE) => {
  return {...state, isLoading: true};
};

export const fetchEvaluatorTokensSuccess = (state = INITIAL_STATE, evaluatorTokens ) => {
  return { ...state, evaluatorTokens,isLoading: false};
};

export const fetchEvaluatorTokensFailure = (state = INITIAL_STATE, { error } ) => {
  return { ...state, error, isLoading: false};
};

export const fetchRetailView =(state = INITIAL_STATE) => {
  return {...state,isLoading: true};
};
export const fetchRetailViewSuccess = (state = INITIAL_STATE, retailView ) => {
  return { ...state, retailView, isLoading: false};
};

export const fetchRetailViewFailure = (state = INITIAL_STATE, { error } ) => {
  return { ...state, error,isLoading: false};
};

export const fetchEvaluatorView =(state = INITIAL_STATE) => {
  return {...state,isLoading: true};
};
export const fetchEvaluatorViewSuccess = (state = INITIAL_STATE, evaluatorView ) => {
  return { ...state, evaluatorView,isLoading: false};
};

export const fetchEvaluatorViewFailure = (state = INITIAL_STATE, { error } ) => {
  return { ...state, error,isLoading: false};
};

export const resetView = () => {
  return INITIAL_STATE;
};

export const resetEvaluatorByKey = (state = INITIAL_STATE, { data }) => {
  const { key, value } = data;
  return { ...state, [key]: value };
};

export const HANDLERS = {
  [Types.FETCH_EVALUATOR_TOKENS]: fetchEvaluatorTokens,
  [Types.FETCH_EVALUATOR_TOKENS_SUCCESS]: fetchEvaluatorTokensSuccess,
  [Types.FETCH_EVALUATOR_TOKENS_FAILURE]: fetchEvaluatorTokensFailure,
  [Types.FETCH_RETAIL_VIEW]: fetchRetailView,
  [Types.FETCH_RETAIL_VIEW_SUCCESS]: fetchRetailViewSuccess,
  [Types.FETCH_RETAIL_VIEW_FAILURE]: fetchRetailViewFailure,
  [Types.FETCH_EVALUATOR_VIEW]: fetchEvaluatorView,
  [Types.FETCH_EVALUATOR_VIEW_SUCCESS]: fetchEvaluatorViewSuccess,
  [Types.FETCH_EVALUATOR_VIEW_FAILURE]: fetchEvaluatorViewFailure,
  [Types.RESET_EVALUATOR_BY_KEY]: resetEvaluatorByKey,
  [Types.RESET_VIEW]: resetView

};

export default createReducer(INITIAL_STATE, HANDLERS);
