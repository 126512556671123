import React, {useEffect, useState} from "react";
import {getTimeDifference, hasTimeElapsed, redirectToUrl} from "../../utils/helper";
import styles from "./qc-status-pp.module.css";
import CountdownTimer from "../shared/countdown-timer";
import HourTimer from "../shared/hour-timer";
import MinuteTimer from "../shared/minute-timer";
import { NUMBERS, QC_APPROVE } from "../../utils/app-constants";
import { Link } from "react-router-dom";
import View from "../images/view.svg";

const QcStatusPp = ({ rowDetails, appointments }) => {
  const { qcStatusPP, qcPendingDate, adminSummaryUrl, qcStatus } = rowDetails;
  const { data } = appointments;
  const [hasElapsed, setHasElapsed] = useState(false);

  useEffect(() => {
    if (qcStatusPP === NUMBERS.TWO && qcPendingDate) {
      let interval = setInterval(() => {
        const hasReachedLimit = hasTimeElapsed(qcPendingDate, NUMBERS.FIFTEEN);
        if (hasReachedLimit) {
          setHasElapsed(hasReachedLimit);
          clearInterval(interval);
          //updatePriorityCountByKeyConnect(APPOINTMENT_TYPES_STRING.PAYMENT_PROCESSING);
        };
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const renderByQcApprove = () => {
    switch (qcStatusPP) {
      case NUMBERS.TWO: {
        if (qcPendingDate) {
          return (
            <div className={`${styles.time} ${hasElapsed ? styles.lapse : ''}`}>
              <CountdownTimer direction="forward"
                initialTime={getTimeDifference(new Date(qcPendingDate), new Date())}>
                <HourTimer /> {`hrs: `}
                <MinuteTimer /> min
              </CountdownTimer>
            </div>
          );
        } else {
          return <span>{qcStatus ? qcStatus: <>-</>}</span>;
        }
      }
      case NUMBERS.FOUR:
        return <span className={styles.qcRejected}>{QC_APPROVE.REJECTED}
          <Link to="#" onClick={() => { redirectToUrl(adminSummaryUrl); }} >
            <img src={View} alt="" />
          </Link>
        </span>;

      case NUMBERS.THREE:
        return <span>{QC_APPROVE.IN_PROGRESS}</span>;
      default:
        return <span>{qcStatus ? qcStatus: <>-</>}</span>;
    }
  };

  return (
    <>
      {
        renderByQcApprove()
      }
    </>
  );

};

export default QcStatusPp;
