import { createTypes } from "reduxsauce";

export default createTypes(
  `
FETCH_CHECK_IN
FETCH_CHECK_IN_SUCCESS
FETCH_CHECK_IN_FAILURE
RESET_CHECK_IN_KEY
`,
  {
    prefix: "checkin/"
  }
);
