import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UserSettings from "./component";
import { resetAssociates } from "../individuals/actions";
import { updateChannelSuccess, postLoginSuccess, setActiveChannel } from '../../pages/login/actions';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  resetAssociatesConnect: resetAssociates,
  updateChannelSuccessConnect: updateChannelSuccess,
  postLoginSuccessConnect: postLoginSuccess,
  setActiveChannelConnect: setActiveChannel,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);
