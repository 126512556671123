/* eslint-disable indent */
import React from "react";
import Timer from "react-compound-timer";

const DayTimer = () => {
  return (
    <Timer.Days />

  );
};

export default DayTimer;
