import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  isLoading: true,
  leadDetail: null,
  saleonlineQuestions: [],
  userResponse: {},
  offeredPrice: 0,
  error: null,
};

export const fetchLeadDetailSuccess = (state = INITIAL_STATE, { data }) => {
  return { ...state, isLoading: false, leadDetail: data, error: null };
};

export const fetchLeadDetailFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, isLoading: false, leadDetail: null, error };
};

export const resetLeadDetail = () => {
  return INITIAL_STATE;
};

export const resetSaleonlineQuestions = (state = INITIAL_STATE) => {
  return { ...state, saleonlineQuestions: [] };
};

export const fetchSaleonlineQuestionsSuccess = (state = INITIAL_STATE, { data }) => {
  return { ...state, isLoading: false, saleonlineQuestions: data, error: null };
};

export const fetchSaleonlineQuestionsFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, isLoading: false, saleonlineQuestions: [], error };
};

export const updateAnsweredQuestions = (state = INITIAL_STATE, {key, value}) => {
  if (key && value) {
    return {
      ...state,
      userResponse: {
        ...state.userResponse,
        [key] : value
      }
    };
  }
};

export const updateOfferPrice = (state = INITIAL_STATE, { price }) => {
  return { ...state, offeredPrice: price };
};

export const LoadingLeadDetail = (state = INITIAL_STATE, {flag}) => {
  return { ...state, isLoading: flag };
};

export const HANDLERS = {
  [Types.FETCH_LEAD_DETAIL_SUCCESS]: fetchLeadDetailSuccess,
  [Types.FETCH_LEAD_DETAIL_FAILURE]: fetchLeadDetailFailure,
  [Types.FETCH_SALEONLINE_QUESTIONS_SUCCESS]: fetchSaleonlineQuestionsSuccess,
  [Types.FETCH_SALEONLINE_QUESTIONS_FAILURE]: fetchSaleonlineQuestionsFailure,
  [Types.REST_LEAD_DETAIL]: resetLeadDetail,
  [Types.UPDATE_USER_ANSWERED_QUESTION]: updateAnsweredQuestions,
  [Types.REST_SALEONLINE_QUESTIONS]: resetSaleonlineQuestions,
  [Types.LOADING_LEAD_DETAIL]: LoadingLeadDetail,
  [Types.UPDATE_OFFER_PRICE]: updateOfferPrice,
};

export default createReducer(INITIAL_STATE, HANDLERS);
