import { createTypes } from "reduxsauce";

export default createTypes(
  `
    FETCH_ASSOCIATES
    FETCH_ASSOCIATES_SUCCESS
    FETCH_ASSOCIATES_FAILURE
    RESET_ASSOCIATES
`,
  {
    prefix: "individuals/"
  }
);
