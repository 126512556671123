import React, { useState } from "react";
import styles from "./car-specification.module.css";
import GreenStar from "./images/green-star.svg";
import GreenHalfStar from "./images/green-half-star.svg";
import RedStar from "./images/red-star.svg";
import RedHalfStar from "./images/red-half-star.svg";
import OrangeStar from "./images/orange-star.svg";
import OrangeHalfStar from "./images/orange-half-star.svg";
import TransparentStar from "./images/transparent-star.svg";
import DownArrow from "./images/down-arrow.svg";
import Tick from "./images/correct.svg";
import Exclaimation from "./images/exclaimation.svg";
import specificationsParser from "../../utils/helpers/specifications-parser";
import priceFormatter from "../../utils/helpers/price-formatter";

const starColors = {
  1: RedStar,
  1.5: RedHalfStar,
  2: RedStar,
  2.5: OrangeHalfStar,
  3: OrangeStar,
  3.5: OrangeHalfStar,
  4: GreenStar,
  4.5: GreenHalfStar,
  5: GreenStar,
  transparent: TransparentStar
};

const TABS = {
  rfc: "RFC DETAILS",
  carDetails: "CAR DETAILS"
};

const starsMap = (rating, colors) => {
  const ratingMod = rating % parseInt(rating);
  return [0, 0, 0, 0, 0].map((i, index) => {
    const absoluteRating = rating - ratingMod;
    if (ratingMod !== 0 && index >= absoluteRating && index < absoluteRating + 1) {
      return colors[rating];
    }
    if (index >= absoluteRating) {
      return colors["transparent"];
    }
    return colors[absoluteRating];
  });
};

const carDetailsFilter = (data) => {
  const carDetailsKeys = [
    "Registration Date",
    "Insurance Type",
    "Chassis Number",
    "Fitness Upto",
    "RC Availability",
    "RC Condition",
    "Chassis Number on RC",
    "RTO",
    "Partipeshi Request",
    "RTO NOC Issued",
    "Odometer"
  ];

  const carDetails = [];
  carDetailsKeys.map((key) => {
    data.map(row => {
      if (row.title === key) {
        carDetails.push(row);
      }
    });
  });

  return carDetails;
};

const CarSpecifications = ({ currentCarDetails }) => {
  const { totalRfc } = currentCarDetails;
  const [currentTab, setCurrentTab] = useState(TABS.rfc);
  const data = specificationsParser(currentCarDetails);
  const content = [
    { type: "highlights", heading: "Highlights", ratings: "5", description: "Highlights", data: { ...data.highlights } },
    { type: "documentDetail", heading: "Document Details", ratings: "5", description: "Highlights", data: { ...data.documentDetail } },
    { type: "exteriorTyres", heading: "Exterior + Tyres", ratings: "5", description: "Highlights", data: { ...data.exteriorTyres } },
    { type: "engineTransmission", heading: "Engine + Transmission", ratings: "5", description: "Highlights", data: { ...data.engineTransmission } },
    { type: "airConditioning", heading: "Air Conditioning", ratings: "5", description: "Highlights", data: { ...data.airConditioning } },
    { type: "electricalsInterior", heading: "Electricals + Interiors", ratings: "5", description: "Highlights", data: { ...data.electricalsInterior } },
    { type: "steeringSuspensionBrakes", heading: "Steering + Suspension + Brakes", ratings: "5", description: "Highlights", data: { ...data.steeringSuspensionBrakes } },
  ];
  const { data: { content: documentData } } = content.find((section) => section.type === 'documentDetail');
  const carDetails = carDetailsFilter(documentData);
  const noRatings = ["documentDetail", "highlights"];
  const noWarnings = ["documentDetail", "highlights"];
  const withDescription = ["documentDetail"];

  const [openTabIndex, setOpenTabIndex] = useState(null);
  const onClickAccordian = (tabIndex) => {
    setOpenTabIndex(openTabIndex === tabIndex ? null : tabIndex);
  };
  const toggleTab = (tab) => {
    setCurrentTab(tab);
  };

  return (
    <div className={styles.carSpecificationWrap}>
      <div className={styles.specificationHeader}>
        {
          Object.keys(TABS).map((tab, index) => {
            return (<p key={`tab_${index}`} onClick={() => { toggleTab(TABS[tab]); }} className={`${styles.padding} ${currentTab === TABS[tab] ? styles.headerActive : ""}`}>{TABS[tab]}</p>);
          })
        }
      </div>
      {
        currentTab === TABS.rfc && <div className={styles.carDetailWrap}>
          <p className={styles.rfc}>
            <span>RFC : </span><span className={styles.rfcPrice}>{priceFormatter(totalRfc)}</span>
          </p>
          <React.Fragment>
            {content.map(({ heading, data, type }, tabIndex) => (

              <div key={tabIndex} className={styles.ratingOuter}>
                <div onClick={() => onClickAccordian(tabIndex)} className={styles.detailsHeading}>
                  <p>{heading}</p>
                  <ul>
                    {(!noRatings.includes(type) && !isNaN(data.rating)) &&
                    <React.Fragment>
                      {starsMap(data.rating, starColors)
                        .map((color, index) => <li key={index}><img alt="" src={color} /></li>)}
                    </React.Fragment>
                    }
                    <li className={openTabIndex !== tabIndex ? styles.arrowUp : styles.arrowDown}>
                      <img alt="" src={DownArrow} />
                    </li>
                  </ul>
                </div>
                {openTabIndex === tabIndex && <p className={styles.carDescription}>
                  <ul className={type === 'documentDetail' ? styles.dataList : ''}>
                    {data.content.map((detail, index) => (
                      <li key={index}>
                        {!noWarnings.includes(type) &&
                        <span><img src={detail.value === "Yes" ? Tick : Exclaimation}
                          alt="Tick" /></span>
                        }
                        <p className={styles.detailTopic}>{detail.title}</p>
                        {withDescription.includes(type) &&
                        <p className={styles.detailResult}>{detail.value}</p>
                        }
                        {detail.subParts && detail.subParts.length > 0 && <li>
                          <ul>
                            {detail.subParts
                              .map(subPart => <li>
                                <span><img
                                  src={subPart.value === "Yes" ? Tick : Exclaimation}
                                  alt="Tick" /></span>
                                <p className={styles.detailTopic}> {[subPart.title, (subPart.additionalInfo && subPart.additionalInfo[0] ? `${subPart.additionalInfo[0].treadDepth.title} - (${subPart.additionalInfo[0].treadDepth.value})` : null)].filter(x => !!x).join(" - ")}</p>
                              </li>)}
                          </ul>
                        </li>}
                      </li>
                    ))}
                  </ul>

                  {data.groupedContent && <ul>
                    {data.groupedContent.map((detail, index) => (
                      <li key={index}>
                        <span><img src={!detail.hasWarnings ? Tick : Exclaimation} alt="Tick" /></span>
                        <p className={styles.detailTopic}>{detail.title}</p>
                        {detail.content && <ul>
                          {detail.content.map((gcDetail, index) => (
                            <li key={index}>
                              <span><img src={gcDetail.value ? Tick : Exclaimation}
                                alt="Tick" /></span>
                              <p className={styles.detailTopic}>{gcDetail.title}</p>
                            </li>
                          ))}
                        </ul>}
                      </li>
                    ))}
                  </ul>}

                  {data.comments && <ul>
                    {data.comments.map((detail, index) => (
                      <li key={index}>
                        <span><img src={Exclaimation} alt="Tick" /></span>
                        <p className={styles.detailTopic}>{detail.title}</p>
                      </li>
                    ))}
                  </ul>}
                </p>}
              </div>
            ))}
          </React.Fragment>
        </div>
      }
      {currentTab === TABS.carDetails && <div className={styles.carDetailWrap}>
        <div className={styles.carDetails}>
          <ul>
            {
              carDetails.map((section, index) => {
                return <li key={`detail_${index}`}><span>{section.title}</span>{section.value}</li>;
              })
            }
          </ul>

        </div>
      </div>}
    </div>
  );
};

export default CarSpecifications;