import { createTypes } from "reduxsauce";

export default createTypes(
  `
    SET_LEAD_SORT_DIRECTION
    SET_LEAD_FILTER

`,
  {
    prefix: "lead-sort-filter/"
  }
);
