import {domoService} from "../../service";

const getDomoToken = (params) => () => {
  return new Promise((resolve, reject) => {
    domoService.getDomoToken(params).then((response) => {
      resolve(response.data.detail);
    }).catch((error) => {
      reject(error);
    });
  });
};

export {
  getDomoToken
};
