import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AppointmentPage from "./component";
import { fetchAppointments, searchAndFilterAppointments, removeSearch, resetAppointments, resetAppointmentByKey, sortAppointmentByKey, fetchAppointmentReviewInspections } from "./actions";
import withLayout from "../../components/layout/component";
import { resetLeadMenuByKey } from "../../components/lead-menu/actions";
import { resetSearchByKey } from "../../components/search/actions";
import { resetAppointmentCountByKey } from "../../components/sidebar/actions";
import { resetGlobalSearch } from "../../components/global-search/actions";
import {resetApiNoAccess} from "../../components/hoc-access-controller/actions";
import {fetchCjAllocationStatus} from "../../components/cj-allocation-status/actions";
import { updateFilterQuery } from "../../components/homeInspection-filters/actions";
import { updateHeaderRowFilterQuery } from "../../components/shared/table-header-with-control/actions";
const mapStateToProps = ({
  appointments,
  sidebar,
  leadMenu,
  search,
  visitedFilters,
  globalSearch,
  homeInspectionFilters,
  zoneFilters,
  headerRowFilter,
  homeInspectionConfirmLead,
  reviewInspection,
  paymentProcessingFilter,
  cjAllocationStatus,
}) => ({
  appointments,
  sidebar,
  leadMenu,
  search,
  visitedFilters,
  globalSearch,
  homeInspectionFilters,
  zoneFilters,
  headerRowFilter,
  homeInspectionConfirmLead,
  reviewInspection,
  paymentProcessingFilter,
  cjAllocationStatus
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchAppointmentsConnect: fetchAppointments,
  // setAppointmentStateConnect: setAppointmentState,
  searchAppointmentsConnect: searchAndFilterAppointments,
  removeSearchconnect: removeSearch,
  resetAppointmentsConnect: resetAppointments,
  resetAppointmentByKeyConnect: resetAppointmentByKey,
  resetLeadMenuByKeyConnect: resetLeadMenuByKey,
  resetSearchByKeyConnect: resetSearchByKey,
  resetAppointmentCountByKeyConnect: resetAppointmentCountByKey,
  resetGlobalSearchConnect: resetGlobalSearch,
  sortAppointmentByKeyConnect: sortAppointmentByKey,
  fetchAppointmentReviewInspectionsConnect: fetchAppointmentReviewInspections,
  resetApiNoAccessConnect: resetApiNoAccess,
  fetchCjAllocationStatusConnect: fetchCjAllocationStatus,
  updateFilterQueryConnect: updateFilterQuery,
  updateHeaderRowFilterQueryConnect: updateHeaderRowFilterQuery,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withLayout(AppointmentPage));
