import React, { useState } from "react";
// import styles from "./check-in-deny.module.css";
import Button from "../shared/button/component";
import { POPUP_HEADING, ACTIONS_BUTTON } from "../../utils/app-constants";
import Popup from "../popup";
import CheckInDenyPopup from "../check-in-deny-popup";

const CheckInDeny = ({ rowDetails, scrollRef, setHasMoreItems, resetData }) => {

  const { appointment } = rowDetails;
  const [denyOpen, setDenyOpen] = useState(false);

  const onDenyOpen = () => {
    setDenyOpen(true);
  };

  const onDenyClose = () => {
    setDenyOpen(false);
    resetData();
  };

  return (
    <>
      <div>
        <Button ctaText={ACTIONS_BUTTON.VISITED_CANCEL} onClick={() => onDenyOpen(appointment)} classNames="smallCta" />
      </div>
      <Popup isOpen={denyOpen} close={onDenyClose} heading={POPUP_HEADING.CANCEL} >
        <CheckInDenyPopup rowDetails={rowDetails} onCancelClick={onDenyClose} scrollRef={scrollRef} setHasMoreItems={setHasMoreItems} />
      </Popup>
    </>
  );
};

export default CheckInDeny;
