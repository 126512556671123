import React from "react";

const ListItem = ({
  children,
  onClick = () => { },
  classes = ""
}) => {
  return (
    <li onClick={onClick} className={classes}>
      {children}
    </li>
  );
};

export default ListItem;
