import React from "react";
import { APPOINTMENT_TYPES_STRING } from "../../utils/app-constants";
import styles from "./global-search-item.module.css";

const GlobalSearchItem = ({ item }) => {
  const { state_name, appointment, customer_name, nego_diy_view=0, nego_diy_pll_view=0, retarget_nego_view=0, 
    retarget=0} = item;
  let app_state_name = state_name;
  if (retarget_nego_view === 1) {
    app_state_name = APPOINTMENT_TYPES_STRING.CENTRAL_NEGOTIATION;
  } else if (retarget === 1) {
    app_state_name = APPOINTMENT_TYPES_STRING.CENTRAL_DIY_NEGOTIATION;
  } else if (nego_diy_view === 1 || nego_diy_pll_view === 1) {
    app_state_name = APPOINTMENT_TYPES_STRING.DIY_NEGOTIATION;
  }
  return (
    <>
      <div className={styles.leadInfo}>
        <p className={styles.searchId}>{appointment}</p>
        <p className={styles.searchName}>{customer_name}</p>
      </div>
      <div className={styles.searchButton}>
        <p>{app_state_name}</p>
      </div>
    </>
  );
};

export default GlobalSearchItem;
