import React, {useState} from "react";
import styles from "./sell-online-our-price.module.scss";
import Button from "../../components/shared/button";
import priceFormatter from "../../utils/helpers/price-formatter";
import moment from "moment";
import Popup from "../popup/component";
import AllocateCjSchedulePopup from "../allocate-cj-schedule-popup/component";
import SoCancelAppointmentPopup from "../so-cancel-appointment-popup/component";
import SoBookingAppointmentPopup from "../so-booking-appointment-popup/component";
import CarDetailsPopup from "../car-details-popup/component";
import { AppointmentService } from "../../service";
import { showToastMessages } from "../../utils/helper";
// import { AppointmentService } from "../../service";
// import { showToastMessages} from "../../utils/helper";
const SellOnlineOurPrice = ({data, updateDataList}) => {
  const {sellerIntent, sellerOfferPrice, appointment, homeInspectionCjEmail, 
    customerAddress, appointmentBlockingTime, sellerPriceAccepted, homeInsDate: {date, time}= {}, 
    ctaAccessFlag, appointmentToken} = data;
  const [allocateCJOpen, setAllocateCJOpen] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [sceduleOpen, setSceduleOpen] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);
  const [priceBreakupData, setPriceBreakupData] = useState();
    
  const onBookingApptOpen = () => {
    setSceduleOpen(true);
  };

  const onBookingApptClose = () => {
    setSceduleOpen(false);
  };

  const onCancelApptOpen = () => {
    setCancelOpen(true);
  };

  const onCancelApptClose = () => {
    setCancelOpen(false);
  };
  const onAllocateCJOpen = () => {
    setAllocateCJOpen(true);
  };

  const onAllocateCJClose = () => {
    setAllocateCJOpen(false);
  };

  const onDetailsOpen = () => {
    AppointmentService.getSOPriceBreakup(appointmentToken).then((resp)=>{
      setPriceBreakupData(resp.detail);
      setDetailsOpen(true);
    }).catch((err)=>{
      console.log({err});
      showToastMessages(err.detail, false);
      setPriceBreakupData();
    });
  };

  const onDetialsClose = () => {
    setDetailsOpen(false);
  };

  return (

    <div className={styles.sellOnlineOurPrice}>
      {!!sellerIntent && sellerIntent ? <>
        {/* <div className={styles.congratulationsFlag}>
                    <h6>Congratulations</h6>
                    <h4>You’ve got the best price</h4>
                </div> */}
        <div className={styles.ourPriceSection}>
          <div>
            <h4>Our Price</h4>
            <p className={styles.price}>{priceFormatter(sellerOfferPrice, true)}</p>
          </div>
          {sellerPriceAccepted && <div className={styles.priceAcceptedFlagGreen}>
            <h6>Customer Accepted Price</h6>
          </div>}
        </div>
        {<div className={styles.ctaWrapper}>
          {!ctaAccessFlag && <Button ctaText={!!homeInspectionCjEmail ? "RE-ASSIGN CJ": "ASSIGN  CJ"} classNames="saveCta" onClick={onAllocateCJOpen} disabled={!customerAddress}/>}
          <Button classNames="secondaryCta" ctaText={"Price Explanation"}  onClick={onDetailsOpen}  />
          <Popup isOpen={detailsOpen} close={onDetialsClose}>
            <CarDetailsPopup close={onDetialsClose} priceBreakupData={priceBreakupData} data={data}/>
          </Popup>
          {!!homeInspectionCjEmail &&<div className={styles.allocateTimeslot}> 
            <span className={styles.cjEmail}>{homeInspectionCjEmail}</span>
            <span className={styles.cjDate}>{!!date ? date : ""}</span>
            <span className={styles.cjTime}>{!!time ? time : ""}</span>
          </div>}
                    
          <Popup isOpen={allocateCJOpen} close={onAllocateCJClose}>
            <AllocateCjSchedulePopup onClose={onAllocateCJClose} rowDetails={data} appointmentId={appointment} updateDataList={updateDataList} IsCJAllocate={true} isSaleOnline={true}/>
          </Popup>
        </div>}
      </> : <>
        <div className={styles.staticText}>
          <h3>Price sent to the customer</h3>
          <p>You will be notified if customer accepts the price</p>
          <h4>Appointment details will be available at {moment(appointmentBlockingTime).format('h:mm a')} for calling </h4>
          {/* <Button ctaText={"SEND OFFER LINK"} classNames="saveCta" onClick={onSendingOfferLink} disabled={sendOfferLinkCount === 2 ? true : false}/> */}
        </div>
      </>}
      {!!data?.soRaAssistFlag && (data?.appointmentStatus === "verified" ? 
        <div className={styles.reCancelCtaWrapper}>
          <Button ctaText={"Re-Schedule RI"} classNames="secondaryCta" onClick={onBookingApptOpen}/> 
          <div className={styles.reCancelCta}>
            <Button ctaText={"Cancel Appointment"} classNames="secondaryCta" onClick={onCancelApptOpen}/>
          </div>

          <Popup isOpen={sceduleOpen} close={onBookingApptClose}>
            <SoBookingAppointmentPopup title={"Reschedule Appointment"} ctaText={"RESCHEDULE"} onClose={onBookingApptClose} rowDetails={data} appointmentId={appointment} updateDataList={updateDataList}/>
          </Popup>
                    
          <Popup isOpen={cancelOpen} close={onCancelApptClose}>
            <SoCancelAppointmentPopup onClose={onCancelApptClose} rowDetails={data} updateDataList={updateDataList}/>
          </Popup>
        </div>
                : 
        <>
          <Button ctaText={"Booking Appointment"} classNames="secondaryCta" onClick={onBookingApptOpen}/> 
          <Popup isOpen={sceduleOpen} close={onBookingApptClose}>
            <SoBookingAppointmentPopup title={"Booking Appointment"} ctaText={"SCHEDULE"} onClose={onBookingApptClose} rowDetails={data} appointmentId={appointment} updateDataList={updateDataList}/>
          </Popup>
        </>
      )}
    </div>
  );
};

export default SellOnlineOurPrice;
