export const PROBABLITY_TYPES = {
  HIGH: { key: "high", label: "High Acceptance Probability" },
  MEDIUM: { key: "medium", label: "Medium Acceptance Probability" },
  LOW: { key: "low", label: "Low Acceptance Probability" },
  VERY_LOW: { key: "verylow", label: "Very Low Acceptance Probability" }
};

export default (ranges, diff, minPrice) => {
  const filteredPriceRange = ranges.filter(({ key }) => {
    const price = parseInt(key) + parseInt(minPrice);
    return price >= diff;
  });
  const band = filteredPriceRange.length > 0 ? filteredPriceRange[0].band : "";
  if (band === "H") {
    return PROBABLITY_TYPES.HIGH.key;
  } else if (band === "M") {
    return PROBABLITY_TYPES.MEDIUM.key;
  } else if (band === "L") {
    return PROBABLITY_TYPES.LOW.key;
  } else {
    return PROBABLITY_TYPES.VERY_LOW.key;
  }
};