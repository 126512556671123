import React from "react";
import Timer from "react-compound-timer";

const SecondsTimer = () => {
  return (
    <Timer.Seconds />
  );
};

export default SecondsTimer;
