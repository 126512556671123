import { createTypes } from "reduxsauce";

export default createTypes(
  `
    UPDATE_FILTER_QUERY
`,
  {
    prefix: "pllnegotiationfilter/"
  }
);
