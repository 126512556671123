import React from "react";
import CallConnected from "../../components/shared/images/call_connected.svg";
import NotConnected from "../../components/shared/images/not_connected.svg";
import CallCancelelled from "../../components/shared/images/cancelelled.svg";
import styles from "./call-log-image.module.scss";
const CallLogImage = ({ callType }) => {

  const getImage = () => {
    if (callType && callType.trim()) {
      switch (callType.trim().toLowerCase()) {
        case "completed":
          return CallConnected;
        case "client-hangup":
          return CallCancelelled;
        case "incompleted":
        case "incomplete":
          return NotConnected;
        default:
          return "";
      }
    } else {
      return NotConnected;
    }
  };

  return (
    <div className={styles.callLogimage}>
      <img src={getImage()} alt="Call Log" />
    </div>
  );
};

export default CallLogImage;
