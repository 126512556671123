// import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Incentive from "./component";
import withLayout from "../../components/layout/component";
import {fetchRAIncentive} from "./actions";

const mapStateToProps = ({raIncentive}) => ({
  raIncentive
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  raIncentiveConnect: fetchRAIncentive
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withLayout(Incentive, false));