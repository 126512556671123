import Types from "./types";
import { AppointmentService } from "../../service";
import { getVahanBackgroundCheck } from "../../utils/mocApi";

const fetchVahanDetails = (appointmentId) => dispatch => {
  // const response = getVahanBackgroundCheck();
  // dispatch(fetchVahanDetailsSuccess(response));
  return new Promise((resolve, reject) => {
    return AppointmentService.getPrVahanCheck(appointmentId)
      .then(({ data }) => {
        const { detail } = data;
        dispatch(fetchVahanDetailsSuccess(detail));
        resolve(detail);
      })
      .catch(error => {
        // dispatch(fetchCarDataFailure(error));
        reject(error);
      });
  });
};

const fetchVahanDetailsSuccess = (data) => ({
  type: Types.FETCH_VAHAN_DETAILS,
  data
});

export {
  fetchVahanDetails
};
