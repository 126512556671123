import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  isLoading: true,
  error: null,
  leftMenu: [],
  header: [],
  leadCount: {
    sellOnlineCount: 0,
    scheduledCount: 0,
    visitedCount: 0,
    inspectedCount: 0,
    inNegotiationCount: 0,
    clearancePendingCount: 0,
    paymentProcessingCount: 0
  },
  priorityCount: {
    "scheduled": 0,
    "inspected": 0,
    "visited": 0,
    "negotiation": 0,
    "payment-processing": 0,
    "clearance-pending": 0,
    "sell-online": 0
  },
  pllFilter: {
    "allLeads": 0,
    "paiStatus": 0,
    "notConnecteed": 0,
    "starred": 0,
    "label": "",
    "filterQuery": null,
    "count": 0
  },
  retailFilter: {
    "allLeads": 0,
    "paiStatus": 0,
    "notConnecteed": 0,
    "starred": 0
  }
};

export const fetchCounts = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};

export const fetchAppointmentCountsSuccess = (state = INITIAL_STATE, { data }) => {
  const leadCount = {};
  const priorityCount = {};

  state.leftMenu.forEach((sidebar) => {
    const leads = data.find(item => item.stateId === Number(sidebar.stateId));
    leadCount[sidebar.leadCount] = leads.count;
    priorityCount[sidebar.priorityCount] = leads.priorityCount;
  });
  leadCount.scheduledCount = 0;
  leadCount.visitedCount = 0;
  leadCount.inspectedCount = 0;
  leadCount.sellOnlineCount = 0;
  return { ...state, isLoading: false, leadCount, priorityCount, error: null };
};

export const fetchAppointmentCountsFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, isLoading: false, error };
};

export const resetAppointmentCountByKey = (state = INITIAL_STATE, { data }) => {
  const { key, value } = data;
  return { ...state, [key]: value };
};

export const updatePriorityCountByKey = (state = INITIAL_STATE, { data }) => {
  const { key } = data;
  let { priorityCount } = state;
  priorityCount[key] = priorityCount[key] + 1;
  return { ...state, priorityCount };
};

export const updatePllFilterByKey = (state = INITIAL_STATE, { data }) => {
  const { key, value } = data;
  let { pllFilter } = state;
  pllFilter[key] = value;
  return { ...state, pllFilter };
};

export const updateRetailFilterByKey = (state = INITIAL_STATE, { data }) => {
  const { key, value } = data;
  let { retailFilter } = state;
  retailFilter[key] = value;
  return { ...state, retailFilter };
};

export const resetSidebar = () => {
  return INITIAL_STATE;
};

export const fetchMenus = (state = INITIAL_STATE) => {
  return { ...state, 
    leftMenu: [],
    header: [],
    isLoading: true 
  };
};
export const fetchMenusSuccess = (state = INITIAL_STATE, { data }) => {
  const {leftMenu = [], header= []} = data;
  return { ...state, isLoading: false, leftMenu, header, error: null };
};

export const fetchMenusFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, isLoading: false, error };
};

export const HANDLERS = {
  [Types.FETCH_APPOINTMENT_COUNTS]: fetchCounts,
  [Types.FETCH_APPOINTMENT_COUNTS_SUCCESS]: fetchAppointmentCountsSuccess,
  [Types.FETCH_APPOINTMENT_COUNTS_FAILURE]: fetchAppointmentCountsFailure,
  [Types.RESET_APPOINTMENT_COUNTS_KEY]: resetAppointmentCountByKey,
  [Types.UPDATE_PRIORITY_COUNT_BY_KEY]: updatePriorityCountByKey,
  [Types.UPDATE_PLL_FILTER_BY_KEY]: updatePllFilterByKey,
  [Types.RESET_SIDEBAR]: resetSidebar,
  [Types.UPDATE_RETAIL_FILTER_BY_KEY]: updateRetailFilterByKey,
  [Types.FETCH_USER_MENU]: fetchMenus,
  [Types.FETCH_USER_MENU_SUCCESS]: fetchMenusSuccess,
  [Types.FETCH_USER_MENU_FAILURE]: fetchMenusFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
