import React, { useState, useEffect } from "react";
import styles from "./visited-filters.module.css";
import { VISITED_FILTERS } from "../../utils/app-constants";
import { getUpdatedFilterCounts } from "../../utils/helper";

const VisitedFilters = ({ appointments, visitedFilters, updateFilterQueryConnect }) => {

  const { visitedFiltersCount } = appointments;
  const { filterQuery } = visitedFilters;
  const [selectedFilter, setSelectedFilter] = useState(VISITED_FILTERS.TOTAL.key);
  const [filtersCount, setFiltersCount] = useState(Object.assign({}, visitedFiltersCount));

  useEffect(() => {
    return () => {
      updateFilterQueryConnect(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const slctdFilter = Object.values(VISITED_FILTERS).find(item => item.key === selectedFilter).key;
    const updateObject = slctdFilter === VISITED_FILTERS.TOTAL.key ? true : false;
    const newFiltersCount = getUpdatedFilterCounts(filtersCount, visitedFiltersCount, slctdFilter, updateObject);
    if (slctdFilter !== VISITED_FILTERS.TOTAL.key) {
      updateTotalCount(newFiltersCount);
    }
    setFiltersCount(newFiltersCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitedFiltersCount]);

  const updateTotalCount = (newFiltersCount) => {
    let count = 0;
    Object.values(VISITED_FILTERS).forEach(item => {
      if (item.key !== VISITED_FILTERS.TOTAL.key) {
        count += newFiltersCount[item.key];
      }
    });
    newFiltersCount[VISITED_FILTERS.TOTAL.key] = count;
  };

  const onFilterSelect = (selectedKey, selectedValue) => {
    const query = `&ins_status=${selectedValue}`;
    if (query !== filterQuery) {
      setSelectedFilter(selectedKey);
      updateFilterQueryConnect(query);
    }
  };

  return (
    <ul>
      {
        Object.values(VISITED_FILTERS).map(item => {
          return (
            <li key={item.key} onClick={() => { onFilterSelect(item.key, item.value); }} className={selectedFilter === item.key ? styles.selected : ''}>{filtersCount[item.key]} {item.label}</li>
          );
        })
      }
    </ul>
  );
};

export default VisitedFilters;
