import React from "react";
import styles from "./filters.module.css";
import VisitedFilters from "../visited-filters";
import NegotiationFilter from "../negotiation-filter";
import PllNegotiationFilter from "../pllNegotiation-filter";
import { APPOINTMENT_TYPES_STRING } from "../../utils/app-constants";
import { isPllUser } from "../../utils/helper";
import HomeInspectionFilters from "../homeInspection-filters";
import SellOnlineFilter from "../sell-online-filter";
import MissedCallNegotiationFilter from "../missed-call-negotiation-filter/component";
const Filters = ({ appointments, appointmentType }) => {
  const isPll = isPllUser();
  const { appointmentState } = appointments;
  const renderFilterByState = () => {
    switch (appointmentState) {
      case APPOINTMENT_TYPES_STRING.SCHEDULED:
        return (
          <div className={styles.visitedWrap}>
            <div className={styles.filters}>
              <HomeInspectionFilters />
            </div>
          </div>
        );
      case APPOINTMENT_TYPES_STRING.VISITED:
        return (
          <div className={styles.visitedWrap}>
            <div className={styles.filters}>
              <VisitedFilters />
            </div>
          </div>
        );
      case APPOINTMENT_TYPES_STRING.SELL_ONLINE:
        return (
          <div className={styles.sellOnlineFilter}>
            {!isPll && <SellOnlineFilter />}
          </div>
        );
      default:
        if ([APPOINTMENT_TYPES_STRING.NEGOTIATION, APPOINTMENT_TYPES_STRING.DIY_NEGOTIATION, APPOINTMENT_TYPES_STRING.CENTRAL_NEGOTIATION, APPOINTMENT_TYPES_STRING.CENTRAL_DIY_NEGOTIATION].includes(appointmentType)){
          return (
            <>
              {APPOINTMENT_TYPES_STRING.CENTRAL_NEGOTIATION !== appointmentType && <div className={styles.missedCallFilter}>
                <MissedCallNegotiationFilter />
              </div>}
              <div className={`${styles.filters}`}>
                {!!isPll ? <PllNegotiationFilter appointmentType={appointmentType}/> : <NegotiationFilter />}
              </div>
            </>
          );
        } else {
          return (<></>);
        }
    }
  };

  return (
    renderFilterByState()
  );
};

export default Filters;
