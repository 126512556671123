import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FollowUpPopup from "./component";
import { fetchFollowUpList, postFollowUp, resetState, resetFollowUp } from "./actions";
import { resetAppointmentByKey } from "../../pages/appointment/actions";
import { resetLostLeadsByKey, resetLostLeads } from "../../pages/lost-leads/actions";

const mapStateToProps = ({
  followUp,
  appointments,
}) => ({
  followUp,
  appointments
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchFollowUpListConnect: fetchFollowUpList,
  postFollowUpConnect: postFollowUp,
  resetAppointmentByKeyConnect: resetAppointmentByKey,
  resetStateConnect: resetState,
  resetFollowUpConnect: resetFollowUp,
  resetLostLeadsByKeyConnect: resetLostLeadsByKey,
  resetLostLeadsConnect: resetLostLeads
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpPopup);
