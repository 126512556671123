import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./lead-check-in-menu.module.scss";

const LeadCheckInMenu = ({ location }) => {

  const { pathname } = location;
  const [isActiveMenu, setIsActiveMenu] = useState(false);

  useEffect(() => {
    checkActiveMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const checkActiveMenu = () => {
        pathname.replace('/', '') === 'check-in' ? setIsActiveMenu(true) : setIsActiveMenu(false);
  };

  return (
    <div className={styles.leadCheckinMenu}>
      <Link className={`${isActiveMenu ? styles.titleActive : ''}`} to="/check-in">Check-In</Link>
    </div>
  );
};

export default LeadCheckInMenu;
