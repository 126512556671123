import React from "react";
import "./button.css";
import PropTypes from "prop-types";
import ButtonLoader from "../../button-loader";

const Button = (props) => {
  const {
    ctaText,
    onClick = () => {},
    classNames = "",
    disabled = false,
    actionCtaText = "",
    isLoading
  } = props;
  return (
    <button onClick={onClick} className={classNames} disabled={disabled}>
      {actionCtaText ? actionCtaText : ctaText}
      {isLoading && <ButtonLoader />}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string
};
export default Button;
