import React from "react";
import { Switch, Route } from "react-router-dom";
import { openRoutes, secureRoutes } from "./constants";
import { isAuthorizedAccess } from "../../utils/helper";
import UnauthorizedAccess from "../unauthorized-access";
import LeadProcurement from "../../b2b/pages/lead-procurement";
import B2BLeadDetail from "../../b2b/pages/lead-detail";
import B2BLogin from "../../b2b/pages/login";
import DealerOfferPrice from "../../b2b/pages/dealer-offer-price";
import { useSession } from "@descope/react-sdk";
const RenderRoutes = ({ isB2B = false }) => {
	const { isAuthenticated } = useSession();
	return (
		<Switch>
			{isB2B ? (
				<>
					{secureB2BRoutes.map((route) => (
						<Route key={route.component} {...route} />
					))}
					{openB2BRoutes.map((oRoute) => (
						<Route key={oRoute.component} {...oRoute} />
					))}
				</>
			) : (
				<>
					{isAuthenticated && !isAuthorizedAccess() && (
						<Route component={UnauthorizedAccess} />
					)}
					{openRoutes.map((route) => {
						return <Route key={route.key} {...route} />;
					})}
					{secureRoutes.map((route) => {
						return <Route key={route.key} {...route} />;
					})}
				</>
			)}
		</Switch>
	);
};

export default RenderRoutes;

const secureB2BRoutes = [
	{
		path: "/b2b",
		exact: true,
		component: LeadProcurement,
	},
	{
		path: "/b2b/lead/detail/:leadToken",
		exact: true,
		component: B2BLeadDetail,
	},
];
const openB2BRoutes = [
	{
		path: "/b2b/dealer/offer-price/:leadToken",
		exact: true,
		component: DealerOfferPrice,
	},
	{
		path: "/b2b/login",
		exact: true,
		component: B2BLogin,
	},
];

