import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  filterQuery: null,
  withCustomer: false,
  withCustomerCount: 0,
  actionRequiredCount: 0,
  missedCallQuery: null
};

export const updateFilterQuery = (state = INITIAL_STATE, { filterQuery }) => {
  return { ...state, filterQuery };
};

export const updateMissedCallFilterQuery = (state = INITIAL_STATE, { missedCallQuery }) => {
  return { ...state, missedCallQuery };
};

export const updateWithCustomer = (state = INITIAL_STATE, { withCustomer }) => {
  return { ...state, withCustomer };
};

export const updateWithCustomerCount = (state = INITIAL_STATE, { withCustomerCount }) => {
  return { ...state, withCustomerCount };
};

export const updateActionRequiredCount = (state = INITIAL_STATE, { actionRequiredCount }) => {
  return { ...state, actionRequiredCount };
};

export const HANDLERS = {
  [Types.UPDATE_FILTER_QUERY]: updateFilterQuery,
  [Types.NEGO_DATA_MISSED_CALL_QUERY]: updateMissedCallFilterQuery,
  [Types.NEGO_DATA_ACTION_REQUIRED_COUNT]: updateActionRequiredCount,
  [Types.NEGO_DATA_WITH_CUSTOMER_COUNT]: updateWithCustomerCount,
  [Types.NEGO_DATA_WITH_CUSTOMER] : updateWithCustomer
};

export default createReducer(INITIAL_STATE, HANDLERS);
