import React, { useEffect, useState } from "react";
import styles from "./login.module.scss";
import Sohomebanner from "./images/sohomebanner.jpg";
import CarImg from "./images/carImg.svg";
import DescopeSignInWidget from "./components/DescopeSignInWidget";
import { saveToLocalStorage } from "../../../utils/helper";
import { LS_KEYS, USER_ROLES, APP_CONSTANTS } from "../../common/app-constants";
import { useSession, useUser, useDescope, getSessionToken } from "@descope/react-sdk";
import { useHistory } from "react-router-dom";
import { onBackHome, onBackLogin, getCurrentB2BRoles, getLoginErrorMessage } from "../../common/utility";

const Login = (props) => {
  const queryParams = new URLSearchParams(window.location.search);
  const errorKey = queryParams.get('key');
  const [loginErrorMessage, setLoginErrorMessage] = useState();
  const history = useHistory();
  const { isSessionLoading } = useSession();
  const { isUserLoading} = useUser();
  const { logout } = useDescope();
  const navigateByRole = (props) => {
    const {email, name, roles, organization} = props;
    let currentRole = '';
    if (roles.includes(USER_ROLES.RA) && roles.includes(USER_ROLES.CJ)) {
      currentRole = USER_ROLES.ADMIN;
    } else if (roles.includes(USER_ROLES.RA)) {
      currentRole = USER_ROLES.RA;
    } else if (roles.includes(USER_ROLES.CJ)) {
      currentRole = USER_ROLES.CJ;
    } 
    if (!currentRole) {
      logout();
      localStorage.clear();
      onBackLogin(APP_CONSTANTS.NO_ROLES_KEY);
    } else {
      saveToLocalStorage(LS_KEYS.DISPLAY_ROLE, currentRole);
	  saveToLocalStorage(LS_KEYS.DISPLAY_EMAIL, email);
      saveToLocalStorage(LS_KEYS.DISPLAY_NAME, name);
      saveToLocalStorage(LS_KEYS.ORGANIZATION_NAME, organization);
      onBackHome();
    }
  };

  const onSuccess = (e) => {
    if (!!e?.detail?.user) {
      const { email, name } = e?.detail?.user;
      const roleNames = getCurrentB2BRoles();
      navigateByRole({email, name, roles: roleNames, organization: "CSPL"});
    }
  };

  const onError = () => {
    setLoginErrorMessage("Error Login In");
  };

  useEffect(()=> {
    const message = getLoginErrorMessage(errorKey);
    setLoginErrorMessage(message);
  }, [errorKey]);

  if (isSessionLoading || isUserLoading) {
    return <p>Loading...</p>;
  }
  return (
    <div className={styles.login}>
      <div className={styles.loginOuter}>
        <div className={styles.soBannerWrapper}>
          <picture width="360" height="250">
            <img
              width="360"
              height="250"
              className={styles.banners}
              src={Sohomebanner}
              alt="so"
            />
          </picture>
          <div className={styles.bannerContent}>
            <div className={styles.content}>
              <h1>
                A FINAL OFFER
                <br />
                in 5 minutes
              </h1>
              <p className={styles.puchline}>
                You will get full amount
                <br />
                before pick-up
              </p>
            </div>
          </div>
        </div>
        {/* <h3>Login with your CARS24 gmail id</h3> */}
        {/* <B2BOktaSignInWidget onSuccess={onSuccess} onError={onError} /> */}
		<div>
        <DescopeSignInWidget onSuccess={onSuccess} onError={onError} />
		</div>
        <div className={styles.hassleFree}>
          <h4>HASSLE FREE SELLING</h4>
          <img src={CarImg} alt="car" />
        </div>
      </div>
      <div className={styles.errorContainer}>
        <small key="loginErr" className="form-text text-danger">
          {loginErrorMessage}
        </small>
      </div>
    </div>
  );
};

export default Login;
