import AutoComplete from ".";
import { MAP_TYPE, NUMBERS } from "../../utils/app-constants";

export const googlePlacesOptions = async (input, type) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (input.length > NUMBERS.TWO) {
        const response = await AutoComplete.getInstance(type).getPlaces({ search: input });
        if (type === MAP_TYPE.GOOGLE) {
          const promises = response.map((item) => {
            return new Promise(async (resolve, reject) => {
              await getLatLng(item.place_id, type).then((resp)=>{
                resolve({...resp, label: item.description, value: item.place_id});
              }).catch((err)=>{
                console.log("err", err);
                resolve({label: item.description, value: item.place_id});
              });
            });
          });
          Promise.all(promises).then((values) => {
            resolve(values);
          });
        } else if (type === MAP_TYPE.MMI) {
          const mmiResult = response.map((item) =>  {
            if (item.type === "CITY") {
              return { label: `${item.placeName}, ${item.placeAddress}, ${item.description}`, value: item.place_id, lat: item.lat, lng: item.lng };
            } else if (item.type === "PINCODE") {
              return { label: `${item.placeAddress}, ${item.placeName}`, value: item.place_id, lat: item.lat, lng: item.lng, pincode: item.placeName };
            } else {
              return { label: item.description, value: item.place_id, lat: item.lat, lng: item.lng };
            }
          });
          resolve(mmiResult);
        } else {
          resolve([]); 
        }
      } else {
        resolve([]);
      }
    } catch (err) {
      resolve([]);
    }
  });
};

export const getLocation = async (input, type) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (input.length > NUMBERS.TWO) {
        const response = await AutoComplete.getInstance(type).getPlaces({ search: input });
        const result = response.map((item) =>
          ({ label: item.description, value: item.place_id, terms: item.terms}));
        resolve(result);
      } else {
        reject([]);
      } 
            
    } catch (err) {
      reject([]);
    }
  });
};

export const getLatLng = async (input, type) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = (await AutoComplete.getInstance(type).getGeoCode({ placeId: input }, "place")).pop();
      const locationObj = {
        lat: response.geometry.location.lat(),
        lng: response.geometry.location.lng()
      };
      const pin = response.address_components.find((item) => item.types.includes("postal_code"));
      resolve({lat: locationObj.lat, lng: locationObj.lng, pincode: pin ? pin.short_name : ''});
    } catch (err) {
      resolve({lat: '', lng: '', pincode: ''});
    }
  });
};

