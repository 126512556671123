/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import SelectComponent from "../shared/select/component";
import Checkbox from "../shared/checkbox/component";
import styles from "./pll-lead-allocation.module.scss";
import LeadAllocation from "../lead-allocation/component";
import Button from "../shared/button/component";
import { showToastMessages, getLoginData } from "../../utils/helper";
import { AppointmentService } from "../../service";
import { PLL_INVENTORY_APPOINTEMENT_FILTER, ROLE_KEYS,  NUMBERS, ALLOCATION_TYPE } from "../../utils/app-constants";
import PllAllocationFilters from "../../components/pll-allocation-filters";

const PllLeadAllocation = ({
	fetchPllAssociatesConnect,
	pllStores,
	resetPLLByKeyConnect,
	setPllLeadTypeFilterConnect,
	fetchAppointmentListConnect,
	pllAllocationFilter,
	fetchPllStoresConnect,
}) => {
	const {
		storeAppointmentList,
		pllUsers,
		selectedAppointments,
		raUser,
		selectedStoreIds,
		city,
		storeId,
		storeIds,
		pageNo,
		loadMore,
	} = pllStores;
	const [users, setUsers] = useState([]);
	const [checked, setChecked] = useState(false);
	const [length, setLength] = useState(0);
	const [userError, setUserError] = useState(false);
	const [allAppointments, setAllAppointments] = useState([]);
	const [raAllocationType, setRAAllocationType] = useState();
	const { isHierarchy = false, originalRole='' } = getLoginData();
	useEffect(() => {
		if (storeAppointmentList) {
			setAllAppointments(storeAppointmentList);
			setLength(storeAppointmentList.length);
		}
	}, [storeAppointmentList]);

	useEffect(() => {
		if (city) {
			fetchPllAssociatesConnect(city);
		}
	}, [city]);

	useEffect(() => {
		if (
			selectedAppointments.length === allAppointments.length &&
			allAppointments.length > 0
		) {
			setChecked(true);
		} else {
			setChecked(false);
		}
	}, [selectedAppointments, allAppointments]);

	useEffect(() => {
		const { isRetail = false } = pllAllocationFilter;
		const userFilterValue = isRetail ? '0' : '1';
		const usersList = pllUsers.filter(item => item?.is_pll_associate === userFilterValue).map((user) => {
			return {
				value: { id: user.id_lms_users, email: user.email },
				label: `${user.first_name + " " + user.last_name} (${
					user.allocatedPllLeadCount
				})`,
			};
		});
		setUsers(usersList);
	}, [pllUsers, pllAllocationFilter]);

	const onUserSelect = (value) => {
		const user = pllUsers.find((item) => item.id_lms_users === value.id);
		const { ra_type = "1" } = user || {};
		setRAAllocationType(ra_type);
		resetPLLByKeyConnect("raUser", value);
	};

	const isValidAppointment = (appId) => {
		const curApp =
			!!allAppointments &&
			allAppointments.find((appt) => appt.appointment === appId);
		if (!!curApp) {
			if (raAllocationType === ALLOCATION_TYPE.LOW) {
				if (NUMBERS.TWO_LAKHS - curApp.c24Quote < NUMBERS.ZERO) {
					return false;
				}
			} else if (raAllocationType === ALLOCATION_TYPE.HIGH) {
				if (curApp.c24Quote - NUMBERS.TWO_LAKHS < NUMBERS.ZERO) {
					return false;
				}
			}
		}
		return true;
	};
	const CheckedValidAppIds = () => {
		if (!raAllocationType || (!!raAllocationType && raAllocationType === "0")) {
			return true;
		}
		for (let i = 0; i < selectedAppointments.length; i++) {
			const appId = selectedAppointments[i];
			if (!isValidAppointment(appId)) {
				return false;
			}
		}
		return true;
	};

	const submitAppIds = (isTempAllocation=0) => {
		const { isRetail = false } = pllAllocationFilter;
		let response = [];
		let resObj = {};
		if (!raUser) {
			setUserError(true);
			//showToastMessages("Please Select RA Associate",false);
		} else if (!selectedAppointments || selectedAppointments.length === 0) {
			showToastMessages("Please Select at-least one Appointment", false);
		} else if (!CheckedValidAppIds()) {
			if (raAllocationType === ALLOCATION_TYPE.LOW) {
				showToastMessages(
					"Select Cars of C24 Less Than/Equal To 2L only",
					false
				);
			} else if (raAllocationType === ALLOCATION_TYPE.HIGH) {
				showToastMessages("Select Cars of C24 more Than 2L only", false);
			}
		} else if (
			raUser &&
			selectedAppointments &&
			selectedAppointments.length > 0
		) {
			let appts = [];
			selectedAppointments.map((item) => appts.push(item.appointment));
			resObj.pllAssociate = raUser.id;
			resObj.pllAssociateEmail = raUser.email;
			resObj.appointmentList = appts;
			if (!!isRetail) {
				resObj.isRetail = isRetail;
			}
			if (!!isTempAllocation) {
				resObj.isTempAllocation = isTempAllocation;
			}
			if (isHierarchy) {
				if (originalRole === ROLE_KEYS.TM_NEW) {
					resObj.isPll = 0;
				} else if (originalRole === ROLE_KEYS.PLL_TM) {
					resObj.isPll = 1;
				} else if (originalRole === ROLE_KEYS.TM) {
					resObj.isPll = 2;
				}
			}
			response.push(resObj);
			AppointmentService.allocateAppointment(response)
				.then(() => {
					showToastMessages("Leads Allocated", true);
					fetchPllStoresConnect(storeIds).then(() => {
						resetPLLByKeyConnect("storeId", null);
						resetPLLByKeyConnect("selectedStoreIds", []);
						resetPLLByKeyConnect("selectedAppointments", []);
						resetPLLByKeyConnect("storeAppointmentList", []);
						resetPLLByKeyConnect("raUser", "");
						resetPLLByKeyConnect("pageNo", 1);
						resetPLLByKeyConnect("loadMore", false);
						fetchPllAssociatesConnect(city);
						setUserError(false);
					});
				})
				.catch(() => {
					showToastMessages("Error in Allocating", false);
				});
		}
	};

	const onCheckboxHandler = () => {
		if (selectedAppointments.length === allAppointments.length) {
			resetPLLByKeyConnect("selectedAppointments", []);
		} else {
			resetPLLByKeyConnect("selectedAppointments", allAppointments);
		}
	};

	const onLeadsTypeSelect = (value) => {
		const { query } = pllAllocationFilter;
		let gsAssuredParam = !!query ? query : "";
		if (value === 1 || value === 0) {
			gsAssuredParam = `${gsAssuredParam}&isGSAssured=${value}`;
		}
		storeId && fetchAppointmentListConnect(storeId, gsAssuredParam);
		setPllLeadTypeFilterConnect(value);
	};

	const onLoadMore = () => {
		const newPageNo = pageNo + NUMBERS.ONE;
		resetPLLByKeyConnect("pageNo", newPageNo);
	};

	return (
		<div className={styles.pllLeadAllocationWrapper}>
			<div className={styles.allSelectboxWrapper}>
				<div className={styles.allSelectbox}>
					<Checkbox
						onChangeCallback={() => onCheckboxHandler()}
						checkedStatus={checked}
						id={`chk_select_all`}
						name={`chk_select_all`}
					/>
					<p>Select all {length > 0 ? `(${length})` : ""}</p>
					<PllAllocationFilters isHierarchy={isHierarchy} originalRole={originalRole}/>
				</div>

				<div className={styles.rightSelectBoxWrapper}>
					<div className={styles.selectLeads}>
						<SelectComponent
							optionsList={PLL_INVENTORY_APPOINTEMENT_FILTER}
							placeholder="Select lead"
							onChange={onLeadsTypeSelect}
							hasError={!raUser && userError}
							name={city}
							defaultValue={PLL_INVENTORY_APPOINTEMENT_FILTER[0]}
						/>
					</div>
					<div className={styles.selectLeads}>
						<SelectComponent
							optionsList={users}
							placeholder="Assign To"
							onChange={onUserSelect}
							hasError={!raUser && userError}
							name={city}
						/>
					</div>
				</div>
			</div>
			<div className={styles.leadFilterMainWrapper}>
				<LeadAllocation
					setUserError={setUserError}
					appointmentList={allAppointments}
					pllStores={pllStores}
					resetPLLByKeyConnect={resetPLLByKeyConnect}
					raAllocationType={raAllocationType}
				/>
				{selectedStoreIds.length === 0 && (
					<p className={styles.infoText}>
						Kindly select the store to allocate the leads
					</p>
				)}
			</div>
			<div className={styles.buttonWrapper}>
				<div className={styles.loadMore}>
					{loadMore ? (
						<Button
							ctaText={"Load More"}
							classNames="blueButton"
							onClick={() => {
								onLoadMore();
							}}
						/>
					) : (
						<></>
					)}
				</div>
				{!!selectedStoreIds?.length && !!allAppointments?.length && (
					<>
						{isHierarchy ? (
							<div className={styles.submitButtons}>
								<Button
									ctaText={"Temporary Assign"}
									classNames={`blueButton ${styles.inSubmitBtn}`}
									onClick={() => {
										submitAppIds(NUMBERS.ONE);
									}}
								/>
								<Button
									ctaText={"Permanent Assign"}
									classNames={`blueButton ${styles.inSubmitBtn}`}
									onClick={() => {
										submitAppIds();
									}}
								/>
							</div>
						) : (
							<div className={styles.submitButton}>
								<Button
									ctaText={"SUBMIT"}
									classNames="blueButton"
									onClick={() => {
										submitAppIds();
									}}
								/>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default PllLeadAllocation;
