import React, { useEffect, useRef, useState } from "react";
import styles from "./lead-c24-Quote-detail.module.scss";
import H3 from "../shared/h3/component";
import LabelWithText from "../shared/label-with-text/component";
import { LEAD_DETAILS_TABS, NUMBERS } from "../../utils/app-constants";
import InputText from "../shared/input-text/component";
import priceFormatter from "../../utils/helpers/price-formatter";
import { onNumberKeyPress, showToastMessages, isBikeSource, isPllUser, getLMSOrinialRole } from "../../utils/helper";
import ButtonLoader from "../button-loader";
import { AppointmentService } from "../../service";
import Popup from "../popup/component";
import OfferPopup from "../offer-popup";
import Button from "../shared/button/component";
import DiyPllAcceptPricePopup from "../diy-pll-accept-price-popup/component";

const LEAD_QUOTE = {
  WEB_QUOTE: 'Web Quote',
  UPDATED_WEBSITE: 'Updated Website Quote',
  SUGGESTED: 'Suggested C24 quote',
  FINAL_QUOTE: 'Final C24 quote',
  SO_OFFER: 'Final SO price'
};
const LeadC24QuoteDetail = ({ selectedTab, data, isInventoryBuying, isSaleOnline }) => {

  const c24QuoteRef = useRef();
  const {
    appointmentId,
    minWebQuote,
    maxWebQuote,
    minUpdatedWebQuote,
    maxUpdatedWebQuote,
    suggestedC24Quote,
    finalC24Quote,
    stateName,
    isC24QuoteShared,
    offerData,
    pllLimitC24Quote,
    negativeMargin,
    minC24Quote,
    maxC24Quote,
    marginQuotaCtaFlag,
    minC24QuoteFlag,
    soOfferPrice,
    maxSoOfferPrice,
    diyPllLead,
	isDirectOcb=false,
  } = data;
  const [price, setPrice] = useState(priceFormatter(!!isSaleOnline ? soOfferPrice : finalC24Quote));
  const [origPrice, setOrigPrice] = useState(priceFormatter(!!isSaleOnline ? soOfferPrice : finalC24Quote));
  const [isLoading, setIsLoading] = useState(false);
  const [initialValue, setInitialValue] = useState("");
  const [isPriceEditable, setIsPriceEditable] = useState(false);
  const { direct } = !!offerData ? offerData : [];
  const [errorClass, setErrorClass] = useState("");
  const minimumPrice = !!isSaleOnline ? soOfferPrice : !minC24QuoteFlag ? Math.round(suggestedC24Quote - (suggestedC24Quote * .15)) : 0;
  const [minPrice, setMinPrice] = useState(minimumPrice);
  const isBike = isBikeSource();
  const isPll = isPllUser();
  const isPllRH =  getLMSOrinialRole() === 'PLL-CH';
  const [isQuotaClicked, setIsQuotaClicked] = useState(false);
  const [totalMarginQuota, setTotalMarginQuota] = useState(0);
  const [isPriceConfirmationOpen, setIsPriceConfirmationOpen] = useState(false);
    
  useEffect(() => {
    checkIsPriceEditable();
  }, []);

  const isC24QuoteEditable =() => {
    if (isPll && !isBike && !!negativeMargin) {
      return  (NUMBERS.TWO_LAKHS - suggestedC24Quote) >= NUMBERS.ZERO;
    } else if (isDirectOcb) {
		return false;
	} else {
      return true;
    }
  };

  useEffect(() => {
    if (selectedTab && selectedTab === LEAD_DETAILS_TABS.C24QUOTE_DETAIL.key && c24QuoteRef && c24QuoteRef.current) {
      c24QuoteRef.current.scrollIntoView();
    }
  }, [selectedTab]);

  const onPriceFocused = () => {
    const formattedValue = price && price.replace(/,/g, '') ? Number(price.replace(/,/g, '')) : "";
    setPrice(formattedValue.toString());
    setInitialValue(formattedValue);
  };

  const onPriceChange = (e) => {
    const { target: { value } } = e;
    const formattedValue = value && value.replace(/,/g, '') ? Number(value.replace(/,/g, '')) : "";
    setPrice(formattedValue.toString());
    setErrorClass("");
  };
 
  const onDiyPllPricePopupClosed = (isChanged, price) => {
    if (!isChanged) {
      setPrice(priceFormatter(origPrice));
    } else {
      setPrice(priceFormatter(price));
      setOrigPrice(price);
    }
    setIsQuotaClicked(false);
    setTotalMarginQuota(0);
    setIsPriceConfirmationOpen(false);
  };

  const onPriceBlurred = (value) => {
    const formattedValue = value && value.replace(/,/g, '') ? priceFormatter(Number(value.replace(/,/g, ''))) : "";
    const nonFormattedVal = value && value.replace(/,/g, '') ? Number(value.replace(/,/g, '')) : "";
    setPrice(formattedValue.toString());
    setErrorClass("");
    if (initialValue !== nonFormattedVal) {
      if ((!isPllRH && minimumPrice > nonFormattedVal) || 
                (!isPllRH && !!pllLimitC24Quote && !isBike && isPll && nonFormattedVal > pllLimitC24Quote) || 
                (isPllRH && (nonFormattedVal < minC24Quote || nonFormattedVal > maxC24Quote)) || 
                (isSaleOnline && (nonFormattedVal < soOfferPrice || nonFormattedVal >  maxSoOfferPrice))) {
        setErrorClass("form-text text-danger"); 
      } else {
        if (!!isSaleOnline) {
          onOfferPriceUpdate(nonFormattedVal);   
        } else {
          if (!diyPllLead) {
            setIsQuotaClicked(false);
            setTotalMarginQuota(0);
            onPriceUpdate(nonFormattedVal);
          } else {
            setIsPriceConfirmationOpen(true);
          }
        }
                
      }
    }
  };

  const onPriceUpdate = (value) => {
    setIsLoading(true);
    const params = {
      'appointmentId': appointmentId,
      'c24BuyingQuote': value || 0
    };
    AppointmentService.updateSuggestedQuote(params)
      .then(response => {
        const { data: { detail: { message } } } = response;
        showToastMessages(message);
        !isPllRH && !minC24QuoteFlag && setMinPrice(Math.round(value - (value * .15)));
      }).catch(error => {
        const { detail: { message } } = error;
        showToastMessages(message, false);
      }).finally(()=>{
        setIsLoading(false);
      });
  };

  const onOfferPriceUpdate = (value) => {
    setIsLoading(true);
    const params = {
      'appointmentId': appointmentId,
      'offeredPrice': value || 0
    };
    AppointmentService.updateSaleOnlineQuote(params)
      .then(response => {
        showToastMessages(response?.detail);
      }).catch(error => {
        const { detail: { message } = {}} = error || {};
        showToastMessages(message || error, false);
      }).finally(()=>{
        setIsLoading(false);
      });
  };

  const checkIsPriceEditable = () => {
    const nonEditableStates = [
      'scheduled',
      'visited',
      'inspected'
    ];
    const checkC24QuoteStates = [
      'in-negotiation',
      'lost'
    ];
    let isEditable = false;
    const currentState = stateName.trim().toLowerCase();
    if (isPllRH && currentState === 'in-negotiation') {
      isEditable = true;
    } else {
      if (nonEditableStates.includes(currentState) || (checkC24QuoteStates.includes(currentState) && !isC24QuoteShared))
        isEditable = false;
      else
        isEditable = true;
    }
    setIsPriceEditable(isEditable);
  };

  const formatPriceData = (value) => {
    if (value && parseInt(value) > 0) {
      return parseInt(value).toLocaleString("en-IN");
    } else {
      return "0";
    }
  };
  const [offerOpen, setOfferOpen] = useState(false);
  const onOfferOpen = () => {
    setOfferOpen(true);
  };
  const onOfferClose = () => {
    setOfferOpen(false);
  };
  const onUseQuotaClick = () => {
    setTotalMarginQuota(0);
    !isQuotaClicked && AppointmentService.getAppointmentMarginQuota(appointmentId).then((resp)=>{
      const {detail} = resp || {};
      const {totalQuota = 0} = detail || {};
      setTotalMarginQuota(totalQuota);
    }).finally(()=>{
      setIsQuotaClicked(true);
    });
  };
  return (
    <div ref={c24QuoteRef} className={styles.leadQuoteDetail}>
      <H3 text={"C24 Quote"} />
      {!isDirectOcb && <div className={styles.textWrapper}>
        <div className={styles.detailsLeftWrapper}>
          <LabelWithText
            label={LEAD_QUOTE.WEB_QUOTE}
            text={`₹ ${formatPriceData(minWebQuote)} - ₹ ${formatPriceData(maxWebQuote)}`}
          />
        </div>
        <div className={styles.detailsRightWrapper}>
          <LabelWithText
            label={LEAD_QUOTE.UPDATED_WEBSITE}
            text={`₹ ${formatPriceData(minUpdatedWebQuote)} - ₹ ${formatPriceData(maxUpdatedWebQuote)}`}
          />
        </div>
      </div>}
      <div className={styles.textWrapper}>
        <div className={styles.detailsLeftWrapper}>
          <LabelWithText
            label={LEAD_QUOTE.SUGGESTED}
            text={isDirectOcb ? 'Not Applicable' : `${isPriceEditable ? `₹ ${formatPriceData(suggestedC24Quote)}` : '******'}`}
          />
          {!!marginQuotaCtaFlag && !!isPllRH && <div className={styles.useQuota}>
            <Button ctaText={"Use Quota"} classNames="blueButton" onClick={onUseQuotaClick}/>
            {isQuotaClicked && <p>{totalMarginQuota} remaining</p>}
          </div>}
        </div>
        {!!isSaleOnline && <div className={styles.detailsRightWrapper}> 
          {!!maxSoOfferPrice ? <>
            <InputText
              onFocusCallback={onPriceFocused}
              onChange={onPriceChange}
              value={price}
              text={LEAD_QUOTE.SO_OFFER}
              maxLength={NUMBERS.NINE}
              onKeyPress={onNumberKeyPress}
              onBlurCallback={onPriceBlurred}
              disabled={!maxSoOfferPrice || ((soOfferPrice - maxSoOfferPrice) === NUMBERS.ZERO)}
            />
            {(soOfferPrice - maxSoOfferPrice) !== NUMBERS.ZERO && <small key="info" className={`${errorClass}`}>{`Enter price between Rs ${priceFormatter(soOfferPrice)} and ${priceFormatter(maxSoOfferPrice)} only.`}</small>}
          </> :
          <LabelWithText
            label={LEAD_QUOTE.SO_OFFER}
            text={price}
          />
          }
        </div>
        }
        {!isSaleOnline && <div className={styles.detailsRightWrapper}>
          {!!isPllRH &&
          <>
            <InputText
              onFocusCallback={onPriceFocused}
              onChange={onPriceChange}
              value={price}
              text={LEAD_QUOTE.FINAL_QUOTE}
              maxLength={NUMBERS.NINE}
              onKeyPress={onNumberKeyPress}
              onBlurCallback={onPriceBlurred}
              disabled={totalMarginQuota < NUMBERS.ONE}
            />
            {!!minC24Quote && !!maxC24Quote && <small key="info" className={`${errorClass}`}>{`Enter price between ${priceFormatter(minC24Quote)} and ${priceFormatter(maxC24Quote)}`}</small>}
          </>
          }
          {!!isPriceEditable && !isLoading && !isPllRH &&
          <>
            <InputText
              onFocusCallback={onPriceFocused}
              onChange={onPriceChange}
              value={isDirectOcb ? '' : price}
              text={LEAD_QUOTE.FINAL_QUOTE}
              maxLength={NUMBERS.NINE}
              onKeyPress={onNumberKeyPress}
              onBlurCallback={onPriceBlurred}
              disabled={isInventoryBuying ? isInventoryBuying : !isC24QuoteEditable()}
            />
            {!pllLimitC24Quote && !!minPrice && <small key="info" className={`${errorClass}`}>{`Enter price above Rs ${priceFormatter(minPrice)} only.`}</small>}
            {!!pllLimitC24Quote && !isBike && isPll && isC24QuoteEditable() && <small key="info" className={`${errorClass}`}>{`You can enter price upto ${priceFormatter(pllLimitC24Quote)}.`}</small>}
          </>
          }
          <Popup isOpen={isPriceConfirmationOpen} close={()=> onDiyPllPricePopupClosed(false)}>
            <DiyPllAcceptPricePopup close={onDiyPllPricePopupClosed} price={price} apptId={appointmentId} source={true}/>
          </Popup>
          {!isPllRH && !!isPriceEditable && !!isLoading && <div className={styles.loader}><ButtonLoader color='#000' /></div>}
          {!isPllRH && !isPriceEditable &&
          <LabelWithText
            label={LEAD_QUOTE.FINAL_QUOTE}
            text='******'
          />
          }
          {!isPllRH && !!direct && direct.length > 0 &&
          <>
            <div className={styles.offer} onClick={onOfferOpen}>Offer</div>
            <Popup isOpen={offerOpen} close={onOfferClose}>
              <OfferPopup onClose={onOfferClose} offerData={direct} />
            </Popup>
          </>
          }
        </div>}
      </div>
    </div>
  );
};

export default LeadC24QuoteDetail;
