import React, {useState, useEffect} from "react";
import styles from "./bike-initiate-payment-popup.module.scss";
import H2 from "../../components/h2";
import Button from "../../components/shared/button";
import InputTextGreenTick from "../../components/shared/input-text-green-tick";
import { NUMBERS, TOKEN_PAYMENT_STATUS } from "../../utils/app-constants";
import { AppointmentService } from "../../service";
import hasSpecialCharacters from "../../utils/helpers/has-special-characters";
import { showToastMessages } from "../../utils/helper";
import { validateRegistrationNumber } from "../../utils/validators/registrationNumber-validator";
import InputTextRegistrationNumber from "../shared/input-text-registration-number";

const BikeInitiatePaymentPopup = (props) => {
  const {history, onClose, userData} = props;
  const {year, customerName, model, appointmentId, tokenPaymentStatus} = userData;
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [chasisNumber, setChasisNumber] = useState('');
  const [engineNumber, setEngineNumber] = useState('');
  const [isProceed, setIsProceed] = useState(false);
  const [isValidregistrationNumber, setIsValidregistrationNumber] = useState(false);

  useEffect(()=>{
    if (tokenPaymentStatus === TOKEN_PAYMENT_STATUS.RE_INITIATE) {
      setIsProceed(true);
      AppointmentService.getBikeInitiatePRToken(appointmentId).then((resp)=>{
        const {data: {detail: {regNumber='', chassisNumber='', engineNumber=''} = {}}} = resp;
        setRegistrationNumber(regNumber);
        setChasisNumber(chassisNumber);
        setEngineNumber(engineNumber);
      }).finally(()=>{
        setIsProceed(false);
      });
    }
  },[appointmentId, tokenPaymentStatus]);

  const onRegistrationNumberChange = (e) => {
    const { target: { value } } = e;
    if (value.length <= NUMBERS.ELEVEN) {
      setRegistrationNumber(value.toUpperCase());
      setIsValidregistrationNumber(validateRegistrationNumber(value));
    }
  };

  const onChasisNumberChange = (e) => {
    const { target: { value } } = e;
    setChasisNumber(value);
  };

  const onEngineNumberChange = (e) => {
    const { target: { value } } = e;
    setEngineNumber(value);
  };

  const onProceedInitiatePayment = () => {
    if (!registrationNumber) {
      showToastMessages("Registration number shouldn't empty", false);
      return;
    }

    if (hasSpecialCharacters(registrationNumber) || hasSpecialCharacters(chasisNumber) || hasSpecialCharacters(engineNumber)) {
      return;
    }

    const params = {
      appointmentId: appointmentId,
      ownerNameOnRc: "",
      regNumber: registrationNumber,
      chassisNumber: chasisNumber,
      engineNumber: engineNumber
    };
    setIsProceed(true);
    // setTimeout(()=> {
    //     setIsProceed(false);
    // }, 5000);
    AppointmentService.postBikeInitatePrToken(params).then(() => {
      history.push(`/document-upload/${appointmentId}`);
      onClose();
    }).catch((error) => {
      const { detail: {message}  } = error;
      console.log("error", error);
      showToastMessages(message, false);
    }).finally(()=>{
      setIsProceed(false);
    });
  };
    
  return (
    <div className={styles.bikeInitiatePaymentPopup}>
      <H2 text="RC Details"/>
      <div className={styles.carDataList}>
        <ul>
          <li>{customerName}</li>
          <li>{model}</li>
          <li>{year}</li>
        </ul>
      </div>
      <div className={styles.inputWrapper}>
        <InputTextRegistrationNumber
          text="Registration Number*" 
          errorMessage={"It seems you have put wrong Registration number. Please check again."}
          value={registrationNumber}
          validator={validateRegistrationNumber}
          onChange={onRegistrationNumberChange}
        />
      </div>
      <p className={styles.textHeading}>Other Details</p>
      <span className={styles.subText}>Optional</span>
      <div className={styles.inputWrapper}>
        <InputTextGreenTick 
          text="Chassis Number" 
          error="It seems you have put wrong Chassis number. Please check again."
          maxLength={NUMBERS.TWENTY}
          value={chasisNumber}
          onChange={onChasisNumberChange}
          validator={hasSpecialCharacters}
        />
      </div>
      <div className={styles.inputWrapper}>
        <InputTextGreenTick 
          text="Engine Number" 
          error="It seems you have put wrong Chassis number. Please check again."
          value={engineNumber}
          maxLength={NUMBERS.TWENTY}
          onChange={onEngineNumberChange}
          validator={hasSpecialCharacters}
        />
      </div>
      <div>
        <Button ctaText="PROCEED" classNames="blueButton" onClick={onProceedInitiatePayment} disabled={isProceed || !isValidregistrationNumber}/>
      </div>
    </div>
  );
};

export default BikeInitiatePaymentPopup;
