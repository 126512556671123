import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Search from "./component";
import { resetSearchByKey } from "./actions";

const mapStateToProps = ({
  search,
  appointments
}) => ({
  search,
  appointments
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  resetSearchByKeyConnect: resetSearchByKey
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Search);
