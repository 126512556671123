import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  globalQuery: null
};

export const resetGlobalSearch = () => {
  return INITIAL_STATE;
};

export const resetGlobalSearchByKey = (state = INITIAL_STATE, { data }) => {
  const { key, value } = data;
  return { ...state, [key]: value };
};

export const HANDLERS = {
  [Types.RESET_GLOBAL_SEARCH]: resetGlobalSearch,
  [Types.RESET_GLOBAL_SEARCH_BY_KEY]: resetGlobalSearchByKey
};

export default createReducer(INITIAL_STATE, HANDLERS);
