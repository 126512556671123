import React from "react";
import styles from "./dealer-car-price-offer.module.scss";
import Button from "../../../components/shared/button/component";
import { BUTTON_STYLES } from "../../common/app-constants";
import Arrow from "./images/arrow.png";
import {santiziePrice} from "../../common/utility";

const DealerCarPriceOffer = ({minPrice=0, maxPrice=0, onRejectPrice, onAcceptPrice, isSubmit }) => {
  return (
    <div className={styles.wrapper}>
      <ul>
        <li>
          <div className={styles.priceWrap}>
            <div className={styles.offersText}><img src={Arrow} alt="right tick"/> Congratulations</div>
            <span>Your final price will be between</span>
            <strong className={styles.price}>₹ {`${santiziePrice(minPrice)} - ${santiziePrice(maxPrice, true)}`}</strong>
          </div>
        </li>
        <li>
          <div className={styles.buttonWrap}>
            <Button ctaText={"REJECT PRICE"} classNames={`${BUTTON_STYLES.DEFAULT} ${styles.btnReject}`} onClick={onRejectPrice} disabled={isSubmit}/>
            <Button ctaText={"ACCEPT PRICE"} classNames={`${BUTTON_STYLES.DEFAULT} ${styles.btnAccept}`} onClick={onAcceptPrice} disabled={isSubmit}/>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default DealerCarPriceOffer;
