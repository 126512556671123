import React from "react";
import styles from "./pagination-loader.module.css";

const PaginationLoader = () => {
  return (
    <p className={styles.loading}>Loading...</p>
  );
};

export default PaginationLoader;
