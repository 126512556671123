import React, {useEffect, useState} from "react";
import styles from "./cj-planning.module.scss";
import { PAGE_HEADING, NUMBERS } from "../../utils/app-constants";
import StickyComponent from "../../components/shared/sticky-component";
import ToggleArrow from "../../components/images/toggleArrow.svg";
import ToggleDown from "../../components/images/toggleDown.svg";
import CjExecutionCard from "../../components/cj-execution-card";
import { AppointmentService } from "../../service";
import moment from "moment";
import { getAppointmentTypeFromPathName, getUniqueStoreForCities } from "../../utils/helper";
import Search from "../../components/search";
import { useDispatch } from "react-redux";
import {updateApiNoAccess} from "../../components/hoc-access-controller/actions";
import HocAccessController from "../../components/hoc-access-controller";
const CjExecution = ({
  location, 
  fetchCjAllocationStatusConnect,
  leadMenu,
  resetApiNoAccessConnect
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [cjPageIndex, setCjPageIndex] = useState(0);
  const [cjNextDisable, setCjNextDisable] = useState(false);
  const [cjPrevDisable, setCjPrevDisable] = useState(true);
  const cjPageSize = NUMBERS.FIVE;
  const [cjData, setCjData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [cjRenderedData, setCjRenderedData] = useState([]);
  const { pathname } = location;
  const appointmentType = getAppointmentTypeFromPathName(pathname);
  const { resetAndFetchData } = leadMenu;
  const dispatch = useDispatch();
  const updateCjAllocationStatus = () => {
    let filterQuery = "";
    let cityStores = getUniqueStoreForCities();
    filterQuery = "?storeId=" + cityStores;
    fetchCjAllocationStatusConnect(filterQuery);
  };

  const renderCjData = (pageIndex) => {
    const cjs = cjData.length > 0 && cjData.slice(pageIndex * cjPageSize, pageIndex * cjPageSize + cjPageSize);
    !!cjs && setCjRenderedData(cjs);
  };

  const resetAndFetchNewListing = () => {
    setIsLoading(true);
    AppointmentService.getResourceAllocationStatus().then((resp)=>{
      if (!!resp && !!resp.data && !!resp.data.detail) {
        setCjData(Object.values(resp?.data?.detail?.allocation));
      }
    }).catch((error)=>{
      dispatch(updateApiNoAccess(error));
    }).finally(()=>{
      setIsLoading(false);
    });
    updateCjAllocationStatus();
  };

  useEffect(()=>{
    resetApiNoAccessConnect();
    resetAndFetchNewListing();
  },[]);

  useEffect(() => {
    if (resetAndFetchData) {
      resetAndFetchNewListing();
    }
  }, [resetAndFetchData]);

  useEffect(()=>{
    if (!isLoading) {
      setCjPageIndex(0);
      renderCjData(0);
    }
  }, [isLoading]);

  const onClickPrev = () => {
    const prePageCount = cjPageIndex - NUMBERS.ONE;
    if (prePageCount < NUMBERS.ZERO) {
      setCjPrevDisable(true);
      setCjNextDisable(false);
    } else {
      setCjPageIndex(prePageCount);
      renderCjData(prePageCount);
    }
  };

  const onClickNext = () => {
    const totalPageCount = cjData.length / cjPageSize;
    const nextPageCount = cjPageIndex + NUMBERS.ONE;
    if (nextPageCount < totalPageCount) {
      setCjPageIndex(nextPageCount);
      setCjPrevDisable(false);
      renderCjData(nextPageCount);
    } else {
      setCjNextDisable(true);
    }
  };

  return (
    <HocAccessController>
      <div className={styles.cjPlankng}>
        {!isLoading &&<>
          <StickyComponent topOffset={NUMBERS.MINUS_SIXTY} classes="freezeHeader" >
            <div className={styles.headingSearchWrapper}>
              <div className={styles.toggleSection}>
                <div className={styles.arrowCalendar}>
                  <img onClick={() => setIsOpen(!isOpen)} src={isOpen ? ToggleArrow : ToggleDown }  alt="arrow" />
                  <span >{PAGE_HEADING.CJ_EXECUTION}</span>
                </div>
              </div>
              <Search appointmentType={appointmentType}/> 
            </div>
          </StickyComponent>
          <div className={styles.cjPlanningWrapper}>
            {isOpen && (
              <div className={styles.cjPlanningData}>
                <div className={styles.slotsWrapper}>
                  <span>Allocated:</span>
                  <span>Completed:</span>
                  <span className={styles.active}>Punch In Time:</span>
                  <span className={styles.active}>1st inspection start time</span>
                </div>
                <div className={styles.cjDataWrapper}>
                  <div className={styles.nextPrev}>
                    <button className={styles.prev} onClick={onClickPrev} disabled={cjPrevDisable} />
                    <button className={styles.next} onClick={onClickNext} disabled={cjNextDisable}/>
                  </div>
                  {!!cjRenderedData && cjRenderedData.length > 0 && cjRenderedData.map((item)=>{
                    return (
                      <div className={styles.cjData}>
                        <h3>{item.name}</h3>
                        <span>{item.allocated}</span>
                        <span>{item.inspected}</span>
                        <span className={!!item.punchInTime ? styles.active : ''}>{!!item.punchInTime ? moment(item.punchInTime).format('LT') : 'Leave'}</span>
                        <span>{!!item.firstInspectionTime ? moment(item.firstInspectionTime).format('LT') : '--'}</span>
                      </div>
                    );
                  })
                  }
                </div>
              </div>
            )}
            <CjExecutionCard appointmentType={appointmentType} />
          </div>
        </>}
      </div>
    </HocAccessController>
  );
};

export default CjExecution;
