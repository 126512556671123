import React from "react";
import { NOT_AVAILABLE, PICKUP_PENDING } from "../../utils/app-constants";
import styles from "./car-status.module.css";
const CarStatus = ({rowDetails}) => {
  const { pickUpRequestStatus }= rowDetails;
  return (
    <div>
      <span className={pickUpRequestStatus === PICKUP_PENDING ? styles.pickupPending : ''}>{pickUpRequestStatus ? pickUpRequestStatus : NOT_AVAILABLE}</span>
    </div>
  );
};

export default CarStatus;
