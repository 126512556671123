import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Sidebar from "./component";
import {
    resetAppointmentByKey,
    resetAppointments,
} from "../../pages/appointment/actions";
import {
    fetchUserMenus,
    fetchAppointmentCounts,
    resetAppointmentCountByKey,
    resetSidebar,
    updatePllFilterByKey,
    updateRetailFilterByKey,
} from "./actions";
import { resetSearch } from "../search/actions";
import { resetLeadMenuByKey } from "../../components/lead-menu/actions";
const mapStateToProps = ({ appointments, sidebar }) => ({
    appointments,
    sidebar,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            resetAppointmentByKeyConnect: resetAppointmentByKey,
            resetAppointmentsConnect: resetAppointments,
            fetchAppointmentCountsConnect: fetchAppointmentCounts,
            resetSearchConnect: resetSearch,
            resetAppointmentCountByKeyConnect: resetAppointmentCountByKey,
            resetSidebarConnect: resetSidebar,
            updatePllFilterByKeyConnect: updatePllFilterByKey,
            updateRetailFilterByKeyConnect: updateRetailFilterByKey,
            fetchUserMenusConnect: fetchUserMenus,
            resetLeadMenuByKeyConnect: resetLeadMenuByKey,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
