export default (api) => {
	const getLeadDetails = (token) => {
		return new Promise((resolve, reject) => {
			api
				.get(`lead-details/${token}`)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};
	const getAppointmentDetails = (token) => {
		return new Promise((resolve, reject) => {
			api
				.get(`appointment/token/${token}`)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};
	const onSubmitDealerPrice = (params) => {
		return new Promise((resolve, reject) => {
			api
				.post(`offer-price/status`, params)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};
	return {
		getLeadDetails,
		onSubmitDealerPrice,
		getAppointmentDetails,
	};
};
