import React, {useState, useEffect} from 'react';
import SupersetDashboard from '../../components/superset-dashboard';
import { REPORT_TYPE, USER_TYPE } from '../../components/domo-dashboard/constant';
import { getFromLocalStorage } from "../../utils/helper";
import { LOCAL_STORAGE_KEYS, ROLE_KEYS } from "../../utils/app-constants";
import withLayout from '../../components/layout/component';

const CallReports = (props) => {
  const localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
  const {originalRole='', email=''} = JSON.parse(localData) || {};
  const userType = ROLE_KEYS.CJM !== originalRole ? USER_TYPE.ZM : USER_TYPE.RA;
  const pathName= props?.match?.path?.substring(1);
  const [reportId, setReportId] = useState();
  const [dashboardId, setDashboardId] = useState();
  useEffect(()=>{
	switch (pathName) {
		case "ra-incentive":
			setReportId('0a56f38e-ba13-4fef-8310-816bab80a9c2');
			setDashboardId('160');
			break;
    case "zm-incentive":
        setReportId('0a56f38e-ba13-4fef-8310-816bab80a9c2');
        setDashboardId('244');
        break;
    case "hypo-incentive":
        setReportId('0a56f38e-ba13-4fef-8310-816bab80a9c2');
        setDashboardId('261');
        break;
		case "call-reports":
			setReportId('c6d2f404-2703-4371-9d92-24a7c8018c7b');
			setDashboardId('167');
			break;
		default:
			break;
	}
  }, [pathName]);
  return (
	<>
		{!!reportId && <SupersetDashboard
		reportType={REPORT_TYPE.MISSED_CALL}
		reportId={reportId}
		dashboardId={dashboardId}
		userType={userType}
		raEmail={email}/>}
	</>
  );
};

export default withLayout(CallReports, false);;
