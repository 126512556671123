import Types from "./types";

const resetLostLeadSearch = () => ({
  type: Types.RESET_LOSTLEAD_SEARCH
});

const resetLostLeadSearchByKey = (key, value) => ({
  type: Types.RESET_LOSTLEAD_SEARCH_BY_KEY,
  data: { key, value }
});

export {
  resetLostLeadSearch,
  resetLostLeadSearchByKey
};
