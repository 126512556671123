/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import styles from "./homeInspection-filters.module.css";
import {
  HOME_INSPECTION_FILTERS,
  CJM_HOME_INSPECTION_FILTERS,
  LOCAL_STORAGE_KEYS,
  ROLE_KEYS,
  CJM_UNASSIGNED_DAYS_FILTERS,
} from "../../utils/app-constants";
import { getFromLocalStorage } from "../../utils/helper";
import SelectComponent from "../shared/select";
const HomeInspectionFilters = ({
  homeInspectionFilters,
  updateFilterQueryConnect,
}) => {
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedUnAssignedDays, setSelectedUnAssignedDays] = useState("");
  const { hisfilterQuery } = homeInspectionFilters;
  const { originalRole = "", isHierarchy = false } =
    JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA)) || {};
  const isCJM =
    [ROLE_KEYS.CJM, ROLE_KEYS.A2I_HEAD, ROLE_KEYS.FOOTFALL_MANAGER].includes(
      originalRole
    ) && isHierarchy;
  const filters = isCJM ? CJM_HOME_INSPECTION_FILTERS : HOME_INSPECTION_FILTERS;
  useEffect(() => {
    if (!!hisfilterQuery) {
      if (hisfilterQuery === "init") {
        setSelectedFilter("nextDay");
      }
    } else {
      setSelectedFilter("today");
    }
    return () => {
      updateFilterQueryConnect(null);
    };
  }, []);

  const onFilterSelect = (selectedKey, selectedValue) => {
    let query = null;
    if (isCJM && selectedKey === "lastDays") {
      setSelectedFilter(selectedKey);
      query = `&unAssigned=1`;
    } else {
      query = `&leadDays=${selectedValue}`;
      if (query !== hisfilterQuery) {
        setSelectedFilter(selectedKey);
      } else {
        setSelectedFilter("");
      }
    }
    updateFilterQueryConnect(query);
  };
  const onUnAssignedDaysSelect = (e) => {
    setSelectedUnAssignedDays(e);
    const query = `&unAssigned=1&unAssignedDay=${e}`;
    if (query !== hisfilterQuery) {
      updateFilterQueryConnect(query);
    } else {
      updateFilterQueryConnect(null);
    }
  };
  return (
    <>
      {selectedFilter === "lastDays" && (
        <div className={styles.unassignedSelect}>
          <SelectComponent
            placeholder="Days"
            name="unassigned-days"
            onChange={onUnAssignedDaysSelect}
            optionsList={Object.values(CJM_UNASSIGNED_DAYS_FILTERS)}
          />
        </div>
      )}
      <ul className={styles.filters}>
        {Object.values(filters).map((item) => {
          return (
            <li
              key={item.key}
              onClick={() => {
                onFilterSelect(item.key, item.value);
              }}
              className={selectedFilter === item.key ? styles.selected : ""}
            >
              {item.label}
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default HomeInspectionFilters;
