import Types from "./types";
import { AppointmentService } from "../../service";
// import { verifyOTPMoc } from "../../utils/mocApi";

const fetchVarifyOtp = (param) => dispatch => {
  return AppointmentService.verifyWaitTimeOtp(param)
    .then(response => {
      // response = verifyOTPMoc();
      const { data: { detail } } = response;
      return detail;
    })
    .catch((error) => {
      throw error;
    });
};
const fetchSendOtp = (param) => dispatch => {
  return AppointmentService.sendWaitTimeOtp(param)
    .then(response => {
      // response = getSimilarCarMoq();
      const { data: { detail } } = response;
      return detail;
    })
    .catch((error) => {
      throw error;
    });
};

export {
  fetchVarifyOtp,
  fetchSendOtp
};
