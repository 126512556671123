import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BookAppointmentPopup from "./component";
import {fetchMake,fetchOdometer,fetchState} from "../book-appointment/actions";

const mapStateToProps = ({
  bookAppointment
}) => ({
  bookAppointment
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchMakeConnect:fetchMake,
  fetchOdometerConnect: fetchOdometer,
  fetchStateConnect: fetchState

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BookAppointmentPopup);
