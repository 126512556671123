/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import styles from "./waittime-dashboard.module.scss";
import CarLogo from "../../components/images/logoC24.svg";
import AdImage from "../../components/images/ad.png";
import firebase from "../../utils/firebase";
import {
    getFireBaseDB, getFireBaseUserName, getFireBasePassword
} from "../../utils/url-constants";
import { WAIT_TIME_DASHBOARD_HIDEADD_INTERVAL, WAIT_TIME_DASHBOARD_DISPLAYADD_INTERVAL, WAIT_TIME_DASHBOARD_PAGE_SIZE, WAIT_TIME_DASHBOARD_SCREENSPLIT_INTERVAL } from "../../utils/app-constants";
import moment from "moment";
import sortByKey from "../../utils/helpers/sort-by-key";
import "firebase/auth";
import Popup from "../../components/popup/component";
import WaittimeDashboardPopup from "../../components/waittime-dashboard-popup";
import {
    create_UUID,
    getWaitTimeDashBoardLocalStaorageKey, setWaitTimeDashBoardLocalStaorageKey
} from "../../utils/helper";

const WaittimeDashboard = ({ match, fetchValidateDashboardConnect }) => {
    const { params: { storeId } } = match;
    let store_Id = storeId;
    const [customerData, setCustomerData] = useState([]);
    const [recordCount, setRecordCount] = useState([]);
    const pageSizeDataLimit = WAIT_TIME_DASHBOARD_PAGE_SIZE;
    const [noOfPages, setNoOfPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [showAdd, setShowAdd] = useState(false);
    const [displayAdd, setDisplayAdd] = useState(false);
    const [validateData, setValidateData] = useState(false);
    const [validDashBoard, setValidDashBoard] = useState(false);
    const [storeWelComeMsg, setStoreWelComeMsg] = useState("");
    const [wscreenSplitTime, setWscreenSplitTime] = useState(WAIT_TIME_DASHBOARD_SCREENSPLIT_INTERVAL);
    const [adSplittime, setAdSplittime] = useState(WAIT_TIME_DASHBOARD_DISPLAYADD_INTERVAL);
    const [adTimeOut, setAdTimeOut] = useState(WAIT_TIME_DASHBOARD_HIDEADD_INTERVAL);
    const [checkInOpen, setCheckInOpen] = useState(false);
    const [advertiseUrl, setAdvertiseUrl] = useState("");

    let _currentPage = 1;
    // let _statusCount = 1;
    const email = getFireBaseUserName();
    const password = getFireBasePassword();
    let auctionStatus = {
        "suspend": "Suspended",
        "suspended_live": "Suspended",
        "bid_over": "Auction Over",
    };

    useEffect(() => {
        const custDashboardDb = firebase.getInstance().database().ref(`/${getFireBaseDB()}/${store_Id}`);
        firebase.getInstance().auth().signInWithEmailAndPassword(email, password)
            .then(user => {
                setValidateData(true);
            })
            .catch(error => {
                setValidateData(false);
                console.log("Error in firebase user authentication - " + error);
            });
        let dashboardUid = getWaitTimeDashBoardLocalStaorageKey(store_Id);
        if (dashboardUid == "") {
            dashboardUid = create_UUID();
            setWaitTimeDashBoardLocalStaorageKey(store_Id, dashboardUid);
        }
        fetchValidateDashboardConnect(store_Id, dashboardUid).then(resp => {
            if (!!resp) {
                const { success, storeName, storeConfig: { screenSplitTime, ad_split_time, ad_time_out, displayAdv, advUrl } } = resp;
                if (success) {
                    setAdvertiseUrl(!!advUrl ? advUrl : "");
                    setStoreWelComeMsg(`Welcome to ${storeName} Branch`);
                    setDisplayAdd(displayAdv);
                    if (screenSplitTime > 0 && wscreenSplitTime != screenSplitTime) {
                        setWscreenSplitTime(screenSplitTime);
                    }
                    if (ad_split_time > 0 && adSplittime != ad_split_time) {
                        setAdSplittime(ad_split_time);
                    }
                    if (ad_time_out > 0 && adTimeOut != ad_time_out) {
                        setAdTimeOut(ad_time_out);
                    }
                    setValidDashBoard(success);
                } else {
                    setCheckInOpen(true);
                }
            }

        }).catch((error) => {
            // const { status, detail: { success } } = error;
            const { status, detail } = error;
            if (!!status && !!detail) {
                const { success } = detail;
                if (status == 422 && success == false) {
                    setCheckInOpen(true);
                }
                else {
                    // console.log(" error in validate dashboard api - " + error);
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (validDashBoard) {
            const custDashboardDb = firebase.getInstance().database().ref(`/${getFireBaseDB()}/${store_Id}`);
            if (validateData) {
                custDashboardDb.on('child_added', (snapshot) => {
                    const data = snapshot.val();
                    if (data) {
                        addToFirebaseObject(data);
                    }
                });
                custDashboardDb.on('child_changed', (snapshot) => {
                    const data = snapshot.val();
                    if (data) {
                        updateDeleteFirebaseObject(data, "update");
                    }
                });
                custDashboardDb.on('child_removed', (snapshot) => {
                    const data = snapshot.val();
                    if (data) {
                        updateDeleteFirebaseObject(data, "delete");
                    }
                });
            }

            let interval = setInterval(() => {
                divideData();
            }, wscreenSplitTime);

            let intervalShowAdd;
            let intervalHideAdd;
            if (displayAdd) {
                intervalHideAdd = setInterval(function (id) {
                    setShowAdd(false);
                    console.log("setInterval Call 1 - " + new Date());
                }, adTimeOut, (0));

                intervalShowAdd = setInterval(function (id) {
                    setShowAdd(true);
                    console.log("setInterval Call 2 - " + new Date());
                }, adSplittime, (1));

                // intervalShowAdd = setInterval(function () {
                //     setShowAdd(true);
                //     console.log("setInterval Call 1 - " + new Date() + " - " + showAdd);
                // }, adSplittime); //adSplittime

                // setTimeout(function () {
                //     setTimeout(function () {
                //         setShowAdd(false);
                //         console.log("setInterval Call 2 - " + new Date() + " - " + showAdd);
                //     }, adTimeOut);
                // }, adTimeOut); //adTimeOut

                // // setTimeout(function () {
                // //     console.log("settimeOut Call 1 - " + new Date() + " - " + showAdd);
                // //     intervalHideAdd = setInterval(function () {
                // //         setShowAdd(false);
                // //         console.log("setInterval Call 2 - " + new Date() + " - " + showAdd);
                // //     }, adTimeOut + adSplittime); //adSplittime  //one Approach is at this time the set interval would be the (adSplittime + adSplittime)
                // // }, adTimeOut); //adTimeOut
            }
            return () => {
                custDashboardDb.off();
                clearInterval(interval);
                clearInterval(intervalShowAdd);
                clearInterval(intervalHideAdd);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validateData, validDashBoard, displayAdd]);
    const updateDeleteFirebaseObject = (data, action) => {
        let isTokenExistIndex = recordCount.findIndex(x => x.tokenNumber == data.tokenNumber);

        if (action == "update") {
            if (isTokenExistIndex >= 0) {
                recordCount[isTokenExistIndex] = data;

                let rowData = [...recordCount];
                let newData = rowData.map(row => {
                    const newRow = { ...row };
                    // newRow.checked = true;
                    return newRow;
                });

                setRecordCount(newData);
                setCustomerData(newData);
            }
        }
        if (action == "delete") {
            if (isTokenExistIndex >= 0) {
                recordCount.splice(isTokenExistIndex, 1);
                let rowData = [...recordCount];
                let newData = rowData.map(row => {
                    const newRow = { ...row };
                    // newRow.checked = true;
                    return newRow;
                });
                setRecordCount(newData);
                setCustomerData(newData);
            }
        }

    };

    const onCheckInOpen = () => {
        setCheckInOpen(true);
    };

    const onCheckInClose = (storeName, screenSplitTime, ad_split_time, ad_time_out, displayAdv, advUrl) => {
        setCheckInOpen(false);
        setStoreWelComeMsg(`Welcome to ${storeName} Branch`);
        setDisplayAdd(displayAdv);
        setAdvertiseUrl(!!advUrl ? advUrl : "");
        if (screenSplitTime > 0 && wscreenSplitTime != screenSplitTime) {
            setWscreenSplitTime(screenSplitTime);
        }
        if (ad_split_time > 0 && adSplittime != ad_split_time) {
            setAdSplittime(ad_split_time);
        }
        if (ad_time_out > 0 && adTimeOut != ad_time_out) {
            setAdTimeOut(ad_time_out);
        }

        setValidDashBoard(true);
    };

    const addToFirebaseObject = (data) => {
        let dataObjectArrayForcount = recordCount;
        let dataObjectArray = [];//customerData;// [...customerData];
        var date = new Date();
        var expectedDate = new Date(data.expectedStartTime);
        if (date.toDateString() == expectedDate.toDateString()) {
            let dataObject = {
                appointment: data.appointment,
                auctionStatus: data.auctionStatus,
                auction_end_time: data.auction_end_time,
                color: data.color,
                expectedStartTime: data.expectedStartTime,
                idLmsCustomerToken: data.idLmsCustomerToken,
                idLmsStore: data.idLmsStore,
                model: data.model,
                status: data.status,
                tokenNumber: data.tokenNumber,
                year: data.year
            };
            dataObjectArray.push(dataObject);
            dataObjectArrayForcount.push(dataObject);
            setRecordCount(dataObjectArray);
            setCustomerData(m => m.concat(dataObjectArray));

            if (recordCount.length > pageSizeDataLimit) {
                divideData();
            }
        }
    };
    const divideData = () => {
        let sortedRecordCount = recordCount.sort((x, y) => x.tokenNumber - y.tokenNumber);
        setRecordCount(sortedRecordCount);
        if (recordCount.length > pageSizeDataLimit) {
            setCurrentPage(currentPage + 1);
            _currentPage++;

            if (_currentPage > getNoOfPages()) {
                setCurrentPage(1);
                _currentPage = 1;
            }
            splitScreen();
        }
    };
    const splitScreen = () => {
        setCustomerData([]);
        for (var i = (_currentPage - 1) * pageSizeDataLimit; i < (_currentPage * pageSizeDataLimit) && i < recordCount.length; i++) {
            // eslint-disable-next-line no-loop-func
            setCustomerData(m => m.concat(recordCount[i]));
        }
    };

    const getNoOfPages = () => {
        let pageno = Math.ceil(recordCount.length / pageSizeDataLimit);
        setNoOfPages(pageno);
        return pageno;
    };
    const getInspectionstatus = (item) => {
        switch (item.status) {
            case "0": {
                return "Expected start : " + moment(item.expectedStartTime).format("hh:mm:ss a");
            }
            case "1": {
                return "In Progress";
            }
            case "2":
                return "Inspection Denied";
            case "9":
                return "Inspection Done";
            default:
                return "";
        }
    };
    const getAuctionStatus = (item) => {
        if (item.auctionStatus == "auction_start" || item.auctionStatus == "instant_deal") {
            let auctionstatus = "Estimated End " + item.auction_end_time;

            let cDate = new Date();
            let d = item.auction_end_time + ":00 " + (cDate.getMonth() + 1) + "-" + cDate.getDate() + "-" + cDate.getFullYear();
            var tDate = new Date(d);
            if (cDate - tDate > 0) {
                auctionstatus = "Auction Extended";
            }
            return auctionstatus;
        }

        let status = item.auctionStatus;
        let auctionStatus = status;
        if (status == "suspend") {
            auctionStatus = "Suspended";
        }
        else if (status == "suspended_live") {
            auctionStatus = "Suspended";
        }
        else if (status == "bid_over") {
            auctionStatus = "Auction Over";
        }
        return auctionStatus;
    };
    const getColor = (item) => {

        let startDate = new Date(item.expectedStartTime);
        let currentDate = new Date();
        let dateDifference = startDate.getTime() - currentDate.getTime();

        //time difference in number of minutes
        let timeDiffrence = Math.ceil(dateDifference / (60 * 1000));

        let color = "";
        //If token is in progress
        if (item.status != "1" && item.status != "2" && item.status != "9") {
            if (timeDiffrence < 0) {
                color = "redCircle";
            }
            else if (timeDiffrence <= 30 && timeDiffrence > 0) {
                color = "orangeCircle";
            }
        }

        return color;
    };
    return (
      <div className={styles.waitimeDashboard}>
        <div className={styles.headar}>
          <div className={styles.logoSection}>
            <img src={CarLogo} alt="car logo" />
            <p className={styles.store}>{storeWelComeMsg}</p>
          </div>
          <div className={styles.waitheaderRight}>
            <p className={styles.ourSupport}>Contact Our Support</p>
            <p className={styles.startingShortly}>Starting Shortly</p>
          </div>
        </div>
        <div className={styles.contentWrapper}>
          {(showAdd && advertiseUrl == "") && <img src={AdImage} alt="ad" />}
          {(showAdd && advertiseUrl !== "") &&
                    // <iframe src="https://screenodata.s3-ap-southeast-1.amazonaws.com/screeno-uploads/eros_now_demo"
            <iframe src={advertiseUrl}
                        width="100%" height="91%" frameborder="0"
                        allowfullscreen sandbox>
            </iframe>
                }
          <table cellPadding="0" cellSpacing="0" width="100%">
            <thead>
              <tr>
                {/* <th>index</th> */}
                <th>Token No.</th>
                <th>Appointment ID</th>
                <th>Car Model</th>
                <th>Inspection Status</th>
                <th>Auction Status</th>
              </tr>
            </thead>
            {customerData && customerData.length > 0 && <tbody>
                {customerData.sort((x, y) => x.tokenNumber - y.tokenNumber).map((item, index) =>
                  <tr>
                    {/* <td>{index} - <span className={styles[getColor(item)]}></span></td> */}
                    {/* <td><span className={styles[getColor(item)]}></span></td> */}
                    <td>
                      <div className={styles.countWrapper}>
                        <span className={`${styles[getColor(item)]}`}></span>
                        <p className={`${styles.count}`}>{item.tokenNumber}</p>
                      </div>
                    </td>
                    <td>{item.appointment}</td>
                    <td>{item.model} ({item.year})</td>
                    <td>  {getInspectionstatus(item)}</td>
                    {/* <td>  {item.status}</td> */}
                    <td>  {(item.status == "2" || item.status == "9") ? getAuctionStatus(item) : "-"}</td>
                  </tr>
                        )}

            </tbody>
                    }
          </table>

        </div>
        {checkInOpen && <Popup isOpen={onCheckInOpen} close={onCheckInClose} showCloseButton={false}>
          <WaittimeDashboardPopup close={onCheckInClose} storeId={store_Id} />
        </Popup>
            }
      </div>
    );
};

export default WaittimeDashboard;
