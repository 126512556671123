import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CarFactors from "./component";

const mapStateToProps = ({
  customerCharges
}) => ({
  customerCharges
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CarFactors);
