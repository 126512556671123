import React from 'react';
import styles from  "./response-loader.module.css";

const DiffReportResponseLoader = (props) => {
    const isCarDetails = !!Object.keys(props.data)?.length;
    return (
        <div className={styles.responseLoaderWrapper}>
            <div className={isCarDetails ? styles.circleCheck: styles.circleWarning}><span>{isCarDetails ? "✔" : "!"}</span></div>
            <div  className={styles.message}>{isCarDetails ? "There is no price difference!" : "No diff report available"}</div>
        </div>
    );
};

export default DiffReportResponseLoader;
