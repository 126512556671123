import React, {useState} from "react";
import styles from "./negoPriceOffers.module.scss";
import Popup from "../popup";
import RaJourneyDocumentationPopup from "../ra-journey-documentation-popup/component";
import DocumentStatusPopup from "../../components/document-status-popup";
import Button from "../../components/shared/button";
const RaJourney = (props) => {
  const {
    apiNoAccess,
    ResetApiNoAccessConnect,
    data: {documentStages:{tokenLabel='', docSuccessCount=0, documents=[]}={}, appointmentId=''}={}
  } = props;
  const [allocateOpen, setAllocateOpen] = useState(false);
  const isRAAssistance = documents.filter((item) => item.status === 3).length > 0;
  const [documentStatusOpen, setDocumentStatusOpen] = useState(false);
    
  const onDocumentStatusOpen = () => {
    ResetApiNoAccessConnect();
    setDocumentStatusOpen(true);
  };
  const onDocumentStatusClose = () => {
    setDocumentStatusOpen(false);
  };
  const onAllocateOpen = () => {
    ResetApiNoAccessConnect();
    setAllocateOpen(true);
  };
  const onAllocateClose = () => {
    setAllocateOpen(false);
  };
  return (
    <div className={styles.raJourney}>
      <ul className={styles.document}>
        {documents.map((item, index)=> {
          return (
            <li key={`doc-key-${item.key}-${index}`} className={item.status === 2 ? styles.green : item.status === 3 ? styles.red : null}>
              {item.label}
            </li>
          );
        })}
      </ul>
      <div className={styles.moreCta}>
        {isRAAssistance && <p className={styles.info}>Need RA Assistance</p>}
        <button onClick={onAllocateOpen}>Know More</button>
        <Popup isOpen={allocateOpen && !apiNoAccess} close={onAllocateClose}>
          <RaJourneyDocumentationPopup 
            onClose={onAllocateClose} 
            tokenLabel={tokenLabel}  
            docSuccessCount={docSuccessCount} 
            appointmentId={appointmentId}
          />
        </Popup>
      </div>
      <div className={styles.viewDocumentsCta}>
        <Button onClick={onDocumentStatusOpen} classNames="smallCta" ctaText="View Documents"/>
        <Popup isOpen={documentStatusOpen && !apiNoAccess} close={onDocumentStatusClose}>
          <DocumentStatusPopup appointmentid = {appointmentId} onClose = {onDocumentStatusClose} isNego={true}/>
        </Popup>
      </div>
    </div>
        
  );
};

export default RaJourney;