const partMap = ({ additionalInfo, subParts }) => {
  if(subParts) {
    return [].concat(
      ...Object.values(subParts)
        .map(({ additionalInfo }) => {
          return (additionalInfo || []).map(({ image }) => {
            return image;
          });
        })
    );
  }
  if(additionalInfo) {
    return [].concat(
      ...Object.values(additionalInfo)
        .filter(({ image }) => {
          return !!image;
        })
    );
  }
  return [];
};

const parseDefectedImages = (node) => {
  return [].concat(
    ...Object
      .values(node)
      .map(({ elements }) => 
        Object.values(elements)
      )
  ).filter(({ additionalInfo, subParts, value }) => (!!additionalInfo || !!subParts) && value.toLowerCase() === "no" )
    .map(partMap); 
};

export default (currentCarDetails) => {
  const { allMedia, others } = currentCarDetails;
  const exteriorImages = Object.values((allMedia.exteriorTyres || {}).mainImages || {});
  const interiorImages = Object.values((allMedia.electricalsInterior || {}).mainImages || {});
  const engineImages = Object.values((allMedia.engineTransmission || {}).mainImages || {});
  const defectImages = [].concat(...parseDefectedImages(others || {}));
  const allImages = [...exteriorImages, ...interiorImages, ...engineImages, ...defectImages];

  return {
    exteriorImages,
    interiorImages,
    engineImages,
    defectImages,
    allImages
  };
};