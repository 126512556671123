/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import H2 from "../h2/component";
import styles from "./listing-c2c.module.scss";
import PaiSlider from "../pai-slider";
import Button from "../../components/shared/button";
import IncrementalTextbox from "../incremental-textbox/component";
import priceFormatter from "../../utils/helpers/price-formatter";
import { AppointmentService } from "../../service";
import { showToastMessages, getFromLocalStorage } from "../../utils/helper";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { LOCAL_STORAGE_KEYS } from "../../utils/app-constants";
import { geListOtpMoc } from "../../utils/mocApi";

const useStyles = makeStyles(theme => {
  return {
    textField: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      fontSize: '10px',
      width: '100%'
    }
  };
});

const ListingC2c = ({ appointmentId, c24Quote, close }) => {

  const [inputText, setInputText] = useState("");
  const [closurePrice, setClosurePrice] = useState(0);
  const [values, setValues] = useState([0]);
  const [update, setUpdate] = useState([0]);
  const [domain, setDomain] = useState([]);
  const [showSlider, setShowSlider] = useState(false);
  const step = 1000;
  const sliderData = null;
  const priceAcceptance = [];
  const classes = useStyles();
  const [inputTextError, setInputTextError] = useState(false);

  useEffect(() => {
    if (appointmentId > 0) {
      const payload = {
        "appointmentId": appointmentId,
        "c24Quote": c24Quote
      };
      AppointmentService.getC2CBoundyValues(payload)
        .then(response => {
          const { data: { detail: { minPrice, maxPrice } } } = response;
          if (minPrice > 0 && maxPrice > 0) {
            setDomain([minPrice, maxPrice]);
            setShowSlider(true);
          }
        })
        .catch(error => {
          console.log('Error getting c2cboundryValues', error);
        });

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onChangeClick = (type) => {
    const newPrice = type === "increment" ? closurePrice + step : closurePrice - step;
    setClosurePrice(newPrice);
    setValues([newPrice]);
  };
  const onUpdateSlider = update => {
    const currentValue = update[0];
    setUpdate(update);
    setClosurePrice(currentValue);
  };

  const onChangeSlider = values => {
    setValues(values);
  };
  const onChangeText = (e) => {
    const value = e.target.value && e.target.value.replace(/,/g, '') ? priceFormatter(Number(e.target.value.replace(/,/g, ''))) : "";
    const price = Number(value.replace(/,/g, ''));
    setClosurePrice(price);
    setInputText(value);
  };
  const ListCar = () => {
    const localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
    const loggedInEmail = localData ? JSON.parse(localData)[LOCAL_STORAGE_KEYS.EMAIL] : "";
    const actualRole = localData ? JSON.parse(localData)[LOCAL_STORAGE_KEYS.ORIGIN_ROLE] : "";
    const cPrice = closurePrice;

    // const payload = {
    //   "appointmentId": appointmentId,
    //   "listingPrice": cPrice,
    //   "userEmail": loggedInEmail,
    //   "userActualRole": actualRole,
    //   "prevListingPrice": 0
    // };
    const payload = {
      "appointmentId": appointmentId,
      "listingPrice": cPrice,
    };
    AppointmentService.sendListCarOtp(payload)
      .then((resp) => {
        const { data: { detail, title } } = resp;
        showToastMessages(resp && resp.data ? title : "Listing Price updated successfully");
        close(detail, cPrice);
      })
      .catch((resp) => {
        resp = geListOtpMoc();
        const { data: { detail } } = resp;
        close(detail, cPrice);

        // showToastMessages(resp.title, false);
        //close();
      });
  };
  const closeListCar = () => {
    close(null, -1);
  };
  return (
    <div className={styles.listingCarPopup}>
      <div className={styles.sliderWrapper}>
        <p className={styles.clousrePrice}>Listing Price</p>
        {showSlider && <IncrementalTextbox
          value={priceFormatter(closurePrice)}
          onChangeClick={onChangeClick}
        />
        }
        {!showSlider &&
          <div>
            <TextField
              error={inputTextError}
              label="Listing Price"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              onChange={onChangeText}
              value={inputText}
              inputProps={{
                maxLength: 9,
              }}
              InputLabelProps={{ style: {} }}
            />
          </div>
        }
      </div>

      {showSlider &&
        < PaiSlider
          domain={domain}
          onChange={onChangeSlider}
          onUpdate={onUpdateSlider}
          values={values}
          update={update}
          step={step}
          sliderData={sliderData}
          prices={priceAcceptance}
        />
      }
      <div className={styles.ctaButton}>
        <Button
          classNames="blueButton"
          ctaText={"SUBMIT"}
          onClick={ListCar}
        />
        <Button
          classNames="cancelCta"
          ctaText="Cancel"
          onClick={() => closeListCar()}
        />
      </div>
    </div>
  );
};

export default ListingC2c;
