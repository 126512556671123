/* eslint-disable indent */
import React from "react";
import styles from "./profile.module.scss";
import Back from "../../components/back";
import H2 from "../../components/h2/component";
import { LOCAL_STORAGE_KEYS, ROLE_KEYS } from "../../utils/app-constants";
import { getFromLocalStorage } from "../../utils/helper";
import Rating from "../../components/images/star.svg";
const Profile = () => { 
	// no proper code. can be used through better way
    const localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
    const role = localData ? JSON.parse(localData)[LOCAL_STORAGE_KEYS.ROLE] : "";
    const email = localData ? JSON.parse(localData)[LOCAL_STORAGE_KEYS.EMAIL] : "";
    const firstName = localData ? JSON.parse(localData)[LOCAL_STORAGE_KEYS.FIRST_NAME] : "";
    const lastName = localData ? JSON.parse(localData)[LOCAL_STORAGE_KEYS.LAST_NAME] : "";
    const mobile = localData ? JSON.parse(localData)[LOCAL_STORAGE_KEYS.MOBILE] : "";
    let rating = localData ? JSON.parse(localData)[LOCAL_STORAGE_KEYS.RATING] : "";
    rating = !!rating ? rating : 0;
    let stores = localData ? JSON.parse(localData)[LOCAL_STORAGE_KEYS.STORE_NAME] : "";
    const storeName = !!stores && stores.length === 1 ? stores[0] : null;
    const profilePicName = (!!firstName ? firstName.charAt(0).toUpperCase() : "") + (!!lastName ? lastName.charAt(0).toUpperCase() : "");

    const getRole = () => {
        switch (role.toLowerCase()) {
            case ROLE_KEYS.RA:
                return "Retail Associate";
            case ROLE_KEYS.RM:
                return "Retail Manager";
            case ROLE_KEYS.HOSTESS:
                return "Hostess";
            default:
                return role;
        }
    };
    const getRatingColor = () => {
        if (rating === 0) return "noRating";
        if (rating < 3 && rating > 0) return "profileRed";
        if (rating >= 3 && rating < 4) return "profileGreen";
        if (rating >= 4) return "profileYellow";
    };

    const getRatingText = (rating) => {
        let ratingText = Math.round(rating);
        if (rating === 0) {
            ratingText = "N/A";
        }
        return ratingText;
    };

    return (
      <div className={styles.profileWrapper}>
        <div className={styles.profileHeading}>
          <div className={styles.back}>
            <Back />
          </div>
          <H2 text={"profile"} />
        </div>
        <div className={styles.profileMainWrapper}>
          <div className={styles.profileContent}>
            <div className={styles.profilePic}>
              <p>{profilePicName}</p>
            </div>
            <div className={styles.userNameWrapper}>
              <p className={styles.userName}>{firstName} {lastName}</p>
            </div>
            <div className={styles.profileLeftSection}>
              <p className={styles.proEmail}>Email</p>
              <p className={styles.proPhone}>Phone</p>
              {!!storeName && <p className={styles.proStore}>Store</p>}
              <p className={styles.proRating}>Rating</p>
              <p className={styles.proRole}>Role</p>
            </div>
            <div className={styles.profileInfo}>
              <p>{email}</p>
              <div className={styles.text}>
                {mobile}
              </div>
              {!!storeName && <p>{storeName}</p>}
              <div className={`${styles.profileRating} ${styles[getRatingColor(rating)]}`}>
                <img src={Rating} alt="Rating" />
                <span className={styles.ratingText}>{getRatingText(rating)}</span>
              </div>
              <p>{getRole()}</p>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Profile;
