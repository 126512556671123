import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PaymentPrStatus from "./component";
import {resetApiNoAccess} from "../hoc-access-controller/actions";
const mapStateToProps = ({
  hocAccess : {
    apiNoAccess
  }
}) => ({
  apiNoAccess
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  ResetApiNoAccessConnect: resetApiNoAccess
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPrStatus);
