import React, { useState } from "react";
import { PAGE_HEADING } from "../../utils/app-constants";
import styles from "./lead-activity-feed.module.css";
import H2 from "../h2/component";
import LeadActivityFeedTabs from "../../components/lead-activity-feed-tabs";
import LeadActivities from "../../components/lead-activities";
import ChangeLogActivities from "../../components/change-log-activities";
import { LEAD_ACTIVITY_FEED } from "../../utils/app-constants";

const LeadActivityFeed = ({ appointmentId }) => {

  const [showLeadActivity, setShowLeadActivity] = useState(true);

  const setActivityTabs = (tabId) => {
    if (tabId === LEAD_ACTIVITY_FEED.Call_Log.key) {
      setShowLeadActivity(true);
    }
    if (tabId === LEAD_ACTIVITY_FEED.Change_Log.key) {
      setShowLeadActivity(false);
    }
  };
  return (
    <div className={styles.leadActivityFeed}>
      <H2 text={PAGE_HEADING.ACTIVITY_FEED} />
      <LeadActivityFeedTabs setActivityTabs={setActivityTabs} />
      <div className={styles.fixedWrap}>
        <div className={styles.scrollwrapper}>
          {showLeadActivity && <LeadActivities appointmentId={appointmentId} />}
          {!showLeadActivity && <ChangeLogActivities appointmentId={appointmentId} />}
        </div>
      </div>
    </div>
  );
};

export default LeadActivityFeed;
