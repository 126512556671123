import React, {useEffect, useState} from "react";
import { NUMBERS } from "../../utils/app-constants";
import CountdownTimer from "../shared/countdown-timer";
import { getTimeDifference, hasTimeElapsed } from "../../utils/helper";
import MinuteTimer from "../shared/minute-timer";
import SecondsTimer from "../shared/seconds-timer";
import styles from "./auction-status.module.css";

const AuctionStatus = ({ rowDetails,appointments }) => {

  const { auctionStatus, auctionEndTime, stageChangedTime } = rowDetails;
  const { data } = appointments;
  const [hasElapsed, setHasElapsed] = useState(false);

  useEffect(() => {
    if (auctionStatus === NUMBERS.ZERO) {
      let interval = setInterval(() => {
        const hasReachedLimit = hasTimeElapsed(stageChangedTime, NUMBERS.TEN);
        if (hasReachedLimit) {
          setHasElapsed(hasReachedLimit);
          clearInterval(interval);
        };
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getAuctionStatus = () => {
    switch (auctionStatus) {
      case NUMBERS.ZERO:
        return <span className={hasElapsed ? styles.highlight : ""} >TP not set</span>;
      case NUMBERS.ONE: {
        if (auctionEndTime) {
          const initialTime = getTimeDifference(new Date(), new Date(auctionEndTime));
          return (
            <CountdownTimer initialTime={initialTime} >
              <MinuteTimer /> {`min : `}
              <SecondsTimer /> sec
            </CountdownTimer>
          );
        } else {
          return <>End-Time not received</>;
        }
      }
      case NUMBERS.TWO:
        return <span>Suspended</span>;
      case NUMBERS.THREE:
        return <span className={styles.buyNow}>{'Instant Sell Lead'}</span>; 
      default:
        return <></>;
    }
  };

  return (
    getAuctionStatus()
  );
};

export default AuctionStatus;
