import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styles from "./document-status-popup.module.scss";
import H2 from "../h2/component";
// import { getAppointmentDocumentDetail } from "../../utils/mocApi";
import { AppointmentService } from "../../service";
import Loader from "../../components/shared/loader";
import {updateApiNoAccess} from "../../components/hoc-access-controller/actions";
const nego_doc_types = ['KYC_DOCUMENTS', 'LOAN_DOCUMENTS'];
const DocumentStatusPopup = ({ appointmentid, isNego = false }) => {
  const [documents, setDocuments] = useState();
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setIsLoading(true);
    AppointmentService.getAppointmentDocumentStatusDetails(appointmentid)
      .then(response => {
        const docs = response.data.detail;
        let cats = [...new Set(docs.map(item=> item.category))];
        if (isNego) {
          cats = cats.filter((item)=> nego_doc_types.includes(item));
        }
        setCategories(cats);
        setDocuments(docs);
      }).catch((error)=> {
        dispatch(updateApiNoAccess(error));
      }).finally(()=>{
        setIsLoading(false); 
      });
  }, []);

  return (
    <>
      {isLoading ? 
        <Loader /> :
        <div className={styles.documentStatusPopup} >
          <H2 text={"Document Status"} />
          <div className={styles.documentStatusCard}>
            {
              categories.map(cat => {
                if (!!cat) {
                  const cat_documents = documents.filter((item)=> item.category === cat);
                  if (!!cat_documents && cat_documents.length> 0) {
                    return (
                      <ul>
                        <span className={styles.header}>{cat.replace('_',' ')}</span>
                        {cat_documents.map((item, index)=>{
                          return (
                            <li key={`key-${index}`}>
                              <span> {item.name}
                              </span>
                              {!!item.uploads && item.uploads.length > 0 ? 
                                <p className={styles.recieved}>{"Submitted"}</p>:
                                <p className={styles.pending}>{"Not Started"}</p>}
                            </li>
                          );
                        })}
                      </ul>
                    );
                  }
                }
              })
            }
          </div>
        </div>}
    </>
  );
};

export default DocumentStatusPopup;
