import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CustomerJourneyCard from "./component";
import { fetchCustomerOfferDetails } from "../../pages/customer-offer-price/actions";

const mapStateToProps = ({
  negotiations,
  customerOffer
}) => ({
  negotiations,
  customerOffer
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchCustomerOfferDetailsConnect: fetchCustomerOfferDetails
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CustomerJourneyCard);
