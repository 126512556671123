import React from "react";
import styles from "./congratulations-price.module.scss";
import priceFormatter from "../../utils/helpers/price-formatter";

const CongratulationsPrice = ({carData, nego, isMarketVisible, marketPrice}) => {
  return (
    <div className={styles.congratulationsPrice}>
      <div className={styles.congratulations}>
        <h4>Congratulations, {nego?.customerName || '...'} !</h4>
        <h5>You’ve got the CARS24 assured price</h5>
      </div>
      <div className={styles.taglist}>
        <ul>
          <li>Best Price</li>
          <li>Sell Car in One Visit</li>
          <li>FREE! RC Transfer</li>
          <li>Instant Payment</li>
        </ul>
      </div>
      <div className={styles.marketPriceSection}>
        <div className={styles.priceSection}>
          <h3>₹  {priceFormatter(nego?.c24Quote) || 'Loading...'}</h3>
          {isMarketVisible && <p><span>{priceFormatter(nego?.c24Quote - marketPrice)} above</span> Market Price </p>}
        </div>
        <p className={styles.bargains}>Bargains are a hassle, and we add up the most so you don't have to exert yourself. That's why, our inventory prices are <span>Non-negotiable</span> and the <span>Best available.</span></p>
      </div>
    </div>
  );
};

export default CongratulationsPrice;
