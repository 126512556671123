import React, { useEffect, useState } from "react";
import styles from "./calendar-range.module.css";
import moment from "moment";
import CalendarPick from "../calendar-pick";

const CalendarRange = ({
  resetRangeByKeyConnect,
  updateFilterQueryConnect
}) => {
  const [toggle, setToggle] = useState(false);
  const [fromDate, setFromDate] = useState(moment(new Date()).subtract(3, 'days').toDate());
  const [toDate, setToDate] = useState(moment(new Date()).subtract(0, 'days').toDate());

  useEffect(() => {
    const range = {
      from: moment(fromDate).format('YYYY-MM-DD').toString(),
      to: moment(toDate).format('YYYY-MM-DD').toString(),
    };
    resetRangeByKeyConnect("range", range);
    updateFilterQueryConnect(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate]);

  return (
    <div className={styles.calenderWrapper}>
      <div className={styles.calanderFormWrapper}>
        From <p onClick={() => { setToggle(!toggle); }} className={styles.calanderForm}>{
          `${moment(fromDate).format('DD/MM/YYYY').toString()} - ${moment(toDate).format('DD/MM/YYYY').toString()}`}
        </p>
      </div>
      <div className={styles.calander}>
        {
          toggle && <CalendarPick
            fromDate={fromDate.toDateString()}
            toDate={toDate.toDateString()}
            setFromDate={setFromDate}
            setToDate={setToDate}
            setToggle={setToggle}
          />
        }

      </div>
    </div>
  );
};

export default CalendarRange;
