import React from "react";
import styles from "./sell-online-list.module.scss";
import SellOnlineCard from "../../components/sell-online-card";

const SellOnlineList = ({data, scrollRef, reloadAppointmentData, resetListing}) => {

  return (
    <div className={styles.sellOnlineList}>
      {!!data && data.map((item)=> {
        return (
          <>
            <SellOnlineCard
              rowDetails={item} 
              scrollRef={scrollRef}
              reloadAppointmentData={reloadAppointmentData}
              resetListing={resetListing}
            />
          </>
        );
      })}
    </div>
  );
};

export default SellOnlineList;
