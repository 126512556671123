import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  headerRowFilterQuery: ""
};

export const updateHeaderRowFilterQuery = (state = INITIAL_STATE, { headerRowFilterQuery }) => {
  return { ...state, headerRowFilterQuery };
};

export const HANDLERS = {
  [Types.UPDATE_HEADER_ROW_FILTER_QUERY]: updateHeaderRowFilterQuery

};

export default createReducer(INITIAL_STATE, HANDLERS);
