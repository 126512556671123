import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LeadDetail from "./component";
import withB2BLayout from "../../../b2b/components/layout/component";
import { fetchLeadDetail, resetLeadDetail, fetchSaleonlineQuestions, LoadingLeadDetail } from "./actions";
import { fetchDealerCityList } from "../lead-procurement/actions";

const mapStateToProps = ({
  leadProcurementData: {
    dealerCitylist
  },
  lead: {
    isLoading,
    leadDetail,
    saleonlineQuestions,
    offeredPrice
  }
}
) => ({
  isLoading,
  dealerCitylist,
  leadDetail,
  saleonlineQuestions,
  offeredPrice
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  FetchLeadDetailConnect: fetchLeadDetail,
  ResetLeadDetailConnect: resetLeadDetail,
  FetchSaleonlineQuestionsConnect: fetchSaleonlineQuestions,
  fetchDealerCityListConnect: fetchDealerCityList,
  LoadingLeadDetailConnect: LoadingLeadDetail,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withB2BLayout(LeadDetail, false));
