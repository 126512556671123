import React from "react";
import styles from "./location-picker.module.scss";
import LocationSearch from './location-search';
const mapType = 'googlemap';
const locationType = 'place';
const LocationPicker = ({setCustomLocation, setIsClickComplete}) => {
  const onLocationChange = async ({coords, label, pincode, formattedAddress}) => {
	setIsClickComplete(true);
    setCustomLocation({coords, label, pincode, formattedAddress});
  };
  return (
    <div className={styles.locationWrapper}>
      <LocationSearch
        onLocationChange={onLocationChange}
        locationType={locationType}
        mapType={mapType}
      />
    </div>
  );
};

export default LocationPicker;
