import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CustomerOfferPriceJourneyNew from "./component";
import { fetchLeadDetails, resetLeadDetails } from "../../pages/lead-detail/actions";
import { preCheckRaisePAI, fetchNegotiations, resetNegotiations } from "../../components/negotiation-list/actions";
// import { fetchCustomerOfferDetails } from "../../pages/customer-offer-price/actions";
import { fetchCustomerOfferDetails } from "../../pages/customer-offer-price/actions";

const mapStateToProps = ({
  leadDetails,
  negotiations,
  customerOffer,
  similarTransactedCar
}) => ({
  leadDetails,
  negotiations,
  customerOffer,
  similarTransactedCar
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchLeadDetailsConnect: fetchLeadDetails,
  resetLeadDetailsConnect: resetLeadDetails,
  preCheckRaisePAIConnect: preCheckRaisePAI,
  fetchNegotiationsConnect: fetchNegotiations,
  resetNegotiationsConnect: resetNegotiations,
  fetchCustomerOfferDetailsConnect: fetchCustomerOfferDetails
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CustomerOfferPriceJourneyNew);