import Types from "./types";
import {AppointmentService} from "../../service";

// FETCH_MAKE
// FETCH_MAKE_SUCCESS
// FETCH_MAKE_FAILURE
// FETCH_ODOMETER
// FETCH_ODOMETER_SUCCESS
// FETCH_ODOMETER_FAILURE
// FETCH_STATE
// FETCH_STATE_SUCCESS
// FETCH_STATE_FAILURE

const fetchMake = ()=> dispatch => {
  return AppointmentService.getmake()
    .then(response => {
      const { data: { detail } } = response;
      dispatch(fetchMakeSuccess(detail));
      return response;
    })
    .catch(error => dispatch(fetchMakeFailure(error)));
};

const fetchMakeSuccess =(data)=>({
  type: Types.FETCH_MAKE_SUCCESS,
  data
});

const fetchMakeFailure =(error)=>({
  type: Types.FETCH_MAKE_FAILURE,
  error
});

const fetchOdometer = ()=> dispatch => {
  return AppointmentService.getOdometer()
    .then(response => {
      const { data: { detail } } = response;
      dispatch(fetchOdometerSuccess(detail));
      return response;
    })
    .catch(error => dispatch(fetchOdometerFailure(error)));
};

const fetchOdometerSuccess =(data)=>({
  type: Types.FETCH_ODOMETER_SUCCESS,
  data
});

const fetchOdometerFailure =(error)=>({
  type: Types.FETCH_ODOMETER_FAILURE,
  error
});

const fetchState = ()=> dispatch => {
  return AppointmentService.getState()
    .then(response => {
      const { data: { detail } } = response;
      dispatch(fetchStateSuccess(detail));
      return response;
    })
    .catch(error => dispatch(fetchStateFailure(error)));
};

const fetchStateSuccess =(data)=>({
  type: Types.FETCH_STATE_SUCCESS,
  data
});

const fetchStateFailure =(error)=>({
  type: Types.FETCH_STATE_FAILURE,
  error
});

export {
  fetchMake,
  fetchOdometer,
  fetchState
};
