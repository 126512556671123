/* eslint-disable indent */
import React, {useState} from "react";
import styles from "./payment-pr-status.module.scss";
import Button from "../../components/shared/button";
import Popup from "../popup/component";
import DocumentStatusPopup from "../../components/document-status-popup";
import priceFormatter from "../../utils/helpers/price-formatter";
import { AppointmentService } from "../../service";
import { showToastMessages } from "../../utils/helper";

const PaymentPrStatus = ({ rowDetails, ResetApiNoAccessConnect, apiNoAccess }) => {
    const [documentStatusOpen, setDocumentStatusOpen] = useState(false);
    const [isVerifyBid, setIsVerifyBid] = useState(false);
    const { prStatusSection, appointment, adminSummaryUrl, c24Quote, c24quotExpiryTime, showVerifyBidCTA } = rowDetails || {};
    const { status, statusTag, isBidRejected } = prStatusSection || {};
    const ocbValidDate = new Date(c24quotExpiryTime) === "Invalid Date" ? "" : new Date(c24quotExpiryTime).toLocaleDateString('en-in', {
        month: 'short',
        day: 'numeric'
    });
    const getStatusClass = (statusTag) => {
        let imgClass = "prInitialised";
        switch (statusTag) {
            case "PR_INITIALIZED":
                imgClass = "prInitialised";
                break;
            case "PR_SUBMITTED":
                imgClass = "prSubmitted";
                break;
            case "RETAIL_PR_APPROVED":
                imgClass = "prApproved";
                break;
            case "PR_APPROVED":
                imgClass = "prApproved";
                break;
            case "PR_REJECTED":
                imgClass = "prRejected";
                break;
            default:
                imgClass = "prInitialised";
                break;
        }
        return imgClass;
    };
    
    const onDocumentStatusOpen = () => {
        ResetApiNoAccessConnect();
        setDocumentStatusOpen(true);
    };
    const onDocumentStatusClose = () => {
        setDocumentStatusOpen(false);
    };
    const openAdminUrl = () => {
        window.open(adminSummaryUrl, '_blank', 'noopener');
    };
    const onVerifyBid = () => {
        setIsVerifyBid(true);
        const payload = {
            "appointmentId": appointment,
            "action":"verify"
        };
        AppointmentService.verifyBid(payload).then((resp)=>{
            showToastMessages(resp.detail, true);
        }).catch((err)=>{
            console.log(err);
            showToastMessages(err.detail || err, false);
        }).finally(()=>{
            setIsVerifyBid(false);
        });
    };
    return (
      <div className={styles.paymentPrStatus}>
        <div className={styles.prInitialisedWrapper}>
          <p className={`${styles[getStatusClass(statusTag)]}`} onClick={openAdminUrl}>{status}</p>
        </div>
        {
                (isBidRejected === true) &&
                <div className={styles.bidRejected}>
                  <p className={styles.textbid}>{isBidRejected ? "Bid Rejected" : ""}</p>
                </div>
            }
        <div className={styles.validText}>
          <strong>C24 Quote: {priceFormatter(c24Quote, true)}</strong>
          {!!c24quotExpiryTime && <p>(Valid till {ocbValidDate})</p>}
        </div>
        {!!showVerifyBidCTA && <div className={styles.ctaWrapperBid}>
          <Button onClick={onVerifyBid} classNames="blueButton" ctaText="Verify Bid"  disabled={isVerifyBid}/>
        </div>}
        <div className={styles.ctaWrapper}>
          <Button onClick={onDocumentStatusOpen} classNames="smallCta" ctaText="View Documents"/>
        </div>
        <Popup isOpen={documentStatusOpen && !apiNoAccess} close={onDocumentStatusClose}>
          <DocumentStatusPopup appointmentid = {appointment} onClose = {onDocumentStatusClose}/>
        </Popup>
      </div>
    );
};

export default PaymentPrStatus;