import React from 'react';
import styles from './styles.module.css';

const SelectDropdown = ({ className, value, name, required = false, placeholder = "", defaultValue = "", hasError = false, isDisabled = false, isMulti = false, onChange, optionsList }) => {
  const options = optionsList && optionsList.map((option, index) => {
    return <option key={'option_' + index} value={option.value}>{option.label}</option>;
  });
  return (
    <div  className={styles.dropdown}>
      <select
        required={required}
        className={className ? className : "form-control"}
        name={name}
        onChange={e => onChange(e)}
        value={value || ''}
        disabled={isDisabled}
        placeholder={placeholder}
      >
        <option hidden value="">{placeholder}</option>
        {options && options}
      </select>
      <span>{placeholder}</span>
    </div>
  );
};

export default SelectDropdown;