import React from "react";
import styles from "./ra-offer-price-analyzer.module.scss";
import carPriceAnalyzer from "../../common/images/car-price-analyzing.svg";
import priceAnalyzer from "../../common/images/analyzer.svg";
const RaOfferPriceAnalyzer = () => {
  return (
    <div className={styles.wrapper}>
      <div>
        <img src={priceAnalyzer} alt={'check'}/>
      </div>
    </div>
  );
};

export default RaOfferPriceAnalyzer;
