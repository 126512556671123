import Types from "./types";

const resetLeadMenuByKey = (key, value) => ({
  type: Types.RESET_LEAD_MENU_BY_KEY,
  data: { key, value }
});

export {
  resetLeadMenuByKey
};
