import React, {useEffect, useState} from "react";
import { Checkbox, InputLabel, MenuItem, FormControl, Select, FormHelperText } from "@mui/material";

const MultiSelect = ({
  id,
  label,
  options,
  placeholder,
  value,
  handleChange = () => {},
  isError = false
}) => {
  const [selectedValue, setSelectedValue] = useState([]);

  useEffect(() => {
    if(value) {
      setSelectedValue(value);
    }
  }, [value]);

  const onChange = ({target: {value}}) => {
    setSelectedValue(value);
    handleChange(value);
  };

  return (
    <FormControl error={isError} fullWidth size="small">
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        placeholder={placeholder}
        label={label}
        id={id}
        value={selectedValue}
        onChange={onChange}
        multiple
      >
        {
          options.map(item => (
            <MenuItem key={item.value} value={item.value}>
              <Checkbox checked={selectedValue.indexOf(item.value) > -1} />
              {item.label}
            </MenuItem>
          ))
        }
      </Select>
      {
        isError && <FormHelperText>Value is required. Can't be empty.</FormHelperText>
      }
    </FormControl>
  );
};

export default MultiSelect;
