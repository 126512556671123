import React, { useState, useEffect } from "react";
import styles from "./nego-lead-filters.module.scss";
import SelectComponent from "../../components/shared/select";

const NegoLeadFilters = (props) => {
  const {negotiations, negotiationFilter, updateFilterQueryConnect} = props;
  const { filterQuery } = negotiationFilter;
  const [customerFeedbacks, setCustomerFeedbacks] = useState([]);
  const [inspectionTypes, setInspectionTypes] = useState([]);
  const [retailFeedbacks, setRetailFeedbacks] = useState([]);

  useEffect(()=>{
    const {filters, isLoading} =  negotiations;
    if (!isLoading) {
      const {customer_feedback ={}, inspection_type ={}, retail_feedback={}} = filters || {};
      const cfItems = customer_feedback && Object.keys(customer_feedback).map((item)=> {
        return {label: customer_feedback[item], value: item};
      });
      setCustomerFeedbacks(cfItems);
      const itItems = inspection_type && Object.keys(inspection_type).map((item)=> {
        return {label: inspection_type[item], value: item};
      });
      setInspectionTypes(itItems);
      const rfItems = retail_feedback && Object.keys(retail_feedback).map((item)=> {
        return {label: retail_feedback[item], value: item};
      });
      setRetailFeedbacks(rfItems);
    }
  },[negotiations]);

  const onUpdateFilterQuery = (key, value) => {
    let queryString = filterQuery;
    if (!!queryString && queryString.includes(key)){
      const params = queryString.split("&");
      if (params.length > 0){
        const remainingFilters = params.filter(item => !item.includes(key));
        const paramString = remainingFilters.join('&');
        queryString = paramString;
      }
    }
    if (!!value) {
      queryString = `${!!queryString ? queryString : ''}&${key}=${value}`;
    }
    updateFilterQueryConnect(queryString);
  };

  const onCustomerLeadStatusChange = (e) => {
    onUpdateFilterQuery("cust_response", e);
  };

  const onRALeadStatusChange = (e) => {
    onUpdateFilterQuery("ra_feedback", e);
  };

  const onInspectionTypeChange = (e) => {
    onUpdateFilterQuery("inspection_type", e);
  };

  return (
    <div className={styles.negoLeadFilters}>
      <SelectComponent
        placeholder={"CUSTOMER LEAD STATUS"}
        optionsList={customerFeedbacks}
        onChange={onCustomerLeadStatusChange}
      />
      <SelectComponent
        placeholder={"RA LEAD STATUS"}
        optionsList={retailFeedbacks}
        onChange={onRALeadStatusChange}
      />
      <SelectComponent
        placeholder={"INSPECTION TYPE"}
        optionsList={inspectionTypes}
        onChange={onInspectionTypeChange}
      />
    </div>
  );
};

export default NegoLeadFilters;
