import React, { useEffect, useState } from "react";
import DoucumnetUploadRto from "../../doucumnet-upload-rto";
import SelectComponent from "../select/component";
import SelectDropdown from "../SelectDropdown/SelectDropdown";
// import styles from "./anchor.module.css";
import styles from "./selectDocument.module.scss";

const SelectDocumentUpload = ({appointmentId, data, parent, docKey, allowedFileTypes, formReadOnly}) => {
  const [selectedDocType, setSelectedDocType] = useState('');
  useEffect(()=>{
    if (!!data && !!data.docs) {
      const keyMaps = Object.keys(data.docs);
      for (const doc of keyMaps) {
        let imageAvailable = false;
        const docPages = data.docs[doc];
        for (const page of docPages) {
          if (!!page.image) {
            imageAvailable = true;
            break;
          }
        }
        if (imageAvailable) {
          setSelectedDocType(doc);
          break;
        }
      }
    }
  },[]);
  return (
    <div className={styles.selectDocumentUpload}>
      <SelectDropdown
        key={`${parent}_${docKey}`}
        name={`docElement_${docKey}`}
        onChange={(e) => setSelectedDocType(e.target.value)}
        placeholder={data.title}
        optionsList={data.options.map((item) => {
          return {
            label: item,
            value: item
          };
        })}
        value={selectedDocType}
      />
      <ul>
        {
          !!selectedDocType && !!data.docs && data.docs[selectedDocType] && data.docs[selectedDocType].map((page, idx)=> {
            return (
              <li key={`key_${docKey}_${page}_${idx}`}>
                <DoucumnetUploadRto
                  appointmentId={appointmentId} 
                  docKey={selectedDocType} 
                  data={page} 
                  allowedFileTypes={allowedFileTypes} 
                  formReadOnly={formReadOnly}
                />
              </li>
            ); 
          })
        }
      </ul>
    </div>
  );
};

export default SelectDocumentUpload;
