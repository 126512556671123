import { createReducer } from "reduxsauce";
import Types from "./types";
import { GetQueryStringFromUrl, getUnique } from "../../utils/helper";
import sortByKey from "../../utils/helpers/sort-by-key";
// import { NUMBERS } from "../../utils/app-constants";

export const INITIAL_STATE = {
  isLoading: true,
  columns: [],
  page: 0,
  data: [],
  error: null,
  total: 0,
  appointmentState: "negotiation",
  sortKey: '',
  sortOrder: 'desc',
  filteredData: [],
  isFiltered: false,
  visitedFiltersCount: {
    pending: 0,
    inProgress: 0,
    total: 0
  },
  unAllocatedCount: 0,
  confirmRows: 0,
  nonConfirmRows: 0,
  loadMore: false,
  insuranceType:null,
  expiryCount: {
    nextday: 0,
    today: 0,
    lost: 0
  },
  assignReasons: [],
  prStatuses: [],
  retailAssociates: [],
  selectedRAs: [],
};

export const fetchAppointments = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};

export const fetchAppointmentsSuccess = (state = INITIAL_STATE, props ) => {
  const { data, filterQuery} = props;
  let _loadMore = true;
  const { columns, totalRows, result, totalPending, totalInProgress, notAllocated, confirmRows, nonConfirmRows, insuranceType,
    expires_next_day, expires_today, expires_lost, assignReasons, retailFilter, prStatusFilter, selectedRetail } 
        = data;
    
  const prStasues = prStatusFilter && prStatusFilter.map((item)=> {
    return {
      label: item.value,
      value: item
    };
  });
  const retails = retailFilter && retailFilter.map((item)=> {
    return {
      ...item,
      value: item.key,
      label: item.value,
    };
  });
  let selectedRetailKeys = [];
  retailFilter && selectedRetail && selectedRetail.split(",").forEach((item)=>
    retailFilter.find((ra) => {
      if (ra.value === item) {
        selectedRetailKeys.push(ra.key);
      }
    })
  );
  const visitedFiltersCount = {
    pending: totalPending || 0,
    inProgress: totalInProgress || 0,
    total: totalRows || 0
  };

  const expiryCount = {
    today: expires_today,
    nextday: expires_next_day,
    lost: expires_lost
  };

  let rowData = [...state.data, ...result];
  rowData = getUnique(rowData, 'appointment');
  if (!!filterQuery) {
    let sortColumn = GetQueryStringFromUrl(filterQuery, "sortColumn");
    let sortOrder = GetQueryStringFromUrl(filterQuery, "sortOrder");
    if (!!sortOrder && !!sortColumn) {
      rowData = sortByKey(rowData, sortColumn, sortOrder);
    }
  }
  if (!!rowData && rowData.length > 0 && (totalRows > rowData.length)) {
    _loadMore = true;
  }
  else {
    _loadMore = false;
  }
  return { ...state, columns, total: totalRows, data: rowData, isLoading: false, error: null, filteredData: [], 
    isFiltered: false, visitedFiltersCount, unAllocatedCount: notAllocated, confirmRows: confirmRows, 
    nonConfirmRows: nonConfirmRows, loadMore: _loadMore, insuranceType, expiryCount, assignReasons, 
    retailAssociates: retails || [], prStatuses: prStasues || [], selectedRAs: selectedRetailKeys};
};

export const fetchAppointmentsFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, error, data: [], isLoading: false, filteredData: [], isFiltered: false };
};

export const setAppointmentState = (state = INITIAL_STATE, { appointmentState }) => {
  return { ...state, appointmentState };
};

export const searchAndFilterAppointments = (state = INITIAL_STATE, { filteredData }) => {
  return { ...state, filteredData, isFiltered: true };
};

export const removeSearch = (state = INITIAL_STATE) => {
  return { ...state, isFiltered: false, filteredData: [] };
};

export const resetAppointments = () => {
  return INITIAL_STATE;
};

export const resetAppointmentByKey = (state = INITIAL_STATE, { data }) => {
  const { key, value } = data;
  return { ...state, [key]: value };
};
export const sortAppointmentByKey = (state = INITIAL_STATE, { data }) => {
  const { key, sortOrder } = data;
  let rowData = [...state.data];
  let newrowData = sortByKey(rowData, key, sortOrder);
  return { ...state, data: newrowData, sortKey: key, sortOrder: sortOrder };

};
export const resetSortOrderAndKey = (state = INITIAL_STATE, { data }) => {
  const { key, sortOrder } = data;
  return { ...state, sortKey: key, sortOrder: sortOrder };

};

export const HANDLERS = {
  [Types.FETCH_APPOINTMENTS]: fetchAppointments,
  [Types.FETCH_APPOINTMENTS_SUCCESS]: fetchAppointmentsSuccess,
  [Types.FETCH_APPOINTMENTS_FAILURE]: fetchAppointmentsFailure,
  [Types.SET_APPOINTMENT_STATE]: setAppointmentState,
  [Types.SEARCH_AND_FILTER_APPOINTMENTS]: searchAndFilterAppointments,
  [Types.REMOVE_SEARCH]: removeSearch,
  [Types.RESET_APPOINTMENTS]: resetAppointments,
  [Types.RESET_APPOINTMENT_BY_KEY]: resetAppointmentByKey,
  [Types.SORT_APPOINTMENTS_BY_KEY]: sortAppointmentByKey,
  [Types.RESET_SORT_ORDER_BY_KEY]: resetSortOrderAndKey
};

export default createReducer(INITIAL_STATE, HANDLERS);
