import React from "react";
import styles from "./customer-offer-price-popup.module.scss";
import H2 from "../../components/h2";
import NoResponse from "../../components/images/noResponse.svg";
import priceFormatter from "../../utils/helpers/price-formatter";
const CustomerOfferPricePopup = ({data}) => {
  const {dataAvailable = false, responseReceived, priceAccepted, reasonForAcceptance, cep} = data;
  return (
    <div className={styles.customerOfferPricePopup}>
      <H2 text="Customer Offer Price Response on Website" />
      {dataAvailable ? <div className={styles.customerResponce}>
        <ul>
          <li>
            <p>Response received?</p>
            <span>{responseReceived}</span>
          </li>
          <li>
            <p>Price Accepted?</p>
            <span>{priceAccepted}</span>
          </li>
          <li>
            <p>Reason for non-acceptance?</p>
            <span>{reasonForAcceptance}</span>
          </li>
          <li>
            <p>CEP</p>
            <span>{`₹ ${priceFormatter(cep, true)}`}</span>
          </li>
        </ul>
      </div> : <div className={styles.noResponse}>
        <img src={NoResponse} alt="noResponse" />
        <div>
          <p>No response received Yet!</p>
          <span>Customer has still not responded to the offered price.</span>
        </div>
      </div>}
    </div>
  );
};

export default CustomerOfferPricePopup;
