import React, { useState } from "react";
import styles from "./sell-online-card.module.scss";
import CarDetail from "../../components/car-detail";
import H4 from "../shared/h4/component";
import IconEdit from "../shared/icon-edit/component";
import Button from "../../components/shared/button";
import SellOnlineOurPrice from "../../components/sell-online-our-price";
// import CustomerLocationPopup from "../../components/customer-location-popup";
import Popup from "../../components/popup";
import FollowUpPopup from "../follow-up-popup";
import FollowUpHistory from "../follow-up-history";
import { dateToFromNow } from "../../utils/helper";
// import HiLocationDetail from "../hi-location-detail/component";
import CustomerAppointmentPopup from "../../components/customer-appointment-popup";

const SellOnlineCard = ({rowDetails, scrollRef, reloadAppointmentData, resetListing}) => {
  const [sellOnlineOpen, setSellOnlineOpen] = useState(false);
  const [editAddressOpen, setEditAddressOpen] = useState(false);
  const [followUpOpen, setFollowUpOpen] = useState(false);
  const {ctaAccessFlag, lastCallStatus, lastCallTime, followUpMessage, followUpRemarks, followUpTime, appointment, zoneName, customerAddress, exophone, sellerIntent, onlineApptDate = {} } = rowDetails;
  const {date='', time=''} = onlineApptDate;
  const onSellOnlineOpen = () => {
    setSellOnlineOpen(true);
  };

  const onSellOnlineClose = () => {
    setSellOnlineOpen(false);
  };

  const onEditAddressOpen = () => {
    setEditAddressOpen(true);
  };

  const onEditAddressClose = () => {
    setEditAddressOpen(false);
  };

  const onFollowUpOpen = () => {
    setFollowUpOpen(true);
  };
  const onFollowUpClose = () => {
    setFollowUpOpen(false);
  };

  const getCallStatusStyleClass = () =>{
    let scCallStatus = "";
    switch(lastCallStatus){
      case "Call connected":
        scCallStatus = "callConnected";
        break;
      case "Call not connected":
        scCallStatus = "callnotConnected";
        break;
      case "Not called":
        scCallStatus = "notCalled";
        break;
      default:
    }
    return scCallStatus;
  };

  const getFollowUpTime = (date) => {
    const momentDate = dateToFromNow(date);
    return momentDate;
  };

  return (
    <div className={styles.sellOnlineCard}>
      <div className={styles.sellCard}>
        <div className={styles.carDetails}>
          <CarDetail data={rowDetails} isLostLead={false} isSaleOnline={true} updateDataList={reloadAppointmentData}/>
        </div>
        {/* followup section */}
        <div className={styles.priceNegotiaton}>
          {!!sellerIntent && sellerIntent > 0 && <>
            {!!lastCallStatus && <div className={styles.qualityCallWrapper}>
              <div className={lastCallStatus && `${styles[getCallStatusStyleClass()]}`}>
                <span>{lastCallStatus}</span>
                <span>{lastCallStatus !== "Not called" ? lastCallTime : ""}</span>
              </div>
            </div>}
            <div className={styles.textIconWrap}>
              <H4 text={`Follow up - ${followUpMessage ? followUpMessage : 'N/A'}`} />
              {!ctaAccessFlag && <>
                <IconEdit onClick={onFollowUpOpen} />
                <Popup isOpen={followUpOpen} close={onFollowUpClose}>
                  <div className={styles.followUpHistoryWrapper}>
                    <div className={styles.followupWrapper}>
                      <FollowUpPopup rowDetails={rowDetails} onCancelClick={onFollowUpClose} scrollRef={scrollRef} isSellOnline={true} onResetParent={resetListing}/>
                    </div>
                    <div className={styles.followuphistory}>
                      <FollowUpHistory appointmentId={appointment}/>
                    </div>
                  </div>
                </Popup>
              </>}
              {followUpTime && <span className={styles.dayTime}>{getFollowUpTime(followUpTime)}</span>}
            </div>
            {followUpRemarks && followUpRemarks.trim() && <p className={styles.remarks}>{followUpRemarks}</p>}
            {/* Address section */} 
            {!customerAddress &&<>
              <div className={styles.ctaWrapper}><Button onClick={onSellOnlineOpen} ctaText="SET APPOINTMENT" classNames="saveCta" disabled={!exophone} /></div>
              <Popup isOpen={sellOnlineOpen} close={onSellOnlineClose}>
                {/* <CustomerLocationPopup onClose={onSellOnlineClose} rowDetails={rowDetails} updateDataList={reloadAppointmentData}/> */}
                <CustomerAppointmentPopup  onClose={onEditAddressClose} rowDetails={rowDetails} scrollRef={scrollRef} updateDataList={reloadAppointmentData} isSaleOnline={true}/>
              </Popup>
            </>}
            {!!customerAddress && <div className={styles.addressWrapper}>
              <p>Customer Appointment  Details</p>
              <div className={styles.editWrapper}>
                <ul>
                  {!!zoneName && <li className={styles.location}>{zoneName}</li>}
                  {!!customerAddress && <li className={styles.address}>{customerAddress}</li>}
                </ul>
                {!ctaAccessFlag && <>
                  <IconEdit onClick={onEditAddressOpen}/>
                  <Popup isOpen={editAddressOpen} close={onEditAddressClose}>
                    <CustomerAppointmentPopup  onClose={onEditAddressClose} rowDetails={rowDetails} scrollRef={scrollRef} updateDataList={reloadAppointmentData} isSaleOnline={true}/>
                    {/* <HiLocationDetail onClose={onEditAddressClose} rowDetails={rowDetails} scrollRef={scrollRef} updateDataList={reloadAppointmentData}/> */}
                  </Popup>
                </>}
              </div>
              <div className={styles.dateTimeWrapper}>
                <ul>
                  <li className={styles.date}>{date}</li>
                  <li className={styles.time}>{time}</li>
                </ul>
              </div>
            </div>}
          </>}
        </div>
        <SellOnlineOurPrice data={rowDetails} updateDataList={reloadAppointmentData}/>
      </div>
    </div>
  );
};

export default SellOnlineCard;
