import React from "react";
import styles from "./incremental-textbox.module.css";

const IncrementalTextbox = ({
  value,
  onChangeClick
}) => {
  return (
    <div className={styles.incrementalTextbox}>
      <button onClick={() => onChangeClick("decrement")}>-</button>
      <input value={value} type="text" />
      <button onClick={() => onChangeClick("increment")}>+</button>
    </div>
  );
};

export default IncrementalTextbox;
