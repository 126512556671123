import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  isLoading: true,
  cjAllocationStausList: [],
  totalUnAllocatedCount: 0
};
export const quack = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};

export const fetchCjAllocationStatusSuccess = (state = INITIAL_STATE, { data }) => {
  const { total, detail } = data;
  return { ...state, totalUnAllocatedCount: total, cjAllocationStausList: detail, isLoading: false, error: null };
};
export const fetchCjAllocationStatusFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, error, isLoading: false };
};
export const HANDLERS = {
  [Types.FETCH_CJ_ALLOCATION_STATUS_SUCCESS]: fetchCjAllocationStatusSuccess,
  [Types.FETCH_CJ_ALLOCATION_STATUS_FAILURE]: fetchCjAllocationStatusFailure,

};

export default createReducer(INITIAL_STATE, HANDLERS);
