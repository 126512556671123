import Types from "./types";
import { AppointmentService } from "../../service";
// import { getCJAllocationStatusList } from "../../utils/mocApi";

const fetchCjAllocationStatus = (filterQuery) => dispatch => {
  return AppointmentService.getEvaluatorLeadCountBasedOnStore(filterQuery)
    .then(response => {
      // response = getPllStoreListMoq();
      const { data } = response;
      const { detail } = data;
      dispatch(fetchCjAllocationStatusSuccess(data));
      return response;
    })
    .catch((error) => {
      dispatch(fetchCjAllocationStatusFailure(error));
    }

    );
};

const fetchCjAllocationStatusSuccess = (data) => ({
  type: Types.FETCH_CJ_ALLOCATION_STATUS_SUCCESS,
  data
});

const fetchCjAllocationStatusFailure = (error) => ({
  type: Types.FETCH_CJ_ALLOCATION_STATUS_FAILURE,
  error
});

export {
  fetchCjAllocationStatus
};
