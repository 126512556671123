import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styles from "./sidebar.module.css";
import {
    APPOINTMENT_TYPES_STRING,
    RESET_REDUCER_STATE_KEYS,
    INITIAL_PRIORITY_COUNTS,
    NUMBERS,
} from "../../utils/app-constants";
import {
    getAppointmentTypeFromPathName,
    getLMSOrinialRole,
} from "../../utils/helper";
import { isPllUser } from "../../utils/helper";

const Sidebar = ({
    expandSidebar,
    openSidebar,
    hideSidebar,
    resetAppointmentByKeyConnect,
    resetAppointmentsConnect,
    fetchAppointmentCountsConnect,
    sidebar,
    resetSearchConnect,
    resetAppointmentCountByKeyConnect,
    updatePllFilterByKeyConnect,
    updateRetailFilterByKeyConnect,
    fetchUserMenusConnect,
    resetLeadMenuByKeyConnect,
}) => {
    const history = useHistory();
    const {
        location: { pathname },
    } = history;
    const appointmentType = getAppointmentTypeFromPathName(pathname);
    const { leadCount, priorityCount, leftMenu = [] } = sidebar;
    const isPll = isPllUser();
    const origRole = getLMSOrinialRole();
    const [activeItem, setActiveItem] = useState(null);
    useEffect(() => {
        if (leftMenu.length === NUMBERS.ZERO) {
            fetchUserMenusConnect({ role: origRole, permission: 0 });
        }
        return () => {
            resetAppointmentCountByKeyConnect(
                RESET_REDUCER_STATE_KEYS.PRIORITY_COUNT,
                Object.assign({}, INITIAL_PRIORITY_COUNTS)
            );
        };
    }, []);

    useEffect(() => {
        if (appointmentType) {
            resetFilterQuery();
            fetchAppointmentCountsConnect();
        }
    }, [appointmentType]);

    const changeState = (type, index) => {
		
        if (appointmentType === APPOINTMENT_TYPES_STRING.LEAD_MON) {
            resetSearchConnect();
            resetAppointmentsConnect();
            resetAppointmentByKeyConnect(
                RESET_REDUCER_STATE_KEYS.APPOINTMENT_STATE, appointmentType);
            resetLeadMenuByKeyConnect(
                RESET_REDUCER_STATE_KEYS.RESET_AND_FETCH_DATA,
                true
            );
        } else if (type !== appointmentType) {
            resetSearchConnect();
            resetAppointmentsConnect();
            resetAppointmentByKeyConnect(
                RESET_REDUCER_STATE_KEYS.APPOINTMENT_STATE,
                type
            );
        }  
        setActiveItem(index);
    };
    const onClickFilter = (label, filter) => {
        if (isPll) {
            updatePllFilterByKeyConnect("filterQuery", filter);
            updatePllFilterByKeyConnect("label", label);
            updatePllFilterByKeyConnect("count", 1);
        } else {
            updateRetailFilterByKeyConnect("filterQuery", filter);
            updateRetailFilterByKeyConnect("label", label);
            updateRetailFilterByKeyConnect("count", 1);
        }
    };
    const resetFilterQuery = () => {
        if (isPll) {
            updatePllFilterByKeyConnect("filterQuery", null);
        } else {
            updateRetailFilterByKeyConnect("filterQuery", null);
        }
    };
    return (
        <div
            className={`${styles.sideBar} ${
                expandSidebar ? styles.sideBarExpend : ""
            }`}
            onMouseEnter={openSidebar}
            onMouseLeave={hideSidebar}>
            <div className={styles.todoList}>
                <p className={styles.listTitle}>TO-DO LIST</p>
                <ul>
                    {leftMenu.map((item, index) => {
                        const apptType =
							APPOINTMENT_TYPES_STRING[item.string];
                        return (
                            <li
                                key={item.key}
                                className={
                                    appointmentType === apptType
                                        ? styles.active
                                        : ""
                                }
                                onClick={() => changeState(apptType, index)}>
                                <Link
                                    className={styles[item.iconStyle]}
                                    to={item.routePath}>
                                    {item.priorityCount &&
										priorityCount[item.priorityCount] >
											0 && (
                                        <i>
                                            {
                                                priorityCount[
                                                    item.priorityCount
                                                ]
                                            }
                                        </i>
                                    )}
                                    <span>
                                        {leadCount[item.leadCount]
                                            ? leadCount[item.leadCount]
                                            : "-"}
                                    </span>
                                    <p>{item.text}</p>
                                </Link>
                                {activeItem === index && !!item.subparts && (
                                    <ul className={styles.submenuList}>
                                        {item.subparts.map((subItem) => {
                                            return (
                                                <li
                                                    key={`SIDEBAR_${subItem.label}`}>
                                                    <Link
                                                        to="#"
                                                        onClick={() =>
                                                            onClickFilter(
                                                                subItem.label,
                                                                subItem.filter
                                                            )
                                                        }>
                                                        {subItem.label}
                                                    </Link>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default Sidebar;
