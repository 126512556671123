import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateWithCustomer } from "../negotiation-filter/actions";
import styles from "./negotabs.module.scss";

const NegoTabs = () => {
  const dispatch = useDispatch();
  const negoFilterData = useSelector(state => state.negotiationFilter);
  const onTabClicked = (value) => {
    dispatch(updateWithCustomer(value));
  };
  return (
    <div className={styles.negoTabs}>
      <ul>
        <li className={!negoFilterData.withCustomer ? styles.active : ''} onClick={()=> onTabClicked(false)}>Action Required ({negoFilterData.actionRequiredCount})</li>
        <li className={!!negoFilterData.withCustomer ? styles.active : ''} onClick={() => onTabClicked(true)}>With Customer ({negoFilterData.withCustomerCount})</li>
      </ul>
    </div>
  );
};

export default NegoTabs;