import React from "react";
import styles from "./reason-deal-lost.module.scss";

const ReasonDealLost = ({reason=''}) => {
  return (
    <div className={styles.reasonDealLost}>
      <h3>Reason for Deal Lost</h3>
      <span>Reason</span>
      <p>{reason}</p>
    </div>
  );
};

export default ReasonDealLost;
