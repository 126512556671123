import { createTypes } from "reduxsauce";

export default createTypes(
  `
    UPDATE_REVIEW_INSPECTION_FILTER_QUERY
`,
  {
    prefix: "reviewinspection/"
  }
);
