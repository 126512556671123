import * as Sentry from "@sentry/react";
import {getSentryConfig} from "../url-constants";
import {ERROR_CODES} from "../../service/api-constants";

const API_STATUS_CODES = [
  ERROR_CODES.ERROR,
  ERROR_CODES.NOT_FOUND,
  ERROR_CODES.BAD_REQUEST,
  ERROR_CODES.UNAUTHORIZED
];

const IGNORE_APIS = [
  { endpoint: `/js`, status: API_STATUS_CODES },
  { endpoint: `/j/collect`, status: API_STATUS_CODES },
];

const IGNORE_ERRORS = [
  "UnhandledRejection",
  "SecurityError",
  "Illegal invocation",
  "@derivedConstructor",
  "innerText",
  "href",
  "Network Error",
  /Request failed with status code (400|401|404|0)/
];

export const initializeSentry = () => {
  const config = getSentryConfig();
  if (process.env.REACT_APP_HOST_ENV === "PRODUCTION") {
    Sentry.init({
      dsn: config.dsn,
      environment: process.env.REACT_APP_HOST_ENV,
      ignoreErrors: IGNORE_ERRORS,
      // Performance Monitoring
      tracesSampleRate: config.rate, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: config.rate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      beforeSend(event, hint) {
        const err = hint.originalException;
        if ((err && err.config && err.request && err.config.url &&
                IGNORE_APIS.filter(item => (err.config.url.indexOf(item.endpoint) >= 0 && item.status.filter(statusCode => err.request.status === statusCode).length) || (err.path && err.path.indexOf(item.endpoint) >= 0)).length)
                || err.errorCode) {
          return;
        }
        return event;
      }
    });
  }
};
