/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import styles from "./customer-offer-price-journey.module.scss";
import DealersIcons from "../../components/images/dealersIcons.svg";
import CustomerPartnersViewed from "../../components/customer-partners-viewed";
import CustomerOffer from "../../components/customer-offer";
import CustomerYoursCars from "../../components/customer-yours-cars";
import CustomerSimilarCars from "../../components/customer-similar-cars";
import CustomerWhySell from "../../components/customer-why-sell";
import { AppointmentService } from "../../service";
import CustomerPriceJourneyHeader from "../../components/customer-offer-price-journey-header";
import { LOCAL_STORAGE_KEYS } from "../../utils/app-constants";
import { getFromLocalStorage, getRandomNumberBetweenRange, saveToLocalStorage, seDealerCountLocalStaorageKey } from "../../utils/helper";
import Feedback from "../../components/feedback";
import CarFactors from "../../components/car-factors";

const CustomerOfferPriceJourney = ({
    match,
    isInventoryBuying,
    resetLeadDetailsConnect,
    negotiations,
    fetchNegotiationsConnect,
    fetchCustomerOfferDetailsConnect,
    customerOffer,
    appointmentType,
    withCustomer
}) => {
    const { params: { appointmentId } } = match;
    const { customerOffer: { c24Quote, auction_bidding_status, showCep } } = customerOffer;
    const [negoData, setNegoData] = useState(null);
    const { data } = negotiations;
    const [componentShow, setComponentShow] = useState(false);
    const [showCharges, setShowCharges] = useState(false);
    const [showPostAuction, setShowPostAuction] = useState(false);
    let dealerCount = 0;
    let _dealerCount = getRandomNumberBetweenRange(300, 400);
    // let isFeedBackSubmit = getFromLocalStorage(LOCAL_STORAGE_KEYS.DEP_FEEDBACK_SUBMIT);

    dealerCount = seDealerCountLocalStaorageKey(appointmentId, _dealerCount);// getFromLocalStorage(LOCAL_STORAGE_KEYS.DEALER_COUNT);
    // if (!!auction_bidding_status) {
    //     let count = getFromLocalStorage(LOCAL_STORAGE_KEYS.DEALER_COUNT);
    //     try {
    //         if (auction_bidding_status === "BID_OVER") {
    //             if (count !== null) {
    //                 dealerCount = count;
    //             }
    //             else {
    //                 dealerCount = getRandomNumberBetweenRange(350, 400);
    //                 saveToLocalStorage(LOCAL_STORAGE_KEYS.DEALER_COUNT, dealerCount);
    //             }
    //         } else {
    //             if (!!count) {
    //                 dealerCount = count;
    //             }
    //         }
    //     }
    //     catch (e) {

    //     }
    // }

    useEffect(() => {
        if (appointmentId) {

            fetchCustomerOfferDetailsConnect(appointmentId);
            // fetchLeadDetailsConnect(appointmentId);
            let globalSearchQuery = appointmentId + "&globalSearch=true";
            fetchNegotiationsConnect(1, globalSearchQuery, null, null, null, null, null, null, withCustomer, appointmentType)
                .then(resp => {
                    if (resp.data) {
                        const { data: { detail: { data: { result } } } } = resp;
                        if (result.length > 0) {
                            const negoData = result.find(negotiation => negotiation.appointmentId === appointmentId);
                            if (negoData) {
                                // console.log("The final SortOder is - " + negoData.sortOrder);
                                let arrayOpenViewSortOrder = [2, 3, 4, 18, 17, 23, 29, 30, 41];
                                if (arrayOpenViewSortOrder.indexOf(negoData.sortOrder) >= 0) {
                                    setShowCharges(true);
                                }
                                if (negoData.sortOrder !== 1) {
                                    setShowPostAuction(true);
                                }
                                if (negoData.sortOrder === 1) {
                                    document.getElementById("dvPostAuction").style.display = "none";
                                }
                                setNegoData(negoData);

                            }
                        }
                        else {
                            document.getElementById("dvPostAuction").style.display = "none";
                        }
                    }
                })
                .catch(error => console.log(error));
            AppointmentService.getSimilarTransactedCars(appointmentId)
                .then(response => {
                    const { data: { detail } } = response;
                    if (!!detail && detail.length < 2) {
                        setComponentShow(false);
                    }
                    else {
                        setComponentShow(true);
                    }
                })
                .catch(error => {
                    console.log('Error fetching getSimilarTransactedCars', error);
                });
        }
        return () => {
            resetLeadDetailsConnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
      <>  
        {showPostAuction && <div> <div className={styles.wrapper}>
          {isInventoryBuying || (!!negoData && !!negoData.isInventoryBuying) ? <p></p> :
          <div className={styles.dealerWrapper}>
            <div className={styles.dealerCard}>
              <img src={DealersIcons} alt="DealersIcons" />
              {dealerCount > 0 && <h3 className={styles.blueColor}>{dealerCount > 0 ? dealerCount : 62}</h3>}
              <p>Channel Partners who viewed your car.</p>
            </div>
            {negoData !== null &&
              <CustomerPriceJourneyHeader appointMentData={negoData} />
                        }
          </div> 
                }
          {showCharges && <div>
            {showCep && <CarFactors appointmentId={appointmentId} />}
            <div className={styles.offerPriceWrapper}>
              <div className={styles.offersSection}>
                <CustomerPartnersViewed data={data} />
                <CustomerOffer />
              </div>
            </div>
            </div>
                }
        </div>
          <div className={styles.whySell}>
            <div className={styles.wrapper}>
              {componentShow && <div className={styles.similarCars}>
                <CustomerYoursCars />
                <CustomerSimilarCars />
                </div>}
              <CustomerWhySell />
            </div>
          </div>
            {/* {!isFeedBackSubmit && <Feedback appointmentId={appointmentId} />} */}
        </div>
            }
      </>
    );
};

export default CustomerOfferPriceJourney;
