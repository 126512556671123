import React, { useEffect, useRef, useState } from "react";
import styles from "./cjplanningcard.module.scss";
import { APPOINTMENT_TYPES_STRING, NUMBERS, RESET_REDUCER_STATE_KEYS } from "../../utils/app-constants";
import { AppointmentService } from "../../service";
import DataInformationMessage from "../shared/data-information-message/component";
import Button from "../../components/shared/button";
import Table from "../shared/table/component";
import TableRow from "../../components/shared/table-row";
import TableBody from "../../components/shared/table-body";
import TableHeaderWithControl from "../shared/table-header-with-control/component";
import TableHeader from "../shared/table-header/component";
import TableColumn from "../../components/shared/table-column";
import { ColumnTypeMapping, getColumnComponent, getTableColumnTypeByKey } from "../../utils/helper";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {updateApiNoAccess} from "../../components/hoc-access-controller/actions";

const CjExecutionCard = ({
  appointmentType,
  appointments,
  search,
  globalSearch,
  leadMenu,
  resetAppointmentByKeyConnect,
  fetchAppointmentsConnect,
  resetGlobalSearchConnect,
  resetAppointmentsConnect,
  sortAppointmentByKeyConnect,
  resetLeadMenuByKeyConnect
}) => {
  const { data, columns, page, isLoading, error, loadMore, assignReasons, sortOrder, sortKey } = appointments;
  const { query } = search;
  const { globalQuery } = globalSearch;
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [dataLoading, setDataLoading] = useState(false);
  const scrollRef = useRef();
  const itemRef = useRef();
  const [lastItemIndex, setLastItemIndex] = useState(9);
  const [renderedData, setRenderedData] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const { resetAndFetchData } = leadMenu;
  const { headerRowFilterQuery } = useSelector(state => state.headerRowFilter);
  const dispatch = useDispatch();
  const resetAndFetchNewListing = () => {
    setCurrentPageNo(1);
    loadMoreAppointments(1);
    resetAppointmentByKeyConnect(RESET_REDUCER_STATE_KEYS.IS_LOADING, true);
    resetAppointmentByKeyConnect(RESET_REDUCER_STATE_KEYS.DATA, []);
  };

  const updateDataList = (appointmentId) => {
    const newList = [...data];
    const currentNegoIndex = newList.findIndex(app => app.appointment === appointmentId);
    AppointmentService.getAppointments({type: 'resource/monitoring', page: 1, searchQuery: appointmentId, headerRowFilterQuery})
      .then(resp => {
        if (resp.data) {
          const { data: { detail: { result } } } = resp;
          if (result.length > 0) {
            const appData = result.find(appoint => appoint.appointment === appointmentId);
            if (appData) {
              newList[currentNegoIndex] = appData;
              resetAppointmentByKeyConnect(RESET_REDUCER_STATE_KEYS.DATA, newList);
            }
          }
        }
      })
      .catch(error => {
        dispatch(updateApiNoAccess(error));
        console.log(error);
      });
  };

  const paginationLoader = (page) => {
        page > 1 ? setDataLoading(true) : setDataLoading(false);
  };

  const getFinalSearchQuery = () => {
    if (globalQuery) {
      return globalQuery;
    } else {
      return query;
    }
  };

  const bindAppointmentsData = (response) => {
    if (response && response.data && globalQuery) {
      resetGlobalSearchConnect();
    }
    setDataLoading(false);
  };

  const loadMoreAppointments = (page) => {
    if (appointmentType === APPOINTMENT_TYPES_STRING.CJ_EXECUTION) {
      paginationLoader(page);
      const finalQuery = getFinalSearchQuery();
      fetchAppointmentsConnect({type: 'resource/monitoring', page, searchQuery: finalQuery, headerRowFilterQuery})
        .then(resp => {
          bindAppointmentsData(resp);
        });
    }
  };

  useEffect(()=>{
    resetAppointmentByKeyConnect(RESET_REDUCER_STATE_KEYS.APPOINTMENT_STATE, appointmentType);
    setCurrentPageNo(1);
    loadMoreAppointments(1);

    return () => {
      resetAppointmentsConnect();
    };
  },[]);

  useEffect(()=>{
    if (headerRowFilterQuery !== null) {
      resetAndFetchNewListing();
    }
  },[headerRowFilterQuery]);

  useEffect(() => {
    if (query !== null) {
      resetAndFetchNewListing();
    }
  }, [query]);

  useEffect(() => {
    if (resetAndFetchData) {
      resetLeadMenuByKeyConnect(RESET_REDUCER_STATE_KEYS.RESET_AND_FETCH_DATA, false);
      resetAndFetchNewListing();
    }
  }, [resetAndFetchData]);

  useEffect(() => {
    if (columns.length > 0) {
      let shownColumns = columns.filter(columnItem => columnItem.isVisible !== NUMBERS.ZERO.toString());
      setVisibleColumns(shownColumns);
    }
  }, [columns]);

  useEffect(() => {
    setRenderedData(data);
    if (itemRef.current && currentPageNo > 1) {
      itemRef.current.scrollIntoView({
        behavior: "auto",
        block: "end"
      });
    }
    setLastItemIndex(data.length-1);
  }, [data]);

  const onLoadMore = () => {
    let i = currentPageNo;
    setCurrentPageNo(i + 1);
    loadMoreAppointments(i + 1);
  };

  const onSortClick = (key) => {
    let defaultSortOrder = "asc";
    if (sortOrder === "asc") {
      defaultSortOrder = "desc";
    }
    sortAppointmentByKeyConnect(key, defaultSortOrder);
    resetAndFetchNewListing();
  };

  const getColumnHeader = (item, index) => {
    if (item.controlType === "dd") {
      return (<TableHeaderWithControl key={index} headerKey={item.key} headerValue={item.value} isSort={item.isSortable === 1 ? true : false} onSortClick={onSortClick} sortOrder={sortOrder} sortKey={sortKey} ddValues={item.options} />);
    }
    else {
      return <TableHeader key={index} headerKey={item.key} headerValue={item.value} isSort={item.isSortable === 1 ? true : false} onSortClick={onSortClick} sortOrder={sortOrder} sortKey={sortKey} />;
    }
  };

  const getSerialNumber = (index) => {
    return page * NUMBERS.TEN + index + 1;
  };
  const getCallStatusStyleClass = (callStatus) =>{
    let scCallStatus = "notCalled";
    switch(callStatus){
      case "Call connected":
        scCallStatus = "callConnected";
        break;
      case "Call not connected":
        scCallStatus = "notConnected";
        break;
      default:
    }
    return scCallStatus;
  };

  const getColumn = (key, value, rowDetails, index) => {
    if (key === 'callStatus') {
      return (
        <div className={styles.callStatus}>
          <p className={`${styles[getCallStatusStyleClass(rowDetails.lastCallStatus)]}`}>
            {!!rowDetails.lastCallTime && <span>{rowDetails.lastCallTime}</span>}
            <span>{rowDetails.lastCallStatus}</span>
          </p>
        </div>
      );
    } else if (key === "slaStatus") {
      return (
        <div>
          <p className={!!rowDetails.leadType && rowDetails.leadType === "Home Inspection" ? styles.homeInspection : rowDetails.leadType === "Sell Online" ? styles.sellOnline : styles.reinspection}>{rowDetails.leadType}</p>
          <p className={['ON TIME','ONGOING (ON TIME)','COMPLETED ON TIME','ONGOING(On Time)','COMPLETED(On Time)','NONE'].includes(rowDetails.slaStatus) === true ? styles.headingText : styles.denied}>{rowDetails.slaStatus}</p>
          {!!rowDetails.lostReason && 
          <div className={styles.connectTool}>
            <p>{rowDetails.lostReason}</p><span>More</span>
            <div className={styles.helpInfo}>{rowDetails.lostReason}</div>
          </div>
          }
        </div>
      );
    } else {
      const tableType = getTableColumnTypeByKey(key);
      switch (tableType) {
        case ColumnTypeMapping.STRING:
          return <span className={key === "timeslot" ? styles.time : styles.time}>{value}</span>;
        case ColumnTypeMapping.DASH_STRING:
          return <span className={styles.modleName}>{!!value ? value : "-"}</span>;
        case ColumnTypeMapping.LINK:
          return <Link className={styles.dataId} to='#'>{value}</Link>;
        case ColumnTypeMapping.COMPONENT:
          return getColumnComponent(key, rowDetails, scrollRef, setHasMoreItems, index, null, updateDataList, 
            null, null, null, null, resetAndFetchNewListing);
        default: break;
      }
    }
  };
  const renderAppointments = () => {
    return (
      <Table>
        <thead>
          <TableRow>
            {visibleColumns.map((item, index)=> {
              return (
                <>
                  {getColumnHeader(item, index)}
                </>
              );
            })}
          </TableRow>
        </thead>
        <TableBody>
          {!isLoading && !error && renderedData.map((item, index)=> {
            return (
              <TableRow key={`row-${index}`} tableRowRef={(index === lastItemIndex) ? itemRef : null}>
                <>
                  {
                    visibleColumns.map((col, colIndex) => {
                      return (
                        <TableColumn key={`col-${colIndex}`} item={col} >
                          {getColumn(col.key, item[col.key], item, getSerialNumber(index))}
                        </TableColumn>
                      );
                    })
                  }
                </>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }; 
  return (
    <div className={styles.cjplanningCard}>
      {renderAppointments()}
      <DataInformationMessage 
        isLoading={isLoading}
        error={error}
        data={renderedData}
        dataLoading={dataLoading}
      />
      {loadMore && <div className={styles.loadmore}>
        <Button classNames="secondaryCta" ctaText={"Load More"} onClick={onLoadMore}></Button>
      </div>
      }
    </div>
  );
};

export default CjExecutionCard;
