import React, { useState, useEffect } from "react";
import Carousel from "../carousel/component";
import GroupedButton from "../grouped-button/component";
import carImagesParser from "../../utils/helpers/car-images-parser";
import popups from "../../utils/popup-constants";
import Button from "../shared/button/component";
import PaiSlider from "../pai-slider";
import priceFormatter from "../../utils/helpers/price-formatter";
import paiInfo from "../../utils/raise-pai/pai-info";
import calculateProbablity, { PROBABLITY_TYPES } from "../../utils/raise-pai/calculate-probablity";
import styles from "./carousel-inspection-report.module.css";
import { showToastMessages, isPllUser, getFromLocalStorage } from "../../utils/helper";
import { LOCAL_STORAGE_KEYS } from "../../utils/app-constants";

const CarouselInspectionReport = ({ currentCarDetails, negotiations, preCheckRaisePAIConnect }) => {
  const { currentPopup, currentAppointmentIndex, data, currentSuggestedC24Quote } = negotiations;
  const currentAppointmentData = data[currentAppointmentIndex];
  const { year, make, model, variant, appointmentId, storeId, auctionId, c24Quote, carId } = currentAppointmentData;
  const images = carImagesParser(currentCarDetails);
  const [isLoading, setIsLoading] = useState(false);
  let isPll = isPllUser();
  const buttons = [
    { key: "exteriorImages", text: `Exterior(${images.exteriorImages.length})` },
    { key: "interiorImages", text: `Interior(${images.interiorImages.length})` },
    { key: "engineImages", text: `Engine(${images.engineImages.length})` },
    { key: "defectImages", text: `Defect(${images.defectImages.length})` },
    { key: "allImages", text: `All(${images.allImages.length})` }
  ];
  const [currentTab, setCurrentTab] = useState("exteriorImages");
  const [calculatedPaiInfo, setCalculatedPaiInfo] = useState({});
  const [probablityType, setProbablityType] = useState(null);
  const {
    showSlider,
    domain,
    sliderData,
    priceAcceptance,
    defaultValues,
    step,
    optimalIncrease
  } = calculatedPaiInfo;

  const [values, setValues] = useState((defaultValues || []).slice());
  const [update, setUpdate] = useState((defaultValues || []).slice());

  const onCarouselToggle = (currentTab) => {
    setCurrentTab(currentTab);
  };

  const onClickQuotePrice = () => {
    setIsLoading(true);
    const loginData = JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA) || "null");
    if (!loginData && !loginData.email) { showToastMessages("Please login again.", false); }
    // const payload = {
    //     "appointment_id": appointmentId,
    //     "carId": carId,
    //     "auction_id": auctionId,
    //     "store_id": storeId,
    //     // "c24quote": calculatedc24Quote,
    //     "current_c24quote": currentSuggestedC24Quote,
    //     "requested_c24quote": 0,
    //     "userEmail": loginData.email,
    //     "request_type": "RAISE_PAI",
    //     "is_pll": isPll,
    //     "c24Diff": 0,
    //     "probability": "",
    //     "reqCheck": "paiRequest",
    //     "customerPage": true
    // };
    const payload = {
      "appointment_id": appointmentId,
      "carId": carId,
      "auction_id": auctionId,
      "store_id": storeId,
      "c24quote": currentSuggestedC24Quote,
      "requested_c24quote": 0,
      "userEmail": loginData.email,
      "request_type": "RAISE_PAI",
      "is_pll": isPll,
      "c24Diff": 0,
      "probability": "",
      "reqCheck": "paiRequest",
      "customerPage": true
    };
    preCheckRaisePAIConnect(payload).then(() => {
      setIsLoading(false);
      setCalculatedPaiInfo(paiInfo(negotiations));
    }).catch(({ data: { message } }) => {
      showToastMessages(message, false);
      setIsLoading(false);
    });
  };

  const onUpdateSlider = update => {
    const currentValue = update[0];
    setProbablityType(calculateProbablity(priceAcceptance, currentValue - c24Quote, sliderData));
    setUpdate(update);
  };

  const onChangeSlider = values => {
    setValues(values);
  };

  useEffect(() => {
    if (showSlider) {
      setProbablityType(calculateProbablity(priceAcceptance, optimalIncrease, sliderData));
      setValues(defaultValues.slice());
      setUpdate(defaultValues.slice());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSlider]);

  return (
    <React.Fragment>
      <Carousel images={images[currentTab]} />
      {currentPopup === popups.CAR_SPECS && <GroupedButton onClick={onCarouselToggle} buttons={buttons} activeButton={currentTab} />}
      {currentPopup === popups.CAR_SPECS_CUSTOMER_VIEW &&
      <div className={styles.carDetailsReport}>
        <div className={styles.carInfo}>
          <p className={styles.modelDetails}>{`${[year, [make, model, variant].join(" ")].join(", ")}`}</p>
          <p className={styles.appointmentid}>APPOINTMENT ID: {appointmentId}</p>
        </div>
        <div>
          <Button
            isLoading={isLoading}
            classNames={"greenButton"}
            onClick={onClickQuotePrice}
            ctaText={`₹ ${priceFormatter(currentSuggestedC24Quote)}`} />
        </div>
      </div>}
      {showSlider && <div className={styles.priceTextAmount}>
        <span>₹ {priceFormatter(values[0])}</span>
        <div className={styles.priceWrapper}>
          <div className={styles.priceSlider}>
            <PaiSlider
              domain={domain}
              onChange={onChangeSlider}
              onUpdate={onUpdateSlider}
              values={values}
              update={update}
              step={step}
              sliderData={sliderData}
              prices={priceAcceptance}
            />
          </div>
          <React.Fragment>
            {probablityType === PROBABLITY_TYPES.HIGH.key && <p className={styles.greenText}>{PROBABLITY_TYPES.HIGH.label}</p>}
            {probablityType === PROBABLITY_TYPES.MEDIUM.key && <p className={styles.orengeText}>{PROBABLITY_TYPES.MEDIUM.label}</p>}
            {probablityType === PROBABLITY_TYPES.LOW.key && <p className={styles.redText}>{PROBABLITY_TYPES.LOW.label}</p>}
            {probablityType === PROBABLITY_TYPES.VERY_LOW.key && <p className={styles.redText}>{PROBABLITY_TYPES.VERY_LOW.label}</p>}
          </React.Fragment>
        </div>
      </div>}
      {showSlider === false && <span>No data available</span>}
    </React.Fragment>
  );
};

export default CarouselInspectionReport;
