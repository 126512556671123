import React from "react";
import Button from "../../../components/shared/button/component";
import { BUTTON_STYLES } from "../../common/app-constants";
import styles from "./generate-offer-price.module.scss";
import CjOfferPrice from "../../common/images/cj_offer_price.svg";
import {onBackHome} from "../../common/utility";
const GenerateOfferPrice = () => {
  return (
    <React.Fragment>
      <div className={styles.cjOfferPriceInfo}>
        <img className={styles.imageSpace} src={CjOfferPrice} alt="cj" />
        <h4 className={styles.heading}>Thanks for filling the lead. Price has been communicated to dealer</h4>
        <span className={styles.info}>RA has been informed</span>
      </div>
      <div className={styles.buttonWrap}>
        <Button ctaText={"Create a new lead"} classNames={BUTTON_STYLES.DEFAULT} onClick={onBackHome}/>
      </div>
    </React.Fragment>
  );
};

export default GenerateOfferPrice;
