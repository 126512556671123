import React, { useEffect, useState } from "react";
import styles from "./customer-offer-price-journey-new.module.scss";
import CustomerOfferNew from "../../components/customer-offer-new";
import CustomerSimilarCarsNew from "../../components/customer-similar-cars-new";
import { AppointmentService } from "../../service";
import priceFormatter from "../../utils/helpers/price-formatter";
import Textarea from "../shared/textarea/component";
import Button from "../shared/button/component";
import { LOCAL_STORAGE_KEYS } from "../../utils/app-constants";
import { getFromLocalStorage, getRandomNumberBetweenRange, saveToLocalStorage, seDealerCountLocalStaorageKey, showToastMessages } from "../../utils/helper";
import CongratulationsPrice from "../../components/congratulations-price";
import Jeep from "../../components/images/jeep.svg";
import highRating from "../../components/images/5star.svg";
import Below5 from "../../components/images/below5.svg";
import Like from "../images/likeIcons.svg";
import DisLike from "../images/dislikeIcons.svg";
import ActiveStar from "../images/activeStar.svg";
import DeActiveStar from "../images/deActiveStar.svg";
import WhiteStar from "../images/whiteStar.svg";
import { getSellerReview, getSimilarCar } from "../../utils/mocApi";
import GreenStar from "./images/green-star.svg";
import GreenHalfStar from "./images/green-half-star.svg";
import RedStar from "./images/red-star.svg";
import RedHalfStar from "./images/red-half-star.svg";
import OrangeStar from "./images/orange-star.svg";
import OrangeHalfStar from "./images/orange-half-star.svg";
import TransparentStar from "./images/transparent-star.svg";

const CustomerOfferPriceJourneyNew = ({
  match,
  isInventoryBuying,
  resetLeadDetailsConnect,
  negotiations,
  fetchNegotiationsConnect,
  fetchCustomerOfferDetailsConnect,
  customerOffer,
  similarTransactedCar,
  appointmentType,
  withCustomer
}) => {
  const { params: { appointmentId } } = match;
  const { customerOffer: { c24Quote, auction_bidding_status, showCep } } = customerOffer;
  const { customerOffer: { make, model, variant, year, odometer, front_image } } = customerOffer;
  const [carData, setCarData] = useState([]);
  const [slickSlider, setSlickSlider] = useState(true);
  const [negoData, setNegoData] = useState(null);
  const { data } = negotiations;
  const [componentShow, setComponentShow] = useState(false);
  const [showCharges, setShowCharges] = useState(false);
  const [showPostAuction, setShowPostAuction] = useState(false);
  const [sellerAvgReview, setSellerAvgReview] = useState();
  const [sellerReviews, setSellerReviews] = useState([]);
  const [isMarketVisible, setIsMarketVisible] = useState(false);
  const [marketPrice, setMarketPrice] = useState('');
  const [showLikeView, setShowLikeView] = useState(false);
  const [showDislikeView, setShowDislikeView] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);
  const [feedBack, setFeedBack] = useState("");
  const [feedBackText, setFeedBackText] = useState("");
  const [submitFeedBackSuccess, setSubmitFeedBackSuccess] = useState(false);
  const [yourCarRating, setYouCarRating] = useState({});
  let isFeedBackSubmit = getFromLocalStorage(LOCAL_STORAGE_KEYS.DEP_FEEDBACK_SUBMIT);
  const starColors = {
    1: RedStar,
    1.5: RedHalfStar,
    2: RedStar,
    2.5: OrangeHalfStar,
    3: OrangeStar,
    3.5: OrangeHalfStar,
    4: GreenStar,
    4.5: GreenHalfStar,
    5: GreenStar,
    transparent: TransparentStar
  };
  let dealerCount = 0;
  let _dealerCount = getRandomNumberBetweenRange(300, 400);

  dealerCount = seDealerCountLocalStaorageKey(appointmentId, _dealerCount);// getFromLocalStorage(LOCAL_STORAGE_KEYS.DEALER_COUNT);
  useEffect(() => {
    if (appointmentId) {

      fetchCustomerOfferDetailsConnect(appointmentId);
      let globalSearchQuery = appointmentId + "&globalSearch=true";
      fetchNegotiationsConnect(1, globalSearchQuery, null, null, null, null, null, null, withCustomer, appointmentType)
        .then(resp => {
          if (resp.data) {
            const { data: { detail: { data: { result } } } } = resp;
            if (result.length > 0) {
              const negoData = result.find(negotiation => negotiation.appointmentId === appointmentId);
              if (negoData) {
                // console.log("The final SortOder is - " + negoData.sortOrder);
                let arrayOpenViewSortOrder = [2, 3, 4, 18, 17, 23, 29, 30, 41];
                if (arrayOpenViewSortOrder.indexOf(negoData.sortOrder) >= 0) {
                  setShowCharges(true);
                }
                if (negoData.sortOrder !== 1) {
                  setShowPostAuction(true);
                }
                if (negoData.sortOrder === 1) {
                  document.getElementById("dvPostAuction").style.display = "none";
                }
                setNegoData(negoData);

              }
            }
            else {
              document.getElementById("dvPostAuction").style.display = "none";
            }
          }
        })
        .catch(error => console.log(error));
    }
    return () => {
      resetLeadDetailsConnect();
    };

  }, []);

  const getYourCarDetails = () => {
    if (!!yourCarRating) {
      AppointmentService.getCarData(appointmentId)
        .then(response => {
          const { data: { detail: { data } } } = response;
          const carData = data[appointmentId];
          setYouCarRating(carData.others);
        })
        .catch(error => {
          const { detail } = error;
          showToastMessages(detail || error, false);
        });
    }
  };

  useEffect(() => {
    //getInvSimilarCars
    if (appointmentId > 0) {
      AppointmentService.getInvSimilarCars(appointmentId)
        .then(response => {
          const { data: { detail: { similarCars, marketPrice } } } = response;
          similarCars && setCarData(similarCars);
          setMarketPrice(marketPrice || 0);
          if (!!similarCars && similarCars.length < 2) {
            setComponentShow(false);
          }
          else {
            if (!!similarCars && similarCars.length >= 3) {
              setSlickSlider(true);
            }
            if (!!similarCars && similarCars.length < 3) {
              setSlickSlider(false);
            }
            setComponentShow(true);
            //Mock API data
            // setCarData(detail); 
            // let response = getSimilarCar();
            // const { data: { detail: { similarCars, marketPrice } } } = response;
            // setCarData(similarCars);
            // setMarketPrice(marketPrice);
          }
          // setIsLoading(false);
        })
        .catch(error => {
          console.log('Error fetching Similar Cars list', error);
        });

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentId]);

  useEffect(() => {
    // Market Visibility
    if (carData.length >= 3 && marketPrice && marketPrice < data[0]?.c24Quote) {
      getYourCarDetails();
      setIsMarketVisible(true);
    } else {
      setIsMarketVisible(false);
    }
  }, [carData, marketPrice, data]);

  useEffect(() => {
    // const response = getSellerReview();
    // const { data: { detail: { average, review } } } = response;
    // setSellerAvgReview(average);
    // setSellerReviews(review);

    AppointmentService.sellerReviews()
      .then(response => {
        const { data: { detail: { average, review } } } = response;
        setSellerAvgReview(average);
        setSellerReviews(review);
      }).catch(error => {
        const { detail } = error;
        showToastMessages(detail || error, false);
      });
  }, []);

  const getRatingText = (rating) => {
    let ratingText = Math.round(rating);
    if (rating === 0) {
      ratingText = 1;
    }
    return ratingText;
  };

  const starsMap = (rating, colors) => {
    const ratingMod = rating % parseInt(rating);
    return [0, 0, 0, 0, 0].map((i, index) => {
      const absoluteRating = rating - ratingMod;
      if (ratingMod !== 0 && index >= absoluteRating && index < absoluteRating + 1) {
        return colors[rating];
      }
      if (index >= absoluteRating) {
        return colors["transparent"];
      }
      return colors[absoluteRating];
    });
  };

  const getRatingColor = (rating) => {
    if (rating === 0) return "noRating";
    if (rating < 3 && rating > 0) return "profileRed";
    if (rating >= 3 && rating < 4) return "profileYellow";
    if (rating >= 4) return "profileGreen";
  };

  const showQuestionDiv = (text) => {
    setFeedBack(text);
    setShowQuestion(true);
  };
  const onFeedBackChange = (e) => {
    const { target: { value } } = e;
    setFeedBackText(value);
  };
  const SubmitFeedBack = () => {
    const data = {
      "appointmentId": appointmentId,
      "type": "dep_report",
      "comment": feedBackText,
      "feedback": feedBack
    };
    AppointmentService.sendDepReportFeedBack(data)
      .then(resp => {
        const { data: { detail } } = resp;
        setFeedBackText("");
        if (!!feedBackText) {
          showToastMessages(detail);
          saveToLocalStorage(LOCAL_STORAGE_KEYS.DEP_FEEDBACK_SUBMIT, true);
          setSubmitFeedBackSuccess(true);
          setShowQuestion(false);
        }
      })
      .catch(err => {
        const { detail } = err;
        showToastMessages(detail, false);

      });
  };
  const setEnableDisbaleButton = (enable) => {
    if (enable) {
      setShowLikeView(true);
      setShowDislikeView(false);
      setFeedBack("yes");

    }
    else {
      setShowLikeView(false);
      setShowDislikeView(true);
      setFeedBack("no");
    }
  };
  useEffect(() => {
    if (!!feedBack) {
      SubmitFeedBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedBack]);

  const onClickHideFeedBack = () => {
    // setFeedBack("no");
    setSubmitFeedBackSuccess(true);
  };

  return (
    <>
      <CongratulationsPrice carData={carData} nego={data[0]} isMarketVisible={isMarketVisible} marketPrice={marketPrice} />
      {isMarketVisible && <div className={styles.marketPrice}>
        <div className={styles.marketPriceSection}>
          <div className={styles.marketPriceText}>
            <h4>Market Price</h4>
            <h5>Based on previous transactions</h5>
          </div>
          <p className={styles.price}>₹  {priceFormatter(marketPrice, true)}</p>
        </div>
        <div className={styles.yoursCarSimlerCars}>
          <div className={styles.yourCarsWrapper}>
            <h4>Your Car</h4>
            <div className={styles.yourCars}>
              <div className={styles.imgWrapper}>
                <img src={front_image} alt="cars" />
              </div>
              <div className={styles.carsSectionDetails}>
                <h4>{make} {model}</h4>
                <span className={styles.varient}>{variant}</span>
                <p className={styles.kmModel}>{priceFormatter(odometer, true)} km {year} </p>
                <div className={styles.ratingSection}>
                  <ul>
                    <li>
                      <h4>Engine</h4>
                      {/* <div className={styles.rating}>
                                                <img src={highRating} alt="5 star" />
                                                <span className={styles.highRating}>5.0</span>
                                            </div> */}
                      <div className={styles.rating}>
                        <img src={getRatingText(yourCarRating?.engineTransmission?.rating) === 5 ? `${highRating}` : `${Below5}`} alt="Rating" />
                        <span className={`${styles[getRatingText(yourCarRating?.engineTransmission?.rating) === 5 ? `highRating` : `below5`]}`}>{yourCarRating?.engineTransmission?.rating}</span>
                      </div>
                    </li>
                    <li>
                      <h4>Exterior</h4>
                      {/* <div className={styles.rating}>
                                                <img src={Below5} alt="4 star" />
                                                <span className={styles.below5}>4.0</span>
                                            </div> */}
                      <div className={styles.rating}>
                        <img src={getRatingText(yourCarRating?.exteriorTyres?.rating) === 5 ? `${highRating}` : `${Below5}`} alt="Rating" />
                        <span className={`${styles[getRatingText(yourCarRating?.exteriorTyres?.rating) === 5 ? `highRating` : `below5`]}`}>{yourCarRating?.exteriorTyres?.rating}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <CustomerSimilarCarsNew carData={carData} slickSlider={slickSlider} componentShow={componentShow} />
        </div>
        {/* {(!isFeedBackSubmit || !submitFeedBackSuccess) && <div className={styles.feedback}>
                    <p>Was this helpful to understand the offer price?</p>
                    <a href="javascript:void(0)" onClick={() => showQuestionDiv("yes")}>
                        <img src={Like} onClick={() => setEnableDisbaleButton(true)} alt="Like" />
                    </a>
                    <a href="javascript:void(0)" onClick={() => showQuestionDiv("no")}>
                        <img src={DisLike} onClick={() => setEnableDisbaleButton(false)} alt="Dislike" />
                    </a>
                    {showQuestion &&
                        <div className={styles.feedbackWrapper}>
                            <div className={styles.feedback}>
                                <div className={styles.textarea}>
                                    <Textarea
                                        onChange={onFeedBackChange}
                                        value={feedBackText}
                                        placeholder="Please give your feedback on depreciation report and price report." />
                                </div>
                                <div className={styles.cta}>
                                    <Button ctaText="SUBMIT" classNames="saveCta" onClick={SubmitFeedBack} />
                                </div>
                            </div>
                        </div>
                    }
                </div>} */}
      </div>}
      <div className={styles.reviewPriceBreakdown}>
        <div className={styles.customerReviewsWrapper}>
          <h3>Customer Reviews</h3>
          <div className={styles.reviewWrapper}>
            <div className={styles.topReview}>
              <div className={styles.ratingSection}>
                <h2>{getRatingText(sellerAvgReview)}/5</h2>
                <div className={styles.starRating} >
                  {starsMap(getRatingText(sellerAvgReview), starColors)
                    .map((color, index) => <img key={index} alt={`star_${index}`} src={color} />)}
                </div>

                {/* <div className={styles.starRating}>
                                    <img src={ActiveStar} alt="active" />
                                    <img src={ActiveStar} alt="active" />
                                    <img src={ActiveStar} alt="active" />
                                    <img src={ActiveStar} alt="active" />
                                    <img src={DeActiveStar} alt="deActive" />
                                </div> */}
              </div>
              <div className={styles.ourCustomers}>
                <p className={styles.title}>What our customers love about us!</p>
                <ul>
                  <li>
                    <span>“Best Price”</span>
                    <span>“Staff Service”</span>
                  </li>
                  <li>
                    <span>“Convenience”</span>
                    <span>“Fast Process”</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.reviewList}>
              <ul>
                {sellerReviews.length > 0 && sellerReviews
                  .map((seller, index) => <li>
                    <span className={styles.name}>{seller.sellerName}</span>
                    {/* <div className={styles.highRating}>
                                        <img src={WhiteStar} alt="star" />
                                        <span>5.0</span>
                                    </div> */}
                    <div className={`${styles.highRating} ${styles[getRatingColor(getRatingText(seller.rating))]}`}>
                      <img src={WhiteStar} alt="Rating" />
                      <span className={styles.ratingText}>{getRatingText(seller.rating)}</span>
                    </div>
                    <p className={styles.msg}>{seller.comment}</p>
                  </li>
                  )}

              </ul>
            </div>
          </div>
        </div>
        <div className={styles.priceBreakdown}>
          <h3>Price Breakdown</h3>
          <div className={styles.priceBreakdownTable}>
            <CustomerOfferNew newCustomerOffer={true} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerOfferPriceJourneyNew;
