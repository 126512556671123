/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styles from "./review-inspection.module.scss";
import {
	REVIEW_INSPECTION_FILTERS,
	ROLE_KEYS,
	LEADMON_FILTERS,
	APPOINTMENT_TYPES_STRING,
} from "../../utils/app-constants";
import { getLMSOrinialRole } from "../../utils/helper";

const ReviewInspection = ({
	appointmentType,
	reviewInspection,
	updatereviewInspectionFilterQueryConnect,
}) => {
	const origRole = getLMSOrinialRole();
	if (origRole !== ROLE_KEYS.TM) {
		delete REVIEW_INSPECTION_FILTERS.LEAD_MON;
	}
	const filterSource =
		appointmentType === APPOINTMENT_TYPES_STRING.LEAD_MON
			? LEADMON_FILTERS
			: REVIEW_INSPECTION_FILTERS;
	const defaultFilter =
		appointmentType === APPOINTMENT_TYPES_STRING.LEAD_MON
			? "assigned"
			: "currentinspection";
	const [selectedFilter, setSelectedFilter] = useState("");
	const { reviewInspectionFilterQuery } = reviewInspection;
	
	useEffect(() => {
		let curFilter = defaultFilter;
		if (reviewInspectionFilterQuery === "currentinspection") {
			curFilter = "currentinspection";
		} else if (reviewInspectionFilterQuery === "pendingreviews") {
			curFilter = "pendingreviews";
		} else if (reviewInspectionFilterQuery === "assigned") {
			curFilter = "assigned";
		} else if (reviewInspectionFilterQuery === "bought") {
			curFilter = "bought";
		} else if (reviewInspectionFilterQuery === "all_leads") {
			curFilter = "all_leads";
		} else if (reviewInspectionFilterQuery === "LeadMon") {
			curFilter = "LeadMon";
		} else if (reviewInspectionFilterQuery === "platform") {
			curFilter = "platform";
		}
		setSelectedFilter(curFilter);
	}, [reviewInspectionFilterQuery]);

	const onFilterSelect = (selectedKey) => {
		setSelectedFilter(selectedKey);
		updatereviewInspectionFilterQueryConnect(selectedKey);
	};

	return (
		<div className={styles.ccConfirmLeads}>
			<ul>
				{Object.values(filterSource).map((item) => {
					return (
						<li
							key={item.key}
							onClick={() => {
								onFilterSelect(item.key, item.value);
							}}
							className={selectedFilter === item.key ? styles.active : ""}>
							{item.label}{" "}
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default ReviewInspection;
