import React from "react";
import Timer from "react-compound-timer";

const MinuteTimer = () => {
  return (
    <Timer.Minutes />
  );
};

export default MinuteTimer;
