/* eslint-disable indent */
import React, { useEffect, useState, useRef } from "react";
import {
    NUMBERS,
    RESET_REDUCER_STATE_KEYS,
    APPOINTMENT_TYPES_STRING
} from "../../utils/app-constants";
import H2 from "../../components/h2";
import { PAGE_HEADING } from "../../utils/app-constants";
import styles from "./lost-leads.module.scss";
import Table from "../../components/shared/table";
import TableBody from "../../components/shared/table-body";
import TableRow from "../../components/shared/table-row";
import TableColumn from "../../components/shared/table-column";
import TableHeader from "../../components/shared/table-header";
import { Link } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroller';
import {
    getTableColumnTypeByKey,
    ColumnTypeMapping,
    getColumnComponent,
    getAppointmentTypeNumberFromString
} from "../../utils/helper";
import DataInformationMessage from "../../components/shared/data-information-message";
import Reschedule from "../../components/reschedule";
import Back from "../../components/back";
import HocAccessController from "../../components/hoc-access-controller";
const LostLeads = ({
    fetchLostLeadsConnect,
    resetLostLeadsByKeyConnect,
    resetLeadMenuByKeyConnect,
    resetLostLeadsConnect,
    leadMenu,
    lostLeads,
    location,
    history,
    resetAppointmentByKeyConnect,
    globalSearch,
    resetGlobalSearchConnect,
    resetApiNoAccessConnect
}) => {
    const { columns, data, isLoading, error } = lostLeads;
    const [visibleColumns, setVisibleColumns] = useState([]);
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [dataLoading, setDataLoading] = useState(false);
    const [pageStart] = useState(0);
    const scrollRef = useRef();
    const { resetAndFetchData } = leadMenu;
    const { state } = location;
    const fromPageKey = state ? state.fromPage : "";
    const [fromPage] = useState(fromPageKey);
    const lostFrom = getAppointmentTypeNumberFromString(fromPage);
    const { globalQuery } = globalSearch;

    useEffect(() => {
        resetApiNoAccessConnect();
        resetLostLeadsByKeyConnect(RESET_REDUCER_STATE_KEYS.FROM_PAGE, fromPage);
        resetAppointmentByKeyConnect(RESET_REDUCER_STATE_KEYS.APPOINTMENT_STATE, fromPage);
        return () => {
            resetLostLeadsConnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (resetAndFetchData) {
            resetLeadMenuByKeyConnect(RESET_REDUCER_STATE_KEYS.RESET_AND_FETCH_DATA, false);
            resetAndFetchNewListing();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetAndFetchData]);

    useEffect(() => {
        if (globalQuery) {
            resetAndFetchNewListing();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalQuery]);

    useEffect(() => {
        if (columns.length > 0) {
            let shownColumns = [];
            shownColumns = columns.filter(item => item.isVisible !== NUMBERS.ZERO.toString()).map(item => { return { key: item.key, value: item.value }; });
            setVisibleColumns(shownColumns);
        }
    }, [columns]);

    const getFinalSearchQuery = () => {
        if (globalQuery) {
            return globalQuery;
        }
    };

    const paginationLoader = (page) => {
        page > 1 ? setDataLoading(true) : setDataLoading(false);
    };

    const loadMoreLeads = (page) => {
        setHasMoreItems(false);
        paginationLoader(page);
        const finalQuery = getFinalSearchQuery();
        fetchLostLeadsConnect(page, lostFrom, finalQuery).then(resp => {
            if (resp && resp.data) {
                const { data: { detail: { result } } } = resp;
                if (result && result.length === NUMBERS.TWENTY) {
                    setHasMoreItems(true);
                }
            }
            setDataLoading(false);
            if (globalQuery) {
                resetGlobalSearchConnect();
            }
        });

    };

    const resetAndFetchNewListing = () => {
        scrollRef.current.pageLoaded = 0;
        setHasMoreItems(true);
        resetLostLeadsByKeyConnect(RESET_REDUCER_STATE_KEYS.IS_LOADING, true);
        resetLostLeadsByKeyConnect(RESET_REDUCER_STATE_KEYS.DATA, []);
    };

    const getColumn = (key, value, rowDetails, index) => {
        const tableType = getTableColumnTypeByKey(key);
        switch (tableType) {
            case ColumnTypeMapping.STRING:
                return <span>{value}</span>;
            case ColumnTypeMapping.LINK:
                return <Link to='#'>{value}</Link>;
            case ColumnTypeMapping.COMPONENT:
                return getColumnComponent(key, rowDetails, scrollRef, setHasMoreItems, index);
            default: break;
        }
    };

    const renderActionsColumn = (rowDetails) => {
        return <Reschedule key={rowDetails.appointment} rowDetails={rowDetails} scrollRef={scrollRef} setHasMoreItems={setHasMoreItems} />;
    };
    const getSerialNumber = (index) => {
        return index + 1;
    };
    
    const renderLeads = () => (
      <div className={styles.lostLeadWrapper}>
        <div className={styles.lostHeading}>
          <Back history={history} />
          <H2 text={PAGE_HEADING.LOST_LEADS} />
          {fromPage && <p className={styles.pageDirection}>({fromPage})</p>}
        </div>
        <Table>
          {visibleColumns.length > 0 &&
            <thead>
              <TableRow>
                {visibleColumns.map((item, index) =>
                  <TableHeader key={index} headerKey={item.key} headerValue={item.value} />
                            )}
                {(fromPage === APPOINTMENT_TYPES_STRING.VISITED || fromPage === APPOINTMENT_TYPES_STRING.SCHEDULED) && <th><p>Actions</p></th>}
              </TableRow>
            </thead>
                }
          <TableBody>
            {
                        !isLoading && !error &&
                        data.map((item, index) => (
                          <TableRow key={`row-${index}`} >
                            <>
                              {
                                        visibleColumns.map((visCol, visColIndex) => {
                                            return (
                                              <TableColumn key={`col-${visColIndex}`} item={item} >
                                                {
                                                        getColumn(visCol.key, item[visCol.key], item, getSerialNumber(index))
                                                    }
                                              </TableColumn>
                                            );

                                        })
                                    }
                              {(fromPage === APPOINTMENT_TYPES_STRING.VISITED || fromPage === APPOINTMENT_TYPES_STRING.SCHEDULED) &&
                                <TableColumn>
                                  <div key={`tc-${index}`}>{renderActionsColumn(item)}</div>
                                </TableColumn>
                                    }
                            </>
                          </TableRow>
                        )
                        )
                    }
          </TableBody>
        </Table>
      </div>
    );

    return (
      <HocAccessController>
        <InfiniteScroll
                ref={scrollRef}
                pageStart={pageStart}
                loadMore={loadMoreLeads}
                hasMore={hasMoreItems}
            >
          {renderLeads()}
        </InfiniteScroll>
        <DataInformationMessage
                isLoading={isLoading}
                error={error}
                data={data}
                dataLoading={dataLoading}
            />
      </HocAccessController>
    );
};

export default LostLeads;
