import React, { useEffect, useState } from "react";
import CustomerLeadDetail from "../../components/customer-lead-detail";
import LeadActivityFeed from "../../components/lead-activity-feed";
import ProgressStep from "../../components/progress-step";
import styles from "./lead-details.module.scss";
import H2 from "../../components/h2";
import DataInformationMessage from "../../components/shared/data-information-message";
import Back from "../../components/back";
import Button from "../../components/shared/button";
import Popup from "../../components/popup/component";
import LostLeadNegoPopup from "../../components/lost-lead-nego-popup";
import { GLOBAL_SEARCH_STATES } from "../../utils/app-constants";
import { isPllUser, GetQueryString } from "../../utils/helper";
import HocAccessController from "../../components/hoc-access-controller";
const LeadDetail = ({
  match,
  leadDetails,
  fetchLeadDetailsConnect,
  resetLeadDetailsConnect,
  history,
  resetGlobalSearchConnect,
  globalSearch,
  resetApiNoAccessConnect
}) => {
  const { params: { appointmentId } } = match;
  const isSaleOnline = GetQueryString("isSaleOnline");
  const { isLoading, data, error } = leadDetails;
  const { globalQuery } = globalSearch;
  const isPll = isPllUser();
  const [losleadnegoOpen, setLosleadnegoOpen] = useState(false);
    
  useEffect(() => {
    resetApiNoAccessConnect();
    if (appointmentId) {
      fetchLeadDetailsConnect(appointmentId);
    }
    if (globalQuery) {
      resetGlobalSearchConnect();
    }
    return () => {
      resetLeadDetailsConnect();
    };
  }, []);

  const onLosleadnegoOpen = () => {
    setLosleadnegoOpen(true);
  };
  const onLosleadnegoClose = () => {
    setLosleadnegoOpen(false);
  };
  return (
    <HocAccessController>
      {data &&
      <div className={styles.detailsPage}>
        <div className={styles.headingWrapper}>
          <div className={styles.backHeadingWrapper}>
            <Back history={history} />
            <H2 text={`${data.customerName}, ${appointmentId}`} />
          </div>
          {data.state_name && data.state_name.toLowerCase() === GLOBAL_SEARCH_STATES.NEGOTIATION && data.isLostLeadV2Configured && !isPll
                        && (!data.isInventoryBuying || (!!data.isInventoryBuying && !!data.lostCTAFlag)) 
                        && <Button onClick={onLosleadnegoOpen} classNames="greyButton" ctaText="Lost" />}
        </div>
        <Popup isOpen={losleadnegoOpen} close={onLosleadnegoClose}>
          <LostLeadNegoPopup onClose={onLosleadnegoClose} data={data} history={history} />
        </Popup>
        <ProgressStep data={data}/>
        <div className={styles.LeadDetail}>
          <CustomerLeadDetail data={data}  isSaleOnline={isSaleOnline}/>
          <LeadActivityFeed appointmentId={appointmentId} />
        </div>
      </div>
      }
      <DataInformationMessage
        isLoading={isLoading}
        error={error}
        data={data}
      />

    </HocAccessController>
  );
};

export default LeadDetail;
