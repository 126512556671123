import React, { useEffect } from "react";
import H2 from "../h2/component";
import styles from "./follow-up-history.module.css";
import Table from "../shared/table";
import TableBody from "../shared/table-body";
import TableRow from "../shared/table-row";
import TableColumn from "../shared/table-column";
import TableHeader from "../shared/table-header";
import priceFormatter from "../../utils/helpers/price-formatter";
import { isBikeSource } from "../../utils/helper";

const FollowUpHistory = ({
  fetchFollowUpHistoryConnect,
  appointmentId,
  followUpHistory,
  resetFollowUpHistoryConnect,
  showPriceColum
}) => {
  const { detail, isLoading } = followUpHistory;
  const isBike = isBikeSource();
  useEffect(() => {
    fetchFollowUpHistoryConnect(appointmentId);
    return (() => {
      resetFollowUpHistoryConnect();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (

    <div className={styles.leaddata}>
      <div className={styles.heading}>
        <H2 text={"FOLLOW-UP HISTORY"} />
      </div>
      <div className={styles.history}>
        <Table>
          <thead>
            <TableRow>
              <TableHeader headerKey={'S.No'} headerValue={'S.No'} />
              <TableHeader headerKey={'Date'} headerValue={'DATE'} />
              {showPriceColum && <TableHeader headerKey={'CEP'} headerValue={'CEP'} />}
              {!isBike && showPriceColum && <TableHeader headerKey={'Price Offered'} headerValue={'PRICE OFFERED'} />}
              <TableHeader headerKey={'Disposition'} headerValue={'DISPOSITION'} />
              <TableHeader headerKey={'Remarks'} headerValue={'REMARKS'} />
            </TableRow>
          </thead>
          <TableBody>
            {
              detail.length > 0 &&
                            detail.map((item, index) => {
                              return (
                                <TableRow>
                                  <TableColumn >
                                    {index + 1}
                                  </TableColumn>
                                  <TableColumn >
                                    {item.created_at}
                                  </TableColumn>
                                  {showPriceColum && <TableColumn >
                                    {!!item.expected_customer_price ? priceFormatter(item.expected_customer_price, true) : "0"}
                                  </TableColumn>}
                                  {!isBike && showPriceColum && <TableColumn >
                                    {!!item.price_offered ? priceFormatter(item.price_offered, true) : "0"}
                                  </TableColumn>}
                                  <TableColumn >
                                    {item.disposition}
                                  </TableColumn>
                                  <TableColumn >
                                    {item.remarks}
                                  </TableColumn>
                                </TableRow>

                              );
                            })
            }
          </TableBody>
        </Table>
        {
          isLoading && <div className={styles.noResult}>Loading</div>
        }
        {
          !isLoading && detail.length === 0 && <div className={styles.noResult}>No previous follow-ups</div>
        }
      </div>
    </div>
  );
};

export default FollowUpHistory;
