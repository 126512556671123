import { createReducer } from "reduxsauce";
// import Types from "./types";

export const INITIAL_STATE = {
  data: null,
  activeChannel: null,
  filteredChannels: null,
  selectedStores: null,
  totalUnreadCount: 0,
  error: null,
  isPosting: true
};

// export const fetchLogin = (state = INITIAL_STATE) => {
//     return { ...state, isPosting: true };
// };

// export const postLoginSuccess = (state = INITIAL_STATE, { data }) => {
//     // console.log("postLoginSuccess, channels: ", data);
//     // const {channelList, totalUnreadCount} = data || {};
//     // return { ...state, data:channelList, totalUnreadCount, activeChannel: null, filteredChannels: null, isPosting: false, error: null };
// };

// export const postLoginFailure = (state = INITIAL_STATE, { error }) => {
//     return { ...state, error, isPosting: false };
// };

// export const resetLogin = () => {
//     return INITIAL_STATE;
// };

// export const updateChannelSuccess = (state = INITIAL_STATE, { data }) => {
//     // console.log('updateChannelSuccess',data);
//     // const {channel, event} = data;
//     // console.log('updateChannelSuccess', event.type);
//     const channelData = channel.state._channel;
//     const channelId = channelData.id;
//     let tot_unreadCount = 0;
//     // const updateChannel = (event.type === 'message.new' || event.type === 'message.read')  ? channelData : null;
//     const updatedChannels = !!state.data && state.data.map((item)=>{

//         if(item.id === channelId){
//             return channel;
//         }else{
//             return item;
//         }
//     });
//     // Unreadcount update after channels update
//     if (!!updatedChannels){
//         for (const c of updatedChannels){
//             tot_unreadCount = tot_unreadCount + c.state.unreadCount;
//         }
//     }
//     return { ...state, data : updatedChannels, totalUnreadCount: tot_unreadCount, isPosting: false, error: null };
// };

// export const filterChannels = (state = INITIAL_STATE, { data:search }) => {
//     let filteredChannels = null;
    
//     if(search){
//         filteredChannels = state.data.filter((item)=>{
//             const searchStr = `${item.data.raName} ${item.data.model} ${item.data.variant} ${item.data.year}`.toLowerCase();
//             return item.id.includes(search) || searchStr.includes(search.toLowerCase());
//         });
//     }
    
//     return { ...state, filteredChannels, error: null };
// };

// export const setActiveChannel = (state = INITIAL_STATE, { data }) => {
//     const activeChannel = !!state && !!state.data && state.data.find((item) =>  item.id === `APT_${data}`);
//     return { ...state, activeChannel};
// };

export const setSelectedStores = (state = INITIAL_STATE, { data }) => {
  // console.log("setSelectedStores: ", data);
  return { ...state, selectedStores: data };
};

export const HANDLERS = {
  // [Types.FETCH_LOGIN]: fetchLogin,
  // [Types.POST_LOGIN_SUCCESS]: postLoginSuccess,
  // [Types.POST_LOGIN_FAILURE]: postLoginFailure,
  // [Types.RESET_LOGIN]: resetLogin,
  // [Types.UPDATE_CHANNEL_SUCCESS] : updateChannelSuccess,
  // [Types.SET_ACTIVE_CHANNEL] : setActiveChannel,
  // [Types.FILTER_CHANNELS]: filterChannels,
  // [Types.SELECTED_STORES]: setSelectedStores
};

export default createReducer(INITIAL_STATE, HANDLERS);
