import React from "react";
import styles from "./call-log-type.module.css";

const CallLogType = ({ type }) => {

  const getType = () => {
    if (type && type.trim()) {
      switch (type.trim().toLowerCase()) {
        case "retail":
          return "Retail";
        default:
          return "PLL";
      }
    } else {
      return "";
    }
  };

  return (
    <p className={styles.logTypePil}>{getType()}</p>
  );
};

export default CallLogType;
