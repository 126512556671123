import { createTypes } from "reduxsauce";

export default createTypes(
  `
    RESET_LEAD_MENU_BY_KEY
`,
  {
    prefix: "leadmenu/"
  }
);
