import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Notifications from "./component";
import { resetNotificationnByKey } from "./actions";

const mapStateToProps = ({ notifications }) => ({ notifications });

const mapDispatchToProps = (dispatch) => bindActionCreators({
  resetNotificationnByKeyConnect: resetNotificationnByKey
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
