import React from "react";
import styles from "./customer-something-wrong.module.scss";
import SamethingWrong from "./images/samethingWrong.svg";

const CustomerSomethingWrong = () => {
  return (
    <div className={styles.errorContainer}>
      <img src={SamethingWrong} alt={"SamethingWrong"} />
      <p className={styles.header}>Something’s wrong here...</p>
      <p className={styles.subHeader}>Our smart minds are on it to solve the issue</p>
      <button className={styles.refreshCta} onClick={() => window.location.reload()}>Tap to Refresh</button>
    </div>
  );
};

export default CustomerSomethingWrong;
