import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CustomerOfferPriceJourneyHeader from "./component";
import { preCheckRaisePAI, fetchNegotiations, resetNegotiations, setCurrentPopup } from "../../components/negotiation-list/actions";

const mapStateToProps = ({
  negotiations,
  depreciation
}) => ({
  negotiations,
  depreciation
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  preCheckRaisePAIConnect: preCheckRaisePAI,
  setCurrentPopupConnect: setCurrentPopup,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CustomerOfferPriceJourneyHeader);
