import React from "react";
import styles from "./unauthorised-access.module.scss";
import { Link } from "react-router-dom";
import { getHomePageByRole } from "../../utils/helper";

const UnauthorizedAccess = () => {

  return (
    <>
      <div className={`${styles.textMiddle}`}>
        <h3>You are not authorized for this feature </h3>
        <Link to={getHomePageByRole()}>Click here</Link>
      </div>
    </>
  );
};

export default UnauthorizedAccess;
