import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  isLoading: true,
  registrationNumber: '',
  registrationCarDetails: {},
  dealer: {},
  dealerCitylist: [],
  error: null
};

export const updateRegistrationNumber = (state = INITIAL_STATE, { regNumber }) => {
  return { ...state, registrationNumber: regNumber};
};

export const updateRegistrationCarDetails = (state = INITIAL_STATE, { data }) => {
  return { ...state, registrationCarDetails: data};
};

export const resetRegistrationCarDetails = (state = INITIAL_STATE) => {
  return { ...state, registrationCarDetails: {}};
};

export const updateDealer = (state = INITIAL_STATE, { data }) => {
  return { ...state, dealer: data, error: null };
};

export const fetchCarDetailsSuccess = (state = INITIAL_STATE, { data }) => {
  return { ...state, isLoading: false, registrationCarDetails: data, error: null };
};

export const fetchCarDetailsFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, isLoading: false, registrationCarDetails: {}, error };
};

export const fetchDealerCitySuccess = (state = INITIAL_STATE, { data }) => {
  return { ...state, isLoading: false, dealerCitylist: data, error: null };
};

export const fetchDealerCityFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, isLoading: false, registrationCarDetails: {}, error };
};

export const resetLeadData = () => {
  return INITIAL_STATE;
};

export const HANDLERS = {
  [Types.UPDATE_SELECTED_DEALER]: updateDealer,
  [Types.RESET_LEAD_DATA]: resetLeadData,
  [Types.FETCH_CAR_DETAILS_SUCCESS]: fetchCarDetailsSuccess,
  [Types.FETCH_CAR_DETAILS_FAILURE]: fetchCarDetailsFailure,
  [Types.UPDATE_REG_NUMBER]: updateRegistrationNumber,
  [Types.UPDATE_CAR_DETAILS]: updateRegistrationCarDetails,
  [Types.RESET_CAR_DETAILS]: resetRegistrationCarDetails,
  [Types.FETCH_DEALER_CITY_SUCCESS]: fetchDealerCitySuccess,
  [Types.FETCH_DEALER_CITY_FAILURE]: fetchDealerCityFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
