import React, { useState, useEffect } from "react";
import H2 from "../../components/h2";
import styles from "./cj-allocation-status-popup.module.scss";

const CjAllocationStatusPopup = ({ cjAllocationStatus, fetchCjAllocationStatusConnect, count, homeInspectionFilters }) => {
  const [allocationStatusOpen, setAllocationStatusOpen] = useState(false);
  const onAllocationStatusOpen = () => {
    setAllocationStatusOpen(true);
  };

  const { totalUnAllocatedCount, cjAllocationStausList } = cjAllocationStatus;
  const [dataList, setDataList] = useState([]);
  const [recordCount, setRecordCount] = useState(0);
  const onAllocationStatusClose = () => {
    setAllocationStatusOpen(false);
  };
  const { hisfilterQuery } = homeInspectionFilters;
  const [showInspectedColum, setShowInspectedColumn] = useState((hisfilterQuery === "&leadDays=1") ? true : false);
  useEffect(() => {
    // fetchCjAllocationStatusConnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!cjAllocationStausList && cjAllocationStausList.length > 0) {
      setDataList(cjAllocationStausList);
      setRecordCount(cjAllocationStausList.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(cjAllocationStausList)]);
  return (
    <div className={styles.allocationStatusPopup}>
      <div className={styles.allocationStatusPopupHeading}>
        <H2 text={"cj allocation status"} />
        <div className={styles.notcj}>
          <p>{count}<span>leads on </span></p>
          <span>which CJ not allocated</span>
        </div>
      </div>
      <table cellPadding="0" cellSpacing="0" width="100%">
        <thead>
          <tr>
            <th>CJ Name</th>
            <th>Allocated</th>
            {showInspectedColum && <th>Inspected</th>}
          </tr>
        </thead>
        <tbody>
          {
            dataList.map((item) => {
              return (
                <tr>
                  <td>{item.firstName} {item.lastName}</td>
                  <td>{item.count}</td>
                  {showInspectedColum && <td>{item.inspectedCount}</td>}
                </tr>
              );

            })
          }
        </tbody>
      </table>

    </div>
  );
};

export default CjAllocationStatusPopup;
