import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CjExecution from "./component";
import withLayout from "../../components/layout/component";
import { fetchCjAllocationStatus } from "../../components/cj-allocation-status/actions";
import {resetApiNoAccess} from "../../components/hoc-access-controller/actions";
const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchCjAllocationStatusConnect: fetchCjAllocationStatus,
  resetApiNoAccessConnect: resetApiNoAccess
}, dispatch);

const mapStateToProps = ({
  leadMenu
}) => ({
  leadMenu
});

export default connect(mapStateToProps, mapDispatchToProps)(withLayout(CjExecution));