import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import NegotiationFilter from "./component";
import { updateActionRequiredCount, updateFilterQuery, updateWithCustomerCount } from "./actions";
import { resetRangeByKey } from "../calendar-range/actions";

const mapStateToProps = ({
  negotiationFilter,
  negotiations
}) => ({
  negotiationFilter,
  negotiations

});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateFilterQueryConnect: updateFilterQuery,
  resetRangeByKeyConnect: resetRangeByKey,
  updateWithCustomerCountConnect: updateWithCustomerCount,
  updateActionRequiredCountConnect: updateActionRequiredCount
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NegotiationFilter);
