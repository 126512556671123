import Types from "./types";
import { AppointmentService } from "../../service";

const checkInAppointment = (params) => dispatch => {
  return AppointmentService.checkInAppointment(params)
    .then(response => {
      const { data: { detail } } = response;
      dispatch(checkInPopupSuccess(detail));
      return response;

    })
    .catch(error => {
      dispatch(checkInPopupFailure(error));
      throw error;
    });
};

const checkInPopupSuccess = (data) => ({
  type: Types.CHECK_IN_POPUP_SUCCESS,
  data
});

const checkInPopupFailure = (error) => ({
  type: Types.CHECK_IN_POPUP_FAILURE,
  error
});

const resetCheckInPopupByKey = (key, value) => ({
  type: Types.RESET_CHECK_IN_POPUP_KEY,
  data: { key, value }
});

const resetCheckInPopup = () => ({
  type: Types.RESET_CHECK_IN_POPUP
});

export {
  checkInAppointment,
  resetCheckInPopupByKey,
  resetCheckInPopup
};
