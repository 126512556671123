import { createTypes } from "reduxsauce";

export default createTypes(
  `
    FETCH_CUSTOMER_OFFER_SUCCESS
    FETCH_CUSTOMER_OFFER_FAILURE
`,
  {
    prefix: "customerofferprice/"
  }
);
