import React, {useEffect} from "react";
import UnauthorizedAccess from "../../components/unauthorized-access";
const HocAccessController = ({
  apiNoAccess,
  ResetApiNoAccessConnect,
  children
}) => {
  useEffect(()=>{
    return () => {
      ResetApiNoAccessConnect();
    };
  }, []);
  return (
    <> {
            apiNoAccess ? 
              <UnauthorizedAccess />: 
              <> 
                {children}
              </>
    }   
    </>
  );
};
export default HocAccessController;
