import Button from "../shared/button/component";
import { ACTIONS_BUTTON } from "../../utils/app-constants";
import classes from "./approve-reject-price-quote.module.css";
import { AppointmentService } from "../../service";import { showToastMessages } from "../../utils/helper";
;

export default function ApproveRejectPriceQuote({rowDetails, resetAndFetchNewListing, updatereviewInspectionFilterQueryConnect}) {
    const { negotiatedPrice, procurementStatus, appointment, dealerCode } = rowDetails;
    if (procurementStatus?.toUpperCase() === "PROCURED") {
        return (
            <span className={classes.procurementStatus}>Approved</span>
        );
    } else if (procurementStatus?.toUpperCase() === "OPT_REJECTED") {
        return (
            <span className={classes.procurementStatus}>Rejected</span>
        );
    } else if (procurementStatus?.toUpperCase() !== "OPT_INITIATED") {
        return <span className={classes.procurementStatus}>{procurementStatus?.toUpperCase()}</span>;
    }
    const handleApproveQuote = async () => {
        try {
            const param = {
                "appointmentId": appointment,
                "status": "PROCURED",
                "price": negotiatedPrice,
                "dealerCode": dealerCode
            };
            AppointmentService.ApprovePaymentPriceQuote(param)
            .then(() => {
                updatereviewInspectionFilterQueryConnect("platform");
                resetAndFetchNewListing();
                showToastMessages("Price quote approved successfully", true);
            })
            .catch(() => showToastMessages('Error in approving the price quote', false));
        } catch (error) {
            console.log("error while call approve API", error);
        }
    };

    const handleRejectQuote = async () => {
        try {
            const param = {
                "appointmentId": appointment,
                "status": "OPT_REJECTED",
                "dealerCode": dealerCode
            };
            AppointmentService.RejectPaymentPriceQuote(param)
            .then(() => {
                updatereviewInspectionFilterQueryConnect("platform");
                resetAndFetchNewListing();
                showToastMessages("Price quote rejected successfully", true);
            })
            .catch(() => showToastMessages('Error in approving the price quote', false));
        } catch (error) {
            console.log("error while call reject API", error);
        }
    };
    
    return (
        <div className={classes.actionButtons}>
            <Button ctaText={ACTIONS_BUTTON.APPROVE}
              classNames="blueButton smallCta"
              onClick={handleApproveQuote}
            />
            <Button ctaText={ACTIONS_BUTTON.REJECT}
              classNames="blueButton smallCta"
              style={{ background: "red" }}
              onClick={handleRejectQuote}
            />
        </div>
    );
};