import React, { useState } from "react";
import CallLogRecord from "../../components/shared/images/recording.svg";
import styles from "./call-log-recording.module.scss";
import { redirectToUrl, showToastMessages } from "../../utils/helper";
import { AppointmentService } from "../../service";
import { getGatewayUrl } from "../../utils/url-constants";
import { getSessionToken } from "@descope/react-sdk";

const CallLogRecording = ({ recordingUrl, callSid, callType }) => {

  const [rcrdngUrl, setRcrdngUrl] = useState(recordingUrl || "");
  const [isLoading,setIsLoading] = useState(false);

  const getUrl = () => {
    if (callSid) {
      AppointmentService.getRecordingUrl(callSid)
        .then(resp => {
          const { data: { detail: { recordingUrl } } } = resp;
          setRcrdngUrl(recordingUrl || "");
        })
        .catch(err => {
          const { detail: { message } } = err;
          showToastMessages(message, false);
        });
    }
  };

  const redirectWithBlobUrl = async(rcrdngUrl) =>{
        if (!isLoading) {
            try {
                setIsLoading(true);
                const response = await fetch(
                    `${getGatewayUrl()}lms-comm/api/exotel/recording-info?url=${rcrdngUrl}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "x-auth-key": `${getSessionToken()}`
                        },
                    }
                );

                const blob = await response.blob();
                const url = URL.createObjectURL(blob);
                redirectToUrl(url);
            } catch (error) {
                console.error("Error fetching audio data:", error);
            } finally {
                setIsLoading(false);
            }
        }
  };

  return (
    <div className={styles.callLogRecording}>
       {isLoading && <p>Loading....</p>} 
      {!isLoading && rcrdngUrl && <img src={CallLogRecord} alt="" onClick={() => redirectWithBlobUrl(rcrdngUrl)} />}
      {!rcrdngUrl && callType && callType.trim().toLowerCase() === 'completed' && <p className={styles.textRecording} onClick={getUrl}>Get Recording</p>}
    </div>
  );
};

export default CallLogRecording;
