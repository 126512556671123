import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LeadCheckIn from "./component";
import withLayout from "../../components/layout/component";
import { fetchCheckInLeads, resetCheckInLeads, resetCheckInLeadsByKey } from "./actions";

const mapStateToProps = ({
  leadCheckIn
}) => ({
  leadCheckIn
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchCheckInLeadsConnect: fetchCheckInLeads,
  resetCheckInLeadsConnect: resetCheckInLeads,
  resetCheckInLeadsByKeyConnect: resetCheckInLeadsByKey
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withLayout(LeadCheckIn, false));
