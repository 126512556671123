import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EvaluatorRetailView from "./component";
import {fetchRetailView, fetchEvaluatorView, resetEvaluatorByKey} from "../../pages/evaluator-view/actions";

const mapStateToProps = ({
  evaluator
}) => ({evaluator});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchRetailViewConnect:fetchRetailView,
  fetchEvaluatorViewConnect:fetchEvaluatorView,
  resetEvaluatorByKeyConnect:resetEvaluatorByKey
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EvaluatorRetailView);
