import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import styles from "./dashboard.module.scss";
import {HEADER_DASHBOARD_MASTER_DATA} from "../../utils/app-constants";
import { trackLMSEvents } from "../../utils/helpers/tracking-events";
import { getUid } from "../../utils/helper";

const Dashboard = (props) => {
  const {data} = props;
  const openMenu = () => {
    setShowLeadsMenu(true);
  };
  const closeMenu = () => {
    setShowLeadsMenu(false);
  };
   
  const [showLeadsMenu, setShowLeadsMenu] = useState(false);
  const [subMenus, setSubMenus] = useState([]);
  useEffect(()=>{
    if (data.child) {
      const childs = data.child.map((item)=> {
        const uiElement = HEADER_DASHBOARD_MASTER_DATA[item.pKey];
        return {
          ...item,
          ...uiElement
        };
      });
      const childMenus = childs.sort((a,b)=> a.displayOrder - b.displayOrder);
      setSubMenus(childMenus);
    }
  },[]);

  const linkClickHandler = (label) =>{
    if(label == 'incentive'){
      trackLMSEvents("my_incentive_clicked", {
        eventLabel: getUid(),
      });
    }
  };

  return (
    <div className={styles.dashboardWrapper} onMouseLeave={closeMenu} key={data.pKey}>
      <p onMouseEnter={openMenu}>{data.pText}</p>
      <div className={`${styles.leadsDropdown} ${showLeadsMenu ? styles.active : ''}`}>
        <ul> 
          {
            subMenus.map((item) => (
              <li key={item.pKey} className={styles.active} onClick={()=>linkClickHandler(item.pKey)}>
                <Link target={item.target} to={item.url}>{item.pText}</Link>
              </li>
            ))
          }
        </ul>
      </div>
    </div>
  );
};

export default Dashboard;
