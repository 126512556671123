import React, { useEffect, useState } from "react";
import { getFromLocalStorage, saveToLocalStorage } from "../../utils/helper";
import { LOCAL_STORAGE_KEYS, LEAD_TYPE } from "../../utils/app-constants";
import styles from "./individuals.module.scss";
import { AppointmentService } from "../../service";

const Individuals = ({
  onSelectionChange
}) => {

  const localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
  const idLmsStore = localData ? JSON.parse(localData)[LOCAL_STORAGE_KEYS.LMS_STORE_ID] : "";
  const storeName = localData ? JSON.parse(localData)[LOCAL_STORAGE_KEYS.STORE_NAME] : "";
  const individualsList = getFromLocalStorage(LOCAL_STORAGE_KEYS.INDIVIDUALS_LIST);
  const selectedUid = getFromLocalStorage(LOCAL_STORAGE_KEYS.INDIVIDUAL_ID);
  const selectedUName = getFromLocalStorage(LOCAL_STORAGE_KEYS.INDIVIDUAL_NAME);
  const [data, setData] = useState(individualsList ? JSON.parse(individualsList) : []);
  const [isSelfAssigned, setIsSelfAssigned] = useState(false);

  const getName = (associate) => {
    const { firstName, lastName } = associate;
    return `${firstName} ${lastName}`;
  };

  useEffect(() => {
    if (data.length === 0) {
      if (idLmsStore) {
        AppointmentService.getRetailAssociates(idLmsStore)
          .then(response => {
            const { data: { detail } } = response;
            saveToLocalStorage(LOCAL_STORAGE_KEYS.INDIVIDUALS_LIST, JSON.stringify(detail));
            setData(detail);
          })
          .catch(error => {
            console.log('Error fetching RA List', error);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getActiveClass = (item) => {
    return item.idLmsUsers === parseInt(selectedUid);
  };

  const onIndividualclick = (item) => {
    const existingLeadType = getFromLocalStorage(LOCAL_STORAGE_KEYS.LEADS);
    const { idLmsUsers } = item;
    if (existingLeadType !== LEAD_TYPE.INDIVIDUAL || selectedUid !== idLmsUsers) {
      saveToLocalStorage(LOCAL_STORAGE_KEYS.LEADS, LEAD_TYPE.INDIVIDUAL);
      saveToLocalStorage(LOCAL_STORAGE_KEYS.INDIVIDUAL_ID, idLmsUsers);
      saveToLocalStorage(LOCAL_STORAGE_KEYS.INDIVIDUAL_NAME, getName(item));
      onSelectionChange();
    }
  };

  const onSelfAssignedLeads = () => {
    setIsSelfAssigned(true);
    saveToLocalStorage(LOCAL_STORAGE_KEYS.LEADS, LEAD_TYPE.INDIVIDUAL);
    saveToLocalStorage(LOCAL_STORAGE_KEYS.INDIVIDUAL_ID, selectedUid);
    saveToLocalStorage(LOCAL_STORAGE_KEYS.INDIVIDUAL_NAME, selectedUName);
    onSelectionChange();
  };

  return (
    <>
      <div>
        {/* <Button  */}
        <span>{storeName}</span>
        <ul className={styles.subChild}>
          {data.length === 0 && 
          <li 
            key={'self_leads'} 
            className={isSelfAssigned ? styles.active : ''} 
            onClick={()=> onSelfAssignedLeads()}>My Assigned Leads
          </li>
          }
          {
            data && data.map(item => {
              return <li key={item.idLmsUsers} className={getActiveClass(item) ? styles.active : ""}
                onClick={() => onIndividualclick(item)}>{getName(item)}</li>;
            })
          }
        </ul>
      </div>

    </>
  );
};

export default Individuals;
