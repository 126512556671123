import React, {useEffect, useRef, useState} from "react";
import styles from "./so-cancel-appointment-popup.module.scss";
import H2 from "../h2/component";
import Button from "../shared/button/component";
import SelectComponent from "../shared/select/component";
import { AppointmentService } from "../../service";
import { showToastMessages } from "../../utils/helper";
const respData = {
  "type": "Cancel-appointment",
  "title": "Cancel appointment",
  "status": 200,
  "detail": {
    "cancelReason": [
      {
        "title": "I want to sell but not ready to sell at this price",
        "remarks": false
      },
      {
        "title": "Documents not available",
        "remarks": false
      },
      {
        "title": "I want to buy a car",
        "remarks": false
      },
      {
        "title": "I dont want to sell now",
        "remarks": false
      },
      {
        "title": "My reason is not listed",
        "remarks": true
      }
    ]
  }
};

const SoCancelAppointmentPopup = ({onClose, rowDetails, updateDataList}) => {
  const [remarks, setRemarks] = useState('');
  const [cancelReasons, setCancelReasons] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCancelling, setIsCancelling] = useState(false);
  const [selectedCancelReason, setSelectedCancelReason] = useState();
    
  useEffect(()=>{
    setIsLoading(true);
    AppointmentService.getAppointmentCancelReasons().then((resp)=>{
      setCancelReasons(resp?.detail?.cancelReason);
    }).catch((err)=>{
      console.log({err: err});
      // const resp=respData;
      // setCancelReasons(resp?.detail?.cancelReason);
    }).finally(()=>{
      setIsLoading(false);
    });
  }, []);

  const onCancelAppointment = () => {
    if (!selectedCancelReason) {
      showToastMessages("Please select cancellation reason", false);
      return;
    } 
    if (!!selectedCancelReason && !!selectedCancelReason?.remarks) {
      if (!remarks) {
        showToastMessages("Please input remarks if any", false);
        return;
      }
    }
    setIsCancelling(true);
    AppointmentService.submitAppointmentCancelReason({
      "appointmentId": rowDetails?.appointment,
      "reason": !selectedCancelReason?.remarks ? selectedCancelReason?.title : remarks}).then((resp)=>{
      showToastMessages(resp?.message);
      onClose();
      setTimeout(()=>{
        updateDataList();
      }, 2000);
    }).catch((err)=>{
      showToastMessages(err?.detail || err, false);
    }).finally(()=>{
      setIsCancelling(false);
    });
  };
  return (
    <> {isLoading ? <div>
      <span>Loading...</span>
    </div> :
    <div className={styles.soCancelAppointmentPopup}>
      <div className={styles.heading}>
        <H2 text={"Cancel Appointment"} />
      </div>
      <ul className={styles.customerDesc}>
        <li>{rowDetails?.customerName}</li>
        <li>{`${rowDetails?.make} ${rowDetails?.model}`}</li>
        <li>{rowDetails?.year}</li>
      </ul>
      <div className={styles.inputWrap}>
        <SelectComponent
          name={"cancelation_reason"}
          placeholder={"Cancellation Reason"}
          optionsList={cancelReasons.map((item) => {
            return {
              label: item.title,
              value: item
            };
          })}
          onChange={(value)=>setSelectedCancelReason(value)}
        />
      </div>
      {!!selectedCancelReason?.remarks && <div className={styles.inputWrap}>
        <textarea
          id={`cancellation-remarks`}
          placeholder="Remarks if any"
          value={remarks}
          onChange={(e)=> setRemarks(e.target.value)}
        ></textarea>
      </div>}
      <div className={styles.ctaWrapper}>
        <Button classNames={"saveCta"} ctaText={"CANCEL"} onClick={onCancelAppointment} disabled={isCancelling}/>
      </div>
    </div>}
    </>
  );
};

export default SoCancelAppointmentPopup;
