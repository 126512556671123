import React, { useState } from "react";
import styles from "./payment-vehicle-status.module.scss";
import DoneIcon from "../../components/images/tokenDone.svg";
import WaitingIcon from "../../components/images/waitingIcon.svg";
// import FailedIcon from "../../components/images/paymentFailed.svg";
import StoreIcon from "../../components/images/storeIcon.svg";
// import HomeIcon from "../../components/images/homeIcons.svg";
import AllocateCjPopup from "../../components/allocate-cj-popup";
import { AppointmentService } from "../../service";
import Button from "../../components/shared/button";
import { ACTIONS_BUTTON } from "../../utils/app-constants";
import Popup from "../../components/popup";
import { showToastMessages } from "../../utils/helper";
import PaymentRaiseOcbPopup from "../payment-raise-ocb-popup/component";
// import { getOCBDetails } from "../../utils/mocApi";
import Timer from "react-compound-timer";
import PaymentGeneratePrPopup from "../payment-generate-pr-popup/component";
import priceFormatter from "../../utils/helpers/price-formatter";
import PaymentAcceptPricePopup from "../payment-accept-price-popup/component";

const PaymentVehicleStatus = ({ rowDetails, updateDataList, data }) => {
  const { carStatusSection, homeInspectionCj, 
    lastPaymentStatus, pickupStatus, appointment: appId, appointmentId, isReInspectionDone, isResendOtpApplicable, showOcbDetailsCTA = false } = rowDetails || {};
  const { daysToDealLost, storeVisibility, stages } = carStatusSection || {};
  const { daysSinceBought, todayDaysAllowed } = !!daysToDealLost ? daysToDealLost : {};
  const { stage1, stage2, stage1Success, stage2Success } = !!stages ? stages : {};
  const [allocateOpen, setAllocateOpen] = useState(false);
  const [ocbOpen, setOcbOpen] = useState(false);
  const [raiseOCBPopup, setRaiseOCBPopup] = useState(false);
  const [currentCjDetail, setCurrentCjDetail] = useState(null);
  const [ocbDetails, setOcbDetails] = useState();
  const [generatePRPopup, setGeneratePRPopup] = useState(false);
  const [acceptPricePopup, setAcceptPricePopup] = useState(false);
  const [ocbOpenning, setOcbOpenning] = useState(false);
  const appointment = appId || appointmentId;
  const onAllocateOpen = () => {
    try {
      AppointmentService.getAppointedCjDetail(appointment)
        .then(response => {
          const { data: { detail } } = response;
          setCurrentCjDetail(detail);
          setAllocateOpen(true);
        }).catch(error => {
          console.log(error);
          setAllocateOpen(true);
        });
    }
    catch (exception) {
      setAllocateOpen(true);
    }
  };

  const onRerendOTPClick = () => {
    if (appointment) {
      const payload = {
        "appointmentId": appointment
      };
      AppointmentService.postResendPickupOTP(payload)
        .then(response => {
          const { data: { detail } } = response;
          showToastMessages(detail.message, true);
        })
        .catch(error => {
          const { detail } = error;
          showToastMessages(detail.message, false);
        });
    }
  };

  const onAllocateClose = () => {
    setAllocateOpen(false);
  };
    
  const onOcbOpen = () => {
    setOcbOpenning(true);
    AppointmentService.getOCBDetails(appointment).then((resp)=>{
      // const mock = getOCBDetails();
      setOcbDetails(resp?.detail);
    }).catch((err)=>{
      setOcbDetails();
      showToastMessages(err?.detail || err, false);
    }).finally(()=>{
      setOcbOpen(true);
      setOcbOpenning(false);
    });
  };

  const onOcbClose = () => {
    setOcbOpen(false);
  };

  const onRaiseOCBOpen = () => {
    setRaiseOCBPopup(true);
  };

  const onRaiseOCBClose = () => {
    setRaiseOCBPopup(false);
    onOcbClose();
  };

  const onAcceptPriceOpen = () => {
    setAcceptPricePopup(true);
  };

  const onAcceptPriceClose = () => {
    setAcceptPricePopup(false);
    onOcbClose();
  };

  const onGeneratePROpen = () => {
    setGeneratePRPopup(true);
  };

  const onGeneratePROpenClose = () => {
    setGeneratePRPopup(false);
    onOcbClose();
  };

  const getTimerComponent = (timestamp) => {
    const initialTime = timestamp * 1000;
    return (
      <Timer
        initialTime={initialTime}
        direction="backward"
      >
        <span className={styles.ocbTimer}>
          <span><Timer.Hours /> HRS</span>
          <span><Timer.Minutes /> MIN LEFT</span>
        </span>
      </Timer>
    );
  };
    
  const showOCBDetailsComponent = () => {
    switch (ocbDetails.ocbSortOrder) {
      case 1:
        return (
               
          <div className={styles.raiseOcb}>
            <ul>
              <li>
                <span>Current Cars24 Price</span>
                <span>: ₹{priceFormatter(ocbDetails?.currentHB, true)}</span>
              </li>
              <li>
                <span>Best Cars24 Price</span>
                <span>: ₹{priceFormatter(ocbDetails?.bestHB, true)}</span>
              </li>
            </ul>
            <div className={styles.ctabox}>
              <Button ctaText={"RAISE OCB"}  onClick={onRaiseOCBOpen}/>
              <Popup isOpen={raiseOCBPopup} close={onRaiseOCBClose}>
                <PaymentRaiseOcbPopup rowDetails={rowDetails} ocbDetails={ocbDetails} close={onRaiseOCBClose}/>
              </Popup>
            </div>
          </div>
        );
      case 2:
        return (
          <div className={styles.ocbRuning}>
            <h6>OCB RUNNING AT</h6>
            <h5>₹{priceFormatter(ocbDetails?.expectedOneClickAmount, true)}</h5>
            {getTimerComponent(ocbDetails?.auction_over_time)}
          </div>
        );
      case 3:
        return (
          <div className={styles.generatePr}>
            <h5>Current Cars24 Price</h5>
            <h4>₹{priceFormatter(ocbDetails?.calculatedc24Quote, true)} <span>(valid till {ocbDetails?.c24quotExpiryTime})</span></h4>
            <h3>OCB Accepted</h3>
            <div className={styles.ctabox}>
              <Button ctaText={"Generate PR"}  onClick={onGeneratePROpen}/>
              <Popup isOpen={generatePRPopup} close={onGeneratePROpenClose}>
                <PaymentGeneratePrPopup rowDetails={rowDetails} ocbDetails={ocbDetails} close={onGeneratePROpenClose}/>
              </Popup>
            </div>
          </div>
        );
      case 4:
        return (
          <div className={styles.ocbExpred}>
            <h4>OCB EXPIRED FOR ₹ {priceFormatter(ocbDetails?.expectedOneClickAmount, true)}</h4>
            <h5>₹ 0</h5>
            <div className={styles.ctabox}>
              <Button ctaText={"RAISE OCB"} onClick={onRaiseOCBOpen}/>
              <Popup isOpen={raiseOCBPopup} close={onRaiseOCBClose}>
                <PaymentRaiseOcbPopup rowDetails={rowDetails} ocbDetails={ocbDetails} close={onRaiseOCBClose}/>
              </Popup>
            </div>
          </div>
        );
      case 5:
        return (
          <div className={styles.acceptPrice}>
            <p>Dealer's Negotiated Price</p>
            <h5>₹{priceFormatter(ocbDetails?.currentHB, true)}</h5>
            <div className={styles.ctabox}>
              <Button ctaText={"Accept Price"}  onClick={onAcceptPriceOpen}/>
              <Popup isOpen={acceptPricePopup} close={onAcceptPriceClose}>
                <PaymentAcceptPricePopup rowDetails={rowDetails} ocbDetails={ocbDetails} close={onAcceptPriceClose}/>
              </Popup>
            </div>
            <div className={styles.ocbRuningStatus}>
              <p>OCB RUNNING FOR <span>₹{priceFormatter(ocbDetails?.expectedOneClickAmount, true)}</span></p>
              {getTimerComponent(ocbDetails?.auction_over_time)}
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className={styles.paymentVehicleStatus}>
      <p className={styles.stockStatus}>{stage1}</p>
      {!!stage1 && stage1 !== "Stocked-in" && !!daysSinceBought && !!todayDaysAllowed &&
      <p className={styles.lostDeal}>Days to deal lost <h6 className={styles.red}>{daysSinceBought}/{todayDaysAllowed}</h6></p>
      }
      {stage1 && !!stage2 && <div className={styles.tokanStatus}>
        <ul>
          <li className={stage2Success ? styles.deliveryBorder : styles.deliveryBorderFailed}>
            <img src={stage1Success ? DoneIcon : WaitingIcon} alt="done" />
            <h4>{stage1}</h4>
          </li>
          {!!stage2 && <li>
            <img src={stage2Success ? DoneIcon : WaitingIcon} alt="waiting" />
            <h4>{stage2}</h4>
          </li>
          }
        </ul>
      </div>
      }
      {!!stage1 && stage1 === "Stock-in Pending" && <div className={styles.waitingCase}>
        <img src={WaitingIcon} alt="waiting" />
        <p className={styles.txt}>{"Waiting for Customer to deliver Car"}</p>
      </div>
      }
      <div>
        {
                    !!isResendOtpApplicable ? <button className={styles.allocatecj} onClick={onRerendOTPClick}><span className={styles.text}>Resend OTP</span></button> : ''
        }
      </div>
      <div className={styles.ocbDetails}>
        {/* it should double  */}
        {!!showOcbDetailsCTA && <div> 
          <button className={styles.ocbDetailsText} onClick={onOcbOpen} disabled={ocbOpenning} >OCB Details</button>
          {!!ocbDetails && <Popup isOpen={ocbOpen} close={onOcbClose}>
            {showOCBDetailsComponent()}
          </Popup>}
        </div>}
        {!!storeVisibility && storeVisibility === "HI" &&
        <div className={styles.allocatecj}>
          {((!!lastPaymentStatus || lastPaymentStatus === "") && (homeInspectionCj === undefined || homeInspectionCj === null))
                            && (pickupStatus === "" || pickupStatus === null) && <button className={styles.allocatecj} onClick={onAllocateOpen}><span className={styles.text}>{ACTIONS_BUTTON.ALLOCATE_CJ}</span></button>
          }
          {
            (!!lastPaymentStatus || lastPaymentStatus === "") && !!homeInspectionCj &&
            <>
              <div>
                {isReInspectionDone === 0 && (pickupStatus === "" || pickupStatus === null) &&
                <button className={styles.allocatecj} onClick={onAllocateOpen}><span className={styles.text}>Reallocate CJ</span></button>
                }
              </div>
            </>
          }

        </div>
        }
      </div>
      <div className={styles.stores}>
        <img src={StoreIcon} alt="store" />
        {storeVisibility}
      </div>
      <Popup isOpen={allocateOpen} close={onAllocateClose}>
        <AllocateCjPopup onClose={onAllocateClose} rowDetails={rowDetails} data={data} updateDataList={updateDataList} currentDetail={currentCjDetail} />
      </Popup>
    </div>
  );
};

export default PaymentVehicleStatus;
