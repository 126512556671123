import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ChargesHoldbacks from "./component";
import { fetchChargesHoldback } from "./actions";
import { fetchCustomerOfferDetails } from '../../pages/customer-offer-price/actions';

const mapStateToProps = ({
  chargesHoldbacks,
  customerOffer
}) => ({
  chargesHoldbacks,
  customerOffer
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  chargesHoldbackConnect: fetchChargesHoldback,
  fetchCustomerOfferDetailsConnect: fetchCustomerOfferDetails
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChargesHoldbacks);
