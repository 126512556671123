import { createTypes } from "reduxsauce";

export default createTypes(
  `
    FETCH_RA_TOKEN_SUCCESS
`,
  {
    prefix: "paymentinitiatetoken/"
  }
);
