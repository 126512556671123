import React from "react";
import styles from "../../pages/customer-journey/customer-journey.module.scss";
import Button from "../../components/shared/button";
import MinuteTimer from "../../components/shared/minute-timer/component";
import SecondsTimer from "../../components/shared/seconds-timer/component";
import RoundedSign from "../../components/images/rounded.svg";
import C24QuotePopup from "../../components/c24-quote-popup/component";
import Popup from "../../components/popup/component";
import CountdownTimer from "../../components/shared/countdown-timer/component";
import { NUMBERS } from "../../utils/app-constants";
import { withRouter } from 'react-router-dom';

const DepriciationEnterOtp = ({ appointmentId, negotiationData, fetchNegotiations, history, setShowCustomerOfferPriceJourney, setShowDepReportJourney }) => {
  const countdownTime = NUMBERS.FIVE * NUMBERS.SIXTY * NUMBERS.THOUSAND;
  const [ resendDisabled, setResendDisabled ] = React.useState(true);
  const [currentTime, setCurrentTime] = React.useState(countdownTime);
  const [showOtpPopUp, setShowOtpPopUp] = React.useState(false);
      
  React.useEffect(() => {
    if (appointmentId) {
      window.addEventListener("beforeunload", onTabRefreshOrClose);
      addTimer();
    }
    return () => {
      window.removeEventListener("beforeunload", onTabRefreshOrClose);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTabRefreshOrClose = () => {
    let timerStartTime = parseInt(localStorage.getItem(`depTimerStart${appointmentId}`));
    if(timerStartTime){
      localStorage.setItem(`depTimerPause${appointmentId}`, Date.now());
    }
  };

  const addTimer = () => {
    if(resendDisabled){
      let timerStartTime = parseInt(localStorage.getItem(`depTimerStart${appointmentId}`));
      let timerPauseTime = parseInt(localStorage.getItem(`depTimerPause${appointmentId}`));
      if(timerPauseTime){
        let diffTime = countdownTime - (timerPauseTime - timerStartTime);
        if(diffTime < countdownTime && diffTime > 0){
          setCurrentTime(diffTime);
        }else{
          setResendDisabled(false);
        }
      }else{
        localStorage.setItem(`depTimerStart${appointmentId}`, Date.now());
      }
    }
  };

  const onClickEnterOTP = () => {
    if(negotiationData.data.length){
      setShowOtpPopUp(true);
    }
  };

  const handleAfterEnterOtp = () => {
    let queryParams = new URLSearchParams(window.location.search);
    queryParams.set("showOtpUnlockTimer", "false");
    queryParams.set("showreport", "charge");
    history.push(window.location.pathname+"?"+queryParams.toString());
    fetchNegotiations();
    setShowCustomerOfferPriceJourney(true);
    setShowDepReportJourney(false);
    localStorage.removeItem(`depTimerStart${appointmentId}`);
    localStorage.removeItem(`depTimerPause${appointmentId}`);
  };

  const renderCongratulationsHeader = () => {
    return (
      <div className={styles.congratulationsFlag}>
        <h6>Congratulations</h6>
        <h4>You’ve got the CARS24 assured price</h4>
      </div>
    );
  };

  const renderResendOTPSection = () => {
    return (
      <div className={styles.resendOtpSection}>
        <div className={styles.countDownTextWrapper}>
          <div className={styles.countDown}>
            {resendDisabled ? <CountdownTimer 
              initialTime={currentTime} 
              checkpoints={[
                {
                  time: 0,
                  callback: () => {
                    setResendDisabled(false);
                  },
                }
              ]}
            >
              <MinuteTimer /> : <SecondsTimer /> 
              <p>min left</p>
            </CountdownTimer>:
            <img src={RoundedSign} alt="RoundedSign" />}
          </div>
          <div className={styles.text}>
            <h4>Getting the highest price for your car in the market!!</h4>
            <p>Meanwhile, please describe the detailed depreciation report & how it affects the final offered price to the customer.</p>
          </div>
        </div>
        <div>
          <Button disabled={resendDisabled} classNames={"blueButton"} ctaText={"ENTER OTP"} onClick={onClickEnterOTP} />
        </div>
      </div>
    );
  };

  return (
    <>
      {renderCongratulationsHeader()}
      <div className={styles.resendOtpContainer}>
        {renderResendOTPSection()}
      </div>
      <Popup isOpen={showOtpPopUp} close={() => setShowOtpPopUp(false)}>
        <C24QuotePopup negotiations={negotiationData} resetListing={handleAfterEnterOtp} close={() => setShowOtpPopUp(false)} />
      </Popup>
    </>
  );
};

export default withRouter(DepriciationEnterOtp);