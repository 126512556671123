import { createTypes } from "reduxsauce";

export default createTypes(
  `
    FETCH_FOLLOW_UP
    FETCH_FOLLOW_UP_SUCCESS
    FETCH_FOLLOW_UP_FAILURE
    POST_FOLLOW_UP_SUCCESS
    POST_FOLLOW_UP_FAILURE
    RESET_FOLLOW_UP_KEY
    RESET_FOLLOW_UP
`,
  {
    prefix: "followuppopup/"
  }
);
