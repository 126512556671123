import React, { useState } from "react";
import styles from "./missed-call-negotiation-filter.module.css";
import missedCall from './images/missedCall.svg';
import { MISSED_CALL_NEGOTIATION_FILTERS } from "../../utils/app-constants";
import { useDispatch, useSelector } from "react-redux";
import { updateMissedCallFilterQuery } from "../negotiation-filter/actions";

const MissedCallNegotiationFilter = () => {
  const dispath = useDispatch();
  const negoFilterData = useSelector(state => state.negotiationFilter);
  const negoData = useSelector(state => state.negotiations);
  const { missedCallQuery } = negoFilterData;
  const [selectedFilter, setSelectedFilter] = useState('');
  const onFilterSelect = (selectedKey, selectedValue) => {
    let key = 'missedCallDay';
    let queryString = missedCallQuery;
    let newQs = `&${key}=${selectedValue}`;
    if (!!queryString && queryString === newQs){
      setSelectedFilter('');
      queryString=null;
    } else {
      setSelectedFilter(selectedKey);
      queryString = newQs;  
    }
    dispath(updateMissedCallFilterQuery(queryString));
  };

  return (
    <ul>
      {
        Object.values(MISSED_CALL_NEGOTIATION_FILTERS).map((item) => {
          return (
            <li 
              key={item.key} 
              onClick={() => { onFilterSelect(item.key, item.key); }} 
              className={selectedFilter === item.key ? styles.selected : ''}> 
              <p className={styles.missedCall}>
                <img src={missedCall} alt="missedcall" /> 
                {` ${negoData?.missedCallCount[item.key] || 0} ${item.label}`} 
              </p>
                                
            </li>
          );
        })}
    </ul>
  );
};

export default MissedCallNegotiationFilter;