import React, { useState, useEffect } from "react";
import SwipeableViews from 'react-swipeable-views';
import { bindKeyboard } from 'react-swipeable-views-utils';
import  styles from "./carousel.module.css";
import Prev from "./images/prev-btn.svg";
import Next from "./images/next-btn.svg";
import { thumborUrl } from "../../utils/url-constants";
const BindKeyboardSwipeableViews = bindKeyboard(SwipeableViews);

const Carousel = ({ images, defaultIndex = 0 }) => {
  const cdnBaseUrl = `${thumborUrl()}/`;
  const [swipableIndex, setSwipableIndex] = useState(defaultIndex);
    
  useEffect(() => {
    setSwipableIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(images)]);

  const onChangeIndex = (index) => {
    setSwipableIndex(index);
  };

  const onClickNext = () => {
    const nextIndex = swipableIndex + 1 === images.length ? 0 : swipableIndex + 1;
    setSwipableIndex(nextIndex);
  };

  const onClickPrevious = () => {
    const previousIndex = swipableIndex - 1 === -1 ? images.length - 1 : swipableIndex - 1;
    setSwipableIndex(previousIndex);
  };

  return (
    <div className={styles.carouselWrapper}>
      <BindKeyboardSwipeableViews index={swipableIndex} onChangeIndex={onChangeIndex}>
        { images && images.length > 0 && images.map(({url, title}, index) => {
          return <div key={`img_${index}`} className={styles.imageWrapper}>
            <img key={index} src={`${cdnBaseUrl}${url}`} alt={title} />
            <p className={styles.imgCaption}>{title}</p>
          </div>;
        }) }
      </BindKeyboardSwipeableViews>
            
      <button 
        // disabled={swipableIndex - 1 === -1} 
        onClick={onClickPrevious} className={styles.prev}><img src={Prev} alt="Previous" /></button>
      <button 
        // disabled={swipableIndex + 1 === images.length} 
        onClick={onClickNext} className={styles.nxt}><img src={Next} alt="Next" /></button>
    </div>
  );
};

export default Carousel;
