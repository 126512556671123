import axios from "axios";
import { apiUrl, apiAuthKey } from "../utils/url-constants";
import { ERROR_CODES } from "./api-constants";
import { LOCAL_STORAGE_KEYS, APP_SOURCE, USER_TYPE } from "../utils/app-constants";

const DEBUG = process.env.NODE_ENV === "development";
const BASE_API_URL = apiUrl();

/* Create axios instance */
const api = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    "Content-Type": "application/json"
  }
});

api.interceptors.request.use((config) => {
  config.headers['api-auth-key'] = apiAuthKey();
  config.headers['x-source'] = localStorage.getItem(LOCAL_STORAGE_KEYS.SELECTED_BASE_API_HOST) || APP_SOURCE.CAR;
  config.headers[LOCAL_STORAGE_KEYS.LMS_ACCESS_TOKEN] = localStorage.getItem(LOCAL_STORAGE_KEYS.LMS_ACCESS_TOKEN);
  config.headers[LOCAL_STORAGE_KEYS.USER_SOURCE] = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_SOURCE) || USER_TYPE.OTHER;
  config.baseURL = apiUrl();
  if (DEBUG) { console.info("✉️ ", config); }
  return config;
}, (error) => {
  if (DEBUG) { console.error("✉️ ", error); }
  return Promise.reject(error);
});

/**
 * Passes response.data to services.
 * In dev, intercepts response and logs it into console for dev
 */
api.interceptors.response.use((response) => {
  if (DEBUG) { console.info('📩 ', response); }
  return response;
}, (error) => {
  if (error.response && error.response.data) {
    if (DEBUG) { console.error('Error: ', error.response.data); }
    if (error.response.status === ERROR_CODES.UNAUTHORISED && !window.location.pathname.includes('login')) {
      localStorage.clear();
      window.location = `${window.origin}/login`;
    }
    return Promise.reject(error.response.data);
  }
  if (DEBUG) { console.error('📩 ', error); }
  return Promise.reject(error.message);
});

export default api;
