import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PllNegotiationFilter from "./component";
import { updateFilterQuery } from "./actions";

const mapStateToProps = ({
  pllNegotiationFilter,
  negotiations
}) =>
  ({
    pllNegotiationFilter,
    negotiations
  });

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateFilterQueryConnect: updateFilterQuery
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PllNegotiationFilter);
