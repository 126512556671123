/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import SelectComponent from "../shared/select/component";
import { AppointmentService } from "../../service";
import styles from "./hi-ra-detail.module.scss";
import { showToastMessages } from "../../utils/helper";
import { RESET_REDUCER_STATE_KEYS } from "../../utils/app-constants";
import { getRandomNumberBetweenRange } from "../../utils/helper";

const HiRaDetail = ({ rowDetails, data, updateDataList, scrollRef, setHasMoreItems, resetAppointmentByKeyConnect }) => {
  const { homeInspectionCj, appointment, customerAddress, exophone, retail_associate_email, retailAssociateId, retailAssociateEmail } = rowDetails;
  let raDefaultObj = null;
  let evauatorData = !!data ? JSON.parse(data) : [];
  if (!!evauatorData && evauatorData.length > 0) {
    let selectObject = { firstName: "", email: "Select RA", lastName: "", idLmsUsers: "" };
    evauatorData.unshift(selectObject);
    if (!!retail_associate_email) {
      let cj = evauatorData.find(x => x.email === retail_associate_email);
      if (!!cj) {
        raDefaultObj = { label: cj.email, value: cj.idLmsUsers };
      }
    }
    else if(!!retailAssociateEmail && !!retailAssociateId){
      raDefaultObj = {label: retailAssociateEmail, value: retailAssociateId};
    }
  }
  const [evaluatorList, setEvaluatorList] = useState(evauatorData);
  const [labelText, setLabelText] = useState("");
  const [key, setKey] = useState(appointment);

  const onEvaluatorSelect = (value) => {
    if (!!value && value !== ""){
      setLabelText("");
      const params = {
        'appointment': appointment,
        'field': 'ra_id',
        'value': value
      };
      AppointmentService.updateAppointment(params)
        .then(resp => {
          const { data: { detail: { message } } } = resp;
          showToastMessages(message);
          setTimeout(() => {
            updateDataList(appointment);
          }, 2000);
        })
        .catch(err => {
          const { detail } = err;
          showToastMessages(detail, false);
          raDefaultObj = { label: "", value: "" };
          setLabelText(detail);
          updateDataList(appointment);
          setKey(appointment + "_err");
        });
    }
  };

  return (
    <div>
      <div className={styles.dropdown}>
        <SelectComponent
          key={key}
          name={`select_${retail_associate_email}`}
          placeholder={"RA"}
          optionsList={evaluatorList.map((item) => {
            return {
              label: item.email,
              value: item.idLmsUsers
            };
          })}
          questionId={appointment}
          onChange={onEvaluatorSelect}
          defaultValue={!!raDefaultObj ? raDefaultObj : ""}
        />
        <span className={"form-text text-danger"}>{labelText}</span>
      </div>
    </div >
  );
};

export default HiRaDetail;
