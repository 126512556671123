import React, { useState, useEffect } from "react";
import styles from './customer-offer-new.module.scss';
import Rupess from "../../components/images/rupess.svg";
import BankcashBack from "../../components/images/bankcashback.svg";
import GetDiscount from "../../components/images/getDiscount.svg";
import Info from "../../components/images/info.svg";
import PriceRupess from "../../components/images/priceRupess.svg";
import Checkbox from "../../components/shared/checkbox";
import { AppointmentService } from "../../service";
// import DataInformationMessage from "../../components/shared/data-information-message";
// import { getCustomerChargesMoq } from "../../utils/mocApi";
import priceFormatter from "../../utils/helpers/price-formatter";
// import { getCustomerChargesNewMoc } from "../../utils/mocApi";

const CustomerOfferNew = ({ customerOffer, fetchCustomerChargesDetailsConnect, newCustomerOffer = false }) => {
  const { customerOffer: { appointmentId, c24Quote } } = customerOffer;
  const [customerCharges, setCustomerCharges] = useState([]);
  const [customerHoldbacks, setCustomerHoldbacks] = useState([]);
  const [cashBack, setCashBack] = useState([]);
  const [totalCharges, setTotalCharges] = useState(0);
  const [totalHoldBacks, setTotalHoldBacks] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [chargeActive, setChargeActive] = useState('');
  const [holdsActive, setHoldsActive] = useState('');

  useEffect(() => {
    if (appointmentId > 0 && c24Quote > 0) {
      fetchCustomerChargesDetailsConnect(appointmentId, c24Quote);
      AppointmentService.getCustomerCharges(appointmentId, c24Quote)
        .then(response => {
          // response = getCustomerChargesNewMoc();
          const { data: { detail: { charges: { charges, holds}, cashbacks } } } = response;
          let nonZeroCharges = charges.filter(ch => ch.amount !== 0);
          let nonZeroHoldbacks = holds.filter(item => item.amount !== 0);
          setCustomerChargesObject(nonZeroCharges);
          setCustomerHoldbacksObject(nonZeroHoldbacks);
          setCashBack(cashbacks);
          setIsLoading(false);
        })
        .catch(error => {
          console.log('Error fetching AppointMent list', error);
          setIsLoading(false);
        });

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentId, c24Quote]);

  const setCustomerChargesObject = (detail) => {
    let rowData = [...detail];
    let newData = rowData.map(row => {
      const newRow = { ...row };
      // newRow.checked = true;
      return newRow;
    });
    setCustomerCharges(newData);
    setFinalPriceAfterDeductionCharges(newData);
  };

  const setCustomerHoldbacksObject = (detail) => {
    let rowData = [...detail];
    let newData = rowData.map(row => {
      const newRow = { ...row };
      // newRow.checked = true;
      return newRow;
    });
    setCustomerHoldbacks(newData);
    setFinalPriceAfterDeductionHoldbackCharges(newData);
  };

  const setFinalPriceAfterDeductionCharges = (items) => {
    const icludeCharges = items.filter(x => x.isApplicable === true);
    if (!!icludeCharges) {
      var totalCharges = icludeCharges.reduce(function (prev, cur) { return prev + cur.amount; }, 0);
      setTotalCharges(totalCharges);
    }
  };

  const setFinalPriceAfterDeductionHoldbackCharges = (items) => {
    const icludeCharges = items.filter(x => x.isApplicable === true);
    if (!!icludeCharges) {
      var holdbackCharges = icludeCharges.reduce(function (prev, cur) { return prev + cur.amount; }, 0);
      setTotalHoldBacks(holdbackCharges);
    }
  };

  const onCustomerChargesChecked = (item) => {
    const _customerCharges = JSON.parse(JSON.stringify(customerCharges));
    let _charges = _customerCharges.find((obj => obj.label === item.label));
    _charges.isApplicable = !_charges.isApplicable;
    setCustomerCharges(_customerCharges);
    setFinalPriceAfterDeductionCharges(_customerCharges);
  };

  const onCustomerHoldbacksChecked = (item) => {
    const _customerHoldbacks = JSON.parse(JSON.stringify(customerHoldbacks));
    let _charges = _customerHoldbacks.find((obj => obj.label === item.label));
    _charges.isApplicable = !_charges.isApplicable;
    setCustomerHoldbacks(_customerHoldbacks);
    setFinalPriceAfterDeductionHoldbackCharges(_customerHoldbacks);
  };

  const getClassName = (checkStatus) => {

    let checkClass = "";
    if (!checkStatus) checkClass = "lineThrough";
    return checkClass;
  };
  const getInfoText = (itemlabel) => {
    switch (itemlabel.trim()) {
      case "Service Charges":
        return "CARS24 service charge for the entire process of car selling.";
      case "Insurance Charges":
        return "Charges in case vehicle insurance is expired. You can get them waived off by renewing/bringing your insurance.";
      case "Insurance Challan":
        return "Charges for existing challan on your vehicle that CARS24 will pay.";
      case "Late Stock In Charges":
        return "Charges due to delay in delivery of the car after token payment is processed. These are applied after T+3 days from token processing date.";
      case "Hypo Removal Charges":
        return " As your car was on loan your bank’s name is present on the RC. This charge is to get the bank’s name removed";
      case "Instant Payment Charges":
        return " Charges levied to process your payment within the same day.";
      case "Aadhar eKYC Charges":
        return " Charges for facilitating owner identification through Aadhar Seeding. You can get them waived off by being present for PartyPeshi.";
      case "Address Proof Charges":
        return " Deducted in case of non-availability of required address proof. You can get them waived off by bringing 2 Address proofs having same city, as that on the address of RC.";
      case "Hypothecation Removal":
        return " Charges to get Hypothecation removed from RC.";
      case "Duplicate RC Charges":
        return " Deducted in case the original RC is damaged or lost. You can get them waived off by bringing Original RC.";
      case "NOC HOLD":
        return " Hold applied for NOC to be submitted on or before the last specified date. Payment will be released within 7 working days of document submission.";
      case "Party Peshi HOLD":
        return " Hold applied for presence at RTO for RC transfer process (you will receive request within 120 days of car delivery, if required). Amount will be released within 7 working days of Party Peshi.";
      case "Service History HOLD":
        return " Hold applied for vehicle background check. Charges will be released within 7 working days post-delivery of the car if no discrepancy found during vehicle background check.";
      case "Challan Charges":
        return " Charges for existing challan on your vehicle that CARS24 will pay.";
      case "Duplicate Key":
        return " Deduction as per car value in case duplicate key is not available. You can get them waived off by bringing duplicate key.";

      default:
        return "";
    }
  };
  const onChargeClick = () => {
    if (chargeActive === 'active'){
      setChargeActive('');
    }
    else{
      setChargeActive('active');
    }
  };
  const onHoldsClick = () => {
    if (holdsActive === 'active'){
      setHoldsActive('');
    }
    else{
      setHoldsActive('active');
    }
  };

  return (
    <div className={styles.customerOffer}>
      <ul>
        <li>
          <div className={styles.priceTopRow}>
            <div className={styles.offerLeftSection}>
              <h4>YOUR PRICE</h4>
            </div>
            <div className={styles.offerRightSection}>
              <p className={styles.price}><img src={Rupess} alt="rupess" />{priceFormatter(c24Quote, true)}</p>
            </div>
          </div>
        </li>
        <li>
          {<div className={`${styles.clickStripWrapper} ${chargeActive?styles.active: ''}`} onClick={()=> onChargeClick()}>
            <div className={styles.clickStrip}>
              <div className={styles.offerLeftSection}><span className={styles.bold}>Total Charges</span></div>
              <div className={styles.offerRightSection}>
                <span className={styles.bold}><img src={Rupess} alt="rupess"/> {priceFormatter(totalCharges, true)}</span>
              </div>
            </div>
            <div className={styles.subData}>
              <ul>
                {
                  customerCharges && customerCharges.length > 0 && customerCharges.map((item, index) => {
                    return <li className={styles[getClassName(item.isApplicable)]} key={index}>
                      <div className={styles.offerLeftSection}>
                        <Checkbox
                          id={item.label}
                          name={`chk_${item.label}`}
                          onChangeCallback={() => onCustomerChargesChecked(item)}
                          checkedStatus={item.isApplicable}
                        />
                        <p>{item.label}</p>
                        {!!getInfoText(item.label) && <div className={styles.infoWrapper}>
                          <img className={styles.infoIcon} src={Info} alt="info" />
                          <div className={styles.infoText}>{getInfoText(item.label)}</div>
                        </div>
                        }
                      </div>
                      <div className={styles.offerRightSection}>
                        - <img src={PriceRupess} alt="rupess" /> <span className={styles.redPrice}>{priceFormatter(item.amount, true)}</span>
                      </div>
                    </li>;
                  })
                }
              </ul>
            </div>
          </div>}
        </li>
        <li>
          <div className={styles.priceTopRow}>
            <div className={styles.offerLeftSection}>
              <h4>Net payable amount</h4>
            </div>
            <div className={styles.offerRightSection}>
              <p className={styles.price}><img src={Rupess} alt="rupess" />{priceFormatter(c24Quote - totalCharges)}</p>
            </div>
          </div>
        </li>
        <li>
          {<div className={`${styles.clickStripWrapper} ${holdsActive?styles.active: ''}`} onClick={()=> onHoldsClick()}>
            <div className={styles.clickStrip}>
              <div className={styles.offerLeftSection}><span className={styles.bold}>Holdbacks</span></div>
              <div className={styles.offerRightSection}>
                <span className={styles.bold}><img src={Rupess} alt="rupess"/> {priceFormatter(totalHoldBacks, true)}</span>
              </div>
            </div>
            <div className={styles.subData}>
              <ul>
                {
                  customerHoldbacks && customerHoldbacks.length > 0 && customerHoldbacks.map((item) => {
                    return <li className={styles[getClassName(item.isApplicable)]}>
                      <div className={styles.offerLeftSection}>
                        <Checkbox
                          id={item.label}
                          name={`chk_${item.label}`}
                          onChangeCallback={() => onCustomerHoldbacksChecked(item)}
                          checkedStatus={item.isApplicable}
                        />
                        <p>{item.label}</p>
                        <div className={styles.infoWrapper}>
                          <img className={styles.infoIcon} src={Info} alt="info" />
                          <div className={styles.infoText}>{getInfoText(item.label)}</div>
                        </div>

                      </div>
                      <div className={styles.offerRightSection}>
                        - <img src={PriceRupess} alt="rupess" /> <span className={styles.redPrice}>{priceFormatter(item.amount, true)}</span>
                      </div>
                    </li>;
                  })
                }
              </ul>
            </div>        
          </div>}
        </li>
                
        <li className={styles.mt5}>
          <div className={styles.priceTopRow}>
            <div className={styles.offerLeftSection}>
              <h4 className={styles.bold}>{newCustomerOffer ? 'Delivery amount' : 'FINAL AMOUNT'}</h4>
            </div>
            <div className={styles.offerRightSection}>
              <p className={styles.price}><img src={Rupess} alt="rupess" />{priceFormatter(c24Quote - totalCharges - totalHoldBacks)}</p>
            </div>
          </div>
        </li>
      </ul>
      {cashBack && cashBack.length > 0 && cashBack.map((item) => {
        return (
          item.isShow &&
          <div className={item.type !== "acko" ? styles.cashbackBank : styles.getDiscount}>
            <div className={styles.cashbacktext}>
              <img src={item.type !== "acko" ? BankcashBack : GetDiscount} alt="cash back" />
              <div className={styles.strips}>
                <p><span>{!!item.label1 ? item.label1 : ""}</span></p>
                <p><span>{!!item.label2 ? item.label2 : ""}</span></p>
              </div>
            </div>
            <p className={styles.price}>+₹ {priceFormatter(item.amount)}</p>
          </div>

        );
      })
      }
    </div>
  );
};

export default CustomerOfferNew;