export default (api) => {

  const fetchPlaceSuggestions = (params) => {
    return api.get(`/get-geo-locations`, { params });
  };

  const fetchReverseGeoCodeSuggestions = (params) => {
    return api.get(`/get-reverse-geocode`, { params });
  };

  const fetchPlaceDetails = (params) => {
    return api.get(`/get-place-detail`, { params });
  };

  return {
    fetchPlaceSuggestions,
    fetchReverseGeoCodeSuggestions,
    fetchPlaceDetails
  };
};
