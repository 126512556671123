import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  error: null,
  message: "",
  isPosting: null
};

export const checkInPopupSuccess = (state = INITIAL_STATE, { data }) => {
  const { message } = data;
  return { ...state, message, isPosting: false, error: null };
};

export const checkInPopupFailure = (state = INITIAL_STATE, { error }) => {
  const { detail: { message } } = error;
  return { ...state, error, message, isPosting: false };
};

export const resetCheckInPopupByKey = (state = INITIAL_STATE, { data }) => {
  const { key, value } = data;
  return { ...state, [key]: value };
};

export const resetCheckInPopup = () => {
  return INITIAL_STATE;
};

export const HANDLERS = {
  [Types.CHECK_IN_POPUP_SUCCESS]: checkInPopupSuccess,
  [Types.CHECK_IN_POPUP_FAILURE]: checkInPopupFailure,
  [Types.RESET_CHECK_IN_POPUP_KEY]: resetCheckInPopupByKey,
  [Types.RESET_CHECK_IN_POPUP]: resetCheckInPopup
};

export default createReducer(INITIAL_STATE, HANDLERS);
