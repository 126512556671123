import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CarImageDetail from "./component";
import { updatePriorityCountByKey } from "../sidebar/actions";

const mapStateToProps = ({
  negotiations
}) => ({
  negotiations
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updatePriorityCountByKeyConnect: updatePriorityCountByKey
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CarImageDetail);
