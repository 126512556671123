import React, { useState, useRef } from "react";
import styles from "./incentive-view-details.module.scss";
// const menuRef = useRef(null);
const IncentiveViewDetails = ({data}) => {
  const [showDropDownView, setShowDropDownView] = useState(false);
  return (
    <div className={`${styles.incentiveViewDetails} ${showDropDownView ? styles.active : ''}`}>
      <div onClick={() => setShowDropDownView(!showDropDownView)}>
        <div className={styles.viewDetails}>
          <p>The amount shown here is only the car bought payout; final payout will also include penalties</p>
          <h4>View Details</h4>
        </div>
      </div>
      <div className={styles.viewDetailsCardMinWrapper}>
        <div className={styles.viewDetailsCardWrapper}>
          <div className={styles.detailsCard}>
            <h5>{data.centre}</h5>
            <div className={styles.cardDataWrapper}>
              <div className={styles.subCard}>
                <p>{data.checkinDenied}</p>
                <span>Check In Denied</span>
              </div>
              <div className={styles.subCard}>
                <p>{data.inspectionDenied}</p>
                <span>Inspection Denied</span>
              </div>
            </div>
          </div>

          <div className={styles.detailsCard}>
            <h5>Your Penalties</h5>
            <div className={styles.cardDataWrapper}>
              <div className={styles.subCard}>
                <p>{data.docketRating}</p>
                <span>{"Docket Rating <4.5"}</span>
              </div>
              <div className={styles.subCard}>
                <p>{data.inspectionMiss}</p>
                <span>Inspection miss</span>
              </div>
            </div>
          </div>

          <div className={styles.detailsCard}>
            <h5>Incentive Structure</h5>
            <div className={styles.cardDataWrapper}>
              <a target="_blnak" href="https://docs.google.com/spreadsheets/d/17CRqXttSlAOjWcjbalM7EQiPtnqaani3XGXLuOnykaE/edit" classNames="selfCheckinCta">View Detailed Incentive Structure</a>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default IncentiveViewDetails;
