import React from "react";
import styles from "./target-achieve-bar.module.scss";
import RupessIcon from "../../components/images/yellowRupessIcon.svg";
import IncentiveViewDetails from "../../components/incentive-view-details";

const TargetAchieveBar = ({data}) => {
  const {targetStatus, centre} = !!data? data : {};
  const viewDetails = {
    checkinDenied: !!targetStatus ? targetStatus.checkinDenied: '',
    inspectionDenied: !!targetStatus ? targetStatus.inspectionDenied: '',
    docketRating: !!targetStatus ? targetStatus.docketRating: '',
    inspectionMiss: !!targetStatus ? targetStatus.inspectionMiss: '',
    centre: !!centre ? centre: ''
  };
  const targetDetails = {
    currentIncentive: !!targetStatus? targetStatus.currentIncentive: '',
    currentTarget: !!targetStatus ? targetStatus.currentTarget: '',
    currentProgress: !!targetStatus? targetStatus.currentProgress: '',
    eightyPerIncentive: !!targetStatus? targetStatus.eightyPerIncentive: '',
    eightyPerTarget: !!targetStatus? targetStatus.eightyPerTarget: '',
    hunderedPerTarget: !!targetStatus? targetStatus.hunderedPerTarget: '',
    hunderedPerIncentive: !!targetStatus? targetStatus.hunderedPerIncentive: '',
    carsCurrentToEightyPercent: !!targetStatus? targetStatus.carsCurrentToEightyPercent: '',
    carsCurrentToHunderedPercent: !!targetStatus? targetStatus.carsCurrentToHunderedPercent: '',
    carsEightyToHunderedPercent: !!targetStatus? targetStatus.carsEightyToHunderedPercent: '',
    remainingDays:!!targetStatus? targetStatus.remainingDays: '',
    targetAchieved:!!targetStatus? targetStatus.targetAchieved: '',
    month: !!targetStatus? targetStatus.month: ''
  };
  const showProgressWindow = () =>{
    let currentNumber = Number(targetDetails.currentProgress);
    // currentNumber = 90;
    // debugger;
    if (currentNumber < 80){
      return showBeforeEighty();
    }
    else if (currentNumber === 80){
      return showEighty();
    }
    else if (currentNumber > 80 && currentNumber < 100){
      return showNinety();
    }
    else if (currentNumber === 100){
      return showHundred();
    }
    else{
      return showMoreThanHundred();
    }
  };
  const showBeforeEighty = () => {
    return (
      <>
        <div className={`${styles.beforeEighty} ${styles.eighty1}`}>
          <div className={styles.youSection}>
            <div className={styles.infoSection}>
              <p>Buy {targetDetails.currentTarget} more cars to get this incentive</p>
              <h3>₹{targetDetails.currentIncentive}</h3>
            </div>
            <p className={styles.you}>You</p>
            <div className={styles.dataSection}>
              <p>{targetDetails.currentProgress}%</p>
              <span>{targetDetails.currentTarget} Cars</span>
            </div>
          </div>
        </div>
        <p className={styles.moreCars30}>+{targetDetails.carsCurrentToEightyPercent} more cars</p>
        <div className={styles.defaultEighty}>
          <div className={styles.infoSection}>
            <p>Buy {targetDetails.carsCurrentToEightyPercent} more cars to get this incentive</p>
            <h3>₹{targetDetails.eightyPerIncentive}</h3>
          </div>
          <img src={RupessIcon} alt={"rupess"} />
          <div className={styles.dataSection}>
            <p>{80}%</p>
            <span>{targetDetails.eightyPerTarget} Cars</span>
          </div>
        </div>
        <p className={styles.moreCars60}>+{targetDetails.carsCurrentToHunderedPercent} more cars</p>
        <div className={styles.defaultHundred}>
          <div className={styles.infoSection}>
            <p>Buy {targetDetails.carsCurrentToHunderedPercent} more cars to get this incentive</p>
            <h3>₹{targetDetails.hunderedPerIncentive}</h3>
          </div>
          <img src={RupessIcon} alt={"rupess"} />
          <div className={styles.dataSection}>
            <p>{100}%</p>
            <span>{targetDetails.hunderedPerTarget} Cars</span>
          </div>
        </div>
      </>
    );
  };
  const showEighty = () => {
    return (
      <>
        <div className={`${styles.beforeEighty} ${styles.eighty}`}>
          <div className={styles.youSection}>
            <div className={styles.infoSection}>
              <p className={styles.targetAchieved}>{targetDetails.currentProgress}% Target Achieved</p>
              <h3>₹{targetDetails.eightyPerIncentive}</h3>
            </div>
            <p className={styles.you}>You</p>
            <div className={styles.dataSection}>
              <p className={styles.targetdone}>{targetDetails.currentProgress}%</p>
              <span>{targetDetails.currentTarget} Cars</span>
            </div>
          </div>
        </div>
        <p className={styles.moreCars60}>+{targetDetails.carsCurrentToHunderedPercent} more cars</p>
        <div className={styles.defaultHundred}>
          <div className={styles.infoSection}>
            <p>Buy {targetDetails.carsCurrentToHunderedPercent} more cars to get this incentive</p>
            <h3>₹{targetDetails.hunderedPerIncentive}</h3>
          </div>
          <img src={RupessIcon} alt={"rupess"} />
          <div className={styles.dataSection}>
            <p>{100}%</p>
            <span>{targetDetails.hunderedPerTarget} Cars</span>
          </div>
        </div>
      </>
    );
  };
  const showNinety = () => {
    return (
      <>
        <div className={styles.defaultEighty}>
          <img src={RupessIcon} alt={"rupess"} />
          <div className={styles.dataSection}>
            <p className={styles.targetdone}>{80}%</p>
            <span>{targetDetails.eightyPerTarget} Cars</span>
          </div>
        </div>
        <div className={`${styles.beforeEighty} ${styles.ninety}`}>
          <div className={styles.youSection}>
            <div className={styles.infoSection}>
              <h3>₹{targetDetails.currentIncentive}</h3>
            </div>
            <p className={styles.you}>You</p>
            <div className={styles.dataSection}>
              <p>{targetDetails.currentProgress}%</p>
              <span>{targetDetails.currentTarget} Cars</span>
            </div>
          </div>
        </div>
        <p className={styles.moreCars60}>+{targetDetails.carsCurrentToHunderedPercent} more cars</p>
        <div className={styles.defaultHundred}>
          <div className={styles.infoSection}>
            <p>Buy {targetDetails.carsCurrentToHunderedPercent} more cars to get this incentive</p>
            <h3>₹{targetDetails.hunderedPerIncentive}</h3>
          </div>
          <img src={RupessIcon} alt={"rupess"} />
          <div className={styles.dataSection}>
            <p>{100}%</p>
            <span>{targetDetails.hunderedPerTarget} Cars</span>
          </div>
        </div>
      </>
    );
  };
  const showHundred = () => {
    return (
      <>
        <div className={styles.defaultEighty}>
          <img src={RupessIcon} alt={"rupess"} />
          <div className={styles.dataSection}>
            <p className={styles.targetdone}>{80}%</p>
            <span>{targetDetails.eightyPerTarget} Cars</span>
          </div>
        </div>
        <div className={`${styles.beforeEighty} ${styles.hundred}`}>
          <div className={styles.youSection}>
            <div className={styles.infoSection}>
              <p className={styles.targetAchieved}>{targetDetails.currentProgress}% Target Achieved</p>
              <h3>₹{targetDetails.currentIncentive}</h3>
            </div>
            <p className={styles.you}>You</p>
            <div className={styles.dataSection}>
              <p className={styles.targetdone}>{targetDetails.currentProgress}%</p>
              <span>{targetDetails.currentTarget} Cars</span>
            </div>
          </div>
        </div>
      </>
    );
  };
  const showMoreThanHundred = () => {
    return (
      <>
        <div className={styles.defaultEighty}>
          <img src={RupessIcon} alt={"rupess"} />
          <div className={styles.dataSection}>
            <p className={styles.targetdone}>{80}%</p>
            <span>{targetDetails.eightyPerTarget} Cars</span>
          </div>
        </div>
        <div className={styles.defaultHundred}>
          <img src={RupessIcon} alt={"rupess"} />
          <div className={styles.dataSection}>
            <p className={styles.targetdone}>{100}%</p>
            <span>{targetDetails.eightyPerTarget} Cars</span>
          </div>
        </div>
        <div className={`${styles.beforeEighty} ${styles.hundredPlus}`}>
          <div className={styles.youSection}>
            <div className={styles.infoSection}>
              <h3>₹{targetDetails.currentIncentive}</h3>
            </div>
            <p className={styles.you}>You</p>
            <div className={styles.dataSection}>
              <p>{targetDetails.currentProgress}%</p>
              <span>{targetDetails.currentTarget} Cars</span>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <div className={styles.targetAchieveBar}>
      <div className={styles.targetAchieveBarHeader}>
        <p className={styles.date}>{targetDetails.month}</p>
        <div className={styles.targetSecttion}>
          <h2>Target Achieved : {targetDetails.targetAchieved}%</h2>
          <span>{targetDetails.remainingDays} days remaining</span>
        </div>
      </div>
      <div className={styles.progressBarWrapper}>
        <div className={styles.progressbar}>
          {showProgressWindow()}
        </div>  
      </div>
      <div className={styles.viewDetailsWrapper}>
        <IncentiveViewDetails data = {viewDetails}/>
      </div>
    </div>
  );
};

export default TargetAchieveBar;