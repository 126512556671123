import React from "react";

const TableColumn = ({ children, colLength=0}) => {

  return (
    <td colSpan={colLength}>{children}</td>
  );
};

export default TableColumn;
