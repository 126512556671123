import React from "react";
import QuestionListTab from "../question-list-tab";
import styles from '../saleonline-questions.module.scss';
const SingleSelectQuestion = ({
  data,
  userResponse,
  submitAnswer,
}) => {
  const getActiveStyle = (option) => {
    if (!!userResponse && userResponse.hasOwnProperty(data.key)) {
      const isActive = [userResponse[data.key].value, userResponse[data.key].key].includes(option.key);
      return isActive;
    } else {
      return false;
    }
  };
  return (
    <ul className={styles.listTab}>
      {data?.options.map((option, index)=> {
        return (
          <li key={index} onClick={()=> submitAnswer(data, option)}>
            <QuestionListTab
              text={option?.label}
              subtext={option?.description || ""}
              active={getActiveStyle(option)}
            />
          </li>
        );
      })}
    </ul>
  );
};
export default SingleSelectQuestion;
