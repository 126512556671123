import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AcceptPricePopup from "./component";
import { setCurrentPopup, setSuggestedC24Quote, updateSuggestedQuote, acceptOcbQuote } from "../negotiation-list/actions";

const mapStateToProps = ({ negotiations }) => ({
  negotiations
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setCurrentPopupConnect: setCurrentPopup,
  updateSuggestedQuoteConnect: updateSuggestedQuote,
  setSuggestedC24QuoteConnect: setSuggestedC24Quote,
  acceptOcbQuoteConnect: acceptOcbQuote

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AcceptPricePopup);
