import Types from "./types";
import { AppointmentService } from "../../service";
import {updateApiNoAccess} from "../../components/hoc-access-controller/actions";
const fetchLostLeads = (page, lostFrom, searchQuery) => dispatch => {
  return AppointmentService.getLostLeads(page, lostFrom, searchQuery)
    .then(response => {
      const { data: { detail } } = response;
      dispatch(fetchLostLeadsSuccess(detail));
      return response;
    })
    .catch(error => {
      dispatch(updateApiNoAccess(error));
      dispatch(fetchLostLeadsFailure(error));
    });
};

const fetchLostLeadsSuccess = (data) => ({
  type: Types.FETCH_LOST_LEADS_SUCCESS,
  data
});

const resetLostLeads = () => ({
  type: Types.RESET_LOST_LEADS,
});

const fetchLostLeadsFailure = (error) => ({
  type: Types.FETCH_LOST_LEADS_FAILURE,
  error
});

const resetLostLeadsByKey = (key, value) => ({
  type: Types.RESET_LOST_LEADS_BY_KEY,
  data: { key, value }
});

export {
  fetchLostLeads,
  resetLostLeadsByKey,
  resetLostLeads
};
