import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DepreciationReports from "./component";
import { fetchDepreciationReports } from "./actions";

const mapStateToProps = ({
  depreciation
}) => ({
  depreciation
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchDepreciationReportsConnect:fetchDepreciationReports
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DepreciationReports);
