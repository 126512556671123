import React, { useEffect, useState } from "react";
import Loader from "../../../components/shared/loader";
import SomethingWentWrong from "../../../components/shared/something-went-wrong";
import { showToastMessages } from "../../../utils/helper";
import { APPOINTMENT_STATUS, APP_CONSTANTS, DEALER_LEAD_STATUS } from "../../common/app-constants";
import DealerAcceptPrice from "../../components/dealer-accept-price";
import DealerCarPriceOffer from "../../components/dealer-car-price-offer";
import DealerRejctPrice from "../../components/dealer-reject-price";
import TerminateDealerRejectPrice from "../../components/terminate-dealer-reject-price";
import { DealerService } from "../../service";
import styles from "./dealer-offer-price.module.scss";
const DealerOfferPrice = (props) => {
  const token = props.match.params?.leadToken;
  const [leadDetails, setLeadDetails] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [submit, setSubmit] = useState(false);
  const [screenType, setScreenType] = useState();
  useEffect(()=>{
    if (!!token && !!isLoading) {
      DealerService.getLeadDetails(token)
        .then(response => {
          setLeadDetails(response?.detail);
          const {offer_price_status=''} = response?.detail || {};
          if (!!offer_price_status && offer_price_status.toLocaleLowerCase() === APPOINTMENT_STATUS.ACCEPTED) {
            setScreenType(DEALER_LEAD_STATUS.POST_ACCEPTED);
          } else if (!!offer_price_status && offer_price_status?.toLocaleLowerCase() === APPOINTMENT_STATUS.REJECTED) {
            setScreenType(DEALER_LEAD_STATUS.POST_REJECTED);
          } else {
            setScreenType(DEALER_LEAD_STATUS.OFFER_PRICE);
          }
        })
        .catch(error => {
          setLeadDetails();
          setErrorMessage(error);
        }).finally(()=>{
          setIsLoading(false);
        });
    }
  },[token, isLoading]);

  const onRejectPrice = () => {
    setScreenType(DEALER_LEAD_STATUS.REJECTING_PRICE);
  };

  const onAcceptPrice = () => {
    setScreenType(DEALER_LEAD_STATUS.ACCEPTING_PRICE);
    const params = {
      token: token,
      status: APP_CONSTANTS.ACCEPTED,
    };
    onSubmitOfferPrice(params);
  };

  const onConfirmReject =(value) => {
    const params = {
      token: token,
      status: APP_CONSTANTS.REJECTED,
      reason: value,
    };
    onSubmitOfferPrice(params);
  };

  const onSubmitOfferPrice = (params) => {
    setSubmit(true);
    DealerService.onSubmitDealerPrice(params).then(()=>{
      showToastMessages("Success");
      if (screenType === DEALER_LEAD_STATUS.ACCEPTING_PRICE) {
        setScreenType(DEALER_LEAD_STATUS.POST_ACCEPTED);
      } else if (screenType === DEALER_LEAD_STATUS.REJECTING_PRICE) {
        setScreenType(DEALER_LEAD_STATUS.POST_REJECTED);
      }
    }).catch(() => {
      showToastMessages("Failed. Please try again", false);
    }).finally(()=>{
      setSubmit(false);
      setIsLoading(true); 
    });
  };

  const onRedirectPage = () => {
    switch (screenType) {
      case DEALER_LEAD_STATUS.POST_ACCEPTED:
        return (
          <DealerAcceptPrice 
            minPrice={leadDetails?.narrowPriceMin}
            maxPrice={leadDetails?.narrowPriceMax}
            serviceCharge={APP_CONSTANTS.DEFAULT_SERVICE_CHARGE}
          />
        );
      case DEALER_LEAD_STATUS.REJECTING_PRICE:
        return (
          <DealerRejctPrice onConfirmRejectCallback={onConfirmReject} isSubmit={submit}/>
        );
      case DEALER_LEAD_STATUS.POST_REJECTED:
        return (
          <TerminateDealerRejectPrice 
            minPrice={leadDetails?.narrowPriceMin}
            maxPrice={leadDetails?.narrowPriceMax}
            reason={leadDetails?.offer_price_deny_reason}
          />
        );
      default:
        return (<DealerCarPriceOffer 
          minPrice={leadDetails?.narrowPriceMin}
          maxPrice={leadDetails?.narrowPriceMax}
          onAcceptPrice={onAcceptPrice}
          onRejectPrice={onRejectPrice}
          isSubmit={submit}
        />);
    }
  };
  return (
    <div className={styles.dealerOfferPrice}>
      {!!isLoading ? <Loader /> 
                : errorMessage ? <SomethingWentWrong /> 
                    : 
                <>
                  <div className={styles.bookDetailwrapper}>
                    <h1>{`${leadDetails?.year} ${leadDetails?.make_display} ${leadDetails?.model_display}`}</h1>
                    <p className={styles.bottomText}>
                      {`${leadDetails?.variant_display_name} ${leadDetails?.rto_code}`}
                    </p>
                  </div>
                  { onRedirectPage()}
                </>
      } 
    </div>
  );
};

export default DealerOfferPrice;
