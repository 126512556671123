export default (api) => {
  const updateLoginActivity = (params) => {
    return new Promise((resolve, reject) => {
      api.post(`update-week-off`, params)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  return {
    updateLoginActivity
  };
};