import React, { useEffect, useState } from "react";
import { NUMBERS } from "../../../utils/app-constants";
import { APP_CONSTANTS, SO_QUESTION_INPUT_TYPE, SO_QUESTION_KEY, SO_SCREEN_TYPES, OFFER_PRICE_PAGE_TYPE } from "../../common/app-constants";
import { LeadService } from "../../service";
import InputTextQuestion from "./input-text-question";
import MultiSelectQuestion from "./multi-select-question";
import QuestionProgressBar from "./question-progressbar";
import styles from "./saleonline-questions.module.scss";
import SingleSelectQuestion from "./single-select-question";
import {isUserCj} from "../../common/utility";
const SaleonlineQuestions = (props) => {
  const {
    leadDetail,
    saleonlineQuestions, 
    userResponse, 
    setScreenType,
    setRefresh,
    UpdateAnsweredQuestionsConnect,
    LoadingLeadDetailConnect,
    ResetSaleonlineQuestionsConnect,
    UpdateOfferPriceConnect,
  } = props;
  const {
    sellOnlineQuestions : answeredQuestions,
    token,
  } = leadDetail || {};
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(NUMBERS.ZERO);
  const [totQuestions, setTotQuestions] = useState(NUMBERS.ZERO);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [isSubmittingButton, setisSubmittingButton] = useState(false);
  const [questionSet, setQuestionSet] = useState([]);
  useEffect(() => {
    setHeaderHeight(document.documentElement.scrollHeight);
  }, [headerHeight]);
    
  const getQuestionFilterKeys = () => {
    let updatedKeys = [];
    answeredQuestions.forEach(item => {
      if (item?.question === SO_QUESTION_KEY.CngLpgFitment && item?.value.toLocaleLowerCase() === APP_CONSTANTS.NO) {
        updatedKeys = [...updatedKeys, SO_QUESTION_KEY.CngLpgEndorsed];
      } else if (item?.question === SO_QUESTION_KEY.OdometerReading && item?.value < APP_CONSTANTS.TOTAL_KMS_THRESHOLD) {
        updatedKeys = [...updatedKeys, SO_QUESTION_KEY.TyresReplaced];
      }
      UpdateAnsweredQuestionsConnect({key: item?.question, value: item});
    });
    return updatedKeys;
  };
    
  useEffect(()=>{
    if (!!answeredQuestions && !!saleonlineQuestions && saleonlineQuestions.length > 0) {
      let filterQuestions = [...saleonlineQuestions];
      if (answeredQuestions.length > 0) {
        const filterKeys = getQuestionFilterKeys();
        if (filterKeys.length > NUMBERS.ZERO) {
          filterQuestions = filterQuestions.filter(item => !filterKeys.includes(item?.key));
        };
      }
      setQuestions(filterQuestions);
      setQuestionSet(filterQuestions);
      const totAnswered = answeredQuestions.length;
      const totalQuestions = filterQuestions.length;
      const curIndex = totAnswered > NUMBERS.ZERO ? totAnswered - NUMBERS.ONE : NUMBERS.ZERO;
      setCurrentQuestionIndex(curIndex);
      setTotQuestions(totalQuestions);
    }
  },[answeredQuestions, saleonlineQuestions]);
    
  const onNextV2 = (question, answer) => {
    let filterQuestions = [...questions];
    if (SO_QUESTION_KEY.CngLpgFitment === question.key) {
      if (answer.key.toLocaleLowerCase() === APP_CONSTANTS.NO) {
        filterQuestions = filterQuestions.filter(item => item?.key !== SO_QUESTION_KEY.CngLpgEndorsed);
      } else {
        const cngLpgQuestion = filterQuestions.find(item => item.key === SO_QUESTION_KEY.CngLpgEndorsed);
        if (!cngLpgQuestion) {
          const cngLpg = questionSet.find(item => item.key === SO_QUESTION_KEY.CngLpgEndorsed);
          filterQuestions = [...filterQuestions, cngLpg];
        }
      }
    }
    else if (SO_QUESTION_KEY.OdometerReading === question.key) {
      if (answer < APP_CONSTANTS.TOTAL_KMS_THRESHOLD) {
        filterQuestions = filterQuestions.filter(item => item?.key !== SO_QUESTION_KEY.TyresReplaced);
      } else {
        const tyreQuestion = filterQuestions.find(item => item.key === SO_QUESTION_KEY.TyresReplaced);
        if (!tyreQuestion) {
          const tyreQuestionItem = questionSet.find(item => item.key === SO_QUESTION_KEY.TyresReplaced);
          filterQuestions = [...filterQuestions, tyreQuestionItem];
        }
      }
    }
    setQuestions(filterQuestions.sort((a,b)=> a.displayOrder - b.displayOrder));
    setTotQuestions(filterQuestions.length);
  };

  const onQuestionAnswerSubmit = (question, answer) => {
    UpdateAnsweredQuestionsConnect({key: question?.key, value: answer});
    submitQuestionAnswer(question, answer);
  };

  const onInputTextQuestionAnswerSubmit = (question, answer) => {
    UpdateAnsweredQuestionsConnect({key: question?.key, value: {key: answer, value: answer}});
    submitQuestionAnswer(question, answer);
  };

  const onLastQuestionAnswerSubmit = () => {
    const question = questions[currentQuestionIndex]; 
    const answer = userResponse[question?.key];
    submitQuestionAnswer(question, answer, true);
  };
    
  const onConfirmedAnswer = async (answer, response) => {
    if (currentQuestionIndex === totQuestions - NUMBERS.ONE) {
      setScreenType(SO_SCREEN_TYPES.SO_OFFER_PRICE_ANALYZER);
      UpdateOfferPriceConnect(response.offered_price);
      ResetSaleonlineQuestionsConnect();
      setTimeout(() => {
        setRefresh(true);
        if (isUserCj()) {
          setScreenType(SO_SCREEN_TYPES.SO_CJ_OFFER_PRICE);
        } else {
          setScreenType(SO_SCREEN_TYPES.SO_OFFER_PRICE);
        }
      }, 1000);
    } else {
      setCurrentQuestionIndex(currentQuestionIndex + NUMBERS.ONE);
    }
  };

  const submitQuestionAnswer = async (question, option, isLastQuestion=false) => {
    let isGSEligble = false;
    let payload = {
      question: question?.key,
      question_description: question?.label,
      input_type: question?.inputType,
      price_eligible: question?.eligiblePrice,
      token: token
    };
    if (question?.inputType === SO_QUESTION_INPUT_TYPE.CHECKBOX) {
      const MCQ = userResponse[question?.key];
      isGSEligble = MCQ.every(question =>  !question.gsOptOut);
      const mcqValues = MCQ.map(option => {
        return option.value;
      });
      payload = {...payload, 
        gs_eligible_car: !!isGSEligble ? APP_CONSTANTS.YES : APP_CONSTANTS.NO, 
        value: mcqValues.toString()
      };
    } else if (question?.inputType === SO_QUESTION_INPUT_TYPE.BUTTON){
      isGSEligble = !option?.gsOptOut;
      payload = {...payload, 
        gs_eligible_car: !!isGSEligble ? APP_CONSTANTS.YES : APP_CONSTANTS.NO, 
        value: option?.key
      };
    } else {
      payload = {...payload, 
        gs_eligible_car: !!isGSEligble ? APP_CONSTANTS.YES : APP_CONSTANTS.NO, 
        value: option
      };
      isGSEligble = true;
    }
    if (isLastQuestion) {
      payload = {...payload, action: APP_CONSTANTS.SUBMIT, comm_event: "narrow-vnb"};
    }
    LoadingLeadDetailConnect(true);
    await LeadService.postSellonlineQuestion(payload)
      .then((soResponse)=>{
        const {detail: resp} = soResponse;
        // if (!isGSEligble) {
        //     const params = {
        //         gs_denied_reason: `${question?.key} | ${option?.key}`,
        //         gs_eligible_car: APP_CONSTANTS.NO,
        //     };
        //     LeadService.updateLeadWithGsDeny(token, params).then(()=> {
        //         ResetSaleonlineQuestionsConnect();
        //         setRefresh(true);
        //         setScreenType(SO_SCREEN_TYPES.SO_OFFER_PRICE);
        //     });
        // } else {
        //     onNextV2(question, option);
        //     onConfirmedAnswer(option, resp);
        // }
        onNextV2(question, option);
        onConfirmedAnswer(option, resp);
      }).finally(()=> {
        LoadingLeadDetailConnect(false);
      });
  };

  const renderQuestion = () => {
    const question = questions[currentQuestionIndex];
    if (question?.inputType === SO_QUESTION_INPUT_TYPE.CHECKBOX) {
      return (
        <MultiSelectQuestion
          data={question}
          userResponse={userResponse}
          UpdateAnsweredQuestionsConnect={UpdateAnsweredQuestionsConnect}
          setisSubmittingButton={setisSubmittingButton}
        />
      );
    } else if (question?.inputType === SO_QUESTION_INPUT_TYPE.TEXT) {
      return (<InputTextQuestion 
        data={question}
        userResponse={userResponse}
        onChangeInputText={onInputTextQuestionAnswerSubmit}
        headerHeight={headerHeight}
        placeholder="Enter number of kilometres"
      />
      );
    } else if (question?.inputType === SO_QUESTION_INPUT_TYPE.BUTTON) {
      return (<SingleSelectQuestion 
        data={question}
        submitAnswer={onQuestionAnswerSubmit}
        userResponse={userResponse}
      />);
    } else {
      return (
        <p>Application Error - Please try after sometime</p>
      );
    }
  };

  const getCurrentQuestionLabel = () => {
    if (currentQuestionIndex >= NUMBERS.ZERO && currentQuestionIndex < totQuestions) {
      const curQuestion = questions[currentQuestionIndex];
      if (curQuestion.key === SO_QUESTION_KEY.IsAccidental) {
        const diffYear = new Date().getFullYear() - leadDetail.year;
        return diffYear < NUMBERS.TWO ? curQuestion.label.replace('X years', 'year') : curQuestion.label.replace('X', diffYear);
      } else {
        return curQuestion.label;
      }
    }
  };
    
  return ( 
    <>{!!questions && questions.length > 0 &&
    <div className={styles.sellerQuestion}>
      <h4 className={styles.sellerQuestionHeader}>{getCurrentQuestionLabel()}</h4>
      {renderQuestion()}
      {!!totQuestions && totQuestions > NUMBERS.ZERO && <QuestionProgressBar 
        questionKey={questions[currentQuestionIndex] && questions[currentQuestionIndex].key}
        totalSteps={totQuestions}
        currentStepIndex={currentQuestionIndex}
        isSubmittingButton={isSubmittingButton}
        onConfirm={onLastQuestionAnswerSubmit}
        setCurrentQuestionIndex={setCurrentQuestionIndex}
        userResponse={userResponse}
      />}
    </div>}
    </>
  );
};

export default SaleonlineQuestions;
