import Types from "./types";

const resetSearch = () => ({
  type: Types.RESET_SEARCH
});

const resetSearchByKey = (key, value) => ({
  type: Types.RESET_SEARCH_BY_KEY,
  data: { key, value }
});

export {
  resetSearchByKey,
  resetSearch
};
