import React from "react";
import ArrowIcons from "../images/arrow.svg";
import styles from "./arrow.module.css";

const Arrow = () => {
  return (
    <span className={styles.arrow}>
      <img src={ArrowIcons} alt=""/>
    </span>
  );
};

export default Arrow;
