import React, { useState, useEffect } from "react";
import { APPOINTMENT_TYPES_NUMBER, ERROR_MESSAGE, POPUP_BUTTONS, NUMBERS, TEXT_AREA_REGEX, DATATYPE, NEGO_SELL_ONLINE_FOLLOWUP_TYPE } from "../../utils/app-constants";
import SelectComponent from "../shared/select/component";
import H2 from "../h2/component";
import Textarea from "../shared/textarea/component";
import Button from "../shared/button/component";
import styles from "./negotiation-popup.module.css";
import { showToastMessages } from "../../utils/helper";
// import InputText from "../shared/input-text/component";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import priceFormatter from "../../utils/helpers/price-formatter";
import { isC2CUser, isPllUser } from "../../utils/helper";
import Datepicker from "../shared/datepicker/component";

const useStyles = makeStyles(theme => {
  return {
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(2),
      fontSize: '10px',
    }
  };
});

const NegotiationPopup = ({ close, fetchFollowUpListConnect, followUp, negotiations, postFollowUpConnect, resetListing, resetFollowUpConnect, isLostLeadFlow, quoteNew }) => {

  const { data, currentAppointmentIndex } = negotiations;
  const currentAppointment = data[currentAppointmentIndex];
  const { appointmentId, customerName, model, year, followUpMessage, followUpRemarks, customerExpectedPrice, priceOffered, dealLostReason, storeType } = currentAppointment;
  const { details, isPosting, error, message } = followUp;
  const [dispositionList, setDispositionList] = useState([]);
  const [disposition, setDisposition] = useState();
  const [remarks, setRemarks] = useState(followUpRemarks ? followUpRemarks : "");
  const [dispositionError, setDispositionError] = useState(false);
  const [defaultFollowUp, setDefaultFollowUp] = useState();
  const classes = useStyles();
  const [priceOfferToCustomer, setPriceOfferToCustomer] = useState(priceOffered ? priceFormatter(priceOffered) : "");
  const [custExpectedPrice, setCustomerExpectedPrice] = useState(customerExpectedPrice ? priceFormatter(customerExpectedPrice) : "");
  let isC2C = isC2CUser();
  let isPll = isPllUser();
    
  const [subDisposition, setSubDisposition] = useState();
  const [subDispValue, setSubDispValue] = useState();
  const [subDispositionError, setSubDispositionError] = useState(false);
  // console.log("storeType", storeType);
  let dealLostForPriceRepo = isLostLeadFlow;
  if (!!dealLostReason && isPll) {
    dealLostForPriceRepo = true;
  }
  useEffect(() => {
    let type = APPOINTMENT_TYPES_NUMBER.NEGOTIATION;
    if (storeType === "sell_online") {
      type = NEGO_SELL_ONLINE_FOLLOWUP_TYPE;
    }
    fetchFollowUpListConnect(type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (details.length > 0) {
      const dispositions = details.map(item => { return { value: item.key, label: item.name }; });
      if (followUpMessage) {
        const selectedValue = dispositions.find(item => followUpMessage.includes(item.label));
        selectedValue && setDefaultFollowUp(selectedValue);
        selectedValue && setDisposition(selectedValue.value);
      }
      setDispositionList(dispositions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  useEffect(() => {
    if (disposition) {
      const detail = details.find(item => item.key === disposition);
      if (detail.subDispositions.length > 0) {
        setSubDisposition(detail.subDispositions[0]);
      } else {
        setSubDisposition();
      }
      setDispositionError(false);
      setSubDispositionError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disposition]);

  useEffect(() => {
    if (!isPosting) {
      if (error) {
        showToastMessages(message, false);
      } else {
        showToastMessages(message);
        setTimeout(() => {
          resetListing();
          resetFollowUpConnect();
          close();
        }, 2000);

        // resetNegotiationsConnect();
        // fetchNegotiationsConnect();

      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPosting]);

  const onDispositionSelect = (value) => {
    setDisposition(value);
  };

  const onDateChange = (val) => {
    setSubDispValue(val);
    setSubDispositionError(false);
  };

  const onRemarksChange = (e) => {
    const { target: { value } } = e;
    setRemarks(value);
  };

  const validateDetails = () => {
    let errors = 0;
    if (!disposition) {
      ++errors;
      setDispositionError(true);
    } else {
      setDispositionError(false);
    }
    if (subDisposition && subDisposition.required && !subDispValue) {
      ++errors;
      setSubDispositionError(true);
    } else {
      setSubDispositionError(false);
    }
    if (errors > 0) {
      return false;
    } else {
      return true;
    }
  };

  const onSaveClick = () => {
    const isValid = validateDetails();
    let offerPrice = Number(priceOfferToCustomer.replace(/,/g, ''));
    let expectedPrice = Number(custExpectedPrice.replace(/,/g, ''));
    if (isValid) {
      const dispName = details.find(item => item.key === disposition).name;
      const params = {
        "appointment": appointmentId,
        "dispositionId": disposition,
        "disposition": dispName,
        "subDisposition": subDisposition && subDisposition.required ? subDispValue.toLocaleTimeString() : "",
        "remarks": remarks ? remarks.trim().replace(TEXT_AREA_REGEX, " ") : "",
        "state": NUMBERS.SEVENTEEN,
        "priceOffered": offerPrice,
        "expectedCustomerPrice": expectedPrice
      };
      postFollowUpConnect(params);
    }
  };
  const onChangeExpectedPrice = (e) => {
    // const { target: { value } } = e;
    const value = e.target.value && e.target.value.replace(/,/g, '') ? priceFormatter(Number(e.target.value.replace(/,/g, ''))) : "";
    // const formattedValue = value && value.replace(/,/g, '') ? Number(value.replace(/,/g, '')) : "";
    setCustomerExpectedPrice(value);
  };
  const onChangeOfferPrice = (e) => {
    // const { target: { value } } = e;
    // const formattedValue = value && value.replace(/,/g, '') ? Number(value.replace(/,/g, '')) : "";
    const value = e.target.value && e.target.value.replace(/,/g, '') ? priceFormatter(Number(e.target.value.replace(/,/g, ''))) : "";
    setPriceOfferToCustomer(value);
  };
  return (
    <div>
      <H2 text={"FOLLOW-UP"} />
      <div>
        <p className={styles.txt}>{`${customerName} | ${model} | ${year}`}</p>
      </div>
      <div className="mt-3">
        <div className={styles.inputWrap}>
          <TextField
            label="Price OfferedTo Customer"
            className={classes.textField}
            margin="dense"
            variant="outlined"
            onChange={onChangeOfferPrice}
            value={priceOfferToCustomer}
            inputProps={{
              maxLength: 9,
            }}
            InputLabelProps={{ style: {} }}
          >
          </TextField>
        </div>
      </div>
      <div className="mt-3">
        <div className={styles.inputWrap}>
          <TextField
            label="Customer Expected Price"
            className={classes.textField}
            margin="dense"
            variant="outlined"
            onChange={onChangeExpectedPrice}
            value={custExpectedPrice}
            inputProps={{
              maxLength: 9,
            }}
            InputLabelProps={{ style: {} }}
          >
          </TextField>
        </div>
      </div>

      <div className="mt-3">
        {dispositionList.length > 0 &&
        <SelectComponent placeholder="Call Result" name="dispositionStatus" onChange={onDispositionSelect} optionsList={dispositionList} defaultValue={defaultFollowUp} hasError={dispositionError} error={ERROR_MESSAGE.DISPOSITION} />
        }
      </div>
      {subDisposition && subDisposition.dataType === DATATYPE.TIMER &&
      <div className="mt-3">
        <div className={styles.customCalender}>
          <Datepicker name="subDisposition"
            selected={subDispValue} placeholder="Time"
            onChange={onDateChange} showTime={true} showTimeOnly={true} />
        </div>
      </div>
      }
      {
        subDisposition && subDisposition.required && subDispositionError &&
        <span className={styles.errorText}>{ERROR_MESSAGE.SUB_DISPOSITION}</span>
      }
      <div className="mt-3">
        <Textarea
          id="remarks"
          name="remarks"
          value={remarks}
          // rows={6}
          onChange={onRemarksChange}
          text="Remarks if any"
        />
      </div>
      <div className={`${styles.ctaButton} ${quoteNew ? styles.quoteNew : ``}${(isLostLeadFlow || isC2C) ? styles.fixWrapper : ``}`}>
        <Button classNames="saveCta" disable="disable" ctaText={POPUP_BUTTONS.SAVE} onClick={onSaveClick} />
        <Button classNames="cancelCta" ctaText={POPUP_BUTTONS.CANCEL} onClick={close} />
      </div>
    </div>
  );
};

export default NegotiationPopup;
