import React, { useState, useEffect, useCallback } from "react";
import styles from "./charges-holdbacks.module.scss";
import Rupess from "../../components/images/rupess.svg";
import BankcashBack from "../../components/images/bankcashback.svg";
import GetDiscount from "../../components/images/getDiscount.svg";
import Info from "../../components/images/info.svg";
import PriceRupess from "../../components/images/priceRupess.svg";
import Checkbox from "../../components/shared/checkbox";
import priceFormatter from "../../utils/helpers/price-formatter";
import { AppointmentService } from "../../service";
import { showToastMessages } from "../../utils/helper";

const ChargesHoldbacks = ({ fetchCustomerOfferDetailsConnect, customerOffer, appointmentId, chargesHoldbackConnect, chargesHoldbacks, customerConsented, tokenAmount }) => {
  const { customerOffer: { c24Quote } } = customerOffer;
  const [chargeFixedActive, setChargeFixedActive] = useState('');
  const [chargeConditionalActive, setChargeConditionalActive] = useState('');
  const [cashBack, setCashBack] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [customerFixedCharges, setCustomerFixedCharges] = useState([]);
  const [customerConditionalCharges, setCustomerConditionalCharges] = useState([]);
  const [customerHoldbacks, setCustomerHoldbacks] = useState([]);
  const [totalHoldBacks, setTotalHoldBacks] = useState(0);
  const [totalFixedCharges, setTotalFixedCharges] = useState(0);
  const [totalConditionalCharges, setTotalConditionalCharges] = useState(0);
  const [pennyChargesSms, setPennyChargesSms] = useState(false);

  const onChargeClick = (tab) => {
    if (tab === 'fixed') {
      if (chargeFixedActive === 'active') {
        setChargeFixedActive('');
      }
      else {
        setChargeFixedActive('active');
      }
    } else {
      if (chargeConditionalActive === 'active') {
        setChargeConditionalActive('');
      }
      else {
        setChargeConditionalActive('active');
      }
    }

  };

  const getClassName = (checkStatus) => {
    let checkClass = "";
    if (!checkStatus) checkClass = "lineThrough";
    return checkClass;
  };

  useEffect(() => {
    fetchCustomerOfferDetailsConnect(appointmentId).catch((err)=>{
      console.log({err});
    });
    chargesHoldbackConnect(appointmentId).catch((err)=>{
      console.log({err});
    });
  }, [chargesHoldbackConnect, appointmentId, fetchCustomerOfferDetailsConnect]);

  const setCustomerFixedChargesObject = useCallback((detail) => {
    let rowData = [...detail];
    let newData = rowData.map(row => {
      const newRow = { ...row };
      // newRow.checked = true;
      return newRow;
    });
    setCustomerFixedCharges(newData);
    setFinalFixedPriceAfterDeductionCharges(newData);
  }, []);

  const setCustomerConditionalChargesObject = useCallback((detail) => {
    let rowData = [...detail];
    let newData = rowData.map(row => {
      const newRow = { ...row };
      // newRow.checked = true;
      return newRow;
    });
    setCustomerConditionalCharges(newData);
    setFinalConditionalPriceAfterDeductionCharges(newData);
  }, []);

  const setCustomerHoldbacksObject = useCallback((detail) => {

    let rowData = [...detail];
    let newData = rowData.map(row => {
      const newRow = { ...row };
      // newRow.checked = true;
      return newRow;
    });
    setCustomerHoldbacks(newData);
    setFinalPriceAfterDeductionHoldbackCharges(newData);
  }, []);

  useEffect(() => {
    if (Object.keys(chargesHoldbacks.userCharges).length) {
      const { userCharges: { charges: { charges, holds, c24qoute }, cashbacks } } = chargesHoldbacks;
      let nonZerofixedCharges = charges.filter(ch => ch.amount !== 0 && ch.category === 'Fixed Charges');
      let nonZeroconditionalcharges = charges.filter(ch => ch.amount !== 0 && ch.category === 'Conditional Charges');
      let nonZeroHoldbacks = holds.filter(item => item.amount !== 0);

      // setC24Quote(c24qoute);
      setCustomerFixedChargesObject(nonZerofixedCharges);
      setCustomerHoldbacksObject(nonZeroHoldbacks);
      setCustomerConditionalChargesObject(nonZeroconditionalcharges);
      setCashBack(cashbacks);
      setIsLoading(false);
    }
  }, [chargesHoldbacks, setCustomerFixedChargesObject, setCustomerHoldbacksObject, setCustomerConditionalChargesObject]);

  const setFinalFixedPriceAfterDeductionCharges = (items) => {
    const icludeCharges = items.filter(x => x.isApplicable === true);
    if (!!icludeCharges) {
      var totalFixedCharges = icludeCharges.reduce(function (prev, cur) { return prev + cur.amount; }, 0);
      setTotalFixedCharges(totalFixedCharges);
    }
  };

  const setFinalConditionalPriceAfterDeductionCharges = (items) => {
    const icludeCharges = items.filter(x => x.isApplicable === true);
    if (!!icludeCharges) {
      var totalConditionalCharges = icludeCharges.reduce(function (prev, cur) { return prev + cur.amount; }, 0);
      setTotalConditionalCharges(totalConditionalCharges);
    }
  };

  const setFinalPriceAfterDeductionHoldbackCharges = (items) => {
    const icludeCharges = items.filter(x => x.isApplicable === true);
    if (!!icludeCharges) {
      var holdbackCharges = icludeCharges.reduce(function (prev, cur) { return prev + cur.amount; }, 0);
      setTotalHoldBacks(holdbackCharges);
    }
  };

  // useEffect(() => {
  //     console.log(detail?.charges);
  // }, [detail]);

  const onCustomerFixedChargesChecked = (item) => {
    const _customerCharges = JSON.parse(JSON.stringify(customerFixedCharges));
    let _charges = _customerCharges.find((obj => obj.label === item.label));
    _charges.isApplicable = !_charges.isApplicable;
    setCustomerFixedCharges(_customerCharges);
    setFinalFixedPriceAfterDeductionCharges(_customerCharges);
  };

  const onCustomerConditionalChargesChecked = (item) => {
    const _customerCharges = JSON.parse(JSON.stringify(customerConditionalCharges));
    let _charges = _customerCharges.find((obj => obj.label === item.label));
    _charges.isApplicable = !_charges.isApplicable;
    setCustomerConditionalCharges(_customerCharges);
    setFinalConditionalPriceAfterDeductionCharges(_customerCharges);
  };

  const onCustomerHoldbacksChecked = (item) => {
    const _customerHoldbacks = JSON.parse(JSON.stringify(customerHoldbacks));
    let _charges = _customerHoldbacks.find((obj => obj.label === item.label));
    _charges.isApplicable = !_charges.isApplicable;
    setCustomerHoldbacks(_customerHoldbacks);
    setFinalPriceAfterDeductionHoldbackCharges(_customerHoldbacks);
  };

  const submitPennyChargesSms = () => {
    const payload = {
      appointmentId: appointmentId,
    };
    AppointmentService.prResendChargesConsent(payload)
      .then(response => {
        console.log(response);
        setPennyChargesSms(true);
        setTimeout(() => {
          setPennyChargesSms(false);
        }, 6000);
        const { detail } = response;
        showToastMessages(detail, true);
      }).catch(error => {
        const { detail } = error;
        showToastMessages(detail || error, false);
      });
  };

  return (
    <div className={styles.chargesHoldbacks}>
      <div className={styles.documentWrapper}>
        <h3>Charges and Holdbacks</h3>
        <p className={styles.info}>The charges and holdbacks displayed may change after vahan background check is completed</p>
        <div className={styles.verificationWrapper}>
          {customerConsented ? <p className={styles.verificationApproved}>Customer Verified</p> : <p className={styles.verification}>Customer verification pending</p> }
          {!customerConsented && <p className={styles.send} onClick={submitPennyChargesSms}>Send to customer</p>}
          {pennyChargesSms && <p className={styles.smsSent}>SMS Sent</p>}
        </div>

        <div className={styles.customerOffer}>
          <ul>
            <li>
              <div className={styles.priceTopRow}>
                <div className={styles.offerLeftSection}>
                  <h4>Offer Price</h4>
                </div>
                <div className={styles.offerRightSection}>
                  <p className={styles.price}><img src={Rupess} alt="rupess" />{priceFormatter(c24Quote, true)}</p>
                </div>
              </div>
            </li>
            <li>
              <div className={`${styles.clickStripWrapper} ${chargeFixedActive ? styles.active : ''}`} onClick={() => onChargeClick('fixed')}>
                <div className={styles.clickStrip}>
                  <div className={styles.offerLeftSection}><span className={styles.bold}>Fixed charges</span></div>
                  <div className={styles.offerRightSection}>
                    <span className={styles.bold}><img src={Rupess} alt="rupess" />{priceFormatter(totalFixedCharges, true)}</span>
                  </div>
                </div>
                {customerFixedCharges && customerFixedCharges.length > 0 && customerFixedCharges.map((value, index) => {
                  return (
                    <div className={styles.subData} key={index}>
                      <ul>
                        <li className={styles[getClassName(value.isApplicable)]}>
                          <div className={styles.offerLeftSection}>
                            <Checkbox
                              id={value.label}
                              name={`chk_${value.label}`}
                              onChangeCallback={() => onCustomerFixedChargesChecked(value)}
                              checkedStatus={value.isApplicable}
                            />
                            <p>{value.label}</p>
                            <div className={styles.infoWrapper}>
                              <img className={styles.infoIcon} src={Info} alt="info" />
                              <div className={styles.infoText}>{value.remarks}</div>
                            </div>
                          </div>
                          <div className={styles.offerRightSection}>
                            - <img src={PriceRupess} alt="rupess" /> <span className={styles.redPrice}>{value.amount}</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  );
                })
                }

              </div>
            </li>
            <li>
              <div className={`${styles.clickStripWrapper} ${chargeConditionalActive ? styles.active : ''}`} onClick={() => onChargeClick('conditional')}>
                <div className={styles.clickStrip}>
                  <div className={styles.offerLeftSection}><span className={styles.bold}>Conditional charges</span></div>
                  <div className={styles.offerRightSection}>
                    <span className={styles.bold}><img src={Rupess} alt="rupess" />{priceFormatter(totalConditionalCharges, true)}</span>
                  </div>
                </div>
                {customerConditionalCharges && customerConditionalCharges.length > 0 && customerConditionalCharges.map((value, index) => {
                  return (
                    <div className={styles.subData}>
                      <ul>
                        <li className={styles[getClassName(value.isApplicable)]}>
                          <div className={styles.offerLeftSection}>
                            <Checkbox
                              id={value.label}
                              name={`chk_${value.label}`}
                              onChangeCallback={() => onCustomerConditionalChargesChecked(value)}
                              checkedStatus={value.isApplicable}
                            />
                            <p>{value.label}</p>
                            <div className={styles.infoWrapper}>
                              <img className={styles.infoIcon} src={Info} alt="info" />
                              <div className={styles.infoText}>{value.remarks}</div>
                            </div>
                          </div>
                          <div className={styles.offerRightSection}>
                            - <img src={PriceRupess} alt="rupess" /> <span className={styles.redPrice}>{value.amount}</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  );
                })
                }

              </div>
            </li>
            <li>
              <div className={styles.priceTopRow}>
                <div className={styles.offerLeftSection}>
                  <h4>Net payable amount</h4>
                </div>
                <div className={styles.offerRightSection}>
                  <p className={styles.price}><img src={Rupess} alt="rupess" />{priceFormatter(c24Quote - (totalFixedCharges + totalConditionalCharges))}</p>
                </div>
              </div>
              <div className={styles.payableText}>
                <p>Token (Payable Now)</p>
                <p> <img src={PriceRupess} alt="rupess" /> {priceFormatter(tokenAmount)}</p>
              </div>
              <div className={styles.payableText}>
                <p>Payable On Car Delivery</p>
                <p> <img src={PriceRupess} alt="rupess" /> {priceFormatter((c24Quote - (totalFixedCharges + totalConditionalCharges) - totalHoldBacks - tokenAmount))}</p>
              </div>
              {customerHoldbacks && customerHoldbacks.length > 0 && <div className={styles.payableText}>
                <p>Payable later</p>
                <p> <img src={PriceRupess} alt="rupess" /> {totalHoldBacks}</p>
              </div>}
              {customerHoldbacks && customerHoldbacks.length > 0 && customerHoldbacks.map((value, index) => {
                return (
                  <div className={[styles.priceTextWrapper, styles[getClassName(value.isApplicable)]].join(' ')} key={index}>
                    <div className={styles.offerLeftSection}>
                      <Checkbox
                        id={value.label}
                        name={`chk_${value.label}`}
                        onChangeCallback={() => onCustomerHoldbacksChecked(value)}
                        checkedStatus={value.isApplicable}
                      />
                      <p>{value.label}</p>
                      <div className={styles.infoWrapper}>
                        <img className={styles.infoIcon} src={Info} alt="info" />
                        <div className={styles.infoText}>{value.remarks}</div>
                      </div>
                    </div>
                    <div className={styles.offerRightSection}>
                      - <img src={PriceRupess} alt="rupess" /> <span className={styles.redPrice}>{value.amount}</span>
                    </div>
                  </div>
                );
              })}

            </li>
          </ul>
        </div>

      </div>
    </div>
  );
};

export default ChargesHoldbacks;
