import Types from "./types";
import { AppointmentService } from "../../service";

const getStoreAssociateList = (searchQuery) => dispatch => {

  //getStoreAssociateList(searchQuery)
  return AppointmentService.getStoreAssociateList(searchQuery)
    .then(response => {
      response = {
        "type": "http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html",
        "title": "Store associates list",
        "status": 200,
        "detail": [
          {
            "empCode": "112230",
            "firstName": "Ankita",
            "lastName": "Srivastav",
            "idLmsUsers": 131,
            "email": "ankita.srivastav@cars24.com",
            "name": "Golf Course Extension",
            "storeCity": "Gurgaon"
          },
          {
            "empCode": "112231",
            "firstName": "Vivek",
            "lastName": "Tripathi",
            "idLmsUsers": 132,
            "email": "ankita.srivastav@cars24.com",
            "name": "Golf Course Extension",
            "storeCity": "Gurgaon"
          },
          {
            "empCode": "112232",
            "firstName": "Test1",
            "lastName": "Test2",
            "idLmsUsers": 133,
            "email": "test1.test2@cars24.com",
            "name": "Golf Course Extension",
            "storeCity": "Gurgaon"
          }
        ]
      };
      //const { data: { detail } } = response;
      return response;
    })
    .catch(error => {
      return error;
    });
};

export {
  getStoreAssociateList
};