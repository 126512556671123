import { createTypes } from "reduxsauce";

export default createTypes(
  `
    FETCH_LEADS_SUCCESS
    FETCH_LEADS_FAILURE
    RESET_LEADS
    UPDATE_LEADS_FILTER_QUERY

`,
  {
    prefix: "raleads/"
  }
);
