import React, { useState } from "react";
import styles from "./welcome-self-appointment.module.scss";
import Welcome from "./images/welcomeStore.svg";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from "../../components/shared/button";
import { AppointmentService } from "../../service";
import { getMocStoreCheckIn } from "../../utils/mocApi";
import { onNumberKeyPress } from "../../utils/helper";
import { NUMBERS } from "../../utils/app-constants";

const useStyles = makeStyles(theme => {
  return {
    phoneText: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(2),
      fontSize: '10px',
    }
  };
});

const WelcomeSelfAppointment = ({
  storeId,
  storeName,
  onSuccess
}) => {
  const classes = useStyles();
  const [mobileNo, setMobileNo] = useState("");
  const [errorText, setErrorText] = useState("");

  const onClickProceed = () => {
    const data = {
      "store": storeId,
      "phone": mobileNo
    };
    if (mobileNo.length !== NUMBERS.TEN) {
      setErrorText("Please enter valid Phone no");
    }
    else {
      AppointmentService.storeCheckIn(data)
        .then(resp => {
          const { data: { detail } } = resp;
          onSuccess(detail, true);
        })
        .catch(err => {
          // let response = getMocStoreCheckIn();
          // const { data: { detail } } = response;
          //onSuccess(detail, true);
          onSuccess(null, false);

        });
    }
  };
  const onMobilePhoneChange = (e) => {
    const { target: { value } } = e;
    setErrorText("");
    if (!!value && value.length < 11) {
      setMobileNo(value);
    }

  };
  return (
    <div className={styles.welcomeSelfAppointment}>
      <div className={styles.mainImage}>
        <img src={Welcome} alt="welcome" />
      </div>
      <p className={styles.checkinText}>Welcome to CARS24!</p>
      <p className={styles.welcomeSubText}>{storeName}</p>
      <p className={styles.phoneNo}>Please enter your mobile number to continue.</p>
      <div className={styles.phoneNumber}>
        <TextField
          label="Mobile Number"
          className={classes.phoneText}
          margin="dense"
          variant="outlined"
          onChange={onMobilePhoneChange}
          maxLength={10}
          onKeyPress={onNumberKeyPress}
          value={mobileNo}
        >
        </TextField>
        {
          !!errorText &&
          <p className={styles.textDanger}>{errorText}</p>
        }
      </div>
      <div className={styles.ctaWrapper}>
        <Button ctaText="Proceed" classNames="selfCheckinCta" onClick={() => onClickProceed()} />
      </div>
    </div>
  );
};

export default WelcomeSelfAppointment;
