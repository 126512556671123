/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import styles from "./customer-similar-cars.module.scss";
import Cars from "../../components/images/frontmain_car.jpg";
import Rupess from "../../components/images/rupess.svg";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";
import { AppointmentService } from "../../service";
import { getSimilarTranscatedCarMoq } from "../../utils/mocApi";
import priceFormatter from "../../utils/helpers/price-formatter";
import { trackLMSEvents } from "../../utils/helpers/tracking-events";
import { getUid } from "../../utils/helper";

const CustomerSimilarCars = ({ customerOffer }) => {
    const [carData, setCarData] = useState([]);
    const { customerOffer: { appointmentId } } = customerOffer;
    const [slickSlider, setSlickSlider] = useState(true);
    const [componentShow, setComponentShow] = useState(false);

    const onNextClick = (onClick) => {
        // console.log("Corusal next arrow Click");
        trackEventCall(appointmentId, "Right Arrow");
        onClick();

    };
    const onBackClick = (onClick) => {
        // console.log("Corusal back arrow Click");
        trackEventCall(appointmentId, "Left Arrow");
        onClick();
    };
    const trackEventCall = (appointmentId, eventAction) => {
        trackLMSEvents("similarTransactedCarInputOutputPage", {
            eventLabel: appointmentId,
            lsRAID: getUid(),
            eventAction: eventAction,
        });
    };
    const SampleNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
          <div
                className={className}
                onClick={() => onNextClick(onClick)}
            />
        );
    };

    const SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
          <div
                className={className}
                // style={{ ...style, display: "block", background: "green" }}
                onClick={() => onBackClick(onClick)}
            />
        );
    };

    const carouselSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };
    const ownerShipNoAsString = (ownerShipNo) => {
        switch (ownerShipNo) {
            case "1":
                return "1st";
            case "2":
                return " 2nd";
            case "3":
                return "3rd";
            case "4":
                return "4th";
            case "5":
                return "5th";
            case "6":
                return "6th";
            default:
                return "";
        }

    };
    useEffect(() => {
        if (appointmentId > 0) {
            AppointmentService.getSimilarTransactedCars(appointmentId)
                .then(response => {
                    const { data: { detail } } = response;
                    if (!!detail && detail.length < 2) {
                        setComponentShow(false);
                    }
                    else {
                        if (!!detail && detail.length >= 3) {
                            setSlickSlider(true);
                        }
                        if (!!detail && detail.length < 3) {
                            setSlickSlider(false);
                        }
                        setComponentShow(true);
                        trackLMSEvents("ls_SimilarCarCount", {
                            eventLabel: appointmentId,
                            lsRAID: getUid(),
                            SimilarCar: detail.length,
                            Timestamp: new Date()
                        });
                        setCarData(detail);
                    }
                    // setIsLoading(false);
                })
                .catch(error => {
                    console.log('Error fetching AppointMent list', error);
                });

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointmentId]);

    return (
      <>
        {componentShow && <div className={styles.customerSimilarCars}>
          <h3 className={styles.heading}>SIMILAR VEHICLES TRANSACTED BEFORE</h3>
            {!!carData && carData.length > 0 && slickSlider && <div className={styles.carouselWrapper}>
              <Slider {...carouselSettings}>
                {carData.length > 0 && carData.map((item, index) =>
                  <div>
                    <div className={styles.carsCard}>
                      <div className={styles.carsCardImage}>
                        <img src={item.front_image} alt="" />
                      </div>
                      <div className={styles.yoursCarsContent}>
                        <p className={styles.carsPrice}><img src={Rupess} alt="rupess" /> {priceFormatter(item.bought_price, true)}</p>
                        <p>Owner {ownerShipNoAsString(item.ownership_number)}</p>
                        <p>Driven: {priceFormatter(item.odometer, true)} km</p>
                        <p>Mfg Year: {item.year}</p>
                      </div>
                    </div>
                  </div>
                        )}
              </Slider>
            </div>}
          <div className={styles.staticSlider}>
            {carData.length > 0 && !slickSlider && carData.map((item, index) =>

              <div className={styles.carsCard}>
                <div className={styles.carsCardImage}>
                  <img src={item.front_image} alt="" />
                </div>
                <div className={styles.yoursCarsContent}>
                  <p className={styles.carsPrice}><img src={Rupess} alt="rupess" /> {priceFormatter(item.bought_price, true)}</p>
                  <p>Owner {ownerShipNoAsString(item.ownership_number)}</p>
                  <p>Driven: {priceFormatter(item.odometer, true)} km</p>
                  <p>Mfg Year: {item.year}</p>
                </div>
              </div>

                    )}
          </div>
        </div >
            }
      </>
    );
};

export default CustomerSimilarCars;
