import React, { useState, useEffect } from "react";
import styles from "./allocate-cj-popup.module.scss";
import { POPUP_BUTTONS } from "../../utils/app-constants";
import Button from "../shared/button";
import H2 from "../h2/component";
import homeCj from "../../components/images/homecj.svg";
import arrowCj from "../../components/images/arrowcj.svg";
import TimeSlot from "../../components/images/timeSlot.svg";
import Calander from "../../components/images/calander.svg";
import Usercj from "../../components/images/usercj.svg";
import SelectComponent from "../shared/select/component";
import { showToastMessages, getNextActiveDays } from "../../utils/helper";
import { AppointmentService } from "../../service";
import Textarea from "../shared/textarea/component";
import { useDispatch } from "react-redux";
import {updateApiNoAccess} from "../hoc-access-controller/actions";
import {API_ENDPOINTS} from "../../service/api-constants";
import Loader from "../../components/shared/loader";
const AllocateCjPopup = (props) => {
  const { rowDetails, data, updateDataList, resetListing, onClose, currentDetail, 
    appointmentId : appId, userData, negotiations} = props;
  const { homeInspectionCj, appointment, model, variant, year, customerName, 
    zoneName, customerAddress, scheduleRIFlag=false  } = rowDetails || userData || {};
  const [homeInspectionCJ, sethomeInspectionCJ] = useState(homeInspectionCj);
  const [currentUserDetail, setCurrentUserDetail] = useState(currentDetail);
  const [timeSlot, setTimeSlot] = useState(null);
  const [dateSlot, setDateSlot] = useState(null);
  const dispatch = useDispatch();
  const appointmentId = appId || appointment;
  let cjDefaultObj = null;
  let cjDefaulDateObj = null;
  let cjDefaultSlotObj = null;

  const [slot, setSlot] = useState([]);
  const [activeDays, setActiveDays] = useState([]);
  const key = appointment || appId;

  const [selectedCj, setSelectedCj] = useState("");
  const [selectedSlot, setSelectedSlot] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [errorClass, setErrorClass] = useState("");
  const [errorText, setErrorText] = useState("");
  let [evaluatorList, setEvaluatorList] = useState([]);
  // selected negotiation data
  const { data : negoData, currentAppointmentIndex } = negotiations;
  const currentAppointmentData = negoData[currentAppointmentIndex] || {};
  const { customerAddress : negoCustomerAddress } = currentAppointmentData;
  const [ customerAddressState, setCustomerAddress ] = useState(!!customerAddress ? customerAddress : !!negoCustomerAddress ? negoCustomerAddress : "");
  const [ customerEditedAddress, setCustomerEditedAddress ] = useState(customerAddressState);
  const [ showAddressTextarea, setShowAddressTextarea ] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const isAddressEditable = origin === APPOINTMENT_TYPES_STRING.NEGOTIATION;
  useEffect(()=>{
    setIsLoading(true);
    setActiveDays(getNextActiveDays(6));
  }, []);
  useEffect(() => {
    if (!!data) {
      let evauatorData = JSON.parse(data);
      setEvaluatorList(evauatorData);
      setIsLoading(false);
    } else {
      !scheduleRIFlag && AppointmentService.getEvaluatorLeadCountBasedOnAppointment(appointmentId)
        .then(response => {
          const { data: { detail } } = response;
          setEvaluatorList(detail);
          if (!homeInspectionCJ) {
            AppointmentService.getAppointedCjDetail(appointmentId)
              .then(response => {
                const { data: { detail: { evaluatorId } } } = response;
                sethomeInspectionCJ(evaluatorId);
                setSelectedCj(evaluatorId.toString());
              }).catch(error => {
                dispatch(updateApiNoAccess(error));
                console.log("API error: ", error);

              });

          }
        }).catch(err => {
          dispatch(updateApiNoAccess(err));
          if (err.status !== API_ENDPOINTS.NOT_ACCESS) {
            const { detail } = err;
            showToastMessages(detail, false);
          }
        }).finally(()=> {
          setIsLoading(false);
        });
      AppointmentService.getAppointedCjDetail(appointmentId)
        .then(response => {
          setCurrentUserDetail(response.data);
          let timeDate = response.data.detail;
          setTimeSlot({ label: timeDate.timeSlot, value: timeDate.timeSlot });
          setDateSlot({ label: timeDate.appointmentDate, value: timeDate.appointmentDate });
          setSelectedSlot(timeDate.timeSlot);
          setSelectedDate(timeDate.appointmentDate);
        }).catch(error => {
          dispatch(updateApiNoAccess(error));
          console.log("API error: ", error);

        }).finally(()=>{
          setIsLoading(false);
        });
    };
  }, [appointmentId]);

  if (!!currentUserDetail) {
    const { timeSlot, appointmentDate } = currentUserDetail;
    cjDefaultSlotObj = { label: timeSlot, value: timeSlot };
    cjDefaulDateObj = { label: appointmentDate, value: appointmentDate };
  }

  if (!!evaluatorList && evaluatorList.length > 0) {
    let selectObject = { email: "Select CJ", idLmsUsers: "" };
    evaluatorList.unshift(selectObject);
    evaluatorList = evaluatorList.filter((thing, index, self) =>
      index === self.findIndex((t) => (
        t.email === thing.email
      ))
    );
    if (!!homeInspectionCJ) {
      let cj = evaluatorList.find(x => x.idLmsUsers == homeInspectionCJ);
      if (!!cj) {
        cjDefaultObj = { label: cj.email, value: cj.idLmsUsers };
      }
    }
    if (timeSlot) cjDefaultSlotObj = timeSlot;
    if (dateSlot) cjDefaulDateObj = dateSlot;
  }

  useEffect(() => {
    if (!!homeInspectionCJ) {
      setSelectedCj(homeInspectionCJ);
    }
    AppointmentService.getTimeSlot()
      .then(resp => {
        const { data: { detail: { slots } } } = resp;
        const timeSlots = slots.map(item => { return { value: item.id, label: item.value }; });
        setSlot(timeSlots);
      })
      .catch(error => {
        dispatch(updateApiNoAccess(error));
        console.log(error);
      });

    if (!!currentUserDetail) {
      const { timeSlot, appointmentDate } = currentUserDetail;
      setSelectedSlot(timeSlot);
      setSelectedDate(appointmentDate);
    }
  }, []);

  const onSlotSelect = (value) => {
    setErrorClass("");
    setErrorText("");
    setSelectedSlot(value);
    setTimeSlot({ label: value, value: value });
  };
  const onDateSelect = (value) => {
    setErrorClass("");
    setErrorText("");
    setSelectedDate(value);
    setDateSlot({ label: value, value: value });
  };
  const onEvaluatorSelect = (value) => {
    // alert(value + " - " + value);
    setErrorClass("");
    setErrorText("");
    setSelectedCj(value);
    sethomeInspectionCJ(value);

  };
  const validateData = () => {
    let bValid = true;
    let errors = "";
    if (selectedSlot === "") {
      bValid = false;
      errors = !!errors ? errors + " & Slot  " : " Slot ";
    }
    if (selectedDate === "") {
      bValid = false;
      errors = errors = !!errors ? errors + " & Date" : " Date ";
    }
    if (!bValid) {
      setErrorText(`Please select ${errors}`);
      setErrorClass("form-text text-danger");
    }
    return bValid;
  };
  const onScheduleRI = () => {
    if (validateData()) {
      const params = {
        'appointment': appointment || appointmentId,
        'appointmentDate': selectedDate,
        'timeSlot': selectedSlot
      };
      AppointmentService.scheduleRI(params).then(resp => {
        const { data: { detail } } = resp;
        showToastMessages(detail);
        onClose();
        resetListing();
        if(updateDataList) updateDataList(appointment);
      }).catch(err => {
        const { detail } = err;
        showToastMessages(detail, false);
      }); 
    }
  };

  const onAssignCj = () => {
    let bValid = validateData();
    if (bValid) {
      let action = "ALLOCATE";
      if (selectedCj === "") {
        action = "DEALLOCATE";
      }
      const params = {
        'appointment': appointment || appointmentId,
        'evaluatorId': selectedCj,
        'action': action,
        'appointmentDate': selectedDate,
        'timeSlot': selectedSlot,
        'customerAddress': customerAddress
      };
      AppointmentService.assignBikeEvaluator(params)
        .then(resp => {
          const { data: { detail } } = resp;
          showToastMessages(detail);
          onClose();
          resetListing();
          if(updateDataList) updateDataList(appointment);
        })
        .catch(err => {
          const { detail } = err;
          showToastMessages(detail, false);
        });
    }
  };

  const addressDoneHandler = () => {
    const params = {
      'appointment': appointmentId,
      'field': 'customerAddress',
      'value': customerEditedAddress
    };
    AppointmentService.updateAppointment(params)
      .then(resp => {
        const { data: { detail: { message } } } = resp;
        showToastMessages(message);
        setCustomerAddress(customerEditedAddress);
        setShowAddressTextarea(false);
      })
      .catch(err => {
        const { detail } = err;
        showToastMessages(detail, false);
        setShowAddressTextarea(false);
      });
  };

  const addressCancelHandler = () => {
    setCustomerEditedAddress(customerAddressState);
    setShowAddressTextarea(false);
  };

  const displayEditableAddress = () => {
    return(
      <div className={styles.address}>
        {showAddressTextarea ?
          <div className={styles.addressWrapper}>
            <Textarea
              placeholder={"Please enter address"}
              value={customerEditedAddress}
              onChange={(e) => setCustomerEditedAddress(e.target.value)}
            />
            <div className={styles.addressUpdateCta}>
              <Button ctaText="DONE" classNames="secondaryCta" onClick={addressDoneHandler} />
              <Button ctaText="CANCEL" classNames="addressCancelCta" onClick={addressCancelHandler} />
            </div>
          </div>
                    :
          <>
            <p className={styles.addressWrapper}>{customerAddressState}</p>
            <span className={styles.edit} onClick={() => setShowAddressTextarea(true)}>Edit</span>
          </>
        }
      </div>
    );
  };
    
  return (
    <>{isLoading ? <Loader /> :
    <div className={styles.allocateWrapper}>
      <H2 text={!!scheduleRIFlag ? "Schedule RI" : "Allocate CJ"} />
      <p className={styles.txt}>{customerName || userData?.customerName} | {model || userData?.model} {variant || userData?.variant} | {year || userData?.year}</p>
      {customerAddress && 
      <div className={styles.customerLocationDetails}>
        <img src={homeCj} alt="homeIcon" />
        <p>{customerAddress}</p>
        {!!zoneName && <img src={arrowCj} alt="location" />}
        {!!zoneName && <p>{zoneName}</p>}
      </div>
      }
      <div className={styles.allocatecj}>
        <img src={TimeSlot} alt="TimeSlot" />
        <div className={styles.mr10}>
          {!!timeSlot && <SelectComponent
            key={key}
            name={`cj_Slot_${homeInspectionCJ}`}
            placeholder={"Select Time Slot"}
            optionsList={slot.map((item) => {
              return {
                label: item.label,
                value: item.label
              };
            })}
            questionId={appointment}
            onChange={onSlotSelect}
            defaultValue={timeSlot}
          />}
          {!timeSlot && <SelectComponent
            key={key}
            name={`cj_Slot_${homeInspectionCJ}`}
            placeholder={"Select Time Slot"}
            optionsList={slot.map((item) => {
              return {
                label: item.label,
                value: item.label
              };
            })}
            questionId={appointment}
            onChange={onSlotSelect}
          />}
        </div>
        <img src={Calander} alt="Calander" />
        <div className={styles.mr10}>
          {!!dateSlot && <SelectComponent
            key={key}
            name={`cj_date_${homeInspectionCJ}`}
            placeholder={"Select Date"}
            optionsList={activeDays.map((item) => {
              return {
                label: item,
                value: item
              };
            })}
            questionId={appointment}
            onChange={onDateSelect}
            defaultValue={dateSlot}
          />}
          {!dateSlot && <SelectComponent
            key={key}
            name={`cj_date_${homeInspectionCJ}`}
            placeholder={"Select Date"}
            optionsList={activeDays.map((item) => {
              return {
                label: item,
                value: item
              };
            })}
            questionId={appointment}
            onChange={onDateSelect}
          />}
        </div>
        {evaluatorList.length > 0 && <img src={Usercj} alt="Usercj" />}
        <div className={styles.mr10}>
          {evaluatorList.length > 0 && <SelectComponent
            key={key}
            name={`select_${homeInspectionCJ}`}
            placeholder={"cj"}
            optionsList={evaluatorList.map((item) => {
              return {
                label: item.email,
                value: item.idLmsUsers
              };
            })}
            questionId={appointment}
            onChange={onEvaluatorSelect}
            defaultValue={!!cjDefaultObj ? cjDefaultObj : ""}
          />}
        </div>

      </div>
      <small key="info" className={`${errorClass}`}>{errorText}</small>
      <div className={styles.ctaButton}>
        <Button classNames="saveCta" ctaText={POPUP_BUTTONS.SUBMIT} disabled={showAddressTextarea} onClick={!!scheduleRIFlag ? onScheduleRI : onAssignCj} />
        <Button classNames="cancelCta" ctaText={POPUP_BUTTONS.CANCEL} onClick={onClose} />
      </div>
    </div>}
    </>
  );
};

export default AllocateCjPopup;
