import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SellOnlineFilter from "./component";
import { updateFilterQuery } from "./actions";
import { resetRangeByKey } from "../calendar-range/actions";

const mapStateToProps = ({
  sellOnlineFilter,
  appointments
}) => ({
  sellOnlineFilter,
  appointments

});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateFilterQueryConnect: updateFilterQuery,
  resetRangeByKeyConnect: resetRangeByKey
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SellOnlineFilter);
