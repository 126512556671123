import { createTypes } from "reduxsauce";

export default createTypes(
  `
    PLL_FILTER_QUERY
`,
  {
    prefix: "pllallocationfilters/"
  }
);
