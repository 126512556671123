import { createTypes } from "reduxsauce";

export default createTypes(
  `
    RESET_NOTIFICATION
    RESET_NOTIFICATION_BY_KEY
`,
  {
    prefix: "notifications/"
  }
);
