import { getFromLocalStorage } from "../../utils/helper";
import { APP_CONSTANTS, LS_KEYS, USER_ROLES, USER_ROLES_PATH } from "./app-constants";
import  {NUMBERS} from '../../utils/app-constants';
import {getB2BTenantId} from '../../utils/url-constants';
import { getSessionToken, getJwtRoles } from "@descope/react-sdk";

export const getB2BLoginUserData = () => {
  return  JSON.parse(localStorage.getItem(APP_CONSTANTS.OKTA_TOKEN) || "{}");
};

export const santiziePrice = (price, inWords = false) => {
  let updatedPrice = (price / NUMBERS.ONE_LAKH).toFixed(NUMBERS.TWO);
  if (inWords) {
    updatedPrice = `${updatedPrice} Lakhs`;
  }
  return updatedPrice;
};

export const getLocationDetails = (leadGeoLocation) => {
  const {zone={}, locations={}} = leadGeoLocation || {};
  if (Object.keys(zone || {}).length > 0) {
    return {...zone};
  } else {
    let locsDataSort = Object.values(locations).sort((a,b)=> a.distance - b.distance);
    if (locsDataSort.length > 0) {  
      return {...locsDataSort[0], zone_id: null};
    }
  }
};

export const isAuthorizedUser = () => {
  let pathName = window.location.pathname;
  if (!!pathName && pathName.includes(APP_CONSTANTS.appType) && (pathName.includes('implicit') || pathName.includes('dealer/offer-price'))) {
    return true;
  } 
  const curRole = getFromLocalStorage(LS_KEYS.DISPLAY_ROLE);
  switch (curRole) {
    case USER_ROLES.ADMIN:
    case USER_ROLES.RA:
      return USER_ROLES_PATH.ADMIN.includes(pathName) || pathName.includes('b2b/lead/detail');
    case USER_ROLES.CJ:
      return USER_ROLES_PATH.CJ.includes(pathName) || pathName.includes('b2b/lead/detail');;
    default:
      return true;
  }
};

export const isUserCj = () => {
  return getFromLocalStorage(LS_KEYS.DISPLAY_ROLE) === USER_ROLES.CJ;
};

export const isUserRA = () => {
  return [USER_ROLES.ADMIN,USER_ROLES.RA].includes(getFromLocalStorage(LS_KEYS.DISPLAY_ROLE));
};

export  const onBackHome = () => {
  window.location = `${window.origin}/${APP_CONSTANTS.appType}`;
};

export  const onBackLogin = (message=APP_CONSTANTS.UNAUTHORIZED_LOGIN_KEY) => {
  window.location = `${window.origin}/${APP_CONSTANTS.appType}/login?key=${message}`;
};

export const getCurrentB2BRoles = () => {
  const tenantId = getB2BTenantId();
  const sessionToken = getSessionToken();
  let roles = [];
  if (sessionToken && tenantId) {
    roles = getJwtRoles(sessionToken, tenantId);
  }
  return roles;
};

export const getLoginErrorMessage = (key) => {
  let errorMessage  = '';
  if (key === APP_CONSTANTS.NO_ROLES_KEY) {
    errorMessage = 'No permission found with your login in this application. Kindly contact support team for your access';
  } else if (key === APP_CONSTANTS.UNAUTHORIZED_LOGIN_KEY) {
    errorMessage = 'Unauthorized API access';
  }
  return errorMessage;
};