/* eslint-disable max-len */
import { SuggestionService } from "../../service";
import { normalizeObject } from "./helper";

const AUTO_SUGGESTION_MAP = {
  search: "query"
};

const PLACE_DETAILS_MAP = {
  placeId: "place_id"
};
class MapMyIndiaSuggestion {

  getPlaces(request) {
    const payload = normalizeObject(AUTO_SUGGESTION_MAP, request);
    return new Promise((resolve, reject) => {
      SuggestionService.fetchPlaceSuggestions({
        ...payload
        // pod: "CITY"
      })
        .then(response => {
          try {
            const {data:{detail}} = response;
            const result = detail.suggestedLocations.map((item) => ({
              ...item,
              place_id: item.eLoc,
              lat: item.latitude,
              lng: item.longitude,
              description: `${item.placeName} ${item.placeAddress}`
            }));
            resolve(result);
          } catch (er) {
            reject(new Error({ message: er }));
          }
        })
        .catch(() => {
          reject(new Error({ message: "Some error occured" }));
        });
    });
  }

  getPlaceDetails(request) {
    const payload = normalizeObject(PLACE_DETAILS_MAP, request);
    return new Promise((resolve, reject) => {
      SuggestionService.fetchPlaceDetails(payload)
        .then(response => {
          try {
            const data = response.data.detail.results.pop();
            data.formatted_address = `${data.houseNumber} ${data.houseName} ${data.district}`;
            data.geometry = {
              location: {
                lat: () => data.latitude,
                lng: () => data.longitude
              }
            };
            resolve([data]);
          } catch (e) {
            reject(new Error({ message: e }));
          }
        })
        .catch(() => {
          reject(new Error({ message: "Some error occured" }));
        });
    });
  }

  getReverseGeoCode(request) {
    const location = { lat: request.location.lat, lng: request.location.lng };
    return new Promise((resolve, reject) => {
      SuggestionService.fetchReverseGeoCodeSuggestions(location)
        .then(response => {
          try {
            resolve(response.data.detail.results);
          } catch (e) {
            reject(new Error({ message: "Some error occured" }));
          }
        })
        .catch(() => {
          reject(new Error({ message: "Some error occured" }));
        });
    });
  }

  getGeoCode(request, type) {
    let fn = this.getReverseGeoCode;
    if (type === "place") {
      fn = this.getPlaceDetails;
    }
    return fn(request);
  }

  async getAddressFromCoordinates(location) {
    try {
      const locations = await this.getReverseGeoCode({ location });
      return locations[0].formatted_address;
    } catch (error) {
      return "India";
    }
  }
}

export default MapMyIndiaSuggestion;
