import React from "react";
import styles from "./lead-detail-tabs.module.scss";
import { LEAD_DETAILS_TABS } from "../../utils/app-constants";
import { isBikeSource } from "../../utils/helper";

const LeadDetailTabs = ({ selectedTab, setSelectedTab, state_name, isPll, isC24QuoteShared, isCJMRole = false }) => {
  let leadTabs = Object.values(LEAD_DETAILS_TABS);
  const isBike = isBikeSource(); 
  if (isPll) {
    leadTabs = leadTabs.filter(x => x.key !== "dealerDetail");
  }
  else {
    if ((!!state_name && state_name.toLowerCase() !== "in-negotiation") && !isPll) {
      if (!isC24QuoteShared) {
        leadTabs = leadTabs.filter(x => x.key !== "dealerDetail");
      }
    }
    else if ((!!state_name && state_name.toLowerCase() === "in-negotiation") && !isPll) {
      if (!isC24QuoteShared) {
        leadTabs = leadTabs.filter(x => x.key !== "dealerDetail");
      }
    }
  }
  if (!isBike) {
    leadTabs = leadTabs.filter(x => x.key !== "bikePriceAcceptance");
  }
  if (isCJMRole) {
    leadTabs = leadTabs.filter(x => !["c24QuoteDetail"].includes(x.key));
  }
  const getClass = (tab) => {
    return tab === selectedTab ? styles.active : "";
  };

  return (
    <nav className={styles.tabs}>
      <ul>
        {
          leadTabs.map(item => {
            return (
              <li key={item.key} className={getClass(item.key)} onClick={() => setSelectedTab(item.key)}>
                {item.label}
              </li>
            );
          })
        }
      </ul>
    </nav>
  );
};

export default LeadDetailTabs;
