import React, { useState } from "react";
import styles from "./lead-check-in-search.module.scss";
import CheckinSearchIcon from "../../components/images/search.svg";
// import InputText from "../shared/input-text/component";
import { onNumberKeyPress } from "../../utils/helper";
import { ERROR_MESSAGE, NUMBERS } from "../../utils/app-constants";

const LeadCheckInSearch = ({
  query,
  setQuery
}) => {

  const [searchQuery, setSearchQuery] = useState();
  const [showError, setShowError] = useState(false);
  // const [isSearchedData, setIsSearchedData] = useState(false);

  const onChangeHandler = (e) => {
    const { target: { value } } = e;
    setSearchQuery(value);
  };

  // const onSearchClick = () => {
  //     if (searchQuery !== undefined && query !== searchQuery) {
  //         if (searchQuery.trim()) {
  //             if (searchQuery.trim().length === NUMBERS.TEN) {
  //                 setQuery(searchQuery.trim());
  //                 setShowError(false);
  //             } else {
  //                 setShowError(true);
  //             }
  //             setIsSearchedData(true);
  //         } else if (isSearchedData) {
  //             clearSearch();
  //         }
  //     }
  // };
  const onSearchClick = () => {
    if (searchQuery !== undefined && query !== searchQuery) {
      if (searchQuery.trim()) {
        if (searchQuery.trim().length === NUMBERS.TEN) {
          // setIsSearchedData(true);
          setQuery(searchQuery.trim());
          setShowError(false);
        } else {
          setShowError(true);
          setSearchQuery();
        }
      } else {
        clearSearch();
      }
    }
  };

  const onKeyPressHandler = (e) => {
    const { charCode } = e;
    if (charCode === NUMBERS.THIRTEEN) {
      onSearchClick();
    } else {
      onNumberKeyPress(e);
    }
  };

  const clearSearch = () => {
    setSearchQuery();
    // setIsSearchedData(false);
    setQuery();
    setShowError(false);
  };

  return (
    <div className={styles.leadSearchMainWrapper}>
      <div className={styles.leadSearchWrapper}>
        <p className={styles.searchHeading}>Enter App. ID or phone no. to check in</p>
        <div className={styles.leadSearchWrapper}>
          <input type="text"
            name="search"
            autoComplete="off"
            value={searchQuery || ""}
            maxLength={10}
            onChange={onChangeHandler}
            onKeyPress={onKeyPressHandler}
          />
          <button><img src={CheckinSearchIcon} alt="" onClick={onSearchClick} /></button>
          {/* <InputText text="Search"
                        value={searchQuery || ""}
                        maxLength={10}
                        onChange={onChangeHandler}
                        onKeyPress={onKeyPressHandler}
                    /> */}
          {/* {isSearchedData && <button className={styles.searchRest} onClick={clearSearch}>x</button>}
                    {!isSearchedData && <button><img src={CheckinSearchIcon} alt="" onClick={onSearchClick} /></button>} */}
        </div>
        {showError &&
        <span className={styles.errorText}>{ERROR_MESSAGE.CHECK_IN_LEAD_SEARCH}</span>
        }
      </div>

    </div>
  );
};

export default LeadCheckInSearch;
