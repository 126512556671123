import React from "react";
import styles from "./change-log-activities.module.scss";
import ChangeLog from "../../components/change-log";

const ChangeLogActivities = ({ appointmentId }) => {
  return (
    <div className={styles.activitiesWrapper}>
      <ChangeLog appointmentId={appointmentId}/>
    </div>
  );
};

export default ChangeLogActivities;
