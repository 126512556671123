import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RaLeads from "./component";
import { fetchRaLeads, resetLeads, updateLeadsFilterQuery } from "./actions";

const mapStateToProps = ({
  raLeads,
  sortFilter
}) => ({
  raLeads,
  sortFilter
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchRaLeadsConnect : fetchRaLeads,
  resetLeadsConnect: resetLeads,
  updateLeadsFilterQueryConnect: updateLeadsFilterQuery
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RaLeads);
