// import { resettableReducer } from 'reduxsauce';
import { combineReducers } from 'redux';
import appointmentsReducer from "../../pages/appointment/reducers";
import negotiationsReducer from "../negotiation-list/reducers";
import checkInReducer from "../check-in-popup/reducers";
import checkInActionReducer from "../check-in/reducers";
import followUpReducer from "../follow-up-popup/reducers";
import followUpHistoryReducer from "../follow-up-history/reducers";
import denyCheckInReducer from "../check-in-deny-popup/reducers";
import sidebarReducer from "../sidebar/reducers";
import leadMenuReducer from "../lead-menu/reducers";
import searchReducer from "../search/reducers";
import negotiationFilterReducer from "../negotiation-filter/reducers";
import sellOnlineFilterReducer from "../sell-online-filter/reducers";
import visitedFiltersReducer from "../visited-filters/reducers";
import globalSearchReducer from "../global-search/reducers";
import leadDetailsReducer from "../../pages/lead-detail/reducers";
import lostLeadsReducer from "../../pages/lost-leads/reducers";
import individualsReducer from "../individuals/reducers";
import leadCheckInReducer from "../../pages/lead-check-in/reducers";
import depreciationReducer from "../depreciation-reports/reducers";
import pllNegotiationFilterReducer from "../pllNegotiation-filter/reducers";
import evaluatorReducer from "../../pages/evaluator-view/reducers";
import bookAppointmentReducer from "../book-appointment/reducers";
import pllStoresReducer from "../pll-store-list/reducers";
import lostLeadsearchReducer from "../search-lost-leads/reducers";
import similarCarsReducer from "../../pages/similar-cars/reducers";
import negotiationRangeReducer from "../calendar-range/reducers";
import customerOfferReducer from "../../pages/customer-offer-price/reducers";
import notificationReducer from "../notifications/reducers";
import homeInspectionFiltersReducer from "../homeInspection-filters/reducers";
import zoneFiltersReducer from "../zone-filter/reducers";
import headerRowFilterReducer from "../shared/table-header-with-control/reducers";
import homeInspectionConfirmLeadReducer from "../cc-confirm-leads/reducers";
import cjAllocationStatusReducer from "../cj-allocation-status/reducers";
import customerChargesReducer from "../customer-offer/reducers";
import reviewInspectionReducer from "../review-inspection/reducers";
import paymentProcessingFilterReducer from "../payment-processing-filter/reducers";
import raIncentiveReducer from "../../pages/incentive/reducers";
import postLoginReducer from "../../pages/login/reducers";
// import raChatReducer from "../ra-dealer-chat/reducers";
import paymentInitiateTokenReducer from "../../pages/payment-initiate-token/reducers";
import vahanBackgroundReducer from '../../components/vahan-background/reducers';
import chargesHoldbacksReducer from '../../components/charges-holdbacks/reducers';
// listen for the action type of 'RESET', you can change this.
// const resettable = resettableReducer('RESET')
import pllAllocationFilterReducer from "../../components/pll-allocation-filters/reducers";
import hocAccessControllerReducer from "../hoc-access-controller/reducers";
import unallocatedLeadsReducer from '../../pages/unallocated-leads/reducers';

export default combineReducers({
  appointments: appointmentsReducer,
  negotiationFilter: negotiationFilterReducer,
  sellOnlineFilter: sellOnlineFilterReducer,
  negotiations: negotiationsReducer,
  checkIn: checkInReducer,
  checkInAction: checkInActionReducer,
  followUp: followUpReducer,
  followUpHistory: followUpHistoryReducer,
  denyCheckIn: denyCheckInReducer,
  sidebar: sidebarReducer,
  leadMenu: leadMenuReducer,
  search: searchReducer,
  visitedFilters: visitedFiltersReducer,
  globalSearch: globalSearchReducer,
  leadDetails: leadDetailsReducer,
  leadCheckIn: leadCheckInReducer,
  lostLeads: lostLeadsReducer,
  individuals: individualsReducer,
  depreciation: depreciationReducer,
  pllNegotiationFilter: pllNegotiationFilterReducer,
  evaluator: evaluatorReducer,
  bookAppointment: bookAppointmentReducer,
  pllStores: pllStoresReducer,
  lostLeadsearch: lostLeadsearchReducer,
  negotiationRange: negotiationRangeReducer,
  similarCars: similarCarsReducer,
  customerOffer: customerOfferReducer,
  notifications: notificationReducer,
  homeInspectionFilters: homeInspectionFiltersReducer,
  zoneFilters: zoneFiltersReducer,
  headerRowFilter: headerRowFilterReducer,
  homeInspectionConfirmLead: homeInspectionConfirmLeadReducer,
  cjAllocationStatus: cjAllocationStatusReducer,
  customerCharges: customerChargesReducer,
  reviewInspection: reviewInspectionReducer,
  paymentProcessingFilter: paymentProcessingFilterReducer,
  raIncentive: raIncentiveReducer,
  raChannels: postLoginReducer,
  paymentInitiateToken: paymentInitiateTokenReducer,
  vahanBackground: vahanBackgroundReducer,
  chargesHoldbacks: chargesHoldbacksReducer,
  pllAllocationFilter: pllAllocationFilterReducer,
  hocAccess: hocAccessControllerReducer,
  unallocatedLeads:unallocatedLeadsReducer
});