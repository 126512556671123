import React from "react";
import styles from "./change-log-details.module.scss";
import priceFormatter from "../../utils/helpers/price-formatter";
import moment from "moment";
const ChangeLogDetails = ({ data }) => {
    
  const getReqType = (columnName, type) => {
    let reqType = type;
    let pTag = <p className={styles.retail}>{reqType}</p>;
    switch(columnName)
    {
      case "retailAssociateEmail":
        reqType = 'Retail';
        pTag = <p className={styles.retail}>{reqType}</p>;
        break;
      case "appointmentCreated":
      case "appointmentRescheduled":
      case "secondaryPhone":
        reqType = '';
        pTag = '';
        break;
      default:
        break;
    }
    return pTag;
  };
  return (
    <div className={styles.calLogTextWrapper}>
      <div className={styles.logtitleSection}>
        <p className={styles.logTitle}>{data.org_column === "retailAssociateEmail" ? "RA ALLOCATED : " + data.newValue : data.columnName}</p>
        <p className={styles.logTime}>{moment(data.createdDate).format('DD-MMM-YYYY hh:mm A').toString()}</p>
        {getReqType(data.org_column, data.reqType)}
      </div>
      <div className={styles.callLogStaticData}>
        {data.org_column === "paiRaised" && <p className={styles.text}>PAI Raised By {data.email} for {priceFormatter(data.newValue)}</p>}
        {data.org_column === "listPrice" && <p className={styles.text}>Car Listed By {data.email}</p>}
        {data.org_column === "reviseListPrice" && <p className={styles.text}>Car Listed By {data.email}</p>}
        {data.org_column === "ocbRaised" && <p className={styles.text}>OCB Raised By {data.email} for {priceFormatter(data.newValue)}</p>}
        {data.org_column === "retailAssociateEmail" && <p className={styles.text}>RA Changed by {data.name}</p>}
        {data.org_column === "appointmentCreated" && <p className={styles.text}>Appointment Created by {data.email}</p>}
        {data.org_column === "appointmentRescheduled" && <p className={styles.text}>Appointment Rescheduled by {data.email}</p>}
        {data.org_column === "secondaryPhone" && <p className={styles.text}>Secondary Number Updated by {data.email}</p>}
      </div>
    </div>
  );
};

export default ChangeLogDetails;
