import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import HomeInspectionFilters from "./component";
import { updateFilterQuery } from "./actions";

const mapStateToProps = ({
  homeInspectionFilters
}) => ({
  homeInspectionFilters
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateFilterQueryConnect: updateFilterQuery
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HomeInspectionFilters);
