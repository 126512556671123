import axios from "axios";
import { ERROR_CODES } from "../../service/api-constants";
import { getVehicleServiceMetaData } from "../../utils/url-constants";
import {onBackLogin} from "../common/utility";
const DEBUG = process.env.NODE_ENV === "development";
const apiMetaData = getVehicleServiceMetaData();

const api = axios.create({
  baseURL: apiMetaData.url,
  headers: {
    "Content-Type": "application/json"
  }
});

api.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Basic ${apiMetaData.key}`;
  if (DEBUG) { console.info("✉️ ", config); }
  return config;
}, (error) => {
  if (DEBUG) { console.error("✉️ ", error); }
  return Promise.reject(error);
});

api.interceptors.response.use((response) => {
  if (DEBUG) { console.info('📩 ', response); }
  return response;
}, (error) => {
  if (DEBUG) { console.error('Error: ', error.message); }
  if (error.response && error.response.status === ERROR_CODES.UNAUTHORISED && !window.location.pathname.includes('login')) {
    localStorage.clear();
    onBackLogin();
  }
  return Promise.reject(error.message);
});

export default api;