import Types from "./types";
// import {getRAIncentive} from "../../utils/mocApi";
import { AppointmentService } from "../../service";

const fetchRAIncentive = (raEmail) => dispatch => {
  return AppointmentService.getDashboardRAIncentive(raEmail)
    .then(response => {
      // response = getRAIncentive();
      const { data: { detail } } = response;
      dispatch(fetchRAIncentiveSuccess(detail));
      return response;
    })
    .catch(error => {
      dispatch(fetchRAIncentiveFailure(error));
    });
            
}; 

const fetchRAIncentiveSuccess = (data) => ({
  type: Types.FETCH_RA_INCENTIVE_SUCCESS,
  data
});

const fetchRAIncentiveFailure = (error) => ({
  type: Types.FETCH_RA_INCENTIVE_FAILURE,
  error
});

const resetRAIncentive = () => ({
  type: Types.RESET_RA_INCENTIVE
});

export {
  fetchRAIncentive,
  resetRAIncentive
};
