import React, {useState, useEffect} from "react";
import styles from "./bike-re-raise-initiate-payment-popup.module.scss";
import H2 from "../h2";
import Button from "../shared/button";
import InputTextGreenTick from "../shared/input-text-green-tick";
import { NUMBERS } from "../../utils/app-constants";
import { AppointmentService } from "../../service";
import hasSpecialCharacters from "../../utils/helpers/has-special-characters";
import { showToastMessages } from "../../utils/helper";
import SelectComponent from "../shared/select/component";
import { validateRegistrationNumber } from "../../utils/validators/registrationNumber-validator";
import InputTextRegistrationNumber from "../shared/input-text-registration-number";

const BikeReRaiseInitiatePaymentPopup = ({data, tokenPaymentData, onClose}) => {
  const {regNumber, chassisNumber : chNumber, engineNumber: engNumber, vahanRaiseReasons, purchaseRequestId} = tokenPaymentData || {};
  const {appointmentId, customerName = '', model='', year=''} = data || {};
  const [registrationNumber, setRegistrationNumber] = useState(regNumber);
  const [chasisNumber, setChasisNumber] = useState(chNumber);
  const [engineNumber, setEngineNumber] = useState(engNumber);
  const [isProceed, setIsProceed] = useState(false);
  const [raiseReason, setRaiseReason] = useState('');
  const [isProceedSubmit, setIsProceedSubmit] = useState(false);
  const [isValidregistrationNumber, setIsValidregistrationNumber] = useState(false);

  useEffect(()=>{
    setIsValidregistrationNumber(validateRegistrationNumber(regNumber));
  },[regNumber]);
    
  const onRegistrationNumberChange = (e) => {
    const { target: { value } } = e;
    if (value.length <= NUMBERS.ELEVEN) {
      setRegistrationNumber(value.toUpperCase());
      setIsValidregistrationNumber(validateRegistrationNumber(value));
    }
  };

  const onChasisNumberChange = (e) => {
    const { target: { value } } = e;
    setChasisNumber(value);
  };

  const onEngineNumberChange = (e) => {
    const { target: { value } } = e;
    setEngineNumber(value);
  };

  const onProceedInitiatePayment = () => {
    setIsProceedSubmit(true);
    if (!registrationNumber) {
      showToastMessages("Registration number shouldn't empty", false);
      return;
    }

    if (!raiseReason || hasSpecialCharacters(registrationNumber) || hasSpecialCharacters(chasisNumber) || hasSpecialCharacters(engineNumber)) {
      return;
    }

    const params = {
      appointmentId: appointmentId,
      action: "RAISE_VAHAN",
      purchaseRequestId: purchaseRequestId,
      regNumber: registrationNumber,
      chassisNumber: chasisNumber,
      engineNumber: engineNumber,
      reRaiseReason: raiseReason
    };
    setIsProceed(true);
    AppointmentService.postBikePurchaseRequest(params).then(() => {
      onClose();
    }).catch((error) => {
      const { detail ='Error in update PR'} = error;
      console.log("error", error);
      showToastMessages(detail, false);
    }).finally(()=>{
      setIsProceed(false);
    });
  };
  return (
    <div className={styles.bikeInitiatePaymentPopup}>
      <H2 text="RC Details"/>
      <div className={styles.carDataList}>
        <ul>
          <li>{customerName}</li>
          <li>{model}</li>
          <li>{year}</li>
        </ul>
      </div>
      <div className={styles.inputWrapper}>
        <SelectComponent
          key={'raiseReason'}
          name={'raiseReason'}
          placeholder={"Reason for Re-Raise Vahan"}
          optionsList={!!vahanRaiseReasons ? vahanRaiseReasons.map((item) => {
            return {
              label: item,
              value: item
            };
          }) : []}
          questionId={appointmentId}
          onChange={(e)=> setRaiseReason(e)}
        />
        {!raiseReason && isProceedSubmit && <small className={styles.error}>Field Required</small> }
      </div>
      <div className={styles.inputWrapper}>
        <InputTextRegistrationNumber
          text="Registration Number*" 
          errorMessage={"It seems you have put wrong Registration number. Please check again."}
          value={registrationNumber}
          validator={validateRegistrationNumber}
          onChange={onRegistrationNumberChange}
        />
      </div>
      <p className={styles.textHeading}>Other Details</p>
      <span className={styles.subText}>Optional</span>
      <div className={styles.inputWrapper}>
        <InputTextGreenTick 
          text="Chassis Number" 
          error="It seems you have put wrong Chassis number. Please check again."
          maxLength={NUMBERS.TWENTY}
          value={chasisNumber}
          onChange={onChasisNumberChange}
          validator={hasSpecialCharacters}
        />
      </div>
      <div className={styles.inputWrapper}>
        <InputTextGreenTick 
          text="Engine Number" 
          error="It seems you have put wrong Chassis number. Please check again."
          value={engineNumber}
          maxLength={NUMBERS.TWENTY}
          onChange={onEngineNumberChange}
          validator={hasSpecialCharacters}
        />
      </div>
      <div>
        <Button ctaText="PROCEED" classNames="blueButton" onClick={onProceedInitiatePayment} disabled={isProceed || !isValidregistrationNumber || !raiseReason}/>
      </div>
    </div>
  );
};

export default BikeReRaiseInitiatePaymentPopup;
