import { createTypes } from "reduxsauce";

export default createTypes(
  `
    FETCH_FOLLOW_UP_HISTORY
    FETCH_FOLLOW_UP_HISTORY_SUCCESS
    FETCH_FOLLOW_UP_HISTORY_FAILURE
    RESET_FOLLOW_UP_HISTORY
`,
  {
    prefix: "followuphistory/"
  }
);
