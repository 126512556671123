/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import styles from "./evaluator-store-select.module.scss";
import SelectComponent from "../../components/shared/select/component";
import { getFromLocalStorage, saveToLocalStorage } from "../../utils/helper";
import { LOCAL_STORAGE_KEYS } from "../../utils/app-constants";
import { AppointmentService } from "../../service";
import DataInformationMessage from "../../components/shared/data-information-message";

const EvaluatorStoreSelect = ({ history }) => {
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        const storeList = getFromLocalStorage(LOCAL_STORAGE_KEYS.CITY_STORES_LIST) ?
            JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.CITY_STORES_LIST)) : [];
        if (storeList && storeList.length === 0) {
            setIsLoading(true);
            AppointmentService.getStoreList()
                .then(response => {
                    setIsLoading(false);
                    const { data: { detail } } = response;
                    saveToLocalStorage(LOCAL_STORAGE_KEYS.CITY_STORES_LIST, JSON.stringify(detail));
                    processStoresList();
                }).catch(error => {
                    setIsLoading(false);
                    console.log(error);
                });
        }
        else {
            processStoresList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const processStoresList = () => {
        const storeList = getFromLocalStorage(LOCAL_STORAGE_KEYS.CITY_STORES_LIST) ?
            JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.CITY_STORES_LIST)) : [];
        const storeOptions = [];
        storeList.map((store) => {
            return (
                storeOptions.push({
                    value: store.idLmsStore,
                    label: store.name
                })
            );
        });
        setOptions(storeOptions);
    };

    const onStoreSelect = (option) => {
        history.push(`/evaluator-view/${option}`);
    };

    return (
      <>
        <div className={styles.evaluatorStoreSelect}>
          <h3>Select Store</h3>
          <div className={styles.selectStore}>
            <SelectComponent
                        placeholder="Select Store"
                        optionsList={options ? options : []}
                        onChange={onStoreSelect}
                    />
          </div>
        </div>
        <DataInformationMessage
                isLoading={isLoading}
                error={false}
                data={[1]}
            />
      </>
    );
};

export default EvaluatorStoreSelect;
