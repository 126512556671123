import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SellOnline from "./component";
import withLayout from "../../components/layout/component";
import { fetchAppointments, resetAppointmentByKey, resetAppointments} from "../appointment/actions";
import { resetGlobalSearch } from "../../components/global-search/actions";
import {resetApiNoAccess} from "../../components/hoc-access-controller/actions";
import { resetLeadMenuByKey } from "../../components/lead-menu/actions";
const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchAppointmentsConnect: fetchAppointments,
  resetAppointmentByKeyConnect: resetAppointmentByKey,
  resetLeadMenuByKeyConnect: resetLeadMenuByKey,
  resetGlobalSearchConnect: resetGlobalSearch,
  resetAppointmentsConnect: resetAppointments,
  resetApiNoAccessConnect: resetApiNoAccess
}, dispatch);
const mapStateToProps = ({
  appointments,
  sidebar,
  search,
  globalSearch,
  sellOnlineFilter,
  leadMenu
}) => ({
  appointments,
  sidebar,
  search,
  globalSearch,
  sellOnlineFilter,
  leadMenu
});

export default connect(mapStateToProps, mapDispatchToProps)(withLayout(SellOnline));