import React from "react";
import styles from "./depreciation-car-reports.module.scss";

const DepreciationCarReports = ({graphData}) => {
  const { data }  = graphData;
  const {year,fuel_type,make,model} = data;
  return (
    <div className={styles.depreciationCarReports}>
      <div>
        <p className={styles.depreciationCarTitle}>Depreciation for {make} {model}</p>
        <ul>
          <li><span>Year</span> {year}</li>
          <li><span>Fuel Type</span>{fuel_type}</li>
        </ul>
      </div>
    </div>
  );
};

export default DepreciationCarReports;
