import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  zonefilterQuery: null
};

export const updateZoneFilterQuery = (state = INITIAL_STATE, { zonefilterQuery }) => {
  return { ...state, zonefilterQuery };
};

export const HANDLERS = {
  [Types.UPDATE_ZONE_FILTER_QUERY]: updateZoneFilterQuery
};

export default createReducer(INITIAL_STATE, HANDLERS);
