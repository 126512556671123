import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CustomerOfferPaiSlider from "./component";
import { preCheckRaisePAI, paiRequest } from "../../components/negotiation-list/actions";

const mapStateToProps = ({
  negotiations,
}) => ({
  negotiations,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  preCheckRaisePAIConnect: preCheckRaisePAI,
  paiRequestConnect: paiRequest
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CustomerOfferPaiSlider);
