import React,  { useState } from "react";
import Link from "../link";
import styles from "./car-detail.module.css";
import getCarName from "../../utils/helpers/get-car-name";
import { fetchInspectionReport, getFromLocalStorage, isBikeSource, isC2CUser, isPllUser, showToastMessages } from "../../utils/helper";
import { LOCAL_STORAGE_KEYS, ROLE_KEYS, LEAD_TYPE } from "../../utils/app-constants";
import AppointmentLink from "../appointment-link";
import Nego_car from "../images/nego_car.svg";
import Nego_cale from "../images/nego_cale.svg";
import Nego_user from "../images/nego_user.svg";
import Nego_phone from "../images/nego_phone.svg";
import Nego_report from "../images/nego_report.svg";
import Nego_ra from "../images/nego_ra.svg";
import Popup from "../../components/popup";
import SellerFeedbackToggle from  "../../components/seller-feedback-toggle";
import Button from "../shared/button/component";
import { AppointmentService } from "../../service";
import SoAllocateRAPopup from "../so-allocate-ra-popup/component";
// import AllocateCjSchedulePopup from "../allocate-cj-schedule-popup/component";
import {ERROR_CODES} from "../../service/api-constants";
import { getPortalHostUrl } from "../../utils/url-constants";

const PORTAL_HOST_URL = getPortalHostUrl();
const CarDetail = ({ data, isLostLead, updateDataList, isSaleOnline=false }) => {
  const {
    appointmentId,
		appointmentKey,
    appointment,
    exophone,
    customerName,
    carId,
    retail_associate_email,
    lostDateTime,
    lostMarkedBy,
    isDepReportAvailable,
    // isC2cTokenDone,
    // generateVnFlag,
    fallbackVn='',
    inspReportUrl='',
    appointmentBlockingTime,
    ctaAccessFlag,
    // leadType: appLeadType ='',
    isVnEnabled,
    // allocatePllRaFlag = false,
    storeType='',
    // isMonetized=false,
    isClickToCall= false,
  } = data || {};

  const isBike = isBikeSource();
  // const leadType = getFromLocalStorage(LOCAL_STORAGE_KEYS.LEADS);
  // const showRaDetail = leadType === LEAD_TYPE.STORE && retail_associate_email;
  // const orgRole = getLMSOrinialRole();
  const {originalRole: orgRole} = JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA));
  // const isRAAllocateAccess = isC2CUser() || isPllUser();
  // const isRAAccess = isHierarchy && orgRole === ROLE_KEYS.RA_TRA ? false : true;
  const isBtnAccess = [ROLE_KEYS.TM, ROLE_KEYS.PLL_TM, ROLE_KEYS.TM_NEW, ROLE_KEYS.PLL_CH, ROLE_KEYS.CORP_EDITOR, ROLE_KEYS.PLL_ZM_CENTER, ].includes(orgRole);
  const [virtualNo, setVirtualNo] = useState(exophone);
  const [isLoading, setIsLoading] = useState(false);
  const viewReport = () => {
    if (isBike) {
      !!inspReportUrl && window.open(inspReportUrl,  "_blank", 'noopener');
    } else {
      fetchInspectionReport(appointmentId);
    }
  };

  const openDepReport = () => {
    // var url = "/dep-report/" + appointmentId + "?carId=" + carId + "&report=ctaclick";
    var url = "/customer-journey/" + appointmentId + "?showreport=dep&carId=" + carId + "&report=ctaclick&isDepReportAvailable=" + isDepReportAvailable + "&pagesource=negotiation";
    window.open(url, "_blank", 'noopener');
  };

  const [allocateOpen, setAllocateOpen] = useState(false);
  const onAllocateOpen = () => {
    setAllocateOpen(true);
  };

  const onAllocateClose = () => {
    setAllocateOpen(false);
  };

  const onGenerateVN = () => {
    setIsLoading(true);
    AppointmentService.getVirtualNo(appointment || appointmentId).then(response => {
      const { data: { detail: { exophone } } } = response;
      setVirtualNo(exophone);
      setTimeout(() => {
        updateDataList(appointment);
      }, 10000);
    }).catch(error => {
      const { detail: { message }, status } = error;
      if (status === ERROR_CODES.NOT_ACCESS) {
        showToastMessages(error.detail || message, false);
      } else {
        isBike && fallbackVn && setVirtualNo(fallbackVn);
        (!isBike || !fallbackVn) && showToastMessages(message, false);
      }
    }).finally(()=>{
      setIsLoading(false);
    });
  };

  const onCallCustomer = () => {
    setIsLoading(true);
    AppointmentService.callCustomer(appointment || appointmentId).then(response => {
      showToastMessages(response.detail);
    }).catch(error => {
      const { detail: { message }, status } = error;
      if (status === ERROR_CODES.NOT_ACCESS) {
        showToastMessages(error.detail || message, false);
      }
    }).finally(()=>{
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    });
  };

  return (
    <div className={styles.carDetail}>
      <ul>
        <li><div className={styles.detailsIcon}><img src={Nego_car} alt="Nego_car" /></div>
          <p>{getCarName(data)}</p>
        </li>
        <li><div className={styles.detailsIcon}><img src={Nego_cale} alt="Nego_cale" /></div>
          <div className={styles.detailsInfo}>
            <div className={styles.sohiWrapper}>
              {!!ctaAccessFlag ?  <p>{appointmentId || appointment}</p>: <AppointmentLink 
                appointmentId={appointmentId || appointment} 
                trackEvent={true} isSaleOnline={isSaleOnline}
              />}
            </div>
          </div>
        </li>
        {isLostLead &&
        <>
          <li><div className={styles.detailsIcon}>Lost At</div>
            <div className={styles.detailsInfo}>{!!lostDateTime ? lostDateTime : "N/A"}</div>
          </li>
          <li><div className={styles.detailsIcon}>Lost By</div>
            <div className={styles.detailsInfo}>{!!lostMarkedBy ? lostMarkedBy : "N/A"}</div>
          </li>
        </>
        }
        {
          !isLostLead && !isSaleOnline &&<li>
            <div className={styles.detailsIcon}><img src={Nego_user} alt="Nego_user" /></div>
            <div className={styles.detailsInfo}>
              <div className={styles.sohiWrapper}>
                <p>{customerName}</p>
              </div>
            </div>
          </li>
        }
        {isSaleOnline && !appointmentBlockingTime && <li>
          <>
            <div className={styles.detailsIcon}><img src={Nego_phone} alt="Nego_phone" /></div>
            {!!virtualNo || !!ctaAccessFlag ? 
              <div className={styles.detailsInfo}><p className={styles.vnNumberWrapper}>{virtualNo}</p></div> : 
              <div className={styles.detailsInfo}>
                {storeType !== LEAD_TYPE.B2B && <Button 
                  isLoading={isLoading} 
                  ctaText="GENERATE NO." 
                  classNames="generateCta" 
                  onClick={onGenerateVN}/>}
              </div>
            }
          </>
        </li>}
        {!isSaleOnline && !isLostLead && (!ctaAccessFlag ||  (!!ctaAccessFlag && !!isVnEnabled)) && !isBike && <li>
          <>
            <div className={styles.detailsIcon}><img src={Nego_phone} alt="Nego_phone" /></div>
            {   !!isClickToCall ? 
              <div className={styles.detailsInfo}>
                <Button 
                  disabled={isLoading}
                  isLoading={isLoading} 
                  ctaText="Call Customer" 
                  classNames="generateCta" 
                  onClick={onCallCustomer}
                />
              </div>
                            :
                            !!virtualNo ? 
                              <div className={styles.detailsInfo}>
                                <p className={styles.vnNumberWrapper}>{virtualNo}</p>
                              </div> : 
                              <div className={styles.detailsInfo}>
                                {storeType !== LEAD_TYPE.B2B && <Button 
                                  isLoading={isLoading} 
                                  ctaText="GENERATE NO." 
                                  classNames="generateCta" 
                                  onClick={onGenerateVN}/>}
                              </div>
            }
          </>
        </li>}
        {
          <li>
            <SellerFeedbackToggle rowDetails={data}/>
          </li>
        }
        {/* <li className={styles.downloadReport}> */}
        {!isLostLead && <li>
          {!isSaleOnline && <div className={styles.detailsIcon}><img src={Nego_report} alt="nego_report" /></div>}
          <div className={styles.detailsInfo}>
            <div className={styles.reportCtaWrapper}>
              {isDepReportAvailable && !isSaleOnline &&
              <span className={styles.reportCtaActive}>
                <Link href="#" onClick={openDepReport} text={"Dep. Report"} />
              </span>
              }
              <span className={styles.reportCtaDeactive} >
                {/* {isSaleOnline ? <Link href="#" onClick={viewReport} text={"Self Insp. Report"} /> : <Link href="#" onClick={viewReport} text={"Insp. Report"} />} */}
                {!isSaleOnline && <Link href="#" onClick={viewReport} text={"Insp. Report"} />}
								{
                  appointmentKey && 
                  <Link 
                    href={`${PORTAL_HOST_URL}lms/negoview/${appointmentKey}`} 
                    preventDefault={true} 
                    text={"Nego View"} 
                    onClick={()=>{window.open(`${PORTAL_HOST_URL}lms/negoview/${appointmentKey}`,  "_blank", 'noopener');}}
                  />
                }
              </span>
            </div>
          </div>
        </li>
        }
        {!isLostLead && !isBike && <li>
          <div className={styles.detailsIcon}><img src={Nego_ra} alt="Nego_ra" /></div>
          <div className={styles.detailsInfo}>
            {!!retail_associate_email && <span className={styles.emailMaxword}>{retail_associate_email}</span>}
            {isBtnAccess && <span className={styles.allocate}  onClick={onAllocateOpen}>{!!retail_associate_email ? "Re-Allocate RA" :"Allocate RA"}</span>}
          </div>
          <Popup isOpen={allocateOpen} close={onAllocateClose}>
            <SoAllocateRAPopup onClose={onAllocateClose} data={data} resetUpdateDataList={updateDataList}/>
          </Popup>
        </li>}
      </ul>
    </div>
  );
};

export default CarDetail;

