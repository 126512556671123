import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateDealer } from "../../pages/lead-procurement/actions";
import DealerSearch from "./component";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  UpdateDealerConnect: updateDealer
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DealerSearch);
