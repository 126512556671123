import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LeadDetail from "./component";
import withLayout from "../../components/layout/component";
import { fetchLeadDetails, resetLeadDetails } from "./actions";
import { resetGlobalSearch } from "../../components/global-search/actions";
import {resetApiNoAccess} from "../../components/hoc-access-controller/actions";
const mapStateToProps = ({
  leadDetails,
  globalSearch
}) => ({
  leadDetails,
  globalSearch
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchLeadDetailsConnect: fetchLeadDetails,
  resetLeadDetailsConnect: resetLeadDetails,
  resetGlobalSearchConnect: resetGlobalSearch,
  resetApiNoAccessConnect: resetApiNoAccess
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withLayout(LeadDetail, false));
