import Types from "./types";
import { AppointmentService } from "../../service";
import {updateApiNoAccess} from "../../components/hoc-access-controller/actions";
const fetchLeadDetails = (appointmentId) => dispatch => {
  return AppointmentService.getLeadDetails(appointmentId)
    .then(response => {
      const { data: { detail } } = response;
      dispatch(fetchLeadDetailsSuccess(detail));
    })
    .catch(error => {
      dispatch(updateApiNoAccess(error));
      dispatch(fetchLeadDetailsFailure(error));
    });
};

const fetchLeadDetailsSuccess = (data) => ({
  type: Types.FETCH_LEAD_DETAILS_SUCCESS,
  data
});

const fetchLeadDetailsFailure = (error) => ({
  type: Types.FETCH_LEAD_DETAILS_FAILURE,
  error
});

const resetLeadDetails = () => ({
  type: Types.RESET_LEAD_DETAILS
});

export {
  fetchLeadDetails,
  resetLeadDetails
};