import React from "react";

const Footer = () => {
  return (
    <>
      <div>Footer Component</div>
    </>
  );
};

export default Footer;