import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import StoreList from "./component";
import { getStoreList } from "./actions";
import { fetchCjAllocationStatus } from "../cj-allocation-status/actions";
import { setSelectedStores } from "../../pages/login/actions";

const mapStateToProps = ({
  homeInspectionFilters,
  appointments
}) => ({
  homeInspectionFilters,
  appointments
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getStoreListConnect: getStoreList,
  fetchCjAllocationStatusConnect: fetchCjAllocationStatus,
  setSelectedStoresConnect: setSelectedStores
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StoreList);
