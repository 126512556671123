import React, { useEffect, useState } from "react";
import H2 from "../h2/component";
import priceFormatter from "../../utils/helpers/price-formatter";
import Checkbox from "../shared/checkbox/component";
import styles from "./lead-allocation.module.scss";
import { showToastMessages, isBikeSource } from "../../utils/helper";
import { NUMBERS, ALLOCATION_TYPE } from "../../utils/app-constants";
import tempUserLogo from "./images/temporary-user.svg";
const LeadAllocation = ({
	pllStores,
	resetPLLByKeyConnect,
	raAllocationType,
	appointmentList,
}) => {
	const [selectedAppIds, setSelectedAppIds] = useState([]);
	const { selectedAppointments, selectedStoreNames, storeAppointmentList } =
		pllStores;
	const isBike = isBikeSource();

	useEffect(() => {
		setSelectedAppIds(appointmentList);
	}, [appointmentList]);

	const onCheckboxHandler = (appId) => {
		let newAppIdsList = [];
		selectedAppointments.map((item) => 
			newAppIdsList.push(item.appointment)
		);
		const index = newAppIdsList.findIndex((app) => app === appId);
		if (index > -1) {
			for (let i = 0; i < newAppIdsList.length; i++) {
				if (newAppIdsList[i] === appId) {
					newAppIdsList.splice(i, 1);
				}
			}
		} else if (isValidAppointment(appId)) {
			newAppIdsList.push(appId);
		}
		const selectedList = storeAppointmentList.filter((item) =>
			newAppIdsList.includes(item.appointment)
		);
		resetPLLByKeyConnect("selectedAppointments", selectedList);
	};

	const isChecked = (appId) => {
		const app = selectedAppointments.find((app) => app.appointment === appId);
		return !!app;
	};

	const isValidAppointment = (appId) => {
		let isValid = false;
		if (
			isBike ||
			!raAllocationType ||
			(!!raAllocationType && raAllocationType === "0")
		) {
			isValid = true;
		} else {
			const curApp = appointmentList.find((appt) => appt.appointment === appId);
			if (!!curApp) {
				if (raAllocationType === ALLOCATION_TYPE.LOW) {
					if (NUMBERS.TWO_LAKHS - curApp.c24Quote >= NUMBERS.ZERO) {
						isValid = true;
					} else {
						showToastMessages(
							"Select Cars of C24 Less Than/Equal To 2L only",
							false
						);
					}
				} else if (raAllocationType === ALLOCATION_TYPE.HIGH) {
					if (curApp.c24Quote - NUMBERS.TWO_LAKHS > NUMBERS.ZERO) {
						isValid = true;
					} else {
						showToastMessages("Select Cars of C24 more Than 2L only", false);
					}
				}
			}
		}

		return isValid;
	};

	return (
		<div className={styles.leadFilterWrapper}>
			<H2 text={selectedStoreNames} />
			<div className={styles.storeList}>
				<ul>
					{selectedAppIds.map((appId) => {
						return (
							<li>
								<div className={styles.storeIdWrapper}>
									<Checkbox
										id={`chk_${appId.appointment}`}
										name={`chk_${appId.appointment}`}
										onChangeCallback={() =>
											onCheckboxHandler(appId.appointment)
										}
										checkedStatus={isChecked(appId.appointment)}
									/>
									<p>App ID {appId.appointment}</p>
								</div>
								{!isBike && (
									<div>
										<span>{`${
											appId.isExpired === NUMBERS.ONE ? "Exipred" : ""
										} C24 Quote: ${priceFormatter(appId.c24Quote)}`}</span>
									</div>
								)}
								<div className={styles.assignWrapper}>
									{appId.first_name && (
										<>
											<p className={styles.active}>
												{appId.first_name + " " + appId.last_name}
												{!!appId.isTempAllocation && (
												<img className={styles.tempico} src={tempUserLogo} alt="custom" />
												)}
											</p>
											
										</>
									)}
									{!appId.first_name && (
										<p className={styles.name}>Unallocated</p>
									)}
								</div>
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
};

export default LeadAllocation;
