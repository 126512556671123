import { createTypes } from "reduxsauce";

export default createTypes(
  `
    FETCH_CJ_ALLOCATION_STATUS_SUCCESS
    FETCH_CJ_ALLOCATION_STATUS_FAILURE
`,
  {
    prefix: "cjallocationstatus/"
  }
);
