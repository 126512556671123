import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReauctionPopup from "./component";
import { paiRequest, setCurrentPopup, fetchNegotiations, resetNegotiations } from "../negotiation-list/actions";

const mapStateToProps = ({ negotiations }) => ({ negotiations });

const mapDispatchToProps = (dispatch) => bindActionCreators({
  paiRequestConnect: paiRequest,
  setCurrentPopupConnect: setCurrentPopup,
  fetchNegotiationsConnect: fetchNegotiations,
  resetNegotiationsConnect: resetNegotiations
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ReauctionPopup);
