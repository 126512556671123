import { createReducer } from "reduxsauce";
import Types from "./types";
import { showToastMessages } from "../../utils/helper";
export const INITIAL_STATE = {
  apiNoAccess: false,
};

export const setApiNoAccess = (state = INITIAL_STATE, {data}) => {
  if (!state.apiNoAccess) {
    showToastMessages(data.detail, false);
  }
  return { ...state, apiNoAccess: true};
};

export const resetApiNoAccess = (state = INITIAL_STATE) => {
  return { ...state, apiNoAccess: false};
};

export const HANDLERS = {
  [Types.SET_API_NO_ACCESS]: setApiNoAccess,
  [Types.RESET_API_NO_ACCESS]: resetApiNoAccess,

};

export default createReducer(INITIAL_STATE, HANDLERS);
