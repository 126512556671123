import { createTypes } from "reduxsauce";

export default createTypes(
  `
    UPDATE_ZONE_FILTER_QUERY
`,
  {
    prefix: "zonefilter/"
  }
);
