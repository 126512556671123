import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import NegotiationPopupBike from "./component";
import { fetchFollowUpList, postFollowUp, resetFollowUp } from "../follow-up-popup/actions";
import { fetchNegotiations, resetNegotiations } from "../../components/negotiation-list/actions";
import { fetchLostLeadQuestions } from "../lost-lead-nego-popup/actions";

const mapStateToProps = ({
  followUp,
  negotiations
}) => ({
  followUp,
  negotiations
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchFollowUpListConnect: fetchFollowUpList,
  postFollowUpConnect: postFollowUp,
  fetchNegotiationsConnect: fetchNegotiations,
  resetNegotiationsConnect: resetNegotiations,
  resetFollowUpConnect: resetFollowUp,
  fetchLostLeadQuestionsConnect: fetchLostLeadQuestions
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NegotiationPopupBike);
