/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import styles from "./dep-report.module.scss";
import DepLogo from "../../components/images/depLogo.svg";
import Asterisk from "../../components/images/asterisk.svg";
import Button from "../../components/shared/button";
import Accordion from "../../components/shared/accordion";
import ExponentialGraph from "../../components/exponential-graph";
import { trackLMSEvents } from "../../utils/helpers/tracking-events";
import {
    getUid, showToastMessages,
    getDepreciationTooltip_New, fetchInspectionReport, GetQueryString, getEventCategory
} from "../../utils/helper";
import { DEPRECIATION_CONSTANTS_NEW } from "../../utils/app-constants";
import { AppointmentService } from "../../service";
import specificationsParser from "../../utils/helpers/specifications-parser";

import { usePageVisibility } from "../../utils/pageVisibilityUtils";
import {
    useTotalVisibilityTimer
} from "../../components/shared/timerHooks";
const SAMPLE_ITEMS = [
    {
        key: "insurance",
        heading: "Insurance Type",
        content: "sdf",
        type: "default",
        displayPara: "Expired/ Third party Insurance has a double impact on the perceived value of a car - The buyer has to buy a new insurance and might have to pay a higher premium for insurance break-in. Also any cost on existing repairs will have to be borne by them."
    },
    {
        key: "owner",
        heading: "Owner Number",
        content: "sdf",
        type: "default",
        displayPara: "Multiple owners increase the risk of an unknown underlying issue with the car - Hence buyers prefer cars that have been driven by single owners and this has a significant impact on the perceived value."
    },
    {
        key: "odometer",
        heading: "Odometer",
        content: "sdf",
        type: "default",
        displayPara: "There is an average usage that comes with the age of the car - If a car is used more than that, the perceived value goes down as buyers assume more wear and tear in the vehicle."
    },
    {
        key: "engine",
        heading: "Engine + Suspension",
        content: "second",
        type: "table",
        displayPara: "In absence of Insurance cover, the value of vehicle is impacted depending on the original value."
    },
    {
        key: "exterior",
        heading: "Exterior + Tyres",
        content: "sdf",
        type: "table",
        displayPara: "In absence of Insurance cover, the value of vehicle is impacted depending on the original value."
    }

    //interior
];

const DepReport = ({
    depreciation,
    fetchDepreciationReportsConnect,
    trackEvent = false,
    openTime = "",
    match
}) => {
    const { params: { appointmentId } } = match;
    const [data, setData] = useState(null);
    const [carDetail, setCarDetail] = useState("");
    const [depDetail, setDepDetail] = useState("");
    const [avgPrice, setAvgPrice] = useState([]);
    const [finalPrice, setFinalPrice] = useState([]);
    const [metricData, setMetricData] = useState(null);
    const [carTableData, setCarTableData] = useState(null);
    const [webQuote, setWebQuote] = useState(0);
    const [expectedQuote, setExpectedQuote] = useState(0);

    const carId = GetQueryString("carId");
    const fromReport = GetQueryString("report");

    const isVisible = usePageVisibility();

    // Change the title based on page visibility
    if (isVisible) {
        document.title = "LMS Galactica - Active";
    } else {
        document.title = "LMS Galactica - Inactive";
    }

    // //Custom timers which are affected by the page visibility
    // const timerVal = useCurrentVisibilityTimer(isVisible);
    const totalTimeVal = useTotalVisibilityTimer(isVisible);

    const handleBeforeUnload = (event) => {
        let totaltime = 1;
        if (!!document.getElementById("hdntotalTimeVal") && !!document.getElementById("hdntotalTimeVal").value) {
            totaltime = document.getElementById("hdntotalTimeVal").value;
        }
        let _webQuote = 0;
        if (!!document.getElementById("hdnwebQuote") && !!document.getElementById("hdnwebQuote").value) {
            _webQuote = document.getElementById("hdnwebQuote").value;
        }
        let _expectedQuote;
        if (!!document.getElementById("hdnexpectedQuote") && !!document.getElementById("hdnexpectedQuote").value) {
            _expectedQuote = document.getElementById("hdnwebQuote").value;
        }
        let _eventCat = getEventCategory(fromReport);
        trackLMSEvents("'ls_negotiationPageClose'", {
            event: 'ls_negotiationPageClose',
            eventCategory: _eventCat,
            eventAction: 'Close Negotiation Report',
            eventLabel: appointmentId,
            lsViewTime: totaltime,
            lsRAID: getUid(),
            webQuote: _webQuote,
            expectedQuote: _expectedQuote
        });
        event.preventDefault();
    };
    const trackReportEvent = (_webQuote, _expectedQuote) => {
        let reportLabelForEvent = "Negotiation Report";
        let category = "ls_viewReportLinkClick";
        if (!!fromReport && fromReport.toLowerCase() === "In-negotiation") {
            reportLabelForEvent = "Negotiation Report";
            category = "ls_viewReportLinkClick";
        }
        else if (!!fromReport && fromReport.toLowerCase() === "inspected") {
            reportLabelForEvent = "Inspected Report";
            category = "ls_ViewReport_InspectedTab";
        }
        else if (!!fromReport && fromReport.toLowerCase() === "inspected-state") {
            reportLabelForEvent = "Inspected Report";
            category = "ls_ViewReport_InspectedStateTab";
        }
        else if (!!fromReport && fromReport.toLowerCase() === "depriciation") {
            reportLabelForEvent = "Depriciation Report";
            category = "ls_ViewReport_Depriciation";
        }
        else if (!!fromReport && fromReport.toLowerCase() === "ctaclick") {
            reportLabelForEvent = "Negotiation Report";
            category = "ls_depReportCTA";
        }
        trackLMSEvents(category, {
            eventLabel: appointmentId,
            lsRAID: getUid(),
            webQuote: _webQuote,
            expectedQuote: _expectedQuote,
        });
    };
    useEffect(() => {
        fetchDepreciationReportsConnect(appointmentId).then((resp) => {
            // resp = getDepriciationReportMoq();
            if (resp.status === 200) {
                const { data: { detail: { data } } } = resp;
                if (!!data) {
                    const { make, model, variant, fuel_type, year, body_type, vg_range_min,
                        vg_range_max, final_price_range_min, final_price_range_max } = data;
                    setCarDetail(`${make} ${model} ${variant}`);
                    setDepDetail(`${fuel_type} ${body_type} ${year}`);

                    setAvgPrice([vg_range_min, vg_range_max]);
                    setFinalPrice([final_price_range_min, final_price_range_max]);
                }

                const { data: { detail } } = resp;
                setData(detail);
                let _webQuote = getDepreciationTooltip_New(DEPRECIATION_CONSTANTS_NEW.veryGoodMax, data).gtmData;
                if (!!_webQuote) {
                    setWebQuote(_webQuote);
                }
                let _expectedQuote = getDepreciationTooltip_New(DEPRECIATION_CONSTANTS_NEW.final, data).gtmData;
                if (!!_expectedQuote) {
                    setExpectedQuote(_expectedQuote);
                }
                if (!!_webQuote && !!_expectedQuote) {
                    trackReportEvent(_webQuote, _expectedQuote);
                }
                window.addEventListener("beforeunload", handleBeforeUnload);
            }
            // alert("Fetch Data");
        }).catch(error => {
            console.log(error);
            //setMake(getMake().detail);

        });
        try {
            AppointmentService.getCarData(appointmentId)
                .then(resp => {
                    const { data: { detail: { data } } } = resp;
                    const carData = data[appointmentId];
                    const specificationData = specificationsParser(carData);
                    const content = [
                        { type: "highlights", heading: "Highlights", ratings: "5", description: "Highlights", data: { ...specificationData.highlights } },
                        { type: "documentDetail", heading: "Document Details", ratings: "5", description: "Highlights", data: { ...specificationData.documentDetail } },
                        { type: "exteriorTyres", heading: "Exterior + Tyres", ratings: "5", description: "Highlights", data: { ...specificationData.exteriorTyres } },
                        { type: "engineTransmission", heading: "Engine + Transmission", ratings: "5", description: "Highlights", data: { ...specificationData.engineTransmission } },
                        { type: "airConditioning", heading: "Air Conditioning", ratings: "5", description: "Highlights", data: { ...data.airConditioning } },
                        { type: "electricalsInterior", heading: "Electricals + Interiors", ratings: "5", description: "Highlights", data: { ...specificationData.electricalsInterior } },
                        { type: "steeringSuspensionBrakes", heading: "Steering + Suspension + Brakes", ratings: "5", description: "Highlights", data: { ...specificationData.steeringSuspensionBrakes } },
                    ];
                    setMetricData(content);
                    setCarTableData(carData);
                }).catch(err => {
                    if (!!err && !!err.detail) {
                        const { detail: { message } } = err;
                        showToastMessages(message, false);
                    }
                    else {
                        showToastMessages("Error in fetching data", false);
                    }
                });
        }
        catch (exception) {
            showToastMessages("Error in fetching data", false);
        }
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const getEngineAndExteriorTableData = (key) => {
        try {
            const showEngineSuspension = ["Engine Oil Quality", "Engine Sound", "Steering", "Suspension", "Brake", "Engine", "Engine Oil", "Gear Shifting", "Clutch"];
            const showExteriorTyre = ["Pillar", "Apron", "Roof", "Firewall", "Quarter Panel", "Tyre"];

            let finalShowFields = [];
            if (key === "engine") {
                const enngineData = metricData.find((section) => section.type === 'engineTransmission');
                enngineData.data.content.map(item => {
                    if (showEngineSuspension.find(field => field === item.title)) {
                        if (item.subParts && item.subParts.length > 0) {
                            finalShowFields = [...finalShowFields, ...item.subParts];
                        } else {
                            finalShowFields.push(item);
                        }
                    }
                });
                const suspensionBrake = metricData.find((section) => section.type === 'steeringSuspensionBrakes');
                suspensionBrake.data.content.map(item => {
                    if (showEngineSuspension.find(field => field === item.title)) {
                        if (item.subParts && item.subParts.length > 0) {
                            finalShowFields = [...finalShowFields, ...item.subParts];
                        }
                        else {
                            finalShowFields.push(item);
                        }
                    }
                });
            }
            if (key === "exterior") {
                const exteriorData = metricData.find((section) => section.type === 'exteriorTyres');
                exteriorData.data.content.map(item => {
                    if (showExteriorTyre.find(field => field === item.title)) {
                        if (item.subParts && item.subParts.length > 0) {
                            finalShowFields = [...finalShowFields, ...item.subParts];
                        }
                        else {
                            finalShowFields.push(item);
                        }
                    }
                });
                exteriorData.data.groupedContent.map(item => {
                    item.content.map(subItem => {
                        if (showExteriorTyre.find(field => field === subItem.title)) {
                            if (subItem.subParts && subItem.subParts.length > 0) {
                                finalShowFields = [...finalShowFields, ...subItem.subParts];
                            }
                            else {
                                finalShowFields.push(subItem);
                            }
                        }
                    });
                });
            }
            return finalShowFields;
        }
        catch (exception) {
            showToastMessages("Error in fetching data", false);
        }
    };
    const getReportDataBody = (key, type, para) => {
        try {
            if (type === "table") {
                let finalShowFields = getEngineAndExteriorTableData(key);
                return (
                  <table cellPadding="0" cellSpacing="0" width="100%" className={styles.dataTable}>
                    {finalShowFields.map(item => {
                            const { additionalInfo } = item;
                            let displayCondition = "";
                            if (!!additionalInfo && additionalInfo.length > 0) {
                                if (!!additionalInfo[0].treadDepth) {
                                    displayCondition = additionalInfo[0].treadDepth.value;
                                }
                                if (!!additionalInfo[0].currentCondition) {
                                    let currentConditionVal = additionalInfo[0].currentCondition.value;
                                    if (!!additionalInfo[0].currentCondition.value && additionalInfo[0].currentCondition.value.length > 0) {
                                        currentConditionVal = additionalInfo[0].currentCondition.value.join(",");
                                    }
                                    displayCondition = !!displayCondition ? displayCondition + ", " + currentConditionVal : currentConditionVal;
                                }
                                if (!!additionalInfo[0].workDone) {
                                    let workDoneVal = additionalInfo[0].workDone.value;
                                    if (!!additionalInfo[0].workDone.value && additionalInfo[0].workDone.value.length > 0) {
                                        workDoneVal = additionalInfo[0].workDone.value.join(",");
                                    }
                                    displayCondition = !!displayCondition ? displayCondition + ", " + workDoneVal : workDoneVal;
                                }
                            }
                            return (<tr>
                              <td width="30%">{item.title}</td>
                              <td width="70%"><span className={item.value === "Yes" ? `${styles.greenSign}` : `${styles.crossSign}`}>{displayCondition}</span></td>
                              {/* <td>{displayCondition}</td> */}
                            </tr>
                            );
                        })}
                  </table>
                );
            }
            else if (type === "default") {
                return (
                  <div>{para}</div>
                );
            }
            else {
                return (
                  <></>
                );
            }
        } catch (exception) {
            showToastMessages("Error in fetching data", false);
            return (
              <></>
            );
        }
    };
    const getRatingColor = (key, dep) => {
        let ratingClass = "";
        dep = parseInt(dep);
        let rating = 0;
        switch (key) {
            case DEPRECIATION_CONSTANTS_NEW.odometer:
                if (dep > 0) { ratingClass = "yellowSign"; }
                else { ratingClass = "greenSign"; }
                break;
            case DEPRECIATION_CONSTANTS_NEW.owner:
                if (dep > 0) { ratingClass = "yellowSign"; }
                else { ratingClass = "greenSign"; }
                break;
            case DEPRECIATION_CONSTANTS_NEW.insurance:
                if (dep > 0) { ratingClass = "yellowSign"; }
                else { ratingClass = "greenSign"; }
                break;
            case DEPRECIATION_CONSTANTS_NEW.exterior:
                const exteriorData = metricData.find((section) => section.type === 'exteriorTyres');
                rating = 0;
                if (!!exteriorData && !!exteriorData.data && !!exteriorData.data.rating) {
                    rating = exteriorData.data.rating;
                }
                // rating = dep;
                if (rating <= 2) { ratingClass = "redRating"; }
                else if (rating > 2 && rating <= 4) { ratingClass = "yellowRating"; }
                else { ratingClass = "greenRating"; }
                break;
            case DEPRECIATION_CONSTANTS_NEW.engine:
                const enngineData = metricData.find((section) => section.type === 'engineTransmission');
                rating = 0;
                if (!!enngineData && !!enngineData.data && !!enngineData.ratings) {
                    rating = enngineData.data.rating;
                }
                // rating = dep;
                if (rating <= 2) { ratingClass = "redRating"; }
                else if (rating > 2 && rating <= 4) { ratingClass = "yellowRating"; }
                else { ratingClass = "greenRating"; }
                break;
            default:
                ratingClass = "greenRating";
                break;
        }
        return ratingClass;

    };
    const viewReport = () => {
        if (!!carId) {
            trackLMSEvents("viewReportButtonFromLeadDetail", {
                eventLabel: appointmentId,
                eventAction: "View Inspection Report",
                eventCategory: 'Depriciation Report',
                lsRAID: getUid()
            });
            fetchInspectionReport(appointmentId);
        }
        else {
            showToastMessages("Error in fetching Inspection Report", false);
        }
    };
    return (
      <>
        <input id="hdntotalTimeVal" type="hidden" value={totalTimeVal}></input>
        <input id="hdnwebQuote" type="hidden" value={webQuote}></input>
        <input id="hdnexpectedQuote" type="hidden" value={expectedQuote}></input>
        <div className={styles.depReportWrapper}>
          <div className={styles.carNameSection}>
            <div>
              <h1>PRICE ESTIMATION FOR YOUR CAR</h1>
              <p>{carDetail}</p>
            </div>
            <img src={DepLogo} alt={DepLogo} />
          </div>
          <div className={styles.graphSection}>
            <div className={styles.graphContent}>
              <h2>STANDARD DEPRECIATION: {depDetail}</h2>
              <p className={styles.text}>This curve shows the current value of the car as % of on road price. The depreciation shown is not make model specific but rather age wise depreciation for a particular car category.</p>
              {avgPrice.length > 0 && <p className={styles.value}>Standard Depreciated Value: ₹{avgPrice[0]} - ₹{avgPrice[1]} </p>}
            </div>
            <div className={styles.graph}>
              {!!data && <ExponentialGraph graphData={data} />}
            </div>
          </div>
          {/* {!(Object.keys(data).length === 0 && data.constructor === Object) && <ExponentialGraph graphData={data} />} */}
          <div className={styles.inspectionReport}>
            <h2>POST INSPECTION ANALYSIS</h2>
            <Button ctaText={"VIEW PDF"} onClick={viewReport} classNames={"viewPdf"} />
            {/* <Link to="#" onClick={viewReport}>View report</Link> */}
          </div>
          <div className={styles.staticHeading}>
            <span>Category</span>
            <span>Status</span>
            <span>% Change</span>
            <span className={styles.alignRight}>Amount</span>
          </div>
          <div className={styles.valueWrapper}>
            <span>Standard Depreciated Value</span>
            <span>₹{avgPrice[0]} - ₹{avgPrice[1]}</span>
          </div>
          {!!data && !!carTableData && !!metricData &&
            <div className={styles.dataList}>
              <Accordion totalItems={SAMPLE_ITEMS.length}>
                {({
                                onClick,
                                currentVisibleStates
                            }) => {
                                // console.log(currentVisibleStates, "123");
                                return SAMPLE_ITEMS.map((item, index) => {
                                    const values = getDepreciationTooltip_New(item.heading, data.data);
                                    let { keyData, rating, depr, range, text } = values;
                                    if (item.key === "exterior") {
                                        const exteriorData = metricData.find((section) => section.type === 'exteriorTyres');
                                        if (!!exteriorData && !!exteriorData.data && !!exteriorData.data.rating) {
                                            rating = exteriorData.data.rating;
                                        }
                                    }
                                    if (item.key === "engine") {
                                        const enngineData = metricData.find((section) => section.type === 'engineTransmission'); if (!!enngineData && !!enngineData.data && !!enngineData.ratings) {
                                            rating = enngineData.data.rating;
                                        }
                                    }
                                    if (values) {
                                        return (
                                          <Accordion.Content key={index}>
                                            <div className={currentVisibleStates[index] ? styles.dataContentShow : styles.dataContentList}>
                                              <Accordion.Heading>
                                                <div className={styles.listHeading} onClick={() => {

                                                            onClick({ index });
                                                        }}>
                                                  <span>{text}</span>
                                                  {!!rating && <span className={`${styles[getRatingColor(item.heading, depr)]}`}>{rating ? rating : ""}</span>}
                                                  {!!keyData && <span className={`${styles[getRatingColor(item.heading, depr)]}`}>{keyData ? keyData.value : ""}</span>}
                                                  {!!depr && <span>{depr && depr === "0" ? `${depr}%` : `-${depr}%`}</span>}
                                                  <span className={styles.alignRight}>{range === "0" ? `-` : `-${range}`}</span>
                                                </div>
                                              </Accordion.Heading>
                                              <Accordion.Body>
                                                <div className={currentVisibleStates[index] ? styles.dataContent : styles.dataContent}>
                                                  {
                                                                getReportDataBody(item.key, item.type, item.displayPara)
                                                            }
                                                </div>
                                              </Accordion.Body>
                                            </div>
                                          </Accordion.Content>
                                        );
                                    }
                                });

                            }}
              </Accordion>
            </div>
                }
        </div>
        <div className={styles.carPrice}>
          <h2>Possible Price for your Car</h2>
          {finalPrice.length > 0 && <h2>₹{finalPrice[0]} - ₹{finalPrice[1]} </h2>}
        </div>
        <div className={styles.footerText}>
          <div className={styles.asterisk}>
            <img src={Asterisk} alt="asterisk" />
            Other factors also affect the price value of the car
          </div>
          <div>
            <ul>
              <li>Absence of duplicate keys</li>
              <li>Facelifts/ discontinuation of variant</li>
              <li>Interstate vehicles/ Logistics cost</li>
            </ul>
          </div>
          <div>
            <ul>
              <li>Absence of service history</li>
              <li>Colors other than white/ silver</li>
              <li>Ongoing car discounts</li>
            </ul>
          </div>
        </div>
      </>
    );
};
export default DepReport;