import React, { useState, useEffect } from "react";
import H2 from "../h2/component";
import styles from "./listing-car-popup.module.scss";
import PaiSlider from "../pai-slider";
import Button from "../../components/shared/button";
import IncrementalTextbox from "../incremental-textbox/component";
import priceFormatter from "../../utils/helpers/price-formatter";
import { AppointmentService } from "../../service";
import { showToastMessages, getFromLocalStorage } from "../../utils/helper";
import { LOCAL_STORAGE_KEYS } from "../../utils/app-constants";
// const { customerName, make, model, year } = currentAppointmentData;

const ListingCarPopup = ({
  negotiations,
  close,
  resetListing,
  paiRequestConnect,
  setCurrentPopupConnect
}) => {
  const { data, currentAppointmentIndex } = negotiations;
  const currentAppointmentData = data[currentAppointmentIndex] || {};
  const { customerName, make, model, year, currentHB, bestHB, preCheckData, carRegion, appointmentId, c2cListingPrice, c24Quote } = currentAppointmentData;
  // const { customerName, make, model, year, currentHB, bestHB, carRegion } = currentAppointmentData;
  const [inputText, setInputText] = useState("");
  const [isLoading, setisLoading] = useState(false);

  const [showSlider, setShowSlider] = useState(false);
  const [closurePrice, setClosurePrice] = useState(0);// useState(currentHB);
  const [values, setValues] = useState([0]);//useState([1000]);
  const [update, setUpdate] = useState([0]);//useState([1000]);
  const [domain, setDomain] = useState([]);
  const step = 1000;
  // const domain = [0, 5000];
  const sliderData = null;
  const priceAcceptance = [];// [{ "key": 1000, "value": "100", "band": "H" }];

  useEffect(() => {
    if (appointmentId > 0) {
      // showToastMessages("hi");
      const payload = {
        "appointmentId": appointmentId,
        "c24Quote": c24Quote
      };
      AppointmentService.getC2CBoundyValues(payload)
        .then(response => {
          const { data: { detail: { minPrice, maxPrice } } } = response;
          if (minPrice > 0 && maxPrice > 0) {
            setDomain([minPrice, maxPrice]);
          }
        })
        .catch(error => {
          console.log('Error getting c2cboundryValues', error);
        });

    }
    // setClosurePrice(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeClick = (type) => {
    const newPrice = type === "increment" ? closurePrice + step : closurePrice - step;
    setClosurePrice(newPrice);
    setValues([newPrice]);
  };
  const onUpdateSlider = update => {
    const currentValue = update[0];
    setUpdate(update);
    setClosurePrice(currentValue);
  };

  const onChangeSlider = values => {
    setValues(values);
  };
  const onChangeText = (e) => {
    const value = e.target.value && e.target.value.replace(/,/g, '') ? priceFormatter(Number(e.target.value.replace(/,/g, ''))) : "";
    const price = Number(value.replace(/,/g, ''));
    setClosurePrice(price);
    setInputText(value);
  };
  const ListCar = () => {
    // setIsSubmitted(true);
    setisLoading(true);
    const localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
    const loggedInEmail = localData ? JSON.parse(localData)[LOCAL_STORAGE_KEYS.EMAIL] : "";
    const actualRole = localData ? JSON.parse(localData)[LOCAL_STORAGE_KEYS.ORIGIN_ROLE] : "";
    const cPrice = closurePrice;//Number(closurePrice.replace(/,/g, ''));
    const {
      appointmentId,
      carId,
      storeId
    } = currentAppointmentData;
    const payload = {
      "appointmentId": appointmentId,
      "listingPrice": cPrice,
      "userEmail": loggedInEmail,
      "userActualRole": c2cListingPrice > 0 ? "" : actualRole,
      "prevListingPrice": c2cListingPrice
    };
    AppointmentService.c2cList(payload)
      .then((resp) => {
        const { data: { title } } = resp;
        showToastMessages(resp && resp.data ? title : "Listing Price updated successfully");
        setTimeout(() => {
          setCurrentPopupConnect(null, null);
          close();
          resetListing();
        }, 2000);

      })
      .catch((resp) => {
        showToastMessages(resp.title, false);
      });
  };
  return (
    <div className={styles.listingCarPopup}>
      <H2 text={c2cListingPrice > 0 ? "revise listing price" : "enter listing price"} />
      <ul className={styles.customerDesc}>
        {/* <li>{customerName}</li>
                <li>{`${make} ${model}`}</li>*/}
        <li>{year}</li>
        <li>{customerName}</li>
        <li>{make}</li>
        <li>{model}</li>
      </ul>
      <div className={styles.sliderWrapper}>
        <p className={styles.clousrePrice}>Listing Price</p>
        <IncrementalTextbox
          value={priceFormatter(closurePrice)}
          onChangeClick={onChangeClick}
        />
      </div>

      {!!domain && domain.length > 0 &&
      < PaiSlider
        domain={domain}
        onChange={onChangeSlider}
        onUpdate={onUpdateSlider}
        values={values}
        update={update}
        step={step}
        sliderData={sliderData}
        prices={priceAcceptance}
      />
      }
      <div className={styles.ctaButton}>
        <Button
          classNames="blueButton"
          ctaText={"SUBMIT"}
          onClick={ListCar}
        />
        <Button
          classNames="cancelCta"
          ctaText="Cancel"
          onClick={close}
        />
      </div>
    </div>
  );
};

export default ListingCarPopup;
