// {
//     'event':'ls_negotiationRaisePAI',
//     'eventCategory':'Negotiation Report',
//     'eventAction':'Raise PAI', // this will be report name
//     'eventLabel':'1027595618', //App ID
//     'lsRAID':'1234' ,
//     'lsTime':180 // this must be in second
// }

export default {
  viewReportFromInspected: {
    event: "ls_ViewInspectedReport",
    eventCategory: "Inspected Report",
    eventAction: "View Report Click",
  },

  viewReportButtonFromInspected: {
    event: 'ls_InspectionReport',
    eventCategory: 'Inspected Report',
  },

  closeReportFromInspected: {
    event: "ls_ReportClose",
    eventCategory: "Depreciation Report",
    eventAction: "Close Inspection Report",
  },

  ls_viewApplicationDetail: {
    event: "ls_viewApplicationDetail",
    eventCategory: "Negotiation Report",
    eventAction: "View Application Report",
  },
  viewReportFromLeadDetail: {
    event: 'ls_viewReport',
    eventCategory: 'Negotiation Report',
    eventAction: 'View Report',
  },

  viewReportButtonFromLeadDetail: {
    event: 'ls_negotiationInspectionReport',
    // eventCategory: 'Negotiation Report',
  },

  closeReportFromLeadDetail: {
    event: 'ls_negotiationReportClose',
    eventCategory: 'Negotiation Report',
    eventAction: 'Close Depreciation Report',
  },
  clickUnlockOtp: {
    event: 'ls_negotiationUnlockOTP',
    eventCategory: 'Negotiation Report',
    eventAction: 'Unlock via otp'
  },
  clickGetQuote: {
    event: 'ls_negotiationGetQuote',
    eventCategory: 'Negotiation Report'
  },
  onClickRaisePAI: {
    event: 'ls_negotiationRaisePAI',
    eventCategory: 'Negotiation Report',
    eventAction: 'Raise PAI'
  },
  similarCarLink: {
    event: 'ls_negotiationCTAClick',
    eventCategory: 'Negotiation Report',
    eventAction: 'Similar Car',
  },
  generateCustomerPage: {
    event: 'ls_generateCustomerPageSubmit',
    eventCategory: 'Negotiation Report',
    eventAction: 'Final Quote',
  },
  similarTransactedCarInputOutputPage: {
    event: 'ls_generateCustomerPageArrow',
    eventCategory: 'Negotiation Report',
  },
  ls_negotiationPageClose: {
    event: 'ls_negotiationPageClose',
    eventCategory: 'Negotiation Report',
    eventAction: 'Close Negotiation Report',
  },
  ls_ViewReport_InspectedTab: {
    event: 'ls_ViewReport_InspectedTab',
    eventCategory: 'Inspection Report',//'Inspected Report',
    eventAction: 'View Report',//'View Depreciation Report Click',
  },
  ls_ViewReport_InspectedStateTab: {
    event: 'ls_ViewReport_InspectedTab',
    eventCategory: 'Inspection Report',//'Inspected Report',
    eventAction: 'View Depreciation Report Click',
  },
  ls_viewReportLinkClick: {
    event: 'ls_viewReportLinkClick',
    eventCategory: 'Negotiation Report',
    eventAction: 'View Report',
  },
  ls_ViewReport_Depriciation: {
    event: 'ls_ViewReport_InspectedTab',
    eventCategory: 'Inspected Report',
    eventAction: 'View Depreciation Report Click',
  },
  ls_dateRangeSelection: {
    event: 'ls_dateRangeSelection',
    eventCategory: 'Date Selection',
    eventAction: 'Apply',
  },
  ls_getDealerDetail: {
    event: "ls_getDealerDetail",
    eventCategory: "Dealer Detail",
    eventAction: "Get Dealer Details",
  },
  ls_callDealer: {
    event: "ls_callDealer",
    eventCategory: "Dealer Detail",
    eventAction: "Call Dealer'",
  },
  ls_depReportCTA: {
    event: 'ls_depReportCTA',
    eventCategory: 'Negotiation Report',
    eventAction: 'Dep Report CTA Click',
  },
  ls_GetMorePrice: {
    event: 'ls_GetMorePrice',
    eventCategory: 'Negotiation',
    eventAction: 'GetMorePrice',
  },
  ls_RaisePAI_ModifyLP: {
    event: 'ls_RaisePAI_ModifyLP',
    eventCategory: 'Negotiation',
    eventAction: 'RaisePAIModlifyLP',
  },
  ls_ListCar: {
    event: 'ls_ListCar',
    eventCategory: 'Negotiation',
    eventAction: 'ListCar',
  },
  ls_ReviseListCar: {
    event: 'ls_ReviseListCar',
    eventCategory: 'Negotiation',
    eventAction: 'ReviseListCar',
  },
  ls_GetQuote: {
    event: 'ls_GetQuote',
    eventCategory: 'Negotiation',
    eventAction: 'GetQuote',
  },
  ls_OfferPriceEdit: {
    event: 'ls_OfferPriceEdit',
    eventCategory: 'Negotiation',
    eventAction: 'Final Quote',
  },
  ls_ViewDepDetails: {
    event: 'ls_ViewDepDetails',

  },
  ls_ViewOfferDetails: {
    event: 'ls_ViewOfferDetails',
  },
  ls_inspectedViewDepClicked: {
    event: 'ls_inspectedViewDepClicked',
    eventCategory: 'Inspected',
    eventAction: 'ViewDepReport',
  },
  ls_SimilarCarCount: {
    event: 'ls_SimilarCarCount',
    eventCategory: 'Negotiation',
    eventAction: 'SimilarCarCount',
  },
  ls_CarGuruAvaialble: {
    event: 'ls_CarGuruAvaialble',
    eventCategory: 'Negotiation',
    eventAction: 'CarGuruAvaialble',
  },
  url_shown_on_lms: {
    event: "url_shown_on_lms",
    eventCategory: "pre_inspection_form", 
  },
  url_clicked_on_lms: {
    event: "url_clicked_on_lms",
    eventCategory: "pre_inspection_form", 
  },
  checkin_form_submitted: {
    event: "checkin_form_submitted",
    eventCategory: "pre_inspection_form", 
  },
  domo_missed_call_dashboard_viewed: {
    event: "DOMO CX Missed Call Dashboard Viewed",
    eventCategory: "LMS DOMO Dashboard"
  },
  my_incentive_clicked:{
    "event":"lms_myincentive_clicked",
    "eventCategory":"lms_myincentive",
    "eventAction":"lms_myincentive_clicked"
  }
};

// dataLayer.push({
//     'event': 'ls_generateCustomerPageArrow',
//     'eventCategory': 'Negotiation Report',
//     'eventAction': 'Left Arrow',
//     'eventLabel': '1027595618',
//     'lsRAID': '1234'
//   })