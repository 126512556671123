import { createTypes } from "reduxsauce";

export default createTypes(
  `
    FETCH_CHECK_IN_LEADS_SUCCESS
    FETCH_CHECK_IN_LEADS_FAILURE

    RESET_CHECK_IN_LEADS
    RESET_CHECK_IN_LEADS_BY_KEY
`,
  {
    prefix: "leadcheckin/"
  }
);
