import Types from "./types";
import { AppointmentService } from "../../service";

const fetchCheckInLeads = (page, query) => dispatch => {
  return AppointmentService.getCheckInLeads(page, query)
    .then(response => {
      const { data: { detail } } = response;
      dispatch(fetchCheckInLeadsSuccess(detail));
      return response;
    })
    .catch(error => dispatch(fetchCheckInLeadsFailure(error)));
};

const fetchCheckInLeadsSuccess = (data) => ({
  type: Types.FETCH_CHECK_IN_LEADS_SUCCESS,
  data
});

const fetchCheckInLeadsFailure = (error) => ({
  type: Types.FETCH_CHECK_IN_LEADS_FAILURE,
  error
});

const resetCheckInLeads = () => ({
  type: Types.RESET_CHECK_IN_LEADS
});

const resetCheckInLeadsByKey = (key, value) => ({
  type: Types.RESET_CHECK_IN_LEADS_BY_KEY,
  data: { key, value }
});

export {
  fetchCheckInLeads,
  resetCheckInLeads,
  resetCheckInLeadsByKey
};
