import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EvaluatorView from "./component";
import withLayout from "../../components/layout/component";
import {fetchEvaluatorTokens, fetchEvaluatorView, fetchRetailView,resetView} from "./actions";

const mapStateToProps = ({
  evaluator
}) => ({
  evaluator
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchEvaluatorTokensConnect: fetchEvaluatorTokens,
  fetchRetailViewConnect:fetchRetailView,
  fetchEvaluatorViewConnect:fetchEvaluatorView,
  resetViewConnect:resetView
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withLayout(EvaluatorView, false, false,false,false));
