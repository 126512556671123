import React, { Fragment } from "react";
import PropTypes from "prop-types";
import "./slider-rail.css";
const SliderRail = ({ getRailProps, railColor }) => {
  const customStyles = {
    backgroundColor: railColor
  };
  return (
    <Fragment>
      <div className="railOuter" {...getRailProps()} />
      <div className="railInner" style={customStyles} />
    </Fragment>
  );
};

SliderRail.propTypes = {
  getRailProps: PropTypes.func.isRequired,
  railColor: PropTypes.string
};

export default SliderRail;
