import React from "react";
import styles from "./store-change-confirmation-popup.module.scss";
import Button from "../../shared/button/component";
import { POPUP_BUTTONS } from "../../../utils/app-constants";
const StoreChangeConfirmationPopup = ({onSubmit, onCancelClick, appointmentId, storeName}) => {
    
  return (
    <div className={styles.storeChange}>
      <p className={styles.staticText}>Store change</p>
      <>
        <p className={styles.infoText}>{`Are you sure you want to change the assigned store for Appointment ID - ${appointmentId} to ${storeName}`}</p>
        <div className={styles.ctaButton}>
          <Button classNames="saveCta" ctaText={POPUP_BUTTONS.YES} onClick={onSubmit} />
          <Button classNames="cancelCta" ctaText={POPUP_BUTTONS.NO} onClick={onCancelClick} />
        </div>
      </>
    </div>
  );
};
export default StoreChangeConfirmationPopup;