import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  query: null,
  isRetail: 0,
};

export const updatePllAllocationFilter = (state = INITIAL_STATE, {query}) => {
	return { ...state, query: query?.qs, isRetail: query?.isRetail};
};

export const HANDLERS = {
  [Types.PLL_FILTER_QUERY]: updatePllAllocationFilter

};

export default createReducer(INITIAL_STATE, HANDLERS);
