import React, {useEffect} from "react";
import ReactModal from 'react-modal';
import styles from "./popup.module.css";
import Close from "../images/close.svg";
import H2 from "../h2/component";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

const Popup = (props) => {
  const { children,
    isOpen,
    close,
    heading = "",
    contentStyles = {},
    closeOnOutside = true,
    showCloseButton = true,
    shouldCloseOnEsc = true,
    ResetApiNoAccessConnect,
  } = props;
  useEffect(()=> {
    return () => {
      !!ResetApiNoAccessConnect && ResetApiNoAccessConnect();
    };
  }, []);
  return (
    <div>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={close}
        style={{ ...customStyles, content: { ...customStyles.content, ...contentStyles } }}
        contentLabel="Modal"
        appElement={document.getElementById('root')}
        shouldCloseOnEsc={shouldCloseOnEsc}
        shouldCloseOnOverlayClick={closeOnOutside}
      >
        {showCloseButton &&
        <div className={styles.popupWrapper}>
          <button className={styles.closeModal} onClick={close}><img src={Close} alt="close" /></button>
          {heading && <H2 text={heading}></H2>}
        </div>
        }
        {children}
      </ReactModal>
    </div>
  );
};

export default Popup;

