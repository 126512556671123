import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import StoresMenu from "./component";
import { resetLeadMenuByKey } from "../lead-menu/actions";
import { fetchAppointmentCounts } from "../sidebar/actions";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  resetLeadMenuByKeyConnect: resetLeadMenuByKey,
  fetchAppointmentCountsConnect: fetchAppointmentCounts
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StoresMenu);
