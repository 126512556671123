import { createTypes } from "reduxsauce";

export default createTypes(
  `
    FETCH_DEPRECIATION_REPORTS
    FETCH_DEPRECIATION_SUCCESS
    FETCH_DEPRECIATION_FAILURE
    RESET_DEPRECIATION
`,
  {
    prefix: "depreciationreports/"
  }
);
