import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchLostLeads } from "./actions";
import LostLeads from "./component";
import { resetLostLeadsByKey, resetLostLeads } from "./actions";
import withLayout from "../../components/layout/component";
import { resetLeadMenuByKey } from "../../components/lead-menu/actions";
import { resetAppointmentByKey } from "../../pages/appointment/actions";
import { resetGlobalSearch } from "../../components/global-search/actions";
import {resetApiNoAccess} from "../../components/hoc-access-controller/actions";
const mapStateToProps = ({
  lostLeads,
  leadMenu,
  search,
  globalSearch
}) => ({
  lostLeads,
  leadMenu,
  search,
  globalSearch
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchLostLeadsConnect: fetchLostLeads,
  resetLostLeadsByKeyConnect: resetLostLeadsByKey,
  resetLeadMenuByKeyConnect: resetLeadMenuByKey,
  resetLostLeadsConnect: resetLostLeads,
  resetAppointmentByKeyConnect: resetAppointmentByKey,
  resetGlobalSearchConnect: resetGlobalSearch,
  resetApiNoAccessConnect: resetApiNoAccess
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withLayout(LostLeads, false));
