import Types from "./types";
import { AppointmentService } from "../../service";
import { getCustomerPaymentChargesNewMoc } from "../../utils/mocApi";

const fetchChargesHoldback = (appointmentId) => dispatch => {
  // const response = getCustomerPaymentChargesNewMoc();
  // dispatch(fetchChargesHoldbackSuccess(response));
  return new Promise((resolve, reject) => {
    return AppointmentService.getCustomerCharges(appointmentId)
      .then(({ data }) => {
        const { detail } = data;
        dispatch(fetchChargesHoldbackSuccess(detail));
        resolve(detail);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const fetchChargesHoldbackSuccess = (data) => ({
  type: Types.FETCH_CHARGES_HOLDBACK,
  data
});

export {
  fetchChargesHoldback
};