import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  customerOffer: {}
};

export const fetchCustomerOfferSuccess = (state = INITIAL_STATE, { data }) => {
  return { ...state, customerOffer: data };
};

export const fetchCustomerOfferFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, customerOffer: {} };
};
export const HANDLERS = {
  [Types.FETCH_CUSTOMER_OFFER_SUCCESS]: fetchCustomerOfferSuccess,
  [Types.FETCH_CUSTOMER_OFFER_FAILURE]: fetchCustomerOfferFailure

};

export default createReducer(INITIAL_STATE, HANDLERS);
