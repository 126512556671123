import Types from "./types";
import { AppointmentService } from "../../service";
import { getSimilarCarMoq } from "../../utils/mocApi";

const fetchSimilarCarDetails = (appointmentId, page) => dispatch => {
  return AppointmentService.getSimilarCarsDetails(appointmentId, page)
    .then(response => {
      // response = getSimilarCarMoq();
      const { data: { detail } } = response;
      dispatch(fetchSimilarCarsSuccess(detail));
      return detail;
    })
    .catch((error) => {
      //throw error;
      // let response = getSimilarCarMoq();
      // const { detail } = response;
      // dispatch(fetchSimilarCarsSuccess(error));
      dispatch(fetchSimilarCarsFailure(error));
      // return detail;
    });
};

const fetchSimilarCarsSuccess = (data) => ({
  type: Types.FETCH_SIMILARCARS_SUCCESS,
  data
});
const fetchSimilarCarsFailure = (data) => ({
  type: Types.FETCH_SIMILARCARS_FAILURE,
  data
});

export {
  fetchSimilarCarDetails
};
