import React from "react";
import {APPOINTMENT_TYPES_STRING, NOT_AVAILABLE} from "../../utils/app-constants";
import styles from "./reason.module.css";
import {Link} from "react-router-dom";
import {redirectToUrl} from "../../utils/helper";
import View from "../images/view.svg";

const Reason = ({ rowDetails, lostLeads }) => {
  const { lostReason } = rowDetails;
  const { fromPage } = lostLeads;
  return (
    <div className={styles.view}>
      <span title={ lostReason }>{ lostReason ? lostReason:NOT_AVAILABLE }</span>
      {/*{*/}
      {/*    fromPage === APPOINTMENT_TYPES_STRING.PAYMENT_PROCESSING &&*/}
      {/*        <Link to="#" onClick={() => { redirectToUrl('adminUrl'); }} >*/}
      {/*            <img src={View} alt="" />*/}
      {/*        </Link>*/}
      {/*}*/}
    </div>
  );
};

export default Reason;
