import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import NegotiationList from "./component";
import { fetchNegotiations, fetchCarData, setCurrentPopup, resetNegotiations, resetNegotiationsByKey, fetchMissedCalls } from "./actions";
import { resetLeadMenuByKey } from "../lead-menu/actions";
import { resetSearchByKey } from "../search/actions";
import { resetAppointmentCountByKey } from "../sidebar/actions";
import { resetGlobalSearch } from "../global-search/actions";
import { resetLostLeadSearchByKey } from "../search-lost-leads/actions";

const mapStateToProps = ({
  negotiations,
  leadMenu,
  search,
  negotiationFilter,
  globalSearch,
  appointments,
  pllNegotiationFilter,
  negotiationRange,
  lostLeadsearch,
  sidebar,
  hocAccess : {
    apiNoAccess
  }
}) => ({
  negotiations,
  leadMenu,
  search,
  negotiationFilter,
  globalSearch,
  appointments,
  negotiationRange,
  pllNegotiationFilter,
  lostLeadsearch, //combinedReducer
  sidebar,
  apiNoAccess
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchNegotiationsConnect: fetchNegotiations,
  fetchCarDataConnect: fetchCarData,
  setCurrentPopupConnect: setCurrentPopup,
  resetNegotiationsConnect: resetNegotiations,
  resetNegotiationsByKeyConnect: resetNegotiationsByKey,
  resetLeadMenuByKeyConnect: resetLeadMenuByKey,
  resetSearchByKeyConnect: resetSearchByKey,
  resetAppointmentCountByKeyConnect: resetAppointmentCountByKey,
  resetGlobalSearchConnect: resetGlobalSearch,
  resetLostLeadSearchByKeyConnect: resetLostLeadSearchByKey,
  fetchMissedCallsConnect: fetchMissedCalls
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NegotiationList);
