/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import lostIcon from "../images/lost_icon.svg";
import styles from "./search-lost-leads.module.scss";
import { NUMBERS, RESET_REDUCER_STATE_KEYS } from "../../utils/app-constants";

const SearchLostLeads = ({ lostLeadsearch, resetLostLeadSearchByKeyConnect, appointments, history, resetSearchConnect }) => {
  const { lostleads } = lostLeadsearch;
  const { appointmentState } = appointments;
  const [lostLeadSearchQuery, setLostLeadSearchQuery] = useState();
  const [isSearchedData, setIsSearchedData] = useState(false);

  const onSearchClick = () => {
    // if (!!lostleads) {
    //   let setLead = !lostleads;
    //   setIsSearchedData(true);
    //   setLostLeadSearchQuery(true);
    //   resetLostLeadSearchByKeyConnect(RESET_REDUCER_STATE_KEYS.LOST_LEADS, setLead);
    // } else {
    //   setIsSearchedData(true);
    //   setLostLeadSearchQuery(true);
    //   resetLostLeadSearchByKeyConnect(RESET_REDUCER_STATE_KEYS.LOST_LEADS, true);
    // }
    resetSearchConnect();
    history.push("/nego-lost-lead");
  };
  const clearSearch = () => {
    setLostLeadSearchQuery();
    setIsSearchedData(false);
    // resetLostLeadSearchByKeyConnect(RESET_REDUCER_STATE_KEYS.LOST_LEADS, false);
  };
  return (
    <button className={styles.lostIcon} onClick={onSearchClick}><img src={lostIcon} height="32px" width="32px" alt="" /></button>
  );
};

export default SearchLostLeads;
