import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LeadProcurement from "./component";
import withB2BLayout from "../../../b2b/components/layout/component";
import { fetchDealerCityList } from "./actions";

const mapStateToProps = () => ({
    
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchDealerCityListConnect: fetchDealerCityList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withB2BLayout(LeadProcurement, false));
