export const TOKEN_PAYMENT_STATUS = {
	NOT_INITIATED: "NOT_INITIATED",
	DRAFTED: "DRAFTED",
	SUBMITTED: "SUBMITTED",
	DONE: "DONE",
	REJECTED: "REJECTED",
	RE_INITIATE: "RE_INITIATE",
};

export const TOKEN_PR_STATUS = {
	NOT_INITIATED_YET: 0,
	SAVE_AS_DRAFT: 1,
	TOKEN_SUBMIT: 2,
	RA_CANCEL: 3,
	PR_DECLINED: 4,
	PR_APPROVED: 5,
};

export const BIKE_PICKUP_STATUS = {
	SCHEDULED: 1,
	RE_SCHEDULED: 2,
	NONE: 0,
};

export const MAP_TYPE = {
	GOOGLE: "googlemap",
	MMI: "mapmyindia",
};
export const APP_SOURCE = {
	CAR: "car",
	BIKE: "bike",
};

export const PAGE_HEADING = {
	SCHEDULED: "SCHEDULED",
	VISITED: "VISITED",
	NEGOTIATION: "IN-NEGO",
	INSPECTED: "INSPECTED",
	LEAD_DETAILS: "Lead details",
	ACTIVITY_FEED: "Activity Feed",
	CLEARANCE_PENDING: "CLEARANCE PENDING",
	PAYMENT_PROCESSING: "PAYMENT PROCESSING",
	LOST_LEADS: "LOST LEADS",
	LOST_LEADS_NEGO: "LOST LEADS",
	IN_NEGO: "(IN NEGO)",
	SELL_ONLINE: "Sell Online",
	CJ_EXECUTION: "CJ ALLOCATION STATUS",
	DIY_NEGOTIATION: "In-nego DIY",
	CENTRAL_NEGOTIATION: "RT IN-NEGO",
	CENTRAL_DIY_NEGOTIATION: "RT In-nego DIY",
	LEAD_MON: "LEADMON",
};

export const POPUP_HEADING = {
	CHECK_IN: "CHECK-IN",
	CONFIRM_CHECK_IN: "CONFIRM CHECK-IN",
	FOLLOW_UP: "FOLLOW-UP CALL",
	FOLLOW_UP_HISTORY: "FOLLOW-UP HISTORY",
	CANCEL: "INSPECTION CANCELLATION",
};

export const POPUP_BUTTONS = {
	CHECK_IN: "SUBMIT",
	CANCEL: "CANCEL",
	SAVE: "SAVE",
	SUBMIT: "SUBMIT",
	BACK: "BACK",
	YES: "Yes",
	NO: "No",
};

export const ACTIONS_BUTTON = {
	SCHEDULED_CHECK_IN: "check-in",
	VISITED_CANCEL: "Deny",
	DOWNLOAD_INSPECTION_REPORT: "DOWNLOAD",
	VIEW_INSPECTION_REPORT: "view",
	GENERATE_NO: "GENERATE NO.",
	RESCHEDULE: "RESCHEDULE",
	ALLOCATE_CJ: "Allocate CJ",
	ALLOCATE_RA: "Allocate RA",
	REALLOCATE_RA: "ReAllocate RA",
	APPROVE: "Approve",
	REJECT: "Reject",
};

export const BIKE_ACTIONS_BUTTON = {
	RESCHEDULE: "Book HI",
};

export const BUTTONS_CTA_TEXT = {
	SIGN_IN: "Sign In",
	SAVE: "SAVE",
	SEARCH: "SEARCH",
	SEE_LEADS: "See Leads",
};

export const APPOINTMENT_TYPES_STRING = {
	SELL_ONLINE: "sell-online",
	SCHEDULED: "scheduled",
	VISITED: "visited",
	INSPECTED: "inspected",
	NEGOTIATION: "negotiation",
	PAYMENT_PROCESSING: "payment-processing",
	CLEARANCE_PENDING: "clearance-pending",
	LOST_LEAD: "lost-leads",
	LOST: "lost",
	CJ_EXECUTION: "cj-execution",
	DIY_NEGOTIATION: "diy-negotiation",
	CENTRAL_NEGOTIATION: "rt-negotiation",
	CENTRAL_DIY_NEGOTIATION: "rt-diy-negotiation",
	LEAD_MON: "leadmon",
  UNALLOCATED:"unallocated"
};

export const APPOINTMENT_TYPES_NUMBER = {
	SCHEDULED: 1,
	VISITED: 2,
	INSPECTED: 11,
	NEGOTIATION: 17,
	CLEARANCE_PENDING: 18,
	LOST: 20,
	PAYMENT_PROCESSING: 22,
	SELL_ONLINE: 30,
	DIY_NEGOTIATION: 35,
};

export const NUMBERS = {
	MINUS_SIXTY: -60,
	MINUS_ONE: -1,
	ZERO: 0,
	ONE: 1,
	TWO: 2,
	THREE: 3,
	FOUR: 4,
	FIVE: 5,
	SIX: 6,
	SEVEN: 7,
	EIGHT: 8,
	NINE: 9,
	TEN: 10,
	ELEVEN: 11,
	THIRTEEN: 13,
	FOURTEEN: 14,
	FIFTEEN: 15,
	SIXTEEN: 16,
	SEVENTEEN: 17,
	EIGHTEEN: 18,
	TWENTY: 20,
	TWENTY_TWO: 22,
	FORTY_EIGHT: 48,
	FIFTY_SEVEN: 57,
	SIXTY: 60,
	THIRTY: 30,
	THIRTYONE: 31,
	THOUSAND: 1000,
	ONE_LAKH: 100000,
	TWO_LAKHS: 200000,
	ONE_CR: 10000000,
	HUNDRED: 100,
	NINETY: 90,
};

export const EMAIl_PLACEHOLDER = {
	PRIMARY: "PRIMARY EMAIL ID",
	SECONDARY: "SECONDARY EMAIL ID",
};

export const ERROR_MESSAGE = {
	ALREADY_CHECKED_IN: "Already checked-in",
	DISPOSITION: "Please select call result",
	SUB_DISPOSITION: "Please select time",
	PRIMARY_CANCELLATION_REASON: "Please select cancellation reason",
	SECONDARY_CANCELLATION_REASON: "Please select cancellation reason",
	EMAIL: "Please enter valid email address",
	PASSWORD: "Password is required",
	NO_RESULTS_HEADING: "No results found",
	NO_RESULTS_CONTENT:
		"Try adjusting your search to find what you are looking for",
	SOMETHING_WENT_WRONG_HEADING: "Oops!",
	SOMETHING_WENT_WRONG_CONTENT: "Something went wrong, please try again",
	CUSTOMER_EXPECTED_PRICE: "Please enter price",
	OTP: "Please enter valid Otp",
	OTHER_NUMBER: "Please enter valid number",
	ADDRESS: "Please enter valid address",
	CHECK_IN_LEAD_SEARCH: "Please enter 10 digit number for search",
	REGISTRATION_REQUIRED: "Please enter registration no. b/w 9 to 10 digit only",
	REMARKS: "This section is required",
	NO_LEADS: "NO LEADS",
	NO_LEADS_MESSAGE: "For new leads, you can go to In-Nego Page",
};

export const HEADER_KEYS = {
	S_NUMBER: "S_NO",
	CHECK_IN_ACTION: "CHECK_IN",
	FOLLOW_UP_ACTION: "FOLLOW_UP",
	CANCELLATION: "CANCELLATION",
	EXOPHONE: "exophone",
};

export const DATATYPE = {
	TIMER: "timer",
	LINK: "link",
};

export const RESET_REDUCER_STATE_KEYS = {
	IS_LOADING: "isLoading",
	IS_POSTING: "isPosting",
	APPOINTMENT_STATE: "appointmentState",
	DATA: "data",
	RESET_AND_FETCH_DATA: "resetAndFetchData",
	QUERY: "query",
	PRIORITY_COUNT: "priorityCount",
	GLOBAL_QUERY: "globalQuery",
	FROM_PAGE: "fromPage",
	ERROR: "error",
	LOST_LEADS: "lostleads",
};
export const FILTER_KEYS = {
	NEXT_DAY: "nextday",
	TODAY: "today",
	LAST_DAY: "yesterday",
	ONE_DAY: "oneDay",
	TWO_DAYS: "twoDays",
	THREE_DAYS: "threeDays",
	FOUR_DAYS: "fourDays",
	FIVE_DAYS: "fiveDays",
	SIX_DAYS: "sixDays",
	SEVEN_DAYS: "sevenDays",
	EIGHT_DAYS: "eightDays",
	NINE_DAYS: "nineDays",
	FOUR_PLUS_DAYS: "pllFourPlusDays",
	TWENTY_PLUS_DAYS: "pllTwentyPlusDays",
	LOST: "lost",
	FIRST_TWO_DAYS: "pllIntent12Days",
	THIRD_FOURTH_DAYS: "pllIntent34Days",
	FIVE_PLUS_DAYS: "pllIntentFivePlusDays",
	TOTAL: "total",
};

export const MISSED_CALL_NEGOTIATION_FILTERS = {
	TODAY: { key: "today", label: "(Today)", value: 0 },
	LAST_DAY: { key: "yesterday", label: "(Yesterday)", value: 1 },
};

export const NEGOTIATION_FILTERS = {
	TODAY: { key: "today", label: "(Day 3)", value: 0 },
	ONE_DAY: { key: "oneDay", label: "(Day 2)", value: 1 },
	TWO_DAYS: { key: "twoDays", label: "(Day 1)", value: 2 },
	THREE_DAYS: { key: "threeDays", label: "(Day 0)", value: 3 },
	TOTAL: { key: "total", label: "(Total Leads)", value: "" },
};

export const BIKE_NEGOTIATION_FILTERS = {
	FOUR_DAYS: { key: "fourDays", label: "(Day 4 to 15)", value: 4 },
	TODAY: { key: "today", label: "(Day 3)", value: 0 },
	ONE_DAY: { key: "oneDay", label: "(Day 2)", value: 1 },
	TWO_DAYS: { key: "twoDays", label: "(Day 1)", value: 2 },
	THREE_DAYS: { key: "threeDays", label: "(Day 0)", value: 3 },
	TOTAL: { key: "total", label: "(Total Leads)", value: "" },
};

export const SELL_ONLINE_FILTERS = {
	NEXT_DAY: { key: "nextday", label: "All leads", value: "next_day" },
	TO_DAY: { key: "today", label: "Fresh leads", value: "today" },
	LOST: { key: "lost", label: "Older than 2 days", value: "lost" },
};

export const PLL_USER_NEGOTIATION_FILTERS = {
	FOUR_DAYS: {
		key: FILTER_KEYS.FOUR_DAYS,
		label: "(Day 1 + 2 + 3 + 4)",
		value: "1,2,3,4",
	},
	FIVE_DAYS: {
		key: FILTER_KEYS.FIVE_DAYS,
		label: "(Day 5 + 6)",
		value: "5,6",
	},
	SIX_DAYS: {
		key: FILTER_KEYS.SIX_DAYS,
		label: "(Day 7 + 8 + 9)",
		value: "7,8,9",
	},
	FOUR_PLUS_DAYS: {
		key: FILTER_KEYS.FOUR_PLUS_DAYS,
		label: "(Day 9+)",
		value: "10",
	},
	TWENTY_PLUS_DAYS: {
		key: FILTER_KEYS.TWENTY_PLUS_DAYS,
		label: "(Day 20+)",
		value: "20",
	},
	TOTAL: { key: "total", label: "(Total Leads)", value: "" },
};

export const CENTRAL_PLL_USER_NEGOTIATION_FILTERS = {
	FIRST_TWO_DAYS: {
		key: FILTER_KEYS.FIRST_TWO_DAYS,
		label: "(Day 1 & 2)",
		value: "1,2",
	},
	THIRD_FOURTH_DAYS: {
		key: FILTER_KEYS.THIRD_FOURTH_DAYS,
		label: "(Day 3 & 4)",
		value: "3,4",
	},
	FIVE_PLUS_DAYS: {
		key: FILTER_KEYS.FIVE_PLUS_DAYS,
		label: "(Day 5+)",
		value: "5",
	},
	TOTAL: { key: "total", label: "(Total Leads)", value: "" },
};

export const BIKE_PLL_USER_NEGOTIATION_FILTERS = {
	FOUR_DAYS: {
		key: FILTER_KEYS.FOUR_DAYS,
		label: "(Day 1 + 2 + 3 + 4)",
		value: "1,2,3,4",
	},
	FIVE_DAYS: {
		key: FILTER_KEYS.FIVE_DAYS,
		label: "(Day 5 + 6)",
		value: "5,6",
	},
	SIX_DAYS: { key: FILTER_KEYS.SIX_DAYS, label: "(Day 7)", value: "7" },
	TOTAL: { key: "total", label: "(Total Leads)", value: "" },
};

export const VISITED_FILTERS = {
	PENDING: { key: "pending", label: "Pending", value: 0 },
	IN_PROGRESS: { key: "inProgress", label: "In-Progress", value: 1 },
	TOTAL: { key: "total", label: "Total Count", value: "" },
};
export const HOME_INSPECTION_FILTERS = {
	TODAY: { key: "today", label: "Today", value: 1 },
	PREV_DAY: { key: "nextDay", label: "Next Day", value: 2 },
};
export const CJM_HOME_INSPECTION_FILTERS = {
	LAST_DAYS: { key: "lastDays", label: "UnAssigned Leads", value: 0 },
	TODAY: { key: "today", label: "Today", value: 1 },
	PREV_DAY: { key: "nextDay", label: "Next Day", value: 2 },
};
export const CJM_UNASSIGNED_DAYS_FILTERS = {
	NEXT_DAY: { value: "next", label: "Next Day" },
	TODAY: { value: "today", label: "Today" },
	PREV_DAY: { value: "prev", label: "Previous" },
};
export const CC_CONFIRMED_FILTERS = {
	TODAY: { key: "confirm", label: "CONFIRMED LEADS", value: 1 },
	PREV_DAY: { key: "nonconfirm", label: "NOT CONFIRMED LEADS", value: 0 },
};
export const REVIEW_INSPECTION_FILTERS = {
	CURRENT_INSPECTION: {
		key: "currentinspection",
		label: "Current Inspections",
		value: 1,
	},
	PENDING_REVIEW: {
		key: "pendingreviews",
		label: "Pending Reviews",
		value: 0,
	},
	LEAD_MON: { key: "LeadMon", label: "LeadMon", value: -1 },
};
export const LEADMON_FILTERS = {
	ASSIGNED: {
		key: "assigned",
		label: "Assigned",
		value: 1,
	},
	ALL_LEADS: {
		key: "all_leads",
		label: "All Leads",
		value: 0,
	},
	SOLD: {
		key: "bought",
		label: "Sold",
		value: 2,
	},
	ON_PLATFORM_REQUEST: {
		key: "platform",
		label: "On Platform Request",
		value: 3,
	}
};
export const PAYMENT_PROCESSING_FILTERS = {
	THIS_WEEK: { key: "thisweek", label: "This Week", value: false },
	PREVIOUS_WEEK: { key: "previousweek", label: "Previous Week", value: true },
};
export const LOCAL_STORAGE_KEYS = {
	LOGIN_DATA: "loginData",
	EMAIL: "email",
	STORE_NAME: "storeName",
	STORE_ID: "storeId",
	LEADS: "leads",
	UID: "uid",
	LMS_STORE_ID: "idLmsStore",
	INDIVIDUAL_ID: "indvUid",
	SELECTED_STORES: "selectedStores",
	ROLE: "role",
	INDIVIDUALS_LIST: "individualsList",
	CITY_STORES_LIST: "cityStoresList",
	PLL_USER: "isPll",
	PLL_APPOINTMENT: "pllAppointment",
	PLL_RAID: "pllraId",
	PLL_RANAME: "pllraName",
	INDIVIDUAL_NAME: "indvName",
	QUOTE_TIMESTAMPS: "quoteTimeStamps",
	FULL_NAME: "fullName",
	MOBILE: "mobile",
	FIRST_NAME: "fname",
	LAST_NAME: "lname",
	RATING: "rating",
	C2C_USER: "isC2c",
	lostLead: "lostLead",
	WAITTIME_DASHBOARD_UID: "waittimeuid",
	STORE_TYPE: "storeType",
	STORE_LOCALITY: "locality",
	CJ_LIST: "cjList",
	ORIGIN_ROLE: "originalRole",
	DEALER_COUNT: "dealerCount",
	COVID_BANNER: "showCovidBanner",
	PAI_RAISE_WARNING: "showPaiRaiseWarning",
	DEALER_IMPRESSION_START: "dealerImpressionStart",
	DEALER_COUNT_REACHED: "dealerCountReached",
	HI_CONFIGURED_CITIES: "hiCity",
	SELECTED_CITIES: "selectedCities",
	RA_LIST: "raList",
	DEP_FEEDBACK_SUBMIT: "depFeedback",
	IS_INCENTIVE_DASHBOARD_VISIBLE: "isIncentiveDashboardVisible",
	RAID: "raid",
	RA_STORE_CHAT_CONFIG: "raStoreChatConfig",
	SELECTED_BASE_API_HOST: "selectedBaseAPIHost",
	MAP_TYPE_ACTIVE: "mapType",
	RA_STORE_ID: "raStoreId",
	RA_MEMBER_IN_STORES: "raMemberInStores",
	LMS_ACCESS_TOKEN: "lms-access-token",
	USER_SOURCE: "user-source",
	BIKE_RA_LIST: "bikeRAList",
	SHOW_UPLOAD_CATEGORY: "showUploadCategory",
	NO_ACCESS: "no-access",
};
export const SIDEBAR_MAP_LIST = {
	SELL_ONLINE: "sell-online",
	SCHEDULED: "scheduled",
	VISITED: "visited",
	INSPECTED: "inspected",
	NEGOTIATION: "negotiation",
	PAYMENT_PROCESSING: "payment-processing",
	CLEARANCE_PENDING: "clearance-pending",
	CJ_PLANNING: "cj-planning",
	CJ_EXECUTION: "cj-execution",
	DIY_NEGOTIATION: "diy-negotiation",
	CENTRAL_NEGOTIATION: "rt-negotiation",
	CENTRAL_DIY_NEGOTIATION: "rt-diy-negotiation",
	LEAD_MON: "leadmon",
  UNALLOCATED:'unallocated-leads',
  RT_UNALLOCATED:'rt-unallocated-leads'
};

export const HEADER_DASHBOARD_MENU_KEYS = {
    RA_INCENTIVE: "incentive",
    ZM_INCENTIVE: "zm-incentive",
    HYPO_INCENTIVE: "hypo-incentive",
    EVALUATOR_STORE_SELECT: "evaluator-store-select",
    CALL_REPORTS: "call-reports",
    INCOMING_CALL_LOGS: "incoming-call-logs",
	ZENDESK_TICKETS: "zendesk",
};

export const HEADER_DASHBOARD_MASTER_DATA = {
    [HEADER_DASHBOARD_MENU_KEYS.RA_INCENTIVE]: {
        url: "/ra-incentive",
    },
    [HEADER_DASHBOARD_MENU_KEYS.ZM_INCENTIVE]: {
      url: "/zm-incentive",
    },
    [HEADER_DASHBOARD_MENU_KEYS.HYPO_INCENTIVE]: {
      url: "/hypo-incentive",
    },
    [HEADER_DASHBOARD_MENU_KEYS.EVALUATOR_STORE_SELECT]: {
        url: "/evaluator-store-select",
    },
    [HEADER_DASHBOARD_MENU_KEYS.CALL_REPORTS]: {
        url: "/call-reports",
    },
    [HEADER_DASHBOARD_MENU_KEYS.INCOMING_CALL_LOGS]: {
        url: "/incoming-call-logs",
    },
	[HEADER_DASHBOARD_MENU_KEYS.ZENDESK_TICKETS]: {
        url: "/zendesk-tickets",
    },
};

export const MASTER_SIDE_BAR = {
	[SIDEBAR_MAP_LIST.SELL_ONLINE]: {
		iconStyle: "sellOnlineIcon",
		leadCount: "sellOnlineCount",
		priorityCount: "sell-online",
		stateId: 30,
		routePath: "/sell-online",
	},
	[SIDEBAR_MAP_LIST.SCHEDULED]: {
		iconStyle: "scheduledIcon",
		leadCount: "scheduledCount",
		priorityCount: "scheduled",
		stateId: 1,
		routePath: "/scheduled",
	},
	[SIDEBAR_MAP_LIST.VISITED]: {
		iconStyle: "visitedIcon",
		leadCount: "visitedCount",
		priorityCount: "visited",
		stateId: 2,
		routePath: "/visited",
	},
	[SIDEBAR_MAP_LIST.INSPECTED]: {
		iconStyle: "inspectedIcon",
		leadCount: "inspectedCount",
		priorityCount: "inspected",
		stateId: 11,
		routePath: "/inspected",
	},
	[SIDEBAR_MAP_LIST.NEGOTIATION]: {
		iconStyle: "negotiationIcon",
		leadCount: "inNegotiationCount",
		priorityCount: "negotiation",
		stateId: 17,
		routePath: "/negotiation",
	},
	[SIDEBAR_MAP_LIST.DIY_NEGOTIATION]: {
		iconStyle: "diyIcon",
		leadCount: "diy-inNegotiationCount",
		priorityCount: "diy-negotiation",
		stateId: 35,
		routePath: "/diy-negotiation",
	},
	[SIDEBAR_MAP_LIST.PAYMENT_PROCESSING]: {
		iconStyle: "paymentProcessingIcon",
		leadCount: "paymentProcessingCount",
		priorityCount: "payment-processing",
		stateId: 22,
		routePath: "/payment-processing",
	},
	[SIDEBAR_MAP_LIST.CLEARANCE_PENDING]: {
		iconStyle: "clearancePendingIcon",
		leadCount: "clearancePendingCount",
		priorityCount: "clearance-pending",
		stateId: 18,
		routePath: "/clearance-pending",
	},
	[SIDEBAR_MAP_LIST.CJ_EXECUTION]: {
		iconStyle: "planningIcon",
		leadCount: "planningCount",
		priorityCount: "cj-execution",
		routePath: "/cj-execution",
	},
	[SIDEBAR_MAP_LIST.CENTRAL_NEGOTIATION]: {
		iconStyle: "negotiationIcon",
		leadCount: "inNegotiationCount",
		priorityCount: "negotiation",
		stateId: 37,
		routePath: "/rt-negotiation",
	},
	[SIDEBAR_MAP_LIST.CENTRAL_DIY_NEGOTIATION]: {
		iconStyle: "diyIcon",
		leadCount: "diy-inNegotiationCount",
		priorityCount: "diy-negotiation",
		stateId: 38,
		routePath: "/rt-diy-negotiation",
	},
	[SIDEBAR_MAP_LIST.LEAD_MON]: {
		iconStyle: "planningIcon",
		leadCount: "leadmonCount",
		priorityCount: "leadmonCount",
		stateId: 99,
		routePath: "/leadmon",
	},
  [SIDEBAR_MAP_LIST.UNALLOCATED]: {
		iconStyle: "negotiationIcon",
		leadCount: "unallocatedLeadsCount",
		priorityCount: "unallocated_leads",
		routePath: "/unallocated-leads",
	},
  [SIDEBAR_MAP_LIST.RT_UNALLOCATED]: {
		iconStyle: "negotiationIcon",
		leadCount: "unallocatedLeadsCount",
		priorityCount: "unallocated_leads",
		routePath: "/unallocated-leads",
	},
};

export const TIME_DIFF_IN_MINUTES = 60000;

export const PENDING = "Pending";

export const CRITICAL_REJECT = "Critical Reject";

export const REJECTED = "Rejected";

export const PICKUP_PENDING = "Pickup Pending";

export const TODAY = "Today";

export const NOT_AVAILABLE = "N/A";

export const HI_STORE_TYPE = "locality";

export const QC_STATUS = {
	IN_PROGRESS: "In-Progress",
	CRITICAL_REJECT: "Critical Reject",
};

export const IGNORED_EMAIL_FOR_CHECK_IN = ["abtest_", "dsa_"];

export const TIMER_FLAGS = {
	PAI_RUNNING: "PAI_RUNNING",
	AUCTION_RUNNING: "AUCTION_RUNNING",
	OCB_RUNNING: "OCB_RUNNING",
};

export const TEXT_AREA_REGEX = /\r?\n|\r/g;

export const FORGET_PASSWORD_LINK = "https://portal.24c.in/";

export const LEAD_TYPE = {
	STORE: "Store",
	INDIVIDUAL: "Individual",
	B2B: "B2B",
};

export const USER_TYPE = {
	CARS24: "cars24",
	OTHER: "other",
};

export const INFO_TEXT = {
	EXOPHONE: "Virtual number and the number of connected calls",
};

export const INITIAL_PRIORITY_COUNTS = {
	scheduled: 0,
	visited: 0,
	inspected: 0,
	negotiation: 0,
	"payment-processing": 0,
	"clearance-pending": 0,
};

export const NO_HOLDBACKS = "No Pending Hold";

export const GLOBAL_SEARCH_STATES = {
	SCHEDULED: "scheduled",
	VISITED: "visited",
	INSPECTED: "inspected",
	NEGOTIATION: "in-negotiation",
	PAYMENT_PROCESSING: "payment processing",
	CLEARNANCE_PENDING: "clearance pending",
	LOST_LEADS: "lost-leads",
	SELL_ONLINE: "sell online",
};

export const QC_APPROVE = {
	REJECTED: "PR Rejected",
	IN_PROGRESS: "Approved",
};

export const LEAD_DETAILS_TABS = {
	APPOINTMENT_DETAIL: { key: "appointmentDetail", label: "Appointment" },
	CUSTOMER_DETAIL: { key: "customerDetail", label: "Customer Details" },
	DEALER_DETAIL: { key: "dealerDetail", label: "Dealer Details" },
	CAR_DETAIL: { key: "carDetail", label: "Car Details" },
	C24QUOTE_DETAIL: { key: "c24QuoteDetail", label: "C24 Quote" },
	BIKE_PRICE_ACCEPTANCE: {
		key: "bikePriceAcceptance",
		label: "Price Acceptance",
	},
	// PAYMENT_RC_DETAIL: { key: 'paymentRcDetail', label: 'Payment & RC' }
};

export const TABLE_COLUMNS = {
	VISITED_ACTION: { columnName: "action", label: "ACTIONS" },
	INSPECTED_LEADMON_ACTION: { columnName: "action", label: "ACTIONS" },
	// INSPECTED_ANNOUNCE: { columnName: 'announce', label: 'ANNOUNCE' }
};

export const LEAD_PROGRESS_STEP = [
	{ value: "scheduled", label: "Scheduled" },
	{ value: "visited", label: "Visited" },
	{ value: "inspected", label: "Inspected" },
	{ value: "in-negotiation", label: "In-Negotiation" },
	{ value: "payment processing", label: "Payment Processing" },
	{ value: "clearance pending", label: "Clearance Pending" },
];

export const ROLE_KEYS = {
	CORP: "corp",
	RM: "rm",
	RM_NEW: "RM",
	TM_NEW: "TM",
	RA: "ra",
	RA_NEW: "RA",
	HOSTESS: "hostesses",
	NO_ACCESS: "na",
	TM: "TM (Retail+PLL)",
	CJM: "CJ Manager",
	PLL: "PLL",
	PLL_RH: "PLL-RH",
	RA_TRA: "RA/TRA",
	PLL_RA_CENTER: "PLL-RA-Center",
	PLL_ZM_CENTER: "PLL-ZM-Center",
	PLL_RA: "PLL-RA",
	PLL_RM: "PLL-RM",
	CITY_HEADS: "City Heads",
	HI_RA_TRA: "HI RA/TRA",
	FRANCHISE_RA: "Franchise - RA",
	CORP_EDITOR: "Corporate-Editor",
	CORP_VIEWER: "Corporate-Viewer",
	PLL_TM: "PLL-TM",
	A2I_HEAD: "A2I-Head",
	FOOTFALL_MANAGER: "Footfall-Manager",
	PLL_CH: "PLL-CH",
	RA_LM: "RA-LM",
  RA_CX_OCB:"RA-CX-OCB"
};

export const REPORTS_TAB = {
	inspection: "Inspection Report",
	depreciation: "Depreciation Report",
};

export const DEPRECIATION_CONSTANTS = {
	veryGoodMax: "Web Quote",
	engine: "Engine + Suspension",
	insurance: "Insurance Type",
	exterior: "Exterior + Tyres",
	owner: "Owner Number",
	odometer: "Odometer",
	final: "Expected Quote",
};
export const DEPRECIATION_CONSTANTS_NEW = {
	veryGoodMax: "Web Quote",
	engine: "Engine + Suspension",
	insurance: "Insurance Type",
	exterior: "Exterior + Tyres",
	interior: "Interior",
	owner: "Owner Number",
	odometer: "Odometer",
	final: "Expected Quote",
};

export const RETAILUSER_ROLEACCESS_PATHS = {
    roleAccess: [
        {
            role: ROLE_KEYS.HOSTESS,
            paths: [
                "/check-in",
                "/",
                "/evaluator-view",
                "/evaluator-store-select",
            ],
        },
        {
            role: ROLE_KEYS.RA,
            paths: [
                "/",
                "/login",
                "/detail",
                "/negotiation",
                "/nego-lost-lead",
                "/scheduled",
                "/visited",
                "/clearance-pending",
                "/inspected",
                "/payment-processing",
                "/lost-leads",
                "/check-in",
                "/evaluator-view",
                "/evaluator-store-select",
                "/profile",
                "/similar-cars",
                "/customer-offer-price",
                "/waittime-dashboard",
                "/dep-report",
                "/customer-journey",
                "/self-checkin",
                "/incentive",
                "/document-upload",
                "/payment-initiate-token",
                "/sell-online",
                "/self-Inspection-report",
                "/diy-negotiation",
                "/upload-csv",
                "/call-reports",
                "/incoming-call-logs",
                "/leadmon",
				"/zendesk-tickets",
            ],
        },
        {
            role: ROLE_KEYS.RM,
            paths: [
                "/",
                "/login",
                "/detail",
                "/negotiation",
                "/nego-lost-lead",
                "/scheduled",
                "/visited",
                "/clearance-pending",
                "/inspected",
                "/payment-processing",
                "/lost-leads",
                "/check-in",
                "/evaluator-view",
                "/evaluator-store-select",
                "/profile",
                "/similar-cars",
                "/customer-offer-price",
                "/waittime-dashboard",
                "/dep-report",
                "/customer-journey",
                "/self-checkin",
                "/incentive",
                "/document-upload",
                "/payment-initiate-token",
                "/sell-online",
                "/self-Inspection-report",
                "/diy-negotiation",
                "/upload-csv",
                "/call-reports",
                "/incoming-call-logs",
				"/allocate",
				"/zendesk-tickets",
            ],
        },
        {
            role: ROLE_KEYS.CORP,
            paths: [
                "/",
                "/login",
                "/detail",
                "/negotiation",
                "/nego-lost-lead",
                "/scheduled",
                "/visited",
                "/clearance-pending",
                "/inspected",
                "/payment-processing",
                "/lost-leads",
                "/check-in",
                "/evaluator-view",
                "/evaluator-store-select",
                "/profile",
                "/similar-cars",
                "/customer-offer-price",
                "/waittime-dashboard",
                "/dep-report",
                "/customer-journey",
                "/self-checkin",
                "/incentive",
                "/document-upload",
                "/payment-initiate-token",
                "/sell-online",
                "/self-Inspection-report",
                "/diy-negotiation",
                "/upload-csv",
                "/call-reports",
                "/incoming-call-logs",
            ],
        },
        {
            role: ROLE_KEYS.NO_ACCESS,
            paths: ["/", "/login"],
        },
        {
            role: ROLE_KEYS.CJM,
            paths: [
                "/visited",
                "/login",
                "/detail",
                "/inspected",
                "/scheduled",
            ],
        },
    ],
};
export const RA_TRA_RETAILUSER_ROLEACCESS_PATHS = {
    roleAccess: [
        {
            role: ROLE_KEYS.HOSTESS,
            paths: [
                "/check-in",
                "/",
                "/evaluator-view",
                "/evaluator-store-select",
            ],
        },
        {
            role: ROLE_KEYS.RA,
            paths: [
                "/",
                "/login",
                "/detail",
                "/negotiation",
                "/nego-lost-lead",
                "/clearance-pending",
                "/payment-processing",
                "/lost-leads",
                "/check-in",
                "/evaluator-view",
                "/evaluator-store-select",
                "/profile",
                "/similar-cars",
                "/customer-offer-price",
                "/waittime-dashboard",
                "/dep-report",
                "/customer-journey",
                "/self-checkin",
                "/incentive",
                "/document-upload",
                "/payment-initiate-token",
                "/self-Inspection-report",
                "/diy-negotiation",
                "/upload-csv",
                "/call-reports",
                "/incoming-call-logs",
				"/zendesk-tickets",
            ],
        },
        {
            role: ROLE_KEYS.RM,
            paths: [
                "/",
                "/login",
                "/detail",
                "/negotiation",
                "/nego-lost-lead",
                "/scheduled",
                "/visited",
                "/clearance-pending",
                "/inspected",
                "/payment-processing",
                "/lost-leads",
                "/check-in",
                "/evaluator-view",
                "/evaluator-store-select",
                "/profile",
                "/similar-cars",
                "/customer-offer-price",
                "/waittime-dashboard",
                "/dep-report",
                "/customer-journey",
                "/self-checkin",
                "/incentive",
                "/document-upload",
                "/payment-initiate-token",
                "/sell-online",
                "/self-Inspection-report",
                "/diy-negotiation",
                "/upload-csv",
                "/call-reports",
                "/incoming-call-logs",
				"/zendesk-tickets",
            ],
        },
        {
            role: ROLE_KEYS.NO_ACCESS,
            paths: ["/", "/login"],
        },
        {
            role: ROLE_KEYS.CORP,
            paths: [
                "/",
                "/login",
                "/detail",
                "/negotiation",
                "/nego-lost-lead",
                "/scheduled",
                "/visited",
                "/clearance-pending",
                "/inspected",
                "/payment-processing",
                "/lost-leads",
                "/check-in",
                "/evaluator-view",
                "/evaluator-store-select",
                "/profile",
                "/similar-cars",
                "/customer-offer-price",
                "/waittime-dashboard",
                "/dep-report",
                "/customer-journey",
                "/self-checkin",
                "/incentive",
                "/document-upload",
                "/payment-initiate-token",
                "/sell-online",
                "/self-Inspection-report",
                "/diy-negotiation",
                "/upload-csv",
                "/call-reports",
                "/incoming-call-logs",
            ],
        },
        {
            role: ROLE_KEYS.CJM,
            paths: [
                "/visited",
                "/login",
                "/detail",
                "/inspected",
                "/scheduled",
            ],
        },
    ],
};
export const PLLUSER_ROLEACCESS_PATHS = {
    roleAccess: [
        {
            role: ROLE_KEYS.HOSTESS,
            paths: ["/check-in", "/", "/login"],
        },
        {
            role: ROLE_KEYS.RA,
            paths: [
                "/",
                "/login",
                "/detail",
                "/negotiation",
                "/diy-negotiation",
                "/nego-lost-lead",
                "/clearance-pending",
                "/payment-processing",
                "/lost-leads",
                "/check-in",
                "/evaluator-view",
                "/evaluator-store-select",
                "/profile",
                "/waittime-dashboard",
                "/dep-report",
                "/customer-journey",
                "/self-checkin",
                "/incentive",
                "/document-upload",
                "/payment-initiate-token",
                "/sell-online",
                "/self-inspection-report",
                "/call-reports",
                "/rt-negotiation",
                "/incoming-call-logs",
				"/zendesk-tickets",
            ],
        },
        {
            role: ROLE_KEYS.RM,
            paths: [
                "/",
                "/login",
                "/detail",
                "/negotiation",
                "/diy-negotiation",
                "/nego-lost-lead",
                "/clearance-pending",
                "/payment-processing",
                "/lost-leads",
                "/check-in",
                "/evaluator-view",
                "/evaluator-store-select",
                "/allocate",
                "/profile",
                "/similar-cars",
                "/waittime-dashboard",
                "/dep-report",
                "/customer-journey",
                "/self-checkin",
                "/incentive",
                "/document-upload",
                "/payment-initiate-token",
                "/sell-online",
                "/self-inspection-report",
                "/call-reports",
                "/incoming-call-logs",
                "/rt-negotiation",
                "/rt-diy-negotiation",
				"/zendesk-tickets",
            ],
        },
        {
            role: ROLE_KEYS.CORP,
            paths: [
                "/",
                "/login",
                "/detail",
                "/negotiation",
                "/nego-lost-lead",
                "/scheduled",
                "/visited",
                "/clearance-pending",
                "/inspected",
                "/payment-processing",
                "/lost-leads",
                "/check-in",
                "/evaluator-view",
                "/evaluator-store-select",
                "/profile",
                "/similar-cars",
                "/customer-offer-price",
                "/waittime-dashboard",
                "/dep-report",
                "/customer-journey",
                "/self-checkin",
                "/incentive",
                "/document-upload",
                "/payment-initiate-token",
                "/sell-online",
                "/self-Inspection-report",
                "/diy-negotiation",
                "/upload-csv",
                "/call-reports",
                "/incoming-call-logs",
            ],
        },
        {
            role: ROLE_KEYS.NO_ACCESS,
            paths: ["/", "/login"],
        },
        {
            role: ROLE_KEYS.CJM,
            paths: [
                "/visited",
                "/login",
                "/detail",
                "/inspected",
                "/scheduled",
            ],
        },
    ],
};
export const C2CUSER_ROLEACCESS_PATHS = {
	roleAccess: [
		{
			role: ROLE_KEYS.HOSTESS,
			paths: ["/", "/login", "/negotiation"],
		},
		{
			role: ROLE_KEYS.RA,
			paths: [
				"/",
				"/login",
				"/negotiation",
				"/customer-journey",
				"/self-inspection-report",
			],
		},
		{
			role: ROLE_KEYS.RM,
			paths: [
				"/",
				"/login",
				"/negotiation",
				"/customer-journey",
				"/self-inspection-report",
			],
		},
		{
			role: ROLE_KEYS.NO_ACCESS,
			paths: ["/", "/login", "/self-inspection-report"],
		},
	],
};
export const APPOINTMENT_ID_REGEX = /\/([0-9]+)[^\/]*$/g;

export const VALID_ROLES = ["rm", "ra", "hostesses", "cjm", "corp"];

export const WAIT_TIME_DASHBOARD_PAGE_SIZE = 13;
export const WAIT_TIME_DASHBOARD_SCREENSPLIT_INTERVAL = 7000;
export const WAIT_TIME_DASHBOARD_DISPLAYADD_INTERVAL = 300000;
export const WAIT_TIME_DASHBOARD_HIDEADD_INTERVAL = 10000;
export const HIGH_PRICE_EXPECTATION_PRICE = "226";

export const LEAD_ACTIVITY_FEED = {
	Call_Log: { key: "calllog", label: "Call Log" },
	Change_Log: { key: "changelog", label: "Change Log" },
};

export const PLL_LEADS_TYPE = {
	ALL_LEADS: "All Leads",
	GS_ASSURED: "GS Assured",
	NON_GS_ASSURED: "Non-GS Assured",
};

export const YES_NO = {
	YES: "y",
	NO: "n",
};

export const PLL_INVENTORY_APPOINTEMENT_FILTER = [
	{ key: 2, label: PLL_LEADS_TYPE.ALL_LEADS, value: NUMBERS.TWO },
	{ key: 1, label: PLL_LEADS_TYPE.GS_ASSURED, value: NUMBERS.ONE },
	{ key: 0, label: PLL_LEADS_TYPE.NON_GS_ASSURED, value: NUMBERS.ZERO },
];

export const MAX_ALLOWED_UPLOAD_SIZE = 5 * 1024 * 1000;
export const NEGO_SELL_ONLINE_FOLLOWUP_TYPE = 1730;

export const PDF_MIME_TYPE = "application/pdf";
export const PDF_FILE_EXTENSION = ".pdf";

export const ALLOCATION_TYPE = {
	LOW: "1",
	HIGH: "2",
};

export const SLIDER_TYPES = {
	SLIDER: "SLIDER",
	TEXTBOX: "TEXTBOX",
	TEXTBOX_NO_LIMIT: "TEXTBOX_NO_LIMIT",
	TEXTBOX_WITH_LIMIT: "TEXTBOX_WITH_LIMIT",
	NA: "NA",
};

export const DIY_SORT_ORDER = {
    SO_NO_PRICE_CHANGE: 411,
    SO_PRICE_NOT_ACCEPTED: 412,
    SO_PRICE_ACCEPTED: 413,
    HI_PRICE_NOT_ACCEPTED: 414,
    HI_PRICE_ACCEPTED: 415,
    HI_GS_PRICE_NOT_ACCEPTED: 416,
    HI_GS_PRICE_ACCEPTED: 417,
    HI_NON_GS_PRICE_NOT_ACCEPTED: 418,
    HI_NON_GS_PRICE_ACCEPTED: 419,
    DIY_PAI_RUNNING: 420,
    DIY_PAI_ACCEPTED: 421,
    DIY_OCB_RUNNING_AT: 422,
    DIY_OCB_ACCEPTED: 423,
    DIY_OCB_EXPIRED: 424,
    DIY_PARTIAL_PAI_NEGO_RETAIL: 425,
    DIY_PARTIAL_PAI_NEGO_RETAIL_PR: 426,
    DIY_SUSPENDED: 427,
    DIY_AUCTION_RUNNING: 428,
    DIY_INTERNAL_BID_5: 429,
    DIY_INTERNAL_BID_6: 430,
    DIY_PLL_DEFAULT_VIEW: 461,
    DIY_PLL_AUCTION_RUNNING: 462,
    DIY_PLL_PAI_RUNNING: 463,
    DIY_PLL_OCB_OR_PAI_ACCEPTED: 464,
    DIY_PLL_OCB_RUNNING: 465,
    DIY_PLL_OCB_EXPIRED: 466,
    DIY_PLL_PARTIAL_OCB_RUNNING: 467,
    DIY_PLL_PARTIAL_OCB_EXPIRED: 468,
    DIY_PLL_INVENTORY: 469,
    DIY_PLL_CEPENABLED: 470,
    DIY_PLL_CUST_ACCEPT_PRICE: 471,
    DIY_PLL_SELLER_INTENT: 472,
    DIY_RA_NEGO_VIEW: 473,
	DIY_DIRECT_OCB_NA: 501,
	DIY_DIRECT_OCB_EXPIRED: 502,
    NONE: 400,
};

export const RM_ROLES = [
	ROLE_KEYS.CORP_VIEWER,
	ROLE_KEYS.RM,
	ROLE_KEYS.RM_NEW,
	ROLE_KEYS.TM_NEW,
	ROLE_KEYS.TM,
	ROLE_KEYS.PLL_ZM_CENTER,
	ROLE_KEYS.PLL_RA_CENTER,
	ROLE_KEYS.PLL_RM,
	ROLE_KEYS.CITY_HEADS,
	ROLE_KEYS.CORP_EDITOR,
	ROLE_KEYS.PLL_RH,
	ROLE_KEYS.CJM,
	ROLE_KEYS.A2I_HEAD,
	ROLE_KEYS.PLL_CH,
];
export const RA_ROLES = [
	ROLE_KEYS.FRANCHISE_RA,
	ROLE_KEYS.HI_RA_TRA,
	ROLE_KEYS.PLL_RA,
	ROLE_KEYS.RA_TRA,
	ROLE_KEYS.RA,
	ROLE_KEYS.RA_NEW,
	ROLE_KEYS.RA_LM,
  ROLE_KEYS.RA_CX_OCB
];

export const APPOINTMENT_FLAG_KEYS = {
	LEAD_TYPE: "leadType",
	LEAD_MON: "isMonetized",
	REPEAT_USER: "isRepeatUser",
	HYPO_APPROVED: "isHypoAutoApproved",
	AUCTION_LABEL: "auction_label",
	RETARGET_LABEL: "retarget_label",
	TEMP_ALLOCATE: 'temp_allocation',
	DIRECT_OCB: "direct_ocb"
};

export const HEADER_TAB_TYPES = {
	RETAIL: 0,
	PLL: 1,
	BOTH: 2,
	NONE: 99,
};

export const TAB_KEYS = {
	RETAIL: 'retail',
	PLL: 'pll',
	RT: 'rt',
	NONE: '',
};