import React from "react";
import styles from "./top-performers.module.scss";

const TopPerformers = ({data}) => {
  const {cityPerformers} = !!data? data : {};
   
  const showTopPerformers = () =>{
    return (
      !!cityPerformers && cityPerformers.slice(0,5).map((item) => {
        return (
          <li>
            <span className={styles.cont}>{item.rank}.</span>
            <span className={styles.name}>{item.raName}</span>
            <span className={styles.target}>{item.targetAchieved}%</span>
          </li>
        );
      })
    );
  };
  const showYouPerformer = () =>{
    const youDetails = !!cityPerformers ? cityPerformers.find(item => item.isUser === true) : '';
    if (!!youDetails) {
      return (
        <li>
          <span className={styles.cont}>{youDetails.rank}.</span>
          <span className={styles.name}>You</span>
          <span className={styles.target}>{youDetails.targetAchieved}%</span>
        </li>
      );
    }
  };
  return (
    <div className={styles.topPerformersWrapper}>
      <h3>Top Performers of your city</h3>
      <div className={styles.performersHeading}>
        <p>Retail Associates</p>
        <p>Target Achieved</p>
      </div>
      <ul className={styles.topPerformersList}>
        {showTopPerformers()}
      </ul>
      <ul className={styles.youStatus}>
        {showYouPerformer()}
      </ul>    
    </div>
  );
};

export default TopPerformers;
