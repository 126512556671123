// import queryString from "querystring";
import { API_ENDPOINTS } from "./api-constants";

export default (api) => {

  const login = (params) => {
    return api.post(`${API_ENDPOINTS.LOGIN}`, params);
  };

  const googleLogin = (params) =>{
    return api.post(`${API_ENDPOINTS.GOOGLE_LOGIN}`, params);
  };

  return {
    login,
    googleLogin
  };
};