import React, { useState } from "react";
import styles from "./self-checkin.module.scss";
import CheckinLogo from "./images/checkinlogo.svg";
import SelfBookAppointment from "../../components/self-book-appointment";
import CongratulationsSelfAppointment from "../../components/congratulations-self-appointment";
import WelcomeSelfAppointment from "../../components/welcome-self-appointment";
import { GetQueryString } from "../../utils/helper";

const SelfCheckin = ({

}) => {
  let storeId = GetQueryString("storeId");
  let storeName = decodeURI(GetQueryString("name"));

  const [appointmentId, setAppointmentId] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [location, setLocation] = useState("");

  const [storeCheckInStep, setStoreCheckInStep] = useState(0);
  const [code, setCode] = useState(0);
  const [welComeObj, setwelComeObj] = useState(null);

  const checkInSuccess = (objSuccess, status) => {
    if (status) {
      if (!!objSuccess) {
        const { code } = objSuccess;
        if (code === 1) {
          setwelComeObj(objSuccess);
          setStoreCheckInStep(1);
          setCode(code);
        }
        else if (code === 2) {
          setStoreCheckInStep(2);
        }
        else if (code === 3 || code === 4 || code === 5) {
          setStoreCheckInStep(1);
          setCode(code);
        }
      }
    }
    else {
      setStoreCheckInStep(2);
    }
  };
  return (
    <div className={styles.selfCheckinWrapper}>
      <div className={styles.selfCheckinHeader}>
        <img src={CheckinLogo} alt="" />
      </div>
      {storeCheckInStep === 0 &&
      <WelcomeSelfAppointment storeId={storeId} storeName={storeName} onSuccess={checkInSuccess} />
      }
      {storeCheckInStep === 1 &&
      <CongratulationsSelfAppointment welComeObj={welComeObj} checkInStep={code} />
      }
      {storeCheckInStep === 2 &&
      <SelfBookAppointment />
      }
    </div>
  );
};

export default SelfCheckin;