import React,{useState} from "react";
import Button from "../../../../components/shared/button/component";
import H2 from "../../../../components/h2/component";
import styles from './ra-remarks-popup.module.scss';
import Textarea from "../../../../components/shared/textarea/component";
import {AppointmentService} from '../../../../service';
import {showToastMessages} from '../../../../utils/helper';
const RARemarksPopup = (props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [remarks, setRemarks] = useState('');
	const onAddRARemarks = () => {
		setIsLoading(true);
		AppointmentService.updateZendeskTicketComment(props?.data?.ticketId, remarks).then((resp) => {
			showToastMessages(resp?.detail?.message || 'Remarks added successfully');
			props?.onClose();
		}).catch(err => {
			showToastMessages(err || 'Failed to add remarks', false);
			console.log({err});
		}).finally(()=>{
			setIsLoading(false);
		});
	};
	return (
		<div className={styles.raremarkswrap}>
			<H2 text="Add Remarks" />
			<div>
				<Textarea
					id="ra-remarks"
					name="ra-remarks"
					value={remarks}
					onChange={(e)=> setRemarks(e.target.value)}
					text="Remarks if any"
				/>
			</div>
			<div className={styles.actionbutton}>
				<Button 
					isLoading={isLoading}
					disabled={!remarks || isLoading}
					onClick={onAddRARemarks}
					classNames="blueButton"
					ctaText="Submit"
				/>
			</div>
		</div>
	);
};

export default RARemarksPopup;