import React from "react";
import {TODAY,NOT_AVAILABLE} from "../../utils/app-constants";
import styles from "./days-to-deal.module.css";

const DaysToDeal = ({ rowDetails }) => {
  const { daysDealLost } = rowDetails;

  return (
    <span className={daysDealLost === TODAY ? styles.today : ''} >{daysDealLost?daysDealLost:NOT_AVAILABLE}</span>
  );
};

export default DaysToDeal;
