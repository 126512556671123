import React, { useEffect, useState } from "react";
import withLayout from '../../components/layout/component';
import HocAccessController from "../../components/hoc-access-controller";
import DataInformationMessage from "../../components/shared/data-information-message";
import styles from './zekdesk-tickets.module.css';
import {getData} from './mock-data';
import Table from "../../components/shared/table";
import TableBody from "../../components/shared/table-body";
import TableRow from "../../components/shared/table-row";
import TableColumn from "../../components/shared/table-column";
import TableHeader from "../../components/shared/table-header";
import {ZENDESK_DISPLAY_COLUMNS} from './viewModels';
import {getTableColumnTypeByKey, ColumnTypeMapping } from "../../utils/helper";
import { Link } from "react-router-dom";
import RARemarks from './components/ra-remarks';
import ViewCCRemarks from './components/view-cc-remarks';
import {AppointmentService} from '../../service';
import moment from "moment";
const ZendeskTickets = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState('');
	const [tickets, setTickets] = useState([]);
	
	useEffect(()=> {
		setIsLoading(true);
		AppointmentService.getZendeskTickets().then((resp)=> {
			const {detail: {tickets=[]} = {}} = resp;
			setTickets(tickets);
		}).catch((err)=> {
			setError(err?.detail?.message || 'Service error');
		}).finally(()=>{
			setIsLoading(false);
		});
	},[]);

	const getColumnHeader = (key, value, item, index) => {
		return (
			<TableHeader
				key={index}
				headerKey={item.key}
				headerValue={item.value}
			/>
		);
	};

	const getColumn = (key, value) => {
		const colType = getTableColumnTypeByKey(key);
		switch (colType) {
			case ColumnTypeMapping.WRAPPED_STRING:
				return <div className={styles.warppedText}>{value}</div>;
			case ColumnTypeMapping.STRING:
				return <span className={styles.modleName}>{value}</span>;
			case ColumnTypeMapping.DASH_STRING:
				return (
					<span className={styles.modleName}>
						{!!value ? value : "-"}
					</span>
				);
			case ColumnTypeMapping.LINK:
				return (
					<Link className={styles.dataId} to="#">
						{value}
					</Link>
				);
			case ColumnTypeMapping.DATE_FORMAT:
				return <div className={styles.warppedText}>{moment(value).format('L')}</div>;
			default:
				break;
		}
	};

	const getActionColumn = (rowDetails, columnName) => {
		if (columnName === 'cc_remarks') {
			return (
				<ViewCCRemarks data={rowDetails}/>
			);
		} else if (columnName === 'ra_remarks') {
			return (
				<RARemarks data={rowDetails}/>
			);
		}
	};

	const renderTickets = () => {
		return (
			<Table>
				<thead>
					<TableRow>
						{ZENDESK_DISPLAY_COLUMNS.map((item, index)=> {
							return (
								<>
									{getColumnHeader(item.key, item.value, item, index)}
								</>
							);
						})}
						<th>
							<p className={styles.tableHeading}>
								CC REMARKS
							</p>
						</th>
						<th>
							<p className={styles.tableHeading}>
								RA REMARKS
							</p>
						</th>
					</TableRow>
				</thead>
				<TableBody>
					{!isLoading && !error && tickets.map((item, index)=> (
						<TableRow 
							key={`row-${index}`}
						>
							<>
								{ZENDESK_DISPLAY_COLUMNS.map((row, colIndex)=> (
									<>
										<TableColumn
											key={`col-${colIndex}`}
											item={item}
										>
											{getColumn(row.key, item[row.key])}
										</TableColumn>
									</>
								))}
								<TableColumn>
									<div key={`tc-${index}`}>
										{getActionColumn(
											item, "cc_remarks"
										)}
									</div>
								</TableColumn>
								<TableColumn>
								<div key={`tc-${index}`}>
									{getActionColumn(
										item, "ra_remarks"
									)}
								</div>
								</TableColumn>
							</>
						</TableRow>
					))}
				</TableBody>	
			</Table>
		);
	};
	return <HocAccessController>
		<div className={styles.dataList}>
			{renderTickets()}
		</div>
		<DataInformationMessage
			isLoading={isLoading}
			error={error}
			data={tickets}
		/>
	</HocAccessController>;
};

export default withLayout(ZendeskTickets, false);