/* eslint-disable indent */
import React, { useEffect, useState, } from "react";
import FilterLeadsCheckbox from "../../components/shared/filter-leads-checkbox/";
import styles from "./pll-lead-allocation.module.scss";
import SelectComponent from "../shared/select/component";
import { showToastMessages } from "../../utils/helper";
import { LOCAL_STORAGE_KEYS, NUMBERS } from "../../utils/app-constants";
import { getFromLocalStorage, getLoginData } from "../../utils/helper";
// Approach 1- We will create a reducer where we will push the selected store and based on that store we will
// fetch data for the pllleads allocation
const PAGE_SIZE = NUMBERS.FIFTEEN;

const PllStoreList = ({
  pllStores,//reducer
  fetchPllStoresConnect,
  updateStoreListForCheckConnect,
  fetchStoresAppointmentsConnect,
  resetPLLByKeyConnect,
  pllAllocationFilter
}) => {
  const { storeList, selectedStoreIds, TotalCount, cityEnabled, leadTypeFilter, pageNo } = pllStores;
  const [storeData, setStoreData] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [cityDropDown, setCityDropDown] = useState([]);
  const [isFetchingAppointments, setIsFetchingAppointments] = useState(false);
  const {query, isRetail} = pllAllocationFilter;
  useEffect(() => {
    const cityStoresList = getFromLocalStorage(LOCAL_STORAGE_KEYS.CITY_STORES_LIST);
    setStoreData(cityStoresList ? JSON.parse(cityStoresList) : []);
  }, []);

  useEffect(() => {
    let dropDown = [];
    cityList.map(city => {
      if (!!city) {
        dropDown.push({
          label: city,
          value: city
        });
      }
    });
    setCityDropDown(dropDown);
  }, [cityList]);

  useEffect(() => {
    if (storeData && cityList.length <= 0) {
      const cities = [...new Set(storeData.map(item => item.storeCity))];
      setCityList(cities);
    }
  }, [storeData]);

  useEffect(()=> {
    if (pageNo > NUMBERS.ONE && selectedStoreIds.length > NUMBERS.ZERO) {
      const _stores = selectedStoreIds.join(',');
      fetchData(pageNo, _stores);
    }
  }, [pageNo]);

  useEffect(()=> {
    if (selectedStoreIds.length > NUMBERS.ZERO && !!query) {
      const _stores = selectedStoreIds.join(',');
      fetchData(pageNo, _stores);
    }
  }, [query]);

  useEffect(()=>{
    if (selectedStoreIds && selectedStoreIds.length > NUMBERS.ZERO) {
      const _stores = [...selectedStoreIds].join(',');
      fetchData(pageNo, _stores);
    }
  }, [selectedStoreIds]);

  const updateSelectedStoreList = (idLmsStore) => {
    const newList = [...selectedStoreIds];
    if (newList.length === 3) {
      if (newList.find(store => store === idLmsStore)) {
        const i = newList.findIndex(store => store === idLmsStore);
        newList.splice(i, 1);
      }
      else {
        showToastMessages("You can only select 3 stores", false);
      }
      resetPLLByKeyConnect("selectedStoreIds", newList);
    }
    else {
      if (newList.find(store => store === idLmsStore)) {
        const i = newList.findIndex(store => store === idLmsStore);
        newList.splice(i, 1);
      } else {
        newList.push(idLmsStore);
      }
      resetPLLByKeyConnect("selectedStoreIds", newList);
    }
  };

  const onCheckboxHandler = (idLmsStore) => {
    updateStoreListForCheckConnect(idLmsStore);
    updateSelectedStoreList(idLmsStore);
    resetPLLByKeyConnect("pageNo", 1);
    resetPLLByKeyConnect("storeAppointmentList", []);
    resetPLLByKeyConnect("selectedAppointments", []);
    resetPLLByKeyConnect("storeId", idLmsStore);
    resetPLLByKeyConnect("loadMore", false);
  };

  const getUniqueCityStores = (value) => {
    let stores = storeData.filter(x => x.storeCity === value);
    return stores.map(item=> item.idLmsStore).join(',');
  };

  const onSelectCity = (value) => {
    setSelectedCity(value);
    const stores = getUniqueCityStores(value);
    fetchPllStoresConnect(stores);
    resetPLLByKeyConnect("storeIds", stores);
    resetPLLByKeyConnect("city", value);
    resetPLLByKeyConnect("selectedAppointments", []);
    resetPLLByKeyConnect("storeId", null);
    resetPLLByKeyConnect("raUser", "");
    resetPLLByKeyConnect("pageNo", 1);
    resetPLLByKeyConnect("selectedStoreIds", []);
    resetPLLByKeyConnect("storeAppointmentList", []);
    resetPLLByKeyConnect("loadMore", false);
  };

  const fetchData = (page, stores) => {
      let gsAssuredParam = '';
      if(leadTypeFilter === 1 || leadTypeFilter === 0){
        gsAssuredParam = `&isGSAssured=${leadTypeFilter}`;
      }
      if (query) {
        gsAssuredParam = `${gsAssuredParam}${query}`;
      }
	  if (isRetail) {
		gsAssuredParam = `${gsAssuredParam}&isRetail=true`;
	  }
      setIsFetchingAppointments(true);
      fetchStoresAppointmentsConnect(stores, gsAssuredParam, page, PAGE_SIZE).finally(()=>{
        setTimeout(()=> {
          setIsFetchingAppointments(false);
        }, [500]);
      });
  };

  return (
    <div className={styles.pllStoreList}>
      <div className={styles.storename}>
        <p>Store Name</p>
        <p className={styles.unalloctaed}>Unallocated Leads({TotalCount})</p>
      </div>
      <div className={styles.storeListWrapper}>
        <div className={styles.cityStoreList}>
          <div className={styles.cityName}>
            <SelectComponent
              optionsList={cityDropDown}
              placeholder="select"
              onChange={onSelectCity}
              isDisabled={cityEnabled}
            />
          </div>
          {
            selectedCity && storeList && storeList.map((item) => {
              return <div className={styles.storeList} key={item.idLmsStore}>
                <FilterLeadsCheckbox
                  id={`chk_${item.idLmsStore}`}
                  name={`chk_${item.idLmsStore}`}
                  onChangeCallback={() => onCheckboxHandler(item.idLmsStore)}
                  checkedStatus={!!selectedStoreIds.find(store => store === item.idLmsStore)}
                  isDisabled={isFetchingAppointments}
                />
                <p>{item.name}</p>
                {
                  item.unAllocatedPllLeadCount === 0 &&
                  <span className={styles.unAllocatedPllLeadCountZero}>0</span>
                }
                {
                  item.unAllocatedPllLeadCount !== 0 &&
                  <span className={styles.unAllocatedPllLeadCount}>{item.unAllocatedPllLeadCount}</span>
                }
              </div>;
            }
            )
          }
        </div>
      </div>
    </div>
  );
};

export default PllStoreList;
