import React, { useState, useEffect } from "react";
import styles from "./proposed-offer-price.module.scss";
import callIcon from "./images/callIcon.svg";
import responseSubmittedImg from "./images/responseSubmitted.svg";
import Checkbox from "../../../../components/shared/checkbox";
// import data from "./data.json";
import Loader from "../../../../components/shared/loader";
import priceFormatter from "../../../../utils/helpers/price-formatter";
import { API_ENDPOINTS } from "../../../../service/api-constants";
import { apiUrl } from "../../../../utils/url-constants";
import axios from "axios";
import CustomerSomethingWrong from "../../../../components/customer-something-wrong/component";
const BASE_API_URL = apiUrl();

const ProposedOfferPrice = () => {
  const queryString = window.location.search;
  const paramObject = new URLSearchParams(queryString);
  const token = paramObject.get('token');
  const tokenParam = `?token=${token}`;
  const [isPostingData, setIsPostingData] = useState(false);
  const [chargeActive, setChargeActive] = useState({});
  const [response, setResponse] = useState({});
  const [showErrorComponent, setShowErrorComponent] = useState(false);
  const [consumerConsent, setConsumerConsent] = useState(false);
  const headers =  { headers: {
    'Content-Type': 'application/json'
  }};

  const onChargeClick = (keyName) => {
    const chargeActiveObject = {...chargeActive};
    chargeActiveObject[keyName] = !chargeActiveObject[keyName];
    setChargeActive(chargeActiveObject);
  };

  useEffect(() => {
    const getApiURL = BASE_API_URL + API_ENDPOINTS.CUSTOMER_PROPOSED_OFFER_PRICE + tokenParam;
    // setResponse(data.data);
    axios.get(getApiURL, headers).then(response => {
      setResponse(response.data);
      setShowErrorComponent(false);
    }).catch(() => {
      setShowErrorComponent(true);
    });
  }, []);

  const fixedCharges = [];
  let totalFixedCharges = 0;
  const conditinalCharges = [];
  let totalConditinalCharges = 0;
  let totalHoldsCharges = 0;
  const holdCharges = [];
  const responseDetail = response?.detail?.charges || null;
  const appointmentId = response?.detail?.appointmentId || null;
  const { extra, charges, holds } = responseDetail || {};
  const tokenCharge = extra?.tokenAmount || 0;
  const totalOfferPrice = extra?.c24Quote || 0;
  const phone = response?.detail?.charges?.extra?.phone;

  charges && charges.forEach((charge, index) => {
    charge.index = index;
    if(charge.isApplicable && charge.category === "Conditional Charges") {
      if(!charge.isConsent) {
        totalConditinalCharges += charge.amount;
      }
      conditinalCharges.push(charge);
    }
    if(charge.isApplicable && charge.category === "Fixed Charges") {
      if(!charge.isConsent) {
        totalFixedCharges += charge.amount;
      }
      fixedCharges.push(charge);
    }
  });

  holds && holds.forEach((hold, index) => {
    hold.index = index;
    hold.isConditional = hold.category === 'Conditional Holdback';
    if(hold.isApplicable) {
      if(!hold.isConsent) {
        totalHoldsCharges += hold.amount;
      }
      holdCharges.push(hold);
    }
    
  });

  const approxPayableAmount = totalOfferPrice - totalConditinalCharges - totalFixedCharges;
  const payableNow = approxPayableAmount - totalHoldsCharges;
  const setConsent = index => {
    const responseData = {...response};
    const item = charges[index];
    item.isConsent = !item.isConsent;
    charges[index] = item;
    setResponse(responseData);
  };

  const setHoldConsent = index => {
    const responseData = {...response};
    const item = holds[index];
    item.isConsent = !item.isConsent;
    holds[index] = item;
    setResponse(responseData);
  };

  const onChangeHandler = event => {
    setConsent(event.target.name);
  };

  const onChangeHoldsHandler = event => {
    setHoldConsent(event.target.name);
  };

  const consumerConsentHandler = () => {
    setConsumerConsent(!consumerConsent);
  };

  const proceedHandler = () => {
    setIsPostingData(true);
    const postApiURL = BASE_API_URL + API_ENDPOINTS.CUSTOMER_PROPOSED_OFFER_PRICE;
    const requestParams = {
      appointmentId,
      token,
      acceptedPrice: totalOfferPrice
    };
    axios.post(postApiURL, requestParams, headers).then(response => {
      setIsPostingData(false);
      setResponse(response.data);
      setShowErrorComponent(false);
    }).catch(() => {
      setShowErrorComponent(true);
    });
  };

  const displayOfferPriceScreens = () => {
    if(response.detail.code === 0 || showErrorComponent){
      return(
        <CustomerSomethingWrong />
      );
    }
    else if(response.detail.code === 3 || response.detail.code === 4 ){
      return (
        <ImageWithDescripion 
          image={responseSubmittedImg}
          header={'Thank You For Your Response'}
          subHeader={'Our team will contact you once we get this price.'}
        />);
    }
    else if(response.detail.code === 2 || response.detail.code === 1){
      return (
        <ImageWithDescripion 
          image={responseSubmittedImg}
          header={response.detail.code === 2 ? 'This link has expired!' : 'The link is Invalid!'}
        />
      );
    }else if(response.detail.charges) {
      return (
        <>
          <div className={styles.darkBgTitle}>Our team will try to get this price for your car.</div>
          <div className={styles.priceTitle}>
            <span>Proposed Offer Price</span>
            <span>₹ {priceFormatter(totalOfferPrice, true)}</span>
          </div>
          <div className={`${styles.clickStripWrapper} ${chargeActive.fixed?styles.active: ''}`}>
            <div className={styles.clickStrip} onClick={()=> onChargeClick('fixed')}>
              <span className={styles.clickArrow}>Fixed Charges</span>
              <span>₹ {priceFormatter(totalFixedCharges, true)}</span>
            </div>
            <div className={styles.subData}>
              <ul>
                {
                  fixedCharges.map(fixedCharge => (
                    <li key={fixedCharge.index}>
                      <div className={styles.fixedLabel}>
                        <span>{fixedCharge.label}</span>
                        <span>₹ {priceFormatter(fixedCharge.amount, true)}</span>
                      </div>
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
          <div className={`${styles.clickStripWrapper} ${chargeActive.conditinal?styles.active: ''}`}>
            <div className={styles.clickStrip} onClick={()=> onChargeClick('conditinal')}>
              <span className={styles.clickArrow}>Conditional Charges</span>
              <span>₹ {priceFormatter(totalConditinalCharges, true)}</span>
            </div>
            <div className={styles.subData}>
              <ul>
                {
                  conditinalCharges.map(conditinalCharge => (
                    <li key={conditinalCharge.index}>
                      <div className={styles.conditinalLabel}>
                        <span className={conditinalCharge.isConsent ? styles.textThrough : '' }>{conditinalCharge.label}</span>
                        <span className={conditinalCharge.isConsent ? styles.textThrough : '' }>₹ {priceFormatter(conditinalCharge.amount, true) }</span>
                      </div>
                      <div className={styles.consentTextWrapper}>
                        {conditinalCharge.consentText &&(<Checkbox 
                          id={'c' + conditinalCharge.index}
                          name={conditinalCharge.index} 
                          onChangeCallback={onChangeHandler} 
                          checkedStatus={conditinalCharge.isConsent}
                        />)}
                        <span className={styles.consentText}>{conditinalCharge.consentText}</span>
                      </div>
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
                    
          <div className={styles.priceTitle}>
            <span>Approx. Payable Amount</span>
            <span>₹ {priceFormatter(approxPayableAmount, true)}</span>
          </div>
          <div className={`${styles.clickStripWrapper} ${chargeActive.payable ? styles.active: ''}`}>
            <div className={styles.clickStrip}>
              <span>Payable Now</span>
              <span>₹ {priceFormatter(payableNow, true)}</span>
            </div>
            <div className={styles.clickStrip} onClick={()=> onChargeClick('payable')}>
              <span className={styles.clickArrow}>Payable Later</span>
              <span>₹ {priceFormatter(totalHoldsCharges, true)}</span>
            </div>
            <div className={styles.subDataDiscount}>
              <ul>
                {holdCharges.map(hold => (
                  <li key={hold.index}>
                    <div className={styles.conditinalLabel}>
                      <span className={hold.isConsent ? styles.textThrough : '' }>{hold.label}</span>
                      <span className={hold.isConsent ? styles.textThrough : '' }>₹ {priceFormatter(hold.amount, true)}</span>
                    </div>
                    <div className={styles.consentTextWrapper}>
                      { hold.isConditional && (<Checkbox 
                        id={'hold'+hold.index}
                        name={hold.index}
                        onChangeCallback={onChangeHoldsHandler} 
                        checkedStatus={hold.isConsent}
                      />)}
                      <span className={styles.consentText}>{hold.consentText}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className={styles.tncCheckWrapper}>
            <Checkbox 
              id="consumerConsent"
              name="consumerConsent"
              onChangeCallback={consumerConsentHandler}
              checkedStatus={consumerConsent}
            />
            <p>I have gone through the  <a target="_blank" href={responseDetail.tncLink} rel="noreferrer">Proposed price, Charges & Holdbacks</a> and would like to sell my car if I get the proposed offer.</p>
          </div>
          <div className={styles.chargesCtaWrapper}>
            <ul>
              {/* <li >
                                <span className={styles.smallSize}>Charges *</span>
                                <span>₹ {priceFormatter(tokenCharge, true)}</span>
                            </li> */}
              <li>
                <span>Payable Now</span>
                <span>₹ {priceFormatter(payableNow, true)}</span>
              </li>
              <li>
                <span>Payable Later</span>
                <span>₹ {priceFormatter(totalHoldsCharges, true)}</span>
              </li>
            </ul>
            <button className={ consumerConsent ? styles.proceedBtn : styles.proceedBtnDisabled} disabled={!consumerConsent} onClick={proceedHandler}>Proceed</button>
            <small>* These are estimated prices. Our team will contact you with the final offer.</small>
          </div>
        </>
      );
    }
  };

  return(
    <div className={styles.customerConsentCharges}>
      <div className={styles.customerConsentChargesHeader}>
        <p>Proposed Offer Price</p>
        {phone && (<div className={styles.callSupport}>
          <a href={'tel:'+phone}>
            <img src={callIcon} alt="call icon" />
            <span>Support</span>
          </a>
        </div>)}
      </div>
      { showErrorComponent ? 
        <CustomerSomethingWrong /> :
                (isPostingData || !response?.detail) ? <Loader /> : displayOfferPriceScreens()
      }
    </div>
  ); 
};

export default ProposedOfferPrice;

const ImageWithDescripion = ({ image, header, subHeader }) => {
  return(
    <div className={styles.thankYouContainer}>
      <img src={image} alt={header} />
      <p className={styles.header}>{header}</p>
      <p className={styles.subHeader}>{subHeader}</p>
    </div>
  );
};