import events from "./events";

const sendEvent = (data) => {
  window.dataLayer.push({ ...data });
};

const trackCustomEvents = ({ lmsEvents, category }, extras) => {
  const data = { ...extras, ...lmsEvents[category] };
  sendEvent(data);
};

const trackLMSEvents = (category, extras = {}) => {
  trackCustomEvents({
      lmsEvents: events, category
  }, extras);
};

export {
  trackLMSEvents
};
