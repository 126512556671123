import React, { useState } from "react";
import styles from "./quote-popup.module.css";
import H2 from "../h2/component";
import InputText from "../shared/input-text/component";
import Button from "../shared/button/component";
import { ERROR_MESSAGE, LOCAL_STORAGE_KEYS, APP_SOURCE } from "../../utils/app-constants";
import { AppointmentService } from "../../service";
import { showToastMessages, onNumberKeyPress, getUid, saveToLocalStorage, getFromLocalStorage } from "../../utils/helper";
import { trackLMSEvents } from "../../utils/helpers/tracking-events";

const C24QuotePopup = ({ negotiations, close, resetListing }) => {

  const { data, currentAppointmentIndex } = negotiations;
  const currentAppointment = data[currentAppointmentIndex];
  console.log("currentAppointment", currentAppointment);
  const { customerName, make, model, year, customerPhone, appointmentId, isDepReportAvailable, isDepReportViewed, isInventoryBuying } = currentAppointment;

  const [changeNumber, setChangeNumber] = useState(false);
  const [otp, setOtp] = useState("");
  const [otherNumber, setOtherNumber] = useState("");
  const [otherNumberError, setOtherNumberError] = useState(false);
  const [otpError, setOtpError] = useState(false);

  const onChangeClick = () => {
    setChangeNumber(true);
  };

  const sendOtp = () => {
    let isValid = true;
    if (changeNumber) {
      isValid = validateOtherNumber();
    }
    if (isValid) {
      const params = {
        "appointmentId": appointmentId,
        "otherNumber": changeNumber ? otherNumber : "",
        "inventoryBuying": isInventoryBuying
      };
      AppointmentService.sendOtp(params)
        .then(response => {
          const { data: { detail: { message } } } = response;
          showToastMessages(message);
        }).catch(error => {
          const { detail: { message } } = error;
          showToastMessages(message, false);
        });
    }
  };

  const onOtpChange = (e) => {
    const { target: { value } } = e;
    setOtp(value);
  };

  const onOtherNumberChange = (e) => {
    const { target: { value } } = e;
    setOtherNumber(value);
  };

  const validateOtp = () => {
    if (otp.length === 4) {
      setOtpError(false);
      return true;
    } else {
      setOtpError(true);
      return false;
    }
  };

  const validateOtherNumber = () => {
    if (otherNumber.length === 10) {
      setOtherNumberError(false);
      return true;
    } else {
      setOtherNumberError(true);
      return false;
    }
  };

  const validateData = () => {
    if (changeNumber) {
      const isValidOtp = validateOtp();
      const isValidNumber = validateOtherNumber();
      return isValidOtp && isValidNumber;
    } else {
      return validateOtp();
    }
  };

  const saveTimeStamps = () => {
    const currentTime = new Date();
    const timesStamps = getFromLocalStorage(LOCAL_STORAGE_KEYS.QUOTE_TIMESTAMPS) ?
            JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.QUOTE_TIMESTAMPS)) : [];
    const newList = [...timesStamps];
    if (newList.length > 0) {
      const timeStamp = newList.find(quote => quote.appointmentId === appointmentId);
      if (!timeStamp) {
        newList.push({
          appointmentId: appointmentId,
          unlockotpTime: currentTime,
          eventSent: false
        });
        saveToLocalStorage(LOCAL_STORAGE_KEYS.QUOTE_TIMESTAMPS, JSON.stringify(newList));
      }
    } else {
      const newData = [];
      newData.push({
        appointmentId: appointmentId,
        unlockotpTime: currentTime,
        eventSent: false
      });
      saveToLocalStorage(LOCAL_STORAGE_KEYS.QUOTE_TIMESTAMPS, JSON.stringify(newData));
    }
  };

  const openDepReport = () => {
    let showreport = "charge";
    if (isDepReportAvailable && !isDepReportViewed) {
      showreport = "dep";
    }
    var url = "/customer-journey/" + appointmentId + "?showreport=" + showreport + "&isDepReportAvailable=" + isDepReportAvailable + "&isDepReportViewed=" + isDepReportViewed + "&pagesource=negotiation";
    window.open(url, "_blank", 'noopener');
  };

  const getQuote = () => {
    const isValid = validateData();
    if (isValid) {
      const params = {
        "appointmentId": appointmentId,
        "otp": otp
      };
      AppointmentService.verifyOtp(params)
        .then(response => {
          const { data: { detail: { message } } } = response;
          // trackLMSEvents("clickGetQuote", {
          //     eventAction: "Successful",
          //     eventLabel: appointmentId,
          //     lsRAID: getUid()
          // });
          trackLMSEvents("ls_GetQuote", {
            eventLabel: appointmentId,
            lsRAID: getUid(),
            Timestamp: new Date()
          });
          saveTimeStamps();
          showToastMessages(message);
          setTimeout(() => {
            const selectedBaseAPIHostKey = localStorage.getItem(LOCAL_STORAGE_KEYS.SELECTED_BASE_API_HOST);
            if (selectedBaseAPIHostKey !== APP_SOURCE.BIKE) {
              openDepReport();
            } 
            close();
            resetListing();
          }, 3000);

        }).catch(error => {
          const { detail: { message } } = error;
          trackLMSEvents("clickGetQuote", {
            eventAction: "Failure",
            eventLabel: appointmentId,
            lsRAID: getUid()
          });
          showToastMessages(message, false);
        });
    }
  };

  if (!currentAppointment) return <React.Fragment />;

  return (
    <div className={styles.popupWrap}>
      <H2 text="Unlock C24 Quote" />
      <ul className={styles.customerDesc}>
        <li>{customerName}</li>
        <li>{`${make} ${model}`}</li>
        <li>{year}</li>
      </ul>
      <p className={styles.otpDetail}>
        Get the OTP from the customer to unlock the C24 quote</p>
      {changeNumber &&
      <div className={styles.inputPhone}>
        <InputText text="Phone"
          value={otherNumber}
          maxLength={10}
          onChange={onOtherNumberChange}
          onKeyPress={onNumberKeyPress}
        />
        {/* <p className={styles.saveText}>Save</p> */}
        {otherNumberError &&
        <span className={styles.errorText}>{ERROR_MESSAGE.OTHER_NUMBER}</span>
        }
      </div>
      }
      {!changeNumber &&
      <div className={styles.changenumberWrap}>
        <p>Phone</p>
        <div className={styles.phoneNumberChange}>
          <p>{`+91-${customerPhone}`}</p>
          <button onClick={onChangeClick}>Change</button>
        </div>
      </div>
      }
      <div className={styles.otpWrap}>
        <div className={styles.resendOtp}>
          <p className={styles.sendOTP} onClick={sendOtp}>{`${changeNumber ? 'Send' : 'Resend'}`} OTP</p>
        </div>
        <div className={styles.inputOtp}>
          <InputText
            text="Enter OTP"
            value={otp}
            placeholder="_ _ _ _"
            maxLength={4}
            onChange={onOtpChange}
            onKeyPress={onNumberKeyPress}
          />
          {otpError &&
          <span className={styles.errorText}>{ERROR_MESSAGE.OTP}</span>
          }
        </div>
      </div>
      <div className={styles.getQuote}>
        <Button classNames="blueButton" ctaText="Get Quote" onClick={getQuote} />
        <button className={styles.cancelCta} onClick={close}>cancel</button>
      </div>
    </div>
  );
};

export default C24QuotePopup;
