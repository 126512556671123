import { createTypes } from "reduxsauce";

export default createTypes(
  `
    UPDATE_FILTER_QUERY
    NEGO_DATA_WITH_CUSTOMER
    NEGO_DATA_WITH_CUSTOMER_COUNT
    NEGO_DATA_ACTION_REQUIRED_COUNT
    NEGO_DATA_MISSED_CALL_QUERY
`,
  {
    prefix: "negotiationfilter/"
  }
);
