/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import React, { useEffect, useState, useRef } from "react";
import MultiSelect from "../../components/shared/multi-select";
import {
	APPOINTMENT_TYPES_STRING,
	NUMBERS,
	RESET_REDUCER_STATE_KEYS,
	LOCAL_STORAGE_KEYS,
	TABLE_COLUMNS,
	ROLE_KEYS,
} from "../../utils/app-constants";
import H2 from "../../components/h2";
import { PAGE_HEADING } from "../../utils/app-constants";
import styles from "./appointment-page.module.css";
import Search from "../../components/search";
import Table from "../../components/shared/table";
import TableBody from "../../components/shared/table-body";
import TableRow from "../../components/shared/table-row";
import TableColumn from "../../components/shared/table-column";
import TableHeader from "../../components/shared/table-header";
import ArrowIcon from "../../components/shared/arrow";
import { Link } from "react-router-dom";
import CheckInDeny from "../../components/check-in-deny";
import {
	getAppointmentTypeFromPathName,
	getTableColumnTypeByKey,
	ColumnTypeMapping,
	getColumnComponent,
	getLoginData,
	isSelectedStorePHomeInspectionStore,
	getFromLocalStorage,
	IsConfirmedNotConfirmedViewOfSchedule,
	isBikeSource,
	getUniqueStoreForCities,
} from "../../utils/helper";
import DataInformationMessage from "../../components/shared/data-information-message";
import Filters from "../../components/filters";
import LostLeadsList from "../../components/lost-leads-list";
import { AppointmentService } from "../../service";
import Popup from "../../components/popup";
import AllocateCjPopup from "../../components/allocate-cj-popup";
import TableHeaderWithControl from "../../components/shared/table-header-with-control";
import CjAllocationStatus from "../../components/cj-allocation-status";
import CcConfirmLeads from "../../components/cc-confirm-leads";
import ReviewInspection from "../../components/review-inspection";
import PaymentProcessingFilter from "../../components/payment-processing-filter";
import Button from "../../components/shared/button";
import Reschedule from "../../components/reschedule/component";
import popups from "../../utils/popup-constants";
import HocAccessController from "../../components/hoc-access-controller";
// import {getAppointmentDetails} from "../../utils/mocApi";
import AppointmentDetailCard from "../../components/appointment-detail-card";
import SelectComponent from "../../components/shared/select";
import Loader from "../../components/shared/loader";
import LeadMonAssignRa from "../../components/leadmon-assign-ra/component";
import priceFormatter from "../../utils/helpers/price-formatter";
const AppointmentPage = ({
	location,
	fetchAppointmentsConnect,
	appointments,
	resetAppointmentByKeyConnect,
	resetLeadMenuByKeyConnect,
	leadMenu,
	search,
	visitedFilters,
	globalSearch,
	resetGlobalSearchConnect,
	resetAppointmentsConnect,
	homeInspectionFilters,
	zoneFilters,
	sortAppointmentByKeyConnect,
	headerRowFilter,
	homeInspectionConfirmLead,
	reviewInspection,
	fetchAppointmentReviewInspectionsConnect,
	paymentProcessingFilter,
	resetApiNoAccessConnect,
	cjAllocationStatus,
	fetchCjAllocationStatusConnect,
	updateFilterQueryConnect,
	updateHeaderRowFilterQueryConnect,
}) => {
	const {
		appointmentState,
		columns,
		data,
		page,
		isFiltered,
		filteredData,
		isLoading,
		error,
		sortKey,
		sortOrder,
		confirmRows,
		nonConfirmRows,
		loadMore,
		insuranceType,
		prStatuses,
		retailAssociates,
		selectedRAs: selected_reatil_associates,
	} = appointments;
	const [dataLoading, setDataLoading] = useState(false);
	const [rowClickLoader, setRowClickLoader] = useState(false);
	const { filterQuery } = visitedFilters;
	const [visibleColumns, setVisibleColumns] = useState([]);
	const [renderedData, setRenderedData] = useState([]);
	const [hasMoreItems, setHasMoreItems] = useState(true);
	const [showActions, setShowActions] = useState(true);
	const [currentPopup, setCurrentPopup] = useState({
		popup: "",
		currentRowDetails: {},
	});
	const scrollRef = useRef();
	const itemRef = useRef();
	const [lastItemIndex, setLastItemIndex] = useState(9);
	const { pathname } = location;
	const appointmentType = getAppointmentTypeFromPathName(pathname);
	const { resetAndFetchData } = leadMenu;
	const { query } = search;
	const { globalQuery } = globalSearch;
	const { hisfilterQuery } = homeInspectionFilters;
	const { confirmLeadsFilterQuery } = homeInspectionConfirmLead;
	const { reviewInspectionFilterQuery } = reviewInspection;
	const { paymentProcessingFilterQuery } = paymentProcessingFilter;
	const homeInspectionStore = isSelectedStorePHomeInspectionStore(); //isPHomeInspectionStore();
	const { zonefilterQuery } = zoneFilters;
	const { headerRowFilterQuery } = headerRowFilter;
	const isHIConfigured = IsConfirmedNotConfirmedViewOfSchedule();
	const cjData = getFromLocalStorage(LOCAL_STORAGE_KEYS.CJ_LIST);
	const raData = getFromLocalStorage(LOCAL_STORAGE_KEYS.RA_LIST);
	const [currentPageNo, setCurrentPageNo] = useState(1);
	const isBike = isBikeSource();
	const { originalRole = "", isHierarchy } = getLoginData();
	const [activeIndex, setActiveIndex] = useState();
	const [appointmentData, setAppointmentData] = useState();
	const [prStatusFilterQuery, setPrStatusFilterQuery] = useState("");
	const [raFilterQuery, setRaFilterQuery] = useState();
	const [selectedRetailAssociates, setSelectedRetailAssociates] = useState([]);
	const isLeadMonFilter = reviewInspectionFilterQuery === "LeadMon";
	const isCJM =
		[ROLE_KEYS.CJM, ROLE_KEYS.A2I_HEAD, ROLE_KEYS.FOOTFALL_MANAGER].includes(
			originalRole
		) && isHierarchy;
	let loadKey = "";
	const { cjAllocationStausList = [] } = cjAllocationStatus || {};
	useEffect(() => {
		if (itemRef.current && currentPageNo > 1) {
			itemRef.current.scrollIntoView({
				behavior: "auto",
				block: "end",
			});
		}
		setLastItemIndex(data.length - 1);
	}, [data]);

	useEffect(() => {
		if (
			selected_reatil_associates &&
			selected_reatil_associates.length > NUMBERS.ZERO
		) {
			setSelectedRetailAssociates(selected_reatil_associates);
		}
	}, [selected_reatil_associates]);

	useEffect(() => {
		resetApiNoAccessConnect();
		resetAppointmentByKeyConnect(
			RESET_REDUCER_STATE_KEYS.APPOINTMENT_STATE,
			appointmentType
		);
		!cjAllocationStausList?.length && updateCjAllocationStatus();
		setCurrentPageNo(1);
		return () => {
			resetAppointmentsConnect();
		};
	}, []);

	useEffect(() => {
		resetAppointmentByKeyConnect(
			RESET_REDUCER_STATE_KEYS.APPOINTMENT_STATE,
			appointmentType
		);
		setHasMoreItems(true);
	}, [pathname]);

	useEffect(() => {
		checkShowActions();
		setCurrentPageNo(1);
		setVisibleColumns([]);
		updateFilterQueryConnect(null);
		updateHeaderRowFilterQueryConnect("");
		resetAndFetchNewListing("appointmentState");
	}, [appointmentState]);

	useEffect(() => {
		if (resetAndFetchData) {
			resetLeadMenuByKeyConnect(
				RESET_REDUCER_STATE_KEYS.RESET_AND_FETCH_DATA,
				false
			);
			resetAndFetchNewListing("resetAndFetchData");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resetAndFetchData]);

	useEffect(() => {
		if (query !== null) {
			resetAndFetchNewListing("query");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query]);

	useEffect(() => {
		if (globalQuery) {
			resetAndFetchNewListing("globalQuery");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [globalQuery]);

	useEffect(() => {
		if (filterQuery !== null) {
			resetAndFetchNewListing("filterQuery");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterQuery]);
	useEffect(() => {
		if (hisfilterQuery !== null) {
			resetAndFetchNewListing("hisfilterQuery");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hisfilterQuery]);
	useEffect(() => {
		if (confirmLeadsFilterQuery !== null) {
			resetAndFetchNewListing("confirmLeadsFilterQuery");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [confirmLeadsFilterQuery]);
	useEffect(() => {
		if (reviewInspectionFilterQuery != null) {
			resetAndFetchNewListing("reviewInspectionFilterQuery");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reviewInspectionFilterQuery]);
	useEffect(() => {
		if (zonefilterQuery !== null) {
			resetAndFetchNewListing("zonefilterQuery");
		}
	}, [zonefilterQuery]);
	useEffect(() => {
		if (headerRowFilterQuery !== null) {
			resetAndFetchNewListing("headerRowFilterQuery");
		}
	}, [headerRowFilterQuery]);
	useEffect(() => {
		resetAndFetchNewListing("paymentProcessingFilterQuery");
	}, [paymentProcessingFilterQuery]);

	useEffect(() => {
		resetAndFetchNewListing();
	}, [prStatusFilterQuery]);

	useEffect(() => {
		resetAndFetchNewListing();
	}, [raFilterQuery]);

	useEffect(() => {
		// setShowControlOnHeader(false);
		if (columns.length > 0) {
			let shownColumns = [];
			shownColumns = columns
				.filter(
					(columnItem) => columnItem.isVisible !== NUMBERS.ZERO.toString()
				)
				.map((item) => {
					return {
						key: item.key,
						value: item.value,
						isSortable: item.isSortable,
						controlType: item.controlType,
						options: item.options,
					};
				});
			setVisibleColumns(shownColumns);
		}
	}, [columns]);

	useEffect(() => {
		if (isFiltered) {
			setRenderedData(filteredData);
		} else {
			setRenderedData(data);
		}
	}, [data, filteredData, isFiltered]);

	const paginationLoader = (page) => {
		page > 1 ? setDataLoading(true) : setDataLoading(false);
	};

	const updateCjAllocationStatus = () => {
		const cityStores = getUniqueStoreForCities();
		const filterQuery = "?storeId=" + cityStores;
		fetchCjAllocationStatusConnect(filterQuery);
	};

	const getHomeInspectionFilterQuery = () => {
		let homeInsFilterQery = "";
		if (appointmentType === APPOINTMENT_TYPES_STRING.PAYMENT_PROCESSING) {
			homeInsFilterQery = "&isHomeInsp=true";
		} else if (appointmentType === APPOINTMENT_TYPES_STRING.SCHEDULED) {
			homeInsFilterQery = ""; //"&isHomeInsp=true";
			if (!!hisfilterQuery) {
				if (hisfilterQuery === "init") {
					homeInsFilterQery = "&leadDays=1";
				} else {
					homeInsFilterQery = hisfilterQuery;
				}
			} else {
				homeInsFilterQery = "&leadDays=1";
			}
			if (isHIConfigured) {
				if (!!confirmLeadsFilterQuery) {
					if (confirmLeadsFilterQuery === "init") {
						homeInsFilterQery = homeInsFilterQery + "&leadConfirmed=1";
					} else {
						homeInsFilterQery = homeInsFilterQery + confirmLeadsFilterQuery;
					}
				} else {
					homeInsFilterQery = homeInsFilterQery + "&leadConfirmed=1";
				}
			}
		} else if (appointmentType === APPOINTMENT_TYPES_STRING.LEAD_MON) {
			if (
				reviewInspectionFilterQuery === "assigned" ||
				!reviewInspectionFilterQuery
			) {
				homeInsFilterQery = `&leadType=assigned`;
			} else if (reviewInspectionFilterQuery === "bought") {
				homeInsFilterQery = `&leadType=bought`;
			} else if (reviewInspectionFilterQuery === "platform") {
				homeInsFilterQery = `&leadType=platform`;
			}
		}
		// }
		return homeInsFilterQery;
	};
	const getHomeInspectionSortQuery = () => {
		let sortFilterQuery = null;
		if (appointmentType === APPOINTMENT_TYPES_STRING.SCHEDULED) {
			if (!!sortKey) {
				sortFilterQuery = "&sortColumn=" + sortKey;
				if (!!sortOrder) {
					sortFilterQuery = sortFilterQuery + "&sortOrder=" + sortOrder;
				} else {
					sortFilterQuery = sortFilterQuery + "&sortOrder=asc";
				}
			}
		}
		return sortFilterQuery;
	};

	const updatePendingReviewList = () => {
		resetAndFetchNewListing("updatePendingReviewList");
	};

	const updateDataList = (appointmentId) => {
		if (isCJM) {
			resetAndFetchNewListing("cjmrefrsh");
		} else {
			const newList = [...data];
			const currentNegoIndex = newList.findIndex(
				(app) => app.appointment === appointmentId
			);
			let homeInsFilterQery = getHomeInspectionFilterQuery();
			let sortFilterQuery = getHomeInspectionSortQuery();
			AppointmentService.getAppointments({
				type: appointmentState,
				page: 1,
				filterQuery,
				searchQuery: appointmentId,
				hisFilterQuery: homeInsFilterQery,
				zoneQuery: zonefilterQuery,
				sortFilterQuery,
				headerRowFilterQuery,
			})
				.then((resp) => {
					if (resp.data) {
						const {
							data: {
								detail: { result },
							},
						} = resp;
						if (result.length > 0) {
							const appData = result.find(
								(appoint) => appoint.appointment === appointmentId
							);
							if (appData) {
								newList[currentNegoIndex] = appData;
								resetAppointmentByKeyConnect(
									RESET_REDUCER_STATE_KEYS.DATA,
									newList
								);
							}
						}
					}
				})
				.catch((error) => console.log(error));
		}
	};

	const loadMoreAppointments = (page) => {
		if (appointmentState && appointmentType === appointmentState) {
			paginationLoader(page);
			setHasMoreItems(false);
			const finalQuery = getFinalSearchQuery();
			const homeInsFilterQery = getHomeInspectionFilterQuery();
			const sortFilterQuery = getHomeInspectionSortQuery();
			if (
				reviewInspectionFilterQuery === "pendingreviews" &&
				appointmentState === APPOINTMENT_TYPES_STRING.INSPECTED
			) {
				fetchAppointmentReviewInspectionsConnect(page).then((resp) => {
					bindAppointmentsData(resp);
				});
			} else if (isLeadMonFilter) {
				fetchAppointmentsConnect({
					type: appointmentState,
					leadMon: 1,
					page,
					searchQuery: finalQuery,
				}).then((resp) => {
					bindAppointmentsData(resp);
				});
			} else if (
				appointmentState === APPOINTMENT_TYPES_STRING.PAYMENT_PROCESSING
			) {
				let type = appointmentState;
				if (isHierarchy) {
					type = "payment-processing-detail";
				}
				fetchAppointmentsConnect({
					type,
					page,
					filterQuery,
					searchQuery: finalQuery,
					hisFilterQuery: homeInsFilterQery,
					zoneQuery: zonefilterQuery,
					sortFilterQuery,
					headerRowFilterQuery,
					paymentProcessingFilterQuery,
					prStatusFilterQuery,
					raFilterQuery,
				}).then((resp) => {
					bindAppointmentsData(resp);
				});
			} else {
				fetchAppointmentsConnect({
					type: appointmentState,
					page,
					filterQuery,
					searchQuery: finalQuery,
					hisFilterQuery: homeInsFilterQery,
					zoneQuery: zonefilterQuery,
					sortFilterQuery,
					headerRowFilterQuery,
				}).then((resp) => {
					bindAppointmentsData(resp);
				});
			}
		}
	};
	// refactored for duplicate code
	const bindAppointmentsData = (response) => {
		if (response && response.data) {
			const {
				data: {
					detail: { result },
				},
			} = response;
			if (result.length === NUMBERS.TEN) {
				setHasMoreItems(true);
			}
			if (globalQuery) {
				resetGlobalSearchConnect();
			}
		}
		setDataLoading(false);
	};

	const getFinalSearchQuery = () => {
		if (globalQuery) {
			return globalQuery;
		} else {
			return query;
		}
	};

	const resetAndFetchNewListing = (key) => {
		if (!loadKey) {
			loadKey = key;
			setHasMoreItems(true);
			resetAppointmentByKeyConnect(RESET_REDUCER_STATE_KEYS.IS_LOADING, true);
			resetAppointmentByKeyConnect(RESET_REDUCER_STATE_KEYS.DATA, []);
			setCurrentPageNo(1);
			loadMoreAppointments(1);
		}
	};

	const getPageHeading = () => {
		switch (appointmentState) {
			case APPOINTMENT_TYPES_STRING.SCHEDULED:
				return <H2 text={PAGE_HEADING.SCHEDULED} />;
			case APPOINTMENT_TYPES_STRING.VISITED:
				return <H2 text={isCJM ? "Allocated" : PAGE_HEADING.VISITED} />;
			case APPOINTMENT_TYPES_STRING.INSPECTED:
				return <H2 text={PAGE_HEADING.INSPECTED} />;
			case APPOINTMENT_TYPES_STRING.CLEARANCE_PENDING:
				return <H2 text={PAGE_HEADING.CLEARANCE_PENDING} />;
			case APPOINTMENT_TYPES_STRING.PAYMENT_PROCESSING:
				return <H2 text={PAGE_HEADING.PAYMENT_PROCESSING} />;
			case APPOINTMENT_TYPES_STRING.LEAD_MON:
				return <H2 text={PAGE_HEADING.LEAD_MON} />;
			default:
				break;
		}
	};

	const getColumn = (key, value, rowDetails, index, isPPRevamp) => {
		const tableType = getTableColumnTypeByKey(key);
		const isApptIdLink = false;
		// appointmentState === APPOINTMENT_TYPES_STRING.LEAD_MON &&
		// reviewInspectionFilterQuery === "all_leads";
		switch (tableType) {
			case ColumnTypeMapping.WRAPPED_STRING:
				return <div className={styles.warppedText}>{value}</div>;
			case ColumnTypeMapping.STRING:
				return <span className={styles.modleName}>{value}</span>;
			case ColumnTypeMapping.DASH_STRING:
				return (
					<span className={styles.modleName}>{!!value ? value : "-"}</span>
				);
			case ColumnTypeMapping.LINK:
				return (
					<Link className={styles.dataId} to="#">
						{value}
					</Link>
				);
			case ColumnTypeMapping.PRICE_STRING:
				return <span className={styles.modleName}>{priceFormatter(value)}</span>;
			case ColumnTypeMapping.COMPONENT:
				return getColumnComponent(
					key,
					rowDetails,
					scrollRef,
					setHasMoreItems,
					index,
					cjData,
					updateDataList,
					raData,
					appointmentState,
					updatePendingReviewList,
					insuranceType,
					resetAndFetchNewListing,
					isPPRevamp,
					isApptIdLink,
					isLeadMonFilter
				);
			default:
				break;
		}
	};

	const getColumnHeader = (key, value, item, index) => {
		if (item.controlType === "dd") {
			return (
				<TableHeaderWithControl
					key={index}
					headerKey={item.key}
					headerValue={item.value}
					isSort={item.isSortable === 1 ? true : false}
					onSortClick={onSortClick}
					sortOrder={sortOrder}
					sortKey={sortKey}
					ddValues={item.options}
					appointmentState={appointmentState}
				/>
			);
		} else {
			return (
				<TableHeader
					key={index}
					headerKey={item.key}
					headerValue={item.value}
					isSort={item.isSortable === 1 ? true : false}
					onSortClick={onSortClick}
					sortOrder={sortOrder}
					sortKey={sortKey}
				/>
			);
		}
	};
	const renderSerialNumberColumn = (index) => {
		return <span>{page * NUMBERS.TEN + index + 1}</span>;
	};

	const getSerialNumber = (index) => {
		return page * NUMBERS.TEN + index + 1;
	};
	const resetCurrentPopUp = () => {
		setCurrentPopup({ popup: "", currentRowDetails: {} });
	};
	const renderColumn = (rowDetails, columnName) => {
		switch (appointmentState) {
			case APPOINTMENT_TYPES_STRING.VISITED:
				if (columnName === TABLE_COLUMNS.VISITED_ACTION.columnName) {
					if (isBike) {
						return (
							<div className={styles.visitedAction}>
								<span
									className={styles.link}
									onClick={() =>
										setCurrentPopup({
											popup: popups.ALLOCATE_CJ,
											currentRowDetails: rowDetails,
										})
									}>
									{!rowDetails.homeInspectionCj
										? "ALLOCATE CJ"
										: "RE-ALLOCATE CJ"}
								</span>
								<Reschedule
									key={rowDetails.appointment}
									rowDetails={rowDetails}
									scrollRef={scrollRef}
									setHasMoreItems={setHasMoreItems}
								/>
							</div>
						);
					} else {
						return (
							<CheckInDeny
								key={rowDetails.appointment}
								rowDetails={rowDetails}
								scrollRef={scrollRef}
								setHasMoreItems={setHasMoreItems}
								resetData={resetAndFetchNewListing}
							/>
						);
					}
				}
				break;
			case APPOINTMENT_TYPES_STRING.INSPECTED:
				if (
					columnName === TABLE_COLUMNS.INSPECTED_LEADMON_ACTION.columnName &&
					isLeadMonFilter
				) {
					return (
						<LeadMonAssignRa
							rowDetails={rowDetails}
							resetAndFetchNewListing={resetAndFetchNewListing}
						/>
					);
				}
				break;
			default:
				break;
		}
	};
	const checkShowActions = () => {
		switch (appointmentState) {
			case APPOINTMENT_TYPES_STRING.VISITED:
				setShowActions(true);
				break;
			default:
				setShowActions(false);
				break;
		}
	};

	const showSerialNumberColumn = () => {
		if (
			appointmentState &&
			appointmentState === APPOINTMENT_TYPES_STRING.VISITED
		) {
			if (
				homeInspectionStore &&
				appointmentState === APPOINTMENT_TYPES_STRING.SCHEDULED
			) {
				return false;
			} else {
				return true;
			}
		} else {
			return false;
		}
	};
	const onSortClick = (key) => {
		let defaultSortOrder = "asc";
		if (sortOrder === "asc") {
			defaultSortOrder = "desc";
		}
		// resetAndFetchNewListing();
		sortAppointmentByKeyConnect(key, defaultSortOrder);
		resetAndFetchNewListing();
	};
	const onLoadMore = () => {
		let i = currentPageNo;
		setCurrentPageNo(i + 1);
		loadMoreAppointments(i + 1);
	};
	const renderAppointments = () => (
		<div className={styles.appointmentList}>
			<Table>
				{visibleColumns.length > 0 && (
					<thead>
						<TableRow>
							{showSerialNumberColumn() && (
								<th>
									<p className={styles.tableHeading}>S No.</p>
								</th>
							)}
							{visibleColumns.map((item, index) => {
								return (
									<>{getColumnHeader(item.key, item.value, item, index)}</>
								);
							})}
							{(showActions && !isCJM) ||
								(isLeadMonFilter && (
									<th>
										<p className={styles.tableHeading}>
											{TABLE_COLUMNS.VISITED_ACTION.label}
										</p>
									</th>
								))}
						</TableRow>
					</thead>
				)}
				<TableBody>
					{!isLoading &&
						!error &&
						renderedData.map((item, index) => (
							<TableRow
								key={`row-${index}`}
								tableRowRef={index === lastItemIndex ? itemRef : null}>
								<>
									{showSerialNumberColumn() && (
										<TableColumn key={`s-no-${index}`}>
											{renderSerialNumberColumn(index)}
										</TableColumn>
									)}
									{visibleColumns.map((visCol, visColIndex) => {
										return (
											<TableColumn key={`col-${visColIndex}`} item={item}>
												{getColumn(
													visCol.key,
													item[visCol.key],
													item,
													getSerialNumber(index)
												)}
											</TableColumn>
										);
									})}
									{showActions && !isCJM && (
										<TableColumn>
											<div key={`tc-${index}`}>
												{renderColumn(
													item,
													TABLE_COLUMNS.VISITED_ACTION.columnName
												)}
											</div>
										</TableColumn>
									)}
									{!isCJM && isLeadMonFilter && (
										<TableColumn>
											<div key={`tc-${index}`}>
												{renderColumn(
													item,
													TABLE_COLUMNS.INSPECTED_LEADMON_ACTION.columnName
												)}
											</div>
										</TableColumn>
									)}
								</>
							</TableRow>
						))}
				</TableBody>
			</Table>
		</div>
	);

	const onRowExpand = (row) => {
		setRowClickLoader(true);
		setAppointmentData();
		const type =
			appointmentState === APPOINTMENT_TYPES_STRING.PAYMENT_PROCESSING &&
			isHierarchy
				? "payment-processing-detail"
				: appointmentState;
		AppointmentService.getAppointmentDetail(type, row.appointment)
			.then((resp) => {
				// resp = getAppointmentDetails();
				const {
					data: { detail },
				} = resp;
				setAppointmentData(detail);
			})
			.finally(() => {
				setRowClickLoader(false);
			});
	};

	const onTableRowClick = (index, item) => {
		if (index === activeIndex) {
			setActiveIndex(undefined);
			setAppointmentData();
			return;
		}
		setActiveIndex(index);
		onRowExpand(item);
	};

	const viewAppointmentList = () => (
		<div className={styles.appointmentList}>
			<Table>
				{visibleColumns.length > 0 && (
					<thead>
						<TableRow>
							{visibleColumns.map((item, index) => {
								return (
									<>{getColumnHeader(item.key, item.value, item, index)}</>
								);
							})}
						</TableRow>
					</thead>
				)}
				<TableBody>
					{!isLoading &&
						!error &&
						renderedData.map((item, index) => (
							<>
								<TableRow
									key={`row-${index}`}
									tableRowRef={index === lastItemIndex ? itemRef : null}
									onRowClick={() => onTableRowClick(index, item)}>
									<>
										{showSerialNumberColumn() && (
											<TableColumn key={`s-no-${index}`}>
												{renderSerialNumberColumn(index)}
											</TableColumn>
										)}
										{visibleColumns.map((visCol, visColIndex) => {
											return (
												<TableColumn key={`col-${visColIndex}`} item={item}>
													{getColumn(
														visCol.key,
														item[visCol.key],
														item,
														getSerialNumber(index),
														true
													)}
												</TableColumn>
											);
										})}
										<TableColumn key={`s-no-${index}`}>
											<ArrowIcon
												grey={"GreyArrow"}
												rotateBy={activeIndex === index ? 180 : 90}
											/>
										</TableColumn>
									</>
								</TableRow>
								{activeIndex === index && (
									<TableRow>
										<TableColumn
											key={`s-no-${index}`}
											colLength={visibleColumns.length}>
											{rowClickLoader ? (
												<Loader />
											) : (
												<AppointmentDetailCard
													appointmentState={appointmentState}
													rowDetails={appointmentData}
													index={index}
												/>
											)}
										</TableColumn>
									</TableRow>
								)}
							</>
						))}
				</TableBody>
			</Table>
		</div>
	);

	const onSelectRetailAssociates = (e) => {
		if (e.length <= NUMBERS.TEN) {
			setSelectedRetailAssociates(e);
			setRaFilterQuery(e);
		}
	};

	return (
		<HocAccessController>
			<div className={styles.fixedheadingSearchWrapper}>
				<div className={styles.headingSearchWrapper}>
					<div className={styles.titleSection}>
						{appointmentType !== APPOINTMENT_TYPES_STRING.SCHEDULED && (
							<div className={styles.title}>
								{getPageHeading()}
								{appointmentType ===
									APPOINTMENT_TYPES_STRING.PAYMENT_PROCESSING && (
									<PaymentProcessingFilter />
								)}
							</div>
						)}
						{[
							APPOINTMENT_TYPES_STRING.LEAD_MON,
							APPOINTMENT_TYPES_STRING.INSPECTED,
						].includes(appointmentType) && (
							<ReviewInspection appointmentType={appointmentType} />
						)}
					</div>
					{appointmentType === APPOINTMENT_TYPES_STRING.SCHEDULED && (
						<div className={styles.hiTitle}>
							<H2 text={"CJ Allocation Status"} />
						</div>
					)}
					<Filters />
					<div className={styles.onpageSerachWrapper}>
						{appointmentType === APPOINTMENT_TYPES_STRING.PAYMENT_PROCESSING &&
							isHierarchy && (
								<div className={styles.ppDataFilter}>
									<div className={styles.onRightFilter}>
										<SelectComponent
											name={"pp-pr-status"}
											placeholder={"PR Status"}
											optionsList={prStatuses}
											questionId={"pp-pr-status"}
											onChange={(e) => setPrStatusFilterQuery(e.key)}
											isSearchable={false}
											isClearable={false}
										/>
									</div>
									{retailAssociates && (
										<div className={styles.onRightFilter}>
											<MultiSelect
												id={"pp-select-ra"}
												options={retailAssociates}
												label={"Select RAs"}
												placeholder={"Select RAs"}
												handleChange={onSelectRetailAssociates}
												value={selectedRetailAssociates}
											/>
										</div>
									)}
								</div>
							)}
						<Search appointmentType={appointmentType} />
						<LostLeadsList appointmentState={appointmentState} />
					</div>
				</div>
				{appointmentType === APPOINTMENT_TYPES_STRING.SCHEDULED && (
					<>
						<div className={styles.hiBox}>
							<CjAllocationStatus />
						</div>
						{isHIConfigured && (
							<div className={styles.confirmleads}>
								<CcConfirmLeads
									confirmCount={confirmRows}
									nonConfirmCount={nonConfirmRows}
								/>
							</div>
						)}
					</>
				)}
			</div>
			{appointmentType === APPOINTMENT_TYPES_STRING.PAYMENT_PROCESSING &&
			isHierarchy
				? viewAppointmentList()
				: renderAppointments()}
			<DataInformationMessage
				isLoading={isLoading}
				error={error}
				data={renderedData}
				dataLoading={dataLoading}
			/>
			{loadMore && (
				<div
					className={
						appointmentType === APPOINTMENT_TYPES_STRING.SCHEDULED
							? styles.scheduleLoadmore
							: styles.loadmore
					}>
					<Button
						classNames="secondaryCta"
						ctaText={"Load More"}
						onClick={onLoadMore}></Button>
				</div>
			)}
			<Popup
				closeOnOutside={() => resetCurrentPopUp()}
				isOpen={!!currentPopup.popup}
				close={() => resetCurrentPopUp()}>
				{currentPopup.popup === popups.ALLOCATE_CJ && (
					<AllocateCjPopup
						onClose={() => resetCurrentPopUp()}
						appointmentId={
							currentPopup.currentRowDetails
								? currentPopup.currentRowDetails?.appointment
								: null
						}
						userData={
							currentPopup.currentRowDetails
								? currentPopup.currentRowDetails
								: null
						}
						resetListing={resetAndFetchNewListing}
					/>
				)}
			</Popup>
		</HocAccessController>
	);
};

export default AppointmentPage;
