import { createTypes } from "reduxsauce";

export default createTypes(
  `
    FETCH_APPOINTMENT_COUNTS
    FETCH_APPOINTMENT_COUNTS_SUCCESS
    FETCH_APPOINTMENT_COUNTS_FAILURE
    RESET_APPOINTMENT_COUNTS_KEY
    UPDATE_PRIORITY_COUNT_BY_KEY
    RESET_SIDEBAR
    UPDATE_PLL_FILTER_BY_KEY
    UPDATE_RETAIL_FILTER_BY_KEY
    FETCH_USER_MENU
    FETCH_USER_MENU_SUCCESS
    FETCH_USER_MENU_FAILURE
`,
  {
    prefix: "sidebar/"
  }
);
