import React, { useState } from "react";
// import styles from "./layout.module.css";
import { ToastContainer } from 'react-toastify';
import LayoutContext from "./context";
import Header from "../header";
import Footer from "../footer";

export default function withLayout(WrappedComponent) {
  const Layout = (props) => {
    const {location: {pathname=''} = {}} = props || {};
    const [state, setState] = useState({
      storeName: "",
      storeId: "",
      associateName: "TODO"
    });

    const updateLayoutContext = (val) => {
      setState({
        ...state,
        associateName: val
      });
    };
    return (
      <>
        <LayoutContext.Provider
          value={{
            data: state,
            updateLayoutContext: updateLayoutContext
          }}
        >

          <Header/>
          <div>
            <WrappedComponent {...props} />
          </div>
          {/* {!pathname.includes('/b2b/lead/detail') && <Footer/> } */}
          <ToastContainer />
        </LayoutContext.Provider>
      </>
    );
  };
  return Layout;
}
