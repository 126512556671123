import Types from "./types";
import { AppointmentService } from "../../service";
// import { getClearancePendingData, getPaymentProcessingMoc, getVisitedMock, getInspectedMock, getInspectionReviewMoc, getScheduledPageData } from "../../utils/mocApi";
import { updateApiNoAccess } from "../../components/hoc-access-controller/actions";
const fetchAppointments = (props) => (dispatch) => {
    return AppointmentService.getAppointments(props)
        .then((response) => {
            // response = getClearancePendingData();
            const {
                data: { detail },
            } = response;
            if (!!props.sortFilterQuery) {
                dispatch(
                    fetchAppointmentsSuccess(detail, props.sortFilterQuery)
                );
            } else {
                dispatch(fetchAppointmentsSuccess(detail, props.filterQuery));
            }
            return response;
        })
        .catch((error) => {
            dispatch(updateApiNoAccess(error));
            dispatch(fetchAppointmentsFailure(error));
        });
};

const fetchAppointmentReviewInspections = (page) => (dispatch) => {
    // dispatch(fetchAppointment());
    return AppointmentService.getAppointmentsReview(page)
        .then((response) => {
            // response = getInspectionReviewMoc();
            const {
                data: { detail },
            } = response;
            dispatch(fetchAppointmentsSuccess(detail, null));
            return response;
        })
        .catch((error) => {
            // let response = getInspectionReviewMoc();
            // const { data: { detail } } = response;
            // dispatch(fetchAppointmentsSuccess(detail, null));
            // return response;
            dispatch(updateApiNoAccess(error));
            dispatch(fetchAppointmentsFailure(error));
        });
};

const fetchAppointment = () => ({
    type: Types.FETCH_APPOINTMENTS,
});

const fetchAppointmentsSuccess = (data, filterQuery) => ({
    type: Types.FETCH_APPOINTMENTS_SUCCESS,
    data,
    filterQuery,
});

const fetchAppointmentsFailure = (error) => ({
    type: Types.FETCH_APPOINTMENTS_FAILURE,
    error,
});

// const setAppointmentState = (appointmentState) => ({
//     type: Types.SET_APPOINTMENT_STATE,
//     appointmentState
// });

const searchAndFilterAppointments = (filteredData) => ({
    type: Types.SEARCH_AND_FILTER_APPOINTMENTS,
    filteredData,
});

const removeSearch = () => ({
    type: Types.REMOVE_SEARCH,
});

const resetAppointments = () => ({
    type: Types.RESET_APPOINTMENTS,
});

const resetAppointmentByKey = (key, value) => ({
    type: Types.RESET_APPOINTMENT_BY_KEY,
    data: { key, value },
});

const sortAppointmentByKey = (key, sortOrder) => ({
    type: Types.SORT_APPOINTMENTS_BY_KEY,
    data: { key, sortOrder },
});
const resetSortOrderByKey = (key, sortOrder) => ({
    type: Types.RESET_SORT_ORDER_BY_KEY,
    data: { key, sortOrder },
});
export {
    fetchAppointments,
    // setAppointmentState,
    searchAndFilterAppointments,
    removeSearch,
    fetchAppointment,
    resetAppointments,
    resetAppointmentByKey,
    sortAppointmentByKey,
    resetSortOrderByKey,
    fetchAppointmentReviewInspections,
};
