import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DocumentUpload from "./component";
import withLayout from "../../components/layout/component";
import { fetchNegotiations } from "../../components/negotiation-list/actions";
const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchNegotiationsConnect: fetchNegotiations,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withLayout(DocumentUpload, false));