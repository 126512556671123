import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReviewInspection from "./component";
import { updatereviewInspectionFilterQuery } from "./actions";

const mapStateToProps = ({
  reviewInspection
}
) => ({
  reviewInspection
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updatereviewInspectionFilterQueryConnect: updatereviewInspectionFilterQuery
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ReviewInspection);
