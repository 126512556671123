import React, { useEffect, useState } from "react";
import styles from "./allocate.module.scss";

const Allocate = ({ history, location }) => {
	// const [allocateOpen, setAllocateOpen] = useState(false);
	const { pathname } = location;
	const [isActiveMenu, setIsActiveMenu] = useState(false);

	useEffect(() => {
		checkActiveMenu();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	const onAllocateOpen = () => {
		history.push("/allocate");
	};

	const checkActiveMenu = () => {
		pathname.replace("/", "") === "allocate"
			? setIsActiveMenu(true)
			: setIsActiveMenu(false);
	};

	// const onAllocateClose = () => {
	//     setAllocateOpen(false);
	// };
	return (
		<div>
			<div
				onClick={onAllocateOpen}
				className={`${styles.allocateTitle} ${
					isActiveMenu ? styles.titleActive : ""
				}`}>
				Allocate
			</div>
			{/*<Popup isOpen={allocateOpen} close={onAllocateClose}>*/}
			{/*    <AllocatePopup onClose = {onAllocateClose}/>*/}
			{/*</Popup>*/}
		</div>
	);
};

export default Allocate;
