import React, {useState} from "react";
import styles from "./doucumnet-upload-rto.module.scss";
import { MAX_ALLOWED_UPLOAD_SIZE, PDF_MIME_TYPE, PDF_FILE_EXTENSION } from "../../utils/app-constants";
import { showToastMessages, openUrlTab } from "../../utils/helper";
import { AppointmentService } from "../../service";
import Upload from "../../components/images/upload.svg";
import PdfIcon from "../../components/images/pdfIcon.svg";

const DoucumnetUploadRto = ({appointmentId, docKey, data, allowedFileTypes, formReadOnly}) => {
  const {image, name, page} = data || {};
  const [otherImage, setOtherImage] = useState();
  const onImageUpload = (args) => {
    const { target: { files } } = args;
    if (files && files.length > 0) {
      const selectedFile = files[0];
      if (selectedFile.size > MAX_ALLOWED_UPLOAD_SIZE) {
        showToastMessages(`File Size more than 5Mb`, false);
      } else {
        setOtherImage(selectedFile);
        let payload = new FormData();
        payload.append('appointmentId', appointmentId);
        payload.append('docCode', docKey);
        payload.append('uploadPhoto', selectedFile);
        payload.append('page', page);
        AppointmentService.postBikeUploadDocument(payload).then(resp =>{
          showToastMessages(resp.data.detail);
        }).catch((err)=>{
          showToastMessages(err.detail, false);
        });
      }
    }
  };

  return (
    <div className={styles.upload}>
      <img src={!!otherImage ? otherImage.type === PDF_MIME_TYPE ? PdfIcon  : URL.createObjectURL(otherImage) : !!image ? image.includes(PDF_FILE_EXTENSION) ? PdfIcon : image : Upload} alt="upload" onClick={()=> openUrlTab(image)}/>
      <label for={`file-input-aadhar-front-${docKey}-${name}-${page}`}>
        {!formReadOnly && <span>{!!image || !!otherImage ? "Re-upload" : "Upload"}</span>}
      </label>
      {!formReadOnly && <input id={`file-input-aadhar-front-${docKey}-${name}-${page}`} type="file"  accept={allowedFileTypes} onChange={(e) => onImageUpload(e)}/>}
    </div>
  );
};

export default DoucumnetUploadRto;
