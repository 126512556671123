/* eslint-disable max-params */

// I know it's fugly. If you pass anything other that asc
// it'll sort in descending order.
export default (data, key, order = "asc") => {
  return data.concat().sort((a, b) => {
    if (a[key] < b[key]) {
      return order === "asc" ? -1 : 1;
    } else if (a[key] > b[key]) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  });
};
