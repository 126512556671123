import React, {useRef, useState} from "react";
import styles from "./so-booking-appointment-popup.module.scss";
import H2 from "../h2/component";
import Button from "../shared/button/component";
import { LOCAL_STORAGE_KEYS, MAP_TYPE} from "../../utils/app-constants";
import { getFromLocalStorage, showToastMessages } from "../../utils/helper";
import AutoComplete from "../../utils/auto-complete";
import OnlineSearch from "../shared/search/online";
import { googlePlacesOptions } from "../../utils/auto-complete/search-options";
import { AppointmentService } from "../../service";
import InputText from "../shared/input-text/component";
import _debounce from 'lodash/debounce';

const SoBookingAppointmentPopup = ({title, ctaText, appointmentId, onClose, updateDataList}) => {
  const changeLocationRef = useRef();
  const mapType = getFromLocalStorage(LOCAL_STORAGE_KEYS.MAP_TYPE_ACTIVE);
  const [availableZone, setAvailableZone] = useState(null);
  const [isScheduled, setIsScheduled] = useState(false);
  const [availableLocationDateSlots, setAvailableLocationDateSlots] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [availableLocationDateTimeSlots, setAvailableLocationDateTimeSlots] = useState([]);
  const [selectedTimeslot, setSelectedTimeslot] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [address, setAddress] = useState('');
  const [landmark, setLandmark] = useState('');
  const [location, setLocation] = useState({lat: '', lng:''});
  const resetData = () => {
    setAvailableLocationDateTimeSlots(null);
    setSelectedTimeslot(null);
    setAvailableZone(null);
    setAvailableLocationDateSlots();
    setSelectedDate();
    setSelectedTimeslot();
  };
  const onLocationSelection = async (item) => {
    let lat = item.lat;
    let lng = item.lng;

    if (mapType === MAP_TYPE.GOOGLE){
      const response = await AutoComplete.getInstance(mapType).getGeoCode({
        placeId: item.value }, "place");
      const coords = response[0].geometry.location;
      lat = coords.lat();
      lng = coords.lng();
    }
    setLocation({lat: lat, lng: lng});
    setSearchText(item.label);
    resetData();
    AppointmentService.getFetchLocation(lat, lng, 'sell_online')
      .then(response => {
        const { data: { detail : {slots, zone } } } = response;
        const selectedLocationSlot = Object.values(slots)[0];
        const availableDates = Object.values(selectedLocationSlot?.date_slot);
        setAvailableLocationDateSlots(availableDates);
        setAvailableLocationDateTimeSlots(null);
        setSelectedTimeslot(null);
        setAvailableZone(zone);
      }).catch(error => {
        console.log(error);
      });
  };

  const loadSuggestedOptions = React.useCallback(
    _debounce((inputValue, callback) => {
      googlePlacesOptions(inputValue, mapType).then(options => callback(options));
    }, 1000),
    []
  );

  const onSelectedDateAvailableTimeSlot = (date) => {
    setSelectedDate(date);
    const availableTimeSlots = Object.values(availableLocationDateSlots).filter(item => item.date === date);
    const selectedDateTimeSlots = Object.values(availableTimeSlots[0].time);
    var timeSlots = [];
    for (const c of selectedDateTimeSlots){
      for (let [key, value] of Object.entries(c)){
        timeSlots.push({key,value});
      }
    }
    setAvailableLocationDateTimeSlots(timeSlots);
    setSelectedTimeslot(null);
  };
  const onSelectedTimeslots = (time) => {
    setSelectedTimeslot(time);
  };
  const onRescheduleAppointment = () => {
    const payload = {
      appointment_id: appointmentId,
      city_id: availableZone?.city_id,
      store_id: availableZone?.location_id,
      zone_id: availableZone?.zone_id,
      appointment_date: selectedDate,
      appointment_time: selectedTimeslot,
      latitude: location.lat,
      longitude: location.lng,
      customerAddress1: address,
      customerAddress2: landmark,
      formattedAddress: searchText
    };
    if (ctaText === "RESCHEDULE") {
      setIsScheduled(true);
      AppointmentService.postRescheduleAppointment(payload)
        .then(response => {
          const { data: { detail} } = response;
          showToastMessages(detail);
          onClose();
          setTimeout(()=> {
            updateDataList();
          }, 2000);
        }).catch(error => {
          const { detail, validation_messages } = error;
          showToastMessages(detail?.message || detail || Object.values(validation_messages || {}).join(', ') || detail , false);
        }).finally(()=>{
          setIsScheduled(false);
        });
    } else if (ctaText === "SCHEDULE") {
      setIsScheduled(true);
      AppointmentService.confirmAppointment(payload)
        .then(response => {
          showToastMessages(response?.data?.detail || response?.detail);
          onClose();
          setTimeout(()=> {
            updateDataList();
          }, 2000);
        }).catch(error => {
          const { detail } = error;
          showToastMessages(detail?.message || detail , false);
        }).finally(()=>{
          setIsScheduled(false);
        });
    }
  };
  const showTimeslot = (index) => {
    const {value: {time}, key} = availableLocationDateTimeSlots[index] || '';
    return (
      <div className={`${styles.time} ${selectedTimeslot ===  key ? styles.active : ''}`} onClick={()=> onSelectedTimeslots(key)}>
        <h3>{time}</h3>
      </div>
    );
  };
  return (
    <>
      <div className={styles.soBookingAppointmentPopup}>
        <div className={styles.heading}>
          <H2 text={title} />
        </div>
                
        <div className={styles.inputWrap}>
          <OnlineSearch
            placeholder="Search your Location/Pincode"
            onChange={onLocationSelection}
            getOptions={loadSuggestedOptions}
            overrideSearchRef={changeLocationRef}
            customDefaultValue = {searchText}
          />
        </div>
        <div className={styles.inputWrap}>
          <InputText 
            id={`key-cep-text`}
            text="House No/ Flat No./Building Name"
            value={address}
            onChange={(e)=> setAddress(e.target.value)}
          /> 
        </div>
        <div className={styles.inputWrap}>
          <InputText
            id={`key-cep-text`}
            text="Landmark"
            value={landmark}
            onChange={(e)=> setLandmark(e.target.value)}
          /> 
        </div>
        {!!availableLocationDateSlots && 
        <>  
          <p className={styles.title}>Preferred date</p>
          <div className={styles.dateWrapper}>
            {
              Object.values(availableLocationDateSlots).map((item) => {
                return (
                  <div className={`${styles.date} ${selectedDate === item.date ? styles.active : ''}`} onClick={()=> onSelectedDateAvailableTimeSlot(item.date)}>
                    <h3>{item.value}</h3>
                  </div>
                );
              })
            }
          </div> 
        </>}
        {!!availableLocationDateTimeSlots && <p className={styles.title}>Preferred time</p> &&
        <>
          <div className={styles.timeWrapper}>
            {Object.keys(availableLocationDateTimeSlots).map((index) => showTimeslot(index))}
          </div>
          <div className={styles.ctaWrapper}>
            <Button classNames={"saveCta"} ctaText={ctaText} onClick = {onRescheduleAppointment} disabled={isScheduled} />
          </div>
        </>
        }
      </div>
    </>
  );
};

export default SoBookingAppointmentPopup;
