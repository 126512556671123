import React, { useState, useEffect } from "react";
import styles from './payment-raise-ocb-popup.module.scss';
import H2 from "../h2/component";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from "../shared/button/component";
import priceFormatter from "../../utils/helpers/price-formatter";
import { showToastMessages} from "../../utils/helper";
import ocbCalculateProbablity, { PROBABLITY_TYPES } from "../../utils/raise-ocb/ocb-calculate-probability";
import ocbInfo from "../../utils/raise-ocb/ocb-info";
import IncrementalTextbox from "../incremental-textbox/component";
import PaiSlider from "../pai-slider";
import ocbCalculateProbability from "../../utils/raise-ocb/ocb-calculate-probability";
import { AppointmentService } from "../../service";

const useStyles = makeStyles(theme => {
  return {
    textField: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      fontSize: '10px',
      width: '100%'
    }
  };
});

const PaymentRaiseOcbPopup = (props) => {
  const {
    rowDetails,
    close,
    ocbDetails
  } = props;
  const {customerName, year, model, appointment} = rowDetails;
  const {currentHB, make, auctionId, storeId, c24Quote} = ocbDetails || {};
  const [ocbInfoData, setOcbInfoData] = useState();
  const [probablityType, setProbablityType] = useState(null);
  const [closurePrice, setClosurePrice] = useState(currentHB);
  const [values, setValues] = useState([]);
  const [update, setUpdate] = useState([]);
  const [inputText, setInputText] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const classes = useStyles();
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(()=>{
    setisLoading(true);
    const payload = {
      "appointment_id": appointment,
      "auction_id": auctionId,
      "store_id": storeId,
      "request_type":"RAISE_OCB_BOUGHT",
      "c24quote": c24Quote
    };
    AppointmentService.preCheck(payload)
      .then(response => {
        const { data: { detail } } = response;
        const ocb = ocbInfo({preCheckData: detail});
        setValues(ocb?.defaultValues?.slice());
        setUpdate(ocb?.defaultValues?.slice());
        setClosurePrice(ocb?.minPrice);
        if (ocb?.showSlider) {
          setProbablityType(ocbCalculateProbablity(ocb?.priceAcceptance, ocb?.minPrice, ocb?.minPrice));
        }
        setOcbInfoData(ocb);
      })
      .catch(error => {
        showToastMessages(error?.detail, false);
        close();
      }).finally(()=>{
        setisLoading(false);
      });
  }, []);

  const onChangeClick = (type) => {
    const newPrice = type === "increment" ? closurePrice + ocbInfoData?.step : closurePrice - ocbInfoData?.step;
    setClosurePrice(newPrice);
    setProbablityType(ocbCalculateProbability(ocbInfoData?.priceAcceptance, newPrice, ocbInfoData?.minPrice));
    setValues([newPrice]);
  };

  const onChangeText = (e) => {
    const value = e.target.value && e.target.value.replace(/,/g, '') ? priceFormatter(Number(e.target.value.replace(/,/g, ''))) : "";
    const price = Number(value.replace(/,/g, ''));
    setClosurePrice(price);
    setInputText(value);
  };

  const onChangeSlider = values => {
    setValues(values);
  };

  const onUpdateSlider = update => {
    const currentValue = update[0];
    setProbablityType(ocbCalculateProbablity(ocbInfoData?.priceAcceptance, currentValue, ocbInfoData?.minPrice));
    setUpdate(update);
    setClosurePrice(currentValue);
  };

  const RaiseOcbQuote = () => {
    setIsSubmitted(true);
    const payload = {
      "appointment_id": appointment,
      "carId": ocbDetails?.carId,
      "auction_id": auctionId,
      "store_id": storeId,
      "current_c24quote": currentHB,
      "requested_c24quote": ocbDetails?.is_pll ?  0 : closurePrice ,
      "request_type": "RAISE_OCB_BOUGHT",
      "is_pll": ocbDetails?.is_pll,
      "c24Diff": 0,
      "requested_hb": ocbDetails?.is_pll ? closurePrice : 0,
      "probability": !!probablityType ? probablityType : ""
    };

    AppointmentService.paiRequest(payload).then(response => {
      const { data: { detail } } = response;
      showToastMessages(detail?.message);
      console.log({response});
      close();
    })
      .catch(error => {
        showToastMessages(error?.detail, false);
        console.log({error});
      }).finally(()=>{
        setIsSubmitted(false);
      });
  };

  return (isLoading ? <div><p>Loading...</p></div> :
  <div className={styles.currentHubWrap}>
    <H2 text="RAISE OCB" />
    <ul className={styles.customerDesc}>
      <li>{customerName}</li>
      <li>{`${make} ${model}`}</li>
      <li>{year}</li>
    </ul>
    <div className={styles.currentHub}>
      <h3>Current HB</h3>
      <h3 className={styles.price}>{priceFormatter(currentHB, true)}</h3>
    </div>
    {ocbInfoData?.showSlider && <p className={styles.clousrePrice}>Guaranteed Closure Price</p>}
    {ocbInfoData?.showSlider &&
    <IncrementalTextbox
      value={priceFormatter(closurePrice)}
      onChangeClick={onChangeClick}
    />
    }
    {!ocbInfoData?.showSlider && <div>
      <TextField
        label="Guranteed Closure Price"
        className={classes.textField}
        margin="dense"
        variant="outlined"
        onChange={onChangeText}
        value={inputText}
        inputProps={{
          maxLength: 9,
        }}
      />
    </div>
    }
    {ocbInfoData?.showSlider && <div>
      <PaiSlider
        domain={ocbInfoData?.domain}
        onChange={onChangeSlider}
        onUpdate={onUpdateSlider}
        values={values}
        update={update}
        step={ocbInfoData?.step}
        sliderData={ocbInfoData?.sliderData}
        prices={ocbInfoData?.priceAcceptance}
      />
      <React.Fragment>
        {probablityType === PROBABLITY_TYPES.HIGH.key && <p className={styles.greenText}>{PROBABLITY_TYPES.HIGH.label}</p>}
        {probablityType === PROBABLITY_TYPES.MEDIUM.key && <p className={styles.orengeText}>{PROBABLITY_TYPES.MEDIUM.label}</p>}
        {probablityType === PROBABLITY_TYPES.LOW.key && <p className={styles.redText}>{PROBABLITY_TYPES.LOW.label}</p>}
        {probablityType === PROBABLITY_TYPES.VERY_LOW.key && <p className={styles.redText}>{PROBABLITY_TYPES.VERY_LOW.label}</p>}
      </React.Fragment>
    </div>}
    <div className={styles.currentHubButton}>
      <Button
        isLoading={isLoading}
        disabled={isSubmitted}
        onClick={RaiseOcbQuote}
        classNames="blueButton"
        ctaText="Submit" />
      <button className={styles.closeModal} onClick={close}>CANCEL</button>
    </div>
    {/* <p className={styles.totalRequest}>{ocbInfoData?.pendingRequest} Request remaining</p> */}
  </div>
  );
};

export default PaymentRaiseOcbPopup;
