/* eslint-disable indent */
import React from "react";
import styles from "./notifications-list.module.scss";
import { RESET_REDUCER_STATE_KEYS, GLOBAL_SEARCH_STATES, APPOINTMENT_TYPES_STRING } from "../../utils/app-constants";
import { AppointmentService } from "../../service";
import { dateToFromNow } from "../../utils/helper";

const NotificationsList = (
    {
        data,
        resetGlobalSearchByKeyConnect,
        resetSearchConnect,
        history
    }) => {
    
    const onClickHandler = (appointmentId, type='') => {
		if (type === 'zendesk') {
			history.push(`/zendesk-tickets?id=${appointmentId}`);
		} else {
			AppointmentService.globalSearch(appointmentId)
				.then(resp => {
					const { data: { detail } } = resp;
					if (!!data && !!detail && detail.length > 0) {
						const item = detail[0];
						if (!!item) {
							const { state_name } = item;
							if (state_name && state_name.trim()) {
								const path = getAppointmentTypeStringByState(state_name.toLowerCase());
								resetSearchConnect();
								resetGlobalSearchByKeyConnect(RESET_REDUCER_STATE_KEYS.GLOBAL_QUERY, `${appointmentId}&globalSearch=true`);
								history.push(path ? `/${path}` : `/detail/${appointmentId}`);
							}
						}
						else {
							history.push(`/detail/${appointmentId}`);
						}
					}
					else {
						history.push(`/detail/${appointmentId}`);
					}
				});
		}
	};
    const getAppointmentTypeStringByState = (stateName) => {
        switch (stateName) {
            case GLOBAL_SEARCH_STATES.SCHEDULED:
                return APPOINTMENT_TYPES_STRING.SCHEDULED;
            case GLOBAL_SEARCH_STATES.VISITED:
                return APPOINTMENT_TYPES_STRING.VISITED;
            case GLOBAL_SEARCH_STATES.INSPECTED:
                return APPOINTMENT_TYPES_STRING.INSPECTED;
            case GLOBAL_SEARCH_STATES.NEGOTIATION:
                return APPOINTMENT_TYPES_STRING.NEGOTIATION;
            case GLOBAL_SEARCH_STATES.PAYMENT_PROCESSING:
                return APPOINTMENT_TYPES_STRING.PAYMENT_PROCESSING;
            case GLOBAL_SEARCH_STATES.CLEARNANCE_PENDING:
                return APPOINTMENT_TYPES_STRING.CLEARANCE_PENDING;
            default:
                return "";
        }
    };
    const getFormattedTime = (date) => {
        // date = "2020-02-16 20:15:22";
        // date = "";
        let momentDate = dateToFromNow(date);
        // console.log("Data is - " + date + " - and moment date is - " + momentDate);
        return momentDate;
    };
    const getImageBasedOnType = (type) => {
        let imgClass = "chatIcons";
        switch (type) {
            case "dealerBackout":
                imgClass = "chatIcons";
                break;
            case "paiOcbAccept":
            case "buyNowAccepted":
                imgClass = "acceptedIcon";
                break;
            case "ocbExpired":
                imgClass = "rejectedIcon";
                break;
            case "paiOcbNegotiated":
                imgClass = "negotiatedIcon";
                break;
            case "elite_INTENT_CAPTURED":
                imgClass = "tokenKycIcon";
                break;
            case "elite_TOKEN_INITIATED":
                imgClass = "tokenKycIcon";
                break;
            case "elite_BACKGROUND_CHECK_DONE":
                imgClass = "contactRaIcon";
                break;
            case "elite_KYC_DONE":
                imgClass = "tokenKycIcon";
                break;
            case "elite_TOKEN_SUBMITTED":
                imgClass = "submitTokenIcon";
                break;
            case "elite_EXITED":
                imgClass = "customerExitIcon";
                break;
            case "elite_customerContacted":
                imgClass = "contactRaIcon";
                break;
            case "elite_PENNY_DROP_DONE":
                imgClass = "partiallyVerifiedIcon";
                break;
            case "elite_TOKEN_PROCESSED":
                imgClass = "tokenSuccessfulIcon";
                break;
            case "elite_TOKEN_REJECTED":
                imgClass = "tokenRejectedIcon";
                break;
            case "hi_checkin":
                imgClass = "acceptedIcon";
                break;
            case "hi_checkin_denied":
                imgClass = "rejectedIcon";
                break;
            case "selfCheckinNew":
                imgClass = "selfCheckInIcon";
                break;
            case "selfCheckinExisting":
                imgClass = "selfCheckInIcon";
                break;
            default:
                imgClass = "acceptedIcon";
                break;
        }
        return imgClass;
    };

    return (
        !!data && data.length > 0 &&
        <div className={styles.notificationsListWrapper}>
            {
              <ul>
                {data.sort((a, b) => new Date(b.created_date).getTime() - new Date(a.created_date).getTime()).map((item, index) =>
                  <li onClick={() => onClickHandler(item.appointmentId, item.type)} key={`key-${index}`}>
                    <div className={`${styles[getImageBasedOnType(item.type)]}`} />
                    <span>{getFormattedTime(item.created_date)}</span>
                    <p>{item.message}</p>
                  </li>
                    )}
              </ul>
            }
        </div>
    );
};

export default NotificationsList;
