import { AppointmentService } from "../../service";

const fetchMarketingQuestions = (page, searchQuery, filterQuery) => dispatch => {
  // let response = getInnegotiationList();

  return AppointmentService.marketingQuestions()
    .then(response => {
      // response = getMarketingQuestionsMoq();
      const { data: { detail } } = response;
      return detail;
    })
    .catch((error) => {
      throw error;
    });
};

export {
  fetchMarketingQuestions
};

