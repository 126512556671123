import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  make: [],
  odometer: [],
  state: [],
  isLoading: false,
  error: null
};

export const fetchMake = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true};
};

export const fetchMakeSuccess = (state = INITIAL_STATE, { data } ) => {
  return { ...state, make: data, isLoading: false};
};

export const fetchMakeFailure = (state = INITIAL_STATE, { error } ) => {
  return { ...state, error, isLoading: false};
};

export const fetchOdometer = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true};
};

export const fetchOdometerSuccess = (state = INITIAL_STATE, { data } ) => {
  return { ...state, odometer: data, isLoading: false};
};

export const fetchOdometerFailure = (state = INITIAL_STATE, { error } ) => {
  return { ...state, error, isLoading: false};
};

export const fetchState = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true};
};

export const fetchStateSuccess = (state = INITIAL_STATE, { data } ) => {
  return { ...state, state: data, isLoading: false};
};

export const fetchStateFailure = (state = INITIAL_STATE, { error } ) => {
  return { ...state, error, isLoading: false};
};

// FETCH_MAKE
// FETCH_MAKE_SUCCESS
// FETCH_MAKE_FAILURE
// FETCH_ODOMETER
// FETCH_ODOMETER_SUCCESS
// FETCH_ODOMETER_FAILURE
// FETCH_STATE
// FETCH_STATE_SUCCESS
// FETCH_STATE_FAILURE

export const HANDLERS = {
  [Types.FETCH_MAKE]: fetchMake,
  [Types.FETCH_MAKE_SUCCESS]: fetchMakeSuccess,
  [Types.FETCH_MAKE_FAILURE]: fetchMakeFailure,
  [Types.FETCH_ODOMETER]: fetchOdometer,
  [Types.FETCH_ODOMETER_SUCCESS]: fetchOdometerSuccess,
  [Types.FETCH_ODOMETER_FAILURE]: fetchOdometerFailure,
  [Types.FETCH_STATE]: fetchState,
  [Types.FETCH_STATE_SUCCESS]: fetchStateSuccess,
  [Types.FETCH_STATE_FAILURE]: fetchStateFailure,

};

export default createReducer(INITIAL_STATE, HANDLERS);
