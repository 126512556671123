import baseApi from "./baseApi";
import appointmentApi from "./appointment-service";
import authenticationApi from "./auth-service";
import suggestionApi from "./auto-suggestion-service";
import secureApi from "./secure-service";
import domoApi from "./domo-service";
import supersetApi from "./superset-service";
const appointment = appointmentApi(baseApi);
const auth = authenticationApi(baseApi);
const suggestions = suggestionApi(baseApi);
const secure = secureApi(baseApi);
const domo = domoApi(baseApi);
const superset = supersetApi();
export {
  baseApi,
  appointment as AppointmentService,
  auth as AuthService,
  suggestions as SuggestionService,
  secure as SecureService,
  domo as domoService,
  superset as SupersetService
};
