import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import InitiatePaymentPopup from "./component";
import {setCurrentPopup} from "../negotiation-list/actions";
const mapStateToProps = ({negotiations}) => ({negotiations});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setCurrentPopupConnect: setCurrentPopup,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InitiatePaymentPopup);
