import vehApi from './vehicleApi';
import vehService from './vehicleService';
import plApi from './partnersLeadApi';
import plService from './partnersLeadService';
import lead from './leadApi';
import lService from './leadService';
import cApi from './carApi';
import cService from './carService';
import openLead from './javaLeadApi';
import openService from './javaLeadService';
import locationAPI from './locationServiceApi';
import location from './locationService';
const vehicle = vehService(vehApi);
const pLeads = plService(plApi);
const ls = lService(lead);
const car = cService(cApi);
const open = openService(openLead);
const loc = location(locationAPI);
export {
  vehicle as VehicleService,
  pLeads as PartnersLeadService,
  ls as LeadService,
  car as CarService,
  open as DealerService,
  loc as LocationService,
};