export default {
  RAISE_PAI: "RAISE_PAI",
  CAR_SPECS: "CAR_SPECS",
  CAR_SPECS_CUSTOMER_VIEW: "CAR_SPECS_CUSTOMER_VIEW",
  GENERATE_PR: "GENERATE_PR",
  FOLLOW_UP: "FOLLOW_UP",
  QUOTE_UNLOCK: "QUOTE_UNLOCK",
  REAUCTION: "REAUCTION",
  SUGGESTED_QUOTE: "SUGGESTED_QUOTE",
  RAISE_OCB: "RAISE_OCB",
  ACCEPTANCE_PRICE: "ACCEPTANCE_PRICE",
  PLL_ACCEPTANCE_PRICE: "PLL_ACCEPTANCE_PRICE",
  C2C_LIST_PRICE: "C2C_LIST_PRICE",
  ALLOCATE_CJ: "ALLOCATE_CJ",
  INITIATE_PAYMENT: "INITIATE_PAYMENT",
  PARK_AND_SELL_REAUCTION: "PARK_AND_SELL_REAUCTION",
  BIKE_INITIATE_PAYMENT: "BIKE_INITIATE_PAYMENT",
  SCHEDULE_PICKUP: "SCHEDULE_PICKUP",
  INSPECTION_REPORT: "INSPECTION_REPORT",
  DIY_PLL_ACCEPT_PRICE: 'DIY_PLL_ACCEPT_PRICE',
  DIY_PLL_EDIT_PRICE: 'DIY_PLL_EDIT_PRICE',
  DIFF_REPORT: 'DIFF_REPORT',
};