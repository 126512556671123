import Init from "./init";
import { normalizeObject } from "./helper";

const AUTO_SUGGESTION_MAP = {
  search: "input"
};

class AutoSuggestion extends Init {

  getPlaces(request) {
    const payload = normalizeObject(AUTO_SUGGESTION_MAP, request);
    return new Promise((resolve, reject) => {
      this.getAutCompleteInstance().getPlacePredictions(
        {
          ...payload, componentRestrictions: { country: this.getRegion() }
        }, (response, status) => {
          if (status === "OK") {
            resolve(response);
          } else {
            reject(new Error({ message: status }));
          }
        });
    });
  }

  getGeoCode(request) {
    return new Promise((resolve, reject) => {
      this.getGeoCoderInstance().geocode(request, (response, status) => {
        if (status === "OK") {
          resolve(response);
        } else {
          reject(new Error({ message: status }));
        }
      });
    });
  }

  async getAddressFromCoordinates(location) {
    try {
      const locations = await this.getGeoCode({ location });
      return locations[0].formatted_address;
    } catch (error) {
      return "India";
    }
  }

}

export default AutoSuggestion;
