import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Filters from "./component";

const mapStateToProps = ({
  appointments
}) => ({
  appointments
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
