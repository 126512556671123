import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CarDetailPopup from "./component";

const mapStateToProps = ({ negotiations }) => ({ negotiations });

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CarDetailPopup);
