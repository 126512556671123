import React, {useEffect, useState, useRef} from "react";
import styles from "./sell-online.module.scss";
import { PAGE_HEADING, NUMBERS, RESET_REDUCER_STATE_KEYS, APPOINTMENT_TYPES_STRING } from "../../utils/app-constants";
import H2 from "../../components/h2";
import StickyComponent from "../../components/shared/sticky-component";
import Filters from "../../components/filters";
import SellOnlineList from "../../components/sell-online-list";
import { getAppointmentTypeFromPathName } from "../../utils/helper";
import DataInformationMessage from "../../components/shared/data-information-message/component";
import Button from "../../components/shared/button/component";
import { AppointmentService } from "../../service";
import HocAccessController from "../../components/hoc-access-controller";
const SellOnline = ({
  location,
  appointments,
  search,
  globalSearch,
  resetAppointmentByKeyConnect,
  fetchAppointmentsConnect,
  resetGlobalSearchConnect,
  sellOnlineFilter,
  resetAppointmentsConnect,
  resetApiNoAccessConnect,
  resetLeadMenuByKeyConnect,
  leadMenu
}) => {
  const { pathname } = location;
  const appointmentType = getAppointmentTypeFromPathName(pathname);
  const { data, isLoading, error, loadMore } = appointments;
  const { query } = search;
  const { globalQuery } = globalSearch;
  const { filterQuery } = sellOnlineFilter;
  const { resetAndFetchData } = leadMenu;
  const [dataLoading, setDataLoading] = useState(false);
  const [renderedData, setRenderedData] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const scrollRef = useRef();
  const itemRef = useRef();
  let loadKey = '';
  useEffect(() => {
    resetApiNoAccessConnect();
    resetAppointmentByKeyConnect(RESET_REDUCER_STATE_KEYS.APPOINTMENT_STATE, appointmentType);
    setCurrentPageNo(1);
    loadMoreAppointments(1);
    return () => {
      // Reset on unmount
      resetAppointmentsConnect();
    };
  },[]);

  useEffect(() => {
    setRenderedData(data);
    if (itemRef.current && currentPageNo > 1) {
      itemRef.current.scrollIntoView({
        behavior: "auto",
        block: "end"
      });
    }
  }, [data]);

  useEffect(() => {
    if (resetAndFetchData) {
      resetLeadMenuByKeyConnect(RESET_REDUCER_STATE_KEYS.RESET_AND_FETCH_DATA, false);
      resetListing('resetAndFetchData');
    }
  }, [resetAndFetchData]);

  useEffect(() => {
    if (filterQuery !== null) {
      resetListing('filterQuery');
    }
  }, [filterQuery]);

  useEffect(() => {
    if (globalQuery) {
      resetListing('globalQuery');
    }
  }, [globalQuery]);

  useEffect(() => {
    resetAppointmentByKeyConnect(RESET_REDUCER_STATE_KEYS.APPOINTMENT_STATE, appointmentType);
  }, [pathname]);

  const paginationLoader = (page) => {
        page > 1 ? setDataLoading(true) : setDataLoading(false);
  };

  const getFinalSearchQuery = () => {
    if (globalQuery) {
      return globalQuery;
    } else {
      return query;
    }
  };

  const bindAppointmentsData = (response) => {
    if (response && response.data && globalQuery) {
      resetGlobalSearchConnect();
    }
    setDataLoading(false);
  };

  const loadMoreAppointments = (page) => {
    if (appointmentType === APPOINTMENT_TYPES_STRING.SELL_ONLINE) {
      paginationLoader(page);
      const finalQuery = getFinalSearchQuery();
      // const homeInsFilterQery = getHomeInspectionFilterQuery();
      fetchAppointmentsConnect({type: APPOINTMENT_TYPES_STRING.SCHEDULED, page, filterQuery, searchQuery: finalQuery, leadType: 'online'})
        .then(resp => {
          bindAppointmentsData(resp);
        });
    }
  };

  const resetListing = (key) => {
    if (!loadKey) {
      loadKey = key;
      resetAppointmentByKeyConnect(RESET_REDUCER_STATE_KEYS.IS_LOADING, true);
      resetAppointmentByKeyConnect(RESET_REDUCER_STATE_KEYS.DATA, []);
      setCurrentPageNo(1);
      loadMoreAppointments(1);
    }
  };

  const onLoadMore = () => {
    let i = currentPageNo;
    setCurrentPageNo(i + 1);
    loadMoreAppointments(i + 1);
  };

  const reloadAppointmentData = (appointmentId) => {
    const newList = [...data];
    const currentNegoIndex = newList.findIndex(app => app.appointment === appointmentId);
    AppointmentService.getAppointments({type: APPOINTMENT_TYPES_STRING.SCHEDULED, page: 1, filterQuery, searchQuery: appointmentId, leadType: "online"})
      .then(resp => {
        if (resp.data) {
          const { data: { detail: { result } } } = resp;
          if (result.length > 0) {
            const appData = result.find(appoint => appoint.appointment === appointmentId);
            if (appData) {
              newList[currentNegoIndex] = appData;
              resetAppointmentByKeyConnect(RESET_REDUCER_STATE_KEYS.DATA, newList);
            }
          }
        }
      })
      .catch(error => console.log(error));
  };

  return (
    <HocAccessController>
      <StickyComponent topOffset={NUMBERS.MINUS_SIXTY} classes="freezeHeader" >
        <div className={styles.headingSearchWrapper}>
          <div className={styles.title}>
            <H2 text={PAGE_HEADING.SELL_ONLINE} />
          </div>
          <div className={styles.searchSection}>
            <Filters />
          </div>
        </div>
      </StickyComponent>
      {/* Appointments list */}
      <>
        {!isLoading && !error && <SellOnlineList 
          data={data} 
          scrollRef={scrollRef} 
          reloadAppointmentData={reloadAppointmentData}
          resetListing={resetListing}
        />}
        <DataInformationMessage
          isLoading={isLoading}
          error={error}
          data={renderedData}
          dataLoading={dataLoading}
        />
        {loadMore && <div className={styles.loadmore}>
          <Button classNames="secondaryCta" ctaText={"Load More"} onClick={onLoadMore}></Button>
        </div>
        }
      </>
    </HocAccessController>
  );
};

export default SellOnline;
