import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  sort: '',
  filter: ''
};

export const updateLeadSort = (state = INITIAL_STATE, { value }) => {
  return { ...state, sort: value};
};

export const updateLeadFilterQuery = (state = INITIAL_STATE, { query }) => {
  return { ...state, filter: query};
};

export const HANDLERS = {
  [Types.SET_LEAD_SORT_DIRECTION]: updateLeadSort,
  [Types.SET_LEAD_FILTER]: updateLeadFilterQuery,

};

export default createReducer(INITIAL_STATE, HANDLERS);
