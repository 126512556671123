import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchVehicleDetails } from "../../pages/lead-procurement/actions";
import LeadProcurementCard from "./component";

const mapStateToProps = ({
  leadProcurementData
}) => ({
  leadProcurementData
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchVehicleDetailsConnect: fetchVehicleDetails,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LeadProcurementCard);
