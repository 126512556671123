import React from "react";
import Arrow from "./images/right-arrow.svg";
import GreyArrow from "./images/arrow-grey.svg";

const ArrowIcon = ({ grey, rotateBy, width, height, ...props }) => {
  const transform = typeof rotateBy === "string" ? rotateBy : `rotate(${rotateBy}deg)`;
  const style = {
    width,
    height,
    transform
  };

  let icon = Arrow;
  if (grey) {
    icon = GreyArrow;
  }

  return (
    <img src={icon} style={style} {...props} alt={'arrow'}/>
  );
};
export default ArrowIcon;
