import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppointmentService } from "../../service";
import styles from "./styles.module.scss";
import {updateApiNoAccess} from "../../components/hoc-access-controller/actions";
const RaJourneyDocumentationPopup = (props) => {
  const { appointmentId } = props;
  const [docs, setDocs] = useState([]);
  const dispatch = useDispatch();
  useEffect(()=>{
    AppointmentService.getDocumentStatusData(appointmentId).then((resp)=>{
      const documents = Object.values(resp.detail);
      setDocs(documents);
    }).catch((err)=>{
      dispatch(updateApiNoAccess(err));
      console.log(err);
    });
  },[]);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={styles.raJourneyDocumentationPopup}>
      <div className={styles.documentsWrapperList}>
        {!!docs ? <ul>
          <li>
            <h3>Documents</h3>
            <h3>Issue</h3>
          </li>
          {Object.values(docs).map((item, index)=>{
            return (
              <li key={`doc-item-${index}-${item.key}`}>
                <label>{item.label}</label>
                <span className={
                                    item.status === "VERIFIED" ? styles.done : 
                                        item.status === "PENDING" ? styles.pending : styles.error}>{item.title} 
                  {item.key === 'PENNY' && item.status === "VERIFICATION_FAILED" && (
                    <div className={styles.bankinfo}>
                      <p>Name on RC: <span>{item?.accountName}</span></p>
                      <p>Bank Account Name: <span>{item?.nameFromBank}</span></p>
                    </div>
                  )}
                  {['AADHAR_CARD_FRONT', 'PAN_CARD'].includes(item.key) && !['PENDING', 'VERIFIED'].includes(item.status) && (
                    <div className={styles.bankinfo}>
                      <p>Name on RC: <span>{item?.nameOnRC}</span></p>
                      <p>Name on Document: <span>{item?.nameOnDocument}</span></p>
                    </div>
                  )}
                </span>
                                
              </li>
            );
          })
          }
        </ul> : <p>Loading ...</p>}
      </div>

    </div>
  );
};

export default RaJourneyDocumentationPopup;
