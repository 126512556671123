import React, {useEffect} from "react";
import EvaluatorStoreTabs from "../../components/evaluator-store-tabs/";
import EvaluatorRetailView from "../../components/evaluator-retail-view/";
import styles from "./evaluator-view.module.scss";
import {getFromLocalStorage} from "../../utils/helper";
import {LOCAL_STORAGE_KEYS} from "../../utils/app-constants";

const EvaluatorView = ({
  match,
  resetViewConnect,
}) => {
  const { params: { storeId } } = match;
  const storeList = getFromLocalStorage(LOCAL_STORAGE_KEYS.CITY_STORES_LIST)?
        JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.CITY_STORES_LIST)):[];
  let isValid = false;
  const store = storeList.find(store=> store.idLmsStore == storeId);
    store ? isValid = true: isValid=false;

    useEffect(() => {
      return () => {
        // Reset on unmount
        resetViewConnect();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div className={styles.evaluatorWrapper}>
        {
          store &&
          <h3>Welcome to <span>{store.name}</span></h3>
        }
        {
          store &&
          <EvaluatorStoreTabs
            storeId={storeId}/>
        }
        <EvaluatorRetailView
          storeId={storeId}
          isValidStore={isValid}
        />
      </div>
    );
};

export default EvaluatorView;
