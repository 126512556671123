import React, {useState} from "react";
import styles from "./initiate-payment-popup.module.scss";
import H2 from "../h2/component";
import RadioButton from "../shared/radio-button/component";
import  Button  from "../../components/shared/button";
const PAYMENT_OPTIONS = {
  INITIATE_TOKEN: "INITIATE_TOKEN",
  INITIATE_DELIVERY_PAYMENT: "INITIATE_DELIVERY_PAYMENT"
};
const InitiatePaymentPopup = ({close, history, resetListing, negotiations}) => {
  const [selectedPaymentOptions, setSelectedPaymentOptions] = useState(PAYMENT_OPTIONS.INITIATE_TOKEN);
  const { currentAppointmentIndex, data } = negotiations;
  const currentAppointmentData = data[currentAppointmentIndex] || {};
  const {customerName, make, model, year, appointmentId} = currentAppointmentData;
  const onRadioButtonClick = (value) => {
    setSelectedPaymentOptions(value);
  };
  const onInitiatePayment = () => {
    history.push(`/document-upload/${appointmentId}`);
  };
  return (
    <div className={styles.initiatePaymentPopup}>
      <H2 text={"Initiate Payment"} />
      <ul className={styles.customerDesc}>
        <li>{customerName}</li>
        <li>{`${make} ${model}`}</li>
        <li>{year}</li>
      </ul>
      <div className={styles.paymentOption}>
        <div className={styles.checkboxHolder}>
          <RadioButton id = {PAYMENT_OPTIONS.INITIATE_TOKEN} key={PAYMENT_OPTIONS.INITIATE_TOKEN}  text={"Initiate Token"} checkedStatus={selectedPaymentOptions === PAYMENT_OPTIONS.INITIATE_TOKEN} onClickCallback={() => {onRadioButtonClick(PAYMENT_OPTIONS.INITIATE_TOKEN);}} />
        </div>
        <div className={styles.checkboxHolder}>
          {/* <RadioButton id = {PAYMENT_OPTIONS.INITIATE_DELIVERY_PAYMENT} key={PAYMENT_OPTIONS.INITIATE_DELIVERY_PAYMENT} text={"Initiate Delivery Payment"}  checkedStatus={selectedPaymentOptions === PAYMENT_OPTIONS.INITIATE_DELIVERY_PAYMENT} onClickCallback={() => {onRadioButtonClick(PAYMENT_OPTIONS.INITIATE_DELIVERY_PAYMENT);}} /> */}
          <RadioButton id = {PAYMENT_OPTIONS.INITIATE_DELIVERY_PAYMENT} key={PAYMENT_OPTIONS.INITIATE_DELIVERY_PAYMENT} text={"Initiate Delivery Payment"}  checkedStatus={selectedPaymentOptions === PAYMENT_OPTIONS.INITIATE_DELIVERY_PAYMENT}/>
        </div>
      </div>
      <div className={styles.ctaWrapper}>
        <Button ctaText={"Cancel"} classNames="cancelCta" onClick={() => {close();}} />
        <Button ctaText={"Initiate Payment"} classNames="saveCta" onClick={() => {onInitiatePayment();}} />
      </div>
            
    </div>
  );
};

export default InitiatePaymentPopup;
