import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateRegistrationCarDetails, resetRegistrationCarDetails, resetLeadData } from "../../pages/lead-procurement/actions";
import LeadCarDetails from "./component";

const mapStateToProps = ({
  leadProcurementData
}) => ({
  leadProcurementData
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  UpdateRegistrationCarDetailsConnect: updateRegistrationCarDetails,
  ResetRegistrationCarDetailsConnect: resetRegistrationCarDetails,
  ResetLeadDataConnect: resetLeadData,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LeadCarDetails);
