import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import styles from "./lead-activities.module.scss";
// import Edit from  "../images/edit.svg";
import CallLogDate from "../call-log-date/component";
import CallLog from "../call-log/component";
import { AppointmentService } from "../../service";

const LeadActivities = ({ appointmentId }) => {

  const [callLogData, setCallLogData] = useState();

  useEffect(() => {
    if (appointmentId) {
      AppointmentService.getCallLogs(appointmentId)
        .then(resp => {
          const { data: { detail } } = resp;
          setCallLogData(detail);
        });
      // .catch(err => {
      //     console.log('error', err);
      // });
    }
  },
  [appointmentId]);

  return (
    <div className={styles.activitiesWrapper}>
      {
        callLogData &&
                Object.keys(callLogData).map((item, index) => {
                  return (
                    <>
                      <CallLogDate key={`item_${index}`} date={item} />
                      {
                        callLogData[item].map((log, index) => {
                          return (
                            <CallLog key={`${item}_log_${index}`} data={log} />
                          );
                        })
                      }
                    </>
                  );
                })
      }
      {/* {
                (!callLogData || (callLogData && callLogData.length === 0)) &&                  
                <p className={styles.noCall}>No Call logs</p>
            } */}
    </div>
  );
};

export default LeadActivities;
