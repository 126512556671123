import priceFormatter from "./price-formatter";

export default (currentCarDetails = {}) => {
  const filterRFC = ({ title, value }) => title.toLowerCase() !== "Estimated RFC".toLowerCase() && (value !== "N/A" || value !== "n/a");
  const hasWarningsCallback = ({ value }) => (value !== "Yes" || value !== "yes");
  const commentMap = ({ comment }) => ({ title: comment, value: comment });
  const documentDetail = currentCarDetails.documentDetail || { elements: {}, comments: { value: [] } };
  // const documentDetailContent = Object.values(documentDetail.elements);
  const documentDetailContent = Object.values(documentDetail.elements).map(el => {
    if (el.title === 'Odometer')
      return Object.assign({}, el, { value: priceFormatter(el.value) });
    return el;
  });

  const documentDetailComments = ((documentDetail.comments || {}).value || []).map(commentMap);

  const highlights = currentCarDetails.highlights || {};
  const highlightsContent = []
    .concat(...Object.values(highlights))
    .map(commentMap);

  const highlightsComments = ((highlights.comments || {}).value || []).map(commentMap);
  const otherRfcDetails = currentCarDetails.others || {};

  const exteriorTyres = otherRfcDetails.exteriorTyres || { elements: {}, grouped: [], comments: {} };
  const exteriorTyresElements = !exteriorTyres.elements.tyre ?
        exteriorTyres.elements :
        {
          ...exteriorTyres.elements, ...{
            tyre: {
              ...exteriorTyres.elements.tyre,
              subParts: Object.values(exteriorTyres.elements.tyre.subParts)
                .map(({ title, value, additionalInfo }) => {
                  return {
                    title,
                    value,
                    additionalInfo: (additionalInfo || []).map((info) => {
                      return {
                        ...info,
                        treadDepth: {
                          title: info.treadDepth.title,
                          value: info.treadDepth.value[0]
                        }
                      };
                    })
                  };
                })
            }
          }
        };
  const exteriorTyresContent = Object.values(exteriorTyresElements).filter(filterRFC);
  const exteriorTyresComments = ((exteriorTyres.comments || {}).value || []).map(commentMap);

  const exteriorTyresGroupedContent = exteriorTyres.grouped.map(({ title, elements }) => {
    const content = Object.values(elements);
    const hasWarnings = content.filter(hasWarningsCallback).length > 0;
    return {
      title,
      hasWarnings,
      content
    };
  });

  const engineTransmission = otherRfcDetails.engineTransmission || { elements: {}, comments: {} };
  const engineTransmissionContent = Object.values(engineTransmission.elements).filter(filterRFC);
  const engineTransmissionComments = ((engineTransmission.comments || {}).value || []).map(commentMap);

  const airConditioning = otherRfcDetails.airConditioning || { elements: {}, comments: {} };
  const airConditioningContent = Object.values(airConditioning.elements).filter(filterRFC);
  const airConditioningComments = ((airConditioning.comments || {}).value || []).map(commentMap);

  const electricalsInterior = otherRfcDetails.electricalsInterior || { elements: {}, comments: {} };
  const electricalsInteriorContent = Object.values(electricalsInterior.elements).filter(filterRFC);
  const electricalsInteriorComments = ((electricalsInterior.comments || {}).value || []).map(commentMap);

  const steeringSuspensionBrakes = otherRfcDetails.steeringSuspensionBrakes || { elements: {}, comments: {} };
  const steeringSuspensionBrakesContent = Object.values(steeringSuspensionBrakes.elements).filter(filterRFC);
  const steeringSuspensionComments = ((steeringSuspensionBrakes.comments || {}).value || []).map(commentMap);

  return {
    documentDetail: {
      content: documentDetailContent,
      comments: documentDetailComments
    },
    highlights: {
      content: highlightsContent,
      comments: highlightsComments
    },
    exteriorTyres: {
      content: exteriorTyresContent,
      rating: parseFloat(exteriorTyres.rating),
      groupedContent: exteriorTyresGroupedContent,
      comments: exteriorTyresComments
    },
    engineTransmission: {
      content: engineTransmissionContent,
      rating: parseFloat(engineTransmission.rating),
      comments: engineTransmissionComments
    },
    airConditioning: {
      content: airConditioningContent,
      rating: parseFloat(airConditioning.rating),
      comments: airConditioningComments
    },
    electricalsInterior: {
      content: electricalsInteriorContent,
      rating: parseFloat(electricalsInterior.rating),
      comments: electricalsInteriorComments
    },
    steeringSuspensionBrakes: {
      content: steeringSuspensionBrakesContent,
      rating: parseFloat(steeringSuspensionBrakes.rating),
      comments: steeringSuspensionComments
    },
  };
};