import React, { useState, useEffect } from "react";
import styles from "./customer-consent-charges.module.scss";
import callIcon from "./images/callIcon.svg";
import Checkbox from "../../components/shared/checkbox";
import CustomerConsentChargesCongratulations from "../../components/customer-consent-charges-congratulations";
import {getConsentChargesBaseApiUrl} from '../../utils/url-constants';
// import data from './data.json';
import Loader from "../../components/shared/loader";

const CustomerConsentCharges = ({match}) => {
  const { params: { appointmentId } } = match;
  const queryString = window.location.search;
  const paramObject = new URLSearchParams(queryString);
  const token = paramObject.get('token');
  const [isFetching, setIsFetching] = useState(true);
  const [isPostingData, setIsPostingData] = useState(false);
  const [isConsentGiven, setIsConsentGiven] = useState(false);
  const [chargeActive, setChargeActive] = useState({});
  const [response, setResponse] = useState({});
  const [consumerConsent, setConsumerConsent] = useState(false);

  const onChargeClick = (keyName) => {
    const chargeActiveObject = {...chargeActive};
    chargeActiveObject[keyName] = !chargeActiveObject[keyName];
    setChargeActive(chargeActiveObject);
  };
  const Authorization = "Bearer " + token;
  useEffect(() => {
       
    const apiURL = `${getConsentChargesBaseApiUrl()}/token/charges/${appointmentId}`;

    fetch(apiURL, {
      method: 'get',
      headers: {
        'Authorization': Authorization,
        'Content-Type': 'application/json'
      }
    }).then(response => response.json()).then(data => {
      setIsFetching(false);
      setResponse(data.data);
    }).catch((error) => {
      console.log('Error:', error);
      setIsFetching(false);
      setResponse({});
    });;
    // setIsFetching(false);
    // setResponse(data.data);
  }, [appointmentId]);

  if(isFetching) {
    return <Loader />;
  }

  const fixedCharges = [];
  let totalFixedCharges = 0;
  const conditinalCharges = [];
  let totalConditinalCharges = 0;
  let totalHoldsCharges = 0;
  const holdCharges = [];
  response.detail && response.detail.charges && response.detail.charges.forEach((charge, index) => {
    charge.index = index;
    if(charge.isApplicable && charge.category === "Conditional Charges") {
      if(!charge.isConsent) {
        totalConditinalCharges += charge.amount;
      }
      conditinalCharges.push(charge);
    }
    if(charge.isApplicable && charge.category === "Fixed Charges") {
      if(!charge.isConsent) {
        totalFixedCharges += charge.amount;
      }
      fixedCharges.push(charge);
    }
  });

  response.detail && response.detail.holds && response.detail.holds.forEach((hold, index) => {
    hold.index = index;
    hold.isConditional = hold.category === 'Conditional Holdback';
    if(hold.isApplicable) {
      if(!hold.isConsent) {
        totalHoldsCharges += hold.amount;
      }
      holdCharges.push(hold);
    }
        
  });
  const setConsent = index => {
    const responseData = {...response};
    const item = responseData.detail.charges[index];
    item.isConsent = !item.isConsent;
    responseData.detail.charges[index] = item;
    setResponse(responseData);
  };

  const setHoldConsent = index => {
    const responseData = {...response};
    const item = responseData.detail.holds[index];
    item.isConsent = !item.isConsent;
    responseData.detail.holds[index] = item;
    setResponse(responseData);
  };

  const onChangeHandler = event => {
    setConsent(event.target.name);
  };

  const onChangeHoldsHandler = event => {
    setHoldConsent(event.target.name);
  };

  const consumerConsentHandler = () => {
    setConsumerConsent(!consumerConsent);
  };
    
  const proceedHandler = () => {
    setIsPostingData(true);
    const postApiURL = `${getConsentChargesBaseApiUrl()}/token/consent/charges`;
    const { charges, holds} = response.detail;
    const requestParams = {
      appointmentId,
      source: 'LMS',
      consentData: {
        charges,
        holds
      }
    };
    fetch(postApiURL, {
      method: 'post',
      headers: {
        'Authorization': Authorization,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestParams),
    }).then(response => response.json()).then(data => {
      setIsPostingData(false);
      setIsConsentGiven(true);
    });

  };
    
  const phone = response.detail && response.detail.extra && response.detail.extra.phone || null;
  const isCustomerConsented = response.detail && response.detail.extra && response.detail.extra.isCustomerConsented;
  const tokenCharge = response.detail && response.detail.extra && response.detail.extra.tokenAmount || 1000;
  const raName = response.detail && response.detail.extra && response.detail.extra.raName;
  const boughtDate = response.detail && response.detail.extra && response.detail.extra.boughtDate;
  const isTokenProcessed = response.detail && response.detail.extra && response.detail.extra.isTokenProcessed;
  const totalOfferPrice = response.detail && response.detail.extra && response.detail.extra.c24Quote || 0;
  const netPayableAmount = totalOfferPrice - totalConditinalCharges - totalFixedCharges;
  const payOnCarDelivery = netPayableAmount - tokenCharge - totalHoldsCharges;
  return (
    <div className={styles.customerConsentCharges}>
      {isPostingData && <Loader />}
      {(isConsentGiven || isCustomerConsented) ? (
        <CustomerConsentChargesCongratulations 
          raName={raName} 
          phone={phone} 
          isTokenProcessed={isTokenProcessed}
          tokenCharge={tokenCharge}
          boughtDate={boughtDate}
        />) :
        <div>
          <div className={styles.customerConsentChargesHeader}>
            <p>Charges & Holdbacks</p>
            {phone && (<div className={styles.callSupport}>
              <a href={'tel:'+phone}>
                <img src={callIcon} alt="call icon" />
                <span>Support</span>
              </a>
            </div>)}
          </div>
          <div className={styles.priceTitle}>
            <span>Offer Price</span>
            <span>₹ {totalOfferPrice && totalOfferPrice.toLocaleString()}</span>
          </div>
          <div className={`${styles.clickStripWrapper} ${chargeActive.fixed?styles.active: ''}`}>
            <div className={styles.clickStrip} onClick={()=> onChargeClick('fixed')}>
              <span className={styles.clickArrow}>Fixed Charges</span>
              <span>₹ {totalFixedCharges && totalFixedCharges.toLocaleString()}</span>
            </div>
            <div className={styles.subData}>
              <ul>
                {
                  fixedCharges.map(fixedCharge => (
                    <li key={fixedCharge.index}>
                      <div className={styles.fixedLabel}>
                        <span>{fixedCharge.label}</span>
                        <span>₹ {fixedCharge.amount && fixedCharge.amount.toLocaleString()}</span>
                      </div>
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
          <div className={`${styles.clickStripWrapper} ${chargeActive.conditinal?styles.active: ''}`}>
            <div className={styles.clickStrip} onClick={()=> onChargeClick('conditinal')}>
              <span className={styles.clickArrow}>Conditional Charges</span>
              <span>₹ {totalConditinalCharges && totalConditinalCharges.toLocaleString()}</span>
            </div>
            <div className={styles.subData}>
              <ul>
                {
                  conditinalCharges.map(conditinalCharge => (
                    <li key={conditinalCharge.index}>
                      <div className={styles.conditinalLabel}>
                        <span className={conditinalCharge.isConsent ? styles.textThrough : '' }>{conditinalCharge.label}</span>
                        <span className={conditinalCharge.isConsent ? styles.textThrough : '' }>₹ {conditinalCharge.amount && conditinalCharge.amount.toLocaleString()}</span>
                      </div>
                      <div className={styles.consentTextWrapper}>
                        {conditinalCharge.consentText &&(<Checkbox 
                          id={'c' + conditinalCharge.index}
                          name={conditinalCharge.index} 
                          onChangeCallback={onChangeHandler} 
                          checkedStatus={conditinalCharge.isConsent}
                        />)}
                        <span className={styles.consentText}>{conditinalCharge.consentText}</span>
                      </div>
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
                    
          <div className={styles.priceTitle}>
            <span>Net Payable Amount</span>
            <span>₹ {netPayableAmount && netPayableAmount.toLocaleString()}</span>
          </div>
          <div className={`${styles.clickStripWrapper} ${chargeActive.payable ? styles.active: ''}`}>
            <div className={styles.clickStrip}>
              <span>Token<span className={styles.smallSize}>(Payable Now)</span></span>
              <span>₹ {tokenCharge && tokenCharge.toLocaleString()}</span>
            </div>
            <div className={styles.clickStrip}>
              <span>Payable On Car Delivery</span>
              <span>₹ {payOnCarDelivery && payOnCarDelivery.toLocaleString()}</span>
            </div>
            <div className={styles.clickStrip} onClick={()=> onChargeClick('payable')}>
              <span className={styles.clickArrow}>Payable Later</span>
              <span>₹ {totalHoldsCharges && totalHoldsCharges.toLocaleString()}</span>
            </div>
            <div className={styles.subDataDiscount}>
              <ul>
                {holdCharges.map(hold => (
                  <li key={hold.index}>
                    <div className={styles.conditinalLabel}>
                      <span className={hold.isConsent ? styles.textThrough : '' }>{hold.label}</span>
                      <span className={hold.isConsent ? styles.textThrough : '' }>₹ {hold.amount && hold.amount.toLocaleString()}</span>
                    </div>
                    <div className={styles.consentTextWrapper}>
                      { hold.isConditional && (<Checkbox 
                        id={'hold'+hold.index}
                        name={hold.index}
                        onChangeCallback={onChangeHoldsHandler} 
                        checkedStatus={hold.isConsent}
                      />)}
                      <span className={styles.consentText}>{hold.consentText}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className={styles.tncWrapper}>
            <h4>Terms and Conditions</h4>
            <div dangerouslySetInnerHTML={{__html: response.detail && response.detail.tnc}}>
            </div>
            {/* <p>Deliver the car along with required documents within 7 days to avoid deal cancellation</p>
                        <p>Deal cancellation charges of Rs. 5000 are applicable in case you cancel the deal post token.</p> */}
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox 
              id="consumerConsent"
              name="consumerConsent"
              onChangeCallback={consumerConsentHandler}
              checkedStatus={consumerConsent}
            />
            <p>I’ve read all <a target="_blank" href={response.detail && response.detail.tncLink} rel="noreferrer">Terms and Conditions</a> and I agree to the charges and holdbacks shown here.</p>
          </div>
          <div className={styles.chargesCtaWrapper}>
            <ul>
              <li >
                <span>Token <span className={styles.smallSize}>(Payable Now)</span></span>
                <span>₹ {tokenCharge && tokenCharge.toLocaleString()}</span>
              </li>
              <li>
                <span>Payable On Car Delivery</span>
                <span>₹ {payOnCarDelivery && payOnCarDelivery.toLocaleString()}</span>
              </li>
              <li>
                <span>Payable Later</span>
                <span>₹ {totalHoldsCharges && totalHoldsCharges.toLocaleString()}</span>
              </li>
            </ul>
            <button className={ consumerConsent ? styles.proceedBtn : styles.proceedBtnDisabled} disabled={!consumerConsent} onClick={proceedHandler}>Proceed</button>
            <small>*Subject to above conditions fulfilled at the time of car delivery</small>
          </div>
        </div>
      }
    </div>
  );
};

export default CustomerConsentCharges;