import React, { useState, useEffect } from "react";
import H2 from "../h2/component";
import styles from "./lost-lead-nego-popup.module.scss";
import SelectComponent from "../shared/select/component";
import Textarea from "../shared/textarea/component";
import Button from "../shared/button/component";
import RadioButton from "../shared/radio-button/component";
import { showToastMessages, getFromLocalStorage, navigateToHomeWithDelay, isBikeSource } from "../../utils/helper";
import { AppointmentService } from "../../service";
import { LOCAL_STORAGE_KEYS, HIGH_PRICE_EXPECTATION_PRICE } from "../../utils/app-constants";
import { getLostLeadReasonMoq } from "../../utils/mocApi";
import ListingC2c from "../../components/listing-c2c";
import Popup from "../../components/popup";
import OtpConfirmPopup from "../../components/otp-confirm-popup";
//import LostLeadNegoPopup from "../../components/lost-lead-nego-popup";

const LostLeadNegoPopup = ({
  data,
  fetchLostLeadQuestionsConnect,
  onClose,
  history
}) => {
  const [lostLeadQuestions, setLostLeadQuestions] = useState([]);
  const [subQuestions, setSubQuestions] = useState([]);
  const [lostLeadQuestion, setlostLeadQuestion] = useState("");
  const [lostLeadReason, setLostLeadReason] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [listCar, setListCar] = useState(false);

  const list = [{ label: "a", value: "b" }];
  const { state_name, pre_state_name, appointmentId, make, model, year, customerName, c24Quote, c2cListingPrice, isLostLeadV2Configured } = data;
  const localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
  const firstName = localData ? JSON.parse(localData)[LOCAL_STORAGE_KEYS.FIRST_NAME] : "";
  const lastName = localData ? JSON.parse(localData)[LOCAL_STORAGE_KEYS.LAST_NAME] : "";
  const [otpResponse, setOtpResponse] = useState({});
  const [listClosurePrice, setListClosurePrice] = useState(0);
  const [showOtpConfirmation, setShowOtpConfirmation] = useState(false);
  const [otpConfirmationOpen, setOtpConfirmationOpen] = useState(false);
  const isBike = isBikeSource();

  const onsetOtpConfirmationOpen = () => {
    setOtpConfirmationOpen(true);
  };
  const onsetOtpConfirmationClose = (resp) => {
    setOtpConfirmationOpen(false);
    if (resp === true) {
      onSubmitLostLeadQuestion();
    } else {
      onClose();
    }
  };

  useEffect(() => {
    if (!!lostLeadQuestions) {
      fetchLostLeadQuestionsConnect().then((response) => {
        // response = getLostLeadReasonMoq();
        // const { detail: { reasons } } = response;
        let reasonsArray = [...response];
        // if (isLostLeadV2Configured === false) {
        reasonsArray = reasonsArray.filter(x => x.parentId !== HIGH_PRICE_EXPECTATION_PRICE);
        // }
        setLostLeadQuestions(reasonsArray);
        setIsLoading(false);
      })
        .catch(error => {
          setIsLoading(false);
          showToastMessages("Error in Fetching Questions", false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onSelectChange = (option, questionid) => {
    setListCar(false);
    setlostLeadQuestion(option);
    setLostLeadReason("");
    let questAnswer = lostLeadQuestions.filter(x => x.parentId === option);
    if (option === HIGH_PRICE_EXPECTATION_PRICE) {
      if (c2cListingPrice <= 0) {
        setListCar(true);
      }
      setSubQuestions([]);
    }
    else {
      if (!!questAnswer && questAnswer.length > 0) {
        setSubQuestions(questAnswer[0].subReasons);

      }
    }
    if (!isBike && !!questAnswer[0].subReasons && questAnswer[0].subReasons.length === 1) {
      setLostLeadReason(questAnswer[0].subReasons[0].id);
    } else if (isBike && questAnswer) {
      setLostLeadReason(questAnswer[0].parentId);
    }
  };
  const onLostLeadReasonClick = (item) => {
    setLostLeadReason(item.id);
  };
  const getResponse = () => {
    const loginData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
    const userEmail = loginData ? JSON.parse(loginData)[LOCAL_STORAGE_KEYS.EMAIL] : '';
    if (lostLeadReason.length > 0) {
      return {
        "appointmentId": appointmentId,
        "lostReason": lostLeadReason,
        "userEmail": userEmail
      };
    }
    else {
      return null;
    }
  };
  const onSubmitLostLeadQuestion = () => {
    // alert("onSubmitLostLeadQuestion");
    const response = getResponse();
    if (response) {
      AppointmentService.submitLostLead(response)
        .then(res => {
          // setDisabled(false);
          const { data: { detail: { message } } } = res;
          showToastMessages(message, true);
          onClose();
          navigateToHomeWithDelay(history);
        })
        .catch(error => {
          // setDisabled(false);
          const { detail: { message } } = error;
          showToastMessages(message, false);
        });
    }
    else {
      showToastMessages("Please select above reason", false);

    }
  };
  const onSubmitListCar = (response, price) => {
    if (price > 0) {
      setOtpResponse(response);
      setListClosurePrice(price);
      setShowOtpConfirmation(true);
    }
    else {
      onClose();
    }
  };
  return (
    <div className={styles.lostLeadpopup}>
      <H2 text="Reason for marking lost" />
      <div className={styles.list}>
        <ul>
          <li>{customerName} </li>
          <li>{model}</li>
          <li>{year}</li>
        </ul>
      </div>
      <p className={styles.reason}>Reason</p>
      {lostLeadQuestions && lostLeadQuestions.length > 0 &&
      <SelectComponent
        placeholder={"Select Reason"}
        optionsList={lostLeadQuestions.map((item) => {
          return {
            label: item.parent,
            value: item.parentId
          };
        })}
        onChange={onSelectChange}
      />}
      {!!subQuestions && subQuestions.length > 0 &&
                subQuestions.map(item => {
                  return (
                    <div className={styles.checkboxWrapper}>
                      <RadioButton key={item.id} id={item.id} name="lostleadNego" value={item.id} text={item.name} checkedStatus={lostLeadReason === item.id} onClickCallback={() => { onLostLeadReasonClick(item); }} />
                    </div>
                  );
                })
      }
      {(listCar && !showOtpConfirmation) &&
      <ListingC2c appointmentId={appointmentId} c24Quote={c24Quote} close={onSubmitListCar} />
        // <ListingC2c appointmentId={appointmentId} c24Quote={c24Quote} close={onSubmitLostLeadQuestion} />
      }

      {!listCar && <div className={styles.submitCta}>
        <Button classNames="blueButton" ctaText={"submit"} onClick={onSubmitLostLeadQuestion} disabled={!lostLeadReason} />
        <Button classNames="cancelCta" ctaText={"cancel"} onClick={onClose} />
      </div>
      }

      {showOtpConfirmation &&
      <OtpConfirmPopup onClose={onsetOtpConfirmationClose} detail={otpResponse} listPrice={listClosurePrice} appointmentId={appointmentId} reload={false} />
      }
      <small>Reason marked will be audited</small>
    </div>
  );
};

export default LostLeadNegoPopup;
