import React, {useEffect, useState} from 'react';
import styles from  "./diff-report-popup.module.css";
import {AppointmentService} from '../../service';
import priceFormatter from "../../utils/helpers/price-formatter";
import Loader from "../../components/shared/loader";
import {KEY_TITLE_MAPPERS} from './key-mapper';
import DiffReportResponseLoader from './response-loader';
import { getUid } from "../../utils/helper";
import {trackLMSEvents} from "../../utils/helpers/tracking-events";
import moment from "moment";
const LAYOUT_OUTSIDER_KEYS = ['appointmentId', 'narrow_webquote'];
const DIFF_REPORT_STASUSES = {
	MISMATCH: 'diff_report_exists',
	NO_MISMATCH: 'no_difference',
	NO_DATA: 'no_data_available_from_ds',
	OPEN_TIME: 'diff_report_user_opened_time',

};
const DiffReportPopup = (props) => {
    const [data, setData] = useState({});
    const [missmatchedData,setMissmatchedData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const {rowData: {appointmentId, isPriceReportViewed=0}, resetListing, isOpenFromLD = false} = props || {};
    let total_price_drop = 0;
	// const startDateTime = new Date();
	useEffect(() => {
		return () => {
			// const endTime = new Date();
			// const timediff = getTimeDifference(startDateTime, endTime);
			// trackLMSEvents('price_diff_report', {
			// 	eventLabel: appointmentId,
			// 	lsRAID: getUid(),
			// 	event: `${timediff/NUMBERS.THOUSAND} seconds`,
			// 	eventCategory: 'price_diff_report',
			// 	eventAction:DIFF_REPORT_STASUSES.OPEN_TIME,
			// 	Timestamp: moment().format()
			// });
            !isOpenFromLD && resetListing();
        };
    }, []);
    
    useEffect(()=> {
        setIsLoading(true);
        AppointmentService.getPriceDiffData(appointmentId).then(resp=>{
            setData(resp?.detail?.response);
        }).catch(() => {
            setData({});
			trackLMSEvents('price_diff_report', {
				eventLabel: appointmentId,
				lsRAID: getUid(),
				event:DIFF_REPORT_STASUSES.NO_DATA,
				eventCategory: 'price_diff_report',
				eventAction:DIFF_REPORT_STASUSES.NO_DATA,
				Timestamp: moment().format()
			});
        }).finally(()=>{
            !isPriceReportViewed && AppointmentService.UpdatePriceDiffReportView(appointmentId);
            setIsLoading(false);
        });
    },[appointmentId]);

    useEffect(()=>{
		const keys = Object.keys(data || {});
		if (!data || (!!data && keys.length === 0)) return;
        if(!!keys?.length){
            const filterData = keys?.filter((item)=> !LAYOUT_OUTSIDER_KEYS.includes(item));
            setMissmatchedData(filterData);
			
			if (!!filterData?.length) {
				trackLMSEvents('price_diff_report', {
					eventLabel: appointmentId,
					lsRAID: getUid(),
					event:DIFF_REPORT_STASUSES.MISMATCH,
					eventCategory: 'price_diff_report',
					eventAction:DIFF_REPORT_STASUSES.MISMATCH,
					Timestamp: moment().format()
				});
			} else {
				trackLMSEvents('price_diff_report', {
					eventLabel: appointmentId,
					lsRAID: getUid(),
					event:DIFF_REPORT_STASUSES.NO_MISMATCH,
					eventCategory: 'price_diff_report',
					eventAction:DIFF_REPORT_STASUSES.NO_MISMATCH,
					Timestamp: moment().format()
				});
			}
        }
        else{
            setMissmatchedData([]);
			trackLMSEvents('price_diff_report', {
				eventLabel: appointmentId,
				lsRAID: getUid(),
				event:DIFF_REPORT_STASUSES.NO_MISMATCH,
				eventCategory: 'price_diff_report',
				eventAction:DIFF_REPORT_STASUSES.NO_MISMATCH,
				Timestamp: moment().format()
			});
        }
    },[data]);
    return (
        <>
            {isLoading ? <Loader/>:<div className={styles.DiffReportPopup}>
                <div className={styles.title}>PRICE DIFF REPORT</div>
                {!!missmatchedData?.length ? 
                    <>
                        <div className={styles.tableWrapper}>
                            <table>
                                <thead className={styles.diffReportTable}>
                                    <tr>
                                        <th>CRITERIA</th>
                                        <th>SELLER INPUT</th>
                                        <th>CJ INPUT</th>
                                        <th>PRICE DROP</th>
                                    </tr>
                                </thead>
                                <tbody className={styles.checktable}>
                                    {Object.keys(data).map(key => {
                                        if (!LAYOUT_OUTSIDER_KEYS.includes(key)) {
                                            const value = data[key];
                                            const keyTitle = key.replace(/_/g, ' ');
                                            total_price_drop = total_price_drop + Number(value?.price_drop);
                                            return (
                                                <>
                                                    <tr className={styles.tablePrice}>
                                                        <td className={styles.criteria}>{keyTitle}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td className={styles.price}>{!!value?.price_drop ? `₹ ${priceFormatter(value?.price_drop)}` : ''}</td>
                                                    </tr>
                                                    {!!value?.mismatch && Object.keys(value?.mismatch).map((childKey, childIndex) => {
													    const childMapper = KEY_TITLE_MAPPERS[childKey];
                                                        const childData = value?.mismatch[childKey];
                                                        const title = !!childMapper ? childMapper.title : childKey;
                                                        const seller = !!childMapper[childData?.cx_input] ? childMapper[childData?.cx_input] : childData?.cx_input;
                                                        const cj = !!childMapper[childData?.cj_input] ? childMapper[childData?.cj_input] : childData?.cj_input;
                                                        return (
                                                            <tr key={`h2-${childIndex}`} className={styles.subPart}>
                                                                <td className={styles.subCriteria}>{title}</td>
                                                                <td>{seller}</td>
                                                                <td>{cj}</td>
                                                                <td></td>
                                                            </tr>
                                                        );
                                                    })}
                                                </>
                                            );
                                        } else {
                                            return <></>;
                                        }
                            
                                    })}
                                </tbody>
                            </table>
                            <div className={styles.priceWrapper}>
                                <div className={styles.totalDifference}>TOTAL DIFFERENCE</div>
                                <div className={styles.price}>{`₹ ${priceFormatter(total_price_drop)}`}</div>
                            </div>
                        </div>
                    </> 
                    : 
                    <DiffReportResponseLoader data={data}/>
                }
                
            </div>
            }</>
    );
};

export default DiffReportPopup;
