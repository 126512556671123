import React from "react";
import Individuals from "../individuals";
import IndividualsSearch from "../individuals-search";
import { RM_ROLES, RA_ROLES } from "../../utils/app-constants";

const IndividualsMenu = ({
  originalRole,
  isPllUser,
  onSelectionChange,
  showLeadsMenu
}) => {
  return (
    <div>
      {RA_ROLES.includes(originalRole) && !isPllUser &&
      <Individuals
        onSelectionChange={onSelectionChange}
      />
      }
      {(RM_ROLES.includes(originalRole) || (RA_ROLES.includes(originalRole) && !!isPllUser)) && 
      <IndividualsSearch
        isPllUser={isPllUser}
        onSelectionChange={onSelectionChange}
        showLeadsMenu={showLeadsMenu}
      />
      }
    </div>
  );
};

export default IndividualsMenu;
