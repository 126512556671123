import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PllLeads from "./component";
import withLayout from "../../components/layout/component";
import {resetPllAllocation} from "../../components/pll-store-list/actions";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  resetPllAllocationConnect: resetPllAllocation
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withLayout(PllLeads, false));