import React, { useEffect, useState } from "react";
import H4 from "../shared/h4/component";
import styles from "./price-negotiation.module.css";
import View from "../images/eye.svg";
import IconEdit from "../shared/icon-edit/component";
import priceFormatter from "../../utils/helpers/price-formatter";
import popups from "../../utils/popup-constants";
import { AppointmentService } from "../../service";
import Nego_report from "../images/nego_report.svg";
import {
  isPllUser,
  dateToFromNow,
  isBikeSource,
  showToastMessages,
  getUid,
  openInNewTab,
  getFromLocalStorage,
} from "../../utils/helper";
import Popup from "../popup/component";
import {
  LOCAL_STORAGE_KEYS,
  NUMBERS,
  ROLE_KEYS,
} from "../../utils/app-constants";
import CustomerOfferPricePopup from "../../components/customer-offer-price-popup";
import moment from "moment";
import { trackLMSEvents } from "../../utils/helpers/tracking-events";
import wa from "./images/WhatsApp.svg";
import rtStartIcon from "./images/negoStar.svg";
import quotaEligibleIcon from "./images/quotaEligible.svg";
// 1 for customer, 2 for RETAILUSER_ROLEACCESS_PATHS, 3 for CC
const ACCEPTED_BY_TEXT = ["", "by Customer", "by Retail", "by CC"];

const PriceNegotiationWrap = ({
  data,
  appointmentIndex,
  setCurrentPopupConnect,
  isLostLeadFlow,
  isLostLead,
  ResetApiNoAccessConnect,
}) => {
    const {
        customerExpectedPrice,
        appointmentId,
        followUpMessage,
        followUpRemarks,
        sortOrder,
        dealLostReason,
        lostReason,
        priceOffered,
        followUpTime,
        sellOnlineRetailAcceptance = 0,
        sellOnlineAcceptance = 0,
        sellOnlineReason = "",
        sellOnlineCep = "",
        offer_response_date,
        acceptedBy = "",
        inspReportCTAFlag,
        whatAppCTAFlag,
        retail_associate_email,
        whatAppCTAurl,
        retargetLabel = "",
        pll_intent_date,
        isFpQuotaEligible,
        diyLinkLimit = 0,
        pll_intent_status,
        sell_later_date,
        tp = 0,
        c24Quote = 0,
        C24TPRation,
        offerPrice
    } = data;
    const isPll = isPllUser();
    const IsTargetPriceGreen =
    !!C24TPRation && c24Quote * 1.0 > C24TPRation * 1.0 * tp;
  const [offerOpen, setOfferOpen] = useState(false);
  const [diySMSCounter, setDiySMSCounter] = useState(diyLinkLimit);
  const [isSMSSending, setIsSMSSending] = useState(false);
  const [isWhatsAppIconVisible, setIsWhatsAppIconVisible] = useState(false);
  const [isSendPickyAssistChatLoading,setIsSendPickyAssistChatLoading] = useState(false);
  const isBike = isBikeSource();
  const customerResponse = {
    status:
      sellOnlineAcceptance === NUMBERS.THREE
          ? "Price Rejected"
          : sellOnlineAcceptance === NUMBERS.TWO
              ? "Price Accepted"
              : "No Response Received",
    cssClass:
      sellOnlineAcceptance === NUMBERS.THREE
          ? "rejected"
          : sellOnlineAcceptance === NUMBERS.TWO
              ? "accepted"
              : "pending",
    lastOfferDatetime: "key required",
    dataAvailable: sellOnlineAcceptance > NUMBERS.ONE ? true : false,
    responseReceived: sellOnlineAcceptance < NUMBERS.TWO ? "No" : "Yes",
    priceAccepted:
      sellOnlineAcceptance === NUMBERS.TWO
          ? "Yes"
          : sellOnlineAcceptance === NUMBERS.THREE
              ? "No"
              : "",
    reasonForAcceptance: sellOnlineReason,
    cep: sellOnlineCep,
    time: !!offer_response_date
            ? `${
                sellOnlineAcceptance === NUMBERS.THREE
                    ? "Price Rejected"
                    : sellOnlineAcceptance === NUMBERS.TWO
                        ? "Price Accepted"
                        : ""
            } ${moment(offer_response_date).fromNow()}`
            : "",
    priceAcceptedBy: !!acceptedBy ? `(${ACCEPTED_BY_TEXT[acceptedBy]})` : "",
  };

  useEffect(() => {
    const { originalRole, email } =
      JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA)) || {};
    if (
      !!originalRole &&
      [ROLE_KEYS.RA_TRA.toLowerCase(),ROLE_KEYS.RA_CX_OCB.toLowerCase()].includes(originalRole.toLowerCase())  &&
      !!retail_associate_email &&
      email === retail_associate_email &&
      !!whatAppCTAFlag
    ) {
      setIsWhatsAppIconVisible(true);
    }
  }, [data]);

  const onClickEdit = () => {
    ResetApiNoAccessConnect();
    setCurrentPopupConnect(popups.FOLLOW_UP, appointmentIndex);
  };

  const onWhatsAppClick = () => {
    !!whatAppCTAurl && openInNewTab(whatAppCTAurl);
  };

  const getFollowUpTime = (date) => {
    // date = "2020-02-16 20:15:22";
    // date = "";
    let momentDate = dateToFromNow(date);
    // console.log("Data is - " + date + " - and moment date is - " + momentDate);
    return momentDate;
  };

  const onOfferOpen = () => {
    setOfferOpen(true);
  };
  const onOfferClose = () => {
    setOfferOpen(false);
  };

  const sendPickyAssistChat = () => {
    setIsSendPickyAssistChatLoading(true);
    const payload = {
      "appointmentId": appointmentId,
      "type": "PICKY_ASSIST"
    };
    AppointmentService.sendPickyAssistChat(payload).then(resp => {
      const {data: {detail: {message}}} = resp;
      showToastMessages(message);
    }).catch(err => {
      showToastMessages(err.detail, false);
    }).finally(()=>{
      setIsSendPickyAssistChatLoading(false);
    });
  };

  const onSendInspectionSMS = () => {
    setIsSMSSending(true);
    AppointmentService.sendInspectionSMS(appointmentId)
      .then((resp) => {
        const { message, diyLinkLimit: updatedDiyLinkLimit } =
          resp?.detail || {}; //
        setDiySMSCounter(updatedDiyLinkLimit);
        showToastMessages(message);
      })
      .catch((err) => {
        showToastMessages(err?.detail, false);
      })
      .finally(() => {
        setIsSMSSending(false);
        trackLMSEvents("DIY_RA_Manual_LMS_SMS", {
          eventLabel: appointmentId,
          lsRAID: getUid(),
          Timestamp: new Date(),
          eventAction: "Send_DIY_SMS",
          eventCategory: "DIY_RA_Manual_LMS_SMS",
        });
      });
  };

  return (
    <div className={styles.priceNegotiationWrap}>
      {!isLostLead && (
        <div className={styles.textIconWrap}>
          <H4
            text={`Follow up - ${followUpMessage ? followUpMessage : "N/A"}`}
          />
          <div className={styles.editWrapper}>
            <IconEdit onClick={onClickEdit} />
            {followUpTime && (
              <span className={styles.dayTime}>
                {getFollowUpTime(followUpTime)}
              </span>
            )}
          </div>
        </div>
      )}
      {isLostLead && (
        <div>
          <p className={styles.msgText}>{`${
                        dealLostReason ? dealLostReason : ""
          } - ${lostReason ? lostReason : ""}`}</p>
          <div className={styles.textIconWrap}>
            {isLostLeadFlow && lostReason && (
              <img onClick={onClickEdit} src={View} alt="view" />
            )}
          </div>

          {isPll && (
            <>
              <div className={styles.textIconWrap}>
                <H4
                  text={`Follow up - ${
                                        followUpMessage ? followUpMessage : "N/A"
                  }`}
                />
                <IconEdit onClick={onClickEdit} />
                {followUpTime && (
                  <span className={styles.dayTime}>
                    {getFollowUpTime(followUpTime)}
                  </span>
                )}
              </div>
            </>
          )}
        </div>
      )}
      {followUpRemarks && followUpRemarks.trim() && (
        <p className={styles.remarks}>{followUpRemarks}</p>
      )}
      {!isBike && sortOrder !== 1 && (
        <div className={styles.expectedSection}>
          {!isLostLeadFlow && (
            <div>
              <div className={styles.customerPrice}>
                <span>CEP</span>
                <span className={styles.price}>
                  {priceFormatter(customerExpectedPrice, true) !== NUMBERS.ZERO
                                        ? priceFormatter(customerExpectedPrice, true)
                                        : "-"}
                                </span>
                            </div>
                            <div className={styles.customerPrice}>
                                    <span>CX TP</span>
                                    <span
                                        className={`${
                                            IsTargetPriceGreen ? styles.bestTpFactor : styles.price
                                        }`}
                                    >
                                        {priceFormatter(tp, true)}
                                    </span>
                                </div>
                            <div className={styles.customerPrice}>
                                <span>Price Offered to Customer</span>
                                <span className={styles.price}>
                                    {priceFormatter(priceOffered, true) !== NUMBERS.ZERO
                                        ? priceFormatter(priceOffered, true)
                                        : "-"}
                </span>
              </div>
              {!!pll_intent_date && (
                <div className={styles.customerPrice}>
                  <span>Retargeting Intent Received Date</span>
                  <span>{moment(pll_intent_date).format("DD MMM YYYY")}</span>
                </div>
              )}
              {(!!retargetLabel || !!isFpQuotaEligible) && (
                <div className="d-flex justify-content-between align-items-start">
                  <div className={`col ${styles.rtTargetLabel}`}>
                    {!!retargetLabel && (
                      <span>
                        <img src={rtStartIcon} alt="view" /> {retargetLabel}
                      </span>
                    )}
                  </div>
                  <div className={`col ${styles.rtTargetLabel}`}>
                    {!!isFpQuotaEligible && (
                      <span>
                        <img src={quotaEligibleIcon} alt="view" />
                      </span>
                    )}
                  </div>
                  {!!pll_intent_status && pll_intent_status === NUMBERS.TWO && (
                    <span className={styles.sellLaterDate}>
                      {moment(sell_later_date).format("DD MMM YYYY")}
                    </span>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {isBike && (
        <div className={styles.priceAccepted}>
          <ul>
            <li>
              Price Accepted?{" "}
              <span>
                {sellOnlineRetailAcceptance === NUMBERS.TWO
                                    ? "Yes"
                                    : sellOnlineRetailAcceptance === NUMBERS.THREE
                                        ? "No"
                                        : "N/A"}
              </span>
            </li>
            <li>
              CEP:{" "}
              <span>
                {!!customerExpectedPrice &&
                  `₹ ${priceFormatter(customerExpectedPrice)}`}{" "}
              </span>
            </li>
          </ul>
        </div>
      )}
      {isBike && (
        <div className={styles.responseMainWrapper}>
          <div className={styles.response}>
            <p>Customer Response on Website</p>
            <span
              className={`${styles.responseText} ${customerResponse.cssClass}`}
            >{`${customerResponse.status} ${customerResponse.priceAcceptedBy}`}</span>
            {!!customerResponse.time && (
              <span className={styles.msgText}>{customerResponse.time}</span>
            )}
          </div>
          <div className={styles.viewDetails} onClick={onOfferOpen}>
            View Details
          </div>
          <Popup isOpen={offerOpen} close={onOfferClose}>
            <CustomerOfferPricePopup
              onClose={onOfferClose}
              data={customerResponse}
            />
          </Popup>
        </div>
      )}
      {!!isWhatsAppIconVisible && (
        <div className={"text-right"}>
          <p className={styles.whatsappchat} onClick={onWhatsAppClick}>
            <img src={wa} alt="view" /> Chat
          </p>
        </div>
      )}
      <div className={styles.pickyAssistDiv}>
        <div className={styles.pickyAssistDivIcon}><img src={Nego_report} alt="nego_report" /></div>
        <div className={styles.pickyAssistDivButton} onClick={sendPickyAssistChat}>
          {!isSendPickyAssistChatLoading ? 'Initiate Picky Assist Chat':'Sending'}
        </div>
      </div>
      {!!inspReportCTAFlag && (
        <>
          <button
            className={styles.sendCta}
            onClick={onSendInspectionSMS}
            disabled={isSMSSending || diySMSCounter < NUMBERS.ONE}
          >{`Send DIY SMS (${diySMSCounter})`}</button>
          <p
            className={styles.errorText}
          >{`Text limit of ${diySMSCounter} SMS remaining`}</p>
        </>
      )}
      {
        offerPrice != null && 
        <div className={styles.offerAppicableDiv}>
          <div className={styles.offerAppicableDiv_heading}>Offer Applicable</div>
          <div className={styles.offerAppicableDiv_price}>₹ {priceFormatter(offerPrice,true)}</div>
        </div>
      }
    </div>
  );
};

export default PriceNegotiationWrap;
