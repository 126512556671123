import Types from "./types";

const resetNotification = () => ({
  type: Types.RESET_NOTIFICATION
});

const resetNotificationnByKey = (key, value) => ({
  type: Types.RESET_NOTIFICATION_BY_KEY,
  data: { key, value }
});

export {
  resetNotification,
  resetNotificationnByKey
};
