import React from "react";
import styles from "./customer-yours-cars.module.scss";
import Yourcars from "../../components/images/frontmain_car.jpg";
import Rupess from "../../components/images/rupessWhite.svg";
import priceFormatter from "../../utils/helpers/price-formatter";

const CustomerYoursCars = ({
  customerOffer
}) => {
  const { customerOffer: { year, odometer, front_image, c24Quote } } = customerOffer;
  return (
    <div>
      <h3 className={styles.heading}>Your Car</h3>
      <div className={styles.customerYoursCars}>
        <div className={styles.yoursCarsWrapper}>
          <img src={front_image} alt="cars" />
        </div>
        <div className={styles.yoursCarsContent}>
          <p className={styles.carsPrice}><img src={Rupess} alt="rupess" /> {priceFormatter(c24Quote)}</p>
          <p>Your Car</p>
          <p>Driven: {priceFormatter(odometer, true)} km</p>
          <p>Mfg Year: {year}</p>
        </div>
      </div>
    </div>
  );
};

export default CustomerYoursCars;