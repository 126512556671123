import React, { useState, useEffect } from "react";
import styles from "./check-in-deny-popup.module.css";
import { POPUP_BUTTONS, ERROR_MESSAGE, RESET_REDUCER_STATE_KEYS, LOCAL_STORAGE_KEYS, TEXT_AREA_REGEX } from "../../utils/app-constants";
import Button from "../shared/button/component";
import SelectComponent from "../shared/select/component";
import RadioButton from "../shared/radio-button/component";
import { showToastMessages, getFromLocalStorage } from "../../utils/helper";
import Textarea from "../shared/textarea/component";
const CheckInDenyPopup = ({ onCancelClick, rowDetails, fetchCancellationListConnect, denyCheckInConnect, denyCheckIn, scrollRef, resetAppointmentByKeyConnect, setHasMoreItems, resetDenyCheckInByKeyConnect }) => {

  const { reasons, isPosting, error, message } = denyCheckIn;
  const { appointment, customerName, model, year } = rowDetails;

  const [codeReasonMappingList, setCodeReasonMappingList] = useState([]);
  const [primaryReasonList, setPrimaryReasonList] = useState([]);
  const [secondaryReasonList, setSecondaryReasonList] = useState([]);
  const [primaryReason, setPrimaryReason] = useState();
  const [secondaryReason, setSecondaryReason] = useState();
  const [primaryReasonError, setPrimaryReasonError] = useState(false);
  const [secondaryReasonError, setSecondaryReasonError] = useState(false);
  const [reasonsMapping, setReasonsMapping] = useState([]);
  const [remarks, setRemarks] = useState();
  const [isRemarkRequired, setIsRemarkRequired] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  useEffect(() => {
    fetchCancellationListConnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reasons && reasons.length > 0) {
      const codeMapping = reasons.map(item => {
        return (
          {
            code: Object.keys(item)[0],
            value: (Object.keys(Object.values(item)[0]))[1],
          }
        );
      });
      const list = reasons.map(item => {
        return (
          {
            value: Object.keys(Object.values(item)[0])[1],
            label: Object.keys(Object.values(item)[0])[1]
          }
        );
      });
      const mappingList = reasons.map(item => {
        return (
          {
            key: Object.keys(Object.values(item)[0])[1],
            values: Object.values(Object.values(item)[0])[1],
            reason: (Object.values(Object.values(item)[0]))[0]
          }
        );
      });
      setPrimaryReason(list[0].value);
      setPrimaryReasonList(list);
      setReasonsMapping(mappingList);
      setSecondaryReasonList(mappingList[0].values);
      setCodeReasonMappingList(codeMapping);
      setIsRemarkRequired(mappingList[0].reason);
    }
  }, [reasons]);

  useEffect(() => {
    if (primaryReason) {
      const reason = reasonsMapping.find(item => item.key === primaryReason);
      setSecondaryReasonList(reason.values);
      setSecondaryReason();
      setPrimaryReasonError(false);
      setSecondaryReasonError(false);
      setIsRemarkRequired(reason.reason);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryReason]);

  useEffect(() => {
    if (isPosting !== null && !isPosting) {
      if (error) {
        showToastMessages(message, false);
      } else {
        setHasMoreItems(true);
        showToastMessages(message);
        onCancelClick();
      }
      resetDenyCheckInByKeyConnect(RESET_REDUCER_STATE_KEYS.IS_POSTING, null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPosting]);

  const onPrimaryReasonSelect = (value) => {
    setPrimaryReason(value);
  };

  const onSecondaryReasonClick = (value) => {
    setSecondaryReason(value);
    setSecondaryReasonError(false);
  };

  const onRemarksChange = (e) => {
    const { target: { value } } = e;
    setRemarks(value);
  };

  const validateDetails = () => {
    let errors = 0;
    if (!primaryReason) {
      ++errors;
      setPrimaryReasonError(true);
    } else {
      setPrimaryReasonError(false);
    }
        
    if (primaryReason && (!!secondaryReasonList && secondaryReasonList.length > 0) && !secondaryReason) {
      ++errors;
      setSecondaryReasonError(true);
    } else {
      setSecondaryReasonError(false);
    }
    if(isRemarkRequired && !remarks){
      ++errors;
    }
    if (errors > 0) {
      return false;
    } else {
      return true;
    }
  };

  const onSubmit = () => {
    setSubmitted(true);
    const isValid = validateDetails();
    if (isValid) {
      const selectedCode = codeReasonMappingList.find(item => item.value === primaryReason);
      const localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
      const loggedInEmail = localData ? JSON.parse(localData)[LOCAL_STORAGE_KEYS.EMAIL] : "";
      const params = {
        "reason": "denied",
        "denied_reason": primaryReason,
        "appointment_id": appointment,
        "remarks": remarks ? remarks.trim().replace(TEXT_AREA_REGEX, " ") : "",
        "user_email": loggedInEmail,
        "denied_full_reason": `${secondaryReason ? secondaryReason : ''} | ${selectedCode ? selectedCode.code : ''}`
      };
      denyCheckInConnect(params);
    }
  };

  return (
    <>
      {
        primaryReasonList.length > 0 &&
        <div>
          <div className={styles.checkinText}>
            <p className={styles.txt}>{`${customerName} | ${model} | ${year}`}</p>
          </div>
          <p className={styles.staticText}>Disposition</p>
          <div className="pb-3 mb-3">
            <SelectComponent name="primaryReason" onChange={onPrimaryReasonSelect} optionsList={primaryReasonList} hasError={primaryReasonError} defaultValue={primaryReasonList[0]} error={ERROR_MESSAGE.PRIMARY_CANCELLATION_REASON} />
          </div>
          <p className={styles.staticText}>Select a Reason</p>
          {
            secondaryReasonList.map(item => {
              return (
                <div className={styles.checkboxWrapper}>
                  <RadioButton key={item} id={item} name="scndryRsn" value={item} text={item} checkedStatus={secondaryReason === item} onClickCallback={() => { onSecondaryReasonClick(item); }} />
                </div>
              );
            })
          }
          {
            primaryReason && secondaryReasonError &&
            <div>
              <span className={styles.errorText}>{ERROR_MESSAGE.SECONDARY_CANCELLATION_REASON}</span>
            </div>
          }
          <div className="mt-4">
            <Textarea
              id="remarks"
              name="remarks"
              value={remarks}
              onChange={onRemarksChange}
              text={isRemarkRequired ? "Remarks if any*" : "Remarks if any"}
            />
            {
              isSubmitted && isRemarkRequired && !remarks &&
              <div>
                <span className={styles.errorText}>{ERROR_MESSAGE.REMARKS}</span>
              </div>
            }
          </div>
          <div className={styles.ctaButton}>
            <Button classNames="saveCta" isLoading={isPosting ? isPosting : false} disabled={isPosting ? isPosting : false} ctaText={POPUP_BUTTONS.SUBMIT} onClick={onSubmit} />
            <Button classNames="cancelCta" ctaText={POPUP_BUTTONS.CANCEL} onClick={onCancelClick} />
          </div>
        </div >
      }

    </>
  );
};

export default CheckInDenyPopup;
