import React from "react";
import styles from "./customer-consent-charges-congratulations.module.scss";
import CongratulationsWait from "./images/congratulationsWait.svg";
import CongratulationsDone from "./images/congratulationsDone.svg";
import RetailImg from "./images/retailImg.svg";
import callIcon from "./images/callIcon.svg";
import moment from 'moment';

const CustomerConsentChargesCongratulations = ({
  phone, 
  isTokenProcessed = false, 
  tokenCharge, 
  raName, 
  boughtDate = null
}) => {
   
  const currentDate = boughtDate && new Date(boughtDate) || new Date();
  currentDate.setDate(currentDate.getDate() + 3);
  const displayDate = moment(currentDate).format('DD MMM YYYY');

  return (
    <div className={styles.customerConsentChargesCongratulations}>
      <div className={styles.customerConsentChargesHeader}>
        <p>Token Submitted</p>
      </div>
      <div className={styles.congratulationsImgSection}>
        {isTokenProcessed ? <img src={CongratulationsDone} alt="congratulationsDone" /> :
        <img src={CongratulationsWait} alt="congratulationsWait" />
        }
        <h3>Congratulations!</h3>
        {isTokenProcessed ? <p>Token of <span>₹{tokenCharge}</span> credited to your account.</p> :
        <p>Token of <span>₹{tokenCharge}</span> sent for approval.</p>
        }
      </div>
      <div className={styles.retailWrapper}>
        <div className={styles.retailTextWrapper}>
          <img src={RetailImg} alt="retailImg" />
          <div className={styles.retailName}>
            <p>CARS24 Retail Associate</p>
            <span>{raName}</span>
          </div>
        </div>
        <a href={'tel:'+phone}><img src={callIcon} alt="callIcon" className={styles.callIcon} /></a>
      </div>
      <div className={styles.retailStatus}>
        <h4>What’s next?</h4>
        <div className={styles.statusWrapper}>
          <div className={styles.status}>
            { !isTokenProcessed && <h3 className={styles.border1}>Token Approval Pending</h3>}
            <h3>Car Delivery and Documents</h3>
            <p>Deliver/Handover your car by {displayDate} <span>and bring required documents.</span></p>
            {isTokenProcessed && (<React.Fragment>
              <h3 className={styles.border2}>Delivery payment</h3>
              <p>Recieve payment in your account</p>
            </React.Fragment>)}
          </div>
        </div>
                
      </div>
    </div>
  );
};

export default CustomerConsentChargesCongratulations;
