import { createTypes } from "reduxsauce";

export default createTypes(
  `
    FETCH_NEGOTIATIONS_SUCCESS
    FETCH_NEGOTIATIONS_FAILURE

    FETCH_CAR_DATA_SUCCESS
    FETCH_CAR_DATA_FAILURE

    SET_CURRENT_POPUP

    PRECHECK_RAISE_PAI
    PRECHECK_RAISE_PAI_SUCCESS
    PRECHECK_RAISE_PAI_FAILURE

    PAI_REQUEST_SUCCESS
    PAI_REQUEST_FAILURE

    UPDATE_SUGGESTED_QUOTE_SUCCESS
    UPDATE_SUGGESTED_QUOTE_FAILURE

    SET_SUGGESTED_C24_QUOTE

    RESET_NEGOTIATIONS
    RESET_NEGOTIATIONS_BY_KEY

    PRECHECK_TOKEN_ELITE_SUCCESS

    FETCH_MISSEDCALLS_SUCCESS
    FETCH_MISSEDCALLS_FAILURE
`,
  {
    prefix: "negotiationlist/"
  }
);
