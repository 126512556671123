import React from "react";
import Edit from "./images/edit.svg";
import styles from "./edit-icon.module.css";

const IconEdit = ({ onClick }) => {
  return (
    <div onClick={onClick} className={styles.editWrap}>
      <img alt="Edit" src={Edit} width="30" height="30" />
    </div>
  );
};

export default IconEdit;
