/* eslint-disable indent */
import React from "react";
import { toast } from "react-toastify";
import {
	APPOINTMENT_TYPES_STRING,
	APPOINTMENT_TYPES_NUMBER,
	TIME_DIFF_IN_MINUTES,
	NUMBERS,
	DEPRECIATION_CONSTANTS,
	LOCAL_STORAGE_KEYS,
	ROLE_KEYS,
	MASTER_SIDE_BAR,
	PLL_USER_NEGOTIATION_FILTERS,
	NEGOTIATION_FILTERS,
	RETAILUSER_ROLEACCESS_PATHS,
	RA_TRA_RETAILUSER_ROLEACCESS_PATHS,
	PLLUSER_ROLEACCESS_PATHS,
	APPOINTMENT_ID_REGEX,
	LEAD_TYPE,
	C2CUSER_ROLEACCESS_PATHS,
	DEPRECIATION_CONSTANTS_NEW,
	HI_STORE_TYPE,
	APP_SOURCE,
	DIY_SORT_ORDER,
	FILTER_KEYS,
	YES_NO,
	TAB_KEYS,
} from "./app-constants";
import FollowUp from "../components/follow-up";
import Phone from "../components/phone";
import CheckInDateTime from "../components/check-in-date-time";
import InspectionStatus from "../components/inspection-status";
import QcStatus from "../components/qc-status";
import ViewInspectionReport from "../components/view-inspection-report";
import InspectionDateTime from "../components/inspection-date-time";
import AuctionStatus from "../components/auction-status";
import ModelVariantYear from "../components/model-variant-year";
import LastPaymentStatus from "../components/last-payment-status";
import CarStatus from "../components/car-status/";
import PendingHoldback from "../components/pending-holdback/";
import DaysToDeal from "../components/days-to-deal";
import QcStatusPp from "../components/qc-status-pp/";
import { AppointmentService } from "../service";
import AppointmentLink from "../components/appointment-link";
import VirtualNo from "../components/virtual-no";
import Disposition from "../components/disposition";
import Reason from "../components/reason";
import priceFormatter from "./helpers/price-formatter";
import moment from "moment";
import CustomerDetail from "../components/hi-customer-detail";
import LocationDetail from "../components/hi-location-detail";
// import CjDetail from "../components/hi-cj-detail";
import ViewDepriciationReport from "../components/view-depriciation-report";
import AllocateCj from "../components/allocate-cj";
import RaDetail from "../components/hi-ra-detail";
import AllocateCjSchedule from "../components/allocate-cj-schedule";

import PaymentPrStatus from "../components/payment-pr-status";
import PaymentDeliveryStatus from "../components/payment-delivery-status";
import PaymentVehicleStatus from "../components/payment-vehicle-status";
import ReviewInspectionReport from "../components/review-inspection-report";
import LeadMonAssignRa from "../components/leadmon-assign-ra/component";
import ApproveRejectPriceQuote from "../components/approve-reject-price-quote";
import { getPortalHostUrl } from "./url-constants";
import Link from "../components/link";

export const getAllocateIsPllString = () => {
	const { originalRole='', isHierarchy=false } = getLoginData();
    let value = "";
	if (isHierarchy) {
		if (originalRole === ROLE_KEYS.TM_NEW) {
			value = `isPll=0`;
		} else if (originalRole === ROLE_KEYS.PLL_TM) {
			value = `isPll=1`;
		} else if (originalRole === ROLE_KEYS.TM) {
			value = `isPll=2`;
		}
	}
	return value;
};

export const showToastMessages = (message, isSuccess = true) => {
	toast.dismiss();
	if (!!message) {
		if (isSuccess) {
			toast.success(message, {
				position: toast.POSITION.TOP_CENTER,
				hideProgressBar: true,
				autoClose: 3000,
			});
		} else {
			toast.error(message, {
				position: toast.POSITION.TOP_CENTER,
				// autoClose: false,
				hideProgressBar: true,
				autoClose: 5000,
			});
		}
	}
};

export const getAppointmentTypeFromPathName = (pathName) => {
	pathName = pathName.replace("/", "");
	switch (pathName) {
		case "scheduled":
			return APPOINTMENT_TYPES_STRING.SCHEDULED;
		case "visited":
			return APPOINTMENT_TYPES_STRING.VISITED;
		case "inspected":
			return APPOINTMENT_TYPES_STRING.INSPECTED;
		case "negotiation":
			return APPOINTMENT_TYPES_STRING.NEGOTIATION;
		case "clearance-pending":
			return APPOINTMENT_TYPES_STRING.CLEARANCE_PENDING;
		case "payment-processing":
			return APPOINTMENT_TYPES_STRING.PAYMENT_PROCESSING;
		case "lost-leads":
			return APPOINTMENT_TYPES_STRING.LOST;
		case "sell-online":
			return APPOINTMENT_TYPES_STRING.SELL_ONLINE;
		case "cj-execution":
			return APPOINTMENT_TYPES_STRING.CJ_EXECUTION;
		case "diy-negotiation":
			return APPOINTMENT_TYPES_STRING.DIY_NEGOTIATION;
		case "rt-negotiation":
			return APPOINTMENT_TYPES_STRING.CENTRAL_NEGOTIATION;
		case "rt-diy-negotiation":
			return APPOINTMENT_TYPES_STRING.CENTRAL_DIY_NEGOTIATION;
		case "leadmon":
			return APPOINTMENT_TYPES_STRING.LEAD_MON;
    case "unallocated-leads":
      return APPOINTMENT_TYPES_STRING.UNALLOCATED;
		default:
			return isPllCentralUser()
				? APPOINTMENT_TYPES_STRING.CENTRAL_NEGOTIATION
				: APPOINTMENT_TYPES_STRING.NEGOTIATION;
	}
};

export const getAppointmentTypeNumberFromString = (type) => {
	switch (type) {
		case APPOINTMENT_TYPES_STRING.SCHEDULED:
		case APPOINTMENT_TYPES_STRING.SELL_ONLINE:
			return APPOINTMENT_TYPES_NUMBER.SCHEDULED;
		case APPOINTMENT_TYPES_STRING.VISITED:
			return APPOINTMENT_TYPES_NUMBER.VISITED;
		case APPOINTMENT_TYPES_STRING.INSPECTED:
			return APPOINTMENT_TYPES_NUMBER.INSPECTED;
		case APPOINTMENT_TYPES_STRING.NEGOTIATION:
			return APPOINTMENT_TYPES_NUMBER.NEGOTIATION;
		case APPOINTMENT_TYPES_STRING.PAYMENT_PROCESSING:
			return APPOINTMENT_TYPES_NUMBER.PAYMENT_PROCESSING;
		default:
			return "";
	}
};

export const getAppointmentTypeStringFromNumber = (type) => {
	switch (type) {
		case APPOINTMENT_TYPES_NUMBER.SCHEDULED:
			return APPOINTMENT_TYPES_STRING.SCHEDULED;
		case APPOINTMENT_TYPES_NUMBER.VISITED:
			return APPOINTMENT_TYPES_STRING.VISITED;
		case APPOINTMENT_TYPES_NUMBER.INSPECTED:
			return APPOINTMENT_TYPES_STRING.INSPECTED;
		case APPOINTMENT_TYPES_NUMBER.NEGOTIATION:
			return APPOINTMENT_TYPES_STRING.NEGOTIATION;
		default:
			return APPOINTMENT_TYPES_STRING.NEGOTIATION;
	}
};

export const getTableColumnTypeByKey = (key) => {
	switch (key) {
		case "number":
		case "year":
		case "customerName":
			return ColumnTypeMapping.STRING;
		case "appointment":
		case "virtualNo":
		case "disposition":
		case "lostReason":
		case "pickUpRequestStatus":
		case "daysDealLost":
		case "exophone":
		case "followUpMessage":
		case "inspection":
		case "checkInDatetime":
		case "currentInspectionDate":
		case "auctionStartTime":
		case "auctionStatus":
		case "qcStatus":
		case "carId":
		case "modelVariantYear":
		case "pendingHoldback":
		case "lastPaymentStatus":
		case "qcStatusPP":
		case "customerDetail":
		case "customerLocation":
		case "hiEvaluatorName":
		case "homeInspectionCj":
		case "allocateCj":
		case "isDepReportAvailable":
		case "retailAssociate":
		case "prStatus":
		case "deliveryPaymentLoans":
		case "vehicleStatus":
		case "reviewReport":
		case "approveRejectPrice":
    case "appointmentKey":
			return ColumnTypeMapping.COMPONENT;
		case "postSaleRcStatus":
			return ColumnTypeMapping.DASH_STRING;
		case "hiDeniedReason":
		case "category":
		case "subCategory": 
			return ColumnTypeMapping.WRAPPED_STRING;
		case "createdDate":
			return ColumnTypeMapping.DATE_FORMAT;
		case "finalNegoPrice":
			return ColumnTypeMapping.PRICE_STRING;
		default:
			return ColumnTypeMapping.STRING;
	}
};

export const ColumnTypeMapping = {
	STRING: "string",
	LINK: "link",
	COMPONENT: "component",
	DASH_STRING: "dashstring",
	WRAPPED_STRING: "wrapped-text",
	DATE_FORMAT: 'date-format',
	PRICE_STRING: "price-string",
	// TRIMMED_STRING: 'trimmed_string'
};

export const getColumnComponent = (
	key,
	rowDetails,
	scrollRef,
	setHasMoreItems,
	index,
	cjData,
	updateDataList,
	raData,
	appointmentState,
	updatePendingReviewList,
	insuranceType,
	resetAndFetchNewListing,
	isPPRevamp = false,
	isLeadMonLink = false
, isLeadMonFilter) => {
	const { storeType, exophone, ctaAccessFlag, appointmentKey} = rowDetails;
	const isBike = isBikeSource();
	const isCJM = isCJManager();

	switch (key) {
		case "appointment": {
			const { appointment } = rowDetails;
			return isBike || !!ctaAccessFlag || isPPRevamp || isLeadMonLink ? (
				<span>{appointment}</span>
			) : (
				<AppointmentLink appointmentId={appointment} />
			);
		}
    case "appointmentKey": {
      const PORTAL_HOST_URL = getPortalHostUrl();
      return(
        <Link
          href={`${PORTAL_HOST_URL}/retail/lms/negoview/${appointmentKey}`} 
          preventDefault={true} 
          text={"Nego View"} 
          onClick={()=>{window.open(`${PORTAL_HOST_URL}lms/negoview/${appointmentKey}`,  "_blank", 'noopener');}}
        />
      );
    }
		case "exophone":
			return <Phone rowDetails={rowDetails} />;
		case "followUpMessage":
			return !!ctaAccessFlag ? (
				<></>
			) : (
				<FollowUp
					rowDetails={rowDetails}
					scrollRef={scrollRef}
					setHasMoreItems={setHasMoreItems}
					onResetParent={resetAndFetchNewListing}
				/>
			);
		case "checkInDatetime":
			return <CheckInDateTime rowDetails={rowDetails} />;
		case "currentInspectionDate":
			return <InspectionDateTime rowDetails={rowDetails} />;
		case "auctionStatus":
			return <AuctionStatus rowDetails={rowDetails} />;
		case "qcStatus":
			return <QcStatus rowDetails={rowDetails} />;
		case "qcStatusPP":
			return <QcStatusPp rowDetails={rowDetails} />;
		case "inspection":
			return <InspectionStatus rowDetails={rowDetails} />;
		case "virtualNo":
			return isBike || !!ctaAccessFlag || isPPRevamp ? (
				<span>{exophone}</span>
			) : (
				<VirtualNo rowDetails={rowDetails} />
			);
		case "disposition":
			return <Disposition rowDetails={rowDetails} />;
		case "lostReason":
			return <Reason rowDetails={rowDetails} />;
		case "carId":
			return <ViewInspectionReport rowDetails={rowDetails} />;
		case "modelVariantYear":
			return <ModelVariantYear rowDetails={rowDetails} />;
		case "pendingHoldback":
			return <PendingHoldback rowDetails={rowDetails} />;
		case "lastPaymentStatus":
			return <LastPaymentStatus rowDetails={rowDetails} />;
		case "pickUpRequestStatus":
			return <CarStatus rowDetails={rowDetails} />;
		case "daysDealLost":
			return <DaysToDeal rowDetails={rowDetails} />;
		case "customerDetail":
			return (
				<CustomerDetail
					rowDetails={rowDetails}
					index={index}
					appointmentState={appointmentState}
				/>
			);
		case "customerLocation":
			return isCJM || isBike || storeType === HI_STORE_TYPE ? (
				<LocationDetail
					rowDetails={rowDetails}
					scrollRef={scrollRef}
					setHasMoreItems={setHasMoreItems}
					updateDataList={updateDataList}
				/>
			) : (
				<></>
			);
		case "homeInspectionCj":
			return isCJM || isBike || storeType === HI_STORE_TYPE ? (
				<AllocateCjSchedule
					rowDetails={rowDetails}
					data={cjData}
					scrollRef={scrollRef}
					setHasMoreItems={setHasMoreItems}
					updateDataList={updateDataList}
				/>
			) : (
				<></>
			);
		case "retailAssociate": //retailAssociate
			if (appointmentState === APPOINTMENT_TYPES_STRING.VISITED) {
				return (
					<RaDetail
						rowDetails={rowDetails}
						data={raData}
						scrollRef={scrollRef}
						setHasMoreItems={setHasMoreItems}
						updateDataList={updateDataList}
					/>
				);
			} else {
				return storeType === HI_STORE_TYPE ? (
					<RaDetail
						rowDetails={rowDetails}
						data={raData}
						scrollRef={scrollRef}
						setHasMoreItems={setHasMoreItems}
						updateDataList={updateDataList}
					/>
				) : (
					<></>
				);
			}
		case "isDepReportAvailable":
			return <ViewDepriciationReport rowDetails={rowDetails} />;
		case "allocateCj":
			return storeType === HI_STORE_TYPE ? (
				<AllocateCj
					rowDetails={rowDetails}
					scrollRef={scrollRef}
					setHasMoreItems={setHasMoreItems}
					updateDataList={updateDataList}
					data={cjData}
				/>
			) : (
				<></>
			);
		case "prStatus":
			return <PaymentPrStatus rowDetails={rowDetails} />;
		case "deliveryPaymentLoans":
			return <PaymentDeliveryStatus rowDetails={rowDetails} />;
		case "vehicleStatus":
			return (
				<PaymentVehicleStatus
					rowDetails={rowDetails}
					data={cjData}
					updateDataList={updateDataList}
				/>
			);
		case "reviewReport":
			return (
				<ReviewInspectionReport
					rowDetails={rowDetails}
					updatePendingReviewList={updatePendingReviewList}
					insuranceType={insuranceType}
				/>
			);
        case 'isLeadMon':
                return <LeadMonAssignRa />;
		case 'approveRejectPrice':
			return <ApproveRejectPriceQuote rowDetails={rowDetails} resetAndFetchNewListing={resetAndFetchNewListing} />;
		default:
			break;
	}
};
// import CustomerDetail from "../components/hi-customer-detail";
// import LocationDetail from "../components/hi-location-detail";
// import CjDetail from "../components/hi-cj-detail";
export const saveToLocalStorage = (key, value) => {
	// console.log('saveToLocalStorage',{key, value});
	localStorage.setItem(key, value);
};

export const getFromLocalStorage = (key) => {
	return localStorage.getItem(key);
};

export const removeFromLocalStorage = (key) => {
	localStorage.removeItem(key);
};
// function for hasTimeElapsed
export const hasTimeElapsed = (date, timeInMinutes) => {
	const timeDiff = new Date().getTime() - new Date(date).getTime();
	if (timeDiff / TIME_DIFF_IN_MINUTES >= timeInMinutes) {
		return true;
	} else {
		return false;
	}
};

// get method for timedifference
export const getTimeDifference = (fromDate, toDate) => {
	return Math.floor(toDate.getTime() - fromDate.getTime());
};

export const fetchInspectionReport = (appointmentId) => {
	if (appointmentId) {
		AppointmentService.getInspectionReport(appointmentId)
			.then((response) => {
				const {
					data: { detail },
				} = response;
				window.open(detail, "_blank", "noopener");
			})
			.catch((error) => {
				showToastMessages(error?.detail || error?.data?.detail, false);
			});
	}
};

export const getUpdatedFilterCounts = (
	presentObject,
	newObject,
	keyToBeUpdated,
	updateObject
) => {
	if (updateObject) {
		return Object.assign({}, newObject);
	} else {
		presentObject[keyToBeUpdated] = newObject[keyToBeUpdated];
		return Object.assign({}, presentObject);
	}
};

export const getPllUpdatedFilterCounts = (
	presentObject,
	newObject,
	keyToBeUpdated,
	updateObject
) => {
	if (updateObject) {
		return Object.assign({}, newObject);
	} else {
		if (keyToBeUpdated === FILTER_KEYS.FOUR_DAYS) {
			presentObject[FILTER_KEYS.ONE_DAY] = newObject[FILTER_KEYS.ONE_DAY];
			presentObject[FILTER_KEYS.TWO_DAYS] =
				newObject[FILTER_KEYS.TWO_DAYS];
			presentObject[FILTER_KEYS.THREE_DAYS] =
				newObject[FILTER_KEYS.THREE_DAYS];
			presentObject[FILTER_KEYS.FOUR_DAYS] =
				newObject[FILTER_KEYS.FOUR_DAYS];
		} else if (keyToBeUpdated === FILTER_KEYS.FIVE_DAYS) {
			presentObject[FILTER_KEYS.FIVE_DAYS] =
				newObject[FILTER_KEYS.FIVE_DAYS];
			presentObject[FILTER_KEYS.SIX_DAYS] =
				newObject[FILTER_KEYS.SIX_DAYS];
		} else if (keyToBeUpdated === FILTER_KEYS.SIX_DAYS) {
			presentObject[FILTER_KEYS.SEVEN_DAYS] =
				newObject[FILTER_KEYS.SEVEN_DAYS];
			presentObject[FILTER_KEYS.EIGHT_DAYS] =
				newObject[FILTER_KEYS.EIGHT_DAYS];
			presentObject[FILTER_KEYS.NINE_DAYS] =
				newObject[FILTER_KEYS.NINE_DAYS];
		} else if (keyToBeUpdated === FILTER_KEYS.FIRST_TWO_DAYS) {
			presentObject[FILTER_KEYS.ONE_DAY] = newObject[FILTER_KEYS.ONE_DAY];
			presentObject[FILTER_KEYS.TWO_DAYS] =
				newObject[FILTER_KEYS.TWO_DAYS];
		} else if (keyToBeUpdated === FILTER_KEYS.THIRD_FOURTH_DAYS) {
			presentObject[FILTER_KEYS.THREE_DAYS] =
				newObject[FILTER_KEYS.THREE_DAYS];
			presentObject[FILTER_KEYS.FOUR_DAYS] =
				newObject[FILTER_KEYS.FOUR_DAYS];
		} else if (keyToBeUpdated === FILTER_KEYS.FIVE_PLUS_DAYS) {
			presentObject[FILTER_KEYS.FIVE_PLUS_DAYS] =
				newObject[FILTER_KEYS.FIVE_PLUS_DAYS];
		} else {
			presentObject[keyToBeUpdated] = newObject[keyToBeUpdated];
		}
		return Object.assign({}, presentObject);
	}
};

export const redirectToUrl = (url) => {
	if (typeof window !== "undefined") {
		window.open(url);
	}
};

export const onNumberKeyPress = (e) => {
	if (
		e.charCode === NUMBERS.ZERO ||
		e.charCode === NUMBERS.EIGHT ||
		e.charCode === NUMBERS.THIRTEEN
	) {
		return null;
	} else if (
		e.charCode >= NUMBERS.FORTY_EIGHT &&
		e.charCode <= NUMBERS.FIFTY_SEVEN
	) {
		return true;
	} else {
		e.preventDefault();
		return false;
	}
};

export const navigateToHomeWithDelay = (history) => {
	const localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
	const role = localData
		? JSON.parse(localData)[LOCAL_STORAGE_KEYS.ROLE]
		: "";

	let redirectUrl = getHomePageURL();
	if (role.toLowerCase() === ROLE_KEYS.HOSTESS) {
		redirectUrl = "/check-in";
		window.location.reload();
	}
	setTimeout(() => {
		history.push(redirectUrl);
	}, 2000);
};

export const getDepreciationTooltip = (label, data) => {
	switch (label) {
		case DEPRECIATION_CONSTANTS.veryGoodMax:
			const { vg_range_min, vg_range_max } = data;
			return {
				text: "Web Quote",
				range: `₹${vg_range_min} - ₹${vg_range_max}`,
				gtmData: `${vg_range_min} - ${vg_range_max}`,
			};
		case DEPRECIATION_CONSTANTS.engine:
			const {
				lower_engine_suspension_rating,
				engine_dep_percentage,
				engine_dep_range_min,
				engine_dep_range_max,
			} = data;
			return {
				text: "Engine + Suspension",
				rating: `${lower_engine_suspension_rating}`,
				depr: `${engine_dep_percentage}`,
				range:
					engine_dep_percentage === 0
						? `₹0`
						: `(₹${engine_dep_range_min} - ₹${engine_dep_range_max})`,
			};
		case DEPRECIATION_CONSTANTS.insurance:
			const {
				insurance_type,
				insurance_dep_percentage,
				insurance_dep_range_min,
				insurance_dep_range_max,
			} = data;
			return {
				text: "Insurance Type",
				keyData: { key: "Insurance Type", value: `${insurance_type}` },
				depr: `${insurance_dep_percentage}`,
				range:
					insurance_dep_percentage === 0
						? `₹0`
						: `(₹${insurance_dep_range_min} - ₹${insurance_dep_range_max})`,
			};
		case DEPRECIATION_CONSTANTS.exterior:
			const {
				exterior_rating,
				exterior_dep_percentage,
				exterior_dep_range_min,
				exterior_dep_range_max,
			} = data;
			return {
				text: "Exterior + Tyres",
				rating: exterior_rating,
				depr: `${exterior_dep_percentage}`,
				range:
					exterior_dep_percentage === 0
						? `₹0`
						: `(₹${exterior_dep_range_min} - ₹${exterior_dep_range_max})`,
			};
		case DEPRECIATION_CONSTANTS.owner:
			const {
				owner_number,
				owner_dep_percentage,
				owner_dep_range_min,
				owner_dep_range_max,
			} = data;
			return {
				text: "Owner Number",
				keyData: { key: "Owner No", value: `${owner_number}` },
				depr: `${owner_dep_percentage}`,
				range:
					owner_dep_percentage === 0
						? `₹0`
						: `(₹${owner_dep_range_min} - ₹${owner_dep_range_max})`,
			};
		case DEPRECIATION_CONSTANTS.final:
			const { final_price_range_min, final_price_range_max } = data;
			return {
				text: "Expected Quote",
				range: `₹${final_price_range_min} - ₹${final_price_range_max}`,
				gtmData: `${final_price_range_min} - ${final_price_range_max}`,
			};
		case DEPRECIATION_CONSTANTS.odometer:
			const {
				odometer_reading,
				odometer_dep_percentage,
				odometer_dep_range_min,
				odometer_dep_range_max,
			} = data;
			return {
				text: "Odometer",
				keyData: {
					key: "Odometer Reading",
					value: `${priceFormatter(odometer_reading)}`,
				},
				depr: `${odometer_dep_percentage}`,
				range:
					odometer_dep_percentage === 0
						? `₹0`
						: `(₹${odometer_dep_range_min} - ₹${odometer_dep_range_max})`,
			};

		default:
			return null;
	}
};

export const getDepreciationTooltip_New = (label, data) => {
	switch (label) {
		case DEPRECIATION_CONSTANTS_NEW.veryGoodMax:
			const { vg_range_min, vg_range_max } = data;
			return {
				text: "Web Quote",
				range: `₹${vg_range_min} - ₹${vg_range_max}`,
				gtmData: `${vg_range_min} - ${vg_range_max}`,
			};
		case DEPRECIATION_CONSTANTS_NEW.engine:
			const {
				lower_engine_suspension_rating,
				engine_dep_percentage,
				engine_dep_range_min,
				engine_dep_range_max,
			} = data;
			return {
				text: "Engine + Suspension",
				rating: `${lower_engine_suspension_rating}`,
				depr: `${engine_dep_percentage}`,
				range:
					engine_dep_percentage === 0
						? `0`
						: `(₹${engine_dep_range_min} - ₹${engine_dep_range_max})`,
			};
		case DEPRECIATION_CONSTANTS_NEW.insurance:
			const {
				insurance_type,
				insurance_dep_percentage,
				insurance_dep_range_min,
				insurance_dep_range_max,
			} = data;
			return {
				text: "Insurance Type",
				keyData: { key: "Insurance Type", value: `${insurance_type}` },
				depr: `${insurance_dep_percentage}`,
				range:
					insurance_dep_percentage === 0
						? `0`
						: `(₹${insurance_dep_range_min} - ₹${insurance_dep_range_max})`,
			};
		case DEPRECIATION_CONSTANTS_NEW.exterior:
			const {
				exterior_rating,
				exterior_dep_percentage,
				exterior_dep_range_min,
				exterior_dep_range_max,
			} = data;
			return {
				text: "Exterior + Tyres",
				rating: exterior_rating,
				depr: `${exterior_dep_percentage}`,
				range:
					exterior_dep_percentage === 0
						? `0`
						: `(₹${exterior_dep_range_min} - ₹${exterior_dep_range_max})`,
			};
		case DEPRECIATION_CONSTANTS_NEW.interior:
			// const { exterior_rating, exterior_dep_percentage, exterior_dep_range_min, exterior_dep_range_max } = data;
			return {
				text: "Interior",
				// rating: exterior_rating,
				// depr: `${exterior_dep_percentage}`,
				// range: exterior_dep_percentage === 0 ? `₹0` : `(₹${exterior_dep_range_min} - ₹${exterior_dep_range_max})`
			};
		case DEPRECIATION_CONSTANTS_NEW.owner:
			const {
				owner_number,
				owner_dep_percentage,
				owner_dep_range_min,
				owner_dep_range_max,
			} = data;
			return {
				text: "Owner Number",
				keyData: { key: "Owner No", value: `${owner_number}` },
				depr: `${owner_dep_percentage}`,
				range:
					owner_dep_percentage === 0
						? `0`
						: `(₹${owner_dep_range_min} - ₹${owner_dep_range_max})`,
			};
		case DEPRECIATION_CONSTANTS_NEW.final:
			const { final_price_range_min, final_price_range_max } = data;
			return {
				text: "Expected Quote",
				range: `₹${final_price_range_min} - ₹${final_price_range_max}`,
				gtmData: `${final_price_range_min} - ${final_price_range_max}`,
			};
		case DEPRECIATION_CONSTANTS_NEW.odometer:
			const {
				odometer_reading,
				odometer_dep_percentage,
				odometer_dep_range_min,
				odometer_dep_range_max,
			} = data;
			return {
				text: "Odometer",
				keyData: {
					key: "Odometer Reading",
					value: `${priceFormatter(odometer_reading)}`,
				},
				depr: `${odometer_dep_percentage}`,
				range:
					odometer_dep_percentage === 0
						? `0`
						: `(₹${odometer_dep_range_min} - ₹${odometer_dep_range_max})`,
			};

		default:
			return null;
	}
};
export const isAuthorizedAccess = () => {
	const localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
	const {
		role = "",
		originalRole: orgRole = "",
		isHierarchy = false,
	} = JSON.parse(localData) || {};
	const isRATRAAccess =
		isHierarchy && [ROLE_KEYS.RA_TRA,ROLE_KEYS.RA_CX_OCB].includes(orgRole) ? true : false;
	let pathName = window.location.pathname;
	let data = RETAILUSER_ROLEACCESS_PATHS;
    if(pathName.startsWith("/unallocated-leads") || pathName.startsWith("/zm-incentive") || pathName.startsWith("/ra-incentive") || pathName.startsWith("/hypo-incentive")){
        return true;
    }
	if (
		!!pathName &&
		(pathName.includes("customer-wait-time") ||
			pathName.includes("implicit"))
	) {
		return true;
	}
	if (!!role) {
		pathName = pathName.trim().replace(APPOINTMENT_ID_REGEX, "");
		if (pathName === "/") {
			if (role.toLowerCase() === ROLE_KEYS.HOSTESS) {
				pathName = "/check-in";
			}
		}
		if (isPllUser()) {
			data = PLLUSER_ROLEACCESS_PATHS;
		} else if (isC2CUser()) {
			data = C2CUSER_ROLEACCESS_PATHS;
		} else if (isRATRAAccess) {
			data = RA_TRA_RETAILUSER_ROLEACCESS_PATHS;
		}
		const rolesPaths =
			data["roleAccess"].length > 0 &&
			!!data["roleAccess"].find(
				(x) => x.role.toLowerCase() === role.toLowerCase()
			)
				? data["roleAccess"].find(
						(x) => x.role.toLowerCase() === role.toLowerCase()
				  ).paths
				: [];
		return rolesPaths.includes(pathName);
	} else {
		return true;
	}
};
export const getGraphData = (graph, data) => {
	const {
		odometer_dep_percentage,
		engine_dep_percentage,
		exterior_dep_percentage,
		insurance_dep_percentage,
		owner_dep_percentage,
	} = data;
	const finalData = [
		{
			name: "Web Quote",
			Maximum: graph.vg_range_upper,
			Minimum: 0,
			amt: 0,
		},
		{
			name: "Engine + Suspension",
			Maximum:
				engine_dep_percentage === 0
					? 200
					: graph.engine_dep_upper - graph.engine_dep_lower,
			Minimum: graph.engine_dep_lower,
			amt: 0,
		},
		{
			name: "Insurance Type",
			Maximum:
				insurance_dep_percentage === 0
					? 200
					: graph.insurance_dep_range_upper -
					  graph.insurance_dep_range_lower,
			Minimum: graph.insurance_dep_range_lower,
			amt: 0,
		},
		{
			name: "Exterior + Tyres",
			Maximum:
				exterior_dep_percentage === 0
					? 200
					: graph.exterior_dep_range_upper -
					  graph.exterior_dep_range_lower,
			Minimum: graph.exterior_dep_range_lower,
			amt: 0,
		},
		{
			name: "Owner Number",
			Maximum:
				owner_dep_percentage === 0
					? 200
					: graph.owner_dep_range_upper - graph.owner_dep_range_lower,
			Minimum: graph.owner_dep_range_lower,
			amt: 0,
		},
		{
			name: "Odometer",
			Maximum:
				odometer_dep_percentage === 0
					? 200
					: graph.odometer_dep_range_upper -
					  graph.odometer_dep_range_lower,
			Minimum: graph.odometer_dep_range_lower,
			amt: 0,
		},
		{
			name: "Expected Quote",
			Maximum: graph.final_price_range_upper,
			Minimum: 0,
			amt: 0,
		},
	];
	return finalData;
};
export const isPllCentralUser = () => {
	const { originalRole = "" } =
		JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA)) || {};
	return [ROLE_KEYS.PLL_RA_CENTER, ROLE_KEYS.PLL_ZM_CENTER].includes(
		originalRole
	);
};
export const isPllUser = () => {
	let localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
	return localData
		? JSON.parse(localData)[LOCAL_STORAGE_KEYS.PLL_USER] === true
			? true
			: false
		: false;
};
export const isCJManager = () => {
	let localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
	const { originalRole = "" } = JSON.parse(localData) || {};
	return originalRole === ROLE_KEYS.CJM;
};
export const getLMSOrinialRole = () => {
	let localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
	return localData ? JSON.parse(localData)["originalRole"] : "";
};
export const isLoginType = (role) => {
	let localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
	return localData
		? JSON.parse(localData)["role"].toLowerCase() === role
			? true
			: false
		: false;
};
export const showCovidBanner = () => {
	let localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
	return localData
		? JSON.parse(localData)[LOCAL_STORAGE_KEYS.COVID_BANNER] === true
			? true
			: false
		: false;
};
export const showPaiRaiseWarning = () => {
	let localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
	return localData
		? JSON.parse(localData)[LOCAL_STORAGE_KEYS.PAI_RAISE_WARNING] === 1
			? true
			: false
		: false;
};
export const showMyIncentiveDashboard = () => {
	let localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
	return localData
		? JSON.parse(localData)[
				LOCAL_STORAGE_KEYS.IS_INCENTIVE_DASHBOARD_VISIBLE
		  ] === true
			? true
			: false
		: false;
};
export const getCurrentUserRole = () => {
	let localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
	return localData ? JSON.parse(localData)[LOCAL_STORAGE_KEYS.ROLE] : "";
};
export const isUserPllAndRetail = () => {
	let returnValue = false;
	let localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
	const { role = "", originalRole = "" } = JSON.parse(localData) || {};
	if (
		[ROLE_KEYS.RM, ROLE_KEYS.CORP].includes(role.toLowerCase()) &&
		[
			ROLE_KEYS.TM,
			ROLE_KEYS.PLL_RH,
			ROLE_KEYS.CITY_HEADS,
			ROLE_KEYS.CORP_EDITOR,
			ROLE_KEYS.CORP_VIEWER,
			ROLE_KEYS.PLL_CH,
		].includes(originalRole)
	) {
		returnValue = true;
	}
	return returnValue;
};
export const getLoginData = () => {
	const localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
	return JSON.parse(localData) || {};
};
export const isC2CUser = () => {
	let localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
	return localData
		? JSON.parse(localData)[LOCAL_STORAGE_KEYS.C2C_USER] === true
			? true
			: false
		: false;
};
export const isBikeSource = () => {
	const selectedBaseAPIHostKey = localStorage.getItem(
		LOCAL_STORAGE_KEYS.SELECTED_BASE_API_HOST
	);
	return selectedBaseAPIHostKey === APP_SOURCE.BIKE ? true : false;
};

export const getLoginUserMenuHeaders = (data) => {
	const { tab=TAB_KEYS.RETAIL } = getLoginData();
	const flags = tab === TAB_KEYS.RT ? ["3"] : tab === TAB_KEYS.PLL ? ["1", "2"] : ["0", "2"];
	const { leftMenu = {}, header = {} } = data || {};
	const { isHierarchy = false, isPll=false } = getLoginData();
	// left panel menu
	const menus = Object.values(leftMenu).map((item) => {
		const uiElement = MASTER_SIDE_BAR[item.pKey];
		let subparts = [];
		if (item.child) {
			const subMenus =
				!!item.child &&
				item.child
					.filter((item) => !!item.pKey && flags.includes(item.isPll))
					.sort((a, b) => a.displayOrder - b.displayOrder);
			subparts =
				!!subMenus &&
				subMenus.map((element) => {
					return {
						...element,
						label: element.pText,
						filter: element.pKey,
					};
				});
		}
		return {
			string: item.pKey,
			...item,
			...uiElement,
			text: item.pText,
			isPll: item.isPll || "0",
			subparts,
		};
	});
	const sortedMenus = menus
		.filter((item) => !!item.pKey && flags.includes(item.isPll))
		.sort((a, b) => a.displayOrder - b.displayOrder);

	//header menus
	const headers = Object.values(header).map((item) => {
		return {
			string: item.pKey,
			...item,
			text: item.pText,
			isPll: item.isPll || "0",
		};
	});
	
	const filteredHeaders = headers.filter((item)=> {
		// item.text === "allocate" && isHierarchy && !isPll
		if (item.text !== "allocate") {
			return item;
		} else  { 
			if (isPll || isHierarchy) {
				return item;
			} else {
				return null;
			}
		}
	});
	const sortedHeaders = filteredHeaders
		.filter((item) => !!item.pKey && flags.includes(item.isPll))
		.sort((a, b) => a.displayOrder - b.displayOrder);
	return { leftMenu: sortedMenus, header: sortedHeaders };
};

export const navigationFilterForUser = () => {
	if (isPllUser()) {
		return PLL_USER_NEGOTIATION_FILTERS;
	} else {
		return NEGOTIATION_FILTERS;
	}
};

export const getHomePageByRole = () => {
	const localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
	saveToLocalStorage(LOCAL_STORAGE_KEYS.NO_ACCESS, YES_NO.NO);
	const role = localData
		? JSON.parse(localData)[LOCAL_STORAGE_KEYS.ROLE]
		: "";
	if (role.toLowerCase() === ROLE_KEYS.HOSTESS) return "/check-in";
	else return `/${getHomePageURL()}`;
};

export const getIndividualOrStoreName = () => {
	let name = "";
	const leadType = getFromLocalStorage(LOCAL_STORAGE_KEYS.LEADS);
	const individualName = getFromLocalStorage(
		LOCAL_STORAGE_KEYS.INDIVIDUAL_NAME
	);
	switch (leadType) {
		case LEAD_TYPE.STORE:
			name = getStoreName();
			break;
		case LEAD_TYPE.INDIVIDUAL:
			name = !!individualName ? individualName : "";

			break;
		default:
			return "";
	}
	return name;
};

const getStoreName = () => {
	let storeName = "";
	const cityStores = getFromLocalStorage(LOCAL_STORAGE_KEYS.CITY_STORES_LIST);
	const selectedStoreList = getFromLocalStorage(
		LOCAL_STORAGE_KEYS.SELECTED_STORES
	);
	const selectedStore = !!selectedStoreList
		? selectedStoreList.split(",")
		: [];
	if (selectedStore.length > 1) {
		storeName = "Multiple Stores";
	} else {
		let cityStoreList = !!cityStores ? JSON.parse(cityStores) : null;
		if (
			cityStoreList == null &&
			selectedStore.length === 1 &&
			selectedStore[0] !== ""
		) {
			const localData = getFromLocalStorage(
				LOCAL_STORAGE_KEYS.LOGIN_DATA
			);
			storeName = localData
				? JSON.parse(localData)[LOCAL_STORAGE_KEYS.STORE_NAME]
				: "";
		} else if (cityStoreList != null) {
			storeName = cityStoreList.find(
				(st) => st.idLmsStore === selectedStore[0]
			);
			storeName = !!storeName ? storeName.name : "";
		}
	}
	return storeName;
};

export const getStoreIdForSingleStoreAssignned = () => {
	let storeId = 0;
	const selectedStoreList = getFromLocalStorage(
		LOCAL_STORAGE_KEYS.SELECTED_STORES
	);
	const selectedStore = !!selectedStoreList
		? selectedStoreList.split(",")
		: [];
	if (!!selectedStore && selectedStore.length === 1) {
		storeId = selectedStore[0];
	}
	return storeId;
};

export const getStoreIdForSingleOrDoubleStoreAssignned = () => {
	let storeIds = [];
	const selectedStoreList = getFromLocalStorage(
		LOCAL_STORAGE_KEYS.SELECTED_STORES
	);
	const selectedStore = !!selectedStoreList
		? selectedStoreList.split(",")
		: [];
	if (!!selectedStore && selectedStore.length < 3) {
		storeIds = selectedStore;
	}
	return storeIds;
};

export const getUid = () => {
	const localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
	return localData ? JSON.parse(localData)[LOCAL_STORAGE_KEYS.UID] : "";
};

export const dateToFromNow = (date) => {
	if (date) {
		// var fromNow = moment(date).fromNow();
		return moment(date).calendar(null, {
			specifycustomvalueslastWeek: "[Last] dddd",
			lastDay: "[Yesterday] hh:mm A",
			sameDay: "[Today] hh:mm A",
			nextDay: "[Tomorrow] hh:mm A",
			lastWeek: "DD/MM/YYYY hh:mm A",
			nextWeek: "DD/MM/YYYY hh:mm A",
			sameElse: function () {
				// return "[" + fromNow + "]";
				return "DD/MM/YYYY hh:mm A";
			},
		});
	} else {
		return "";
	}
};
export const TimeRemainingFromNow = (datEnd, dateStart) => {
	if (datEnd) {
		var totalMinutes = 30;
		var dateFuture = new Date(datEnd);
		var dateNow = new Date();

		var seconds = Math.floor((dateFuture - dateNow) / 1000);
		var minutes = Math.floor(seconds / 60);
		var hours = Math.floor(minutes / 60);
		var days = Math.floor(hours / 24);

		if (!!dateStart) {
			totalMinutes = Math.floor(
				(dateFuture - new Date(dateStart)) / (1000 * 60)
			);
			var totalhours = Math.floor(totalMinutes / 60);
			var totaldays = Math.floor(hours / 24);
			totalMinutes = totalMinutes - totaldays * 24 * 60 - totalhours * 60;
		}
		hours = hours - days * 24;
		minutes = minutes - days * 24 * 60 - hours * 60;
		seconds =
			seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
		return {
			Days: days,
			Hours: hours,
			Minute: minutes,
			Seconds: seconds,
			TotalMinutes: totalMinutes,
		};
	} else {
		return "";
	}
};

export const ExpireInDays = (date) => {
	if (date) {
		const createdDate = new Date(date).toDateString();
		const expiryDate = new Date(
			new Date(createdDate).setDate(new Date(createdDate).getDate() + 3)
		);
		const currentDate = new Date(new Date().toDateString());
		const diffTime = Math.abs(expiryDate.getTime() - currentDate.getTime());
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		return diffDays > 0 ? `in ${diffDays} days` : "today";
	} else {
		return "";
	}
};

export const removeDuplicates = (originalArray, prop) => {
	var newArray = [];
	var lookupObject = {};

	for (var i in originalArray) {
		lookupObject[originalArray[i][prop]] = originalArray[i];
	}

	for (i in lookupObject) {
		newArray.push(lookupObject[i]);
	}
	return newArray;
};

export const getUnique = (arr, comp) => {
	const seen = new Set();
	// Traverse the array from end to start to capture the last occurrence
	const unique = arr
		.slice()
		.reverse()
		.filter((item) => {
			// Check if the value has already been seen
			if (seen.has(item[comp])) {
				return false; // Skip if already seen
			}
			seen.add(item[comp]); // Add to the set if it's the first time encountered
			return true; // Keep the item
		})
		.reverse(); // Reverse to restore the original order

	return unique;
};

export const reloadWithDelay = (time) => {
	setTimeout(() => {
		window.location.reload();
	}, [time]);
};

export const create_UUID = () => {
	var dt = new Date().getTime();
	var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
		/[xy]/g,
		function (c) {
			var r = (dt + Math.random() * 16) % 16 | 0;
			dt = Math.floor(dt / 16);
			return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
		}
	);
	return uuid;
};

export const getUniqueId = (length, characters) => {
	var result = "";
	// var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(
			Math.floor(Math.random() * charactersLength)
		);
	}
	return result;
};
export const getWaitTimeDashBoardLocalStaorageKey = (storeId) => {
	let uid = "";
	let waitTimeData = getFromLocalStorage(
		LOCAL_STORAGE_KEYS.WAITTIME_DASHBOARD_UID
	);
	let waitTimeArr = waitTimeData ? JSON.parse(waitTimeData) : [];
	if (!!waitTimeArr && waitTimeArr.length > 0) {
		let find = waitTimeArr.find((x) => x.storeID == storeId);
		if (!!find) {
			return find.uid;
		} else {
			return "";
		}
	} else {
		return uid;
	}
};
export const setWaitTimeDashBoardLocalStaorageKey = (storeId, uid) => {
	let waitTimeUidArray = [];
	let waitTimeObj = {
		storeID: storeId,
		uid: uid,
	};
	waitTimeUidArray.push(waitTimeObj);
	// let abc =
	saveToLocalStorage(
		LOCAL_STORAGE_KEYS.WAITTIME_DASHBOARD_UID,
		JSON.stringify(waitTimeUidArray)
	);
};

export const createCookie = (name, value, days, domain) => {
	var expires = "";
	var _domain = "";
	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		expires = "; expires=" + date.toGMTString();
	} else expires = "";

	if (domain) {
		_domain = "; domain=" + domain;
	}
	document.cookie = name + "=" + value + expires + _domain + "; path=/;";
};
export const readCookie = (name) => {
	// alert("app.js file");

	var nameEQ = name + "=";
	var ca = document.cookie.split(";");
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) === " ") c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) === 0)
			return c.substring(nameEQ.length, c.length);
	}
	return null;
};
export const isPHomeInspectionStore = () => {
	let localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
	return localData
		? !!JSON.parse(localData)[LOCAL_STORAGE_KEYS.STORE_TYPE] &&
		  JSON.parse(localData)[LOCAL_STORAGE_KEYS.STORE_TYPE] ===
				LOCAL_STORAGE_KEYS.STORE_LOCALITY
			? true
			: false
		: false;
};
export const GetQueryString = (field) => {
	var href = window.location.href;
	var reg = new RegExp("[?&]" + field + "=([^&#]*)", "i");
	var string = reg.exec(href);
	return string ? string[1] : null;
};

export const GetQueryStringFromUrl = (url, field) => {
	var href = url;
	var reg = new RegExp("[?&]" + field + "=([^&#]*)", "i");
	var string = reg.exec(href);
	return string ? string[1] : null;
};

export const getEventCategory = (report) => {
	let reportLabelForEvent = "Negotiation Report";
	if (!!report && report.toLowerCase() === "In-negotiation") {
		reportLabelForEvent = "Negotiation Report";
	} else if (!!report && report.toLowerCase() === "inspected") {
		reportLabelForEvent = "Inspected Report";
	} else if (!!report && report.toLowerCase() === "depriciation") {
		reportLabelForEvent = "Inspected Report";
	}
	return reportLabelForEvent;
};

export const isSelectedStorePHomeInspectionStore = () => {
	let isStoreHomeInspection = false;
	let storeName = "";
	const cityStores = getFromLocalStorage(LOCAL_STORAGE_KEYS.CITY_STORES_LIST);
	const selectedStoreList = getFromLocalStorage(
		LOCAL_STORAGE_KEYS.SELECTED_STORES
	);
	const selectedStore = !!selectedStoreList
		? selectedStoreList.split(",")
		: [];
	if (selectedStore.length > 1) {
		isStoreHomeInspection = false;
	} else {
		let cityStoreList = !!cityStores ? JSON.parse(cityStores) : null;
		if (
			cityStoreList == null &&
			selectedStore.length === 1 &&
			selectedStore[0] !== ""
		) {
			isStoreHomeInspection = false;
		} else if (cityStoreList != null) {
			storeName = cityStoreList.find(
				(st) => st.idLmsStore === selectedStore[0]
			);
			if (!!storeName && storeName.type === "locality") {
				isStoreHomeInspection = true;
			}
		}
		// isStoreHomeInspection = true;
	}
	return isStoreHomeInspection;
};

export const IsConfirmedNotConfirmedViewOfSchedule = () => {
	let bFlag = false;
	const localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
	let hiCities = localData
		? JSON.parse(localData)[LOCAL_STORAGE_KEYS.HI_CONFIGURED_CITIES]
		: "";
	// hiCities = ["New Delhi", "Gurgaon"];
	if (!!hiCities) {
		const cityStoresList = getFromLocalStorage(
			LOCAL_STORAGE_KEYS.SELECTED_CITIES
		);
		const arrCityStoreList = cityStoresList
			? JSON.parse(cityStoresList)
			: [];
		const cities = [
			...new Set(arrCityStoreList.map((item) => item.storeCity)),
		];
		// bFlag = isSubArray(hiCities, cities);
		bFlag = isSubArray(cities, hiCities);
	}
	return bFlag;
};

const isSubArray = (source, target) => {
	return source.every((i) => target.includes(i));
};

export const getUniqueStoreForCities = () => {
	let stores = [];
	const _cityStoresList = getFromLocalStorage(
		LOCAL_STORAGE_KEYS.SELECTED_CITIES
	); //getFromLocalStorage(LOCAL_STORAGE_KEYS.CITY_STORES_LIST);
	const cityStoresList = !!_cityStoresList ? JSON.parse(_cityStoresList) : [];
	const cities = [...new Set(cityStoresList.map((item) => item.storeCity))];
	cities.forEach((item) => {
		let _storeCity = cityStoresList.find((x) => x.storeCity === item);
		if (!!_storeCity) {
			stores.push(_storeCity.idLmsStore);
		}
	});

	return !!stores?.length ? stores.join(",") : "";
};

export const getRandomNumberBetweenRange = (min, max) => {
	return Math.floor(Math.random() * (max - min)) + min;
};
export const getPercentIncreased = (number, minutes, percentage) => {
	let count = 0;
	if (minutes < 4) {
		count = number;
	} else {
		if (!!percentage && percentage.length > 0) {
			let dealerImpression = percentage.find(
				(x) => x.min === getMinuteRange(minutes)
			);
			if (!!dealerImpression) {
				let percent = dealerImpression.prcnt;
				count = Math.floor((number * percent) / 100);
			}
		} else {
			count = Math.floor((number * getPercent(minutes)) / 100);
		}
	}
	return count;
};
const getMinuteRange = (minute) => {
	if (minute >= 4 && minute < 8) {
		return 8;
	} else if (minute >= 8 && minute < 12) {
		return 12;
	} else if (minute >= 12 && minute < 16) {
		return 16;
	} else if (minute >= 16 && minute < 20) {
		return 20;
	} else if (minute >= 20 && minute < 24) {
		return 24;
	} else if (minute >= 24 && minute < 28) {
		return 28;
	} else if (minute >= 28 && minute < 32) {
		return 32;
	} else {
		return 32;
	}
};
const getPercent = (minutes) => {
	switch (minutes) {
		case 4:
		case 5:
		case 6:
		case 7:
			return 100;
		case 8:
		case 9:
		case 10:
		case 11:
			return 145;
		case 12:
		case 13:
		case 14:
		case 15:
			return 205;
		case 16:
		case 17:
		case 18:
		case 19:
			return 255;
		case 20:
		case 21:
		case 22:
		case 23:
			return 365;
		case 24:
		case 25:
		case 26:
		case 27:
			return 455;
		case 28:
		case 29:
		case 30:
		case 31:
			return 480;
		case 32:
			return 480;
		default:
			return 500;
	}
};
export const getDiffDaysFromToday = (date) => {
	const today = new Date();
	const customDate = new Date(date);
	return Math.abs(Math.floor((today - customDate) / 86400000));
};

export const getNextActiveDays = (daysRequired) => {
	let days = [];
	// let daysRequired = 7;

	for (let i = 0; i <= daysRequired; i++) {
		days.push(moment().add(i, "days").format("YYYY-MM-DD"));
	}

	return days;
};

export const getDiySortOrderKey = (diySortOrder, isPll = false) => {
	switch (diySortOrder) {
		case 1:
			return DIY_SORT_ORDER.SO_NO_PRICE_CHANGE;
		case 2:
			return DIY_SORT_ORDER.SO_PRICE_NOT_ACCEPTED;
		case 3:
			return DIY_SORT_ORDER.SO_PRICE_ACCEPTED;
		case 4:
			return DIY_SORT_ORDER.HI_PRICE_NOT_ACCEPTED;
		case 5:
			return DIY_SORT_ORDER.HI_PRICE_ACCEPTED;
		case 6:
			return DIY_SORT_ORDER.HI_GS_PRICE_NOT_ACCEPTED;
		case 7:
			return DIY_SORT_ORDER.HI_GS_PRICE_ACCEPTED;
		case 8:
			return DIY_SORT_ORDER.HI_NON_GS_PRICE_NOT_ACCEPTED;
		case 9:
			return DIY_SORT_ORDER.HI_NON_GS_PRICE_ACCEPTED;
		case 10:
			return DIY_SORT_ORDER.DIY_PAI_RUNNING;
		case 11:
			return !!isPll
				? DIY_SORT_ORDER.DIY_PLL_DEFAULT_VIEW
				: DIY_SORT_ORDER.DIY_PAI_ACCEPTED;
		case 12:
			return !!isPll
				? DIY_SORT_ORDER.DIY_PLL_AUCTION_RUNNING
				: DIY_SORT_ORDER.DIY_OCB_RUNNING_AT;
		case 13:
			return !!isPll
				? DIY_SORT_ORDER.DIY_PLL_PAI_RUNNING
				: DIY_SORT_ORDER.DIY_OCB_ACCEPTED;
		case 14:
			return !!isPll
				? DIY_SORT_ORDER.DIY_PLL_OCB_OR_PAI_ACCEPTED
				: DIY_SORT_ORDER.DIY_OCB_EXPIRED;
		case 15:
			return !!isPll
				? DIY_SORT_ORDER.DIY_PLL_OCB_RUNNING
				: DIY_SORT_ORDER.DIY_PARTIAL_PAI_NEGO_RETAIL;
		case 16:
			return !!isPll
				? DIY_SORT_ORDER.DIY_PLL_OCB_EXPIRED
				: DIY_SORT_ORDER.DIY_PARTIAL_PAI_NEGO_RETAIL_PR;
		case 17:
			return !!isPll
				? DIY_SORT_ORDER.DIY_PLL_PARTIAL_OCB_RUNNING
				: DIY_SORT_ORDER.DIY_SUSPENDED;
		case 18:
			return DIY_SORT_ORDER.DIY_PLL_PARTIAL_OCB_EXPIRED;
		case 19:
			return !!isPll
				? DIY_SORT_ORDER.DIY_PLL_INVENTORY
				: DIY_SORT_ORDER.DIY_AUCTION_RUNNING;
		case 20:
			return !!isPll
				? DIY_SORT_ORDER.DIY_PLL_CEPENABLED
				: DIY_SORT_ORDER.DIY_INTERNAL_BID_5;
		case 21:
			return !!isPll
				? DIY_SORT_ORDER.DIY_PLL_CUST_ACCEPT_PRICE
				: DIY_SORT_ORDER.DIY_INTERNAL_BID_6;
		case 22:
			return DIY_SORT_ORDER.DIY_PLL_SELLER_INTENT;
		case 23:
			return DIY_SORT_ORDER.DIY_RA_NEGO_VIEW;
		case 24: 
			return DIY_SORT_ORDER.DIY_DIRECT_OCB_NA;
		case 25:
			return DIY_SORT_ORDER.DIY_DIRECT_OCB_EXPIRED;
		default:
			return 41;
	}
};

export const seDealerCountLocalStaorageKey = (appointMentId, count) => {
	try {
		let _appointMentId = parseInt(appointMentId);
		let dealerCountFromStorage = getFromLocalStorage(
			LOCAL_STORAGE_KEYS.DEALER_COUNT_REACHED
		);
		let jsonDealerStorage = [];
		let newjsonDealerStorage = [];
		let returnCount = count;
		if (dealerCountFromStorage != null) {
			jsonDealerStorage = JSON.parse(dealerCountFromStorage);
			newjsonDealerStorage = JSON.parse(dealerCountFromStorage);
			if (!!jsonDealerStorage && jsonDealerStorage.length > 0) {
				let apptData = newjsonDealerStorage.find(
					(x) => x.appointMentId === _appointMentId
				);
				if (!!apptData) {
					returnCount = apptData.delaerCount;
				} else {
					let waitTimeObj = {
						appointMentId: _appointMentId,
						delaerCount: count,
					};
					jsonDealerStorage.push(waitTimeObj);
				}
			} else {
				let waitTimeObj = {
					appointMentId: _appointMentId,
					delaerCount: count,
				};
				jsonDealerStorage.push(waitTimeObj);
			}
		} else {
			let waitTimeObj = {
				appointMentId: _appointMentId,
				delaerCount: count,
			};
			jsonDealerStorage.push(waitTimeObj);
		}
		saveToLocalStorage(
			LOCAL_STORAGE_KEYS.DEALER_COUNT_REACHED,
			JSON.stringify(jsonDealerStorage)
		);
		return returnCount;
	} catch (e) {
		return count;
	}
};

export const setDealerImpressionStartLocalStaorageKey = (
	appointMentId,
	count
) => {
	try {
		let _appointMentId = parseInt(appointMentId);
		let dealerCountFromStorage = getFromLocalStorage(
			LOCAL_STORAGE_KEYS.DEALER_IMPRESSION_START
		);
		let jsonDealerStorage = [];
		let newjsonDealerStorage = [];
		let returnCount = count;
		if (dealerCountFromStorage != null) {
			jsonDealerStorage = JSON.parse(dealerCountFromStorage);
			newjsonDealerStorage = JSON.parse(dealerCountFromStorage);
			if (!!jsonDealerStorage && jsonDealerStorage.length > 0) {
				let apptData = newjsonDealerStorage.find(
					(x) => x.appointMentId === _appointMentId
				);
				if (!!apptData) {
					returnCount = apptData.delaerCount;
				} else {
					let waitTimeObj = {
						appointMentId: _appointMentId,
						delaerCount: count,
					};
					jsonDealerStorage.push(waitTimeObj);
				}
			} else {
				let waitTimeObj = {
					appointMentId: _appointMentId,
					delaerCount: count,
				};
				jsonDealerStorage.push(waitTimeObj);
			}
		} else {
			let waitTimeObj = {
				appointMentId: _appointMentId,
				delaerCount: count,
			};
			jsonDealerStorage.push(waitTimeObj);
		}
		saveToLocalStorage(
			LOCAL_STORAGE_KEYS.DEALER_IMPRESSION_START,
			JSON.stringify(jsonDealerStorage)
		);
		return returnCount;
	} catch (e) {
		return count;
	}
};

export const openUrlTab = (url, type = "_blank") => {
	if (url) {
		const myWindow = window.open(url, type);
		return myWindow;
	} else {
		return false;
	}
};

export const openInNewTab = (url) => {
	const newWindow = window.open(url, "_blank", "noopener,noreferrer");
	if (newWindow) newWindow.opener = null;
};

export const getTimeDiffinMinsFromNow = (startDatetime) => {
	let startDate = new Date(startDatetime);
	let currentDate = new Date();
	let dateDifference = currentDate.getTime() - startDate.getTime();
	return Math.ceil(dateDifference / (60 * 1000));
};

const getNormalPrice = (price, allowZero = false, country = "IN") => {
	const countryLocale = {
		IN: "en-IN",
		AE: "en-AE",
		AU: "en-AU",
	};
	return Number(price || (allowZero ? "0" : "")).toLocaleString(
		countryLocale[country] || "en-US"
	);
};

export const getPrice = (price, text = false, fixedUpTo = NUMBERS.TWO) => {
	let val = Math.abs(price);
	if (val >= NUMBERS.ONE_CR) {
		val = `${(val / NUMBERS.ONE_CR).toFixed(fixedUpTo)} ${
			text ? " Cr" : ""
		}`;
	} else if (val >= NUMBERS.ONE_LAKH) {
		val = `${(val / NUMBERS.ONE_LAKH).toFixed(fixedUpTo)} ${
			text ? " Lakhs" : ""
		}`;
	} else if (val <= NUMBERS.ONE_LAKH) {
		val = getNormalPrice(price);
	}
	return val;
};

export const getHomePageURL = () => {
	const { originalRole = "", tab } = getLoginData();
	if ([ROLE_KEYS.CJM].includes(originalRole)) {
		return APPOINTMENT_TYPES_STRING.SCHEDULED;
	} else if ([ROLE_KEYS.RA_LM].includes(originalRole)) {
		return APPOINTMENT_TYPES_STRING.LEAD_MON;
	} else if (tab === TAB_KEYS.RT) { 
		return APPOINTMENT_TYPES_STRING.CENTRAL_NEGOTIATION;
	}
	else {
		return APPOINTMENT_TYPES_STRING.NEGOTIATION;
	}
};
