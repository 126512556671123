import React, {useState} from 'react';
import styles from './reason-confirm-popup.module.scss';
import Textarea from '../shared/textarea/component';
import Button from '../shared/button/component';
import { AppointmentService } from "../../service";
import { showToastMessages } from "../../utils/helper";
const ReasonConfirmPopup = ({onClose, appointmentId, auctionId}) => {
  const [reasonText, setReasonText] = useState("");
  const onReasonTextChange = (e) => {
    const { target: { value } } = e;
    if (value.length <= 200){
      setReasonText(value);
    }
  };
  const postRaisePIReason = () => {
    const payload = {
      "appointmentId": appointmentId,
      "type": "raise_pai",
      "auction_id": auctionId,
      "comment": reasonText
    };
    AppointmentService.sendDepReportFeedBack(payload)
      .then(resp => {
        const { data: { detail } } = resp;
        showToastMessages(detail, true);
      })
      .catch(err => {
        const { detail } = err;
        showToastMessages(detail, false);

      });
    onClose(false);
  };
  return (
    <div className={styles.reasonConfirmPopup}>
      <p className={styles.text}>{'Kindly share the reason for raising the PAI  in very low probability.'}</p>
      <Textarea placeholder={'This is a reason text.'} onChange={onReasonTextChange} value={reasonText} maxlength="200"/>
      <div className={styles.ctaWrapper}>
        <Button ctaText= {'Confirm'} classNames="transparentBtn" onClick = {() => postRaisePIReason()}/>
      </div>
    </div>
  );
};

export default ReasonConfirmPopup;
