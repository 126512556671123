import { createTypes } from "reduxsauce";

export default createTypes(
  `
    FETCH_LOGIN
    POST_LOGIN_SUCCESS
    POST_LOGIN_FAILURE
    RESET_LOGIN
    UPDATE_CHANNEL_SUCCESS
    SET_ACTIVE_CHANNEL
    FILTER_CHANNELS
    SELECTED_STORES
`,
  {
    prefix: "login/"
  }
);
