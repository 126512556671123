import React, { useState } from "react";
import Loader from "../../../components/shared/loader";
import LeadCarDetails from "../../components/lead-car-details";
import LeadProcurementCard from "../../components/lead-procurement-card";
import styles from "./lead-procurement.module.scss";
const LeadProcurement = () => {
  const [isHomeGetPriceClicked, setIsHomeGetPriceClicked] = useState(false);
  const [isLoading, setLoading] = useState(false);
  return (
    <div className={styles.mainWrapper}> {
            !!isLoading ? 
              <Loader /> :
              <div className={styles.leadProcurement}>
                {!isHomeGetPriceClicked ? 
                  <LeadProcurementCard setIsHomeGetPriceClicked={setIsHomeGetPriceClicked} setLoading={setLoading}/> : 
                  <LeadCarDetails setIsHomeGetPriceClicked={setIsHomeGetPriceClicked} setLoading={setLoading}/>}
              </div>
    }   
    </div>
  );
};

export default LeadProcurement;
