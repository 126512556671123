import React, { useState } from "react";
import Button from "../shared/button/component";
import { ACTIONS_BUTTON, ROLE_KEYS } from "../../utils/app-constants";
import Popup from "../popup";
import LeadCheckInDenyPopup from "../lead-check-in-deny-popup";

const LeadCheckInDeny = ({
  history,
  rowDetails,
  role,
  selectedStore,
  setSelectedStoreError,
  resetAndFetchData,
}) => {
  const { appointment } = rowDetails;
  const [open, setOpen] = useState(false);
  const onOpen = () => {
    if (isValidStoreId()) {
      setOpen(true);
    } else {
      setSelectedStoreError(true);
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  const isValidStoreId = () => {
    if (role.toLowerCase() === ROLE_KEYS.RM) {
      if (selectedStore)
        return true;
      else
        return false;
    } else
      return true;
  };

  return (
    <>
      <div>
        <Button ctaText={ACTIONS_BUTTON.SCHEDULED_CHECK_IN} onClick={() => onOpen(appointment)} classNames="smallCta" />
      </div>
      <Popup isOpen={open} close={onClose} >
        <LeadCheckInDenyPopup history={history} selectedStore={selectedStore} role={role} rowDetails={rowDetails} onCancelClick={onClose} resetAndFetchData={resetAndFetchData} />
      </Popup>
    </>
  );
};

export default LeadCheckInDeny;
