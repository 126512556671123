import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./book-inspection-popup.module.scss";
import { APP_CONSTANTS, SO_SCREEN_TYPES } from "../../common/app-constants";
import { INITIAL_STATE } from "./dataModel";
import { PAGE_TYPE } from "./constants";
import { NUMBERS } from "../../../utils/app-constants";
import customLoc from "../../common/images/customLoc.svg";
import defaultLoc from "../../common/images/defaultLoc.svg";
import redInfo from "../../common/images/red-info-icon.svg";
import backButton from "../../common/images/back-button.svg";
import Loader from "../../../components/shared/loader";
import { LocationService } from "../../service";
import { getDiffDaysFromToday } from "../../../utils/helper";
import moment from "moment";
import { LeadService } from "../../service";
import LocationPicker from "../location-picker";
import { DealerService } from "../../service";
const BookInspection = ({
	rowDetails,
	leadDetail,
	FetchLeadDetailConnect,
	onGoBack,
	appointmentStatus,
}) => {
	const { token } = rowDetails || {};
	const [timeSlots, setTimeSlots] = useState([]);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [slotData, setSlotData] = useState();
	const [data, setData] = useState(INITIAL_STATE);
	const history = useHistory();
	const [customLocation, setCustomLocation] = useState();
	const [appointmentData, setAppointmentData] = useState();
	const [isLocationServicable, setIsLocationServicable] = useState(true);
	const [isClickComplete, setIsClickComplete] = useState(false);
	useEffect(() => {
		setData((data) => ({
			...data,
			pageTitle: appointmentStatus,
			isReschedule: appointmentStatus === APP_CONSTANTS.RESCHEDULE_APPOINETMENT,
		}));
		appointmentStatus === APP_CONSTANTS.RESCHEDULE_APPOINETMENT &&
			!!token &&
			DealerService.getAppointmentDetails(token)
				.then((resp) => {
					const { appointmentGeoData = {} } = resp.detail;
					setIsClickComplete(true);
					setAppointmentData(appointmentGeoData);
				})
				.catch((err) => {
					console.log({ err });
					setAppointmentData();
				});
	}, [appointmentStatus, token]);

	useEffect(() => {
		if (token) {
			setIsDataLoading(true);
			FetchLeadDetailConnect(token).finally(() => {
				setIsDataLoading(false);
			});
		}
	}, [token]);

	useEffect(() => {
		if (
			isClickComplete &&
			(data.isDealerCenter || data.isCustom || !!appointmentData)
		) {
			let curLat = "",
				curLng = "";
			if (appointmentData && data.isReschedule) {
				curLat = appointmentData?.user_lat;
				curLng = appointmentData?.user_lng;
			} else if (data.isDealerCenter && leadDetail) {
				const { latitude, longitude } = leadDetail.b2bDealer || {};
				curLat = latitude;
				curLng = longitude;
			} else if (data.isCustom && !!customLocation) {
				curLat = customLocation?.coords?.lat;
				curLng = customLocation?.coords?.lng;
			}
			if (!!curLat && !!curLng) {
				setIsDataLoading(true);
				setIsClickComplete(false);
				LocationService.getStoreAvailableSlots({
					lat: curLat,
					lng: curLng,
					token,
				})
					.then((resp) => {
						const { slots, zone } = resp.detail || {};
						setIsLocationServicable(true);
						if (!!zone) {
							setData({
								...data,
								page: PAGE_TYPE.DEALER_LOCATION,
								lat: curLat,
								lng: curLng,
								...zone,
								pincode: data.isCustom ?  customLocation?.pincode : leadDetail?.postal_code,
								pnsLocationId: zone.pns_location_id,
								...(!!appointmentData && {
									address1: appointmentData?.address1,
									address2: appointmentData?.address2,
								}),
								customAddress: !!appointmentData && data.isReschedule
									? appointmentData?.appointment_address
									: data.isCustom
									? customLocation?.formattedAddress
									: leadDetail?.b2bDealer?.centreAddress,
							});
							const dateSlots = slots && slots[zone.location_id]?.date_slot;
							setSlotData(dateSlots);
						} else {
							setIsLocationServicable(false);
							setData({
								...data,
								page: PAGE_TYPE.DEALER_LOCATION,
								lat: curLat,
								lng: curLng,
								customAddress:
									customLocation?.formattedAddress || customLocation?.label,
							});
						}
					})
					.catch((err) => {
						console.log({ excp: err });
						setData({
							...data,
							page: PAGE_TYPE.DEALER_LOCATION,
							lat: curLat,
							lng: curLng,
							customAddress: customLocation?.formattedAddress || "",
						});
						setIsLocationServicable(false);
					})
					.finally(() => {
						setIsDataLoading(false);
					});
			}
		}
	}, [
		token,
		leadDetail,
		customLocation,
		data.isDealerCenter,
		data.isCustom,
		isClickComplete,
		appointmentData,
	]);

	const resetData = () => {
		setIsLocationServicable(true);
		setSlotData();
		setTimeSlots();
		setCustomLocation();
	};

	const onDealerCenterClick = () => {
		resetData();
		setIsClickComplete(true);
		setData({ ...INITIAL_STATE, isDealerCenter: true, isCustom: false, isReschedule: false, });
	};

	const onCustomerCenterClick = () => {
		resetData();
		setIsClickComplete(false);
		setData((prev) => ({
			...prev,
			isDealerCenter: false,
			isCustom: true,
			isReschedule: false,
			page: PAGE_TYPE.CUSTOM_LOCATION,
		}));
	};
	
	const onBookInspectionClick = ({ value }) => {
		switch (value) {
			case PAGE_TYPE.ENTER_ADDRESS:
				setData({
					...data,
					page: PAGE_TYPE.ENTER_ADDRESS,
					pageTitle: APP_CONSTANTS.ENTER_ADDRESS,
				});
				break;
			case PAGE_TYPE.CONFIRM_INSPECTION:
				setData({
					...data,
					page: PAGE_TYPE.ENTER_ADDRESS,
					pageTitle: APP_CONSTANTS.ENTER_ADDRESS,
				});
				if (!data.address1 || !data.address2) {
					return;
				}
				setIsDataLoading(true);
				const params = {
					appointment: {
						date: data.date || moment().format("YYYY-MM-DD"),
						device_category: "m-web",
						fullname: data.contactName,
						loc: NUMBERS.SIX,
						location: data.pnsLocationId,
						phone: data.phone,
						time: data.time || "10:00:00",
						city_id: data.city_id,
						appointment_type: leadDetail.lead_type,
						pincode: data.pincode,
						zone_id: data.zone_id,
						autoverify: APP_CONSTANTS.YES,
						source: APP_CONSTANTS.appType,
						...(!!appointmentData && { reschedule_source: "original" }),
					},
					appointment_geo: {
						user_lat: data.lat,
						user_lng: data.lng,
						appointment_address: data.customAddress,
						address1: data.address1,
						address2: `${data.address2}, ${data.landmark}`,
					},
				};
				if (!!appointmentData) {
					LeadService.rescheduleAppointment(token, params)
						.then(() => {
							history.push({
								pathname: `/${APP_CONSTANTS.appType}/lead/detail/${token}`,
								state: SO_SCREEN_TYPES.APPOINTMENT_SUCCESS,
							});
						})
						.finally(() => {
							setIsDataLoading(false);
							onGoBack(false);
						});
				} else {
					LeadService.createAppointment(token, params)
						.then(() => {
							history.push({
								pathname: `/${APP_CONSTANTS.appType}/lead/detail/${token}`,
								state: SO_SCREEN_TYPES.APPOINTMENT_SUCCESS,
							});
						})
						.finally(() => {
							setIsDataLoading(false);
							onGoBack(false);
						});
				}
				break;
			default:
				setData({
					...data,
					page: PAGE_TYPE.DEALER_LOCATION,
					pageTitle: appointmentStatus,
				});
				break;
		}
	};

	const onPageNavigation = () => {
		if (
			[PAGE_TYPE.ENTER_ADDRESS, PAGE_TYPE.CUSTOM_LOCATION].includes(data.page)
		) {
			setData({
				...data,
				page: PAGE_TYPE.DEALER_LOCATION,
				pageTitle: appointmentStatus,
			});
		} else {
			onGoBack(false);
		}
	};

	return (
		<>
			{isDataLoading ? (
				<Loader />
			) : (
				<div>
					<div className={styles.navBar}>
						<img
							src={backButton}
							alt="back"
							onClick={() => onPageNavigation()}
						/>
						<span>{data.pageTitle}</span>
					</div>
					{data.page === PAGE_TYPE.DEALER_LOCATION && (
						<div className={styles.carInspection}>
							<span>Select address for inspection</span>
							{!isLocationServicable && (
								<div className={styles.nonServicable}>
									<img src={redInfo} alt="red" />
									<p>
										Inspection location is not serviceable. Please talk to
										manager.
									</p>
								</div>
							)}
							<div
								className={
									data.isDealerCenter
										? `${styles.dealerLocationDetails} ${styles.active}`
										: styles.dealerLocationDetails
								}
								onClick={onDealerCenterClick}>
								<img src={defaultLoc} alt="default" />
								<p>{leadDetail?.b2bDealer.centreAddress || ""}</p>
							</div>
							<div
								className={
									data.isCustom
										? `${styles.customLocationDetails} ${styles.active}`
										: styles.customLocationDetails
								}
								onClick={onCustomerCenterClick}>
								<img
									src={!!data.customAddress ? defaultLoc : customLoc}
									alt="custom"
								/>
								<p className={!data.customAddress ? styles.defaultText : ""}>
									{!!data.customAddress
										? data.customAddress
										: "Enter customer location"}
								</p>
								<span>{!!data.customAddress ? "CHANGE" : ""}</span>
							</div>
							{!!slotData && (
								<div className={styles.date}>
									<span>Select Date</span>
									<div className={styles.dateCardWrapper}>
										{Object.keys(slotData).map((date) => {
											const dateSlot = slotData[date];
											const daysDiff = getDiffDaysFromToday(date);
											const customClassName = `${styles.dateCard} ${
												dateSlot.status === APP_CONSTANTS.NA
													? styles.inactive
													: ""
											} ${data.date === date ? styles.selected : ""}`;
											return (
												<div
													key={date}
													className={customClassName}
													disabled={slotData[date] === APP_CONSTANTS.NA}
													onClick={() => {
														if (dateSlot.status === APP_CONSTANTS.LF) {
															setData({ ...data, date, time: "" });
															setTimeSlots();
															let times = [];
															Object.keys(dateSlot.time).map((timeKey) => {
																const timeData = dateSlot.time[timeKey];
																Object.keys(timeData).map((key) => {
																	const timeItem = timeData[key];
																	times.push({ ...timeItem, key });
																});
															});
															setTimeSlots(times);
														}
													}}>
													<>
														{daysDiff < NUMBERS.TWO && (
															<p
																className={`${
																	daysDiff === NUMBERS.ZERO
																		? styles.dateTagToday
																		: ""
																} ${styles.dateTag} ${
																	dateSlot.status === APP_CONSTANTS.NA
																		? styles.disabled
																		: ""
																}`}>
																{daysDiff === NUMBERS.ONE
																	? "Tomorrow"
																	: daysDiff === NUMBERS.ZERO
																	? "today"
																	: ""}
															</p>
														)}
														<span className={styles.day}>
															{dateSlot.mobile_value.split(",")[0]}
														</span>
														<span className={styles.date}>
															{dateSlot.mobile_value.split(",")[1]}
														</span>
													</>
												</div>
											);
										})}
									</div>
								</div>
							)}
							{timeSlots && timeSlots.length > NUMBERS.ZERO && (
								<div className={styles.selectedSlot}>
									<span className={styles.slotTitle}>Select slot</span>
									<div className={styles.slotWrapper}>
										{timeSlots &&
											timeSlots.map((slot) => {
												const timeClassName = `${styles.slotCard} ${
													slot.status === APP_CONSTANTS.NA
														? styles.inactive
														: ""
												} ${data.time === slot.key ? styles.selected : ""}`;
												return (
													<div
														key={slot.key}
														className={timeClassName}
														onClick={() => {
															if (
																[APP_CONSTANTS.LF, APP_CONSTANTS.AF].includes(
																	slot.status
																)
															) {
																setData({
																	...data,
																	time: slot.key,
																	phone: leadDetail?.b2bDealer?.ucmPhone,
																	contactName: leadDetail?.b2bDealer?.ucmName,
																});
															}
														}}>
														<span className={styles.slotRange}>
															{slot.time_range}
														</span>
													</div>
												);
											})}
									</div>
								</div>
							)}
							{data.time && isLocationServicable && (
								<div
									className={
										isLocationServicable
											? styles.FormData
											: `${styles.FormData} ${styles.formNonServicable}`
									}>
									<span>Contact Details</span>
									<div className={styles.inputPhone}>
										<input
											// ref={formInputRef}
											type="tel"
											value={data.phone}
											placeholder="Phone Number"
											onChange={(e) =>
												setData({ ...data, phone: e.target.value })
											}
											maxLength={NUMBERS.TEN}
										/>
									</div>
									<div className={styles.inputContactName}>
										<input
											type="text"
											value={data.contactName}
											onChange={(e) =>
												setData({ ...data, contactName: e.target.value })
											}
											placeholder="Contact Name"
										/>
									</div>
								</div>
							)}
							<div className={styles.ProceedBtn}>
								{/* {!isLocationServicable && <div className={styles.locationInfo}>
                            <img src={info} alt='custom' />
                            <p>To still book inspection, click on confirm inspection. CJ needs to be assigned manually in this case.</p>
                        </div>} */}
								<span
									className={`${styles.btnProcced} ${
										isLocationServicable &&
										data.phone &&
										data.contactName &&
										data.date &&
										data.time
											? styles.active
											: ""
									}`}
									onClick={() =>
										onBookInspectionClick({ value: PAGE_TYPE.ENTER_ADDRESS })
									}>
									Proceed
								</span>
							</div>
						</div>
					)}
					{data.page === PAGE_TYPE.CUSTOM_LOCATION && (
						<div className={styles.searchLocation}>
							<LocationPicker setCustomLocation={setCustomLocation} setIsClickComplete={setIsClickComplete}/>
						</div>
					)}
					{data.page === PAGE_TYPE.ENTER_ADDRESS && (
						<div className={styles.enterAddress}>
							<span>{APP_CONSTANTS.ENTER_ADDRESS}</span>
							<div className={styles.inputControl}>
								<input
									type="text"
									value={data.address1}
									onChange={(e) =>
										setData({ ...data, address1: e.target.value })
									}
									placeholder="House number"
								/>
							</div>
							<div className={styles.inputControl}>
								<input
									type="text"
									value={data.address2}
									onChange={(e) =>
										setData({ ...data, address2: e.target.value })
									}
									placeholder="Apartment/Building name"
								/>
							</div>
							<div className={styles.inputControl}>
								<input
									type="text"
									value={data.landmark}
									onChange={(e) =>
										setData({ ...data, landmark: e.target.value })
									}
									placeholder="Nearest landmark (optional)"
								/>
							</div>
							<div className={styles.ConfirmBtn}>
								<span
									className={`${styles.btnConfirm} ${
										isLocationServicable && data.address1 && data.address2
											? styles.active
											: ""
									}`}
									onClick={() =>
										onBookInspectionClick({
											value: PAGE_TYPE.CONFIRM_INSPECTION,
										})
									}>
									Confirm InspEction
								</span>
							</div>
						</div>
					)}
				</div>
			)}
		</>
	);
};

export default BookInspection;
