import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  isLoading: true,
  data: [],
  page: 0,
  total: 0,
  loadMore: false,
  filterQuery: '',
  error: ''
};

export const fetchLeadsSuccess = (state = INITIAL_STATE, {data}) => {
  let _loadMore = true;
  const {
    content,
    last,
    number,
    totalPages
  } = data;
  _loadMore = !last;
  let rowData = [...state.data, ...content];
  return { ...state, 
    data: rowData, 
    loadMore: _loadMore,   
    isLoading: false,
    total: totalPages,
    page: number
  };
};

export const fetcLeadsFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, error, isLoading: false };
};

export const resetLeads = () => {
  return INITIAL_STATE;
};

export const updateLeadsFilterQuery = (state = INITIAL_STATE, { query }) => {
  return { ...state, filterQuery: query};
};

export const HANDLERS = {
  [Types.FETCH_LEADS_SUCCESS]: fetchLeadsSuccess,
  [Types.FETCH_LEADS_FAILURE]: fetcLeadsFailure,
  [Types.RESET_LEADS]: resetLeads,
  [Types.UPDATE_LEADS_FILTER_QUERY]: updateLeadsFilterQuery,

};

export default createReducer(INITIAL_STATE, HANDLERS);
