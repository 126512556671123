import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AllocateCjSchedulePopup from "./component";
import { fetchCjAllocationStatus } from "../cj-allocation-status/actions";

const mapStateToProps = (
  {
    homeInspectionFilters, 
    negotiations
  }
) => ({
  homeInspectionFilters,
  negotiations
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchCjAllocationStatusConnect: fetchCjAllocationStatus
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AllocateCjSchedulePopup);
