import Types from "./types";
import {AppointmentService} from "../../service";
import {INITIAL_STATE} from "./reducers";

const fetchEvaluatorTokens = (storeId) => dispatch => {
  return AppointmentService.getEvaluatorTokens(storeId)
    .then(response => {
      const { data: { detail } } = response;
      dispatch(fetchEvaluatorTokensSuccess(detail));
      return response;
    })
    .catch(error => dispatch(fetchEvaluatorTokensFailure(error)));
};

const fetchEvaluatorTokensSuccess = (data) => ({
  type: Types.FETCH_EVALUATOR_TOKENS_SUCCESS,
  data
});

const fetchEvaluatorTokensFailure = (error) => ({
  type: Types.FETCH_EVALUATOR_TOKENS_FAILURE,
  error
});

const fetchRetailView = (storeId) => dispatch => {
  return AppointmentService.getRetailView(storeId)
    .then(response => {
      const { data: { detail } } = response;
      dispatch(fetchRetailViewSuccess(detail));
      return response;
    })
    .catch(error => dispatch(fetchRetailViewFailure(error)));
};

const fetchRetailViewSuccess = (data) => ({
  type: Types.FETCH_RETAIL_VIEW_SUCCESS,
  data
});

const fetchRetailViewFailure = (error) => ({
  type: Types.FETCH_RETAIL_VIEW_FAILURE,
  error
});

const fetchEvaluatorView = (storeId) => dispatch => {
  return AppointmentService.getEvaluatorView(storeId)
    .then(response => {
      const { data: { detail } } = response;
      dispatch(fetchEvaluatorViewSuccess(detail));
      return response;
    })
    .catch(error => dispatch(fetchEvaluatorViewFailure(error)));
};

const fetchEvaluatorViewSuccess = (data) => ({
  type: Types.FETCH_EVALUATOR_VIEW_SUCCESS,
  data
});

const fetchEvaluatorViewFailure = (error) => ({
  type: Types.FETCH_EVALUATOR_VIEW_FAILURE,
  error
});

const resetView = () => ({
  type: Types.RESET_VIEW
});

const resetEvaluatorByKey = (key, value) => ({
  type: Types.RESET_EVALUATOR_BY_KEY,
  data: { key, value }
});

export {
  fetchEvaluatorTokens,
  fetchRetailView,
  fetchEvaluatorView,
  resetView,
  resetEvaluatorByKey
};