import React, { useState } from 'react';
import Button from '../shared/button';
import { ACTIONS_BUTTON } from '../../utils/app-constants';
import Popup from "../popup";
import SoAllocateRAPopup from "../so-allocate-ra-popup/component";

const LeadMonAssignRa = ({rowDetails,resetAndFetchNewListing}) => {
    const [allocateOpen, setAllocateOpen] = useState(false);
    const onAllocateOpen = () => {
        setAllocateOpen(true);
    };

    const onAllocateClose = () => {
        setAllocateOpen(false);
    };
    return (
        <div>
            <span>{rowDetails?.retail_associate_email}</span>
            <Button ctaText={!!rowDetails?.retail_associate_email?.length ? ACTIONS_BUTTON.REALLOCATE_RA : ACTIONS_BUTTON.ALLOCATE_RA}
                classNames={"smallCta"}
                onClick={onAllocateOpen}
            />
            <Popup isOpen={allocateOpen} close={onAllocateClose}>
                <SoAllocateRAPopup onClose={onAllocateClose} data={rowDetails} isLeadMon={true} resetUpdateDataList={resetAndFetchNewListing}/>
            </Popup>
        </div>
    );
};

export default LeadMonAssignRa;
