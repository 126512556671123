import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  userCharges: {},
  isLoading: true
};

export const fetchChargesHoldbackSuccess = (state = INITIAL_STATE, {data}) => {
  return {
    ...state,
    isLoading: true,
    userCharges: data
  };
};

export const HANDLERS = {
  [Types.FETCH_CHARGES_HOLDBACK]: fetchChargesHoldbackSuccess

};

export default createReducer(INITIAL_STATE, HANDLERS);
