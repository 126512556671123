import React from "react";
import styles from "./customer-why-sell.module.scss";
import SellCar from "../../components/images/ic_sell_car.svg";
import priceGuarenteed from "../../components/images/ic_best_price_guarenteed.svg";
import transferIcon from "../../components/images/free_rc_transfer_icon.svg";
import instantPayment from "../../components/images/ic_instant_payment.svg";

const CustomerWhySell = () => {
  return (
    <div className={styles.customerWhySell}>
      <h3>WHY SELL TO CARS24?</h3>
      <div className={styles.offersList}>
        <ul>
          <li>
            <img src={SellCar} alt="SellCar" />
            <p>Sell Car in One Visit</p>
          </li>
          <li>
            <img src={priceGuarenteed} alt="priceGuarenteed" />
            <p>Best Car Price</p>
          </li>

          <li>
            <img src={instantPayment} alt="instantPayment" />
            <p>Instant Payment</p>
          </li>
          <li>
            <img src={transferIcon} alt="transferIcon" />
            <p>Free RC Transfer</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CustomerWhySell;
