import React, { useEffect, useState, useRef } from "react";
import NegotiationCard from "../card-negotiation";
import Popup from "../popup";
import CarDetailPopup from "../car-detail-popup";
import RaisePaiPopup from "../raise-pai-popup";
import GeneratePRPopup from "../generate-pr-popup";
import NegotiationPopup from "../negotiation-popup";
import C24QuotePopup from "../c24-quote-popup";
import ReauctionPopup from "../reauction-popup";
import SuggestedQuotePopup from "../suggested-quote-popup";
import styles from "./negotiation-list.module.css";
import { RESET_REDUCER_STATE_KEYS, APPOINTMENT_TYPES_STRING, LOCAL_STORAGE_KEYS, ROLE_KEYS } from "../../utils/app-constants";
import popups from "../../utils/popup-constants";
import DataInformationMessage from "../../components/shared/data-information-message";
import FollowUpHistory from "../follow-up-history";
import { getFromLocalStorage, isBikeSource, getCurrentUserRole } from "../../utils/helper";
import { AppointmentService } from "../../service";
import RaiseOcbPopup from "../raise-ocb-popup";
import AcceptPricePopup from "../accept-price-popup";
import AcceptPricePllPopup from "../accept-price-pll-popup";
import ListingCarPopup from "../../components/listing-car-popup";
import Button from "../../components/shared/button";
import InitiatePaymentPopup from "../../components/initiate-payment-popup";
import AllocateCjPopup from "../../components/allocate-cj-popup";
import ParkAndSellReauctionPopup from "../park-and-sell-reauction-popup";
import BikeInitiatePaymentPopup from "../bike-initiate-payment-popup/component";
import SchedulePickupPopup from "../schedule-pickup-popup/component";
import NegotiationPopupBike from "../../components/negotiation-popup-bike";
import BikeAllocateCjPopup from "../../components/bike-allocate-cj-popup";
import ReportPopup from "../report-popup/component";
import DiyPllAcceptPricePopup from "../diy-pll-accept-price-popup/component";
import DiffReportPopup from "../diff-report-popup";
const NegotiationList = ({
  negotiations,
  fetchNegotiationsConnect,
  fetchCarDataConnect,
  setCurrentPopupConnect,
  resetNegotiationsConnect,
  resetLeadMenuByKeyConnect,
  leadMenu,
  search,
  negotiationFilter,
  globalSearch,
  resetGlobalSearchConnect,
  pllNegotiationFilter,
  resetNegotiationsByKeyConnect,
  negotiationRange,
  lostLeadsearch,
  history,
  sidebar,
  appointmentType,
  apiNoAccess,
  isPll,
  isC2C,
}) => {
  const { range } = negotiationRange;
  const { data, currentCarDetails, currentPopup, isLoading, error, currentAppointmentIndex, loadMore } = negotiations;
  const [dataLoading, setDataLoading] = useState(false);
  const { filterQuery } = isPll ? pllNegotiationFilter : negotiationFilter;
  const { withCustomer = false, missedCallQuery } = negotiationFilter || {};
  const scrollRef = useRef();
  const [lastItemIndex, setLastItemIndex] = useState(9);
   
  const popupStyles = [
    popups.RAISE_PAI,
    popups.GENERATE_PR,
    popups.QUOTE_UNLOCK,
    popups.SUGGESTED_QUOTE
  ].includes(currentPopup || "") ? { minWidth: "auto" } : {};
   
  const { resetAndFetchData } = leadMenu;
  const { query } = search;
  const { globalQuery } = globalSearch;
  const [currentIndexData, setCurrentIndexData] = useState(null);
  const { lostleads } = lostLeadsearch;
  const role = getCurrentUserRole();
  const isBike = isBikeSource();
  let isLostLeadFlow = ((lostleads === true) && (!!role && (role.toLowerCase() === ROLE_KEYS.RA || role.toLowerCase() === ROLE_KEYS.RM))) ? true : false;
  isLostLeadFlow = isLostLeadFlow && !isPll ? true : false;

  const [currentPageNo, setCurrentPageNo] = useState(1);
  const { pllFilter, retailFilter } = sidebar;
  let loadKey = '';
  useEffect(() => {
    if (!!appointmentType) {
      resetListing('appointmentType');
    }
  }, [appointmentType]);

  useEffect(() => {
    if (resetAndFetchData) {
      resetLeadMenuByKeyConnect(RESET_REDUCER_STATE_KEYS.RESET_AND_FETCH_DATA, false);
      resetListing('resetAndFetchData');
    }
  }, [resetAndFetchData]);

  useEffect(() => {
    if (query !== null) {
      resetListing('query');
    }
  }, [query]);
    
  useEffect(() => {
    if (checkDates()) {
      resetListing('checkDates');
    }
  }, [range]);

  useEffect(() => {
    if (!!globalQuery) {
      resetListing('globalQuery');
    }
  }, [globalQuery]);

  useEffect(() => {
    if (!!filterQuery) {
      resetListing('filterQuery');
    }
  }, [filterQuery]);

  useEffect(()=>{
    if (!!missedCallQuery) {
      resetListing('missedCallQuery');
    }
  }, [missedCallQuery]);

  useEffect(() => {
    resetListing('withCustomer');
  }, [withCustomer]);

  useEffect(() => {
    if (!!sidebar && (!!sidebar.pllFilter.filterQuery || !!sidebar.retailFilter.filterQuery)) {
      resetListing('sidebar');
    }
  }, [sidebar]);

  useEffect(() => {
    if (scrollRef.current && currentPageNo > 1) {
      scrollRef.current.scrollIntoView({
        behavior: "auto",
        block: "end"
      });
      setLastItemIndex(data.length - 1);
    }
  }, [currentPageNo, data]);

  useEffect(() => {
    if (currentAppointmentIndex != null) {
      setCurrentIndexData(data[currentAppointmentIndex]);
    } else {
      setCurrentIndexData(null);
    }
  }, [currentAppointmentIndex]);

  const paginationLoader = (page) => {
        page > 1 ? setDataLoading(true) : setDataLoading(false);
  };

  const checkDates = () => {
    if (range) {
      return !!(range.from && range.to);
    }
  };

  const getNegoFilterQuery = () => {
    let query = filterQuery;
    return query;
  };

  const loadMoreNegotiations = (page) => {
    let getNegoCall = true;
    if (isC2C && (range.from === "" || range.to === "")) {
      if (!!filterQuery) {
      } else {
        getNegoCall = false;
      }
    }
    paginationLoader(page);
    const finalQuery = getFinalSearchQuery();
    const lostLeadQuery = getLostLeadQuery();
    const pllSideBarFilter = getPllSideBarFilter();
    const retailSideBarFilter = getRetailSideBarFilter();
    const negoFilterQuery = getNegoFilterQuery();
    if (getNegoCall) {
      fetchNegotiationsConnect(page, finalQuery, negoFilterQuery, isC2C, isC2C && checkDates() ? range : null, lostLeadQuery, pllSideBarFilter, retailSideBarFilter, withCustomer, appointmentType, missedCallQuery)
        .then(resp => {
          if (!!resp && !!resp.data) {
            const { data: { detail: { data: { result } } } } = resp;
            if (globalQuery) {
              resetGlobalSearchConnect();
            }
          }
          setDataLoading(false);
        });
    }
        
  };

  const onClickViewCarDetails = (appointmentId, index) => {
    fetchCarDataConnect(appointmentId)
      .then(
        () => setCurrentPopupConnect(popups.CAR_SPECS, index)
      );
  };

  const resetListing = (key) => {
    if (!loadKey) {
      loadKey = key;
      resetNegotiationsConnect();
      setCurrentPageNo(1);
      loadMoreNegotiations(1);

    }
  };
  const updateDataList = (appointmentId) => {
    const lostLeadQuery = getLostLeadQuery();
    const currentNegoIndex = data.findIndex(negotiation => negotiation.appointmentId === appointmentId);
    if (appointmentType === APPOINTMENT_TYPES_STRING.DIY_NEGOTIATION) {
      AppointmentService.getDiyNegotiations(1, appointmentId, filterQuery, isC2C, isC2C && checkDates() ? range : null, lostLeadQuery).then((resp)=>{
        refreshListing(currentNegoIndex, appointmentId, resp);
      }).catch(err => console.log(err));
    } else if (APPOINTMENT_TYPES_STRING.CENTRAL_NEGOTIATION === appointmentType) {
      AppointmentService.getRTNegotiations(1, appointmentId, filterQuery, isC2C, isC2C && checkDates() ? range : null, lostLeadQuery).then((resp)=>{
        refreshListing(currentNegoIndex, appointmentId, resp);
      }).catch(err => console.log(err));
    } else if (APPOINTMENT_TYPES_STRING.CENTRAL_DIY_NEGOTIATION === appointmentType) {
      AppointmentService.getRTDIYNegotiations(1, appointmentId, filterQuery, isC2C, isC2C && checkDates() ? range : null, lostLeadQuery).then((resp)=>{
        refreshListing(currentNegoIndex, appointmentId, resp);
      }).catch(err => console.log(err));
    } else {
      AppointmentService.getNegotiations(1, appointmentId, filterQuery, isC2C, isC2C && checkDates() ? range : null, lostLeadQuery)
        .then(resp => {
          refreshListing(currentNegoIndex, appointmentId, resp);
        })
        .catch(error => console.log(error));
    }
  };

  const refreshListing = (index, appointmentId, response) => {
    const newList = [...data];
    if (response.data) {
      const { data: { detail: { data: { result } } } } = response;
      if (result.length > 0) {
        const negoData = result.find(negotiation => negotiation.appointmentId === appointmentId);
        if (negoData) {
          newList[index] = negoData;
          resetNegotiationsByKeyConnect(RESET_REDUCER_STATE_KEYS.DATA, newList);
        }
      }
    }
  };

  const updateListing = () => {
    updateDataList(currentIndexData.appointmentId);
  };

  const getFinalSearchQuery = () => {
    if (globalQuery) {
      return globalQuery;
    } else {
      return query;
    }
  };
  const getLostLeadQuery = () => {
    if (window.location.pathname === "/nego-lost-lead") {
      var lostLeadFromLocalStorage = getFromLocalStorage(LOCAL_STORAGE_KEYS.lostLead);
      if (lostleads === true) {
        return lostleads;
      }
      else if (lostLeadFromLocalStorage === "true") {
        return true;
      }
    }
  };
  const getPllSideBarFilter = () => {
    if (!!pllFilter && !!pllFilter.filterQuery) {
      switch (pllFilter.filterQuery) {
        case "starred":
        case "rt-starred":
        case "diy-starred":
          return `&isStarred=1`;
        case "connected":
        case "rt-connected":
        case "diy-connected":
          return `&isConnected=1`;
        case "not-connected":
        case "rt-not-connected":
        case "diy-not-connected":
          return `&isConnected=0`;
        case "remaining-leads":
        case "rt-remaining-leads":
        case "diy-remaining-leads":
          return `&remainingLeads=1`;
        case "rt-retail-lost-leads":
        case "retail-lost-leads":
        case "diy-retail-lost-leads":
          return `&retailLostLeads=1`;
        case "rt-gs-leads":
        case "gs-leads":
        case "diy-gs-leads":
          return `&inventory=1`;
        case "lead-mon":
        case "diy-lead-mon":
        case "rt-lead-mon":
          return `&leadMon=1`;
        case "diy-all-leads":
        case 'rt-all-leads':
          return '';
        case "rt-accepted":
        case "diy-accepted":
          return `&pai_ocb_status=accepted`; 
        case "rt-rejected":
        case "diy-rejected":
          return `&pai_ocb_status=rejected`; 
        default:
          return `&pai_ocb_status=${pllFilter.filterQuery}`;
      }
    }
  };
  const getRetailSideBarFilter = () => {
    if (!!retailFilter && !!retailFilter.filterQuery) {
      if (["starred", "diy-starred"].includes(retailFilter.filterQuery)) {
        return `&isStarred=1`;
      } else if (["not-connected", "diy-not-connected"].includes(retailFilter.filterQuery)) {
        return `&isConnected=0`;
      } else if (["gs-leads", "diy-gs-leads"].includes(retailFilter.filterQuery)) {
        return `&inventory=1`;
      } else if (["lead-mon","diy-lead-mon"].includes(retailFilter.filterQuery)) {
        return `&leadMon=1`;
      } else if (["diy-all-leads", "all-leads"].includes(retailFilter.filterQuery)) {
        return '';
      } else if (["diy-accepted"].includes(retailFilter.filterQuery)) {
        return `&pai_ocb_status=accepted`;
      } else if (["diy-rejected"].includes(retailFilter.filterQuery)) {
        return `&pai_ocb_status=rejected`;
      }
      else {
        return `&pai_ocb_status=${retailFilter.filterQuery}`;
      }
    }
  };
  const shouldPopUpClose = (currentPopup) => {
    if (currentPopup === popups.FOLLOW_UP && isBike) {
      return false;
    }
    return ![popups.CAR_SPECS, popups.CAR_SPECS_CUSTOMER_VIEW].includes(currentPopup);
  };
  const showPopupCloseButton = (currentPopup) => {
    if (currentPopup === popups.FOLLOW_UP && isBike) {
      return false;
    }
    return true;
  };
  const shouldPopupCloseOnEsc = (currentPopup) => {
    if (currentPopup === popups.FOLLOW_UP && isBike) {
      return false;
    }
    return true;
  };

  const onLoadMore = () => {
    let i = currentPageNo;
    setCurrentPageNo(i + 1);
    loadMoreNegotiations(i + 1);
  };
    
  return <div className={styles.negotiationPagewrapper}>
    {data.map((negotiation, index) => <div key={`p-${index}`} ref={(index === lastItemIndex) ? scrollRef : null}>
      <NegotiationCard
        onClickViewCarDetails={() => onClickViewCarDetails(negotiation.appointmentId, index)}
        key={index}
        appointmentIndex={index}
        data={negotiation}
        isLostLeadFlow={isLostLeadFlow}
        isLostLead={lostleads}
        isPll={isPll}
        updateDataList={updateDataList}
        history={history}
        appointmentType={appointmentType}
      /></div>)
    }
    <DataInformationMessage
      isLoading={isLoading}
      error={error}
      data={data}
      dataLoading={dataLoading}
      appointmentType={appointmentType}
    />
    {loadMore &&
    <div className={styles.loadmore}>
      <Button classNames="secondaryCta" ctaText={"Load More"} onClick={onLoadMore}></Button>
    </div>
    }
    <Popup contentStyles={popupStyles} closeOnOutside={shouldPopUpClose(currentPopup)} isOpen={!!currentPopup && !apiNoAccess} close={() => setCurrentPopupConnect()} 
      showCloseButton={showPopupCloseButton(currentPopup)} shouldCloseOnEsc={shouldPopupCloseOnEsc(currentPopup)}>
      {[popups.CAR_SPECS, popups.CAR_SPECS_CUSTOMER_VIEW].includes(currentPopup) && <CarDetailPopup currentCarDetails={currentCarDetails} onCancelClick={() => setCurrentPopupConnect()} />}
      {currentPopup === popups.RAISE_PAI && <RaisePaiPopup resetListing={updateListing} close={() => setCurrentPopupConnect()} />}
      {currentPopup === popups.GENERATE_PR && <GeneratePRPopup resetListing={updateListing} close={() => setCurrentPopupConnect()} history={history} />}
      {currentPopup === popups.QUOTE_UNLOCK && <C24QuotePopup resetListing={updateListing} close={() => setCurrentPopupConnect()} />}
      {currentPopup === popups.REAUCTION && <ReauctionPopup resetListing={updateListing} close={() => setCurrentPopupConnect()} />}
      {currentPopup === popups.SUGGESTED_QUOTE && <SuggestedQuotePopup resetListing={updateListing} close={() => setCurrentPopupConnect()} history={history} />}
      {currentPopup === popups.RAISE_OCB && <RaiseOcbPopup resetListing={updateListing} close={() => setCurrentPopupConnect()} />}
      {currentPopup === popups.ACCEPTANCE_PRICE && <AcceptPricePopup resetListing={updateListing} close={() => setCurrentPopupConnect()} />}
      {currentPopup === popups.PLL_ACCEPTANCE_PRICE && <AcceptPricePllPopup resetListing={updateListing} close={() => setCurrentPopupConnect()} />}
      {currentPopup === popups.C2C_LIST_PRICE && <ListingCarPopup resetListing={updateListing} close={() => setCurrentPopupConnect()} />}
      {currentPopup === popups.FOLLOW_UP &&
      <div className={styles.negoFllowup}>
        {!isBike && <NegotiationPopup resetListing={updateListing} close={() => setCurrentPopupConnect()} isLostLeadFlow={isLostLeadFlow} />}
        {isBike && <NegotiationPopupBike resetListing={updateListing} close={() => setCurrentPopupConnect()} isLostLeadFlow={isLostLeadFlow} resetNegoListing={resetListing}/>}
        <div className={styles.historypop}>
          <FollowUpHistory
            appointmentId={currentIndexData ? currentIndexData.appointmentId : null} showPriceColum={true}
          />
        </div>
      </div>
      }
      {currentPopup === popups.ALLOCATE_CJ && isBike && <BikeAllocateCjPopup onClose={() => setCurrentPopupConnect()} appointmentId={currentIndexData ? currentIndexData.appointmentId : null} userData={currentIndexData ? currentIndexData : null} resetListing={updateListing} origin={APPOINTMENT_TYPES_STRING.NEGOTIATION} />}
      {currentPopup === popups.ALLOCATE_CJ && !isBike && <AllocateCjPopup onClose={() => setCurrentPopupConnect()} appointmentId={currentIndexData ? currentIndexData.appointmentId : null} userData={currentIndexData ? currentIndexData : null} resetListing={updateListing} origin={APPOINTMENT_TYPES_STRING.NEGOTIATION} />}
      {currentPopup === popups.INITIATE_PAYMENT && <InitiatePaymentPopup resetListing={updateListing} history={history} close={() => setCurrentPopupConnect()} />}
      {currentPopup === popups.PARK_AND_SELL_REAUCTION && <ParkAndSellReauctionPopup resetListing={updateListing} history={history} onClose={() => setCurrentPopupConnect()} userData={currentIndexData ? currentIndexData : null} />}
      {currentPopup === popups.BIKE_INITIATE_PAYMENT && <BikeInitiatePaymentPopup resetListing={updateListing} history={history} onClose={() => setCurrentPopupConnect()} userData={currentIndexData ? currentIndexData : null} />}
      {currentPopup === popups.SCHEDULE_PICKUP && <SchedulePickupPopup resetListing={updateListing} history={history} onClose={() => setCurrentPopupConnect()} userData={currentIndexData ? currentIndexData : null} />}
      {currentPopup === popups.INSPECTION_REPORT && <ReportPopup data={currentIndexData} close={() => setCurrentPopupConnect()} />}
      {currentPopup === popups.DIY_PLL_ACCEPT_PRICE && <DiyPllAcceptPricePopup resetListing={updateListing} close={() => setCurrentPopupConnect()} isRefreshRequired={true} />}
      {currentPopup === popups.DIY_PLL_EDIT_PRICE && <DiyPllAcceptPricePopup resetListing={updateListing} close={() => setCurrentPopupConnect()} isPriceEdit={true} isRefreshRequired={true}/>}
	  {currentPopup === popups.DIFF_REPORT && <DiffReportPopup rowData={currentIndexData} resetListing={updateListing} close={() => setCurrentPopupConnect()} isRefreshRequired={true}/>}
    </Popup>
  </div>;
};

export default NegotiationList;
