import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GlobalSearch from "./component";
import { resetGlobalSearchByKey } from "./actions";
import { resetSearch } from "../search/actions";
import { updateconfirmLeadsFilterQuery } from "../cc-confirm-leads/actions";
import { updateFilterQuery } from "../homeInspection-filters/actions";
import { updatereviewInspectionFilterQuery } from "../review-inspection/actions";
const mapStateToProps = ({ appointments }) => ({
    appointments,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            updateconfirmLeadsFilterQueryConnect: updateconfirmLeadsFilterQuery,
            resetGlobalSearchByKeyConnect: resetGlobalSearchByKey,
            resetSearchConnect: resetSearch,
            resetFilterQueryConnect: updateFilterQuery,
            updatereviewInspectionFilterQueryConnect:
				updatereviewInspectionFilterQuery,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSearch);
