import Types from "./types";
import { AppointmentService } from "../../service";

// const getStoreList = () => dispatch => {
//     let response = {
//         "type": "http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html",
//         "title": "Store list",
//         "status": 200,
//         "detail": [
//             {
//                 "idLmsStore": 3167,
//                 "name": "Golf Course Extension",
//                 "storeCity": "Gurgaon"
//             },
//             {
//                 "idLmsStore": 3168,
//                 "name": "Cyber City",
//                 "storeCity": "Gurgaon"
//             },
//             {
//                 "idLmsStore": 3320,
//                 "name": "tester",
//                 "storeCity": "Gurgaon"
//             },
//             {
//                 "idLmsStore": 3330,
//                 "name": "guruna",
//                 "storeCity": "Gurgaon"
//             },
//             {
//                 "idLmsStore": 3334,
//                 "name": "jjd",
//                 "storeCity": "Gurgaon"
//             },
//             {
//                 "idLmsStore": 1839,
//                 "name": "Habsiguda",
//                 "storeCity": "New Delhi"
//             },
//             {
//                 "idLmsStore": 3169,
//                 "name": "Saket",
//                 "storeCity": "New Delhi"
//             },
//             {
//                 "idLmsStore": 3184,
//                 "name": "Lajpat Nagar",
//                 "storeCity": "New Delhi"
//             },
//             {
//                 "idLmsStore": 3185,
//                 "name": "Janakpuri",
//                 "storeCity": "New Delhi"
//             }
//         ]
//     };
//     return response;
// };

const getStoreList = () => dispatch => {

  //getStoreAssociateList(searchQuery)
  return AppointmentService.getStoreList()
    .then(response => {
      response = {
        "type": "http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html",
        "title": "Store list",
        "status": 200,
        "detail": [
          {
            "idLmsStore": 3167,
            "name": "Golf Course Extension",
            "storeCity": "Gurgaon"
          },
          {
            "idLmsStore": 3168,
            "name": "Cyber City",
            "storeCity": "Gurgaon"
          },
          {
            "idLmsStore": 3320,
            "name": "tester",
            "storeCity": "Gurgaon"
          },
          {
            "idLmsStore": 3330,
            "name": "guruna",
            "storeCity": "Gurgaon"
          },
          {
            "idLmsStore": 3334,
            "name": "jjd",
            "storeCity": "Gurgaon"
          },
          {
            "idLmsStore": 1839,
            "name": "Habsiguda",
            "storeCity": "New Delhi"
          },
          {
            "idLmsStore": 3169,
            "name": "Saket",
            "storeCity": "New Delhi"
          },
          {
            "idLmsStore": 3184,
            "name": "Lajpat Nagar",
            "storeCity": "New Delhi"
          },
          {
            "idLmsStore": 3185,
            "name": "Janakpuri",
            "storeCity": "New Delhi"
          }
        ]
      };
      const { data: { detail } } = response;
      return detail;
    })
    .catch(error => {
      error = {
        "type": "http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html",
        "title": "Store list",
        "status": 200,
        "detail": [
          {
            "idLmsStore": 3167,
            "name": "Golf Course Extension",
            "storeCity": "Gurgaon"
          },
          {
            "idLmsStore": 3168,
            "name": "Cyber City",
            "storeCity": "Gurgaon"
          },
          {
            "idLmsStore": 3320,
            "name": "tester",
            "storeCity": "Gurgaon"
          },
          {
            "idLmsStore": 3330,
            "name": "guruna",
            "storeCity": "Gurgaon"
          },
          {
            "idLmsStore": 3334,
            "name": "jjd",
            "storeCity": "Gurgaon"
          },
          {
            "idLmsStore": 1839,
            "name": "Habsiguda",
            "storeCity": "New Delhi"
          },
          {
            "idLmsStore": 3169,
            "name": "Saket",
            "storeCity": "New Delhi"
          },
          {
            "idLmsStore": 3184,
            "name": "Lajpat Nagar",
            "storeCity": "New Delhi"
          },
          {
            "idLmsStore": 3185,
            "name": "Janakpuri",
            "storeCity": "New Delhi"
          }
        ]
      };
      const { detail } = error;
      return detail;
    });
};
export {
  getStoreList
};
