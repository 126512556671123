import React from "react";
import styles from "./inspection-status.module.css";
import { PENDING } from "../../utils/app-constants";

const InspectionStatus = ({ rowDetails }) => {

  const { inspection } = rowDetails;

  return (
    <>
      {inspection &&
        <span className={inspection === PENDING ? styles.statusPending : styles.statusProgress}>{inspection}</span>
      }
    </>
  );
};

export default InspectionStatus;
