import React from "react";
import Sticky from 'react-sticky-el';
import "./sticky-component.css";

const StickyComponent = ({ children, topOffset = 0, classes = "" }) => {
  return (
    <Sticky topOffset={topOffset} className={classes} >
      {children}
    </Sticky>
  );
};

export default StickyComponent;
