/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import styles from "./view-depriciation-report.module.css";
import Button from "../shared/button/component";
import { ACTIONS_BUTTON, APP_SOURCE } from "../../utils/app-constants";
import Popup from "../popup";
import InspectionReportPopup from "../inspection-report-popup";
import { AppointmentService } from "../../service";
import { getUid, showToastMessages, isBikeSource } from "../../utils/helper";
import { trackLMSEvents } from "../../utils/helpers/tracking-events";

const ViewDepriciationReport = ({ rowDetails }) => {
  const { carId, appointment, isDepReportAvailable, inspReportUrl='' } = rowDetails;
  const [open, setOpen] = useState(false);
  const [clickedAppointment, setClickedAppointment] = useState();
  const [carDetails, setCarDetails] = useState();
  const isBike = isBikeSource();

  useEffect(() => {
    if (clickedAppointment) {
      fetchReport();
    }
  },[clickedAppointment]);

  const openPopup = () => {
    trackLMSEvents("viewReportFromInspected", {
      eventLabel: appointment,
      lsRAID: getUid()
    });
    if (isBike){
      !!inspReportUrl && window.open(inspReportUrl,  "_blank", 'noopener');
    }else{
      setClickedAppointment(appointment);
      setOpen(true);
    }
  };

  const closePopup = () => {
    setClickedAppointment();
    setOpen(false);
    setCarDetails();
  };

  const fetchReport = () => {
    AppointmentService.getCarData(clickedAppointment)
    .then(resp => {
      const { data: { detail: { data } } } = resp;
      const carData = data[clickedAppointment];
      setCarDetails(carData);
    }).catch(err => {
      const { detail: { message } } = err;
      showToastMessages(message, false);
      closePopup();
    });
  };

  const openDepReport = () => {
    var url = "/customer-journey/" + appointment + "?showreport=dep&carId=" + carId + "&report=inspected-state&isDepReportAvailable=true&isDepReportViewed=false&pagesource=inspected";
    window.open(url, "_blank", 'noopener');
  };

  return (
    <>
      {carId &&
        <div className={styles.viewCta}>
          <Button ctaText={ACTIONS_BUTTON.VIEW_INSPECTION_REPORT}
            classNames={!isDepReportAvailable ? "smallCtaActive" : "smallCta"}
            onClick={openPopup}
            disabled={clickedAppointment === appointment}
          />
          {
            isDepReportAvailable && !isBike &&
            <Button ctaText={ACTIONS_BUTTON.VIEW_INSPECTION_REPORT}
              classNames="smallCtaActive"
              onClick={openDepReport}
            />
          }
        </div>
      }
      {!carId &&
        <span>N/A</span>
      }
      {
        clickedAppointment === appointment && carDetails &&
        <Popup isOpen={open} close={closePopup} closeOnOutside={false}>
          <InspectionReportPopup
            appointmentData={rowDetails}
            carData={carDetails}
            trackEvent={true}
          />
        </Popup>
      }
    </>
  );
};

export default ViewDepriciationReport;
