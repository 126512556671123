import React, { useEffect, useState } from "react";
import {
	APP_CONSTANTS,
	SO_QUESTION_VERSION,
	SO_SCREEN_TYPES,
} from "../../common/app-constants";
import CarPriceOffer from "../../components/car-price-offer";
import NotGenerateOffer from "../../components/not-generate-offer";
import RaOfferPriceAnalyzer from "../../components/ra-offer-price-analyzer";
import SaleonlineQuestions from "../../components/saleonline-questions";
import GenerateOfferPrice from "../../components/generate-offer-price";
import { LeadService } from "../../service";
import styles from "./lead-detail.module.scss";
import { isUserCj } from "../../common/utility";
import Loader from "../../../components/shared/loader";
import LeadAppointmentDetails from "../../components/lead-appointment-details";
const LeadDetail = (props) => {
	const {
		match,
		FetchLeadDetailConnect,
		FetchSaleonlineQuestionsConnect,
		isLoading,
		leadDetail,
		LoadingLeadDetailConnect,
	} = props;
	const token = match.params?.leadToken;
	const [screenType, setScreenType] = useState(SO_SCREEN_TYPES.NA);
	const [dataLoading, setDataLoading] = useState(true);
	const [refresh, setRefresh] = useState(false);
	// const source = props.location?.state?.source;
	const onUpdateDealerCity = (cityName, user_city_id) => {
		const payload = {
			city: cityName,
			price_eligible: 1,
			token: token,
		};
		LeadService.postSellonlineQuestion(payload)
			.then(() => {
				const updatePayload = {
					phone: leadDetail?.phone,
					make_id: leadDetail?.make_id,
					model_id: leadDetail?.model_id,
					year_id: leadDetail?.year_id,
					variant_id: leadDetail?.variant_id,
					device_category: "m-web",
					kms: leadDetail?.kms,
					state_id: leadDetail?.state_id,
					whatsapp_consent: true,
					rto_id: leadDetail?.rto_id,
					user_city_id: user_city_id,
					gs_eligible_car: APP_CONSTANTS.YES,
				};
				LeadService.updateLead(token, updatePayload).then(() => {
					FetchLeadDetailConnect(token);
				});
			})
			.finally(() => {
				LoadingLeadDetailConnect(false);
			});
	};

	useEffect(() => {
		if (!dataLoading && !!leadDetail) {
			LoadingLeadDetailConnect(true);
			const {
				gs_eligible_car,
				user_city_id,
				narrowPriceMin,
				city_name,
				lead_number,
				status,
			} = leadDetail;
			if (!!lead_number && status === "AppointmentCaptured") {
				setScreenType(SO_SCREEN_TYPES.APPOINTMENT_SUCCESS);
			} else if (!!narrowPriceMin && narrowPriceMin > 0) {
				if (isUserCj()) {
					setScreenType(SO_SCREEN_TYPES.SO_CJ_OFFER_PRICE);
				} else {
					setScreenType(SO_SCREEN_TYPES.SO_OFFER_PRICE);
				}
				// LoadingLeadDetailConnect(false);
			} else if (!gs_eligible_car && !!user_city_id) {
				onUpdateDealerCity(city_name, user_city_id);
			} else if (gs_eligible_car === APP_CONSTANTS.YES) {
				setScreenType(SO_SCREEN_TYPES.SO_QUESTIONS);
			} else {
				setScreenType(SO_SCREEN_TYPES.GS_NO_ELIGIBLE);
				// LoadingLeadDetailConnect(false);
			}
		}
		return () => {
			LoadingLeadDetailConnect(false);
		};
	}, [leadDetail, dataLoading]);

	useEffect(() => {
		if (token) {
			LoadingLeadDetailConnect(true);
			setDataLoading(true);
			FetchLeadDetailConnect(token).finally(() => {
				setDataLoading(false);
				LoadingLeadDetailConnect(false);
			});
		}
	}, [token, refresh]);

	useEffect(() => {
		if (!!screenType && !!leadDetail) {
			if (screenType === SO_SCREEN_TYPES.SO_QUESTIONS) {
				LoadingLeadDetailConnect(true);
				FetchSaleonlineQuestionsConnect(
					SO_QUESTION_VERSION.VERSION2,
					leadDetail
				).finally(() => {
					LoadingLeadDetailConnect(false);
				});
			}
		}
	}, [screenType, leadDetail]);

	const redirectRaOffer = () => {
		switch (screenType) {
			case SO_SCREEN_TYPES.SO_OFFER_PRICE:
				return <CarPriceOffer />;
			case SO_SCREEN_TYPES.SO_CJ_OFFER_PRICE:
				return <GenerateOfferPrice />;
			case SO_SCREEN_TYPES.SO_QUESTIONS:
				return (
					<SaleonlineQuestions
						setScreenType={setScreenType}
						screenType={screenType}
						setRefresh={setRefresh}
						footer={false}
					/>
				);
			case SO_SCREEN_TYPES.GS_NO_ELIGIBLE:
				return <NotGenerateOffer reason={leadDetail.gsDeniedReason} />;
			case SO_SCREEN_TYPES.SO_NO_OFFER_PRICE:
				return <NotGenerateOffer />;
			case SO_SCREEN_TYPES.SO_OFFER_PRICE_ANALYZER:
				return <RaOfferPriceAnalyzer />;
			case SO_SCREEN_TYPES.APPOINTMENT_SUCCESS:
				return (
					<LeadAppointmentDetails
						minPrice={leadDetail?.narrowPriceMin}
						maxPrice={leadDetail?.narrowPriceMax}
						token={token}
					/>
				);
			default:
				return <NotGenerateOffer />;
		}
	};

	return (
		<div className={styles.leadDetails}>
			{!isLoading && !!leadDetail && !dataLoading ? (
				<>
					{!!screenType && (
						<>
							{screenType === SO_SCREEN_TYPES.SO_QUESTIONS && (
								<h4 className={styles.heading}>Car Condition</h4>
							)}
							{[
								SO_SCREEN_TYPES.SO_OFFER_PRICE,
								SO_SCREEN_TYPES.SO_QUESTIONS,
								SO_SCREEN_TYPES.SO_CJ_OFFER_PRICE,
								SO_SCREEN_TYPES.APPOINTMENT_SUCCESS,
							].includes(screenType) && (
								<div className={styles.bookDetailwrapper}>
									<div className={styles.bookingDetails}>
										<h1>{`${leadDetail.year} ${leadDetail.make_display} ${leadDetail.model_display}`}</h1>
										{[
											SO_SCREEN_TYPES.APPOINTMENT_SUCCESS,
										].includes(screenType) && (
											<p>Appointment #{leadDetail?.appointment?.pubApptId}</p>
										)}
									</div>
									{![
										SO_SCREEN_TYPES.APPOINTMENT_SUCCESS,
									].includes(screenType) && (
										<p className={styles.bottomText}>
											{`${leadDetail.variant_display_name} ${leadDetail.rto_code}`}
										</p>
									)}
								</div>
							)}
							{redirectRaOffer()}
						</>
					)}
				</>
			) : (
				<Loader />
			)}
		</div>
	);
};

export default LeadDetail;
