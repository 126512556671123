import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./datepicker.module.css";

const DatepickerComponent = ({
  name = "",
  classNames = "",
  selected,
  onChange,
  showTime = false,
  showTimeOnly = false,
  placeholder = ""
}) => {
  return (
    <div className={styles.inputShift}>
      <DatePicker
        name={name}
        placeholderText={placeholder}
        calendarClassName="datepicker-container"
        className={classNames}
        selected={selected}
        onChange={(e) => onChange(e)}
        minDate={new Date()}
        disabledKeyboardNavigation
        showTimeSelect={showTime}
        showTimeSelectOnly={showTimeOnly}
        timeFormat={showTimeOnly ? "" : "HH:mm"}
        timeIntervals={15}
        dateFormat={showTimeOnly ? "h:mm aa" : "d MMMM yyyy h:mm aa"}
        timeCaption="Time"
      />
    </div>    
  );
};

export default DatepickerComponent;
