/* eslint-disable indent */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SimilarCars from "./component";
import { fetchSimilarCarDetails } from "./actions";

const mapStateToProps = (
    {
        similarCars
    }) => (
        {
            similarCars //combined reducer
        });

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSimilarCarDetailsConnect: fetchSimilarCarDetails
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SimilarCars);
