import ProposedOfferPrice from "../../pages/mobile/customer/proposed-offer-price";
import CustomerConsentCharges from "../../pages/customer-consent-charges";
import Login from "../../pages/login";
import SelfInspectionReport from "../../pages/self-inspection-report";
import WaittimeDashboard from "../../pages/waittime-dashboard";
import LeadDetail from "../../pages/lead-detail";
import Negotiation from "../../pages/negotiation";
import CjPlanning from "../../pages/cj-planning";
import CjExecution from "../../pages/cj-execution";
import UploadCsv from "../../pages/upload-csv";
import Appointment from "../../pages/appointment";
import SellOnline from "../../pages/sell-online";
import LostLeads from "../../pages/lost-leads";
import LeadCheckIn from "../../pages/lead-check-in";
import EvaluatorView from "../../pages/evaluator-view";
import EvaluatorStoreSelect from "../../pages/evaluator-store-select";
import PllLead from "../../pages/pll-leads";
import SimilarCars from "../../pages/similar-cars";
import Profile from "../../pages/profile";
import NegotiationLostLead from "../../pages/negotiation-lost-lead";
import CustomerOfferPrice from "../../pages/customer-offer-price";
import DepReport from "../../pages/dep-report";
import CustomerJourney from "../../pages/customer-journey";
import SelfCheckin from "../../pages/self-checkin";
import Incentive from "../../pages/incentive";
import PaymentInitiateToken from "../../pages/payment-initiate-token";
import DocumentUpload from "../../pages/document-upload";
import CallReportsPage from "../../pages/call-reports";
import CallLogs from "../../pages/call-logs";
import ZendeskTickets from "../../pages/zendesk-tickets";
import AppointmentPage from "../../pages/unallocated-leads/";
export const openRoutes = [
	{
		text: "ProposedOfferPrice",
		key: "ProposedOfferPrice",
		path: "/proposed-offer-price",
		exact: true,
		component: ProposedOfferPrice,
	},
	{
		key: "CustomerConsentCharges",
		text: "CustomerConsentCharges",
		path: "/customer-consent-charges/:appointmentId",
		exact: true,
		component: CustomerConsentCharges,
	},
	{
		key: "Login",
		text: "Login",
		path: "/login",
		exact: true,
		component: Login,
	},
	{
		text: "SelfInspectionReport",
		key: "SelfInspectionReport",
		path: "/self-Inspection-report",
		exact: true,
		component: SelfInspectionReport,
	},
	{
		text: "WaittimeDashboard",
		key: "WaittimeDashboard",
		path: "/customer-wait-time/:storeId",
		exact: true,
		component: WaittimeDashboard,
	},
	{
		text: "LeadDetail",
		key: "LeadDetail",
		path: "/detail/:appointmentId",
		exact: true,
		component: LeadDetail,
	},
];

export const secureRoutes = [
  {
    key: 'Home',
	  path: "/",
	  exact: true,
	  component: Negotiation,
  },
  {
	  path: "/cj-planning",
	  exact: true,
	  component: CjPlanning,
	  key: 'CjPlanning'
  },
  {
	  path: "/cj-execution",
	  exact: true,
	  component: CjExecution,
	  key: 'CjExecution'
  },
  {
	  path: "/upload-csv",
	  exact: true,
	  component: UploadCsv,
	  key: 'UploadCsv'
  },
  {
	  path: "/(scheduled|visited)",
	  exact: true,
	  component: Appointment,
	  key: 'Appointment'
  },
  {
	  path: "/sell-online",
	  exact: true,
	  component: SellOnline,
	  key: 'SellOnline'
  },
  {
	  path: "/negotiation",
	  exact: true,
	  component: Negotiation,
	  key: 'Negotiation'
  },
  {
	  path: "/diy-negotiation",
	  exact: true,
	  component: Negotiation,
	  key: 'diy-negotiation'
  },
  {
	  path: "/clearance-pending",
	  exact: true,
	  component: Appointment,
	  key: 'clearance-pending'
  },
  {
	  path: "/inspected",
	  exact: true,
	  component: Appointment,
	  key: 'inspected'
  },
  {
	  path: "/payment-processing",
	  exact: true,
	  component: Appointment,
	  key: 'payment-processing'
  },
  {
	  path: "/lost-leads",
	  exact: true,
	  component: LostLeads,
	  key: 'lost-leads'
  },
  {
	  path: "/check-in",
	  exact: true,
	  component: LeadCheckIn,
	  key: 'check-in'
  },
  {
	  path: "/evaluator-view/:storeId",
	  exact: true,
	  component: EvaluatorView,
	  key: 'evaluator-view'
  },
  {
	  path: "/evaluator-store-select",
	  exact: true,
	  component: EvaluatorStoreSelect,
	  key: 'evaluator-store-select'
  },
  {
	  path: "/pll-lead",
	  exact: true,
	  component: PllLead,
	  key: 'pll-lead'
  },
  {
	  path: "/similar-cars/:appointmentId",
	  exact: true,
	  component: SimilarCars,
	  key: 'similar-cars'
  },
  {
	  path: "/profile",
	  exact: true,
	  component: Profile,
	  key: 'profile'
  },
  {
	  path: "/nego-lost-lead",
	  exact: true,
	  component: NegotiationLostLead,
	  key: 'nego-lost-lead'
  },
  {
	  path: "/customer-offer-price/:appointmentId",
	  exact: true,
	  component: CustomerOfferPrice,
	  key: 'customer-offer-price'
  },
  {
	  path: "/dep-report/:appointmentId",
	  exact: true,
	  component: DepReport,
	  key: 'dep-report'
  },
  {
	  path: "/customer-journey/:appointmentId",
	  exact: true,
	  component: CustomerJourney,
	  key: 'customer-journey'
  },
  {
	  path: "/self-checkin",
	  exact: true,
	  component: SelfCheckin,
	  key: 'self-checkin'
  },
  {
	  path: "/hypo-incentive",
	  exact: true,
	  component: CallReportsPage,
	  key: 'hypo-incentive'
  },
  {
	  path: "/zm-incentive",
	  exact: true,
	  component: CallReportsPage,
	  key: 'zm-incentive'
  },
  {
	  path: "/ra-incentive",
	  exact: true,
	  component: CallReportsPage,
	  key: 'ra-incentive'
  },
  {
	path: "/zendesk-tickets",
	exact: true,
	component: ZendeskTickets,
	key: 'zendesk-tickets',
},
  {
	  path: "/payment-initiate-token/:appointmentId",
	  exact: true,
	  component: PaymentInitiateToken,
	  key: 'payment-initiate-token'
  },
  {
	  path: "/document-upload/:appointmentId",
	  exact: true,
	  component: DocumentUpload,
	  key: 'document-upload'
  },
  {
	  path: "/call-reports",
	  exact: true,
	  component: CallReportsPage,
	  key: 'call-reports'
  },
  {
	  path: "/incoming-call-logs",
	  exact: true,
	  component: CallLogs,
	  key: 'incoming-call-logs'
  },
  {
	  path: "/rt-negotiation",
	  exact: true,
	  component: Negotiation,
	  key: 'rt-negotiation'
  },
  {
	  path: "/rt-diy-negotiation",
	  exact: true,
	  component: Negotiation,
	  key: 'rt-diy-negotiation'
  },
  {
	path: "/leadmon",
	exact: true,
	component: Appointment,
	key: 'leadmon'
},
{
	path: "/allocate",
	exact: true,
	component: PllLead,
	key: "pll-lead-allocate",
},
{
	path: "/unallocated-leads",
	exact: true,
	component: AppointmentPage,
	key: "unallocate-leads",
},
];