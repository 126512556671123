import AutoSuggestion from "./auto-suggestion";
import MapMyIndiaSuggestion from "./mmi-suggestion";
import { MAP_TYPE } from "../app-constants";

const AutoComplete = (function () {
  let instance = null;

  return {
    getInstance(type) {
      if (!instance) {
        if (type === MAP_TYPE.MMI) {
          instance = new MapMyIndiaSuggestion();
        } else if (type === MAP_TYPE.GOOGLE) {
          instance = new AutoSuggestion();
        }
      }
      return instance;
    }
  };
})();

export default AutoComplete;
