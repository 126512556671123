import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ListingCarPopup from "./component";
import { paiRequest, setCurrentPopup } from "../negotiation-list/actions";

const mapStateToProps = ({ negotiations }) => ({
  negotiations
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setCurrentPopupConnect: setCurrentPopup,
  paiRequestConnect: paiRequest
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListingCarPopup);
