import React, { useState, useEffect, useRef } from "react";
import styles from "./customer-journey.module.scss";
import CustomerJourneyCard from "../../components/customer-journey-card";
import DepReportJourney from "../../components/dep-report-journey";
import CustomerOfferPriceJourney from "../../components/customer-offer-price-journey";
import CustomerOfferPriceJourneyNew from "../../components/customer-offer-price-journey-new";
import Arrow from "../../components/images/arrow.svg";
import { GetQueryString } from "../../utils/helper";
import { trackLMSEvents } from "../../utils/helpers/tracking-events";
import {getUid, getFromLocalStorage, getRandomNumberBetweenRange, showCovidBanner, 
  seDealerCountLocalStaorageKey, isPllUser} from "../../utils/helper";
import { APPOINTMENT_TYPES_STRING, LOCAL_STORAGE_KEYS } from "../../utils/app-constants";
import Feedback from "../../components/feedback/component";
import DepriciationEnterOtp from "../../components/depriciation-enter-otp/component";
import HocAccessController from "../../components/hoc-access-controller";
import Loader from "../../components/shared/loader";
const CustomerJourney = ({
  match,
  fetchNegotiationsConnect,
  ResetApiNoAccessConnect,
}) => {
  const isPll = isPllUser();
  const [isInventoryBuyingByApi, setisInventoryBuyingByApi] = useState(false);
  const reptType = GetQueryString("showreport");
  const fromReport = GetQueryString("report");
  const isDepReportAvailable = GetQueryString("isDepReportAvailable");
  const showOtpUnlockTimer = GetQueryString("showOtpUnlockTimer");
  const [negotiationData, setNegotiationData] = React.useState({ currentAppointmentIndex: 0, data: []});
  const isInventoryBuying = GetQueryString("isInventoryBuying") === 'true';
  const diy = GetQueryString("diy");
  const withCustomer = GetQueryString("withCustomer") === 'true';
  const c24PriceRef = useRef();
  const { params: { appointmentId } } = match;
  const raId = getUid();
  const appointmentType = !!diy ? APPOINTMENT_TYPES_STRING.DIY_NEGOTIATION : APPOINTMENT_TYPES_STRING.NEGOTIATION;
  const [showDepReportJourney, setShowDepReportJourney] = useState(reptType === "dep" ? true : false);
  const [showCustomerOfferPriceJourney, setShowCustomerOfferPriceJourney] = useState(reptType === "charge" ? true : false);
  const bShowCovidBanner = showCovidBanner();
  if (isDepReportAvailable !== "true") {
    let dealerCount = getRandomNumberBetweenRange(300, 400);
    seDealerCountLocalStaorageKey(appointmentId, dealerCount);
  }
  let category = "Negotiation";
  if (!!fromReport && fromReport.toLowerCase() === "inspected-state") {
    category = "Inspected";
  }
  let isFeedBackSubmit = getFromLocalStorage(LOCAL_STORAGE_KEYS.DEP_FEEDBACK_SUBMIT);
  const ShowHideDepReportTabandTrack = (bFlag) => {
    setShowDepReportJourney(bFlag);
    setShowCustomerOfferPriceJourney(!bFlag);
    let action = "OpenDepDetails";
    if (!bFlag) { action = "CloseDepDetails"; }
    trackLMSEvents("ls_ViewDepDetails", {
      eventAction: action,
      eventCategory: category,
      eventLabel: appointmentId,
      lsRAID: raId,
      Timestamp: new Date()
    });
  };
  const ShowHideAuctionTabandTrack = (bFlag) => {
    setShowCustomerOfferPriceJourney(bFlag);
    setShowDepReportJourney(!bFlag);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    let action = "OpenOfferDetails";
    if (!bFlag) { action = "CloseOfferDetails"; }
    trackLMSEvents("ls_ViewOfferDetails", {
      eventAction: action,
      eventCategory: category,
      eventLabel: appointmentId,
      lsRAID: raId,
      Timestamp: new Date()
    });
  };
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    ResetApiNoAccessConnect();
    if (appointmentId) {
      setIsLoading(true);
      fetchNegotiations();
    }
  }, []);

  const fetchNegotiations = () =>  {
    let globalSearchQuery = appointmentId + "&globalSearch=true";
    fetchNegotiationsConnect(1, globalSearchQuery, null, null, null, null, null, null, withCustomer, appointmentType)
      .then(resp => {
        if (resp.data) {
          const { data: { detail: { data: { result } } } } = resp;
          if (result.length > 0) {
            const negoData = result.find(negotiation => negotiation.appointmentId === appointmentId);
            if (negoData) {
              setisInventoryBuyingByApi(negoData.isInventoryBuying);
              setNegotiationData({...negotiationData, data: [negoData]});
            }
          }
          else {
            document.getElementById("dvPostAuction").style.display = "none";
          }
        }
      })
      .catch(error => console.log(error)).finally(()=>{
        setIsLoading(false);
      });
  };

  return (
    <>{isLoading ? 
      <Loader /> :
      <HocAccessController>
        <div className={styles.customerJourney}>
          {
            showOtpUnlockTimer === "true" &&
            <DepriciationEnterOtp 
              appointmentId={appointmentId}
              negotiationData={negotiationData}
              fetchNegotiations={fetchNegotiations}
              setShowCustomerOfferPriceJourney={setShowCustomerOfferPriceJourney}
              setShowDepReportJourney={setShowDepReportJourney}
            />
          }
          <h1>PRICE ESTIMATION FOR YOUR CAR</h1>
          <>
            {(isDepReportAvailable === "true" && bShowCovidBanner) && <div className={styles.covidWrapper}>
              {/* <img src={Covid19} alt="covid19" />
                            <p>The prices estimations shown below are based on the data analysis before the effects of COVID-19 on the markets. Hence the <h3>final offer price might be lower</h3> than the predicted prices. </p> */}
            </div>
            }
            {isDepReportAvailable === "true" &&
            <CustomerJourneyCard match={match} />
            }
          </>
          {
            isDepReportAvailable === "true" &&
            <div id="cars24_dvDepReport" appointmentId={appointmentId} raId={raId} showDepReportDiv={showDepReportJourney ? "true" : "false"} className={`${styles.depReportJourney} ${showDepReportJourney ? styles.active : ''}`}>
              <div className={styles.strip} onClick={() => ShowHideDepReportTabandTrack(!showDepReportJourney)} >
                <span>Standard Depreciation and Deductions</span>
                <span className={styles.arrow}>
                  <img src={Arrow} alt="arrow" />
                </span>
              </div>
              <div className={styles.depReportJourneyContent}>
                <DepReportJourney match={match} />
              </div>
            </div>
          }
          {!isPll && showOtpUnlockTimer !== "true" && <div id="cars24_dvAuctionPrice" appointmentId={appointmentId} raId={raId} showAuctionPriceDiv={showCustomerOfferPriceJourney ? "true" : "false"} className={`${styles.customerOfferPriceJourney} ${showCustomerOfferPriceJourney ? styles.active : ''}`}>
            <div id="dvPostAuction" className={styles.strip} onClick={() => ShowHideAuctionTabandTrack(!showCustomerOfferPriceJourney)}>
              <span>Post Auction Price</span>
              <span className={styles.arrow}>
                <img src={Arrow} alt="arrow" />
              </span>
            </div>
            <div className={styles.customerOfferPriceJourneyContent} ref={c24PriceRef}>
              {isInventoryBuyingByApi ?
                <CustomerOfferPriceJourneyNew match={match} isInventoryBuying={isInventoryBuying} appointmentType={appointmentType} withCustomer={withCustomer}/> :
                <CustomerOfferPriceJourney match={match} isInventoryBuying={isInventoryBuying} appointmentType={appointmentType} withCustomer={withCustomer}/>
              }
            </div>
          </div>}
          {!isFeedBackSubmit && <Feedback appointmentId={appointmentId} />}
        </div>
      </HocAccessController>}
    </>
  );
};

export default CustomerJourney;
