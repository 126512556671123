import React from "react";
import styles from "./store-search-item.module.scss";

const StoreSearchItem = ({ item }) => {
  const { name, storeCity} = item;
  return (
    <>
      <div className={styles.leadInfo}>
        <p className={styles.searchId}>{name}</p>
        <span>{storeCity}</span>
      </div>
    </>
  );
};

export default StoreSearchItem;
