import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LeadMenu from "./component";
import { resetLeadMenuByKey } from "./actions";
import { fetchAppointmentCounts } from "../sidebar/actions";
import { resetNotificationnByKey } from "../notifications/actions";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  resetLeadMenuByKeyConnect: resetLeadMenuByKey,
  fetchAppointmentCountsConnect: fetchAppointmentCounts,
  resetNotificationnByKeyConnect: resetNotificationnByKey
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LeadMenu);
