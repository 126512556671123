import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DepReport from "./component";
import { fetchDepreciationReports } from "../../components/depreciation-reports/actions";

const mapStateToProps = ({
  depreciation
}) => ({
  depreciation
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchDepreciationReportsConnect: fetchDepreciationReports
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DepReport);
