import React, { useState, useEffect } from "react";
import styles from "./customer-journey-card.module.scss";
import auctionRunning from "../../components/images/auctionRunning.svg";
import DealersIcons from "../../components/images/dealersIcons.svg";
import dealerCarIcons from "../../components/images/dealerCarIcons.svg";
import {
  TimeRemainingFromNow, getPercentIncreased, saveToLocalStorage, getFromLocalStorage, setDealerImpressionStartLocalStaorageKey
} from "../../utils/helper";
import { LOCAL_STORAGE_KEYS } from "../../utils/app-constants";

const CustomerJourneyCard = ({
  match,
  fetchCustomerOfferDetailsConnect
}) => {
  const [showComponent, setShowComponent] = useState(false);
  const { params: { appointmentId } } = match;
  const [remainingTime, setRemainingTime] = useState("");
  const [dealerReachedOut, setDealerReachedOut] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState("");
  const [dealerImpression, setDealerImpression] = useState("");
  const [boughtCarsNo, setBoughtCarsNo] = useState(0);
  const [boughtCarsMsg, setBoughtCarsMsg] = useState("");
  useEffect(() => {
    const storageDealerCount = getFromLocalStorage(LOCAL_STORAGE_KEYS.DEALER_COUNT);
    if (!!storageDealerCount) {
      if (parseInt(storageDealerCount) < dealerReachedOut) {
        setDealerReachedOut(dealerReachedOut);
      }
    }
    else {

      setDealerReachedOut(dealerReachedOut);
    }
  }, [dealerReachedOut]);

  useEffect(() => {
    let graphInterval;
    if (appointmentId > 0) {
      fetchCustomerOfferDetailsConnect(appointmentId)
        .then(response => {
          const { auction_bidding_status, auction_start_time, make, model,
            auction_end_time, boughtCars, impression } = response;
          if (!!auction_bidding_status && auction_bidding_status !== "BID_OVER") {
            setBoughtCarsNo(boughtCars);
            const msg = `${make} ${model} bought in last 6 months.`; ///setBoughtCarsMsg
            setBoughtCarsMsg(msg);
            graphInterval = setInterval(() => {
              let _timeRemaining = TimeRemainingFromNow(auction_end_time, auction_start_time);
              setTimeRemaining(_timeRemaining);
              if (!!_timeRemaining && (_timeRemaining.Minute < 1 && _timeRemaining.Seconds < 10)) {
                clearInterval(graphInterval);
              }
            }, 1000);

            const { start } = impression;
            setDealerImpressionStartLocalStaorageKey(appointmentId, start);
            setDealerImpression(impression);
          }

        })
        .catch(error => {
          console.log('Error getting c2cboundryValues', error);
        });

    }
    return () => {
      clearInterval(graphInterval);
    };
  }, [appointmentId]);
  useEffect(() => {
    let interval;
    if (!!timeRemaining) {
      let randomNumber = 0;
      let impressionPrcnt = [];
      if (!!dealerImpression) {
        const { start, pecentage } = dealerImpression;
        let _dealerImpressionStart = setDealerImpressionStartLocalStaorageKey(appointmentId, start);
        randomNumber = _dealerImpressionStart;
        impressionPrcnt = pecentage;
      }
      const timeleft = (!!timeRemaining && !!timeRemaining.Minute) ? timeRemaining.Minute : 0;
      const totalMinute = (!!timeRemaining && !!timeRemaining.TotalMinutes) ? timeRemaining.TotalMinutes : 0;
      let timelefttoShowDealerReachedOut = totalMinute - timeleft;
      let dealerCount = timelefttoShowDealerReachedOut > 0 ? getPercentIncreased(randomNumber, timelefttoShowDealerReachedOut, impressionPrcnt) : randomNumber;
      try {
        let storageDealerCount = getFromLocalStorage(LOCAL_STORAGE_KEYS.DEALER_COUNT);
        if (!!storageDealerCount) {
          if (parseInt(storageDealerCount) < dealerCount) {
            setDealerReachedOut(dealerCount);
          }
        }
        else {

          setDealerReachedOut(dealerCount);
        }
      }
      catch (e) {
        setDealerReachedOut(dealerCount);
      }
      saveToLocalStorage(LOCAL_STORAGE_KEYS.DEALER_COUNT, dealerReachedOut > 0 ? 0 : "");
      if (!!timeRemaining) {
        if (!!timeRemaining.Minute) {
          let _tMine = timeRemaining.Minute > 9 ? timeRemaining.Minute : `0${timeRemaining.Minute}`;
          let _tSec = timeRemaining.Seconds > 9 ? timeRemaining.Seconds : `0${timeRemaining.Seconds}`;
          setRemainingTime(`${_tMine}:${_tSec}`);
        }
      }
      setShowComponent(true);
      interval = setInterval(() => {
        timelefttoShowDealerReachedOut = timelefttoShowDealerReachedOut + 4;
        dealerCount = getPercentIncreased(randomNumber, timelefttoShowDealerReachedOut, impressionPrcnt);
        setDealerReachedOut(dealerCount);
        const storageDealerCount = getFromLocalStorage(LOCAL_STORAGE_KEYS.DEALER_COUNT);
        if (!!storageDealerCount) {
          if (parseInt(storageDealerCount) < dealerCount) {
            setDealerReachedOut(dealerCount);
          }
        }
        else {
          setDealerReachedOut(dealerCount);
        }
        saveToLocalStorage(LOCAL_STORAGE_KEYS.DEALER_COUNT, dealerReachedOut > 0 ? 0 : "");
        if (timeleft <= 1) {
          clearInterval(interval);
        }
      }, 240000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [appointmentId, dealerImpression, dealerReachedOut, timeRemaining]);

  return (
    <>
      {showComponent &&
      <div className={styles.customerJourneyCard}>
        <div className={styles.card}>
          <img src={auctionRunning} alt="auctionRunning" />
          <div className={styles.chart}>
            <div className={styles.graph}>
              <h4 className={styles.runningTime}>{remainingTime}</h4>
              <h5 className={styles.timeRemaining}>minutes remaining</h5>
            </div>
          </div>
          <p className={styles.auctionRunning}>Auction Running</p>
        </div>

        <div className={styles.card}>
          <img src={DealersIcons} alt="dealersIcons" />
          <h3 className={styles.blueColor}>{dealerReachedOut > 0 ? dealerReachedOut : ""}</h3>
          <p>Channel Partners who viewed your car.</p>
        </div>
        <div className={styles.card}>
          <img src={dealerCarIcons} alt="dealerCarIcons" />
          <h3 className={styles.orangeColor}>{boughtCarsNo}</h3>
          <p>{boughtCarsMsg}</p>
        </div>
      </div>
      }
    </>
  );
};

export default CustomerJourneyCard;

