import React from "react";
import styles from "./round-arrow-red.module.css";
import RedArrow from "./images/red-arrow.svg";

const IconRoundArrowRed = () => {
  return (
    <div className={styles.arrowWrap}>
      <img alt="" src={RedArrow} />
    </div>
  );
};

export default IconRoundArrowRed;
