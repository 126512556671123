export default (api) => {
    
  const getDealers = (search) => {
    return new Promise((resolve, reject)=> {
      api.get(`api/v1/dealer?keyword=${search}`).then((response)=>{
        resolve(response.data);
      }).catch(err => {
        reject(err);
      });
    });
  };
  const getRALeads = ({userEmail, pageNo=0, filterQuery='', sort=''}) => {
    return new Promise((resolve, reject)=> {
      api.get(`api/v1/leads?user_email=${userEmail}&page=${pageNo}&size=10${!!filterQuery ? `&filter=${filterQuery}` : ''}${!!sort ? `&sort_direction=${sort}` : ''}`).then((response)=>{
        resolve(response.data);
      }).catch(err => {
        reject(err);
      });
    });
  };

  const getStoreAvailableSlots = ({lat, lng, token}) => {
    return new Promise((resolve, reject)=> {
      api.get(`api/v2/slots/available/store-and-zone?token=${token}&lat=${lat}&lng=${lng}&inspection_type=PnS`).then((response)=>{
        resolve(response.data);
      }).catch(err => {
        reject(err);
      });
    });
  };

  return {
    getStoreAvailableSlots,
    getDealers,
    getRALeads,
  };
};