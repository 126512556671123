import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  fbStoreId: 0,notificationCount:0
};

export const resetNotification = () => {
  return INITIAL_STATE;
};

export const resetNnotificationByKey = (state = INITIAL_STATE, { data }) => {
  const { key, value } = data;
  return { ...state, [key]: value };
};

export const HANDLERS = {
  [Types.RESET_NOTIFICATION]: resetNotification,
  [Types.RESET_NOTIFICATION_BY_KEY]: resetNnotificationByKey
};

export default createReducer(INITIAL_STATE, HANDLERS);