export const vahanCheckStatus = [
  {
    status: 0,
    label: 'Not Applicable',
    statusColor: 'activeGreen'
  },
  {
    status: 1,
    label: 'Pending',
    statusColor: 'activeRed'
  },
  {
    status: 2,
    label: 'Draft',
    statusColor: 'activeRed'
  },
  {
    status: 3,
    label: 'Completed',
    statusColor: 'activeGreen'
  },
  {
    status: 4,
    label: 'Requested',
    statusColor: 'activeRed'
  },
];

export const rcCheckStatus = [
  {
    status: 0,
    label: 'Not Initiated',
    statusColor: 'activeRed'
  },
  {
    status: 1,
    label: 'Initiated',
    statusColor: 'activeGreen'
  },
  {
    status: 2,
    label: 'Draft',
    statusColor: 'activeRed'
  },
  {
    status: 3,
    label: 'Completed',
    statusColor: 'activeGreen'
  },
  {
    status: 4,
    label: 'Requested',
    statusColor: 'activeRed'
  },
];

export const businessApprovalStatus = [
  {
    status: 0,
    label: 'Not Applicable',
    statusColor: 'activeRed'
  },
  {
    status: 1,
    label: 'Applicable',
    statusColor: 'activeGreen'
  }
];

export const serviceHistoryStatus = [
  {
    status: 0,
    label: 'Not Initiated',
    statusColor: 'activeRed'
  },
  {
    status: 1,
    label: 'Pending',
    statusColor: 'activeRed'
  },
  {
    status: 2,
    label: 'Save Draft',
    statusColor: 'activeGreen'
  },
  {
    status: 3,
    label: 'Completed',
    statusColor: 'activeGreen'
  },
  {
    status: 4,
    label: 'Re-Requested',
    statusColor: 'activeRed'
  },
];
