import React, { useState, useEffect } from "react";
import styles from "./offer-popup.module.scss";
import H2 from "../h2/component";
import { AppointmentService } from "../../service";
const OfferPopup = ({
  offerData
}) => {
  const [data, setData] = useState(offerData);
  return (
    <div className={styles.offerPopupWrapper}>
      <H2 text={"Offer"} />
      <div className={styles.offerTable}>
        <table cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th>S No.</th>
              <th>Company</th>
              <th>Discription</th>
              <th>Discount</th>
            </tr>
          </thead>
          <tbody>
            {data.map(item => {
              return <tr>
                <td>{item.sNo}</td>
                <td>{item.Company}</td>
                <td>{item.Description}   </td>
                <td>{item.Discount}</td>
              </tr>;

            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OfferPopup;
