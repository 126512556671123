import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CjPlanning from "./component";
import withLayout from "../../components/layout/component";
const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);
const mapStateToProps = ({
}) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withLayout(CjPlanning));