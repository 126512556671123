import axios from "axios";
import { ERROR_CODES } from "../../service/api-constants";
import { getFromLocalStorage } from "../../utils/helper";
import { getPartnersLeadMetaData } from "../../utils/url-constants";
import { LS_KEYS } from "../common/app-constants";
import { onBackLogin } from "../common/utility";
import { getSessionToken } from "@descope/react-sdk";
const DEBUG = process.env.NODE_ENV === "development";
const apiMetaData = getPartnersLeadMetaData();

const api = axios.create({
  baseURL: apiMetaData.url,
  headers: {
    "Content-Type": "application/json",
    X_COUNTRY: "IN",
    X_VEHICLE_TYPE: "CAR",
  },
});

api.interceptors.request.use(
  (config) => {
    // config.headers['Authorization'] = `Bearer ${getFromLocalStorage(LS_KEYS.USER_ACCESS_TOKEN)}`;
    config.headers["x-auth-key"] = `${getSessionToken()}`;

    if (DEBUG) {
      console.info("✉️ ", config);
    }
    return config;
  },
  (error) => {
    if (DEBUG) {
      console.error("✉️ ", error);
    }
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    if (DEBUG) {
      console.info("📩 ", response);
    }
    return response;
  },
  (error) => {
    if (DEBUG) {
      console.error("Error: ", error.message);
    }
    if (
      error.response &&
      error.response.status === ERROR_CODES.UNAUTHORISED &&
      !window.location.pathname.includes("login")
    ) {
      localStorage.clear();
      onBackLogin();
    }
    return Promise.reject(error.message);
  }
);

export default api;
