import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchAppointments, resetAppointmentByKey, resetAppointments, sortAppointmentByKey } from "../../pages/appointment/actions";
import { resetGlobalSearch } from "../global-search/actions";
import { resetLeadMenuByKey } from "../lead-menu/actions";
import { resetAppointmentCountByKey } from "../sidebar/actions";
import CjPlanningCard from "./component";

const mapStateToProps = ({
  appointments,
  search,
  globalSearch,
  leadMenu
}) => ({
  appointments,
  search,
  globalSearch,
  leadMenu
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchAppointmentsConnect: fetchAppointments,
  resetAppointmentCountByKeyConnect: resetAppointmentCountByKey,
  resetGlobalSearchConnect: resetGlobalSearch,
  resetAppointmentByKeyConnect: resetAppointmentByKey,
  sortAppointmentByKeyConnect: sortAppointmentByKey,
  resetAppointmentsConnect: resetAppointments,
  resetLeadMenuByKeyConnect: resetLeadMenuByKey
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CjPlanningCard);
