import React, { useEffect, useState } from "react";
import ExponentialGraph from "../exponential-graph";
import DepreciationBar from "../depreciation-bar";
import styles from "./depreciation-reports.module.scss";
import DepreciationCarReports from "../depreciation-car-reports";
import DepreciationInspectionReports from "../depreciation-inspection-reports";
import { trackLMSEvents } from "../../utils/helpers/tracking-events";
import { getDepreciationTooltip, getTimeDifference, getUid } from "../../utils/helper";
import { DEPRECIATION_CONSTANTS } from "../../utils/app-constants";
const DepreciationReports = ({
  depreciation,
  appointmentId,
  trackEvent = false,
  openTime = ""
}) => {
  const { data: { data } } = depreciation;

  useEffect(() => {
    return () => {
      const { data: priceData } = data;
      if (trackEvent) {
        trackLMSEvents("closeReportFromInspected", {
          eventLabel: appointmentId,
          lsViewTime: Math.floor(getTimeDifference(openTime, new Date()) / 1000),
          lsRAID: getUid(),
          webQuote: getDepreciationTooltip(DEPRECIATION_CONSTANTS.veryGoodMax, priceData).gtmData,
          expectedQuote: getDepreciationTooltip(DEPRECIATION_CONSTANTS.final, priceData).gtmData
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={styles.depreciationReports}>
      <DepreciationCarReports graphData={data} />
      <ExponentialGraph graphData={data} />
      <DepreciationBar graphData={data} />
      <DepreciationInspectionReports graphData={data} />
    </div>
  );
};

export default DepreciationReports;
