import Types from "./types";
import { AppointmentService } from "../../service";

const fetchPllStores = (stores) => dispatch => {
  return AppointmentService.getStoreListV2(stores)
    .then(response => {
      const { detail } = response;
      var TotalUnAllocatedCount = !!detail ? detail.reduce(function (prev, cur) { return prev + cur.unAllocatedPllLeadCount; }, 0) : 0;
      dispatch(fetchPllStoresSuccess(response));
      dispatch(resetPLLByKey("TotalCount", TotalUnAllocatedCount));
      return response;
    })
    .catch(error => dispatch(fetchPllStoresFailure(error)));
};

const fetchAppointmentList = (storeIds, gsAssuredParam) => dispatch => {
  return AppointmentService.getPllRaAppointmentList(null, storeIds, gsAssuredParam)
    .then(response => {
      //response = getAppointmentList(storeId);
      const { data } = response;
      dispatch(fetchAppointmentListSuccess(data));
      return response;
    })
    .catch(error => dispatch(fetchAppointmentListFailure(error)));
};

const fetchStoresAppointments = (storeIds, gsAssuredParam, page, limit) => dispatch => {
  return AppointmentService.getPllAppointmentList(storeIds, gsAssuredParam, page, limit)
    .then(response => {
      const { data } = response;
      dispatch(fetchAppointmentListSuccess(data));
      return response;
    })
    .catch(error => dispatch(fetchAppointmentListFailure(error)));
};

const fetchPllAssociates = (cityname) => dispatch => {
  return AppointmentService.getPllRaList(null,cityname)
  // return AppointmentService.getStoreList()
    .then(response => {
      // response = getPllAssociatesList();
      const { data } = response;
      dispatch(fetchPllAssociatesSuccess(data));
      return response;
    })
    .catch(error => dispatch(fetchPllAssociatesFailure(error)));
};

const updatePllStoreList = (storeList) => ({
  //UPDATE_PLL_STORE_LIST
  type: Types.UPDATE_PLL_STORE_LIST,
  storeList
});

const updateStoreListForCheck = (id) => ({
  type: Types.UPDATE_STORE_LIST,
  id
});

const fetchPllStoresSuccess = (data) => ({
  type: Types.FETCH_PLL_STORE_SUCCESS,
  data
});

const fetchPllStoresFailure = (error) => ({
  type: Types.FETCH_PLL_STORE_FAILURE,
  error
});

const fetchAppointmentListSuccess = (data) => ({
  type: Types.FETCH_APPOINTMENT_LIST_SUCCESS,
  data
});

const fetchAppointmentListFailure = (error) => ({
  type: Types.FETCH_APPOINTMENT_LIST_FAILURE,
  error
});

const fetchPllAssociatesSuccess = (data) => ({
  type: Types.FETCH_PLL_ASSOCIATES_SUCCESS,
  data
});

const fetchPllAssociatesFailure = (error) => ({
  type: Types.FETCH_PLL_ASSOCIATES_FALIURE,
  error
});

const resetPLLByKey = (key, value) => ({
  type: Types.RESET_PLL_BY_KEY,
  data: { key, value }
});

const resetPllAllocation = () => ({
  type: Types.RESET_PLL
});

const setPllLeadTypeFilter = (leadTypeFilter) => ({
  type: Types.SET_PLL_LEAD_TYPE_FILTER,
  data: { leadTypeFilter }
});

export {
  fetchStoresAppointments,
  fetchPllStores,
  updateStoreListForCheck,
  fetchAppointmentList,
  fetchPllAssociates,
  resetPLLByKey,
  updatePllStoreList,
  resetPllAllocation,
  setPllLeadTypeFilter,
};
