import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EvaluatorStoreTabs from "./component";
import { fetchEvaluatorTokens} from "../../pages/evaluator-view/actions";

const mapStateToProps = ({
  evaluator
}) => ({
  evaluator
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchEvaluatorTokensConnect: fetchEvaluatorTokens
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EvaluatorStoreTabs);
