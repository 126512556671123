import React, { useRef } from "react";
import Select from 'react-select';
import styles from "./select.module.css";

const SelectComponent = ({ name, placeholder = "", defaultValue = "", hasError = false, isDisabled = false,
  isMulti = false, isSearchable = true, isClearable = false,
  onChange,
  onInputChange = () => {},
  optionsList,
  questionId,
  error = "" 
}) => {
  const selectRef = useRef();
  const options = optionsList.map((option) => {
    return (
      { value: option.value, label: option.label }
    );
  });
  const onSelectItem = (e) => {
    const retValue = isMulti ? e : e.value;
    onChange(retValue);
  };
  return (
    <div className={hasError ? styles.validatorInput : ""}>
      <Select
        key={name}
        name={name}
        ref={selectRef}
        placeholder={placeholder}
        defaultValue={defaultValue}
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isMulti={isMulti}
        options={options}
        classNamePrefix='select'
        className={isMulti ? 'basic-multi-select' : 'basic-single'}
        onInputChange={onInputChange}
        onChange={onSelectItem} />
      {hasError &&
      <span className={styles.error}>{error}</span>
      }
    </div>
  );
};

export default SelectComponent;