import React, { useState, useEffect } from "react";
import styles from "./buy-now-pilot-popup.module.scss";
import H2 from "../h2/component";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from "../../components/shared/button";
import priceFormatter from "../../utils/helpers/price-formatter";
import { showToastMessages, getUid } from "../../utils/helper";
import { AppointmentService } from "../../service";
import { trackLMSEvents } from "../../utils/helpers/tracking-events";
import moment from "moment";
import IncrementalTextbox from "../incremental-textbox/component";
import PriceRangeSlider from "../shared/price-range-slider";
const useStyles = makeStyles(theme => {
  return {
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(2),
      fontSize: '10px',
    }
  };
});

const BuyNowPilotPopup = ({ appointmentId, customerOffer, onClose, carId, finalPrice }) => {
  // const [buyNowPrice, setBuyNowPrice] = useState("");
  const [errorClass, setErrorClass] = useState("");
  const [inputPriceError, setInputPriceError] = useState(false);
  const [inputPriceErrorMessage, setInputPriceErrorMessage] = useState("");
  const [isPriceSubmitted, setIsPriceSubmitted] = useState(true);
  const [initialButtonBit, setInitialButtonBit] = useState(true);
  const { customerOffer: { model, year, customerName } } = !!customerOffer ? customerOffer : {};
  const minBuyNowPrice = +finalPrice[0].replace(/,/g, '');
  const maxBuyNowPrice = +finalPrice[1].replace(/,/g, '');
  const classes = useStyles();
  const [stepSize, setStepSize] = useState(1000);
  let [buyNowPrice, setBuyNowPrice] = useState(minBuyNowPrice);
  useEffect(() => {
    let vals = [];
    let current = minBuyNowPrice;
    while (current <= maxBuyNowPrice) {
      vals.push(current);
      current = current + stepSize;
    }
    setStepSize(1000);
  }, []);
  const onSumbitBuyPrice = () => {
    const payload = {
      "appointmentId": appointmentId,
      "buyNowPrice": Number(buyNowPrice),
      "carId": carId
    };
    AppointmentService.postAppointmentBuyNowPrice(payload)
      .then(response => {
        const { data: { detail } } = response;
        showToastMessages(!!detail && !!detail.message ? detail.message : "Buy now price submitted", true);
        trackLMSEvents("ls_BuyNow", {
          eventLabel: appointmentId,
          lsRAID: getUid(),
          eventCategory: 'Inspected',
          eventAction: 'BuyNowSubmit',
          Timestamp: moment().format()
        });
      })
      .catch(error => {
        const { detail } = error;
        showToastMessages(!!detail && !!detail.message ? detail.message : "Buy now price is failed to submit", false);
      });
    onClose();
  };

  const onSubmitStartAuction = () => {
    const payload = {
      "appointmentId": appointmentId,
      "carId": carId
    };
    console.log(payload);
    AppointmentService.postStartAuction(payload)
      .then(response => {
        const { data: { detail } } = response;
        showToastMessages(!!detail && !!detail.message ? detail.message : "Auction started successfully", true);
      })
      .catch(error => {
        const { detail } = error;
        showToastMessages(!!detail && !!detail.message ? detail.message : "Check after sometime", false);
      });
    onClose();
  };

  const onChangeClick = (type) => {
    const newBuyNowPrice = type === "increment" ? buyNowPrice + stepSize : buyNowPrice - stepSize;
    setBuyNowPrice(newBuyNowPrice);
    setInitialButtonBit(false);
  };
    
  const handleDownPaymentChange = (value) => {
    setBuyNowPrice(value);
    setInitialButtonBit(false);
  };
  return (
    <div className={styles.buyNowPilotPopup}>
      <H2 text={"Enter Instant Sell Price"} />
      <ul className={styles.customerDesc}>
        <li>{customerName}</li>
        <li>{model}</li>
        <li>{year}</li>
      </ul>
      <div className={[styles.inputWrap, 'text-center'].join(' ')}>
        <h6>CUSTOMER EXPECTED PRICE</h6>
        <IncrementalTextbox
          value={priceFormatter(buyNowPrice)}
          onChangeClick={onChangeClick}
        />
        <div className='mt-3'>
          <PriceRangeSlider
            onStepChange={handleDownPaymentChange}
            step={stepSize}
            min={minBuyNowPrice}
            max={maxBuyNowPrice}
            selectedValue={buyNowPrice}
            text="Customer Expected Price" />
        </div>
      </div>
      <p className={styles.info}>PAI will not be allowed if Buy Now is accepted.</p>
      <div className={styles.ctaWrapper}>
        <Button ctaText="Normal Auction" classNames="transparentBtn" onClick={() => onSubmitStartAuction()}/>
        <Button ctaText="Instant Sell Auction" classNames="blueButton ml-3" onClick={() => onSumbitBuyPrice()} disabled={initialButtonBit}/>
      </div>
    </div>
  );
};

export default BuyNowPilotPopup;