import React from "react";

const UnorderedList = ({ children }) => {
  return (
    <ul>
      {children}
    </ul>
  );
};

export default UnorderedList;
