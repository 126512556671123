import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CustomerOfferPrice from "./component";
import { fetchLeadDetails, resetLeadDetails } from "../lead-detail/actions";
import { preCheckRaisePAI, fetchNegotiations, resetNegotiations } from "../../components/negotiation-list/actions";
import { fetchCustomerOfferDetails } from "./actions";
//import { fetchNegotiations, fetchCarData, setCurrentPopup, resetNegotiations, resetNegotiationsByKey } from "./actions";

const mapStateToProps = ({
  leadDetails,
  negotiations,
  customerOffer
}) => ({
  leadDetails,
  negotiations,
  customerOffer
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchLeadDetailsConnect: fetchLeadDetails,
  resetLeadDetailsConnect: resetLeadDetails,
  preCheckRaisePAIConnect: preCheckRaisePAI,
  fetchNegotiationsConnect: fetchNegotiations,
  resetNegotiationsConnect: resetNegotiations,
  fetchCustomerOfferDetailsConnect: fetchCustomerOfferDetails
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CustomerOfferPrice);
