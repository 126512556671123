import React, { useEffect, useRef, useState } from "react";
import Button from "../../../components/shared/button/component";
import DataInformationMessage from "../../../components/shared/data-information-message/component";
import { getFromLocalStorage } from "../../../utils/helper";
import { LS_KEYS } from "../../common/app-constants";
import LeadSortFilter from "../../components/lead-sort-filter";
import RaLeadCard from "../../components/ra-lead-card";
import styles from "./ra-leads.module.scss";
import {isUserRA} from '../../common/utility';
import { NUMBERS } from "../../../utils/app-constants";
const RaLeads = ({
  raLeads,
  sortFilter,
  fetchRaLeadsConnect,
  resetLeadsConnect,
  onClose,
}) => {
  const {data=[], error='', isLoading, loadMore, page} = raLeads;
  const [currentPageNo, setCurrentPageNo] = useState(page);
  const scrollRef = useRef();
  const [dataLoading, setDataLoading] = useState(false);
  const [loadCount, setLoadCount] = useState(0);
  const userEmail = getFromLocalStorage(LS_KEYS.DISPLAY_EMAIL);
  const {sort='', filter=''} = sortFilter || {};
    
  useEffect(() => {
    if (scrollRef.current && currentPageNo > NUMBERS.ONE) {
      scrollRef.current.scrollIntoView({
        behavior: "auto",
        block: "end"
      });
    }
  }, [currentPageNo, data]);

  useEffect(()=>{
    if (!isLoading) {
      setLoadCount(NUMBERS.ZERO);
    }
  },[isLoading]);

  useEffect(()=>{
    resetListing();
  },[sort, filter]);

  const paginationLoader = (page) => {
        page > NUMBERS.ONE ? setDataLoading(true) : setDataLoading(false);
  };

  const resetListing = () => {
    const nextLoadCount = loadCount + NUMBERS.ONE;
    setLoadCount(nextLoadCount);
    if (nextLoadCount === NUMBERS.ONE) {
      resetLeadsConnect();
      setCurrentPageNo(0);
      loadMoreLeads(0);
    }
  };
  const loadMoreLeads = (page) => {
    paginationLoader(page);
    if (userEmail) {
      fetchRaLeadsConnect({email: userEmail, pageNo: page, filterQuery:filter, sort: sort}).finally(()=>{
        setDataLoading(false);
      });
    } else {
      setDataLoading(false);
    }
  };

  const onLoadMore = () => {
    let i = currentPageNo;
    setCurrentPageNo(i + NUMBERS.ONE);
    loadMoreLeads(i + NUMBERS.ONE);
  };

  return (
    <div className={styles.raLeads}>
      {isUserRA() && <> 
        <LeadSortFilter />
        {data.map((lead, index)=> 
          <div key={`lead-${index}`}>
            <RaLeadCard data={lead} onClose={onClose}/>
          </div>
        )}
        <DataInformationMessage 
          isLoading={isLoading}
          error={error}
          data={data}
          dataLoading={dataLoading}
          appointmentType={'RA Leads'}
        />
        {loadMore && <div className={styles.loadMore}>
          <Button classNames="secondaryCta" ctaText={"Load More"} onClick={onLoadMore}></Button>
        </div>}
      </>}
    </div>
  );
};

export default RaLeads;
