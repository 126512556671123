import Types from "./types";

const postLoginSuccess = (data) => ({
  type: Types.POST_LOGIN_SUCCESS,
  data
});

const updateChannelSuccess = (data) => ({
  type: Types.UPDATE_CHANNEL_SUCCESS,
  data
});

const filterChannels = (data) => ({
  type: Types.FILTER_CHANNELS,
  data
});

const setActiveChannel = (data) => ({
  type: Types.SET_ACTIVE_CHANNEL,
  data
});

const setSelectedStores = (data) => ({
  type: Types.SELECTED_STORES,
  data
});

export {
  postLoginSuccess,
  updateChannelSuccess,
  setActiveChannel,
  filterChannels,
  setSelectedStores
};
