import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  reviewInspectionFilterQuery: null
};

export const updatereviewInspectionFilterQuery = (state = INITIAL_STATE, { reviewInspectionFilterQuery }) => {
  return { ...state, reviewInspectionFilterQuery };
};

export const HANDLERS = {
  [Types.UPDATE_REVIEW_INSPECTION_FILTER_QUERY]: updatereviewInspectionFilterQuery

};

export default createReducer(INITIAL_STATE, HANDLERS);
