import React, { useState, useEffect } from "react";
import styles from "./car-image-detail.module.css";
import { NUMBERS } from "../../utils/app-constants";
import { hasTimeElapsed, isBikeSource } from "../../utils/helper";
import getCarName from "../../utils/helpers/get-car-name";
import OwnershipDetail from "../ownership-detail/component";

const CarImageDetail = ({ leadData, onClick = () => { }, negotiations }) => {
  const { mainImage, sortOrder, auctionEndTime, auction_count, ownerNumber, sellOnlineFlag } = leadData;
  const { data } = negotiations;
  const description = getCarName(leadData) || "";
  const croppedImage = mainImage ? mainImage.replace("528x0", "194x172") : "";
  const [hasElapsed, setHasElapsed] = useState(false);
  const isBike = isBikeSource();
  useEffect(() => {
    if (sortOrder === NUMBERS.ONE && auction_count < NUMBERS.TWO) {
      let interval = setInterval(() => {
        const auctionEndDate = new Date(auctionEndTime * 1000);
        const hasReachedLimit = hasTimeElapsed(auctionEndDate, NUMBERS.FIVE);
        if (hasReachedLimit) {
          setHasElapsed(hasReachedLimit);
          clearInterval(interval);
          //updatePriorityCountByKeyConnect(APPOINTMENT_TYPES_STRING.NEGOTIATION);
        };
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className={`${styles.carImage} ${hasElapsed ? styles.lapse : ''}`}>
      {!!sellOnlineFlag && isBike && <div className={styles.sellonlineStrips}>Sell Online</div>}
      {isBike ? <div><img src={croppedImage} alt={description} /> </div> : 
      <div onClick={onClick}>
        <img src={croppedImage} alt={description} />
        {ownerNumber && <OwnershipDetail ownerNumber={ownerNumber} />}
        {<p className={styles.carText}>view car details</p>}
      </div>
      }
            
    </div>
  );
};

export default CarImageDetail;
