import React from "react";
import styles from "./change-log-image.module.scss";
import ChangeLogCar from "../images/changeLogCar.svg";
import PaiChangeLog from "../images/paiChangeLog.svg";
import AppointmentCreatedLog from "../images/appointmentCreatedChangeLog.svg";
import RetailAssociateEmailLog from "../images/retailAssociateEmailLog.svg";
import SecondaryPhoneLog from "../images/secondaryPhoneLog.svg";

const ChangeLogImage = ({ imageType }) => {
  return (
    <div className={styles.callLogimage}>
      {(imageType === "listPrice" || imageType === "reviseListPrice") && <img src={ChangeLogCar} alt="Change Log" />}
      {(imageType === "paiRaised" || imageType === "ocbRaised") && <img src={PaiChangeLog} alt="Change Log" />}
      {(imageType === "appointmentCreated") && <img src={AppointmentCreatedLog} alt="AppointmentCreatedLog" />}
      {(imageType === "appointmentRescheduled") && <img src={AppointmentCreatedLog} alt="Change Log" />}
      {(imageType === "secondaryPhone") && <img src={SecondaryPhoneLog} alt="Change Log" />}
      {(imageType === "retailAssociateEmail") && <img src={RetailAssociateEmailLog} alt="RetailAssociateEmailLog" />}
    </div>
  );
};

export default ChangeLogImage;
