import React, { useState, useEffect } from "react";
import styles from "./customer-appointment-popup.module.scss";
import H2 from "../../components/h2";
import upArrow from "../../components/images/upArrow.svg";
import homeIcon from "../../components/images/homeIcon.svg";
import TimeSlot from "../../components/images/clockBlue.svg";
import Calander from "../../components/images/calendar.svg";
import Textarea from "../shared/textarea/component";
import Button from "../shared/button/component";
import { AppointmentService } from "../../service";
import { showToastMessages } from "../../utils/helper";
import SelectComponent from "../shared/select/component";
const CustomerAppointmentPopup = ({ onClose, rowDetails, updateDataList, isSaleOnline = false}) => {
  const { model, variant, year, zoneName, customerAddress, appointment, onlineApptDate = {}} = rowDetails;
  const {date='', time=''} = onlineApptDate;
  const [inputText, setInputText] = useState(customerAddress);
  const [dateSlots, setDateSlots] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [dateTimeSlots, setDateTimeSlots] = useState();
  const [cityId, setCityId] = useState();
  const [storeId, setStoreId] = useState();
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(!!time ? {label: time, value: time} : null);
  const [selectedDateSlot, setSelectedDateSlot] = useState(!!date ? {label: date, value: date} : null);
    
  useEffect(() => {
    isSaleOnline && AppointmentService.getSellOnlineTimeSlot(appointment).then((resp)=>{
      const {data: {detail :{date_slot ={}, city_id='', location_id=''} = {}} = {}} = resp; 
      setDateTimeSlots(date_slot);
      setCityId(city_id);
      setStoreId(location_id);
      const dates = Object.values(date_slot).map((item)=> {
        return {label: item.date, value: item.date };
      });
      setDateSlots(dates);
      if (!!date) {
        let selDate = Object.values(date_slot).find((item)=> item.date === date);
        const times = !!selDate && Object.values(selDate.time).map((item) => { 
          return (
            Object.values(item).map((row)=> {
              return {
                label: row.time_range, value: row.time
              };
            })
          );
        });
        var mergedTimes = !!times && [].concat.apply([], times);
        !!mergedTimes && setTimeSlots(mergedTimes);
      }
    }).catch((err)=>{
      console.log("Err", err);
      showToastMessages(err.message, false);
    });
  }, []);

  const onDateSelect = (date) => {
    setSelectedDateSlot({label: date, value: date});
    const selectedDateSlots = Object.values(dateTimeSlots).find((item) => item.date === date);
    const times = !!selectedDateSlots && Object.values(selectedDateSlots.time).map((item) => { 
      return (
        Object.values(item).map((row)=> {
          return {
            label: row.time_range, value: row.time
          };
        })
      );
    });
    var mergedTimes = [].concat.apply([], times);
    setTimeSlots(mergedTimes);
    setSelectedTimeSlot(null);
  };

  const onSlotSelect = (time) => {
    setSelectedTimeSlot({label: time, value: time});
  };

  const onChangeText = (e) => {
    const value = e.target.value;
    setInputText(value);
  };

  const onRescheduleAppointment = () => {
    const payload = {
      appointment_id: appointment,
      city_id: cityId,
      store_id: storeId,
      zone_id: '',
      appointment_date: !!selectedDateSlot ? selectedDateSlot.value : '',
      appointment_time: !!selectedTimeSlot ? selectedTimeSlot.value : '',
      formattedAddress: inputText
    };
    setIsSubmitClicked(true);
    AppointmentService.postRescheduleAppointment(payload)
      .then(response => {
        console.log(response);
        const { data: { detail} } = response;
        showToastMessages(detail);
        setTimeout(()=> {
          updateDataList(appointment);
          onClose();
        }, 2000);
      }).catch(error => {
        const { detail } = error;
        showToastMessages(detail, false);
      }).finally(()=>{
        setIsSubmitClicked(false);
      });
        
  };

  const onUpdateAddress = () => {
    const params = {
      'appointment': appointment,
      'field': 'customerAddress',
      'value': inputText
    };
    setIsSubmitClicked(true);
    AppointmentService.updateAppointment(params)
      .then((resp) => {
        const { data: { detail: { message } } } = resp;
        showToastMessages(message);
        setTimeout(() => {
          updateDataList(appointment);
        }, 2000);
      })
      .catch(err => {
        const { detail } = err;
        showToastMessages(detail, false);
      }).finally(()=>{
        setIsSubmitClicked(false);
        onClose();
      });
  };

  return (
    <div className={styles.customerAppointmentPopup}>
      <H2 text={"CUSTOMER APPOINTMENT"} />
      <ul className={styles.customerDesc}>
        <li>{model}</li>
        <li>{variant}</li>
        <li>{year}</li>
      </ul>
      <div className={styles.customerLocationDetails}>
        {!!zoneName && <>
          <img src={upArrow} alt="location" />
          <p>{zoneName}</p>
        </>}
        <img src={homeIcon} alt="homeIcon" />
        <Textarea
          onChange={onChangeText}
          value={inputText}
          text="Permanent Address"
        />
      </div>
      {isSaleOnline && <div className={styles.timeDataWrapper}>
        <div className={styles.fixwrapper}>
          <img src={Calander} alt="Calander" />
          <SelectComponent
            key={`cj_date_${selectedDateSlot}`}
            name={`cj_date_${selectedDateSlot}`}
            placeholder={"Select Date"}
            optionsList={dateSlots}
            questionId={appointment}
            onChange={onDateSelect}
            defaultValue={selectedDateSlot ? selectedDateSlot : ""}
          />
        </div>
        <div className={styles.fixwrapper}>
          <img src={TimeSlot} alt="TimeSlot" />
          {isSaleOnline && <SelectComponent
            key={`cj_date_${selectedTimeSlot}`}
            name={`cj_date_${selectedTimeSlot}`}
            placeholder={"Select Time"}
            optionsList={timeSlots}
            questionId={appointment}
            onChange={onSlotSelect}
            defaultValue={selectedTimeSlot ? selectedTimeSlot : ""}
            className={'hello'}
          />}
        </div>
      </div>}
      {isSaleOnline && <p className={styles.errorMsg}>you are rescheduling this appointment</p>}
      <div className={styles.ctaWrapper}>
        <Button ctaText={"save"} classNames="blueButton" onClick={isSaleOnline ? onRescheduleAppointment : onUpdateAddress} disabled={isSubmitClicked} />
        <Button ctaText={"cancel"} classNames="cancelCta" onClick={onClose} />
      </div>
    </div>
  );
};

export default CustomerAppointmentPopup;
