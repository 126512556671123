import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import NegotiationLostLead from "./component";
import withLayout from "../../components/layout/component";
import { resetAppointmentByKey } from "../appointment/actions";
import { resetLostLeadSearchByKey } from "../../components/search-lost-leads/actions";

const mapStateToProps = ({ lostLeadsearch, }) => ({ lostLeadsearch, });

const mapDispatchToProps = (dispatch) => bindActionCreators({
  resetAppointmentByKeyConnect: resetAppointmentByKey,
  resetLostLeadSearchByKeyConnect: resetLostLeadSearchByKey
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withLayout(NegotiationLostLead, false));
