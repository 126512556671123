/* eslint-disable indent */
import React, { useEffect } from "react";
import NegotiationList from "../../components/negotiation-list";
import { PAGE_HEADING, RESET_REDUCER_STATE_KEYS, APPOINTMENT_TYPES_STRING, NUMBERS, LOCAL_STORAGE_KEYS } from "../../utils/app-constants";
import H2 from "../../components/h2";
import styles from "./negotiation-lost-lead.module.css";
import StickyComponent from "../../components/shared/sticky-component";
import { saveToLocalStorage } from "../../utils/helper";
import Back from "../../components/back";

const NegotiationLostLead = ({
  resetAppointmentByKeyConnect,
  lostLeadsearch,
  resetLostLeadSearchByKeyConnect,
  history,
  location
}) => {
  const { lostleads } = lostLeadsearch;

  saveToLocalStorage(LOCAL_STORAGE_KEYS.lostLead, true);
  useEffect(() => {
    resetLostLeadSearchByKeyConnect(RESET_REDUCER_STATE_KEYS.LOST_LEADS, true);
    resetAppointmentByKeyConnect(RESET_REDUCER_STATE_KEYS.APPOINTMENT_STATE, APPOINTMENT_TYPES_STRING.NEGOTIATION);
    saveToLocalStorage(LOCAL_STORAGE_KEYS.lostLead, true);
    return () => {
      // Reset on unmount
      resetLostLeadSearchByKeyConnect(RESET_REDUCER_STATE_KEYS.LOST_LEADS, false);
      saveToLocalStorage(LOCAL_STORAGE_KEYS.lostLead, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={styles.headingSearchWrapper}>
        <div className={styles.lostHeading}>
          <Back history={history} />
          <H2 text={PAGE_HEADING.LOST_LEADS_NEGO} />
          <p className={styles.pageDirection}>{PAGE_HEADING.IN_NEGO}</p>
        </div>
      </div>
      <div className={styles.negotiationList}>
        <NegotiationList location={location}/>
      </div>
    </>
  );
};

export default NegotiationLostLead;
