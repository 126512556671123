import React, { useState } from "react";
import Popup from "../popup";
import CheckInPopup from "../check-in-popup";
import { POPUP_HEADING, ACTIONS_BUTTON, ROLE_KEYS } from "../../utils/app-constants";
import Button from "../shared/button/component";
import MarketingSurvey from "../marketing-survey";

const CheckIn = ({
  rowDetails,
  history,
  role,
  selectedStore,
  setSelectedStoreError,
  resetAndFetchData
}) => {
  const { customerEmail, appointment } = rowDetails;
  const [checkInOpen, setCheckInOpen] = useState(false);
  const [clickedAppointmentId, setClickedAppointmentId] = useState();
  const [appointmentFormOpen, setAppointmentFormOpen] = useState(false);

  const onAppointmentFormClose = () => {
    setAppointmentFormOpen(false);
  };
  const onAppointmentFormOpen = () => {
    setAppointmentFormOpen(true);
  };
  const onCheckInOpen = (appointmentId) => {
    if (isValidStoreId()) {
      setClickedAppointmentId(appointmentId);
      setCheckInOpen(true);
    } else {
      setSelectedStoreError(true);
    }
  };

  const onCheckInClose = (flag) => {
    if(flag){
      onAppointmentFormOpen();
    }
    setCheckInOpen(false);
    setClickedAppointmentId();

  };

  const isValidStoreId = () => {
    if (role.toLowerCase() === ROLE_KEYS.RM) {
      if (selectedStore)
        return true;
      else
        return false;
    } else
      return true;
  };

  return (
    <>
      <div>
        <Button key={appointment} ctaText={ACTIONS_BUTTON.SCHEDULED_CHECK_IN} onClick={() => onCheckInOpen(appointment)} classNames="smallCta" disabled={clickedAppointmentId === appointment} />
      </div>
      {clickedAppointmentId === appointment &&
      <Popup isOpen={checkInOpen} close={() => onCheckInClose(false)} heading={customerEmail ? POPUP_HEADING.CONFIRM_CHECK_IN : POPUP_HEADING.CHECK_IN} >
        <CheckInPopup history={history} appointmentData={rowDetails} onCancelClick={onCheckInClose} role={role} selectedStore={selectedStore} resetAndFetchData={resetAndFetchData} />
      </Popup>

      }
      <Popup isOpen={appointmentFormOpen} close={onAppointmentFormClose}>
        <MarketingSurvey onClose={onAppointmentFormClose} resetAndFetchData={resetAndFetchData} history={history} role={role} appointment={appointment}/>
      </Popup>
    </>
  );
};

export default CheckIn;
