import React from "react";
import { REJECTED, NOT_AVAILABLE } from "../../utils/app-constants";
import styles from "./last-payment-status.module.css";
import View from "../images/view.svg";
import { Link } from "react-router-dom";
import { redirectToUrl } from "../../utils/helper";

const LastPaymentStatus = ({ rowDetails }) => {
  const { lastPaymentStatus, adminUrl } = rowDetails;
  return (
    <div className={styles.lastPayment}>
      <span className={lastPaymentStatus === REJECTED ? styles.lpRejected: ""} >{lastPaymentStatus?lastPaymentStatus:NOT_AVAILABLE}</span>
      {
        lastPaymentStatus &&
        <Link to="#" onClick={()=>{ redirectToUrl(adminUrl);} } >
          <img src={View} alt=""/>
        </Link>
      }
    </div>
  );
};

export default LastPaymentStatus;
