/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./waittime-dashboard-popup.module.scss";
import H2 from "../h2/component";
import InputText from "../../components/shared/input-text";
import Button from "../shared/button/component";
import { getWaitTimeDashBoardLocalStaorageKey } from "../../utils/helper";
import { getValidateDashBoardMoc } from "../../utils/mocApi";

const WaittimeDashboardPopup = ({
  fetchVarifyOtpConnect,
  fetchSendOtpConnect,
  storeId,
  close

}) => {
  const [value, setValue] = useState("");
  const [sendOtpMsg, setSendOtpMsg] = useState("");
  const [heading, setHeading] = useState("Enter OTP to continue");
  const uid = getWaitTimeDashBoardLocalStaorageKey(storeId);

  useEffect(() => {
    // fetchVarifyOtpConnect(store_Id, uid).then(resp => {
    //     if (!!resp) {

    //     }

    // }).catch((error) => {
    //     console.log(" error in validate dashboard api - " + error);
    // });;
    // fetchSendOtpConnect(store_Id, uid).then(resp => {
    //     if (!!resp) {

    //     }

    // }).catch((error) => {
    //     console.log(" error in validate dashboard api - " + error);
    // });;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onChangeHandler = (e) => {
    const { target: { value } } = e;
    setValue(value);
  };
  const onReSendOtpClick = () => {
    // alert("1");
    const send_otp = {
      "storeId": storeId,
      "uid": uid
    };
    fetchSendOtpConnect(send_otp).then(response => {
      const { success, storeName } = response;
      if (success) {
        setSendOtpMsg("OTP Send");
      }
    }).catch(error => {
      console.log(" error in Send OTP api - " + error);
    });
  };
  const onVerifyOtpClick = () => {
    // close("abc");
    // alert("2");
    const verify_otp = {
      "storeId": storeId,
      "otpKey": value,
      "uid": uid
    };
    fetchVarifyOtpConnect(verify_otp).then(response => {
      // response = getValidateDashBoardMoc();
      // let abc = 1;
      // const { data: { detail: { success, storeName, storeConfig: { screenSplitTime, ad_split_time, ad_time_out, displayAdv, advUrl } } } } = response;
      const { success, storeName, storeConfig: { screenSplitTime, ad_split_time, ad_time_out, displayAdv, advUrl } } = response;
      if (success) {
        close(storeName, screenSplitTime, ad_split_time, ad_time_out, displayAdv, advUrl);
      }
    }).catch(error => {
      setHeading("Invalid OTP. Resend and try again.");
      console.log(" error in verify OTP api - " + error);
    });
  };

  return (
    <div className={styles.waittimeDashboardPopup}>
      <H2 text={heading} />
      <div className={styles.otpWrapper}>
        <p>Enter OTP</p>
        <InputText
          onChange={onChangeHandler}
          value={value}
          text={""}
          maxLength={10}
        />
        <p><Link to="#" onClick={onReSendOtpClick}>Click to send OTP</Link></p>
        <Button ctaText={"Verify OTP"} classNames="blueButton" onClick={onVerifyOtpClick} />
      </div>
      {sendOtpMsg !== "" && < p >OTP Sent</p>}
    </div >
  );
};

export default WaittimeDashboardPopup;
