import React from "react";
import StoreList from "../store-list";

const StoresMenu = ({
  onSelectionChange,
  showLeadsMenu
}
) => {
  return (
    <StoreList
      onSelectionChange={onSelectionChange}
      showLeadsMenu={showLeadsMenu}
    />
  );
};

export default StoresMenu;
