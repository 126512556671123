import { createTypes } from "reduxsauce";

export default createTypes(
  `
    FETCH_RA_INCENTIVE
    FETCH_RA_INCENTIVE_SUCCESS
    FETCH_RA_INCENTIVE_FAILURE
    RESET_RA_INCENTIVE
`,
  {
    prefix: "incentive/"
  }
);
