import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FollowUpHistory from "./component";
import { fetchFollowUpHistory,resetFollowUpHistory } from "./actions";

const mapStateToProps = ({
  followUpHistory
}) => ({
  followUpHistory
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchFollowUpHistoryConnect: fetchFollowUpHistory,
  resetFollowUpHistoryConnect: resetFollowUpHistory
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpHistory);
