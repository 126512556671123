import React from "react";
import styles from "./model-variant-year.module.scss";

const ModelVariantYear = ({ rowDetails }) => {

  const { model, variant, year } = rowDetails;

  return (
    <span className={styles.variant}>{model}/{year}/{variant} </span>
  );
};

export default ModelVariantYear;
