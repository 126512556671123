import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  message: "",
  isCheckedIn: 0,
  error: null,
  isLoading: true,
  isPreCheck: false
};

export const fetchCheckIn = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true, isPreCheck: true };
};

export const fetchCheckInStatusSuccess = (state = INITIAL_STATE, { data }) => {
  const { message, isCheckedIn } = data;
  return { ...state, message, isCheckedIn: parseInt(isCheckedIn), error: null, isLoading: false, isPreCheck: false };
};

export const fetchCheckInStatusFailure = (state = INITIAL_STATE, { error }) => {
  const { detail: { message } } = error;
  return { ...state, error, isLoading: false, message, isPreCheck: false };
};

export const resetCheckInState = (state = INITIAL_STATE, { data }) => {
  const { key, value } = data;
  return { ...state, [key]: value };
};

export const HANDLERS = {
  [Types.FETCH_CHECK_IN]: fetchCheckIn,
  [Types.FETCH_CHECK_IN_SUCCESS]: fetchCheckInStatusSuccess,
  [Types.FETCH_CHECK_IN_FAILURE]: fetchCheckInStatusFailure,
  [Types.RESET_CHECK_IN_KEY]: resetCheckInState,
};

export default createReducer(INITIAL_STATE, HANDLERS);
