/* eslint-disable indent */
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import LeadCheckInSearch from "../../components/lead-check-in-search";
import { NUMBERS, RESET_REDUCER_STATE_KEYS, LOCAL_STORAGE_KEYS, ROLE_KEYS } from "../../utils/app-constants";
import styles from "./lead-check-in.module.scss";
import Table from "../../components/shared/table/component";
import TableRow from "../../components/shared/table-row/component";
import TableHeader from "../../components/shared/table-header/component";
import TableBody from "../../components/shared/table-body/component";
import TableColumn from "../../components/shared/table-column/component";
import {
    getTableColumnTypeByKey,
    ColumnTypeMapping,
    getColumnComponent,
    getFromLocalStorage,
} from "../../utils/helper";
import InfiniteScroll from 'react-infinite-scroller';
import CheckIn from "../../components/check-in";
import LeadCheckInDeny from "../../components/lead-check-in-deny";
import SelectComponent from "../../components/shared/select/component";
import Back from "../../components/back";
import BookAppointment from "../../components/book-appointment/";
import Button from "../../components/shared/button";
import BookAppointmentPopup from "../../components/book-appointment-popup";
import Popup from "../../components/popup";
import GeneratedAppointmentPopup from "../../components/generated-appointment-popup";
import { AppointmentService } from "../../service";

const LeadCheckIn = ({
    leadCheckIn,
    fetchCheckInLeadsConnect,
    resetCheckInLeadsConnect,
    resetCheckInLeadsByKeyConnect,
    history
}) => {

    const stores = getFromLocalStorage(LOCAL_STORAGE_KEYS.CITY_STORES_LIST);
    const storesMap = stores && JSON.parse(stores);
    let storeList = storesMap && storesMap.length > NUMBERS.ZERO && JSON.parse(stores).map(item => ({ value: item.idLmsStore, label: item.name }));
    const { isLoading, columns, data, error } = leadCheckIn;
    const [visibleColumns, setVisibleColumns] = useState([]);
    const [hasMoreItems, setHasMoreItems] = useState(false);
    const [pageStart] = useState(0);
    const [query, setQuery] = useState();
    const scrollRef = useRef();
    const [storesList, setStoresList] = useState([]);
    const [selectedStore, setSelectedStore] = useState("");
    const [selectedStoreError, setSelectedStoreError] = useState(false);
    const [showAppointment, setShowAppointment] = useState(false);
    const [generatedAppointment, setGeneratedAppointment] = useState("");
    const localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
    const {role='', originalRole: origRole} = JSON.parse(localData) || {};
    const [showNewFeature, setShowNewFeature] = useState(true);
    const [leadPhoneData, setLeadPhoneData] = useState([]);
    const [storeDefaultObj] = useState((!!storeList && storeList.length === 1) ? { label: storeList[0].label, value: storeList[0].value } : "");
    const isCheckinAccess = [ROLE_KEYS.PLL_RA,  ROLE_KEYS.PLL_RM, ROLE_KEYS.PLL_RH, ROLE_KEYS.PLL_RA_CENTER, ROLE_KEYS.PLL_ZM_CENTER, ROLE_KEYS.CORP_VIEWER, ROLE_KEYS.PLL_CH].includes(origRole);
    const [bookAppointmentOpen, setBookAppointmentOpen] = useState(false);
    const [appointmentPopup, setAppointmentPopup] = useState(false);
    const onBookAppointmentOpen = () => {
        if (isValidStoreId()) {
            setBookAppointmentOpen(true);
            setSelectedStoreError(false);
        } else {
            setBookAppointmentOpen(false);
            setSelectedStoreError(true);
            // showToastMessages("Please Select Store", false);
        }
    };
    const onBookAppointmentClose = () => {
        setBookAppointmentOpen(false);
    };

    const onAppointmentPopupOpen = () => {
        setAppointmentPopup(true);

    };
    const onAppointmentPopupClose = () => {
        setAppointmentPopup(false);
    };

    const getLeadDataWithPhone = (storeId) => {
        AppointmentService.getLeadWithPhone(storeId)
            .then(resp => {
                const { data: { detail } } = resp;
                if (!!detail && detail.length > 0) {
                    setLeadPhoneData(detail);
                }
                else {
                    setShowNewFeature(false);
                }
            })
            .catch(err => {

                // let resp = getPhoneLeadCheckIn();
                // const { data: { detail } } = resp;
                setShowNewFeature(false);
                // setLeadPhoneData(detail);

            });
    };
    useEffect(() => {
        if (!isCheckinAccess) {
            const stores = getFromLocalStorage(LOCAL_STORAGE_KEYS.CITY_STORES_LIST);
            if (stores) {
                const storeList = JSON.parse(stores).map(item => ({ value: item.idLmsStore, label: item.name }));
                setStoresList(storeList);
                if (!!storeList && storeList.length === 1) {
                    getLeadDataWithPhone(storeList[0].value);
                }
            }
        }
        return () => {
            resetCheckInLeadsConnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (query) {
            resetAndFetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    useEffect(() => {
        if (columns.length > 0) {
            let shownColumns = [];
            shownColumns = columns.filter(item => item.isVisible !== NUMBERS.ZERO.toString()).map(item => { return { key: item.key, value: item.value }; });
            setVisibleColumns(shownColumns);
        }
    }, [columns]);

    const loadData = (page) => {
        if (query) {
            setShowAppointment(false);
            setHasMoreItems(false);
            fetchCheckInLeadsConnect(page, query).then(resp => {
                if (resp.data) {
                    setShowAppointment(true);
                    const { data: { detail: { result } } } = resp;
                    if (result.length === NUMBERS.TWENTY) {
                        setHasMoreItems(true);
                    }
                }
            });
        }
    };

    const isValidStoreId = () => {
        if (!isCheckinAccess) {
            if (selectedStore)
                return true;
            else
                return false;
        } else if (role.toLowerCase() === ROLE_KEYS.HOSTESS) {
            return true;
        }
        else {
            return true;
        }
    };

    const getColumn = (key, value, rowDetails) => {
        const tableType = getTableColumnTypeByKey(key);
        switch (tableType) {
            case ColumnTypeMapping.STRING:
                return <span className={styles.modleName}>{value}</span>;
            case ColumnTypeMapping.LINK:
                return <Link className={styles.dataId} to='#'>{value}</Link>;
            case ColumnTypeMapping.COMPONENT:
                return getColumnComponent(key, rowDetails, scrollRef, setHasMoreItems);
            default: break;
        }
    };

    const renderActions = (item) => {
        const { stateId, appointment } = item;
        switch (parseInt(stateId)) {
            case NUMBERS.ONE:
                return <CheckIn key={appointment} history={history} rowDetails={item} role={role} selectedStore={selectedStore} setSelectedStoreError={setSelectedStoreError} resetAndFetchData={resetAndFetchData} />;
            case NUMBERS.TWO:
                return <>Already Checked-In</>;
            default:
                return <LeadCheckInDeny key={appointment} history={history} rowDetails={item} role={role} selectedStore={selectedStore} setSelectedStoreError={setSelectedStoreError} resetAndFetchData={resetAndFetchData} />;
        }
    };

    const resetAndFetchData = () => {
        scrollRef.current.pageLoaded = 0;
        setHasMoreItems(true);
        resetCheckInLeadsByKeyConnect(RESET_REDUCER_STATE_KEYS.IS_LOADING, true);
        resetCheckInLeadsByKeyConnect(RESET_REDUCER_STATE_KEYS.DATA, []);
    };

    const onStoreSelect = (value) => {
        setSelectedStore(value);
        setSelectedStoreError(false);
        getLeadDataWithPhone(value);
        setShowNewFeature(true);
        setQuery("");
        resetAndFetchData();
        setShowAppointment(false);
    };
    const OnClickSearcBasedOnAppointment = () => {
        setShowNewFeature(false);
    };
    const renderDataWithPhoneNoButNotBooked = () => {
        return (
          <table cellPadding="0" cellSpacing="0" width="100%">
            <thead>
              <tr>
                <th><p className={styles.tableHeading}>Phone no</p></th>
                <th><p className={styles.tableHeading}>Visit Time</p></th>
                <th><p className={styles.tableHeading}>Actions</p></th>
              </tr>
            </thead>
            <tbody>
              {
                        leadPhoneData.map((item) => {
                            return (
                              <tr>
                                <td>{item.phoneNumber}</td>
                                <td>{item.createdAt}</td>
                                <td>
                                  {/* <Button ctaText="Book an appointment" onClick={onBookAppointmentOpen} classNames="blueButton" /> */}
                                  <BookAppointment
                                            setSelectedStoreError={setSelectedStoreError}
                                            selectedStore={selectedStore}
                                            rowDetails={item}
                                            history={history}
                                            onClose={onBookAppointmentClose}
                                            setAppointment={setGeneratedAppointment}
                                            onAppointmentPopupOpen={onAppointmentPopupOpen}
                                            resetCheckInLeadsConnect={resetCheckInLeadsConnect}
                                        />
                                </td>
                              </tr>
                            );

                        })
                    }
            </tbody>
          </table>
        );
    };

    const renderData = () => (
      <Table>
        {visibleColumns.length > 0 && data.length > 0 &&
        <thead>
          <TableRow>
            {
                            visibleColumns.map((item, index) => <TableHeader key={index} headerKey={item.key} headerValue={item.value} />)
                        }
            <th><p className={styles.tableHeading}>Actions</p></th>
          </TableRow>
        </thead>
            }
        <TableBody>
          {
                    !isLoading && !error && data.length > 0 &&
                    data.map((item, index) => (
                      <TableRow key={`row-${index}`} >
                        <>
                          {
                                    visibleColumns.map((visCol, visColIndex) => {
                                        return (
                                          <TableColumn key={`col-${visColIndex}`} item={item} >
                                            {getColumn(visCol.key, item[visCol.key], item)}
                                          </TableColumn>
                                        );
                                    })
                                }
                          <TableColumn>
                            {renderActions(item)}
                          </TableColumn>
                          <td><BookAppointment
                                    setSelectedStoreError={setSelectedStoreError}
                                    selectedStore={selectedStore}
                                    rowDetails={item}
                                    history={history}
                                    onClose={onBookAppointmentClose}
                                    setAppointment={setGeneratedAppointment}
                                    onAppointmentPopupOpen={onAppointmentPopupOpen}
                                    resetCheckInLeadsConnect={resetCheckInLeadsConnect}
                                />

                          </td>

                        </>
                      </TableRow>
                    )
                    )
                }
        </TableBody>
      </Table>
    );

    return (
      <div>
        <div className={styles.leadCheckinWrapper}>
          <div className={styles.leadCheckInFixedWrapper}>
            <div className={styles.leadSelectWrapper}>
              <div className={styles.backButton}>
                <Back history={history} />
              </div>
              {
                            !isCheckinAccess &&
                            <div className={styles.selectLead}>
                              <SelectComponent
                                    placeholder="Select Store"
                                    name="storeList"
                                    onChange={onStoreSelect}
                                    optionsList={storesList}
                                    hasError={selectedStoreError}
                                    error={"Please select a store"}
                                    isSearchable={true}
                                    defaultValue={storeDefaultObj}
                                />
                            </div>
                        }

            </div>

            {
                        (!!leadPhoneData && leadPhoneData.length > 0) &&
                        <>
                          <div
                                className={styles.leadCheckInTable}>
                            {renderDataWithPhoneNoButNotBooked()}
                          </div>
                          <div className={styles.searchCta}>
                            <Button ctaText="Search" onClick={OnClickSearcBasedOnAppointment} classNames="blueButton" />
                          </div>
                        </>
                    }

            {!showNewFeature && <LeadCheckInSearch query={query} setQuery={setQuery} />}
          </div>
          {
                    showAppointment && data.length === 0 && <div className={styles.appointmentButton}>
                      <Button ctaText="Book an appointment" onClick={onBookAppointmentOpen} classNames="blueButton" />
                    </div>
                }
          {
            <div
                        className={styles.leadCheckInTable}>
              <div className={styles.searchTable}>
                <InfiniteScroll
                                ref={scrollRef}
                                className={styles.appointmentList}
                                pageStart={pageStart}
                                loadMore={loadData}
                                hasMore={hasMoreItems}
                            >
                  {renderData()}
                </InfiniteScroll>
              </div>
              {/* {isLoading !== null &&
                            <DataInformationMessage
                                isLoading={isLoading}
                                error={error}
                                data={[1]}
                            />
                        } */}
            </div>
                }
          <Popup isOpen={bookAppointmentOpen} close={onBookAppointmentClose} closeOnOutside={false}>
            <BookAppointmentPopup
                        selectedStore={selectedStore}
                        onClose={onBookAppointmentClose}
                        history={history}
                        setAppointment={setGeneratedAppointment}
                        onAppointmentPopupOpen={onAppointmentPopupOpen}
                    />

          </Popup>
          <Popup isOpen={appointmentPopup} close={onAppointmentPopupClose}>
            <GeneratedAppointmentPopup
                        appointment={generatedAppointment}
                        onAppointmentPopupClose={onAppointmentPopupClose}
                        history={history}
                    />
          </Popup>
        </div>

      </div>
    );
};

export default LeadCheckIn;
