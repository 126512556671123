import React, {Fragment} from "react";
import styles from '../saleonline-questions.module.scss';
import QuestionListTab from "../question-list-tab";

const NO_ISSUES = "no_issues";

const MultiSelectQuestion = ({
  data,
  userResponse,
  UpdateAnsweredQuestionsConnect,
  setisSubmittingButton,
}) => {
  const multiAnswerLastQuestion = (option) => {
        
    const userAnswer = userResponse;
    const optionKey = data?.key;
    const questionAnser = {
      key: data?.key,
      value: option?.key,
    };
        
    if (userAnswer[optionKey] && Array.isArray(userAnswer[optionKey]) && userAnswer[optionKey].length > 0) {
      const newResponse = userAnswer[optionKey].filter(item => item.value === option.key);
      if (newResponse.length) {
        const unSelectResponse = userAnswer[optionKey].filter(item => item.value !== option.key);
        userAnswer[optionKey] = unSelectResponse;
      } else {
        userAnswer[optionKey] = [...userAnswer[optionKey], questionAnser];
      }
      if (userAnswer[optionKey].length > 1) {
        userAnswer[optionKey] = userAnswer[optionKey].filter(item => item.value !== NO_ISSUES);
      }
    } else {
      userAnswer[optionKey] = [questionAnser];
    }
    if (userAnswer[optionKey] && userAnswer[optionKey].length > 0) {
      setisSubmittingButton(true);
    } else {
      setisSubmittingButton(false);
    }
    UpdateAnsweredQuestionsConnect({key: optionKey, value: userAnswer[optionKey]});
  };

  const checkIfMultiSelect = () => {
    if (!userResponse) return false;
    const item = userResponse[data?.key];
    return (Array.isArray(item) && item.length > 0);
  };

  const getActiveMultiSelectStyle = (option) => {
    if (checkIfMultiSelect()) {
      const idx = userResponse[data?.key].findIndex(item => item.value === option.key);
      return idx >= 0;
    }
    return false;
  };

  const isAnyMultiselectActive = () => {
    if (checkIfMultiSelect()) {
      const idx = userResponse[data?.key].findIndex(item => item.value !== NO_ISSUES);
      return idx >= 0;
    }
    return false;
  };

  return (
    <Fragment>
      <ul className={styles.listTab}>
        {data?.options.map((option, index) => {
          return (
            <Fragment key={index}>
              {(option?.key !== NO_ISSUES || (option?.key === NO_ISSUES && !isAnyMultiselectActive())) &&
              <li onClick={() => multiAnswerLastQuestion(option)}>
                <QuestionListTab
                  checkbox={true}
                  text={option.label}
                  subtext={option.description || ""}
                  option={option}
                  active={getActiveMultiSelectStyle(option)}
                />
              </li>}
            </Fragment>);
        })}
      </ul>
    </Fragment>
  );
};

export default MultiSelectQuestion;
