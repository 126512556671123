import React from "react";
import styles from "./dealer-accept-price.module.scss";
import checked from "../../common/images/check-completed.svg";
import {santiziePrice} from "../../common/utility";
const DealerAcceptPrice = ({minPrice=0, maxPrice=0}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.congratulations}>
        <img src={checked} alt={'check'}/>
        {`Congratulations`}
      </div>
      <strong className={styles.price}>₹ {`${santiziePrice(minPrice)} - ${santiziePrice(maxPrice, true)}`}</strong>
      <div className={styles.thankyou}>
        Thank you for accepting the price quote. <br /> Our RA will connect with you for confirming inspection timings. Final value will be generated post inspection.
      </div>
    </div>
  );
};

export default DealerAcceptPrice;
