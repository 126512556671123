import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CarPriceOffer from "./component";

const mapStateToProps = ({
  lead: {
    leadDetail,
    offeredPrice
  }
}) => ({
  leadDetail,
  offeredPrice
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CarPriceOffer);
