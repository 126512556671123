import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  data: null,
  error: null
};

export const fetchDepreciationReports = (state = INITIAL_STATE) => {
  return {...state};
};

export const fetchDepreciationReportsSuccess = (state = INITIAL_STATE, data ) => {
  return { ...state, data};
};

export const fetchDepreciationReportsFailure = (state = INITIAL_STATE, { error } ) => {
  return { ...state, error};
};

export const resetDepreciation = () => {
  return INITIAL_STATE;
};

export const HANDLERS = {
  [Types.FETCH_DEPRECIATION_REPORTS]: fetchDepreciationReports,
  [Types.FETCH_DEPRECIATION_SUCCESS]: fetchDepreciationReportsSuccess,
  [Types.FETCH_DEPRECIATION_FAILURE]: fetchDepreciationReportsFailure,
  [Types.RESET_DEPRECIATION]: resetDepreciation
};

export default createReducer(INITIAL_STATE, HANDLERS);