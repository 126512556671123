import React, { useState, useEffect } from "react";
import styles from "./search.module.css";
import Searchicon from "../images/search.svg";
import {trackLMSEvents} from "../../utils/helpers/tracking-events";
import moment from "moment";
import { NUMBERS, RESET_REDUCER_STATE_KEYS } from "../../utils/app-constants";
import { getUid } from "../../utils/helper";

const Search = ({ search, resetSearchByKeyConnect, appointments, appointmentType }) => {

  const { query } = search;
  const { appointmentState } = appointments;
  const [searchQuery, setSearchQuery] = useState();
  const [isSearchedData, setIsSearchedData] = useState(false);

  useEffect(() => {
    setSearchQuery();
    setIsSearchedData(false);
  }, [appointmentState]);

  const onChangeHandler = (e) => {
    const { target: { value } } = e;
    setSearchQuery(value);
  };

  const onSearchClick = () => {
    console.log("searchQuery", searchQuery);
    if (searchQuery !== undefined && query !== searchQuery) {
      trackLMSEvents("ls_LeadSearch", {
        eventLabel: searchQuery,
        lsRAID: getUid(),
        event:'ls_LeadSearch',
        eventCategory: appointmentType,
        eventAction:'LocalSearch',
        Timestamp: moment().format()
      });
      if (searchQuery.trim()) {
        setIsSearchedData(true);
        resetSearchByKeyConnect(RESET_REDUCER_STATE_KEYS.QUERY, searchQuery.trim());
      } else if (isSearchedData) {
        clearSearch();
      }

    }
  };

  const onSearchKeyPress = (e) => {
    const { charCode } = e;
    if (charCode === NUMBERS.THIRTEEN) {
      onSearchClick();
    }
  };

  const clearSearch = () => {
    setSearchQuery();
    setIsSearchedData(false);
    resetSearchByKeyConnect(RESET_REDUCER_STATE_KEYS.QUERY, "");
  };

  return (
    <div className={styles.searchWrapper}>
      <div className={styles.search}>
        <input type="text" name="search" placeholder="Search on page" onChange={onChangeHandler} onKeyPress={onSearchKeyPress} value={searchQuery || ""} autoComplete="off" />
        {isSearchedData && <button className={styles.searchRest} onClick={clearSearch}>x</button>}
        {!isSearchedData && <button><img alt="" src={Searchicon} onClick={onSearchClick} /></button>}
      </div>
    </div>

  );
};

export default Search;
