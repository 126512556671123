import Types from "./types";
import { AppointmentService } from "../../service";
// import { getLostLeadQuestion } from "../../utils/mocApi";

const fetchLostLeadQuestions = () => dispatch => {
  return AppointmentService.LostLeadQuestions()
    .then(response => {
      // response = getLostLeadQuestion();
      const { data: { detail: { reasons } } } = response;
      return reasons;
    })
    .catch((error) => {
      throw error;
    });
};
export {
  fetchLostLeadQuestions
};
