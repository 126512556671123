import React from "react";
import styles from "./terminate-dealer-reject-price.module.scss";
import rejectTerminal from "../../common/images/terminal-reject.svg";
import {santiziePrice} from "../../common/utility";
const TerminateDealerRejectPrice = ({minPrice=0, reason, maxPrice=0}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.priceWrapper}>
        <p>CARS24 price is between</p>
        <strong className={styles.price}>₹ {`${santiziePrice(minPrice)} - ${santiziePrice(maxPrice, true)}`}</strong>
        <div className={styles.thankyou}>
          {`Fair to Best condition.`}
        </div>
      </div>
      <div className={styles.rejection}>
        <div className={styles.congratulations}>
          <img src={rejectTerminal} alt={'rejectTerminal'}/>
        </div>
        <p>Seems like you didn't like Cars24 Price quote.</p>
        <p>Reason for rejection - {reason}</p>
        <span>Our RA will get in touch with you</span>
      </div>
    </div>
  );
};

export default TerminateDealerRejectPrice;
