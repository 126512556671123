import React, { useState, useEffect } from "react";
import { AppointmentService } from "../../service";
import RadioButton from "../shared/radio-button/component";
import styles from "./lead-check-in-deny-popup.module.css";
import { ERROR_MESSAGE, POPUP_BUTTONS, LOCAL_STORAGE_KEYS, ROLE_KEYS, RESET_REDUCER_STATE_KEYS } from "../../utils/app-constants";
import Button from "../shared/button/component";
import { getFromLocalStorage, showToastMessages, navigateToHomeWithDelay } from "../../utils/helper";

const LeadCheckInDenyPopup = ({
  history,
  rowDetails,
  onCancelClick,
  selectedStore,
  role,
  checkInAppointmentConnect,
  resetCheckInPopupByKeyConnect,
  resetAndFetchData
}) => {

  const { appointment, customerName, model, year, customerEmail, secondaryEmail, make, variant } = rowDetails;
  const [reasonList, setReasonList] = useState([]);
  const [reason, setReason] = useState();
  const [isPosting, setIsPosting] = useState(false);
  const [showError, setShowError] = useState(false);
  useEffect(() => {
    AppointmentService.getCancellationReasons().then(response => {
      const { data: { detail: { reasons } } } = response;
      setReasonList(reasons);
    })
      .catch((error) => {
        setReasonList([]);
      });
  }, [appointment, rowDetails]);

  const onClickHandler = (value) => {
    setReason(value);
    setShowError(false);
  };
  const getStoreId = () => {
    if (role.toLowerCase() === ROLE_KEYS.RM)
      return selectedStore;
    else {
      const localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
      const storeId = localData ? JSON.parse(localData)[LOCAL_STORAGE_KEYS.LMS_STORE_ID] : '';
      if (Array.isArray(storeId)) {
        return storeId && storeId.length > 0 ? storeId[0] : '';
      } else
        return storeId;
    }
  };
  const ReCheckIn = () => {
    const storeId = getStoreId();
    const loginData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
    const userEmail = loginData ? JSON.parse(loginData)[LOCAL_STORAGE_KEYS.EMAIL] : '';
    const checkin_data = {
      "appointment_id": appointment,
      "make": make,
      "model": model,
      "variant": variant,
      "year": year,
      "customer_name": customerName,
      "customer_phone": "", // Not getting in data so sent as blank
      "customer_email": customerEmail,
      "alotted_storeid": storeId,
      "current_store_id": storeId,
      "customer_secondary_email": !!secondaryEmail ? secondaryEmail : "",
      "recheckin": true,
      "userEmail": userEmail
    };
    resetCheckInPopupByKeyConnect(RESET_REDUCER_STATE_KEYS.IS_POSTING, true);
    checkInAppointmentConnect({ checkin_data }).then(response => {
      const { data: { detail: { message } } } = response;
      showToastMessages(message);
      setIsPosting(false);
      onCancelClick();
      if (role.toLowerCase() === ROLE_KEYS.HOSTESS) {
        resetAndFetchData();
      }
      else {
        navigateToHomeWithDelay(history);
      }

    }).catch(error => {
      const { detail: { message } } = error;
      showToastMessages(message || error.detail, false);
      setIsPosting(false);
    });
  };

  const onSubmit = () => {
    if (reason) {
      if (reason === "Re-Inspection") {
        setIsPosting(true);
        ReCheckIn();
      }
      else {
        setShowError(false);
        const localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
        const loggedInEmail = localData ? JSON.parse(localData)[LOCAL_STORAGE_KEYS.EMAIL] : "";
        const params = {
          "reason": "formality",
          "denied_reason": "",
          "appointment_id": appointment,
          "user_email": loggedInEmail,
          "post_inspection_reason": reason
        };
        setIsPosting(true);
        AppointmentService.denyCheckIn(params)
          .then(response => {
            const { data: { detail: { message } } } = response;
            showToastMessages(message);
            setIsPosting(false);
            onCancelClick();
            if (role.toLowerCase() === ROLE_KEYS.HOSTESS) {
              resetAndFetchData();
            } else {
              navigateToHomeWithDelay(history);
            }
          })
          .catch(error => {
            const { detail: { message } } = error;
            showToastMessages(message || error.detail , false);
            setIsPosting(false);
          });
      }
    } else {
      setShowError(true);
    }
  };

  return (
    <div>
      <p className={styles.staticText}>Select Customer check-in reason</p>
      {reasonList.length > 0 &&
      <>
        {
          reasonList.map(item => {
            return (
              <div className={styles.checkboxWrapper}>
                <RadioButton key={item} id={item} name="reason" value={item} text={item} onClickCallback={() => onClickHandler(item)} />
              </div>
            );
          })
        }
        {showError &&
        <div>
          <span className={styles.errorText}>{ERROR_MESSAGE.PRIMARY_CANCELLATION_REASON}</span>
        </div>
        }
        <div className={styles.ctaButton}>
          <Button classNames="saveCta" ctaText={POPUP_BUTTONS.SUBMIT} isLoading={isPosting} disabled={isPosting} onClick={onSubmit} />
          <Button classNames="cancelCta" ctaText={POPUP_BUTTONS.CANCEL} onClick={onCancelClick} />
        </div>
      </>
      }
    </div>
  );
};

export default LeadCheckInDenyPopup;
