import { createTypes } from "reduxsauce";

export default createTypes(
  `
    FETCH_LEAD_DETAILS_SUCCESS
    FETCH_LEAD_DETAILS_FAILURE
    RESET_LEAD_DETAILS
`,
  {
    prefix: "leaddetail/"
  }
);
