/* eslint-disable indent */
export const getMenus = () => {
  return {
    "type": "http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html",
    "title": "User Permission Info",
    "status": 200,
    "detail": {
        "success": true,
        "data": {
            "leftMenu": {
                "135": {
                    "id_lms_role": "203",
                    "roleName": "RM",
                    "pKey": "scheduled",
                    "pText": "Scheduled",
                    "id": "135",
                    "type": "1",
                    "parent": null,
                    "displayOrder": "2"
                },
                "134": {
                    "id_lms_role": "203",
                    "roleName": "RM",
                    "pKey": "sell-online",
                    "pText": "Sell Online",
                    "id": "134",
                    "type": "1",
                    "parent": null,
                    "displayOrder": "1"
                },
                "147": {
                    "id_lms_role": "203",
                    "roleName": "RM",
                    "pKey": "clearance-pending",
                    "pText": "Clearance Pending",
                    "id": "147",
                    "type": "1",
                    "parent": null,
                    "displayOrder": "8"
                },
                "138": {
                    "id_lms_role": "203",
                    "roleName": "RM",
                    "pKey": "negotiation",
                    "pText": "Negotiation",
                    "id": "138",
                    "type": "1",
                    "parent": null,
                    "displayOrder": "5",
                    "child": [
                        {
                            "id_lms_role": "203",
                            "roleName": "RM",
                            "pKey": "starred",
                            "pText": "Starred",
                            "id": "142",
                            "type": "1",
                            "parent": "138",
                            "displayOrder": "4"
                        },
                        {
                            "id_lms_role": "203",
                            "roleName": "RM",
                            "pKey": "not-connected",
                            "pText": "Not Connected",
                            "id": "144",
                            "type": "1",
                            "parent": "138",
                            "displayOrder": "6"
                        },
                        {
                            "id_lms_role": "203",
                            "roleName": "RM",
                            "pKey": "pai-ocb-accepted",
                            "pText": "PAI/OCB Accepted",
                            "id": "140",
                            "type": "1",
                            "parent": "138",
                            "displayOrder": "2"
                        },
                        {
                            "id_lms_role": "203",
                            "roleName": "RM",
                            "pKey": "pai-ocb-rejected",
                            "pText": "PAI/OCB Rejected",
                            "id": "141",
                            "type": "1",
                            "parent": "138",
                            "displayOrder": "3"
                        },
                        {
                            "id_lms_role": "203",
                            "roleName": "RM",
                            "pKey": "gs-leads",
                            "pText": "GS Leads",
                            "id": "143",
                            "type": "1",
                            "parent": "138",
                            "displayOrder": "5"
                        },
                        {
                            "id_lms_role": "203",
                            "roleName": "RM",
                            "pKey": "all-leads",
                            "pText": "All Leads",
                            "id": "139",
                            "type": "1",
                            "parent": "138",
                            "displayOrder": "1"
                        }
                    ]
                },
                "136": {
                    "id_lms_role": "203",
                    "roleName": "RM",
                    "pKey": "visited",
                    "pText": "Visited",
                    "id": "136",
                    "type": "1",
                    "parent": null,
                    "displayOrder": "3"
                },
                "145": {
                    "id_lms_role": "203",
                    "roleName": "RM",
                    "pKey": "diy-negotiation",
                    "pText": "Negotiation DIY",
                    "id": "145",
                    "type": "1",
                    "parent": null,
                    "displayOrder": "6"
                },
                "146": {
                    "id_lms_role": "203",
                    "roleName": "RM",
                    "pKey": "payment-processing",
                    "pText": "Payment Processing",
                    "id": "146",
                    "type": "1",
                    "parent": null,
                    "displayOrder": "7"
                }
            },
            "header": {
                "130": {
                    "id_lms_role": "203",
                    "roleName": "RM",
                    "pKey": "check-in",
                    "pText": "Check-in",
                    "id": "130",
                    "type": "2",
                    "parent": null,
                    "displayOrder": "3"
                },
                "131": {
                    "id_lms_role": "203",
                    "roleName": "RM",
                    "pKey": "Leads",
                    "pText": "Leads (Multiple Store)",
                    "id": "131",
                    "type": "2",
                    "parent": null,
                    "displayOrder": "1",
                    "child": [
                        {
                            "id_lms_role": "203",
                            "roleName": "RM",
                            "pKey": "Branch",
                            "pText": "Branch",
                            "id": "133",
                            "type": "2",
                            "parent": "131",
                            "displayOrder": "2"
                        },
                        {
                            "id_lms_role": "203",
                            "roleName": "RM",
                            "pKey": "Individual",
                            "pText": "Individual",
                            "id": "132",
                            "type": "2",
                            "parent": "131",
                            "displayOrder": "1"
                        }
                    ]
                },
                "117": {
                    "id_lms_role": "203",
                    "roleName": "RM",
                    "pKey": "dashboard",
                    "pText": "Dashboard",
                    "id": "117",
                    "type": "2",
                    "parent": "0",
                    "displayOrder": "3",
                    "child": [
                        {
                            "id_lms_role": "203",
                            "roleName": "RM",
                            "pKey": "incoming-call-logs",
                            "pText": "Incoming Call Logs",
                            "id": "129",
                            "type": "2",
                            "parent": "117",
                            "displayOrder": "4"
                        },
                        {
                            "id_lms_role": "203",
                            "roleName": "RM",
                            "pKey": "call-reports",
                            "pText": "Missed Call Reports",
                            "id": "128",
                            "type": "2",
                            "parent": "117",
                            "displayOrder": "3"
                        },
                        {
                            "id_lms_role": "203",
                            "roleName": "RM",
                            "pKey": "evaluator-store-select",
                            "pText": "Retail & Evaluator View",
                            "id": "127",
                            "type": "2",
                            "parent": "117",
                            "displayOrder": "2"
                        },
                        {
                            "id_lms_role": "203",
                            "roleName": "RM",
                            "pKey": "incentive",
                            "pText": "My Incentive",
                            "id": "126",
                            "type": "2",
                            "parent": "117",
                            "displayOrder": "1"
                        }
                    ]
                }
            }
        }
    }
  };
};

export const getMissedCallMock = () => {
  let response = {
    "type": "Ocb-request",
    "title": "OCB Request",
    "status": 200,
    "data": {
      "detail": {
        "missed_call": {
          "today": 9,
          "yesterday": 11
        }
      }
    }
  };
  return response;
};
export const getOCBDetails = () => {
  let response = {
    "type": "Ocb-request",
    "title": "OCB Request",
    "status": 200,
    "detail": {
      "auctionId": 478716,
      "carId": 430136,
      "ocbSortOrder": 5,
      "c24Quote": 248000,
      "calculatedc24Quote": 248000,
      "c24QuoteTag": "Bought",
      "c24quotExpiryTime": null,
      "expectedOneClickAmount": 150000,
      "currentHB": 150000,
      "bestHB": 334000,
      "is_pll": true,
      "storeId": 3185,
      "make": "MAHINDRA",
      "appointmentId": "10036419957",
      "auction_over_time": 1653569999
    }
  };
  return response;
};
export const getMockAppointmentAnnouncement = () => {
  let response = {
    data: {
      "type": "In-Negotiation",
      "title": "In-Negotiation List",
      "status": 200,
      "detail": {
        "data": {
          "result": [
            {
              "exophone": "02248977660",
              "appointmentId": "1033066937",
              "storeId": "3341",
              "storeType": "store",
              "dealCreatedDate": "2020-11-22 22:45:04",
              "originalInsDate": "2020-11-23 14:09:39",
              "customerExpectedPrice": "0",
              "priceOffered": 0,
              "customerPhone": "******1234",
              "customerName": "Omkar Naresh Ratnakar",
              "followUpMessage": "",
              "followUpRemarks": "",
              "followUpTime": "2020-11-23 09:45:40",
              "isSharedC24Quote": 0,
              "retail_associate_email": "sameer.shaikh@cars24.com",
              "lostReason": "",
              "lostDateTime": "",
              "lostMarkedBy": "",
              "idLmsReason": "",
              "showInputOutputPage": false,
              "pai_ocb_status": null,
              "make": "MAHINDRA",
              "model": "SCORPIO",
              "year": "2014",
              "variant": "VLX 4WD AIRBAG BS IV",
              "carId": "1575048",
              "ownerNumber": "1",
              "otpFlag": 0,
              "adminUrl": "http://admin.24c.in/orders/order?toolbarFilters[o_leadId]=1033066937",
              "is_pll": null,
              "isStarred": 0,
              "isConnected": 0,
              "isDepReportAvailable": false,
              "isDepReportViewed": false,
              "cashbacks": [],
              "isC2cTokenDone": 0,
              "buyNowFlag": null,
              "mainImage": "https://fastly-production.24c.in/inspection/2020/11/23/RQ5QC3Z/v0/exteriorTyres/mainImages/platemaskingfrontmain_1606120246.jpg?width=194&height=172",
              "dealLostReason": "",
              "c24Quote": 521000,
              "calculatedc24Quote": 521000,
              "c24QuoteTag": "External Bid",
              "c24quotExpiryTime": "2020-11-26 23:59:59",
              "oneClickBuy": 0,
              "auctionBiddingStatus": "bid_over",
              "auction_over_time": 0,
              "auction_count": 1,
              "reauctionFlag": 1,
              "auctionId": 2958395,
              "auctionEndTime": "2020-11-23T14:58:15",
              "expectedOneClickAmount": 0,
              "is_suspend": 0,
              "bidValidity": "2020-11-26",
              "currentHB": 557500,
              "bestHB": 557500,
              "pai_status": null,
              "ocb_status": null,
              "is_partial_request": null,
              "request_type": null,
              "expected_hb": null,
              "negotiated_price": null,
              "requestedC24quote": null,
              "pai_timer": null,
              "raise_acceptance": null,
              "sortOrder": 1,
              "conversion": {
                "acceptance_probability": 0.81,
                "max_delta_prob": 0.66,
                "max_delta_band": "M",
                "flag": 1
              },
              "c2cListingPrice": "",
              "ccToken": 0,
              "lastCallStatus": "Not called",
              "lastCallTime": null,
              "qualityCall": false,
              "call_duration": 0,
              "isChatEnable": false
            },
            {
              "exophone": "02248974531",
              "appointmentId": "1428450538",
              "storeId": "4290",
              "storeType": "store",
              "dealCreatedDate": "2020-11-12 12:38:50",
              "originalInsDate": "2020-11-23 14:08:17",
              "customerExpectedPrice": "541000",
              "priceOffered": 0,
              "customerPhone": "******9101",
              "customerName": "patel",
              "followUpMessage": "",
              "followUpRemarks": "",
              "followUpTime": "",
              "isSharedC24Quote": 0,
              "retail_associate_email": "rushi.pathak@cars24.com",
              "lostReason": "",
              "lostDateTime": "",
              "lostMarkedBy": "",
              "idLmsReason": "",
              "showInputOutputPage": false,
              "pai_ocb_status": null,
              "make": "MARUTI SUZUKI",
              "model": "SWIFT",
              "year": "2014",
              "variant": "VDI",
              "carId": "1575102",
              "ownerNumber": "1",
              "otpFlag": 0,
              "adminUrl": "http://admin.24c.in/orders/order?toolbarFilters[o_leadId]=1428450538",
              "is_pll": null,
              "isStarred": 0,
              "isConnected": 0,
              "isDepReportAvailable": false,
              "isDepReportViewed": false,
              "cashbacks": [],
              "isC2cTokenDone": 0,
              "buyNowFlag": null,
              "mainImage": "https://fastly-production.24c.in/inspection/2020/11/23/RQ5QRC6/v0/exteriorTyres/mainImages/platemaskingfrontmain_1606120325.jpg?width=194&height=172",
              "dealLostReason": "",
              "c24Quote": 344000,
              "calculatedc24Quote": 344000,
              "c24QuoteTag": "External Bid",
              "c24quotExpiryTime": "2020-11-26 23:59:59",
              "oneClickBuy": 0,
              "auctionBiddingStatus": "bid_over",
              "auction_over_time": 0,
              "auction_count": 1,
              "reauctionFlag": 1,
              "auctionId": 2958416,
              "auctionEndTime": "2020-11-23T15:00:51",
              "expectedOneClickAmount": 0,
              "is_suspend": 0,
              "bidValidity": "2020-11-26",
              "currentHB": 364000,
              "bestHB": 364000,
              "pai_status": null,
              "ocb_status": null,
              "is_partial_request": null,
              "request_type": null,
              "expected_hb": null,
              "negotiated_price": null,
              "requestedC24quote": null,
              "pai_timer": null,
              "raise_acceptance": null,
              "sortOrder": 41,
              "conversion": {
                "acceptance_probability": 0,
                "max_delta_prob": 0,
                "max_delta_band": "",
                "flag": 0
              },
              "c2cListingPrice": "",
              "ccToken": 0,
              "lastCallStatus": "Not called",
              "lastCallTime": null,
              "qualityCall": false,
              "call_duration": 0,
              "isChatEnable": false
            },
            {
              "exophone": "",
              "appointmentId": "1035126831",
              "storeId": "3220",
              "storeType": "store",
              "dealCreatedDate": "2020-11-23 13:39:21",
              "originalInsDate": "2020-11-23 14:08:15",
              "customerExpectedPrice": "129000",
              "priceOffered": 0,
              "customerPhone": "******6789",
              "customerName": "Amol P Bidkar",
              "followUpMessage": "",
              "followUpRemarks": "",
              "followUpTime": "",
              "isSharedC24Quote": 1,
              "retail_associate_email": "rajan.ukani@cars24.com",
              "lostReason": "",
              "lostDateTime": "",
              "lostMarkedBy": "",
              "idLmsReason": "",
              "showInputOutputPage": false,
              "pai_ocb_status": null,
              "make": "TATA",
              "model": "INDICA VISTA",
              "year": "2012",
              "variant": "GVX SAFIRE 65",
              "carId": "1575053",
              "ownerNumber": "1",
              "otpFlag": 0,
              "adminUrl": "http://admin.24c.in/orders/order?toolbarFilters[o_leadId]=1035126831",
              "is_pll": null,
              "isStarred": 0,
              "isConnected": 0,
              "isDepReportAvailable": false,
              "isDepReportViewed": false,
              "cashbacks": [],
              "isC2cTokenDone": 0,
              "buyNowFlag": null,
              "mainImage": "https://fastly-production.24c.in/inspection/2020/11/23/RQ5QCQD/v0/exteriorTyres/mainImages/platemaskingfrontmain_1606119290.jpg?width=194&height=172",
              "dealLostReason": "",
              "c24Quote": 110000,
              "calculatedc24Quote": 110000,
              "c24QuoteTag": "External Bid",
              "c24quotExpiryTime": "2020-11-26 23:59:59",
              "oneClickBuy": 0,
              "auctionBiddingStatus": "bid_over",
              "auction_over_time": 0,
              "auction_count": 1,
              "reauctionFlag": 1,
              "auctionId": 2958255,
              "auctionEndTime": "2020-11-23T14:48:13",
              "expectedOneClickAmount": 0,
              "is_suspend": 0,
              "bidValidity": "2020-11-26",
              "currentHB": 110151,
              "bestHB": 110151,
              "pai_status": null,
              "ocb_status": null,
              "is_partial_request": null,
              "request_type": null,
              "expected_hb": null,
              "negotiated_price": null,
              "requestedC24quote": null,
              "pai_timer": null,
              "raise_acceptance": null,
              "sortOrder": 41,
              "conversion": {
                "acceptance_probability": 0.92,
                "max_delta_prob": 0.43,
                "max_delta_band": "L",
                "flag": 1
              },
              "c2cListingPrice": "",
              "ccToken": 0,
              "lastCallStatus": "Not called",
              "lastCallTime": null,
              "qualityCall": false,
              "call_duration": 0,
              "isChatEnable": false
            },
            {
              "exophone": "02248973482",
              "appointmentId": "1065086039",
              "storeId": "3543",
              "storeType": "store",
              "dealCreatedDate": "2020-11-21 16:21:28",
              "originalInsDate": "2020-11-23 14:07:54",
              "customerExpectedPrice": "0",
              "priceOffered": 0,
              "customerPhone": "******4778",
              "customerName": "Arun jaiswal",
              "followUpMessage": "",
              "followUpRemarks": "",
              "followUpTime": "2020-11-23 10:30:42",
              "isSharedC24Quote": 0,
              "retail_associate_email": "yash.rawal@cars24.com",
              "lostReason": "",
              "lostDateTime": "",
              "lostMarkedBy": "",
              "idLmsReason": "",
              "showInputOutputPage": false,
              "pai_ocb_status": null,
              "make": "MARUTI SUZUKI",
              "model": "WAGON R 1.0",
              "year": "2011",
              "variant": "LXI CNG",
              "carId": "1574995",
              "ownerNumber": "1",
              "otpFlag": 0,
              "adminUrl": "http://admin.24c.in/orders/order?toolbarFilters[o_leadId]=1065086039",
              "is_pll": null,
              "isStarred": 0,
              "isConnected": 0,
              "isDepReportAvailable": false,
              "isDepReportViewed": false,
              "cashbacks": [],
              "isC2cTokenDone": 0,
              "buyNowFlag": null,
              "mainImage": "https://fastly-production.24c.in/inspection/2020/11/23/RQ53YYQ/v0/exteriorTyres/mainImages/platemaskingfrontmain_1606119773.jpg?width=194&height=172",
              "dealLostReason": "",
              "c24Quote": 176000,
              "calculatedc24Quote": 176000,
              "c24QuoteTag": "External Bid",
              "c24quotExpiryTime": "2020-11-26 23:59:59",
              "oneClickBuy": 0,
              "auctionBiddingStatus": "bid_over",
              "auction_over_time": 0,
              "auction_count": 1,
              "reauctionFlag": 1,
              "auctionId": 2958287,
              "auctionEndTime": "2020-11-23T14:45:05",
              "expectedOneClickAmount": 0,
              "is_suspend": 0,
              "bidValidity": "2020-11-26",
              "currentHB": 195000,
              "bestHB": 195000,
              "pai_status": null,
              "ocb_status": null,
              "is_partial_request": null,
              "request_type": null,
              "expected_hb": null,
              "negotiated_price": null,
              "requestedC24quote": null,
              "pai_timer": null,
              "raise_acceptance": null,
              "sortOrder": 41,
              "conversion": {
                "acceptance_probability": 0.76,
                "max_delta_prob": 0.65,
                "max_delta_band": "M",
                "flag": 1
              },
              "c2cListingPrice": "",
              "ccToken": 0,
              "lastCallStatus": "Not called",
              "lastCallTime": null,
              "qualityCall": false,
              "call_duration": 0,
              "isChatEnable": false
            },
            {
              "exophone": "02071534305",
              "appointmentId": "1662966237",
              "storeId": "3987",
              "storeType": "locality",
              "dealCreatedDate": "2020-11-21 21:23:17",
              "originalInsDate": "2020-11-23 14:07:43",
              "customerExpectedPrice": "0",
              "priceOffered": 0,
              "customerPhone": "******6694",
              "customerName": "Deepak",
              "followUpMessage": "",
              "followUpRemarks": "",
              "followUpTime": "2020-11-23 10:03:58",
              "isSharedC24Quote": 0,
              "retail_associate_email": "komal.arora@cars24.com",
              "lostReason": "",
              "lostDateTime": "",
              "lostMarkedBy": "",
              "idLmsReason": "",
              "showInputOutputPage": false,
              "pai_ocb_status": null,
              "make": "MARUTI SUZUKI",
              "model": "SWIFT",
              "year": "2015",
              "variant": "VDI ABS",
              "carId": "1574165",
              "ownerNumber": "2",
              "otpFlag": 0,
              "adminUrl": "http://admin.24c.in/orders/order?toolbarFilters[o_leadId]=1662966237",
              "is_pll": null,
              "isStarred": 0,
              "isConnected": 0,
              "isDepReportAvailable": false,
              "isDepReportViewed": false,
              "cashbacks": [],
              "isC2cTokenDone": 0,
              "buyNowFlag": null,
              "mainImage": "https://fastly-production.24c.in/inspection/2020/11/23/RQ53RXQ/v0/exteriorTyres/mainImages/platemaskingfrontmain_1606119547.jpg?width=194&height=172",
              "dealLostReason": "",
              "c24Quote": 445000,
              "calculatedc24Quote": 445000,
              "c24QuoteTag": "External Bid",
              "c24quotExpiryTime": "2020-11-26 23:59:59",
              "oneClickBuy": 0,
              "auctionBiddingStatus": "bid_over",
              "auction_over_time": 0,
              "auction_count": 1,
              "reauctionFlag": 1,
              "auctionId": 2958288,
              "auctionEndTime": "2020-11-23T14:45:44",
              "expectedOneClickAmount": 0,
              "is_suspend": 0,
              "bidValidity": "2020-11-26",
              "currentHB": 466524,
              "bestHB": 466524,
              "pai_status": null,
              "ocb_status": null,
              "is_partial_request": null,
              "request_type": null,
              "expected_hb": null,
              "negotiated_price": null,
              "requestedC24quote": null,
              "pai_timer": null,
              "raise_acceptance": null,
              "sortOrder": 41,
              "conversion": {
                "acceptance_probability": 0.75,
                "max_delta_prob": 0.27,
                "max_delta_band": "L",
                "flag": 1
              },
              "c2cListingPrice": 590000,
              "ccToken": 0,
              "lastCallStatus": "Call connected",
              "lastCallTime": "02:55 PM | 23 Nov",
              "qualityCall": true,
              "call_duration": 352,
              "isChatEnable": false
            },
            {
              "exophone": "08047491141",
              "appointmentId": "1125156731",
              "storeId": "3388",
              "storeType": "store",
              "dealCreatedDate": "2020-11-22 19:48:50",
              "originalInsDate": "2020-11-23 14:07:40",
              "customerExpectedPrice": "845000",
              "priceOffered": 0,
              "customerPhone": "******5599",
              "customerName": "Ashish Mittal",
              "followUpMessage": "",
              "followUpRemarks": "",
              "followUpTime": "",
              "isSharedC24Quote": 0,
              "retail_associate_email": "gurpreet.rainu@cars24.com",
              "lostReason": "",
              "lostDateTime": "",
              "lostMarkedBy": "",
              "idLmsReason": "",
              "showInputOutputPage": false,
              "pai_ocb_status": null,
              "make": "HONDA",
              "model": "CITY",
              "year": "2015",
              "variant": "V MT PETROL",
              "carId": "1575074",
              "ownerNumber": "1",
              "otpFlag": 0,
              "adminUrl": "http://admin.24c.in/orders/order?toolbarFilters[o_leadId]=1125156731",
              "is_pll": null,
              "isStarred": 0,
              "isConnected": 0,
              "isDepReportAvailable": true,
              "isDepReportViewed": false,
              "cashbacks": [],
              "isC2cTokenDone": 0,
              "buyNowFlag": null,
              "mainImage": "https://fastly-production.24c.in/inspection/2020/11/23/RQ5QC53/v0/exteriorTyres/mainImages/platemaskingfrontmain_1606119774.jpg?width=194&height=172",
              "dealLostReason": "",
              "c24Quote": 472000,
              "calculatedc24Quote": 472000,
              "c24QuoteTag": "External Bid",
              "c24quotExpiryTime": "2020-11-26 23:59:59",
              "oneClickBuy": 0,
              "auctionBiddingStatus": "bid_over",
              "auction_over_time": 0,
              "auction_count": 1,
              "reauctionFlag": 1,
              "auctionId": 2958257,
              "auctionEndTime": "2020-11-23T14:42:16",
              "expectedOneClickAmount": 0,
              "is_suspend": 0,
              "bidValidity": "2020-11-26",
              "currentHB": 500306,
              "bestHB": 500306,
              "pai_status": null,
              "ocb_status": null,
              "is_partial_request": null,
              "request_type": null,
              "expected_hb": null,
              "negotiated_price": null,
              "requestedC24quote": null,
              "pai_timer": null,
              "raise_acceptance": null,
              "sortOrder": 41,
              "conversion": {
                "acceptance_probability": 0.84,
                "max_delta_prob": 0.52,
                "max_delta_band": "M",
                "flag": 1
              },
              "c2cListingPrice": "",
              "ccToken": 0,
              "lastCallStatus": "Not called",
              "lastCallTime": null,
              "qualityCall": false,
              "call_duration": 0,
              "isChatEnable": false
            },
            {
              "exophone": "08037293776",
              "appointmentId": "1621816539",
              "storeId": "3764",
              "storeType": "store",
              "dealCreatedDate": "2020-11-21 10:24:58",
              "originalInsDate": "2020-11-23 14:06:07",
              "customerExpectedPrice": "610000",
              "priceOffered": 0,
              "customerPhone": "******7980",
              "customerName": "Shahid",
              "followUpMessage": "",
              "followUpRemarks": "",
              "followUpTime": "",
              "isSharedC24Quote": 0,
              "retail_associate_email": "neha.ojha@cars24.com",
              "lostReason": "",
              "lostDateTime": "",
              "lostMarkedBy": "",
              "idLmsReason": "",
              "showInputOutputPage": false,
              "pai_ocb_status": null,
              "make": "TATA",
              "model": "SAFARI STORME",
              "year": "2013",
              "variant": "2.2 EX 4X2",
              "carId": "1575063",
              "ownerNumber": "3",
              "otpFlag": 0,
              "adminUrl": "http://admin.24c.in/orders/order?toolbarFilters[o_leadId]=1621816539",
              "is_pll": null,
              "isStarred": 0,
              "isConnected": 0,
              "isDepReportAvailable": false,
              "isDepReportViewed": false,
              "cashbacks": [],
              "isC2cTokenDone": 0,
              "buyNowFlag": null,
              "mainImage": "https://fastly-production.24c.in/inspection/2020/11/23/RQ5QCXD/v0/exteriorTyres/mainImages/platemaskingfrontmain_1606119524.jpg?width=194&height=172",
              "dealLostReason": "",
              "c24Quote": 254000,
              "calculatedc24Quote": 254000,
              "c24QuoteTag": "External Bid",
              "c24quotExpiryTime": "2020-11-26 23:59:59",
              "oneClickBuy": 0,
              "auctionBiddingStatus": "bid_over",
              "auction_over_time": 0,
              "auction_count": 1,
              "reauctionFlag": 1,
              "auctionId": 2958286,
              "auctionEndTime": "2020-11-23T14:43:38",
              "expectedOneClickAmount": 0,
              "is_suspend": 0,
              "bidValidity": "2020-11-26",
              "currentHB": 272000,
              "bestHB": 272000,
              "pai_status": null,
              "ocb_status": null,
              "is_partial_request": null,
              "request_type": null,
              "expected_hb": null,
              "negotiated_price": null,
              "requestedC24quote": null,
              "pai_timer": null,
              "raise_acceptance": null,
              "sortOrder": 41,
              "conversion": {
                "acceptance_probability": 0,
                "max_delta_prob": 0,
                "max_delta_band": "",
                "flag": 0
              },
              "c2cListingPrice": "",
              "ccToken": 0,
              "lastCallStatus": "Not called",
              "lastCallTime": null,
              "qualityCall": false,
              "call_duration": 0,
              "isChatEnable": false
            },
            {
              "exophone": "",
              "appointmentId": "1133186831",
              "storeId": "3724",
              "storeType": "pns",
              "dealCreatedDate": "2020-11-23 13:41:56",
              "originalInsDate": "2020-11-23 14:05:19",
              "customerExpectedPrice": "244000",
              "priceOffered": 0,
              "customerPhone": "******6652",
              "customerName": "Rekha ",
              "followUpMessage": "",
              "followUpRemarks": "",
              "followUpTime": "",
              "isSharedC24Quote": 1,
              "retail_associate_email": "ajeeth.kumar@cars24.com",
              "lostReason": "",
              "lostDateTime": "",
              "lostMarkedBy": "",
              "idLmsReason": "",
              "showInputOutputPage": false,
              "pai_ocb_status": null,
              "make": "NISSAN",
              "model": "MICRA",
              "year": "2011",
              "variant": "XV PETROL",
              "carId": "1575068",
              "ownerNumber": "2",
              "otpFlag": 0,
              "adminUrl": "http://admin.24c.in/orders/order?toolbarFilters[o_leadId]=1133186831",
              "is_pll": null,
              "isStarred": 0,
              "isConnected": 0,
              "isDepReportAvailable": false,
              "isDepReportViewed": false,
              "cashbacks": [],
              "isC2cTokenDone": 0,
              "buyNowFlag": null,
              "mainImage": "https://fastly-production.24c.in/inspection/2020/11/23/RQ5QCXZ/v0/exteriorTyres/mainImages/platemaskingfrontmain_1606119675.jpg?width=194&height=172",
              "dealLostReason": "",
              "c24Quote": 155000,
              "calculatedc24Quote": 155000,
              "c24QuoteTag": "External Bid",
              "c24quotExpiryTime": "2020-11-26 23:59:59",
              "oneClickBuy": 0,
              "auctionBiddingStatus": "bid_over",
              "auction_over_time": 0,
              "auction_count": 1,
              "reauctionFlag": 1,
              "auctionId": 2958299,
              "auctionEndTime": "2020-11-23T14:45:56",
              "expectedOneClickAmount": 0,
              "is_suspend": 0,
              "bidValidity": "2020-11-26",
              "currentHB": 155440,
              "bestHB": 155440,
              "pai_status": null,
              "ocb_status": null,
              "is_partial_request": null,
              "request_type": null,
              "expected_hb": null,
              "negotiated_price": null,
              "requestedC24quote": null,
              "pai_timer": null,
              "raise_acceptance": null,
              "sortOrder": 41,
              "conversion": {
                "acceptance_probability": 0.86,
                "max_delta_prob": 0.55,
                "max_delta_band": "L",
                "flag": 1
              },
              "c2cListingPrice": "",
              "ccToken": 0,
              "lastCallStatus": "Not called",
              "lastCallTime": null,
              "qualityCall": false,
              "call_duration": 0,
              "isChatEnable": false
            },
            {
              "exophone": "02248974391",
              "appointmentId": "1333996337",
              "storeId": "3851",
              "storeType": "store",
              "dealCreatedDate": "2020-11-21 20:39:15",
              "originalInsDate": "2020-11-23 14:04:36",
              "customerExpectedPrice": "404000",
              "priceOffered": 0,
              "customerPhone": "******8867",
              "customerName": "Rahul",
              "followUpMessage": "",
              "followUpRemarks": "",
              "followUpTime": "",
              "isSharedC24Quote": 0,
              "retail_associate_email": "sumeet.sihag@cars24.com",
              "lostReason": "",
              "lostDateTime": "",
              "lostMarkedBy": "",
              "idLmsReason": "",
              "showInputOutputPage": false,
              "pai_ocb_status": null,
              "make": "MARUTI SUZUKI",
              "model": "ALTO K10",
              "year": "2018",
              "variant": "VXI ",
              "carId": "1575072",
              "ownerNumber": "1",
              "otpFlag": 0,
              "adminUrl": "http://admin.24c.in/orders/order?toolbarFilters[o_leadId]=1333996337",
              "is_pll": null,
              "isStarred": 0,
              "isConnected": 0,
              "isDepReportAvailable": false,
              "isDepReportViewed": false,
              "cashbacks": [],
              "isC2cTokenDone": 0,
              "buyNowFlag": null,
              "mainImage": "https://fastly-production.24c.in/inspection/2020/11/23/RQ5QC56/v0/exteriorTyres/mainImages/platemaskingfrontmain_1606119787.jpg?width=194&height=172",
              "dealLostReason": "",
              "c24Quote": 262000,
              "calculatedc24Quote": 262000,
              "c24QuoteTag": "External Bid",
              "c24quotExpiryTime": "2020-11-26 23:59:59",
              "oneClickBuy": 0,
              "auctionBiddingStatus": "bid_over",
              "auction_over_time": 0,
              "auction_count": 1,
              "reauctionFlag": 1,
              "auctionId": 2958223,
              "auctionEndTime": "2020-11-23T14:37:06",
              "expectedOneClickAmount": 0,
              "is_suspend": 0,
              "bidValidity": "2020-11-26",
              "currentHB": 280000,
              "bestHB": 280000,
              "pai_status": null,
              "ocb_status": null,
              "is_partial_request": null,
              "request_type": null,
              "expected_hb": null,
              "negotiated_price": null,
              "requestedC24quote": null,
              "pai_timer": null,
              "raise_acceptance": null,
              "sortOrder": 41,
              "conversion": {
                "acceptance_probability": 0.76,
                "max_delta_prob": 0.56,
                "max_delta_band": "M",
                "flag": 1
              },
              "c2cListingPrice": 359000,
              "ccToken": 0,
              "lastCallStatus": "Not called",
              "lastCallTime": null,
              "qualityCall": false,
              "call_duration": 0,
              "isChatEnable": false
            },
            {
              "exophone": "04047920756",
              "appointmentId": "1017820634",
              "storeId": "3295",
              "storeType": "store",
              "dealCreatedDate": "2020-11-17 14:51:26",
              "originalInsDate": "2020-11-23 14:04:00",
              "customerExpectedPrice": "150000",
              "priceOffered": 0,
              "customerPhone": "******1598",
              "customerName": "MANISH",
              "followUpMessage": "",
              "followUpRemarks": "",
              "followUpTime": "",
              "isSharedC24Quote": 0,
              "retail_associate_email": "sachin.kajal@cars24.com",
              "lostReason": "",
              "lostDateTime": "",
              "lostMarkedBy": "",
              "idLmsReason": "",
              "showInputOutputPage": false,
              "pai_ocb_status": null,
              "make": "MARUTI SUZUKI",
              "model": "SX4",
              "year": "2007",
              "variant": "ZXI",
              "carId": "1574582",
              "ownerNumber": "2",
              "otpFlag": 0,
              "adminUrl": "http://admin.24c.in/orders/order?toolbarFilters[o_leadId]=1017820634",
              "is_pll": null,
              "isStarred": 0,
              "isConnected": 0,
              "isDepReportAvailable": false,
              "isDepReportViewed": false,
              "cashbacks": [],
              "isC2cTokenDone": 0,
              "buyNowFlag": null,
              "mainImage": "https://fastly-production.24c.in/inspection/2020/11/23/RQ53QZ6/v0/exteriorTyres/mainImages/platemaskingfrontmain_1606114335.jpg?width=194&height=172",
              "dealLostReason": "",
              "c24Quote": 48000,
              "calculatedc24Quote": 48000,
              "c24QuoteTag": "External Bid",
              "c24quotExpiryTime": "2020-11-26 23:59:59",
              "oneClickBuy": 0,
              "auctionBiddingStatus": "bid_over",
              "auction_over_time": 0,
              "auction_count": 1,
              "reauctionFlag": 1,
              "auctionId": 2958224,
              "auctionEndTime": "2020-11-23T14:37:09",
              "expectedOneClickAmount": 0,
              "is_suspend": 0,
              "bidValidity": "2020-11-26",
              "currentHB": 57146,
              "bestHB": 57146,
              "pai_status": null,
              "ocb_status": null,
              "is_partial_request": null,
              "request_type": null,
              "expected_hb": null,
              "negotiated_price": null,
              "requestedC24quote": null,
              "pai_timer": null,
              "raise_acceptance": null,
              "sortOrder": 41,
              "conversion": {
                "acceptance_probability": 0.82,
                "max_delta_prob": 0.7,
                "max_delta_band": "M",
                "flag": 1
              },
              "c2cListingPrice": "",
              "ccToken": 0,
              "lastCallStatus": "Not called",
              "lastCallTime": null,
              "qualityCall": false,
              "call_duration": 0,
              "isChatEnable": false
            }
          ]
        },
        "expires_today": 1343,
        "expires_in_1_days": 1742,
        "expires_in_2_days": 2105,
        "expires_in_3_days": 943,
        "total": 6133
      }
    }

  };
  return response;
};

export const getScheduledPageData = () => {
  let response = {
    data:{
      "type": "http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html",
      "title": "Lead Info",
      "status": 200,
      "detail": {
        "result": [
          {
            "customerName": "Prashant",
            "appointmentStatus": "not-verified",
            "storeType": "locality",
            "appointment": "1036637646",
            "followUpMessage": "",
            "currentApptDate": "2021-04-23 09:30:00",
            "stateName": "Scheduled",
            "year": "2009",
            "exophone": "11111",
            "model": "NANO",
            "stateId": "1",
            "make": "TATA",
            "customerEmail": "ppawar1974@gmail.com",
            "rescheduleUrl": "https://www.cars24.com/appointment/confirmation?l=Z90DZ0Ndpb8FabImrk72RWsVvDOEu-S0&e=1&utm_medium=reschedule&utm_source=LMS",
            "variant": "CX",
            "zoneName": "HI-Worli",
            "customerAddress": "400011, Mumbai",
            "homeInspectionCj": "",
            "timeSlot": "09:30 - 11:30",
            "paymentStatus": "",
            "secondaryEmail": "abtest_z90dz0ndpb8fabimrk72rwsvvdoeu-s0@cars24.mail.com",
            "connectedCalls": 0,
            "generateFlag": true,
            "lastCallStatus": "Not called",
            "lastCallTime": null,
            "cc_lms_tag": "",
            "retail_associate_email": "",
            "hi_cj_self_assigned": 0,
            "is_duplicate": "0",
            "homeInspectionCjEmail": ""
          },
          {
            "customerName": "Pradeep",
            "appointmentStatus": "not-verified",
            "storeType": "locality",
            "appointment": "1022657646",
            "followUpMessage": "",
            "currentApptDate": "2021-04-23 12:00:00",
            "stateName": "Scheduled",
            "year": "2015",
            "exophone": "22222",
            "model": "SWIFT",
            "stateId": "1",
            "make": "MARUTI SUZUKI",
            "customerEmail": "dsa_lh_isjh06iyik3xbny976vw89uhqizxg--x@cars24.mail.com",
            "rescheduleUrl": "https://www.cars24.com/appointment/confirmation?l=iSjh06iyik3xbNy976vw89uhqIzxg--X&e=1&utm_medium=reschedule&utm_source=LMS",
            "variant": "VDI",
            "zoneName": "HI-North-Bengaluru",
            "customerAddress": "Visveshwaraiah Industrial Estate, Bengaluru, Karnataka, 560048",
            "homeInspectionCj": "",
            "timeSlot": "11:30 - 13:30",
            "paymentStatus": "",
            "secondaryEmail": null,
            "connectedCalls": 0,
            "generateFlag": true,
            "lastCallStatus": "Not called",
            "lastCallTime": null,
            "cc_lms_tag": "",
            "retail_associate_email": "",
            "hi_cj_self_assigned": 0,
            "is_duplicate": "0",
            "homeInspectionCjEmail": ""
          },
          {
            "customerName": "Amit",
            "appointmentStatus": "not-verified",
            "storeType": "locality",
            "appointment": "1053617646",
            "followUpMessage": "",
            "currentApptDate": "2021-04-23 10:00:00",
            "stateName": "Scheduled",
            "year": "2012",
            "exophone": "2222",
            "model": "I10",
            "stateId": "1",
            "make": "HYUNDAI",
            "customerEmail": "dsa_lh_yt9q929yag-ejufzxvn1xgwiblrfzsrw@cars24.mail.com",
            "rescheduleUrl": "https://www.cars24.com/appointment/confirmation?l=Yt9q929yAG-ejufZXVn1XgWIBLRfzsrw&e=1&utm_medium=reschedule&utm_source=LMS",
            "variant": "ASTA 1.2 AT KAPPA2 WITH SUNROOF",
            "zoneName": "HI-Noida",
            "customerAddress": "Block D, Sector 63, Noida, Uttar Pradesh, 201301",
            "homeInspectionCj": "",
            "timeSlot": "09:30 - 11:30",
            "paymentStatus": "",
            "secondaryEmail": null,
            "connectedCalls": 0,
            "generateFlag": true,
            "lastCallStatus": "Not called",
            "lastCallTime": null,
            "cc_lms_tag": "",
            "retail_associate_email": "harshit.vohra@cars24.com",
            "hi_cj_self_assigned": 0,
            "is_duplicate": "0",
            "homeInspectionCjEmail": ""
          },
          {
            "customerName": "arun",
            "appointmentStatus": "verified",
            "storeType": "locality",
            "appointment": "1998617646",
            "followUpMessage": "",
            "currentApptDate": "2021-04-23 11:00:00",
            "stateName": "Scheduled",
            "year": "2010",
            "exophone": "",
            "model": "INNOVA",
            "stateId": "1",
            "make": "TOYOTA",
            "customerEmail": "dsa_cc_in_uezm4lyxreqd_ei9ghlu1nccgrxydv69@cars24.mail.com",
            "rescheduleUrl": "https://www.cars24.com/appointment/confirmation?l=Uezm4LYXrEqD_eI9gHLU1nCcgRxyDV69&e=1&utm_medium=reschedule&utm_source=LMS",
            "variant": "2.5 G4 7 STR",
            "zoneName": "HI-Bikaner",
            "customerAddress": "Sector 3, Jai Narayan Vyas Colony, Bikaner, Rajasthan, 334001",
            "homeInspectionCj": "",
            "timeSlot": "09:30 - 11:30",
            "paymentStatus": "",
            "secondaryEmail": null,
            "connectedCalls": 0,
            "generateFlag": true,
            "lastCallStatus": "Not called",
            "lastCallTime": null,
            "cc_lms_tag": "",
            "retail_associate_email": "",
            "hi_cj_self_assigned": 0,
            "is_duplicate": "0",
            "homeInspectionCjEmail": ""
          },
          {
            "customerName": "Amar",
            "appointmentStatus": "verified",
            "storeType": "locality",
            "appointment": "1018697646",
            "followUpMessage": "",
            "currentApptDate": "2021-04-23 12:00:00",
            "stateName": "Scheduled",
            "year": "2009",
            "exophone": "",
            "model": "I10",
            "stateId": "1",
            "make": "HYUNDAI",
            "customerEmail": "dsa_vk_jcf5lcq1ctyqvttvwbeucthi9dms4ubo@cars24.mail.com",
            "rescheduleUrl": "https://www.cars24.com/appointment/confirmation?l=jCF5lCq1CtyqvTTvWBeucTHI9DMs4UBO&e=1&utm_medium=reschedule&utm_source=LMS",
            "variant": "MAGNA",
            "zoneName": null,
            "customerAddress": "Sector 54, Gurugram, Haryana, 122011",
            "homeInspectionCj": "",
            "timeSlot": "11:30 - 13:30",
            "paymentStatus": "SUCCESS",
            "secondaryEmail": null,
            "connectedCalls": 0,
            "generateFlag": true,
            "lastCallStatus": "Not called",
            "lastCallTime": null,
            "cc_lms_tag": "",
            "retail_associate_email": "",
            "hi_cj_self_assigned": 0,
            "is_duplicate": "0",
            "homeInspectionCjEmail": ""
          },
          {
            "customerName": "surinder",
            "appointmentStatus": "verified",
            "storeType": "locality",
            "appointment": "1995657646",
            "followUpMessage": "",
            "currentApptDate": "2021-04-23 09:00:00",
            "stateName": "Scheduled",
            "year": "2019",
            "exophone": "",
            "model": "SWIFT DZIRE",
            "stateId": "1",
            "make": "MARUTI SUZUKI",
            "customerEmail": "abc@gmail.com",
            "rescheduleUrl": "https://www.cars24.com/appointment/confirmation?l=XvehIPh1ugdEMbhzayswTepJhAQ8Qwz5&e=1&utm_medium=reschedule&utm_source=LMS",
            "variant": "TOUR S DIESEL",
            "zoneName": "HI-Bikaner",
            "customerAddress": "Hotel Chirag, Vallabh Garden, Near Sudarshna Nagar, Bikaner, Rajasthan, 334003",
            "homeInspectionCj": "",
            "timeSlot": "",
            "paymentStatus": "",
            "secondaryEmail": null,
            "connectedCalls": 0,
            "generateFlag": true,
            "lastCallStatus": "Not called",
            "lastCallTime": null,
            "cc_lms_tag": "",
            "retail_associate_email": "vijendra.singh@cars24.com",
            "hi_cj_self_assigned": 0,
            "is_duplicate": "0",
            "homeInspectionCjEmail": ""
          },
          {
            "customerName": "Mangesh",
            "appointmentStatus": "not-verified",
            "storeType": "locality",
            "appointment": "1033697646",
            "followUpMessage": "",
            "currentApptDate": "2021-04-23 09:30:00",
            "stateName": "Scheduled",
            "year": "2011",
            "exophone": "04048219857",
            "model": "ZEN ESTILO",
            "stateId": "1",
            "make": "MARUTI SUZUKI",
            "customerEmail": "mangesh.parulkar@gmail.com",
            "rescheduleUrl": "https://www.cars24.com/appointment/confirmation?l=7G8g52gl3G3-R30bCA588RMSvFeltRjd&e=1&utm_medium=reschedule&utm_source=LMS",
            "variant": "LXI",
            "zoneName": null,
            "customerAddress": " (Arabian Sea)",
            "homeInspectionCj": "",
            "timeSlot": "09:30 - 11:30",
            "paymentStatus": "",
            "secondaryEmail": null,
            "connectedCalls": 0,
            "generateFlag": false,
            "lastCallStatus": "Call not connected",
            "lastCallTime": "2021-04-22 14:08:27",
            "cc_lms_tag": "",
            "retail_associate_email": "",
            "hi_cj_self_assigned": 0,
            "is_duplicate": "0",
            "homeInspectionCjEmail": ""
          },
          {
            "customerName": "Avijit Basu",
            "appointmentStatus": "not-verified",
            "storeType": "locality",
            "appointment": "1195617646",
            "followUpMessage": "",
            "currentApptDate": "2021-04-23 13:30:00",
            "stateName": "Scheduled",
            "year": "2012",
            "exophone": "",
            "model": "MICRA",
            "stateId": "1",
            "make": "NISSAN",
            "customerEmail": "dsa_ka_ivfwhzaawbmk1hlfeomzecw1tapvnrxy@cars24.mail.com",
            "rescheduleUrl": "https://www.cars24.com/appointment/confirmation?l=wqWtF6JXNSauE-0ow-aRvMfqRmafysE5&e=1&utm_medium=reschedule&utm_source=LMS",
            "variant": "XV DIESEL",
            "zoneName": null,
            "customerAddress": "700156, Kolkata",
            "homeInspectionCj": "",
            "timeSlot": "13:30 - 15:30",
            "paymentStatus": "",
            "secondaryEmail": "abtest_wqwtf6jxnsaue-0ow-arvmfqrmafyse5@cars24.mail.com",
            "connectedCalls": 0,
            "generateFlag": true,
            "lastCallStatus": "Not called",
            "lastCallTime": null,
            "cc_lms_tag": "",
            "retail_associate_email": "",
            "hi_cj_self_assigned": 0,
            "is_duplicate": "0",
            "homeInspectionCjEmail": ""
          },
          {
            "customerName": "Chandra kant",
            "appointmentStatus": "not-verified",
            "storeType": "locality",
            "appointment": "1861697646",
            "followUpMessage": "",
            "currentApptDate": "2021-04-23 09:30:00",
            "stateName": "Scheduled",
            "year": "2019",
            "exophone": "",
            "model": "ECOSPORT",
            "stateId": "1",
            "make": "FORD",
            "customerEmail": "dsa_sss_fkcn2_yjltg-avy_qapvc2llwrd7dmrm@cars24.mail.com",
            "rescheduleUrl": "https://www.cars24.com/appointment/confirmation?l=FKCn2_YjlTG-avy_qAPvc2llWrd7DMRM&e=1&utm_medium=reschedule&utm_source=LMS",
            "variant": "1.0 ECOBOOST TITANIUM OPT",
            "zoneName": "HI-Karimnagar",
            "customerAddress": "Karimnagar Bypass, Hussainpura, Karimnagar, Telangana, 505001",
            "homeInspectionCj": "",
            "timeSlot": "09:30 - 11:30",
            "paymentStatus": "",
            "secondaryEmail": null,
            "connectedCalls": 0,
            "generateFlag": true,
            "lastCallStatus": "Not called",
            "lastCallTime": null,
            "cc_lms_tag": "",
            "retail_associate_email": "",
            "hi_cj_self_assigned": 0,
            "is_duplicate": "0",
            "homeInspectionCjEmail": ""
          },
          {
            "customerName": "himant",
            "appointmentStatus": "not-verified",
            "storeType": "locality",
            "appointment": "1067667646",
            "followUpMessage": "",
            "currentApptDate": "2021-04-23 11:30:00",
            "stateName": "Scheduled",
            "year": "2020",
            "exophone": "",
            "model": "Glanza",
            "stateId": "1",
            "make": "TOYOTA",
            "customerEmail": "abc@gmail.com",
            "rescheduleUrl": "https://www.cars24.com/appointment/confirmation?l=9LdWjpWL_zEoOb3fu4ncYTRdbs5-9bDD&e=1&utm_medium=reschedule&utm_source=LMS",
            "variant": "G CVT ",
            "zoneName": "HI-East-Pune",
            "customerAddress": "Hadapsar, Pune, Maharashtra, 411028",
            "homeInspectionCj": "",
            "timeSlot": "11:30 - 13:30",
            "paymentStatus": "",
            "secondaryEmail": null,
            "connectedCalls": 0,
            "generateFlag": true,
            "lastCallStatus": "Not called",
            "lastCallTime": null,
            "cc_lms_tag": "",
            "retail_associate_email": "",
            "hi_cj_self_assigned": 0,
            "is_duplicate": "0",
            "homeInspectionCjEmail": ""
          }
        ],
        "totalRows": 2105,
        "notAllocated": 1827,
        "page": "1",
        "columns": [
          {
            "key": "appointment",
            "value": "APP. ID",
            "isVisible": "0",
            "isSortable": 0,
            "controlType": ""
          },
          {
            "key": "customerName",
            "value": "CUST. NAME",
            "isVisible": "0",
            "isSortable": 0,
            "controlType": ""
          },
          {
            "key": "modelVariantYear",
            "value": "MODEL/YEAR/VAR",
            "isVisible": "0",
            "isSortable": 0,
            "controlType": ""
          },
          {
            "key": "customerDetail",
            "value": "CUSTOMER DETAILS",
            "isVisible": "1",
            "isSortable": 0,
            "controlType": ""
          },
          {
            "key": "virtualNo",
            "value": "VIRTUAL NO.",
            "isVisible": "1",
            "isSortable": 0,
            "controlType": ""
          },
          {
            "key": "customerLocation",
            "value": "CUSTOMER LOCATION",
            "isVisible": "1",
            "isSortable": 0,
            "controlType": ""
          },
          {
            "key": "timeSlot",
            "value": "TIME SLOT",
            "isVisible": "1",
            "isSortable": 1,
            "controlType": "dd"
          },
          {
            "key": "retailAssociate",
            "value": "RA",
            "isVisible": "0",
            "isSortable": 1,
            "controlType": "dd"
          },
          {
            "key": "paymentStatus",
            "value": "PAYMENT STATUS",
            "isVisible": "1",
            "isSortable": 0,
            "controlType": "dd",
            "options": [
              {
                "key": "",
                "value": "NONE"
              },
              {
                "key": "SUCCESS",
                "value": "SUCCESS"
              },
              {
                "key": "FAILURE",
                "value": "FAILURE"
              },
              {
                "key": "PENDING",
                "value": "PENDING"
              },
              {
                "key": "CONFIRMED",
                "value": "CONFIRMED"
              },
              {
                "key": "CANCELED",
                "value": "CANCELED"
              }
            ]
          },
          {
            "key": "appointmentStatus",
            "value": "APPOINTMENT STATUS",
            "isVisible": "1",
            "isSortable": 0,
            "controlType": "dd",
            "options": [
              {
                "key": "",
                "value": "NONE"
              },
              {
                "key": "VERIFIED",
                "value": "VERIFIED"
              },
              {
                "key": "NOT-VERIFIED",
                "value": "NOT-VERIFIED"
              }
            ]
          },
          {
            "key": "followUpMessage",
            "value": "Call Remarks",
            "isVisible": "1",
            "isSortable": 0,
            "controlType": "dd"
          },
          {
            "key": "homeInspectionCj",
            "value": "CJ",
            "isVisible": "1",
            "isSortable": 0,
            "controlType": "dd"
          },
          {
            "key": "year",
            "value": "YEAR",
            "isVisible": "0",
            "isSortable": 0,
            "controlType": ""
          },
          {
            "key": "model",
            "value": "MODEL",
            "isVisible": "0",
            "isSortable": 0,
            "controlType": ""
          },
          {
            "key": "customerEmail",
            "value": "CUSTOMER EMAIL",
            "isVisible": "0",
            "isSortable": 0,
            "controlType": ""
          },
          {
            "key": "secondaryEmail",
            "value": "SECONDARY EMAIL",
            "isVisible": "0",
            "controlType": ""
          },
          {
            "key": "rescheduleUrl",
            "value": "RESCHEDULE URL",
            "isVisible": "0",
            "isSortable": 0,
            "controlType": ""
          },
          {
            "key": "variant",
            "value": "VARIANT",
            "isVisible": "0",
            "isSortable": 0,
            "controlType": ""
          },
          {
            "key": "zoneName",
            "value": "ZONE",
            "isVisible": "0",
            "isSortable": 0,
            "controlType": ""
          }
        ],
        "confirmRows": 0,
        "nonConfirmRows": 2105
      }
    }
    
  };
  return response;
};

export const getRAIncentive = () => {
  let response = {
    data: {
      "type": "Ra Incentive Data",
      "title": "Ra Incentive Data",
      "status": 200,
      "detail": {
        "centre": "Delhi Test",
        "cityPerformers": [
          {
            "rank": 1,
            "raName": "Pratik Parikh",
            "targetAchieved": "60",
            "raEmail": "abc@gmail.com",
            "isUser": false
          },
          {
            "rank": 2,
            "raName": "Pratik Parikh",
            "targetAchieved": "60",
            "raEmail": "pratik.parikh@cars24.com",
            "isUser": true
          },
          {
            "rank": 3,
            "raName": "Pratik Parikh",
            "targetAchieved": "60",
            "raEmail": "abc@gmail.com",
            "isUser": false
          },
          {
            "rank": 4,
            "raName": "Pratik Parikh",
            "targetAchieved": "60",
            "raEmail": "abc@gmail.com",
            "isUser": false
          },
          {
            "rank": 5,
            "raName": "Pratik Parikh",
            "targetAchieved": "60",
            "raEmail": "abc@gmail.com",
            "isUser": false
          },
          {
            "rank": 6,
            "raName": "Pratik Parikh",
            "targetAchieved": "60",
            "raEmail": "abc@gmail.com",
            "isUser": false
          },
          {
            "rank": 7,
            "raName": "Pratik Parikh",
            "targetAchieved": "60",
            "raEmail": "abc@gmail.com",
            "isUser": false
          },
        ],
        "targetStatus": {
          "month": "Oct, 2020",
          "targetAchieved": "130",
          "remainingDays": 19,
          "currentProgress": "130",
          "currentTarget": "59",
          "eightyPerTarget": "34",
          "hunderedPerTarget": "43",
          "currentIncentive": "25700",
          "eightyPerIncentive": "8500",
          "hunderedPerIncentive": "10750",
          "carsCurrentToEightyPercent": 0,
          "carsCurrentToHunderedPercent": 9,
          "carsEightyToHunderedPercent": 9,
          "checkinDenied": "3",
          "inspectionDenied": "29",
          "docketRating": "7",
          "inspectionMiss": "3"
        }
      }
    }
  };
  return response;
};
export const geListOtpMoc = () => {
  let response = {
    data: {
      "type": "C2C list OTP",
      "title": "Otp send successfully",
      "status": 200,
      "detail": {
        "success": true,
        "make": "HYUNDAI",
        "model": "EON",
        "year": 2015,
        "customerName": "SellerName",
        "customerPhone": "9999999999"
      }
    }
  };
  return response;
};
export const getInspectionReviewMoc = () => {
  let response = {
    data: {
      "type": "Review-inspection",
      "title": "Review Inspection Leads",
      "status": 200,
      "detail": {
        "result": [
          {
            "appointment": "1034835414",
            "customerName": "Customer",
            "make": "MARUTI SUZUKI",
            "model": "SWIFT",
            "year": "2015",
            "variant": "VDI Glory edition",
            "evaluatorName": "android@cars24.com",
            "data": {
              "carCondition": {
                "parameters": {
                  "Car pulling on side": {
                    "lowerChancesMessage": "1% lower chances of achieving target price"
                  },
                  "Exhaust Smoke": {
                    "lowerChancesMessage": "3% lower chances of achieving target price",
                    "message": "You have reported Exhaust Smoke as Black",
                    "value": "No"
                  }
                }
              },
              "chancesOfachievingTargetPrice": 2,
              "documentCondition": {
                "Duplicate Key": "No",
                "Insurance Type": "Insurance Expired",
                "Insurance Expiry Date": "2020/11/20"
              },
              "noOfPanels": 5,
              "totalImapctOnHBTP": 8,
              "videoUrl": "inspection/2020/12/7/CD633C5/v0/engineTransmission/additionalInfo/exhaustSmoke/exhaustsmokeadditionalinfoform2_1607322913.mp4"
            },
            "version": "v2"
          }
        ],
        "totalRows": 1,
        "columns": [
          {
            "key": "appointment",
            "value": "APP. ID",
            "isVisible": "1"
          },
          {
            "key": "customerName",
            "value": "CUST. NAME",
            "isVisible": "1"
          },
          {
            "key": "modelVariantYear",
            "value": "MODEL/YEAR/VAR",
            "isVisible": "1"
          },
          {
            "key": "evaluatorName",
            "value": "DONE BY CJ",
            "isVisible": "1"
          },
          {
            "key": "reviewReport",
            "value": "ACTION",
            "isVisible": "1"
          }
        ],
        "insuranceType": [
          "Zero Depreciation",
          "Comprehensive",
          "Insurance Expired",
          "3rd Party"
        ]
      }
    }

  };
  return response;
};

export const getMocYear = () => {
  let response = {
    data: {
      "name": "Variant Year List",
      "type": "Success",
      "message": "Variant year list found",
      "status": "200",
      "detail": [
        {
          "year_id": 17333,
          "year": "2018"
        },
        {
          "year_id": 14460,
          "year": "2017"
        },
        {
          "year_id": 12705,
          "year": "2016"
        },
        {
          "year_id": 805,
          "year": "2015"
        },
        {
          "year_id": 2318,
          "year": "2014"
        },
        {
          "year_id": 3676,
          "year": "2013"
        },
        {
          "year_id": 4800,
          "year": "2012"
        }
      ]
    }
  };
};
export const verifyOTPMoc = () => {
  let response = {
    data: {
      "type": "Validate-Dashboard",
      "title": "Validate waittime dashboard",
      "status": 200,
      "detail": {
        "success": true,
        "storeName": "Janakpuri",
        "storeConfig": {
          "screenSplitTime": 7000,
          "ad_split_time": 30000,
          "ad_time_out": 20000,
          "displayAdv": true,
          "advUrl": "https://screeno.locad.net/screeno-player/?pairing_code=a1d54"
        }
      }
    }

  };
  return response;
};

export const getPaymentProcessingMoc = () => {
  let response = {
    data: {
      "type": "Payment Processing",
      "title": "Payment Processing List",
      "status": 200,
      "detail": {
        "totalRows": 41,
        "page": "1",
        "result": [
          {
            "orderId": 358946,
            "appointment": "1007695812",
            "customerName": "ABC",
            "model": "EON",
            "year": 2015,
            "variant": "ERA PLUS",
            "pickupStatus": 0,
            "pickUpRequestStatus": "Pickup Pending",
            "homeInspectionCj": null,
            "isReInspectionDone": 0,
            "customerAddress": "",
            "storeType": "store",
            "prSubmittedDate": "2020-09-01 17:22:35",
            "virtualNumber": "9911867544",
            "orderPurchaseRequestId": 71877,
            "qcStatus": "Approved",
            "qcStatusPP": 3,
            "adminSummaryUrl": "http://admin-staging.24c.in/orders/purchase-request/summary/71877",
            "qcPendingDate": "",
            "lastPaymentStatus": "",
            "daysDealLost": "",
            "adminUrl": "http://admin-staging.24c.in/orders/payment-instruction/71877",
            "prStatusSection": {
              "status": "PR APPROVED",
              "statusTag": "RETAIL_PR_APPROVED",
              "isBidRejected": false,
              "isHvb": true
            },
            "paymentStatusSection": {
              "stage1": "PR Submitted",
              "stage2": "Waiting for Token",
              "stage1Success": true,
              "stage2Success": true
            },
            "carStatusSection": {
              "daysToDealLost": {
                "daysSinceBought": "6",
                "todayDaysAllowed": "13"
              },
              "storeVisibility": "Retail",
              "stages": {
                "stage1": "Stock-in Pending",
                "stage2": "",
                "stage1Success": true,
                "stage2Success": true
              }
            },
            "isResendOtpApplicable": true
          },
          {
            "orderId": 358954,
            "appointment": "1007655812",
            "customerName": "Thanksggggfgggggggggg",
            "model": "EON",
            "year": 2015,
            "variant": "ERA PLUS",
            "pickupStatus": 4,
            "pickUpRequestStatus": "",
            "homeInspectionCj": null,
            "isReInspectionDone": 0,
            "customerAddress": "",
            "storeType": "store",
            "prSubmittedDate": "2020-09-01 17:22:35",
            "virtualNumber": null,
            "orderPurchaseRequestId": 71879,
            "qcStatus": "Approved",
            "qcStatusPP": 3,
            "adminSummaryUrl": "http://admin-staging.24c.in/orders/purchase-request/summary/71879",
            "qcPendingDate": "",
            "lastPaymentStatus": "",
            "daysDealLost": "",
            "adminUrl": "http://admin-staging.24c.in/orders/payment-instruction/71879",
            "prStatusSection": {
              "status": "PR APPROVED",
              "statusTag": "RETAIL_PR_APPROVED",
              "isBidRejected": false,
              "isHvb": true
            },
            "paymentStatusSection": {
              "stage1": "Pickup Request submitted",
              "stage2": "Waiting for PI Raise",
              "stage1Success": true,
              "stage2Success": true
            },
            "carStatusSection": {
              "daysToDealLost": {
                "daysSinceBought": "6",
                "todayDaysAllowed": "13"
              },
              "storeVisibility": "Retail",
              "stages": {
                "stage1": "Stocked-in",
                "stage2": "",
                "stage1Success": true,
                "stage2Success": true
              }
            },
            "isResendOtpApplicable": true
          },
          {
            "orderId": 358960,
            "appointment": "1017665812",
            "customerName": "Alisha",
            "model": "INNOVA CRYSTA",
            "year": 2018,
            "variant": "2.7 GX AT 8 STR",
            "pickupStatus": 4,
            "pickUpRequestStatus": "",
            "homeInspectionCj": null,
            "isReInspectionDone": 0,
            "customerAddress": "",
            "storeType": "store",
            "prSubmittedDate": "2020-09-01 17:22:35",
            "virtualNumber": null,
            "orderPurchaseRequestId": 71884,
            "qcStatus": "Approved",
            "qcStatusPP": 3,
            "adminSummaryUrl": "http://admin-staging.24c.in/orders/purchase-request/summary/71884",
            "qcPendingDate": "",
            "lastPaymentStatus": "Delivery Amount",
            "daysDealLost": "1 days",
            "adminUrl": "http://admin-staging.24c.in/orders/payment-instruction/71884",
            "prStatusSection": {
              "status": "PR APPROVED",
              "statusTag": "RETAIL_PR_APPROVED",
              "isBidRejected": false,
              "isHvb": true
            },
            "paymentStatusSection": {
              "stage1": "PI Approved",
              "stage2": "Payment Done",
              "stage1Success": true,
              "stage2Success": true,
              "loan": [

              ]
            },
            "carStatusSection": {
              "daysToDealLost": {
                "daysSinceBought": "5",
                "todayDaysAllowed": "13"
              },
              "storeVisibility": "Retail",
              "stages": {
                "stage1": "Stocked-in",
                "stage2": "",
                "stage1Success": true,
                "stage2Success": true
              }
            },
            "isResendOtpApplicable": true
          },
          {
            "orderId": 359010,
            "appointment": "1003685812",
            "customerName": "Thanksggggfgggggggggg",
            "model": "EON",
            "year": 2015,
            "variant": "ERA PLUS",
            "pickupStatus": 4,
            "pickUpRequestStatus": "",
            "homeInspectionCj": null,
            "isReInspectionDone": 0,
            "customerAddress": "",
            "storeType": "store",
            "prSubmittedDate": "2020-09-01 17:22:35",
            "virtualNumber": null,
            "orderPurchaseRequestId": 71894,
            "qcStatus": "Approved",
            "qcStatusPP": 3,
            "adminSummaryUrl": "http://admin-staging.24c.in/orders/purchase-request/summary/71894",
            "qcPendingDate": "",
            "lastPaymentStatus": "Delivery Amount",
            "daysDealLost": "",
            "adminUrl": "http://admin-staging.24c.in/orders/payment-instruction/71894",
            "prStatusSection": {
              "status": "PR APPROVED",
              "statusTag": "RETAIL_PR_APPROVED",
              "isBidRejected": false,
              "isHvb": true
            },
            "paymentStatusSection": {
              "stage1": "PI Approved",
              "stage2": "Payment Done",
              "stage1Success": true,
              "stage2Success": true
            },
            "carStatusSection": {
              "daysToDealLost": {
                "daysSinceBought": "5",
                "todayDaysAllowed": "13"
              },
              "storeVisibility": "Retail",
              "stages": {
                "stage1": "Stocked-in",
                "stage2": "",
                "stage1Success": true,
                "stage2Success": true
              }
            },
            "isResendOtpApplicable": true
          },
          {
            "orderId": 359024,
            "appointment": "1002655812",
            "customerName": "Thanksggggfgggggggggg",
            "model": "BOLT",
            "year": 2015,
            "variant": "XE",
            "pickupStatus": 4,
            "pickUpRequestStatus": "",
            "homeInspectionCj": null,
            "isReInspectionDone": 0,
            "customerAddress": "",
            "storeType": "store",
            "prSubmittedDate": "2020-09-01 17:22:35",
            "virtualNumber": null,
            "orderPurchaseRequestId": 71893,
            "qcStatus": "Approved",
            "qcStatusPP": 3,
            "adminSummaryUrl": "http://admin-staging.24c.in/orders/purchase-request/summary/71893",
            "qcPendingDate": "",
            "lastPaymentStatus": "Delivery Amount",
            "daysDealLost": "",
            "adminUrl": "http://admin-staging.24c.in/orders/payment-instruction/71893",
            "prStatusSection": {
              "status": "PR APPROVED",
              "statusTag": "RETAIL_PR_APPROVED",
              "isBidRejected": false,
              "isHvb": true
            },
            "paymentStatusSection": {
              "stage1": "PI Approved",
              "stage2": "Payment Done",
              "stage1Success": true,
              "stage2Success": true
            },
            "carStatusSection": {
              "daysToDealLost": {
                "daysSinceBought": "5",
                "todayDaysAllowed": "13"
              },
              "storeVisibility": "Retail",
              "stages": {
                "stage1": "Stocked-in",
                "stage2": "",
                "stage1Success": true,
                "stage2Success": true
              }
            },
            "isResendOtpApplicable": true
          },
          {
            "orderId": 359030,
            "appointment": "1002625812",
            "customerName": "Thanksggggfgggggggggg",
            "model": "BOLT",
            "year": 2015,
            "variant": "XE",
            "pickupStatus": null,
            "pickUpRequestStatus": "",
            "homeInspectionCj": null,
            "isReInspectionDone": 0,
            "customerAddress": "",
            "storeType": "store",
            "prSubmittedDate": "2020-09-01 17:22:35",
            "virtualNumber": null,
            "orderPurchaseRequestId": 71892,
            "qcStatus": "Approved",
            "qcStatusPP": 3,
            "adminSummaryUrl": "http://admin-staging.24c.in/orders/purchase-request/summary/71892",
            "qcPendingDate": "",
            "lastPaymentStatus": "",
            "daysDealLost": "",
            "adminUrl": "http://admin-staging.24c.in/orders/payment-instruction/71892",
            "prStatusSection": {
              "status": "PR APPROVED",
              "statusTag": "RETAIL_PR_APPROVED",
              "isBidRejected": false,
              "isHvb": true
            },
            "paymentStatusSection": {
              "stage1": "PR Submitted",
              "stage2": "Waiting for Token",
              "stage1Success": true,
              "stage2Success": true
            },
            "carStatusSection": {
              "daysToDealLost": {
                "daysSinceBought": "5",
                "todayDaysAllowed": "13"
              },
              "storeVisibility": "Retail",
              "stages": {
                "stage1": "Stock-in Pending",
                "stage2": "",
                "stage1Success": true,
                "stage2Success": true
              }
            }
          },
          {
            "orderId": 359040,
            "appointment": "1004635812",
            "customerName": "Thanksggggfgggggggggg",
            "model": "EON",
            "year": 2015,
            "variant": "ERA PLUS",
            "pickupStatus": 0,
            "pickUpRequestStatus": "Pickup Pending",
            "homeInspectionCj": null,
            "isReInspectionDone": 0,
            "customerAddress": "",
            "storeType": "store",
            "prSubmittedDate": "2020-09-01 17:22:35",
            "virtualNumber": null,
            "orderPurchaseRequestId": 71897,
            "qcStatus": "Approved",
            "qcStatusPP": 3,
            "adminSummaryUrl": "http://admin-staging.24c.in/orders/purchase-request/summary/71897",
            "qcPendingDate": "",
            "lastPaymentStatus": "",
            "daysDealLost": "",
            "adminUrl": "http://admin-staging.24c.in/orders/payment-instruction/71897",
            "prStatusSection": {
              "status": "PR APPROVED",
              "statusTag": "RETAIL_PR_APPROVED",
              "isBidRejected": false,
              "isHvb": true
            },
            "paymentStatusSection": {
              "stage1": "PI Approved",
              "stage2": "Payment in 48 hours",
              "stage1Success": true,
              "stage2Success": true
            },
            "carStatusSection": {
              "daysToDealLost": {
                "daysSinceBought": "5"
              },
              "storeVisibility": "Retail",
              "stages": {
                "stage1": "Stock-in Pending",
                "stage2": "",
                "stage1Success": true,
                "stage2Success": true
              }
            }
          },
          {
            "orderId": 359044,
            "appointment": "1004605812",
            "customerName": "Thanksggggfgggggggggg",
            "model": "7 SERIES",
            "year": 2003,
            "variant": "ACTIVE HYBRID",
            "pickupStatus": 0,
            "pickUpRequestStatus": "Pickup Pending",
            "homeInspectionCj": null,
            "isReInspectionDone": 0,
            "customerAddress": "",
            "storeType": "store",
            "prSubmittedDate": "2020-09-01 17:22:35",
            "virtualNumber": null,
            "orderPurchaseRequestId": 71907,
            "qcStatus": "Approved",
            "qcStatusPP": 3,
            "adminSummaryUrl": "http://admin-staging.24c.in/orders/purchase-request/summary/71907",
            "qcPendingDate": "",
            "lastPaymentStatus": "Token",
            "daysDealLost": "1 days",
            "adminUrl": "http://admin-staging.24c.in/orders/payment-instruction/71907",
            "prStatusSection": {
              "status": "PR APPROVED",
              "statusTag": "RETAIL_PR_APPROVED",
              "isBidRejected": false,
              "isHvb": true
            },
            "paymentStatusSection": {
              "stage1": "PI Approved",
              "stage2": "Payment in 48 hours",
              "stage1Success": true,
              "stage2Success": true
            },
            "carStatusSection": {
              "daysToDealLost": {
                "daysSinceBought": "5",
                "todayDaysAllowed": "13"
              },
              "storeVisibility": "Retail",
              "stages": {
                "stage1": "Stock-in Pending",
                "stage2": "",
                "stage1Success": true,
                "stage2Success": true
              }
            }
          },
          {
            "orderId": 359050,
            "appointment": "1006675812",
            "customerName": "Thanksggggfgggggggggg",
            "model": "EON",
            "year": 2015,
            "variant": "ERA PLUS",
            "pickupStatus": 4,
            "pickUpRequestStatus": "",
            "homeInspectionCj": null,
            "isReInspectionDone": 0,
            "customerAddress": "",
            "storeType": "locality",
            "prSubmittedDate": "2020-09-01 17:22:35",
            "virtualNumber": null,
            "orderPurchaseRequestId": 71898,
            "qcStatus": "Approved",
            "qcStatusPP": 3,
            "adminSummaryUrl": "http://admin-staging.24c.in/orders/purchase-request/summary/71898",
            "qcPendingDate": "",
            "lastPaymentStatus": "",
            "daysDealLost": "",
            "adminUrl": "http://admin-staging.24c.in/orders/payment-instruction/71898",
            "prStatusSection": {
              "status": "PR APPROVED",
              "statusTag": "RETAIL_PR_APPROVED",
              "isBidRejected": false,
              "isHvb": true
            },
            "paymentStatusSection": {
              "stage1": "PI Approved",
              "stage2": "Payment in 48 hours",
              "stage1Success": true,
              "stage2Success": true
            },
            "carStatusSection": {
              "daysToDealLost": {
                "daysSinceBought": "5",
                "todayDaysAllowed": "13"
              },
              "storeVisibility": "HI",
              "stages": {
                "stage1": "Pickup Request Submitted",
                "stage2": "Pickup Pending 2020-08-27 00:00:00",
                "stage1Success": true,
                "stage2Success": true
              }
            }
          },
          {
            "orderId": 359114,
            "appointment": "1004635312",
            "customerName": "rITESH",
            "model": "EON",
            "year": 2015,
            "variant": "ERA PLUS",
            "pickupStatus": 0,
            "pickUpRequestStatus": "Pickup Pending",
            "homeInspectionCj": null,
            "isReInspectionDone": 0,
            "customerAddress": "",
            "storeType": "store",
            "prSubmittedDate": "2020-09-01 17:22:35",
            "virtualNumber": null,
            "orderPurchaseRequestId": 71941,
            "qcStatus": "Approved",
            "qcStatusPP": 3,
            "adminSummaryUrl": "http://admin-staging.24c.in/orders/purchase-request/summary/71941",
            "qcPendingDate": "",
            "lastPaymentStatus": "Delivery Amount",
            "daysDealLost": "4 days",
            "adminUrl": "http://admin-staging.24c.in/orders/payment-instruction/71941",
            "prStatusSection": {
              "status": "PR APPROVED",
              "statusTag": "RETAIL_PR_APPROVED",
              "isBidRejected": true,
              "isHvb": false
            },
            "paymentStatusSection": {
              "stage1": "PI Approved",
              "stage2": "Payment Done",
              "stage1Success": true,
              "stage2Success": true
            },
            "carStatusSection": {
              "daysToDealLost": {
                "daysSinceBought": "2",
                "todayDaysAllowed": "13"
              },
              "storeVisibility": "Retail",
              "stages": {
                "stage1": "Stock-in Pending",
                "stage2": "",
                "stage1Success": true,
                "stage2Success": true
              }
            }
          },
          {
            "orderId": 359126,
            "appointment": "1000685312",
            "customerName": "Thanksggggfgggggggggg",
            "model": "EON",
            "year": 2015,
            "variant": "ERA PLUS",
            "pickupStatus": 0,
            "pickUpRequestStatus": "Pickup Pending",
            "homeInspectionCj": null,
            "isReInspectionDone": 0,
            "customerAddress": "",
            "storeType": "store",
            "prSubmittedDate": "2020-09-01 17:22:35",
            "virtualNumber": null,
            "orderPurchaseRequestId": 71920,
            "qcStatus": "Approved",
            "qcStatusPP": 3,
            "adminSummaryUrl": "http://admin-staging.24c.in/orders/purchase-request/summary/71920",
            "qcPendingDate": "",
            "lastPaymentStatus": "Token",
            "daysDealLost": "2 days",
            "adminUrl": "http://admin-staging.24c.in/orders/payment-instruction/71920",
            "prStatusSection": {
              "status": "PR APPROVED",
              "statusTag": "RETAIL_PR_APPROVED",
              "isBidRejected": true,
              "isHvb": false
            },
            "paymentStatusSection": {
              "stage1": "PI Approved",
              "stage2": "Payment Done",
              "stage1Success": true,
              "stage2Success": true
            },
            "carStatusSection": {
              "daysToDealLost": {
                "daysSinceBought": "4",
                "todayDaysAllowed": "13"
              },
              "storeVisibility": "Retail",
              "stages": {
                "stage1": "Stock-in Pending",
                "stage2": "",
                "stage1Success": true,
                "stage2Success": true
              }
            }
          },
          {
            "orderId": 359134,
            "appointment": "1006695312",
            "customerName": "Thanksggggfgggggggggg",
            "model": "EON",
            "year": 2015,
            "variant": "ERA PLUS",
            "pickupStatus": 0,
            "pickUpRequestStatus": "Pickup Pending",
            "homeInspectionCj": null,
            "isReInspectionDone": 0,
            "customerAddress": "",
            "storeType": "store",
            "prSubmittedDate": "2020-09-01 17:22:35",
            "virtualNumber": null,
            "orderPurchaseRequestId": 71923,
            "qcStatus": "Approved",
            "qcStatusPP": 3,
            "adminSummaryUrl": "http://admin-staging.24c.in/orders/purchase-request/summary/71923",
            "qcPendingDate": "",
            "lastPaymentStatus": "Delivery Amount",
            "daysDealLost": "",
            "adminUrl": "http://admin-staging.24c.in/orders/payment-instruction/71923",
            "prStatusSection": {
              "status": "PR APPROVED",
              "statusTag": "RETAIL_PR_APPROVED",
              "isBidRejected": false,
              "isHvb": true
            },
            "paymentStatusSection": {
              "stage1": "PI Approved",
              "stage2": "Payment Done",
              "stage1Success": true,
              "stage2Success": true,
              "loan": {
                "stage1": "PI Approved",
                "stage2": "Payment in 48 hours",
                "stage1Success": true,
                "stage2Success": true
              }
            },
            "carStatusSection": {
              "daysToDealLost": {
                "daysSinceBought": "4",
                "todayDaysAllowed": "13"
              },
              "storeVisibility": "Retail",
              "stages": {
                "stage1": "Stock-in Pending",
                "stage2": "",
                "stage1Success": true,
                "stage2Success": true
              }
            }
          },
          {
            "orderId": 359155,
            "appointment": "1009685212",
            "customerName": "Thanksggggfgggggggggg",
            "model": "BOLT",
            "year": 2015,
            "variant": "XE",
            "pickupStatus": -1,
            "pickUpRequestStatus": "Cancelled",
            "homeInspectionCj": null,
            "isReInspectionDone": 0,
            "customerAddress": "",
            "storeType": "store",
            "prSubmittedDate": "2020-09-01 17:22:35",
            "virtualNumber": null,
            "orderPurchaseRequestId": 71942,
            "qcStatus": "Approved",
            "qcStatusPP": 3,
            "adminSummaryUrl": "http://admin-staging.24c.in/orders/purchase-request/summary/71942",
            "qcPendingDate": "",
            "lastPaymentStatus": "",
            "daysDealLost": "",
            "adminUrl": "http://admin-staging.24c.in/orders/payment-instruction/71942",
            "prStatusSection": {
              "status": "PR APPROVED",
              "statusTag": "RETAIL_PR_APPROVED",
              "isBidRejected": false,
              "isHvb": true
            },
            "paymentStatusSection": {
              "stage1": "PI Approved",
              "stage2": "Payment in 48 hours",
              "stage1Success": true,
              "stage2Success": true
            },
            "carStatusSection": {
              "daysToDealLost": {
                "daysSinceBought": "2",
                "todayDaysAllowed": "13"
              },
              "storeVisibility": "Retail",
              "stages": {
                "stage1": "Stocked-in",
                "stage2": "",
                "stage1Success": true,
                "stage2Success": true
              }
            }
          },
          {
            "orderId": 359157,
            "appointment": "1009635212",
            "customerName": "Thanksggggfgggggggggg",
            "model": "EON",
            "year": 2015,
            "variant": "ERA PLUS",
            "pickupStatus": 0,
            "pickUpRequestStatus": "Pickup Pending",
            "homeInspectionCj": null,
            "isReInspectionDone": 0,
            "customerAddress": "",
            "storeType": "store",
            "prSubmittedDate": "2020-09-01 17:22:35",
            "virtualNumber": null,
            "orderPurchaseRequestId": 71945,
            "qcStatus": "Approved",
            "qcStatusPP": 3,
            "adminSummaryUrl": "http://admin-staging.24c.in/orders/purchase-request/summary/71945",
            "qcPendingDate": "",
            "lastPaymentStatus": "",
            "daysDealLost": "",
            "adminUrl": "http://admin-staging.24c.in/orders/payment-instruction/71945",
            "prStatusSection": {
              "status": "PR APPROVED",
              "statusTag": "RETAIL_PR_APPROVED",
              "isBidRejected": false,
              "isHvb": true
            },
            "paymentStatusSection": {
              "stage1": "PI Approved",
              "stage2": "Payment in 48 hours",
              "stage1Success": true,
              "stage2Success": true
            },
            "carStatusSection": {
              "daysToDealLost": {
                "daysSinceBought": "2",
                "todayDaysAllowed": "13"
              },
              "storeVisibility": "Retail",
              "stages": {
                "stage1": "Stock-in Pending",
                "stage2": "",
                "stage1Success": true,
                "stage2Success": true
              }
            }
          },
          {
            "orderId": 359159,
            "appointment": "1009645212",
            "customerName": "Thanksggggfgggggggggg",
            "model": "EON",
            "year": 2015,
            "variant": "ERA PLUS",
            "pickupStatus": 1,
            "pickUpRequestStatus": "Assigned",
            "homeInspectionCj": null,
            "isReInspectionDone": 0,
            "customerAddress": "",
            "storeType": "store",
            "prSubmittedDate": "2020-09-01 17:22:35",
            "virtualNumber": null,
            "orderPurchaseRequestId": 71974,
            "qcStatus": "Approved",
            "qcStatusPP": 3,
            "adminSummaryUrl": "http://admin-staging.24c.in/orders/purchase-request/summary/71974",
            "qcPendingDate": "",
            "lastPaymentStatus": "",
            "daysDealLost": "",
            "adminUrl": "http://admin-staging.24c.in/orders/payment-instruction/71974",
            "prStatusSection": {
              "status": "PR APPROVED",
              "statusTag": "RETAIL_PR_APPROVED",
              "isBidRejected": false,
              "isHvb": true
            },
            "paymentStatusSection": {
              "stage1": "Pickup Request submitted",
              "stage2": "Waiting for PI Raise",
              "stage1Success": true,
              "stage2Success": true
            },
            "carStatusSection": {
              "daysToDealLost": {
                "daysSinceBought": "0",
                "todayDaysAllowed": "13"
              },
              "storeVisibility": "Retail",
              "stages": {
                "stage1": "Stocked-in",
                "stage2": "",
                "stage1Success": true,
                "stage2Success": true
              }
            }
          },
          {
            "orderId": 359171,
            "appointment": "1007655212",
            "customerName": "Thanksggggfgggggggggg",
            "model": "EON",
            "year": 2015,
            "variant": "ERA PLUS",
            "pickupStatus": -1,
            "pickUpRequestStatus": "Cancelled",
            "homeInspectionCj": null,
            "isReInspectionDone": 0,
            "customerAddress": "",
            "storeType": "store",
            "prSubmittedDate": "2020-09-01 17:22:35",
            "virtualNumber": null,
            "orderPurchaseRequestId": 71943,
            "qcStatus": "Approved",
            "qcStatusPP": 3,
            "adminSummaryUrl": "http://admin-staging.24c.in/orders/purchase-request/summary/71943",
            "qcPendingDate": "",
            "lastPaymentStatus": "",
            "daysDealLost": "",
            "adminUrl": "http://admin-staging.24c.in/orders/payment-instruction/71943",
            "prStatusSection": {
              "status": "PR APPROVED",
              "statusTag": "RETAIL_PR_APPROVED",
              "isBidRejected": false,
              "isHvb": true
            },
            "paymentStatusSection": {
              "stage1": "PI Approved",
              "stage2": "Payment in 48 hours",
              "stage1Success": true,
              "stage2Success": true
            },
            "carStatusSection": {
              "daysToDealLost": {
                "daysSinceBought": "2",
                "todayDaysAllowed": "16"
              },
              "storeVisibility": "Retail",
              "stages": {
                "stage1": "Stocked-in",
                "stage2": "",
                "stage1Success": true,
                "stage2Success": true
              }
            }
          },
          {
            "orderId": 359173,
            "appointment": "1007685212",
            "customerName": "Thanksggggfgggggggggg",
            "model": "EON",
            "year": 2015,
            "variant": "ERA PLUS",
            "pickupStatus": 1,
            "pickUpRequestStatus": "Assigned",
            "homeInspectionCj": null,
            "isReInspectionDone": 0,
            "customerAddress": "",
            "storeType": "store",
            "prSubmittedDate": "2020-09-01 17:22:35",
            "virtualNumber": null,
            "orderPurchaseRequestId": 71939,
            "qcStatus": "Approved",
            "qcStatusPP": 3,
            "adminSummaryUrl": "http://admin-staging.24c.in/orders/purchase-request/summary/71939",
            "qcPendingDate": "",
            "lastPaymentStatus": "",
            "daysDealLost": "",
            "adminUrl": "http://admin-staging.24c.in/orders/payment-instruction/71939",
            "prStatusSection": {
              "status": "PR APPROVED",
              "statusTag": "RETAIL_PR_APPROVED",
              "isBidRejected": false,
              "isHvb": true
            },
            "paymentStatusSection": {
              "stage1": "PI Approved",
              "stage2": "Payment in 48 hours",
              "stage1Success": true,
              "stage2Success": true
            },
            "carStatusSection": {
              "daysToDealLost": {
                "daysSinceBought": "2",
                "todayDaysAllowed": "13"
              },
              "storeVisibility": "Retail",
              "stages": {
                "stage1": "Stocked-in",
                "stage2": "",
                "stage1Success": true,
                "stage2Success": true
              }
            }
          },
          {
            "orderId": 359179,
            "appointment": "1007645212",
            "customerName": "Thanksggggfgggggggggg",
            "model": "EON",
            "year": 2015,
            "variant": "ERA PLUS",
            "pickupStatus": 0,
            "pickUpRequestStatus": "Pickup Pending",
            "homeInspectionCj": null,
            "isReInspectionDone": 0,
            "customerAddress": "",
            "storeType": "store",
            "prSubmittedDate": "2020-09-01 17:22:35",
            "virtualNumber": null,
            "orderPurchaseRequestId": 71935,
            "qcStatus": "Approved",
            "qcStatusPP": 3,
            "adminSummaryUrl": "http://admin-staging.24c.in/orders/purchase-request/summary/71935",
            "qcPendingDate": "",
            "lastPaymentStatus": "",
            "daysDealLost": "",
            "adminUrl": "http://admin-staging.24c.in/orders/payment-instruction/71935",
            "prStatusSection": {
              "status": "PR APPROVED",
              "statusTag": "RETAIL_PR_APPROVED",
              "isBidRejected": false,
              "isHvb": true
            },
            "paymentStatusSection": {
              "stage1": "PI Approved",
              "stage2": "Payment in 48 hours",
              "stage1Success": true,
              "stage2Success": true
            },
            "carStatusSection": {
              "daysToDealLost": {
                "daysSinceBought": "2",
                "todayDaysAllowed": "13"
              },
              "storeVisibility": "Retail",
              "stages": {
                "stage1": "Stock-in Pending",
                "stage2": "",
                "stage1Success": true,
                "stage2Success": true
              }
            }
          },
          {
            "orderId": 359181,
            "appointment": "1007605212",
            "customerName": "Thanksggggfgggggggggg",
            "model": "EON",
            "year": 2015,
            "variant": "ERA PLUS",
            "pickupStatus": -1,
            "pickUpRequestStatus": "Cancelled",
            "homeInspectionCj": null,
            "isReInspectionDone": 0,
            "customerAddress": "",
            "storeType": "store",
            "prSubmittedDate": "2020-09-01 17:22:35",
            "virtualNumber": null,
            "orderPurchaseRequestId": 71933,
            "qcStatus": "Approved",
            "qcStatusPP": 3,
            "adminSummaryUrl": "http://admin-staging.24c.in/orders/purchase-request/summary/71933",
            "qcPendingDate": "",
            "lastPaymentStatus": "",
            "daysDealLost": "",
            "adminUrl": "http://admin-staging.24c.in/orders/payment-instruction/71933",
            "prStatusSection": {
              "status": "PR APPROVED",
              "statusTag": "RETAIL_PR_APPROVED",
              "isBidRejected": false,
              "isHvb": true
            },
            "paymentStatusSection": {
              "stage1": "PI Approved",
              "stage2": "Payment in 48 hours",
              "stage1Success": true,
              "stage2Success": true
            },
            "carStatusSection": {
              "daysToDealLost": {
                "daysSinceBought": "2",
                "todayDaysAllowed": "13"
              },
              "storeVisibility": "Retail",
              "stages": {
                "stage1": "Stocked-in",
                "stage2": "",
                "stage1Success": true,
                "stage2Success": true
              }
            }
          },
          {
            "orderId": 359189,
            "appointment": "1001635212",
            "customerName": "Thanksggggfgggggggggg",
            "model": "EON",
            "year": 2015,
            "variant": "ERA PLUS",
            "pickupStatus": 0,
            "pickUpRequestStatus": "Pickup Pending",
            "homeInspectionCj": null,
            "isReInspectionDone": 0,
            "customerAddress": "",
            "storeType": "store",
            "prSubmittedDate": "2020-09-01 17:22:35",
            "virtualNumber": null,
            "orderPurchaseRequestId": 71926,
            "qcStatus": "Approved",
            "qcStatusPP": 3,
            "adminSummaryUrl": "http://admin-staging.24c.in/orders/purchase-request/summary/71926",
            "qcPendingDate": "",
            "lastPaymentStatus": "Delivery Amount",
            "daysDealLost": "",
            "adminUrl": "http://admin-staging.24c.in/orders/payment-instruction/71926",
            "prStatusSection": {
              "status": "PR APPROVED",
              "statusTag": "RETAIL_PR_APPROVED",
              "isBidRejected": false,
              "isHvb": true
            },
            "paymentStatusSection": {
              "stage1": "PI Approved",
              "stage2": "Payment Done",
              "stage1Success": true,
              "stage2Success": true
            },
            "carStatusSection": {
              "daysToDealLost": {
                "daysSinceBought": "3",
                "todayDaysAllowed": "13"
              },
              "storeVisibility": "Retail",
              "stages": {
                "stage1": "Stock-in Pending",
                "stage2": "",
                "stage1Success": true,
                "stage2Success": true
              }
            }
          }
        ],
        "columns": [
          {
            "key": "customerDetail",
            "value": "CUSTOMER DETAILS",
            "isVisible": "1"
          },
          {
            "key": "prStatus",
            "value": "PR STATUS",
            "isVisible": "1"
          },
          {
            "key": "deliveryPaymentLoans",
            "value": "DELIVERY PAYMENT & LOANS",
            "isVisible": "1",
            "controlType": "dd",
            "options": [
              {
                "key": "",
                "value": "None"
              },
              {
                "key": "rejected-purchase",
                "value": "PR Rejected"
              },
              {
                "key": "token-only",
                "value": "Token Cars Only"
              },
              {
                "key": "pi-pending",
                "value": "PI Pending"
              },
              {
                "key": "pi-failed",
                "value": "PI Failed"
              }
            ]
          },
          {
            "key": "vehicleStatus",
            "value": "VEHICLE STATUS",
            "isVisible": "1"
          }
        ]
      }
    }
  };
  return response;
};

export const getClearancePendingData = () => {
  const response = {
    data: {
      "type": "Clearance Pending",
      "title": "Clearance Pending List",
      "status": 200,
      "detail": {
        "totalRows": 399,
        "page": "1",
        "result": [
          {
            "orderId": 4313662,
            "appointment": "10158112774",
            "customerName": "ANAND SINGH  BALYAN",
            "model": "Zen Estilo",
            "year": 2008,
            "variant": "LX",
            "postSaleRcStatus": "Dealer Inventory",
            "pendingHoldback": "",
            "storeType": "locality",
            "orderPurchaseRequestId": "2245851",
            "adminUrl": "http://admin.24c.in/orders/payment-instruction/2245851"
          },
          {
            "orderId": 4313677,
            "appointment": "10113214776",
            "customerName": "MR PURUSHOTTAM SHARMA",
            "model": "Baleno",
            "year": 2015,
            "variant": "DELTA PETROL 1.2",
            "postSaleRcStatus": "Dealer Inventory",
            "pendingHoldback": "",
            "storeType": "locality",
            "orderPurchaseRequestId": "2217251",
            "adminUrl": "http://admin.24c.in/orders/payment-instruction/2217251"
          },
          {
            "orderId": 4313883,
            "appointment": "10103614771",
            "customerName": "MANOJ KUMAR",
            "model": "SANTRO XING",
            "year": 2012,
            "variant": "GL PLUS",
            "postSaleRcStatus": "Dealer Inventory",
            "pendingHoldback": "",
            "storeType": "locality",
            "orderPurchaseRequestId": "2219928",
            "adminUrl": "http://admin.24c.in/orders/payment-instruction/2219928"
          },
          {
            "orderId": 4313991,
            "appointment": "10182117779",
            "customerName": "YOGESH KUMAR SHARMA",
            "model": "Swift Dzire",
            "year": 2015,
            "variant": "ZDI",
            "postSaleRcStatus": "Dealer Inventory",
            "pendingHoldback": "",
            "storeType": "locality",
            "orderPurchaseRequestId": "2219042",
            "adminUrl": "http://admin.24c.in/orders/payment-instruction/2219042"
          },
          {
            "orderId": 4314623,
            "appointment": "10143010777",
            "customerName": "NAGINA",
            "model": "Vento",
            "year": 2013,
            "variant": "HIGHLINE DIESEL 1.6",
            "postSaleRcStatus": "Dealer Inventory",
            "pendingHoldback": "",
            "storeType": "locality",
            "orderPurchaseRequestId": "2249993",
            "adminUrl": "http://admin.24c.in/orders/payment-instruction/2249993"
          },
          {
            "orderId": 4314649,
            "appointment": "10143412776",
            "customerName": "SOFGEN INFOTECH PVT LTD",
            "model": "Ecosport",
            "year": 2013,
            "variant": "TITANIUM 1.5L DIESEL",
            "postSaleRcStatus": "Dealer Inventory",
            "pendingHoldback": "",
            "storeType": "locality",
            "orderPurchaseRequestId": "2212947",
            "adminUrl": "http://admin.24c.in/orders/payment-instruction/2212947"
          },
          {
            "orderId": 4314681,
            "appointment": "10118013773",
            "customerName": "PARTHO SARKAR",
            "model": "Alto",
            "year": 2008,
            "variant": "LXI",
            "postSaleRcStatus": "Dealer Inventory",
            "pendingHoldback": "",
            "storeType": "locality",
            "orderPurchaseRequestId": "2211181",
            "adminUrl": "http://admin.24c.in/orders/payment-instruction/2211181"
          },
          {
            "orderId": 4314956,
            "appointment": "10183812779",
            "customerName": "R S ELECTROMECH PRIVATE LIMITED",
            "model": "Xcent",
            "year": 2014,
            "variant": "S 1.1 CRDI (O)",
            "postSaleRcStatus": "Transfer in Process",
            "pendingHoldback": "",
            "storeType": "locality",
            "orderPurchaseRequestId": "2214683",
            "adminUrl": "http://admin.24c.in/orders/payment-instruction/2214683"
          },
          {
            "orderId": 4314997,
            "appointment": "10152818775",
            "customerName": "JITENDER TEWATIA",
            "model": "Swift Dzire",
            "year": 2009,
            "variant": "VXI 1.3",
            "postSaleRcStatus": "Dealer Inventory",
            "pendingHoldback": "",
            "storeType": "locality",
            "orderPurchaseRequestId": "2214968",
            "adminUrl": "http://admin.24c.in/orders/payment-instruction/2214968"
          },
          {
            "orderId": 4315061,
            "appointment": "10192519774",
            "customerName": "GARGI",
            "model": "Baleno",
            "year": 2017,
            "variant": "DELTA PETROL 1.2",
            "postSaleRcStatus": "Dealer Inventory",
            "pendingHoldback": "",
            "storeType": "locality",
            "orderPurchaseRequestId": "2222249",
            "adminUrl": "http://admin.24c.in/orders/payment-instruction/2222249"
          }
        ],
        "columns": [
          {
            "key": "appointment",
            "value": "APP. ID",
            "isVisible": "1"
          },
          {
            "key": "customerName",
            "value": "CUST. NAME",
            "isVisible": "1"
          },
          {
            "key": "modelVariantYear",
            "value": "MODEL/YEAR/VAR",
            "isVisible": "1"
          },
          {
            "key": "pendingHoldback",
            "value": "PENDING HOLDBACK",
            "isVisible": "1"
          },
          {
            "key": "postSaleRcStatus",
            "value": "RC Status",
            "isVisible": "1"
          }
        ]
      }
    }
  };
  return response;
};

export const getInspectedMock = () => {
  let response = {
    data: {
      "type": "http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html",
      "title": "Inspected leads",
      "status": 200,
      "detail": {
        "result": [
          {
            "customerName": "SellerName",
            "appointment": "1002505714",
            "storeType": "store",
            "stateName": "Inspected",
            "make": "HYUNDAI",
            "model": "EON",
            "year": "2015",
            "variant": "ERA PLUS",
            "currentInspectionDate": "2020-10-26 11:31:14",
            "stateId": "11",
            "qcStatus": "In-Progress",
            "auctionStatus": 0,
            "auctionStartTime": "2020-10-26 11:31:14",
            "auctionEndTime": "2020-10-26 11:33:34",
            "auction_count": "1",
            "evaluatorName": "test2@cars24.com",
            "carId": "323253",
            "stageChangedTime": "2020-10-26 11:44:11",
            "isDepReportAvailable": false,
            "isDepReportViewed": false,
            "announcement": true
          },
          {
            "customerName": "SellerName",
            "appointment": "1002565714",
            "storeType": "store",
            "stateName": "Inspected",
            "make": "TATA",
            "model": "BOLT",
            "year": "2015",
            "variant": "XE",
            "currentInspectionDate": "2020-10-26 11:31:37",
            "stateId": "11",
            "qcStatus": "In-Progress",
            "auctionStatus": 0,
            "auctionStartTime": "",
            "auctionEndTime": "",
            "auction_count": 0,
            "evaluatorName": "test2@cars24.com",
            "carId": "323254",
            "stageChangedTime": "2020-10-26 11:44:04",
            "isDepReportAvailable": false,
            "isDepReportViewed": false,
            "announcement": true
          },
          {
            "customerName": "SellerName",
            "appointment": "1004595714",
            "storeType": "store",
            "stateName": "Inspected",
            "make": "HYUNDAI",
            "model": "EON",
            "year": "2015",
            "variant": "ERA PLUS",
            "currentInspectionDate": "2020-10-26 11:31:56",
            "stateId": "11",
            "qcStatus": "In-Progress",
            "auctionStatus": 0,
            "auctionStartTime": "",
            "auctionEndTime": "",
            "auction_count": 0,
            "evaluatorName": "test2@cars24.com",
            "carId": "323255",
            "stageChangedTime": "2020-10-26 11:42:46",
            "isDepReportAvailable": false,
            "isDepReportViewed": false,
            "announcement": true
          },
          {
            "customerName": "SellerName",
            "appointment": "1000555714",
            "storeType": "store",
            "stateName": "Inspected",
            "make": "HYUNDAI",
            "model": "EON",
            "year": "2015",
            "variant": "ERA PLUS",
            "currentInspectionDate": "2020-10-26 11:46:56",
            "stateId": "11",
            "qcStatus": "In-Progress",
            "auctionStatus": 0,
            "auctionStartTime": "",
            "auctionEndTime": "",
            "auction_count": 0,
            "evaluatorName": "test2@cars24.com",
            "carId": "323258",
            "stageChangedTime": "2020-10-26 11:50:27",
            "isDepReportAvailable": false,
            "isDepReportViewed": false,
            "announcement": true
          },
          {
            "customerName": "QATestData",
            "appointment": "1000575714",
            "storeType": "locality",
            "stateName": "Inspected",
            "make": "MARUTI SUZUKI",
            "model": "SWIFT DZIRE",
            "year": "2016",
            "variant": "LXI 1.2 BS IV",
            "currentInspectionDate": "2020-10-26 11:49:39",
            "stateId": "11",
            "qcStatus": "In-Progress",
            "auctionStatus": 0,
            "auctionStartTime": "",
            "auctionEndTime": "",
            "auction_count": 0,
            "evaluatorName": null,
            "carId": "323257",
            "stageChangedTime": "2020-10-26 11:49:39",
            "isDepReportAvailable": false,
            "isDepReportViewed": false,
            "announcement": false
          },
          {
            "customerName": "SellerName",
            "appointment": "1000505714",
            "storeType": "store",
            "stateName": "Inspected",
            "make": "HYUNDAI",
            "model": "EON",
            "year": "2015",
            "variant": "ERA PLUS",
            "currentInspectionDate": "2020-10-26 11:58:14",
            "stateId": "11",
            "qcStatus": "In-Progress",
            "auctionStatus": 0,
            "auctionStartTime": "",
            "auctionEndTime": "",
            "auction_count": 0,
            "evaluatorName": "test2@cars24.com",
            "carId": "323260",
            "stageChangedTime": "2020-10-26 11:58:15",
            "isDepReportAvailable": false,
            "isDepReportViewed": false,
            "announcement": true
          },
          {
            "customerName": "SellerName",
            "appointment": "1001565114",
            "storeType": "store",
            "stateName": "Inspected",
            "make": "HYUNDAI",
            "model": "EON",
            "year": "2015",
            "variant": "ERA PLUS",
            "currentInspectionDate": "2020-10-26 16:01:59",
            "stateId": "11",
            "qcStatus": "In-Progress",
            "auctionStatus": 0,
            "auctionStartTime": "",
            "auctionEndTime": "",
            "auction_count": 0,
            "evaluatorName": "test2@cars24.com",
            "carId": "323281",
            "stageChangedTime": "2020-10-26 16:02:00",
            "isDepReportAvailable": false,
            "isDepReportViewed": false,
            "announcement": true
          }
        ],
        "totalRows": 7,
        "page": "1",
        "columns": [
          {
            "key": "appointment",
            "value": "APP. ID",
            "isVisible": "1"
          },
          {
            "key": "customerName",
            "value": "CUST. NAME",
            "isVisible": "1"
          },
          {
            "key": "modelVariantYear",
            "value": "MODEL/YEAR/VAR",
            "isVisible": "1"
          },
          {
            "key": "currentInspectionDate",
            "value": "TIME SINCE INSP.",
            "isVisible": "1"
          },
          {
            "key": "qcStatus",
            "value": "QC STATUS",
            "isVisible": "1"
          },
          {
            "key": "auctionStatus",
            "value": "AUCTION STATUS",
            "isVisible": "1"
          },
          {
            "key": "evaluatorName",
            "value": "DONE BY CJ",
            "isVisible": "1"
          },
          {
            "key": "carId",
            "value": "INSP. REPORT",
            "isVisible": "0"
          },
          {
            "key": "isDepReportAvailable",
            "value": "INSP REPORT / DEP REPORT",
            "isVisible": "1"
          }
        ]
      }
    }
  };
  return response;
};
export const getMocStoreCheckIn = () => {
  let response = {
    data: {
      "type": "Store-auto-checkin",
      "title": "Store Auto Checkin",
      "status": 200,
      "detail": {
        "success": true,
        "message": "Checkin done successfully for  - 9810673725",
        "code": 1,
        "appointment": "1005825191",
        "make": "CHEVROLET",
        "model": "AVEO",
        "year": 2012,
        "variant": "LS 1.4",
        "idLmsStore": 3185,
        "storeName": "Janakpuri",
        "location": "2/4 A prem nagar janakpuri, above syndicate bank, New Delhi-110058"
      }
    }
  };
  return response;
};

export const getMocVariant = () => {
  let response = {
    data: {
      "name": "Car Fuel Variant",
      "type": "Success",
      "message": "Fuel variant found",
      "status": "200",
      "detail":
        [
          {
            "variant_id": 3707,
            "model_id": 102,
            "variant_name": "1.4 VTVT E",
            "variant_name_short": "1.4 VTVT E",
            "status": "Active",
            "variant_display_name": "1.4 VTVT E",
            "transportation_category": "Category_3",
            "transmission_type": "MT",
            "tax_value": "18"
          },
          {
            "variant_id": 3281,
            "model_id": 102,
            "variant_name": "1.6 EX VTVT",
            "variant_name_short": "VTVT 1.6 EX",
            "status": "Active",
            "variant_display_name": "1.6 Ex VTVT",
            "transportation_category": "Category_3",
            "transmission_type": "MT",
            "tax_value": "18"
          },
          {
            "variant_id": 3282,
            "model_id": 102,
            "variant_name": "1.6 S VTVT",
            "variant_name_short": "VTVT 1.6 S",
            "status": "Active",
            "variant_display_name": "1.6 S VTVT",
            "transportation_category": "Category_3",
            "transmission_type": "MT",
            "tax_value": "18"
          },
          {
            "variant_id": 3284,
            "model_id": 102,
            "variant_name": "1.6 S(O) VTVT",
            "variant_name_short": "1.6 S(O) VTVT",
            "status": "Active",
            "variant_display_name": "1.6 S(O) VTVT",
            "transportation_category": "Category_3",
            "transmission_type": "MT",
            "tax_value": "18"
          },
          {
            "variant_id": 3498,
            "model_id": 102,
            "variant_name": "1.6 S(O) VTVT AT",
            "variant_name_short": "1.6 S(O) VTVT AT",
            "status": "Active",
            "variant_display_name": "1.6 S(O) VTVT AT",
            "transportation_category": "Category_3",
            "transmission_type": "AT",
            "tax_value": "18"
          },
          {
            "variant_id": 3583,
            "model_id": 102,
            "variant_name": "1.6 SX VTVT",
            "variant_name_short": "1.6 SX VTVT",
            "status": "Active",
            "variant_display_name": "1.6 SX VTVT",
            "transportation_category": "Category_3",
            "transmission_type": "MT",
            "tax_value": "18"
          },
          {
            "variant_id": 3920,
            "model_id": 102,
            "variant_name": "1.6 SX VTVT (O)",
            "variant_name_short": "1.6 SX VTVT (O)",
            "status": "Active",
            "variant_display_name": "1.6 SX VTVT (O)",
            "transportation_category": "Category_3",
            "transmission_type": "MT",
            "tax_value": "18"
          },
          {
            "variant_id": 569,
            "model_id": 102,
            "variant_name": "1.6 SX VTVT AT",
            "variant_name_short": "1.6 SX VTVT AT",
            "status": "Active",
            "variant_display_name": "1.6 SX VTVT AT",
            "transportation_category": "Category_3",
            "transmission_type": "AT",
            "tax_value": "18"
          },
          {
            "variant_id": 3546,
            "model_id": 102,
            "variant_name": "1.6 SX VTVT AT (O)",
            "variant_name_short": "1.6 SX VTVT AT (O)",
            "status": "Active",
            "variant_display_name": "1.6 SX VTVT AT (O)",
            "transportation_category": "Category_3",
            "transmission_type": "AT",
            "tax_value": "18"
          },
          {
            "variant_id": 3613,
            "model_id": 102,
            "variant_name": "EX 1.6 VTVT AT",
            "variant_name_short": "1.6 EX VTVT AT",
            "status": "Active",
            "variant_display_name": "EX 1.6 VTVT AT",
            "transportation_category": "Category_3",
            "transmission_type": "AT",
            "tax_value": "18"
          }
        ]
    }
  };
};
export const getValidateDashBoardMoc = () => {
  let response = {
    data: {
      "type": "Validate-Dashboard",
      "title": "Validate waittime dashboard",
      "status": 200,
      "detail": {
        "success": true,
        "storeName": "Janakpuri",
        "storeConfig": {
          "screenSplitTime": 7000,
          "ad_split_time": 30000,
          "ad_time_out": 20000,
          "displayAdv": true,
          "advUrl": "https://screeno.locad.net/screeno-player/?pairing_code=a1d54"
        }
      }
    }

  };
  return response;
};

export const getPhoneLeadCheckIn = () => {
  let response = {
    data: {
      "type": "http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html",
      "title": "Missed Call Checkin",
      "status": 200,
      "detail": [
        {
          "phoneNumber": "324234233",
          "appointmentId": null,
          "status": "0",
          "createdAt": "2020-07-20 15:52:45",
          "idLmsStore": "3353",
          "name": "GurgoanHomeLocation"
        },
        {
          "phoneNumber": "324234234",
          "appointmentId": null,
          "status": "0",
          "createdAt": "2020-07-20 15:52:10",
          "idLmsStore": "3353",
          "name": "GurgoanHomeLocation"
        }
      ]
    }

  };
  return response;
};

export const getChangeLogMoc = () => {
  let response = {
    data: {
      "type": "Change Logs",
      "title": "Change logs",
      "status": 200,
      "detail": [
        {
          "columnName": "Pai Raised",
          "oldValue": "100000",
          "newValue": "120000",
          "createdDate": "2020-07-13 20:34:48",
          "email": "admin@acura.com",
          "date": "13 Jul,2020",
          "time": "20:34",
          "org_column": "paiRaised",
          "leadType": "Retail"
        },
        {
          "columnName": "List Car",
          "oldValue": "100000",
          "newValue": "120000",
          "createdDate": "2020-07-13 20:34:48",
          "email": "admin@acura.com",
          "date": "13 Jul,2020",
          "time": "20:34",
          "org_column": "carList",
          "leadType": "PLL"
        },
      ]

    }

  };
  return response;
};
export const getCustomerChargesNewMoc = () => {

  let response = {
    data: {
      "type": "http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html",
      "title": "Tentative charges",
      "status": 200,
      "detail": {
        "charges": {
          "holds": [
            {
              "label": "NOC HOLD",
              "isApplicable": true,
              "amount": 0,
              "remarks": "Provide NOC from the loan bank at the time of delivery to avoid this holdback"
            },
            {
              "label": "Party Peshi HOLD",
              "isApplicable": true,
              "amount": 0,
              "remarks": "Your presence is required at RTO for RC transfer. Holdback would be refunded after the Party Peshi"
            },
            {
              "label": "Service History HOLD",
              "isApplicable": true,
              "amount": 0,
              "remarks": "Amount would be refunded if we find no discrepancy in the car"
            }
          ],
          "charges": [
            {
              "label": "Instant Payment Charges",
              "isApplicable": true,
              "amount": 2380,
              "remarks": "Charges levied to process your payment within the same day"
            },
            {
              "label": "Service Charges",
              "isApplicable": true,
              "amount": 2499,
              "remarks": "CARS24 service charge for the entire process of selling your car"
            },
            {
              "label": "Late Stock In Charges",
              "isApplicable": true,
              "amount": 0,
              "remarks": "This is a per day charge. Please deliver the car within 3 days to avoid this"
            },
            {
              "label": "Insurance Charges",
              "isApplicable": true,
              "amount": 2023,
              "remarks": "Please provide valid insurance (expiring later than 15 days) document to avoid this charge"
            },
            {
              "label": "Challan Charges",
              "isApplicable": true,
              "amount": 1100,
              "remarks": "Please pay pending challans before delivery to remove this charge"
            },
            {
              "label": "Duplicate RC Charges",
              "isApplicable": true,
              "amount": 5000,
              "remarks": "Providing original RC at the time of delivery will remove this charge"
            },
            {
              "label": "Address Proof Charges",
              "isApplicable": true,
              "amount": 2380,
              "remarks": "Share an additional Address Proof of the city in which your car is registered, at the time of Delivery to avoid this"
            },
            {
              "label": "Hypothecation Removal",
              "isApplicable": false,
              "amount": 0,
              "remarks": "Provide RC without hypothecation to avoid this charge"
            },
            {
              "label": "Aadhar eKYC Charges",
              "isApplicable": true,
              "amount": 714,
              "remarks": "Charges for facilitating owner identification through Aadhar Seeding"
            },
            {
              "label": "Interstate Movement Charges",
              "isApplicable": true,
              "amount": 1190,
              "remarks": "Charges levied to facilitate sale of car outside registration state"
            },
            {
              "label": "Insufficient Fuel Charges",
              "isApplicable": false,
              "amount": 0,
              "remarks": "This is applicable if there is not sufficient fuel in your car. Please connect with RA to remove this charge."
            }
          ],
          "tnc": "<ul><li>Deliver the car within 3 days of token receipt to avoid late stock in charges.</li><li>Deliver the car along with required documents within 7 days to avoid deal cancellation.</li><li>Deal cancellation charges of Rs. 5000 are applicable in case you cancel the deal post token.</li></ul>",
          "tncLink": "https://www.cars24.com/token/terms-and-conditions/"
        },
        "cashbacks": [],
        "cepBreaker": null
      }
    }
  };
  return response;
};

export const getTimeSlotMoq = () => {
  let response = {
    data: {
      "type": "http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html",
      "title": "Store associates list",
      "status": 200,
      "detail": [
        {
          "slotId": "1",
          "value": "10-12",
        },
        {
          "slotId": "2",
          "value": "12-2",
        }
      ]
    }
  };
  return response;
};
export const getCJAllocationStatusList = () => {
  let response = {
    data: {
      "type": "http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html",
      "title": "CJ ALLOcation status list",
      "status": 200,
      "total": 8,
      "detail": [
        {
          "id_lms_users": "122",
          "email": "rishabh.kumar@cars24.com",
          "first_name": "Rishabh",
          "last_name": "Kumar",
          "emp_code": "10126",
          "allocatedAppointment": 12,
          "inspectedAppointment": 12,
          "storeId": 3167
        },
        {
          "id_lms_users": "505",
          "email": "Rajat.manga@cars24.com",
          "first_name": "Rajat",
          "last_name": "Manga",
          "emp_code": "13489",
          "allocatedAppointment": 22,
          "inspectedAppointment": 12,
          "storeId": 3167
        },
        {
          "id_lms_users": "512",
          "email": "naveen.verma@cars24.com",
          "first_name": "Naveen",
          "last_name": "Verma",
          "emp_code": "34590",
          "allocatedAppointment": 52,
          "inspectedAppointment": 12,
          "storeId": 3168
        },
        {
          "id_lms_users": "514",
          "email": "vivek.tripathi@cars24.com",
          "first_name": "Vivek",
          "last_name": "Tripathi",
          "emp_code": "16972",
          "allocatedAppointment": 32,
          "inspectedAppointment": 12,
          "storeId": 3168
        },
        {
          "id_lms_users": "515",
          "email": "anupam.chauhan@cars24.com",
          "first_name": "Anupam",
          "last_name": "Chauhan",
          "emp_code": "16972",
          "allocatedAppointment": 12,
          "inspectedAppointment": 12,
          "storeId": 3168
        }
      ]
    }
  };
  return response;
};
export const getRAListMoq = () => {
  let response = {
    "data": {
      "type": "http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html",
      "title": "Store associates list",
      "status": 200,
      "detail": [
        {
          "empCode": "15019",
          "firstName": "Rushi",
          "lastName": "Pathak",
          "idLmsUsers": 20612,
          "email": "rushi.pathak@cars24.com",
          "name": "Chandkheda",
          "storeCity": "Ahmedabad"
        },
        {
          "empCode": "16375",
          "firstName": "Vrushali",
          "lastName": "Chityal",
          "idLmsUsers": 22927,
          "email": "vrushali.chityal@cars24.com",
          "name": "PCMC",
          "storeCity": "Pune"
        }
      ]
    }
  };
  return response;
};
export const getLostLeadReasonMoq = () => {
  let respone = {
    "type": "Lost Reasons",
    "title": "Lost Reasons",
    "status": 200,
    "detail": {
      "reasons": [
        {
          "parentId": "217",
          "parent": "Sold outside",
          "subReasons": [
            {
              "id": "221",
              "name": "Other online aggregator"
            },
            {
              "id": "218",
              "name": "Individual"
            },
            {
              "id": "220",
              "name": "Exchange"
            },
            {
              "id": "219",
              "name": "Used car dealer"
            }
          ]
        },
        {
          "parentId": "222",
          "parent": "Documents issue",
          "subReasons": [
            {
              "id": "224",
              "name": "RC registered owner cannot come for transaction"
            },
            {
              "id": "223",
              "name": "Company authorization unavailable"
            }
          ]
        },
        {
          "parentId": "223",
          "parent": "High price expectation",
          "subReasons": []
        }
      ]
    }
  };
  return respone;
};
export const getAllocatedCjMoc = () => {
  let response = {
    "type": "Home inspection CJ info",
    "title": "Home inspection CJ info",
    "status": 200,
    "detail": {
      "appointment": "1008675697",
      "appointmentDate": {
        "date": "2019-05-06 00:00:00.000000",
        "timezone_type": 3,
        "timezone": "UTC"
      },
      "timeSlot": "12:00 - 14:00",
      "evaluatorId": 1
    }
  };
  return response;
};

export const getZoneMoc = () => {
  let response = {
    "type": "Car Model",
    "title": "Model found",
    "status": "200",
    "detail": [
      {
        "ZoneId": "1",
        "ZoneName": "East"
      },
      {
        "ZoneId": "2",
        "ZoneName": "West"
      },
      {
        "ZoneId": "3",
        "ZoneName": "North"
      },
      {
        "ZoneId": "4",
        "ZoneName": "South"
      }
    ]
  };
  return response;
};
export const getDepriciationReportMoq = () => {
  let response = { data: {
    "type": "Depreciation-Report",
    "title": "Depreciation Report",
    "status": 200,
    "detail": {
      "graph": {
        "vg_range_lower": 0,
        "vg_range_upper": 334368,
        "engine_dep_upper": 334368,
        "engine_dep_lower": 318048.5,
        "insurance_dep_range_upper": 318048.5,
        "insurance_dep_range_lower": 318048.5,
        "exterior_dep_range_upper": 318048.5,
        "exterior_dep_range_lower": 278880,
        "owner_dep_range_upper": 278880,
        "owner_dep_range_lower": 278880,
        "odometer_dep_range_upper": 278880,
        "odometer_dep_range_lower": 278880,
        "final_price_range_lower": 0,
        "final_price_range_upper": 278880
      },
      "data": {
        "make": "MARUTI SUZUKI",
        "model": "SWIFT",
        "variant": "ZDI",
        "year": 2012,
        "owner_number": 1,
        "storeCityId": 6,
        "odometer_reading": 100457,
        "fuel_type": "Diesel",
        "storeId": 46,
        "insurance_type": "Comprehensive",
        "exterior_rating": 3.5,
        "engine_rating": 4,
        "suspension_rating": 5,
        "city_code": "DL",
        "mmv": "MARUTI SUZUKI_SWIFT_ZDI",
        "body_type": "Hatchback",
        "demand_bucket": "high_demand",
        "category": "hatchback_diesel_dl_high_demand",
        "avg_odo": 12135.068061364,
        "age": 8,
        "odoPerYear": 12557.125,
        "odo_category": "normal_odo",
        "A": 0.95239185,
        "B": 0.11590336,
        "C": 0,
        "equation": "y=Ae^(-B*x)+C",
        "vg_range_max": "3,34,368",
        "vg_range_min": "3,18,445",
        "age_bin": "(6, 8]",
        "insurance_dep_percentage": 0,
        "insurance_dep_range_max": "0",
        "insurance_dep_range_min": "0",
        "owner_dep_percentage": 0,
        "owner_dep_range_max": "0",
        "owner_dep_range_min": "0",
        "exterior_dep_percentage": 12,
        "exterior_dep_range_max": "40,124",
        "exterior_dep_range_min": "38,213",
        "engine_dep_percentage": 5,
        "engine_dep_range_max": "16,718",
        "engine_dep_range_min": "15,921",
        "odometer_dep_percentage": 0,
        "odometer_dep_range_max": "0",
        "odometer_dep_range_min": "0",
        "lower_engine_suspension_rating": 4,
        "final_price_range_max": "2,80,234",
        "final_price_range_min": "2,61,603"
      }
    }
  }};
  return response;
};

export const getSimilarTranscatedCarMoq = () => {
  let response = {
    "type": "http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html",
    "title": "Similar transacted cars",
    "status": 200,
    "detail": [
      {
        "appointment": "9711988081",
        "make": "Nissan",
        "model": "Micro",
        "year": "2013",
        "registration_city": "Gurgaon",
        "odometer": "45000",
        "bought_price": "62000",
        "ownership_number": "",
        "front_image": "https://thumbor-staging.24c.in/unsafe/194x172/inspection/2020/02/3/CDR6RRC/v0/exteriorTyres/mainImages/frontmain_1580732760.jpeg"
      },
      {
        "appointment": "9711988082",
        "make": "Nissan",
        "model": "Micro",
        "year": "2013",
        "registration_city": "Gurgaon",
        "odometer": "45000",
        "bought_price": "58000",
        "ownership_number": "",
        "front_image": "https://thumbor-staging.24c.in/unsafe/194x172/inspection/2020/02/3/CDR6RRC/v0/exteriorTyres/mainImages/frontmain_1580732760.jpeg"
      },
      {
        "appointment": "9711988082",
        "make": "Nissan",
        "model": "Micro",
        "year": "2013",
        "registration_city": "Gurgaon",
        "odometer": "45000",
        "bought_price": "58000",
        "ownership_number": "",
        "front_image": "https://thumbor-staging.24c.in/unsafe/194x172/inspection/2020/02/3/CDR6RRC/v0/exteriorTyres/mainImages/frontmain_1580732760.jpeg"
      },
      {
        "appointment": "9711988082",
        "make": "Nissan",
        "model": "Micro",
        "year": "2013",
        "registration_city": "Gurgaon",
        "odometer": "45000",
        "bought_price": "58000",
        "ownership_number": "",
        "front_image": "https://thumbor-staging.24c.in/unsafe/194x172/inspection/2020/02/3/CDR6RRC/v0/exteriorTyres/mainImages/frontmain_1580732760.jpeg"
      }
    ]
  };
  return response;
};

export const getCustomerOffersMoq = () => {
  let response = {
    data: {
      "type": "http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html",
      "title": "Appointment details for input output page",
      "status": 200,
      "detail": {
        "appointmentId": "1053967695",
        "make": "Nissan",
        "model": "Micro",
        "year": "2013",
        "variant": "xdd",
        "fuel_type": "Petrol",
        "odometer": "23456",
        "front_image": "",
        "suggested_c24_quote": 205000,
        "c24Quote": 210000
      }
    }
  };
  return response;
};
export const getCustomerChargesMoq = () => {
  let response = {
    data: {
      "type": "http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html",
      "title": "Tentative charges",
      "status": 200,
      "detail": [
        {
          "label": " Service Charges",
          "charges": 5355,
          "isApplicable": true
        },
        {
          "label": "Late Stock In Charges",
          "charges": 0,
          "isApplicable": true
        },
        {
          "label": "Aadhar eKYC Charges",
          "charges": 1190,
          "isApplicable": false
        },
        {
          "label": "addressProofCharge",
          "charges": 2380,
          "isApplicable": false
        },
        {
          "label": "Instant Payment Charges",
          "charges": 1785,
          "isApplicable": true
        },
        {
          "label": "Hypo Removal Charges",
          "charges": 1190,
          "isApplicable": true
        },
        {
          "label": "Insurance Charges",
          "charges": 5593,
          "isApplicable": false
        }
      ]
    }
  };
  return response;
};
export const getSimilarCarMoq = () => {
  let response = {
    "type": "Similar Cars",
    "title": "Similar Cars List",
    "status": 200,
    "detail": {
      "message": "Data found successfully",
      "data": {
        "result": [
          {
            "appointmentId": "1021775513",
            "carId": 310983,
            "storeId": 18,
            "make": "MAHINDRA",
            "model": "XUV500",
            "year": 2017,
            "variant": "W4",
            "ownerNumber": 2,
            "customerExpectedPrice": "0",
            "exophone": "",
            "dealCreatedDate": "2020-01-16 10:58:52",
            "customerName": "ssdd",
            "customerPhone": "******3333",
            "mainImage": "https://thumbor-staging.24c.in/unsafe/528x0/inspection/2020/01/16/CDRCYZD/v0/exteriorTyres/mainImages/frontmain_1579152534.jpeg",
            "c24Quote": 446000,
            "calculatedc24Quote": 446000,
            "c24QuoteTag": "External Bid",
          },
          {
            "appointmentId": "1021775514",
            "carId": 310983,
            "storeId": 18,
            "make": "MAHINDRA",
            "model": "XUV500",
            "year": 2017,
            "variant": "W4",
            "ownerNumber": 2,
            "customerExpectedPrice": "0",
            "exophone": "",
            "dealCreatedDate": "2020-01-16 10:58:52",
            "customerName": "ssdd",
            "customerPhone": "******3333",
            "mainImage": "https://thumbor-staging.24c.in/unsafe/528x0/inspection/2020/01/16/CDRCYZD/v0/exteriorTyres/mainImages/frontmain_1579152534.jpeg",
            "c24Quote": 446000,
            "calculatedc24Quote": 446000,
            "c24QuoteTag": "External Bid",
          },
          {
            "appointmentId": "1021775515",
            "carId": 310983,
            "storeId": 18,
            "make": "MAHINDRA",
            "model": "XUV500",
            "year": 2017,
            "variant": "W4",
            "ownerNumber": 2,
            "customerExpectedPrice": "0",
            "exophone": "",
            "dealCreatedDate": "2020-01-16 10:58:52",
            "customerName": "ssdd",
            "customerPhone": "******3333",
            "mainImage": "https://thumbor-staging.24c.in/unsafe/528x0/inspection/2020/01/16/CDRCYZD/v0/exteriorTyres/mainImages/frontmain_1579152534.jpeg",
            "c24Quote": 446000,
            "calculatedc24Quote": 446000,
            "c24QuoteTag": "External Bid",
          },
          {
            "appointmentId": "1021775516",
            "carId": 310983,
            "storeId": 18,
            "make": "MAHINDRA",
            "model": "XUV500",
            "year": 2017,
            "variant": "W4",
            "ownerNumber": 2,
            "customerExpectedPrice": "0",
            "exophone": "",
            "dealCreatedDate": "2020-01-16 10:58:52",
            "customerName": "ssdd",
            "customerPhone": "******3333",
            "mainImage": "https://thumbor-staging.24c.in/unsafe/528x0/inspection/2020/01/16/CDRCYZD/v0/exteriorTyres/mainImages/frontmain_1579152534.jpeg",
            "c24Quote": 446000,
            "calculatedc24Quote": 446000,
            "c24QuoteTag": "External Bid",
          },
          {
            "appointmentId": "1021775516",
            "carId": 310983,
            "storeId": 18,
            "make": "MAHINDRA",
            "model": "XUV500",
            "year": 2017,
            "variant": "W4",
            "ownerNumber": 2,
            "customerExpectedPrice": "0",
            "exophone": "",
            "dealCreatedDate": "2020-01-16 10:58:52",
            "customerName": "ssdd",
            "customerPhone": "******3333",
            "mainImage": "https://thumbor-staging.24c.in/unsafe/528x0/inspection/2020/01/16/CDRCYZD/v0/exteriorTyres/mainImages/frontmain_1579152534.jpeg",
            "c24Quote": 446000,
            "calculatedc24Quote": 446000,
            "c24QuoteTag": "External Bid",
          }
        ]
      },
      "make": "Maruti Suzuki",
      "model": "Swift Dzire",
      "year": "2016"
    }
  };
  return response;
};
export const getPllStoreListMoq = () => {
  let response = {
    data: {
      "type": "http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html",
      "title": "Store list",
      "status": 200,
      "detail": [
        {
          "idLmsStore": 3167,
          "name": "Golf Course Extension",
          "storeCity": "Gurgaon",
          "unAllocatedPllLeadCount": 28
        },
        {
          "idLmsStore": 3168,
          "name": "Cyber City",
          "storeCity": "Gurgaon",
          "unAllocatedPllLeadCount": 49
        }
      ]
    }
  };
  return response;
};
export const getAppointMentListMoq = () => {
  let response = {
    data: {
      "type": "http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html",
      "title": "Pll appointment list",
      "status": 200,
      "detail": [
        {
          "storeId": "3167",
          "storeName": "Golf Course Extn",
          "appointmentList": "1015109097,1015789496,1015807697,1016917595"
        },
        {
          "storeId": "3168",
          "storeName": "JanakPuri",
          "appointmentList": "1015108097,1015759496,1015607697,1016217595"
        }
      ]
    }
  };
  return response;
};
export const getPllOfferMoq = () => {
  let response = {
    data: {
      "type": "http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html",
      "title": "Offer list",
      "status": 200,
      "detail": [
        {
          "sNo": 122,
          "Company": "Cars24 ",
          "Discription": "Get INR 15,000 Exchange Get INR 15,000 Exchange 1",
          "Discount": "100000"
        },
        {
          "sNo": 122,
          "Company": "Cars24 Finance",
          "Discription": "Test 2",
          "Discount": "200000"
        }
      ]
    }
  };
  return response;
};
export const getRaiseApiMoc = () => {
  let response = {
    data: {
      "type": "PAI Precheck",
      "title": "PAI Precheck",
      "status": 200,
      "detail": {
        "message": "Conversion Data",
        "data": {
          "result": {
            "total_pending_counts": {
              "total": "100",
              "pending": "0"
            },
            "max_limit": 800000,
            "appointment_id": "1020729599"
          },
          "message": "Conversion Data",
          "precheck": true
        }
      }
    }
  };
  return response;
};
export const getPllRaListMoq = () => {
  let response = {
    data: {
      "type": "http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html",
      "title": "PLL associate list",
      "status": 200,
      "detail": [
        {
          "id_lms_users": 122,
          "email": "rishabh.kumar@cars24.com",
          "first_name": "Rishabh",
          "last_name": "Kumar",
          "emp_code": 10126
        },
        {
          "id_lms_users": 506,
          "email": "retail6@cars24.com",
          "first_name": "Ankit",
          "last_name": "Sharma",
          "emp_code": "11006"
        }
      ]
    }
  };
  return response;
};
export const getInNegotiationListMoc = () => {
  let response = {
      data: {
        "type": "In-Negotiation",
        "title": "In-Negotiation List",
        "status": 200,
        "detail": {
          "data": {
            "result": [
              {
				"expType": "A",
                "exophone": "08047162072,0340",
                "appointmentId": "10396915911",
                "checkoutUrl": "",
                "storeId": "3333",
                "storeType": "locality",
                "dealCreatedDate": "2022-08-01 13:50:43",
                "originalInsDate": "2022-07-28 10:00:00",
                "currentInsDate": "2022-08-01 14:11:18",
                "stageChangedTime": "2022-08-01 14:13:04",
                "customerExpectedPrice": "0",
                "priceOffered": 0,
                "CEPPrice": 0,
                "customerPhone": "******4511",
                "customerName": "Customer",
                "followUpMessage": "",
                "followUpRemarks": "",
                "followUpTime": "",
                "isSharedC24Quote": 1,
                "retail_associate_email": "",
                "lostReason": "",
                "lostDateTime": "",
                "lostMarkedBy": "",
                "idLmsReason": "",
                "showInputOutputPage": false,
                "pai_ocb_status": null,
                "make": "MARUTI SUZUKI",
                "model": "VITARA BREZZA",
                "year": "2019",
                "variant": "VDI AMT",
                "carId": "431009",
                "ownerNumber": "1",
                "otpFlag": 1,
                "adminUrl": "http://admin-staging.c24svc.app/orders/order?toolbarFilters[o_leadId]=10396915955",
                "is_pll": 1,
                "isStarred": 0,
                "trojanStatus": 0,
                "isConnected": 0,
                "isDepReportAvailable": false,
                "isDepReportViewed": false,
                "cashbacks": [],
                "isC2cTokenDone": 0,
                "buyNowFlag": null,
                "token_vahan_status": null,
                "token_payment_status": null,
                "token_payment_update": null,
                "tokenPaymentStatus": "NOT_INITIATED",
                "tokenPaymentFlag": 0,
                "tokenPaymentRemark": "",
                "homeInspectionCj": null,
                "tokenPrStatus": 0,
                "auctionEfficiencyFlag": false,
                "customerAddress": "test, test,   400050, Saint John Baptist Road, near Federal Bank, Mount Mary, Bandra West, Mumbai, Maharashtra, India",
                "highIntentFlag": 0,
                "offered_price": 0,
                "pickup_request_status": null,
                "sell_online_acceptance": null,
                "sell_online_cep": null,
                "sell_online_reject_reason": null,
                "is_report_updated": null,
                "sell_online_retail_acceptance": null,
                "inspection_type": null,
                "home_ins_date": "2022-08-03 10:00:00",
                "msitePriceAccepted": false,
                "car_category": "hi_sell_online",
                "offer_response_date": null,
                "tpHit": 0,
                "assuredPrice": 0,
                "pll_margin": 0,
                "inv_buying": null,
                "price_acceptance_date": null,
                "hypothecation": null,
                "purchase_request_status": null,
                "ngtScrap": 0,
                "diy_penny_status": 3,
                "panCardStatus": 3,
                "aadharFrontStatus": null,
                "aadharBackStatus": null,
                "diy_doc_status": 4,
                "is_pr_submitted": null,
                "pr_created_date": null,
                "mainImage": "https://thumbor-staging.24c.in/unsafe/360x360/inspection/2022/08/1/C3DRCCY/v0/exteriorTyres/mainImages/frontmain_1659342980.jpg?width=194&height=172",
                "dealLostReason": "",
                "c24Quote": 500000,
                "bestC24Quote": 510000,
                "calculatedc24Quote": 0,
                "c24QuoteTag": "No bid",
                "c24quotExpiryTime": null,
                "oneClickBuy": 0,
                "auctionBiddingStatus": "bid_over",
                "auction_over_time": 0,
                "auction_count": 4,
                "reauctionFlag": 1,
                "auctionId": 480356,
                "auctionEndTime": "2022-08-02T15:40:01",
                "expectedOneClickAmount": 805000,
                "is_suspend": 0,
                "bidValidity": "2020-11-26",
                "currentHB": 0,
                "bestHB": 788000,
                "isInventoryBuying": false,
                "tp": 900000,
                "pai_status": null,
                "ocb_status": null,
                "is_partial_request": null,
                "request_type": null,
                "expected_hb": null,
                "negotiated_price": null,
                "requestedC24quote": null,
                "pai_timer": null,
                "raise_acceptance": null,
                "hiSellerIntent": 0,
                "cepEnable": false,
                "sortOrder": 41,
                "seller_price_eligible": true,
                "max_updated_quoted_price": 250000,
                "min_updated_quoted_price": 300000,
                "conversion": [],
                "isChatEnable": false,
                "checkWfMin": false,
                "allocateCjFlag": false,
                "leadType": "SO HI",
                "diySortOrder": 24,
                "offerStages": [
                  {
                    "key": "PRICE_OFFERED",
                    "display": true,
                    "label": "Price Offered",
                    "info": false,
                    "completed": true
                  },
                  {
                    "key": "RI_SCHEDULED",
                    "display": true,
                    "label": "RI Scheduled",
                    "info": false,
                    "completed": true
                  },
                  {
                    "key": "RI_DONE",
                    "display": true,
                    "label": "RI Done",
                    "info": false,
                    "completed": true
                  },
                  {
                    "key": "TOKEN",
                    "display": true,
                    "label": "PR Pending",
                    "info": false,
                    "completed": false
                  },
                  {
                    "key": "TOKEN_SUBMITTED",
                    "display": true,
                    "label": "PR Submission Pending",
                    "info": false,
                    "completed": false
                  }
                ],
                "documentStages": {
                  "documents": [
                    {
                      "key": "PAN_CARD",
                      "display": true,
                      "label": "PAN/Aadhar",
                      "info": false,
                      "status": 3
                    },
                    {
                      "key": "PENNY",
                      "display": true,
                      "label": "Penny",
                      "info": false,
                      "status": 3
                    }
                  ]
                },
                "inspReportCTAFlag": true
              },
              {
				"expType": "A",
                "exophone": "08047162072,0340",
                "appointmentId": "10396915912",
                "checkoutUrl": "",
                "storeId": "3333",
                "storeType": "locality",
                "dealCreatedDate": "2022-08-01 13:50:43",
                "originalInsDate": "2022-07-28 10:00:00",
                "currentInsDate": "2022-08-01 14:11:18",
                "stageChangedTime": "2022-08-01 14:13:04",
                "customerExpectedPrice": "0",
                "priceOffered": 0,
                "CEPPrice": 0,
                "customerPhone": "******4511",
                "customerName": "Customer",
                "followUpMessage": "",
                "followUpRemarks": "",
                "followUpTime": "",
                "isSharedC24Quote": 1,
                "retail_associate_email": "",
                "lostReason": "",
                "lostDateTime": "",
                "lostMarkedBy": "",
                "idLmsReason": "",
                "showInputOutputPage": false,
                "pai_ocb_status": null,
                "make": "MARUTI SUZUKI",
                "model": "VITARA BREZZA",
                "year": "2019",
                "variant": "VDI AMT",
                "carId": "431009",
                "ownerNumber": "1",
                "otpFlag": 1,
                "adminUrl": "http://admin-staging.c24svc.app/orders/order?toolbarFilters[o_leadId]=10396915955",
                "is_pll": 1,
                "isStarred": 0,
                "trojanStatus": 0,
                "isConnected": 0,
                "isDepReportAvailable": false,
                "isDepReportViewed": false,
                "cashbacks": [],
                "isC2cTokenDone": 0,
                "buyNowFlag": null,
                "token_vahan_status": null,
                "token_payment_status": null,
                "token_payment_update": null,
                "tokenPaymentStatus": "NOT_INITIATED",
                "tokenPaymentFlag": 0,
                "tokenPaymentRemark": "",
                "homeInspectionCj": null,
                "tokenPrStatus": 0,
                "auctionEfficiencyFlag": false,
                "customerAddress": "test, test,   400050, Saint John Baptist Road, near Federal Bank, Mount Mary, Bandra West, Mumbai, Maharashtra, India",
                "highIntentFlag": 0,
                "offered_price": 0,
                "pickup_request_status": null,
                "sell_online_acceptance": null,
                "sell_online_cep": null,
                "sell_online_reject_reason": null,
                "is_report_updated": null,
                "sell_online_retail_acceptance": null,
                "inspection_type": null,
                "home_ins_date": "2022-08-03 10:00:00",
                "msitePriceAccepted": false,
                "car_category": "hi_sell_online",
                "offer_response_date": null,
                "tpHit": 0,
                "assuredPrice": 0,
                "pll_margin": 0,
                "inv_buying": null,
                "price_acceptance_date": null,
                "hypothecation": null,
                "purchase_request_status": null,
                "ngtScrap": 0,
                "diy_penny_status": 3,
                "panCardStatus": 3,
                "aadharFrontStatus": null,
                "aadharBackStatus": null,
                "diy_doc_status": 4,
                "is_pr_submitted": null,
                "pr_created_date": null,
                "mainImage": "https://thumbor-staging.24c.in/unsafe/360x360/inspection/2022/08/1/C3DRCCY/v0/exteriorTyres/mainImages/frontmain_1659342980.jpg?width=194&height=172",
                "dealLostReason": "",
                "c24Quote": 0,
                "calculatedc24Quote": 0,
                "c24QuoteTag": "No bid",
                "c24quotExpiryTime": null,
                "oneClickBuy": 0,
                "auctionBiddingStatus": "bid_over",
                "auction_over_time": 0,
                "auction_count": 4,
                "reauctionFlag": 1,
                "auctionId": 480356,
                "auctionEndTime": "2022-08-02T15:40:01",
                "expectedOneClickAmount": 805000,
                "is_suspend": 0,
                "bidValidity": "2020-11-26",
                "currentHB": 0,
                "bestHB": 788000,
                "isInventoryBuying": false,
                "tp": 900000,
                "pai_status": null,
                "ocb_status": null,
                "is_partial_request": null,
                "request_type": null,
                "expected_hb": null,
                "negotiated_price": null,
                "requestedC24quote": null,
                "pai_timer": null,
                "raise_acceptance": null,
                "hiSellerIntent": 0,
                "cepEnable": false,
                "sortOrder": 41,
                "seller_price_eligible": true,
                "max_updated_quoted_price": 250000,
                "min_updated_quoted_price": 300000,
                "conversion": [],
                "isChatEnable": false,
                "checkWfMin": false,
                "allocateCjFlag": false,
                "leadType": "SO HI",
                "diySortOrder": 24,
                "offerStages": [
                  {
                    "key": "PRICE_OFFERED",
                    "display": true,
                    "label": "Price Offered",
                    "info": false,
                    "completed": true
                  },
                  {
                    "key": "RI_SCHEDULED",
                    "display": true,
                    "label": "RI Scheduled",
                    "info": false,
                    "completed": true
                  },
                  {
                    "key": "RI_DONE",
                    "display": true,
                    "label": "RI Done",
                    "info": false,
                    "completed": true
                  },
                  {
                    "key": "TOKEN",
                    "display": true,
                    "label": "PR Pending",
                    "info": false,
                    "completed": false
                  },
                  {
                    "key": "TOKEN_SUBMITTED",
                    "display": true,
                    "label": "PR Submission Pending",
                    "info": false,
                    "completed": false
                  }
                ],
                "documentStages": {
                  "documents": [
                    {
                      "key": "PAN_CARD",
                      "display": true,
                      "label": "PAN/Aadhar",
                      "info": false,
                      "status": 3
                    },
                    {
                      "key": "PENNY",
                      "display": true,
                      "label": "Penny",
                      "info": false,
                      "status": 3
                    }
                  ]
                },
                "inspReportCTAFlag": true
              },
              {
				"expType": "A",
                "exophone": "08047162072,0340",
                "appointmentId": "10396915913",
                "checkoutUrl": "",
                "storeId": "3333",
                "storeType": "locality",
                "dealCreatedDate": "2022-08-01 13:50:43",
                "originalInsDate": "2022-07-28 10:00:00",
                "currentInsDate": "2022-08-01 14:11:18",
                "stageChangedTime": "2022-08-01 14:13:04",
                "customerExpectedPrice": "0",
                "priceOffered": 0,
                "CEPPrice": 0,
                "customerPhone": "******4511",
                "customerName": "Customer",
                "followUpMessage": "",
                "followUpRemarks": "",
                "followUpTime": "",
                "isSharedC24Quote": 1,
                "retail_associate_email": "",
                "lostReason": "",
                "lostDateTime": "",
                "lostMarkedBy": "",
                "idLmsReason": "",
                "showInputOutputPage": false,
                "pai_ocb_status": null,
                "make": "MARUTI SUZUKI",
                "model": "VITARA BREZZA",
                "year": "2019",
                "variant": "VDI AMT",
                "carId": "431009",
                "ownerNumber": "1",
                "otpFlag": 1,
                "adminUrl": "http://admin-staging.c24svc.app/orders/order?toolbarFilters[o_leadId]=10396915955",
                "is_pll": 1,
                "isStarred": 0,
                "trojanStatus": 0,
                "isConnected": 0,
                "isDepReportAvailable": false,
                "isDepReportViewed": false,
                "cashbacks": [],
                "isC2cTokenDone": 0,
                "buyNowFlag": null,
                "token_vahan_status": null,
                "token_payment_status": null,
                "token_payment_update": null,
                "tokenPaymentStatus": "NOT_INITIATED",
                "tokenPaymentFlag": 0,
                "tokenPaymentRemark": "",
                "homeInspectionCj": null,
                "tokenPrStatus": 0,
                "auctionEfficiencyFlag": false,
                "customerAddress": "test, test,   400050, Saint John Baptist Road, near Federal Bank, Mount Mary, Bandra West, Mumbai, Maharashtra, India",
                "highIntentFlag": 0,
                "offered_price": 0,
                "pickup_request_status": null,
                "sell_online_acceptance": null,
                "sell_online_cep": null,
                "sell_online_reject_reason": null,
                "is_report_updated": null,
                "sell_online_retail_acceptance": null,
                "inspection_type": null,
                "home_ins_date": "2022-08-03 10:00:00",
                "msitePriceAccepted": false,
                "car_category": "hi_sell_online",
                "offer_response_date": null,
                "tpHit": 0,
                "assuredPrice": 0,
                "pll_margin": 0,
                "inv_buying": null,
                "price_acceptance_date": null,
                "hypothecation": null,
                "purchase_request_status": null,
                "ngtScrap": 0,
                "diy_penny_status": 3,
                "panCardStatus": 3,
                "aadharFrontStatus": null,
                "aadharBackStatus": null,
                "diy_doc_status": 4,
                "is_pr_submitted": null,
                "pr_created_date": null,
                "mainImage": "https://thumbor-staging.24c.in/unsafe/360x360/inspection/2022/08/1/C3DRCCY/v0/exteriorTyres/mainImages/frontmain_1659342980.jpg?width=194&height=172",
                "dealLostReason": "",
                "c24Quote": 0,
                "calculatedc24Quote": 0,
                "c24QuoteTag": "No bid",
                "c24quotExpiryTime": null,
                "oneClickBuy": 0,
                "auctionBiddingStatus": "bid_over",
                "auction_over_time": 0,
                "auction_count": 4,
                "reauctionFlag": 1,
                "auctionId": 480356,
                "auctionEndTime": "2022-08-02T15:40:01",
                "expectedOneClickAmount": 805000,
                "is_suspend": 0,
                "bidValidity": "2020-11-26",
                "currentHB": 0,
                "bestHB": 788000,
                "isInventoryBuying": false,
                "tp": 900000,
                "pai_status": null,
                "ocb_status": null,
                "is_partial_request": null,
                "request_type": null,
                "expected_hb": null,
                "negotiated_price": null,
                "requestedC24quote": null,
                "pai_timer": null,
                "raise_acceptance": null,
                "hiSellerIntent": 0,
                "cepEnable": false,
                "sortOrder": 41,
                "seller_price_eligible": true,
                "max_updated_quoted_price": 250000,
                "min_updated_quoted_price": 300000,
                "conversion": [],
                "isChatEnable": false,
                "checkWfMin": false,
                "allocateCjFlag": false,
                "leadType": "SO HI",
                "diySortOrder": 24,
                "offerStages": [
                  {
                    "key": "PRICE_OFFERED",
                    "display": true,
                    "label": "Price Offered",
                    "info": false,
                    "completed": true
                  },
                  {
                    "key": "RI_SCHEDULED",
                    "display": true,
                    "label": "RI Scheduled",
                    "info": false,
                    "completed": true
                  },
                  {
                    "key": "RI_DONE",
                    "display": true,
                    "label": "RI Done",
                    "info": false,
                    "completed": true
                  },
                  {
                    "key": "TOKEN",
                    "display": true,
                    "label": "PR Pending",
                    "info": false,
                    "completed": false
                  },
                  {
                    "key": "TOKEN_SUBMITTED",
                    "display": true,
                    "label": "PR Submission Pending",
                    "info": false,
                    "completed": false
                  }
                ],
                "documentStages": {
                  "documents": [
                    {
                      "key": "PAN_CARD",
                      "display": true,
                      "label": "PAN/Aadhar",
                      "info": false,
                      "status": 3
                    },
                    {
                      "key": "PENNY",
                      "display": true,
                      "label": "Penny",
                      "info": false,
                      "status": 3
                    }
                  ]
                },
                "inspReportCTAFlag": true
              },
              {
				"expType": "B",
                "exophone": "08047162072,0340",
                "appointmentId": "10396915914",
                "checkoutUrl": "",
                "storeId": "3333",
                "storeType": "locality",
                "dealCreatedDate": "2022-08-01 13:50:43",
                "originalInsDate": "2022-07-28 10:00:00",
                "currentInsDate": "2022-08-01 14:11:18",
                "stageChangedTime": "2022-08-01 14:13:04",
                "customerExpectedPrice": "0",
                "priceOffered": 0,
                "CEPPrice": 0,
                "customerPhone": "******4511",
                "customerName": "Customer",
                "followUpMessage": "",
                "followUpRemarks": "",
                "followUpTime": "",
                "isSharedC24Quote": 1,
                "retail_associate_email": "",
                "lostReason": "",
                "lostDateTime": "",
                "lostMarkedBy": "",
                "idLmsReason": "",
                "showInputOutputPage": false,
                "pai_ocb_status": null,
                "make": "MARUTI SUZUKI",
                "model": "VITARA BREZZA",
                "year": "2019",
                "variant": "VDI AMT",
                "carId": "431009",
                "ownerNumber": "1",
                "otpFlag": 1,
                "adminUrl": "http://admin-staging.c24svc.app/orders/order?toolbarFilters[o_leadId]=10396915955",
                "is_pll": 1,
                "isStarred": 0,
                "trojanStatus": 0,
                "isConnected": 0,
                "isDepReportAvailable": false,
                "isDepReportViewed": false,
                "cashbacks": [],
                "isC2cTokenDone": 0,
                "buyNowFlag": null,
                "token_vahan_status": null,
                "token_payment_status": null,
                "token_payment_update": null,
                "tokenPaymentStatus": "NOT_INITIATED",
                "tokenPaymentFlag": 0,
                "tokenPaymentRemark": "",
                "homeInspectionCj": null,
                "tokenPrStatus": 0,
                "auctionEfficiencyFlag": false,
                "customerAddress": "test, test,   400050, Saint John Baptist Road, near Federal Bank, Mount Mary, Bandra West, Mumbai, Maharashtra, India",
                "highIntentFlag": 0,
                "offered_price": 0,
                "pickup_request_status": null,
                "sell_online_acceptance": null,
                "sell_online_cep": null,
                "sell_online_reject_reason": null,
                "is_report_updated": null,
                "sell_online_retail_acceptance": null,
                "inspection_type": null,
                "home_ins_date": "2022-08-03 10:00:00",
                "msitePriceAccepted": false,
                "car_category": "hi_sell_online",
                "offer_response_date": null,
                "tpHit": 0,
                "assuredPrice": 0,
                "pll_margin": 0,
                "inv_buying": null,
                "price_acceptance_date": null,
                "hypothecation": null,
                "purchase_request_status": null,
                "ngtScrap": 0,
                "diy_penny_status": 3,
                "panCardStatus": 3,
                "aadharFrontStatus": null,
                "aadharBackStatus": null,
                "diy_doc_status": 4,
                "is_pr_submitted": null,
                "pr_created_date": null,
                "mainImage": "https://thumbor-staging.24c.in/unsafe/360x360/inspection/2022/08/1/C3DRCCY/v0/exteriorTyres/mainImages/frontmain_1659342980.jpg?width=194&height=172",
                "dealLostReason": "",
                "c24Quote": 0,
                "calculatedc24Quote": 0,
                "c24QuoteTag": "No bid",
                "c24quotExpiryTime": null,
                "oneClickBuy": 0,
                "auctionBiddingStatus": "bid_over",
                "auction_over_time": 0,
                "auction_count": 4,
                "reauctionFlag": 1,
                "auctionId": 480356,
                "auctionEndTime": "2022-08-02T15:40:01",
                "expectedOneClickAmount": 805000,
                "is_suspend": 0,
                "bidValidity": "2020-11-26",
                "currentHB": 0,
                "bestHB": 788000,
                "isInventoryBuying": false,
                "tp": 900000,
                "pai_status": null,
                "ocb_status": null,
                "is_partial_request": null,
                "request_type": null,
                "expected_hb": null,
                "negotiated_price": null,
                "requestedC24quote": null,
                "pai_timer": null,
                "raise_acceptance": null,
                "hiSellerIntent": 0,
                "cepEnable": false,
                "sortOrder": 41,
                "seller_price_eligible": true,
                "max_updated_quoted_price": 250000,
                "min_updated_quoted_price": 300000,
                "conversion": [],
                "isChatEnable": false,
                "checkWfMin": false,
                "allocateCjFlag": false,
                "leadType": "SO HI",
                "diySortOrder": 24,
                "offerStages": [
                  {
                    "key": "PRICE_OFFERED",
                    "display": true,
                    "label": "Price Offered",
                    "info": false,
                    "completed": true
                  },
                  {
                    "key": "RI_SCHEDULED",
                    "display": true,
                    "label": "RI Scheduled",
                    "info": false,
                    "completed": true
                  },
                  {
                    "key": "RI_DONE",
                    "display": true,
                    "label": "RI Done",
                    "info": false,
                    "completed": true
                  },
                  {
                    "key": "TOKEN",
                    "display": true,
                    "label": "PR Pending",
                    "info": false,
                    "completed": false
                  },
                  {
                    "key": "TOKEN_SUBMITTED",
                    "display": true,
                    "label": "PR Submission Pending",
                    "info": false,
                    "completed": false
                  }
                ],
                "documentStages": {
                  "documents": [
                    {
                      "key": "PAN_CARD",
                      "display": true,
                      "label": "PAN/Aadhar",
                      "info": false,
                      "status": 3
                    },
                    {
                      "key": "PENNY",
                      "display": true,
                      "label": "Penny",
                      "info": false,
                      "status": 3
                    }
                  ]
                },
                "inspReportCTAFlag": true
              },
              {
				"expType": "A",
                "exophone": "08047162072,0340",
                "appointmentId": "10396915955",
                "checkoutUrl": "",
                "storeId": "3333",
                "storeType": "locality",
                "dealCreatedDate": "2022-08-01 13:50:43",
                "originalInsDate": "2022-07-28 10:00:00",
                "currentInsDate": "2022-08-01 14:11:18",
                "stageChangedTime": "2022-08-01 14:13:04",
                "customerExpectedPrice": "0",
                "priceOffered": 0,
                "CEPPrice": 0,
                "customerPhone": "******4511",
                "customerName": "Customer",
                "followUpMessage": "",
                "followUpRemarks": "",
                "followUpTime": "",
                "isSharedC24Quote": 1,
                "retail_associate_email": "",
                "lostReason": "",
                "lostDateTime": "",
                "lostMarkedBy": "",
                "idLmsReason": "",
                "showInputOutputPage": false,
                "pai_ocb_status": null,
                "make": "MARUTI SUZUKI",
                "model": "VITARA BREZZA",
                "year": "2019",
                "variant": "VDI AMT",
                "carId": "431009",
                "ownerNumber": "1",
                "otpFlag": 1,
                "adminUrl": "http://admin-staging.c24svc.app/orders/order?toolbarFilters[o_leadId]=10396915955",
                "is_pll": 1,
                "isStarred": 0,
                "trojanStatus": 0,
                "isConnected": 0,
                "isDepReportAvailable": false,
                "isDepReportViewed": false,
                "cashbacks": [],
                "isC2cTokenDone": 0,
                "buyNowFlag": null,
                "token_vahan_status": null,
                "token_payment_status": null,
                "token_payment_update": null,
                "tokenPaymentStatus": "NOT_INITIATED",
                "tokenPaymentFlag": 0,
                "tokenPaymentRemark": "",
                "homeInspectionCj": null,
                "tokenPrStatus": 0,
                "auctionEfficiencyFlag": false,
                "customerAddress": "test, test,   400050, Saint John Baptist Road, near Federal Bank, Mount Mary, Bandra West, Mumbai, Maharashtra, India",
                "highIntentFlag": 0,
                "offered_price": 0,
                "pickup_request_status": null,
                "sell_online_acceptance": null,
                "sell_online_cep": null,
                "sell_online_reject_reason": null,
                "is_report_updated": null,
                "sell_online_retail_acceptance": null,
                "inspection_type": null,
                "home_ins_date": "2022-08-03 10:00:00",
                "msitePriceAccepted": false,
                "car_category": "hi_sell_online",
                "offer_response_date": null,
                "tpHit": 0,
                "assuredPrice": 0,
                "pll_margin": 0,
                "inv_buying": null,
                "price_acceptance_date": null,
                "hypothecation": null,
                "purchase_request_status": null,
                "ngtScrap": 0,
                "diy_penny_status": 3,
                "panCardStatus": 3,
                "aadharFrontStatus": null,
                "aadharBackStatus": null,
                "diy_doc_status": 4,
                "is_pr_submitted": null,
                "pr_created_date": null,
                "mainImage": "https://thumbor-staging.24c.in/unsafe/360x360/inspection/2022/08/1/C3DRCCY/v0/exteriorTyres/mainImages/frontmain_1659342980.jpg?width=194&height=172",
                "dealLostReason": "",
                "c24Quote": 0,
                "calculatedc24Quote": 0,
                "c24QuoteTag": "No bid",
                "c24quotExpiryTime": null,
                "oneClickBuy": 0,
                "auctionBiddingStatus": "bid_over",
                "auction_over_time": 0,
                "auction_count": 4,
                "reauctionFlag": 1,
                "auctionId": 480356,
                "auctionEndTime": "2022-08-02T15:40:01",
                "expectedOneClickAmount": 805000,
                "is_suspend": 0,
                "bidValidity": "2020-11-26",
                "currentHB": 0,
                "bestHB": 788000,
                "isInventoryBuying": false,
                "tp": 900000,
                "pai_status": null,
                "ocb_status": null,
                "is_partial_request": null,
                "request_type": null,
                "expected_hb": null,
                "negotiated_price": null,
                "requestedC24quote": null,
                "pai_timer": null,
                "raise_acceptance": null,
                "hiSellerIntent": 0,
                "cepEnable": false,
                "sortOrder": 41,
                "seller_price_eligible": true,
                "max_updated_quoted_price": 250000,
                "min_updated_quoted_price": 300000,
                "conversion": [],
                "isChatEnable": false,
                "checkWfMin": false,
                "allocateCjFlag": false,
                "leadType": "SO HI",
                "diySortOrder": 23,
                "offerStages": [
                  {
                    "key": "PRICE_OFFERED",
                    "display": true,
                    "label": "Price Offered",
                    "info": false,
                    "completed": true
                  },
                  {
                    "key": "RI_SCHEDULED",
                    "display": true,
                    "label": "RI Scheduled",
                    "info": false,
                    "completed": true
                  },
                  {
                    "key": "RI_DONE",
                    "display": true,
                    "label": "RI Done",
                    "info": false,
                    "completed": true
                  },
                  {
                    "key": "TOKEN",
                    "display": true,
                    "label": "PR Pending",
                    "info": false,
                    "completed": false
                  },
                  {
                    "key": "TOKEN_SUBMITTED",
                    "display": true,
                    "label": "PR Submission Pending",
                    "info": false,
                    "completed": false
                  }
                ],
                "documentStages": {
                  "documents": [
                    {
                      "key": "PAN_CARD",
                      "display": true,
                      "label": "PAN/Aadhar",
                      "info": false,
                      "status": 3
                    },
                    {
                      "key": "PENNY",
                      "display": true,
                      "label": "Penny",
                      "info": false,
                      "status": 3
                    }
                  ]
                },
                "inspReportCTAFlag": true
              },
              {
				"expType": "A",
                "exophone": "08047162072,0340",
                "appointmentId": "10396915956",
                "checkoutUrl": "",
                "storeId": "3333",
                "storeType": "locality",
                "dealCreatedDate": "2022-08-01 13:50:43",
                "originalInsDate": "2022-07-28 10:00:00",
                "currentInsDate": "2022-08-01 14:11:18",
                "stageChangedTime": "2022-08-01 14:13:04",
                "customerExpectedPrice": "0",
                "priceOffered": 0,
                "CEPPrice": 0,
                "customerPhone": "******4511",
                "customerName": "Customer",
                "followUpMessage": "",
                "followUpRemarks": "",
                "followUpTime": "",
                "isSharedC24Quote": 1,
                "retail_associate_email": "",
                "lostReason": "",
                "lostDateTime": "",
                "lostMarkedBy": "",
                "idLmsReason": "",
                "showInputOutputPage": false,
                "pai_ocb_status": null,
                "make": "MARUTI SUZUKI",
                "model": "VITARA BREZZA",
                "year": "2019",
                "variant": "VDI AMT",
                "carId": "431009",
                "ownerNumber": "1",
                "otpFlag": 1,
                "adminUrl": "http://admin-staging.c24svc.app/orders/order?toolbarFilters[o_leadId]=10396915955",
                "is_pll": 1,
                "isStarred": 0,
                "trojanStatus": 0,
                "isConnected": 0,
                "isDepReportAvailable": false,
                "isDepReportViewed": false,
                "cashbacks": [],
                "isC2cTokenDone": 0,
                "buyNowFlag": null,
                "token_vahan_status": null,
                "token_payment_status": null,
                "token_payment_update": null,
                "tokenPaymentStatus": "NOT_INITIATED",
                "tokenPaymentFlag": 0,
                "tokenPaymentRemark": "",
                "homeInspectionCj": null,
                "tokenPrStatus": 0,
                "auctionEfficiencyFlag": false,
                "customerAddress": "test, test,   400050, Saint John Baptist Road, near Federal Bank, Mount Mary, Bandra West, Mumbai, Maharashtra, India",
                "highIntentFlag": 0,
                "offered_price": 0,
                "pickup_request_status": null,
                "sell_online_acceptance": null,
                "sell_online_cep": null,
                "sell_online_reject_reason": null,
                "is_report_updated": null,
                "sell_online_retail_acceptance": null,
                "inspection_type": null,
                "home_ins_date": "2022-08-03 10:00:00",
                "msitePriceAccepted": false,
                "car_category": "hi_sell_online",
                "offer_response_date": null,
                "tpHit": 0,
                "assuredPrice": 0,
                "pll_margin": 0,
                "inv_buying": null,
                "price_acceptance_date": null,
                "hypothecation": null,
                "purchase_request_status": null,
                "ngtScrap": 0,
                "diy_penny_status": 3,
                "panCardStatus": 3,
                "aadharFrontStatus": null,
                "aadharBackStatus": null,
                "diy_doc_status": 4,
                "is_pr_submitted": null,
                "pr_created_date": null,
                "mainImage": "https://thumbor-staging.24c.in/unsafe/360x360/inspection/2022/08/1/C3DRCCY/v0/exteriorTyres/mainImages/frontmain_1659342980.jpg?width=194&height=172",
                "dealLostReason": "",
                "c24Quote": 0,
                "calculatedc24Quote": 0,
                "c24QuoteTag": "No bid",
                "c24quotExpiryTime": null,
                "oneClickBuy": 0,
                "auctionBiddingStatus": "bid_over",
                "auction_over_time": 0,
                "auction_count": 4,
                "reauctionFlag": 1,
                "auctionId": 480356,
                "auctionEndTime": "2022-08-02T15:40:01",
                "expectedOneClickAmount": 805000,
                "is_suspend": 0,
                "bidValidity": "2020-11-26",
                "currentHB": 0,
                "bestHB": 788000,
                "isInventoryBuying": false,
                "tp": 900000,
                "pai_status": null,
                "ocb_status": null,
                "is_partial_request": null,
                "request_type": null,
                "expected_hb": null,
                "negotiated_price": null,
                "requestedC24quote": null,
                "pai_timer": null,
                "raise_acceptance": null,
                "hiSellerIntent": 0,
                "cepEnable": false,
                "sortOrder": 41,
                "seller_price_eligible": true,
                "max_updated_quoted_price": 250000,
                "min_updated_quoted_price": 300000,
                "conversion": [],
                "isChatEnable": false,
                "checkWfMin": false,
                "allocateCjFlag": false,
                "leadType": "SO HI",
                "diySortOrder": 24,
                "offerStages": [
                  {
                    "key": "PRICE_OFFERED",
                    "display": true,
                    "label": "Price Offered",
                    "info": false,
                    "completed": true
                  },
                  {
                    "key": "RI_SCHEDULED",
                    "display": true,
                    "label": "RI Scheduled",
                    "info": false,
                    "completed": true
                  },
                  {
                    "key": "RI_DONE",
                    "display": true,
                    "label": "RI Done",
                    "info": false,
                    "completed": true
                  },
                  {
                    "key": "TOKEN",
                    "display": true,
                    "label": "PR Pending",
                    "info": false,
                    "completed": false
                  },
                  {
                    "key": "TOKEN_SUBMITTED",
                    "display": true,
                    "label": "PR Submission Pending",
                    "info": false,
                    "completed": false
                  }
                ],
                "documentStages": {
                  "documents": [
                    {
                      "key": "PAN_CARD",
                      "display": true,
                      "label": "PAN/Aadhar",
                      "info": false,
                      "status": 3
                    },
                    {
                      "key": "PENNY",
                      "display": true,
                      "label": "Penny",
                      "info": false,
                      "status": 3
                    }
                  ]
                },
                "inspReportCTAFlag": true
              },
              {
				"expType": "A",
                "exophone": "08047162072,0340",
                "appointmentId": "10396915957",
                "checkoutUrl": "",
                "storeId": "3333",
                "storeType": "locality",
                "dealCreatedDate": "2022-08-01 13:50:43",
                "originalInsDate": "2022-07-28 10:00:00",
                "currentInsDate": "2022-08-01 14:11:18",
                "stageChangedTime": "2022-08-01 14:13:04",
                "customerExpectedPrice": "0",
                "priceOffered": 0,
                "CEPPrice": 0,
                "customerPhone": "******4511",
                "customerName": "Customer",
                "followUpMessage": "",
                "followUpRemarks": "",
                "followUpTime": "",
                "isSharedC24Quote": 1,
                "retail_associate_email": "",
                "lostReason": "",
                "lostDateTime": "",
                "lostMarkedBy": "",
                "idLmsReason": "",
                "showInputOutputPage": false,
                "pai_ocb_status": null,
                "make": "MARUTI SUZUKI",
                "model": "VITARA BREZZA",
                "year": "2019",
                "variant": "VDI AMT",
                "carId": "431009",
                "ownerNumber": "1",
                "otpFlag": 1,
                "adminUrl": "http://admin-staging.c24svc.app/orders/order?toolbarFilters[o_leadId]=10396915955",
                "is_pll": 1,
                "isStarred": 0,
                "trojanStatus": 0,
                "isConnected": 0,
                "isDepReportAvailable": false,
                "isDepReportViewed": false,
                "cashbacks": [],
                "isC2cTokenDone": 0,
                "buyNowFlag": null,
                "token_vahan_status": null,
                "token_payment_status": null,
                "token_payment_update": null,
                "tokenPaymentStatus": "NOT_INITIATED",
                "tokenPaymentFlag": 0,
                "tokenPaymentRemark": "",
                "homeInspectionCj": null,
                "tokenPrStatus": 0,
                "auctionEfficiencyFlag": false,
                "customerAddress": "test, test,   400050, Saint John Baptist Road, near Federal Bank, Mount Mary, Bandra West, Mumbai, Maharashtra, India",
                "highIntentFlag": 0,
                "offered_price": 0,
                "pickup_request_status": null,
                "sell_online_acceptance": null,
                "sell_online_cep": null,
                "sell_online_reject_reason": null,
                "is_report_updated": null,
                "sell_online_retail_acceptance": null,
                "inspection_type": null,
                "home_ins_date": "2022-08-03 10:00:00",
                "msitePriceAccepted": false,
                "car_category": "hi_sell_online",
                "offer_response_date": null,
                "tpHit": 0,
                "assuredPrice": 0,
                "pll_margin": 0,
                "inv_buying": null,
                "price_acceptance_date": null,
                "hypothecation": null,
                "purchase_request_status": null,
                "ngtScrap": 0,
                "diy_penny_status": 3,
                "panCardStatus": 3,
                "aadharFrontStatus": null,
                "aadharBackStatus": null,
                "diy_doc_status": 4,
                "is_pr_submitted": null,
                "pr_created_date": null,
                "mainImage": "https://thumbor-staging.24c.in/unsafe/360x360/inspection/2022/08/1/C3DRCCY/v0/exteriorTyres/mainImages/frontmain_1659342980.jpg?width=194&height=172",
                "dealLostReason": "",
                "c24Quote": 0,
                "calculatedc24Quote": 0,
                "c24QuoteTag": "No bid",
                "c24quotExpiryTime": null,
                "oneClickBuy": 0,
                "auctionBiddingStatus": "bid_over",
                "auction_over_time": 0,
                "auction_count": 4,
                "reauctionFlag": 1,
                "auctionId": 480356,
                "auctionEndTime": "2022-08-02T15:40:01",
                "expectedOneClickAmount": 805000,
                "is_suspend": 0,
                "bidValidity": "2020-11-26",
                "currentHB": 0,
                "bestHB": 788000,
                "isInventoryBuying": false,
                "tp": 900000,
                "pai_status": null,
                "ocb_status": null,
                "is_partial_request": null,
                "request_type": null,
                "expected_hb": null,
                "negotiated_price": null,
                "requestedC24quote": null,
                "pai_timer": null,
                "raise_acceptance": null,
                "hiSellerIntent": 0,
                "cepEnable": false,
                "sortOrder": 41,
                "seller_price_eligible": true,
                "max_updated_quoted_price": 250000,
                "min_updated_quoted_price": 300000,
                "conversion": [],
                "isChatEnable": false,
                "checkWfMin": false,
                "allocateCjFlag": false,
                "leadType": "SO HI",
                "diySortOrder": 24,
                "offerStages": [
                  {
                    "key": "PRICE_OFFERED",
                    "display": true,
                    "label": "Price Offered",
                    "info": false,
                    "completed": true
                  },
                  {
                    "key": "RI_SCHEDULED",
                    "display": true,
                    "label": "RI Scheduled",
                    "info": false,
                    "completed": true
                  },
                  {
                    "key": "RI_DONE",
                    "display": true,
                    "label": "RI Done",
                    "info": false,
                    "completed": true
                  },
                  {
                    "key": "TOKEN",
                    "display": true,
                    "label": "PR Pending",
                    "info": false,
                    "completed": false
                  },
                  {
                    "key": "TOKEN_SUBMITTED",
                    "display": true,
                    "label": "PR Submission Pending",
                    "info": false,
                    "completed": false
                  }
                ],
                "documentStages": {
                  "documents": [
                    {
                      "key": "PAN_CARD",
                      "display": true,
                      "label": "PAN/Aadhar",
                      "info": false,
                      "status": 3
                    },
                    {
                      "key": "PENNY",
                      "display": true,
                      "label": "Penny",
                      "info": false,
                      "status": 3
                    }
                  ]
                },
                "inspReportCTAFlag": true
              },
              {
				"expType": "A",
                "exophone": "08047162072,0340",
                "appointmentId": "10396915958",
                "checkoutUrl": "",
                "storeId": "3333",
                "storeType": "locality",
                "dealCreatedDate": "2022-08-01 13:50:43",
                "originalInsDate": "2022-07-28 10:00:00",
                "currentInsDate": "2022-08-01 14:11:18",
                "stageChangedTime": "2022-08-01 14:13:04",
                "customerExpectedPrice": "0",
                "priceOffered": 0,
                "CEPPrice": 0,
                "customerPhone": "******4511",
                "customerName": "Customer",
                "followUpMessage": "",
                "followUpRemarks": "",
                "followUpTime": "",
                "isSharedC24Quote": 1,
                "retail_associate_email": "",
                "lostReason": "",
                "lostDateTime": "",
                "lostMarkedBy": "",
                "idLmsReason": "",
                "showInputOutputPage": false,
                "pai_ocb_status": null,
                "make": "MARUTI SUZUKI",
                "model": "VITARA BREZZA",
                "year": "2019",
                "variant": "VDI AMT",
                "carId": "431009",
                "ownerNumber": "1",
                "otpFlag": 1,
                "adminUrl": "http://admin-staging.c24svc.app/orders/order?toolbarFilters[o_leadId]=10396915955",
                "is_pll": 1,
                "isStarred": 0,
                "trojanStatus": 0,
                "isConnected": 0,
                "isDepReportAvailable": false,
                "isDepReportViewed": false,
                "cashbacks": [],
                "isC2cTokenDone": 0,
                "buyNowFlag": null,
                "token_vahan_status": null,
                "token_payment_status": null,
                "token_payment_update": null,
                "tokenPaymentStatus": "NOT_INITIATED",
                "tokenPaymentFlag": 0,
                "tokenPaymentRemark": "",
                "homeInspectionCj": null,
                "tokenPrStatus": 0,
                "auctionEfficiencyFlag": false,
                "customerAddress": "test, test,   400050, Saint John Baptist Road, near Federal Bank, Mount Mary, Bandra West, Mumbai, Maharashtra, India",
                "highIntentFlag": 0,
                "offered_price": 0,
                "pickup_request_status": null,
                "sell_online_acceptance": null,
                "sell_online_cep": null,
                "sell_online_reject_reason": null,
                "is_report_updated": null,
                "sell_online_retail_acceptance": null,
                "inspection_type": null,
                "home_ins_date": "2022-08-03 10:00:00",
                "msitePriceAccepted": false,
                "car_category": "hi_sell_online",
                "offer_response_date": null,
                "tpHit": 0,
                "assuredPrice": 0,
                "pll_margin": 0,
                "inv_buying": null,
                "price_acceptance_date": null,
                "hypothecation": null,
                "purchase_request_status": null,
                "ngtScrap": 0,
                "diy_penny_status": 3,
                "panCardStatus": 3,
                "aadharFrontStatus": null,
                "aadharBackStatus": null,
                "diy_doc_status": 4,
                "is_pr_submitted": null,
                "pr_created_date": null,
                "mainImage": "https://thumbor-staging.24c.in/unsafe/360x360/inspection/2022/08/1/C3DRCCY/v0/exteriorTyres/mainImages/frontmain_1659342980.jpg?width=194&height=172",
                "dealLostReason": "",
                "c24Quote": 0,
                "calculatedc24Quote": 0,
                "c24QuoteTag": "No bid",
                "c24quotExpiryTime": null,
                "oneClickBuy": 0,
                "auctionBiddingStatus": "bid_over",
                "auction_over_time": 0,
                "auction_count": 4,
                "reauctionFlag": 1,
                "auctionId": 480356,
                "auctionEndTime": "2022-08-02T15:40:01",
                "expectedOneClickAmount": 805000,
                "is_suspend": 0,
                "bidValidity": "2020-11-26",
                "currentHB": 0,
                "bestHB": 788000,
                "isInventoryBuying": false,
                "tp": 900000,
                "pai_status": null,
                "ocb_status": null,
                "is_partial_request": null,
                "request_type": null,
                "expected_hb": null,
                "negotiated_price": null,
                "requestedC24quote": null,
                "pai_timer": null,
                "raise_acceptance": null,
                "hiSellerIntent": 0,
                "cepEnable": false,
                "sortOrder": 41,
                "seller_price_eligible": true,
                "max_updated_quoted_price": 250000,
                "min_updated_quoted_price": 300000,
                "conversion": [],
                "isChatEnable": false,
                "checkWfMin": false,
                "allocateCjFlag": false,
                "leadType": "SO HI",
                "diySortOrder": 24,
                "offerStages": [
                  {
                    "key": "PRICE_OFFERED",
                    "display": true,
                    "label": "Price Offered",
                    "info": false,
                    "completed": true
                  },
                  {
                    "key": "RI_SCHEDULED",
                    "display": true,
                    "label": "RI Scheduled",
                    "info": false,
                    "completed": true
                  },
                  {
                    "key": "RI_DONE",
                    "display": true,
                    "label": "RI Done",
                    "info": false,
                    "completed": true
                  },
                  {
                    "key": "TOKEN",
                    "display": true,
                    "label": "PR Pending",
                    "info": false,
                    "completed": false
                  },
                  {
                    "key": "TOKEN_SUBMITTED",
                    "display": true,
                    "label": "PR Submission Pending",
                    "info": false,
                    "completed": false
                  }
                ],
                "documentStages": {
                  "documents": [
                    {
                      "key": "PAN_CARD",
                      "display": true,
                      "label": "PAN/Aadhar",
                      "info": false,
                      "status": 3
                    },
                    {
                      "key": "PENNY",
                      "display": true,
                      "label": "Penny",
                      "info": false,
                      "status": 3
                    }
                  ]
                },
                "inspReportCTAFlag": true
              },
              {
				"expType": "A",
                "exophone": "08047162072,0340",
                "appointmentId": "10396915959",
                "checkoutUrl": "",
                "storeId": "3333",
                "storeType": "locality",
                "dealCreatedDate": "2022-08-01 13:50:43",
                "originalInsDate": "2022-07-28 10:00:00",
                "currentInsDate": "2022-08-01 14:11:18",
                "stageChangedTime": "2022-08-01 14:13:04",
                "customerExpectedPrice": "0",
                "priceOffered": 0,
                "CEPPrice": 0,
                "customerPhone": "******4511",
                "customerName": "Customer",
                "followUpMessage": "",
                "followUpRemarks": "",
                "followUpTime": "",
                "isSharedC24Quote": 1,
                "retail_associate_email": "",
                "lostReason": "",
                "lostDateTime": "",
                "lostMarkedBy": "",
                "idLmsReason": "",
                "showInputOutputPage": false,
                "pai_ocb_status": null,
                "make": "MARUTI SUZUKI",
                "model": "VITARA BREZZA",
                "year": "2019",
                "variant": "VDI AMT",
                "carId": "431009",
                "ownerNumber": "1",
                "otpFlag": 1,
                "adminUrl": "http://admin-staging.c24svc.app/orders/order?toolbarFilters[o_leadId]=10396915955",
                "is_pll": 1,
                "isStarred": 0,
                "trojanStatus": 0,
                "isConnected": 0,
                "isDepReportAvailable": false,
                "isDepReportViewed": false,
                "cashbacks": [],
                "isC2cTokenDone": 0,
                "buyNowFlag": null,
                "token_vahan_status": null,
                "token_payment_status": null,
                "token_payment_update": null,
                "tokenPaymentStatus": "NOT_INITIATED",
                "tokenPaymentFlag": 0,
                "tokenPaymentRemark": "",
                "homeInspectionCj": null,
                "tokenPrStatus": 0,
                "auctionEfficiencyFlag": false,
                "customerAddress": "test, test,   400050, Saint John Baptist Road, near Federal Bank, Mount Mary, Bandra West, Mumbai, Maharashtra, India",
                "highIntentFlag": 0,
                "offered_price": 0,
                "pickup_request_status": null,
                "sell_online_acceptance": null,
                "sell_online_cep": null,
                "sell_online_reject_reason": null,
                "is_report_updated": null,
                "sell_online_retail_acceptance": null,
                "inspection_type": null,
                "home_ins_date": "2022-08-03 10:00:00",
                "msitePriceAccepted": false,
                "car_category": "hi_sell_online",
                "offer_response_date": null,
                "tpHit": 0,
                "assuredPrice": 0,
                "pll_margin": 0,
                "inv_buying": null,
                "price_acceptance_date": null,
                "hypothecation": null,
                "purchase_request_status": null,
                "ngtScrap": 0,
                "diy_penny_status": 3,
                "panCardStatus": 3,
                "aadharFrontStatus": null,
                "aadharBackStatus": null,
                "diy_doc_status": 4,
                "is_pr_submitted": null,
                "pr_created_date": null,
                "mainImage": "https://thumbor-staging.24c.in/unsafe/360x360/inspection/2022/08/1/C3DRCCY/v0/exteriorTyres/mainImages/frontmain_1659342980.jpg?width=194&height=172",
                "dealLostReason": "",
                "c24Quote": 0,
                "calculatedc24Quote": 0,
                "c24QuoteTag": "No bid",
                "c24quotExpiryTime": null,
                "oneClickBuy": 0,
                "auctionBiddingStatus": "bid_over",
                "auction_over_time": 0,
                "auction_count": 4,
                "reauctionFlag": 1,
                "auctionId": 480356,
                "auctionEndTime": "2022-08-02T15:40:01",
                "expectedOneClickAmount": 805000,
                "is_suspend": 0,
                "bidValidity": "2020-11-26",
                "currentHB": 0,
                "bestHB": 788000,
                "isInventoryBuying": false,
                "tp": 900000,
                "pai_status": null,
                "ocb_status": null,
                "is_partial_request": null,
                "request_type": null,
                "expected_hb": null,
                "negotiated_price": null,
                "requestedC24quote": null,
                "pai_timer": null,
                "raise_acceptance": null,
                "hiSellerIntent": 0,
                "cepEnable": false,
                "sortOrder": 41,
                "seller_price_eligible": true,
                "max_updated_quoted_price": 250000,
                "min_updated_quoted_price": 300000,
                "conversion": [],
                "isChatEnable": false,
                "checkWfMin": false,
                "allocateCjFlag": false,
                "leadType": "SO HI",
                "diySortOrder": 24,
                "offerStages": [
                  {
                    "key": "PRICE_OFFERED",
                    "display": true,
                    "label": "Price Offered",
                    "info": false,
                    "completed": true
                  },
                  {
                    "key": "RI_SCHEDULED",
                    "display": true,
                    "label": "RI Scheduled",
                    "info": false,
                    "completed": true
                  },
                  {
                    "key": "RI_DONE",
                    "display": true,
                    "label": "RI Done",
                    "info": false,
                    "completed": true
                  },
                  {
                    "key": "TOKEN",
                    "display": true,
                    "label": "PR Pending",
                    "info": false,
                    "completed": false
                  },
                  {
                    "key": "TOKEN_SUBMITTED",
                    "display": true,
                    "label": "PR Submission Pending",
                    "info": false,
                    "completed": false
                  }
                ],
                "documentStages": {
                  "documents": [
                    {
                      "key": "PAN_CARD",
                      "display": true,
                      "label": "PAN/Aadhar",
                      "info": false,
                      "status": 3
                    },
                    {
                      "key": "PENNY",
                      "display": true,
                      "label": "Penny",
                      "info": false,
                      "status": 3
                    }
                  ]
                },
                "inspReportCTAFlag": true
              },
              {
				"expType": "A",
                "exophone": "08047162072,0340",
                "appointmentId": "10396915960",
                "checkoutUrl": "",
                "storeId": "3333",
                "storeType": "locality",
                "dealCreatedDate": "2022-08-01 13:50:43",
                "originalInsDate": "2022-07-28 10:00:00",
                "currentInsDate": "2022-08-01 14:11:18",
                "stageChangedTime": "2022-08-01 14:13:04",
                "customerExpectedPrice": "0",
                "priceOffered": 0,
                "CEPPrice": 0,
                "customerPhone": "******4511",
                "customerName": "Customer",
                "followUpMessage": "",
                "followUpRemarks": "",
                "followUpTime": "",
                "isSharedC24Quote": 1,
                "retail_associate_email": "",
                "lostReason": "",
                "lostDateTime": "",
                "lostMarkedBy": "",
                "idLmsReason": "",
                "showInputOutputPage": false,
                "pai_ocb_status": null,
                "make": "MARUTI SUZUKI",
                "model": "VITARA BREZZA",
                "year": "2019",
                "variant": "VDI AMT",
                "carId": "431009",
                "ownerNumber": "1",
                "otpFlag": 1,
                "adminUrl": "http://admin-staging.c24svc.app/orders/order?toolbarFilters[o_leadId]=10396915955",
                "is_pll": 1,
                "isStarred": 0,
                "trojanStatus": 0,
                "isConnected": 0,
                "isDepReportAvailable": false,
                "isDepReportViewed": false,
                "cashbacks": [],
                "isC2cTokenDone": 0,
                "buyNowFlag": null,
                "token_vahan_status": null,
                "token_payment_status": null,
                "token_payment_update": null,
                "tokenPaymentStatus": "NOT_INITIATED",
                "tokenPaymentFlag": 0,
                "tokenPaymentRemark": "",
                "homeInspectionCj": null,
                "tokenPrStatus": 0,
                "auctionEfficiencyFlag": false,
                "customerAddress": "test, test,   400050, Saint John Baptist Road, near Federal Bank, Mount Mary, Bandra West, Mumbai, Maharashtra, India",
                "highIntentFlag": 0,
                "offered_price": 0,
                "pickup_request_status": null,
                "sell_online_acceptance": null,
                "sell_online_cep": null,
                "sell_online_reject_reason": null,
                "is_report_updated": null,
                "sell_online_retail_acceptance": null,
                "inspection_type": null,
                "home_ins_date": "2022-08-03 10:00:00",
                "msitePriceAccepted": false,
                "car_category": "hi_sell_online",
                "offer_response_date": null,
                "tpHit": 0,
                "assuredPrice": 0,
                "pll_margin": 0,
                "inv_buying": null,
                "price_acceptance_date": null,
                "hypothecation": null,
                "purchase_request_status": null,
                "ngtScrap": 0,
                "diy_penny_status": 3,
                "panCardStatus": 3,
                "aadharFrontStatus": null,
                "aadharBackStatus": null,
                "diy_doc_status": 4,
                "is_pr_submitted": null,
                "pr_created_date": null,
                "mainImage": "https://thumbor-staging.24c.in/unsafe/360x360/inspection/2022/08/1/C3DRCCY/v0/exteriorTyres/mainImages/frontmain_1659342980.jpg?width=194&height=172",
                "dealLostReason": "",
                "c24Quote": 0,
                "calculatedc24Quote": 0,
                "c24QuoteTag": "No bid",
                "c24quotExpiryTime": null,
                "oneClickBuy": 0,
                "auctionBiddingStatus": "bid_over",
                "auction_over_time": 0,
                "auction_count": 4,
                "reauctionFlag": 1,
                "auctionId": 480356,
                "auctionEndTime": "2022-08-02T15:40:01",
                "expectedOneClickAmount": 805000,
                "is_suspend": 0,
                "bidValidity": "2020-11-26",
                "currentHB": 0,
                "bestHB": 788000,
                "isInventoryBuying": false,
                "tp": 900000,
                "pai_status": null,
                "ocb_status": null,
                "is_partial_request": null,
                "request_type": null,
                "expected_hb": null,
                "negotiated_price": null,
                "requestedC24quote": null,
                "pai_timer": null,
                "raise_acceptance": null,
                "hiSellerIntent": 0,
                "cepEnable": false,
                "sortOrder": 41,
                "seller_price_eligible": true,
                "max_updated_quoted_price": 250000,
                "min_updated_quoted_price": 300000,
                "conversion": [],
                "isChatEnable": false,
                "checkWfMin": false,
                "allocateCjFlag": false,
                "leadType": "SO HI",
                "diySortOrder": 24,
                "offerStages": [
                  {
                    "key": "PRICE_OFFERED",
                    "display": true,
                    "label": "Price Offered",
                    "info": false,
                    "completed": true
                  },
                  {
                    "key": "RI_SCHEDULED",
                    "display": true,
                    "label": "RI Scheduled",
                    "info": false,
                    "completed": true
                  },
                  {
                    "key": "RI_DONE",
                    "display": true,
                    "label": "RI Done",
                    "info": false,
                    "completed": true
                  },
                  {
                    "key": "TOKEN",
                    "display": true,
                    "label": "PR Pending",
                    "info": false,
                    "completed": false
                  },
                  {
                    "key": "TOKEN_SUBMITTED",
                    "display": true,
                    "label": "PR Submission Pending",
                    "info": false,
                    "completed": false
                  }
                ],
                "documentStages": {
                  "documents": [
                    {
                      "key": "PAN_CARD",
                      "display": true,
                      "label": "PAN/Aadhar",
                      "info": false,
                      "status": 3
                    },
                    {
                      "key": "PENNY",
                      "display": true,
                      "label": "Penny",
                      "info": false,
                      "status": 3
                    }
                  ]
                },
                "inspReportCTAFlag": true
              },{
				"expType": "A",
                "exophone": "08047162072,0340",
                "appointmentId": "10396915961",
                "checkoutUrl": "",
                "storeId": "3333",
                "storeType": "locality",
                "dealCreatedDate": "2022-08-01 13:50:43",
                "originalInsDate": "2022-07-28 10:00:00",
                "currentInsDate": "2022-08-01 14:11:18",
                "stageChangedTime": "2022-08-01 14:13:04",
                "customerExpectedPrice": "0",
                "priceOffered": 0,
                "CEPPrice": 0,
                "customerPhone": "******4511",
                "customerName": "Customer",
                "followUpMessage": "",
                "followUpRemarks": "",
                "followUpTime": "",
                "isSharedC24Quote": 1,
                "retail_associate_email": "",
                "lostReason": "",
                "lostDateTime": "",
                "lostMarkedBy": "",
                "idLmsReason": "",
                "showInputOutputPage": false,
                "pai_ocb_status": null,
                "make": "MARUTI SUZUKI",
                "model": "VITARA BREZZA",
                "year": "2019",
                "variant": "VDI AMT",
                "carId": "431009",
                "ownerNumber": "1",
                "otpFlag": 1,
                "adminUrl": "http://admin-staging.c24svc.app/orders/order?toolbarFilters[o_leadId]=10396915955",
                "is_pll": 1,
                "isStarred": 0,
                "trojanStatus": 0,
                "isConnected": 0,
                "isDepReportAvailable": false,
                "isDepReportViewed": false,
                "cashbacks": [],
                "isC2cTokenDone": 0,
                "buyNowFlag": null,
                "token_vahan_status": null,
                "token_payment_status": null,
                "token_payment_update": null,
                "tokenPaymentStatus": "NOT_INITIATED",
                "tokenPaymentFlag": 0,
                "tokenPaymentRemark": "",
                "homeInspectionCj": null,
                "tokenPrStatus": 0,
                "auctionEfficiencyFlag": false,
                "customerAddress": "test, test,   400050, Saint John Baptist Road, near Federal Bank, Mount Mary, Bandra West, Mumbai, Maharashtra, India",
                "highIntentFlag": 0,
                "offered_price": 0,
                "pickup_request_status": null,
                "sell_online_acceptance": null,
                "sell_online_cep": null,
                "sell_online_reject_reason": null,
                "is_report_updated": null,
                "sell_online_retail_acceptance": null,
                "inspection_type": null,
                "home_ins_date": "2022-08-03 10:00:00",
                "msitePriceAccepted": false,
                "car_category": "hi_sell_online",
                "offer_response_date": null,
                "tpHit": 0,
                "assuredPrice": 0,
                "pll_margin": 0,
                "inv_buying": null,
                "price_acceptance_date": null,
                "hypothecation": null,
                "purchase_request_status": null,
                "ngtScrap": 0,
                "diy_penny_status": 3,
                "panCardStatus": 3,
                "aadharFrontStatus": null,
                "aadharBackStatus": null,
                "diy_doc_status": 4,
                "is_pr_submitted": null,
                "pr_created_date": null,
                "mainImage": "https://thumbor-staging.24c.in/unsafe/360x360/inspection/2022/08/1/C3DRCCY/v0/exteriorTyres/mainImages/frontmain_1659342980.jpg?width=194&height=172",
                "dealLostReason": "",
                "c24Quote": 0,
                "calculatedc24Quote": 0,
                "c24QuoteTag": "No bid",
                "c24quotExpiryTime": null,
                "oneClickBuy": 0,
                "auctionBiddingStatus": "bid_over",
                "auction_over_time": 0,
                "auction_count": 4,
                "reauctionFlag": 1,
                "auctionId": 480356,
                "auctionEndTime": "2022-08-02T15:40:01",
                "expectedOneClickAmount": 805000,
                "is_suspend": 0,
                "bidValidity": "2020-11-26",
                "currentHB": 0,
                "bestHB": 788000,
                "isInventoryBuying": false,
                "tp": 900000,
                "pai_status": null,
                "ocb_status": null,
                "is_partial_request": null,
                "request_type": null,
                "expected_hb": null,
                "negotiated_price": null,
                "requestedC24quote": null,
                "pai_timer": null,
                "raise_acceptance": null,
                "hiSellerIntent": 0,
                "cepEnable": false,
                "sortOrder": 41,
                "seller_price_eligible": true,
                "max_updated_quoted_price": 250000,
                "min_updated_quoted_price": 300000,
                "conversion": [],
                "isChatEnable": false,
                "checkWfMin": false,
                "allocateCjFlag": false,
                "leadType": "SO HI",
                "diySortOrder": 24,
                "offerStages": [
                  {
                    "key": "PRICE_OFFERED",
                    "display": true,
                    "label": "Price Offered",
                    "info": false,
                    "completed": true
                  },
                  {
                    "key": "RI_SCHEDULED",
                    "display": true,
                    "label": "RI Scheduled",
                    "info": false,
                    "completed": true
                  },
                  {
                    "key": "RI_DONE",
                    "display": true,
                    "label": "RI Done",
                    "info": false,
                    "completed": true
                  },
                  {
                    "key": "TOKEN",
                    "display": true,
                    "label": "PR Pending",
                    "info": false,
                    "completed": false
                  },
                  {
                    "key": "TOKEN_SUBMITTED",
                    "display": true,
                    "label": "PR Submission Pending",
                    "info": false,
                    "completed": false
                  }
                ],
                "documentStages": {
                  "documents": [
                    {
                      "key": "PAN_CARD",
                      "display": true,
                      "label": "PAN/Aadhar",
                      "info": false,
                      "status": 3
                    },
                    {
                      "key": "PENNY",
                      "display": true,
                      "label": "Penny",
                      "info": false,
                      "status": 3
                    }
                  ]
                },
                "inspReportCTAFlag": true
              }

            ]
          },
          "expires_in_1_days": 0,
          "expires_in_2_days": 0,
          "expires_in_3_days": 0,
          "expires_in_4_days": 0,
          "expires_in_5_days": 0,
          "expires_in_6_days": 1,
          "expires_in_7_days": 0,
          "expires_in_8_days": 0,
          "expires_in_9_days": 0,
          "expires_in_9plus_days": 0,
          "total": 1
        }
      }
  };
  return response;

};
export const getMake = () => {
  let response = {

    "name": "Car Make",

    "type": "Success",

    "message": "Make found",

    "status": "200",

    "detail": [

      {

        "make_id": 21,

        "make_name": "MARUTI SUZUKI",

        "display_order": 16,

        "make_display": "Maruti Suzuki",

        "is_popular": "yes",

        "logo": "https://static.cars24.com/cars24/make-logo/maruti-suzuki.png",

        "status": "Active"

      },

      {

        "make_id": 15,

        "make_name": "HYUNDAI",

        "display_order": 15,

        "make_display": "Hyundai",

        "is_popular": "yes",

        "logo": "https://static.cars24.com/cars24/make-logo/hyundai.png",

        "status": "Active"

      },

      {

        "make_id": 14,

        "make_name": "HONDA",

        "display_order": 14,

        "make_display": "Honda",

        "is_popular": "yes",

        "logo": "https://static.cars24.com/cars24/make-logo/honda.png",

        "status": "Active"

      },

      {

        "make_id": 34,

        "make_name": "TATA",

        "display_order": 13,

        "make_display": "Tata",

        "is_popular": "yes",

        "logo": "https://static.cars24.com/cars24/make-logo/tata.png",

        "status": "Active"

      },

      {

        "make_id": 35,

        "make_name": "TOYOTA",

        "display_order": 12,

        "make_display": "Toyota",

        "is_popular": "yes",

        "logo": "https://static.cars24.com/cars24/make-logo/toyota.png",

        "status": "Active"

      },

      {

        "make_id": 7,

        "make_name": "CHEVROLET",

        "display_order": 11,

        "make_display": "Chevrolet",

        "is_popular": "yes",

        "logo": "https://static.cars24.com/cars24/make-logo/chevrolet.png",

        "status": "Active"

      },

      {

        "make_id": 12,

        "make_name": "FORD",

        "display_order": 10,

        "make_display": "Ford",

        "is_popular": "yes",

        "logo": "https://static.cars24.com/cars24/make-logo/ford.png",

        "status": "Active"

      },

      {

        "make_id": 20,

        "make_name": "MAHINDRA",

        "display_order": 9,

        "make_display": "Mahindra",

        "is_popular": "yes",

        "logo": "https://static.cars24.com/cars24/make-logo/mahindra.png",

        "status": "Active"

      }
    ]

  };
  return response;

};
export const getModel = (makeId) => {
  let response = {
    "type": "Car Model",
    "title": "Model found",
    "status": "200",
    "detail": [
      {
        "model_id": "299",
        "model_name": "1000"
      },
      {
        "model_id": "133",
        "model_name": "800"
      },
      {
        "model_id": "134",
        "model_name": "A STAR"
      },
      {
        "model_id": "135",
        "model_name": "ALTO"
      },
      {
        "model_id": "136",
        "model_name": "ALTO 800"
      },
      {
        "model_id": "330",
        "model_name": "ALTO K10"
      },
      {
        "model_id": "295",
        "model_name": "BALENO"
      },
      {
        "model_id": "137",
        "model_name": "CELERIO"
      },
      {
        "model_id": "346",
        "model_name": "CELERIO X"
      },
      {
        "model_id": "138",
        "model_name": "CIAZ"
      },
      {
        "model_id": "348",
        "model_name": "DZIRE"
      },
      {
        "model_id": "139",
        "model_name": "EECO"
      },
      {
        "model_id": "140",
        "model_name": "ERTIGA"
      },
      {
        "model_id": "282",
        "model_name": "ESTEEM"
      },
      {
        "model_id": "141",
        "model_name": "GRAND VITARA"
      },
      {
        "model_id": "142",
        "model_name": "GYPSY"
      },
      {
        "model_id": "382",
        "model_name": "HYUNDAI "
      },
      {
        "model_id": "345",
        "model_name": "IGNIS"
      },
      {
        "model_id": "143",
        "model_name": "KIZASHI"
      },
      {
        "model_id": "144",
        "model_name": "OMNI"
      },
      {
        "model_id": "398",
        "model_name": "OMNI E"
      },
      {
        "model_id": "145",
        "model_name": "RITZ"
      },
      {
        "model_id": "331",
        "model_name": "S CROSS"
      },
      {
        "model_id": "146",
        "model_name": "SWIFT"
      },
      {
        "model_id": "147",
        "model_name": "SWIFT DZIRE"
      },
      {
        "model_id": "148",
        "model_name": "SX4"
      },
      {
        "model_id": "423",
        "model_name": "TestModel"
      },
      {
        "model_id": "275",
        "model_name": "VERSA"
      },
      {
        "model_id": "338",
        "model_name": "VITARA BREZZA"
      },
      {
        "model_id": "149",
        "model_name": "WAGON R"
      },
      {
        "model_id": "150",
        "model_name": "WAGON R 1.0"
      },
      {
        "model_id": "276",
        "model_name": "WAGON R DUO"
      },
      {
        "model_id": "151",
        "model_name": "WAGON R STINGRAY"
      },
      {
        "model_id": "300",
        "model_name": "ZEN"
      },
      {
        "model_id": "152",
        "model_name": "ZEN ESTILO"
      }
    ]
  };
  return response;
};
export const getYear = () => {
  let response = {
    "type": "List Model Year Variant", "title": "Model Year Variant", "status": "200", "detail": {
      "2012": [{
        "year": "2012",
        "variantyear_id": "12709",
        "variant_name": "ALTO 800 LXI AIRBAG",
        "variant_id": "803",
        "variant_name_short": "ALTO 800 LXI AIRBAG",
        "transportation_category": "Category_1",
        "variant_status": "Inactive",
        "variantyear_status": "Inactive"
      }, {
        "year": "2012",
        "variantyear_id": "4800",
        "variant_name": "LX",
        "variant_id": "807",
        "variant_name_short": "LX",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2012",
        "variantyear_id": "4801",
        "variant_name": "LX CNG",
        "variant_id": "808",
        "variant_name_short": "LX CNG",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2012",
        "variantyear_id": "4802",
        "variant_name": "LXI",
        "variant_id": "809",
        "variant_name_short": "LXI",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2012",
        "variantyear_id": "4804",
        "variant_name": "LXI CNG",
        "variant_id": "811",
        "variant_name_short": "LXI CNG",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2012",
        "variantyear_id": "4803",
        "variant_name": "LXI CNG AIR BAG",
        "variant_id": "810",
        "variant_name_short": "LXI AIR BAG",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2012",
        "variantyear_id": "20078",
        "variant_name": "LXI OPT",
        "variant_id": "3554",
        "variant_name_short": "LXI O",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2012",
        "variantyear_id": "4805",
        "variant_name": "STD",
        "variant_id": "812",
        "variant_name_short": "STD",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2012",
        "variantyear_id": "4806",
        "variant_name": "STD CNG",
        "variant_id": "813",
        "variant_name_short": "STD CNG",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }],
      "2013": [{
        "year": "2013",
        "variantyear_id": "12710",
        "variant_name": "ALTO 800 LXI AIRBAG",
        "variant_id": "803",
        "variant_name_short": "ALTO 800 LXI AIRBAG",
        "transportation_category": "Category_1",
        "variant_status": "Inactive",
        "variantyear_status": "Inactive"
      }, {
        "year": "2013",
        "variantyear_id": "3677",
        "variant_name": "LX",
        "variant_id": "807",
        "variant_name_short": "LX",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2013",
        "variantyear_id": "3678",
        "variant_name": "LX CNG",
        "variant_id": "808",
        "variant_name_short": "LX CNG",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2013",
        "variantyear_id": "3679",
        "variant_name": "LXI",
        "variant_id": "809",
        "variant_name_short": "LXI",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2013",
        "variantyear_id": "3681",
        "variant_name": "LXI CNG",
        "variant_id": "811",
        "variant_name_short": "LXI CNG",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2013",
        "variantyear_id": "3680",
        "variant_name": "LXI CNG AIR BAG",
        "variant_id": "810",
        "variant_name_short": "LXI AIR BAG",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2013",
        "variantyear_id": "18267",
        "variant_name": "LXI OPT",
        "variant_id": "3554",
        "variant_name_short": "LXI O",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2013",
        "variantyear_id": "3682",
        "variant_name": "STD",
        "variant_id": "812",
        "variant_name_short": "STD",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2013",
        "variantyear_id": "3683",
        "variant_name": "STD CNG",
        "variant_id": "813",
        "variant_name_short": "STD CNG",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2013",
        "variantyear_id": "3676",
        "variant_name": "VXI",
        "variant_id": "805",
        "variant_name_short": "ALTO 800 VXI",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }],
      "2014": [{
        "year": "2014",
        "variantyear_id": "2316",
        "variant_name": "ALTO 800 LXI AIRBAG",
        "variant_id": "803",
        "variant_name_short": "ALTO 800 LXI AIRBAG",
        "transportation_category": "Category_1",
        "variant_status": "Inactive",
        "variantyear_status": "Inactive"
      }, {
        "year": "2014",
        "variantyear_id": "2317",
        "variant_name": "ALTO 800 STD CNG",
        "variant_id": "804",
        "variant_name_short": "ALTO 800 STD CNG",
        "transportation_category": "Category_1",
        "variant_status": "Inactive",
        "variantyear_status": "Inactive"
      }, {
        "year": "2014",
        "variantyear_id": "18923",
        "variant_name": "ANNIVERSARY EDITION ",
        "variant_id": "3761",
        "variant_name_short": null,
        "transportation_category": "Category_1",
        "variant_status": "Inactive",
        "variantyear_status": "Inactive"
      }, {
        "year": "2014",
        "variantyear_id": "2320",
        "variant_name": "LX",
        "variant_id": "807",
        "variant_name_short": "LX",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2014",
        "variantyear_id": "2321",
        "variant_name": "LX CNG",
        "variant_id": "808",
        "variant_name_short": "LX CNG",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2014",
        "variantyear_id": "2322",
        "variant_name": "LXI",
        "variant_id": "809",
        "variant_name_short": "LXI",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2014",
        "variantyear_id": "2324",
        "variant_name": "LXI CNG",
        "variant_id": "811",
        "variant_name_short": "LXI CNG",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2014",
        "variantyear_id": "2323",
        "variant_name": "LXI CNG AIR BAG",
        "variant_id": "810",
        "variant_name_short": "LXI AIR BAG",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2014",
        "variantyear_id": "2325",
        "variant_name": "STD",
        "variant_id": "812",
        "variant_name_short": "STD",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2014",
        "variantyear_id": "2326",
        "variant_name": "STD CNG",
        "variant_id": "813",
        "variant_name_short": "STD CNG",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2014",
        "variantyear_id": "2318",
        "variant_name": "VXI",
        "variant_id": "805",
        "variant_name_short": "ALTO 800 VXI",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2014",
        "variantyear_id": "2319",
        "variant_name": "VXI AIRBAG",
        "variant_id": "806",
        "variant_name_short": "ALTO 800 VXI AIRBAG",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }],
      "2015": [{
        "year": "2015",
        "variantyear_id": "803",
        "variant_name": "ALTO 800 LXI AIRBAG",
        "variant_id": "803",
        "variant_name_short": "ALTO 800 LXI AIRBAG",
        "transportation_category": "Category_1",
        "variant_status": "Inactive",
        "variantyear_status": "Inactive"
      }, {
        "year": "2015",
        "variantyear_id": "804",
        "variant_name": "ALTO 800 STD CNG",
        "variant_id": "804",
        "variant_name_short": "ALTO 800 STD CNG",
        "transportation_category": "Category_1",
        "variant_status": "Inactive",
        "variantyear_status": "Inactive"
      }, {
        "year": "2015",
        "variantyear_id": "807",
        "variant_name": "LX",
        "variant_id": "807",
        "variant_name_short": "LX",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2015",
        "variantyear_id": "808",
        "variant_name": "LX CNG",
        "variant_id": "808",
        "variant_name_short": "LX CNG",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2015",
        "variantyear_id": "809",
        "variant_name": "LXI",
        "variant_id": "809",
        "variant_name_short": "LXI",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2015",
        "variantyear_id": "811",
        "variant_name": "LXI CNG",
        "variant_id": "811",
        "variant_name_short": "LXI CNG",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2015",
        "variantyear_id": "810",
        "variant_name": "LXI CNG AIR BAG",
        "variant_id": "810",
        "variant_name_short": "LXI AIR BAG",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2015",
        "variantyear_id": "812",
        "variant_name": "STD",
        "variant_id": "812",
        "variant_name_short": "STD",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2015",
        "variantyear_id": "813",
        "variant_name": "STD CNG",
        "variant_id": "813",
        "variant_name_short": "STD CNG",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2015",
        "variantyear_id": "805",
        "variant_name": "VXI",
        "variant_id": "805",
        "variant_name_short": "ALTO 800 VXI",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2015",
        "variantyear_id": "806",
        "variant_name": "VXI AIRBAG",
        "variant_id": "806",
        "variant_name_short": "ALTO 800 VXI AIRBAG",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }],
      "2016": [{
        "year": "2016",
        "variantyear_id": "12711",
        "variant_name": "ALTO 800 LXI AIRBAG",
        "variant_id": "803",
        "variant_name_short": "ALTO 800 LXI AIRBAG",
        "transportation_category": "Category_1",
        "variant_status": "Inactive",
        "variantyear_status": "Inactive"
      }, {
        "year": "2016",
        "variantyear_id": "14112",
        "variant_name": "LX",
        "variant_id": "807",
        "variant_name_short": "LX",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2016",
        "variantyear_id": "12707",
        "variant_name": "LX CNG",
        "variant_id": "808",
        "variant_name_short": "LX CNG",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2016",
        "variantyear_id": "14113",
        "variant_name": "LXI",
        "variant_id": "809",
        "variant_name_short": "LXI",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2016",
        "variantyear_id": "12706",
        "variant_name": "LXI CNG",
        "variant_id": "811",
        "variant_name_short": "LXI CNG",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2016",
        "variantyear_id": "19892",
        "variant_name": "LXI CNG OPT",
        "variant_id": "4019",
        "variant_name_short": "LXI CNG OPT",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Inactive"
      }, {
        "year": "2016",
        "variantyear_id": "19557",
        "variant_name": "LXI OPT",
        "variant_id": "3554",
        "variant_name_short": "LXI O",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2016",
        "variantyear_id": "14111",
        "variant_name": "STD",
        "variant_id": "812",
        "variant_name_short": "STD",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2016",
        "variantyear_id": "12705",
        "variant_name": "STD CNG",
        "variant_id": "813",
        "variant_name_short": "STD CNG",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2016",
        "variantyear_id": "14110",
        "variant_name": "VXI",
        "variant_id": "805",
        "variant_name_short": "ALTO 800 VXI",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2016",
        "variantyear_id": "12708",
        "variant_name": "VXI AIRBAG",
        "variant_id": "806",
        "variant_name_short": "ALTO 800 VXI AIRBAG",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }],
      "2017": [{
        "year": "2017",
        "variantyear_id": "14922",
        "variant_name": "ALTO 800 LXI AIRBAG",
        "variant_id": "803",
        "variant_name_short": "ALTO 800 LXI AIRBAG",
        "transportation_category": "Category_1",
        "variant_status": "Inactive",
        "variantyear_status": "Inactive"
      }, {
        "year": "2017",
        "variantyear_id": "15478",
        "variant_name": "LX",
        "variant_id": "807",
        "variant_name_short": "LX",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2017",
        "variantyear_id": "14923",
        "variant_name": "LX CNG",
        "variant_id": "808",
        "variant_name_short": "LX CNG",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2017",
        "variantyear_id": "15639",
        "variant_name": "LXI",
        "variant_id": "809",
        "variant_name_short": "LXI",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2017",
        "variantyear_id": "14460",
        "variant_name": "LXI CNG",
        "variant_id": "811",
        "variant_name_short": "LXI CNG",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2017",
        "variantyear_id": "19825",
        "variant_name": "LXI CNG OPT",
        "variant_id": "4019",
        "variant_name_short": "LXI CNG OPT",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2017",
        "variantyear_id": "18796",
        "variant_name": "LXI OPT",
        "variant_id": "3554",
        "variant_name_short": "LXI O",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2017",
        "variantyear_id": "15479",
        "variant_name": "STD",
        "variant_id": "812",
        "variant_name_short": "STD",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2017",
        "variantyear_id": "14924",
        "variant_name": "STD CNG",
        "variant_id": "813",
        "variant_name_short": "STD CNG",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2017",
        "variantyear_id": "15480",
        "variant_name": "VXI",
        "variant_id": "805",
        "variant_name_short": "ALTO 800 VXI",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2017",
        "variantyear_id": "14925",
        "variant_name": "VXI AIRBAG",
        "variant_id": "806",
        "variant_name_short": "ALTO 800 VXI AIRBAG",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }],
      "2018": [{
        "year": "2018",
        "variantyear_id": "17333",
        "variant_name": "LX",
        "variant_id": "807",
        "variant_name_short": "LX",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2018",
        "variantyear_id": "17334",
        "variant_name": "LX CNG",
        "variant_id": "808",
        "variant_name_short": "LX CNG",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2018",
        "variantyear_id": "17335",
        "variant_name": "LXI",
        "variant_id": "809",
        "variant_name_short": "LXI",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2018",
        "variantyear_id": "17336",
        "variant_name": "LXI CNG",
        "variant_id": "811",
        "variant_name_short": "LXI CNG",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2018",
        "variantyear_id": "20165",
        "variant_name": "LXI CNG OPT",
        "variant_id": "4019",
        "variant_name_short": "LXI CNG OPT",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2018",
        "variantyear_id": "18795",
        "variant_name": "LXI OPT",
        "variant_id": "3554",
        "variant_name_short": "LXI O",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2018",
        "variantyear_id": "17337",
        "variant_name": "STD",
        "variant_id": "812",
        "variant_name_short": "STD",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2018",
        "variantyear_id": "17338",
        "variant_name": "STD CNG",
        "variant_id": "813",
        "variant_name_short": "STD CNG",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2018",
        "variantyear_id": "17339",
        "variant_name": "VXI",
        "variant_id": "805",
        "variant_name_short": "ALTO 800 VXI",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }, {
        "year": "2018",
        "variantyear_id": "17340",
        "variant_name": "VXI AIRBAG",
        "variant_id": "806",
        "variant_name_short": "ALTO 800 VXI AIRBAG",
        "transportation_category": "Category_1",
        "variant_status": "Active",
        "variantyear_status": "Active"
      }]
    }
  };
  return response;

};
export const getState = () => {
  let response = {

    "name": "Car Model",

    "type": "Success",

    "message": "Model found",

    "status": "200",

    "detail": [
      {

        "state_id": 1,

        "state_code": "DL",

        "state_name": "Delhi"

      },

      {

        "state_id": 2,

        "state_code": "HR",

        "state_name": "Haryana"

      },

      {

        "state_id": 3,

        "state_code": "UP",

        "state_name": "Uttar Pradesh"

      },

      {

        "state_id": 19,

        "state_code": "KA",

        "state_name": "Karnataka"

      },

      {

        "state_id": 16,

        "state_code": "MP",

        "state_name": "Madhya Pradesh"

      },

      {

        "state_id": 14,

        "state_code": "MH",

        "state_name": "Maharashtra"

      },

      {

        "state_id": 18,

        "state_code": "AP",

        "state_name": "Andhra Pradesh"

      },

      {

        "state_id": 22,

        "state_code": "KL",

        "state_name": "Kerala"

      },

      {

        "state_id": 36,

        "state_code": "TS",

        "state_name": "Telangana"

      },

      {

        "state_id": 11,

        "state_code": "RJ",

        "state_name": "Rajasthan"

      },

      {

        "state_id": 5,

        "state_code": "CH",

        "state_name": "Chandigarh"

      },

      {

        "state_id": 4,

        "state_code": "PB",

        "state_name": "Punjab"

      },

      {

        "state_id": 6,

        "state_code": "WB",

        "state_name": "West Bengal"

      },

      {

        "state_id": 24,

        "state_code": "AS",

        "state_name": "Assam"

      },

      {

        "state_id": 34,

        "state_code": "BR",

        "state_name": "Bihar"

      },

      {

        "state_id": 17,

        "state_code": "CG",

        "state_name": "Chhattisgarh"

      },

      {

        "state_id": 15,

        "state_code": "GA",

        "state_name": "Goa"

      },

      {

        "state_id": 10,

        "state_code": "GJ",

        "state_name": "Gujarat"

      },

      {

        "state_id": 7,

        "state_code": "HP",

        "state_name": "Himachal Pradesh"

      },

      {

        "state_id": 8,

        "state_code": "JK",

        "state_name": "Jammu and Kashmir"

      },

      {

        "state_id": 35,

        "state_code": "JH",

        "state_name": "Jharkhand"

      }
    ]

  };
  return response;

};
export const getOdometer = () => {
  let response = {

    "name": "Car Model",

    "type": "Success",

    "message": "Model found",

    "status": "200",

    "detail": [
      {

        "km_display": "Up to 10000 km",

        "km_value": 5000

      },

      {

        "km_display": "10000 - 20000 km",

        "km_value": 15000

      },

      {

        "km_display": "20000 - 40000 km",

        "km_value": 30000

      },

      {

        "km_display": "40000 - 60000 km",

        "km_value": 50000

      },

      {

        "km_display": "60000 - 100000 km",

        "km_value": 80000

      },

      {

        "km_display": "Above 100000 km",

        "km_value": 120000

      }
    ]

  };
  return response;

};
export const getCity = () => {
  let response = {

    "name": "Car Model",

    "type": "Success",

    "message": "Model found",

    "status": "200",

    "detail": [
      {
        "cityId": "1692",
        "stateId": "10",
        "cityName": "Ahmedabad"
      },
      {
        "cityId": "2137",
        "stateId": "11",
        "cityName": "Ajmer"
      },
      {
        "cityId": "18",
        "stateId": "2",
        "cityName": "Akoda"
      },
      {
        "cityId": "684",
        "stateId": "4",
        "cityName": "Amritsar"
      },
      {
        "cityId": "4709",
        "stateId": "19",
        "cityName": "Bengaluru"
      },
      {
        "cityId": "11",
        "stateId": "2",
        "cityName": "Bhiwadi"
      },
      {
        "cityId": "769",
        "stateId": "5",
        "cityName": "Chandigarh"
      },
      {
        "cityId": "5732",
        "stateId": "20",
        "cityName": "Chennai"
      },
      {
        "cityId": "8597",
        "stateId": "4",
        "cityName": "City1"
      },
      {
        "cityId": "3",
        "stateId": "1",
        "cityName": "Delhi West"
      },
      {
        "cityId": "6",
        "stateId": "2",
        "cityName": "Faridabad"
      },
      {
        "cityId": "132",
        "stateId": "3",
        "cityName": "Ghaziabad"
      },
      {
        "cityId": "2889",
        "stateId": "15",
        "cityName": "Goa"
      },
      {
        "cityId": "5",
        "stateId": "2",
        "cityName": "Gurgaon"
      },
      {
        "cityId": "2948",
        "stateId": "16",
        "cityName": "Gwalior"
      },
      {
        "cityId": "9",
        "stateId": "2",
        "cityName": "Hodal"
      },
      {
        "cityId": "3686",
        "stateId": "36",
        "cityName": "Hyderabad"
      },
      {
        "cityId": "2920",
        "stateId": "16",
        "cityName": "Indore"
      },
      {
        "cityId": "2130",
        "stateId": "11",
        "cityName": "Jaipur"
      },
      {
        "cityId": "6356",
        "stateId": "22",
        "cityName": "Kochi"
      },
      {
        "cityId": "4",
        "stateId": "2",
        "cityName": "Kurukshetra"
      },
      {
        "cityId": "4862",
        "stateId": "19",
        "cityName": "Mangalore"
      },
      {
        "cityId": "650",
        "stateId": "4",
        "cityName": "Mohali"
      },
      {
        "cityId": "2378",
        "stateId": "14",
        "cityName": "Mumbai"
      },
      {
        "cityId": "2",
        "stateId": "1",
        "cityName": "New Delhi"
      },
      {
        "cityId": "134",
        "stateId": "3",
        "cityName": "Noida"
      },
      {
        "cityId": "7",
        "stateId": "2",
        "cityName": "Palwal"
      },
      {
        "cityId": "2423",
        "stateId": "14",
        "cityName": "Pune"
      },
      {
        "cityId": "8598",
        "stateId": "5",
        "cityName": "Rajattest"
      },
      {
        "cityId": "12",
        "stateId": "2",
        "cityName": "Sohna"
      },
      {
        "cityId": "62",
        "stateId": "2",
        "cityName": "Sonipat"
      },
      {
        "cityId": "1605",
        "stateId": "10",
        "cityName": "Surat"
      },
      {
        "cityId": "8601",
        "stateId": "5",
        "cityName": "test city"
      },
      {
        "cityId": "8599",
        "stateId": "2",
        "cityName": "uilo"
      }
    ]

  };
  return response;

};
export const getBranch = () => {
  let response = {
    "branchList": [
      {
        "location_id": "104",
        "name": "B2C",
        "crm_account_id": "342",
        "type": "B2C",
        "is_prepaid": "no",
        "campaign_image": "https://thumbor-staging.24c.in/unsafe/smart/cars24/c24campaigns/C24_IMG_1576158895_7277.jpg"
      },
      {
        "location_id": "2",
        "name": "Cyber City",
        "crm_account_id": "3168",
        "type": "store",
        "is_prepaid": "no",
        "campaign_image": "https://thumbor-staging.24c.in/unsafe/smart/cars24/c24campaigns/C24_IMG_1576158895_7277.jpg"
      },
      {
        "location_id": "1",
        "name": "Golf Course Extension",
        "crm_account_id": "3167",
        "type": "store",
        "is_prepaid": "no",
        "campaign_image": "https://thumbor-staging.24c.in/unsafe/smart/cars24/c24campaigns/C24_IMG_1576158895_7277.jpg"
      },
      {
        "location_id": "172",
        "name": "Huda City",
        "crm_account_id": null,
        "type": "franchise",
        "is_prepaid": "no",
        "campaign_image": "https://thumbor-staging.24c.in/unsafe/smart/cars24/c24campaigns/C24_IMG_1576158895_7277.jpg"
      },
      {
        "location_id": "94",
        "name": "MG Road Sector 14",
        "crm_account_id": "3295",
        "type": "store",
        "is_prepaid": "no",
        "campaign_image": "https://thumbor-staging.24c.in/unsafe/smart/cars24/c24campaigns/C24_IMG_1576158895_7277.jpg"
      },
      {
        "location_id": "174",
        "name": "sohna road",
        "crm_account_id": null,
        "type": "franchise",
        "is_prepaid": "no",
        "campaign_image": "https://thumbor-staging.24c.in/unsafe/smart/cars24/c24campaigns/C24_IMG_1576158895_7277.jpg"
      },
      {
        "location_id": "207",
        "name": "Test Store B2c",
        "crm_account_id": null,
        "type": "B2C",
        "is_prepaid": "no",
        "campaign_image": "https://thumbor-staging.24c.in/unsafe/smart/cars24/c24campaigns/C24_IMG_1576158895_7277.jpg"
      },
      {
        "location_id": "190",
        "name": "testgurgaon",
        "crm_account_id": null,
        "type": "store",
        "is_prepaid": "no",
        "campaign_image": "https://thumbor-staging.24c.in/unsafe/smart/cars24/c24campaigns/C24_IMG_1576158895_7277.jpg"
      },
      {
        "location_id": "206",
        "name": "viztest",
        "crm_account_id": null,
        "type": "store",
        "is_prepaid": "no",
        "campaign_image": "https://thumbor-staging.24c.in/unsafe/smart/cars24/c24campaigns/C24_IMG_1576158895_7277.jpg"
      }
    ],
    "status": 200
  };
  return response;

};
export const getSlots = () => {
  let response = {
    "name": "Branch Appointment Slots",
    "code": 0,
    "status": 200,
    "detail": {
      "id": 172,
      "location_id": 172,
      "city_id": 5,
      "location_name": "Huda City",
      "is_open_slot": "Yes",
      "start_time": "10:00:00",
      "address_detail": {
        "address1": "Huda city centre metro station",
        "address2": "Gurugram",
        "city_name": "Gurgaon",
        "pin_code": 122001,
        "state": "Haryana",
        "direction": "https://goo.gl/Yr6xvy"
      },
      "date_slot": {
        "2019-12-20": {
          "date": "2019-12-20",
          "value": "Fri, 20-Dec",
          "mobile_value": "Fri, 20th Dec",
          "time": {
            "Morning": {
              "10:00:21": {
                "time": "Open Slot  (10:00 AM - 7:00 PM)",
                "status": "AF"
              }
            },
            "Afternoon": {
              "10:00:22": {
                "time": "Open Slot  (10:00 AM - 7:00 PM)",
                "status": "AF"
              }
            },
            "Evening": {
              "10:00:23": {
                "time": "Open Slot  (10:00 AM - 7:00 PM)",
                "status": "AF"
              }
            }
          }
        },
        "2019-12-21": {
          "date": "2019-12-21",
          "value": "Sat, 21-Dec",
          "mobile_value": "Sat, 21st Dec",
          "time": {
            "Morning": {
              "10:00:00": {
                "time": "Open Slot  (10:00 AM - 7:00 PM)",
                "status": "AF"
              }
            }
          }
        },
        "2019-12-22": {
          "date": "2019-12-22",
          "value": "Sun, 22-Dec",
          "mobile_value": "Sun, 22nd Dec",
          "time": {
            "Morning": {
              "10:00:00": {
                "time": "Open Slot  (10:00 AM - 7:00 PM)",
                "status": "AF"
              }
            }
          }
        },
        "2019-12-23": {
          "date": "2019-12-23",
          "value": "Mon, 23-Dec",
          "mobile_value": "Mon, 23rd Dec",
          "time": {
            "Morning": {
              "10:00:00": {
                "time": "Open Slot  (10:00 AM - 7:00 PM)",
                "status": "AF"
              }
            }
          }
        },
        "2019-12-24": {
          "date": "2019-12-24",
          "value": "Tue, 24-Dec",
          "mobile_value": "Tue, 24th Dec",
          "time": {
            "Morning": {
              "10:00:00": {
                "time": "Open Slot  (10:00 AM - 7:00 PM)",
                "status": "AF"
              }
            }
          }
        },
        "2019-12-25": {
          "date": "2019-12-25",
          "value": "Wed, 25-Dec",
          "mobile_value": "Wed, 25th Dec",
          "time": {
            "Morning": {
              "10:00:00": {
                "time": "Open Slot  (10:00 AM - 7:00 PM)",
                "status": "AF"
              }
            }
          }
        },
        "2019-12-26": {
          "date": "2019-12-26",
          "value": "Thu, 26-Dec",
          "mobile_value": "Thu, 26th Dec",
          "time": {
            "Morning": {
              "10:00:00": {
                "time": "Open Slot  (10:00 AM - 7:00 PM)",
                "status": "AF"
              }
            }
          }
        },
        "2019-12-27": {
          "date": "2019-12-27",
          "value": "Fri, 27-Dec",
          "mobile_value": "Fri, 27th Dec",
          "time": {
            "Morning": {
              "10:00:00": {
                "time": "Open Slot  (10:00 AM - 7:00 PM)",
                "status": "AF"
              }
            }
          }
        }
      }
    },
    "type": "success"
  };
  return response;
};

export const getMarketingQuestionsMoq = () => {
  let response = {
    data: {
      "type": "http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html",
      "title": "Get post check-in questions",
      "status": 200,
      "detail": [
        {
          "questionId": 1,
          "questionText": "Are you planning to purchase a car?",
          "isDefault": 1,
          "isVisible": 1,
          "answers": [
            {
              "answerId": 1,
              "answerText": "Yes, a new car"
            },
            {
              "answerId": 2,
              "answerText": "Yes, a used car"
            },
            {
              "answerId": 3,
              "answerText": "No"
            }
          ]
        },
        {
          "questionId": 2,
          "questionText": "When are you planning to buy a user car?",
          "isDefault": 1,
          "isVisible": 0,
          "answers": [
            {
              "answerId": 4,
              "answerText": "Within 7 days"
            },
            {
              "answerId": 5,
              "answerText": "7 to 15 days"
            },
            {
              "answerId": 6,
              "answerText": "Within a month"
            },
            {
              "answerId": 7,
              "answerText": "After a month"
            }
          ]
        },
        {
          "questionId": 3,
          "questionText": "What is your occupation?",
          "isDefault": 0,
          "isVisible": 1,
          "answers": [
            {
              "answerId": 8,
              "answerText": "Salaried"
            },
            {
              "answerId": 9,
              "answerText": "Business owner"
            }
          ]
        },
        {
          "questionId": 4,
          "questionText": "Is this your first time selling a car?",
          "isDefault": 0,
          "isVisible": 1,
          "answers": [
            {
              "answerId": 10,
              "answerText": "Yes"
            },
            {
              "answerId": 11,
              "answerText": "No, I have sold a car before"
            }
          ]
        },
        {
          "questionId": 5,
          "questionText": "Approximately, how far did you travel to visit CARS24 branch?",
          "isDefault": 0,
          "isVisible": 1,
          "answers": [
            {
              "answerId": 12,
              "answerText": "Less than 5kms"
            },
            {
              "answerId": 13,
              "answerText": "5-10kms"
            },
            {
              "answerId": 14,
              "answerText": "11- 20kms"
            },
            {
              "answerId": 15,
              "answerText": "More than 20kms"
            }
          ]
        }
      ]
    }
  };
  return response;
};
export const getSourceOfAwareness = () => {
  let response = {
    "odometer": {
      "5000": "Up to 10000",
      "15000": "10000-20000",
      "30000": "20000-40000",
      "50000": "40000-60000",
      "80000": "60000-100000",
      "120000": "Above 100000 km"
    },
    "awarenessSource": {
      "googlesearch": "Google/ Online Search",
      "facebook": "Facebook",
      "tvc": "TV advertisement",
      "radioad": "Radio advertisement",
      "outdoorad": "Outdoor (Hoardings, Bus, Cab Branding, etc) Advertisement.",
      "call": "Received a call from CARS24",
      "friendreffered": "Referred by a friend/relative",
      "newspaper": "Newspaper/ Article/ Print Advertisement",
      "movietheaterad": "Movie Theater Advertisement",
      "others": "Others"
    }
  };
  return response;
};
export const getAppointmentList = (storeId) => {
  let response = {};
  if (storeId === "3185") {
    response = {
      data: {
        "type": "http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html",
        "title": "Pll appointment list",
        "status":
          200,
        "detail":
          [
            {
              "storeId": "3185",
              "storeName": "Golf Course Extension",
              "appointmentList": [
                {
                  "appointment": "1017909599",
                  "id_lms_users": null,
                  "email": null,
                  "first_name": null,
                  "last_name": null,
                  "c24_Quote": 120000,
                  "is_expired": false
                },
                {
                  "appointment": "1018736590",
                  "id_lms_users": null,
                  "email": null,
                  "first_name": null,
                  "last_name": null,
                  "c24_Quote": 520000,
                  "is_expired": true
                },
                {
                  "appointment": "1015865597",
                  "id_lms_users": "505",
                  "email": "Rajat.manga@cars24.com",
                  "first_name": "Rajat",
                  "last_name": "Manga",
                  "c24_Quote": 320000,
                  "is_expired": false
                },
                {
                  "appointment": "1029979599",
                  "id_lms_users": "505",
                  "email": "Rajat.manga@cars24.com",
                  "first_name": "Rajat",
                  "last_name": "Manga",
                  "c24_Quote": 620000,
                  "is_expired": true
                }
              ]
            }
          ]
      }
    };
  }
  else if (storeId === 3168) {
    response = {
      data: {
        "type": "http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html",
        "title": "Pll appointment list",
        "status":
          200,
        "detail":
          [
            {
              "storeId": "3168",
              "storeName": "Cyber City",
              "appointmentList": [
                {
                  "appointment": "1017909399",
                  "id_lms_users": null,
                  "email": null,
                  "first_name": null,
                  "last_name": null,
                  "c24_Quote": 120000
                },
                {
                  "appointment": "1018736690",
                  "id_lms_users": null,
                  "email": null,
                  "first_name": null,
                  "last_name": null,
                  "c24_Quote": 220000
                },
                {
                  "appointment": "1016875697",
                  "id_lms_users": null,
                  "email": null,
                  "first_name": null,
                  "last_name": null,
                  "c24_Quote": 140000
                },
                {
                  "appointment": "1018489971",
                  "id_lms_users": null,
                  "email": null,
                  "first_name": null,
                  "last_name": null,
                  "c24_Quote": 320000
                },
                {
                  "appointment": "G11905893",
                  "id_lms_users": null,
                  "email": null,
                  "first_name": null,
                  "last_name": null,
                  "c24_Quote": 220000
                },
                {
                  "appointment": "1016865297",
                  "id_lms_users": null,
                  "email": null,
                  "first_name": null,
                  "last_name": null,
                  "c24_Quote": 220000
                },
                {
                  "appointment": "1076138940",
                  "id_lms_users": null,
                  "email": null,
                  "first_name": null,
                  "last_name": null,
                  "c24_Quote": 320000
                },
                {
                  "appointment": "1053601971",
                  "id_lms_users": null,
                  "email": null,
                  "first_name": null,
                  "last_name": null,
                  "c24_Quote": 520000
                },
                {
                  "appointment": "1015347999",
                  "id_lms_users": null,
                  "email": null,
                  "first_name": null,
                  "last_name": null,
                  "c24_Quote": 520000
                },
                {
                  "appointment": "1013865897",
                  "id_lms_users": null,
                  "email": null,
                  "first_name": null,
                  "last_name": null,
                  "c24_Quote": 620000
                },
                {
                  "appointment": "1016061471",
                  "id_lms_users": null,
                  "email": null,
                  "first_name": null,
                  "last_name": null,
                  "c24_Quote": 420000
                },
                {
                  "appointment": "1015825297",
                  "id_lms_users": null,
                  "email": null,
                  "first_name": null,
                  "last_name": null,
                  "c24_Quote": 420000
                },
                {
                  "appointment": "1015875497",
                  "id_lms_users": null,
                  "email": null,
                  "first_name": null,
                  "last_name": null,
                  "c24_Quote": 220000
                },
                {
                  "appointment": "1026929299",
                  "id_lms_users": null,
                  "email": null,
                  "first_name": null,
                  "last_name": null,
                  "c24_Quote": 220000
                },
                {
                  "appointment": "1044202971",
                  "id_lms_users": null,
                  "email": null,
                  "first_name": null,
                  "last_name": null
                },
                {
                  "appointment": "1028794671",
                  "id_lms_users": null,
                  "email": null,
                  "first_name": null,
                  "last_name": null
                },
                {
                  "appointment": "1028554671",
                  "id_lms_users": null,
                  "email": null,
                  "first_name": null,
                  "last_name": null
                },
                {
                  "appointment": "1016825397",
                  "id_lms_users": null,
                  "email": null,
                  "first_name": null,
                  "last_name": null
                },
                {
                  "appointment": "1018815297",
                  "id_lms_users": null,
                  "email": null,
                  "first_name": null,
                  "last_name": null
                },
                {
                  "appointment": "1106875497",
                  "id_lms_users": null,
                  "email": null,
                  "first_name": null,
                  "last_name": null
                },
                {
                  "appointment": "1012865697",
                  "id_lms_users": null,
                  "email": null,
                  "first_name": null,
                  "last_name": null
                },
                {
                  "appointment": "102557278",
                  "id_lms_users": null,
                  "email": null,
                  "first_name": null,
                  "last_name": null
                },
                {
                  "appointment": "1017939999",
                  "id_lms_users": null,
                  "email": null,
                  "first_name": null,
                  "last_name": null
                },
                {
                  "appointment": "1011805297",
                  "id_lms_users": null,
                  "email": null,
                  "first_name": null,
                  "last_name": null
                },
                {
                  "appointment": "1013845297",
                  "id_lms_users": null,
                  "email": null,
                  "first_name": null,
                  "last_name": null
                },
                {
                  "appointment": "1015979199",
                  "id_lms_users": null,
                  "email": null,
                  "first_name": null,
                  "last_name": null
                },
                {
                  "appointment": "1015865497",
                  "id_lms_users": "505",
                  "email": "Rajat.manga@cars24.com",
                  "first_name": "Rajat",
                  "last_name": "Manga"
                },
                {
                  "appointment": "1029979499",
                  "id_lms_users": "505",
                  "email": "Rajat.manga@cars24.com",
                  "first_name": "Rajat",
                  "last_name": "Manga"
                }
              ]
            }
          ]
      }
    };
  }
  else {
    response = {
      data: {
        detail: []
      }
    };
  }
  return response;
};
export const getPllAssociatesList = () => {
  let response = {
    data: {
      "type": "http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html",
      "title": "PLL associate list",
      "status": 200,
      "total": 8,
      "detail": [
        {
          "id_lms_users": "122",
          "email": "rishabh.kumar@cars24.com",
          "first_name": "Rishabh",
          "last_name": "Kumar",
          "emp_code": "10126",
          "unallocatedAppointment": 2,
          "storeId": 3167
        },
        {
          "id_lms_users": "505",
          "email": "Rajat.manga@cars24.com",
          "first_name": "Rajat",
          "last_name": "Manga",
          "emp_code": "13489",
          "unallocatedAppointment": 2,
          "storeId": 3167
        },
        {
          "id_lms_users": "512",
          "email": "naveen.verma@cars24.com",
          "first_name": "Naveen",
          "last_name": "Verma",
          "emp_code": "34590",
          "unallocatedAppointment": 2,
          "storeId": 3168
        },
        {
          "id_lms_users": "514",
          "email": "vivek.tripathi@cars24.com",
          "first_name": "Vivek",
          "last_name": "Tripathi",
          "emp_code": "16972",
          "unallocatedAppointment": 2,
          "storeId": 3168
        }
      ]
    }
  };
  return response;
};

export const getAppointmentDocumentDetail = () => {
  let response = {
    data: {
      "type": "Document Detail",
      "title": "Appointment document detail",
      "status": 200,
      "detail": {
        "carDetails": {
          "year": 2015,
          "createdBy": null,
          "registrationNumber": "HR26TMP6251",
          "variant": "XE",
          "model": "SUNNY",
          "registrationCity": null,
          "storeId": 18,
          "make": "NISSAN",
          "carId": 318857,
          "orderId": 355965,
          "registrationDate": null,
          "mainImage": null,
          "prCancelled": true,
          "totalDocCount": 52,
          "totalDocManadatory": 33,
          "totalDocWithoutPage": 34
        },
        "formFields": [
          {
            "docTitle": "Form 29 - 3 Original",
            "docCode": "RTO_FORM_29",
            "documentState": "DISPATCHED",
            "returnDocState": null,
            "returnReasonId": null,
            "returnReason": null,
            "lastReturnForDealer": null,
            "isRetailReceived": true,
            "totalDocCount": 1,
            "uploadedDocCount": 1,
            "totalMandatoryCount": 1,
            "docInfo": "Signed by Registered Owner",
            "docList": null,
            "images": [
              {
                "identifier": "eyJhcHBvaW50bWVudElkIjoiMTAwODUxNTMxMiIsImRvY3VtZW50QXR0YWNobWVudElkIjpudWxsLCJkb2N1bWVudElkIjozMiwicGFnZSI6IiJ9",
                "page": "",
                "name": "Form 29 - 3 Original",
                "retailReceiveUrl": "https://cars24-dms-stage.s3.ap-south-1.amazonaws.com/ccaf0595-cdad-11ea-8879-7592f388ec1b-IMG_20200724_183046.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20200807T125419Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1800&X-Amz-Credential=AKIAID5SLFX5647TMLDQ%2F20200807%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=9f5ce0e4e41ceb31e6660cd6634b2281d43000710b62e440636efe6ce956f3cd",
                "retailReceiveStoreId": 18,
                "opsImageUrlList": [
                  {
                    "url": "https://cars24-dms-stage.s3.ap-south-1.amazonaws.com/f812ebca-cdac-11ea-8879-db6f408ae8d4-Clearance_certifiacate.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20200807T125419Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1800&X-Amz-Credential=AKIAID5SLFX5647TMLDQ%2F20200807%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=8838ceae1c8a2d7a6b67b5328f40901f9fa99687e51d436ecab21a5b274644b0",
                    "regionId": 1,
                    "action": "dispatch",
                    "dealerCode": null
                  }
                ],
                "docInfo": "Signed by Registered Owner",
                "mandatory": true
              }
            ],
            "docStateHistory": [
              {
                "state": "ACCEPT",
                "actionTakenByRegionId": 1,
                "fromRegionId": null,
                "toRegionId": null,
                "actionTakenByEmail": "ritesh.1@cars24.com",
                "comments": null,
                "rejectionReasonId": null,
                "rejectionReason": null,
                "createdOnDate": "2020-07-24 05:10:45",
                "createdOnDateIn24Format": "2020-07-24 17:10:45",
                "version": 1,
                "dispatchDetails": null
              }
            ],
            "kit": "Sales",
            "takeImageFrom": "Both",
            "isDocImportant": true,
            "isDispatchable": true,
            "isPdfEnable": true
          }
        ],
        "isUploadMandatoryForReceive": true,
        "isUploadMandatoryForDispatch": true,
        "isUploadMandatoryForDeliveryProof": true,
        "retailReceivedCount": 26,
        "pendingRetailCount": 8,
        "sourceRegionName": null,
        "destRegionName": "Delhi/NCR",
        "sourceRegionId": null,
        "destRegionId": 5,
        "dealerId": 15472,
        "dealerCode": "60462",
        "isGfd": true,
        "isFileNumberPresent": true,
        "currentUserRegionId": null,
        "returnRejectionReasons": [
          {
            "id": 1,
            "reasons": "Detail mismatch"
          }
        ],
        "documentDealerAddressDto": null
      }
    }

  };
  return response;
};
export const getCarDataMockAPI = () => {
  let response = {
    data: {
      "type": "Car Data",
      "title": "Car Data",
      "status": 200,
      "detail": {
        "message": "Data Found",
        "data": {
          "1001925514": {
            "totalRfc": 0,
            "others": {
              "exteriorTyres": {
                "elements": {
                  "quarterPanel": {
                    "title": "Quarter Panel",
                    "value": "No",
                    "subParts": {
                      "rhs": {
                        "additionalInfo": [
                          {
                            "image": {
                              "title": "Quarter_panel_Rhs_Additional_Info_Form_1",
                              "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/additionalInfo/quarterPanel/rhs/quarterpanelrhsadditionalinfoform1_1599893578.jpg",
                              "hashKey": "exteriorTyres_quarterPanel_rhs_additionalInfo_form_0",
                              "pointerCount": "1",
                              "isTilted": 0,
                              "imageUploadTime": {
                                "sec": 1599913368,
                                "usec": 0
                              },
                              "serverTime": {
                                "sec": 1599913379,
                                "usec": 0
                              }
                            },
                            "hashKey": "exteriorTyres_quarterPanel_rhs_additionalInfo_form_0",
                            "currentCondition": {
                              "title": "Current Condition",
                              "value": [
                                "Scratched",
                                "Dented",
                                "Rusted"
                              ]
                            },
                            "severityScaleCC": {
                              "title": "Severity Scale",
                              "value": {
                                "Dented": "2",
                                "Rusted": "1",
                                "Scratched": "3"
                              }
                            },
                            "damageMarker": {
                              "title": "Damage Marker",
                              "value": [
                                "1272,904,1672,1304"
                              ]
                            }
                          }
                        ],
                        "title": "RHS",
                        "value": "No"
                      },
                      "lhs": {
                        "additionalInfo": [
                          {
                            "image": {
                              "title": "Quarter_panel_Lhs_Additional_Info_Form_1",
                              "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/additionalInfo/quarterPanel/lhs/quarterpanellhsadditionalinfoform1_1599894440.jpg",
                              "hashKey": "exteriorTyres_quarterPanel_lhs_additionalInfo_form_0",
                              "pointerCount": "1",
                              "isTilted": 0,
                              "imageUploadTime": {
                                "sec": 1599914230,
                                "usec": 0
                              },
                              "serverTime": {
                                "sec": 1599914240,
                                "usec": 0
                              }
                            },
                            "hashKey": "exteriorTyres_quarterPanel_lhs_additionalInfo_form_0",
                            "currentCondition": {
                              "title": "Current Condition",
                              "value": [
                                "Scratched",
                                "Dented"
                              ]
                            },
                            "workDone": {
                              "title": "Work Done",
                              "value": [
                                "Repainted"
                              ]
                            },
                            "severityScaleCC": {
                              "title": "Severity Scale",
                              "value": {
                                "Dented": "2",
                                "Scratched": "2"
                              }
                            },
                            "damageMarker": {
                              "title": "Damage Marker",
                              "value": [
                                "1632,1136,2032,1536"
                              ]
                            }
                          }
                        ],
                        "title": "LHS",
                        "value": "No"
                      }
                    }
                  },
                  "fenders": {
                    "title": "Fender",
                    "value": "No",
                    "subParts": {
                      "rhs": {
                        "additionalInfo": [
                          {
                            "image": {
                              "title": "Fender_Rhs_Additional_Info_Form_1",
                              "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/additionalInfo/fenders/rhs/fenderrhsadditionalinfoform1_1599893702.jpg",
                              "hashKey": "exteriorTyres_fenders_rhs_additionalInfo_form_0",
                              "pointerCount": "1",
                              "isTilted": 0,
                              "imageUploadTime": {
                                "sec": 1599913491,
                                "usec": 0
                              },
                              "serverTime": {
                                "sec": 1599913502,
                                "usec": 0
                              }
                            },
                            "hashKey": "exteriorTyres_fenders_rhs_additionalInfo_form_0",
                            "currentCondition": {
                              "title": "Current Condition",
                              "value": [
                                "Scratched",
                                "Dented"
                              ]
                            },
                            "severityScaleCC": {
                              "title": "Severity Scale",
                              "value": {
                                "Dented": "2",
                                "Scratched": "2"
                              }
                            },
                            "damageMarker": {
                              "title": "Damage Marker",
                              "value": [
                                "1328,880,1728,1280"
                              ]
                            }
                          }
                        ],
                        "title": "RHS",
                        "value": "No"
                      },
                      "lhs": {
                        "additionalInfo": [
                          {
                            "image": {
                              "title": "Fender_Lhs_Additional_Info_Form_1",
                              "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/additionalInfo/fenders/lhs/fenderlhsadditionalinfoform1_1599894335.jpg",
                              "hashKey": "exteriorTyres_fenders_lhs_additionalInfo_form_0",
                              "pointerCount": "1",
                              "isTilted": 0,
                              "imageUploadTime": {
                                "sec": 1599914129,
                                "usec": 0
                              },
                              "serverTime": {
                                "sec": 1599914135,
                                "usec": 0
                              }
                            },
                            "hashKey": "exteriorTyres_fenders_lhs_additionalInfo_form_0",
                            "currentCondition": {
                              "title": "Current Condition",
                              "value": [
                                "Scratched",
                                "Dented"
                              ]
                            },
                            "workDone": {
                              "title": "Work Done",
                              "value": [
                                "Repainted"
                              ]
                            },
                            "severityScaleCC": {
                              "title": "Severity Scale",
                              "value": {
                                "Dented": "2",
                                "Scratched": "2"
                              }
                            },
                            "damageMarker": {
                              "title": "Damage Marker",
                              "value": [
                                "1600,904,2000,1304"
                              ]
                            }
                          }
                        ],
                        "title": "LHS",
                        "value": "No"
                      }
                    }
                  },
                  "firewall": {
                    "title": "Firewall",
                    "value": "Yes"
                  },
                  "cowlTop": {
                    "title": "Cowl Top",
                    "value": "Yes"
                  },
                  "bonnetPatti": {
                    "title": "Upper Cross Member (Bonnet Patti)",
                    "value": "Yes"
                  },
                  "frontShow": {
                    "title": "Front Show",
                    "value": "Yes"
                  },
                  "lowerCrossMember": {
                    "title": "Lower Cross Member",
                    "value": "No",
                    "additionalInfo": [
                      {
                        "image": {
                          "title": "Lower_cross_member_Additional_Info_Form_1",
                          "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/additionalInfo/lowerCrossMember/lowercrossmemberadditionalinfoform1_1599893850.jpg",
                          "hashKey": "exteriorTyres_lowerCrossMember_additionalInfo_form_0",
                          "pointerCount": 0,
                          "isTilted": 0,
                          "imageUploadTime": {
                            "sec": 1599913647,
                            "usec": 0
                          },
                          "serverTime": {
                            "sec": 1599913650,
                            "usec": 0
                          }
                        },
                        "hashKey": "exteriorTyres_lowerCrossMember_additionalInfo_form_0",
                        "currentCondition": {
                          "title": "Current Condition",
                          "value": [
                            "Rusted"
                          ]
                        },
                        "workDone": {
                          "title": "Work Done",
                          "value": [
                            "Repaired"
                          ]
                        }
                      }
                    ]
                  },
                  "radiatorSupport": {
                    "title": "Radiator Support",
                    "value": "Yes"
                  },
                  "headLightSupport": {
                    "title": "Head Light Support",
                    "value": "Yes"
                  },
                  "runningBorder": {
                    "title": "Running Border",
                    "value": "No",
                    "subParts": {
                      "rhs": {
                        "additionalInfo": [
                          {
                            "image": {
                              "title": "Running_border_Rhs_Additional_Info_Form_1",
                              "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/additionalInfo/runningBorder/rhs/runningborderrhsadditionalinfoform1_1599893605.jpg",
                              "hashKey": "exteriorTyres_runningBorder_rhs_additionalInfo_form_0",
                              "pointerCount": 0,
                              "isTilted": 0,
                              "imageUploadTime": {
                                "sec": 1599913400,
                                "usec": 0
                              },
                              "serverTime": {
                                "sec": 1599913405,
                                "usec": 0
                              }
                            },
                            "hashKey": "exteriorTyres_runningBorder_rhs_additionalInfo_form_0",
                            "currentCondition": {
                              "title": "Current Condition",
                              "value": [
                                "Scratched",
                                "Dented",
                                "Rusted"
                              ]
                            },
                            "severityScaleCC": {
                              "title": "Severity Scale",
                              "value": {
                                "Dented": "2",
                                "Rusted": "2",
                                "Scratched": "2"
                              }
                            }
                          }
                        ],
                        "title": "RHS",
                        "value": "No"
                      },
                      "lhs": {
                        "additionalInfo": [
                          {
                            "image": {
                              "title": "Running_border_Lhs_Additional_Info_Form_1",
                              "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/additionalInfo/runningBorder/lhs/runningborderlhsadditionalinfoform1_1599894425.jpg",
                              "hashKey": "exteriorTyres_runningBorder_lhs_additionalInfo_form_0",
                              "pointerCount": 0,
                              "isTilted": 0,
                              "imageUploadTime": {
                                "sec": 1599914221,
                                "usec": 0
                              },
                              "serverTime": {
                                "sec": 1599914225,
                                "usec": 0
                              }
                            },
                            "hashKey": "exteriorTyres_runningBorder_lhs_additionalInfo_form_0",
                            "currentCondition": {
                              "title": "Current Condition",
                              "value": [
                                "Scratched",
                                "Dented",
                                "Rusted"
                              ]
                            },
                            "severityScaleCC": {
                              "title": "Severity Scale",
                              "value": {
                                "Dented": "2",
                                "Rusted": "1",
                                "Scratched": "2"
                              }
                            }
                          }
                        ],
                        "title": "LHS",
                        "value": "No"
                      }
                    }
                  },
                  "doors": {
                    "title": "Door",
                    "value": "No",
                    "subParts": {
                      "rhsRear": {
                        "additionalInfo": [
                          {
                            "image": {
                              "title": "Door_Rhs_rear_Additional_Info_Form_1",
                              "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/additionalInfo/doors/rhsRear/doorrhsrearadditionalinfoform1_1599893630.jpg",
                              "hashKey": "exteriorTyres_doors_rhsRear_additionalInfo_form_0",
                              "pointerCount": "1",
                              "isTilted": 0,
                              "imageUploadTime": {
                                "sec": 1599913423,
                                "usec": 0
                              },
                              "serverTime": {
                                "sec": 1599913430,
                                "usec": 0
                              }
                            },
                            "hashKey": "exteriorTyres_doors_rhsRear_additionalInfo_form_0",
                            "currentCondition": {
                              "title": "Current Condition",
                              "value": [
                                "Scratched",
                                "Dented"
                              ]
                            },
                            "severityScaleCC": {
                              "title": "Severity Scale",
                              "value": {
                                "Dented": "2",
                                "Scratched": "2"
                              }
                            },
                            "damageMarker": {
                              "title": "Damage Marker",
                              "value": [
                                "1656,900,2056,1300"
                              ]
                            }
                          }
                        ],
                        "title": "RHS Rear",
                        "value": "No"
                      },
                      "rhsFront": {
                        "additionalInfo": [
                          {
                            "image": {
                              "title": "Door_Rhs_front_Additional_Info_Form_1",
                              "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/additionalInfo/doors/rhsFront/doorrhsfrontadditionalinfoform1_1599893644.jpg",
                              "hashKey": "exteriorTyres_doors_rhsFront_additionalInfo_form_0",
                              "pointerCount": "1",
                              "isTilted": 0,
                              "imageUploadTime": {
                                "sec": 1599913434,
                                "usec": 0
                              },
                              "serverTime": {
                                "sec": 1599913444,
                                "usec": 0
                              }
                            },
                            "hashKey": "exteriorTyres_doors_rhsFront_additionalInfo_form_0",
                            "currentCondition": {
                              "title": "Current Condition",
                              "value": [
                                "Scratched",
                                "Dented"
                              ]
                            },
                            "severityScaleCC": {
                              "title": "Severity Scale",
                              "value": {
                                "Dented": "2",
                                "Scratched": "1"
                              }
                            },
                            "damageMarker": {
                              "title": "Damage Marker",
                              "value": [
                                "1664,788,2064,1188"
                              ]
                            }
                          }
                        ],
                        "title": "RHS Front",
                        "value": "No"
                      },
                      "lhsFront": {
                        "additionalInfo": [
                          {
                            "image": {
                              "title": "Door_Lhs_front_Additional_Info_Form_1",
                              "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/additionalInfo/doors/lhsFront/doorlhsfrontadditionalinfoform1_1599894373.jpg",
                              "hashKey": "exteriorTyres_doors_lhsFront_additionalInfo_form_0",
                              "pointerCount": "1",
                              "isTilted": 0,
                              "imageUploadTime": {
                                "sec": 1599914167,
                                "usec": 0
                              },
                              "serverTime": {
                                "sec": 1599914173,
                                "usec": 0
                              }
                            },
                            "hashKey": "exteriorTyres_doors_lhsFront_additionalInfo_form_0",
                            "currentCondition": {
                              "title": "Current Condition",
                              "value": [
                                "Scratched",
                                "Dented",
                                "Rusted"
                              ]
                            },
                            "workDone": {
                              "title": "Work Done",
                              "value": [
                                "Repainted"
                              ]
                            },
                            "severityScaleCC": {
                              "title": "Severity Scale",
                              "value": {
                                "Dented": "2",
                                "Rusted": "1",
                                "Scratched": "2"
                              }
                            },
                            "damageMarker": {
                              "title": "Damage Marker",
                              "value": [
                                "1364,976,1764,1376"
                              ]
                            }
                          }
                        ],
                        "title": "LHS Front",
                        "value": "No"
                      },
                      "lhsRear": {
                        "additionalInfo": [
                          {
                            "image": {
                              "title": "Door_Lhs_rear_Additional_Info_Form_1",
                              "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/additionalInfo/doors/lhsRear/doorlhsrearadditionalinfoform1_1599894384.jpg",
                              "hashKey": "exteriorTyres_doors_lhsRear_additionalInfo_form_0",
                              "pointerCount": "1",
                              "isTilted": 0,
                              "imageUploadTime": {
                                "sec": 1599914176,
                                "usec": 0
                              },
                              "serverTime": {
                                "sec": 1599914184,
                                "usec": 0
                              }
                            },
                            "hashKey": "exteriorTyres_doors_lhsRear_additionalInfo_form_0",
                            "currentCondition": {
                              "title": "Current Condition",
                              "value": [
                                "Scratched",
                                "Dented",
                                "Damaged",
                                "Rusted"
                              ]
                            },
                            "workDone": {
                              "title": "Work Done",
                              "value": [
                                "Repainted",
                                "Repaired",
                                "Replaced"
                              ]
                            },
                            "severityScaleCC": {
                              "title": "Severity Scale",
                              "value": {
                                "Damaged": "1",
                                "Dented": "3",
                                "Rusted": "2",
                                "Scratched": "3"
                              }
                            },
                            "damageMarker": {
                              "title": "Damage Marker",
                              "value": [
                                "1276,980,1676,1380"
                              ]
                            }
                          }
                        ],
                        "title": "LHS Rear",
                        "value": "No"
                      }
                    }
                  },
                  "windshield": {
                    "title": "Windshield",
                    "value": "No",
                    "subParts": {
                      "rear": {
                        "title": "Rear",
                        "value": "Yes"
                      },
                      "front": {
                        "additionalInfo": [
                          {
                            "image": {
                              "title": "Windshield_Front_Additional_Info_Form_1",
                              "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/additionalInfo/windshield/front/windshieldfrontadditionalinfoform1_1599893746.jpg",
                              "hashKey": "exteriorTyres_windshield_front_additionalInfo_form_0",
                              "pointerCount": 0,
                              "isTilted": 0,
                              "imageUploadTime": {
                                "sec": 1599913543,
                                "usec": 0
                              },
                              "serverTime": {
                                "sec": 1599913547,
                                "usec": 0
                              }
                            },
                            "hashKey": "exteriorTyres_windshield_front_additionalInfo_form_0",
                            "currentCondition": {
                              "title": "Current Condition",
                              "value": [
                                "Spots",
                                "Scratched"
                              ]
                            }
                          }
                        ],
                        "title": "Front",
                        "value": "No"
                      }
                    }
                  },
                  "lights": {
                    "title": "Light",
                    "value": "No",
                    "subParts": {
                      "rhsTaillight": {
                        "additionalInfo": [
                          {
                            "image": {
                              "title": "Light_Rhs_taillight_Additional_Info_Form_1",
                              "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/additionalInfo/lights/rhsTaillight/lightrhstaillightadditionalinfoform1_1599893426.jpg",
                              "hashKey": "exteriorTyres_lights_rhsTaillight_additionalInfo_form_0",
                              "pointerCount": 0,
                              "isTilted": 0,
                              "imageUploadTime": {
                                "sec": 1599913220,
                                "usec": 0
                              },
                              "serverTime": {
                                "sec": 1599913227,
                                "usec": 0
                              }
                            },
                            "hashKey": "exteriorTyres_lights_rhsTaillight_additionalInfo_form_0",
                            "currentCondition": {
                              "title": "Current Condition",
                              "value": [
                                "Scratched"
                              ]
                            }
                          }
                        ],
                        "title": "RHS Taillight",
                        "value": "No"
                      },
                      "lhsTaillight": {
                        "additionalInfo": [
                          {
                            "image": {
                              "title": "Light_Lhs_taillight_Additional_Info_Form_1",
                              "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/additionalInfo/lights/lhsTaillight/lightlhstaillightadditionalinfoform1_1599893433.jpg",
                              "hashKey": "exteriorTyres_lights_lhsTaillight_additionalInfo_form_0",
                              "pointerCount": 0,
                              "isTilted": 0,
                              "imageUploadTime": {
                                "sec": 1599913229,
                                "usec": 0
                              },
                              "serverTime": {
                                "sec": 1599913233,
                                "usec": 0
                              }
                            },
                            "hashKey": "exteriorTyres_lights_lhsTaillight_additionalInfo_form_0",
                            "currentCondition": {
                              "title": "Current Condition",
                              "value": [
                                "Scratched"
                              ]
                            }
                          }
                        ],
                        "title": "LHS Taillight",
                        "value": "No"
                      },
                      "lhsHeadlight": {
                        "additionalInfo": [
                          {
                            "image": {
                              "title": "Light_Lhs_headlight_Additional_Info_Form_1",
                              "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/additionalInfo/lights/lhsHeadlight/lightlhsheadlightadditionalinfoform1_1599893791.jpg",
                              "hashKey": "exteriorTyres_lights_lhsHeadlight_additionalInfo_form_0",
                              "pointerCount": 0,
                              "isTilted": 0,
                              "imageUploadTime": {
                                "sec": 1599913587,
                                "usec": 0
                              },
                              "serverTime": {
                                "sec": 1599913591,
                                "usec": 0
                              }
                            },
                            "hashKey": "exteriorTyres_lights_lhsHeadlight_additionalInfo_form_0",
                            "currentCondition": {
                              "title": "Current Condition",
                              "value": [
                                "Scratched"
                              ]
                            }
                          }
                        ],
                        "title": "LHS Headlight",
                        "value": "No"
                      },
                      "rhsHeadlight": {
                        "additionalInfo": [
                          {
                            "image": {
                              "title": "Light_Rhs_headlight_Additional_Info_Form_1",
                              "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/additionalInfo/lights/rhsHeadlight/lightrhsheadlightadditionalinfoform1_1599893805.jpg",
                              "hashKey": "exteriorTyres_lights_rhsHeadlight_additionalInfo_form_0",
                              "pointerCount": 0,
                              "isTilted": 0,
                              "imageUploadTime": {
                                "sec": 1599913600,
                                "usec": 0
                              },
                              "serverTime": {
                                "sec": 1599913605,
                                "usec": 0
                              }
                            },
                            "hashKey": "exteriorTyres_lights_rhsHeadlight_additionalInfo_form_0",
                            "currentCondition": {
                              "title": "Current Condition",
                              "value": [
                                "Scratched"
                              ]
                            }
                          }
                        ],
                        "title": "RHS Headlight",
                        "value": "No"
                      }
                    }
                  },
                  "bumpers": {
                    "title": "Bumper",
                    "value": "No",
                    "subParts": {
                      "rear": {
                        "additionalInfo": [
                          {
                            "image": {
                              "title": "Bumper_Rear_Additional_Info_Form_1",
                              "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/additionalInfo/bumpers/rear/bumperrearadditionalinfoform1_1599893411.jpg",
                              "hashKey": "exteriorTyres_bumpers_rear_additionalInfo_form_0",
                              "pointerCount": 0,
                              "isTilted": 0,
                              "imageUploadTime": {
                                "sec": 1599913206,
                                "usec": 0
                              },
                              "serverTime": {
                                "sec": 1599913211,
                                "usec": 0
                              }
                            },
                            "hashKey": "exteriorTyres_bumpers_rear_additionalInfo_form_0",
                            "currentCondition": {
                              "title": "Current Condition",
                              "value": [
                                "Scratched",
                                "Damaged"
                              ]
                            },
                            "workDone": {
                              "title": "Work Done",
                              "value": [
                                "Repainted",
                                "Replaced"
                              ]
                            }
                          }
                        ],
                        "title": "Rear",
                        "value": "No"
                      },
                      "front": {
                        "additionalInfo": [
                          {
                            "image": {
                              "title": "Bumper_Front_Additional_Info_Form_1",
                              "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/additionalInfo/bumpers/front/bumperfrontadditionalinfoform1_1599893759.jpg",
                              "hashKey": "exteriorTyres_bumpers_front_additionalInfo_form_0",
                              "pointerCount": 0,
                              "isTilted": 0,
                              "imageUploadTime": {
                                "sec": 1599913555,
                                "usec": 0
                              },
                              "serverTime": {
                                "sec": 1599913559,
                                "usec": 0
                              }
                            },
                            "hashKey": "exteriorTyres_bumpers_front_additionalInfo_form_0",
                            "currentCondition": {
                              "title": "Current Condition",
                              "value": [
                                "Scratched",
                                "Damaged"
                              ]
                            },
                            "workDone": {
                              "title": "Work Done",
                              "value": [
                                "Repainted"
                              ]
                            }
                          }
                        ],
                        "title": "Front",
                        "value": "No"
                      }
                    }
                  },
                  "orvm": {
                    "title": "ORVM - Manual / Electrical",
                    "value": "No",
                    "subParts": {
                      "rhs": {
                        "additionalInfo": [
                          {
                            "image": {
                              "title": "Orvm_-_manual_/_electrical_Rhs_Additional_Info_Form_1",
                              "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/additionalInfo/orvm/rhs/orvmmanualelectricalrhsadditionalinfoform1_1599893655.jpg",
                              "hashKey": "exteriorTyres_orvm_rhs_additionalInfo_form_0",
                              "pointerCount": 0,
                              "isTilted": 0,
                              "imageUploadTime": {
                                "sec": 1599913445,
                                "usec": 0
                              },
                              "serverTime": {
                                "sec": 1599913455,
                                "usec": 0
                              }
                            },
                            "hashKey": "exteriorTyres_orvm_rhs_additionalInfo_form_0",
                            "currentCondition": {
                              "title": "Current Condition",
                              "value": [
                                "Scratched"
                              ]
                            }
                          }
                        ],
                        "title": "RHS",
                        "value": "No"
                      },
                      "lhs": {
                        "additionalInfo": [
                          {
                            "image": {
                              "title": "Orvm_-_manual_/_electrical_Lhs_Additional_Info_Form_1",
                              "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/additionalInfo/orvm/lhs/orvmmanualelectricallhsadditionalinfoform1_1599894352.jpg",
                              "hashKey": "exteriorTyres_orvm_lhs_additionalInfo_form_0",
                              "pointerCount": 0,
                              "isTilted": 0,
                              "imageUploadTime": {
                                "sec": 1599914146,
                                "usec": 0
                              },
                              "serverTime": {
                                "sec": 1599914152,
                                "usec": 0
                              }
                            },
                            "hashKey": "exteriorTyres_orvm_lhs_additionalInfo_form_0",
                            "currentCondition": {
                              "title": "Current Condition",
                              "value": [
                                "Scratched"
                              ]
                            }
                          }
                        ],
                        "title": "LHS",
                        "value": "No"
                      }
                    }
                  },
                  "alloyWheels": {
                    "title": "Alloy Wheel",
                    "value": "No",
                    "additionalInfo": [
                      {
                        "image": {
                          "title": "Alloy_wheel_Additional_Info_Form_1",
                          "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/additionalInfo/alloyWheels/alloywheeladditionalinfoform1_1599894341.jpg",
                          "hashKey": "exteriorTyres_alloyWheels_additionalInfo_form_0",
                          "pointerCount": 0,
                          "isTilted": 0,
                          "imageUploadTime": {
                            "sec": 1599914139,
                            "usec": 0
                          },
                          "serverTime": {
                            "sec": 1599914141,
                            "usec": 0
                          }
                        },
                        "hashKey": "exteriorTyres_alloyWheels_additionalInfo_form_0",
                        "currentCondition": {
                          "title": "Current Condition",
                          "value": [
                            "Scratched"
                          ]
                        }
                      }
                    ]
                  },
                  "tyre": {
                    "title": "Tyre",
                    "value": "No",
                    "subParts": {
                      "lhsFrontTyre": {
                        "title": "LHS Front Tyre",
                        "value": "No",
                        "additionalInfo": [
                          {
                            "image": {
                              "title": "Lhs_front_tyre_Additional_Info_Form_1",
                              "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/additionalInfo/lhsFrontTyre/lhsfronttyreadditionalinfoform1_1599894358.jpg",
                              "hashKey": "exteriorTyres_lhsFrontTyre_additionalInfo_form_0",
                              "pointerCount": 0,
                              "isTilted": 0,
                              "imageUploadTime": {
                                "sec": 1599914155,
                                "usec": 0
                              },
                              "serverTime": {
                                "sec": 1599914158,
                                "usec": 0
                              }
                            },
                            "hashKey": "exteriorTyres_lhsFrontTyre_additionalInfo_form_0",
                            "treadDepth": {
                              "title": "Tread Depth",
                              "value": [
                                "6-22%"
                              ]
                            }
                          }
                        ]
                      },
                      "rhsFrontTyre": {
                        "title": "RHS Front Tyre",
                        "value": "No",
                        "additionalInfo": [
                          {
                            "image": {
                              "title": "Rhs_front_tyre_Additional_Info_Form_1",
                              "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/additionalInfo/rhsFrontTyre/rhsfronttyreadditionalinfoform1_1599893714.jpg",
                              "hashKey": "exteriorTyres_rhsFrontTyre_additionalInfo_form_0",
                              "pointerCount": 0,
                              "isTilted": 0,
                              "imageUploadTime": {
                                "sec": 1599913511,
                                "usec": 0
                              },
                              "serverTime": {
                                "sec": 1599913514,
                                "usec": 0
                              }
                            },
                            "hashKey": "exteriorTyres_rhsFrontTyre_additionalInfo_form_0",
                            "treadDepth": {
                              "title": "Tread Depth",
                              "value": [
                                "38-53%"
                              ]
                            }
                          }
                        ]
                      },
                      "lhsRearTyre": {
                        "title": "LHS Rear Tyre",
                        "value": "No",
                        "additionalInfo": [
                          {
                            "image": {
                              "title": "Lhs_rear_tyre_Additional_Info_Form_1",
                              "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/additionalInfo/lhsRearTyre/lhsreartyreadditionalinfoform1_1599894453.jpg",
                              "hashKey": "exteriorTyres_lhsRearTyre_additionalInfo_form_0",
                              "pointerCount": 0,
                              "isTilted": 0,
                              "imageUploadTime": {
                                "sec": 1599914243,
                                "usec": 0
                              },
                              "serverTime": {
                                "sec": 1599914253,
                                "usec": 0
                              }
                            },
                            "hashKey": "exteriorTyres_lhsRearTyre_additionalInfo_form_0",
                            "treadDepth": {
                              "title": "Tread Depth",
                              "value": [
                                "38-53%"
                              ]
                            }
                          }
                        ]
                      },
                      "rhsRearTyre": {
                        "title": "RHS Rear Tyre",
                        "value": "No",
                        "additionalInfo": [
                          {
                            "image": {
                              "title": "Rhs_rear_tyre_Additional_Info_Form_1",
                              "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/additionalInfo/rhsRearTyre/rhsreartyreadditionalinfoform1_1599893591.jpg",
                              "hashKey": "exteriorTyres_rhsRearTyre_additionalInfo_form_0",
                              "pointerCount": 0,
                              "isTilted": 0,
                              "imageUploadTime": {
                                "sec": 1599913388,
                                "usec": 0
                              },
                              "serverTime": {
                                "sec": 1599913391,
                                "usec": 0
                              }
                            },
                            "hashKey": "exteriorTyres_rhsRearTyre_additionalInfo_form_0",
                            "treadDepth": {
                              "title": "Tread Depth",
                              "value": [
                                "38-53%"
                              ]
                            }
                          }
                        ]
                      },
                      "spareTyre": {
                        "title": "Spare Tyre",
                        "value": "Yes",
                        "additionalInfo": [
                          {
                            "image": {
                              "title": "Spare_tyre_Additional_Info_Form_1",
                              "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/additionalInfo/spareTyre/sparetyreadditionalinfoform1_1599893373.jpg",
                              "hashKey": "exteriorTyres_spareTyre_additionalInfo_form_0",
                              "pointerCount": 0,
                              "isTilted": 0,
                              "imageUploadTime": {
                                "sec": 1599913170,
                                "usec": 0
                              },
                              "serverTime": {
                                "sec": 1599913173,
                                "usec": 0
                              }
                            },
                            "hashKey": "exteriorTyres_spareTyre_additionalInfo_form_0",
                            "treadDepth": {
                              "title": "Tread Depth",
                              "value": [
                                "53-69%"
                              ]
                            }
                          }
                        ]
                      }
                    }
                  },
                  "estimatedRFC": {
                    "title": "Estimated RFC",
                    "value": 0
                  }
                },
                "rating": 4,
                "title": "Exterior + Tyres",
                "grouped": [
                  {
                    "title": "Boonet/Hood|Roof|DickyDoor/BootDoor",
                    "elements": {
                      "bonnetHood": {
                        "title": "Bonnet/Hood",
                        "value": "No",
                        "additionalInfo": [
                          {
                            "image": {
                              "title": "Bonnet/hood_Additional_Info_Form_1",
                              "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/additionalInfo/bonnetHood/bonnethoodadditionalinfoform1_1599893781.jpg",
                              "hashKey": "exteriorTyres_bonnetHood_additionalInfo_form_0",
                              "pointerCount": "1",
                              "isTilted": 0,
                              "imageUploadTime": {
                                "sec": 1599913574,
                                "usec": 0
                              },
                              "serverTime": {
                                "sec": 1599913581,
                                "usec": 0
                              }
                            },
                            "hashKey": "exteriorTyres_bonnetHood_additionalInfo_form_0",
                            "currentCondition": {
                              "title": "Current Condition",
                              "value": [
                                "Scratched",
                                "Dented",
                                "Rusted"
                              ]
                            },
                            "workDone": {
                              "title": "Work Done",
                              "value": [
                                "Repainted"
                              ]
                            },
                            "severityScaleCC": {
                              "title": "Severity Scale",
                              "value": {
                                "Dented": "2",
                                "Rusted": "1",
                                "Scratched": "1"
                              }
                            },
                            "damageMarker": {
                              "title": "Damage Marker",
                              "value": [
                                "1248,916,1648,1316"
                              ]
                            }
                          }
                        ]
                      },
                      "dickyDoorBootDoor": {
                        "title": "Dicky Door / Boot Door",
                        "value": "No",
                        "additionalInfo": [
                          {
                            "image": {
                              "title": "Dicky_door_/_boot_door_Additional_Info_Form_1",
                              "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/additionalInfo/dickyDoorBootDoor/dickydoorbootdooradditionalinfoform1_1599893403.jpg",
                              "hashKey": "exteriorTyres_dickyDoorBootDoor_additionalInfo_form_0",
                              "pointerCount": "1",
                              "isTilted": 0,
                              "imageUploadTime": {
                                "sec": 1599913189,
                                "usec": 0
                              },
                              "serverTime": {
                                "sec": 1599913203,
                                "usec": 0
                              }
                            },
                            "hashKey": "exteriorTyres_dickyDoorBootDoor_additionalInfo_form_0",
                            "currentCondition": {
                              "title": "Current Condition",
                              "value": [
                                "Scratched",
                                "Dented"
                              ]
                            },
                            "severityScaleCC": {
                              "title": "Severity Scale",
                              "value": {
                                "Dented": "1",
                                "Scratched": "2"
                              }
                            },
                            "damageMarker": {
                              "title": "Damage Marker",
                              "value": [
                                "1704,888,2104,1288"
                              ]
                            }
                          }
                        ]
                      },
                      "roof": {
                        "title": "Roof",
                        "value": "No",
                        "additionalInfo": [
                          {
                            "image": {
                              "title": "Roof_Additional_Info_Form_1",
                              "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/additionalInfo/roof/roofadditionalinfoform1_1599894465.jpg",
                              "hashKey": "exteriorTyres_roof_additionalInfo_form_0",
                              "pointerCount": "1",
                              "isTilted": 0,
                              "imageUploadTime": {
                                "sec": 1599914258,
                                "usec": 0
                              },
                              "serverTime": {
                                "sec": 1599914265,
                                "usec": 0
                              }
                            },
                            "hashKey": "exteriorTyres_roof_additionalInfo_form_0",
                            "currentCondition": {
                              "title": "Current Condition",
                              "value": [
                                "Scratched",
                                "Dented"
                              ]
                            },
                            "severityScaleCC": {
                              "title": "Severity Scale",
                              "value": {
                                "Dented": "1",
                                "Scratched": "2"
                              }
                            }
                          }
                        ]
                      }
                    }
                  },
                  {
                    "title": "Structure",
                    "elements": {
                      "pillars": {
                        "title": "Pillar",
                        "value": "No",
                        "subParts": {
                          "rhsA": {
                            "additionalInfo": [
                              {
                                "image": {
                                  "title": "Pillar_Rhs_a_Additional_Info_Form_1",
                                  "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/additionalInfo/pillars/rhsA/pillarrhsaadditionalinfoform1_1599893619.jpg",
                                  "hashKey": "exteriorTyres_pillars_rhsA_additionalInfo_form_0",
                                  "pointerCount": 0,
                                  "isTilted": 0,
                                  "imageUploadTime": {
                                    "sec": 1599913416,
                                    "usec": 0
                                  },
                                  "serverTime": {
                                    "sec": 1599913419,
                                    "usec": 0
                                  }
                                },
                                "hashKey": "exteriorTyres_pillars_rhsA_additionalInfo_form_0",
                                "currentCondition": {
                                  "title": "Current Condition",
                                  "value": [
                                    "Scratched"
                                  ]
                                },
                                "severityScaleCC": {
                                  "title": "Severity Scale",
                                  "value": {
                                    "Scratched": "1"
                                  }
                                }
                              }
                            ],
                            "title": "RHS A",
                            "value": "No"
                          },
                          "rhsB": {
                            "title": "RHS B",
                            "value": "Yes"
                          },
                          "rhsC": {
                            "title": "RHS C",
                            "value": "Yes"
                          },
                          "lhsA": {
                            "title": "LHS A",
                            "value": "Yes"
                          },
                          "lhsB": {
                            "title": "LHS B",
                            "value": "Yes"
                          },
                          "lhsC": {
                            "title": "LHS C",
                            "value": "Yes"
                          }
                        }
                      },
                      "bootFloor": {
                        "title": "Boot Floor",
                        "value": "No",
                        "additionalInfo": [
                          {
                            "image": {
                              "title": "Boot_floor_Additional_Info_Form_1",
                              "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/additionalInfo/bootFloor/bootflooradditionalinfoform1_1599893366.jpg",
                              "hashKey": "exteriorTyres_bootFloor_additionalInfo_form_0",
                              "pointerCount": 0,
                              "isTilted": 0,
                              "imageUploadTime": {
                                "sec": 1599913159,
                                "usec": 0
                              },
                              "serverTime": {
                                "sec": 1599913166,
                                "usec": 0
                              }
                            },
                            "hashKey": "exteriorTyres_bootFloor_additionalInfo_form_0",
                            "currentCondition": {
                              "title": "Current Condition",
                              "value": [
                                "Scratched",
                                "Dented",
                                "Rusted"
                              ]
                            },
                            "severityScaleCC": {
                              "title": "Severity Scale",
                              "value": {
                                "Dented": "1",
                                "Rusted": "1",
                                "Scratched": "1"
                              }
                            }
                          }
                        ]
                      },
                      "apron": {
                        "title": "Apron",
                        "value": "Yes"
                      }
                    }
                  }
                ]
              },
              "engineTransmission": {
                "elements": {
                  "engine": {
                    "title": "Engine",
                    "value": "Yes"
                  },
                  "engineSound": {
                    "title": "Engine Sound",
                    "value": "No",
                    "additionalInfo": [
                      {
                        "video": {
                          "title": "Engine_sound_Additional_Info_Form_1",
                          "url": "inspection/2020/09/12/R3RCQ5Q/v0/engineTransmission/additionalInfo/engineSound/enginesoundadditionalinfoform1_1599894128.mp4",
                          "hashKey": "engineTransmission_engineSound_additionalInfo_form_0",
                          "pointerCount": 0,
                          "thumbUrl": "inspection/2020/09/12/R3RCQ5Q/v0/engineTransmission/additionalInfo/engineSound/enginesoundadditionalinfoform1_1599894128.jpg",
                          "imageUploadTime": {
                            "sec": 1599913886,
                            "usec": 0
                          },
                          "serverTime": {
                            "sec": 1599913928,
                            "usec": 0
                          }
                        },
                        "hashKey": "engineTransmission_engineSound_additionalInfo_form_0",
                        "currentCondition": {
                          "title": "Current Condition",
                          "value": [
                            "Water Pump Brg Noise",
                            "Engine Auxiliary Noise"
                          ]
                        }
                      }
                    ]
                  },
                  "exhaustSmoke": {
                    "title": "Exhaust Smoke",
                    "value": "Yes"
                  },
                  "engineBlowByBackCompression": {
                    "title": "Engine Permissible Blow by (Back Compression)",
                    "value": "Yes",
                    "additionalInfo": [
                      {
                        "hashKey": "engineTransmission_engineBlowByBackCompression_additionalInfo_form_0",
                        "currentCondition": {
                          "title": "Current Condition",
                          "value": [
                            "No blow-by"
                          ]
                        }
                      }
                    ]
                  },
                  "engineMounting": {
                    "title": "Engine Mounting",
                    "value": "Yes"
                  },
                  "clutch": {
                    "title": "Clutch",
                    "value": "No",
                    "additionalInfo": [
                      {
                        "currentCondition": {
                          "title": "Current Condition",
                          "value": [
                            "Spongy",
                            "Bearing Noise"
                          ]
                        }
                      }
                    ]
                  },
                  "gearShifting": {
                    "title": "Gear Shifting",
                    "value": "Yes"
                  },
                  "engineOil": {
                    "title": "Engine Oil",
                    "value": "Yes",
                    "additionalInfo": [
                      {
                        "hashKey": "engineTransmission_engineOil_additionalInfo_form_0",
                        "currentCondition": {
                          "title": "Current Condition",
                          "value": [
                            "Level Low",
                            "Dirty"
                          ]
                        }
                      }
                    ]
                  },
                  "engineOilLevelDipstik": {
                    "title": "Engine Oil Level Dipstik",
                    "value": "No"
                  },
                  "battery": {
                    "title": "Battery",
                    "value": "Yes",
                    "additionalInfo": [
                      {
                        "currentCondition": {
                          "title": "Current Condition",
                          "value": [
                            "Weak",
                            "Acid leakage"
                          ]
                        }
                      }
                    ]
                  },
                  "coolant": {
                    "title": "Coolant",
                    "value": "No",
                    "additionalInfo": [
                      {
                        "hashKey": "engineTransmission_coolant_additionalInfo_form_0",
                        "currentCondition": {
                          "title": "Current Condition",
                          "value": [
                            "Dirty"
                          ]
                        }
                      }
                    ]
                  },
                  "estimatedRFC": {
                    "title": "Estimated RFC",
                    "value": "Yes"
                  },
                  "towingRequired": {
                    "title": "Towing Required",
                    "value": "Yes"
                  }
                },
                "rating": 4,
                "comments": {
                  "title": "Comments",
                  "value": [
                    {
                      "comment": "Car in working condition but towing suggested to avoid damage to clutch",
                      "id": 87,
                      "rank": 0,
                      "type": "Comments"
                    },
                    {
                      "comment": "Electrical wiring damaged",
                      "id": 91,
                      "rank": 0,
                      "type": "Comments"
                    }
                  ]
                },
                "title": "Engine + Transmission"
              },
              "airConditioning": {
                "elements": {
                  "acCooling": {
                    "title": "AC Cooling",
                    "value": "Yes"
                  },
                  "heater": {
                    "title": "Heater",
                    "value": "Yes"
                  },
                  "estimatedRFC": {
                    "title": "Estimated RFC",
                    "value": 0
                  }
                },
                "rating": 5,
                "title": "Air Conditioning"
              },
              "electricalsInterior": {
                "elements": {
                  "powerWindows": {
                    "title": "4 Power Windows",
                    "value": "Yes"
                  },
                  "airbagFeature": {
                    "title": "Airbag Feature",
                    "value": "Yes"
                  },
                  "musicSystem": {
                    "title": "Music System",
                    "value": "Yes"
                  },
                  "fabricSeats": {
                    "title": "Fabric Seat",
                    "value": "No",
                    "additionalInfo": [
                      {
                        "image": {
                          "title": "Fabric_seat_Additional_Info_Form_1",
                          "url": "inspection/2020/09/12/R3RCQ5Q/v0/electricalsInterior/additionalInfo/fabricSeats/fabricseatadditionalinfoform1_1599894241.jpg",
                          "hashKey": "electricalsInterior_fabricSeats_additionalInfo_form_0",
                          "pointerCount": 0,
                          "isTilted": 0,
                          "imageUploadTime": {
                            "sec": 1599914037,
                            "usec": 0
                          },
                          "serverTime": {
                            "sec": 1599914042,
                            "usec": 0
                          }
                        },
                        "hashKey": "electricalsInterior_fabricSeats_additionalInfo_form_0",
                        "currentCondition": {
                          "title": "Current Condition",
                          "value": [
                            "Torn"
                          ]
                        }
                      }
                    ]
                  },
                  "steeringMountedAudioControls": {
                    "title": "Steering Mounted Audio Control",
                    "value": "Yes"
                  },
                  "abs": {
                    "title": "ABS",
                    "value": "Yes"
                  },
                  "rearDefogger": {
                    "title": "Rear Defogger",
                    "value": "Yes"
                  },
                  "estimatedRFC": {
                    "title": "Estimated RFC",
                    "value": 0
                  },
                  "electrical": {
                    "title": "Electrical",
                    "value": "Yes"
                  },
                  "interior": {
                    "title": "Interior",
                    "value": "No",
                    "additionalInfo": [
                      {
                        "currentCondition": {
                          "title": "Current Condition",
                          "value": [
                            "Door Trim torn",
                            "Dashboard scratched & broken"
                          ]
                        }
                      }
                    ]
                  }
                },
                "rating": 4,
                "title": "Electricals + Interiors"
              },
              "steeringSuspensionBrakes": {
                "elements": {
                  "steering": {
                    "title": "Steering",
                    "value": "No",
                    "additionalInfo": [
                      {
                        "currentCondition": {
                          "title": "Current Condition",
                          "value": [
                            "Abnormal Noise"
                          ]
                        }
                      }
                    ]
                  },
                  "suspension": {
                    "title": "Suspension",
                    "value": "No",
                    "additionalInfo": [
                      {
                        "hashKey": "steeringSuspensionBrakes_suspension_additionalInfo_form_0",
                        "currentCondition": {
                          "title": "Current Condition",
                          "value": [
                            "Weak"
                          ]
                        }
                      }
                    ]
                  },
                  "brakes": {
                    "title": "Brake",
                    "value": "Yes"
                  },
                  "estimatedRFC": {
                    "title": "Estimated RFC",
                    "value": 0
                  }
                },
                "rating": 3.5,
                "title": "Steering + Suspension + Brakes"
              }
            },
            "documentDetail": {
              "elements": {
                "manufacturingDate": {
                  "title": "Manufacturing Month/Yr",
                  "value": "May-2012"
                },
                "registrationDate": {
                  "title": "Registration Date",
                  "value": "Aug-2012"
                },
                "insuranceType": {
                  "title": "Insurance Type",
                  "value": "Comprehensive"
                },
                "insuranceExpiry": {
                  "title": "Insurance Expiry Date",
                  "value": "25-Jul-2021"
                },
                "noClaimBonus": {
                  "title": "No Claim Bonus",
                  "value": "Yes"
                },
                "noClaimBonusPercentage": {
                  "title": "No Claim Bonus Percentage",
                  "value": "50.00%"
                },
                "chassisNumber": {
                  "title": "Chassis Number",
                  "value": "MAKGM252ECN305476"
                },
                "fitnessUpto": {
                  "title": "Fitness Upto",
                  "value": "31-Jul-2027"
                },
                "rcAvailability": {
                  "title": "RC Availability",
                  "value": "Original"
                },
                "rcCondition": {
                  "title": "RC Condition",
                  "value": "Ok"
                },
                "rto": {
                  "title": "RTO",
                  "value": "WB-02 Kolkata"
                },
                "partiPeshi": {
                  "title": "Partipeshi Request",
                  "value": "Yes (for 100 days)"
                },
                "roadTaxPaid": {
                  "title": "Road Tax Paid",
                  "value": "Limited Period"
                },
                "roadTaxDateValidity": {
                  "title": "Road Tax Date (Validity)",
                  "value": "02-Aug-2022"
                },
                "rtoNocIssued": {
                  "title": "RTO NOC Issued",
                  "value": "No"
                },
                "isUnderHypothecation": {
                  "title": "Under Hypothecation",
                  "value": "No"
                },
                "duplicateKey": {
                  "title": "Duplicate Key",
                  "value": "Yes"
                },
                "sellerIntent": {
                  "title": "sellerIntent",
                  "value": "Yes",
                  "subParts": {
                    "originalRC": {
                      "title": "Original RC",
                      "value": "Yes"
                    },
                    "comprehensiveInsurance": {
                      "title": "Comprehensive Insurance",
                      "value": "Yes"
                    },
                    "noHypothecation": {
                      "title": "No Hypothecation",
                      "value": "Yes"
                    }
                  }
                },
                "chassisNumberEmbossing": {
                  "title": "Chassis Number Embossing",
                  "value": "Ok"
                },
                "odometer": {
                  "title": "Odometer",
                  "value": 70265
                }
              },
              "title": "Car Details"
            },
            "comments": [],
            "highlights": {
              "engineTransmission": [
                {
                  "comment": "Engine Exhaust is colorless",
                  "id": 18,
                  "rank": 5,
                  "type": ""
                },
                {
                  "comment": "No Back Compression",
                  "id": 43,
                  "rank": 6,
                  "type": ""
                }
              ],
              "electricalsInterior": [
                {
                  "comment": "Power Windows",
                  "id": 3,
                  "rank": 12,
                  "type": ""
                },
                {
                  "comment": "Car with Safety AirBag",
                  "id": 4,
                  "rank": 10,
                  "type": ""
                },
                {
                  "comment": "Music System",
                  "id": 5,
                  "rank": 13,
                  "type": ""
                },
                {
                  "comment": "Steering Mounted Audio Controls",
                  "id": 8,
                  "rank": 14,
                  "type": ""
                },
                {
                  "comment": "Car with ABS",
                  "id": 9,
                  "rank": 11,
                  "type": ""
                },
                {
                  "comment": "Rear Defogger",
                  "id": 10,
                  "rank": 16,
                  "type": ""
                }
              ]
            },
            "allMedia": {
              "exteriorTyres": {
                "mainImages": {
                  "rearBootOpened": {
                    "title": "Rear Boot Opened",
                    "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/mainImages/platemaskingrearbootopened_1599893384.jpg"
                  },
                  "rearMain": {
                    "title": "Rear Main",
                    "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/mainImages/platemaskingrearmain_1599893561.jpg"
                  },
                  "rearRightSide": {
                    "title": "Rear Right Side",
                    "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/mainImages/platemaskingrearrightside_1599893569.jpg"
                  },
                  "frontMain": {
                    "title": "Front Main",
                    "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/mainImages/platemaskingfrontmain_1599893741.jpg"
                  },
                  "FrontWithHoodOpen": {
                    "title": "Front With Hood Open",
                    "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/mainImages/frontwithhoodopen_1599893829.jpg"
                  },
                  "frontLeftSide": {
                    "title": "Front Left Side",
                    "url": "inspection/2020/09/12/R3RCQ5Q/v0/exteriorTyres/mainImages/frontleftside_1599894969.jpg"
                  }
                }
              },
              "engineTransmission": {
                "mainImages": {
                  "exhaustSmoke": {
                    "title": "Exhaust Smoke",
                    "url": "inspection/2020/09/12/R3RCQ5Q/v0/engineTransmission/additionalInfo/exhaustSmoke/exhaustsmokeadditionalinfoform1_1599894158.jpg"
                  },
                  "engineBlowByBackCompression": {
                    "title": "Engine Permissible Blow by (Back Compression) (No blow-by)",
                    "url": "inspection/2020/09/12/R3RCQ5Q/v0/engineTransmission/additionalInfo/engineBlowByBackCompression/enginepermissibleblowbybackcompressionadditionalinfoform1_1599894126.jpg"
                  }
                }
              },
              "electricalsInterior": {
                "mainImages": {
                  "interiorFromBack": {
                    "title": "Interior From Back Seat",
                    "url": "inspection/2020/09/12/R3RCQ5Q/v0/electricalsInterior/mainImages/interiorfrombackseat_1599894302.jpg"
                  },
                  "interiorFromFrontRight": {
                    "title": "Interior From Front Right",
                    "url": "inspection/2020/09/12/R3RCQ5Q/v0/electricalsInterior/mainImages/interiorfromfrontright_1599894305.jpg"
                  },
                  "InterorFromRearRight": {
                    "title": "Interior From Rear Right",
                    "url": "inspection/2020/09/12/R3RCQ5Q/v0/electricalsInterior/mainImages/interiorfromrearright_1599894308.jpg"
                  },
                  "Odometer": {
                    "title": "Odometer",
                    "url": "inspection/2020/09/12/R3RCQ5Q/v0/electricalsInterior/mainImages/odometer_1599894312.jpg"
                  }
                }
              }
            },
            "storeName": "HI-Sarjapur"
          }
        }
      }
    }
  };
  return response;
};

export const getRATokenDetailsMock = () => {
  let response = 
    {"data": {
      "type": "PR KYC Detail",
      "title": "PR KYC Detail",
      "status": 200,
      "detail": {
        "appointmentId": 1003245214,
        "purchaseRequestId": 73560,
        "make": "HYUNDAI",
        "model": "EON",
        "regNumber": "HR26TMP6251",
        "customerName": "Thanksggggfgggggggggg",
        "purchaseType": "RA_TOKEN",
        "purchaseStatus": "0",
        "purchaseStatusLabel": "INITIALIZED",
        "ownerIdProofType": "AADHAR_CARD",
        "ownerIdProofNumber": "AADUPDATE",
        "ownerPanNumber": "NHUYT6576T",
        "primaryPennyStatusLabel": "Input pending from customer",
        "primaryBankDetails": {
          "accountType": null,
          "accountName": null,
          "accountNumber": null,
          "bankId": null,
          "ifscCode": null
        },
        "charges": {
          "duplicateRcAmt": 220,
          "challanAmt": 110,
          "fuelRecoveryAmt": 0,
          "loanClosureAmt": 0
        },
        "holdback": {
          "holdingNocAmt": 400,
          "holdingPartyPeshiAmt": 0
        },
        "secondaryBankDetails": {
          "accountType": "secondary",
          "accountName": "navneet",
          "accountNumber": "2328328327328",
          "bankId": 4,
          "ifscCode": "HDFC0012121"
        },
        "applicableDocuments": [
          {
            "documentCode": "AADHAR_CARD",
            "documentName": "Aadhar Card"
          },
          {
            "documentCode": "PAN_CARD_COPY",
            "documentName": "Pan Card Copy"
          },
          {
            "documentCode": "PASSPORT",
            "documentName": "Passport"
          },
          {
            "documentCode": "RATION_CARD",
            "documentName": "Ration Card"
          },
          {
            "documentCode": "TELEPHONE_BILL",
            "documentName": "Telephone Bill"
          },
          {
            "documentCode": "ELECTRICITY_BILL",
            "documentName": "Electricity Bill"
          },
          {
            "documentCode": "GAS_CONNECTION_PASSBOOK",
            "documentName": "Gas Connection Passbook"
          },
          {
            "documentCode": "NATIONALISED_BANK_PASSBOOK",
            "documentName": "Nationalised Bank Passbook"
          }
        ],
        "secondaryBank": true,
        "customerConsented": true,
        "thirdPartyPayment": true
      }
    }
  };
  return response;
};

export const getPrDocumentStatus = () => {
  let response = {
    detail: {
      "type": "PR KYC Detail",
      "title": "PR KYC Detail",
      "status": 200,
      "detail": {
        "appointmentId": 1002405914,
        "purchaseRequestId": 73967,
        "make": "HYUNDAI",
        "model": "SANTRO XING",
        "regNumber": "UP83BH6363",
        "customerName": "MRINAL GAUR",
        "purchaseType": "RA_TOKEN",
        "purchaseStatus": "0",
        "purchaseStatusLabel": "INITIALIZED",
        "ownerIdProofType": "AADHAR_CARD",
        "ownerIdProofNumber": "ANRPG675",
        "ownerPanNumber": "7497439749",
        "primaryPennyStatusLabel": "Input pending from customer",
        "primaryBankDetails": {
          "accountType": null,
          "accountName": null,
          "accountNumber": null,
          "bankId": null,
          "ifscCode": null
        },
        "secondaryBankDetails": {
          "accountType": null,
          "accountName": null,
          "accountNumber": null,
          "bankId": null,
          "ifscCode": null
        },
        "applicableDocuments": [
          {
            "documentCode": "AADHAR_CARD",
            "documentName": "Aadhar Card"
          },
          {
            "documentCode": "PAN_CARD_COPY",
            "documentName": "Pan Card Copy"
          },
          {
            "documentCode": "PASSPORT",
            "documentName": "Passport"
          },
          {
            "documentCode": "RATION_CARD",
            "documentName": "Ration Card"
          },
          {
            "documentCode": "TELEPHONE_BILL",
            "documentName": "Telephone Bill"
          },
          {
            "documentCode": "ELECTRICITY_BILL",
            "documentName": "Electricity Bill"
          },
          {
            "documentCode": "GAS_CONNECTION_PASSBOOK",
            "documentName": "Gas Connection Passbook"
          }
        ],
        "tokenAmount": "1000",
        "secondaryBank": false,
        "customerConsented": false,
        "thirdPartyPayment": true
      }
    }
  };
  return response;
};

export const getVahanBackgroundCheck = () => {
  let response = {
    data: {
      "type": "Ra Token Details",
      "title": "Ra Token Details",
      "status": 200,
      "detail": {
        "vahan": 3,
        "serviceHistory": 3,
        "rcParticular": 1,
        "businessApproval": 1,
        "fraudAudit": true,
        "hvc": true
      }
    }
  };
  return response;
};

export const getVisitedMock = () => {
  let response = {
    data: {
      "type": "http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html",
      "title": "Lead Info",
      "status": 200,
      "detail": {
        "result": [
          {
            "customerName": "Anupam",
            "storeType": "store",
            "appointment": "1034606366",
            "checkInDatetime": "2021-01-25 11:26:14",
            "inspection": "In-Progress",
            "model": "Ampere REO",
            "variant": "",
            "customerEmail": "abtest_fz8YQUjMCyYVbhAfuFxa_Jh894zPuKJV@cars24.mail.com",
            "secondaryEmail": "abtest_cqbe7mk1o94b3cwctnfhmaj80fprmazv@cars24.mail.com",
            "retailAssociateId": "",
            "retailAssociateEmail": "",
            "rescheduleUrl": "https://c2b-website.qac24svc.dev/sell-your-bike/confirmation/fz8YQUjMCyYVbhAfuFxa_Jh894zPuKJV/?e=1&utm_source=LMS&utm_medium=reschedule",
            "customerPhone": "9953450954",
            "year": "2010"
          }
        ],
        "totalPending": 0,
        "totalInProgress": 1,
        "totalRows": 1,
        "page": "1",
        "columns": [
          {
            "key": "appointment",
            "value": "APP. ID",
            "isVisible": "1"
          },
          {
            "key": "customerName",
            "value": "CUST. NAME",
            "isVisible": "1"
          },
          {
            "key": "customerName",
            "value": "CUST. NAME",
            "isVisible": "1"
          },
          {
            "key": "modelVariantYear",
            "value": "MODEL/YEAR/VAR",
            "isVisible": "1"
          },
          {
            "key": "checkInDatetime",
            "value": "TIME SINCE CHECK-IN",
            "isVisible": "1"
          },
          {
            "key": "year",
            "value": "YEAR",
            "isVisible": "0"
          },
          {
            "key": "model",
            "value": "MODEL",
            "isVisible": "0"
          },
          {
            "key": "inspection",
            "value": "INSPECTION STATUS",
            "isVisible": "1"
          }
        ]
      }
    }
  };
  return response;
};

export const getSimilarCar = () => {
  let response = {
    data: {
      detail: {
        marketPrice: 440000,
        similarCars: [
          {
            "appointment": "9711988081",
            "make": "Nissan",
            "model": "Micro",
            "year": "2015",
            "variant": "SIGMA 1.2 K12",
            "odometerReading": "45000",
            "c24Quote": "592000",
            "url": "https://fastly-production.24c.in/inspection/2020/11/6/RQ3CZC3/v0/exteriorTyres/mainImages/platemaskingfrontmain_1604648111.jpg",
            "extRating": 4,
            "engRating": 3.5,
            "cityName": "Mumbai"
          },
          {
            "appointment": "9711988081",
            "make": "Nissan",
            "model": "Micro",
            "year": "2013",
            "variant": "SIGMA 1.2 K12",
            "odometerReading": "45000",
            "c24Quote": "592000",
            "url": "https://fastly-production.24c.in/inspection/2020/11/6/RQ3CZC3/v0/exteriorTyres/mainImages/platemaskingfrontmain_1604648111.jpg",
            "extRating": 5,
            "engRating": 5,
            "cityName": "Mumbai"
          },
          {
            "appointment": "9711988081",
            "make": "Nissan",
            "model": "Micro",
            "year": "2013",
            "variant": "SIGMA 1.2 K12",
            "odometerReading": "45000",
            "c24Quote": "592000",
            "url": "https://fastly-production.24c.in/inspection/2020/11/6/RQ3CZC3/v0/exteriorTyres/mainImages/platemaskingfrontmain_1604648111.jpg",
            "extRating": 5,
            "engRating": 5,
            "cityName": "Mumbai"
          },{
            "appointment": "9711988081",
            "make": "Nissan",
            "model": "Micro",
            "year": "2013",
            "variant": "SIGMA 1.2 K12",
            "odometerReading": "45000",
            "c24Quote": "592000",
            "url": "https://fastly-production.24c.in/inspection/2020/11/6/RQ3CZC3/v0/exteriorTyres/mainImages/platemaskingfrontmain_1604648111.jpg",
            "extRating": 5,
            "engRating": 5,
            "cityName": "Mumbai"
          }
        ]
      },
    }
  };
  return response;
};

export const getAppointmentPreCheckin = () => {
  let response = {
    data: {
      "type":"http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html",
      "title":"Precheckin",
      "status":200,
      "detail":{
         "openCheckin":false,
         "link":""
      }
   }
  };
  return response;
};

export const getBikeRATokenMock = () => {
  let response = {
    data: {
        "type": "Token Payment",
        "title": "Get token Detail",
        "status": 200,
        "detail": {
            "purchaseRequestId": 2407,
            "regNumber": "1234567890",
            "chassisNumber": "",
            "engineNumber": "0",
            "ownerNameOnRc": null,
            "charges": [
                {
                    "key": "challanAmt",
                    "label": "Pending Challan",
                    "amount": null
                },
                {
                    "key": "duplicateRcAmt",
                    "label": "RC Missing Charges",
                    "amount": null
                },
                {
                    "key": "fuelRecoveryAmt",
                    "label": "Insufficient Fuel Charges",
                    "amount": null
                },
                {
                    "key": "loanDdAmt",
                    "label": "Pending Loan DD",
                    "amount": null
                },
                {
                    "key": "deductingServiceAmt",
                    "label": "Service Charges",
                    "amount": null
                },
                {
                    "key": "deductingOtherAmt",
                    "label": "Other Charges",
                    "amount": null
                }
            ],
            "holdback": [
                {
                    "key": "holdingNocAmt",
                    "label": "Bank NOC",
                    "amount": null
                },
                {
                    "key": "holdingPartyPeshiAmt",
                    "label": "Party Peshi",
                    "amount": null
                }
            ],
            "primaryBankDetails": {
                "accountType": null,
                "accountName": null,
                "accountNumber": null,
                "bankId": null,
                "ifscCode": null
            },
            "primaryPennyStatusLabel": "Input pending from customer",
            "panNumber": null,
            "aadharNumber": null,
            "accountHolderName": null,
            "accountNumber": null,
            "ifscCode": null,
            "bankId": null,
            "hypothecation": null,
            "loanStatus": null,
            "hypothecationBank": null,
            "validBankNocAvailable": null,
            "loanClosureAmt": null,
            "vahanStatus": "pending",
            "vahanDetailUrl": "https://admin.qac24svc.dev/orders/moto-background-check/view/9246819410",
            "appointmentId": "9246819410",
            "paymentStatus": "DRAFTED",
            "remark": null,
            "vahanRaiseReasons": [
                "Customer entered incorrect registration number",
                "Incorrect vahan details"
            ],
            "thumbAadharFrontPhoto": "",
            "thumbAadharBackPhoto": "",
            "thumbPanPhoto": "",
            "thumbCancelledChequePhoto": "",
            "thumbConsentLetterPhoto": "",
            "category": {
                "Vehicle Documents": {
                    "name": "Vehicle Documents",
                    "docs": {
                        "ORIGINAL_RC": [
                            {
                                "name": "Original RC",
                                "page": "(1)",
                                "image": null
                            },
                            {
                                "name": "Original RC",
                                "page": "(2)",
                                "image": null
                            },
                            {
                                "name": "Original RC",
                                "page": "(3)",
                                "image": null
                            },
                            {
                                "name": "Original RC",
                                "page": "(4)",
                                "image": null
                            },
                            {
                                "name": "Original RC",
                                "page": "(5)",
                                "image": null
                            }
                        ],
                        "POLLUTION_CERTIFICATE": [
                            {
                                "name": "Pollution Certificate",
                                "page": "",
                                "image": null
                            }
                        ],
                        "ORIGINAL_INVOICE": [
                            {
                                "name": "Bike Purchase Invoice",
                                "page": "",
                                "image": null
                            }
                        ],
                        "ORIGINAL_INSURANCE": [
                            {
                                "name": "Insurance",
                                "page": "",
                                "image": null
                            }
                        ]
                    }
                },
                "RTO Documents": {
                    "name": "RTO Documents",
                    "docs": {
                        "RC_FORM_26": [
                            {
                                "name": "Form 26",
                                "page": "",
                                "image": null
                            }
                        ],
                        "APPLICATION_TRANSFER_INSURANCE": [
                            {
                                "name": "Application for transfer of Insurance",
                                "page": "",
                                "image": null
                            }
                        ],
                        "RTO_FORM_28": [
                            {
                                "name": "Form 28 - 4 Original",
                                "page": "",
                                "image": null
                            }
                        ],
                        "RTO_FORM_29": [
                            {
                                "name": "Form 29 - 3 Original",
                                "page": "",
                                "image": null
                            }
                        ],
                        "RTO_FORM_30": [
                            {
                                "name": "Form 30 - 3 Original",
                                "page": "",
                                "image": null
                            }
                        ],
                        "SALE_AFFIDAVIT": [
                            {
                                "name": "Sale Affidavit",
                                "page": "",
                                "image": null
                            }
                        ],
                        "CLEARANCE_CERTIFICATE": [
                            {
                                "name": "Clearance Certificate",
                                "page": "",
                                "image": null
                            }
                        ]
                    }
                },
                "Bike Owner Documents": {
                    "name": "Bike Owner Documents",
                    "docs": {
                        "PAN_CARD_FORM_60": [
                            {
                                "name": "Form 60",
                                "page": "",
                                "image": null
                            }
                        ],
                        "CUSTOMER_PHOTOS": [
                            {
                                "name": "Customer Photos",
                                "page": "",
                                "image": null
                            }
                        ]
                    }
                }
            }
        }
    }
    
  };
return response;
};
export const saveCepPriceMoc = () => {
  let response = {
    data: {
      "type": "",
      "title": "Unknown",
      "status": 200,
      "detail": {
        "message": "Re-Auction started successfully",
        "auctionId": 472281,
        "success": true,
        "participants": [
          15559
        ]
      }
    }
  };
  return response;
};
export const getBikePickupRequestMock = () => {
  let response = {
    data: {
      "type": "Pickup-request",
      "title": "Pickup Request",
      "status": 200,
      "detail": {
        "appointmentId": "98765432106",
        "logistic": {
          "pickupDate": "2021-04-17",
          "pickupSlotFrom": "12:00",
          "pickupSlotTo": "14:00"
        }
      }
    }
  };
  return response;
};

export const getBikeInitiateTokenPayment = () => {
  let response = {
      data: {
        "type": "Token Payment",
        "title": "Get token Detail",
        "status": 200,
        "detail": {
          "purchaseRequestId": 2677,
          "regNumber": "DL12345678",
          "chassisNumber": null,
          "engineNumber": "0",
          "ownerNameOnRc": null,
          "charges": [
            {
              "key": "challanAmt",
              "label": "Pending Challan",
              "amount": 0
            },
            {
              "key": "duplicateRcAmt",
              "label": "RC Missing Charges",
              "amount": null
            },
            {
              "key": "fuelRecoveryAmt",
              "label": "Insufficient Fuel Charges",
              "amount": null
            },
            {
              "key": "loanDdAmt",
              "label": "Pending Loan DD",
              "amount": null
            },
            {
              "key": "deductingServiceAmt",
              "label": "Service Charges",
              "amount": null
            },
            {
              "key": "deductingOtherAmt",
              "label": "Other Charges",
              "amount": null
            }
          ],
          "holdback": [
            {
              "key": "holdingNocAmt",
              "label": "Bank NOC",
              "amount": null
            },
            {
              "key": "holdingPartyPeshiAmt",
              "label": "Party Peshi",
              "amount": null
            }
          ],
          "primaryBankDetails": {
            "accountType": null,
            "accountName": null,
            "accountNumber": null,
            "ifscCode": null,
            "bankId": null,
            "bankName": null
          },
          "primaryPennyStatusLabel": "Input pending from customer",
          "panNumber": null,
          "aadharNumber": null,
          "accountHolderName": null,
          "accountNumber": null,
          "ifscCode": null,
          "bankId": null,
          "hypothecation": false,
          "loanStatus": null,
          "hypothecationBank": null,
          "validBankNocAvailable": null,
          "loanClosureAmt": null,
          "vahanStatus": "pending",
          "vahanDetailUrl": "https://admin.qac24svc.dev/orders/moto-background-check/view/4606668616",
          "appointmentId": "4606668616",
          "paymentStatus": "DRAFTED",
          "remark": null,
          "vahanRaiseReasons": [
            "Customer entered incorrect registration number",
            "Incorrect vahan details"
          ],
          "thumbAadharFrontPhoto": "",
          "thumbAadharBackPhoto": "",
          "thumbPanPhoto": "",
          "thumbCancelledChequePhoto": "",
          "thumbConsentLetterPhoto": "",
          "category": {
            "Bike Owner Documents": {
              "name": "Bike Owner Documents",
              "docs": {
                "ADDRESS_PROOF": {
                  "type": "dropdown",
                  "options": [
                    "PASSPORT",
                    "VOTER_ID",
                    "TELEPHONE_BILL",
                    "ELECTRICITY_BILL",
                    "RATION_CARD"
                  ],
                  "docs": {
                    "ELECTRICITY_BILL": [
                      {
                        "name": "Electricity Bill",
                        "page": "",
                        "image": null
                      }
                    ],
                    "TELEPHONE_BILL": [
                      {
                        "name": "Telephone bill (MTNL,BSNL)",
                        "page": "",
                        "image": null
                      }
                    ],
                    "VOTER_ID": [
                      {
                        "name": "Voter Id",
                        "page": "(F)",
                        "image": null
                      },
                      {
                        "name": "Voter Id",
                        "page": "(B)",
                        "image": null
                      }
                    ],
                    "RATION_CARD": [
                      {
                        "name": "Ration Card",
                        "page": "",
                        "image": "https://cars24-dms-stage.s3.ap-south-1.amazonaws.com/666cae2b-146a-11ec-ac77-ef250cf345f6-ration_card-1631520775-377.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20210913T081313Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1799&X-Amz-Credential=AKIAID5SLFX5647TMLDQ%2F20210913%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=cae081c7bcf57dd2e90f11152ccddcb437a8840b601ba6ff73225524bb4e9cf4"
                      }
                    ],
                    "PASSPORT": [
                      {
                        "name": "Passport",
                        "page": "(F)",
                        "image": null
                      },
                      {
                        "name": "Passport",
                        "page": "(B)",
                        "image": null
                      }
                    ]
                  }
                },
                "ADDRESS_PROOF_2": {
                  "type": "dropdown",
                  "options": [
                    "PASSPORT",
                    "VOTER_ID",
                    "TELEPHONE_BILL",
                    "ELECTRICITY_BILL",
                    "RATION_CARD"
                  ],
                  "docs": {
                    "ELECTRICITY_BILL": [
                      {
                        "name": "Electricity Bill",
                        "page": "",
                        "image": null
                      }
                    ],
                    "TELEPHONE_BILL": [
                      {
                        "name": "Telephone bill (MTNL,BSNL)",
                        "page": "",
                        "image": null
                      }
                    ],
                    "VOTER_ID": [
                      {
                        "name": "Voter Id",
                        "page": "(F)",
                        "image": null
                      },
                      {
                        "name": "Voter Id",
                        "page": "(B)",
                        "image": null
                      }
                    ],
                    "RATION_CARD": [
                      {
                        "name": "Ration Card",
                        "page": "",
                        "image": "https://cars24-dms-stage.s3.ap-south-1.amazonaws.com/666cae2b-146a-11ec-ac77-ef250cf345f6-ration_card-1631520775-377.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20210913T081313Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1799&X-Amz-Credential=AKIAID5SLFX5647TMLDQ%2F20210913%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=cae081c7bcf57dd2e90f11152ccddcb437a8840b601ba6ff73225524bb4e9cf4"
                      }
                    ],
                    "PASSPORT": [
                      {
                        "name": "Passport",
                        "page": "(F)",
                        "image": null
                      },
                      {
                        "name": "Passport",
                        "page": "(B)",
                        "image": null
                      }
                    ]
                  }
                },
                "PAN_CARD_FORM_60": {
                  "type": "",
                  "docs": [
                    {
                      "name": "Form 60",
                      "page": "",
                      "image": null
                    }
                  ]
                }
              }
            },
            "Vehicle Documents": {
              "name": "Vehicle Documents",
              "docs": {
                "ORIGINAL_RC": {
                  "type": "",
                  "docs": [
                    {
                      "name": "Original RC",
                      "page": "(1)",
                      "image": null
                    },
                    {
                      "name": "Original RC",
                      "page": "(2)",
                      "image": null
                    },
                    {
                      "name": "Original RC",
                      "page": "(3)",
                      "image": null
                    },
                    {
                      "name": "Original RC",
                      "page": "(4)",
                      "image": null
                    },
                    {
                      "name": "Original RC",
                      "page": "(5)",
                      "image": null
                    }
                  ]
                },
                "ORIGINAL_INSURANCE": {
                  "type": "",
                  "docs": [
                    {
                      "name": "Insurance",
                      "page": "",
                      "image": null
                    }
                  ]
                },
                "POLLUTION_CERTIFICATE": {
                  "type": "",
                  "docs": [
                    {
                      "name": "Pollution Certificate",
                      "page": "",
                      "image": null
                    }
                  ]
                },
                "ORIGINAL_INVOICE": {
                  "type": "",
                  "docs": [
                    {
                      "name": "Bike Purchase Invoice",
                      "page": "",
                      "image": null
                    }
                  ]
                }
              }
            },
            "RTO Documents": {
              "name": "RTO Documents",
              "docs": {
                "RTO_FORM_28": {
                  "type": "",
                  "docs": [
                    {
                      "name": "Form 28 - 4 Original",
                      "page": "",
                      "image": null
                    }
                  ]
                },
                "RTO_FORM_29": {
                  "type": "",
                  "docs": [
                    {
                      "name": "Form 29 - 3 Original",
                      "page": "",
                      "image": null
                    }
                  ]
                },
                "RTO_FORM_30": {
                  "type": "",
                  "docs": [
                    {
                      "name": "Form 30 - 3 Original",
                      "page": "",
                      "image": null
                    }
                  ]
                },
                "CLEARANCE_CERTIFICATE": {
                  "type": "",
                  "docs": [
                    {
                      "name": "Clearance Certificate",
                      "page": "",
                      "image": null
                    }
                  ]
                },
                "SALE_AFFIDAVIT": {
                  "type": "",
                  "docs": [
                    {
                      "name": "Sale Affidavit",
                      "page": "",
                      "image": null
                    }
                  ]
                },
                "APPLICATION_TRANSFER_INSURANCE": {
                  "type": "",
                  "docs": [
                    {
                      "name": "Application for transfer of Insurance",
                      "page": "",
                      "image": null
                    }
                  ]
                },
                "CAF_DELIVERY": {
                  "type": "",
                  "docs": [
                    {
                      "name": "Acknowledgement Form",
                      "page": "",
                      "image": null
                    }
                  ]
                }
              }
            }
          }
        }
      }
  };
  return response;
};

export const getAppointmentDetails = () => {
  let response = {
      data: {
          "type": "Payment Processing",
          "title": "Payment Processing List",
          "status": 200,
          "detail": {
            "orderId": 4409999,
            "appointment": "10198910777",
            "customerName": "RAKHI RAKHI",
            "model": "XUV500",
            "year": 2015,
            "variant": "W6",
            "pickupStatus": null,
            "pickUpRequestStatus": "",
            "homeInspectionCj": null,
            "isReInspectionDone": 0,
            "customerAddress": "Suncity School, Sector 37D, Gurugram, Haryana, India",
            "storeType": "store",
            "prSubmittedDate": "2023-05-02 11:22:04",
            "virtualNumber": "08069455555",
            "orderPurchaseRequestId": 2272095,
            "qcStatus": "Approved",
            "qcStatusPP": 3,
            "adminSummaryUrl": "http://admin.24c.in/orders/purchase-request/summary/2272095",
            "qcPendingDate": "",
            "lastPaymentStatus": "Token",
            "daysDealLost": "6 days",
            "adminUrl": "http://admin.24c.in/orders/payment-instruction/2272095",
            "prStatusSection": {
              "status": "PR APPROVED",
              "statusTag": "RETAIL_PR_APPROVED",
              "isBidRejected": false,
              "isHvb": true
            },
            "paymentStatusSection": {
              "stage1": "Token Done",
              "stage2": "Waiting for Pickup Request",
              "stage1Success": true,
              "stage2Success": true,
              "loan": {
                "stage1": "Equifax Done",
                "stage2": "Waiting for PI Raise",
                "stage1Success": true,
                "stage2Success": true
              }
            },
            "carStatusSection": {
              "daysToDealLost": {
                "daysSinceBought": 1,
                "todayDaysAllowed": "6"
              },
              "storeVisibility": "Retail",
              "stages": {
                "stage1": "Stock-in Pending",
                "stage2": "",
                "stage1Success": true,
                "stage2Success": true
              }
            },
            "isResendOtpApplicable": false,
            "calculatedc24Quote": 390000,
            "c24Quote": 390000,
            "c24QuoteTag": "Bought",
            "c24quotExpiryTime": null,
            "hbv": null,
            "createTime": "2023-05-01 14:50:57",
            "counter": 0,
            "pllMargin": 15600,
            "retailMargin": 34700,
            "inputAmount": 0,
            "manualQuote": 0,
            "showOcbDetailsCTA": false,
            "showVerifyBidCTA": false,
            "dealerTokenStatus": 0,
            "is_pll": null,
            "retail_associate_email": "md.hasnain@cars24.com"
          }
    }
  };
  return response;
};
