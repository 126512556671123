import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  detail: [],
  error: null,
  message: "",
  isLoading: true
};

export const fetchHistory = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true, detail: [] };
};

export const fetchFollowUpHistorySuccess = (state = INITIAL_STATE, { data }) => {
  return { ...state, detail: data, isLoading: false, error: null };
};

export const fetchFollowUpHistoryFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, error, isLoading: false };
};

export const resetFollowUpHistory = () => {
  return INITIAL_STATE;
};

export const HANDLERS = {
  [Types.FETCH_FOLLOW_UP_HISTORY]: fetchHistory,
  [Types.FETCH_FOLLOW_UP_HISTORY_SUCCESS]: fetchFollowUpHistorySuccess,
  [Types.FETCH_FOLLOW_UP_HISTORY_FAILURE]: fetchFollowUpHistoryFailure,
  [Types.RESET_FOLLOW_UP_HISTORY]: resetFollowUpHistory
};

export default createReducer(INITIAL_STATE, HANDLERS);
