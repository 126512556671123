import React, {useState, useCallback, useEffect, useRef} from "react";
import styles from "./location-picker.module.scss";
import {NUMBERS} from '../../../utils/app-constants';
import PageLoader from "../../../components/shared/loader";
import { debounce } from "lodash";
import { googlePlacesOptions } from "../../../utils/auto-complete/search-options";
import AutoComplete from "../../../utils/auto-complete";
import House from "./images/house.svg";
import Bulb from "./images/bulb.svg";
import Info from "./images/info.svg";
import CloseIcon from "./images/close.svg";
import NewLocation from "./images/new-location.svg";
import {isLocationFeasibleForInspection} from '../../common/helpers/check-location-feasibility';
import { InView } from "react-intersection-observer";
const LocationSearch = ({
  onLocationChange,
  showLocationErrorToast,
  locationType,
  mapType,
}) => {
  const inputRef = useRef(null);
  const [isFetching, setIsFetching] = useState(false);
  const [searchedPlaces, setSearchedPlaces] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [isLocationOutOfBounds, setIsLocationOutOfBounds] = useState(false);
    
  useEffect(() => {
    const timer = window.setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, NUMBERS.ZERO);
    return () => window.clearTimeout(timer);
  }, [inputRef]);

  const delayedQuery = useCallback(
    debounce((val) =>
      googlePlacesOptions(val, mapType)
        .then(resp => {
          setSearchedPlaces(resp);
        })
    , NUMBERS.FIVE_HUNDRED),
    []
  );

  const handleLocationChange = (value, locationInfo = "") => {
    setInputValue(value);
    if (value && value.length > NUMBERS.THREE) {
      delayedQuery(`${locationInfo}${value}`);
    } else {
      setSearchedPlaces(null);
    }
  };

  const onChangeHandler = ({ target: { value } }) => {
    if (!value) {
      setSearchedPlaces(null);
      setIsLocationOutOfBounds(false);
    }
    handleLocationChange(value);
  };
  const getPincode = (data) => {
    if (!data) return "";
    const elem = data.address_components.filter((item) => item.types.includes("postal_code"));
    if (!elem || !elem[0]) return "";
    return elem[0].short_name || "";
  };
  const handleOnPlaceSelect = async (place) => {
    try {
      const { value, label, lat, lng, pincode } = place;
      let lati = lat;
      let lngi = lng;

      setIsFetching(true);
      const response = await AutoComplete.getInstance(mapType).getGeoCode({
        placeId: value
      }, locationType);
      setIsFetching(false);

      if (!lati) {
        const coords = response[0].geometry.location;
        lati = coords.lat();
        lngi = coords.lng();
      }
            
      const locationObj = {
        coords: { lat: lati, lng:  lngi},
        label,
        formattedAddress: response[0].formatted_address,
        pincode: pincode || getPincode(response[0])
      };

      if (isLocationFeasibleForInspection(response[0])) {
        onLocationChange(locationObj);
        setSearchedPlaces(null);
      } else {
        setIsLocationOutOfBounds(true);
        handleResetVal();
      }
    } catch (error) {
      setIsFetching(false);
    }
  };

  const handleResetVal = () => {
    setInputValue("");
    setSearchedPlaces(null);
  };

  const resetVal = () => {
    setIsLocationOutOfBounds(false);
    handleResetVal();
  };

  const renderErrorMessage = () => {
    if (isLocationOutOfBounds) {
      return (
        <div className={styles.locationInfo}>
          <img src={Info} alt="info" />
          <div className={styles.infoText}>
            <p>The location entered is very broad.</p>
            <span>Please enter your apartment name/landmark so our car expert can visit you on time.</span>
          </div>
        </div>
      );
    }

    return (
      <div className={styles.infoWrapper}>
        <div className={styles.infoSection}>
          <img src={Bulb} alt="info" />
          <p>Search with apartment name or nearest landmark for accuracy.</p>
        </div>
        <div className={styles.houseimg}>
          <img src={House} alt="location search" />
        </div>
        {
          showLocationErrorToast &&
          <div className={styles.locationMainWrapper}>
            <div className={styles.locationWrapper}>
              <img src={NewLocation} alt="location" />
              <span>Location access Denied</span>
            </div>
          </div>
        }
      </div>
    );
  };

  return (
    <div className={styles.searchWrapper}>
      {
                isFetching ?
                  <PageLoader /> :
                  <>
                    <div className={styles.titleText}>
                      <p>Where do you want us to visit for inspection?</p>
                      <span>Please help us with the accurate location.</span>
                    </div>
                    <InView as="div">
                      <div className={styles.searchInput}>
                        <input 
                          ref={inputRef}
                          placeholder={"Search location"}
                          maxLength={255}
                          onChange={onChangeHandler}
                          value={inputValue}
                        />
                        {
                          inputValue  &&
                            <img src={CloseIcon} alt="Close" onClick={resetVal}/>
                        }
                      </div>
                    </InView>
                    {
                      !searchedPlaces && !inputValue && renderErrorMessage()
                    }
                    <ul className={`${styles.locationList} ${styles.locationListWrapper}`}>
                      {
                        searchedPlaces && searchedPlaces.map((place, index) => {
                          return (
                            <li key={index} onClick={() => handleOnPlaceSelect(place)}>
                              <span>{place.label}</span>
                            </li>
                          );
                        })
                      }
                    </ul>
                  </>
      }
    </div>
  );
};
export default LocationSearch;
