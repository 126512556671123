import React, { useState, useEffect, useRef } from "react";
import styles from "./dealer-search.module.css";
import ButtonLoader from "../../../components/button-loader";
import { useDebounce } from "../../../utils/debaounce";
import { NUMBERS } from "../../../utils/app-constants";
import UnorderedList from "../../../components/shared/unordered-list/component";
import ListItem from "../../../components/shared/list-item/component";
import DealerSearchItem from "../dealer-search-item";
import { PartnersLeadService } from "../../service";
// import { getDealersData } from "../../data";
const DealerSearch = ({
  setSelectedDealer,
  UpdateDealerConnect
}) => {
  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [noRecords, setNoRecords] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const searchRef = useRef(null);
  const searchInputRef = useRef();
  const debouncedSearchTerm = useDebounce(query, 1000);
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  useEffect(() => {
    if (showSearch) {
      searchInputRef.current.focus();
    }
  }, [showSearch]);

  useEffect(() => {
    setIsLoading(false);
    if (query && query.trim().length >= NUMBERS.THREE) {
      setIsLoading(true);
    } else if (data.length > 0) {
      setData([]);
      setIsLoading(false);
    }
  }, [query]);
  useEffect(
    () => {
      // Make sure we have a value (user has entered something in input)
      if (query && query.trim().length >= NUMBERS.THREE) {
        fetchSearchResults();
      } else if (data.length > 0) {
        setData([]);
      }
    },
    [debouncedSearchTerm]
  );
  const handleClickOutside = event => {
    const targetName = event.target.getAttribute('name');
    if (searchRef.current && !searchRef.current.contains(event.target) && targetName !== 'collapsed-search') {
      resetStates();
    }
  };

  const onClickHandler = (item) => {
    if (item) {
      setSelectedDealer(item);
      UpdateDealerConnect(item);
    }
    resetStates();
  };
  const onChangeHandler = (e) => {
    const { target: { value } } = e;
    setQuery(value);
    setNoRecords(false);
  };

  const fetchSearchResults = () => {
    setIsLoading(true);
    PartnersLeadService.getDealers(query)
      .then(resp => {
        setData(resp);
        setIsLoading(false);
        if (resp.length === NUMBERS.ZERO) {
          setNoRecords(true);
        }
      })
      .catch(() => {
        // const resp = getDealersData();
        // console.log({getDealers: resp.detail.data});
        // setData(resp.detail.data);
        setData([]);
        setIsLoading(false);
        setNoRecords(false);
      });
  };

  const resetStates = () => {
    setData([]);
    setShowSearch(false);
    setNoRecords(false);
  };

  return (
    <div ref={searchRef} className={styles.dealerSearchWarpper}>
      <div className={styles.dealerSearch}>
        <input autoComplete="off" ref={searchInputRef} type="text" name="dealer-search" placeholder="Type min 3 chars to search" onChange={onChangeHandler} value={query} />
        {isLoading && <div className={styles.dealerSearchLoader}><ButtonLoader color='#000' /></div>}
        {!isLoading && <button className={styles.searchIcon}></button>}
      </div>
      {
        data.length > 0 &&
        <div className={styles.searchList}>
          <UnorderedList>
            {
              data.map(item => {
                return (
                  <ListItem key={item.userId} onClick={() => onClickHandler(item)} >
                    <DealerSearchItem item={item} />
                  </ListItem>);
              })
            }
          </UnorderedList>
        </div>
      }
      {
        noRecords &&
        <div className={styles.searchList}>
          <UnorderedList><ListItem classes={styles.noRecords} onClick={onClickHandler} >No Records Found!</ListItem></UnorderedList>
        </div>
      }
    </div>
  );
};

export default DealerSearch;
