import React, { useState, useEffect } from "react";
import NotificationsIcon from "../images/notifications.svg";
import styles from "./notifications.module.scss";
import NotificationsList from "../notifications-list";
import "firebase/auth";
import firebase from "../../utils/firebase";
import {
  getFireBaseDB, getFireBaseUserName, getFireBasePassword
} from "../../utils/url-constants";
import { createCookie, readCookie } from "../../utils/helper";
import { getStoreIdForSingleOrDoubleStoreAssignned } from "../../utils/helper";

const Notifications = ({
  history, resetNotificationnByKeyConnect, notifications
}) => {
  const { notificationCount } = notifications;
  // const storeId = getStoreIdForSingleStoreAssignned();
  let storeIdList = getStoreIdForSingleOrDoubleStoreAssignned();//[3185, 3351];
  // const { fbStoreId } = notifications;
  // storeId = "3167";//"ZURFNVNQTGtuOC91QnlVVjhURDE2Zz09"; //"cXFNdm1LQ25SNXVrY2twRDBCOFRRUT09";//
  const [validateData, setValidateData] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [recordCount, setRecordCount] = useState([]);
  const [showRecordCount, setShowRecordCount] = useState(false);
  const email = getFireBaseUserName();
  const password = getFireBasePassword();

  useEffect(() => {
    let custDashboardDb;
    let storeId = 0;
    if (!!storeIdList && storeIdList.length > 0) {
      storeId = storeIdList[0];
    }
    if (storeId > 0) {
      custDashboardDb = firebase.getInstance().database().ref(`/${getFireBaseDB()}/notification/${storeId}`);
      const dealerBackOutCookie = readCookie("fbDealerBackout");
      setRecordCount([]);
      setNotificationData([]);
      if (!!dealerBackOutCookie) {
        setValidateData(true);
      } else {
        firebase.getInstance().auth().signInWithEmailAndPassword(email, password)
          .then(() => {
            setValidateData(true);
            createCookie("fbDealerBackout", "1", 1);
          })
          .catch(error => {
            setValidateData(false);
            console.log("Error in firebase user authentication - " + error);
          });
      }

      return () => {
        custDashboardDb.off();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setRecordCount([]);
    setNotificationData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(storeIdList)]);

  useEffect(() => {
    let custDashboardDb;
    storeIdList.map((item) => {
      let _storeId = item;
      custDashboardDb = firebase.getInstance().database().ref(`/${getFireBaseDB()}/notification/${_storeId}`);
      if (validateData) {
        custDashboardDb.on('child_added', (snapshot) => {
          const data = snapshot.val();
          if (data) {
            addToFirebaseObject(data);
          }
        });
      }
    });
    return () => {
      custDashboardDb.off();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateData, JSON.stringify(storeIdList)]);
  const addToFirebaseObject = (data) => {
    try {
      let dataObjectArrayForcount = recordCount;
      let dataObjectArray = [];
      let dataObject = {
        appointment: data.appointment,
        appointmentId: data.appointmentId,
        idLmsStore: data.idLmsStore,
        message: data.message,
        type: data.type,
        created_date: data.created_date
      };
      dataObjectArray.push(dataObject);
      dataObjectArrayForcount.push(dataObject);
      // setRecordCount(dataObjectArray);
      setNotificationData(m => m.concat(dataObjectArray));
      if (dataObjectArray.length > notificationCount) {
        // let _rcCount = notificationCount - dataObjectArray.length;
        // setRecordCount(_rcCount);
        setShowRecordCount(true);
      }
    }
    catch (e) {
    //   let abc = "1";
      console.log("Erroor in addToFirebaseObject method - " + e);
    }

  };
  const resetRecordCount = (count) => {
    resetNotificationnByKeyConnect("notificationCount", count);
    setShowRecordCount(false);

  };
  return (
    <div className={styles.notificationsWrapper}>
      {!!storeIdList && storeIdList.length > 0 && <>
        <div className={styles.notificationsCountWrapper} onClick={() => resetRecordCount(notificationData.length)}>
          {/* {console.log("Record Count - " + !!recordCount ? recordCount.length : 0)}
                    {console.log("Record Count for notification- " + !!recordCount ? recordCount.length : 0)} */}
          <img src={NotificationsIcon} alt="Notifications" />
          {showRecordCount && <p className={styles.notificationsCount}>{notificationData.length}</p>}
        </div>

        <div className={styles.notificationsList}>
          <NotificationsList data={notificationData} history={history} />
        </div>

      </>
      }
    </div >
  );
};

export default Notifications;
