import React, { useState } from "react";
import Button from "../../../components/shared/button/component";
import InputText from "../../../components/shared/input-text/component";
import { NUMBERS } from "../../../utils/app-constants";
import { BUTTON_STYLES } from "../../common/app-constants";
import DealerSearch from "../dealer-search";
import styles from "./lead-procurement-card.module.scss";
import { isValidRegistrationNo } from "../../common/helpers/is-valid-registration-no";
import { showToastMessages } from "../../../utils/helper";

const LeadProcurementCard = (props) => {
	const { fetchVehicleDetailsConnect, setIsHomeGetPriceClicked } = props;
	const [regNumber, setRegNumber] = useState("");
	const [isValidRegNumber, setIsValidRegNumber] = useState(false);
	const [selectedDealer, setSelectedDealer] = useState();
	const isValidRegex = (v) => isValidRegistrationNo(v);
	const registrationNumberValidator = (v) => {
		const isValid = isValidRegex(v.replace(/\s/g, ""));
		setIsValidRegNumber(isValid);
		return isValid;
	};
	const onRegNoLostFocus = () => {
		if (isValidRegNumber) {
			fetchVehicleDetailsConnect(regNumber).catch((err) => {
				console.log({ err });
			});
		}
	};
	const onGetCarPrice = () => {
		if (!isValidRegNumber) {
			showToastMessages("Reg No. not filled", false);
		} else {
			setIsHomeGetPriceClicked(true);
		}
	};

	return (
		<div className={styles.leadProcureCard}>
			<div className={styles.regNumber}>
				<label className={styles.inputLabel}>Enter the car number</label>
				<InputText
					errorMessage={"Please enter a valid car number"}
					value={regNumber}
					placeholder="Eg. DL11AD3345"
					maxLength={NUMBERS.TEN}
					onChange={(e) => setRegNumber(e.target.value.toUpperCase())}
					validator={registrationNumberValidator}
					onBlurCallback={onRegNoLostFocus}
				/>
			</div>
			<div className={styles.regNumber}>
				<label className={styles.inputLabel}>Select a dealer</label>
				<DealerSearch setSelectedDealer={setSelectedDealer} />
				{selectedDealer && (
					<>
						<div className={styles.regNumber}>
							<label className={styles.inputLabel}>UCM Name</label>
							<InputText value={selectedDealer.ucmName} disabled />
						</div>
						<div className={styles.regNumber}>
							<label className={styles.inputLabel}>Dealer Code</label>
							<InputText value={selectedDealer.dealerCode} disabled />
						</div>
						<div className={styles.regNumber}>
							<label className={styles.inputLabel}>Phone Number</label>
							<InputText value={selectedDealer.ucmPhone} disabled />
						</div>
					</>
				)}
			</div>
			{selectedDealer && (
				<div className={styles.btnCarPrice}>
					<Button
						ctaText={"Get car Price"}
						classNames={BUTTON_STYLES.DEFAULT}
						onClick={onGetCarPrice}
					/>
				</div>
			)}
		</div>
	);
};

export default LeadProcurementCard;
