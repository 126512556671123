import Types from "./types";
import {AppointmentService} from "../../service";

const fetchDepreciationReports = (appointmentId) => dispatch => {
  return AppointmentService.getDepreciationData(appointmentId)
    .then(response => {
      const { data: { detail } } = response;
      dispatch(fetchDepreciationReportsSuccess(detail));
      return response;
    })
    .catch(error => dispatch(fetchDepreciationReportsFailure(error)));
};

const fetchDepreciationReportsSuccess = (data) => ({
  type: Types.FETCH_DEPRECIATION_SUCCESS,
  data
});

const fetchDepreciationReportsFailure = (error) => ({
  type: Types.FETCH_DEPRECIATION_FAILURE,
  error
});

const resetDepreciation = () => ({
  type: Types.RESET_DEPRECIATION
});

export {
  fetchDepreciationReports,
  resetDepreciation
};
