import React, { useState, useEffect } from "react";
import styles from "./feedback.module.scss";
import Textarea from "../shared/textarea/component";
import Button from "../shared/button/component";
import Like from "../images/likeIcons.svg";
import DisLike from "../images/dislikeIcons.svg";
import Close from "../images/close.svg";
import { AppointmentService } from "../../service";
import { showToastMessages, saveToLocalStorage } from "../../utils/helper";
import { LOCAL_STORAGE_KEYS } from "../../utils/app-constants";

const Feedback = ({ appointmentId }) => {
  const [showLikeView, setShowLikeView] = useState(false);
  const [showDislikeView, setShowDislikeView] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);
  const [feedBack, setFeedBack] = useState("");
  const [feedBackText, setFeedBackText] = useState("");
  const [submitFeedBackSuccess, setSubmitFeedBackSuccess] = useState(false);

  const showQuestionDiv = (text) => {
    setFeedBack(text);
    setShowQuestion(true);
  };
  const onFeedBackChange = (e) => {
    const { target: { value } } = e;
    setFeedBackText(value);
  };
  const SubmitFeedBack = () => {
    const data = {
      "appointmentId": appointmentId,
      "type": "dep_report",
      "comment": feedBackText,
      "feedback": feedBack
    };
    AppointmentService.sendDepReportFeedBack(data)
      .then(resp => {
        const { data: { detail } } = resp;
        setFeedBackText("");
        if (!!feedBackText) {
          showToastMessages(detail);
          saveToLocalStorage(LOCAL_STORAGE_KEYS.DEP_FEEDBACK_SUBMIT, true);
          setSubmitFeedBackSuccess(true);
          setShowQuestion(false);
        }
      })
      .catch(err => {
        const { detail } = err;
        showToastMessages(detail, false);

      });
  };
  const setEnableDisbaleButton = (enable) => {
    if (enable) {
      setShowLikeView(true);
      setShowDislikeView(false);
      setFeedBack("yes");

    }
    else {
      setShowLikeView(false);
      setShowDislikeView(true);
      setFeedBack("no");
    }
  };
  useEffect(() => {
    if (!!feedBack) {
      SubmitFeedBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedBack]);

  const onClickHideFeedBack = () => {
    // setFeedBack("no");
    setSubmitFeedBackSuccess(true);
  };
  return (
    <>
      {!submitFeedBackSuccess && <div className={styles.feedBackmain}>
        <div className={styles.wrapper}>
          <div className={styles.likeSection}>
            <p className={styles.text}>Was this report helpful to you?</p>
            <a href="javascript://" onClick={() => showQuestionDiv("yes")}>
              <span className={`${showLikeView ? styles.active : ''}`}>
                <img onClick={() => setEnableDisbaleButton(true)} src={Like} alt="like" />
              </span>
            </a>
            <a href="javascript://" onClick={() => showQuestionDiv("no")}>
              <span className={`${showDislikeView ? styles.active : ''}`}>
                <img onClick={() => setEnableDisbaleButton(false)} src={DisLike} alt="dislike" />
              </span>
            </a>
          </div>
          {showQuestion &&
          <div className={styles.feedbackWrapper}>
            <div className={styles.feedback}>
              <div className={styles.textarea}>
                <Textarea
                  onChange={onFeedBackChange}
                  value={feedBackText}
                  placeholder="Please give your feedback on depreciation report and price report." />
              </div>
              <div className={styles.cta}>
                <Button ctaText="SUBMIT" classNames="saveCta" onClick={SubmitFeedBack} />
              </div>
            </div>
          </div>
          }
          <button className={styles.closeIcon} onClick={() => onClickHideFeedBack()}>
            X
          </button>
        </div>
      </div>
      }
    </>
  );
};

export default Feedback;
