import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GeneratePrPopup from "./component";
import {setCurrentPopup, setSuggestedC24Quote, updateSuggestedQuote} from "../negotiation-list/actions";

const mapStateToProps = ({
  negotiations
}) => ({ negotiations });

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateSuggestedQuoteConnect: updateSuggestedQuote,
  setCurrentPopupConnect: setCurrentPopup,
  setSuggestedC24QuoteConnect: setSuggestedC24Quote
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GeneratePrPopup);
