import Types from "./types";
import { AppointmentService } from "../../service";
import {updateApiNoAccess} from "../hoc-access-controller/actions";
const fetchFollowUpList = (type) => dispatch => {
  dispatch(fetchFollowUp());
  return AppointmentService.getFollowUpDispositions(type)
    .then(response => {
      const { data: { detail } } = response;
      dispatch(fetchFollowUpSuccess(detail));
    })
    .catch(error => {
      dispatch(updateApiNoAccess(error));
      dispatch(fetchFollowUpFailure(error));
    });
};

const fetchFollowUp = () => ({
  type: Types.FETCH_FOLLOW_UP
});

const fetchFollowUpSuccess = (data) => ({
  type: Types.FETCH_FOLLOW_UP_SUCCESS,
  data
});

const fetchFollowUpFailure = (error) => ({
  type: Types.FETCH_FOLLOW_UP_FAILURE,
  error
});

const postFollowUp = (params) => dispatch => {
  dispatch(fetchFollowUp());
  return AppointmentService.postFollowUp(params)
    .then(response => {
      const { data: { detail } } = response;
      dispatch(postFollowUpSuccess(detail));
    })
    .catch(error => {
      dispatch(postFollowUpFailure(error));
    });
};

const postFollowUpSuccess = (data) => ({
  type: Types.POST_FOLLOW_UP_SUCCESS,
  data
});

const postFollowUpFailure = (error) => ({
  type: Types.POST_FOLLOW_UP_FAILURE,
  error
});

const resetState = (key, value) => dispatch => {
  dispatch(restFollowUpState(key, value));
};

const restFollowUpState = (key, value) => ({
  type: Types.RESET_FOLLOW_UP_KEY,
  data: { key, value }
});

const resetFollowUp = () => ({
  type: Types.RESET_FOLLOW_UP
});

export {
  fetchFollowUpList,
  postFollowUp,
  resetState,
  resetFollowUp
};
