import React, { useEffect, useState, useRef, useCallback } from "react";
import styles from "./schedule-pickup-popup.module.scss";
import H2 from "../../components/h2";
import Button from "../../components/shared/button";
import Textarea from "../shared/textarea/component";
import { AppointmentService } from "../../service";
import { getFromLocalStorage, showToastMessages } from "../../utils/helper";
import SelectComponent from "../shared/select/component";
// import { getBikePickupRequestMock } from "../../utils/mocApi";
import OnlineSearch from "../shared/search/online";
import { googlePlacesOptions } from "../../utils/auto-complete/search-options";
import { BIKE_PICKUP_STATUS, NUMBERS, LOCAL_STORAGE_KEYS } from "../../utils/app-constants";
import InputText from "../shared/input-text/component";
import debounce from "../../utils/helpers/debounce";
import _debounce from 'lodash/debounce';

const SchedulePickupPopup = ({ resetListing, onClose, userData }) => {
  const mapType = getFromLocalStorage(LOCAL_STORAGE_KEYS.MAP_TYPE_ACTIVE);
  const { customerName, model, year, customerAddress, zone, appointmentId, pickupRequestStatus } = userData;
  const [ isEditingAddress, setIsEditingAddress ] = useState(false);
  const [ address, setAddress ] = useState(customerAddress);
  const [ editedAdress, onChangeEditedAdress ] = useState(customerAddress);
  const [dateSlots, setDateSlots] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [ selectedTime, setSelectedTime ] = useState();
  const [ selectedDate, setSelectedDate ] = useState();
  const changeLocationRef = useRef();
  const [searchText, setSearchText] = useState('');
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [selectedCity, setSelectedCity] = useState();
  const [state, setState] = useState('');
  const [selectedPincode, setSelectedPincode] = useState('');
  const [cityOptions, setCityOptions] = useState([]);
  const [dateTimeSlots, setDateTimeSlots] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);
    
  const loadSuggestedOptions = React.useCallback(
    _debounce((inputValue, callback) => {
      googlePlacesOptions(inputValue, mapType).then(options => callback(options));
    }, 1000),
    []
  );

  useEffect(() => {
    if(pickupRequestStatus === BIKE_PICKUP_STATUS.RE_SCHEDULED){
      AppointmentService.getBikePickupRequest(appointmentId)
        .then(resp => {
          const {data: { detail }} = resp || {};
          const { logistic: {pickupDate, pickupSlot} = {}, stateName, cityName, cityCode, pincode, geoLocation, lat, lng } = detail || {};
          !!cityName && !!cityCode && setSelectedCity({label: cityName, value: cityCode});
          setState(stateName);
          setLat(lat);
          setLng(lng);
          setSelectedPincode(pincode);
          !!pickupDate && setSelectedDate({label: pickupDate, value: pickupDate});
          !!pickupSlot && setSelectedTime({label: pickupSlot, value: pickupSlot});
          getCityOptions(cityName);
          getTimeslots(pickupDate);
          setSearchText(geoLocation);
        })
        .catch(error => {
          const {detail} = error;
          showToastMessages(detail, false);
          getTimeslots();
        });
    } else {
      getTimeslots();
    }
  }, []);

  const getTimeslots = (date) => {
    AppointmentService.getBikePickupDatetimeSlots()
      .then(resp => {
        const { data: { detail: { slots } } } = resp;
        setDateTimeSlots(slots);
        const dates = slots.map(item => { return { value: item.date, label: item.date }; });
        setDateSlots(dates);
        if (!!date) {
          const selDatetimeSlots = slots.find((item) => item.date === date).slots;
          const timeSlotsData = selDatetimeSlots.map((item)=> ({label:item, value:item}));
          setTimeSlots(timeSlotsData);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
  const handleEditAddressDone = () => {
    const params = {
      'appointment': appointmentId,
      'field': 'customerAddress',
      'value': editedAdress
    };
    AppointmentService.updateAppointment(params)
      .then(resp => {
        const { data: { detail: { message } } } = resp;
        showToastMessages(message);
        setAddress(editedAdress);
        setIsEditingAddress(false);
      })
      .catch(err => {
        const { detail } = err;
        showToastMessages(detail, false);
        setIsEditingAddress(false);
      });
  };
  const handleEditAddressCancel = () => {
    setIsEditingAddress(false);
  };

  const onAppointmentPickupRequest = () => {
    setIsSubmit(true);
    if (pickupRequestStatus === BIKE_PICKUP_STATUS.RE_SCHEDULED) {
      if (isValidData()) {
        const params = {
          "appointmentId": appointmentId,
          "action": "CANCEL"
        };
        AppointmentService.postBikePickupRequest(params)
          .then(() => {
            onPickupRequest(true);
          })
          .catch(err=>{
            setIsSubmit(false);
            const { detail } = err;
            showToastMessages(detail, false);
            onClose();
          });
      }
    } else {
      onPickupRequest();
    }
  };
  const isValidData = () => {
    if (!searchText) {
      showToastMessages("Please select valid location", false);
      setIsSubmit(false);
      return false;
    }
    else if (!selectedPincode || (selectedPincode && String(selectedPincode).length !== NUMBERS.SIX)) {
      showToastMessages("Please select valid pincode", false);
      setIsSubmit(false);
      return false;
    } else if (!state) {
      showToastMessages("Please select valid state", false);
      setIsSubmit(false);
      return false;
    } else if (!selectedCity  || (selectedCity && !selectedCity.value)) {
      showToastMessages("Please select valid city", false);
      setIsSubmit(false);
      return false;
    } 
    else if (!selectedDate  || (selectedDate && !selectedDate.value)) {
      showToastMessages("Please select valid date", false);
      setIsSubmit(false);
      return false;
    } else if (!selectedTime  || (selectedTime && !selectedTime.value)) {
      showToastMessages("Please select valid time", false);
      setIsSubmit(false);
      return false;
    }
    return true;
  };

  const onPickupRequest = (isCancelSuccess = false) => {
    const isValid = !isCancelSuccess ? isValidData(isCancelSuccess) : true;
    if (isValid) {
      const params = {
        "appointmentId": appointmentId,
        "pickupDate": selectedDate.value,
        "pickupSlot": selectedTime.value,
        "customerAddress": address,
        "geoLocation" : searchText,
        "lat": lat,
        "lng": lng,
        "city": selectedCity.label,
        "cityCode": selectedCity.value,
        "state": state,
        "pincode": selectedPincode,
        "action": "SUBMIT"
      };
      AppointmentService.postBikePickupRequest(params)
        .then(resp => {
          const { data: { detail: { message } } } = resp;
          showToastMessages(message);
          setTimeout(() => {
            resetListing();
            onClose();
          }, 2000);
        })
        .catch(err => {
          const { detail } = err;
          showToastMessages(detail, false);
          setTimeout(() => {
            resetListing();
            onClose();
          }, 500);
        });
    }
  };

  const onCancelPickupRequest = () => {
    setIsSubmit(true);
    if (pickupRequestStatus === BIKE_PICKUP_STATUS.RE_SCHEDULED) {
      const params = {
        "appointmentId": appointmentId,
        "action": "CANCEL"
      };
      AppointmentService.postBikePickupRequest(params)
        .then(resp => {
          const { data: { detail: { message } } } = resp;
          showToastMessages(message);
          setTimeout(() => {
            resetListing();
            onClose();
          }, 2000);
        })
        .catch(err => {
          const { detail } = err;
          showToastMessages(detail, false);
          setTimeout(() => {
            resetListing();
            onClose();
          }, 2000);
        });
    } else {
      onClose();
    }
  };

  const getCityOptions = (cityName) => {
    !!cityName && AppointmentService.getCities(cityName).then((resp)=>{
      const {data:{detail: {city = {}}}} = resp;
      const cities = !!city && city.map((item)=>({label: item.name, value: item.code}));
      !!cities && setCityOptions(cities);
      !!cities && cities.length === 1 && setSelectedCity(cities[0]);
      (!cities || cities.length === 0) && setSelectedCity();
    });
  };

  const onLocationSelection = async (item) => {
    setState();
    setSelectedCity();
    setLat();
    setLng();
    setSelectedPincode();
    !!item.lat && setLat(item.lat);
    !!item.lng && setLng(item.lng);
    !!item.pincode && setSelectedPincode(item.pincode);
    const locationArray = item.label.split(',');
    if (locationArray.length >= 3) {
      const cityQuery = locationArray[locationArray.length - 3].trim();
      setSelectedCity(cityQuery);
      setState(locationArray[locationArray.length - 2].trim());
      const pin = locationArray[locationArray.length  - 1].trim();
      if (!item.pincode && !isNaN(pin) ) {
        setSelectedPincode(pin);
      }
      getCityOptions(cityQuery);
    }
    setSearchText(item.label);
  };

  const onSelectCity = (e) => {
    const selectedCity = cityOptions && cityOptions.find((item)=> item.value === e);
    setSelectedCity(selectedCity);
  };

  const delayedQuery = useCallback(
    debounce((val) =>
      getCityOptions(val)
    , 2000),
    []
  );

  const onTextChange = (value) => {
    if (value && value.length >= 3) {
      delayedQuery(value);
    } else {
      setSelectedCity();
    }
  };

  const onChangeDate = (e) => {
    setSelectedDate({label: e, value: e});
    const selDatetimeSlots = dateTimeSlots.find((item) => item.date === e).slots;
    const timeSlotsData = selDatetimeSlots.map((item)=> ({label:item, value:item}));
    setTimeSlots(timeSlotsData);
  };

  const onChangeTime = (e) => {
    setSelectedTime({label: e, value: e});
  };

  const onPincodeChange = (e) => {
    const value = e.target.value;
    setSelectedPincode(value);
  };

  const onStateChange = (e) => {
    const value = e.target.value;
    setState(value);
  };
  return (
    <div className={styles.schedulePickupPopup}>
      <H2 text="Schedule pickup"/>
      <div className={styles.carDataList}>
        <ul>
          <li>{customerName}</li>
          <li>{model}</li>
          <li>{year}</li>
        </ul>
      </div>
      <div className={styles.address}>
        <div className={styles.addressWrapper}>
          { isEditingAddress ? 
            <>
              <Textarea 
                value={editedAdress}
                onChange={(e) => onChangeEditedAdress(e.target.value)}
              />
              <div className={styles.addressUpdateCta}>
                <Button ctaText="DONE" classNames="secondaryCta" onClick={handleEditAddressDone}/>
                <Button ctaText="CANCEL" classNames="addressCancelCta" onClick={handleEditAddressCancel} />
              </div>
            </>
                        :<p>{address}</p>}

        </div>
        { !isEditingAddress && <spam onClick={() => setIsEditingAddress(true)} className={styles.edit}>Edit</spam>}
      </div>
            
      <div className={styles.timeDateWrapper}>
        <div className={styles.inputWrap}>
          {!!searchText && <OnlineSearch
            placeholder={'Location'}
            onChange={onLocationSelection}
            getOptions={loadSuggestedOptions}
            overrideSearchRef={changeLocationRef}
            customDefaultValue = {searchText}
          />}
          {!searchText && <OnlineSearch
            placeholder={'Location'}
            onChange={onLocationSelection}
            getOptions={loadSuggestedOptions}
            overrideSearchRef={changeLocationRef}
          />}
        </div>
        <div className={styles.inputWrap}>
          <InputText 
            placeholder="Pincode" 
            value={selectedPincode} 
            id={selectedPincode} 
            name={selectedPincode}
            onChange={onPincodeChange}
            type="number"
            maxLength={NUMBERS.SIX}
            // validationError={invalidPincode}
            // errorMessage="Invalid Pin Code"
          />
        </div>
      </div>
      <div className={styles.timeDateWrapper}>
        <div className={styles.inputWrap}>
          {!!selectedCity && <SelectComponent 
            placeholder="City"
            optionsList={cityOptions}
            defaultValue={selectedCity ? selectedCity : ""}
            onChange={onSelectCity}
            onInputChange={onTextChange}
          />}
          {!selectedCity && <SelectComponent 
            placeholder="City"
            optionsList={cityOptions}
            defaultValue={selectedCity ? selectedCity : ""}
            onChange={onSelectCity}
            onInputChange={onTextChange}
          />}
        </div>
        <div className={styles.inputWrap}>
          <InputText 
            placeholder="State" 
            value={state} 
            id={state} 
            name={state} 
            onChange={onStateChange}
          />
        </div>
      </div>
            
      { zone && <p className={styles.zone}>{zone}</p>}
      <div className={styles.timeDateWrapper}>
        <div className={styles.dateWrapper}>
          {!!selectedDate && <SelectComponent
            placeholder={"Select Date"}
            optionsList={dateSlots}
            defaultValue={selectedDate}
            questionId={appointmentId}
            onChange={onChangeDate}
          />}
          {!selectedDate && <SelectComponent
            placeholder={"Select Date"}
            optionsList={dateSlots}
            defaultValue={selectedDate}
            questionId={appointmentId}
            onChange={onChangeDate}
          />}
        </div>
        <div className={styles.timeWrapper}>
          {!!selectedTime && <SelectComponent
            placeholder={"Select Time"}
            optionsList={timeSlots}
            defaultValue={selectedTime}
            questionId={appointmentId}
            onChange={onChangeTime}
          />}
          {!selectedTime && <SelectComponent
            placeholder={"Select Time"}
            optionsList={timeSlots}
            defaultValue={selectedTime}
            questionId={appointmentId}
            onChange={onChangeTime}
          />}
        </div>
      </div>
      <div className={styles.ctaWrapper}>
        <Button ctaText={pickupRequestStatus === BIKE_PICKUP_STATUS.RE_SCHEDULED ? "RE-SCHEDULE" : "CREATE PICKUP"} classNames="saveCta" onClick={onAppointmentPickupRequest}  disabled={isSubmit} />
        <Button ctaText={pickupRequestStatus === BIKE_PICKUP_STATUS.RE_SCHEDULED ? "CANCEL PICKUP" : "CANCEL"} classNames="cancelCta" onClick={onCancelPickupRequest} disabled={isSubmit} />
      </div>
    </div>
  );
};

export default SchedulePickupPopup;
