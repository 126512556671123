import Types from "./types";

const updateFilterQuery = (filterQuery) => ({
  type: Types.UPDATE_FILTER_QUERY,
  filterQuery
});

export {
  updateFilterQuery
};
