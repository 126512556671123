import React, { useEffect, useState } from "react";
import styles from "./incentive.module.scss";
import H2 from "../../components/h2/component";
import TopPerformers from "../../components/top-performers";
import TargetAchieveBar from "../../components/target-achieve-bar";
import { getFromLocalStorage,getTimeDiffinMinsFromNow,getUid } from "../../utils/helper";
import { LOCAL_STORAGE_KEYS, NUMBERS, ROLE_KEYS } from "../../utils/app-constants";
import {trackLMSEvents} from "../../utils/helpers/tracking-events";
import GiftBox from "../../components/images/giftBox.svg";
import BackArrow from "../../components/images/back-arrow.svg";
import moment from "moment";
import { navigateToHomeWithDelay } from "../../utils/helper";
import Popup from "../../components/popup/component";
import LoginAttendancePopup from "../../components/login-attendance-popup/component";
const Incentive = ({ history,
  raIncentive,
  raIncentiveConnect}) => {
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);
  const {originalRole, email, showWeekoffStatus } = JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA)) || {};
    
  useEffect(()=>{
    if (!!originalRole && [ROLE_KEYS.RA_TRA,ROLE_KEYS.RA_CX_OCB].includes(originalRole) && !showWeekoffStatus) {
      setIsLoginSuccess(true);
    }
  }, []);

  useEffect(() => {
    if (!!email) {
      raIncentiveConnect(email);
      trackReportEvent();
    }
  }, [email]);

  const trackReportEvent =()=>{
    trackLMSEvents("ls_ViewIncentive", {
      eventLabel: '',
      eventAction: 'ViewIncentive',
      eventCategory: 'Negotiation',
      lsRAID: getUid(),
      event: 'ls_ViewIncentive',
      Timestamp: moment().format()
    });
  };
  const backToHome =()=>{
    navigateToHomeWithDelay(history);
  };
  const showDataPanel = () => {
    if (!!raIncentive && !raIncentive.isLoading && !!raIncentive.data && !!raIncentive.data.data && !!raIncentive.data.data.centre){
      return (
        <div className={styles.contentWrapper}>
          <div className={styles.targetAchievedSection}>
            <TargetAchieveBar data = {!!raIncentive.data.data? raIncentive.data.data: {}}/>
          </div>
          <div className={styles.topPerformers}>
            <TopPerformers data = {!!raIncentive.data.data? raIncentive.data.data : {}}/>
          </div>
        </div>
      );
    }
    else{
      return (
        <div className={styles.oops}>
          <img src={GiftBox} alt="gift box" width="146px" height="128px" />
          <h3>OOPS!</h3>
          <p>Either this feature is not available at your store or we may not have your current incentive information with us.</p>
          <p>Please reach out to your TM in case of any queries.</p>
        </div>
      );
    }
  };
  return (
    <div className={styles.incentiveWrapper}>
      <div className={styles.incentiveHeading}>
        <div className={styles.back}>
          <img alt="back" className={styles.back} src={BackArrow} onClick={backToHome}/>
        </div>
        <H2 text={"Incentives"} />
      </div>
      {showDataPanel()}
      <Popup isOpen={isLoginSuccess} close={()=> setIsLoginSuccess(false)} closeOnOutside={false} shouldCloseOnEsc={false} 
        showCloseButton={false}>
        <LoginAttendancePopup onClose={()=> setIsLoginSuccess(false)} user={email} />
      </Popup>
    </div>
  );
};

export default Incentive;
