import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DomoDashbaord from "./component";
import { getDomoToken } from "./actions";

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getDomoTokenConnect: getDomoToken
}, dispatch);

export default connect(null, mapDispatchToProps)(DomoDashbaord);
