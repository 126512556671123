export default (api) => {
  const getStoreAvailableSlots = ({lat, lng, token}) => {
    return new Promise((resolve, reject)=> {
      api.get(`api/v2/slots/available/store-and-zone?token=${token}&lat=${lat}&lng=${lng}&inspection_type=PnS`).then((response)=>{
        resolve(response.data);
      }).catch(err => {
        reject(err);
      });
    });
  };

  return {
    getStoreAvailableSlots,
  };
};