import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  resetAndFetchData: false
};

export const resetLeadMenuByKey = (state = INITIAL_STATE, { data }) => {
  const { key, value } = data;
  return { ...state, [key]: value };
};

export const HANDLERS = {
  [Types.RESET_LEAD_MENU_BY_KEY]: resetLeadMenuByKey
};

export default createReducer(INITIAL_STATE, HANDLERS);
