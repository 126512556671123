import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../components/shared/button/component";
import SelectDropdown from "../../../components/shared/SelectDropdown/SelectDropdown";
import { showToastMessages } from "../../../utils/helper";
import { BUTTON_STYLES, KM_RANGE, APP_CONSTANTS, LS_KEYS } from "../../common/app-constants";
import { LeadService, VehicleService } from "../../service";
import styles from "./lead-car-details.module.scss";
import Loader from "../../../components/shared/loader";
import { getFromLocalStorage } from "../../../utils/helper";
const LeadCarDetails = ({
  setIsHomeGetPriceClicked,
  leadProcurementData,
  setLoading,
  UpdateRegistrationCarDetailsConnect,
  ResetLeadDataConnect,
}) => {
  const {registrationNumber, registrationCarDetails={}, dealer} = leadProcurementData || {};
  const [carMakers, setCarMakers] = useState([]);
  const [carMakerYears, setCarMakerYears] = useState([]);
  const [carMakerYearsModels, setCarMakerYearsModels] = useState([]);
  const [carVariants, setCarVariants] = useState({});
  const [kmsDriven, setKmsDriven] = useState('');
  const [transmissionTypes, setTransmissionTypes] = useState([]);
  const [rightVariants, setRightVariants] = useState([]);
  const [rtoStates, setRtoStates] = useState([]);
  const [stateRtos, setStateRtos] = useState([]);
  const {brand, model, states, curYear, RTO, ds_details=[], fuelType: curFuelTyoe} = registrationCarDetails || {};
  const {make_id=''}=brand || {};
  const {model_id=''} = model || {};
//   const {year='',year_id=''} = curYear || {};
  const {state_id=''} = states || {};
  const {rto_code='',rto_detail_id=''} = RTO || {};
  const {variant={}} = ds_details && ds_details.length > 0 ? ds_details[0] : [];
  const { variant_id = '', transmission_type='', fuel_type=''} = variant || {};
  const {dealerCentreId, ucmPhone, dealerCode, companyCode, cityId, pincode, cityName, latitude, longitude } = dealer || {};
  const [fuelType, setFuelType] = useState(curFuelTyoe || fuel_type);
  const [selectCarTransmissionType, setSelectCarTransmissionType] = useState(transmission_type);
  const [selectRightVariant, setSelectRightVariant] = useState(variant_id);
  const [isConfirmationDone, setIsConfirmationDone] = useState(false);
  const history = useHistory();
  const [regYear, setRegYear] = useState( curYear || {});
  useEffect(()=>{
    VehicleService.getCarMakerMasterData().then(resp=>{
      setCarMakers(resp?.detail.sort((a,b)=> a.displayOrder - b.displayOrder));
    }).catch(err => {
      console.log({err});
    });
    VehicleService.getStates().then(resp=>{
      setRtoStates(resp?.detail.sort((a,b)=> a.displayOrder - b.displayOrder));
    }).catch(err =>{
      console.log({err});
    });
  },[]);

  useEffect(()=>{
	if (make_id) {
      VehicleService.getCarMakerYears(make_id).then(resp=>{
        setCarMakerYears(resp?.detail);
		const {regn_year} = registrationCarDetails || {};
		const selYear = resp?.detail?.find((item)=> item.year === regn_year);
		setRegYear(selYear || {});
      }).catch(err=>{
        console.log({err});
      });
    }
  },[make_id]);
    
  const onSelectMaker = (value) => {
    const makerData = carMakers.find(item=> item.make_id.toString() === value.toString());
    const updatedLeadData = {
      ...registrationCarDetails,
      brand: {
        make_id: makerData.make_id,
        make_display: makerData.make_display
      },
      year: null,
      model: null,
      fuelType: null
    };
    setFuelType('');
    setSelectCarTransmissionType('');
    setSelectRightVariant('');
    UpdateRegistrationCarDetailsConnect(updatedLeadData);
  };

  useEffect(()=>{
	if (!!regYear?.year) {
      VehicleService.getCarMakerYearsModel(make_id, regYear?.year).then(resp=>{
        setCarMakerYearsModels(resp?.detail);
      }).catch(err=>{
        console.log({err});
      });
    }
  },[regYear?.year]);

  const onSelectCarYear = (e) => {
	const yearValue =  e.target.value;
    const curYear = carMakerYears.find(item=> item.year_id.toString() === yearValue);
	setRegYear(curYear);
    const updatedLeadData = {
      ...registrationCarDetails,
      year: curYear,
      model: null,
      fuelType: null,
    };
    setFuelType('');
    setSelectCarTransmissionType('');
    setSelectRightVariant('');
    UpdateRegistrationCarDetailsConnect(updatedLeadData);
  };

  useEffect(()=>{
	if (!!model_id && !!regYear?.year) {
      VehicleService.getCarVariants(model_id, regYear?.year).then(resp=>{
        setCarVariants(resp?.detail);
      }).catch(err=>{
        console.log({err});
      });
    }
  },[model_id, regYear?.year]);

  const onSelectCarModel = (value) => {
    const curModel = carMakerYearsModels.find(item=> item.model_id.toString() === value);
    const updatedLeadData = {
      ...registrationCarDetails,
      model: {
        model_id:curModel.model_id,
        model_display: curModel.model_display
      },
      fuelType: null
    };
    setFuelType('');
    setSelectCarTransmissionType('');
    setSelectRightVariant('');
    UpdateRegistrationCarDetailsConnect(updatedLeadData);     
  };

  const onSelectCarVariant = (value) => {
    setFuelType(value);
    const updatedLeadData = {
      ...registrationCarDetails,
      fuelType: value
    };
    setSelectCarTransmissionType('');
    setSelectRightVariant('');
    UpdateRegistrationCarDetailsConnect(updatedLeadData);  
  };

  useEffect(()=>{
    if (!!carVariants && !!fuelType) {
      const trans = carVariants[fuelType];
      !!trans && setTransmissionTypes(trans);
    }
  },[carVariants, fuelType]);

  useEffect(()=>{
    if (!!carVariants && !!fuelType && !!selectCarTransmissionType) {
      const curTransmissionType = transmissionTypes.find(item => item.value === selectCarTransmissionType);
      !!curTransmissionType && setRightVariants(curTransmissionType.variants);
    }
  },[transmissionTypes, selectCarTransmissionType]);

  useEffect(()=>{
    if (state_id) {
      VehicleService.getStateRtoList(state_id).then(resp=>{
        setStateRtos(resp?.detail.sort((a,b)=> a.displayOrder - b.displayOrder));
      }).catch(err =>{
        console.log({err});
      });
    }
  },[state_id]);

  const onSelectRtoState = (value) => {
    const curState = rtoStates.find(item => item.state_id.toString() === value);
    const updatedLeadData = {
      ...registrationCarDetails,
      states: {
        state_code: curState.state_code,
        state_id: curState.state_id,
        state_name: curState.state_name,
      }
    };
    UpdateRegistrationCarDetailsConnect(updatedLeadData);
  };

  const onSelectRto = (value) => {
    const curRto = stateRtos.find(item=> item.rto_code === value);
    const updatedLeadData = {
      ...registrationCarDetails,
      RTO: {
        rto_detail_id: curRto.rto_id,
        rto_code: curRto.rto_code
      }
    };
    UpdateRegistrationCarDetailsConnect(updatedLeadData);     
  };

  const onConfirmLeadCreation = () => {
    if (!make_id) {
      showToastMessages('Please select car maker', false);
      return;
    } else if (!regYear?.year_id) {
      showToastMessages('Please select car manufacturing year', false);
      return;
    } else if (!model_id) {
      showToastMessages('Please select car model', false);
      return;
    } else if (!fuelType) {
      showToastMessages('Please select car Fuel type', false);
      return;
    } else if (!selectCarTransmissionType) {
      showToastMessages('Please select car transmission type', false);
      return;
    } else if (!selectRightVariant) {
      showToastMessages('Please select car right  variant', false);
      return;
    } else if (!state_id) {
      showToastMessages('Please select car RTO state', false);
      return;
    } else if (!rto_detail_id) {
      showToastMessages('Please select car RTO', false);
      return;
    } else if (!kmsDriven) {
      showToastMessages('Please select car running KMs driven', false);
      return;
    }
    const params = {
      dealerCentreId: dealerCentreId,
      dealerCode: dealerCode,
      phone: ucmPhone,
      make_id: make_id,
      model_id: model_id,
      year_id: regYear?.year_id,
      variant_id: selectRightVariant,
      kms: kmsDriven,
      state_id: state_id,
      rto_id: rto_detail_id,
      device_category: "m-web",
      whatsapp_consent: true,
      user_city_id: cityId, 
      postal_code: pincode, 
      lat: latitude, 
      lng: longitude, 
      city_name: cityName, 
      vehicle_reg_no: registrationNumber,
      companyCode: companyCode,
      createdByUserEmail: getFromLocalStorage(LS_KEYS.DISPLAY_EMAIL),
      leadSource: APP_CONSTANTS.LEAD_SOURCE,
    };
    setIsConfirmationDone(true);
    setLoading(true);
    setIsHomeGetPriceClicked(false);
    LeadService.createLead(params).then((resp)=>{
      const {message, detail: {
        token
      }} = resp;
      showToastMessages(message);
      ResetLeadDataConnect();
      history.push({pathname: `/${APP_CONSTANTS.appType}/lead/detail/${token}`, state: {params, isNew: true}});
    }).catch(err =>{
      showToastMessages(err?.message, false);
    }).finally(()=> {
      setIsConfirmationDone(false);
      setLoading(false);
    });
  };
  return (
    <>
      {isConfirmationDone ? 
        <Loader /> :
        <div className={styles.leadCarDetails}>
          <div className={styles.title}>
            <h2 className={styles.title}>Enter Car Details</h2>
          </div>
          <div className={styles.inputField}>
            <label className={styles.inputLabel}>Car Brand</label>
            <SelectDropdown 
              key={`selectCarBrand`}
              name={`selectCarBrand`}
              onChange={(e)=>onSelectMaker(e.target.value)}
              optionsList={carMakers.map((item) => {
                return {
                  label: item.make_display,
                  value: item.make_id
                };
              })}
              value={make_id}
            />
          </div>
          <div className={styles.inputField}>
            <label className={styles.inputLabel}>Manufacturing Year</label>
            <SelectDropdown 
              key={`selectCarYear`}
              name={`selectCarYear`}
              onChange={onSelectCarYear}
              optionsList={carMakerYears.map((item) => {
                return {
                  label: item.year,
                  value: item.year_id
                };
              })}
              value={regYear?.year_id}
            />
          </div>
          <div className={styles.inputField}>
            <label className={styles.inputLabel}>Model</label>
            <SelectDropdown 
              key={`selectCarModel`}
              name={`selectCarModel`}
              onChange={(e) => onSelectCarModel(e.target.value)}
              optionsList={carMakerYearsModels.map((item) => {
                return {
                  label: item.model_display,
                  value: item.model_id
                };
              })}
              value={model_id}
            />
          </div>
          <div className={styles.inputField}>
            <label className={styles.inputLabel}>Fuel Type</label>
            <SelectDropdown 
              key={`selectCarVariant`}
              name={`selectCarVariant`}
              onChange={(e) => onSelectCarVariant(e.target.value)}
              optionsList={Object.keys(carVariants).map((item) => {
                return {
                  label: item,
                  value: item
                };
              })}
              value={fuelType}
            />
          </div>
          <div className={styles.inputField}>
            <label className={styles.inputLabel}>Transmission Type</label>
            <SelectDropdown 
              key={`selectCarTransmissionType`}
              name={`selectCarTransmissionType`}
              onChange={(e) => {
                setSelectCarTransmissionType(e.target.value);
                setSelectRightVariant('');
              }}
              optionsList={transmissionTypes.map(item=>{
                return {
                  label: item.title,
                  value: item.value
                };
              })}
              value={selectCarTransmissionType}
            />
          </div>
          <div className={styles.inputField}>
            <label className={styles.inputLabel}>Select the right variant</label>
            <SelectDropdown 
              key={`selectRightVariant`}
              name={`selectRightVariant`}
              onChange={(e) => {
                setSelectRightVariant(e.target.value);
              }}
              optionsList={rightVariants.map((item) => {
                return {
                  label: item.variant_display_name,
                  value: item.variant_id,
                };
              })}
              value={selectRightVariant}
            />
          </div>
          <div className={styles.inputField}>
            <label className={styles.inputLabel}>Select RTO state</label>
            <SelectDropdown 
              key={`selectRtoState`}
              name={`selectRtoState`}
              onChange={(e) => onSelectRtoState(e.target.value)}
              optionsList={rtoStates.map((item) => {
                return {
                  label: item.state_code,
                  value: item.state_id,
                };
              })}
              value={state_id}
            />
          </div>
          <div className={styles.inputField}>
            <label className={styles.inputLabel}>Select RTO</label>
            <SelectDropdown 
              key={`selectRto`}
              name={`selectRto`}
              onChange={(e) => onSelectRto(e.target.value)}
              optionsList={stateRtos.map((item) => {
                return {
                  label: item.rto_code,   
                  value: item.rto_code,
                };
              })}
              value={rto_code}
            />
          </div>
          <div className={styles.inputField}>
            <label className={styles.inputLabel}>Kilometers Driven</label>
            <SelectDropdown 
              key={`kmsDriven`}
              name={`kmsDriven`}
              onChange={(e) => setKmsDriven(e.target.value)}
              optionsList={KM_RANGE}
              value={kmsDriven}
            />
          </div>
          <div className={styles.btnCarPrice}>
            <Button 
              ctaText={"Confirm"}
              classNames={BUTTON_STYLES.DEFAULT}  
              onClick={onConfirmLeadCreation}
              disabled={isConfirmationDone}
            />
          </div>
        </div>
      }
    </>
  );
};

export default LeadCarDetails;
