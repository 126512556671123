import React from "react";
import CallLogImage from "../call-log-image/component";
import CallLogDetails from "../call-log-details/component";
import CallLogRecording from "../call-log-recording/component";
import styles from "./call-log.module.scss";

const CallLog = ({ data }) => {
  const { callType, recordingUrl, callSid } = data;

  return (
    <div>
      <div className={styles.callLogCard}>
        <CallLogImage callType={callType} />
        <CallLogDetails data={data} />
        <CallLogRecording callType={callType} recordingUrl={recordingUrl} callSid={callSid} />
      </div>

    </div>

  );
};

export default CallLog;
