/* eslint-disable indent */
import React, { useState } from "react";
import styles from "./quote.module.scss";
import Button from "../shared/button/component";
import AcceptanceStatus from "../acceptance-status";
import priceFormatter from "../../utils/helpers/price-formatter";
import popups from "../../utils/popup-constants";
import { AppointmentService } from "../../service";
import {
    getFromLocalStorage,
    getTimeDifference,
    getUid,
    saveToLocalStorage,
    showToastMessages,
    ExpireInDays,
    isPllUser,
    isBikeSource,
    getDiySortOrderKey
} from "../../utils/helper";
import Timer from "react-compound-timer";
import { TIMER_FLAGS, NUMBERS, LOCAL_STORAGE_KEYS, APP_SOURCE, TOKEN_PAYMENT_STATUS, TOKEN_PR_STATUS, BIKE_PICKUP_STATUS, DIY_SORT_ORDER, LEAD_TYPE, ROLE_KEYS, TAB_KEYS } from "../../utils/app-constants";
import { trackLMSEvents } from "../../utils/helpers/tracking-events";
import moment from "moment";
import Info from "./images/info.png";
import { useSelector } from "react-redux";
import QRCode from "qrcode.react";
import SellerIntendPopup from "../seller-intend-popup/component";
const HIGHLIGHT_COLOR = "green";
const isPll = isPllUser();
const isBike = isBikeSource();
const narrowPriceTitle = 'ONLINE PRICE SHOWN';
const Heading = ({ text, isHighligted, color, price, sortOrder, state ,diySortOrder=-1}) => {
    if (text === 'AUCTION RUNNING' || text === 'PAI RUNNING AT' || text === 'OCB RUNNING AT') {
        return <p className={styles.runningAt}>{text}</p>;
    }
    return (
      <p className={!isHighligted ? (color === HIGHLIGHT_COLOR ? styles.green : styles.heading) : styles.suspended}>{text}
        {
                sortOrder === NUMBERS.SEVEN && <span className={styles.expire}>{priceFormatter(price)}</span>

            }
        {
                sortOrder === NUMBERS.SIXTEEN && <span className={styles.expire}>{priceFormatter(price, true)}</span>

            }
            {
                (
                  (sortOrder === 42 && diySortOrder==25 ) ||
                  (sortOrder === 52) ||
                  (sortOrder === 53)
                )
                && 
                <span className={styles.expire}>{priceFormatter(price, true)}</span>

            }
        {state === DIY_SORT_ORDER.DIY_PLL_OCB_EXPIRED && <span className={styles.expire}>{priceFormatter(price, true)}</span>}
      </p>
    );
};
const HeadingV2 = ({ 
    text, 
    isHighligted, 
    color, 
    price, 
    sortOrder, 
    state,
    showNarrowPrice=false,
    minPrice,
    maxPrice, 
}) => {
    if (text === 'AUCTION RUNNING' || text === 'PAI RUNNING AT' || text === 'OCB RUNNING AT') {
        return (
          <>
            <p className={styles.runningAt}>{text}</p>
            {showNarrowPrice && <div>
              <p className={styles.redText}>{narrowPriceTitle}</p>
              <div className={styles.priceTextWrapper}>
                <p className={styles.narrowPrice}>{`₹ ${priceFormatter(minPrice)} - ₹ ${priceFormatter(maxPrice)}`}</p>
              </div>
            </div>}
          </>
        );
    } else {
        return (
          <>
            <p className={!isHighligted ? (color === HIGHLIGHT_COLOR ? styles.green : styles.heading) : styles.suspended}>{text}
              {
                        sortOrder === NUMBERS.SEVEN && <span className={styles.expire}>{priceFormatter(price)}</span>
    
                    }
              {
                        sortOrder === NUMBERS.SIXTEEN && 
                        <span className={styles.expire}>{priceFormatter(price, true)}</span>
    
                    }
              {state === DIY_SORT_ORDER.DIY_PLL_OCB_EXPIRED && 
                <span className={styles.expire}>{priceFormatter(price, true)}</span>
                    }
            </p>
            {showNarrowPrice && <div>
              <p className={styles.redText}>{narrowPriceTitle}</p>
              <div className={styles.priceTextWrapper}>
                <p className={styles.narrowPrice}>{`₹ ${priceFormatter(minPrice)} - ₹ ${priceFormatter(maxPrice)}`}</p>
              </div>
            </div>}
          </>
        );
    }
};
const CashBackOffer = ({ cashbacks }) => {
    return (
      <>
        <div className={styles.clear}></div>
        {cashbacks && cashbacks.length > 0 && cashbacks.map((item) => {
                return (
                    item.isShow && <div className={styles.cashbackBank}>
                      <p className={styles.priceText}>+₹ {priceFormatter(item.amount)} {item.label2}</p>
                    </div>
                );

            })}
      </>
    );
};
const RaiseOcbHeading = ({ 
    price, 
    bestPrice, 
    date, 
    diy_pll_shared_price, 
    validDate, 
    state, 
    diy_pll_price_status, 
    c24Quote, 
    pllAuctionFlag,
    showNarrowPrice=false,
    minPrice=0,
    maxPrice=0,
 }) => {
    const ocbValidDate = new Date(date) == "Invalid Date" ? "" : new Date(date).toLocaleDateString('en-in', {
        month: 'short',
        day: 'numeric'
    });

    const priceValidDate = new Date(validDate) == "Invalid Date" ? "" : new Date(validDate).toLocaleDateString('en-in', {
        month: 'short',
        day: 'numeric'
    });

    const customerSharedPrice = !!diy_pll_price_status ? diy_pll_shared_price : c24Quote;

    return (
      <>
        <div className={styles.currentHbWrapper}>
          <div className={styles.currentHb}>
            <p><span className={styles.hb_text}>Current Cars24 Price</span>
              <font>{priceFormatter(price, true)} {ocbValidDate && <span className={styles.tillDate}>{ocbValidDate})</span>}</font>
              {ocbValidDate && <span> (valid till</span>}
            </p>
          </div>
          <div className={styles.currentHb}>
            <span className={styles.hb_text}>Best Cars24 Price</span>
            <font>{priceFormatter(bestPrice, true)}</font>
          </div>
          {!!price && state === DIY_SORT_ORDER.DIY_PLL_DEFAULT_VIEW && <div className={styles.currentHb}>
            {!!pllAuctionFlag && !diy_pll_price_status ?
              <p><span className={styles.hb_text}>{`Price Visibile to Customer`}</span>
                -
              </p>
                    : 
              <p><span className={styles.hb_text}>{!!diy_pll_price_status ? `Price Sent to Customer` : `Price Visibile to Customer`}</span>
                <font>{ priceFormatter(customerSharedPrice, true)} {!!validDate && <span className={styles.tillDate}>{priceValidDate})</span>}</font>
                {!!validDate && <span> (valid till</span>}
              </p>
                    }
            </div>}
        </div>
        {showNarrowPrice && <div className={styles.currentHbWrapper}>
          <p className={styles.pllredText}>{narrowPriceTitle}</p>
          <div>
            <p className={styles.narrowPrice}>{`₹ ${priceFormatter(minPrice)} - ₹ ${priceFormatter(maxPrice)}`}</p>
          </div>
        </div>}
      </>);

};
const RANegoHeading = ({ 
    bestPrice, 
    date, 
    c24Quote,
 }) => {
    const validDate = new Date(date) === "Invalid Date" ? "" : new Date(date).toLocaleDateString('en-in', {
        month: 'short',
        day: 'numeric'
    });

    return (
      <div className={styles.currentHbWrapper}>
        <div className={styles.currentHb}>
          <p><span className={styles.hb_text}>Best Price</span>
            <font>{priceFormatter(bestPrice, true)} {validDate && <span className={styles.tillDate}>{validDate})</span>}</font>
            {validDate && <span> (valid till</span>}
          </p>
        </div>
        <div className={styles.currentHb}>
          <span className={styles.hb_text}>Price Visibile to Customer</span>
          <font>{priceFormatter(c24Quote, true)} {validDate && <span className={styles.tillDate}>{validDate})</span>}</font>
          {validDate && <span> (valid till</span>}
        </div>
      </div>
    );
};
const PaiAccepteedAndRunningOcb = ({ 
    price, 
    ocbPrice, 
    expiryDate, 
    timestamp, 
    onClick, 
    showExpiry,
    showNarrowPrice=false,
    minPrice=0,
    maxPrice=0,
    disabled=false

}) => {
    const initialTime = timestamp * 1000;
    return (
      <div className={styles.dealerNegoPrice}>
        {isPll && <p className={styles.priceTitles}>Dealer’s Negotiated Price</p>}
        <div className={styles.priceButtonWrapper}>
          {/* <p className={styles.price}>1,80,000</p> */}
          {isPll && <p className={styles.price}>{priceFormatter(price, true)}</p>}
          <Button ctaText="Accept Price" classNames="blueButton" onClick={onClick} disabled={disabled}/>
        </div>
        {!!showNarrowPrice && <div className={styles.ocbRuningWrapper}>
          <p className={styles.pllredText}>{narrowPriceTitle}</p>
          <p className={styles.priceTextWrapper}>{`₹ ${priceFormatter(minPrice)} - ₹ ${priceFormatter(maxPrice)}`}</p>  
        </div>}
        <div className={styles.ocbRuningWrapper}>
          <p className={styles.ocbruning}>OCB RUNNING FOR</p>
          {/* <p className={styles.ocbPrice}>2,00,000</p> */}
          <p className={styles.ocbPrice}>{priceFormatter(ocbPrice, true)}</p>
        </div>

        <Timer
                initialTime={initialTime}
                direction="backward"
            >
          <span className={styles.ocbTimer}>
            <span><Timer.Hours /> HRS</span>
            <span><Timer.Minutes /> MIN LEFT</span>
          </span>
        </Timer>
        {showExpiry &&
        <p className={styles.expires}>Expires {ExpireInDays(expiryDate)}</p>
            }
      </div >
    );
};
const PaiAccepteedAndExpiredOcb = ({ 
    price, 
    ocbPrice, 
    expiryDate, 
    timestamp, 
    onClick, 
    showExpiry, 
    tokenPrStatus, 
    disabled=false, 
    state,
    showNarrowPrice=false,
    minPrice=0,
    maxPrice=0,
}) => {
    // const initialTime = timestamp * 1000;
    return (
      <div className={styles.dealerNegoPrice}>
        {isPll && <p className={styles.priceTitles}>Dealer’s Negotiated Price</p>}
        <div className={styles.priceButtonWrapper}>
          {isPll && <p className={styles.price}>{priceFormatter(price, true)}</p>}
          {!isPll ? 
            <CarInitiatePaymentCTAButton onClick={onClick} tokenPrStatus={tokenPrStatus} disabled={disabled} /> :
                    state === 20 && <Button ctaText="Generate PR" classNames="blueButton" onClick={onClick} disabled={disabled} />}
        </div>
        {!!showNarrowPrice && <div className={styles.ocbRuningWrapper}>
          <p className={styles.pllredText}>{narrowPriceTitle}</p>
          <p className={styles.priceTextWrapper}>{`₹ ${priceFormatter(minPrice)} - ₹ ${priceFormatter(maxPrice)}`}</p>  
        </div>}
        <div className={styles.ocbRuningWrapper}>
          <p className={styles.ocbruning}>OCB EXPIRED FOR</p>
          <p className={styles.ocbPrice}>{priceFormatter(ocbPrice, true)}</p>
        </div>
        {showExpiry &&
        <p className={styles.expires}>Expires {ExpireInDays(expiryDate)}</p>
            }
      </div >
    );
};
const OcbAcceptedHeading = ({ 
    price, 
    date,
    showNarrowPrice=false,
    minPrice=0,
    maxPrice=0,
}) => {
    const ocbValidDate = new Date(date) === "Invalid Date" ? "" : new Date(date).toLocaleDateString('en-in', {
        month: 'short',
        day: 'numeric'
    });

    return (
      <>{
            showNarrowPrice ? 
              <div className={styles.plllistingPrice}>
                <div>
                  <p className={styles.heading}>{'Best C24 Price'}</p>
                  <div className={styles.currentHbWrapper}>
                    <div className={styles.acceptedHb}>
                      <p><font>{priceFormatter(price, true)}</font> {ocbValidDate && <span>(valid till {ocbValidDate})</span>}</p>
                    </div>
                  </div>
                </div>
                <div>
                  <p className={styles.redText}>{narrowPriceTitle}</p>
                  <div className={styles.priceTextWrapper}>
                    <p className={styles.narrowPrice}>{`₹ ${priceFormatter(minPrice)} - ₹ ${priceFormatter(maxPrice)}`}</p>
                  </div>
                </div>
              </div>
            :
              <div className={styles.currentHbWrapper}>
                <div className={styles.acceptedHb}>
                  <p><font>{priceFormatter(price, true)}</font> {ocbValidDate && <span>(valid till {ocbValidDate})</span>}</p>
                </div>
              </div>
        }
      </>
        
    );
};
const MaskedPrice = ({ text = "**,**,***", currentState }) => {
    let classType = "";
    switch (currentState) {
        case "internalBidExpired":
        case 7:
            classType = styles.reauction;
            break;
        default:
            classType = styles.starRating;
            break;
    }
    return (
      <p className={classType}>{text}</p>
    );
};
const MaskedPriceV2 = ({ minPrice, maxPrice }) => {
    return (
      <div className={styles.listingPrice}>
        <div>
          <p className={"quote_heading__3_mkS"}>{"C24 QUOTE"}</p>
          <div className={styles.priceTextWrapper}>
            <p className={styles.starRating}>{"**,**,***"}</p>
          </div>
        </div>
        <div>
          <p className={`quote_heading__3_mkS ${styles.redText}`}>{narrowPriceTitle}</p>
          <div className={styles.priceTextWrapper}>
            <p className={styles.narrowPrice}>{`₹ ${priceFormatter(minPrice)} - ₹ ${priceFormatter(maxPrice)}`}</p>
          </div>
        </div>
      </div>
    );
};
const UnlockViaOtp = ({ onClick }) => <Button classNames="blueButton lockImg" ctaText="Unlock via OTP" onClick={onClick} />;
const TimeLeft = ({ timestamp, timerFlag }) => {
    const initialTime = timestamp * 1000;

    switch (timerFlag) {
        case TIMER_FLAGS.OCB_RUNNING: {
            return (
              <Timer
                    initialTime={initialTime}
                    direction="backward"
                >
                <div className={styles.timer}>
                  <p><Timer.Hours /> HRS</p>
                  <p><Timer.Minutes /> MIN LEFT</p>
                </div>
              </Timer>
            );
        }
        case TIMER_FLAGS.AUCTION_RUNNING:
        case TIMER_FLAGS.PAI_RUNNING:
            return (
              <Timer
                    initialTime={initialTime}
                    direction="backward"
                >
                <div className={styles.timer}>
                  <p><Timer.Minutes /> MINS</p>
                  <p><Timer.Seconds /> SECONDS LEFT</p>
                </div>
              </Timer>
            );
        default:
            return <></>;
    };
};
const PriceQuote = ({ 
    price, 
    sortOrder, 
    c24QuoteTag, 
    state, 
    showNarrowPrice=false,
    minPrice=0,
    maxPrice=0, 
}) => {
    let displayPrice = priceFormatter(price,true);
    let isVerificationPending = false;
    switch (sortOrder) {
        case NUMBERS.SEVEN:
            displayPrice = 0;
            break;
        case NUMBERS.THIRTEEN:
        case NUMBERS.FOURTEEN:
        case NUMBERS.SIXTEEN:
            displayPrice = priceFormatter(price, true);
            break;
        default:
            isVerificationPending = c24QuoteTag === "Verification Pending";
            if (state === DIY_SORT_ORDER.DIY_PLL_OCB_EXPIRED) {
                displayPrice = 0;
            }
            break;
    }
    return (
      <> {!showNarrowPrice ? 
        <div className={styles.priceTextWrapper}>
          <p className={styles.runningPrice}>{displayPrice}</p>
          {isVerificationPending &&
            <p className={styles.c24QuoteTag}>{`(${c24QuoteTag})`}</p>
                }
        </div> : 
        <>
          <div className={styles.plllistingPrice}>
            <div className={styles.priceTextWrapper}>
              <p className={styles.runningPrice}>{displayPrice}</p>
              {isVerificationPending &&
                <p className={styles.c24QuoteTag}>{`(${c24QuoteTag})`}</p>
                        }
            </div>
            <div>
              <p className={styles.pllredText}>{narrowPriceTitle}</p>
              <div>
                <p className={styles.narrowPrice}>{`₹ ${priceFormatter(minPrice)} - ₹ ${priceFormatter(maxPrice)}`}</p>
              </div>
            </div>
          </div>
        </>
            }
      </>
        
    );
};
const PriceQuoteV2 = ({ 
    price, 
    sortOrder, 
    c24QuoteTag, 
    state, 
    showNarrowPrice=false,
    minPrice=0,
    maxPrice=0, 
}) => {
    let displayPrice = priceFormatter(price);
    let isVerificationPending = false;
    switch (sortOrder) {
        case NUMBERS.SEVEN:
            displayPrice = 0;
            break;
        case NUMBERS.THIRTEEN:
        case NUMBERS.FOURTEEN:
        case NUMBERS.SIXTEEN:
            displayPrice = priceFormatter(price, true);
            break;
        default:
            isVerificationPending = c24QuoteTag === "Verification Pending";
            if (state === DIY_SORT_ORDER.DIY_PLL_OCB_EXPIRED) {
                displayPrice = 0;
            }
            break;
    }
    return (
      <> {!showNarrowPrice ? 
        <div className={styles.priceTextWrapper}>
          <p className={styles.runningPrice}>{displayPrice}</p>
          {isVerificationPending &&
            <p className={styles.c24QuoteTag}>{`(${c24QuoteTag})`}</p>
                }
        </div> : 
        <div className={styles.plllistingPrice}>
          <div className={styles.priceTextWrapper}>
            <p className={styles.runningPrice}>{displayPrice}</p>
            {isVerificationPending &&
            <p className={styles.c24QuoteTag}>{`(${c24QuoteTag})`}</p>
                    }
          </div>
          <div>
            <p className={styles.pllredText}>{narrowPriceTitle}</p>
            <div>
              <p className={styles.narrowPrice}>{`₹ ${priceFormatter(minPrice)} - ₹ ${priceFormatter(maxPrice)}`}</p>
            </div>
          </div>
        </div>
            }
      </>
        
    );
};
const ExpireTime = ({ date }) => {
    if (date) {
        const createdDate = new Date(date).toDateString();
        const expiryDate = new Date((new Date(createdDate)).setDate((new Date(createdDate)).getDate() + 3));
        const currentDate = new Date(new Date().toDateString());
        if (expiryDate.getTime() - currentDate.getTime() >= 0) {
            const diffTime = Math.abs(expiryDate.getTime() - currentDate.getTime());
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            return (<p className={styles.expireTime}>Lead expires {diffDays > 0 ? `in ${diffDays} days` : 'today'}</p>);
        }
        else {
            return <></>;
        }
    } else {
        return <></>;
    }
};
const CTAButton = ({ text, onClick, variant, disabled, isLoading }) =><Button isLoading={isLoading} disabled={disabled} classNames={variant} ctaText={text} onClick={onClick} />;
const CTAButtonCongratulations = ({ text, onClick, variant, disabled, isLoading }) => {
    return (
      <div className={styles.cta}>
        <Button isLoading={isLoading} disabled={disabled} classNames={variant} ctaText={text} onClick={onClick} />
      </div>
    );
};
const RejectedTextComponent = ({ text }) => {
    return(
      <h6 className={styles.bidRejected}>{text}</h6>
    );
};

const c2cListPriceComponent = ({ 
    price, 
    listingPrice, 
    text, 
    c24QuoteTag, 
    textColor, 
    showPrice, 
    c2cToken, 
    trojan,
    showNarrowPrice=false,
    minPrice,
    maxPrice,
	sell_online_ques_flag = false,
	isPriceReportViewed = false,
	onClickDiffReport,
}) => 
    {
    let isVerificationPending = false;
    let listPriceHeading = isBike && "LISTING PRICE";
    isVerificationPending = c24QuoteTag === "Verification Pending";
    let changedDisplay = false;
    const selectedBaseAPIHostKey = localStorage.getItem(LOCAL_STORAGE_KEYS.SELECTED_BASE_API_HOST);
    if (selectedBaseAPIHostKey === APP_SOURCE.BIKE) {
        listPriceHeading = "HIGHEST BID";
    } else if(showNarrowPrice) {
        listPriceHeading = narrowPriceTitle;
    }
    // if (text === 'AUCTION RUNNING' || text === 'PAI RUNNING AT' || text === 'OCB RUNNING AT') {
    if (text === 'AUCTION RUNNING') {
        changedDisplay = true;
    }
    // <p className={styles.runningAt}>{text}</p>
    return (
		<>
		<div className={styles.listingPrice}>
			{c2cToken === 1 && <span className={styles.backout}>Token Done</span>}
			<div>
			{text !== "n/a" && <>
				{changedDisplay === false && <p className={!!textColor ? `quote_heading__3_mkS ${styles.redText}` : "quote_heading__3_mkS"}>{!!text ? text : "C24 QUOTE"}</p>}
				{changedDisplay === true && <p className={styles.runningAt}>{text}</p>}
				</>}
			<div className={styles.priceTextWrapper}>
				{showPrice !== false && <p className={!!textColor ? `${styles.runningPrice} ${styles.redText}` : `${styles.runningPrice}`}>{!!sell_online_ques_flag && !isPriceReportViewed ? "******" : priceFormatter(price, true)}</p>}
				{isVerificationPending &&
				<p className={styles.c24QuoteTag}>{`(${c24QuoteTag})`}</p>}
			</div>
			</div>
			<div>
			{!isBike && !!trojan && trojan === 1 && <div className={styles.trojanRejected}>
				<h6>Trojan Detected</h6>
				</div>}
			<p className={`quote_heading__3_mkS ${showNarrowPrice ? styles.redText : ''}`}>{listPriceHeading}</p>
			<div className={styles.priceTextWrapper}>
				{ isBike && <p className={styles.runningPrice}>{priceFormatter(listingPrice)}</p>}
				{ showNarrowPrice && <p className={styles.narrowPrice}>{`₹ ${priceFormatter(minPrice)} - ₹ ${priceFormatter(maxPrice)}`}</p>}
			</div>
			</div>
		</div>
		{!!sell_online_ques_flag && (
			<div className={styles.priceDiff}>
				<Button
					classNames={"secondaryCta"}
					ctaText={"PRICE DIFF REPORT"}
					onClick={onClickDiffReport}
				/>
		</div>)}
	  </>
    );
};

const c2cListPriceComponentV2 = ({ 
    price, 
    c2cToken, 
    trojan,
    showNarrowPrice=false,
    minPrice,
    maxPrice,
    }) => 
    {
    return (
      <div className={styles.plllistingPrice}>
        {c2cToken === 1 && <span className={styles.backout}>Token Done</span>}
        <div>
          {/* <p className={"quote_heading__3_mkS"}>{"C24 QUOTE"}</p> */}
          <div className={styles.priceTextWrapper}>
            <p className={styles.runningPrice}>{priceFormatter(price, true)}</p>
          </div>
        </div>
        <div>
          {!!trojan && trojan === 1 && <div className={styles.trojanRejected}>
            <h6>Trojan Detected</h6>
            </div>}
          {showNarrowPrice && <>
            <p className={`quote_heading__3_mkS ${styles.redText}`}>{narrowPriceTitle}</p>
            <div className={styles.priceTextWrapper}>
              <p className={styles.narrowPrice}>{`₹ ${priceFormatter(minPrice)} - ₹ ${priceFormatter(maxPrice)}`}</p>
            </div>
            </>}
        </div>
      </div>
    );
};

const BikeC24QuoteReInspectionComponent = ({ price, reInspectionFlag, showReInspPriceLabel = false }) => {
    let reInpectionheading = "";
    if(reInspectionFlag === 0){
        reInpectionheading = <p className={styles.reInspectionPending}> <span>Re-Inspection Pending</span> </p>;
    }else if(reInspectionFlag === 1){
        reInpectionheading = <p className={styles.reInspectionDone}> <span>Re-Inspection Done</span> </p>;
    }
    return(
      <div className={styles.listingPrice}>
        <div>
          <p className="quote_heading__3_mkS">C24 QUOTE</p>
          <div className={styles.priceTextWrapper}>
            <p className={styles.runningPrice}>{priceFormatter(price, true)}</p>
          </div>
        </div>
        <div>
          <p className="quote_heading__3_mkS">{reInpectionheading}</p>
          { showReInspPriceLabel && <p className={styles.priceUpdateText}>Price has been updated due to re-inspection</p>}
        </div>
      </div>
    );
};

const c2cListPriceCongratulationsComponent = ({ 
    price, 
    maxPrice, 
    c24quotExpiryTime, 
    showSellerNotSatified, 
    onClickSellerNotSatisfied, 
    trojan,
    showNarrowPrice=false,
    narrowMinPrice,
    narrowMaxPrice,
}) => {
    return (
      <div className={styles.ourPriceWrapper}>
        <div>
          <p className={styles.ourPrice}>OUR PRICE</p>
          <div className={styles.priceTextWrapper}>
            <p className={`${styles.runningPrice}`}>{priceFormatter(price, true)}</p>
            {showSellerNotSatified  && <p className={styles.link} onClick={onClickSellerNotSatisfied}> Seller still not satisfied? </p>}
          </div>
          {!!c24quotExpiryTime && <p className={styles.offerText}>Offer on limited cars only</p>}
        </div>
        <div>
          {!!maxPrice && <>
            <p className={styles.ourPrice}>{"Max C24 Quote"}</p>
            <div className={styles.priceTextWrapper}>
              <p className={styles.runningPrice}>{priceFormatter(maxPrice, true)}</p>
            </div>
            </>}
          {!isBike && !!trojan && trojan === 1 && <div className={styles.trojanRejected}>
            <h6>Trojan Detected</h6>
            </div>}
          {showNarrowPrice && <>
            <p className={styles.ourPrice}>{narrowPriceTitle}</p>
            <div className={styles.priceTextWrapper}>
              <p className={styles.narrowPrice}>{`₹ ${priceFormatter(narrowMinPrice)} - ₹ ${priceFormatter(narrowMaxPrice)}`}</p>
            </div>
            </>}
        </div>
      </div>
    );
};

const DirectOCBNAPriceComponent = ({ 
    trojan,
}) => {
    return (
      <div className={styles.ocbourPriceWrapper}>
        <div>
          	<p className={styles.ocbourPrice}>C24 QUOTE</p>
			<p>Not applicable</p>
        </div>
        <div>
          {!!trojan && trojan === 1 && <div className={styles.trojanRejected}>
            	<h6>Trojan Detected</h6>
        	</div>}
        </div>
      </div>
    );
};

const PriceAcceptedHeader = ({ }) => {
    return(
      <div className={styles.priceAcceptedFlag}>
        <h6>Customer Accepted Price</h6>
      </div>
    );
};
const showNGTFlag = () => {
    return (<p className={styles.scrapCar}>NGT scrap car</p>);
};

const sellOnlinePriceView = ({ latestPrice, previousPrice, isPriceChanged, sortOrder}) => {
    return (
      <div className={styles.sellOnlinePriceView}>
        <div>
          <p className={styles.ourPrice}>{sortOrder === 30 ? "C24 QUOTE" : "LATEST PRICE"}</p>
          <div className={styles.priceTextWrapper}>
            <p className={`${styles.runningPrice}`}>{priceFormatter(latestPrice, true)}</p>
          </div>
        </div>
        {isPriceChanged && <div className={styles.previousPriceSection}>
          <p className={styles.ourPrice}>{sortOrder === 41 ? "OLD PRICE": "PREVIOUS PRICE"}</p>
          <div className={styles.priceTextWrapper}>
            <p className={styles.runningPrice}>{priceFormatter(previousPrice, true)}</p>
          </div>
        </div>}
      </div>
    );
};

const CongratulationsHeader = ({ congratulationsSubText }) => {
    return (
      <div className={styles.congratulationsFlag}>
        <h6>Congratulations</h6>
        <h4>{congratulationsSubText}</h4>
      </div>
    );
};
const RIScheduledDateTime = ({riScheduleDatetime}) => {
    const dtSchedule = !!riScheduleDatetime ? moment(riScheduleDatetime) : '';
    return (
      <div className={styles.dateTime}>
        <h4>RI Date: {!!dtSchedule ? dtSchedule.format('ll') : ''}</h4>
        <h4>RI Time: {!!dtSchedule ? dtSchedule.format('LT') : ''}</h4>
      </div>
    );
};
const HighConversionChance = () => {
    return (
      <div className={styles.highConversionChanceWrapper}>
        <div className={styles.highConversionChance}>
          <h6>HIGH CONVERSION CHANCE</h6>
        </div>
      </div>
    );
};
const BlankComponent = () => {
    return (<></>);
};
const ClearBothComponent = () => {
    return (<div className={styles.clearboth}></div>);
};
const SuperAuctionComponent = () => {
    return (
      <div className={styles.greatOffers}>
        <h3>You’ve got a great offer.</h3>
        <p>This was a <span>Super Auction!!</span></p>
      </div>
    );
};
const c2cListCTAButton = ({ text, onClick, variant, disabled, isLoading, listingPrice }) => {
    const isBike = isBikeSource();
    if(isBike){
        if (listingPrice > 0) {
            return (
              <Button isLoading={isLoading} disabled={disabled} classNames={variant} ctaText={"Revise List Price"} onClick={onClick} />
            );
        }
        else {
            return (
              <Button isLoading={isLoading} disabled={disabled} classNames={variant} ctaText={text} onClick={onClick} />
            );
        }
    }else{
        return(
          <></>
        );
    }
};

const InitiatePaymentCTAButton = ({ onClick, tokenPaymentStatus }) => {
    switch (tokenPaymentStatus) {
        case TOKEN_PAYMENT_STATUS.SUBMITTED:
        case TOKEN_PAYMENT_STATUS.DONE:
            return (
              <Button classNames={"blueButton ctaLeft"} ctaText={"View Payment"} onClick={onClick} />
            );
        case TOKEN_PAYMENT_STATUS.DRAFTED:
            return (
              <Button classNames={"blueButton ctaLeft"} ctaText={"Resume Payment"} onClick={onClick} />
            );
        case TOKEN_PAYMENT_STATUS.NOT_INITIATED:
            return (
              <Button classNames={"blueButton ctaLeft"} ctaText={"Initiate Payment"} onClick={onClick} />
            );
        case TOKEN_PAYMENT_STATUS.RE_INITIATE:
        case TOKEN_PAYMENT_STATUS.REJECTED:
            return (
              <Button classNames={"blueButton ctaLeft"} ctaText={"Re-Initiate Payment"} onClick={onClick} />
            );
        default:
            return (
              <></>
            );
    };
};

const TokenPaymentFlagLabel = ({ tokenPaymentFlag, tokenPaymentRemark, variant }) => {
    switch(tokenPaymentFlag){
        case 1:
            return (
              <div className={variant?`${styles.blank} ${variant}` : styles.blankComponentBoxLeft}>
                <p className={styles.qcTextOrange}>Under QC Review</p>
              </div>
                
            );
        case 2:
            return(
              <div className={tokenPaymentRemark ? styles.qcInfo : styles.qcTextRed}>
                <span>QC Rejected
                  <p>{tokenPaymentRemark}</p>
                </span>
              </div>
            );
        case 3:
            return(
              <p className={styles.qcTextOrange}>QC Approved - Payment Pending</p>
            );
        case 4:
            return(
              <p className={styles.qcTextGreen}>Payment Delivered</p>
            );
        case 5:
            return(
              <div className={tokenPaymentRemark ? styles.qcInfo : styles.qcTextRed}>
                <span>Finance Rejected
                  <p>{tokenPaymentRemark}</p>
                </span>
              </div>
            );
        default:
            return(
              <></>
            );
    }
};

const CarInitiatePaymentCTAButton = ({ onClick, tokenPrStatus, disabled=false }) => {
    switch (tokenPrStatus) {
        case TOKEN_PR_STATUS.SAVE_AS_DRAFT:
            return (
              <Button classNames={"secondaryCta"} ctaText={"Resume RA TOKEN"} onClick={onClick} disabled={disabled}  />
            );
        case TOKEN_PR_STATUS.TOKEN_SUBMIT:
            return (
              <>
                <p className={styles.qcReview}>{"Under QC review"}</p>
                <Button classNames={"secondaryCta"} ctaText={"VIEW RA TOKEN"}  onClick={onClick} disabled={disabled}/>
              </>
            );
        case TOKEN_PR_STATUS.RA_CANCEL:
            return (
              <>
                <p className={styles.qcRejected}>{"Token Cancelled"}</p>  
                <Button classNames={"secondaryCta"} ctaText={"RE-INITIATE RA TOKEN"} onClick={onClick} disabled={disabled}/>
              </>
            );
        case TOKEN_PR_STATUS.PR_DECLINED:
            return (
              <>
                <p className={styles.qcRejected}>{"QC Rejected"}</p>  
                <Button classNames={"secondaryCta"} ctaText={"RE-INITIATE PR"} onClick={onClick} disabled={disabled}/>
              </>
            );
        case TOKEN_PR_STATUS.PR_APPROVED:
            return (
              <>
                {/* <p>{"PR_APPROVED"}</p>   */}
                <Button classNames={"secondaryCta"} ctaText={"MAKE FULL PR"} disabled={disabled} />
              </>
            );
        default:
            return (
              <Button classNames={"secondaryCta"} ctaText={"GENERATE PR"} onClick={onClick} disabled={disabled}/>
            );
    };
};
const StarButton = ({ isMarkStar, onClick }) => {
    return (
      <div className={styles.starCta}>
        <button className={isMarkStar === 1 ? `${styles.active}` : ``} onClick={onClick} />
      </div>
    );
};

const sellOnlineRIText = ({isPriceChanged = false, onInfoClick}) => {
    return (
      <div className={styles.riStautsText}>
        <p>RI Status: <span>{`${isPriceChanged ? "Price Changed" : "No Changes"}`}</span>
          {!!isPriceChanged && <img onClick={onInfoClick} src={Info} alt="info" />}
        </p>
      </div>
    );
};

const diyRANegoPriceConfirmComponent = ({ c24Quote=5000000, onYesClick, onEditPrice, disabled, onGeneratePR, onGenerateMorePrice }) => {
    return (
      <>
        <div className={styles.diyRaConfirmPrice}>
          <p>{`Want to close the deal AT ₹ ${priceFormatter(c24Quote)}?`}</p>
          <Button onClick={onYesClick} classNames="blueButton ctaLeft" ctaText="Yes" />
          <Button onClick={onEditPrice} classNames="secondaryCta" ctaText="EDIT PRICE" />
        </div>
        <div className={styles.diyRaPriceButtonWrapper}>
          <Button onClick={onGeneratePR} classNames="blueButton" ctaText="Generate PR" />
          <Button disabled={!disabled} onClick={onGenerateMorePrice} classNames="blueButton" ctaText="Get More Price" />
        </div>
      </>
    );
};

const gsLeadsPriceConfirmComponent = ({ state, diy_pll_price_status=0, diy_pll_shared_price=0, validDate, onGerenaretPR, onYesClick, onEditPrice, disabled}) => {
    const ocbValidDate = new Date(validDate) == "Invalid Date" ? "" : new Date(validDate).toLocaleDateString('en-in', {
        month: 'short',
        day: 'numeric'
    });
    
    return (
      <div className={styles.confirmPrice}>
        {!!diy_pll_price_status ? 
          <> 
            {[DIY_SORT_ORDER.DIY_PLL_OCB_OR_PAI_ACCEPTED, DIY_SORT_ORDER.DIY_PLL_PARTIAL_OCB_EXPIRED].includes(state) && <p>{`Price sent to customer ₹ ${priceFormatter(diy_pll_shared_price)} (valid till ${ocbValidDate})`}</p>}
            <Button classNames={"secondaryCta"} ctaText={"GENERATE PR"} onClick={onGerenaretPR} disabled={disabled}/>
          </> : 
          <> 
            {[DIY_SORT_ORDER.DIY_PLL_OCB_OR_PAI_ACCEPTED, DIY_SORT_ORDER.DIY_PLL_PARTIAL_OCB_EXPIRED, DIY_SORT_ORDER.DIY_PLL_DEFAULT_VIEW].includes(state) ? 
              <>
                <p>{`Want to close the deal AT ₹ ${priceFormatter(diy_pll_shared_price)}?`}</p>
                <Button disabled={disabled} onClick={onYesClick} classNames="blueButton ctaLeft" ctaText="Yes" />
                <Button disabled={disabled} onClick={onEditPrice} classNames="secondaryCta" ctaText="EDIT PRICE" />
              </> : 
              <Button classNames={"secondaryCta"} ctaText={"GENERATE PR"} onClick={onGerenaretPR} disabled={disabled}/>
                    }
          </>
            }
      </div>
    );
};

const sellOnlineTokenPRStatus = ({status}) => {
    let prStatus = "Pending";
    switch (status) {
        case 2:
            prStatus = 'Initiated';
            break;
        case 3:
            prStatus = 'Submitted';
            break;
        case 4:
            prStatus = 'Rejected';
            break;
        case 5:
            prStatus = 'Approved';
            break;
        default:
            break;
    }
    return (
      <div className={styles.riStautsText}>
        <p>PR Status: <span>{`${prStatus}`}</span>
        </p>
      </div>
    );
};

const getPAIStatus = (sortOrder, data) => {
    if ([30, 41].includes(sortOrder)) {
        const conversionData = data || {};
        const { max_delta_band } = conversionData;
        if (!!max_delta_band) {
            if (max_delta_band === "H") return "high";
            if (max_delta_band === "M") return "medium";
            if (max_delta_band === "L") return "low";
            if (max_delta_band === "VL") return "low";
        }
        return ""; 
    }
};

const getRetailOCBComponent = ({isRetailOcbPopup=false, clickRaiseOCBHandler, currentState, isDisabled = false}) => {
	if (isRetailOcbPopup) {
		return  (
      <>
        <Button classNames="blueButton ctaLeft" ctaText="RAISE OCB" onClick={clickRaiseOCBHandler} disabled={isDisabled}/>
        <CustomerDrivenOCB/>
      </>
		);
	} else {
		return MaskedPrice({
			text: "Contact PD team to Reauction",
			currentState: currentState
		});
	}
};

const CustomerDrivenOCB = () =>{
  return(
    <div>Customer Driven OCB</div>
  );
};

const SellerIntend =  ({
  c24Quote,
  appointmentId,
  year,
  fetchNegotiationsConnect,
  isHypoAutoApproved
}) =>{
  const [isPopupActive,setIsPopupActive] = useState(false);

  const clickHandler = () =>{
    setIsPopupActive(true);
  };

  return(
    <div className={styles.seller_intend}>
      <p className={styles.seller_intend_c24Quote}>C24 Quote :- ₹ {priceFormatter(c24Quote,true)}</p>
      <Button 
        classNames={'blueButton'} 
        ctaText={'Submit financing Intent'} 
        onClick={clickHandler} 
      />
      {
        isPopupActive && 
        <SellerIntendPopup
          year={Number(year)}
          appointmentId={appointmentId}
          setIsPopupActive={setIsPopupActive}
          fetchNegotiationsConnect={fetchNegotiationsConnect}
          isHypoDsi={isHypoAutoApproved}
        />
      }
    </div>
  );
};

const negotiationStates = (currentState, {
    onClickReauction,
    onClickGeneratePR,
    onClickInitiatePayment,
    onClickAllocateCJ,
    onClickSchedulePickup,
    onClickUnlockViaOtp,
    appointmentIndex,
    currentAppointmentIndex,
    isPreChecking,
    onClickRaiseOCB,
    onAcceptPriceOpen,
    onAcceptPriceOpenPLL,
    onClickMarkStar,
    openDepReport,
    openDIYDepReport,
    onClickSellerNotSatisfied,
    onClickInspectionReportPickup,
    onDiyPllAcceptPriceOpen,
    onDiyPllEditPriceOpen,
    onClickDiffReport,
    stopOcb,
  originalRole,
    fetchNegotiationsConnect,
    isHypoAutoApproved
}, data) => {
    let state = { ui: [] };
    const { calculatedc24Quote,
        auction_over_time,
        pai_timer,
        requestedC24quote,
        originalInsDate,
        sortOrder,
        c24QuoteTag,
        currentHB,
        bestHB,
        bidValidity,
        expectedOneClickAmount,
        c24quotExpiryTime,
        expected_hb,
        c24QStatus,
        c24Quote,
        conversion,
        c2cListingPrice,
        isStarred,
        ccToken,
        cashbacks,
        tokenPaymentStatus,
        homeInspectionCj,
        auctionEfficiencyFlag,
        allocateCjFlag,
        highIntentFlag,
        isInventoryBuying,
        tokenPrStatus,
        msitePriceAccepted,
        tokenPaymentFlag,
        reInspectionFlag,
        showReInspPriceLabel,
        tokenPaymentRemark,
        tp,
        checkWfMin,
        trojanStatus,
        pickupRequestStatus,
        isPriceChanged,
        offered_price,
        assuredPrice, 
        home_ins_date,
        belowTPFlag=false,
        ngtScrap=0,
        ctaAccessFlag=false,
        scheduleRIFlag=false,
        prTokenStatus,
        maxC24Quote,
        pllMarginFlag,
        diy_pll_price_status,
        diy_pll_shared_price,
        pllAuctionFlag,
        seller_price_eligible,
        max_updated_quoted_price,
        min_updated_quoted_price,
		sell_online_ques_flag = 0,
		isPriceReportViewed = 0,
		max_quoted_price = 0,
		min_quoted_price = 0,
        bestC24Quote,
        isGMPAllowed,
        isLmAccessAllowed=false,
        isRetailMarginAllowed=false,
		retailOcbAllowed = false,
        direct_ocb=false,
        auctionOverCTAFlag=false,
        isCustomerOcb=false,
        appointmentId,
        year
    } = data;
    const { flag } = !!conversion ? conversion : 0;
    const ocbAcceptedText = (c24QStatus === "1") ? "PAI ACCEPTED" : "OCB ACCEPTED";
    const allocateCJCTAText = !!scheduleRIFlag ? "Schedule RI" : !homeInspectionCj ? "Allocate CJ" : "Re-Allocate CJ";
	switch (currentState) {
        case 1:
            state = {
                ui: [
                    { Component: isInventoryBuying ? CongratulationsHeader : BlankComponent, props: { congratulationsSubText: checkWfMin ? "You’ve got the best price" : "You have got the Cars24 assured price" }},
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: !!seller_price_eligible ? BlankComponent : Heading, props: { text: "C24 QUOTE" } },
                    { Component: !seller_price_eligible ? MaskedPrice : MaskedPriceV2, props: {minPrice: min_updated_quoted_price, maxPrice: max_updated_quoted_price} },
                    { Component: UnlockViaOtp, props: { onClick: () => onClickUnlockViaOtp() } },
                    { Component: ExpireTime, props: { date: originalInsDate } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        case 17: //PArtial PAI negotiation  - Retail
            state = {
                ui: [
                    { Component: isPll ? BlankComponent : c2cListPriceComponent, props: { price: calculatedc24Quote, listingPrice: c2cListingPrice, text: "Dealer’s Negotiated Price", c2cToken: ccToken, trojan: trojanStatus, showNarrowPrice: seller_price_eligible, maxPrice: max_updated_quoted_price, minPrice: min_updated_quoted_price, } },
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    {
                        Component: PaiAccepteedAndRunningOcb, props: {
                            price: calculatedc24Quote, 
                            ocbPrice: requestedC24quote,
                            expiryDate: originalInsDate, 
                            timestamp: auction_over_time,
                            showExpiry: true,
                            onClick: () => onAcceptPriceOpen()
                        }
                    },
                    // { Component: isBike ? BlankComponent : TrojanLead, props: {trojanStatus}},
                    {
                        Component: isPll ? BlankComponent : c2cListCTAButton, props: {
                            text: "List Price",
                            onClick: () => openDepReport("", c2cListingPrice), variant: "transparentBtn ctaRight", listingPrice: c2cListingPrice
                        }
                    },
                    { Component: isPll ? BlankComponent : CashBackOffer, props: { cashbacks: cashbacks } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        case 18: //PArtial PAI negotiation  - Retail geneerate PR case
            state = {
                ui: [
                    { Component: isPll ? BlankComponent : c2cListPriceComponent, props: { price: calculatedc24Quote, listingPrice: c2cListingPrice, text: "Dealer’s Negotiated Price", c2cToken: ccToken, trojan: trojanStatus, showNarrowPrice: seller_price_eligible, maxPrice: max_updated_quoted_price, minPrice: min_updated_quoted_price, } },
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    // { Component: isBike ? BlankComponent : TrojanLead, props: {trojanStatus}},
                    {
                        Component: PaiAccepteedAndExpiredOcb, props: {
                            price: calculatedc24Quote, ocbPrice: requestedC24quote,
                            expiryDate: originalInsDate, timestamp: auction_over_time,
                            showExpiry: true,
                            onClick: () => onClickGeneratePR(),
                            tokenPrStatus,
                            disabled: !!ctaAccessFlag 
                        }
                    },
                    {
                        Component: isPll ? BlankComponent : c2cListCTAButton, props: {
                            text: "List Price",
                            onClick: () => openDepReport("", c2cListingPrice), variant: "transparentBtn ctaRight", listingPrice: c2cListingPrice
                        }
                    },
                    { Component: isPll ? BlankComponent : CashBackOffer, props: { cashbacks: cashbacks } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        case 19: //PArtial PAI negotiation  - PLL
        case DIY_SORT_ORDER.DIY_PLL_PARTIAL_OCB_RUNNING:
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    {
                        Component: PaiAccepteedAndRunningOcb, props: {
                            price: currentHB, ocbPrice: expectedOneClickAmount,
                            expiryDate: originalInsDate, timestamp: auction_over_time,
                            showExpiry: false,
                            showNarrowPrice: seller_price_eligible, 
                            maxPrice: max_updated_quoted_price, 
                            minPrice: min_updated_quoted_price, 
                            onClick: () => onAcceptPriceOpenPLL(),
                            disabled: !!ctaAccessFlag
                        }
                    },
                    // { Component: isBike ? BlankComponent : TrojanLead, props: {trojanStatus}},
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        case 20: //PArtial PAI negotiation  - PLL
        case DIY_SORT_ORDER.DIY_PLL_PARTIAL_OCB_EXPIRED:
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    {
                        Component: PaiAccepteedAndExpiredOcb, props: {
                            price: isRetailMarginAllowed ? c24Quote : currentHB,
                            ocbPrice: expectedOneClickAmount,
                            expiryDate: originalInsDate, timestamp: auction_over_time,
                            showExpiry: false,
                            onClick: () => onClickGeneratePR(),
                            state: currentState,
                            tokenPrStatus, 
                            disabled: !!ctaAccessFlag,
                            showNarrowPrice: seller_price_eligible, 
                            maxPrice: max_updated_quoted_price, 
                            minPrice: min_updated_quoted_price, 
                        }
                    },
                    // { Component: isBike ? BlankComponent : TrojanLead, props: {trojanStatus}},
                    { Component: !isLmAccessAllowed ? BlankComponent : CTAButton, props: { text: "RAISE OCB", onClick: () => onClickRaiseOCB(), variant: "blueButton", disabled: !!ctaAccessFlag || (isCustomerOcb && [ROLE_KEYS.RA_CX_OCB.toLowerCase(),ROLE_KEYS.RA_TRA.toLowerCase()].includes(originalRole.toLowerCase())) } },
                    { Component: (isCustomerOcb && [ROLE_KEYS.RA_CX_OCB.toLowerCase(),ROLE_KEYS.RA_TRA.toLowerCase()].includes(originalRole.toLowerCase()))?CustomerDrivenOCB:BlankComponent},
                    // need to change the new component //todo
                    { Component: !isPll  || !currentHB || currentState === 20 ? BlankComponent : gsLeadsPriceConfirmComponent, props: 
                        {
                            state: currentState,
                            diy_pll_price_status: diy_pll_price_status, 
                            diy_pll_shared_price: diy_pll_shared_price || currentHB, 
                            validDate: c24quotExpiryTime, 
                            onGerenaretPR: () => onClickGeneratePR(), 
                            onYesClick: () => onDiyPllAcceptPriceOpen(),
                            onEditPrice: ()=> onDiyPllEditPriceOpen(),
                            disabled: !!ctaAccessFlag, 
                        } 
                    },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        case 3:
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: !isPll ? BlankComponent : Heading, props: { text: "C24 QUOTE" } },
                    // { Component: isBike ? BlankComponent : TrojanLead, props: {trojanStatus}},
                    { Component: !isPll ? BlankComponent : PriceQuote, props: { 
                        price: calculatedc24Quote,
                        showNarrowPrice: seller_price_eligible,
                        maxPrice: max_updated_quoted_price,
                        minPrice: min_updated_quoted_price,       
                    } },
                    { Component: Heading, props: { text: "OCB ACCEPTED", isHighligted: false, color: HIGHLIGHT_COLOR } },
                    { Component: isPll ? BlankComponent : c2cListPriceComponent, props: { price: calculatedc24Quote, listingPrice: c2cListingPrice, c2cToken: ccToken, trojan: trojanStatus, showNarrowPrice: seller_price_eligible, maxPrice: max_updated_quoted_price, minPrice: min_updated_quoted_price, } },
                    { Component: isPll ? BlankComponent : CashBackOffer, props: { cashbacks: cashbacks } },
                    { Component: CarInitiatePaymentCTAButton, props: { tokenPrStatus: tokenPrStatus, onClick: () => onClickGeneratePR(), disabled: !!ctaAccessFlag } },
                    { Component: !allocateCjFlag ? BlankComponent : CTAButton, props: { text: allocateCJCTAText, onClick: () => onClickAllocateCJ(), variant: "resceduleCta allocate c24CtaRight", disabled: !!ctaAccessFlag } },
                    { Component: isPll ? BlankComponent : c2cListCTAButton, props: { text: "List Price", onClick: () => openDepReport("", c2cListingPrice), variant: "transparentBtn ctaRight", listingPrice: c2cListingPrice } },
                    { Component: ExpireTime, props: { date: originalInsDate } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        case 31:
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: !isPll ? BlankComponent : Heading, props: { text: "C24 QUOTE" } },
                    { Component: !isPll ? BlankComponent : PriceQuote, props: { 
                        price: calculatedc24Quote,
                        showNarrowPrice: seller_price_eligible,
                        maxPrice: max_updated_quoted_price,
                        minPrice: min_updated_quoted_price,       
                    } },
                    { Component: isPll ? BlankComponent : BikeC24QuoteReInspectionComponent, props: { price: calculatedc24Quote, reInspectionFlag: reInspectionFlag, showReInspPriceLabel: showReInspPriceLabel } },
                    { Component: isPll ? BlankComponent : CashBackOffer, props: { cashbacks: cashbacks } },
                    { Component: InitiatePaymentCTAButton, props: { tokenPaymentStatus: tokenPaymentStatus, onClick: () => onClickInitiatePayment() } },
                    { Component: CTAButton, props: { text: allocateCJCTAText, onClick: () => onClickAllocateCJ(), variant: "resceduleCta allocate c24CtaRight ml-10", disabled: !!ctaAccessFlag } },
                    { Component: ClearBothComponent },
                    { Component: TokenPaymentFlagLabel, props: { tokenPaymentFlag: tokenPaymentFlag, tokenPaymentRemark: tokenPaymentRemark, variant: "blankComponentBoxLeft" }},
                    { Component: !pickupRequestStatus || pickupRequestStatus === BIKE_PICKUP_STATUS.NONE ? BlankComponent : CTAButton, props: { text: pickupRequestStatus === BIKE_PICKUP_STATUS.SCHEDULED ? "SCHEDULE PICKUP" : pickupRequestStatus === BIKE_PICKUP_STATUS.RE_SCHEDULED ? "RE-SCHEDULE PICKUP" : '', onClick: () => onClickSchedulePickup(), variant: "resceduleCta underline c24CtaRight" } },
                    { Component: ExpireTime, props: { date: originalInsDate } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        case 32:
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: !isPll ? BlankComponent : Heading, props: { text: "C24 QUOTE" } },
                    { Component: isPll ? BlankComponent : BikeC24QuoteReInspectionComponent, props: { price: calculatedc24Quote } },
                    { Component: MaskedPrice, props: { text: "Internal-Bid" } },
                    { Component: ExpireTime, props: { date: originalInsDate } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        case 33:
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: isPll ? BlankComponent : c2cListPriceComponent, props: { price: calculatedc24Quote, listingPrice: c2cListingPrice, text: "AUCTION RUNNING", showPrice: false, c2cToken: ccToken, trojan: trojanStatus } },
                    { Component: TimeLeft, props: { timestamp: auction_over_time, timerFlag: TIMER_FLAGS.AUCTION_RUNNING } },
                    { Component: isPll ? BlankComponent : CashBackOffer, props: { cashbacks: cashbacks } },
                    { Component: ExpireTime, props: { date: originalInsDate } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
            case 34:
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: Heading, props: { text: "C24 QUOTE" } },
                    { Component: MaskedPrice },
                    { Component: UnlockViaOtp, props: { onClick: () => onClickUnlockViaOtp() } },
                    { Component: ExpireTime, props: { date: originalInsDate } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        case 22:
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: !isPll ? BlankComponent : Heading, props: { text: "C24 QUOTE" } },
                    // { Component: isBike ? BlankComponent : TrojanLead, props: {trojanStatus}},
                    { Component: !isPll ? BlankComponent : PriceQuote, props: { 
                        price: calculatedc24Quote,
                        showNarrowPrice: seller_price_eligible,
                        maxPrice: max_updated_quoted_price,
                        minPrice: min_updated_quoted_price,       
                    } },
                    { Component: Heading, props: { text: "BUY NOW ACCEPTED", isHighligted: false, color: HIGHLIGHT_COLOR } },
                    { Component: isPll ? BlankComponent : c2cListPriceComponent, props: { price: calculatedc24Quote, listingPrice: c2cListingPrice, c2cToken: ccToken, trojan: trojanStatus, showNarrowPrice: seller_price_eligible, maxPrice: max_updated_quoted_price, minPrice: min_updated_quoted_price, } },
                    { Component: isPll ? BlankComponent : CashBackOffer, props: { cashbacks: cashbacks } },
                    { Component: CarInitiatePaymentCTAButton, props: { tokenPrStatus: tokenPrStatus, onClick: () => onClickGeneratePR(), disabled: !!ctaAccessFlag } },
                    { Component: !allocateCjFlag ? BlankComponent : CTAButton, props: { text: allocateCJCTAText, onClick: () => onClickAllocateCJ(), variant: "resceduleCta allocate c24CtaRight", disabled: !!ctaAccessFlag } },
                    { Component: isPll ? BlankComponent : c2cListCTAButton, props: { text: "List Price", onClick: () => openDepReport("", c2cListingPrice), variant: "transparentBtn ctaRight", listingPrice: c2cListingPrice } },
                    { Component: ExpireTime, props: { date: originalInsDate } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        case 2:
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: !isPll ? BlankComponent : Heading, props: { text: "C24 QUOTE" } },
                    // { Component: isBike ? BlankComponent : TrojanLead, props: {trojanStatus}},
                    { Component: !isPll ? BlankComponent : PriceQuote, props: { 
                        price: requestedC24quote,      
                    } },
                    { Component: Heading, props: { text: "PAI ACCEPTED", isHighligted: false, color: HIGHLIGHT_COLOR } },
                    { Component: isPll ? BlankComponent : c2cListPriceComponentV2, 
                        props: { 
                            price: requestedC24quote, 
                            c2cToken: ccToken, 
                            trojan: trojanStatus,
                            showNarrowPrice: seller_price_eligible,
                            maxPrice: max_updated_quoted_price,
                            minPrice: min_updated_quoted_price,       
                        } 
                    },
                    { Component: isPll ? BlankComponent : CashBackOffer, props: { cashbacks: cashbacks } },
                    { Component: CarInitiatePaymentCTAButton, props: { tokenPrStatus: tokenPrStatus, onClick: () => onClickGeneratePR(), disabled: !!ctaAccessFlag } },
                    { Component: !allocateCjFlag ? BlankComponent : CTAButton, props: { text: allocateCJCTAText, onClick: () => onClickAllocateCJ(), variant: "resceduleCta allocate c24CtaRight", disabled: !!ctaAccessFlag } },
                    { Component: isPll ? BlankComponent : c2cListCTAButton, props: { text: "List Price", onClick: () => openDepReport("", c2cListingPrice), variant: "transparentBtn ctaRight", listingPrice: c2cListingPrice } },
                    { Component: ExpireTime, props: { date: originalInsDate } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        case 5:
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: !isPll ? BlankComponent : Heading, props: { text: "C24 QUOTE" } },
                    // { Component: isBike ? BlankComponent : TrojanLead, props: {trojanStatus}},
                    { Component: isPll ? BlankComponent : c2cListPriceComponent, props: { price: calculatedc24Quote, listingPrice: c2cListingPrice, c2cToken: ccToken, trojan: trojanStatus , showNarrowPrice: seller_price_eligible, maxPrice: max_updated_quoted_price, minPrice: min_updated_quoted_price, } },
                    { Component: MaskedPrice, props: { text: "Internal-Bid" } },
                    {
                        Component: CTAButton, props: {
                            text: "REAUCTION",
                            onClick: () => onClickReauction(),
                            variant: "blueButton ctaLeft",
                            disabled: (currentAppointmentIndex === appointmentIndex) && isPreChecking,
                            isLoading: (currentAppointmentIndex === appointmentIndex) && isPreChecking,
                        }
                    },
                    { Component: isPll ? BlankComponent : c2cListCTAButton, props: { text: "List Price", onClick: () => openDepReport("", c2cListingPrice), variant: "transparentBtn ctaRight", listingPrice: c2cListingPrice } },
                    { Component: ExpireTime, props: { date: originalInsDate } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        case 6:
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: !isPll ? BlankComponent : Heading, props: { text: "C24 QUOTE" } },
                    // { Component: isBike ? BlankComponent : TrojanLead, props: {trojanStatus}},
                    { Component: isPll ? BlankComponent : c2cListPriceComponent, props: { price: calculatedc24Quote, listingPrice: c2cListingPrice, c2cToken: ccToken, trojan: trojanStatus , showNarrowPrice: seller_price_eligible, maxPrice: max_updated_quoted_price, minPrice: min_updated_quoted_price, } },
                    { Component: MaskedPrice, props: { text: "Internal-Bid" } },
                    {
                        Component: CTAButton, props: {
                            text: "REAUCTION",
                            onClick: () => onClickReauction(), variant: "blueButton ctaLeft",
                            disabled: (currentAppointmentIndex === appointmentIndex) && isPreChecking,
                            isLoading: (currentAppointmentIndex === appointmentIndex) && isPreChecking,
                        }
                    },
                    { Component: isPll ? BlankComponent : c2cListCTAButton, props: { text: "List Price", onClick: () => openDepReport("", c2cListingPrice), variant: "transparentBtn ctaRight", listingPrice: c2cListingPrice } },
                    { Component: ExpireTime, props: { date: originalInsDate } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        case 7:
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: !isPll ? BlankComponent : Heading, props: { text: "OCB EXPIRED FOR", isHighligted: true, color: "redText", price: requestedC24quote, sortOrder: sortOrder } },
                    // { Component: isBike ? BlankComponent : TrojanLead, props: {trojanStatus}},
                    { Component: isPll ? BlankComponent : c2cListPriceComponent, props: { price: requestedC24quote, listingPrice: c2cListingPrice, text: "OCB EXPIRED FOR", textColor: "red", c2cToken: ccToken, trojan: trojanStatus , showNarrowPrice: seller_price_eligible, maxPrice: max_updated_quoted_price, minPrice: min_updated_quoted_price, } },
                    { Component: !isPll ? BlankComponent : PriceQuote, props: { 
                        price: requestedC24quote, 
                        sortOrder: sortOrder,
                        showNarrowPrice: seller_price_eligible,
                        maxPrice: max_updated_quoted_price,
                        minPrice: min_updated_quoted_price,       
                     } },
                    { Component: TimeLeft },
					{ Component: getRetailOCBComponent, props: {
						isRetailOcbPopup: !!retailOcbAllowed, 
						clickRaiseOCBHandler: () => onClickRaiseOCB(),
						currentState: currentState,
            isDisabled: !!ctaAccessFlag || (isCustomerOcb && [ROLE_KEYS.RA_CX_OCB.toLowerCase(),ROLE_KEYS.RA_TRA.toLowerCase()].includes(originalRole.toLowerCase()))
						} 
					},
					{ Component: isPll ? BlankComponent : c2cListCTAButton, props: { text: "List Price", onClick: () => openDepReport("", c2cListingPrice), variant: "transparentBtn ctaRight", listingPrice: c2cListingPrice } },
                    { Component: ExpireTime, props: { date: originalInsDate } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        case 8:
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: Heading, props: { text: "OCB RUNNING AT", isHighligted: true } },
                    // { Component: isBike ? BlankComponent : TrojanLead, props: {trojanStatus}},
                    { Component: !isPll ? BlankComponent : PriceQuote, props: { 
                        price: requestedC24quote,
                        showNarrowPrice: seller_price_eligible,
                        maxPrice: max_updated_quoted_price,
                        minPrice: min_updated_quoted_price,       
                    } },
                    { Component: isPll ? BlankComponent : 
                        c2cListPriceComponentV2, 
                            props: { 
                                price: requestedC24quote, 
                                c2cToken: ccToken, 
                                trojan: trojanStatus,
                                showNarrowPrice: seller_price_eligible,
                                maxPrice: max_updated_quoted_price,
                                minPrice: min_updated_quoted_price,       
                            } 
                    },
                    { Component: TimeLeft, props: { timestamp: auction_over_time, timerFlag: TIMER_FLAGS.OCB_RUNNING } },
                    { Component: isPll ? BlankComponent : CashBackOffer, props: { cashbacks: cashbacks } },
                    { Component: isPll ? BlankComponent : c2cListCTAButton, props: { text: "List Price", onClick: () => openDepReport("", c2cListingPrice), variant: "transparentBtn ctaRight", listingPrice: c2cListingPrice } },
                    { Component: ExpireTime, props: { date: originalInsDate } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } },
                    { Component: auctionOverCTAFlag ? CTAButton:BlankComponent , props: { text: "Stop OCB", onClick: () => stopOcb(), variant: "blueButton"} },
                ]
            };
            break;
        case 9:
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: Heading, props: { text: "PAI RUNNING AT", isHighligted: true } },
                    // { Component: isBike ? BlankComponent : TrojanLead, props: {trojanStatus}},
                    { Component: isPll ? BlankComponent : c2cListPriceComponent, props: { price: requestedC24quote, listingPrice: c2cListingPrice, c2cToken: ccToken, text: "n/a", trojan: trojanStatus , showNarrowPrice: seller_price_eligible, maxPrice: max_updated_quoted_price, minPrice: min_updated_quoted_price, } },
                    { Component: !isPll ? BlankComponent : PriceQuote, props: { 
                        price: requestedC24quote,
                        showNarrowPrice: seller_price_eligible,
                        maxPrice: max_updated_quoted_price,
                        minPrice: min_updated_quoted_price,       
                    } },
                    { Component: TimeLeft, props: { timestamp: pai_timer, timerFlag: TIMER_FLAGS.PAI_RUNNING } },
                    { Component: isPll ? BlankComponent : CashBackOffer, props: { cashbacks: cashbacks } },
                    { Component: isPll ? BlankComponent : c2cListCTAButton, props: { text: "List Price", onClick: () => openDepReport("", c2cListingPrice), variant: "transparentBtn ctaRight", listingPrice: c2cListingPrice } },
                    { Component: ExpireTime, props: { date: originalInsDate } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        case 10:
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: !isPll ? BlankComponent : Heading, props: { text: "AUCTION RUNNING", isHighligted: true } },
                    // { Component: isBike ? BlankComponent : TrojanLead, props: {trojanStatus}},
                    { Component: isPll ? BlankComponent : c2cListPriceComponent, props: { price: calculatedc24Quote, listingPrice: c2cListingPrice, text: "AUCTION RUNNING", showPrice: false, c2cToken: ccToken, trojan: trojanStatus , showNarrowPrice: seller_price_eligible, maxPrice: max_updated_quoted_price, minPrice: min_updated_quoted_price, } },
                    { Component: TimeLeft, props: { timestamp: auction_over_time, timerFlag: TIMER_FLAGS.AUCTION_RUNNING } },
                    { Component: isPll ? BlankComponent : CashBackOffer, props: { cashbacks: cashbacks } },
                    { Component: isPll ? BlankComponent : c2cListCTAButton, props: { text: "List Price", onClick: () => openDepReport("", c2cListingPrice), variant: "transparentBtn ctaRight", listingPrice: c2cListingPrice } },
                    { Component: ExpireTime, props: { date: originalInsDate } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        case 12://PLL Raise OCB Case
        case DIY_SORT_ORDER.DIY_PLL_DEFAULT_VIEW:
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: RaiseOcbHeading, props: {
                            price: isRetailMarginAllowed ? c24Quote : currentHB,
                            bestPrice: bestHB, 
                            date: bidValidity, 
                            diy_pll_shared_price: 
                            diy_pll_shared_price || currentHB, 
                            validDate: c24quotExpiryTime, 
                            state: currentState, 
                            diy_pll_price_status: diy_pll_price_status, 
                            c24Quote : c24Quote, 
                            pllAuctionFlag: pllAuctionFlag,
                            showNarrowPrice: seller_price_eligible,
                            maxPrice: max_updated_quoted_price,
                            minPrice: min_updated_quoted_price,
                        } 
                    },
                    // { Component: isBike ? BlankComponent : TrojanLead, props: {trojanStatus}},
                    { Component: !!assuredPrice || !isLmAccessAllowed ? BlankComponent :  CTAButton, props: { text: "RAISE OCB", onClick: () => onClickRaiseOCB(), variant: "blueButton", disabled: !!ctaAccessFlag || (isCustomerOcb && [ROLE_KEYS.RA_CX_OCB.toLowerCase(),ROLE_KEYS.RA_TRA.toLowerCase()].includes(originalRole.toLowerCase())) } },
                    { Component: (isCustomerOcb && [ROLE_KEYS.RA_CX_OCB.toLowerCase(),ROLE_KEYS.RA_TRA.toLowerCase()].includes(originalRole.toLowerCase()))?CustomerDrivenOCB:BlankComponent},
                    // need to replace with new component -- yes, cr
                    { Component: !isPll || !currentHB ? BlankComponent : gsLeadsPriceConfirmComponent, props: 
                        {
                            state: currentState,
                            diy_pll_price_status: diy_pll_price_status, 
                            diy_pll_shared_price: diy_pll_shared_price || currentHB, 
                            validDate: c24quotExpiryTime, 
                            onGerenaretPR: () => onClickGeneratePR(), 
                            onYesClick: () => onDiyPllAcceptPriceOpen(),
                            onEditPrice: ()=> onDiyPllEditPriceOpen(),
                            disabled: !!ctaAccessFlag, 
                        } 
                    },
                    { Component: !belowTPFlag ? BlankComponent : RIScheduledDateTime, props:{riScheduleDatetime: home_ins_date} }, 
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        case 13://PLL PAI running Case
        case DIY_SORT_ORDER.DIY_PLL_PAI_RUNNING:
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: Heading, props: { text: "PAI RUNNING AT", isHighligted: true } },
                    // { Component: isBike ? BlankComponent : TrojanLead, props: {trojanStatus}},
                    { Component: PriceQuoteV2, props: { 
                        price: expected_hb,
                        showNarrowPrice: seller_price_eligible,
                        maxPrice: max_updated_quoted_price,
                        minPrice: min_updated_quoted_price,
                    } },
                    { Component: TimeLeft, props: { timestamp: pai_timer, timerFlag: TIMER_FLAGS.PAI_RUNNING } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        case 14://PLL OCB running Case - cr
        case DIY_SORT_ORDER.DIY_PLL_OCB_RUNNING:
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: Heading, props: { text: "OCB RUNNING AT", isHighligted: true } },
                    { Component: PriceQuote, props: { 
                        price: expectedOneClickAmount,
                        showNarrowPrice: seller_price_eligible,
                        maxPrice: max_updated_quoted_price,
                        minPrice: min_updated_quoted_price,  
                        } 
                    },
                    { Component: TimeLeft, props: { timestamp: auction_over_time, timerFlag: TIMER_FLAGS.OCB_RUNNING } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } },
                    { Component: auctionOverCTAFlag ? CTAButton:BlankComponent , props: { text: "Stop OCB", onClick: () => stopOcb(), variant: "blueButton"} },
                ]
            };
            break;
        case 15://Generate PAI Case
        case DIY_SORT_ORDER.DIY_PLL_OCB_OR_PAI_ACCEPTED:
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: isPll && msitePriceAccepted ? PriceAcceptedHeader : BlankComponent},
                    { Component: !isPll || !!seller_price_eligible ? BlankComponent : Heading, props: { text: "Best C24 Price" } },
                    { Component: isPll ? BlankComponent : c2cListPriceComponent, props: { 
                        price: expected_hb, 
                        listingPrice: c2cListingPrice, 
                        c2cToken: ccToken, 
                        trojan: trojanStatus,
                        showNarrowPrice: seller_price_eligible, 
                        maxPrice: max_updated_quoted_price, 
                        minPrice: min_updated_quoted_price, 
                        } 
                    },
                    { Component: OcbAcceptedHeading, props: { 
                        price: isRetailMarginAllowed ? calculatedc24Quote : expected_hb,
                        date: bidValidity,
                        showNarrowPrice: seller_price_eligible,
                        maxPrice: max_updated_quoted_price,
                        minPrice: min_updated_quoted_price,
                        } 
                    },
                    { Component: isPll ? BlankComponent : c2cListCTAButton, props: { text: "List Price", onClick: () => openDepReport("", c2cListingPrice), variant: "transparentBtn ctaRight" } },
                    { Component: Heading, props: { text: ocbAcceptedText, isHighligted: false, color: HIGHLIGHT_COLOR } },
                    { Component: !isPll || !currentHB ? BlankComponent : gsLeadsPriceConfirmComponent, props: 
                        {
                            state: currentState,
                            diy_pll_price_status: diy_pll_price_status, 
                            diy_pll_shared_price: diy_pll_shared_price || expected_hb, 
                            validDate: c24quotExpiryTime, 
                            onGerenaretPR: () => onClickGeneratePR(), 
                            onYesClick: () => onDiyPllAcceptPriceOpen(),
                            onEditPrice: ()=> onDiyPllEditPriceOpen(),
                            disabled: !!ctaAccessFlag, 
                        } 
                    },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        case 16://OCB Expired
        case DIY_SORT_ORDER.DIY_PLL_OCB_EXPIRED:
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: !isPll ? BlankComponent : currentHB > 0 ? BlankComponent : Heading, props: { text: "OCB EXPIRED FOR", isHighligted: true, color: "red", price: expectedOneClickAmount, sortOrder: sortOrder, state: currentState } },
                    // { Component: isBike ? BlankComponent : TrojanLead, props: {trojanStatus}},
                    { Component: isPll ? BlankComponent : c2cListPriceComponent, props: { price: 0, listingPrice: c2cListingPrice, c2cToken: ccToken, trojan: trojanStatus , showNarrowPrice: seller_price_eligible, maxPrice: max_updated_quoted_price, minPrice: min_updated_quoted_price, } },
                    { Component: currentHB > 0 ? BlankComponent : PriceQuote, props: { 
                        price: currentHB > 0 ? currentHB : 0, 
                        sortOrder: sortOrder, 
                        state: currentState,
                        showNarrowPrice: seller_price_eligible,
                        maxPrice: max_updated_quoted_price,
                        minPrice: min_updated_quoted_price,       
                    } },
                    { Component: isPll ? BlankComponent : c2cListCTAButton, props: { text: "List Price", onClick: () => openDepReport("", c2cListingPrice), variant: "transparentBtn ctaRight", listingPrice: c2cListingPrice } },
                    { Component: currentHB > 0 || !isLmAccessAllowed ? BlankComponent : CTAButton, props: { text: currentHB > 0 ? "GENERATE PR" : "RAISE OCB", onClick: () => currentHB > 0 ? onClickGeneratePR() : onClickRaiseOCB(), variant: "blueButton ctaLeft", disabled: !!ctaAccessFlag || (isCustomerOcb && [ROLE_KEYS.RA_CX_OCB.toLowerCase(),ROLE_KEYS.RA_TRA.toLowerCase()].includes(originalRole.toLowerCase())) } },
                    { Component: (isCustomerOcb && [ROLE_KEYS.RA_CX_OCB.toLowerCase(),ROLE_KEYS.RA_TRA.toLowerCase()].includes(originalRole.toLowerCase()))?CustomerDrivenOCB:BlankComponent},
                    {
                        Component: currentHB === 0 ? BlankComponent : PaiAccepteedAndExpiredOcb, props: {
                            price: currentHB, 
                            ocbPrice: expectedOneClickAmount,
                            expiryDate: originalInsDate, 
                            timestamp: auction_over_time,
                            showExpiry: false,
                            onClick: () => onClickGeneratePR(), 
                            disabled: !!ctaAccessFlag,
                            showNarrowPrice: seller_price_eligible, 
                            maxPrice: max_updated_quoted_price, 
                            minPrice: min_updated_quoted_price, 
                        }
                    },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        case 21: //PLL case auction running
        case DIY_SORT_ORDER.DIY_PLL_AUCTION_RUNNING:
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: HeadingV2, props: { 
                        text: "AUCTION RUNNING", 
                        isHighligted: true,
                        sortOrder: sortOrder, 
                        state: currentState,
                        showNarrowPrice: seller_price_eligible, 
                        maxPrice: max_updated_quoted_price, 
                        minPrice: min_updated_quoted_price, 
                    } },
                    // { Component: isBike ? BlankComponent : TrojanLead, props: {trojanStatus}},
                    { Component: TimeLeft, props: { timestamp: auction_over_time, timerFlag: TIMER_FLAGS.AUCTION_RUNNING } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        case "auctionExpired":
            state = {
                ui: []
            };
            break;
        case "isSuspended":
            return {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: isPll ? BlankComponent : Heading, props: { text: "AUCTION SUSPENDED", isHighligted: true } },
                    // { Component: isBike ? BlankComponent : TrojanLead, props: {trojanStatus}},
                    { Component: isPll ? BlankComponent : c2cListPriceComponent, props: { price: 0, listingPrice: c2cListingPrice, c2cToken: ccToken, trojan: trojanStatus , showNarrowPrice: seller_price_eligible, maxPrice: max_updated_quoted_price, minPrice: min_updated_quoted_price, } },
                    { Component: isPll ? BlankComponent : c2cListCTAButton, props: { text: "List Price", onClick: () => openDepReport("", c2cListingPrice), variant: "transparentBtn ctaRight", listingPrice: c2cListingPrice } },
                    { Component: TimeLeft },
                    { Component: ExpireTime, props: { date: originalInsDate } }
                ]
            };
        case "highPAIAcceptance":
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: !isPll && auctionEfficiencyFlag && !isBike ? SuperAuctionComponent : BlankComponent},
                    // { Component: isBike ? BlankComponent : TrojanLead, props: {trojanStatus}},
                    { Component: !isPll && highIntentFlag === 1 ? HighConversionChance : BlankComponent},
                    { Component: !isPll ? BlankComponent : Heading, props: { text: "C24 QUOTE" } },
                    { Component: !isPll ? BlankComponent : PriceQuote, props: { 
                        price: calculatedc24Quote, 
                        c24QuoteTag: c24QuoteTag,
                        showNarrowPrice: seller_price_eligible,
                        maxPrice: max_updated_quoted_price,
                        minPrice: min_updated_quoted_price,        
                    } },
                    { Component: isPll ? BlankComponent : c2cListPriceComponent, props: { price: calculatedc24Quote, listingPrice: c2cListingPrice, c2cToken: ccToken, trojan: trojanStatus , showNarrowPrice: seller_price_eligible, maxPrice: max_updated_quoted_price, minPrice: min_updated_quoted_price, } },
                    {
                        Component: AcceptanceStatus, props: { status: "high", showStatus: flag }
                    },
                    { Component: isPll ? BlankComponent : CashBackOffer, props: { cashbacks: cashbacks } },
                    { Component: CTAButton, props: { text: !isPll && auctionEfficiencyFlag && !isBike ? "Price Breakup" : "Get More Price", onClick: () => openDepReport("track", c2cListingPrice, true), variant: "primaryCta", disabled: !!ctaAccessFlag } },
                    { Component: CarInitiatePaymentCTAButton, props: { tokenPrStatus: tokenPrStatus, onClick: () => onClickGeneratePR(), disabled: !!ctaAccessFlag } },
                    { Component: !belowTPFlag ? BlankComponent : RIScheduledDateTime, props:{riScheduleDatetime: home_ins_date} }, 
                    { Component: ExpireTime, props: { date: originalInsDate } },
                    { Component: !allocateCjFlag ? BlankComponent : CTAButton, props: { text: allocateCJCTAText, onClick: () => onClickAllocateCJ(), variant: "resceduleCta allocate", disabled: !!ctaAccessFlag } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        case "mediumPAIAcceptance":
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: !isPll && auctionEfficiencyFlag && !isBike ? SuperAuctionComponent : BlankComponent},
                    // { Component: isBike ? BlankComponent : TrojanLead, props: {trojanStatus}},
                    { Component: !isPll && highIntentFlag === 1 ? HighConversionChance : BlankComponent},
                    { Component: !isPll ? BlankComponent : Heading, props: { text: "C24 QUOTE" } },
                    { Component: !isPll ? BlankComponent : PriceQuote, props: { 
                        price: calculatedc24Quote, 
                        c24QuoteTag: c24QuoteTag,
                        showNarrowPrice: seller_price_eligible,
                        maxPrice: max_updated_quoted_price,
                        minPrice: min_updated_quoted_price,       
                    } },
                    { Component: isPll ? BlankComponent : c2cListPriceComponent, props: { price: calculatedc24Quote, listingPrice: c2cListingPrice, c2cToken: ccToken, trojan: trojanStatus , showNarrowPrice: seller_price_eligible, maxPrice: max_updated_quoted_price, minPrice: min_updated_quoted_price, } },
                    { Component: AcceptanceStatus, props: { status: "medium", showStatus: flag } },
                    { Component: isPll ? BlankComponent : CashBackOffer, props: { cashbacks: cashbacks } },
                    { Component: CTAButton, props: { text: !isPll && auctionEfficiencyFlag && !isBike ? "Price Breakup" : "Get More Price", onClick: () => openDepReport("track", c2cListingPrice, true), variant: "primaryCta", disabled: !!ctaAccessFlag } },
                    { Component: CarInitiatePaymentCTAButton, props: { tokenPrStatus: tokenPrStatus, onClick: () => onClickGeneratePR(), disabled: !!ctaAccessFlag } },
                    { Component: !belowTPFlag ? BlankComponent : RIScheduledDateTime, props:{riScheduleDatetime: home_ins_date} }, 
                    { Component: ExpireTime, props: { date: originalInsDate } },
                    { Component: !allocateCjFlag ? BlankComponent : CTAButton, props: { text: allocateCJCTAText, onClick: () => onClickAllocateCJ(), variant: "resceduleCta allocate", disabled: !!ctaAccessFlag } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        case "lowPAIAcceptance":
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: !isPll && auctionEfficiencyFlag && !isBike ? SuperAuctionComponent : BlankComponent},
                    // { Component: isBike ? BlankComponent : TrojanLead, props: {trojanStatus}},
                    { Component: !isPll && highIntentFlag === 1 ? HighConversionChance : BlankComponent},
                    { Component: !isPll ? BlankComponent : Heading, props: { text: "C24 QUOTE" } },
                    { Component: !isPll ? BlankComponent : PriceQuote, props: { 
                        price: calculatedc24Quote, 
                        c24QuoteTag: c24QuoteTag,
                        showNarrowPrice: seller_price_eligible,
                        maxPrice: max_updated_quoted_price,
                        minPrice: min_updated_quoted_price,       
                    } },
                    { Component: isPll ? BlankComponent : c2cListPriceComponent, props: { price: calculatedc24Quote, listingPrice: c2cListingPrice, c2cToken: ccToken, trojan: trojanStatus , showNarrowPrice: seller_price_eligible, maxPrice: max_updated_quoted_price, minPrice: min_updated_quoted_price, } },
                    { Component: AcceptanceStatus, props: { status: "low", showStatus: flag } },
                    { Component: isPll ? BlankComponent : CashBackOffer, props: { cashbacks: cashbacks } },
                    { Component: CTAButton, props: { text: !isPll && auctionEfficiencyFlag && !isBike ? "Price Breakup" : "Get More Price", onClick: () => openDepReport("track", c2cListingPrice, true), variant: "primaryCta", disabled: !!ctaAccessFlag } },
                    { Component: CarInitiatePaymentCTAButton, props: { tokenPrStatus: tokenPrStatus, onClick: () => onClickGeneratePR(), disabled: !!ctaAccessFlag } },
                    { Component: !belowTPFlag ? BlankComponent : RIScheduledDateTime, props:{riScheduleDatetime: home_ins_date} }, 
                    { Component: ExpireTime, props: { date: originalInsDate } },
                    { Component: !allocateCjFlag ? BlankComponent : CTAButton, props: { text: allocateCJCTAText, onClick: () => onClickAllocateCJ(), variant: "resceduleCta allocate", disabled: !!ctaAccessFlag } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        case "noacceptanceProbablity":
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: !isPll && auctionEfficiencyFlag && !isBike ? SuperAuctionComponent : BlankComponent},
                    // { Component: isBike ? BlankComponent : TrojanLead, props: {trojanStatus}},
                    { Component: !isPll && highIntentFlag === 1 ? HighConversionChance : BlankComponent},
                    { Component: !isPll ? BlankComponent : Heading, props: { text: "C24 QUOTE" } },
                    { Component: !isPll ? BlankComponent : PriceQuote, props: { 
                        price: calculatedc24Quote, 
                        c24QuoteTag: c24QuoteTag,
                        showNarrowPrice: seller_price_eligible,
                        maxPrice: max_updated_quoted_price,
                        minPrice: min_updated_quoted_price,       
                    } },
                    { Component: isPll ? BlankComponent : c2cListPriceComponent, props: { price: calculatedc24Quote, listingPrice: c2cListingPrice, c24QuoteTag: c24QuoteTag, c2cToken: ccToken, trojan: trojanStatus , showNarrowPrice: seller_price_eligible, maxPrice: max_updated_quoted_price, minPrice: min_updated_quoted_price, } },
                    { Component: isPll ? BlankComponent : CashBackOffer, props: { cashbacks: cashbacks } },
                    { Component: CTAButton, props: { text: !isPll && auctionEfficiencyFlag && !isBike ? "Price Breakup" : "Get More Price", onClick: () => openDepReport("track", c2cListingPrice, true), variant: "primaryCta", disabled: !!ctaAccessFlag } },
                    { Component: CarInitiatePaymentCTAButton, props: { tokenPrStatus: tokenPrStatus, onClick: () => onClickGeneratePR(), disabled: !!ctaAccessFlag } },
                    { Component: !belowTPFlag ? BlankComponent : RIScheduledDateTime, props:{riScheduleDatetime: home_ins_date} }, 
                    { Component: ExpireTime, props: { date: originalInsDate } },
                    { Component: !allocateCjFlag ? BlankComponent : CTAButton, props: { text: allocateCJCTAText, onClick: () => onClickAllocateCJ(), variant: "resceduleCta allocate", disabled: !!ctaAccessFlag } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        case "internalBidExpired":
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: !isPll ? BlankComponent : Heading, props: { text: "C24 QUOTE" } },
                    // { Component: isBike ? BlankComponent : TrojanLead, props: {trojanStatus}},
                    { Component: MaskedPrice, props: { text: "Internal-Bid" } },
                    { Component: isPll ? BlankComponent : c2cListPriceComponent, props: { price: calculatedc24Quote, listingPrice: c2cListingPrice, c2cToken: ccToken, trojan: trojanStatus , showNarrowPrice: seller_price_eligible, maxPrice: max_updated_quoted_price, minPrice: min_updated_quoted_price, } },
                    { Component: MaskedPrice, props: { text: "Contact PD team to Reauction", currentState: currentState } },
                    { Component: isPll ? BlankComponent : c2cListCTAButton, props: { text: "List Price", onClick: () => openDepReport("", c2cListingPrice), variant: "transparentBtn ctaRight", listingPrice: c2cListingPrice } },
                    { Component: !belowTPFlag ? BlankComponent : RIScheduledDateTime, props:{riScheduleDatetime: home_ins_date} },
                    { Component: ExpireTime, props: { date: originalInsDate } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        case 23:
        case DIY_SORT_ORDER.DIY_PLL_INVENTORY:
            state = {
                ui: [
                    { Component: CongratulationsHeader, props: { congratulationsSubText: "You have got the Cars24 assured price" } },
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    // { Component: isBike ? BlankComponent : TrojanLead, props: {trojanStatus}},
                    { Component: isPll && msitePriceAccepted ? PriceAcceptedHeader : BlankComponent},
                    { Component: !isPll && highIntentFlag === 1 ? HighConversionChance : BlankComponent},
                    { Component: c2cListPriceCongratulationsComponent, props: { 
                        price: isPll ? c24Quote : calculatedc24Quote, 
                        maxPrice: !!pllMarginFlag ? maxC24Quote : null, 
                        c24quotExpiryTime: isPll ? null : c24quotExpiryTime , 
                        trojan: trojanStatus,
                        showNarrowPrice: seller_price_eligible,
                        narrowMaxPrice: max_updated_quoted_price,
                        narrowMinPrice: min_updated_quoted_price, 
                    } },
                    { Component: CarInitiatePaymentCTAButton, props: { tokenPrStatus: tokenPrStatus, onClick: () => onClickGeneratePR(), disabled: !!ctaAccessFlag } },
                    { Component: isPll ? BlankComponent : CTAButtonCongratulations, props: { text: "Price Breakup", onClick: () => openDepReport("track", c2cListingPrice, true, isInventoryBuying), variant: "secondaryCta" } },
                    { Component: !allocateCjFlag ? BlankComponent : CTAButton, props: { text: allocateCJCTAText, onClick: () => onClickAllocateCJ(), variant: "resceduleCta allocate", disabled: !!ctaAccessFlag } },
                    { Component: !trojanStatus || trojanStatus !== 1 ? BlankComponent : CTAButton, props: {text: "RE-AUCTION", onClick: () => onClickReauction(), variant: "blueButton ctaLeft", 
                            disabled: (currentAppointmentIndex === appointmentIndex) && isPreChecking,
                            isLoading: (currentAppointmentIndex === appointmentIndex) && isPreChecking,
                        }},   
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } },
                ]
            };
            break;
        case 24: 
        case DIY_SORT_ORDER.DIY_PLL_CEPENABLED:
            state = {
                ui: [
                    { Component: CongratulationsHeader, props: { congratulationsSubText: "You’ve got the best price" }},
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    // { Component: isBike ? BlankComponent : TrojanLead, props: {trojanStatus}},
                    { Component: c2cListPriceCongratulationsComponent, props: { price: isPll ? c24Quote : calculatedc24Quote, maxPrice: !!pllMarginFlag ? maxC24Quote : null, c24quotExpiryTime: isPll ? null : c24quotExpiryTime, showSellerNotSatified: ((c24Quote + NUMBERS.THOUSAND) < (tp * 1.30)), onClickSellerNotSatisfied, trojanStatus } },
                    { Component: CarInitiatePaymentCTAButton, props: { tokenPrStatus: tokenPrStatus, onClick: () => onClickGeneratePR(), disabled: !!ctaAccessFlag } },
                    { Component: isPll ? BlankComponent : CTAButtonCongratulations, props: { text: "View Price Breakup", onClick: () => openDepReport("track", c2cListingPrice, true, isInventoryBuying), variant: "secondaryCta"} },
                    { Component: !allocateCjFlag ? BlankComponent : CTAButton, props: { text: allocateCJCTAText, onClick: () => onClickAllocateCJ(), variant: "resceduleCta allocate", disabled: !!ctaAccessFlag } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } },
                ]
            };
            break;
        case 25: //GS Assured - Bid rejected
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: RejectedTextComponent, props: { text: "Assured-bid rejected"} },
                    // { Component: isBike ? BlankComponent : TrojanLead, props: {trojanStatus}},
                    {
                        Component: CTAButton, props: {
                            text: "RE-AUCTION",
                            onClick: () => onClickReauction(),
                            variant: "blueButton ctaLeft",
                            disabled: (currentAppointmentIndex === appointmentIndex) && isPreChecking,
                            isLoading: (currentAppointmentIndex === appointmentIndex) && isPreChecking,
                        }
                    }
                ]
            };
            break;
        case 26: //for PLL - NON-inventory buying cars
        case DIY_SORT_ORDER.DIY_PLL_CUST_ACCEPT_PRICE:
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: msitePriceAccepted ? PriceAcceptedHeader : BlankComponent},
                    { Component: c2cListPriceCongratulationsComponent, props: { price: calculatedc24Quote, c24quotExpiryTime: isPll ? null : c24quotExpiryTime } },
                    { Component: CTAButtonCongratulations, props: { text: "GENERATE PR", onClick: () => onClickGeneratePR(), variant: "secondaryCta", disabled: !!ctaAccessFlag } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } },
                ]
            };
            break;
        case 28: //Sell online 
            state = {
                ui: [
                    { Component: CongratulationsHeader, props: { congratulationsSubText: "You’ve got the best price" }},
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: sellOnlinePriceView, props: { latestPrice: calculatedc24Quote, previousPrice: offered_price, isPriceChanged: isPriceChanged} },
                    { Component : sellOnlineRIText, props: {isPriceChanged: isPriceChanged, onInfoClick: () => onClickInspectionReportPickup()}},
                    { Component: CarInitiatePaymentCTAButton, props: { tokenPrStatus: tokenPrStatus, onClick: () => onClickGeneratePR(), disabled: !!ctaAccessFlag } },
                    { Component: sellOnlineTokenPRStatus, props: {status: prTokenStatus}}, 
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } },
                ]
            };
        break;
        case 29:
            state = {
                ui: [
                    { Component: CongratulationsHeader, props: { congratulationsSubText: "You have got the Cars24 assured price" } },
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    // { Component: isBike ? BlankComponent : TrojanLead, props: {trojanStatus}},
                    { Component: isPll && msitePriceAccepted ? PriceAcceptedHeader : BlankComponent},
                    { Component: !isPll && highIntentFlag === 1 ? HighConversionChance : BlankComponent},
                    { Component: c2cListPriceCongratulationsComponent, props: { price: calculatedc24Quote, c24quotExpiryTime: isPll ? null : c24quotExpiryTime , trojan: trojanStatus} },
                    { Component: CarInitiatePaymentCTAButton, props: { tokenPrStatus: tokenPrStatus, onClick: () => onClickGeneratePR(), disabled: !!ctaAccessFlag } },
                    { Component: isPll ? BlankComponent : CTAButtonCongratulations, props: { text: "Price Breakup", onClick: () => openDepReport("track", c2cListingPrice, true, isInventoryBuying), variant: "secondaryCta", disabled: !!ctaAccessFlag } },
                    { Component: !allocateCjFlag ? BlankComponent : CTAButton, props: { text: allocateCJCTAText, onClick: () => onClickAllocateCJ(), variant: "resceduleCta allocate", disabled: !!ctaAccessFlag } },
                    { Component: !trojanStatus || trojanStatus !== 1 ? BlankComponent : CTAButton, props: {text: "RE-AUCTION", onClick: () => onClickReauction(), variant: "blueButton ctaLeft", 
                            disabled: (currentAppointmentIndex === appointmentIndex) && isPreChecking,
                            isLoading: (currentAppointmentIndex === appointmentIndex) && isPreChecking,
                        }},
                    { Component: RIScheduledDateTime, props:{riScheduleDatetime: home_ins_date} }, 
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } },
                ]
            };
            break;
        case 30:
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: sellOnlinePriceView, props: { latestPrice: calculatedc24Quote, previousPrice: offered_price, isPriceChanged: isPriceChanged, sortOrder: sortOrder } },
                    { Component: AcceptanceStatus, props: { status: getPAIStatus(sortOrder, conversion), showStatus: flag } },
                    { Component: CTAButton, props: { text: "Get More Price", onClick: () => openDepReport("track", c2cListingPrice, true), variant: "primaryCta", disabled: !!ctaAccessFlag } },
                    { Component: CarInitiatePaymentCTAButton, props: { tokenPrStatus: tokenPrStatus, onClick: () => onClickGeneratePR(), disabled: !!ctaAccessFlag } },
                    { Component : sellOnlineRIText, props: {isPriceChanged: isPriceChanged, onInfoClick: () => onClickInspectionReportPickup()}},
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } },
                    { Component: ExpireTime, props: { date: originalInsDate } },
                ]
            };
            break;
        case DIY_SORT_ORDER.NONE:
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: sellOnlinePriceView, props: { latestPrice: calculatedc24Quote, previousPrice: offered_price, isPriceChanged: isPriceChanged, sortOrder: sortOrder } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } },
                    { Component: ExpireTime, props: { date: originalInsDate } },
                ]
            };
            break;
        case DIY_SORT_ORDER.SO_PRICE_NOT_ACCEPTED:
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: CongratulationsHeader, props: { congratulationsSubText: "You’ve got the best price" }},
                    { Component: sellOnlinePriceView, props: { latestPrice: calculatedc24Quote, previousPrice: offered_price, isPriceChanged: isPriceChanged, sortOrder: sortOrder } },
                    { Component : sellOnlineRIText, props: {isPriceChanged: isPriceChanged, onInfoClick: () => onClickInspectionReportPickup()}},
                    { Component: CarInitiatePaymentCTAButton, props: { tokenPrStatus: tokenPrStatus, onClick: () => onClickGeneratePR(), disabled: !!ctaAccessFlag } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } },
                    { Component: ExpireTime, props: { date: originalInsDate } },
                ]
            };
            break;
        case DIY_SORT_ORDER.SO_PRICE_ACCEPTED:
            state = {
                ui: [
                    { Component: CongratulationsHeader, props: { congratulationsSubText: "You’ve got the best price" }},
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: sellOnlinePriceView, props: { latestPrice: calculatedc24Quote, previousPrice: offered_price, isPriceChanged: isPriceChanged, sortOrder: sortOrder } },
                    { Component : sellOnlineRIText, props: {isPriceChanged: isPriceChanged, onInfoClick: () => onClickInspectionReportPickup()}},
                    { Component: CarInitiatePaymentCTAButton, props: { tokenPrStatus: tokenPrStatus, onClick: () => onClickGeneratePR(), disabled: !!ctaAccessFlag } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } },
                    { Component: ExpireTime, props: { date: originalInsDate } },
                ]
            };
            break;
        case DIY_SORT_ORDER.SO_NO_PRICE_CHANGE:
            state = {
                ui: [
                    { Component: CongratulationsHeader, props: { congratulationsSubText: "You’ve got the best price" }},
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: sellOnlinePriceView, props: { latestPrice: calculatedc24Quote, previousPrice: offered_price, isPriceChanged: isPriceChanged, sortOrder: sortOrder } },
                    { Component : sellOnlineRIText, props: {isPriceChanged: isPriceChanged, onInfoClick: () => onClickInspectionReportPickup()}},
                    { Component: CarInitiatePaymentCTAButton, props: { tokenPrStatus: tokenPrStatus, onClick: () => onClickGeneratePR(), disabled: !!ctaAccessFlag } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } },
                    { Component: ExpireTime, props: { date: originalInsDate } },
                ]
            };
            break;
        case DIY_SORT_ORDER.HI_PRICE_NOT_ACCEPTED: //4
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: c2cListPriceComponent, props: 
						{ price: calculatedc24Quote, 
							c24QuoteTag: c24QuoteTag, 
							listingPrice: c2cListingPrice, 
							c2cToken: ccToken, 
							trojan: trojanStatus, 
							showNarrowPrice: seller_price_eligible, 
							maxPrice: max_updated_quoted_price,
							minPrice: min_updated_quoted_price,
							sell_online_ques_flag: sell_online_ques_flag,
							isPriceReportViewed: isPriceReportViewed,
							onClickDiffReport: () => onClickDiffReport(),
						} 
					},
                    { Component: AcceptanceStatus, props: { status: getPAIStatus(sortOrder, conversion), showStatus: flag } },
                    { Component: CTAButton, props: { text: "Get More Price", onClick: () => openDIYDepReport("track", c2cListingPrice, true), variant: "primaryCta", disabled: !!ctaAccessFlag ? !!ctaAccessFlag : !!sell_online_ques_flag && !isPriceReportViewed, } },
                    { Component : sellOnlineRIText, props: {isPriceChanged: isPriceChanged, onInfoClick: () => onClickInspectionReportPickup()}},
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } },
                    { Component: ExpireTime, props: { date: originalInsDate } },
                ]
            };
            break;
        case DIY_SORT_ORDER.HI_PRICE_ACCEPTED: //5
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: c2cListPriceComponent, props: 
						{ 
							price: calculatedc24Quote, 
							c24QuoteTag: c24QuoteTag, 
							listingPrice: c2cListingPrice, 
							c2cToken: ccToken, 
							trojan: trojanStatus , 
							showNarrowPrice: seller_price_eligible, 
							maxPrice: max_updated_quoted_price,
							minPrice: min_updated_quoted_price,
							sell_online_ques_flag: sell_online_ques_flag,
							isPriceReportViewed: isPriceReportViewed,
							onClickDiffReport: () => onClickDiffReport(),
						} 
					},
                    { Component : sellOnlineRIText, props: {isPriceChanged: isPriceChanged, onInfoClicks: () => onClickInspectionReportPickup()}},
                    { Component: CarInitiatePaymentCTAButton, props: { tokenPrStatus: tokenPrStatus, onClick: () => onClickGeneratePR(), disabled: !!ctaAccessFlag } },
                    { Component: CTAButton, props: { text: "Get More Price", onClick: () => openDIYDepReport("track", c2cListingPrice, true), variant: "primaryCta", disabled: !!ctaAccessFlag ? !!ctaAccessFlag : !!sell_online_ques_flag && !isPriceReportViewed, } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } },
                    { Component: ExpireTime, props: { date: originalInsDate } },
                ]
            };
            break;
        case DIY_SORT_ORDER.HI_GS_PRICE_NOT_ACCEPTED: //6
            state = {
                ui: [
                    { Component: CongratulationsHeader, props: { congratulationsSubText: "You have got the Cars24 assured price" } },
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: c2cListPriceComponent, props: 
						{ 
							price: calculatedc24Quote, 
							c24QuoteTag: c24QuoteTag, 
							listingPrice: c2cListingPrice, 
							c2cToken: ccToken, 
							trojan: trojanStatus , 
							showNarrowPrice: seller_price_eligible, 
							maxPrice: max_updated_quoted_price,
							minPrice: min_updated_quoted_price,
							sell_online_ques_flag: sell_online_ques_flag,
							isPriceReportViewed: isPriceReportViewed,
							onClickDiffReport: () => onClickDiffReport(),
						} 
					},
                    { Component: CTAButtonCongratulations, props: { text: "Price Breakup", onClick: () => openDIYDepReport("track", c2cListingPrice, true, isInventoryBuying), variant: "secondaryCta", disabled: !!ctaAccessFlag } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } },
                    { Component: ExpireTime, props: { date: originalInsDate } }
                ]
            };
            break;
        case DIY_SORT_ORDER.HI_GS_PRICE_ACCEPTED: // 7
            state = {
                ui: [
                    { Component: CongratulationsHeader, props: { congratulationsSubText: "You have got the Cars24 assured price" } },
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: c2cListPriceComponent, props: 
						{ 
							price: calculatedc24Quote, 
							c24QuoteTag: c24QuoteTag, 
							listingPrice: c2cListingPrice, 
							c2cToken: ccToken, 
							trojan: trojanStatus , 
							showNarrowPrice: seller_price_eligible, 
							maxPrice: max_updated_quoted_price,
							minPrice: min_updated_quoted_price,
							sell_online_ques_flag: sell_online_ques_flag,
							isPriceReportViewed: isPriceReportViewed,
							onClickDiffReport: () => onClickDiffReport(),
						} 
					},
                    { Component: CarInitiatePaymentCTAButton, props: { tokenPrStatus: tokenPrStatus, onClick: () => onClickGeneratePR(), disabled: !!ctaAccessFlag } },
                    { Component: CTAButtonCongratulations, props: { text: "Price Breakup", onClick: () => openDIYDepReport("track", c2cListingPrice, true, isInventoryBuying), variant: "secondaryCta", disabled: !!ctaAccessFlag } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } },
                    { Component: ExpireTime, props: { date: originalInsDate } }
                ]
            };
            break;
        case DIY_SORT_ORDER.HI_NON_GS_PRICE_NOT_ACCEPTED: // 8
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: c2cListPriceComponent, props: 
						{ 
							price: calculatedc24Quote, 
							c24QuoteTag: c24QuoteTag, 
							listingPrice: c2cListingPrice, 
							c2cToken: ccToken, 
							trojan: trojanStatus , 
							showNarrowPrice: seller_price_eligible, 
							maxPrice: max_updated_quoted_price,
							minPrice: min_updated_quoted_price,
							sell_online_ques_flag: sell_online_ques_flag,
							isPriceReportViewed: isPriceReportViewed,
							onClickDiffReport: () => onClickDiffReport(),
						} 
					},
                    { Component: AcceptanceStatus, props: { status: getPAIStatus(sortOrder, conversion), showStatus: flag } },
                    { Component: CTAButton, props: { text: "Get More Price", onClick: () => openDIYDepReport("track", c2cListingPrice, true), variant: "primaryCta", disabled: !!ctaAccessFlag ? !!ctaAccessFlag : !!sell_online_ques_flag && !isPriceReportViewed, } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } },
                    { Component: ExpireTime, props: { date: originalInsDate } }
                ]
            };
            break;
        case DIY_SORT_ORDER.HI_NON_GS_PRICE_ACCEPTED: // 9
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: c2cListPriceComponent, props: 
						{ 
							price: calculatedc24Quote, 
							listingPrice: c2cListingPrice, 
							c2cToken: ccToken, 
							trojan: trojanStatus , 
							showNarrowPrice: seller_price_eligible, 
							maxPrice: max_updated_quoted_price,
							minPrice: min_updated_quoted_price,
							sell_online_ques_flag: sell_online_ques_flag,
							isPriceReportViewed: isPriceReportViewed,
							onClickDiffReport: () => onClickDiffReport(),
						} 
					},
                    { Component: CarInitiatePaymentCTAButton, props: { tokenPrStatus: tokenPrStatus, onClick: () => onClickGeneratePR(), disabled: !!ctaAccessFlag } },
                    { Component: CTAButton, props: { text: "Get More Price", onClick: () => openDIYDepReport("track", c2cListingPrice, true), variant: "primaryCta", disabled: !!ctaAccessFlag ? !!ctaAccessFlag : !!sell_online_ques_flag && !isPriceReportViewed, } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } },
                    { Component: ExpireTime, props: { date: originalInsDate } }
                ]
            };
            break;
        case DIY_SORT_ORDER.DIY_PAI_RUNNING:
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: Heading, props: { text: "PAI RUNNING AT", isHighligted: true } },
                    { Component: PriceQuote, props: { 
                        price: requestedC24quote,
                        showNarrowPrice: seller_price_eligible,
                        maxPrice: max_updated_quoted_price,
                        minPrice: min_updated_quoted_price,        
                    } },
                    { Component: TimeLeft, props: { timestamp: pai_timer, timerFlag: TIMER_FLAGS.PAI_RUNNING } },
                    { Component: ExpireTime, props: { date: originalInsDate } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        case DIY_SORT_ORDER.DIY_PAI_ACCEPTED:
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: Heading, props: { text: "PAI ACCEPTED", isHighligted: false, color: HIGHLIGHT_COLOR } },
                    { Component: c2cListPriceComponent, props: 
						{ 
							price: requestedC24quote, 
							listingPrice: c2cListingPrice, 
							c2cToken: ccToken, 
							trojan: trojanStatus , 
							showNarrowPrice: seller_price_eligible, 
							maxPrice: max_updated_quoted_price,
							minPrice: min_updated_quoted_price,
							sell_online_ques_flag: sell_online_ques_flag,
							isPriceReportViewed: isPriceReportViewed,
							onClickDiffReport: () => onClickDiffReport(),
						} 
					},
                    { Component: CarInitiatePaymentCTAButton, props: { tokenPrStatus: tokenPrStatus, onClick: () => onClickGeneratePR(), disabled: !!ctaAccessFlag } },
                    { Component: ExpireTime, props: { date: originalInsDate } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        case DIY_SORT_ORDER.DIY_OCB_RUNNING_AT:
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: Heading, props: { text: "OCB RUNNING AT", isHighligted: true } },
                    { Component: c2cListPriceComponent, props: { price: requestedC24quote, listingPrice: c2cListingPrice, c2cToken: ccToken, text: "n/a", trojan: trojanStatus , showNarrowPrice: seller_price_eligible, maxPrice: max_updated_quoted_price, minPrice: min_updated_quoted_price, } },
                    { Component: TimeLeft, props: { timestamp: auction_over_time, timerFlag: TIMER_FLAGS.OCB_RUNNING } },
                    { Component: ExpireTime, props: { date: originalInsDate } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } },
                    { Component: auctionOverCTAFlag ? CTAButton:BlankComponent , props: { text: "Stop OCB", onClick: () => stopOcb(), variant: "blueButton"} },
                ]
            };
            break;
        case DIY_SORT_ORDER.DIY_OCB_ACCEPTED:
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: Heading, props: { text: "OCB ACCEPTED", isHighligted: false, color: HIGHLIGHT_COLOR } },
                    { Component: c2cListPriceComponent, props: { price: calculatedc24Quote, listingPrice: c2cListingPrice, c2cToken: ccToken, trojan: trojanStatus , showNarrowPrice: seller_price_eligible, maxPrice: max_updated_quoted_price, minPrice: min_updated_quoted_price, } },
                    { Component: CarInitiatePaymentCTAButton, props: { tokenPrStatus: tokenPrStatus, onClick: () => onClickGeneratePR(), disabled: !!ctaAccessFlag } },
                    { Component: ExpireTime, props: { date: originalInsDate } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        case DIY_SORT_ORDER.DIY_OCB_EXPIRED:
		case DIY_SORT_ORDER.DIY_DIRECT_OCB_EXPIRED:
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: c2cListPriceComponent, props: { price: requestedC24quote, listingPrice: c2cListingPrice, text: "OCB EXPIRED FOR", textColor: "red", c2cToken: ccToken, trojan: trojanStatus , showNarrowPrice: seller_price_eligible, maxPrice: max_updated_quoted_price, minPrice: min_updated_quoted_price, } },
                    { Component: TimeLeft },
					{ Component: getRetailOCBComponent, props: {
						isRetailOcbPopup: !!retailOcbAllowed || (currentState === DIY_SORT_ORDER.DIY_DIRECT_OCB_EXPIRED), 
						clickRaiseOCBHandler: () => onClickRaiseOCB(),
						currentState: currentState,
                        isDisabled: !!ctaAccessFlag || (isCustomerOcb && [ROLE_KEYS.RA_CX_OCB.toLowerCase(),ROLE_KEYS.RA_TRA.toLowerCase()].includes(originalRole.toLowerCase()))
						} 
					},
                    { Component: ExpireTime, props: { date: originalInsDate } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        case DIY_SORT_ORDER.DIY_PARTIAL_PAI_NEGO_RETAIL:
            state = {
                ui: [
                    { Component: c2cListPriceComponent, props: { price: calculatedc24Quote, listingPrice: c2cListingPrice, text: "Dealer’s Negotiated Price", c2cToken: ccToken, trojan: trojanStatus , showNarrowPrice: seller_price_eligible, maxPrice: max_updated_quoted_price, minPrice: min_updated_quoted_price, } },
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    {
                        Component: PaiAccepteedAndRunningOcb, props: {
                            price: calculatedc24Quote, ocbPrice: requestedC24quote,
                            expiryDate: originalInsDate, timestamp: auction_over_time,
                            showExpiry: true,
                            onClick: () => onAcceptPriceOpen()
                        }
                    },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        case DIY_SORT_ORDER.DIY_PARTIAL_PAI_NEGO_RETAIL_PR:
            state = {
                ui: [
                    { Component: c2cListPriceComponent, props: 
						{ 
							price: calculatedc24Quote, 
							listingPrice: c2cListingPrice, 
							text: "Dealer’s Negotiated Price", 
							c2cToken: ccToken, 
							trojan: trojanStatus , 
							showNarrowPrice: seller_price_eligible, 
							maxPrice: max_updated_quoted_price || max_quoted_price,
							minPrice: min_updated_quoted_price || min_quoted_price,
							sell_online_ques_flag: sell_online_ques_flag,
							isPriceReportViewed: isPriceReportViewed,
							onClickDiffReport: () => onClickDiffReport(),
						} 
					},
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    {
                        Component: PaiAccepteedAndExpiredOcb, props: {
                            price: calculatedc24Quote, 
                            ocbPrice: requestedC24quote,
                            expiryDate: originalInsDate, 
                            timestamp: auction_over_time,
                            showExpiry: true,
                            onClick: () => onClickGeneratePR(),
                            tokenPrStatus, 
                            disabled: !!ctaAccessFlag,
                        }
                    },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } },
                    { Component: direct_ocb ? CTAButton:BlankComponent , props: { text: "RAISE OCB", onClick: () => onClickRaiseOCB(), variant: "blueButton ctaLeft",disabled: (isCustomerOcb && [ROLE_KEYS.RA_CX_OCB.toLowerCase(),ROLE_KEYS.RA_TRA.toLowerCase()].includes(originalRole.toLowerCase()))} },
                    { Component: (isCustomerOcb && [ROLE_KEYS.RA_CX_OCB.toLowerCase(),ROLE_KEYS.RA_TRA.toLowerCase()].includes(originalRole.toLowerCase()))?CustomerDrivenOCB:BlankComponent},
                ]
            };
            break;
        case DIY_SORT_ORDER.DIY_AUCTION_RUNNING:
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: c2cListPriceComponent, props: { price: calculatedc24Quote, listingPrice: c2cListingPrice, text: "AUCTION RUNNING", showPrice: false, c2cToken: ccToken, trojan: trojanStatus , showNarrowPrice: seller_price_eligible, maxPrice: max_updated_quoted_price, minPrice: min_updated_quoted_price, } },
                    { Component: TimeLeft, props: { timestamp: auction_over_time, timerFlag: TIMER_FLAGS.AUCTION_RUNNING } },
                    { Component: ExpireTime, props: { date: originalInsDate } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        case DIY_SORT_ORDER.DIY_INTERNAL_BID_5:
        case DIY_SORT_ORDER.DIY_INTERNAL_BID_6:
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: c2cListPriceComponent, props: 
						{ 
							price: calculatedc24Quote, 
							listingPrice: c2cListingPrice, 
							c2cToken: ccToken, 
							trojan: trojanStatus , 
							showNarrowPrice: seller_price_eligible, 
							maxPrice: max_updated_quoted_price, 
							minPrice: min_updated_quoted_price,
							sell_online_ques_flag: sell_online_ques_flag,
							isPriceReportViewed: isPriceReportViewed,
							onClickDiffReport: () => onClickDiffReport(),
						} 
					},
                    { Component: MaskedPrice, props: { text: "Internal-Bid" } },
                    {
                        Component: CTAButton, props: {
                            text: "REAUCTION",
                            onClick: () => onClickReauction(),
                            variant: "blueButton ctaLeft",
                            disabled: (currentAppointmentIndex === appointmentIndex) && isPreChecking,
                            isLoading: (currentAppointmentIndex === appointmentIndex) && isPreChecking,
                        }
                    },
                    { Component: ExpireTime, props: { date: originalInsDate } },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        case DIY_SORT_ORDER.DIY_RA_NEGO_VIEW:
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: RANegoHeading, props: {
                            bestPrice: bestC24Quote, 
                            date: bidValidity, 
                            c24Quote : c24Quote
                        } 
                    },
                    { Component: diyRANegoPriceConfirmComponent, props: 
                        {
                            c24Quote: c24Quote, 
                            onYesClick: () => onDiyPllAcceptPriceOpen(),
                            onEditPrice: ()=> onDiyPllEditPriceOpen(),
                            onGeneratePR: () => onClickGeneratePR(), 
                            onGenerateMorePrice: () => openDepReport("track", c24Quote, true), 
                            disabled: isGMPAllowed || !!ctaAccessFlag, 
                        } 
                    },
                    { Component: !belowTPFlag ? BlankComponent : RIScheduledDateTime, props:{riScheduleDatetime: home_ins_date} }, 
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        case DIY_SORT_ORDER.DIY_DIRECT_OCB_NA:
          state = {
            ui: [
                        { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                        { Component: isPll && msitePriceAccepted ? PriceAcceptedHeader : BlankComponent},
                        { Component: !isPll && highIntentFlag === 1 ? HighConversionChance : BlankComponent},
                        { Component: DirectOCBNAPriceComponent, props: { 
                            trojan: trojanStatus,
                        } },
                        { Component: CTAButton, props: { text: "RAISE OCB", onClick: () => onClickRaiseOCB(), variant: "blueButton", disabled: !!ctaAccessFlag || (isCustomerOcb && [ROLE_KEYS.RA_CX_OCB.toLowerCase(),ROLE_KEYS.RA_TRA.toLowerCase()].includes(originalRole.toLowerCase())) }},
                        { Component: (isCustomerOcb && [ROLE_KEYS.RA_CX_OCB.toLowerCase(),ROLE_KEYS.RA_TRA.toLowerCase()].includes(originalRole.toLowerCase()))?CustomerDrivenOCB:BlankComponent},
                        { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } },
                    ]
          };
        break;
        case 9999997: //PArtial PAI negotiation  - Retail geneerate PR case
        state = {
              ui: [
                { Component: SellerIntend, props: {c24Quote:c24Quote,appointmentId:appointmentId,year:year,fetchNegotiationsConnect:fetchNegotiationsConnect,isHypoAutoApproved:isHypoAutoApproved} },
              ]
            };
          break;
        
        case 9999998: //PArtial PAI negotiation  - Retail geneerate PR case
        state = {
          ui: [
              { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
              {
                  Component: PaiAccepteedAndExpiredOcb, props: {
                      price: isRetailMarginAllowed ? c24Quote : currentHB,
                      ocbPrice: expectedOneClickAmount,
                      expiryDate: originalInsDate, timestamp: auction_over_time,
                      showExpiry: false,
                      onClick: () => onClickGeneratePR(),
                      state: currentState,
                      tokenPrStatus, 
                      disabled: !!ctaAccessFlag,
                      showNarrowPrice: seller_price_eligible, 
                      maxPrice: max_updated_quoted_price, 
                      minPrice: min_updated_quoted_price, 
                  }
              },
              // { Component: isBike ? BlankComponent : TrojanLead, props: {trojanStatus}},
              { Component: !isLmAccessAllowed ? BlankComponent : CTAButton, props: { text: "RAISE OCB", onClick: () => onClickRaiseOCB(), variant: "blueButton", disabled: !!ctaAccessFlag || (isCustomerOcb && [ROLE_KEYS.RA_CX_OCB.toLowerCase(),ROLE_KEYS.RA_TRA.toLowerCase()].includes(originalRole.toLowerCase()))} },
              { Component: (isCustomerOcb && [ROLE_KEYS.RA_CX_OCB.toLowerCase(),ROLE_KEYS.RA_TRA.toLowerCase()].includes(originalRole.toLowerCase()))?CustomerDrivenOCB:BlankComponent},
              // need to change the new component //todo
              { Component: !isPll  || !currentHB || currentState === 20 ? BlankComponent : gsLeadsPriceConfirmComponent, props: 
                  {
                      state: currentState,
                      diy_pll_price_status: diy_pll_price_status, 
                      diy_pll_shared_price: diy_pll_shared_price || currentHB, 
                      validDate: c24quotExpiryTime, 
                      onGerenaretPR: () => onClickGeneratePR(), 
                      onYesClick: () => onDiyPllAcceptPriceOpen(),
                      onEditPrice: ()=> onDiyPllEditPriceOpen(),
                      disabled: !!ctaAccessFlag, 
                  } 
              },
              { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
          ]
      };
            break;
        case 9999999://DIRECT OCB PLL :- OCB Expired 
            state = {
                ui: [
                    { Component: ngtScrap === 1 ? showNGTFlag : BlankComponent},
                    { Component: !isPll ? BlankComponent : currentHB > 0 ? BlankComponent : Heading, props: { text: "OCB EXPIRED FOR", isHighligted: true, color: "red", price: expectedOneClickAmount, sortOrder: sortOrder, state: currentState ,diySortOrder:25} },
                    { Component: isPll ? BlankComponent : c2cListPriceComponent, props: { price: 0, listingPrice: c2cListingPrice, c2cToken: ccToken, trojan: trojanStatus , showNarrowPrice: seller_price_eligible, maxPrice: max_updated_quoted_price, minPrice: min_updated_quoted_price, } },
                    { Component: currentHB > 0 ? BlankComponent : PriceQuote, props: { 
                        price: currentHB > 0 ? currentHB : 0, 
                        sortOrder: sortOrder, 
                        state: currentState,
                        showNarrowPrice: seller_price_eligible,
                        maxPrice: max_updated_quoted_price,
                        minPrice: min_updated_quoted_price,       
                    } },
                    { Component: isPll ? BlankComponent : c2cListCTAButton, props: { text: "List Price", onClick: () => openDepReport("", c2cListingPrice), variant: "transparentBtn ctaRight", listingPrice: c2cListingPrice } },
                    { Component: currentHB > 0 || !isLmAccessAllowed ? BlankComponent : CTAButton, props: { text: currentHB > 0 ? "GENERATE PR" : "RAISE OCB", onClick: () => currentHB > 0 ? onClickGeneratePR() : onClickRaiseOCB(), variant: "blueButton ctaLeft", disabled: !!ctaAccessFlag || (isCustomerOcb && [ROLE_KEYS.RA_CX_OCB.toLowerCase(),ROLE_KEYS.RA_TRA.toLowerCase()].includes(originalRole.toLowerCase())) } },
                    { Component: (isCustomerOcb && [ROLE_KEYS.RA_CX_OCB.toLowerCase(),ROLE_KEYS.RA_TRA.toLowerCase()].includes(originalRole.toLowerCase()))?CustomerDrivenOCB:BlankComponent},
                    {
                        Component: currentHB === 0 ? BlankComponent : PaiAccepteedAndExpiredOcb, props: {
                            price: currentHB, 
                            ocbPrice: expectedOneClickAmount,
                            expiryDate: originalInsDate, 
                            timestamp: auction_over_time,
                            showExpiry: false,
                            onClick: () => onClickGeneratePR(), 
                            disabled: !!ctaAccessFlag,
                            showNarrowPrice: seller_price_eligible, 
                            maxPrice: max_updated_quoted_price, 
                            minPrice: min_updated_quoted_price, 
                        }
                    },
                    { Component: StarButton, props: { isMarkStar: isStarred, onClick: () => onClickMarkStar() } }
                ]
            };
            break;
        default:
          state = {
              ui: []
          };
          break;
    };
    return state;
};

const calculateState = (payload) => {
    const { sortOrder, auction_count, is_suspended, diySortOrder=0, reauctionFlag=0} = payload;
    if((sortOrder == 41 && diySortOrder==31) || (sortOrder == 54)){
      return 9999997; //Case for seller intend
    }
    if (sortOrder === 4) {
        const { conversion } = payload;
        const conversionData = conversion || {};
        const {max_delta_band } = conversionData;
        if (!!max_delta_band) {
            if (max_delta_band === "H") return "highPAIAcceptance";
            if (max_delta_band === "M") return "mediumPAIAcceptance";
            if (max_delta_band === "L") return "lowPAIAcceptance";
            if (max_delta_band === "VL") return "lowPAIAcceptance";
        }
        else { 
            return "noacceptanceProbablity"; 
        }
    } else if ([41, 42].includes(sortOrder)) {
      if (sortOrder == 42) {
        if(diySortOrder == 24){
          return DIY_SORT_ORDER.DIY_DIRECT_OCB_NA;
        }
        else if(diySortOrder == 25){
          return 9999999;
        }
        else if(diySortOrder == 26){
          return 9999998;
        }
      }
        return getDiySortOrderKey(diySortOrder, sortOrder === 42);
    }
    if(sortOrder == 51){
      return DIY_SORT_ORDER.DIY_DIRECT_OCB_NA;
    }
    else if(sortOrder == 52){
      return 16;
    }
    else if(sortOrder == 53){
      return 20;
    }

    if (auction_count > 1) {
        if (sortOrder === 5) return "internalBidExpired";
        if (!reauctionFlag && sortOrder === 6) return "internalBidExpired";
    }
    if (is_suspended || sortOrder === 11) return "isSuspended";

    if (sortOrder) return sortOrder;
    return "noState";
};

const C24Quote = ({
    data,
    appointmentIndex,
    setCurrentPopupConnect,
    preCheckRaisePAIConnect,
    precCheckTokenEliteConnect,
    negotiations,
    isPll,
    updateAppointMent,
    history,
    ResetApiNoAccessConnect,
    fetchNegotiationsConnect
}) => {
    const currentState = calculateState(data);
    const {withCustomer} = useSelector(state => state.negotiationFilter);
    const { currentPopup, currentAppointmentIndex, isPreChecking } = negotiations;
    const getOtpTimeStamps = () => {
        const { appointmentId } = data;
        const timeStamps = getFromLocalStorage(LOCAL_STORAGE_KEYS.QUOTE_TIMESTAMPS) ?
            JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.QUOTE_TIMESTAMPS)) : [];
        const newData = [...timeStamps];
        if (newData.length > 0) {
            let timeStampData = timeStamps.find(item => item.appointmentId === appointmentId);
            if (timeStampData) {
                const timeStamp = timeStampData.unlockotpTime;
                if (timeStamp && !timeStampData.eventSent) {
                    trackLMSEvents("onClickRaisePAI", {
                        eventLabel: appointmentId,
                        lsRAID: getUid(),
                        lsViewTime: Math.floor(getTimeDifference(new Date(timeStamp), new Date()) / 1000),
                    });
                    const currentIndex = newData.findIndex(item => item.appointmentId === appointmentId);
                    timeStampData.eventSent = true;
                    newData[currentIndex] = timeStampData;
                    saveToLocalStorage(LOCAL_STORAGE_KEYS.QUOTE_TIMESTAMPS, JSON.stringify(newData));
                }
            }
        }

    };
    const onDiyPllAcceptPriceOpen = () => {
        setCurrentPopupConnect(popups.DIY_PLL_ACCEPT_PRICE, appointmentIndex);
    };
    const onDiyPllEditPriceOpen = () => {
        setCurrentPopupConnect(popups.DIY_PLL_EDIT_PRICE, appointmentIndex);
    };
    const onAcceptPriceOpen = () => {
        setCurrentPopupConnect(popups.ACCEPTANCE_PRICE, appointmentIndex);
    };
    const onAcceptPriceOpenPLL = () => {
        setCurrentPopupConnect(popups.PLL_ACCEPTANCE_PRICE, appointmentIndex);
    };
    const onClickSellerNotSatisfied = () => {
        setCurrentPopupConnect(popups.PARK_AND_SELL_REAUCTION, appointmentIndex);
    };
    const onClickRaiseOCB = () => {
        ResetApiNoAccessConnect();
        const { appointmentId, storeId, auctionId, carId, retailOcbAllowed = false, diySortOrder, sortOrder ,direct_ocb} = data;
        setCurrentPopupConnect(null, appointmentIndex);
        const loginData = JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA) || "null");
        if (!loginData && !loginData.email) { showToastMessages("Please login again.", false); }
		    const isDirectOcb = ([41,42].includes(sortOrder) && [24, 25, 26].includes(diySortOrder)) || ([51,52,53].includes(sortOrder) || (sortOrder == 41 && diySortOrder ==16 && direct_ocb));
        const payload = {
            "appointment_id": appointmentId,
            "carId": carId,
            "auction_id": auctionId,
            "store_id": storeId,
            "c24quote": 0,
            "requested_c24quote": 0,
            "userEmail": loginData.email,
            "request_type": isDirectOcb ? "DIRECT_OCB" : retailOcbAllowed ? "RAISE_RETAIL_OCB" : "RAISE_PAI",
            "is_pll": isPll,
            "c24Diff": 0,
            "probability": "",
            "reqCheck": "pllRequest",
        };
        // preCheckRaisePAIConnect(payload).then(() => {
        //     setCurrentPopupConnect(popups.RAISE_OCB, appointmentIndex);
        // }).catch(({ data: { message } }) => {
        //     showToastMessages(message, false);
        //     setCurrentPopupConnect(null, null);
        // });
        preCheckRaisePAIConnect(payload).then(() => {
            setCurrentPopupConnect(popups.RAISE_OCB, appointmentIndex);
        }).catch((error) => {
            showToastMessages(error, false);
            setCurrentPopupConnect(null, null);
        });
    };

    const onClickReauction = () => {
        ResetApiNoAccessConnect();
        const { appointmentId, storeId, auctionId, carId } = data;
        const loginData = JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA) || "null");
        if (!loginData && !loginData.email) { showToastMessages("Please login again.", false); }
        setCurrentPopupConnect(null, appointmentIndex);
        const payload = {
            "appointment_id": appointmentId,
            "carId": carId,
            "auction_id": auctionId,
            "store_id": storeId,
            "c24quote": 0,
            "requested_c24quote": 0,
            "userEmail": loginData.email,
            "request_type": "REAUCTION",
            "is_pll": isPll,
            "c24Diff": 0,
            "probability": "",
            "reqCheck": "reauctReq"
        };
        // preCheckRaisePAIConnect(payload).then(() => {
        //     setCurrentPopupConnect(popups.REAUCTION, appointmentIndex);
        // }).catch(({ data: { message } }) => {
        //     showToastMessages(message, false);
        //     setCurrentPopupConnect(null, null);
        // });
        preCheckRaisePAIConnect(payload).then(() => {
            setCurrentPopupConnect(popups.REAUCTION, appointmentIndex);
        }).catch((error) => {
            showToastMessages(error, false);
            setCurrentPopupConnect(null, null);
        });
    };

    const onClickRaisePAI = () => {
        ResetApiNoAccessConnect();
        const { appointmentId, storeId, auctionId, calculatedc24Quote, carId } = data;
        const loginData = JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA) || "null");
        if (!loginData && !loginData.email) { showToastMessages("Please login again.", false); }
        setCurrentPopupConnect(null, appointmentIndex);
        const payload = {
            "appointment_id": appointmentId,
            "carId": carId,
            "auction_id": auctionId,
            "store_id": storeId,
            "c24quote": calculatedc24Quote,
            "requested_c24quote": 0,
            "userEmail": loginData.email,
            "request_type": "RAISE_PAI",
            "is_pll": isPll,
            "c24Diff": 0,
            "probability": "",
            "reqCheck": "paiRequest",

        };
        preCheckRaisePAIConnect(payload).then(() => {
            getOtpTimeStamps();
            setCurrentPopupConnect(popups.RAISE_PAI, appointmentIndex);
        }).catch((error) => {
            showToastMessages(error, false);
            setCurrentPopupConnect(null, null);
        });
    };

    const onClickListC2cPrice = () => {
        ResetApiNoAccessConnect();
        setCurrentPopupConnect(null, appointmentIndex);
        setCurrentPopupConnect(popups.C2C_LIST_PRICE, appointmentIndex);
    };
    const onClickMarkStar = () => {
        const { appointmentId } = data;
        const payload = {
            "appointment": appointmentId,
            "field": "isStarred",
            "value": 1
        };
        AppointmentService.MarkAppointmentStar(payload)
            .then(response => {
                const { data: { detail } } = response;
                showToastMessages(!!detail && !!detail.message ? detail.message : "Star marked Successfully");
                setTimeout(() => {
                    updateAppointMent(appointmentId);
                }, 2000);
            })
            .catch(() => {
                showToastMessages("Error in Star marking", false);
            });

    };
	const onClickDiffReport = () => {
		setCurrentPopupConnect(popups.DIFF_REPORT, appointmentIndex);
	};
    const onClickGeneratePR = () => {
        ResetApiNoAccessConnect();
        const { appointmentId, tokenPrStatus  } = data;
        if (isPll) {
            setCurrentPopupConnect(popups.GENERATE_PR, appointmentIndex);
        }
        else {
            if (tokenPrStatus === TOKEN_PR_STATUS.SAVE_AS_DRAFT || tokenPrStatus === TOKEN_PR_STATUS.TOKEN_SUBMIT){
                history.push({pathname: `/payment-initiate-token/${appointmentId}`, state: data});
            } else {
                precCheckTokenEliteConnect(appointmentId).then(() => {
                    setCurrentPopupConnect(popups.GENERATE_PR, appointmentIndex);
                }).catch(() => {
                    setCurrentPopupConnect(popups.GENERATE_PR, appointmentIndex);
                });
            }
        }
    };
    const onClickAllocateCJ = () => {
        ResetApiNoAccessConnect();
        setCurrentPopupConnect(popups.ALLOCATE_CJ, appointmentIndex);
    };
   
    const onClickSchedulePickup = () => {
        ResetApiNoAccessConnect();
        setCurrentPopupConnect(popups.SCHEDULE_PICKUP, appointmentIndex);
    };
    const onClickInspectionReportPickup = () => {
        ResetApiNoAccessConnect();
        setCurrentPopupConnect(popups.INSPECTION_REPORT, appointmentIndex);
    };
    const onClickInitiatePayment = () => {
        ResetApiNoAccessConnect();
        const { appointmentId, tokenPaymentStatus  } = data;
        if(tokenPaymentStatus === TOKEN_PAYMENT_STATUS.NOT_INITIATED || tokenPaymentStatus === TOKEN_PAYMENT_STATUS.REJECTED || tokenPaymentStatus === TOKEN_PAYMENT_STATUS.RE_INITIATE){
            setCurrentPopupConnect(popups.BIKE_INITIATE_PAYMENT, appointmentIndex);
        } else{
            history.push(`/document-upload/${appointmentId}`);
        }
    };

    const openDepReport = (report, c2cListingPrice, showPriceDetailTab = false, isInventoryBuying = false, showOtpUnlockTimer = false) => {
        ResetApiNoAccessConnect();
        const { appointmentId, isDepReportAvailable, isDepReportViewed } = data;
        try {
            if (report === "track") {
                report = "";
                if (!!c2cListingPrice && parseInt(c2cListingPrice) > 0) {
                    trackLMSEvents("ls_RaisePAI_ModifyLP", {
                        eventLabel: appointmentId,
                        lsRAID: getUid(),
                        Timestamp: new Date()
                    });
                }
                else {
                    trackLMSEvents("ls_GetMorePrice", {
                        eventLabel: appointmentId,
                        lsRAID: getUid(),
                        Timestamp: new Date()
                    });
                }
            }
            else {
                if (report === "") {
                    if (!!c2cListingPrice && parseInt(c2cListingPrice) > 0) {
                        trackLMSEvents("ls_ReviseListCar", {
                            eventLabel: appointmentId,
                            lsRAID: getUid(),
                            Timestamp: new Date()
                        });
                    }
                    else {
                        trackLMSEvents("ls_ListCar", {
                            eventLabel: appointmentId,
                            lsRAID: getUid(),
                            Timestamp: new Date()
                        });
                    }
                }
            }
        }
        catch (e) {

        }
        let showreport = !!report ? report : "charge";
        if (isDepReportAvailable && !isDepReportViewed) {
            showreport = "dep";
        }
        if (showPriceDetailTab) {
            showreport = "charge";
        }
        var url = "/customer-journey/" + appointmentId + "?showreport=" + showreport + "&isDepReportAvailable=" + isDepReportAvailable + "&isDepReportViewed=" + isDepReportViewed + "&isInventoryBuying=" + isInventoryBuying + "&showOtpUnlockTimer=" + showOtpUnlockTimer + "&pagesource=negotiation" ;
        window.open(url, "_blank", 'noopener');
    };

    const openDIYDepReport = (report, c2cListingPrice, showPriceDetailTab = false, isInventoryBuying = false, showOtpUnlockTimer = false) => {
        ResetApiNoAccessConnect();
        const { appointmentId, isDepReportAvailable, isDepReportViewed } = data;
        try {
            if (report === "track") {
                report = "";
                if (!!c2cListingPrice && parseInt(c2cListingPrice) > 0) {
                    trackLMSEvents("ls_RaisePAI_ModifyLP", {
                        eventLabel: appointmentId,
                        lsRAID: getUid(),
                        Timestamp: new Date()
                    });
                }
                else {
                    trackLMSEvents("ls_GetMorePrice", {
                        eventLabel: appointmentId,
                        lsRAID: getUid(),
                        Timestamp: new Date()
                    });
                }
            }
            else {
                if (report === "") {
                    if (!!c2cListingPrice && parseInt(c2cListingPrice) > 0) {
                        trackLMSEvents("ls_ReviseListCar", {
                            eventLabel: appointmentId,
                            lsRAID: getUid(),
                            Timestamp: new Date()
                        });
                    }
                    else {
                        trackLMSEvents("ls_ListCar", {
                            eventLabel: appointmentId,
                            lsRAID: getUid(),
                            Timestamp: new Date()
                        });
                    }
                }
            }
        }
        catch (e) {

        }
        let showreport = !!report ? report : "charge";
        if (isDepReportAvailable && !isDepReportViewed) {
            showreport = "dep";
        }
        if (showPriceDetailTab) {
            showreport = "charge";
        }
        var url = "/customer-journey/" + appointmentId + "?showreport=" + showreport + "&isDepReportAvailable=" + isDepReportAvailable + "&isDepReportViewed=" + isDepReportViewed + "&isInventoryBuying=" + isInventoryBuying + "&showOtpUnlockTimer=" + showOtpUnlockTimer + "&pagesource=negotiation&diy=1&withCustomer=" + withCustomer ;
        window.open(url, "_blank", 'noopener');
    };

    const onClickUnlockViaOtp = () => {
        ResetApiNoAccessConnect();
        const { appointmentId, checkWfMin, isInventoryBuying, c24Quote, c2cListingPrice } = data;
        trackLMSEvents("clickUnlockOtp", {
            eventLabel: appointmentId,
            lsRAID: getUid()
        });
        if(isInventoryBuying && checkWfMin && !isBike){
            AppointmentService.getDepreciationData(appointmentId)
            .then(response => {
                const { data: { detail } } = response;
                const showOtpUnlockTimer = true;
                if(c24Quote > detail.graph?.final_price_range_lower){
                    openDepReport("dep", c2cListingPrice, false, isInventoryBuying, showOtpUnlockTimer);
                }else{
                    setCurrentPopupConnect(popups.QUOTE_UNLOCK, appointmentIndex);
                }
            }).catch(() => {
                setCurrentPopupConnect(popups.QUOTE_UNLOCK, appointmentIndex);
            });
        }else{
            setCurrentPopupConnect(popups.QUOTE_UNLOCK, appointmentIndex);
        }
    };

    const stopOcb = async () => {
      const { appointmentId, carId, auctionId} = data;
      try{
        const res = await AppointmentService.stopOcb(carId,appointmentId,auctionId);
        showToastMessages(res?.detail?.message,true);
      }
      catch(e){
        showToastMessages(e?.detail,false);
      }
    };

    const loginData = JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA) || "null");
    const {role='', originalRole='', weekOff=0, email, tab=TAB_KEYS.NONE} = loginData;

    const negotiationStatesProps = {
        onClickReauction,
        onClickRaisePAI,
        onClickGeneratePR,
        onClickUnlockViaOtp,
        appointmentIndex,
        currentPopup,
        currentAppointmentIndex,
        isPreChecking,
        onClickRaiseOCB,
        onAcceptPriceOpen,
        onAcceptPriceOpenPLL,
        onClickListC2cPrice,
        onClickMarkStar,
        openDepReport,
        openDIYDepReport,
        onClickInitiatePayment,
        onClickAllocateCJ,
        onClickSchedulePickup,
        onClickSellerNotSatisfied,
        onClickInspectionReportPickup,
        onDiyPllAcceptPriceOpen,
        onDiyPllEditPriceOpen,
        onClickDiffReport,
        stopOcb,
        originalRole,
        fetchNegotiationsConnect,
        isHypoAutoApproved:data?.isHypoAutoApproved
    };
    
    return (
      <div className={isPll ? `${styles.c24Wrapper}` : `${styles.c24WrapperTest}`}>
        <div className={styles.qrCode}>
          <QRCode
            value={loginData?.uid?.toString()} 
            size={30}
          />
        </div>
        {currentState &&
                ((negotiationStates(currentState, negotiationStatesProps, data) || {}).ui || [])
                    .map(({ Component, props }, index) => <Component key={`${data.appointmentId}_${index}`} {...props} />)
            }
      </div>
    );
};

export default C24Quote;