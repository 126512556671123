import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  isLoading: null,
  columns: [],
  data: [],
  error: null
};

export const fetchCheckInLeadsSuccess = (state = INITIAL_STATE, { data }) => {
  const { columns, result } = data;
  return { ...state, columns, data: [...state.data, ...result], isLoading: false, error: null };
};

export const fetchCheckInLeadsFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, error, data: [], isLoading: false };
};

export const resetCheckInLeads = () => {
  return INITIAL_STATE;
};

export const resetCheckInLeadsByKey = (state = INITIAL_STATE, { data }) => {
  const { key, value } = data;
  return { ...state, [key]: value };
};

export const HANDLERS = {
  [Types.FETCH_CHECK_IN_LEADS_SUCCESS]: fetchCheckInLeadsSuccess,
  [Types.FETCH_CHECK_IN_LEADS_FAILURE]: fetchCheckInLeadsFailure,
  [Types.RESET_CHECK_IN_LEADS]: resetCheckInLeads,
  [Types.RESET_CHECK_IN_LEADS_BY_KEY]: resetCheckInLeadsByKey
};

export default createReducer(INITIAL_STATE, HANDLERS);
