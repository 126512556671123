import { createTypes } from "reduxsauce";

export default createTypes(
  `
    FETCH_LEAD_DETAIL_SUCCESS
    FETCH_LEAD_DETAIL_FAILURE
    REST_LEAD_DETAIL
    FETCH_SALEONLINE_QUESTIONS_SUCCESS
    FETCH_SALEONLINE_QUESTIONS_FAILURE
    UPDATE_USER_ANSWERED_QUESTION
    REST_SALEONLINE_QUESTIONS
    LOADING_LEAD_DETAIL
    UPDATE_OFFER_PRICE
`,
  {
    prefix: "lead-detail/"
  }
);
