import React from "react";
import PropTypes from "prop-types";
import styles from "./back.module.scss";
import BackArrow from "../images/back-arrow.svg";
import { withRouter } from 'react-router-dom';

const Back = ({ history }) => {
  const onClickHandler = () => {
    history.goBack();
  };
  return <img alt="back" className={styles.back} src={BackArrow} onClick={onClickHandler}/>;
};

Back.propTypes = {
  text: PropTypes.string
};

export default withRouter(Back);
