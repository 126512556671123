import React from 'react';
import styles from "./modal.module.scss";
import backButton from '../../common/images/back-button.svg';

const ModalDialogBox = (props) => {
  const {close, isNavBar= false, title='', children} = props || {};
  return (
    <div className={styles.modalWrapper}>
      {isNavBar && <div className={styles.navigationbar}>
        <img src={backButton} alt='back' onClick={()=> close()}/>
        <span>{title}</span>
      </div>}
      <div className={styles.child}>
        {children}
      </div>
    </div>
  );
};

export default ModalDialogBox;