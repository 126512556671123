import React, { useState } from "react";
import H2 from "../h2";
import Button from "../shared/button/component";
import { getFromLocalStorage, showToastMessages, isPllUser } from "../../utils/helper";
import { LOCAL_STORAGE_KEYS } from "../../utils/app-constants";
import styles from "./reauction-popup.module.css";

const ReauctionPopup = ({ negotiations, paiRequestConnect, setCurrentPopupConnect, close, resetListing }) => {
  const { data, currentAppointmentIndex } = negotiations;
  const currentAppointmentData = data[currentAppointmentIndex];
  const [isLoading, setIsLoading] = useState(false);
  let isPll = isPllUser();
  if (!currentAppointmentData) return <React.Fragment />;

  const onClickReauction = () => {
    setIsLoading(true);
    const {
      appointmentId,
      carId,
      storeId,
      auctionId
    } = currentAppointmentData;
    const loginData = JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA) || "null");
    if (!loginData && !loginData.email) { showToastMessages("Please login again.", false); }
    const payload = {
      "appointment_id": appointmentId,
      "carId": carId,
      "auction_id": auctionId,
      "store_id": storeId,
      "current_c24quote": 0,
      "requested_c24quote": 0,
      "userEmail": loginData.email,
      "request_type": "REAUCTION",
      "is_pll": isPllUser,
      "c24Diff": 0,
      "probability": ""
    };
    paiRequestConnect(payload)
      .then((resp) => {
        showToastMessages(resp && resp.message ? resp.message : resp);
        setCurrentPopupConnect(null, null);
        setIsLoading(false);
        resetListing();
      })
      .catch((resp) => {
        showToastMessages(resp.message, false);
        setIsLoading(false);
      });
  };

  return (
    <div className={styles.reAuction}>
      <H2 text="RE-AUCTION" />
      <p>Are you sure you want to do the re-auction?</p>
      <div className={styles.ctaWrapper}>
        <Button isLoading={isLoading} onClick={() => onClickReauction(currentAppointmentIndex)} classNames="blueButton" ctaText="YES" disabled={isLoading} />
        <button className={styles.closeModal} onClick={close}>NO</button>
      </div>
    </div>
  );
};

export default ReauctionPopup;
