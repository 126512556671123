import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CustomerJourney from "./component";
import withLayout from "../../components/layout/component";
import {fetchNegotiations } from "../../components/negotiation-list/actions";
import {resetApiNoAccess} from "../../components/hoc-access-controller/actions";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchNegotiationsConnect: fetchNegotiations,
  ResetApiNoAccessConnect: resetApiNoAccess
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withLayout(CustomerJourney, false, false));
