/* eslint-disable indent */
import React, { useState, useEffect, useCallback } from "react";
import styles from "./payment-initiate-token.module.scss";
import H2 from "../../components/h2";
import InputText from "../../components/shared/input-text";
import Checkbox from "../../components/shared/checkbox";
import Button from "../../components/shared/button";
import VahanBackground from "../../components/vahan-background";
import ChargesHoldbacks from "../../components/charges-holdbacks";
import SelectComponent from "../../components/shared/select/component";
import Popup from "../../components/popup";
import PaymentInitiateTokenPopup from "../../components/payment-initiate-token-popup";
import { showToastMessages } from "../../utils/helper";
import { AppointmentService } from "../../service";
import { getPrDocumentStatus } from "../../utils/mocApi";
import { TOKEN_PR_STATUS } from "../../utils/app-constants";

const PaymentInitiateToken = (props) => {
    const { history, match, fetchRaTokenDataConnect, paymentInitiateToken, location: {state: {tokenPrStatus} = {}}} = props;
    // const { history, match, fetchRaTokenDataConnect, paymentInitiateToken} = props;
    // console.log("props", props);
    const { params: { appointmentId } } = match;
    const { userData } = paymentInitiateToken;
    const [idProof, setIdProof] = useState('');
    const [panCard, setPanCard] = useState('');
    const [isSencondaryBank, setSencondaryBank] = useState(false);
    const [isThirdPartyPayment, setThirdPartyPayment] = useState(false);
    const [accountNo, setAccountNo] = useState("");
    const [ifsc, setIfsc] = useState("");
    const [accountName, setAccountName] = useState("");
    const [bankName, setBankName] = useState({});
    const [currentTokenPrStaus, setCurrentTokenPrStaus] = useState(tokenPrStatus || {});
    const [primaryErrors, setPrimaryErrors] = useState({
        idProof: '',
        panCard: ''
    });

    const getCurrentTokenPrStatus = () => {
        AppointmentService.getNegotiations(1, appointmentId, null, null, null, null)
            .then(resp => {
                if (resp.data) {
                    const { data: { detail: { data: { result } } } } = resp;
                    if (result.length > 0) {
                        const negoData = result.find(negotiation => negotiation.appointmentId === appointmentId);
                        const {tokenPrStatus} = negoData;
                        setCurrentTokenPrStaus(tokenPrStatus);
                    }
                }
            })
            .catch(error => console.log(error));
    };

    const [optionalErrors, setOptionalErrors] = useState({
        accountNo: '',
        accountName: '',
        bankName: '',
        ifsc: ''
    });

    const [isSubmitted, setIsSubmited] = useState(false);
    const [bankList, setBankList] = useState([]);
    const [documentList, setDocumentList] = useState([]);
    const [selectedDoc, setSelectedDoc] = useState({});
    const [pennySms, setPennySms] = useState(false);
    const [ownersIdProofDoc, setOwnersIdProofDoc] = useState([]);
    const [ownersPanCard, setOwnersPanCard] = useState([]);
    const [ownerConsentLetter, setOwnerConsentLetter] = useState([]);
    const [ownerCancelledCheque, setOwnerCancelledCheque] = useState([]);
    const [tokenOpen, setTokenOpen] = useState(false);
    const [tokenOpenPan, setTokenOpenPan] = useState(false);
    const [tokenOpenConsent, setTokenOpenConsent] = useState(false);
    const [tokenOpencanCelledcheque, setTokenOpenCancelledcheque] = useState(false);
    const [isDocLoading, setIsDocLoading] = useState(false);
    
    const resetInitiatePage = () => {
        fetchRaTokenDataConnect(appointmentId);
        getOwnersDoc('PAN_CARD_COPY');
        getOwnersDoc('CANCELLED_CHEQUE_SECONDARY');
        getOwnersDoc('CONSENT_LETTER');
        getCurrentTokenPrStatus();
    };

    useEffect(() => {
        fetchRaTokenDataConnect(appointmentId);
    }, [fetchRaTokenDataConnect, appointmentId]);

    useEffect(() => {
        setIdProof(userData.ownerIdProofNumber || '');
        setPanCard(userData.ownerPanNumber || '');
        setSencondaryBank(userData.secondaryBank || false);
        setThirdPartyPayment(userData.thirdPartyPayment || false);
        setAccountNo(userData.secondaryBankDetails?.accountNumber || '');
        setIfsc(userData.secondaryBankDetails?.ifscCode || '');
        setAccountName(userData.secondaryBankDetails?.accountName || '');
        userData.applicableDocuments && userData.applicableDocuments.forEach(element => {
            element.label = element.documentName;
            element.value = element.documentName;
        });
        !!userData.applicableDocuments && setDocumentList(userData.applicableDocuments || []);
        !!userData.applicableDocuments && setSelectedDoc(userData.applicableDocuments.find(e => e.documentCode === userData.ownerIdProofType) || {});
        if (Object.keys(userData).length !== 0) {
            getBankPrList(userData);
        }
        !!userData.ownerIdProofType && getOwnersDoc(userData.ownerIdProofType);

        return () => {
            setIdProof('');
            setPanCard('');
            setAccountName('');
            setIfsc('');
            setAccountNo('');
            setBankName({});
            setDocumentList([]);
            setSelectedDoc({});
        };
    }, [userData]);

    useEffect(() => {
        getOwnersDoc('PAN_CARD_COPY');
        getOwnersDoc('CANCELLED_CHEQUE_SECONDARY');
        getOwnersDoc('CONSENT_LETTER');
        getCurrentTokenPrStatus();
    }, []);

    const getOwnersDoc = useCallback(
        (docCode) => {
            setIsDocLoading(true);
            const payload = docCode;
            if (payload) {
                AppointmentService.prDocumentView(appointmentId, docCode)
                    .then(response => {
                        // console.log("doc code: ", response);
                        let {isRetailReceived, images} = response.data.detail[`${payload}`] || {};
                        if (isRetailReceived){
                            switch (docCode) {
                                case 'PAN_CARD_COPY':
                                    setOwnersPanCard(images);
                                    break;
                                case 'CANCELLED_CHEQUE_SECONDARY':
                                    setOwnerCancelledCheque(images);
                                    break;
                                case 'CONSENT_LETTER':
                                    setOwnerConsentLetter(images);
                                    break;
                                default:
                                    setOwnersIdProofDoc(images);
                                    break;
                            }
                        }
                        setIsDocLoading(false);
                    }).catch((error) => {

                    });
            }
        },
        [appointmentId],
    );

    const getBankPrList = useCallback((userData) => {
        AppointmentService.getPrBankList(appointmentId)
            .then(response => {
                const { data: { detail: { detail } } } = response;
                detail.forEach(bankDetail => {
                    if (bankDetail.bankId === userData.secondaryBankDetails?.bankId) {
                        setBankName({
                            'bankName': bankDetail.bankName,
                            'bankId': bankDetail.bankId
                        });
                    }
                });
                setBankList(detail || []);
            }).catch(error => {
            });
    }, [appointmentId]);

    const handlePrimaryValidation = (e) => {
        e.preventDefault();
        let name = e.target.name;
        let value = e.target.value;
        let error = primaryErrors;
        switch (name) {
            case 'idProof':
                error.idProof = !value ? 'This section is mandatory' : '';
                setIdProof(value);
                break;
            case 'pancard':
                error.panCard = !value ? 'This section is mandatory' : value.trim().length < 10 ? 'Invalid PAN no.' : '';
                setPanCard(value);
                break;
            default:
                break;
        }
        setPrimaryErrors(error);
    };

    const handleOptionalValidation = (e) => {
        let name, value, error = optionalErrors;
        if (typeof e === 'string') {
            name = 'bankName';
            value = e;
        } else {
            e.preventDefault();
            name = e.target.name;
            value = e.target.value;
        }

        switch (name) {
            case 'accountNo':
                error.accountNo = !value ? 'This section is mandatory' : '';
                setAccountNo(value);
                break;
            case 'ifscCode':
                error.ifsc = !value ? 'This section is mandatory' : value.trim().length < 11 ? 'Invalid IFSC Code' : '';
                setIfsc(value);
                break;
            case 'accountName':
                error.accountName = !value ? 'This section is mandatory' : '';
                setAccountName(value);
                break;
            case 'bankName':
                error.bankName = !value ? 'This section is mandatory' : '';
                setBankName(value);
                break;
            default:
                break;
        }
        setOptionalErrors(error);
    };

    const validateOptionalForm = optionalErrors => {
        if (!isSencondaryBank) return true;
        let valid = true;
        Object.values(optionalErrors).forEach(val => val.length > 0 && (valid = false));
        return valid;
    };

    const validatePrimaryForm = primaryErrors => {
        let valid = true;
        Object.values(primaryErrors).forEach(val => val.length > 0 && (valid = false));
        return valid;
    };

    const handleSubmit = (formAction) => {
        if (!idProof && selectedDoc?.value) primaryErrors.idProof = 'This section is mandatory';
        if (!idProof && !selectedDoc?.value) primaryErrors.idProof = 'Please select document from above dropdown';
        if (!panCard) primaryErrors.panCard = 'This section is mandatory';
        if (Object.keys(bankName).length === 0 && bankName.constructor === Object) optionalErrors.bankName = 'This section is mandatory';
        if (!accountName) optionalErrors.accountName = 'This section is mandatory';
        if (!ifsc) optionalErrors.ifsc = 'This section is mandatory';
        if (!accountNo) optionalErrors.accountNo = 'This section is mandatory';

        setIsSubmited(true);

        if (validatePrimaryForm(primaryErrors) && validateOptionalForm(optionalErrors)) {
            const payload = {
                appointmentId: appointmentId,
                purchaseRequestId: userData.purchaseRequestId,
                action: formAction,
                customerIntent: "RA_TOKEN",
                ownerIdProofType: selectedDoc?.documentCode,
                ownerIdProofNumber: idProof,
                ownerPanNumber: panCard,
                thirdPartyPayment: isThirdPartyPayment,
                secondaryBank: isSencondaryBank,
            };
            if (isSencondaryBank) {
                payload.secondaryBankDetails = {
                    accountType: 'secondary',
                    accountName: accountName,
                    accountNumber: accountNo,
                    ifscCode: ifsc,
                    bankId: !bankName.bankId ? bankList.find(e => e.bankName === bankName)?.bankId : bankName.bankId
                };
            } else {
                payload.secondaryBankDetails = {
                    accountType: '',
                    accountName: '',
                    accountNumber: '',
                    ifscCode: '',
                    bankId: ''
                };
            }
            AppointmentService.savePrKyc(payload)
                .then(response => {
                    if (formAction === 'SUBMIT') {
                        showToastMessages('PR Submitted', true);
                        setIdProof('');
                        setPanCard('');
                        setAccountName('');
                        setIfsc('');
                        setAccountNo('');
                        setBankName({});
                        setTimeout(() => {
                            history.push(`/negotiation`);
                        }, 1000);
                    } else {
                        showToastMessages('PR Saved', true);
                        setTimeout(() => {
                            resetInitiatePage();
                        }, 1000);
                    }
                }).catch(error => {
                    showToastMessages(error.detail, false);
                });

        } else {
            console.error('Invalid Form');
            if (formAction === 'SUBMIT') {
                window.scrollTo(0, 0);
            }
        }
    };

    const handelDocChange = (value) => {
        const foundSelectedDoc = documentList.find(element => element.value === value);
        if (foundSelectedDoc) {
            setSelectedDoc(foundSelectedDoc);
            if (!idProof) primaryErrors.idProof = 'This section is mandatory';
            else primaryErrors.idProof = '';
        }
    };

    const cancelToken = () => {
        const payload = {
            purchaseRequestId: userData.purchaseRequestId,
            action: "RA_CANCEL",
            customerIntent: "RA_TOKEN",
            appointmentId: appointmentId
        };
        AppointmentService.canclePrKyc(payload)
            .then(response => {
                showToastMessages('PR Cancelled', true);
                setTimeout(() => {
                    history.push(`/negotiation`);
                }, 1000);

            }).catch(error => {
                showToastMessages(error.detail, false);
            });
    };

    const submitPennySms = () => {
        const payload = {
            appointmentId: appointmentId,
        };
        AppointmentService.prResendPennySms(payload)
            .then(response => {
                setPennySms(true);
                setTimeout(() => {
                    setPennySms(false);
                }, 6000);
                const { detail } = response;
                showToastMessages(detail, true);
            }).catch(error => {
                const { detail } = error;
                showToastMessages(detail || error, false);

            });
    };

    const onTokenOpen = (whichPopUp) => {
        switch (whichPopUp) {
            case 'idProof':
                setTokenOpen(true);
                break;
            case 'pancard':
                setTokenOpenPan(true);
                break;
            case 'consentletter':
                setTokenOpenConsent(true);
                break;
            case 'cancelledcheque':
                setTokenOpenCancelledcheque(true);
                break;
            default:
                break;
        };
    };

    const onTokenClose = () => {
        setTokenOpen(false);
        setTokenOpenPan(false);
        setTokenOpenConsent(false);
        setTokenOpenCancelledcheque(false);
    };

    const isButtonDisable = () => {
        if (currentTokenPrStaus === TOKEN_PR_STATUS.PR_APPROVED || currentTokenPrStaus === TOKEN_PR_STATUS.TOKEN_SUBMIT) {
            return true;
        }
        return false;
    };

    const currentUserState = () => {
        switch (currentTokenPrStaus) {
            case TOKEN_PR_STATUS.TOKEN_SUBMIT:
            case TOKEN_PR_STATUS.PR_APPROVED:
            case TOKEN_PR_STATUS.PR_DECLINED:
            case TOKEN_PR_STATUS.SAVE_AS_DRAFT:
                return 'UPDATE';
            case TOKEN_PR_STATUS.NOT_INITIATED_YET:
            case TOKEN_PR_STATUS.RA_CANCEL:
            default:
                return 'SAVE';
        }
    };
    
    const onDownloadThirdPartyLetter = () => {
        AppointmentService.getTPConsentletter(appointmentId)
            .then(response => {
                const {data: {detail: {detail}}} = response;
                console.log("url", detail);
                window.open(detail, "_blank");
            }).catch(error => {
                const { detail } = error;
                showToastMessages(detail || error, false);
            });
    };

    return (
      <div className={styles.paymentInitiateToken}>
        <div className={styles.lostHeading}>
          <H2 text={userData.customerName} />
          <p className={styles.appid}>,{userData.appointmentId}</p>
          <p className={styles.pageDirection}>( My Leads\ Inspected\ lost leads )</p>
        </div>
        <div className={styles.documentWrapper}>
          <h3>KYC Documents</h3>
          <div className={styles.documentCardWrapper}>
            <div className={styles.documentCard}>
              {documentList.length > 0 && <SelectComponent
                            name={`document`}
                            placeholder={"Select Document"}
                            optionsList={documentList.map((item) => {
                                return {
                                    label: item.label,
                                    value: item.value
                                };
                            })}
                            questionId={appointmentId}
                            onChange={handelDocChange}
                            defaultValue={selectedDoc}
                        />}
              <div className={styles.uploadView}>
                <span className={ownersIdProofDoc.length > 0 ? styles.upload : styles.pending}>{ownersIdProofDoc.length > 0 ? 'Uploaded' : 'Pending'}</span>
                <span onClick={() => ownersIdProofDoc.length > 0 ? onTokenOpen('idProof') : null} className={ownersIdProofDoc.length > 0 ? styles.view : styles.dsmText}>{ownersIdProofDoc.length > 0 ? 'VIEW' : 'Please upload from DMS'}</span>
                <Popup isOpen={tokenOpen} close={() => onTokenClose('idProof')}>
                  <PaymentInitiateTokenPopup onClose={onTokenClose} docImages={ownersIdProofDoc} />
                </Popup>
              </div>
              <div className={styles.fieldWrapper}>
                <InputText text={`${selectedDoc?.value || 'ID Proof'} no.*`} name="idProof" value={idProof} onChange={(e) => handlePrimaryValidation(e)} disabled={selectedDoc?.value ? false : true} />
                {
                                (isSubmitted && !!primaryErrors.idProof) &&
                                <small key="idProofError"
                                    className="form-text text-danger">{primaryErrors.idProof}</small>
                            }
              </div>
            </div>
            <div className={styles.documentCard}>
              <h4>PAN card</h4>
              <div className={styles.uploadView}>
                <span className={ownersPanCard.length > 0 ? styles.upload : styles.pending}>{ownersPanCard.length > 0 ? 'Uploaded' : 'Pending'}</span>
                <span onClick={() => ownersPanCard.length > 0 ? onTokenOpen('pancard') : null} className={ownersPanCard.length > 0 ? styles.view : styles.dsmText}>{ownersPanCard.length > 0 ? 'VIEW' : 'Please upload from DMS'}</span>
                <Popup isOpen={tokenOpenPan} close={onTokenClose}>
                  <PaymentInitiateTokenPopup onClose={onTokenClose} docImages={ownersPanCard} />
                </Popup>
              </div>
              <div className={styles.fieldWrapper}>
                <InputText text="PAN card no.*" name="pancard" value={panCard} maxLength={10} onChange={(e) => handlePrimaryValidation(e)} />
                {
                                (isSubmitted && !!primaryErrors.panCard) &&
                                <small key="idProofError"
                                    className="form-text text-danger">{primaryErrors.panCard}</small>
                            }
              </div>
            </div>
          </div>
          <div className={styles.thirdPartyWrapper}>
            <div className={styles.partyPayment}>
              <h3>Third party Payment</h3>
              <Checkbox
                            id={userData.appointmentId}
                            name={`chkPayment_${userData.appointmentId}`}
                            onChangeCallback={() => setThirdPartyPayment(!isThirdPartyPayment)}
                            checkedStatus={isThirdPartyPayment} />
            </div>
                    
            {/* <div className={styles.consentLetter}>
                        <p className={styles.active}>Consent letter:</p>
                        <p>N/A</p>
                    </div> */}
            <div className={styles.consentLetter}>
              <div className={styles.coverWrapper}>
                <p className={ownerConsentLetter.length > 0 ? styles.active : null}>Consent letter:</p>
                <div className={styles.uploadView}>
                  <span className={ownerConsentLetter.length > 0 ? styles.upload : isThirdPartyPayment ? styles.pending : null}>{ownerConsentLetter.length > 0 ? 'Uploaded' : isThirdPartyPayment ? 'Pending' : 'N/A'}</span>
                  <span onClick={() => ownerConsentLetter.length > 0 ? onTokenOpen('consentletter') : null} className={ownerConsentLetter.length > 0 ? styles.view : styles.dsmText}>{ownerConsentLetter.length > 0 ? 'VIEW' : isThirdPartyPayment ? 'Please upload from DMS' : ''}</span>
                  <Popup isOpen={tokenOpenConsent} close={onTokenClose}>
                    <PaymentInitiateTokenPopup onClose={onTokenClose} docImages={ownerConsentLetter} />
                  </Popup>
                </div>
                {isThirdPartyPayment && ownerConsentLetter.length > 0 && <button className={styles.downloadLetter} onClick={onDownloadThirdPartyLetter}>Download sample consent letter</button>}
              </div>
            </div>

          </div>
          <div className={styles.bankDetialsWrapper}>
            <div className={styles.bankDetialsHeading}>
              <h3>Bank Details</h3>
              {userData.primaryPennyStatusLabel !== 'Fully Verified' && <p className={styles.sendSms} onClick={submitPennySms}>Resend SMS</p>}
              {pennySms && <p className={styles.smsSent}>SMS Sent</p>}
            </div>
            <div className={styles.bankText}>
              <p className={styles.primaryText}>
                Primary bank  <span>mandatory</span>
              </p>
              <p className={userData.primaryPennyStatusLabel === 'Fully Verified' ? styles.bankSuccess : styles.pending}>{userData.primaryPennyStatusLabel}</p>
            </div>
            <div className={styles.bankDetailsField}>
              <div className={styles.fieldWrapper}>
                <InputText text="Account no" placeholder="Account no" value={userData.primaryBankDetails?.accountNumber} disabled={true} />
              </div>
              <div className={styles.fieldWrapper}>
                <InputText text="IFSC Code" placeholder="IFSC Code" value={userData.primaryBankDetails?.ifscCode} disabled={true} />
              </div>
              <div className={styles.fieldWrapper}>
                <InputText text="Account holder name" placeholder='Account holder name' value={userData.primaryBankDetails?.accountName} disabled={true} />
              </div>
              <div className={styles.fieldWrapper}>
                <InputText text="Bank Name" placeholder='Bank Name' value={userData.primaryBankDetails?.bankName} disabled={true} />
              </div>
            </div>
            <div className={styles.secondaryBank}>
              <p>Secondary bank
                {isSencondaryBank && <span>mandatory</span>}
              </p>
              <Checkbox
                            id={`sec_${userData.appointmentId}`}
                            name={`chk_${userData.appointmentId}`}
                            onChangeCallback={() => setSencondaryBank(!isSencondaryBank)}
                            checkedStatus={isSencondaryBank}
                        />
            </div>
            {isSencondaryBank && <div className={styles.bankDetailsField}>
              <div className={styles.fieldWrapper}>
                <InputText
                                text="Account no"
                                name="accountNo"
                                value={accountNo}
                                onChange={(e) => handleOptionalValidation(e)}
                            />
                {
                                (isSubmitted && !!optionalErrors.accountNo) &&
                                <small key="accnoErr"
                                    className="form-text text-danger">{optionalErrors.accountNo}</small>
                            }
              </div>
              <div className={styles.fieldWrapper}>
                <InputText text="IFSC Code" name="ifscCode" value={ifsc} maxLength={11} onChange={(e) => handleOptionalValidation(e)} />
                {
                                (isSubmitted && !!optionalErrors.ifsc) &&
                                <small key="ifscErr"
                                    className="form-text text-danger">{optionalErrors.ifsc}</small>
                            }
              </div>
              <div className={styles.fieldWrapper}>
                <InputText text="Account holder name" name="accountName" value={accountName} onChange={(e) => handleOptionalValidation(e)} />
                {
                                (isSubmitted && !!optionalErrors.accountName) &&
                                <small key="accnameErr"
                                    className="form-text text-danger">{optionalErrors.accountName}</small>
                            }
              </div>
              <div className={styles.fieldWrapper}>
                {bankList.length > 0 && <SelectComponent
                                name={`bankName`}
                                placeholder={"Select Bank"}
                                optionsList={bankList.map((item) => {
                                    return {
                                        label: item.bankName,
                                        value: item.bankName
                                    };
                                })}
                                questionId={appointmentId}
                                onChange={(e) => handleOptionalValidation(e)}
                                defaultValue={bankName.bankName ? { label: bankName.bankName, value: bankName.bankName } : null}
                            />}
                {
                                (isSubmitted && !!optionalErrors.bankName) &&
                                <small key="banknameErr"
                                    className="form-text text-danger">{optionalErrors.bankName}</small>
                            }
              </div>
            </div>}
            <div className={styles.chequeWrapper}>
              <p className={styles.cheque}>Cancelled cheque</p>
              <div className={styles.uploadView}>
                <span className={ownerCancelledCheque.length > 0 ? styles.upload : styles.pending}>{ownerCancelledCheque.length > 0 ? 'Uploaded' : 'Pending'}</span>
                <span onClick={() => ownerCancelledCheque.length > 0 ? onTokenOpen('cancelledcheque') : null} className={ownerCancelledCheque.length > 0 ? styles.view : styles.dsmText}>{ownerCancelledCheque.length > 0 ? 'VIEW' : 'Please upload from DMS'}</span>
                <Popup isOpen={tokenOpencanCelledcheque} close={onTokenClose}>
                  <PaymentInitiateTokenPopup onClose={onTokenClose} docImages={ownerCancelledCheque} />
                </Popup>
              </div>
            </div>

            <div className={styles.ctaWrapper}>
              <Button disabled ={isButtonDisable()} ctaText={currentUserState()} classNames="saveCta" onClick={() => handleSubmit('SAVE_DRAFT')} />
            </div>
          </div>
        </div>
        <VahanBackground appointmentId={appointmentId} />
        <ChargesHoldbacks appointmentId={appointmentId} customerConsented={userData.customerConsented} tokenAmount={userData.tokenAmount} />
        <div className={styles.ctaWrapper}>
          <Button disabled ={isButtonDisable()} ctaText={"CANCEL TOKEN"} classNames="transparentBtn" onClick={cancelToken} />
          <Button disabled ={isButtonDisable()} ctaText={"SUBMIT"} classNames="blueButton" onClick={() => handleSubmit('SUBMIT')} />
        </div>
      </div>
    );
};

export default PaymentInitiateToken;
