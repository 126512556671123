import React, { useState, useEffect } from "react";
import styles from "./so-allocate-ra-popup.module.scss";
import H2 from "../h2";
import RadioButton from "../shared/radio-button";
import Button from "../shared/button";
import { AppointmentService } from "../../service";
import { showToastMessages, isPllUser} from "../../utils/helper";
import { NUMBERS } from "../../utils/app-constants";
import Searchicon from "../images/search.svg";

const SoAllocateRAPopup = ({ 
    onClose, 
    data, 
    resetUpdateDataList,
    isLeadMon,
}) => {
    const { appointment, appointmentId, storeId, retail_associate_email} = data || {};
    const [dataList, setDataList] = useState([]);
    const [totaldataList, setTotalDataList] = useState([]);
    const [selectedCj, setSelectedCj] = useState("");
    const [selectedRA, setSelectedRA] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const pageTitleType = "RA";
    const isPLL = isPllUser();
    useEffect(()=> {
        getRAList().then((resp)=>{
            const { data: { detail } } = resp;
            if (!!detail && detail.length > 0) {
                const currentRA = detail.find(item => item.email === retail_associate_email);
                detail.unshift(detail.splice(detail.findIndex(item => item.email === retail_associate_email), 1)[0]);
                !!currentRA && setSelectedCj(currentRA.idLmsUsers || currentRA?.id_lms_users) && setSelectedCj(currentRA.idLmsUsers || currentRA?.id_lms_users);
                setDataList(detail);
                setTotalDataList(detail);
            }
        }).catch(err => {
            console.log("getRaList api error:", err); 
        });
    },[]);

    const onAllocateCjClick = (item) => {
        setSelectedCj(item.idLmsUsers || item.id_lms_users);
        setSelectedRA(item.idLmsUsers || item.id_lms_users);
    };
    
  const onRAAllocate = () => {
    // let action = isAllocate ? "ALLOCATE" : "DEALLOCATE";
    if (!!selectedRA && selectedRA !== ""){
      const params = {
        'appointment': appointment || appointmentId,
        'field': isPLL ? 'pll_id': 'ra_id',
        'value': selectedRA
      };
      AppointmentService.updateAppointment(params).then(resp => {
        const { data: { detail: { message } } } = resp;
        showToastMessages(message);
        onClose();
        setTimeout(() => {
          resetUpdateDataList(appointment || appointmentId);
        }, 2000);
      }).catch(err => {
        const { detail } = err;
        showToastMessages(detail, false);
        resetUpdateDataList(appointment || appointmentId);
        onClose();
      });
    }
  };
  const onChangeHandler = (e) => {
    const { target: { value } } = e;
    setSearchQuery(value.trim());
  };

  const onSearchClick = () => {
    if (searchQuery.trim() && searchQuery.trim().length >= NUMBERS.THREE) {
      let newDataList = [...totaldataList].filter((el) => el.email.includes(searchQuery.trim()));
      setDataList(newDataList);

        }
    };
    const onResetSearchClick = () => {
        setSearchQuery("");
        setDataList(totaldataList);
    };
    const onSearchKeyPress = (e) => {
        const { charCode } = e;
        if (charCode === NUMBERS.THIRTEEN) {
            onSearchClick();
        }
    };

    const getRAList = () => {
        if(isLeadMon){
            return AppointmentService.getStoreRaLeadMonList();
        }
        else{
            return AppointmentService.getStoreRAList(storeId, appointmentId);
        }
    };
    return (
        <div className={styles.soAllocateRAPopup}>
            <H2 text={`Allocate ${pageTitleType}`} />
            <div>
                <div className={styles.searchWrapper}>
                    <div className={styles.search}>
                        <input type="text" name="search" placeholder="Search by Email" onChange={onChangeHandler} onKeyPress={onSearchKeyPress} value={searchQuery} />
                        <button><img alt="" src={Searchicon} onClick={onSearchClick} /></button>
                    </div>
                    <a className={styles.searchRest} href="javascript://" onClick={onResetSearchClick}>{"Reset Search"}</a>
                </div>
                <label className={styles.searchAlert}>* minimum three character required for search</label>
            </div>
            <div className={styles.cjTable}>
                <table cellPadding="0" cellSpacing="0" width="100%">
                    <thead>
                        <tr>
                            <th>{`${pageTitleType} Name`}</th>
                            <th>Allocated</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dataList.map((item) => {
                                return (
                                    <tr>
                                        <td>
                                            <div className={styles.radioButton}>
                                                <RadioButton key={item.idLmsUsers || item.id_lms_users} id={item.idLmsUsers || item.id_lms_users} name="allocateCj" checkedStatus={selectedCj === (item.idLmsUsers || item.id_lms_users)} value={item.idLmsUsers || item.id_lms_users} onClickCallback={() => { onAllocateCjClick(item); }} />
                                                <p className={styles.text}><span>{item.firstName} {item.lastName}</span> ({item.email})</p>
                                            </div>
                                        </td>
                                        {<td><span className={styles.text}>{item.count}</span></td>}
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className={styles.ctaBox}>
                <Button ctaText={"allocate"} classNames="saveCta" onClick={() => onRAAllocate(true)} />
                <Button ctaText={"cancel"} classNames="cancelCta" onClick={onClose} />
            </div>
        </div>
    );
};

export default SoAllocateRAPopup;
