import React,{useEffect, useRef} from "react";
import H3 from "../shared/h3/component";
import LabelWithText from "../shared/label-with-text/component";
import styles from "./price-acceptance-details.module.scss";
import priceFormatter from "../../utils/helpers/price-formatter";
import { LEAD_DETAILS_TABS, NUMBERS } from "../../utils/app-constants";
const LEAD_PAYMENT = {
  RESPONSE_RECEIVED :'Response received?',
  PRICE_ACCEPTED :'Price Accepted?',
  REASON_ACCEPTANCE : 'Reason for non-acceptance?',
  CEP: 'CEP'
};

const PriceAcceptanceDetails = ({selectedTab,data}) => {
  const {price_accepted, reason_for_non_acceptance, CEP} = data;
  const priceAcceptanceRef = useRef();
    
  useEffect(() => {
    if (selectedTab && selectedTab === LEAD_DETAILS_TABS.BIKE_PRICE_ACCEPTANCE.key && priceAcceptanceRef && priceAcceptanceRef.current) {
      priceAcceptanceRef.current.scrollIntoView();
    }
  }, [selectedTab]);

  return (
    <div ref={priceAcceptanceRef} className={styles.leadPayment}>
      <H3 text={"Price Acceptance"} />
      <div className={styles.fieldWrapper}>
        <div className={styles.textWrapper}>
          <div className={styles.detailsLeftWrapper}>
            <LabelWithText
              label={LEAD_PAYMENT.RESPONSE_RECEIVED}
              text={price_accepted < NUMBERS.TWO ? "No" : "Yes"}
            />
          </div>
        </div>
        <div className={styles.textWrapper}>
          <div className={styles.detailsLeftWrapper}>
            <LabelWithText
              label={LEAD_PAYMENT.PRICE_ACCEPTED}
              text={price_accepted === NUMBERS.TWO ?"Yes" : price_accepted === NUMBERS.THREE ? "No" : ""} 
            />
          </div>
        </div>
      </div>  
      <div className={styles.fieldWrapper}>  
        <div className={styles.textWrapper}>
          <div className={styles.detailsLeftWrapper}>
            <LabelWithText
              label={LEAD_PAYMENT.REASON_ACCEPTANCE}
              // text={reason_for_non_acceptance}
            />
            {!!reason_for_non_acceptance && <div className={styles.toolTipTextWrapper}>
              <div className={styles.overText}>
                <span>{reason_for_non_acceptance}</span>
                {reason_for_non_acceptance.length > NUMBERS.FIFTEEN && <h5>more</h5>}
              </div>
              <div className={styles.toolTipWrapper}>
                <div className={styles.toolTip}>{reason_for_non_acceptance}</div>
              </div>
            </div>}
          </div>
        </div>
        <div className={styles.textWrapper}>
          <div className={styles.detailsLeftWrapper}>
            <LabelWithText
              label={LEAD_PAYMENT.CEP}
              text={!!CEP && `₹${priceFormatter(CEP)}`} 
            />
          </div>
        </div>
      </div>
        
    </div>
  );
};

export default PriceAcceptanceDetails;
