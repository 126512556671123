import React, { useState } from "react";
import styles from "./textarea.module.css";
import useToggle from "../../hooks/use-toggle";

const Textarea = ({
  value = "",
  id = "",
  text = "",
  placeholder = "",
  rows = 3,
  onChange = () => { },
  onBlurCallback = () => { },
  validator = () => { },
  errorMessage = "",
  inputRef,
  onFocusCallback = () => { },
  maxLength
}) => {
  const [isTouched, setIsTouched] = useState(false);
  const [isFocused, setIsFocused] = useToggle(false);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(errorMessage);

  const onChangeHandler = (e) => {
    const inputValue = e.target.value;
    if (validator(inputValue)) {
      setHasError(false);
      setError("");
    } else {
      setHasError(true);
      setError(errorMessage);
    }
    onChange(e);
  };

  const onFocus = () => {
    setIsFocused();
    setIsTouched(true);
    onFocusCallback();
  };

  const onBlur = (e) => {
    setIsFocused();
    onBlurCallback(e.target.value);
  };

  return (
    <div className={styles.textareaWrap}>
      <div className="form-group">
        <textarea
          ref={inputRef}
          id={id}
          placeholder={placeholder}
          value={value}
          autoComplete="off"
          rows={rows}
          className="form-control"
          onChange={onChangeHandler}
          onFocus={(e) => onFocus(e)}
          onBlur={(e) => onBlur(e)}
          maxLength={maxLength}
        />
      </div>
      <label className={`${(isFocused || !!value) ? styles.active : ""}`}>{text}</label>
      {
        isTouched &&
                !isFocused &&
                hasError &&
                <span className={styles.error}>{error}</span>
      }
    </div>
  );
};
export default Textarea;
