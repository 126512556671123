/* eslint-disable indent */
import React, { useState, useEffect, useRef } from "react";
import styles from "./global-search.module.css";
import UnorderedList from "../shared/unordered-list";
import ListItem from "../shared/list-item";
import GlobalSearchItem from "../global-search-item";
import {
	RESET_REDUCER_STATE_KEYS,
	GLOBAL_SEARCH_STATES,
	APPOINTMENT_TYPES_STRING,
	NUMBERS,
	ROLE_KEYS,
} from "../../utils/app-constants";
import { AppointmentService } from "../../service";
import ButtonLoader from "../button-loader";
import collapsedSearch from "../images/local-search.svg";
import {
	IsConfirmedNotConfirmedViewOfSchedule,
	getUid,
	getLMSOrinialRole,
} from "../../utils/helper";
import { useDebounce } from "../../utils/debaounce";
import { trackLMSEvents } from "../../utils/helpers/tracking-events";
import moment from "moment";
import { useDispatch } from "react-redux";
import { updateWithCustomer } from "../negotiation-filter/actions";
const GlobalSearch = ({
	history,
	resetSearchConnect,
	resetGlobalSearchByKeyConnect,
	appointments,
	updateconfirmLeadsFilterQueryConnect,
	resetFilterQueryConnect,
	updatereviewInspectionFilterQueryConnect,
}) => {
	const [data, setData] = useState([]);
	const [query, setQuery] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [searchError, setSearchError] = useState("");
	const [showSearch, setShowSearch] = useState(false);
	const { appointmentState } = appointments;
	const searchRef = useRef(null);
	const searchInputRef = useRef();
	const debouncedSearchTerm = useDebounce(query, 5000);
	const dispatch = useDispatch();
	const isRALMUser = getLMSOrinialRole() === ROLE_KEYS.RA_LM;
	useEffect(() => {
		document.addEventListener("click", handleClickOutside, false);
		return () => {
			document.removeEventListener("click", handleClickOutside, false);
		};
	}, []);

	useEffect(() => {
		resetStates();
	}, [appointmentState]);

	useEffect(() => {
		if (showSearch) {
			searchInputRef.current.focus();
		}
	}, [showSearch]);

	useEffect(() => {
		setIsLoading(false);
		if (query && query.trim().length >= NUMBERS.THREE) {
			setIsLoading(true);
		} else if (data.length > 0) {
			setData([]);
			setIsLoading(false);
		}
	}, [query]);
	useEffect(() => {
		if (query && query.trim().length >= NUMBERS.EIGHT) {
			fetchSearchResults();
		} else if (data.length > 0) {
			setData([]);
		}
	}, [debouncedSearchTerm]);
	const handleClickOutside = (event) => {
		const targetName = event.target.getAttribute("name");
		if (
			searchRef.current &&
			!searchRef.current.contains(event.target) &&
			targetName !== "collapsed-search"
		) {
			resetStates();
		}
	};

	const onClickHandler = (item) => {
		if (item) {
			const {
				appointment,
				state_name,
				lost_view,
				nego_customer_view,
				nego_diy_view = 0,
				nego_diy_pll_view = 0,
				retarget_nego_view = 0,
				retarget = 0,
				selfAssigned = 0,
				isMonetized=false} = item;
			if (state_name && state_name.trim()) {
				let path = getAppointmentTypeStringByState(
					state_name.toLowerCase(),
					lost_view
				);
				if (
					nego_diy_view === 1 &&
					state_name.toLowerCase() ===
						GLOBAL_SEARCH_STATES.NEGOTIATION
				) {
					path = APPOINTMENT_TYPES_STRING.DIY_NEGOTIATION;
					!!nego_customer_view && dispatch(updateWithCustomer(true));
				} else if (
					nego_diy_pll_view === 1 &&
					state_name.toLowerCase() ===
						GLOBAL_SEARCH_STATES.NEGOTIATION
				) {
					path = APPOINTMENT_TYPES_STRING.DIY_NEGOTIATION;
				} else if (
					retarget_nego_view === 1 &&
					state_name.toLowerCase() ===
						GLOBAL_SEARCH_STATES.NEGOTIATION
				) {
					path = APPOINTMENT_TYPES_STRING.CENTRAL_NEGOTIATION;
				} else if (
					retarget === 1 &&
					state_name.toLowerCase() ===
						GLOBAL_SEARCH_STATES.NEGOTIATION
				) {
					path = APPOINTMENT_TYPES_STRING.CENTRAL_DIY_NEGOTIATION;
				} else if (
					state_name.toLowerCase() === GLOBAL_SEARCH_STATES.INSPECTED
				) {
					if(isRALMUser){
						updatereviewInspectionFilterQueryConnect(
							selfAssigned === 1 ? "assigned" : "all_leads"
						);
					} else {
						!!isMonetized ? updatereviewInspectionFilterQueryConnect("LeadMon") : updatereviewInspectionFilterQueryConnect("");
					}
				}
				resetSearchConnect();
				resetGlobalSearchByKeyConnect(
					RESET_REDUCER_STATE_KEYS.GLOBAL_QUERY,
					`${appointment}&globalSearch=true`
				);
				if (
					GLOBAL_SEARCH_STATES.SCHEDULED === state_name.toLowerCase()
				) {
					resetFilterQueryConnect(null);
					redirectForSchedule(item, path);
				} else {
					history.push(path ? `/${path}` : `/detail/${appointment}`);
				}
				trackLMSEvents("ls_LeadSearch", {
					eventLabel: appointment,
					lsRAID: getUid(),
					event: "ls_LeadSearch",
					eventCategory: path,
					eventAction: "GlobalSearch",
					Timestamp: moment().format(),
				});
			}
		}
		resetStates();
	};

	const redirectForSchedule = (item, path) => {
		const { appointment, state_name, lost_view, scheduled_hi_view } = item;
		let isHIConfigured = IsConfirmedNotConfirmedViewOfSchedule();
		if (lost_view) {
			history.push(APPOINTMENT_TYPES_STRING.LOST_LEAD, {
				fromPage: state_name.toLowerCase(),
			});
		} else if (scheduled_hi_view >= 0) {
			let selectedValue = "1";
			if (scheduled_hi_view === 1) {
				selectedValue = "1";
				if (isHIConfigured) {
					const query = `&leadConfirmed=${selectedValue}`;
					updateconfirmLeadsFilterQueryConnect(query);
				}
				history.push(path ? `/${path}` : `/detail/${appointment}`);
			} else if (scheduled_hi_view === 0) {
				selectedValue = "0";
				if (isHIConfigured) {
					const query = `&leadConfirmed=${selectedValue}`;
					updateconfirmLeadsFilterQueryConnect(query);
				}
				history.push(path ? `/${path}` : `/detail/${appointment}`);
			} else {
				history.push(path ? `/${path}` : `/detail/${appointment}`);
			}
		} else {
			history.push(path ? `/${path}` : `/detail/${appointment}`);
		}
	};
	const onChangeHandler = (e) => {
		const {
			target: { value },
		} = e;
		setQuery(value);
		setSearchError("");
	};

	const fetchSearchResults = () => {
		setIsLoading(true);
		AppointmentService.globalSearch(query)
			.then((resp) => {
				const {
					data: { detail },
				} = resp;
				setData(detail);
				setIsLoading(false);
				if (detail.length === NUMBERS.ZERO) {
					setSearchError("No Records Found!");
				}
			})
			.catch((err) => {
				console.log({ err });
				if (err.status === 422) {
					setSearchError(err.detail);
				} else {
					setSearchError("");
				}
				setData([]);
				setIsLoading(false);
			});
	};

	const getAppointmentTypeStringByState = (stateName) => {
		switch (stateName) {
			case GLOBAL_SEARCH_STATES.SCHEDULED:
				return APPOINTMENT_TYPES_STRING.SCHEDULED;
			case GLOBAL_SEARCH_STATES.VISITED:
				return APPOINTMENT_TYPES_STRING.VISITED;
			case GLOBAL_SEARCH_STATES.INSPECTED:
				if (isRALMUser) {
					return APPOINTMENT_TYPES_STRING.LEAD_MON;
				} else {
					return APPOINTMENT_TYPES_STRING.INSPECTED;
				}
			case GLOBAL_SEARCH_STATES.NEGOTIATION:
				return APPOINTMENT_TYPES_STRING.NEGOTIATION;
			case GLOBAL_SEARCH_STATES.PAYMENT_PROCESSING:
				return APPOINTMENT_TYPES_STRING.PAYMENT_PROCESSING;
			case GLOBAL_SEARCH_STATES.CLEARNANCE_PENDING:
				return APPOINTMENT_TYPES_STRING.CLEARANCE_PENDING;
			case GLOBAL_SEARCH_STATES.SELL_ONLINE:
				return APPOINTMENT_TYPES_STRING.SELL_ONLINE;
			default:
				return "";
		}
	};

	const resetStates = () => {
		setData([]);
		setQuery("");
		setShowSearch(false);
		setSearchError("");
	};

	return (
		<div ref={searchRef} className={styles.globalSearchWarpper}>
			{showSearch && (
				<div className={styles.globalSearch}>
					<input
						autoComplete="off"
						ref={searchInputRef}
						type="text"
						name="global-search"
						placeholder="Type min 8 chars to search any lead"
						onChange={onChangeHandler}
						value={query}
					/>
					{isLoading && (
						<div className={styles.globalSearchLoader}>
							<ButtonLoader color="#000" />
						</div>
					)}
					{!isLoading && (
						<button className={styles.searchIcon}></button>
					)}
				</div>
			)}
			{!showSearch && (
				<img
					name="collapsed-search"
					src={collapsedSearch}
					alt=""
					className={styles.collapsedSearch}
					onClick={() => setShowSearch(true)}
				/>
			)}
			{!searchError && !!data && data.length > 0 && (
				<div className={styles.searchList}>
					<UnorderedList>
						{data.map((item) => {
							return (
								<ListItem
									key={item.appointment}
									onClick={() => onClickHandler(item)}>
									<GlobalSearchItem item={item} />
								</ListItem>
							);
						})}
					</UnorderedList>
				</div>
			)}
			{searchError && (
				<div className={styles.searchList}>
					<UnorderedList>
						<ListItem
							classes={styles.noRecords}
							onClick={onClickHandler}>
							{searchError}
						</ListItem>
					</UnorderedList>
				</div>
			)}
		</div>
	);
};

export default GlobalSearch;
