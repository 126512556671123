export const API_ENDPOINTS = {
  APPOINTMENTS: 'lead-list',
  NEGOTIATIONS: 'in-negotiation',
  RT_NEGOTIATIONS: 'retarget/lead',
  CHECK_IN_STATUS: 'check-in-status',
  POST_CHECK_IN: 'check-in',
  FOLLOW_UP: 'follow-ups',
  FOLLOW_UP_DISPOSITION: 'follow-up-dispositions',
  CHECK_IN_DENY: 'check-in-denied',
  LOGIN: 'authentication',
  APPOINTMENT_COUNTS: 'lead-count',
  CAR_DATA: 'get-car-data',
  INSPECTION_REPORT: 'inspection-report',
  CUSTOMER_EXPECTED_PRICE: 'update-ecp',
  PRE_CHECK: 'pai-pre-check',
  PAI_REQUEST: 'pai-request',
  SEND_OTP: 'resend-c24-quote-otp',
  VERIFY_OTP: 'verfiy-c24-otp',
  UPDATE_SUGGESTED_QUOTE: 'update-c24quote',
  GLOBAL_SEARCH: 'global-search',
  APPOINTMENT_DETAILS: 'appointment-details',
  CALL_LOGS: 'appointment-call-logs',
  APPOINTMENT_JOURNEY: 'lead-state-logs',
  UPDATE_APPOINTMENT: 'update-appointment',
  RECORDING_URL: 'get-recording-url',
  VIRTUAL_NUMBER: 'allocate-vn',
  DEAL_LOST: 'deal-lost',
  RETAIL_ASSOCIATES: 'store-associate-list',
  CHECK_IN_LEADS: 'checkin-leads',
  STORE_LIST: 'store-list',
  STORE_ASSOCIATE_LIST: 'store-associate-list',
  RA_APPOINTMENT_LIST: 'pll-appointments',
  ALLOCATE_APPOINTMENT: 'assign-pll-appointments',
  PLL_ASSOCIATE_LIST: 'pll-associate-list',
  DEPRECIATION_DATA: 'depreciation-report',
  RAISE_OCB_DATA: 'raise-ocb',
  RETAIL_VIEW: 'retail-summary-view',
  EVALUATOR_VIEW: 'evaluator-summary-view',
  EVALUATOR_TOKENS: 'store-summary-view',
  ACCEPT_OCB_QUOTE: 'ocb-request',
  GOOGLE_LOGIN: 'google-auth',
  FETCH_MAKE: 'make',
  FETCH_MODEL: 'model',
  FETCH_YEAR: 'year-variant',
  FETCH_VARIANT: 'variant',
  // FETCH_STATE: 'fetch-state',
  // FETCH_ODOMETER: 'fetch-odometer',
  // FETCH_CITY: 'fetch-city',
  // FETCH_BRANCH: 'fetch-branch',
  // FETCH_SLOTS: 'fetch-slots',
  // BOOK_APPOINTMENT: 'book-appointment',
  MARKEETING_QUEESTION: 'post-check-in-questions',
  FETCH_STATE: 'state',
  FETCH_ODOMETER: 'odometer',
  FETCH_AWARENESS: 'source-awareness',
  FETCH_CITY: 'registration-cities',
  FETCH_BRANCH: 'city-branches',
  FETCH_SLOTS: 'appointment-slots',
  BOOK_APPOINTMENT: 'book-appointment',
  APPOINTMENT_LIST: 'appointment-list',
  OFFER: 'offer',
  LOST_LEAD_QUEESTION: 'lost-reasons',
  SIMILAR_CAR_DETAILS: 'search-leads',
  CUSTOMER_CHARGES: 'tentative-charges',
  CUSTOMER_OFFER_DETAIL: 'input-output-page',
  SIMILAR_TRANSCATED_CARS: 'similar-transacted-cars',
  VALIDATE_DASHBOARD: 'waittime/validate-dashboard',
  VERIFY_WAIT_TIME_OTP: 'waittime/verify-otp',
  SEND_WAIT_TIME_OTP: 'waittime/send-otp',
  C2C_BOUNDRY_VALUES: 'get-c2-c-list-price',
  C2C_LIST: 'list-c2-c',
  APPOINTMENT_MARK_STAR: 'update-appointment',
  ORDER_DETAIL: 'get-order-details',
  HOME_INSPECTION_ZONE: 'zone-list',
  EVALUATOR_LIST: 'evaluator-list',
  ASSIGN_EVALUATOR: 'assign-evaluator',
  ASSIGN_BIKE_EVALUATOR: 'assign-evaluator',
  TOKEN_ELITE: 'token-elite',
  DEALER_DETAIL: 'dealer-detail',
  DEALER_CONNECT: 'connect-dealer',
  APPOINTED_CJ_DETAIL: 'home-inspection-cj-info',
  TOKEN_ELITE_AB_TEST: 'check-token-delite',
  SEND_DEP_REPORT: 'dep-report-to-pdf',
  CJ_ALLOCATION_STATUS: 'cj-allocation-status',
  TIME_SLOT: 'time-slot',
  EVALUATOR_LEAD_COUNT: 'evaluator-lead-count',
  CHANGE_LOGS: 'change-logs',
  SEND_DEP_REPORT_FEEDBACK: 'save-comment',
  UPDATE_PROFILE: 'update-profile',
  LEAD_DATA_WITH_PHONE: 'unregistered-checkin-list',
  STORE_CHECKIN: 'store-checkin',
  INSPECTION_REVIEW: 'review-inspection',
  LIST_CAR_OTP: 'c2c-list-otp',
  SEND_INSPECTION_REPORT: 'inspection-report',
  DOCUMENT_STATUS_DETAILS: 'document-detail',
  SEND_APPOINTMENT_BUY_NOW_PRICE: 'buy-now',
  SEND_AUCTION_START: 'auction-request',
  REVISE_APPOINTMENT_VN: 'revise-vn',
  DASHBOARD_RA_INCENTIVE: 'ra-incentive',
  GET_APPOINTMENT_ANNOUNCEMENT: 'message-announcement',
  PUSH_APPOINTMENT_ANNOUNCEMENT: 'message-announcement',
  ADMIN_API_USER: 'chat/user',
  RESEND_PICKUP_OTP: 'resend-pickup-otp',
  BIKE_INITIATE_TOKEN_PAYMENT: 'api/bike/initiate-token-payment',
  RESCHDULE_APPOINTMENT_FETCH_LOCATION: 'fetch-locations',
  POST_RESCHDULE_APPOINTMENT_DATA: 'reschedule-appointment',
  SIMILAR_CAR_INV: 'similar-cars',
  SELLER_REVIEW: 'seller-review',
  GET_PR_ENABLE: 'pr-enable',
  GET_PR_INITIATE_TOKEN: 'pr-initiate-token',
  GET_PR_BANK_LIST: 'get-pr-bank-list',
  GET_PR_VAHAN_CHECK :'get-pr-vahan-check',
  GET_PR_KYC :'get-prkyc',
  PR_KYC: 'pr-update-kyc-detail',
  PR_PENNY_SMS: 'pr-resend-penny-sms',
  PR_PENNY_CHARGES_SMS: 'pr-resend-charges-consent',
  PR_DOC_VIEW: 'pr-document-status',
  SAVE_CEP_PRICE: 'save-cep-price',
  PRE_CHECKIN: 'pre-checkin',
  CUSTOMER_PROPOSED_OFFER_PRICE: '/msite/offer',
  BIKE_INITIATE_PR_TOKEN: 'api/bike/initiate-pr-token',
  BIKE_API_RA_LST: 'api/bike/retail-associate-list',
  CAR_CJ_SLOTS: 'car-cj-slot',
  GET_CONSENT_LETTER_DOC: 'get-consent-letter-doc',
  BIKE_PICKUP_REQUEST: '/api/bike/pickup-request',
  BIKE_UPLOAD_DOCUMENT: 'upload-document',
  RESEND_PRICE :'send-seller-intent-msg',
  BIKE_SEND_PRICE_SMS: 'send-offer',
  ON_SPOT_REDIRECTION: 'on-spot-redirection',
  CHECK_AND_ALLOW_PR: 'checkand-allow-pr',
  GET_SLOT_ONLINE: 'get-slot-online',
  BIKE_PURCHASE_REQUEST: '/api/bike/purchase-request',
  RESOURCE_CJ_ALLOCATION_STATUS: 'resource/allocation-status',
  RESOUCE_CJ_MONITORING: 'resource/monitoring',
  HOME_INSPECTION: 'home-inspection',
  SEND_PICKY_ASSIST_CHAT:'/api/notification'
};

export const ERROR_CODES = {
  UNAUTHORISED: 401,
  NOT_ACCESS: 422,
  NOT_FOUND: 404,
  SUCCESS: 200,
  FOUND: 302,
  MOVED_PERMANENTLY: 301,
  BAD_REQUEST: 400,
  ERROR: 500,
};
