import React from "react";
import {
  BarChart, Bar, XAxis, YAxis, Tooltip, Cell
} from 'recharts';
import "./depreciation-bar.scss";
import {DEPRECIATION_CONSTANTS,NUMBERS} from "../../utils/app-constants";
import Rating from "../images/star.svg";
import {getDepreciationTooltip, getGraphData} from "../../utils/helper";

const DepreciationBar = ({graphData}) => {
  const { data,graph } = graphData;
  const barData = getGraphData(graph,data);

  const getRatingColor=(rating)=>{
    if(rating<3)
    {
      return "red";
    }
    else if(rating >=3 && rating <=4)
    {
      return "yellow";
    }
    else {
      return "green";
    }

  };

  const CustomTooltip = ({ active,label }) => {
    const toolTipValues = getDepreciationTooltip(label,{...data});

    if (toolTipValues && active) {
      const { keyData,range,rating, depr} = toolTipValues;
      switch (label) {
        case DEPRECIATION_CONSTANTS.veryGoodMax:
        case DEPRECIATION_CONSTANTS.final:
          return(
            <div>
              <div>{toolTipValues.range}</div>
            </div>
          );
        default:
          return(
            <div>
              {
                rating && <div className={`rating ${getRatingColor(rating)}`}>
                  <img src={Rating} alt="Rating"/>
                  <span className="ratingText">{rating}</span>
                </div>
              }
              {
                keyData && <div>
                  {keyData.key}: {keyData.value}
                </div>
              }
              <div>Depr: {depr}%</div>
              <div>{range}</div>
            </div>
          );
      }

    }
    return null;
  };

  const CustomizedAxisTick = (props)=>{
    const {x, y, payload} = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-25)" font-size="0.6em">{payload.value}</text>
      </g>
    );
  };

  return (
    <div className="depreciation">
      <BarChart
        width={620}
        height={300}
        data={barData}
        margin={{top: 10, right: 20, left: 20, bottom: 30}}
      >
        <XAxis dataKey="name" interval={NUMBERS.ZERO} tick={<CustomizedAxisTick />}/>
        <YAxis />
        <Tooltip content={<CustomTooltip />} cursor={true} />
        <Bar dataKey="Minimum" stackId="a" fill="#ffffff" />
        <Bar dataKey="Maximum" stackId="a">
          {
            barData.map((data)=>{
              const { name } = data;
              if(name === DEPRECIATION_CONSTANTS.final || name === DEPRECIATION_CONSTANTS.veryGoodMax)
                return <Cell fill="#1b92dc" />;
              else return <Cell fill="#ff6359" />;
            })
          }
        </Bar>
      </BarChart>
    </div>
  );
};

export default DepreciationBar;
