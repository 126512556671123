import React, { Fragment } from "react";
import "./handle.css";
import PropTypes from "prop-types";
const Handle = ({
  domain: [min, max],
  handle: { id, value, percent },
  disabled,
  getHandleProps,
  animatedHandle,
  handleColor
}) => {
  return (
    <Fragment>
      <div
        style={{
          left: `${percent}%`,
          position: "absolute",
          transform: "translate(-50%, -50%)",
          WebkitTapHighlightColor: "rgba(0,0,0,0)",
          zIndex: 5,
          width: 28,
          height: 42,
          cursor: "pointer",
          // border: '1px solid white',
          backgroundColor: "none"
        }}
        {...getHandleProps(id)}
      />
      <div
        className={
                    animatedHandle ? `rippleEffect ${handleColor || "orange"} sliderCircle` : "sliderCircle"
        }
        role="slider"
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        style={{
          left: `${percent}%`,
          backgroundImage: disabled ? "#fff" :
                        `${handleColor === "orange" ?
                            "linear-gradient(225deg, #f69b00, #ed6a00)" :
                            "linear-gradient(225deg, #ff6359, #ff6359)"}`
        }}
      />
    </Fragment>
  );
};

Handle.propTypes = {
  domain: PropTypes.array.isRequired,
  handle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired
  }).isRequired,
  getHandleProps: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  animatedHandle: PropTypes.array,
  handleColor: PropTypes.string
};

Handle.defaultProps = {
  disabled: false
};

export default Handle;
