import { createTypes } from "reduxsauce";

export default createTypes(
  `
        FETCH_MAKE
        FETCH_MAKE_SUCCESS
        FETCH_MAKE_FAILURE
        FETCH_ODOMETER
        FETCH_ODOMETER_SUCCESS
        FETCH_ODOMETER_FAILURE
        FETCH_STATE
        FETCH_STATE_SUCCESS
        FETCH_STATE_FAILURE
`,
  {
    prefix: "bookappointment/"
  }
);
