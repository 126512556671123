import { createTypes } from "reduxsauce";

export default createTypes(
  `
    UPDATE_SELECTED_DEALER
    FETCH_CAR_DETAILS_SUCCESS
    FETCH_CAR_DETAILS_FAILURE
    RESET_LEAD_DATA
    UPDATE_REG_NUMBER
    UPDATE_CAR_DETAILS
    RESET_CAR_DETAILS
    FETCH_DEALER_CITY_SUCCESS
    FETCH_DEALER_CITY_FAILURE
`,
  {
    prefix: "leadprocurement/"
  }
);
