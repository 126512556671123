import React, {useRef, useEffect, useState} from "react";
import AsyncSelect from "react-select/lib/Async";

const OnlineSearch = ({
  onChange,
  getOptions,
  placeholder = "Select..",
  classNamePrefix = "search-select",
  customDefaultValue = "",
  labelClass,
  label = "",
  onInputChangeCallback = () => {},
  overrideSearchRef,
  ...restProps
}) => {
  const [searchRef, setSearchRef] = useState(useRef());
  const [inputValue, setInputValue] = useState(customDefaultValue);
  const [selectedValue, setSelectedValue] = useState('');
  const [placeholderValue, setPlaceHolderValue] = useState(placeholder);
  useEffect(() => {
    if (overrideSearchRef) {
      setSearchRef(overrideSearchRef);
    }
  }, [overrideSearchRef]);

  const onInputChange = (query, {action}) => {
    setInputValue(query);
    if (action.action !== "input-blur" && action.action !== "menu-close") {
      if (query.length === 0) {
        setPlaceHolderValue(placeholder);
      }
    }
    return query;
  };
  const onHandleChange = (value) => {
    setSelectedValue(value);
    onChange(value);
  };
  const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

  return (
    <React.Fragment >
      <AsyncSelect  styles={selectStyles} 
        // cacheOptions
        inputValue={inputValue}
        value={selectedValue}
        onInputChange={onInputChange}
        ref={searchRef}
        className="search-select-container"
        loadOptions={getOptions}
        onChange={onHandleChange}
        classNamePrefix={classNamePrefix}
        defaultOptions
        placeholder={placeholderValue}
        {...restProps}
      />
      <label className={labelClass} styleName={"styles.searchLabel"}>{label}</label>
    </React.Fragment>

  );
};
export default OnlineSearch;
