import { createTypes } from "reduxsauce";

export default createTypes(
  `
    FETCH_PLL_STORE_SUCCESS
    FETCH_PLL_STORE_FAILURE
    UPDATE_STORE_LIST
    UPDATE_APPOINTMENT_LIST
    FETCH_APPOINTMENT_LIST_FAILURE
    FETCH_APPOINTMENT_LIST_SUCCESS
    FETCH_PLL_ASSOCIATES_SUCCESS
    FETCH_PLL_ASSOCIATES_FALIURE
    RESET_PLL_BY_KEY
    RESET_PLL
    UPDATE_PLL_STORE_LIST
    SET_PLL_LEAD_TYPE_FILTER
`,
  {
    prefix: "pllstorelist/"
  }
);
