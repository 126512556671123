import React from "react";

export const currentEnv = process.env.REACT_APP_HOST_ENV;

// export const configuration = () => {
//     if (currentEnv === 'PRODUCTION') {
//         return {
//             databaseURL: 'https://dealer-app-c3ec1-3a72c-prod.firebaseio.com'
//         };
//     } else {
//         return {
//             databaseURL: 'https://dealer-app-c3ec1-a936b-stage.firebaseio.com'
//         };
//     }
// };
export const configuration = () => {
  if (currentEnv === 'PRODUCTION') {
    return {
      apiKey: "AIzaSyCDpZkHsNjRxCGpYaYdme93e61NKRQGhng",
      authDomain: "lms-cars24-ac0f3-prod.firebaseapp.com",
      databaseURL: "https://lms-cars24-ac0f3-prod.firebaseio.com",
      projectId: "lms-cars24-ac0f3-prod",
      storageBucket: "lms-cars24.appspot.com",
      messagingSenderId: "831232663597",
      dbName: 'lms-cars24-ac0f3-prod'
    };
  } else if (currentEnv === 'STAGING') {
    return {
      apiKey: "AIzaSyCDpZkHsNjRxCGpYaYdme93e61NKRQGhng",
      authDomain: "lms-cars24-d9fc3-stage.firebaseapp.com",
      databaseURL: "https://lms-cars24-d9fc3-stage.firebaseio.com",
      projectId: "lms-cars24-d9fc3-stage",
      storageBucket: "lms-cars24.appspot.com",
      messagingSenderId: "831232663597",
      dbName: 'lms-cars24-d9fc3-stage'
    };
  }
  else {
    return {
      apiKey: "AIzaSyACqCKbpM8qgzY3rGVfBDBVP-frl0sUhEY",
      authDomain: "customerdashboardd.firebaseapp.com",
      databaseURL: "https://customerdashboardd.firebaseio.com",
      projectId: "customerdashboardd",
      storageBucket: "customerdashboardd.appspot.com",
      messagingSenderId: "10758281321",
      dbName: 'customerdashboardd'
    };
  }
};
