import React, { useState, useEffect } from "react";
import { hasTimeElapsed, getTimeDifference } from "../../utils/helper";
import { QC_STATUS, NUMBERS } from "../../utils/app-constants";
import styles from "./inspection-date-time.module.css";
import CountdownTimer from "../shared/countdown-timer";
import HourTimer from "../shared/hour-timer";
import MinuteTimer from "../shared/minute-timer";

const InspectionDateTime = ({ rowDetails, appointments }) => {

  const { qcStatus, auctionStatus, currentInspectionDate } = rowDetails;
  const { data } = appointments;
  const [hasElapsed, setHasElapsed] = useState(false);
  const [highlight, setHighlight] = useState(false);

  useEffect(() => {
    if (qcStatus === QC_STATUS.CRITICAL_REJECT) {
      setHasElapsed(true);
    } else if (auctionStatus === NUMBERS.ZERO) {
      let interval = setInterval(() => {
        const hasReachedLimit = hasTimeElapsed(currentInspectionDate, NUMBERS.TEN);
        if (hasReachedLimit) {
          // setHasElapsed(hasReachedLimit);
          clearInterval(interval);
          setHighlight(true);
          //updatePriorityCountByKeyConnect(APPOINTMENT_TYPES_STRING.INSPECTED);
        };
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className={`${styles.time} ${hasElapsed ? styles.lapse : ''} ${highlight ? styles.higlightTimer : ''}`}>
      <CountdownTimer direction="forward" initialTime={getTimeDifference(new Date(currentInspectionDate), new Date())}>
        <HourTimer /> {`hrs: `}
        <MinuteTimer /> min
      </CountdownTimer>
    </div>
  );
};

export default InspectionDateTime;
