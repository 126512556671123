import { createTypes } from "reduxsauce";

export default createTypes(
  `
    UPDATE_PAYMENT_PROCESSING_FILTER_QUERY
`,
  {
    prefix: "paymentprocessingfilter/"
  }
);
