import React from "react";
import PropTypes from "prop-types";
import styles from "./h4.module.css";

const H4 = (props) => {
  const { text } = props;
  let heading = (<h4 className={styles.heading}>{text}</h4>);

  return heading;
};

H4.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string
};

export default H4;