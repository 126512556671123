import React, { useEffect, useState } from "react";
import styles from "./ra-lead-card.module.scss";
import AccessTime from "../../common/images/access_time.svg";
import Angle from "../../common/images/right-angle.svg";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { APP_CONSTANTS } from "../../common/app-constants";
import BookInspection from "../book-inspection-popup";
import ModalDialogBox from "../modal-dialog-box";

const RaLeadCard = ({ data, onClose }) => {
	const [ctaText, setCtaText] = useState("");
	const [leadStatus, setLeadStatus] = useState("Lead incomplete");
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [appointmentStatus, setAppointmentStatus] = useState("");
	const history = useHistory();

	useEffect(() => {
		if (data) {
			if (
				!!data.isPurchased &&
				data.isPurchased.toLowerCase() === APP_CONSTANTS.YES
			) {
				setLeadStatus(APP_CONSTANTS.LEAD_PROCURED);
			} else if (
				!!data.isInspected &&
				data.isInspected.toLowerCase() === APP_CONSTANTS.YES
			) {
				setLeadStatus(APP_CONSTANTS.LEAD_INSPECTION_BOOKED);
				setCtaText(APP_CONSTANTS.CONTACT_DEALER);
			} else if (!data.isInspected && !!data.pubApptId) {
				setLeadStatus(APP_CONSTANTS.INSPECTION_IN_PROGRESS);
				setCtaText(APP_CONSTANTS.INSPECTION_IN_PROGRESS);
			} else if (
				!!data.offerPriceStatus &&
				data.offerPriceStatus.toUpperCase() ===
					APP_CONSTANTS.ACCEPTED.toUpperCase()
			) {
				setLeadStatus(APP_CONSTANTS.LEAD_PRICE_ACCEPTED);
				setCtaText(APP_CONSTANTS.BOOK_INSPECTION);
			} else if (
				!!data.offerPriceStatus &&
				data.offerPriceStatus.toUpperCase() ===
					APP_CONSTANTS.REJECTED.toUpperCase()
			) {
				setLeadStatus(APP_CONSTANTS.LEAD_PRICE_REJECTED);
				setCtaText(APP_CONSTANTS.REVISE_PRICE);
			} else if (!!data.pubApptId && !data.offerPriceStatus) {
				setLeadStatus(APP_CONSTANTS.LEAD_PENDING_PRICE_ACCEPTANCE);
				setCtaText(APP_CONSTANTS.SEND_PRICE);
			} else {
				setLeadStatus(APP_CONSTANTS.LEAD_INCOMPLETE);
				setCtaText(APP_CONSTANTS.RESUME);
			}
		}
	}, [data]);

	const onLeadClick = () => {
		if (
			[
				APP_CONSTANTS.RESUME,
				APP_CONSTANTS.SEND_PRICE,
				APP_CONSTANTS.INSPECTION_IN_PROGRESS,
			].includes(ctaText)
		) {
			onClose();
			history.push({
				pathname: `/${APP_CONSTANTS.appType}/lead/detail/${data?.token}`,
				state: ctaText,
			});
		} else if (ctaText === APP_CONSTANTS.CONTACT_DEALER) {
			window.open(`tel:${data?.ucmPhone}`);
		} else if (ctaText === APP_CONSTANTS.BOOK_INSPECTION) {
			setAppointmentStatus(APP_CONSTANTS.BOOK_INSPECTION);
			setIsModalOpen(true);
		}
	};
	const onRescheduleAppointment = () => {
		setAppointmentStatus(APP_CONSTANTS.RESCHEDULE_APPOINETMENT);
		setIsModalOpen(true);
	};
	const onGoBack = (isOpen) => {
		setIsModalOpen(isOpen);
	};

	return (
		<div className={styles.leadCard}>
			<ul>
				<li className={`${styles.detailsIcon} ${styles.leadTitle}`}>
					<p>{data?.ucmName}</p>
				</li>
				<li>
					<div className={styles.detailsIcon}>
						<img src={AccessTime} alt="time" />
						<p>{`Lead created on ${moment(data.createdAt).format("LT ll")}`}</p>
					</div>
				</li>
				<li className={styles.actionWrapper}>
					<div className={styles.detailsInfo}>
						{`${data?.year} ${data?.makeDisplay} ${data?.modelDisplay} ${data?.variantDisplayName}`}
					</div>
				</li>
				<li className={styles.actionWrapper}>
					<div className={styles.actionHeader}>
						<p>{"Registration No:"}</p>
					</div>
					<div className={styles.actionHeader}>
						<p>{`${data?.vehicleRegNo || "NA"}`}</p>
					</div>
				</li>
				<li className={styles.actionWrapper}>
					<div className={styles.actionHeader}>
						<p>{"Created By:"}</p>
					</div>
					<div className={styles.actionHeader}>
						<p>{`${data?.createdByUserEmail || "NA"}`}</p>
					</div>
				</li>
				<li className={styles.actionWrapper}>
					<div className={styles.actionHeader}>
						<span
							className={
								leadStatus === APP_CONSTANTS.LEAD_PRICE_ACCEPTED
									? styles.accepted
									: leadStatus === APP_CONSTANTS.LEAD_PRICE_REJECTED
									? styles.rejected
									: ""
							}>
							{leadStatus}
						</span>
					</div>
					<div className={styles.action} onClick={() => onLeadClick()}>
						<span>{ctaText} </span>
						{[
							APP_CONSTANTS.RESUME,
							APP_CONSTANTS.SEND_PRICE,
							APP_CONSTANTS.CONTACT_DEALER,
							APP_CONSTANTS.BOOK_INSPECTION,
							APP_CONSTANTS.INSPECTION_IN_PROGRESS,
						].includes(ctaText) && <img src={Angle} alt="icon" />}
					</div>
				</li>
				{leadStatus === APP_CONSTANTS.INSPECTION_IN_PROGRESS && (
					<li className={styles.actionWrapper}>
						<div className={styles.actionHeader}></div>
						<div
							className={styles.action}
							onClick={() => onRescheduleAppointment()}>
							<span>{APP_CONSTANTS.RESCHEDULE_APPOINETMENT} </span>
							<img src={Angle} alt="icon" />
						</div>
					</li>
				)}
				{leadStatus === APP_CONSTANTS.LEAD_PRICE_REJECTED && (
					<li>
						<div className={`${styles.rejectionReason} ${styles.active}`}>
							<div className={styles.rejectionReasonText}>
								<div>
									<p>Rejection Reason</p>
								</div>
							</div>
							<h4>{data?.offerPriceDenyReason}</h4>
						</div>
					</li>
				)}
			</ul>
			<div>
				{isModalOpen && (
					<ModalDialogBox title={appointmentStatus}>
						<BookInspection
							rowDetails={data}
							onGoBack={onGoBack}
							appointmentStatus={appointmentStatus}
						/>
					</ModalDialogBox>
				)}
			</div>
		</div>
	);
};

export default RaLeadCard;
