import React from "react";
import { NOT_AVAILABLE} from "../../utils/app-constants";
const Disposition = ({rowDetails}) => {
  const { disposition } = rowDetails;

  return (
    <span >{disposition ? disposition:NOT_AVAILABLE}</span>
  );
};

export default Disposition;
