import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { paiRequest, setCurrentPopup } from "../negotiation-list/actions";
import RaisePaiPopup from "./component";

const mapStateToProps = ({ negotiations }) => ({ negotiations });

const mapDispatchToProps = (dispatch) => bindActionCreators({
  paiRequestConnect: paiRequest,
  setCurrentPopupConnect: setCurrentPopup
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RaisePaiPopup);
