import { createTypes } from "reduxsauce";

export default createTypes(
  `
    FETCH_CUSTOMER_CHARGES_SUCCESS
    FETCH_CUSTOMER_CHARGES_FAILURE
`,
  {
    prefix: "customeroffer/"
  }
);
