import Types from "./types";

const updateLeadSort = (value) => ({
  type: Types.SET_LEAD_SORT_DIRECTION,
  value
});

const UpdateLeadFilterQuery = (query) => ({
  type: Types.SET_LEAD_FILTER,
  query
});

export {
  updateLeadSort,
  UpdateLeadFilterQuery,
};
