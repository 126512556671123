import Types from "./types";
import { AppointmentService } from "../../service";
// import { getCustomerChargesNewMoc } from "../../utils/mocApi";

const fetchCustomerChargesDetails = (appointmentId, c24Quote) => dispatch => {
  return AppointmentService.getCustomerCharges(appointmentId, c24Quote)
    .then(response => {
      // response = getCustomerChargesNewMoc();
      const { data: { detail } } = response;
      dispatch(fetchCustomerChargesSuccess(detail));
      return response;
    })
    .catch((error) => {
      dispatch(fetchCustomerChargesFailure(error));
    });
};

const fetchCustomerChargesSuccess = (data) => ({
  type: Types.FETCH_CUSTOMER_CHARGES_SUCCESS,
  data
});
const fetchCustomerChargesFailure = (data) => ({
  type: Types.FETCH_CUSTOMER_CHARGES_FAILURE,
  data
});

export {
  fetchCustomerChargesDetails
};
