import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PaymentInitiateToken from "./component";
import { fetchRaTokenData } from "./actions";
import withLayout from "../../components/layout/component";

const mapStateToProps = ({
  paymentInitiateToken
}) => ({ paymentInitiateToken });

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchRaTokenDataConnect: fetchRaTokenData,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withLayout(PaymentInitiateToken, false));
