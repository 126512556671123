import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PaymentProcessingFilter from "./component";
import {updatepaymentProcessingFilterQuery} from "./actions";

const mapStateToProps = ({
  paymentProcessingFilter
}
) => ({
  paymentProcessingFilter
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updatepaymentProcessingFilterQueryConnect: updatepaymentProcessingFilterQuery
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PaymentProcessingFilter);
