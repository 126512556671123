import React from "react";
import H2 from "../h2/component";
import styles from "./styles.module.scss";
import History from "./images/history.svg";
import Trending from "./images/trending_up.svg";
import Done from "./images/done.svg";
import Warning from "./images/warning_amber.svg";
import priceFormatter from "../../utils/helpers/price-formatter";
import { getPrice } from "../../utils/helper";

const CATEGORY_KEYS = {
  MARKET_PRICE: "current_market_price",
  ODOMETER: "age_odo",
  OWNER_NUMBER: "owner_number",
  CAR_CONDITION: "car_condition",
  DOCUMENTS: "documents",
  PAST_DAMAGES: "past_damages",
};

const PRICE_EXPLORER_TYPES = {
  NEGATIVE: "Negative",
  POSITIVE: "Positive"
};

const CarDetailsPopup = (props) => {
  const {priceBreakupData, data} = props;
  const {priceWaterfallCategory,priceWaterfall, sellOnlineQuestions} = priceBreakupData;
  const priceCategories = [{"key": "current_market_price", "label": "Current market price", "displayOrder": 0}, ...priceWaterfallCategory];
  const isPriceNegative = (priceExplorerData) => {
    const priceExplorers = !!priceExplorerData && !!priceExplorerData?.price_explainer && Object.values(priceExplorerData?.price_explainer).filter((item)=> item === PRICE_EXPLORER_TYPES.NEGATIVE);
    return !!priceExplorers && priceExplorers.length > 0 ? true : false;
  };
  const displayCategory = (item) => {
    switch (item.key) {
      case CATEGORY_KEYS.MARKET_PRICE:
        const {average_price, onroad_price} = priceWaterfall;
        return (
          <div className={styles.dataGrid}>
            <div className={styles.heading}>
              <span>{item.label}</span>
              <h4 className={average_price > 0 ? styles.green : styles.red}>₹ {getPrice(average_price, true)}</h4>
            </div>
            {!!onroad_price && <div className={styles.onRoadPriceStrip}>
              <span>{`On-road price in ${data.year}`}</span>
              <span>₹ {onroad_price} </span>
            </div>}
            <p className={styles.text}>Current market price is generated after considering factors below</p>
            <ul className={styles.list}>
              <li>
                <img src={History} alt="History"/>Standard depreciation
              </li>
              <li>
                <img src={Trending} alt="Trending"/>Market demand
              </li>
            </ul>
          </div>
        );
      case CATEGORY_KEYS.ODOMETER:
        const odoData = priceWaterfall[CATEGORY_KEYS.ODOMETER];
        const odoMetaData = !!sellOnlineQuestions && sellOnlineQuestions.find((item)=> item.question ==="odometer_reading");
        return (
          <div className={styles.dataGrid}>
            <div className={styles.heading}>
              <span>{item.label}</span>
              <h4 className={odoData?.price_break > 0 ? styles.green : styles.red}>₹ {getPrice(odoData?.price_break, true)}</h4>
            </div>
            <ul className={styles.list}>
              <li>
                <img src={Done} alt="Done"/>{odoMetaData?.value} Kms
              </li>
            </ul>
            <div className={styles.text}
              dangerouslySetInnerHTML={{__html: isPriceNegative(odoData) ? item.negativeText : item.positiveText}}
            />
          </div>
        );
      case CATEGORY_KEYS.OWNER_NUMBER:
        const ownerData = priceWaterfall[CATEGORY_KEYS.OWNER_NUMBER];
        const metaData = !!sellOnlineQuestions && sellOnlineQuestions.find((item)=> item.question === CATEGORY_KEYS.OWNER_NUMBER);
        return (
          <div className={styles.dataGrid}>
            <div className={styles.heading}>
              <span>{item.label}</span>
              <h4 className={ownerData?.price_break > 0 ? styles.green : styles.red}>₹ {getPrice(ownerData?.price_break, true)}</h4>
            </div>
            <ul className={styles.list}>
              <li>
                <img src={Warning} alt="Warning"/>{metaData?.label}
              </li>
            </ul>
            <p className={styles.text}>{ownerData?.price_explainer?.owner_number === PRICE_EXPLORER_TYPES.POSITIVE ? item.positiveText: item.negativeText}</p>
          </div>
        );
      case CATEGORY_KEYS.CAR_CONDITION:
        const carData = priceWaterfall[CATEGORY_KEYS.CAR_CONDITION];
            
        return (
          <div className={styles.dataGrid}>
            <div className={styles.heading}>
              <span>{item.label}</span>
              <h4 className={carData?.price_break > 0 ? styles.green : styles.red}>₹ {getPrice(carData?.price_break, true)}</h4>
            </div>
            <ul className={styles.list}>
              {Object.keys(carData?.price_explainer).map((key)=> {
                const carMetaData = !!sellOnlineQuestions && sellOnlineQuestions.find((item)=> item.question === key);
                return (
                  <li>
                    <img src={carData?.price_explainer[key] === PRICE_EXPLORER_TYPES.POSITIVE ? Done : Warning} alt="Warning"/>{`${carMetaData?.question_description || key} : ${carMetaData?.label || 'NA'}`}
                  </li>
                );
              })
              }
            </ul>
            <div className={styles.text}
              dangerouslySetInnerHTML={{__html: isPriceNegative(carData) ? item.negativeText : item.positiveText}}
            />
          </div>
        );
      case CATEGORY_KEYS.DOCUMENTS:
        const docData = priceWaterfall[CATEGORY_KEYS.DOCUMENTS];
        return (
          <div className={styles.dataGrid}>
            <div className={styles.heading}>
              <span>{item.label}</span>
              <h4 className={docData?.price_break > 0 ? styles.green : styles.red}>₹ {getPrice(docData?.price_break, true)}</h4>
            </div>
            <ul className={styles.list}>
              {Object.keys(docData?.price_explainer).map((key)=> {
                const docsMetaData = !!sellOnlineQuestions && sellOnlineQuestions.find((item)=> item.question === key);
                return (
                  <li>
                    <img src={docData?.price_explainer[key] === "Positive" ? Done : Warning} alt="Warning"/>{`${docsMetaData?.question_description || key} : ${docsMetaData?.label || 'NA'}`}
                  </li>
                );
              })
              }
            </ul>
            <div className={styles.text}
              dangerouslySetInnerHTML={{__html: isPriceNegative(docData) ? item.negativeText : item.positiveText}}
            />
          </div>
        );
      case CATEGORY_KEYS.PAST_DAMAGES:
        const damageData = priceWaterfall[CATEGORY_KEYS.PAST_DAMAGES];
        return (
          <div className={styles.dataGrid}>
            <div className={styles.heading}>
              <span>{item.label}</span>
              <h4 className={damageData?.price_break > 0 ? styles.green : styles.red}>₹ {getPrice(damageData?.price_break, true)}</h4>
            </div>
            <ul className={styles.list}>
              {Object.keys(damageData?.price_explainer).map((key)=> {
                const damagesMetaData = !!sellOnlineQuestions && sellOnlineQuestions.find((item)=> item.question === key);
                return (
                  <li>
                    <img src={damageData?.price_explainer[key] === "Positive" ? Done : Warning} alt="Warning"/>{`${damagesMetaData?.question_description || key} : ${damagesMetaData?.label || 'NA'}`}
                  </li>
                );
              })
              }
            </ul>
            <div className={styles.text}
              dangerouslySetInnerHTML={{__html: isPriceNegative(damageData) ? item.negativeText : item.positiveText}}
            />
          </div>
        );
      default:
        return (
          <div className={styles.dataGrid}>
            <div className={styles.heading}>
              <span>{'default'}</span>
              <h4 className={styles.red}>₹ {getPrice(0, true)}</h4>
            </div>
          </div>
        );
    }
  };
  return (
    <div className={styles.carDetailsPopup}>
      <H2 text={"Car Details"} />
      <div className={styles.carHeader}>
        <div className={styles.carName}>
          <span>{`${data.make} ${data.model}`}</span>
          <span>{`${data.variant} | ${data.year}`}</span>
        </div>
        <div className={styles.priceSection}>
          <span>Our Price</span>
          <h4>₹ {priceFormatter(data.sellerOfferPrice)}</h4>
        </div>
      </div>
      <h3 className={styles.title}>price rational</h3>
      <div className={styles.overflowWrapper}>
        <div className={styles.dataGridWapper}>
          <div className={styles.gridWrapper}>
            <div className={styles.leftGrid}>
              {priceCategories.map((item)=> {
                if (["current_market_price","car_condition"].includes(item.key)) {
                  return displayCategory(item);
                }
              })}
            </div>
            <div className={styles.rightGrid}>
              {priceCategories.map((item)=> {
                if (["owner_number", "age_odo", "documents", "past_damages"].includes(item.key)) {
                  return displayCategory(item);
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarDetailsPopup;
