export default (api) => {
  const getGeoStoreSlot = ({lat,lng,pincode,token}) => {
    return new Promise((resolve, reject) => {
      api.get(`/get-geo-store-slot?lat=${lat}&lng=${lng}&pincode=${pincode}&token=${token}&inspection_type=pns`).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  };

  return {
    getGeoStoreSlot
  };
};