import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Login from "./component";
import { setSelectedStores } from "./actions";
import { fetchUserMenus } from "../../components/sidebar/actions";
const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setSelectedStoresConnect: setSelectedStores,
  fetchUserMenusConnect: fetchUserMenus,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
