/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { useHistory} from "react-router-dom";
import styles from "./login.module.css";
import LoginBG from "./images/login-bg.svg";
import { useSession, useUser,useDescope } from "@descope/react-sdk";
import { LOCAL_STORAGE_KEYS, APP_SOURCE, APPOINTMENT_TYPES_STRING,
  ROLE_KEYS,
  MAP_TYPE,
  LEAD_TYPE, } from "../../utils/app-constants";
import { saveToLocalStorage, getHomePageURL,
  isBikeSource, } from "../../utils/helper";
import DescopeSignInWidget from "./components/DescopeSignInWidget";
import { AuthService, AppointmentService } from "../../service";
import { getSessionToken } from "@descope/react-sdk";
const Login = ({ location, fetchUserMenusConnect, setSelectedStoresConnect }) => {
  const { state } = location;
  const [loginErrorMessage, setLoginErrorMessage] = useState();
  const history = useHistory();
  const { isSessionLoading } = useSession();
  const { isUserLoading, user } = useUser();
  const { logout } = useDescope();
  const isBike = isBikeSource();
  useEffect(() => {
    saveToLocalStorage(
      LOCAL_STORAGE_KEYS.SELECTED_BASE_API_HOST,
      APP_SOURCE.CAR
    );
  }, []);

  const setLeadTypeByRole = (role) => {
    if (role.toLowerCase() === ROLE_KEYS.RA)
      saveToLocalStorage(LOCAL_STORAGE_KEYS.LEADS, LEAD_TYPE.INDIVIDUAL);
    else
      saveToLocalStorage(LOCAL_STORAGE_KEYS.LEADS, LEAD_TYPE.STORE);
  };

  const setCJList = (storeId) => {
    AppointmentService.getEvaluatorList(storeId)
      .then(response => {
        const { data: { detail } } = response;
        saveToLocalStorage(LOCAL_STORAGE_KEYS.CJ_LIST, JSON.stringify(detail));
      }).catch(error => {
        console.log({error});
      });
  };
  
  const setBikeRAList = () => {
    AppointmentService.getBikeAPIRAList()
      .then(response => {
        const { data: { detail } } = response;
        saveToLocalStorage(LOCAL_STORAGE_KEYS.BIKE_RA_LIST, JSON.stringify(detail));
      }).catch(error => {
        console.log("API error:", error);
      });
  };

  const setSelectedStoresByRole = ({
    role, 
    isHierarchy = false, 
    idLmsStore, 
  }) => {
    AppointmentService.getStoreList()
      .then(response => {
        const { data: { detail } } = response;
        if (!!detail && detail.length > 0) {
          setCJList(detail[0].idLmsStore);
        }
        if (!isHierarchy) {
          if (role.toLowerCase() === ROLE_KEYS.RA) {
            saveToLocalStorage(LOCAL_STORAGE_KEYS.SELECTED_STORES, idLmsStore);
            setSelectedStoresConnect(idLmsStore);
          }
          else {
            const selectedStores = detail.map(item => item.idLmsStore);
            saveToLocalStorage(LOCAL_STORAGE_KEYS.SELECTED_STORES, selectedStores.join(","));
            setSelectedStoresConnect(selectedStores.join(","));
          }
        }
        if (role.toLowerCase() === ROLE_KEYS.RA) {
          saveToLocalStorage(LOCAL_STORAGE_KEYS.SELECTED_STORES, idLmsStore);
          setSelectedStoresConnect(idLmsStore);
        }
        else {
          const selectedStores = detail.map(item => item.idLmsStore);
          saveToLocalStorage(LOCAL_STORAGE_KEYS.SELECTED_STORES, selectedStores.join(","));
          setSelectedStoresConnect(selectedStores.join(","));
        }
        saveToLocalStorage(LOCAL_STORAGE_KEYS.CITY_STORES_LIST, JSON.stringify(detail));
        saveToLocalStorage(LOCAL_STORAGE_KEYS.SELECTED_CITIES, JSON.stringify(detail));
        if (role.toLowerCase() === ROLE_KEYS.HOSTESS) {
          history.push(`/check-in`);
        }
        else if(isBike){
          history.push(`/${APPOINTMENT_TYPES_STRING.INSPECTED}`);
        }
        else {
          history.push(`/${getHomePageURL()}`);
        }
      }).catch(error => {
        console.log({error});
        history.push(`/${getHomePageURL()}`);
      }).finally(()=>{
        window.location.reload();
	  });
  };

  const navigateByRole = (data) => {
    const { isHierarchy, role, uid, idLmsStore, fname, lname, mmiEnable} = data;
    const mapType = mmiEnable ? MAP_TYPE.MMI : MAP_TYPE.GOOGLE;
    if (role) {
      !isHierarchy && setLeadTypeByRole(role);
      setLeadTypeByRole(role); //disable temp todo
      if (role.toLowerCase() === ROLE_KEYS.HOSTESS) {
        saveToLocalStorage(LOCAL_STORAGE_KEYS.INDIVIDUAL_ID, uid);
        saveToLocalStorage(LOCAL_STORAGE_KEYS.INDIVIDUAL_NAME, `${fname} ${lname}`);
        setSelectedStoresByRole({role, isHierarchy, idLmsStore});
      } else {
        saveToLocalStorage(LOCAL_STORAGE_KEYS.INDIVIDUAL_ID, uid);
        saveToLocalStorage(LOCAL_STORAGE_KEYS.INDIVIDUAL_NAME, `${fname} ${lname}`);
        setSelectedStoresByRole({role, isHierarchy, idLmsStore});
        saveToLocalStorage(LOCAL_STORAGE_KEYS.MAP_TYPE_ACTIVE, mapType);
      }
    } else {
      let message = 'No mapped role found';
      setLoginErrorMessage(message);
      localStorage.clear();
      history.push({pathname: '/login', state: { loginErrorMessage: message }});
    }
  };
  const getLoginUserMenuHeaders = (data) => {
    const payload = {
      role: data.originalRole, 
      permission: 0
    };
    fetchUserMenusConnect(payload).then(()=> {
      navigateByRole(data);
    });
  };

  useEffect(()=>{
    if (isSessionLoading || isUserLoading) {
      return <p>Loading...</p>;
	  }

    if (!!user) {
		const token = getSessionToken();
		saveToLocalStorage(LOCAL_STORAGE_KEYS.LMS_ACCESS_TOKEN, token);
		const params = {
			email: user?.email,
			id_token: token,
		};
		AuthService.googleLogin(params).then(resp => {
			const {
				data: {
				detail: { data },
				},
			} = resp;
			saveToLocalStorage(
				LOCAL_STORAGE_KEYS.LOGIN_DATA,
				JSON.stringify(data)
			);
			loginErrorMessage && setLoginErrorMessage(null);
			getLoginUserMenuHeaders(data);
			}).catch(error => {
			const {
				detail: { message = '' } = {},
			} = error;
			setLoginErrorMessage(message);
			logout();
			localStorage.clear();
			history.push({pathname: '/login', state: { loginErrorMessage: message }});
			});
    }
  },[isSessionLoading, isUserLoading, user]);

  useEffect(() => {
    saveToLocalStorage(
      LOCAL_STORAGE_KEYS.SELECTED_BASE_API_HOST,
      APP_SOURCE.CAR
    );
    state?.loginErrorMessage && setLoginErrorMessage(state.loginErrorMessage);
  }, []);

  const onSuccess = (e) => {
    console.log('Login successful');
  };

  const onError = () => {
    setLoginErrorMessage("Error Login In");
  };

  return (
    <>
      <div className="container-fluid">
        <div className={styles.loginOuter}>
          <div className={styles.loginWrapper}>
            <div className={styles.loginForm}>
              <DescopeSignInWidget onSuccess={onSuccess} onError={onError}/>
			  
            </div>
            <div className={styles.loginImg}>
              <img src={LoginBG} alt="login" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
