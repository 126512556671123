import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import NotificationsList from "./component";
import { resetSearch } from "../search/actions";
import { resetGlobalSearchByKey } from "../global-search/actions";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  resetGlobalSearchByKeyConnect: resetGlobalSearchByKey,
  resetSearchConnect: resetSearch
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsList);
