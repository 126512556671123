import React from "react";
import { Link } from "react-router-dom";
import styles from "./progress-step.module.scss";
import { APPOINTMENT_TYPES_STRING, LEAD_PROGRESS_STEP } from "../../utils/app-constants";

const ProgressStep = ({ data }) => {
  const { state_name, pre_state_name } = data;
  const isLostLead = state_name.trim().toLowerCase() === APPOINTMENT_TYPES_STRING.LOST;
  const currentState = isLostLead ? (pre_state_name ? pre_state_name.trim().toLowerCase() : "") : state_name.trim().toLowerCase();

  const getMaxIndex = () => {
    const item = LEAD_PROGRESS_STEP.find(x => x.value === currentState);
    if (item) {
      return LEAD_PROGRESS_STEP.indexOf(item);
    } else {
      return -1;
    }
  };

  const maxIndex = getMaxIndex();

  const getStepClass = (index) => {
    if (maxIndex === -1) {
      return "";
    } else {
      const classType = isLostLead ? styles.lost : styles.active;
      if (index <= maxIndex)
        return classType;
      else
        return "";
    }
  };

  return (
    <div className={styles.progressStep}>
      <ul>
        {
          LEAD_PROGRESS_STEP.map((item, index) => {
            return (
              <li key={item.value}>
                <Link className={getStepClass(index)} to="#">{item.label}</Link>
              </li>
            );
          })
        }
      </ul>
    </div>
  );
};

export default ProgressStep;
