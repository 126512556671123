import React, {useEffect, useState, useRef} from "react";
import styles from "./cj-planning.module.scss";
import { PAGE_HEADING, NUMBERS} from "../../utils/app-constants";
import H2 from "../../components/h2";
import StickyComponent from "../../components/shared/sticky-component";
import ToggleArrow from "../../components/images/toggleArrow.svg";
import PreArrow from "../../components/images/nextPreArrow.svg";
import NextArrow from "../../components/images/nextPreArrow.svg";
import CjPlanningCard from "../../components/cj-planning-card";
// import Datepicker from "../shared/datepicker/component";
import Datepicker from "../../components/shared/datepicker/component";
const CjPlanning = ({}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [curnentDate, setCurnentDate] = useState(new Date());
  return (
    <div className={styles.cjPlankng}>
      <StickyComponent topOffset={NUMBERS.MINUS_SIXTY} classes="freezeHeader" >
        <div className={styles.headingSearchWrapper}>
          <div className={styles.toggleSection}>
            <div className={styles.arrowCalendar}>
              <img onClick={() => setIsOpen(!isOpen)} src={ToggleArrow}  alt="arrow" />
              <Datepicker 
                name="subDisposition" 
                // placeholder="December 2021"
                onChange={()=>alert("test")}
                selected={curnentDate}
              />
            </div>
            <div className={styles.nextPrev}>
              <img src={PreArrow} alt="NextArrow" title="Previous" />
              <img className={styles.prev}src={NextArrow} alt="NextArrow" title="Next" />
            </div>
          </div>
        </div>
      </StickyComponent>
      <div className={styles.cjPlanningWrapper}>
        {isOpen && (
          <div className={styles.cjPlanningData}>
            <div className={styles.slotsWrapper}>
              <span>Slots</span>
              <span>Verified Appointments:</span>
              <span>Evaluators:</span>
              <span>Slot Occupancy</span>
              <span>Appointment/CJ</span>
            </div>
            <div className={styles.cjDataWrapper}>
              <div className={styles.cjData}>
                <h3 className={styles.active}>20th Dec</h3>
                <h4 className={styles.active}>Mon</h4>
                <span>52</span>
                <span>52</span>
                <span>52</span>
                <span className={styles.leave}>52</span>
                <span>52</span>
              </div>
              <div className={styles.cjData}>
                <h3>20th Dec</h3>
                <h4>Mon</h4>
                <span>52</span>
                <span>52</span>
                <span>52</span>
                <span>52</span>
                <span>52</span>
              </div>
              <div className={styles.cjData}>
                <h3>20th Dec</h3>
                <h4>Mon</h4>
                <span>52</span>
                <span className={styles.leave}>52</span>
                <span>52</span>
                <span>52</span>
                <span>52</span>
              </div>
              <div className={styles.cjData}>
                <h3>20th Dec</h3>
                <h4>Mon</h4>
                <span>52</span>
                <span>52</span>
                <span>52</span>
                <span>52</span>
                <span>52</span>
              </div>
              <div className={styles.cjData}>
                <h3>20th Dec</h3>
                <h4>Mon</h4>
                <span>52</span>
                <span>52</span>
                <span>52</span>
                <span>52</span>
                <span className={styles.leave}>52</span>
              </div>
              <div className={styles.cjData}>
                <h3>20th Dec</h3>
                <h4>Mon</h4>
                <span>52</span>
                <span>52</span>
                <span>52</span>
                <span>52</span>
                <span>52</span>
              </div>
              <div className={styles.cjData}>
                <h3>20th Dec</h3>
                <h4>Mon</h4>
                <span>52</span>
                <span>52</span>
                <span>52</span>
                <span>52</span>
                <span>52</span>
              </div>
            </div>
          </div>
        )}
        <CjPlanningCard />
      </div>
    </div>
  );
};

export default CjPlanning;
