import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { LoadingLeadDetail, resetSaleonlineQuestions, updateAnsweredQuestions, updateOfferPrice } from "../../pages/lead-detail/actions";
import SaleonlineQuestions from "./component";

const mapStateToProps = ({
  lead: {
    isLoading,
    leadDetail,
    saleonlineQuestions,
    userResponse,
  }
}) => ({
  isLoading,
  leadDetail,
  userResponse,
  saleonlineQuestions,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  UpdateAnsweredQuestionsConnect: updateAnsweredQuestions,
  LoadingLeadDetailConnect: LoadingLeadDetail,
  ResetSaleonlineQuestionsConnect: resetSaleonlineQuestions,
  UpdateOfferPriceConnect: updateOfferPrice,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SaleonlineQuestions);
