import React from "react";
import styles from "./self-book-appointment.module.scss";
import StepAway from "../../components/images/stepAway.svg";
import Button from "../../components/shared/button";

const SelfBookAppointment = () => {

  const Redirect = () => {
    window.open("https://www.cars24.com");
  };
  return (
    <div className={styles.checkinWrapper}>
      <div className={styles.mainImage}>
        <img src={StepAway} alt="StepAway" />
      </div>
      <p className={styles.checkinText}>You’re just one step away from selling your car!</p>
      <p className={styles.subText}>Book appointment and get your car inspected for an instant offer.</p>
      <div className={styles.selfCta}>
        {/* <Button ctaText="Book appointment" classNames="selfCheckinCta" onClick={() => Redirect()} /> */}
        <a href="https://www.cars24.com" classNames="selfCheckinCta">Book appointment</a>
      </div>
    </div>
  );
};

export default SelfBookAppointment;
