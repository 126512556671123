import React, { useEffect, useState } from "react";
import styles from "./customer-offer-price.module.scss";
import offerLogo from "../../components/images/offerLogo.svg";
import PriceOfferLogo from "../../components/images/offerPriceLogo.svg";
import CustomerPartnersViewed from "../../components/customer-partners-viewed";
import CustomerOffer from "../../components/customer-offer";
import CustomerYoursCars from "../../components/customer-yours-cars";
import CustomerSimilarCars from "../../components/customer-similar-cars";
import CustomerWhySell from "../../components/customer-why-sell";
import paiInfo from "../../utils/raise-pai/pai-info";
import calculateProbablity, { PROBABLITY_TYPES } from "../../utils/raise-pai/calculate-probablity";
import PaiSlider from "../../components/pai-slider";
import priceFormatter from "../../utils/helpers/price-formatter";
import { AppointmentService } from "../../service";
import { showToastMessages, isPllUser, getFromLocalStorage } from "../../utils/helper";
import { LOCAL_STORAGE_KEYS } from "../../utils/app-constants";

const CustomerOfferPrice = ({
  match,
  resetLeadDetailsConnect,
  preCheckRaisePAIConnect,
  negotiations,
  fetchNegotiationsConnect,
  fetchCustomerOfferDetailsConnect,
  customerOffer
}) => {
  const { params: { appointmentId } } = match;
  // const { isLoading, data, error } = leadDetails;
  const { customerOffer: { c24Quote } } = customerOffer;
  const [negoData, setNegoData] = useState({});
  const [calculatedPaiInfo, setCalculatedPaiInfo] = useState({});
  const [probablityType, setProbablityType] = useState(null);
  const { data } = negotiations;
  const {
    showSlider,
    domain,
    sliderData,
    priceAcceptance,
    defaultValues,
    step,
    optimalIncrease
  } = calculatedPaiInfo;
  const [values, setValues] = useState((defaultValues || []).slice());
  const [update, setUpdate] = useState((defaultValues || []).slice());
  const [componentShow, setComponentShow] = useState(false);
  let isPll = isPllUser();
  useEffect(() => {
    if (appointmentId) {
      fetchCustomerOfferDetailsConnect(appointmentId).catch((err)=>{
        console.log({err});
      });
      // fetchLeadDetailsConnect(appointmentId);
      fetchNegotiationsConnect(1, appointmentId, null, null, null)
        .then(resp => {
          if (resp.data) {
            const { data: { detail: { data: { result } } } } = resp;
            if (result.length > 0) {
              const negoData = result.find(negotiation => negotiation.appointmentId === appointmentId);
              if (negoData) {
                setNegoData(negoData);
              }
            }
          }
        })
        .catch(error => console.log(error));
      AppointmentService.getSimilarTransactedCars(appointmentId)
        .then(response => {
          const { data: { detail } } = response;
          if (!!detail && detail.length < 2) {
            setComponentShow(false);
          }
          else {
            setComponentShow(true);
          }
        })
        .catch(error => {
          console.log('Error fetching getSimilarTransactedCars', error);
        });
    }
    return () => {
      resetLeadDetailsConnect();
    };
  }, [appointmentId]);

  const onUpdateSlider = update => {
    const currentValue = update[0];
    setProbablityType(calculateProbablity(priceAcceptance, currentValue - negoData.c24Quote, sliderData));
    setUpdate(update);
  };

  const onChangeSlider = values => {
    setValues(values);
  };

  useEffect(() => {
    if (showSlider) {
      setProbablityType(calculateProbablity(priceAcceptance, optimalIncrease, sliderData));
      setValues(defaultValues.slice());
      setUpdate(defaultValues.slice());
    }
  }, [showSlider]);
  const onClickQuotePrice = () => {
    // setIsLoading(true);
    const loginData = JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA) || "null");
    if (!loginData && !loginData.email) { showToastMessages("Please login again.", false); }
    // const payload = {
    //     "appointment_id": appointmentId,
    //     "carId": negoData.carId,
    //     "auction_id": negoData.auctionId,
    //     "store_id": negoData.storeId,
    //     "current_c24quote": negoData.calculatedc24Quote, "requested_c24quote": 0,
    //     "userEmail": loginData.email,
    //     "request_type":"RAISE_PAI",
    //     "is_pll": isPll,
    //     "c24Diff":0,
    //     "probability":"",
    //     "reqCheck": "paiRequest",
    //     "customerPage": true
    // };
    const payload = {
      "appointment_id": appointmentId,
      "carId": negoData.carId,
      "auction_id": negoData.auctionId,
      "store_id": negoData.storeId,
      "c24quote": negoData.calculatedc24Quote,
      "requested_c24quote": 0,
      "userEmail": loginData.email,
      "request_type": "RAISE_PAI",
      "is_pll": isPll,
      "c24Diff": 0,
      "probability": "",
      "reqCheck": "paiRequest",
      "customerPage": true
    };
    preCheckRaisePAIConnect(payload).then(() => {
      // setIsLoading(false);
      setCalculatedPaiInfo(paiInfo(negotiations, 1000, true));
    }).catch(({ error }) => {
      // showToastMessages(message, false);
      // setIsLoading(false);
    });
  };
  return (
    <>
      <div className={styles.logoHeader}>
        <div className={styles.wrapper}>
          <img src={offerLogo} alt="logo" />
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.offerPriceWrapper}>
          <div className={styles.offerlogo}>
            <img src={PriceOfferLogo} alt="offer logo" />
          </div>
          {/* <Button
                        isLoading={false}
                        classNames={"greenButton"}
                        onClick={onClickQuotePrice}
                        ctaText={"Slider"} /> */}

          <p className={styles.title}>OFFER PRICE FOR YOUR CAR</p>
          <div className={styles.priceSlider}>
            {!!c24Quote && <p className={styles.price} onClick={onClickQuotePrice}><span>{priceFormatter(c24Quote)}</span></p>}
            <div className={styles.priceTextAmountWrapper}>
              {showSlider &&
              <div className={styles.priceTextAmount}>
                <span className={styles.optimumAmount}>₹ {priceFormatter(values[0])}</span>
                <div className={styles.priceWrapper}>
                  <div className={styles.priceSlider}>
                    <PaiSlider
                      domain={domain}
                      onChange={onChangeSlider}
                      onUpdate={onUpdateSlider}
                      values={values}
                      update={update}
                      step={step}
                      sliderData={sliderData}
                      prices={priceAcceptance}
                    />
                  </div>
                  <React.Fragment>
                    {probablityType === PROBABLITY_TYPES.HIGH.key && <p className={styles.greenText}>{PROBABLITY_TYPES.HIGH.label}</p>}
                    {probablityType === PROBABLITY_TYPES.MEDIUM.key && <p className={styles.orengeText}>{PROBABLITY_TYPES.MEDIUM.label}</p>}
                    {probablityType === PROBABLITY_TYPES.LOW.key && <p className={styles.redText}>{PROBABLITY_TYPES.LOW.label}</p>}
                    {probablityType === PROBABLITY_TYPES.VERY_LOW.key && <p className={styles.redText}>{PROBABLITY_TYPES.VERY_LOW.label}</p>}
                  </React.Fragment>
                </div>
              </div>
              }
              {showSlider === false && <span className={styles.noData}>No data available</span>}
            </div>

          </div>

          <div className={styles.offersSection}>
            <CustomerPartnersViewed data={data} />
            <CustomerOffer />
          </div>
        </div>
      </div>
      <div className={styles.wrapper}>
        {componentShow && <div className={styles.similarCars}>
          <CustomerYoursCars />
          <CustomerSimilarCars />
        </div>}
        <CustomerWhySell />
      </div>
    </>
  );
};

export default CustomerOfferPrice;
