import React, { useEffect } from "react";
import styles from "./evaluator-store-tabs.module.scss";

const EVALUATOR_STORE_TABS = {
  'Total Customers Waiting': "total_pending_ins",
  'Total Ongoing Inspections': "total_ongoing_ins",
  'Total Inspections Done': "total_done_ins",
  'Total CJs Active': "total_cjs_active",
  'Total Inspections Denied': "total_denied_ins",
  'Total Non-verified OTP': "total_non_verified_otp"
};

const EvaluatorStoreTabs = ({
  fetchEvaluatorTokensConnect,
  storeId,
  evaluator
}) => {
  const { evaluatorTokens: { data } } = evaluator;
  useEffect(() => {
    fetchEvaluatorTokensConnect(storeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {
        data &&
        <div className={styles.evaluatorStoreTabs}>
          <ul>
            {
              Object.keys(EVALUATOR_STORE_TABS).map(item => {
                return (
                  <li key={item}>{item} <span>{data[EVALUATOR_STORE_TABS[item]]}</span></li>
                );
              })
            }
          </ul>
        </div>
      }
    </>
  );

};

export default EvaluatorStoreTabs;
