import React from "react";
import { Slider, Rail, Handles, Tracks } from "react-compound-slider";
import { SliderRail, Handle, Track } from "./components";
import priceToText from "../../utils/helpers/price-to-text";
import styles from "./psi-slider.module.css";

const sliderStyle = {
  position: 'relative',
  width: '100%',
  touchAction: 'none',
};

const calculateGradient = (prices, sliderData) => {
  const colors = {
    green: { color: '#44ca6f', percent: 0 },
    yellow: { color: 'yellow', percent: 0 },
    orange: { color: 'orange', percent: 0 },
    red: { color: 'red', percent: 0 },
    grey: { color: '#D3D3D3', percent: 0, enpercent: 100 },
  };
  if (!!prices && prices.length > 0) {
    const delta = parseFloat(prices[prices.length - 1].key);
    prices.forEach(({ key, value, band }) => {
      // const currentPriceRange = parseInt(value * 100);
      if (band === "H") {
        colors.green.percent = 100 - (((delta - key) / delta) * 100);
      }
      if (band === "M") {
        colors.yellow.percent = 100 - (((delta - key) / delta) * 100);
      }
      if (band === "L") {
        colors.orange.percent = 100 - (((delta - key) / delta) * 100);
      }
      if (band === "VL") {
        colors.red.percent = 100 - (((delta - key) / delta) * 100);
      }
      // if (currentPriceRange > sliderData.high_value) {
      //     colors.green.percent = 100 - (((delta - key) / delta) * 100);
      // }
      // if (currentPriceRange >= sliderData.medium_value && currentPriceRange < sliderData.high_value) {
      //     colors.yellow.percent = 100 - (((delta - key) / delta) * 100);
      // }
      // if (currentPriceRange >= 0 && currentPriceRange < sliderData.medium_value) {
      //     colors.red.percent = 100 - (((delta - key) / delta) * 100);
      // }
    });
    // Sample: #0cff00 0%, #fbc400 33%, #fa0000 100%
    const colorsArray = Object.values(colors).filter(({ percent }) => percent > 0);
    return colorsArray.map(({ color, percent }, index) => {
      let startPercent = 0;
      let endPercent = percent;
      if (color === '#44ca6f') { endPercent = percent - 10; }
      if (color === 'yellow' && index > 0) { startPercent = colorsArray[index - 1].percent + 10; endPercent = percent - 10; }
      if (color === 'orange' && index > 0) { startPercent = colorsArray[index - 1].percent + 10; endPercent = percent - 10; }
      if (color === 'red' && index > 0) { startPercent = colorsArray[index - 1].percent + 10; endPercent = percent - 10; }
      return `${color} ${startPercent}% ${endPercent}%`;
    }).join(", ");
  }
  else {
    return `${colors.grey.color} ${colors.grey.percent}% ${colors.grey.enpercent}%`;
  }
};

const PaiSlider = ({
  domain,
  onChange,
  onUpdate,
  values,
  step,
  prices,
  sliderData
}) => {
  const gradientColors = calculateGradient(prices, sliderData);
  return (
    <div className={styles.psiSlider}>
      <Slider
        mode={1}
        step={step}
        domain={domain}
        rootStyle={sliderStyle}
        onUpdate={onUpdate}
        onChange={onChange}
        values={values}
      >
        <Rail>
          {({ getRailProps }) => <SliderRail getRailProps={getRailProps} gradient={gradientColors} />}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className="slider-handles">
              {handles.map(handle => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={domain}
                  getHandleProps={getHandleProps}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>
        <div className={styles.priceTextAmount}>
          <span>{priceToText(domain[0])}</span>
          <span>{priceToText(domain[1])}</span>
        </div>
      </Slider>
    </div>
  );
};

export default PaiSlider;