import React from 'react';
import DomoDashboard from '../../components/domo-dashboard';
import { REPORT_TYPE, USER_TYPE } from '../../components/domo-dashboard/constant';
import { getFromLocalStorage } from "../../utils/helper";
import { LOCAL_STORAGE_KEYS } from "../../utils/app-constants";
import withLayout from '../../components/layout/component';

const CallLogs = () => {
  const localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
  const {email=''} = JSON.parse(localData) || {};
  return (
    <DomoDashboard
      reportType={REPORT_TYPE.CALL_LOG}
      isAccessAllowed={true}
      userType={USER_TYPE.RA}
      raEmail={email}/>
  );
};

export default withLayout(CallLogs, false);
