import React, { useEffect, useState } from "react";
import styles from "../../components/customer-offer-price-journey/customer-offer-price-journey.module.scss";
import Arrow from "../../components/images/arrow.svg";
import paiInfo from "../../utils/raise-pai/pai-info";
import priceFormatter from "../../utils/helpers/price-formatter";
import { showToastMessages, isPllUser, getFromLocalStorage, ExpireInDays, isC2CUser, isBikeSource, getDiySortOrderKey } from "../../utils/helper";
import { DIY_SORT_ORDER, LOCAL_STORAGE_KEYS } from "../../utils/app-constants";
import popups from "../../utils/popup-constants";
import Popup from "../popup";
import CustomerOfferPaiSlider from "../../components/customer-offer-pai-slider";
import SuggestedQuotePopup from "../suggested-quote-popup";
import { PROBABLITY_TYPES } from "../../utils/raise-pai/calculate-probablity";
import Timer from "react-compound-timer";
import {trackLMSEvents} from "../../utils/helpers/tracking-events";
import {getUid} from "../../utils/helper";
import moment from "moment";
const CustomerOfferPriceJourneyHeader = ({
  negotiations,
  preCheckRaisePAIConnect,
  setCurrentPopupConnect,
  history,
  depreciation
}) => {
  const { data, currentPopup } = negotiations;
  let appointMentData = {};
  if (!!data && data.length > 0) {
    appointMentData = data[0];
  }
  let _avgPrice = [];
  if (!!depreciation) {
    const { data } = depreciation;
    if (!!data) {
      const { data: { data: { data } } } = depreciation;
      const { final_price_range_min, final_price_range_max } = data;
      _avgPrice.push(final_price_range_min);
      _avgPrice.push(final_price_range_max);
    }
  }
  const popupStyles = [
    popups.RAISE_PAI,
    popups.GENERATE_PR,
    popups.QUOTE_UNLOCK,
    popups.SUGGESTED_QUOTE
  ].includes(currentPopup || "") ? { minWidth: "auto" } : {};

  const { sortOrder, c2cListingPrice, assuredPrice, diySortOrder } = appointMentData;
  let isPll = isPllUser();
  const [acceptance, setAcceptance] = useState("");
  let isC2C = isC2CUser();
  const finalSortOrder = sortOrder === 41 ? getDiySortOrderKey(diySortOrder) : sortOrder;
  
  const ShowHideTabs = (tab) => {
    if (tab === "PAI") {
      if (sortOrder === 4) {
        return !assuredPrice; 
      } else if (sortOrder === 30) {
        return true;
      } else if (sortOrder === 41) {
        switch (finalSortOrder) {
          case DIY_SORT_ORDER.HI_PRICE_NOT_ACCEPTED:
          case DIY_SORT_ORDER.HI_NON_GS_PRICE_NOT_ACCEPTED:
          case DIY_SORT_ORDER.HI_NON_GS_PRICE_ACCEPTED:
          case DIY_SORT_ORDER.HI_PRICE_ACCEPTED:
            return true;
          default:
            return false;
        }
      }
      else {
        return false;
      }
    }
    if (tab === "LIST") {
      return true;
    }
  };
  const onClickShowRaisePAIContent = () => {
    setShowRaisePaiTabContent(true);
    setShowListCarTabContent(false);
  };
  const onClickShowRListCarContent = () => {
    setShowRaisePaiTabContent(false);
    setShowListCarTabContent(true);
  };

  const [showRaisePaiTab, setShowRaisePaiTab] = useState(ShowHideTabs("PAI"));
  const [showListCarTab, setShowListCarTab] = useState(ShowHideTabs("LIST"));

  const [showRaisePaiTabContent, setShowRaisePaiTabContent] = useState(ShowHideTabs("PAI") ? true : false);
  const [showListCarTabContent, setShowListCarTabContent] = useState(!ShowHideTabs("PAI") ? true : false);

  useEffect(() => {
    const currentState = calculateState(appointMentData);
    setAcceptance(currentState);
  }, []);
  
  const calculateState = (payload) => {
    const { conversion } = payload;
    const conversionData = conversion || {};
    const { max_delta_band } = conversionData;
    if (!!max_delta_band) {
      if (max_delta_band === "H") return "H";
      if (max_delta_band === "M") return "M";
      if (max_delta_band === "L") return "L";
      if (max_delta_band === "VL") return "L";
    }
  };

  const onClickGenerateCustomerPage = () => {
    const { appointmentIndex, calculatedc24Quote, sortOrder } = appointMentData;
    if (calculatedc24Quote > 0) {
      if (!isC2C && sortOrder !== 8 && sortOrder !== 9 && sortOrder !== 10 && sortOrder !== 11 && sortOrder !== 17) {
        setCurrentPopupConnect(popups.SUGGESTED_QUOTE, !!appointmentIndex ? appointmentIndex : 0);
      }
    }
  };
  const onClickQuotePrice = () => {
    const loginData = JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA) || "null");
    if (!loginData && !loginData.email) { showToastMessages("Please login again.", false); }
    const payload = {
      "appointment_id": appointMentData.appointmentId,
      "carId": appointMentData.carId,
      "auction_id": appointMentData.auctionId,
      "store_id": appointMentData.storeId,
      "c24quote": appointMentData.calculatedc24Quote,
      "requested_c24quote": 0,
      "userEmail": loginData.email,
      "request_type": "RAISE_PAI",
      "is_pll": isPll,
      "c24Diff": 0,
      "probability": "",
      "reqCheck": "paiRequest",
      "customerPage": true
    };
    preCheckRaisePAIConnect(payload).then(() => {
      paiInfo(negotiations, 1000, true);
      setShowDropDownView(!showDropDownView);
    }).catch(() => {
      setShowDropDownView(!showDropDownView);
    });
  };
    // End Slider Related Code
  let arrayOpenViewSortOrder = [4, 2, 3, 7, 18, 9, 6, 8, 5, 17];
  const [showDropDownView, setShowDropDownView] = useState(arrayOpenViewSortOrder.indexOf(sortOrder) >= 0 ? true : false);
  const ArrowWithSliderComponent = () => {
    const { raise_acceptance } = appointMentData;
    let isLowRaisePAITextShow = false;
    if (!!c2cListingPrice && parseInt(c2cListingPrice) > 0) {
      isLowRaisePAITextShow = false;
    } else {
      if (raise_acceptance === PROBABLITY_TYPES.MEDIUM.key || raise_acceptance === PROBABLITY_TYPES.LOW.key || raise_acceptance === PROBABLITY_TYPES.VERY_LOW.key) {
        isLowRaisePAITextShow = true;
      }
    }
    if (showRaisePaiTab){
      return (
        <div className={`${styles.priceSlideWrapper} ${showDropDownView ? styles.active : ''}`}>
          <div className={styles.deactiveArrow}>
            <img src={Arrow} alt="arrow" onClick={() => onClickQuotePrice()} />
          </div>
          <div className={`${styles.priceSliderContent} ${showDropDownView ? styles.active : ''}`}>
            <div className={styles.tabing}>
              <ul>
                <li onClick={onClickShowRaisePAIContent} className={showRaisePaiTabContent ? styles.active : ""}>Raise PAI</li>
              </ul>
            </div>
            {showRaisePaiTabContent &&
            <CustomerOfferPaiSlider />
            }
          </div>
        </div>
      );
    }
  };

  const InternalBidCase = (negoData) => {
    const { c24Quote, auction_count, CEPPrice, isInventoryBuying} = negoData;
    let hidePaiSlider = isInventoryBuying && auction_count >= 2 && c24Quote >= CEPPrice;
    return (
      <div className={styles.offerPriceSection}>
        <div className={styles.moneyIcon}>
          <img src={''} alt="moneyIcon" />
        </div>
        <div className={styles.offersContent}>
          <p className={styles.offerTitle}>Offer Price:</p>
          <p className={styles.offerPrice}>₹ {priceFormatter(c24Quote)}</p>
        </div>
        {!hidePaiSlider && ArrowWithSliderComponent()}
      </div>
    );
  };
  
  const C2CListPriceInfo = () => {
    return (
      <div className={styles.offersContent}>
        <p className={styles.offerTitleYellow}>Car Listed:</p>
        <p className={styles.offerPriceYellow}>₹ {priceFormatter(c2cListingPrice)}</p>
      </div>
    );
  };
  const PaiAccepteedAndExpiredOcb = () => {
    const { requestedC24quote } = appointMentData;
    return (
      <div className={styles.ocbRuningExpired}>
        <p className={styles.ocbruning}>OCB EXPIRED FOR</p>
        <p className={styles.ocbPrice}>₹ {priceFormatter(requestedC24quote, true)}</p>
      </div>
    );
  };
  const PaiAccepteedAndRunningOcb = () => {
    const { requestedC24quote, auction_over_time } = appointMentData;
    const initialTime = auction_over_time * 1000;
    return (
      <>
        <div className={styles.ocbRuningWrapper}>
          <p className={styles.ocbruning}>OCB RUNNING FOR</p>
          <p className={styles.ocbPrice}>₹ {priceFormatter(requestedC24quote, true)}</p>
        </div>
        <Timer
          initialTime={initialTime}
          direction="backward"
        >
          <p className={styles.ocbTimer}>
            <span><Timer.Hours /> HRS</span>
            <span> <Timer.Minutes /> MIN LEFT</span>
          </p>
        </Timer>
        {/* <p className={styles.expires}>Expires {ExpireInDays(originalInsDate)}</p> */}
      </>
    );
  };
  const RaisePAICase = (negoData, headerText, price, offerTitleCss, offerPriceCss, imageIcon) => {
    const { sortOrder, appointmentId} = appointMentData;
    let isVerificationPending = false;
    const isBike = isBikeSource();
    const { c24QuoteTag, c24Quote, auctionEfficiencyFlag, isInventoryBuying, auction_count, CEPPrice } = negoData;
    if (![17, DIY_SORT_ORDER.DIY_PARTIAL_PAI_NEGO_RETAIL].includes(finalSortOrder) && sortOrder !== 8) {
      isVerificationPending = c24QuoteTag === "Verification Pending";
    }
    let dealerNegotiatedCase = false; // need to be removed //TODO
    if ([17, 18, DIY_SORT_ORDER.DIY_PARTIAL_PAI_NEGO_RETAIL, DIY_SORT_ORDER.DIY_PARTIAL_PAI_NEGO_RETAIL_PR].includes(finalSortOrder)) {
      dealerNegotiatedCase = true;
    }
    if (auctionEfficiencyFlag && sortOrder === 4 && !isBike) {
      trackLMSEvents("ls_auction_efficiency", {
        eventLabel: appointmentId,
        lsRAID: getUid(),
        event:'ls_auction_efficiency',
        eventCategory: "Negotiated",
        eventAction:'auction_efficient',
        Timestamp: moment().format()
      });
    }

    let hidePaiSlider = isInventoryBuying && auction_count >= 2 && c24Quote >= CEPPrice; /* when GS cars reauction done then disabling PAI check */
    return (
      <div className={styles.offerPriceSection}>
        {!isBike && [4, 41].includes(sortOrder) && auctionEfficiencyFlag && <div className={styles.greatOffers}>
          <h3>You’ve got a great offer.</h3>
          <p>This was a <span>Super Auction!!</span></p>
        </div>}
        <div className={styles.moneyIcon}>
          <div className={!!imageIcon ? `${styles[imageIcon]}` : `${styles["OrangeIcon"]}`}></div>
        </div>
        <div className={styles.offersContent} >
          <p className={!!offerTitleCss ? `${styles[offerTitleCss]}` : `${styles["offerTitleGreen"]}`}>{!!headerText ? headerText : "Offer Price:"}</p>
          <p className={!!offerPriceCss ? `${styles[offerPriceCss]}` : `${styles["offerPriceGreen"]}`} onClick={onClickGenerateCustomerPage}>₹ {priceFormatter(!!price ? price : c24Quote, true)}</p>
          {[18, DIY_SORT_ORDER.DIY_PARTIAL_PAI_NEGO_RETAIL_PR].includes(finalSortOrder) && <PaiAccepteedAndExpiredOcb />}
          {[17, DIY_SORT_ORDER.DIY_PARTIAL_PAI_NEGO_RETAIL].includes(finalSortOrder) && <PaiAccepteedAndRunningOcb />}
          {isVerificationPending &&
            <p className={styles.c24QuoteTag}>{`(${c24QuoteTag})`}</p>
          }
        </div>
        {c2cListingPrice > 0 ? C2CListPriceInfo() : <></>}
        {!hidePaiSlider && ArrowWithSliderComponent()}
      </div>
    );
  };

  const PostAuctionPriceHeader = (negoData) => {
    const { requestedC24quote, c24Quote} = negoData;
    switch (finalSortOrder) {
      case 0:
        return InternalBidCase(negoData);
      case 1:
        return RaisePAICase(negoData, "C24 QUOTE", c24Quote, "offerTitleGreen", "offerPriceGreen", "GreenIcon");
      case 2:
      case DIY_SORT_ORDER.DIY_PAI_ACCEPTED:
        return RaisePAICase(negoData, "PAI ACCEPTED", c24Quote, "offerTitleGreen", "offerPriceGreen", "GreenIcon");
      case 3:
      case DIY_SORT_ORDER.DIY_OCB_ACCEPTED:
        return RaisePAICase(negoData, "OCB ACCEPTED", c24Quote, "offerTitleGreen", "offerPriceGreen", "GreenIcon");
      case 4:
      case 30:
      case DIY_SORT_ORDER.SO_NO_PRICE_CHANGE: 
      case DIY_SORT_ORDER.SO_PRICE_NOT_ACCEPTED : 
      case DIY_SORT_ORDER.SO_PRICE_ACCEPTED:
      case DIY_SORT_ORDER.HI_PRICE_NOT_ACCEPTED: 
      case DIY_SORT_ORDER.HI_PRICE_ACCEPTED: 
      case DIY_SORT_ORDER.HI_GS_PRICE_NOT_ACCEPTED: 
      case DIY_SORT_ORDER.HI_GS_PRICE_ACCEPTED: 
      case DIY_SORT_ORDER.HI_NON_GS_PRICE_NOT_ACCEPTED: 
      case DIY_SORT_ORDER.HI_NON_GS_PRICE_ACCEPTED: 
        return RaisePAICase(negoData, "Offer Price:", c24Quote, "offerTitleGreen", "offerPriceGreen", "GreenIcon");
      case 5:
      case DIY_SORT_ORDER.DIY_INTERNAL_BID_5:
        return RaisePAICase(negoData, "Internal-Bid", c24Quote, "offerTitleRed", "offerPriceRed", "OrangeIcon");
      case 6:
      case DIY_SORT_ORDER.DIY_INTERNAL_BID_6:
        return RaisePAICase(negoData, "Internal-Bid", c24Quote, "offerTitleRed", "offerPriceRed", "OrangeIcon");
      case 7:
      case 16:
      case DIY_SORT_ORDER.DIY_OCB_EXPIRED:
        return RaisePAICase(negoData, "OCB EXPIRED", c24Quote, "offerTitleRed", "offerPriceRed", "OrangeIcon");
      case 8:
      case DIY_SORT_ORDER.DIY_OCB_RUNNING_AT:
        return RaisePAICase(negoData, "OCB RUNNING AT", requestedC24quote, "offerTitleBlack", "offerPriceBlack", "BlackIcon");
      case 9:
      case DIY_SORT_ORDER.DIY_PAI_RUNNING:
        return RaisePAICase(negoData, "PAI RUNNING AT", requestedC24quote, "offerTitleBlack", "offerPriceBlack", "BlackIcon");
      case 10:
      case DIY_SORT_ORDER.DIY_AUCTION_RUNNING:
        return RaisePAICase(negoData, "Auction Running", c24Quote, "offerTitleYellow", "offerPriceYellow", "OrangeIcon");
      case 11:
      case DIY_SORT_ORDER.DIY_SUSPENDED:
        return RaisePAICase(negoData, "Auction Suspended", 0, "offerTitleRed", "offerPriceRed", "OrangeIcon");
      case 15:
        return RaisePAICase(negoData, "HB", c24Quote, "offerTitleRed", "offerPriceRed", "OrangeIcon");
      case 17:
      case DIY_SORT_ORDER.DIY_PARTIAL_PAI_NEGO_RETAIL:
        return RaisePAICase(negoData, "Dealer’s Negotiated Price", c24Quote, "offerTitleBlue", "offerPriceBlue", "BlueIcon");
      case 18:
      case DIY_SORT_ORDER.DIY_PARTIAL_PAI_NEGO_RETAIL_PR:
        return RaisePAICase(negoData, "Dealer’s Negotiated Price", c24Quote, "offerTitleBlue", "offerPriceBlue", "BlueIcon");
      case 22:
        return RaisePAICase(negoData, "BUY NOW ACCEPTED", c24Quote, "offerTitleGreen", "offerPriceGreen", "GreenIcon");
      case 29:
      default:
        return <></>;
    }
  };
  const shouldPopUpClose = (currentPopup) => {
    return ![popups.CAR_SPECS, popups.CAR_SPECS_CUSTOMER_VIEW].includes(currentPopup);
  };
  const updateListing = () => {
    // updateDataList(currentIndexData.appointmentId);
  };
  return (
    <div className={styles.offerPriceMainWrapper}>
      {
        PostAuctionPriceHeader(appointMentData)
      }
      <Popup contentStyles={popupStyles} closeOnOutside={shouldPopUpClose(currentPopup)}
        isOpen={!!currentPopup} close={() => setCurrentPopupConnect()}>
        {currentPopup === popups.SUGGESTED_QUOTE && <SuggestedQuotePopup resetListing={updateListing} close={() => setCurrentPopupConnect()} history={history} fromInputOutput={true} />}
      </Popup>
    </div>
  );
};

export default CustomerOfferPriceJourneyHeader;
