import { createTypes } from "reduxsauce";

export default createTypes(
  `
    CHECK_IN_POPUP_SUCCESS
    CHECK_IN_POPUP_FAILURE

    RESET_CHECK_IN_POPUP
    RESET_CHECK_IN_POPUP_KEY
`,
  {
    prefix: "checkinpopup/"
  }
);
