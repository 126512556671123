/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styles from "./cc-confirm-leads.module.scss";
import { CC_CONFIRMED_FILTERS } from "../../utils/app-constants";

const CcConfirmLeads = ({
  homeInspectionConfirmLead,
  updateconfirmLeadsFilterQueryConnect,
  confirmCount,
  nonConfirmCount,
  updateHeaderRowFilterQueryConnect,
  sortAppointmentByKeyConnect
}) => {
  const [selectedFilter, setSelectedFilter] = useState("");
  const { confirmLeadsFilterQuery } = homeInspectionConfirmLead;

  useEffect(() => {
    if (!!confirmLeadsFilterQuery) {
      if (confirmLeadsFilterQuery === "init" || confirmLeadsFilterQuery === "&leadConfirmed=1") {
        setSelectedFilter("confirm");
      }
      else if (confirmLeadsFilterQuery === "&leadConfirmed=1") {
        setSelectedFilter("nonconfirm");
      }
    }
    else {
      setSelectedFilter("confirm");
    }
    return () => {
      updateconfirmLeadsFilterQueryConnect(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, []);

  useEffect(() => {
    if (!!confirmLeadsFilterQuery) {
      if (confirmLeadsFilterQuery === "init" || confirmLeadsFilterQuery === "&leadConfirmed=1") {
        setSelectedFilter("confirm");
      }
      else if (confirmLeadsFilterQuery === "&leadConfirmed=0") {
        setSelectedFilter("nonconfirm");
      }
    }
    else {
      setSelectedFilter("confirm");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [confirmLeadsFilterQuery]);

  const onFilterSelect = (selectedKey, selectedValue) => {
    const query = `&leadConfirmed=${selectedValue}`;
    if (query !== confirmLeadsFilterQuery) {
      setSelectedFilter(selectedKey);
      updateconfirmLeadsFilterQueryConnect(query);
      updateHeaderRowFilterQueryConnect(null);
      sortAppointmentByKeyConnect(null, null);
    } else {
      // setSelectedFilter("");
      // updateconfirmLeadsFilterQueryConnect(null);
    }
  };
  return (
    <div className={styles.ccConfirmLeads}>
      <ul>
        {/* <li className={styles.active}>confirmed leads (38)</li>
                <li>not confirmed leads (12)</li> */}
        {
          Object.values(CC_CONFIRMED_FILTERS).map(item => {
            return (
              <li key={item.key} onClick={() => { onFilterSelect(item.key, item.value); }} className={selectedFilter === item.key ? styles.active : ''}>{item.label} ({item.key === CC_CONFIRMED_FILTERS["TODAY"].key ? confirmCount : nonConfirmCount})</li>
            );
          })
        }
      </ul>
    </div>

  );
};

export default CcConfirmLeads;
