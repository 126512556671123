import React, { useState } from "react";
import Header from "../header";
import Footer from "../footer";
import Sidebar from "../sidebar";
import styles from "./layout.module.css";
import { ToastContainer } from 'react-toastify';
import { getIndividualOrStoreName, getAppointmentTypeFromPathName, IsConfirmedNotConfirmedViewOfSchedule } from "../../utils/helper";
import { APPOINTMENT_TYPES_STRING } from "../../utils/app-constants";
import LayoutContext from "./context";

export default function withLayout(WrappedComponent, sidebar = true, header = true, footer = false, onlyLogoHeader = false) {

  const Layout = (props) => {
    const [expandSidebar, setExpandSidebar] = useState(false);
    const name = getIndividualOrStoreName();
    const { pathname } = window.location;
    const appointmentType = getAppointmentTypeFromPathName(pathname);
    let isHIConfigured = IsConfirmedNotConfirmedViewOfSchedule();

    const openSidebar = () => {
      setExpandSidebar(true);
    };

    const hideSidebar = () => {
      setExpandSidebar(false);
    };
    const [state, setState] = useState({
      storeName: "",
      storeId: "",
      associateName: name
    });

    const updateLayoutContext = (val) => {
      setState({
        ...state,
        associateName: val
      });
    };
    return (
      <>
        <LayoutContext.Provider
          value={{
            data: state,
            updateLayoutContext: updateLayoutContext
          }}
        >
          {sidebar && <Sidebar {...props} expandSidebar={expandSidebar} openSidebar={openSidebar} hideSidebar={hideSidebar} />}
          {header && <Header {...props} onlyLogoHeader={onlyLogoHeader} />}
          <div className={`${sidebar ? styles.wrapper : ''} ${expandSidebar ? styles.openMenu : ''}`}>
            <div className={(appointmentType === APPOINTMENT_TYPES_STRING.SCHEDULED && isHIConfigured) ? `${styles.hiWrapper}` : `` }>
              <div className={(appointmentType === APPOINTMENT_TYPES_STRING.SCHEDULED) ? `${styles.normalhiWrapper}` : `` }>
                <WrappedComponent {...props} />
              </div>
            </div>
          </div>
          {footer && <Footer {...props} />}
          <ToastContainer />
        </LayoutContext.Provider>
      </>
    );
  };
  return Layout;
}
