export const PROBABLITY_TYPES = {
  HIGH: { key: "high", label: "High Acceptance Probability" },
  MEDIUM: { key: "medium", label: "Medium Acceptance Probability" },
  LOW: { key: "low", label: "Low Acceptance Probability" },
  VERY_LOW: { key: "verylow", label: "Very Low Acceptance Probability" }
};

export default (ranges, diff) => {
  const filteredPriceRange = ranges.filter(({ key }) => {
    const price = parseInt(key);
    return price >= diff;
  });
    //const priceRange = filteredPriceRange.length > 0 ? filteredPriceRange[0].value : 0;
  const band = filteredPriceRange.length > 0 ? filteredPriceRange[0].band : "";
  // const priceRangePercentage = parseInt(priceRange * 100);
  // const { high_value, medium_value, low_value } = sliderData;

  if (band === "H") {
    return PROBABLITY_TYPES.HIGH.key;
  } else if (band === "M") {
    return PROBABLITY_TYPES.MEDIUM.key;
  } else if (band === "L") {
    return PROBABLITY_TYPES.LOW.key;
  } else {
    return PROBABLITY_TYPES.VERY_LOW.key;
  }

  // if (priceRangePercentage >= high_value) {
  //     return PROBABLITY_TYPES.HIGH.key;
  // }
  // if (priceRangePercentage >= medium_value && priceRangePercentage <= high_value) {
  //     return PROBABLITY_TYPES.MEDIUM.key;
  // }
  // if (priceRangePercentage >= low_value && priceRangePercentage <= medium_value) {
  //     return PROBABLITY_TYPES.LOW.key;
  // }
  // return PROBABLITY_TYPES.VERY_LOW.key;
};