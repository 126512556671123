import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import WaittimeDashboard from "./component";
import { fetchValidateDashboard } from "./actions";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchValidateDashboardConnect: fetchValidateDashboard
  // fetchVarifyOtpConnect: fetchVarifyOtp,
  // fetchSendOtpConnect: fetchSendOtp
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WaittimeDashboard);
