import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  details: [],
  error: null,
  message: "",
  isLoading: true,
  isPosting: true
};

export const fetchFollowUp = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};

export const fetchFollowUpSuccess = (state = INITIAL_STATE, { data }) => {
  return { ...state, details: data, error: null, isLoading: false };
};

export const fetchFollowUpFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, error, isLoading: false };
};

export const postFollowUpSuccess = (state = INITIAL_STATE, { data }) => {
  const { message } = data;
  return { ...state, message, isLoading: false, error: null, isPosting: false };
};

export const postFollowUpFailure = (state = INITIAL_STATE, { error }) => {
  const { detail: { message } } = error;
  return { ...state, error, message, isLoading: false, isPosting: false };
};

export const resetFollowUpState = (state = INITIAL_STATE, { data }) => {
  const { key, value } = data;
  return {...state, [key]: value};
};

export const resetFollowUp = () => {
  return INITIAL_STATE;
};

export const HANDLERS = {
  [Types.FETCH_FOLLOW_UP]: fetchFollowUp,
  [Types.FETCH_FOLLOW_UP_SUCCESS]: fetchFollowUpSuccess,
  [Types.FETCH_FOLLOW_UP_FAILURE]: fetchFollowUpFailure,
  [Types.POST_FOLLOW_UP_SUCCESS]: postFollowUpSuccess,
  [Types.POST_FOLLOW_UP_FAILURE]: postFollowUpFailure,
  [Types.RESET_FOLLOW_UP_KEY]: resetFollowUpState,
  [Types.RESET_FOLLOW_UP]: resetFollowUp,
};

export default createReducer(INITIAL_STATE, HANDLERS);
