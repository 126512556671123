import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  confirmLeadsFilterQuery: null
};

export const updateconfirmLeadsFilterQuery = (state = INITIAL_STATE, { confirmLeadsFilterQuery }) => {
  return { ...state, confirmLeadsFilterQuery };
};

export const HANDLERS = {
  [Types.UPDATE_CONFIRM_LEADS_FILTER_QUERY]: updateconfirmLeadsFilterQuery

};

export default createReducer(INITIAL_STATE, HANDLERS);
