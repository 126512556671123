import React from "react";
import styles from "./label-with-text.module.css";

const LabelWithText = ( props) => {
  const { label, text, isBackupVN=false } = props;
  return (
    <div>
      <label className={styles.heading}>{label}</label>
      <p className={isBackupVN ? `${styles.text} ${styles.backupNumber}` : `${styles.text}`}>{text}</p>
    </div>

  );
};

export default LabelWithText;
