import Types from "./types";
import { AppointmentService } from "../../service";
// import { getValidateDashBoardMoc } from "../../utils/mocApi";

const fetchValidateDashboard = (storeId, uid) => dispatch => {
  return AppointmentService.getValidateDashboard(storeId, uid)
    .then(response => {
      // response = getValidateDashBoardMoc();
      // dispatch(null);
      const { data: { detail } } = response;
      return detail;
    })
    .catch((error) => {
      throw error;
    });
};
// const fetchVarifyOtp = (param) => dispatch => {
//     return AppointmentService.verifyWaitTimeOtp(param)
//         .then(response => {
//             // response = getSimilarCarMoq();
//             const { data: { detail } } = response;
//             return detail;
//         })
//         .catch((error) => {
//             throw error;
//         });
// };
// const fetchSendOtp = (param) => dispatch => {
//     return AppointmentService.sendWaitTimeOtp(param)
//         .then(response => {
//             // response = getSimilarCarMoq();
//             const { data: { detail } } = response;
//             return detail;
//         })
//         .catch((error) => {
//             throw error;
//         });
// };
export {
  fetchValidateDashboard
  // fetchVarifyOtp,
  // fetchSendOtp
};
