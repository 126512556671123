import React, { useState, useEffect } from "react";
import styles from "./follow-up-popup.module.css";
import Button from "../shared/button/component";
import { POPUP_BUTTONS, ERROR_MESSAGE, DATATYPE, RESET_REDUCER_STATE_KEYS, APPOINTMENT_TYPES_NUMBER, TEXT_AREA_REGEX, APPOINTMENT_TYPES_STRING } from "../../utils/app-constants";
import SelectComponent from "../shared/select/component";
import H2 from "../h2/component";
import Datepicker from "../shared/datepicker/component";
import { showToastMessages, getAppointmentTypeNumberFromString } from "../../utils/helper";
import Textarea from "../shared/textarea/component";
import Popup from "../popup/component";
import RescheduleAppointmentPopup from "../../components/reschedule-appointment-popup";
const FollowUpPopup = ({ onCancelClick, fetchFollowUpListConnect, rowDetails, followUp, appointments, postFollowUpConnect
  , resetAppointmentByKeyConnect, resetStateConnect, setHasMoreItems, resetFollowUpConnect,
  resetLostLeadsByKeyConnect, onResetParent, isSellOnline = false }) => {
  const { details, isPosting, error, message } = followUp;
  const { appointmentState } = appointments;
  const { appointment, customerName, model, year } = rowDetails;

  const [dispositionList, setDispositionList] = useState([]);
  const [disposition, setDisposition] = useState();
  const [subDisposition, setSubDisposition] = useState();
  const [dispositionError, setDispositionError] = useState(false);
  const [subDispositionError, setSubDispositionError] = useState(false);
  const [subDispValue, setSubDispValue] = useState();
  const [remarks, setRemarks] = useState();
  const [allocateOpen, setAllocateOpen] = useState(false);
    
  useEffect(() => {
    let type = APPOINTMENT_TYPES_NUMBER.SCHEDULED;
    if (appointmentState === APPOINTMENT_TYPES_STRING.SELL_ONLINE) {
      type = APPOINTMENT_TYPES_NUMBER.SELL_ONLINE; 
    }
    fetchFollowUpListConnect(type);
    return (() => {
      resetFollowUpConnect();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (details.length > 0) {
      const dispositions = details.map(item => { return { value: item.key, label: item.name }; });
      setDispositionList(dispositions);
    }
  }, [details]);

  useEffect(() => {
    if (disposition) {
      const detail = details.find(item => item.key === disposition);
      if (detail.subDispositions.length > 0) {
        setSubDisposition(detail.subDispositions[0]);
      } else {
        setSubDisposition();
      }
      setDispositionError(false);
      setSubDispositionError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disposition]);

  useEffect(() => {
    if (!isPosting) {
      if (error) {
        showToastMessages(message, false);
      } else {
        resetAppointmentByKeyConnect(RESET_REDUCER_STATE_KEYS.IS_LOADING, true);
        resetAppointmentByKeyConnect(RESET_REDUCER_STATE_KEYS.DATA, []);
        resetLostLeadsByKeyConnect(RESET_REDUCER_STATE_KEYS.IS_LOADING, true);
        resetLostLeadsByKeyConnect(RESET_REDUCER_STATE_KEYS.DATA, []);
        !isSellOnline && setHasMoreItems(true);
        showToastMessages(message);
        onCancelClick();
      }
      resetStateConnect(RESET_REDUCER_STATE_KEYS.IS_POSTING, true);
      onResetParent('reset');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPosting]);

  const onDispositionSelect = (value) => {
    setDisposition(value);
  };

  const onDateChange = (val) => {
    setSubDispValue(val);
    setSubDispositionError(false);
  };

  const onRemarksChange = (e) => {
    const { target: { value } } = e;
    setRemarks(value);
  };

  const validateDetails = () => {
    let errors = 0;
    if (!disposition) {
      ++errors;
      setDispositionError(true);
    } else {
      setDispositionError(false);
    }
    if (subDisposition && subDisposition.required && !subDispValue) {
      ++errors;
      setSubDispositionError(true);
    } else {
      setSubDispositionError(false);
    }
    if (errors > 0) {
      return false;
    } else {
      return true;
    }
  };

  const onSaveClick = () => {
    const isValid = validateDetails();
    if (isValid) {
      const dispName = details.find(item => item.key === disposition).name;
      const params = {
        "appointment": appointment,
        "dispositionId": disposition,
        "disposition": dispName,
        "subDisposition": subDisposition && subDisposition.required ? subDispValue.toLocaleTimeString() : "",
        "remarks": remarks ? remarks.trim().replace(TEXT_AREA_REGEX, " ") : "",
        "state": getAppointmentTypeNumberFromString(appointmentState)
      };
      postFollowUpConnect(params);
    }
  };

  const onAllocateOpen = () => {
    setAllocateOpen(true);
  };

  const onAllocateClose = () => {
    setAllocateOpen(false);
  };

  return (
    <>
      <H2 text={"FOLLOW-UP CALL"} />
      <div className={styles.checkinText}>
        <p className={styles.txt}>{`${customerName} | ${model} | ${year}`}</p>
      </div>
      <div className="mt-3">
        <SelectComponent placeholder="Call Result" name="dispositionStatus" onChange={onDispositionSelect} optionsList={dispositionList} hasError={dispositionError} error={ERROR_MESSAGE.DISPOSITION} />
      </div>
      {subDisposition && subDisposition.dataType === DATATYPE.TIMER &&
      <div className="mt-3">
        <div className={styles.customCalender}>
          <Datepicker name="subDisposition"
            selected={subDispValue} placeholder="Time"
            onChange={onDateChange} showTime={true} showTimeOnly={true} />
        </div>
      </div>
      }
      {subDisposition && subDisposition.dataType === DATATYPE.LINK &&
      <div className="mt-3">
        {/* <Anchor url={rescheduleUrl} text="Reschedule Url" newTab={true} /> */}
        <div className={styles.textWrapper}>
          <button className={styles.rescheduleUrl} onClick={onAllocateOpen}>Reschedule</button>
          <Popup isOpen={allocateOpen} close={onAllocateClose}>
            <RescheduleAppointmentPopup onClose={onAllocateClose} appointmentId={appointment} />
          </Popup>
        </div>

      </div>
      }
      {
        subDisposition && subDisposition.required && subDispositionError &&
        <span className={styles.errorText}>{ERROR_MESSAGE.SUB_DISPOSITION}</span>
      }
      <div className="mt-3">
        <Textarea
          id="remarks"
          name="remarks"
          value={remarks}
          onChange={onRemarksChange}
          text="Remarks if any"
        />
      </div>
      <div className={styles.ctaButton}>
        <Button classNames="saveCta" ctaText={POPUP_BUTTONS.SAVE} onClick={onSaveClick} />
        <Button classNames="cancelCta" ctaText={POPUP_BUTTONS.CANCEL} onClick={onCancelClick} />
      </div>
    </>
  );
};

export default FollowUpPopup;
