import Types from "./types";

const updatepaymentProcessingFilterQuery = (paymentProcessingFilterQuery) => ({
  type: Types.UPDATE_PAYMENT_PROCESSING_FILTER_QUERY,
  paymentProcessingFilterQuery
});

export {
  updatepaymentProcessingFilterQuery
};
