import Types from "./types";

const updateconfirmLeadsFilterQuery = (confirmLeadsFilterQuery) => ({
  type: Types.UPDATE_CONFIRM_LEADS_FILTER_QUERY,
  confirmLeadsFilterQuery
});

export {
  updateconfirmLeadsFilterQuery
};
