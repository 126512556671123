import React from "react";
import styles from "./grouped-button.module.css";

const GroupedButton = ({ buttons = [], activeButton = "", onClick = () => {} }) => {
  return (
    <div className={styles.groupedButton}>
      { buttons.map(({ text, key }) => 
        <button 
          onClick={() => onClick(key, text, activeButton === key)} 
          className={activeButton === key && styles.active}>{text}</button>) }
    </div>  
  );
};

export default GroupedButton;
