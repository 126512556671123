import React, { useState, useEffect } from "react";
import styles from "./raise-ocb-popup.module.scss";
import H2 from "../h2/component";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../shared/button/component";
import priceFormatter from "../../utils/helpers/price-formatter";
import {
  showToastMessages,
  getFromLocalStorage,
  isPllUser,
} from "../../utils/helper";
import { LOCAL_STORAGE_KEYS, SLIDER_TYPES } from "../../utils/app-constants";
import ocbCalculateProbablity, {
  PROBABLITY_TYPES,
} from "../../utils/raise-ocb/ocb-calculate-probability";
import ocbInfo from "../../utils/raise-ocb/ocb-info";
import IncrementalTextbox from "../incremental-textbox/component";
import PaiSlider from "../pai-slider";
import warningImg from './images/warning.svg';

const useStyles = makeStyles((theme) => {
  return {
    textField: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      fontSize: "10px",
      width: "100%",
    },
  };
});

const RaiseOcbPopup = ({
  negotiations,
  close,
  resetListing,
  paiRequestConnect,
  setCurrentPopupConnect,
}) => {
  const { data, currentAppointmentIndex } = negotiations;
  const currentAppointmentData = data[currentAppointmentIndex] || {};
  const {
    customerName,
    make,
    model,
    year,
    currentHB,
    preCheckData,
    pll_margin = 0,
	retailOcbAllowed=false,
	diySortOrder,
	sortOrder
  } = currentAppointmentData;
  const [inputText, setInputText] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [inputTextError, setInputTextError] = useState(false);
  const [inputTextErrorMessage, setInputTextErrorMessage] = useState("");
  const classes = useStyles();
  const isPll = isPllUser();
  const pllMargin = isPll ? pll_margin : 0;
  const isDirectOcb = ([41,42].includes(sortOrder) && [24, 25, 26].includes(diySortOrder)) || ([51,52,53].includes(sortOrder)) || (sortOrder == 41 && diySortOrder ==16 && currentAppointmentData?.direct_ocb);
  const {
    showSlider,
    minPrice,
    priceAcceptance,
    sliderData,
    defaultValues,
    domain,
    pendingRequest,
    step,
    sliderType,
	maxPrice,
	optimalPrice,
  } = ocbInfo({preCheckData, pllMargin, isRetailOCB: retailOcbAllowed, isDirectOcb:  isDirectOcb});

  const [probablityType, setProbablityType] = useState(null);
  const [closurePrice, setClosurePrice] = useState(currentHB);
  const [values, setValues] = useState(defaultValues.slice());
  const [update, setUpdate] = useState(defaultValues.slice());
  const maxLimit = pendingRequest; 
  const [isSubmitted, setIsSubmitted] = useState(maxLimit <= 0 ? true : false);

  useEffect(() => {
	isDirectOcb ? setClosurePrice(optimalPrice) : setClosurePrice(minPrice);
	if (showSlider) {
      setProbablityType(
        ocbCalculateProbablity(priceAcceptance, minPrice, minPrice)
      );
      setIsSubmitted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeText = (e) => {
    const value =
			e.target.value && e.target.value.replace(/,/g, "")
			    ? priceFormatter(Number(e.target.value.replace(/,/g, "")))
			    : "";
    const price = Number(value.replace(/,/g, ""));
    setClosurePrice(price);
    setInputText(value);
    const isLimitCheckRequired =
			sliderType !== SLIDER_TYPES.TEXTBOX_NO_LIMIT;
	if (price % 1000 !== 0) {
	  setInputTextError(true);
      setInputTextErrorMessage("Price must be in multiple of 1000s");
    } else if (price < domain[0] && isLimitCheckRequired) {
	  setInputTextError(true);
      setInputTextErrorMessage(`Price cannot be less than ${minPrice}`);
    } else if (price > domain[1] && isLimitCheckRequired) {
	  setInputTextError(true);
      setInputTextErrorMessage(
        `Price cannot be greater than ${domain[1]}`
      );
    } else {
		setIsSubmitted(false);
      	setInputTextError(false);
      	setInputTextErrorMessage("");
    }
  };
  const RaiseOcbQuote = () => {
    setIsSubmitted(true);
    setisLoading(true);
    const cPrice = closurePrice; //Number(closurePrice.replace(/,/g, ''));
    const {
      appointmentId,
      carId,
      storeId,
      auctionId,
      isRetailMarginAllowed = false,
    } = currentAppointmentData;
    const loginData = JSON.parse(
      getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA) || "null"
    );
    if (!loginData && !loginData.email) {
      showToastMessages("Please login again.", false);
    }

	if (isDirectOcb && !cPrice) {
		showToastMessages("Invalid Auction price.", false);
		setIsSubmitted(false);
        setisLoading(false);
		return;
	} 

    const payload = {
      appointment_id: appointmentId,
      carId: carId,
      auction_id: auctionId,
      store_id: storeId,
      current_c24quote: 0,
      requested_c24quote: (retailOcbAllowed || (isDirectOcb && !isPll)) ? cPrice : 0,
      userEmail: loginData.email,
      request_type: isDirectOcb ? "DIRECT_OCB" : retailOcbAllowed ? "RAISE_RETAIL_OCB" :"RAISE_PAI",
      is_pll: isPll,
      c24Diff: 0,
      requested_hb: cPrice,
      probability: !!probablityType ? probablityType : "",
      isRetailMarginAllowed,
	  minPrice, 
	  maxPrice, 
	  optimalPrice,
    };
	paiRequestConnect(payload)
      .then((resp) => {
        showToastMessages(resp && resp.message ? resp.message : resp);
        setCurrentPopupConnect(null, null);
        setisLoading(false);
        resetListing();
      })
      .catch((resp) => {
        showToastMessages(
                    resp && resp.message ? resp.message : resp,
                    false
        );
        setIsSubmitted(false);
        setisLoading(false);
      });
  };
  const onChangeClick = (type) => {
    const newPrice =
			type === "increment" ? closurePrice + step : closurePrice - step;
    if (newPrice <= domain[1] && newPrice >= domain[0]) {
      setClosurePrice(newPrice);
      setProbablityType(
        ocbCalculateProbablity(priceAcceptance, newPrice, minPrice)
      );
      setValues([newPrice]);
    }
  };

  const onUpdateSlider = (update) => {
    const currentValue = update[0];
    setProbablityType(
      ocbCalculateProbablity(priceAcceptance, currentValue, minPrice)
    );
    setUpdate(update);
    setClosurePrice(currentValue);
  };

  const onChangeSlider = (values) => {
    setValues(values);
  };
  return (
    <>
      {!!sliderType && (
        <div className={styles.currentHubWrap}>
          <H2 text="RAISE OCB" />
          <ul className={styles.customerDesc}>
            <li>{customerName}</li>
            <li>{`${make} ${model}`}</li>
            <li>{year}</li>
          </ul>
          <div className={styles.currentHub}>
            <h3>Current HB</h3>
            <h3 className={styles.price}>
              {priceFormatter(currentHB, true)}
            </h3>
          </div>
          <p className={styles.clousrePrice}>
            Guaranteed Closure Price
          </p>
          {sliderType === SLIDER_TYPES.SLIDER && (
            <>
              <IncrementalTextbox
                value={priceFormatter(closurePrice)}
                onChangeClick={onChangeClick}
              />
            </>
          )}
          {SLIDER_TYPES.SLIDER !== sliderType && (
            <div>
              <TextField
                label="Guranteed Closure Price"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                onChange={onChangeText}
                value={inputText}
                inputProps={{
                  maxLength: 9,
                }}
              />
              {inputTextError && (
                <p className={styles.error}>
                  {inputTextErrorMessage}
                </p>
              )}
              {SLIDER_TYPES.TEXTBOX_WITH_LIMIT === sliderType && (
                <p className={styles.infoText}>
                  {`Please enter between ${domain[0]} & ${domain[1]}`}{" "}
                </p>
              )}
            </div>
          )}
          {sliderType === SLIDER_TYPES.SLIDER && (
            <div>
              <PaiSlider
                domain={domain}
                onChange={onChangeSlider}
                onUpdate={onUpdateSlider}
                values={values}
                update={update}
                step={step}
                sliderData={sliderData}
                prices={priceAcceptance}
              />
              <React.Fragment>
                {probablityType ===
									PROBABLITY_TYPES.HIGH.key && (
                  <p className={styles.greenText}>
                    {PROBABLITY_TYPES.HIGH.label}
                  </p>
                )}
                {probablityType ===
									PROBABLITY_TYPES.MEDIUM.key && (
                  <p className={styles.yellowText}>
                    {PROBABLITY_TYPES.MEDIUM.label}
                  </p>
                )}
                {probablityType ===
									PROBABLITY_TYPES.LOW.key && (
                  <p className={styles.orengeText}>
                    {PROBABLITY_TYPES.LOW.label}
                  </p>
                )}
                {probablityType ===
									PROBABLITY_TYPES.VERY_LOW.key && (
                  <p className={styles.redText}>
                    {PROBABLITY_TYPES.VERY_LOW.label}
                  </p>
                )}
              </React.Fragment>
            </div>
          )}
		  {isDirectOcb && <div className={styles.directOCBInfo}>
			<img src={warningImg} alt='alert'/>
			<p>Customer view doesn't include service charge</p>
		  </div>}
          <div className={styles.currentHubButton}>
            <Button
              isLoading={isLoading}
              disabled={isSubmitted || inputTextError}
              onClick={RaiseOcbQuote}
              classNames="blueButton"
              ctaText="Submit"
            />
            <button className={styles.closeModal} onClick={close}>
              CANCEL
            </button>
          </div>
          {!retailOcbAllowed && !isDirectOcb && <p className={styles.totalRequest}>
            {maxLimit} Request remaining
          </p>}
        </div>
      )}
    </>
  );
};

export default RaiseOcbPopup;
