import React from "react";
import styles from "./self-inspection-report.module.scss";
import Logo from "../../components/images/whiteLogo.svg";

const SelfInspectionReport = () => {
  return (
    <div className={styles.selfInspectionReport}>
      <div className={styles.inspectionHeader}>
        <img src={Logo} alt="logo" width="100px" />
        <span>Nov 15, 2020</span>
      </div>
      <div className={styles.selfInspectionHeading}>
        <span>Self-Inspection Report</span>
      </div>
      <div className={styles.appointmentEmail}>
        <p>Appointment ID: <span>2582582580</span></p>
        <p>CJ Email ID: <span>abc@cars24.com</span></p>
      </div>
      <div className={styles.dataTable}>
        <table cellPadding="0" cellSpacing="0" width="100%">
          <tr>
            <td>
              <table cellPadding="0" cellSpacing="0" width="100%" className={styles.firstTable}>
                <tr>
                  <th>Vehicle Information</th>
                </tr>
                <tr>
                  <td>
                    <table cellPadding="0" cellSpacing="0" width="100%" className={styles.subTable}>
                      <tr>
                        <td>Make</td>
                        <td>Mercedes Benz</td>
                      </tr>
                      <tr>
                        <td>Make</td>
                        <td>Mercedes Benz</td>
                      </tr>
                      <tr>
                        <td>Make</td>
                        <td>Mercedes Benz</td>
                      </tr>
                      <tr>
                        <td>Make</td>
                        <td>Mercedes Benz</td>
                      </tr>
                      <tr>
                        <td>Make</td>
                        <td>Mercedes Benz</td>
                      </tr>
                      <tr>
                        <td>Make</td>
                        <td>Mercedes Benz</td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
            <td>
              <table cellPadding="0" cellSpacing="0" width="100%">
                <tr>
                  <th>Details</th>
                </tr>
                <tr>
                  <td>
                    <table cellPadding="0" cellSpacing="0" width="100%" className={styles.subTable}>
                      <tr>
                        <td>No. Of Owner(S)</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td>Odometer Reading</td>
                        <td>50,000</td>
                      </tr>
                      <tr>
                        <td>No. Of Owner(S)</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td>Odometer Reading</td>
                        <td>50,000</td>
                      </tr>
                      <tr>
                        <td>No. Of Owner(S)</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td>Odometer Reading</td>
                        <td>50,000</td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
      <div className={styles.detailsTable}>
        <table cellPadding="0" cellSpacing="0" width="100%">
          <tr>
            <th>Details</th>
          </tr>
          <tr>
            <td>
              <table cellPadding="0" cellSpacing="0" width="100%" className={styles.subTable}>
                <tr>
                  <td>Accidental</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>Car Condition</td>
                  <td>Car runs fine, it need servicing</td>
                </tr>
                <tr>
                  <td>Rusting</td>
                  <td>Major Rusting in >3 parts</td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
      <div className={styles.bottomInfo}>
        <p>CARS24 SERVICES PVT. LTD. 10TH FLOOR, TOWER B, UNITECH CYBER PARK, SECTOR- 39, GURUGRAM - 122003, 
          HARYANA, INDIA. www.cars24.com CIN : U74999HR2015FTCO56386 | HELPLINE NO : 1800 258 5656 | 
          EMAIL : contactus@cars24.com</p>
        <p>EMAIL : contactus@cars24.com</p>
      </div>
    </div>
  );
};

export default SelfInspectionReport;
