import React, { useState, useEffect } from "react";
import styles from "./suggested-quote-popup.module.css";
import H2 from "../h2/component";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from "../shared/button/component";
import priceFormatter from "../../utils/helpers/price-formatter";
import { showToastMessages } from "../../utils/helper";
import popups from "../../utils/popup-constants";
import { trackLMSEvents } from "../../utils/helpers/tracking-events";
import { getUid } from "../../utils/helper";

const useStyles = makeStyles(theme => {
  return {
    textField: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      fontSize: '10px',
      width: '100%'
    }
  };
});

const SuggestedQuotePopup = ({ negotiations, close, updateSuggestedQuoteConnect, setCurrentPopupConnect, fetchCarDataConnect, setSuggestedC24QuoteConnect, history, fromInputOutput }) => {
  const { data, currentAppointmentIndex, currentSuggestedC24Quote } = negotiations;
  const currentAppointmentData = data[currentAppointmentIndex] || {};
  const { customerName, make, model, year, c24Quote, calculatedc24Quote, showInputOutputPage, isDepReportAvailable } = currentAppointmentData;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [inputText, setInputText] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const classes = useStyles();
  const [showMinamountError, setShowMinamountError] = useState(false);
  const [errorClass, setErrorClass] = useState("");

  const minimumPrice = Math.round(calculatedc24Quote - (calculatedc24Quote * .15));

  useEffect(() => {
    setSuggestedC24QuoteConnect(c24Quote);
    setInputText(priceFormatter(c24Quote));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeText = (e) => {
    const value = e.target.value && e.target.value.replace(/,/g, '') ? priceFormatter(Number(e.target.value.replace(/,/g, ''))) : "";
    const cPrice = Number(e.target.value.replace(/,/g, ''));
    setSuggestedC24QuoteConnect(cPrice);
    setInputText(value);
    setShowMinamountError(false);
    setErrorClass("");
  };

  const onSubmitSuggestedQuote = () => {
    setShowMinamountError(false);
    setErrorClass("");
    if (minimumPrice > currentSuggestedC24Quote) {
      setShowMinamountError(true);
      setErrorClass("form-text text-danger");
    }
    else {
      setIsSubmitted(true);
      setisLoading(true);
      const {
        appointmentId,
      } = currentAppointmentData;

      const payload = {
        "appointmentId": appointmentId,
        "c24BuyingQuote": currentSuggestedC24Quote
      };

      updateSuggestedQuoteConnect(payload)
        .then(() => {
          fetchCarDataConnect(appointmentId)
            .then(
              () => {
                // // showToastMessages(resp && resp.message ? resp.message : resp);
                trackEventCall(appointmentId, calculatedc24Quote, currentSuggestedC24Quote, fromInputOutput);
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
                // window.open('/customer-journey/' + appointmentId + "?showreport=charge&isDepReportAvailable=" + isDepReportAvailable, "_blank");
                // close();
                // if (showInputOutputPage) {
                //     // we will configure it as the offer price open
                //     window.open('/customer-offer-price/' + appointmentId, "_blank");
                //     close();
                // }
                // else {
                //     setCurrentPopupConnect(popups.CAR_SPECS_CUSTOMER_VIEW, currentAppointmentIndex);
                //     setIsSubmitted(false);
                //     setisLoading(false);
                // }
              }
            );
        })
        .catch((resp) => {
          showToastMessages(resp.message, false);
          setIsSubmitted(false);
          setisLoading(false);
        });
    };
  };
  const trackEventCall = (appointmentId, suggestedQuote, finalQuote, fromInputOutput) => {
    if (fromInputOutput === true) {
      trackLMSEvents("ls_OfferPriceEdit", {
        eventLabel: appointmentId,
        lsRAID: getUid(),
        Timestamp: new Date(),
        lsSuggestedQuote: suggestedQuote,
        lsFinalQuote: finalQuote
      });
    }
    else {
      trackLMSEvents("generateCustomerPage", {
        eventLabel: appointmentId,
        lsRAID: getUid(),
        lsSuggestedQuote: suggestedQuote,
        lsFinalQuote: finalQuote
      });
    }
  };

  return (
    <div className={styles.popupWrap}>
      <H2 text="GENERATE CUST. PAGE" />
      <ul className={styles.customerDesc}>
        <li>{customerName}</li>
        <li>{`${make} ${model}`}</li>
        <li>{year}</li>
      </ul>
      <div className={styles.quoteText}>
        <p className={styles.text}>SUGGESTED C24 BUYING QUOTE</p>
        <p className={styles.priceText}>{priceFormatter(calculatedc24Quote)}</p>
      </div>

      <div>
        <TextField
          label="Final C24 Quote"
          className={classes.textField}
          margin="dense"
          variant="outlined"
          onChange={onChangeText}
          value={inputText}
          inputProps={{
            maxLength: 9,
          }}
          InputLabelProps={{ style: {} }}
        />
        <small key="info" className={`${errorClass}`}>{`Enter price above Rs ${priceFormatter(minimumPrice)} only.`}</small>
        {/* {showMinamountError &&
                    <small key="infoErr" className={`form-text text-danger`} >{`Enter price above Rs ${priceFormatter(minimumPrice)} only.`}</small>
                } */}

      </div>
      <div className={styles.getQuote}>
        <Button
          isLoading={isLoading}
          disabled={isSubmitted}
          onClick={onSubmitSuggestedQuote}
          classNames="blueButton"
          ctaText="Submit" />
        <button className={styles.closeModal} onClick={close}>CANCEL</button>
      </div>
    </div>
  );
};

export default SuggestedQuotePopup;
