import React, { useEffect, useState } from "react";
import styles from "./pll-allocation-filters.module.scss";
import { NUMBERS, ROLE_KEYS } from "../../utils/app-constants";
import {getAllocateIsPllString} from '../../utils/helper';
const PLL_ALLOCATE_FILTERS = {
	DAY_4_5_LEADS: { label: "Day 4-5 leads", value: 1, key: "D45" },
	DAY_9_PLUS: { label: "Day 9 + leads", value: 3, key: "D9" },
};
const PLL_ALLOCATE_FILTER_KEY = "pll_allocate_filter";

const HIERARCHY_PLL_ALLOCATE_FILTERS = {
	DAY_4_5_LEADS: { label: "Day 4-5 leads", value: 1, key: "D45" },
	DAY_6_9_LEADS: { label: "Day 6-9 leads", value: 2, key: "D69" },
	DAY_9_PLUS: { label: "Day 9 + leads", value: 3, key: "D9" },
};

const HIERARCHY_RETAIL_ALLOCATE_FILTERS = {
	DAY_0_3_LEADS: { label: "Day 0-3 leads", value: 0, key: "D03", isRetail: true },
};

const PLL_INTENT_FILTERS = [
	{ label: "1", value: NUMBERS.ONE },
	{ label: "2", value: NUMBERS.TWO },
	{ label: "3", value: NUMBERS.THREE },
	{ label: "4", value: NUMBERS.FOUR },
	{ label: "5", value: NUMBERS.FIVE },
	{ label: "6", value: NUMBERS.SIX },
	{ label: "7", value: NUMBERS.SEVEN },
	{ label: "8", value: NUMBERS.EIGHT },
	{ label: "9", value: NUMBERS.NINE },
	{ label: "10", value: NUMBERS.TEN },
];
const PllAllocationFilters = (props) => {
	const {
		isHierarchy,
		originalRole,
		updatePllAllocationQueryConnect,
		resetPLLByKeyConnectConnect,
		pllStores,
	} = props;
	const { leadTypeFilter } = pllStores;
	const [selectedFilter, setSelectedFilter] = useState();
	const [intentValue, setIntentValue] = useState();
	const options = PLL_INTENT_FILTERS.map((option, index) => {
		return (
			<option key={"option_" + index} value={option.value}>
				{option.label}
			</option>
		);
	});
	const [filterItems, setFilterItems] = useState([]);

	const GetQueryString = (value='') => {
		const pllString = getAllocateIsPllString();
		let queryString = "";
		if (!!value) {
			queryString = `&${PLL_ALLOCATE_FILTER_KEY}=${value}`;
		}
		if (leadTypeFilter === 1 || leadTypeFilter === 0) {
			queryString = `${queryString}&isGSAssured=${leadTypeFilter}`;
		}
		return `${queryString}${pllString ? `&${pllString}`: ''}`;
	};

	useEffect(() => {
		setIntentValue(null);
		return () => {
			updatePllAllocationQueryConnect(null);
		};
	}, []);

	useEffect(() => {
		let curFilter = PLL_ALLOCATE_FILTERS.DAY_4_5_LEADS;
		if (isHierarchy) {
			if (originalRole === ROLE_KEYS.TM_NEW) {
				curFilter = HIERARCHY_RETAIL_ALLOCATE_FILTERS.DAY_0_3_LEADS;
				setFilterItems(HIERARCHY_RETAIL_ALLOCATE_FILTERS);
			} else if (originalRole === ROLE_KEYS.PLL_TM) {
				curFilter = HIERARCHY_PLL_ALLOCATE_FILTERS.DAY_4_5_LEADS;
				setFilterItems(HIERARCHY_PLL_ALLOCATE_FILTERS);
			} else if (originalRole === ROLE_KEYS.TM) {
				curFilter = HIERARCHY_RETAIL_ALLOCATE_FILTERS.DAY_0_3_LEADS;
				setFilterItems({...HIERARCHY_RETAIL_ALLOCATE_FILTERS, ...HIERARCHY_PLL_ALLOCATE_FILTERS});
			}
		} else {
			setFilterItems(PLL_ALLOCATE_FILTERS);
		}
		setSelectedFilter(curFilter?.key);
		let queryString = GetQueryString(curFilter?.value);
		updatePllAllocationQueryConnect({qs: queryString, isRetail: curFilter?.key === HIERARCHY_RETAIL_ALLOCATE_FILTERS.DAY_0_3_LEADS.key});
	}, [isHierarchy, originalRole]);

	const onFilterSelect = (key, value, isRetail=false) => {
		setSelectedFilter(key);
		let queryString = GetQueryString(value);
		updatePllAllocationQueryConnect({qs: queryString, isRetail});
		resetPLLByKeyConnectConnect("selectedStoreIds", []);
		resetPLLByKeyConnectConnect("storeAppointmentList", []);
		resetPLLByKeyConnectConnect("pageNo", 1);
		resetPLLByKeyConnectConnect("selectedAppointments", []);
		resetPLLByKeyConnectConnect("loadMore", false);
	};
	const onSelectIntentDays = (key, value) => {
		const pllString = getAllocateIsPllString();
		resetPLLByKeyConnectConnect("selectedStoreIds", []);
		resetPLLByKeyConnectConnect("storeAppointmentList", []);
		resetPLLByKeyConnectConnect("pageNo", 1);
		resetPLLByKeyConnectConnect("selectedAppointments", []);
		resetPLLByKeyConnectConnect("loadMore", false);
		setSelectedFilter(key);
		setIntentValue(value);
		updatePllAllocationQueryConnect({qs: `&intentDay=${value}${pllString ? `&${pllString}`: ''}`});
	};
	return (
		<div className={styles.pllAllocationFilters}>
			<ul>
				{Object.values(filterItems).map((item) => {
					return (
						<li
							key={item.key}
							onClick={() => onFilterSelect(item.key, item.value, item?.isRetail)}
							className={`${styles.textChild} ${
								selectedFilter === item.key ? styles.active : ""
							}`}>
							{item.label}
						</li>
					);
				})}
				{[ROLE_KEYS.PLL_TM, ROLE_KEYS.TM].includes(originalRole) && <li
					kay={"dd-filter"}
					className={`${styles.selectChild} ${
						selectedFilter === "dd" ? styles.active : ""
					}`}>
					<select
						name="dd-filter-intent"
						onChange={(e) => onSelectIntentDays("dd", e.target.value)}
						className={styles.noborderselect}
						value={intentValue || ""}>
						<option hidden value="">
							Select Days Filter
						</option>
						{options && options}
					</select>
				</li>}
			</ul>
		</div>
	);
};

export default PllAllocationFilters;
