import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CheckInDateTime from "./component";
import { updatePriorityCountByKey } from "../sidebar/actions";

const mapStateToProps = ({
  appointments
}) => ({
  appointments
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updatePriorityCountByKeyConnect: updatePriorityCountByKey
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CheckInDateTime);
