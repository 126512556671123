import React, { useState, useRef, useEffect } from "react";
import styles from "./evaluator-retail-view.module.scss";
import DataInformationMessage from "../shared/data-information-message";
import { RESET_REDUCER_STATE_KEYS } from "../../utils/app-constants";
import { showToastMessages } from "../../utils/helper";

const EvaluatorRetailView = ({
  evaluator,
  fetchRetailViewConnect,
  fetchEvaluatorViewConnect,
  storeId,
  isValidStore,
  resetEvaluatorByKeyConnect
}) => {
  // const retailViewWrapper = useRef(null);
  const evaluatorViewWrapper = useRef(null);
  const [retailViewTable, setRetailViewTable] = useState(true);
  const [evaluatorViewTable, setEvaluatorViewTable] = useState(false);
  const { retailView, evaluatorView, isLoading, error } = evaluator;
  const { data: retailData } = retailView;
  const { data: evaluatorData } = evaluatorView;
  const [clickToggle, setClickToggle] = useState(false);

  useEffect(() => {
    if (!isValidStore) {
      showToastMessages("Not a Valid Store", false);
      resetEvaluatorByKeyConnect(RESET_REDUCER_STATE_KEYS.ERROR, "Not a valid Store");
    }
    resetEvaluatorByKeyConnect(RESET_REDUCER_STATE_KEYS.IS_LOADING, true);
    fetchRetailViewConnect(storeId);
    fetchEvaluatorViewConnect(storeId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickToggle]);

  const getTimefromDateString = (dateString) => {
    if (dateString) {
      let dateFormat = new Date(dateString).toLocaleTimeString("en-In", { hour: "2-digit", minute: "2-digit" });
      return dateFormat;
    }
  };

  const getCarModel = (row) => {
    return `${row.model} (${row.year})`;
  };

  const emailToName = (emailid) => {
    if (emailid && emailid.indexOf('@') !== -1 && emailid.indexOf('.') !== -1) {
      let name = emailid.split('@')[0];
      if (name.indexOf('.') !== -1) {
        let name1 = name.split('.');
        let firstName = name1[0].charAt(0).toUpperCase() + name1[0].substr(1).toLowerCase();
        let lastName = name1[1].charAt(0).toUpperCase() + name1[1].substr(1).toLowerCase();
        emailid = firstName + " " + lastName;
      }
      else {
        emailid = name.charAt(0).toUpperCase() + name.substr(1).toLowerCase();
      }
    }
    return (emailid ? emailid : '-');

  };

  const getColorCode = (token) => {
    if (token.expectedStartTime) {
      let startDate = new Date(token.expectedStartTime);
      let currentDate = new Date();
      let dateDifference = startDate.getTime() - currentDate.getTime();
      //time difference in number of minutes
      let timeDiffrence = Math.ceil(dateDifference / (60 * 1000));
      let color = "pending";
      if (timeDiffrence < 0) {
        color = "denied";
      } else if (timeDiffrence <= 10 && timeDiffrence >= 0) {
        color = "starts";
      }
      return color;
    }
  };

  const getInspectionStatus = (token) => {
    if (token.status === "9" && token.auctionStatus !== 'not_started' && token.auctionStatus !== 'suspend') {
      return {
        text: "Done",
        style: "done"
      };
    }
    else if (token.status === "2") {
      return {
        text: "Denied",
        style: ""

      };
    }
    else if (token.status === "3") {
      return {
        text: "Time-Out",
        style: ""
      };
    }

    else if (token.status === "0") {
      return {
        text: "Starts at \n" + getTimefromDateString(token.expectedStartTime),
        style: getColorCode(token)
      };
    }
    else if (token.auctionStatus === 'not_started' || token.auctionStatus === 'suspend') {
      return {
        text: "Critical Reject",
        style: "denied"
      };
    }
    else if (token.status === "1") {
      return {
        text: "In Progress",
        style: ""
      };
    }
    else {
      return {
        text: "-",
        style: ""
      };
    }
  };

  const getInspectionEndTime = (token) => {
    if (token.status === "2" || token.status === "1") {
      return {
        text: " "
      };
    }
    else if (token.status === "9" && token.inspectionEndTime != null) {
      return {
        text: getTimefromDateString(token.inspectionEndTime)
      };
    }
    else if (token.status === "0" || token.status === "3") {
      return {
        text: "-"
      };
    }

    else {
      return {
        text: "-"
      };
    }

  };

  const getSuggestedC24Quote = (token) => {
    if (parseInt(token.suggestedC24BuyingQuote) > 0) {
      return {
        text: "Yes"
      };
    }
    else if (token.suggestedC24BuyingQuote === "0" || token.suggestedC24BuyingQuote === null) {
      return {
        text: "No"
      };
    }
    else {
      return {
        text: "-"
      };
    }
  };

  return (
    <>
      {
        !error && <div>
          <div className={styles.evaluatorRetailView}>
            <p onClick={() => {
              setClickToggle(!clickToggle);
            }} className={styles.reload}>Reload</p>
            <div className={`${styles.retailView} ${retailViewTable ? styles.minusIcon : styles.plusIcon}`}
              onClick={() => setRetailViewTable(!retailViewTable)}>
              <p>Retail View</p>
            </div>
            {retailViewTable &&
            <div className={styles.retailViewTable}>
              <table cellPadding="0" cellSpacing="0" width="100%">
                <thead>
                  <tr>
                    <th>Token no.</th>
                    <th>Appointment ID</th>
                    <th>Car Model</th>
                    <th>Check-in Time</th>
                    <th>Assigned To</th>
                    <th>Insp. Status</th>
                    <th>Started By</th>
                    <th>Insp. End Time</th>
                    <th>Assigned RA</th>
                    <th>C24Q Generated</th>
                    <th>Quote Shared</th>
                    {/*<th>Done By</th>*/}
                  </tr>
                </thead>
                <tbody>
                  {
                    retailData && retailData.map((row, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{row.appointment}</td>
                          <td>{getCarModel(row)}</td>
                          <td>
                            <span className={styles["font-weight-bold"]}>
                              {getTimefromDateString(row.checkInDatetime)}
                            </span>
                          </td>
                          <td>{emailToName(row.assignedBy)}</td>
                          <td>
                            <span
                              className={`${styles["font-weight-bold"]} ${styles[(getInspectionStatus(row)).style]}`}>
                              {(getInspectionStatus(row)).text}
                            </span>
                          </td>
                          <td>{emailToName(row.startedBy)}</td>
                          <td>{getInspectionEndTime(row).text}</td>
                          <td>{row.firstName + ' ' + row.lastName}</td>
                          <td>{getSuggestedC24Quote(row).text}</td>
                          <td>
                            <div className={styles.onoffswitch}>
                              <input type="checkbox" name=""
                                className={styles.onoffswitchCheckbox}
                                checked={row.isSharedC24Quote === "1"} />
                              <label className={styles.onoffswitchLabel} htmlFor="yes1">
                                <span className={styles.onoffswitchInner}></span>
                                <span className={styles.onoffswitchSwitch}></span>
                              </label>
                            </div>
                          </td>
                          {/*<td>{emailToName(row.doneBy)}</td> Done By*/}
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </div>
            }
          </div>
          <div className={styles.evaluatorView}>
            <p onClick={() => {
              setClickToggle(!clickToggle);
            }} className={styles.reload}>Reload</p>
            <div ref={evaluatorViewWrapper}
              className={`${styles.retailView} ${evaluatorViewTable ? styles.minusIcon : styles.plusIcon}`}
              onClick={() => setEvaluatorViewTable(!evaluatorViewTable)}>
              <p>Evaluator View</p>
            </div>
            {evaluatorViewTable &&
            <div className={styles.retailViewTable}>
              <table cellPadding="0" cellSpacing="0" width="100%">
                <thead>
                  <tr>
                    <th>Evaluator Name</th>
                    <th>Latest Insp. Start Time</th>
                    <th>Latest Insp. End Time</th>
                    <th>Evaluator Status</th>
                    <th>Upcoming Insp. (Token No.)</th>
                    <th>Inspection Count(Today)</th>
                    <th>Inspection Count(MTD)</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    evaluatorData && evaluatorData.map((evaluator) => {
                      return (
                        <tr>
                          <td>{emailToName(evaluator.email_id)}</td>
                          <td><span
                            className={styles.pending}>{getTimefromDateString(evaluator.latest_ins_start_time)}</span>
                          </td>
                          <td><span
                            className={styles.pending}>{getTimefromDateString(evaluator.latest_ins_end_time)}</span>
                          </td>
                          <td>
                            <span
                              className={evaluator.event === 'inspection_start' ? styles.pending : styles.done}>
                              {evaluator.event === 'inspection_start' ? 'Inspection Ongoing' : 'Available'}
                            </span>
                          </td>
                          <td>{evaluator.next_token_number === "" ? "-" : evaluator.next_token_number}</td>
                          <td>{evaluator.today_done == null ? 0 : evaluator.today_done}</td>
                          <td>{evaluator.mtd == null ? 0 : evaluator.mtd}</td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </div>
            }
          </div>

        </div>
      }
      <DataInformationMessage
        isLoading={isLoading}
        error={error}
        data={[1]}
      />
    </>
  );
};

export default EvaluatorRetailView;
