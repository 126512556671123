import React, {useEffect, useState} from 'react';
import styles from './login-attendance-popup.module.scss';
import Button from '../shared/button/component';
import { SecureService } from '../../service';
import { getFromLocalStorage, getUid, saveToLocalStorage, showToastMessages } from '../../utils/helper';
import { LOCAL_STORAGE_KEYS, NUMBERS } from '../../utils/app-constants';
import { trackLMSEvents } from '../../utils/helpers/tracking-events';
import moment from 'moment';

const BUTTON_VALUES = {
  YES: 'y',
  NO: 'n'
};

const GA_EVENTS = {
  LOGIN_POPUP_VIEWED: "Login Attendance Popup Viewed",
  LOGIN_YES: "Login Attendance Yes Clicked",
  LOGIN_NO: "Login Attendance No Clicked",
  TOGGLE_LOGIN_POPUP_VIEWED: "Toggle Popup Viewed",
  TOGGLE_LOGIN_YES: "Toggle Popup Yes Clicked",
  TOGGLE_LOGIN_NO: "Toggle Popup No Clicked",
};

const LoginAttendancePopup = ({onClose, isAlreadyLogin = false, user, weekOff}) => {
  const [isUpdating, setIsUpdating] = useState(false);

  const trackGAEvent =(value)=>{
    trackLMSEvents(value, {
      eventLabel: value ,
      eventAction: user,
      eventCategory: 'LMS RA Attendance',
      lsRAID: getUid(),
      event: value,
      Timestamp: moment().format()
    });
  };

  useEffect(()=>{
    if (isAlreadyLogin) {
      trackGAEvent(GA_EVENTS.TOGGLE_LOGIN_POPUP_VIEWED);
    } else {
      trackGAEvent(GA_EVENTS.LOGIN_POPUP_VIEWED);
    }
  },[]);

  const onUpdateLoginActivity = (value) => {
    setIsUpdating(true);
    const localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
    const {weekOff} = JSON.parse(localData) || {};
    let payload = {
      'userEmail': user
    };
    let weekoffValue = weekOff;
    if (isAlreadyLogin) {
      if (value === BUTTON_VALUES.YES) {
        if (weekOff === NUMBERS.ONE) {
          weekoffValue = NUMBERS.TWO;
        }
        else if (weekOff === NUMBERS.TWO) {
          weekoffValue = NUMBERS.ONE;
        }
        trackGAEvent(GA_EVENTS.TOGGLE_LOGIN_YES);
      } else {
        trackGAEvent(GA_EVENTS.TOGGLE_LOGIN_NO);
      }
      payload = {...payload, 'weekOffStatus': weekoffValue, action:"weekoffUpdate", event: "toggle"};
    } else {
      weekoffValue = value === BUTTON_VALUES.YES ? NUMBERS.TWO : NUMBERS.ONE;
      payload = {...payload, 'weekOffStatus': weekoffValue, event: "login"};
      if (value === BUTTON_VALUES.YES) {
        trackGAEvent(GA_EVENTS.LOGIN_YES);
      } else {
        trackGAEvent(GA_EVENTS.LOGIN_NO);
      }
    }

    SecureService.updateLoginActivity(payload).then(()=>{
      let jsonData = JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA));
      jsonData["weekOff"] = weekoffValue;
      jsonData["showWeekoffStatus"] = !!isAlreadyLogin ? NUMBERS.TWO : NUMBERS.ONE;
      saveToLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA, JSON.stringify(jsonData));
    }).finally(()=>{
      setTimeout(()=>{
        onClose();
        window.location.reload();
      }, 2000);
    });
  };
  return (
    <div className={styles.loginAttendancePopup}>
      <p className={styles.headerText}>{isAlreadyLogin ? `Are you sure you want to change the status to ${weekOff === NUMBERS.TWO ? 'active' : 'inactive' }` :`Are you on a week off today`}?</p>
      {isAlreadyLogin ? <span className={styles.highlight}>For Today, You will not be allowed to toggle your status again.</span>  : <p className={styles.text}>You are allowed to toggle your status once per day.</p>}
      <div className={styles.ctaWrapper}>
        <Button ctaText= {'Yes'} classNames="cancelCta"  onClick={() => onUpdateLoginActivity(BUTTON_VALUES.YES)} disabled={isUpdating}/>
        <Button ctaText= {'No'} classNames="blueButton" onClick={() => onUpdateLoginActivity(BUTTON_VALUES.NO)} disabled={isUpdating}/>
      </div>
    </div>
  );
};

export default LoginAttendancePopup;
