import { createReducer } from "reduxsauce";
import Types from "./types";
import { GetQueryStringFromUrl, getUnique } from "../../utils/helper";
import sortByKey from "../../utils/helpers/sort-by-key";

export const INITIAL_STATE = {
  isLoading: true,
  columns: [],
  page: 0,
  data: [],
  error: null,
  total: 0,
  appointmentState: "inspected",
  sortKey: '',
  sortOrder: 'desc',
  filteredData: [],
  isFiltered: false,
  visitedFiltersCount: {
    pending: 0,
    inProgress: 0,
    total: 0
  },
  unAllocatedCount: 0,
  confirmRows: 0,
  nonConfirmRows: 0,
  loadMore: false,
  insuranceType:null,
  expiryCount: {
    nextday: 0,
    today: 0,
    lost: 0
  },
  assignReasons: [],
  prStatuses: [],
  retailAssociates: [],
  selectedRAs: [],
};

export const fetchAppointments = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};

export const fetchAppointmentsSuccess = (state = INITIAL_STATE, props ) => {
  const { data} = props;
  let _loadMore = true;
  const { totalRows, result}  = data;

  let rowData = [...state.data, ...result];
  rowData = getUnique(rowData, 'appointmentId');

  if (!!rowData && rowData.length > 0 && (totalRows > rowData.length)) {
    _loadMore = true;
  }
  else {
    _loadMore = false;
  }
  return { ...state, total: totalRows, data: rowData, isLoading: false, error: null,loadMore:_loadMore};
};

export const fetchAppointmentsFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, error, data: [], isLoading: false, filteredData: [], isFiltered: false };
};

export const setAppointmentState = (state = INITIAL_STATE, { appointmentState }) => {
  return { ...state, appointmentState };
};

export const searchAndFilterAppointments = (state = INITIAL_STATE, { filteredData }) => {
  return { ...state, filteredData, isFiltered: true };
};

export const removeSearch = (state = INITIAL_STATE) => {
  return { ...state, isFiltered: false, filteredData: [] };
};

export const resetAppointments = () => {
  return INITIAL_STATE;
};

export const resetAppointmentByKey = (state = INITIAL_STATE, { data }) => {
  const { key, value } = data;
  return { ...state, [key]: value };
};
export const sortAppointmentByKey = (state = INITIAL_STATE, { data }) => {
  const { key, sortOrder } = data;
  let rowData = [...state.data];
  let newrowData = sortByKey(rowData, key, sortOrder);
  return { ...state, data: newrowData, sortKey: key, sortOrder: sortOrder };

};
export const resetSortOrderAndKey = (state = INITIAL_STATE, { data }) => {
  const { key, sortOrder } = data;
  return { ...state, sortKey: key, sortOrder: sortOrder };

};

export const HANDLERS = {
  [Types.FETCH_APPOINTMENTS]: fetchAppointments,
  [Types.FETCH_APPOINTMENTS_SUCCESS]: fetchAppointmentsSuccess,
  [Types.FETCH_APPOINTMENTS_FAILURE]: fetchAppointmentsFailure,
  [Types.SET_APPOINTMENT_STATE]: setAppointmentState,
  [Types.SEARCH_AND_FILTER_APPOINTMENTS]: searchAndFilterAppointments,
  [Types.REMOVE_SEARCH]: removeSearch,
  [Types.RESET_APPOINTMENTS]: resetAppointments,
  [Types.RESET_APPOINTMENT_BY_KEY]: resetAppointmentByKey,
  [Types.SORT_APPOINTMENTS_BY_KEY]: sortAppointmentByKey,
  [Types.RESET_SORT_ORDER_BY_KEY]: resetSortOrderAndKey
};

export default createReducer(INITIAL_STATE, HANDLERS);
