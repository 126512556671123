import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PllStoreList from "./component";
import { fetchPllStores,updateStoreListForCheck,fetchStoresAppointments,resetPLLByKey,fetchPllAssociates } from "./actions";

const mapStateToProps = ({
  pllStores,
  pllAllocationFilter
}) => ({
  pllStores, //RootReducers,
  pllAllocationFilter
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchPllStoresConnect: fetchPllStores,
  updateStoreListForCheckConnect: updateStoreListForCheck,
  fetchStoresAppointmentsConnect: fetchStoresAppointments,
  resetPLLByKeyConnect: resetPLLByKey,
  fetchPllAssociatesConnect: fetchPllAssociates,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PllStoreList);
