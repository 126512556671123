/* eslint-disable indent */
import React, { useEffect } from "react";
import PllStoreList from "../../components/pll-store-list/";
import PllLeadAllocation from "../../components/pll-lead-allocation/";

const PllLeads = ({ resetPllAllocationConnect }) => {
	useEffect(() => {
		return () => {
			// Reset on unmount
			resetPllAllocationConnect();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<PllStoreList />
			<PllLeadAllocation />
		</div>
	);
};

export default PllLeads;
