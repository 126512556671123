import Types from "./types";

const updateFilterQuery = (filterQuery) => ({
  type: Types.UPDATE_FILTER_QUERY,
  filterQuery
});

const updateMissedCallFilterQuery = (missedCallQuery) => ({
  type: Types.NEGO_DATA_MISSED_CALL_QUERY,
  missedCallQuery
});

const updateWithCustomer = (value) => (
  {
    type: Types.NEGO_DATA_WITH_CUSTOMER,
    withCustomer: value
  }
);

const updateWithCustomerCount = (value) => (
  {
    type: Types.NEGO_DATA_WITH_CUSTOMER_COUNT,
    withCustomerCount: value
  }
);

const updateActionRequiredCount = (value) => (
  {
    type: Types.NEGO_DATA_ACTION_REQUIRED_COUNT,
    actionRequiredCount: value
  }
);

export {
  updateFilterQuery,
  updateMissedCallFilterQuery,
  updateWithCustomer,
  updateWithCustomerCount,
  updateActionRequiredCount
};
