import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  paymentProcessingFilterQuery: "&filterByPreviousWeek=false"
};

export const updatepaymentProcessingFilterQuery = (state = INITIAL_STATE, { paymentProcessingFilterQuery }) => {
  return { ...state, paymentProcessingFilterQuery };
};

export const HANDLERS = {
  [Types.UPDATE_PAYMENT_PROCESSING_FILTER_QUERY]: updatepaymentProcessingFilterQuery

};
export default createReducer(INITIAL_STATE, HANDLERS);
