import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MarketingSurvey from "./component";
import { fetchMarketingQuestions } from "./actions";

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchMarketingQuestionsConnect: fetchMarketingQuestions
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MarketingSurvey);