import React, { useEffect, useState} from "react";
import styles from "./document-upload.module.scss";
import BackArrow from "../../components/images/back-arrow.svg";
import H2 from "../../components/h2/component";
import { navigateToHomeWithDelay } from "../../utils/helper";
import Upload from "../../components/images/upload.svg";
import AadharFront from "../../components/images/aadharFront.svg";
import AadharBack from "../../components/images/aadharBack.svg";
import PdfIcon from "../../components/images/pdfIcon.svg";
import InputText from "../../components/shared/input-text";
import Button from "../../components/shared/button/component";
import { AppointmentService } from "../../service";
import { showToastMessages} from "../../utils/helper";
import priceFormatter from "../../utils/helpers/price-formatter";
import SelectComponent from "../../components/shared/select/component";
import Checkbox from "../../components/shared/checkbox";
import { TOKEN_PAYMENT_STATUS, MAX_ALLOWED_UPLOAD_SIZE, NUMBERS, PDF_MIME_TYPE, PDF_FILE_EXTENSION } from "../../utils/app-constants";
import DoucumnetUploadRto from "../../components/doucumnet-upload-rto";
import { getBikeInitiateTokenPayment, getBikeRATokenMock } from "../../utils/mocApi";
import { openUrlTab } from "../../utils/helper";
import InputTextGreenTick from "../../components/shared/input-text-green-tick/component";
import hasSpecialCharacters from "../../utils/helpers/has-special-characters";
import RadioButton from "../../components/shared/radio-button";
import Popup from "../../components/popup/component";
import BikeReRaiseInitiatePaymentPopup from "../../components/bike-re-raise-initiate-payment-popup/component";
import SelectDocumentUpload from "../../components/shared/selectDocumentUpload";
// import BikeInitiatePaymentPopup from "../../components/bike-initiate-payment-popup/component";

const allowedFileTypes = ".png, .jpg, .jpeg, .pdf";

const IMAGE_TYPE = {
  AADHAR_FRONT: "AADHAR_FRONT",
  AADHAR_BACK: "AADHAR_BACK",
  PAN_CARD: "PAN_CARD",
  CANCELLED_CHEQUE: "CANCELLED_CHEQUE",
  CONSENT_LETTER: "CONSENT_LETTER"
};

const LOAN_STATUS = {
  OPEN: "Open",
  CLOSED: "Closed"
};

const NOC_STATUS = {
  YES: "Yes",
  NO: "No"
};

const DocumentUpload = ({ history, match, fetchNegotiationsConnect, data }) => {
  const [negotiationData, setNegotiationData] = useState();
  const [tokenPaymentData, setTokenPaymentData] = useState();
  const [aadharCardFrontBlob, setAadharCardFrontBlob] = useState();
  const [aadharCardBackBlob, setAadharCardBackBlob] = useState();
  const [panCardBlob, setPanCardBlob] = useState();
  const [cancelledChequeBlob, setcancelledChequeBlob] = useState();
  const [consentLetterBlob, setConsentLetterBlob] = useState();
  const [accountNumber, setAccountNumber] = useState('');
  const [accountHolderName, setAccountHolderName] = useState('');
  const [ifsCode, setIfsCode] = useState('');
  const [formReadOnly, setFormReadOnly] = useState(false);

  const [aadharCardFrontLink, setAadharCardFrontLink] = useState();
  const [aadharCardBackLink, setAadharCardBackLink] = useState();
  const [panCardLink, setPanCardLink] = useState();
  const [cancelledChequeLink, setCancelledChequeLink] = useState();
  const [consentLetterLink, setConsentLetterLink] = useState();
  const [bankDetails, setBankDetails] = useState({ bankName: null, bankId: null});
  const [bankList, setBankList] = useState([]);
  const [purchaseRequestId , setPurchaseRequestId ] = useState(null);

  const [invalidfsc, setInvalidIfsc] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const [charges, setCharges] = useState(null);
  const [holdbacks, setHoldbacks] = useState(null);
  const [totalCharges, setTotalCharges] = useState(0);
  const [totalHoldbacks, setTotalHoldbacks] = useState(0);
  const [activeDocumentIndex, setActiveDocumentIndex] = useState();
  const [reload, setReload] = useState(true);
  const { params: { appointmentId } } = match;
  const backToHome = () => {
    navigateToHomeWithDelay(history);
  };
  const [regNumber, setRegNumber] = useState('');
  const [ownerNameOnRc, setOwnerNameOnRc] = useState('');
  const [bankChecked, setBankChecked] = useState(false);
  const [primaryBank, setPrimaryBank] = useState(null);
  const [isFormActionSubmit, setIsFormActionSubmit] = useState(false);
  const [curVahanStatus, setCurVahanStatus] = useState('');
  const [vahanUrl, setVahanUrl] = useState();
  const [hypothecation, setHypothecation] = useState(false);
  const [validBankNocAvailable, setValidBankNocAvailable] = useState();
  const [hypothecationBankDetail, setHypothecationBankDetail] = useState();
  const [loanClosureAmt, setLoanClosureAmt] = useState();
  const [loanStatus, setLoanStatus] = useState();
  const [hypoEnabled, setHypoEnabled] = useState(false);
  const [regOpen, setRegOpen] = useState(false);
  const [isInvalidLoanStatus, setIsInvalidLoanStatus] = useState(false);
  const [isInvalidBankDetail, setIsInvalidBankDetail] = useState(false);
  const [isInvalidLoanAmount, setIsInvalidLoanAmount] = useState(false);
  const [isInvalidNOCStatus, setIsInvalidNOCStatus] = useState(false);

  useEffect(() => {
    if (appointmentId && reload) {
      let globalSearchQuery = appointmentId + "&globalSearch=true";

      fetchNegotiationsConnect(1, globalSearchQuery, null, null, null)
        .then(resp => {
          if (resp.data) {
            const { data: { detail: { data: { result } } } } = resp;
            if (result.length > 0) {
              const negoData = result.find(negotiation => negotiation.appointmentId === appointmentId);
              if (negoData) {
                setNegotiationData(negoData);
                if(negoData.tokenPaymentStatus === TOKEN_PAYMENT_STATUS.SUBMITTED || negoData.tokenPaymentStatus === TOKEN_PAYMENT_STATUS.DONE){
                  setFormReadOnly(true);
                }
              }
            }
          }
        })
        .catch(error => console.log(error));

      AppointmentService.getBikeInitiateTokenPayment(appointmentId)
        .then(response => {
          // response = getBikeInitiateTokenPayment();
          const { data: { detail } } = response;
          setTokenPaymentData(detail);
          const {vahanStatus= '', vahanDetailUrl= '', hypothecation, validBankNocAvailable, 
            loanClosureAmt, loanStatus} = detail || {};
          setCurVahanStatus(vahanStatus);
          setHypoEnabled(vahanStatus === "completed");
          setVahanUrl(vahanDetailUrl);
          setHypothecation(hypothecation);
          setValidBankNocAvailable(validBankNocAvailable);
          setLoanClosureAmt(loanClosureAmt);
          setLoanStatus(loanStatus);
          if (loanStatus !== null && !loanStatus) {
            setLoanStatus(LOAN_STATUS.OPEN);
          }
          if (loanStatus !== null && !!loanStatus) {
            setLoanStatus(LOAN_STATUS.CLOSED);
          }
          if (validBankNocAvailable !== null && !validBankNocAvailable) {
            setValidBankNocAvailable(NOC_STATUS.NO);
          }
          if (validBankNocAvailable !== null && !!validBankNocAvailable) {
            setValidBankNocAvailable(NOC_STATUS.YES);
          }
          if(detail.accountNumber) setAccountNumber(detail.accountNumber);
          if(detail.accountHolderName) setAccountHolderName(detail.accountHolderName);
          if(detail.ifscCode) setIfsCode(detail.ifscCode);
          if(detail.thumbAadharFrontPhoto) setAadharCardFrontLink(detail.thumbAadharFrontPhoto);
          if(detail.thumbAadharBackPhoto) setAadharCardBackLink(detail.thumbAadharBackPhoto);
          if(detail.thumbPanPhoto) setPanCardLink(detail.thumbPanPhoto);
          if(detail.thumbCancelledChequePhoto) setCancelledChequeLink(detail.thumbCancelledChequePhoto);
          if(detail.thumbConsentLetterPhoto) setConsentLetterLink(detail.thumbConsentLetterPhoto);
          if(detail.purchaseRequestId) setPurchaseRequestId(detail.purchaseRequestId);
          detail.regNumber && setRegNumber(detail.regNumber);
          detail.ownerNameOnRc && setOwnerNameOnRc(detail.ownerNameOnRc);
          getBankPrList(detail.bankId);
          if (!!detail.bankId || !!detail.ifscCode || !!detail.accountNumber || !!detail.accountHolderName) {
            setBankChecked(true);
          }
        }).catch(error => {
          getBankPrList();
          console.log('Error fetching getBikeInitiateTokenPayment', error);
        });
    }
  }, [reload]);

  useEffect(() => {
    const {holdback, charges, hypothecationBank} = tokenPaymentData || {};
    if (charges) {
      setCharges(charges);
      let calculateCharges =  getTotalCharges(charges);
      setTotalCharges(calculateCharges);
    }
    if (holdback) {
      setHoldbacks(holdback);
      let calculatedHoldbacks = getTotalCharges(holdback);
      setTotalHoldbacks(calculatedHoldbacks);
    }
    let primaryBank = !!tokenPaymentData && !!tokenPaymentData.primaryBankDetails && !!tokenPaymentData.primaryBankDetails.bankId && bankList && bankList.find((item)=> item.bankId === parseInt(tokenPaymentData.primaryBankDetails.bankId));
    primaryBank && setPrimaryBank(primaryBank);

    let hypoBank = !!hypothecationBank && bankList && bankList.find((item)=> item.bankName === hypothecationBank);
    hypoBank && setHypothecationBankDetail(hypoBank);
  }, [tokenPaymentData, bankList]);

  const getBankPrList = (bankId) => {
    AppointmentService.getPrBankList(appointmentId)
      .then(response => {
        const { data: { detail: { detail } } } = response;
        bankId && detail.forEach(value => {
          if(value.bankId === parseInt(bankId)){
            setBankDetails({
              ...bankDetails,
              bankName: value.bankName,
              bankId: value.bankId
            });
            return;
          }
        });
        setBankList(detail || []);
      });
  };
    
  const isValidHypo = () => {
    setIsInvalidLoanStatus(false);
    setIsInvalidBankDetail(false);
    setIsInvalidLoanAmount(false);
    setIsInvalidNOCStatus(false);
    if (!loanStatus) {
      setIsInvalidLoanStatus(true);
      showToastMessages("Loan status shouldn't be empty", false);
      return false;
    } else if (loanStatus === LOAN_STATUS.OPEN) {
      if (!hypothecationBankDetail && !loanClosureAmt) {
        showToastMessages("Bank details and Loan closure amount are mandatory", false);
        setIsInvalidBankDetail(true);
        setIsInvalidLoanAmount(true);
        return false;
      }else if (!hypothecationBankDetail || (!!hypothecationBankDetail && !hypothecationBankDetail.bankName)) {
        showToastMessages("Bank details is mandatory", false);
        setIsInvalidBankDetail(true);
        return false;
      }else if (!loanClosureAmt || (!!loanClosureAmt && Number(loanClosureAmt) <= 0)) {
        showToastMessages("Loan closure amount is mandatory", false);
        setIsInvalidLoanAmount(true);
        return false;
      }
    } else if (loanStatus === LOAN_STATUS.CLOSED) {
      if (!validBankNocAvailable) {
        showToastMessages("NOC status is mandatory", false);
        setIsInvalidNOCStatus(true);
        return false;
      } 
    }
    return true;
  };

  const onDocumentAction = (action) => {
    setSubmitted(true);
    let isValid =  !!appointmentId;
    if (action === 'submit') {
      setIsFormActionSubmit(true);
      isValid =  !!regNumber && !!ownerNameOnRc;
      if (!!isValid && !!hypothecation && !isValidHypo()) {
        isValid = false;
      }
    }
    if (isValid) {
      let payload = new FormData();
      payload.append('appointmentId', appointmentId);
      // payload.append('aadharNumber', aadharNumber);
      if(!aadharCardFrontLink) payload.append('aadharFrontPhoto', aadharCardFrontBlob);
      if(!aadharCardBackLink) payload.append('aadharBackPhoto', aadharCardBackBlob);
      // payload.append('panNumber', panNumber);
      if(!panCardLink) payload.append('panPhoto', panCardBlob);
      payload.append('accountNumber', accountNumber);
      payload.append('accountHolderName', accountHolderName);
      payload.append('ifscCode', ifsCode);
      payload.append('charges', JSON.stringify(charges));
      payload.append('holdback', JSON.stringify(holdbacks));
      payload.append('bankId', bankDetails.bankId);
      if(!cancelledChequeLink) payload.append('cancelledChequePhoto', cancelledChequeBlob);
      if(!consentLetterLink) payload.append('consentLetterPhoto', consentLetterBlob);
      payload.append('action', action);
      payload.append('purchaseRequestId', purchaseRequestId);
      payload.append('regNumber', regNumber);
      payload.append('ownerNameOnRc', ownerNameOnRc);
      payload.append('hypothecation', hypothecation);
      payload.append('hypothecationBank', hypothecationBankDetail ? hypothecationBankDetail.bankName : '');
      payload.append('loanClosureAmt', loanClosureAmt);
      payload.append('validBankNocAvailable', validBankNocAvailable === NOC_STATUS.YES ? true : validBankNocAvailable === NOC_STATUS.NO ? false: null );
      payload.append('loanStatus', loanStatus === LOAN_STATUS.CLOSED ? true : loanStatus === LOAN_STATUS.OPEN ? false : null);
            
      AppointmentService.postBikeInitiateTokenPayment(payload)
        .then(response => {
          const { data: { detail } } = response;
          showToastMessages(detail.message, true);
          setTimeout(() => {
            history.push('/negotiation');
          }, 1000);
        })
        .catch(error => {
          setSubmitted(false);
          const { detail } = error;
          showToastMessages(detail.message, false);
        });
    } else {
      setSubmitted(false);
    }
  };

  const onImageUpload = (args, image_type) => {
    const { target: { files } } = args;
    if (files && files.length > 0) {
      const selectedFile = files[0];
      if (selectedFile.size > MAX_ALLOWED_UPLOAD_SIZE) {
        showToastMessages(`File Size more than 5Mb`, false);
      } else {
        switch (image_type) {
          case IMAGE_TYPE.AADHAR_FRONT:
            // setAadharCardFrontBlob(window.URL.createObjectURL(selectedFile));
            setAadharCardFrontBlob(selectedFile);
            setAadharCardFrontLink(null);
            break;
          case IMAGE_TYPE.AADHAR_BACK:
            // setAadharCardBackBlob(window.URL.createObjectURL(selectedFile));
            setAadharCardBackBlob(selectedFile);
            setAadharCardBackLink(null);
            break;
          case IMAGE_TYPE.PAN_CARD:
            // setPanCardBlob(window.URL.createObjectURL(selectedFile));
            setPanCardBlob(selectedFile);
            setPanCardLink(null);
            break;
          case IMAGE_TYPE.CANCELLED_CHEQUE:
            // setcancelledChequeBlob(window.URL.createObjectURL(selectedFile));
            setcancelledChequeBlob(selectedFile);
            setCancelledChequeLink(null);
            break;
          case IMAGE_TYPE.CONSENT_LETTER:
            // setcancelledChequeBlob(window.URL.createObjectURL(selectedFile));
            setConsentLetterBlob(selectedFile);
            setConsentLetterLink(null);
            break;
          default:
            break;
        }
      }
    }
  };

  // const onAadharNumberChange = (e) => {
  //     const { target: { value } } = e;
  //     if (!isNaN(value)) {
  //         setAadharNumber(value.trim());
  //         if(value.trim().length < 12) setInvalidAaadhar(true);
  //         else setInvalidAaadhar(false);
  //     }
  // };
  // const onPanNumberChange = (e) => {
  //     // var regex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
  //     const { target: { value } } = e;
  //     // if(regex.test(value)){
  //     setPanNumber(value.trim());
  //     if(value.trim().length < 10) setInvalidPan(true);
  //     else setInvalidPan(false);
  //     // }
  // };

  const onRegNumberChange =(e) => {
    const { target: { value } } = e;
    setRegNumber(value);
  };

  const onOwnerNameOnRcChange =(e) => {
    const { target: { value } } = e;
    setOwnerNameOnRc(value);
  };

  const onAccountNumberChange = (e) => {
    const { target: { value } } = e;
    if (!isNaN(value)) {
      setAccountNumber(value);
    }
  };
  const onAccountHolderNameChange = (e) => {
    const { target: { value } } = e;
    setAccountHolderName(value);
  };
  const onIfsCodeChange = (e) => {
    // var regNumbers = /[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/;  
    const { target: { value } } = e;
    // if (regNumbers.test(value)) {
    setIfsCode(value);
    if(value.trim().length < 11) setInvalidIfsc(true);
    else setInvalidIfsc(false);
    // }
  };

  const getTotalCharges = (list) => {
    if (!list) {
      return 0;
    }
    return list.reduce((acc, curr) => {
      if (curr.amount) {
        return acc + Math.round(+curr.amount);
      }
      return acc;
    }, 0);
  };

  const onChargesChange = (e) => {
    const { target: { value, name } } = e;
    const index = charges.findIndex((item)=> item.key === name);
    charges[index].amount = value;
    let calculateCharges =  getTotalCharges(charges);
    setTotalCharges(calculateCharges);
  };

  const onHoldbackChange = (e) => {
    const { target: { value, name } } = e;
    const index = holdbacks.findIndex((item)=> item.key === name);
    holdbacks[index].amount = value;
    let calculatedHoldbacks = getTotalCharges(holdbacks);
    setTotalHoldbacks(calculatedHoldbacks);
  };

  const onBankNameChange = (value) => {
    setBankDetails({
      ...bankDetails,
      bankName: value.bankName,
      bankId: value.bankId
    });
  };

  const getChargeAmount = (key) => {
    const item = charges.find((item)=> item.key === key);
    if (item) {
      return item.amount;
    } 
    return "";
  };

  const getHoldBacksAmount = (key) => {
    const item = holdbacks.find((item)=> item.key === key);
    if (item) {
      return item.amount;
    } 
    return "";
  };

  const onBankChecked = () => {
    setBankChecked(!bankChecked);
  };

  const onClickVahanDetails = () => {
    !!vahanUrl && window.open(vahanUrl,  "_blank", 'noopener');
  };

  const onRegClose = () => {
    setRegOpen(false);
    setReload(true);
  };

  const onClickRegNumber = () => {
    setRegOpen(true);
    setReload(false);
  };

  const onDocumentSectionClick = (index) => {
    if (index === activeDocumentIndex) {
      setActiveDocumentIndex();
    } else {
      setActiveDocumentIndex(index);
    }
  };
  return (
    <div className={styles.documentUpload}>
      <div className={styles.documentHeading}>
        <div className={styles.back}>
          <img alt="back" className={styles.back} src={BackArrow} onClick={backToHome} />
        </div>
        <H2 text={`${!!negotiationData ? negotiationData.customerName : ''}, ${appointmentId}`} />
      </div>
            
      <div className={styles.documentskycSection}>
        <div className={styles.rejectionReason}>
          <p>Purchase Request Rejection Reason</p>
          <ul>
            <li>NOC form not attached</li>
            <li>Cheque image not clear</li>
          </ul>
          <div className={styles.prWrapper}>
            <div>
              <p>PR submitted on:</p>
              <p>PR raised by:</p>
            </div>
            <div>
              <span>14 - Oct - 2021</span>
              <span>komal.pal@cars24.com</span>
            </div>
          </div>
        </div>

        <div className={styles.vahanWrapper}>
          <p>Vahan Background Check :  <span>{curVahanStatus}</span></p>
          <Button classNames="blueButton" ctaText="VIEW VAHAN deatils" onClick={onClickVahanDetails} disabled={!hypoEnabled}/>
        </div>
        <div className={styles.documentKycWrapper}>
          <h3>Bike & Owner Details</h3>
          <div className={styles.documentKyc50}>
            <div className={styles.withoutkyc}>
              {!regNumber || formReadOnly ? <InputTextGreenTick
                text="Registration Number*"
                errorMessage={"It seems you have put wrong Registration number. Please check again."}
                value={regNumber}
                maxLength={NUMBERS.TEN}
                onChange={onRegNumberChange}
                validator={hasSpecialCharacters}
                disabled={formReadOnly}
                isFixedLength={true}
              /> :  <div className={styles.regNumber} onClick={() => onClickRegNumber()}>
                <span>Registration Number</span>
                <p>{regNumber}</p>
              </div>
              }
              {!regNumber && isFormActionSubmit && <small className={styles.error}>Field Required</small> }
              <Popup isOpen={regOpen} close={onRegClose}>
                <BikeReRaiseInitiatePaymentPopup data={negotiationData} tokenPaymentData={tokenPaymentData} onClose={onRegClose} />
              </Popup>
            </div>
            <div className={styles.panCard}>
              <InputText
                text="Name on RC"
                value={ownerNameOnRc}
                onChange={onOwnerNameOnRcChange}
                disabled={formReadOnly || (!!tokenPaymentData && !!tokenPaymentData.ownerNameOnRc)}
              />
              {!ownerNameOnRc && isFormActionSubmit && <small className={styles.error}>Field Required</small> }
            </div>
          </div>  
          {/* need to update here   */}
          <div className={styles.documentKycUpload}>
            <div className={styles.kyc}>
              <p className="col-2">Aadhar Card</p>
              {<div className={styles.imageUpload}>
                <label for="file-input-aadhar-front">
                  <img src={!!aadharCardFrontBlob ? aadharCardFrontBlob.type === PDF_MIME_TYPE ? PdfIcon : URL.createObjectURL(aadharCardFrontBlob) : !!aadharCardFrontLink ? aadharCardFrontLink.includes(PDF_FILE_EXTENSION) ? PdfIcon : aadharCardFrontLink : AadharFront} alt="Front" title="Front" onClick={()=> openUrlTab(aadharCardFrontLink)}/>
                  {!formReadOnly && <span>{!!aadharCardFrontLink || !!aadharCardFrontBlob ? "Re-upload" : "Upload"}</span>}
                </label>
                {!formReadOnly && <input id="file-input-aadhar-front" type="file" onChange={(e) => onImageUpload(e, IMAGE_TYPE.AADHAR_FRONT)}
                  accept={allowedFileTypes} />}
              </div>
              }
              {<div className={styles.imageUpload}>
                <label for="file-input-aadhar-back">
                  <img src={!!aadharCardBackBlob ? aadharCardBackBlob.type === PDF_MIME_TYPE ? PdfIcon  : URL.createObjectURL(aadharCardBackBlob) : !!aadharCardBackLink ? aadharCardBackLink.includes(PDF_FILE_EXTENSION) ? PdfIcon : aadharCardBackLink : AadharBack} alt="upload" title="Back" onClick={()=> openUrlTab(aadharCardBackLink)}/>
                  {!formReadOnly && <span>{!!aadharCardBackLink || !!aadharCardBackBlob ? "Re-upload" : "Upload"}</span>}
                </label>
                {!formReadOnly && <input id="file-input-aadhar-back" type="file" onChange={(e) => onImageUpload(e, IMAGE_TYPE.AADHAR_BACK)}
                  accept={allowedFileTypes} />}
              </div>
              }
            </div>
                        
            <div className={styles.kyc}>
              <p className="col-2">PAN card photo</p>
              {<div className={styles.imageUpload}>
                <label for="file-input-pan-card">
                  <img src={!!panCardBlob ? panCardBlob.type === PDF_MIME_TYPE ? PdfIcon  : URL.createObjectURL(panCardBlob) : !!panCardLink ? panCardLink.includes(PDF_FILE_EXTENSION) ? PdfIcon : panCardLink : Upload} alt="upload" onClick={()=> openUrlTab(panCardLink)}/>
                  {!formReadOnly && <span>{!!panCardLink || !!panCardBlob ? "Re-upload" : "Upload"}</span>}
                </label>
                {!formReadOnly && <input id="file-input-pan-card" type="file" onChange={(e) => onImageUpload(e, IMAGE_TYPE.PAN_CARD)}
                  accept={allowedFileTypes} />}
              </div>
              }
            </div>
          </div>
          <div className={styles.hypoLoan}>
            <p>Is RC Hypothecation (HP, hypo) status Active?</p>
            <div className={styles.RadioButtonWrapper}>
              <RadioButton  
                id='hypothecation_yes'
                key='hypothecation_yes'
                text="Yes" 
                isDisabled={formReadOnly || hypoEnabled}
                checkedStatus={!!hypothecation}
                onClickCallback={() => setHypothecation(true)}
              />
              <RadioButton 
                id='hypothecation_no'
                key='hypothecation_no' 
                text="No"
                isDisabled={formReadOnly || hypoEnabled}
                checkedStatus={!hypothecation}
                onClickCallback={() => setHypothecation(false)}
              />
            </div>
            {hypothecation && <>
              <div className={styles.RadioButtonWrapper}>
                <p>What is the status of Loan?</p>
                <div className={styles.radioWrapper}>
                  <RadioButton
                    id={`loanStatus_${LOAN_STATUS.OPEN}`}
                    key={`loanStatus_${LOAN_STATUS.OPEN}`}
                    text={LOAN_STATUS.OPEN}
                    checkedStatus={loanStatus === LOAN_STATUS.OPEN}
                    onClickCallback={() => setLoanStatus(LOAN_STATUS.OPEN)}
                    isDisabled={formReadOnly}
                  />
                  <RadioButton  
                    id={`loanStatus_${LOAN_STATUS.CLOSED}`}
                    key={`loanStatus_${LOAN_STATUS.CLOSED}`}
                    text={LOAN_STATUS.CLOSED}
                    checkedStatus={loanStatus === LOAN_STATUS.CLOSED}
                    onClickCallback={() => setLoanStatus(LOAN_STATUS.CLOSED)}
                    isDisabled={formReadOnly}
                  />
                </div>
              </div>
              {isInvalidLoanStatus && isFormActionSubmit && <small className={styles.error}>Field Required</small>}
              {loanStatus === LOAN_STATUS.CLOSED && <div className={styles.nocWrapper}>
                <p>Is Bank NOC available?</p>
                <div className={styles.RadioButtonWrapper}>
                  <RadioButton
                    id='validBankNocAvailable_yes'
                    key='validBankNocAvailable_yes' 
                    text="Yes"
                    checkedStatus={validBankNocAvailable === NOC_STATUS.YES}
                    onClickCallback={() => setValidBankNocAvailable(NOC_STATUS.YES)}
                    isDisabled={formReadOnly}
                  />
                  <RadioButton  
                    id='validBankNocAvailable_no'
                    key='validBankNocAvailable_no' 
                    text="No"
                    checkedStatus={validBankNocAvailable === NOC_STATUS.NO}
                    onClickCallback={() => setValidBankNocAvailable(NOC_STATUS.NO)}
                    isDisabled={formReadOnly}
                  />
                </div>
                {isInvalidNOCStatus && isFormActionSubmit && <small className={styles.error}>Field Required</small>}
              </div>}
              {loanStatus === LOAN_STATUS.OPEN  && bankList && bankList.length > 0 && <div className={styles.loanBankName}>
                {hypothecationBankDetail && <SelectComponent
                  name={`hypothecationBank`}
                  placeholder={"Loan Bank Name"}
                  optionsList={bankList.map((item) => {
                    return {
                      label: item.bankName,
                      value: item
                    };
                  })}
                  onChange={(e)=> setHypothecationBankDetail(e)}
                  defaultValue={hypothecationBankDetail ? { label: hypothecationBankDetail.bankName, value: hypothecationBankDetail.bankId } : null}
                  isDisabled={formReadOnly}
                />}
                {!hypothecationBankDetail && <SelectComponent
                  name={`hypothecationBank`}
                  placeholder={"Loan Bank Name"}
                  optionsList={bankList.map((item) => {
                    return {
                      label: item.bankName,
                      value: item
                    };
                  })}
                  onChange={(e)=> setHypothecationBankDetail(e)}
                  isDisabled={formReadOnly}
                />}
                {isInvalidBankDetail && isFormActionSubmit && <small className={styles.error}>Field Required</small>}
                <InputText
                  type="number" 
                  text="Loan Closure Amount"
                  value={loanClosureAmt}
                  onChange={(e)=> setLoanClosureAmt(e.target.value)}
                  isDisabled={formReadOnly}
                />
                {isInvalidLoanAmount && isFormActionSubmit && <small className={styles.error}>Field Required</small>}

              </div>}
            </>}
          </div>
        </div>
        {/* // const {primaryPennyStatusLabel='', primaryBankDetails: {accountType = '', accountName = '', accountNumber = '', bankId = '', ifscCode = ''} = {}} = detail; */}
        <div className={styles.bankDetailsWrapper}>
          <h3>Bank Details</h3>
          <div className={styles.secondaryWrapper}>
            <h4>Primary bank</h4>
            <p>{(tokenPaymentData && tokenPaymentData.primaryPennyStatusLabel) || ""}</p>
          </div>
          <span className={styles.smallText}>mandatory</span>
          <div className={styles.bankDetails}>
            <div className={styles.kyc}>
              <InputText
                text="Account no"
                value={(tokenPaymentData && tokenPaymentData.primaryBankDetails && tokenPaymentData.primaryBankDetails.accountNumber) || ""}
                disabled
              />
            </div>
            <div className={styles.kyc}>
              <InputText
                text="Account holder name"
                value={(tokenPaymentData && tokenPaymentData.primaryBankDetails && tokenPaymentData.primaryBankDetails.accountName) || ""}
                disabled
              />
            </div>
            <div className={styles.kyc}>
              <InputText
                text="IFSC Code"
                value={(tokenPaymentData && tokenPaymentData.primaryBankDetails && tokenPaymentData.primaryBankDetails.ifscCode) || ""}
                disabled
              />
            </div>
            <div className={styles.withoutkyc}>
              {primaryBank && bankList && <SelectComponent
                name={`primaryBankName`}
                placeholder={"Select Bank"}
                optionsList={bankList.map((item) => {
                  return {
                    label: item.bankName,
                    value: item
                  };
                })}
                defaultValue={primaryBank ? { label: primaryBank.bankName, value: primaryBank.bankId } : null}
                isDisabled={true}
              />}
            </div>
          </div>
          <div className={styles.secondaryWrapper}>
            <h4>Secondary bank</h4>
            <Checkbox 
              id={'bankChecked'} 
              name={`chk_bankChecked`} 
              checkedStatus={bankChecked} 
              onChangeCallback={() => onBankChecked()} />
          </div>
          <span className={styles.smallText}>mandatory</span>
          <div className={styles.bankDetails}>
            <div className={styles.kyc}>
              <InputText
                text="Account no"
                value={accountNumber}
                onChange={onAccountNumberChange}
                disabled={formReadOnly || !bankChecked}
              />
            </div>
            <div className={styles.kyc}>
              <InputText
                text="Account holder name"
                value={accountHolderName}
                onChange={onAccountHolderNameChange}
                disabled={formReadOnly || !bankChecked}
              />
            </div>
            <div className={styles.kyc}>
              <InputText
                text="IFSC Code"
                maxLength={11}
                value={ifsCode}
                onChange={onIfsCodeChange}
                disabled={formReadOnly || !bankChecked}
              />
              {(submitted && invalidfsc && ifsCode) && <small className={styles.error}>Invalid IFSC Code</small> }
            </div>
            <div className={styles.withoutkyc}>
              {bankList.length > 0 && <SelectComponent
                name={`bankName`}
                placeholder={"Select Bank"}
                optionsList={bankList.map((item) => {
                  return {
                    label: item.bankName,
                    value: item
                  };
                })}
                questionId={appointmentId}
                onChange={onBankNameChange}
                defaultValue={bankDetails.bankId ? { label: bankDetails.bankName, value: bankDetails } : null}
                isDisabled={formReadOnly || !bankChecked}
              />}
            </div>
          </div>
          <div className={styles.documentKycUpload}>
            <div className={styles.kyc}>
              <p className="col-2">Cancelled cheque</p>
              {<div className={styles.imageUpload}>
                <label for="file-input-cancelled-cheque">
                  <img src={!!cancelledChequeBlob ? cancelledChequeBlob.type === PDF_MIME_TYPE ? PdfIcon  : URL.createObjectURL(cancelledChequeBlob) : !!cancelledChequeLink ? cancelledChequeLink.includes(PDF_FILE_EXTENSION) ? PdfIcon : cancelledChequeLink : Upload} alt="upload" onClick={()=> openUrlTab(cancelledChequeLink)}/>
                  {!formReadOnly && <span>{!!cancelledChequeLink || !!cancelledChequeBlob ? "Re-upload" : "Upload"}</span>}
                </label>
                {!formReadOnly && <input id="file-input-cancelled-cheque" type="file" onChange={(e) => onImageUpload(e, IMAGE_TYPE.CANCELLED_CHEQUE)}
                  accept={allowedFileTypes} />}
              </div>
              }
            </div>
            <div className={styles.kyc}>
              <p className="col-2">Third Party Consent</p>
              {<div className={styles.imageUpload}>
                <label for="file-input-consent-letter">
                  <img src={consentLetterBlob ? consentLetterBlob.type === PDF_MIME_TYPE ? PdfIcon  : URL.createObjectURL(consentLetterBlob) : !!consentLetterLink ? consentLetterLink.includes(PDF_FILE_EXTENSION) ? PdfIcon : consentLetterLink : Upload} alt="upload" onClick={()=> openUrlTab(consentLetterLink)}/>
                  {!formReadOnly && <span>{!!consentLetterLink || !!consentLetterBlob ? "Re-upload" : "Upload"}</span>}
                </label>
                {!formReadOnly && <input id="file-input-consent-letter" type="file" onChange={(e) => onImageUpload(e, IMAGE_TYPE.CONSENT_LETTER)}
                  accept={allowedFileTypes} />}
              </div>
              }
            </div>
          </div>
        </div>
        <div className={styles.documentUploadSection}>
          <h4>Documents Upload</h4>
          {!!tokenPaymentData && !!tokenPaymentData.category && Object.keys(tokenPaymentData.category).map((key, index)=>{
            return (
              <div className={`${styles.documentSubSection} ${ index === activeDocumentIndex ? styles.active: ''}`}>
                <h3 onClick={()=> onDocumentSectionClick(index)}>{`${index + 1}. ${key}`}</h3>
                <div className={styles.customerForm}> {
                  tokenPaymentData.category[key].docs && Object.keys(tokenPaymentData.category[key].docs).map((docKey, index)=>{
                    const docElement = tokenPaymentData.category[key].docs[docKey];
                    if (docElement.type === "dropdown") {
                      return (
                        <SelectDocumentUpload  
                          parent={key} 
                          docKey={docKey} 
                          data={docElement} 
                          allowedFileTypes={allowedFileTypes}
                          formReadOnly={formReadOnly}
                          appointmentId={appointmentId} 
                        />
                      );
                    } else {
                      const docName = docElement.docs[0].name;
                      return (
                        <div className={styles.uploadForm}>
                          <h5>{docName}</h5>
                          <ul>
                            {docElement.docs.map((page, idx)=> {
                              return (
                                <li key={`key_${docKey}_${idx}`}>
                                  <DoucumnetUploadRto 
                                    appointmentId={appointmentId} 
                                    docKey={docKey} 
                                    data={page} 
                                    allowedFileTypes={allowedFileTypes} 
                                    formReadOnly={formReadOnly}
                                  />
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      );
                    }
                                        
                  }) 
                }   
                </div>
              </div> 
            );
          })
          }
        </div>

        <div className={styles.holdbacksChargesContainer}> 
          <div className={styles.titleSubtile}>
            <h3>Charges and Holdbacks</h3>
            <p>The charges and holdbacks displayed may change after vahan background check is completed</p>
          </div>
          <div className={styles.blueCard}>
            <span>Offer Price</span>
            <span>₹ {negotiationData?.c24Quote ? priceFormatter(negotiationData?.c24Quote) : 0}</span>
          </div>
          <p className={styles.heading}>
            <span>Charges</span>
          </p>
          <div className={styles.inputWrapperContainer}>
            {!!charges && charges.map((item, index) => 
              <div className={styles.inputWrapper}>
                <div className={styles.fieldName}>{item.label}</div>
                <div key={item.key} className={styles.withoutkyc}>
                  <InputText
                    text={item.label}
                    name={`${item.key}`}
                    type={"number"}
                    value={getChargeAmount(item.key)}
                    onChange={onChargesChange}
                    disabled={formReadOnly}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={styles.lightBlueCard}>
            <span>Total Charges</span>
            <span>₹ {totalCharges ? priceFormatter(totalCharges) :  0}</span>
          </div> 
          <p className={styles.heading}>
            <span>Holdbacks</span>
          </p>
          <div className={styles.inputWrapperContainer}>
            {!!holdbacks && holdbacks.map((item) => 
              <div className={styles.inputWrapper}>
                <div className={styles.fieldName}>
                  <p>{item.label}</p>
                </div>
                {<div key={item.key} className={styles.withoutkyc}>
                  <InputText
                    text={item.label}
                    name={`${item.key}`}
                    type={"number"}
                    value={getHoldBacksAmount(item.key)}
                    onChange={onHoldbackChange}
                    disabled={formReadOnly}
                  />
                </div>}
              </div>
            )}
          </div>
          <div className={styles.lightBlueCard}>
            <span>Total Holdbacks</span>
            <span>₹ {totalHoldbacks ? priceFormatter(totalHoldbacks) : 0}</span>
          </div>
          <div className={styles.deliverycard}>
            <div className={styles.blueCard}>
              <span>Delivery Payment</span>
              <span>₹ {priceFormatter(negotiationData?.c24Quote - (totalHoldbacks + totalCharges))}</span>
            </div>
          </div>
        </div>
        <div className={styles.ctaWrapper}>
          { !formReadOnly && 
          <>
            <h4>*mandatory fields</h4>
            <Button ctaText={"Save"} classNames="transparentBtn" onClick={() => onDocumentAction("save")} disabled={submitted} />
            <Button ctaText={"Submit"} classNames="saveCta" onClick={() => onDocumentAction("submit")} disabled={submitted}/>
          </>
          }
        </div>
      </div>
            
    </div>
  );
};

export default DocumentUpload;
