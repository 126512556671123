import { createReducer } from "reduxsauce";
import Types from "./types";
import { getUnique } from "../../utils/helper";

export const INITIAL_STATE = {
  isLoading: true,
  columns: [],
  page: 0,
  data: [],
  error: null,
  fromPage: ""
};

export const fetchLostLeads = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};
export const fetchLostLeadsSuccess = (state = INITIAL_STATE, { data }) => {
  const { columns, totalRows, result } = data;
  let rowData = [...state.data, ...result];
  rowData = getUnique(rowData, "appointment");
  return { ...state, columns, total: totalRows, data: rowData, isLoading: false, error: null, };
};

export const fetchLostLeadsFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, error, data: [], isLoading: false };
};

export const resetAppointmentByKey = (state = INITIAL_STATE, { data }) => {
  const { key, value } = data;
  return { ...state, [key]: value };
};

export const resetLostLeads = () => {
  return INITIAL_STATE;
};

export const HANDLERS = {
  [Types.FETCH_LOST_LEADS]: fetchLostLeads,
  [Types.FETCH_LOST_LEADS_SUCCESS]: fetchLostLeadsSuccess,
  [Types.FETCH_LOST_LEADS_FAILURE]: fetchLostLeadsFailure,
  [Types.RESET_LOST_LEADS_BY_KEY]: resetAppointmentByKey,
  [Types.RESET_LOST_LEADS]: resetLostLeads
};

export default createReducer(INITIAL_STATE, HANDLERS);
