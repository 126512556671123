import React from "react";
import { APPOINTMENT_TYPES_STRING } from "../../utils/app-constants";
import { Link } from "react-router-dom";
import styles from "./lost-leads.module.css";
import lostIcon from "../images/lost_icon.svg";

const LostLeadsList = ({ appointmentState }) => {
  return (
    ((appointmentState === APPOINTMENT_TYPES_STRING.SCHEDULED) || appointmentState === APPOINTMENT_TYPES_STRING.VISITED || appointmentState === APPOINTMENT_TYPES_STRING.PAYMENT_PROCESSING)
        && <Link to={{ pathname: '/lost-leads', state: { fromPage: appointmentState } }} className={styles.lostIcon}>
          <img src={lostIcon} height="32px" width="32px" alt="" />
        </Link>
  );
};

export default LostLeadsList;
