import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./components/reducers";
import b2bReducer from "./b2b/reducers";
import RenderRoutes from "./components/router/render-routes";
import { getGoogleMapKey } from "../src/utils/url-constants";
import Helmet from "react-helmet";
import { APP_CONSTANTS } from "./b2b/common/app-constants";
import { initializeSentry } from "../src/utils/sentry";
import { AuthProvider } from "@descope/react-sdk";
initializeSentry();

const middlewares = [thunk];
const composeEnhancers =
	typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
		: compose;
const enhancer = composeEnhancers(applyMiddleware(...middlewares));
const isB2B = window.location.pathname
  .toLowerCase()
  .includes(APP_CONSTANTS.appType);
export const store = createStore(isB2B ? b2bReducer : rootReducer, enhancer);

const injectGoogleMapScript = () => {
  const googleMapKey = getGoogleMapKey();
  return (
    <Helmet>
      <script
        defer
        type="text/javascript"
        src={`https://maps.googleapis.com/maps/api/js?key=${googleMapKey}&libraries=places&callback=Function.prototype`}
      />
    </Helmet>
  );
};

const App = () => {
  return (
    <AuthProvider projectId={process.env.REACT_APP_PROJECT_ID}>
      {injectGoogleMapScript()}
      <Provider store={store}>
        <main>
          <Router>
            <RenderRoutes isB2B={isB2B} />
          </Router>
        </main>
      </Provider>
    </AuthProvider>
  );
};

export default App;
