import React, { useState } from "react";
import styles from "./book-appointment.module.scss";
import BookAppointmentPopup from "../book-appointment-popup";
import Popup from "../popup";
import { LOCAL_STORAGE_KEYS, ROLE_KEYS } from "../../utils/app-constants";
import { getFromLocalStorage } from "../../utils/helper";
import MarketingSurvey from "../marketing-survey";

const BookAppointment = ({
  selectedStore,
  setSelectedStoreError,
  history,
  rowDetails,
  setAppointment,
  onAppointmentPopupOpen
}) => {
  const [bookAppointmentOpen, setBookAppointmentOpen] = useState(false);
  // const [appointmentFormOpen, setAppointmentFormOpen] = useState(false);

  const role = JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA)) ?
        JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA))[LOCAL_STORAGE_KEYS.ROLE] : "";

  const setStoreError = () => {
    setSelectedStoreError(true);
  };
    // const onAppointmentFormOpen = () => {
    //     setAppointmentFormOpen(true);
    // };

  // const onAppointmentFormClose = () => {
  //     setAppointmentFormOpen(false);
  // };
  const onBookAppointmentOpen = () => {
    if (isValidStoreId()) {
      setBookAppointmentOpen(true);
    } else {
      setBookAppointmentOpen(false);
      setStoreError();
      // showToastMessages("Please Select Store", false);
    }
  };

  const onBookAppointmentClose = () => {
    setBookAppointmentOpen(false);
    // onAppointmentFormOpen(true);
  };

  const isValidStoreId = () => {
    if (role.toLowerCase() === ROLE_KEYS.RM) {
      if (selectedStore)
        return true;
      else
        return false;
    } else if (role.toLowerCase() === ROLE_KEYS.HOSTESS) {
      return true;
    }
    else {
      return true;
    }

  };

  return (
    <div className={styles.bookAppointment}>
      <p onClick={onBookAppointmentOpen} className={styles.bookAppointmentText}>Book an appointment</p>
      <Popup closeOnOutside = {false} isOpen={bookAppointmentOpen} close={onBookAppointmentClose}>
        <BookAppointmentPopup
          selectedStore={selectedStore}
          onClose = {onBookAppointmentClose}
          role={role}
          history={history}
          rowDetails={rowDetails}
          setAppointment={setAppointment}
          onAppointmentPopupOpen={onAppointmentPopupOpen}/>
      </Popup>
      {/* <Popup isOpen={appointmentFormOpen} close={onAppointmentFormClose}>
                <MarketingSurvey onClose={onAppointmentFormClose} />
            </Popup> */}
    </div>
  );
};

export default BookAppointment;
