import Types from "./types";
import { AppointmentService } from "../../service";
import { getLoginUserMenuHeaders } from '../../utils/helper';
// import { getMenus } from '../../utils/mocApi';
const fetchUserMenus = (params) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(fetchMenus());
    return AppointmentService.getLoginUserPermissions(params)
      .then(response => {
        const { detail: { data } } = response;
        const userMenuHeaders = getLoginUserMenuHeaders(data);
        dispatch(fetchMenusSuccess(userMenuHeaders));
        resolve(data);
      })
      .catch(error => {
        console.log(error);
        dispatch(fetchMenusFailure(error));
        reject(error);
      });
  });
};
const fetchMenus = () => ({
  type: Types.FETCH_USER_MENU
});
const fetchMenusSuccess = (data) => ({
  type: Types.FETCH_USER_MENU_SUCCESS,
  data
});
const fetchMenusFailure = (error) => ({
  type: Types.FETCH_USER_MENU_FAILURE,
  error
});

const fetchAppointmentCounts = () => dispatch => {
  dispatch(fetchCounts());
  return AppointmentService.getAppointmentCounts()
    .then(response => {
      const { data: { detail } } = response;
      dispatch(fetchAppointmentCountsSuccess(detail));
    })
    .catch(error => dispatch(fetchAppointmentCountsFailure(error)));
};

const fetchCounts = () => ({
  type: Types.FETCH_APPOINTMENT_COUNTS
});

const fetchAppointmentCountsSuccess = (data) => ({
  type: Types.FETCH_APPOINTMENT_COUNTS_SUCCESS,
  data
});

const fetchAppointmentCountsFailure = (error) => ({
  type: Types.FETCH_APPOINTMENT_COUNTS_FAILURE,
  error
});

const resetAppointmentCountByKey = (key, value) => ({
  type: Types.RESET_APPOINTMENT_COUNTS_KEY,
  data: { key, value }
});

const updatePriorityCountByKey = (key) => ({
  type: Types.UPDATE_PRIORITY_COUNT_BY_KEY,
  data: { key }
});

const updatePllFilterByKey = (key, value) => ({
  type: Types.UPDATE_PLL_FILTER_BY_KEY,
  data: { key, value }
});

const updateRetailFilterByKey = (key, value) => ({
  type: Types.UPDATE_RETAIL_FILTER_BY_KEY,
  data: { key, value }
});

const resetSidebar = () => ({
  type: Types.RESET_SIDEBAR
});

export {
  fetchAppointmentCounts,
  resetAppointmentCountByKey,
  updatePriorityCountByKey,
  resetSidebar,
  updatePllFilterByKey,
  updateRetailFilterByKey,
  fetchUserMenus
};
