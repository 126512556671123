/* eslint-disable indent */
import React, { useState } from "react";
import Popup from "../../components/popup";
import { AppointmentService } from "../../service";
import AllocateCjPopup from "../../components/allocate-cj-popup";
import { getAllocatedCjMoc } from "../../utils/mocApi";
import Button from "../../components/shared/button";
import { ACTIONS_BUTTON } from "../../utils/app-constants";

const AllocateCj = ({ rowDetails, updateDataList, data }) => {
  const { homeInspectionCj, appointment, isReInspectionDone, model, variant, year, customerName, zoneName, customerAddress, lastPaymentStatus } = rowDetails;

  const [allocateOpen, setAllocateOpen] = useState(false);
  let evaluatorName = "";
  const [currentCjDetail, setCurrentCjDetail] = useState(null);
  let evauatorData = !!data ? JSON.parse(data) : []; if (!!evauatorData && evauatorData.length > 0) {
    let selectObject = { firstName: "Select CJ", lastName: "", idLmsUsers: "" };
    evauatorData.unshift(selectObject);
    if (!!homeInspectionCj) {
      let cj = evauatorData.find(x => x.idLmsUsers === homeInspectionCj);
      if (!!cj) {
        evaluatorName = !!cj.email ? cj.email : "";// cj.firstName + " " + cj.lastName;
      }
    }
  }
  const onAllocateOpen = () => {
    try {
      AppointmentService.getAppointedCjDetail(appointment)
        .then(response => {
          const { data: { detail } } = response;
          setCurrentCjDetail(detail);
          setAllocateOpen(true);
        }).catch(error => {
          console.log("API error: ", error);
          setAllocateOpen(true);
        });
    }
    catch (exception) {
      // console.log("API exception: ", exception);
      setAllocateOpen(true);
    }

  };
  const onAllocateClose = () => {
    setAllocateOpen(false);
  };
  return (
    <div>

      {(!!lastPaymentStatus && (homeInspectionCj === undefined || homeInspectionCj === null)) && <Button classNames="transparentBtn" ctaText={ACTIONS_BUTTON.ALLOCATE_CJ} onClick={onAllocateOpen} />}
      {!!lastPaymentStatus && homeInspectionCj &&
        <>
          <div>
            <span>
              {evaluatorName}
            </span>
          </div>
          <div>

            {isReInspectionDone === 0 &&
              <Button classNames="secondaryCta" ctaText={"Reallocate CJ"} onClick={onAllocateOpen}></Button>
            }
          </div>
        </>
      }
      <Popup isOpen={allocateOpen} close={onAllocateClose}>
        <AllocateCjPopup onClose={onAllocateClose} rowDetails={rowDetails} data={data} updateDataList={updateDataList} currentDetail={currentCjDetail} />
      </Popup>
    </div >
  );
};

export default AllocateCj;
