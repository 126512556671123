import { createTypes } from "reduxsauce";

export default createTypes(
  `
    RESET_LOSTLEAD_SEARCH
    RESET_LOSTLEAD_SEARCH_BY_KEY
`,
  {
    prefix: "searchlostleads/"
  }
);
