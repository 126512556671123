import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CcConfirmLeads from "./component";
import { updateconfirmLeadsFilterQuery } from "./actions";
import { updateHeaderRowFilterQuery } from "../../components/shared/table-header-with-control/actions";
import { sortAppointmentByKey } from "../../pages/appointment/actions";
const mapStateToProps = ({
  homeInspectionConfirmLead
}
) => ({
  homeInspectionConfirmLead
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateconfirmLeadsFilterQueryConnect: updateconfirmLeadsFilterQuery,
  updateHeaderRowFilterQueryConnect: updateHeaderRowFilterQuery,
  sortAppointmentByKeyConnect:sortAppointmentByKey
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CcConfirmLeads);
