class Init {
  constructor() {
    this.region = "in";
    this.autoComplete = new window.google.maps.places.AutocompleteService();
    this.geoCoder = new window.google.maps.Geocoder();
  }

  getAutCompleteInstance() {
    return this.autoComplete;
  }

  getGeoCoderInstance() {
    return this.geoCoder;
  }

  getRegion() {
    return this.region;
  }
}

export default Init;
