import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateSuggestedQuote, setCurrentPopup, fetchCarData, setSuggestedC24Quote } from "../negotiation-list/actions";
import SuggestedQuotePopup from "./component";

const mapStateToProps = ({ negotiations }) => ({ negotiations });

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateSuggestedQuoteConnect: updateSuggestedQuote,
  setCurrentPopupConnect: setCurrentPopup,
  fetchCarDataConnect: fetchCarData,
  setSuggestedC24QuoteConnect: setSuggestedC24Quote
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SuggestedQuotePopup);
