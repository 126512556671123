import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PllLeadAllocation from "./component";
import {fetchAppointmentList,fetchPllAssociates,resetPLLByKey,setPllLeadTypeFilter, fetchPllStores} from "../pll-store-list/actions";

const mapStateToProps = ({
  pllStores,
  pllAllocationFilter
}) => ({
  pllStores,
  pllAllocationFilter
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchAppointmentListConnect: fetchAppointmentList,
  fetchPllAssociatesConnect: fetchPllAssociates,
  resetPLLByKeyConnect: resetPLLByKey,
  setPllLeadTypeFilterConnect: setPllLeadTypeFilter,
  fetchPllStoresConnect: fetchPllStores,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PllLeadAllocation);
