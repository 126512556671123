import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Individuals from "./component";
import { fetchRetailAssociates } from "./actions";

const mapStateToProps = ({
  individuals
}) => ({
  individuals
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchRetailAssociatesConnect: fetchRetailAssociates
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Individuals);
