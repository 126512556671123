import React from "react";
import styles from "./depreciation-report-popup.module.scss";
import { REPORTS_TAB } from "../../utils/app-constants";
import DepreciationReports from "../depreciation-reports";

const DepreciationReportPopup = ({
  showTabs = false
}) => {
  return (
    <div>
      {
        showTabs && <div className={styles.reportsTab}>
          <ul>
            <li className={styles.active}>{REPORTS_TAB.depreciation}</li>
          </ul>
        </div>
      }
      <DepreciationReports />
    </div>
  );
};

export default DepreciationReportPopup;
