import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import VahanBackground from "./component";
import { fetchVahanDetails } from "./actions";

const mapStateToProps = ({
  vahanBackground
}) => ({ vahanBackground });

const mapDispatchToProps = (dispatch) => bindActionCreators({
  vahanBackgroundConnect: fetchVahanDetails,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VahanBackground);
