import React, { useState, useEffect } from "react";
import styles from "./style.module.css";
import useLazyQuery from "../../hooks/useLazyQuery";
import Popup from "../popup";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { showToastMessages } from "../../utils/helper";
import appointmentService from "../../service/appointment-service";
import { AppointmentService } from "../../service";

const SellerIntendPopup = ({
  year,
  appointmentId,
  setIsPopupActive,
  fetchNegotiationsConnect,
  setUpdatedSellerIntent=()=>{},
  details=false,
  isHypoDsi=false,
  intent
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const { apiCbFunction: addSfIntend, isLoading: addSfIntendLoading } = useLazyQuery(AppointmentService.sendSfIntend);

  const sfIntendClickHandler = async () => {
    if (!addSfIntendLoading && selectedStatus) {
      const res = await addSfIntend({
        body: {
          appointmentId: appointmentId,
          source: year > 2010 ? 'RA_INTENT_BOTH' : 'RA_INTENT_PL',
          consent: selectedStatus
        }
      });
      if (res?.status == 200) {
        showToastMessages('Intent captured successfully');
        setIsPopupActive(false);
        if(details){
          setUpdatedSellerIntent(selectedStatus);
        }
        else{
          let globalSearchQuery = appointmentId + "&globalSearch=true";
          fetchNegotiationsConnect(1, globalSearchQuery, null, null, null);
        }
      } else {
        showToastMessages(res?.title ?? 'Some error occurred', false);
      }
    }
  };

  const expandClickHandler = () => {
    setIsExpanded(!isExpanded);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  return (
    <Popup isOpen={true} showCloseButton={false}>
      <div className={styles.seller_intent_modal_div}>
        <div className={styles.sim_header}>Check financing intent</div>
        {
            isHypoDsi && 
            <div className={styles.hypo_dsi_falg}>Hot Lead (Hypo Car)</div>
        }
        <div className={styles.sim_pitch}>
          <p className={styles.sim_pitch_header}>Customer Pitch</p>
          <p className={styles.sim_pitch_data}>
            {
              year > 2010 ? (
                <>
                    Sir/Ma'am, at Cars24 we provide funds against your existing car wherein you can keep your car and get the amount you need for your financial concerns (We call this loan against car). We will also top-up your current active auto loan and will take care of your existing loan.
                </>
              ) : (
                <>
                    Sir/Ma'am, at Cars24 we also provide personal loan. Would you be interested in availing the same for funding your next purchase/meeting your financial needs? If you would like to explore, I can capture your intent and share it with my financing team.
                </>
              )
            }
          </p>
          <p className={styles.sim_pitch_pitch}>
            {
              year > 2010 ?
                `Note: Eligible for both loan against car and personal loan`
                :
                `Note: Only eligible for personal loan `
            }
          </p>
        </div>
        <div className={styles.sim_description}>
          Would you be interested in a
          <span>
            {
              year > 2010 ?
                ` car/vehicle refinancing or personal loan?`
                :
                ` personal loan?`
            }
          </span>
        </div>
        <div className={styles.sim_input}>
          <div className={styles.sim_input_radio}>
            <input
              type="radio"
              id="pitched"
              name="status"
              value="YES"
              checked={selectedStatus === 'YES' || (selectedStatus == null && intent == 'YES')}
              onChange={handleStatusChange}
              disabled={intent == 'YES'}
            />
            <label htmlFor="pitched" className={styles.sim_input_label}>Yes</label><br />
          </div>
          <div className={styles.sim_input_radio}>
            <input
              type="radio"
              id="not_pitched"
              name="status"
              value="NO"
              checked={selectedStatus === 'NO' || (selectedStatus == null && intent == 'NO')}
              onChange={handleStatusChange}
              disabled={intent == 'NO'}
            />
            <label htmlFor="not_pitched" className={styles.sim_input_label}>No</label>
          </div>
        </div>
        <div className={styles.sim_content}>
          <div className={styles.sim_content_header}>
            <p className={styles.sim_content_header_title}>Loan Details</p>
            <p className={styles.sim_content_header_icon} onClick={expandClickHandler}>
              {isExpanded ? <ExpandLess fontSize="2px" /> : <ExpandMore fontSize="2px" />}
            </p>
          </div>
          {
            isExpanded &&
            <div className={styles.sim_content_header_data}>
              <div className={styles.sim_content_header_data_grid}>
                <div className={styles.sim_content_header_data_grid_headers}>
                  <div className={styles.sim_content_header_data_grid_headers_items}>Details</div>
                  <div className={styles.sim_content_header_data_grid_headers_items}>Loan Amount</div>
                  <div className={styles.sim_content_header_data_grid_headers_items}>Rate Of Interest</div>
                  <div className={styles.sim_content_header_data_grid_headers_items}>Time to get funds</div>
                </div>
                {
                  year > 2010 &&
                  <div className={styles.sim_content_header_data_grid_td}>
                    <div className={styles.sim_content_header_data_grid_td_value}>Loan against car</div>
                    <div className={styles.sim_content_header_data_grid_td_value}>200% of car’s valuation</div>
                    <div className={styles.sim_content_header_data_grid_td_value}>13-18%</div>
                    <div className={styles.sim_content_header_data_grid_td_value}>2-3 days</div>
                  </div>
                }
                <div className={styles.sim_content_header_data_grid_td}>
                  <div className={styles.sim_content_header_data_grid_td_value}>Personal loan (no collateral required)</div>
                  <div className={styles.sim_content_header_data_grid_td_value}>Upto 25 lakhs</div>
                  <div className={styles.sim_content_header_data_grid_td_value}>Starts from 11%</div>
                  <div className={styles.sim_content_header_data_grid_td_value}>30 minutes</div>
                </div>
              </div>
              <div className={styles.sim_content_header_data_documents}>
                <p className={styles.sim_content_header_data_documents_header}>Loan Documents</p>
                <ul className={styles.sim_content_header_data_documents_listing}>
                  <li className={styles.sim_content_header_data_documents_list_items}>PAN card</li>
                  <li className={styles.sim_content_header_data_documents_list_items}>Aadhar card</li>
                  <li className={styles.sim_content_header_data_documents_list_items}>RC</li>
                  <li className={styles.sim_content_header_data_documents_list_items}>Last 6 month bank statements</li>
                  <li className={styles.sim_content_header_data_documents_list_items}>Statement of accounts (if open loan)</li>
                </ul>
              </div>
            </div>
          }
        </div>
      </div>
      <div className={styles.sim_actions}>
        <div
          className={selectedStatus ? styles.sim_actions_submit : styles.sim_actions_submit_disabled}
          onClick={sfIntendClickHandler}
        >
          {addSfIntendLoading ? 'Loading' : 'Submit Intent'}
        </div>
        <div className={styles.sim_actions_cancel} onClick={() => setIsPopupActive(false)}>Cancel</div>
      </div>
    </Popup>
  );
};

export default SellerIntendPopup;
