import React, { useEffect, useState } from "react";
import styles from "./lead-appointment-details.module.scss";
import Button from "../../../components/shared/button/component";
import { BUTTON_STYLES } from "../../common/app-constants";
import { santiziePrice, onBackHome } from "../../common/utility";
import { DealerService } from "../../service";
import Loader from "../../../components/shared/loader";
import dealerLogo from "../../common/images/dealer-logo.svg";
import moment from "moment";

const LeadAppointmentDetails = ({
	minPrice = 0,
	maxPrice = 0,
	token
}) => {
	const [data, setData] = useState();
	const [isLoading, setIsLoading] = useState(false);
	useEffect(() => {
		if (token) {
			setIsLoading(true);
			DealerService.getAppointmentDetails(token)
				.then((resp) => {
					setData(resp.detail);
				})
				.catch((err) => {
					console.log({ err });
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	}, [token]);

	return (
		<>
			{!!isLoading ? (
				<Loader />
			) : (
				<div className={styles.wrapper}>
					<ul>
						<li>
							<div className={styles.priceWrap}>
								<span>The car will sell between</span>
								<strong className={styles.price}>
									₹{" "}
									{`${santiziePrice(minPrice)} - ${santiziePrice(
										maxPrice,
										true
									)}`}
								</strong>
							</div>
						</li>
						<li>
							<div className={styles.appointment}>
								<span>
									{data?.reschedule === 'yes' 
										? "Inspection rescheduled successfully"
										: "Inspection scheduled"}
								</span>
								<div className={styles.address}>
									<span>{`${moment(data?.date)
										.format("dddd")
										.substring(0, 3)}, ${moment(data?.date).format("ll")}, ${
										data?.time_range
									}`}</span>
									<p>{`${data?.appointmentGeoData?.address1} ${data?.appointmentGeoData?.address2} ${data?.appointmentGeoData?.appointment_address}`}</p>
								</div>
								<div className={styles.dealer}>
									<img src={dealerLogo} alt="img" />
									<span className={styles.info}>
										CJ will be assigned <strong>one hour</strong> before
										inspection
									</span>
								</div>
							</div>
						</li>
						<li>
							<div className={styles.buttonWrap}>
								<Button
									ctaText={"BACK TO HOME"}
									classNames={`${BUTTON_STYLES.DEFAULT} ${styles.btnAccept}`}
									onClick={() => onBackHome()}
								/>
							</div>
						</li>
					</ul>
				</div>
			)}
		</>
	);
};

export default LeadAppointmentDetails;
