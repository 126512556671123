import React from "react";

const Table = ({ children }) => {
  return (
    <table cellPadding="0" cellSpacing="0" width="100%">
      {children}
    </table>
  );
};

export default Table;
