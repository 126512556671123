import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CheckInDenyPopup from "./component";
import { fetchCancellationList, denyCheckIn, resetDenyCheckInByKey } from "./actions";
import { resetAppointmentByKey } from "../../pages/appointment/actions";

const mapStateToProps = ({
  denyCheckIn,
  appointments
}) => ({
  denyCheckIn,
  appointments
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchCancellationListConnect: fetchCancellationList,
  denyCheckInConnect: denyCheckIn,
  resetDenyCheckInByKeyConnect: resetDenyCheckInByKey,
  resetAppointmentByKeyConnect: resetAppointmentByKey
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CheckInDenyPopup);
