import React, { useState, useEffect } from "react";
import { ERROR_MESSAGE, POPUP_BUTTONS, NUMBERS, TEXT_AREA_REGEX, DATATYPE, ACTIONS_BUTTON, HIGH_PRICE_EXPECTATION_PRICE, LOCAL_STORAGE_KEYS } from "../../utils/app-constants";
import SelectComponent from "../shared/select/component";
import H2 from "../h2/component";
import Textarea from "../shared/textarea/component";
import Button from "../shared/button/component";
import styles from "./negotiation-popup-bike.module.css";
import { showToastMessages, getFromLocalStorage } from "../../utils/helper";
import InputText from "../shared/input-text/component";
import { makeStyles } from '@material-ui/core/styles';
import priceFormatter from "../../utils/helpers/price-formatter";
import { isC2CUser } from "../../utils/helper";
import Datepicker from "../shared/datepicker/component";
import RadioButton from "../shared/radio-button/component";
import { AppointmentService } from "../../service";

// const useStyles = makeStyles(theme => {
//     return {
//         textField: {
//             marginLeft: theme.spacing(1),
//             marginRight: theme.spacing(1),
//             marginTop: theme.spacing(2),
//             fontSize: '10px',
//         }
//     };
// });

const CUSTOMER_ACCEPTANCE_CHOICES = {
  YES: 2,
  NO: 3,
  PENDING: 1,
  DEAL_LOST: 4
};

const NegotiationPopupBike = ({ close, fetchFollowUpListConnect, followUp, negotiations, postFollowUpConnect, resetListing, 
  resetFollowUpConnect, isLostLeadFlow, quoteNew, fetchLostLeadQuestionsConnect, resetNegoListing }) => {

  const { data, currentAppointmentIndex } = negotiations;
  const currentAppointment = data[currentAppointmentIndex];
  const { appointmentId, customerName, model, year, followUpMessage, followUpRemarks, customerExpectedPrice, priceOffered, calculatedc24Quote, 
    sellOnlineRetailAcceptance, exophone, fallbackVn='', inspReportUrl ='' } = currentAppointment;
  const { details, isPosting, error, message } = followUp;
  const [dispositionList, setDispositionList] = useState([]);
  const [disposition, setDisposition] = useState();
  const [selectRef, setSelectRef] = useState();
  const [remarks, setRemarks] = useState(followUpRemarks ? followUpRemarks : "");
  const [dispositionError, setDispositionError] = useState(false);
  const [cepError, setCepError] = useState(false);
  const [defaultFollowUp, setDefaultFollowUp] = useState();
  // const classes = useStyles();
  const priceOfferToCustomer = priceOffered ? priceFormatter(priceOffered) : "";
  const [custExpectedPrice, setCustomerExpectedPrice] = useState(customerExpectedPrice ? priceFormatter(customerExpectedPrice) : "");
  let isC2C = isC2CUser();

  const [subDisposition, setSubDisposition] = useState();
  const [subDispValue, setSubDispValue] = useState();
  const [subDispositionError, setSubDispositionError] = useState(false);
  const [acceptance, setAcceptance] = useState(0);
  const [virtualNo, setVirtualNo] = useState(exophone);
  const [isLoading, setIsLoading] = useState(false);
  const BIKE_SELL_ONLINE_NUMBER = 35;
  const [lostLeadQuestions, setLostLeadQuestions] = useState([]);
    
  useEffect(() => {
    fetchFollowUpListConnect(BIKE_SELL_ONLINE_NUMBER);

    fetchLostLeadQuestionsConnect().then((response) => {
      let reasonsArray = [...response];
      reasonsArray = reasonsArray.filter(x => x.parentId !== HIGH_PRICE_EXPECTATION_PRICE);
      let lostReasons = reasonsArray.map((item) => {return {label: item.parent, value: item.parentId};});
      setLostLeadQuestions(lostReasons);
    });
    setDefaultFollowUp();

  }, []);

  useEffect(()=> {
    if (sellOnlineRetailAcceptance > 0){
      onPriceAcceptanceChange(sellOnlineRetailAcceptance);
    }
  },[sellOnlineRetailAcceptance, details]);

  useEffect(() => {
    if (disposition) {
      const detail = details.find(item => item.key === disposition);
      if (detail && detail.subDispositions.length > 0) {
        setSubDisposition(detail.subDispositions[0]);
      } else {
        setSubDisposition();
      }
      setDispositionError(false);
      setSubDispositionError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disposition]);

  useEffect(() => {
    if (!isPosting) {
      if (error) {
        showToastMessages(message, false);
      } else {
        showToastMessages(message);
        setTimeout(() => {
          resetListing();
          resetFollowUpConnect();
          close();
        }, 2000);
      }
    }
  }, [isPosting]);

  const onDispositionSelect = (value, id, ref) => {
    setSelectRef(ref);
    setDisposition(value);
  };

  const onDateChange = (val) => {
    setSubDispValue(val);
    setSubDispositionError(false);
  };

  const onRemarksChange = (e) => {
    const { target: { value } } = e;
    setRemarks(value);
  };

  const validateDetails = (cep) => {
    let errors = 0;
    if (!disposition) {
      ++errors;
      setDispositionError(true);
    } else {
      setDispositionError(false);
    }
    if (subDisposition && subDisposition.required && !subDispValue) {
      ++errors;
      setSubDispositionError(true);
    } else {
      setSubDispositionError(false);
    }
    if (acceptance === 3 && cep <= 0) {
      ++errors;
      setCepError(true);
    } else {
      setCepError(false);
    }
    if (errors > 0) {
      return false;
    } else {
      return true;
    }
  };

  const onSaveClick = () => {
    if (acceptance === CUSTOMER_ACCEPTANCE_CHOICES.DEAL_LOST) {
      const loginData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
      const userEmail = loginData ? JSON.parse(loginData)[LOCAL_STORAGE_KEYS.EMAIL] : '';
      if (disposition) {
        const params = {
          "appointmentId": appointmentId,
          "lostReason": disposition,
          "userEmail": userEmail
        };
        AppointmentService.submitLostLead(params)
          .then(res => {
            // setDisabled(false);
            const { data: { detail: { message } } } = res;
            showToastMessages(message, true);
            setTimeout(() => {
              resetNegoListing();
            }, 2000);
          })
          .catch(error => {
            // setDisabled(false);
            const { detail: { message } } = error;
            showToastMessages(message, false);
          }).finally(()=>{
            close();
          });
      }
    } else {
      let offerPrice = Number(priceOfferToCustomer.replace(/,/g, ''));
      let expectedPrice = Number(custExpectedPrice.replace(/,/g, ''));
      const isValid = validateDetails(expectedPrice);
      if (isValid) {
        const dispName = details.find(item => item.key === disposition).name;
        const params = {
          "appointment": appointmentId,
          "dispositionId": disposition,
          "disposition": dispName,
          "subDisposition": subDisposition && subDisposition.required ? subDispValue.toLocaleTimeString() : "",
          "remarks": remarks ? remarks.trim().replace(TEXT_AREA_REGEX, " ") : "",
          "state": NUMBERS.SEVENTEEN,
          "priceOffered": offerPrice,
          "expectedCustomerPrice": expectedPrice,
          "customerAcceptance": acceptance
        };
        postFollowUpConnect(params);
      }
    }
        
  };

  const onChangeExpectedPrice = (e) => {
    const value = e.target.value && e.target.value.replace(/,/g, '') ? priceFormatter(Number(e.target.value.replace(/,/g, ''))) : "";
    setCustomerExpectedPrice(value);
  };
    
  const onPriceAcceptanceChange = (value) => {
    setAcceptance(value);
    setDispositionList();
    setDisposition();
    setDefaultFollowUp();
    !!selectRef && selectRef.current.select.clearValue();
    if (details.length > 0) {
      const acceptanceDispositions = details.filter((item) => item.response === value);
      const dispositions = acceptanceDispositions.map(item => { return { value: item.key, label: item.name }; });
      if (followUpMessage) {
        const selectedValue = dispositions.find(item => followUpMessage.includes(item.label));
        if (!!selectedValue) {
          setDefaultFollowUp(selectedValue);
          setDisposition(selectedValue.value);
        }
      }
      setDispositionList(dispositions);
    }
  };

  const onMarkDealLost = () => {
    setAcceptance(4);
    setDispositionList(lostLeadQuestions);
  };

  const onGenerateNo = () => {
    setIsLoading(true);
    AppointmentService.getVirtualNo(appointmentId).then(response => {
      const { data: { detail: { exophone } } } = response;
      setVirtualNo(exophone);
    }).catch(() => {
      fallbackVn && setVirtualNo(fallbackVn);
    }).finally(()=>{
      setIsLoading(false);
    });
  };

  const onAppointmentClick = () => {
    !!inspReportUrl && window.open(inspReportUrl,  "_blank", 'noopener');
  };

  return (
    <div className={styles.bikeFollowWrapper}>
      <div className={styles.titleWrapper}>
        <H2 text={"FOLLOW-UP"} />
        <div>
          {!!virtualNo && <span className={styles.call}>{virtualNo}</span>}
          {!virtualNo && <Button ctaText={ACTIONS_BUTTON.GENERATE_NO}  classNames="generateCta" onClick={() => onGenerateNo()} disabled={isLoading} />}
        </div>
      </div>
            
      <div>
        <p className={styles.txt}>{`${customerName} | ${model} | ${year}`} | <span className={styles.aapid} onClick={onAppointmentClick}>{appointmentId}</span></p>
      </div>
      <div className={styles.offersPrice}>
        <p>Price Offered to Customer:</p>
        <span>₹{calculatedc24Quote}</span>
      </div>
      <div className="mt-3 mb-4">
        <p className={styles.txt}>Did customer agree to sell at the offfered price?</p>
        <div className={styles.radioButton}>
          <RadioButton text="Yes" id="Yes" onClickCallback={()=> onPriceAcceptanceChange(CUSTOMER_ACCEPTANCE_CHOICES.YES)} checkedStatus={acceptance === CUSTOMER_ACCEPTANCE_CHOICES.YES}/>
          <RadioButton text="Not Right now" id="NotRightnow" onClickCallback={()=> onPriceAcceptanceChange(CUSTOMER_ACCEPTANCE_CHOICES.NO)} checkedStatus={acceptance === CUSTOMER_ACCEPTANCE_CHOICES.NO} />
          <RadioButton text="Call back later" id="Callbacklater" onClickCallback={()=> onPriceAcceptanceChange(CUSTOMER_ACCEPTANCE_CHOICES.PENDING)} checkedStatus={acceptance === CUSTOMER_ACCEPTANCE_CHOICES.PENDING}/>
          <RadioButton text="Deal Lost" id="DealLost" onClickCallback={()=> onMarkDealLost()} checkedStatus={acceptance === CUSTOMER_ACCEPTANCE_CHOICES.DEAL_LOST}/>
        </div>
      </div>
      <div className="mt-3">
        <div className={styles.inputWrap}>
          <InputText 
            id={`key-cep-text`}
            text="Customer Expected Price"
            value={custExpectedPrice}
            onChange={onChangeExpectedPrice}
            validationError={cepError}
          />
        </div>
      </div>
      <div className="mt-3">
        {!!dispositionList && dispositionList.length > 0 && 
        <SelectComponent 
          placeholder={acceptance !== CUSTOMER_ACCEPTANCE_CHOICES.DEAL_LOST ? `Choose a reason for not selling his Bike` : `Choose a lost deal reason`}
          name="dispositionStatus" 
          onChange={onDispositionSelect} 
          optionsList={dispositionList} 
          defaultValue={!!defaultFollowUp ? defaultFollowUp : null } 
          value={!!defaultFollowUp ? defaultFollowUp.value : null}
          hasError={dispositionError} 
          error={ERROR_MESSAGE.DISPOSITION}
        /> 
        }
      </div>
      {subDisposition && subDisposition.dataType === DATATYPE.TIMER &&
      <div className="mt-3">
        <div className={styles.customCalender}>
          <Datepicker name="subDisposition"
            selected={subDispValue} placeholder="Time"
            onChange={onDateChange} showTime={true} showTimeOnly={true} />
        </div>
      </div>
      }
      {
        subDisposition && subDisposition.required && subDispositionError &&
        <span className={styles.errorText}>{ERROR_MESSAGE.SUB_DISPOSITION}</span>
      }
      <div className="mt-3">
        <Textarea
          id="remarks"
          name="remarks"
          value={remarks}
          // rows={6}
          onChange={onRemarksChange}
          text="Remarks if any"
        />
      </div>
      <div className={`${styles.ctaButton} ${quoteNew ? styles.quoteNew : ``}${(isLostLeadFlow || isC2C) ? styles.fixWrapper : ``}`}>
        <Button classNames="saveCta" disable="disable" ctaText={POPUP_BUTTONS.SAVE} onClick={onSaveClick} />
        {/* <Button classNames="cancelCta" ctaText={POPUP_BUTTONS.CANCEL} onClick={close} /> */}
      </div>
    </div>
  );
};

export default NegotiationPopupBike;
