import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import NegoLeadFilters from "./component";
import { updateFilterQuery } from "../negotiation-filter/actions";
const mapStateToProps = ({
  negotiations,
  negotiationFilter
}) => ({
  negotiations,
  negotiationFilter
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateFilterQueryConnect: updateFilterQuery
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NegoLeadFilters);
