import axios from 'axios';
import {getSupersetMetaData} from '../utils/url-constants';
import { LOCAL_STORAGE_KEYS } from "../utils/app-constants";
import { getFromLocalStorage } from "../utils/helper";

export default () => {
  const metaData = getSupersetMetaData();
  const fetchCsrfToken = async (accessToken) => {
    const url = `${metaData.url}/api/v1/security/csrf_token/`;
    const resp = await axios({
      url,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    });
    return resp.data;
  };
  const fetchGuestToken = (payload) => {
    const token = getFromLocalStorage(LOCAL_STORAGE_KEYS.LMS_ACCESS_TOKEN);
    return fetchCsrfToken(token).then((response) => {
      const url = `${metaData.url}/api/v1/security/guest_token/`;
      return axios({
        url,
        method: "POST",
        data: payload,
        headers: {
          "X-CSRFToken": response.result,
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      });
    });
  };
  return {
    fetchGuestToken
  };
};

