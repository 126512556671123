import React from "react";
// import styles from "./phone.module.css";

const Phone = ({ rowDetails }) => {
  const { exophone, connectedCalls } = rowDetails;
  return (
    <>
      <span>{exophone ? `${exophone}` : '-'}</span>
      <span>{exophone ? ` (${connectedCalls})` : ''}</span>
    </>
  );
};

export default Phone;
