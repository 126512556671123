export default (api) => {
	const getCarDetailsByRegNo = (regNo) => {
		return new Promise((resolve, reject) => {
			api
				.get(`v3/vehicle-number/${regNo}`)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};
	const getCarMakerMasterData = () => {
		return new Promise((resolve, reject) => {
			api
				.get(`make`)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};
	const getCarMakerYears = (maker_id) => {
		return new Promise((resolve, reject) => {
			api
				.get(`make/${maker_id}/years`)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};
	const getCarMakerYearsModel = (maker_id, year_id) => {
		return new Promise((resolve, reject) => {
			api
				.get(`make/${maker_id}/model/${year_id}`)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};
	const getCarVariants = (model_id, year_id) => {
		return new Promise((resolve, reject) => {
			api
				.get(`variant-fuel-list?modelId=${model_id}&year=${year_id}`)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const getStates = (model_id, year_id) => {
		return new Promise((resolve, reject) => {
			api
				.get(`get-states`)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const getStateRtoList = (state_id) => {
		return new Promise((resolve, reject) => {
			api
				.get(`get-rto-list/${state_id}`)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const getDealerCitylist = () => {
		return new Promise((resolve, reject) => {
			api
				.get(`city`)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const getSOQuestions = (version) => {
		return new Promise((resolve, reject) => {
			api
				.get(`sell-online-question?version=${version}`)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	return {
		getDealerCitylist,
		getStateRtoList,
		getStates,
		getCarDetailsByRegNo,
		getCarMakerMasterData,
		getCarMakerYears,
		getCarMakerYearsModel,
		getCarVariants,
		getSOQuestions,
	};
};
