import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import VisitedFilters from "./component";
import { updateFilterQuery } from "./actions";

const mapStateToProps = ({
  appointments,
  visitedFilters
}) => ({
  appointments,
  visitedFilters
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateFilterQueryConnect: updateFilterQuery
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VisitedFilters);
