import Types from "./types";
import { AppointmentService } from "../../service";
import { RESET_REDUCER_STATE_KEYS, APPOINTMENT_TYPES_NUMBER } from "../../utils/app-constants";
import {updateApiNoAccess} from "../hoc-access-controller/actions";
const fetchCancellationList = () => dispatch => {
  dispatch(fetchCancellation());
  return AppointmentService.getCancellationReasons(APPOINTMENT_TYPES_NUMBER.VISITED)
    .then(response => {
      const { data: { detail: { reasons } } } = response;
      dispatch(fetchCancellationSuccess(reasons));
    })
    .catch(error => {
      dispatch(updateApiNoAccess(error));
      dispatch(fetchCancellationFailure(error));
    });
};

const fetchCancellation = () => ({
  type: Types.FETCH_CANCELLATION
});

const fetchCancellationSuccess = (data) => ({
  type: Types.FETCH_CANCELLATION_SUCCESS,
  data
});

const fetchCancellationFailure = (error) => ({
  type: Types.FETCH_CANCELLATION_FAILURE,
  error
});

const denyCheckIn = (params) => dispatch => {
  dispatch(resetDenyCheckInByKey(RESET_REDUCER_STATE_KEYS.IS_POSTING, true));
  return AppointmentService.denyCheckIn(params)
    .then(response => {
      const { data: { detail } } = response;
      dispatch(denyCheckInSuccess(detail));
    })
    .catch(error => {
      dispatch(updateApiNoAccess(error));
      dispatch(denyCheckInFailure(error));
    });
};

const denyCheckInSuccess = (data) => ({
  type: Types.DENY_CHECK_IN_SUCCESS,
  data
});

const denyCheckInFailure = (error) => ({
  type: Types.DENY_CHECK_IN_FAILURE,
  error
});

const resetDenyCheckInByKey = (key, value) => ({
  type: Types.RESET_DENY_CHECK_IN_KEY,
  data: { key, value }
});

export {
  fetchCancellationList,
  denyCheckIn,
  resetDenyCheckInByKey
};
