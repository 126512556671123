import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import C24QuotePopup from "./component";
import { fetchNegotiations, resetNegotiations } from "../../components/negotiation-list/actions";

const mapStateToProps = ({ negotiations }) => ({ negotiations });

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchNegotiationsConnect: fetchNegotiations,
  resetNegotiationsConnect: resetNegotiations
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(C24QuotePopup);
