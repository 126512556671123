import React, { useState, useEffect } from "react";
import styles from "./payment-processing-filter.module.scss";
import {PAYMENT_PROCESSING_FILTERS} from "../../utils/app-constants";
const PaymentProcessingFilter = ({ paymentProcessingFilter, updatepaymentProcessingFilterQueryConnect }) => {
  const [selectedFilter, setSelectedFilter] = useState(false);
  const { paymentProcessingFilterQuery } = !!paymentProcessingFilter ? paymentProcessingFilter : "&filterByPreviousWeek=false";

  useEffect(() => {
    if (!!paymentProcessingFilterQuery) {
      if (paymentProcessingFilterQuery === "&filterByPreviousWeek=false") {
        setSelectedFilter(false);
      }
    }
    else {
      setSelectedFilter(true);
    }
    return () => {
      updatepaymentProcessingFilterQueryConnect(`&filterByPreviousWeek=${selectedFilter}`);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!paymentProcessingFilterQuery) {
      if (paymentProcessingFilterQuery === "&filterByPreviousWeek=false") {
        setSelectedFilter(false);
      }
      else {
        setSelectedFilter(true);
      }
    }
    else {
      setSelectedFilter(true);
    }
  }, [paymentProcessingFilterQuery]);

  const onFilterSelect = (key, value) => {
    setSelectedFilter(key);
    updatepaymentProcessingFilterQueryConnect(`&filterByPreviousWeek=${value}`);
  };
  return (
    <div className={styles.weekFilters}>
      <ul>
        {
          Object.values(PAYMENT_PROCESSING_FILTERS).map(item => {
            return (
              <li key={item.key} onClick={() => {onFilterSelect(item.key, item.value);}} className={selectedFilter === item.value ? styles.active : ''}>{item.label}</li>
            );
          })
        }
      </ul>
    </div>
  );
};

export default PaymentProcessingFilter;
