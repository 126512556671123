import React, { useState, useEffect } from "react";
import styles from "./payment-initiate-token-popup-slider.module.scss";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AppointmentService } from "../../service";
import { trackLMSEvents } from "../../utils/helpers/tracking-events";
import { getUid } from "../../utils/helper";
import frontmain from "../../components/images/frontmain_car.jpg";

const PaymentInitiateTokenPopupSlider = ({ customerOffer, docImages }) => {
  const [carData, setCarData] = useState([]);
  const { customerOffer: { appointmentId } } = customerOffer;
  const [slickSlider, setSlickSlider] = useState(true);
  const [componentShow, setComponentShow] = useState(false);

  const onNextClick = (onClick) => {
    // console.log("Corusal next arrow Click");
    trackEventCall(appointmentId, "Right Arrow");
    onClick();

  };
  const onBackClick = (onClick) => {
    // console.log("Corusal back arrow Click");
    trackEventCall(appointmentId, "Left Arrow");
    onClick();
  };
  const trackEventCall = (appointmentId, eventAction) => {
    trackLMSEvents("similarTransactedCarInputOutputPage", {
      eventLabel: appointmentId,
      lsRAID: getUid(),
      eventAction: eventAction,
    });
  };
  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={() => onNextClick(onClick)}
      />
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        // style={{ ...style, display: "block", background: "green" }}
        onClick={() => onBackClick(onClick)}
      />
    );
  };

  const carouselSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  return (
    <div className={styles.carouselWrapper}>
      <Slider {...carouselSettings}>
        {docImages.docImages.length > 0 && docImages.docImages.map((item, index) =>
          <div className={styles.cardImage} key={index}>
            <img src={item.retailReceiveUrl} alt={`document image_${index}`} />
          </div>
        )}
      </Slider>
    </div>
  );
};

export default PaymentInitiateTokenPopupSlider;
