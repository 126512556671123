import React from "react";
import styles from "./qc-status.module.css";
import { CRITICAL_REJECT } from "../../utils/app-constants";

const QcStatus = ({ rowDetails }) => {

  const { qcStatus } = rowDetails;

  return (
    <span className={qcStatus === CRITICAL_REJECT ? styles.critical : ''} >{qcStatus}</span>
  );
};

export default QcStatus;
