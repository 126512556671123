/* eslint-disable indent */
import React, { useState } from "react";
import styles from "./hi-location-detail.module.scss";
import upArrow from "../images/upArrow.svg";
import homeIcon from "../images/homeIcon.svg";
import editIcon from "../images/edit.svg";
import Popup from "../../components/popup";
import CustomerAppointmentPopup from "../../components/customer-appointment-popup";
const HiLocationDetail = ({ rowDetails, scrollRef, setHasMoreItems, updateDataList }) => {
  const { zoneName, customerAddress, ctaAccessFlag } = rowDetails;
  const [customerLocationOpen, setCustomerLocationOpen] = useState(false);
  const onCustomerLocationOpen = () => {
    setCustomerLocationOpen(true);
  };

  const onCustomerLocationClose = () => {
    setCustomerLocationOpen(false);
  };
  return (
    <>
      <div className={styles.customerLocation}>
        <ul>
          <li>
            {!!zoneName && <div className={styles.detailsIcon}>
              <img src={upArrow} alt="Location Arrow" />
            </div>}
            {!!zoneName && <div className={styles.detailsInfo}>{zoneName}  </div>}
            {!ctaAccessFlag && <div className={styles.customerLocationPopup}>
              <img onClick={onCustomerLocationOpen} src={editIcon} alt="edit" />
              <Popup isOpen={customerLocationOpen} close={onCustomerLocationClose}>
                <CustomerAppointmentPopup onClose={onCustomerLocationClose} rowDetails={rowDetails} scrollRef={scrollRef} setHasMoreItems={setHasMoreItems} updateDataList={updateDataList} />
              </Popup>
            </div>}
          </li>
          {!!customerAddress &&
            <li>
              <div className={styles.detailsIcon}><img src={homeIcon} alt="home Icon" /></div>
              <div className={styles.detailsInfo}>{!!customerAddress ? customerAddress : "N/A"}</div>
            </li>
          }
        </ul>
      </div>

    </>
  );
};

export default HiLocationDetail;
