import React from "react";
import Button from "../../../components/shared/button/component";
import ErrorIcon from "./images/not-found.svg";
import styles from "./not-generate-offer.module.scss";
import {onBackHome} from "../../common/utility";
const NotGenerateOffer = ({
  reason
}) => {
  return (
    <React.Fragment>
      <div className={styles.notFound}>
        <img  src={ErrorIcon} alt="Can not generate online offer" />
        <h4 className={styles.heading}>Can not generate online offer for this particular car</h4>
      </div>
      <div className={styles.message}>
        {`This car does not meet CARS24 procurement standards`}
        <p className={styles.rejected}>{`Rejection reason - ${reason}`}</p>
      </div>
      <div className={styles.buttonWrap}>
        <Button ctaText={"TRY ANOTHER CAR"} classNames="selfCheckinCta" onClick={onBackHome}/>
      </div>
    </React.Fragment>
  );
};

export default NotGenerateOffer;
