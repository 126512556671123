import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import NegotiationCard from "./component";

const mapStateToProps = ({
  lostLeadsearch,
  negotiations: {flagPriority}
}) => ({
  lostLeadsearch,
  flagPriority
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NegotiationCard);
