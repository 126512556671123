import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IndividualsSearch from "./component";
import { getStoreAssociateList } from "./actions";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getStoreAssociateListConnect: getStoreAssociateList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(IndividualsSearch);
