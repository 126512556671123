import React, { useState } from "react";
import Popup from "../../components/popup";
import AllocateCjSchedulePopup from "../../components/allocate-cj-schedule-popup";
// import { getAllocatedCjMoc } from "../../utils/mocApi";
import Button from "../../components/shared/button";
import { ACTIONS_BUTTON } from "../../utils/app-constants";
import styles from "./allocate-cj-schedule.module.scss";

const AllocateCjSchedule = ({ rowDetails, updateDataList, data }) => {
  const { homeInspectionCj, hi_cj_self_assigned, homeInspectionCjEmail, hi_disposition, ctaAccessFlag } = rowDetails;
  const [allocateOpen, setAllocateOpen] = useState(false);
  let email = homeInspectionCjEmail;
  const onAllocateOpen = () => {
    setAllocateOpen(true);
  };
  const onAllocateClose = () => {
    setAllocateOpen(false);
  };
  return (
    <div className={"text-center"}>
      {(homeInspectionCj === undefined || homeInspectionCj === null || homeInspectionCj === "") && !ctaAccessFlag && <Button classNames="transparentBtn" ctaText={ACTIONS_BUTTON.ALLOCATE_CJ} onClick={onAllocateOpen} />}
      <p className={styles.hiDisposition}>{hi_disposition}</p>  
      {homeInspectionCj &&
      <>
        <div>
          {/* {name} */}
          {hi_cj_self_assigned === 1 && <p className={styles.allocatedbySelf}>Allocated By Self</p>}
          {!!email &&
          <span>
            ({email})
          </span>
          }
        </div>
        {hi_cj_self_assigned !== 1 && !ctaAccessFlag && <div>
          <Button classNames="secondaryCta" ctaText={"Reallocate CJ"} onClick={onAllocateOpen}></Button>
        </div>
        }
      </>
      }
      <Popup isOpen={allocateOpen} close={onAllocateClose}>
        <AllocateCjSchedulePopup onClose={onAllocateClose} rowDetails={rowDetails} data={data} updateDataList={updateDataList} IsCJAllocate={true}/>
      </Popup>
    </div >
  );
};

export default AllocateCjSchedule;
