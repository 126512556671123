import { createTypes } from "reduxsauce";

export default createTypes(
  `
    FETCH_LOST_LEADS_SUCCESS
    FETCH_LOST_LEADS_FAILURE
    FETCH_LOST_LEADS
    RESET_LOST_LEADS_BY_KEY
    RESET_LOST_LEADS
`,
  {
    prefix: "lostleads/"
  }
);
