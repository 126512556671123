import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  range: {
    from: "",
    to: "",
  },
  role:""
};

export const resetRangeByKey = (state = INITIAL_STATE, { data }) => {
  const { key, value } = data;
  return { ...state, [key]: value };
};
export const HANDLERS = {
  [Types.RESET_RANGE_BY_KEY]: resetRangeByKey

};

export default createReducer(INITIAL_STATE, HANDLERS);
