import React, { useState, useEffect } from "react";
import { PLL_USER_NEGOTIATION_FILTERS, BIKE_PLL_USER_NEGOTIATION_FILTERS, FILTER_KEYS, CENTRAL_PLL_USER_NEGOTIATION_FILTERS, APPOINTMENT_TYPES_STRING } from "../../utils/app-constants";
import styles from "./pllNegotiation-filter.module.css";
import { getPllUpdatedFilterCounts, isBikeSource, isPllCentralUser } from "../../utils/helper";

const PllNegotiationFilter = ({ updateFilterQueryConnect, negotiations, pllNegotiationFilter, appointmentType }) => {
  const isBike = isBikeSource();
  const pllCentral = isPllCentralUser();
  const { expiryCount } = negotiations;
  const { filterQuery } = pllNegotiationFilter;
  const pllNegoFilter = isBike ? BIKE_PLL_USER_NEGOTIATION_FILTERS : pllCentral || APPOINTMENT_TYPES_STRING.CENTRAL_NEGOTIATION ===  appointmentType ? CENTRAL_PLL_USER_NEGOTIATION_FILTERS : PLL_USER_NEGOTIATION_FILTERS;
  const [selectedFilter, setSelectedFilter] = useState(FILTER_KEYS.TOTAL);
  const [filtersCount, setFiltersCount] = useState(Object.assign({}, expiryCount));
    
  useEffect(() => {
    updateFilterQueryConnect(null);
  }, []);

  useEffect(() => {
    const slctdFilter = Object.values(pllNegoFilter).find(item => item.key === selectedFilter).key;
    const updateObject = slctdFilter === FILTER_KEYS.TOTAL ? true : false;
    const newFiltersCount = getPllUpdatedFilterCounts(filtersCount, expiryCount, slctdFilter, updateObject);
    if (selectedFilter !== FILTER_KEYS.TOTAL) {
      updateTotalCount(newFiltersCount);
    }
    setFiltersCount(newFiltersCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiryCount]);

  const updateTotalCount = (newFiltersCount) => {
    let count = 0;
    Object.values(pllNegoFilter).forEach(item => {
      if (item.key !== FILTER_KEYS.TOTAL) {
        switch (item.key) {
          case FILTER_KEYS.FOUR_DAYS:
            count = count + newFiltersCount[FILTER_KEYS.ONE_DAY] + newFiltersCount[FILTER_KEYS.TWO_DAYS] + newFiltersCount[FILTER_KEYS.THREE_DAYS] + newFiltersCount[FILTER_KEYS.FOUR_DAYS];
            break;
          case FILTER_KEYS.FIVE_DAYS:
            count = count + newFiltersCount[FILTER_KEYS.FIVE_DAYS] + newFiltersCount[FILTER_KEYS.SIX_DAYS];
            break;
          case FILTER_KEYS.SIX_DAYS:
            count = count + newFiltersCount[FILTER_KEYS.SEVEN_DAYS] + newFiltersCount[FILTER_KEYS.EIGHT_DAYS] + newFiltersCount[FILTER_KEYS.NINE_DAYS];
            break;
          case FILTER_KEYS.FIRST_TWO_DAYS:
            count = count +  newFiltersCount[FILTER_KEYS.ONE_DAY] + newFiltersCount[FILTER_KEYS.TWO_DAYS];
            break;
          case FILTER_KEYS.THIRD_FOURTH_DAYS:
            count = count +  newFiltersCount[FILTER_KEYS.THREE_DAYS] + newFiltersCount[FILTER_KEYS.FOUR_DAYS];
            break;
          case FILTER_KEYS.FIVE_PLUS_DAYS:
            count = count +   newFiltersCount[FILTER_KEYS.FIVE_PLUS_DAYS];
            break;
          default:
            count += newFiltersCount[item.key];
            break;
        }
      }
    });
    newFiltersCount[FILTER_KEYS.TOTAL] = count;
  };

  const onFilterSelect = (selectedKey, selectedValue) => {
    const query = `&expires_in=${selectedValue}`;

    if (query !== filterQuery) {
      setSelectedFilter(selectedKey);
      updateFilterQueryConnect(query);
    }
  };
    
  const getFilterCount = (key) => {
    switch (key) {
      case FILTER_KEYS.FIRST_TWO_DAYS:
        return filtersCount[FILTER_KEYS.ONE_DAY] + filtersCount[FILTER_KEYS.TWO_DAYS];
      case FILTER_KEYS.THIRD_FOURTH_DAYS:
        return filtersCount[FILTER_KEYS.THREE_DAYS] + filtersCount[FILTER_KEYS.FOUR_DAYS];
      case FILTER_KEYS.FOUR_DAYS:
        return filtersCount[FILTER_KEYS.ONE_DAY] + filtersCount[FILTER_KEYS.TWO_DAYS] + filtersCount[FILTER_KEYS.THREE_DAYS] + filtersCount[FILTER_KEYS.FOUR_DAYS];
      case FILTER_KEYS.FIVE_DAYS:
        return filtersCount[FILTER_KEYS.FIVE_DAYS] + filtersCount[FILTER_KEYS.SIX_DAYS];
      case FILTER_KEYS.FIVE_PLUS_DAYS:
        return filtersCount[FILTER_KEYS.FIVE_PLUS_DAYS];
      case FILTER_KEYS.SIX_DAYS:
        return filtersCount[FILTER_KEYS.SEVEN_DAYS] + filtersCount[FILTER_KEYS.EIGHT_DAYS] + filtersCount[FILTER_KEYS.NINE_DAYS];
      case FILTER_KEYS.FOUR_PLUS_DAYS:
        return filtersCount[FILTER_KEYS.FOUR_PLUS_DAYS];
      case FILTER_KEYS.TWENTY_PLUS_DAYS:
        return filtersCount[FILTER_KEYS.TWENTY_PLUS_DAYS];
      case FILTER_KEYS.TOTAL:
        return filtersCount[FILTER_KEYS.TOTAL];
      default:
        return 0;
    }
  };

  return (
    <ul>
      {
        Object.values(pllNegoFilter).map((item) => {
          return (
            <li key={item.key} onClick={() => { onFilterSelect(item.key, item.value); }} className={selectedFilter === item.key ? styles.selected : ''}> {getFilterCount(item.key)} {item.label} </li>
          );
        })}
    </ul>
  );
};

export default PllNegotiationFilter;
