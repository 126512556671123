import Types from "./types";

const updatereviewInspectionFilterQuery = (reviewInspectionFilterQuery) => ({
  type: Types.UPDATE_REVIEW_INSPECTION_FILTER_QUERY,
  reviewInspectionFilterQuery
});

export {
  updatereviewInspectionFilterQuery
};
