import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  reasons: [],
  error: null,
  message: "",
  isPosting: null
};

export const fetchCancellation = (state = INITIAL_STATE) => {
  return { ...state, isPosting: null };
};

export const fetchCancellationSuccess = (state = INITIAL_STATE, { data }) => {
  return { ...state, reasons: data, error: null };
};

export const fetchCancellationFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, error, data: [] };
};

export const denyCheckInSuccess = (state = INITIAL_STATE, { data }) => {
  const { message } = data;
  return { ...state, message, error: null, isPosting: false };
};

export const denyCheckInFailure = (state = INITIAL_STATE, { error }) => {
  const { detail: { message } } = error;
  return { ...state, error, message, isPosting: false };
};

export const resetDenyCheckInByKey = (state = INITIAL_STATE, { data }) => {
  const { key, value } = data;
  return { ...state, [key]: value };
};

export const HANDLERS = {
  [Types.FETCH_CANCELLATION]: fetchCancellation,
  [Types.FETCH_CANCELLATION_SUCCESS]: fetchCancellationSuccess,
  [Types.FETCH_CANCELLATION_FAILURE]: fetchCancellationFailure,
  [Types.DENY_CHECK_IN_SUCCESS]: denyCheckInSuccess,
  [Types.DENY_CHECK_IN_FAILURE]: denyCheckInFailure,
  [Types.RESET_DENY_CHECK_IN_KEY]: resetDenyCheckInByKey,
};

export default createReducer(INITIAL_STATE, HANDLERS);
