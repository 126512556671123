/* eslint-disable indent */
import React, { useState } from "react";
import styles from "./park-and-sell-reauction-popup.module.scss";
import Button from "../shared/button/component";
import PriceRangeSlider from "../shared/price-range-slider";
import IncrementalTextbox from "../incremental-textbox/component";
import { AppointmentService } from "../../service";
import priceFormatter from "../../utils/helpers/price-formatter";
import { showToastMessages } from "../../utils/helper";

const ParkAndSellReauctionPopup = ({ onClose, userData, resetListing }) => {
    const { appointmentId, customerName, make, model, year, c24Quote, tp } = userData;
    const minSliderValue = c24Quote + 1000;
    const maxSliderValue = tp * 1.30;
    const [newCEPPrice, setNewCEPPrice] = useState(minSliderValue);

    const handleSliderOnChange = (value) => {
        if(value){
            setNewCEPPrice(value);
        }
    };

    const handleIncrementalInputChange = (value) => {
        if(value === "increment"){
            setNewCEPPrice(newCEPPrice + 1000);
        }else{
            setNewCEPPrice(newCEPPrice - 1000);
        }
    };

    const handleReauctionClick = () => {
        let payload = {
            appointment_id: appointmentId,
            cepPrice: newCEPPrice
        };
        AppointmentService.saveCepPrice(payload).then(response => {
            const { detail } = response.data;
            showToastMessages(detail.message, false);
            resetListing();
            onClose();
        }).catch((error) => {
            onClose();
            const { detail } = error;
            showToastMessages(detail, false);
        });
    };

    return (
      <div className={styles.parkAndSellReauction}>
        <div className={styles.title}>ENTER CEP</div>
        <div className={styles.userDetails}>
          <ul>
            <li>{customerName}</li>
            <li>{make} {model}</li>
            <li>{year}</li>
          </ul>
        </div>
        <p className={styles.cepTitle}>CEP</p>
        <IncrementalTextbox 
                value={newCEPPrice}
                onChangeClick={handleIncrementalInputChange}
            />
        <div className={styles.priceRangeSlider}>
          <PriceRangeSlider 
                    min={minSliderValue}
                    max={maxSliderValue}                       
                    selectedValue={newCEPPrice}
                    onStepChange={handleSliderOnChange}
                    step={1}
                />
          <div className={styles.rangeContainer}>
            <p>₹ {priceFormatter(minSliderValue)}</p>
            <p>₹ {priceFormatter(maxSliderValue)}</p>
          </div>
        </div>
        <div className={styles.ctaWrapper}>
          <Button classNames="blueButton" ctaText={"Reauction"} onClick={handleReauctionClick} />
          <Button classNames="transparentBtn" ctaText={"Cancel"} onClick={onClose} />
        </div>
      </div>
    );
};

export default ParkAndSellReauctionPopup;
