import React,{useState} from "react";
import Button from "../../../../components/shared/button/component";
import Popup from "../../../../components/popup/component";
import RARemarksPopup from "../ra-remarks-popup";
const RARemarks = (props) => {
	const [remarksOpen, setRemarksOpen] = useState(false);
	const onRemarksOpen = () => {
		setRemarksOpen(true);
	  };
	  const onRemarksClose = () => {
		setRemarksOpen(false);
	  };
	return (
		<>
			<Button classNames="smallCta" ctaText={"+ ADD REMARK"} onClick={onRemarksOpen} />
			<Popup isOpen={remarksOpen} close={onRemarksClose}>
				<RARemarksPopup onClose={onRemarksClose} {...props}/>
			</Popup> 
		</>
	);
};

export default RARemarks;