import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setCurrentPopup, preCheckRaisePAI, precCheckTokenElite, fetchNegotiations } from "../negotiation-list/actions";
import C24Quote from "./component";
import {resetApiNoAccess} from "../hoc-access-controller/actions";
const mapStateToProps = ({ negotiations }) => ({ negotiations });

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setCurrentPopupConnect: setCurrentPopup,
  preCheckRaisePAIConnect: preCheckRaisePAI,
  precCheckTokenEliteConnect: precCheckTokenElite,
  ResetApiNoAccessConnect: resetApiNoAccess,
  fetchNegotiationsConnect: fetchNegotiations,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(C24Quote);
