/* eslint-disable indent */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CjAllocationStatus from "./component";
import { fetchCjAllocationStatus } from "./actions";

const mapStateToProps = (
    {
        cjAllocationStatus,
        homeInspectionFilters,
        appointments
    }
) => (
        {
            cjAllocationStatus,
            homeInspectionFilters,
            appointments
        });

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCjAllocationStatusConnect: fetchCjAllocationStatus
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CjAllocationStatus);
