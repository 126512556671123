import React from "react";
import styles from "./payment-initiate-token-popup.module.scss";
import PaymentInitiateTokenPopupSlider from "../../components/payment-initiate-token-popup-slider";

const PaymentInitiateTokenPopup = (docImages) => {
  return (
    <div className={styles.paymentInitiateTokenPopup}>
      <div className={styles.imageWrapper}>
        <PaymentInitiateTokenPopupSlider docImages={docImages} />
      </div>

    </div>
  );
};

export default PaymentInitiateTokenPopup;