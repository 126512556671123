import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CheckInPopup from "./component";
import { checkInAppointment, resetCheckInPopupByKey, resetCheckInPopup } from "./actions";

const mapStateToProps = ({
  checkIn,
  appointments
}) => ({
  checkIn,
  appointments
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  checkInAppointmentConnect: checkInAppointment,
  resetCheckInPopupByKeyConnect: resetCheckInPopupByKey,
  resetCheckInPopupConnect: resetCheckInPopup
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CheckInPopup);
