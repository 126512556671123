import { createReducer } from "reduxsauce";
import Types from "./types";
import {removeDuplicates} from "../../utils/helper";
import { NUMBERS } from "../../utils/app-constants";

export const INITIAL_STATE = {
  isLoading: true,
  storeList: [],
  storeAppointmentList: [],
  error: null,
  pllUsers: [],
  totalCount: 0,
  raUser: "",
  selectedAppointments: [],
  storeId: null,
  selectedStoreIds: [],
  selectedStoreNames: '',
  TotalCount: 0,
  city:"",
  cityEnabled: false,
  leadTypeFilter: NUMBERS.TWO, //default case for All Leads
  storeIds: [],
  loadMore: false,
  pageNo: 1,
};

export const fetchPllStoreSuccess = (state = INITIAL_STATE, { data }) => {
  const { total, detail } = data;
  // const { storeList } = state;
  let rowData = [...detail];
  let newData = rowData.map(row => {
    const newRow = { ...row };
    newRow.checked = false;
    return newRow;
  });
  return { ...state, totalCount: total, storeList: newData, isLoading: false, error: null };
};
export const fetchAppointmentListSuccess = (state = INITIAL_STATE, { data }) => {
  const { detail } = data;
  const { appointmentList = [], isNextAllowed=false} = detail || {};
  const rowData = [...state.storeAppointmentList, ...appointmentList];
  const newData = removeDuplicates(rowData, "appointment");
  const stores = state.selectedStoreIds && state.storeList && state.storeList.filter((store=> state.selectedStoreIds.includes(store.idLmsStore)));
  const _storeNames = stores.map(item=> item.name).join(', ');
  return { ...state, 
    storeAppointmentList: newData, 
    isLoading: false, 
    error: null, 
    selectedStoreNames: _storeNames, 
    loadMore: isNextAllowed };
};
export const updatePllStoreList = (state = INITIAL_STATE, { storeList }) => {
  return { ...state, storeList: storeList };
};
export const fetchAppointmentListFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, error, isLoading: false };
};

export const fetchPllStoreFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, error, isLoading: false };
};

export const fetchPllAssociatesSuccess = (state = INITIAL_STATE, { data }) => {
  const { detail } = data;
  let rowData = [...detail];
  return { ...state, pllUsers: rowData, isLoading: false, error: null };
};

export const fetchPllAssociatesFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, error, isLoading: false };
};

export const updateStoreListForCheck = (state = INITIAL_STATE, { id }) => {
  const {storeList} = state;
  let newStoreList = [...storeList];
  let store = newStoreList.find(store=>store.idLmsStore === id);
  if(store){
    let index = newStoreList.findIndex(store=>store.idLmsStore === id);
    store.checked = !store.checked;
    newStoreList[index] = store;
  }
  return { ...state, storeList:newStoreList};
};
export const resetPLLByKey = (state = INITIAL_STATE, { data }) => {
  const { key, value } = data;
  return { ...state, [key]: value };
};

export const setPllLeadTypeFilter = (state = INITIAL_STATE, { data }) => {
  const { leadTypeFilter } = data;
  return { ...state, leadTypeFilter: leadTypeFilter };
};

export const resetPllAllocation = () => {
  return INITIAL_STATE;
};

export const HANDLERS = {
  [Types.FETCH_PLL_STORE_SUCCESS]: fetchPllStoreSuccess,
  [Types.FETCH_PLL_STORE_FAILURE]: fetchPllStoreFailure,
  [Types.FETCH_APPOINTMENT_LIST_SUCCESS]: fetchAppointmentListSuccess,
  [Types.FETCH_APPOINTMENT_LIST_FAILURE]: fetchAppointmentListFailure,
  [Types.FETCH_PLL_ASSOCIATES_SUCCESS]: fetchPllAssociatesSuccess,
  [Types.FETCH_PLL_ASSOCIATES_FALIURE]: fetchPllAssociatesFailure,
  [Types.RESET_PLL_BY_KEY]: resetPLLByKey,
  [Types.UPDATE_PLL_STORE_LIST]: updatePllStoreList,
  [Types.UPDATE_STORE_LIST]: updateStoreListForCheck,
  [Types.RESET_PLL]: resetPllAllocation,
  [Types.SET_PLL_LEAD_TYPE_FILTER]: setPllLeadTypeFilter
};

export default createReducer(INITIAL_STATE, HANDLERS);
