import React, { useState, useEffect, useRef } from "react";
import styles from "./allocate-cj-popup.module.scss";
import { APPOINTMENT_TYPES_STRING, LOCAL_STORAGE_KEYS, MAP_TYPE, NUMBERS, POPUP_BUTTONS } from "../../utils/app-constants";
import Button from "../shared/button";
import H2 from "../h2/component";
import homeCj from "../../components/images/homecj.svg";
import arrowCj from "../../components/images/arrowcj.svg";
import TimeSlot from "../../components/images/timeSlot.svg";
import Calander from "../../components/images/calander.svg";
import Usercj from "../../components/images/usercj.svg";
import SelectComponent from "../shared/select/component";
import { showToastMessages, getNextActiveDays, isBikeSource, getFromLocalStorage } from "../../utils/helper";
import { AppointmentService } from "../../service";
import Textarea from "../shared/textarea/component";
import OnlineSearch from "../shared/search/online";
import InputText from "../shared/input-text/component";
import { googlePlacesOptions } from "../../utils/auto-complete/search-options";
import Checkbox from "../../components/shared/checkbox";
import _debounce from 'lodash/debounce';

const BikeAllocateCjPopup = ({ rowDetails, data, updateDataList, resetListing, onClose, appointmentId, userData, negotiations, origin = '' }) => {
  const mapType = getFromLocalStorage(LOCAL_STORAGE_KEYS.MAP_TYPE_ACTIVE);
  const { homeInspectionCj, appointment, model, variant, year, customerName, zoneName, customerAddress} = rowDetails || {};
  const [homeInspectionCJ, sethomeInspectionCJ] = useState(homeInspectionCj);
  const [timeSlot, setTimeSlot] = useState(null);
  const [dateSlot, setDateSlot] = useState(null);
  let cjDefaultObj = null;
    
  const [slot, setSlot] = useState([]);
  const [activeDays, setActiveDays] = useState(getNextActiveDays(6));
  const [key, setKey] = useState(appointment);

  const [selectedCj, setSelectedCj] = useState("");
  const [selectedSlot, setSelectedSlot] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [errorClass, setErrorClass] = useState("");
  const [errorText, setErrorText] = useState("");
  let [evaluatorList, setEvaluatorList] = useState([]);
  const { data : negoData, currentAppointmentIndex } = negotiations;
  const currentAppointmentData = negoData[currentAppointmentIndex] || {};
  const { customerAddress : negoCustomerAddress, sellOnlineFlag } = currentAppointmentData;
  const [ customerAddressState, setCustomerAddress ] = useState(!!customerAddress ? customerAddress : !!negoCustomerAddress ? negoCustomerAddress : "");
  const [ customerEditedAddress, setCustomerEditedAddress ] = useState(customerAddressState);
  const [ showAddressTextarea, setShowAddressTextarea ] = useState(false);
  const isBike = isBikeSource();
  const isAddressEditable = isBike && origin === APPOINTMENT_TYPES_STRING.NEGOTIATION;
  const [searchText, setSearchText] = useState('');
  const changeLocationRef = useRef();
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [selectedCity, setSelectedCity] = useState();
  const [state, setState] = useState('');
  const [selectedPincode, setSelectedPincode] = useState('');
  const [autoCJAllocate, setAutoCJAllocate] = useState(false);
    
  const loadSuggestedOptions = React.useCallback(
    _debounce((inputValue, callback) => {
      googlePlacesOptions(inputValue, mapType).then(options => callback(options));
    }, 1000),
    []
  );
    
  useEffect(() => {
    if (!!data) {
      let evauatorData = JSON.parse(data);
      setEvaluatorList(evauatorData);
    } else {
      AppointmentService.getEvaluatorLeadCountBasedOnAppointment(appointmentId)
        .then(response => {
          const { data: { detail } } = response;
          setEvaluatorList(detail);
          if (!homeInspectionCJ) {
            AppointmentService.getAppointedCjDetail(appointmentId)
              .then(response => {
                const { data: { detail: { evaluatorId, appointmentDate, timeSlot } } } = response;
                !!evaluatorId && sethomeInspectionCJ(evaluatorId);
                !!evaluatorId && setSelectedCj(evaluatorId.toString());
                // update date timeslot
                setTimeSlot({ label: timeSlot, value: timeSlot });
                setDateSlot({ label: appointmentDate, value: appointmentDate });
                setSelectedSlot(timeSlot);
                setSelectedDate(appointmentDate);
              }).catch(error => {
                console.log("API error: ", error);

              });

          }
        }).catch(err => {
          const { detail } = err;
          showToastMessages(detail, false);
        });
      AppointmentService.getAppointedCjDetail(appointmentId)
        .then(response => {
          let timeDate = response.data.detail;
          setTimeSlot({ label: timeDate.timeSlot, value: timeDate.timeSlot });
          setDateSlot({ label: timeDate.appointmentDate, value: timeDate.appointmentDate });
          setSelectedSlot(timeDate.timeSlot);
          setSelectedDate(timeDate.appointmentDate);
          setLat(response.data.detail.lat);
          setLng(response.data.detail.lng);
          setSelectedPincode(response.data.detail.pincode);
          setCustomerAddress(response.data.detail.customerAddress);
        }).catch(error => {
          console.log("API error: ", error);

        });
    };
  }, []);

  if (!!evaluatorList && evaluatorList.length > 0) {
    let selectObject = { email: "Select CJ", idLmsUsers: "" };
    evaluatorList.unshift(selectObject);
    evaluatorList = evaluatorList.filter((thing, index, self) =>
      index === self.findIndex((t) => (
        t.email === thing.email
      ))
    );
    if (!!homeInspectionCJ) {
      let cj = evaluatorList.find(x => x.idLmsUsers == homeInspectionCJ);
      if (!!cj) {
        cjDefaultObj = { label: cj.email, value: cj.idLmsUsers };
      }
    }
  }

  useEffect(() => {
    if (!!homeInspectionCJ) {
      setSelectedCj(homeInspectionCJ);
    }
    AppointmentService.getTimeSlot()
      .then(resp => {
        const { data: { detail: { slots } } } = resp;
        const timeSlots = slots.map(item => { return { value: item.id, label: item.value }; });
        setSlot(timeSlots);

      })
      .catch(error => {
        console.log(error);
      });

    return () => {
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSlotSelect = (value) => {
    setErrorClass("");
    setErrorText("");
    setSelectedSlot(value);
    setTimeSlot({ label: value, value: value });
  };
  const onDateSelect = (value) => {
    setErrorClass("");
    setErrorText("");
    setSelectedDate(value);
    setDateSlot({ label: value, value: value });
  };
  const onEvaluatorSelect = (value) => {
    // alert(value + " - " + value);
    setErrorClass("");
    setErrorText("");
    setSelectedCj(value);
    sethomeInspectionCJ(value);

  };
  const validateData = () => {
    let bValid = true;
    let errors = "";
    if (!searchText) {
      bValid = false;
      errors = "Location";
    }
    if (!selectedPincode) {
      bValid = false;
      errors = !!errors ? errors + ", Pincode" : "Pincode";
    }
    if (selectedSlot === "") {
      bValid = false;
      errors = !!errors ? errors + ",  Slot  " : " Slot ";
    }
    if (selectedDate === "") {
      bValid = false;
      errors = !!errors ? errors + " and Date" : " Date ";
    }

    if (!bValid) {
      setErrorText(`Please select ${errors}`);
      setErrorClass("form-text text-danger");
    }
    return bValid;
  };
  const onAssignCj = () => {
    let bValid = validateData();
    if (bValid) {
      let action = "ALLOCATE";
      if (selectedCj === "" && !autoCJAllocate) {
        action = "DEALLOCATE";
      }
      const params = {
        'appointment': appointment || appointmentId,
        'evaluatorId': !!autoCJAllocate ? null : selectedCj,
        'action': action,
        'appointmentDate': selectedDate,
        'timeSlot': selectedSlot,
        'customerAddress': isAddressEditable ? customerAddressState : (!!customerAddress ? customerAddress : !!negoCustomerAddress ? negoCustomerAddress : ""),
        "geoLocation" : searchText,
        "lat": lat,
        "lng": lng,
        "city": selectedCity,
        "state": state,
        "pincode": selectedPincode,
        "autoAssign": !!autoCJAllocate ? 1 : null
      };
      AppointmentService.assignBikeEvaluator(params)
        .then(resp => {
          const { data: { type, detail } } = resp;
          showToastMessages(detail);
          onClose();
          resetListing();
          if(updateDataList) updateDataList(appointment);
        })
        .catch(err => {
          const { detail } = err;
          showToastMessages(detail, false);
        });
    }
  };

  const addressDoneHandler = () => {
    const params = {
      'appointment': appointmentId,
      'field': 'customerAddress',
      'value': customerEditedAddress
    };
    AppointmentService.updateAppointment(params)
      .then(resp => {
        const { data: { detail: { message } } } = resp;
        showToastMessages(message);
        setCustomerAddress(customerEditedAddress);
        setShowAddressTextarea(false);
      })
      .catch(err => {
        const { detail } = err;
        showToastMessages(detail, false);
        setShowAddressTextarea(false);
      });
  };

  const addressCancelHandler = () => {
    setCustomerEditedAddress(customerAddressState);
    setShowAddressTextarea(false);
  };

  const displayEditableAddress = () => {
    return(
      <div className={styles.address}>
        {showAddressTextarea ?
          <div className={styles.addressWrapper}>
            <Textarea
              placeholder={"Please enter address"}
              value={customerEditedAddress}
              onChange={(e) => setCustomerEditedAddress(e.target.value)}
            />
            <div className={styles.addressUpdateCta}>
              <Button ctaText="DONE" classNames="secondaryCta" onClick={addressDoneHandler} />
              <Button ctaText="CANCEL" classNames="addressCancelCta" onClick={addressCancelHandler} />
            </div>
          </div>
                    :
          <>
            <p className={styles.addressWrapper}>{customerAddressState}</p>
            <span className={styles.edit} onClick={() => setShowAddressTextarea(true)}>Edit</span>
          </>
        }
      </div>
    );
  };

  const onLocationSelection = async (item) => {
    setState();
    setSelectedCity();
    setLng();
    setLat();
    setSelectedPincode();
    !!item.lat && setLat(item.lat);
    !!item.lng && setLng(item.lng);
    !!item.pincode && setSelectedPincode(item.pincode);
    const locationArray = item.label.split(',');
        
    if (locationArray.length >= 3) {
      setSelectedCity(locationArray[locationArray.length - 3].trim());
      setState(locationArray[locationArray.length - 2].trim());
      const pin = locationArray[locationArray.length  - 1].trim();
      if (!item.pincode && !isNaN(pin) ) {
        setSelectedPincode(pin);
      }
    }
    setSearchText(item.label);
  };

  const onChangeAutoCJAllocate = () => {
    if (autoCJAllocate) {
      setAutoCJAllocate(false);
    } else {
      setAutoCJAllocate(true);
    }
  };
  return (
    <div className={styles.allocateWrapper}>
      <H2 text={"Allocate CJ"} />
      {/* <p className={styles.txt}>{`${customerName} | ${model} | ${year}`}</p> */}
      <p className={styles.txt}>{customerName || userData?.customerName} | {model || userData?.model} {variant || userData?.variant} | {year || userData?.year}</p>
      {isAddressEditable ?
        <div className={styles.customerLocationDetails}>
          <img src={homeCj} alt="homeIcon" />
          {displayEditableAddress()}
          {currentAppointmentData.zoneName && 
          <>
            <img src={arrowCj} alt="location" />
            <p>{currentAppointmentData.zoneName}</p>
          </>
          }
        </div>:
                customerAddress && 
                <div className={styles.customerLocationDetails}>
                  <img src={homeCj} alt="homeIcon" />
                  <p>{customerAddress}</p>
                  <img src={arrowCj} alt="location" />
                  <p>{zoneName}</p>
                </div>
      }
      <div className={styles.timeDateWrapper}>
        <div className={styles.location}>
          {!!searchText && <OnlineSearch
            placeholder={'Location'}
            onChange={onLocationSelection}
            getOptions={loadSuggestedOptions}
            overrideSearchRef={changeLocationRef}
            customDefaultValue = {searchText}
          />}
          {!searchText && <OnlineSearch
            placeholder={'Location'}
            onChange={onLocationSelection}
            getOptions={loadSuggestedOptions}
            overrideSearchRef={changeLocationRef}
          />}
        </div>
        <div className={styles.pincode}>
          <InputText 
            placeholder="Pincode" 
            value={selectedPincode} 
            id={selectedPincode} 
            name={selectedPincode}
            onChange={(e) => setSelectedPincode(e.target.value)}
            type="number"
            maxLength={NUMBERS.SIX}
          />
        </div>
      </div>
      <div className={styles.timeDateWrapper}>
        <div className={styles.inputWrap}>
          <InputText 
            placeholder="City" 
            value={selectedCity} 
            id={selectedCity} 
            name={selectedCity} 
            onChange={(e) => setSelectedCity(e.target.value)}
          />
        </div>
        <div className={styles.inputWrap}>
          <InputText 
            placeholder="State" 
            value={state} 
            id={state} 
            name={state} 
            onChange={(e) => setState(e.target.value)}
          />
        </div>
      </div>
      <div className={styles.allocatecj}>
        <img src={TimeSlot} alt="TimeSlot" />
        {!!timeSlot && <div className={styles.mr10}>
          <SelectComponent
            key={key}
            name={`cj_Slot_${homeInspectionCJ}`}
            placeholder={"Select Time Slot"}
            optionsList={slot.map((item) => {
              return {
                label: item.label,
                value: item.label
              };
            })}
            questionId={appointment}
            onChange={onSlotSelect}
            defaultValue={timeSlot}
          />
        </div>}
        {!timeSlot && <div className={styles.mr10}>
          <SelectComponent
            key={key}
            name={`cj_Slot_${homeInspectionCJ}`}
            placeholder={"Select Time Slot"}
            optionsList={slot.map((item) => {
              return {
                label: item.label,
                value: item.label
              };
            })}
            questionId={appointment}
            onChange={onSlotSelect}
          />
        </div>}
        <img src={Calander} alt="Calander" />
        {!!dateSlot && <div className={styles.mr10}>
          <SelectComponent
            key={key}
            name={`cj_date_${homeInspectionCJ}`}
            placeholder={"Select Date"}
            optionsList={activeDays.map((item) => {
              return {
                label: item,
                value: item
              };
            })}
            questionId={appointment}
            onChange={onDateSelect}
            defaultValue={dateSlot}
          />
        </div>}
        {!dateSlot && <div className={styles.mr10}>
          <SelectComponent
            key={key}
            name={`cj_date_${homeInspectionCJ}`}
            placeholder={"Select Date"}
            optionsList={activeDays.map((item) => {
              return {
                label: item,
                value: item
              };
            })}
            questionId={appointment}
            onChange={onDateSelect}
          />
        </div>}
        <img src={Usercj} alt="Usercj" />
        <div className={styles.mr10}>
          {evaluatorList.length > 0 && <SelectComponent
            key={key}
            name={`select_${homeInspectionCJ}`}
            placeholder={"cj"}
            optionsList={evaluatorList.map((item) => {
              return {
                label: item.email,
                value: item.idLmsUsers
              };
            })}
            questionId={appointment}
            onChange={onEvaluatorSelect}
            defaultValue={!!cjDefaultObj ? cjDefaultObj : ""}
            isDisabled={autoCJAllocate}
          />}
        </div>

      </div>
      <small key="info" className={`${errorClass}`}>{errorText}</small>
      {!!sellOnlineFlag && <div className={styles.autoAllocate}>
        <Checkbox
          id={`id_${appointment}`}
          name={`chk_${appointment}`}
          onClickCallback={(e) => {onChangeAutoCJAllocate();}}
          checkedStatus={autoCJAllocate}
        />
        <span>Auto allocate CJ</span>
      </div>}
            
      <div className={styles.ctaButton}>
        <Button classNames="saveCta" ctaText={POPUP_BUTTONS.SUBMIT} disabled={showAddressTextarea} onClick={onAssignCj} />
        <Button classNames="cancelCta" ctaText={POPUP_BUTTONS.CANCEL} onClick={onClose} />
      </div>
    </div>
  );
};

export default BikeAllocateCjPopup;
