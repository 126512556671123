import { VehicleService } from "../../service";
import Types from "./types";

const fetchVehicleDetails = (vehicleNumber) => dispatch => {
  dispatch(updateRegNumber(vehicleNumber));
  return new Promise((resolve, reject)=> {
    return VehicleService.getCarDetailsByRegNo(vehicleNumber)
      .then(response => {
        const { detail } = response;
        dispatch(fetchVehicleDetailsSuccess(detail));
        resolve(response);
      })
      .catch(error => {
        dispatch(fetchVehicleDetailsFailure(error));
        reject(error);
      });
  });
    
};

const fetchDealerCityList = () => dispatch => {
  return new Promise((resolve, reject)=> {
    VehicleService.getDealerCitylist()
      .then(response => {
        const { detail } = response;
        dispatch(fetchDealerCitySuccess(detail));
        resolve(response);
      })
      .catch(error => {
        dispatch(fetchDealerCityFailure(error));
        reject(error);
      });
  });
    
};

const updateRegNumber = (regNumber) => ({
  type: Types.UPDATE_REG_NUMBER,
  regNumber
});

const updateRegistrationCarDetails = (data) => ({
  type: Types.UPDATE_CAR_DETAILS,
  data
});

const resetRegistrationCarDetails = () => ({
  type: Types.RESET_CAR_DETAILS
});

const fetchVehicleDetailsSuccess = (data) => ({
  type: Types.FETCH_CAR_DETAILS_SUCCESS,
  data
});

const fetchVehicleDetailsFailure = (error) => ({
  type: Types.FETCH_CAR_DETAILS_FAILURE,
  error
});

const fetchDealerCitySuccess = (data) => ({
  type: Types.FETCH_DEALER_CITY_SUCCESS,
  data
});

const fetchDealerCityFailure = (error) => ({
  type: Types.FETCH_DEALER_CITY_FAILURE,
  error
});

const updateDealer = (data) => ({
  type: Types.UPDATE_SELECTED_DEALER,
  data
});

const resetLeadData = () => ({
  type: Types.RESET_LEAD_DATA
});

export {
  fetchVehicleDetails,
  updateDealer,
  resetLeadData,
  resetRegistrationCarDetails,
  updateRegistrationCarDetails,
  fetchDealerCityList,
};