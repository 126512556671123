import React, { useState, useEffect } from "react";
import styles from "./input-text-question.module.scss";
import Button from "../../../../components/shared/button/component";
import { BUTTON_STYLES } from "../../../common/app-constants";
import { NUMBERS } from "../../../../utils/app-constants";

const key = "odometer_reading";

const InputTextQuestion =  ({
  data,
  userResponse,
  headerHeight,
  onChangeInputText,
  placeholder,
}) => {
  const [inputValue, setInputValue] = useState('');
    
  useEffect(()=>{
    if (!!userResponse && userResponse.hasOwnProperty(key)) {
      const objSize = Object.keys(userResponse[key]).length;
      if (objSize === NUMBERS.ONE) {
        setInputValue(userResponse[key]);
      } else {
        setInputValue(userResponse[key].value);
      }
    }
  },[userResponse]);

  const onFocus = () => {
    if (headerHeight) {
      window.setTimeout(() => {
        window.scrollBy({
          top: headerHeight,
          left: 0,
          behavior: "smooth"
        });
      }, 1000);
    }
  };

  return (
    <React.Fragment>
      <div className={`${styles.inputGroup} ${styles.revampedInputWrapper}`}>
        <input
          type="number"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className={styles.inputForm}
          onFocus ={onFocus}
          autoFocus={true}
          placeholder={placeholder} 
        />
        <div className={styles.inputGroupAppend}>
          <Button disabled={!inputValue} classNames={BUTTON_STYLES.DEFAULT} ctaText={"SUBMIT"} onClick={() => onChangeInputText(data, inputValue)} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default InputTextQuestion;
