import React from "react";
import LogoC24 from "../images/logoC24.svg";

const Logo = () => {
  return (
    <img src={LogoC24} alt="logo" />
  );
};

export default Logo;
