import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BookInspection from "./component";
import {fetchLeadDetail, resetLeadDetail} from '../../pages/lead-detail/actions';
const mapStateToProps = ({
  lead: {
    isLoading,
    leadDetail
  }
}) => ({
  isLoading,
  leadDetail
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  FetchLeadDetailConnect: fetchLeadDetail,
  ResetLeadDetailConnect: resetLeadDetail,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BookInspection);
