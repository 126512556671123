import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { UpdateLeadFilterQuery, updateLeadSort } from "./actions";
import LeadSortFilter from "./component";

const mapStateToProps = ({
  sortFilter
}) => ({
  sortFilter
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  UpdateLeadSortDirectionConnect: updateLeadSort,
  UpdateLeadFilterQueryConnect: UpdateLeadFilterQuery,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LeadSortFilter);
