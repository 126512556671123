import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TableHeaderWithControl from "./component";
import { fetchFollowUpList, postFollowUp, resetState, resetFollowUp } from "../../../components/follow-up-popup/actions";
import { updateHeaderRowFilterQuery } from "./actions";

const mapStateToProps = ({
  followUp,
  headerRowFilter,
  cjAllocationStatus
}) => ({
  followUp,
  headerRowFilter,
  cjAllocationStatus
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchFollowUpListConnect: fetchFollowUpList,
  postFollowUpConnect: postFollowUp,
  resetStateConnect: resetState,
  resetFollowUpConnect: resetFollowUp,
  updateHeaderRowFilterQueryConnect: updateHeaderRowFilterQuery
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TableHeaderWithControl);
