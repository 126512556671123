export const KEY_TITLE_MAPPERS = {
    "owner_number" : {
        "title" : "Car ownership status",
        "1" : "I am the 1st OWNER",
        "2" : "I am the 2nd OWNER",
        "3" : "I am the 3rd OWNER",
        "more_than_3" : "I am the 4th OWNER or more"
    },
    "towing_required" : {
        "title" : "Is your car running perfectly?",
        "no" : "Car runs perfectly",
        "repair_required" : "Car runs rough, engine needs repair",
        "yes" : "Car not running, needs towing"
    },
    "isaccidental" : {
        "title" : "Has your car ever been in an accident in the last X years?",
        "no" : "No",
        "minor_accident" : "Minor Accident",
        "major_accident" : "Major Accident"
    },
    "odometer_reading" : {
        "title" : "How many kms has your car driven?"
    },
    "isUnderhypothecation_documentDetails" : {
        "title" : "Is there any active loan on your car?",
        "yes" : "Yes",
        "no" : "No"
    },
    "rcAvailability" : {
        "title" : "Do you have Original Car RC with you?",
        "original" : "Yes, I have the Original RC",
        "duplicate" : "No, I got the Duplicate RC from RTO",
        "photocopy" : "No, I have a photocopy of my original RC",
        "lost" : "No, I don`t have either RC or photocopy"
    },
    "insurance_type" : {
        "title" : "What is the Car Insurance Type?",
        "comprehensive" : "Comprehensive",
        "3rd party" : "Third Party ",
        "zero depreciation" : "Zero Depreciation ",
        "insurance expired" : "Expired"
    },
    "gear" : {
        "title" : "Are you facing any issues in gear shifts?",
        "smooth" : "No issues, gear shifts are smooth",
        "some_issue" : "Some issues ",
        "major_issue" : "Major issues "
    },
    "hasScratches" : {
        "title" : "How many parts on your car has scratches?",
        "lt_5" : "Less than 5 parts has scratches",
        "6-9" : "6 to 9 parts has scratches",
        "gt_9" : "More than 9 parts has scratch"
    },
    "hasDents" : {
        "title" : "How many parts on your car has dent?",
        "lt_3" : "Less than 3 parts has dent",
        "4-7" : "4-7 parts has dent",
        "gt_7" : "More than 7 parts has dent"
    },
    "hasDamages" : {
        "title" : "How many parts on your car has damages?",
        "0" : "Car has no damages",
        "1-5" : "1-5 parts has damages",
        "gt_5" : "More than 5 parts has damages"
    },
    "pastDamages" : {
        "title" : "How many car parts has been repaired/ repainted/replaced because of past damages?",
        "0" : "All parts are in original condition",
        "1-6" : "1-6 parts are repaired",
        "gt_6" : ">6 parts were damaged and repaired"
    },
    "carStart" : {
        "title" : "Do you face issue in starting the car?",
        "no_issues" : "No issues",
        "minor_issues" : "Minor issues",
        "major_issues" : "Major issues "
    },
    "tyresReplaced" : {
        "title" : "How many tyres are in good condition?",
        "0" : "0",
        "1" : "1",
        "2" : "2",
        "3" : "3",
        "4" : "4"
    },
    "cngLpgFitment" : {
        "title" : "Have you got CNG or LPG installed in your car?",
        "yes" : "Yes",
        "no" : "No"
    },
    "cngLpgEndorsed" : {
        "title" : "is CNG/ LPG endorsed on RC?",
        "yes" : "Yes, CNG/ LPG is endorsed on RC",
        "no" : "No, not endorsed on RC"
    },
    "hasrust" : {
        "title" : "Is there any rust on your car?",
        "no" : "No rust",
        "small_spots" : "1-3 Parts ",
        "more_than_3_parts_rusted" : "On 4 or more parts "
    },
    "smoke" :{
        "title" : "smoke colour",
        "colorless" : "No issues",
        "not colorless" : "Black/White/Blue coloured smoke coming from exhaust"
    },
    "ac_condition" : {
        "title" : "AC condition",
        "0" : "No issues",
        "1" : "AC not cooling"
    },
    "heater_condition" :{
        "title": "Heater condition",
        "0" : "No issues",
        "1" : "Heater not working"
    },
    "additionalIssues" : {
        "title" : "Is there any of following issues in your car? ",
        "black_smoke" : "Black smoke coming from exhaust",
        "white_smoke" : "White smoke coming from exhaust",
        "blue_smoke" : "Blue smoke coming from exhaust",
        "ac_not_working" : "AC not cooling",
        "heater_not_working" : "Heater not working",
        "flood_damaged" : "Flood damaged",
        "no_issues" : "No issues",
        "smoke_exist" : "Black/White/Blue coloured smoke coming from exhaust"
    },
    "make" : {
        "title" : "Make"
    },
    "model" : {
        "title" : "Model"
    },
    "variant" : {
        "title" : "Variant"
    },
    "rto" : {
        "title" : "RTO"
    },
    "city" : {
        "title" : "City"
    },
    "year" :{
        "title" : "Manufacturing year"
    }
};