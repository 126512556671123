import React, { useState } from "react";
import { Link } from "react-router-dom";
import Edit from "../images/edit.svg";
import styles from "./follow-up.module.css";
import FollowUpPopup from "../follow-up-popup";
import Popup from "../popup";
import FollowUpHistory from "../follow-up-history";

const FollowUp = ({ rowDetails, scrollRef, setHasMoreItems, onResetParent }) => {
  const { followUpMessage, cc_lms_tag } = rowDetails;
  const [followUpOpen, setFollowUpOpen] = useState(false);

  const onFollowUpOpen = () => {
    setFollowUpOpen(true);
  };

  const onFollowUpClose = () => {
    setFollowUpOpen(false);
  };

  const getCallstatusCss = (callStatus) => {
    let css = "";
    if (callStatus === "Workable - Not Verified") {
      css = "notVerified";
    }
    else if (callStatus === "Non-workable") {
      css = "notWorkable";
    }
    else if (callStatus === "Verified") {
      css = "verified";
    }
    return css;
  };
  return (
    <div className={styles.tags}>
      <p className={`${styles[getCallstatusCss(cc_lms_tag)]}`}>{cc_lms_tag}</p>
      <p className={styles.editPopup} onClick={onFollowUpOpen} >
        <Link to="#">
          {/* <span>{followUpMessage ? followUpMessage : 'N/A'}</span> */}
          <span>{followUpMessage ? followUpMessage : 'N/A'}</span>
          <img className={styles.imgBlock} src={Edit} alt="" width="30" height="30" />
        </Link>
      </p>
      {
        <Popup isOpen={followUpOpen} close={onFollowUpClose}>
          <div className={styles.followUpHistoryWrapper}>
            <div className={styles.followupWrapper}>
              <FollowUpPopup rowDetails={rowDetails} onCancelClick={onFollowUpClose} scrollRef={scrollRef} setHasMoreItems={setHasMoreItems} onResetParent={onResetParent} />
            </div>
            <div className={styles.followuphistory}>
              <FollowUpHistory appointmentId={rowDetails.appointment} />
            </div>
          </div>
        </Popup>
      }
    </div>
  );
};

export default FollowUp;
