import React, { useState } from "react";
import styles from "./on-off-button.module.scss";
const qsKey = 'leadType';

const OnOffButton = ({negotiationFilter, updateFilterQueryConnect}) => {
  const [isSaleOnline, setIsSaleOnline] = useState(false);
  const { filterQuery } = negotiationFilter;
  const onChangeLeadType = () => {
    setIsSaleOnline(!isSaleOnline);
    onUpdateFilterQuery(!isSaleOnline);
  };

  const onUpdateFilterQuery = (value) => {
    let queryString = filterQuery;
    if (!!queryString && queryString.includes(qsKey)){
      const params = queryString.split("&");
      if (params.length > 0){
        const remainingFilters = params.filter(item => !item.includes(qsKey));
        const paramString = remainingFilters.join('&');
        queryString = paramString;
      }
    }
    if (value) {
      queryString = `${!!queryString ? queryString : ''}&${qsKey}=online`;
    }
    updateFilterQueryConnect(queryString);
  };
  return (
    <div className={styles.onOffButton}>
      <label className={styles.switch}>
        <input type="checkbox" onClick={()=> onChangeLeadType()} checked={isSaleOnline}/>
        <span className={styles.slider}></span>
      </label>
      <span className={styles.labletext}>SELL ONLINE</span>
    </div>
  );
};

export default OnOffButton;
