import React from "react";
import styles from "./round-arrow-green.module.css";
import GreenArrow from "./images/green-arrow.svg";

const IconRoundArrowGreen = () => {
  return (
    <div className={styles.arrowWrap}>
      <img alt="" src={GreenArrow} />
    </div>
  );
};

export default IconRoundArrowGreen;
