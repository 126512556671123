import React, { useEffect, useState } from "react";
import styles from "./header.module.scss";
import companyLogo from "../../common/images/cars24-logo.svg";
import hmMenu from "../../common/images/hm-menu.svg";
import closeMenu from "../../common/images/close-btn.svg";
import { getFromLocalStorage } from "../../../utils/helper";
import { LS_KEYS, USER_ROLES, APP_CONSTANTS } from "../../common/app-constants";
import { onBackHome, onBackLogin } from "../../common/utility";
import myLeadsLogo from "../../common/images/my-leads.svg";
import logoutLogo from "../../common/images/logout-icon.svg";
import ModalDialogBox from "../modal-dialog-box";
import RaLeads from "../ra-leads";
import { useDescope } from "@descope/react-sdk";

const Header = () => {
  const curRole = getFromLocalStorage(LS_KEYS.DISPLAY_ROLE);
  const [menuOpen, setMenuOpen] = useState(false);
  const [myLeadsOpen, setMyLeadsOpen] = useState(false);
  const { logout } = useDescope();
  useEffect(() => {
    if (!curRole) {
      localStorage.clear();
      onBackLogin(APP_CONSTANTS.DEFAULT_LOGIN_KEY);
    }
    else if (curRole && ![USER_ROLES.CJ, USER_ROLES.RA, USER_ROLES.ADMIN].includes(curRole)){
      logout();
      localStorage.clear();
      onBackLogin(APP_CONSTANTS.NO_ROLES_KEY);
    }
  }, [curRole]);

  const onClickLoginMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const onLogout = () => {
    logout();
    localStorage.clear();
    onBackHome();
  };

  const onClose = () => {
    setMenuOpen(false);
    setMyLeadsOpen(false);
  };

  return (
    <header className={`${styles.header} main-header`}>
      <div className={styles.headerRow}>
        <img src={companyLogo} alt={"logo"} onClick={() => onBackHome()} />
        <img
          src={menuOpen ? closeMenu : hmMenu}
          alt={"menu"}
          onClick={() => onClickLoginMenu()}
        />
        {menuOpen && (
          <div className={`${styles.dropDown} ${styles.active}`}>
            <ul>
              <li>
                <div className={styles.account}>
                  <div className={styles.userInfo}>
                    <span>
                      Hey, {getFromLocalStorage(LS_KEYS.DISPLAY_NAME)}
                    </span>
                    <p>{getFromLocalStorage(LS_KEYS.DISPLAY_EMAIL)}</p>
                  </div>
                  <div className={styles.myLeads}>
                    <div
                      className={styles.leads}
                      onClick={() => setMyLeadsOpen(!myLeadsOpen)}
                    >
                      <img src={myLeadsLogo} alt="logo" />
                      <span>
                        My <strong>Leads</strong>
                      </span>
                    </div>
                    {/* <span>SELL</span> */}
                  </div>
                </div>
              </li>
              <li>
                <div className={styles.logout} onClick={onLogout}>
                  <img src={logoutLogo} alt="logout" />
                  <span>Logout</span>
                </div>
              </li>
            </ul>
          </div>
        )}
        {myLeadsOpen && (
          <ModalDialogBox isNavBar={true} close={onClose} title="My Leads">
            <RaLeads onClose={onClose} />
          </ModalDialogBox>
        )}
      </div>
    </header>
  );
};

export default Header;
