import React from "react";
import styles from "./call-log-date.module.scss";

const CallLogDate = ({ date }) => {
  return (
    <p className={styles.date}>{date}</p>
  );
};

export default CallLogDate;
