import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  data: null,
  error: null,
  isLoading: true
};

export const fetchRAIncentive = (state = INITIAL_STATE) => {
  return { ...state,isLoading: true};
};

export const fetchRAIncentiveSuccess = (state = INITIAL_STATE, data) => {
  return { ...state, data,isLoading: false};
};

export const fetchRAIncentiveFailure = (state = INITIAL_STATE, {error}) => {
  return { ...state, error,isLoading: false};
};

export const resetRAIncentive = () => {
  return INITIAL_STATE;
};

export const HANDLERS = {
  [Types.FETCH_RA_INCENTIVE]: fetchRAIncentive,
  [Types.FETCH_RA_INCENTIVE_SUCCESS]: fetchRAIncentiveSuccess,
  [Types.FETCH_RA_INCENTIVE_FAILURE]: fetchRAIncentiveFailure,
  [Types.RESET_RA_INCENTIVE]: resetRAIncentive
};

export default createReducer(INITIAL_STATE, HANDLERS);
