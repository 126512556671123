import React from "react";
import styles from "./call-log-details.module.scss";
import CallLogType from "../call-log-type/component";
import CallLogArrow from "../images/call_log_arrow.svg";

const CallLogDetails = ({ data }) => {

  const {
    time,
    causeCodeLabel,
    onCallDuration,
    callFrom,
    callTo,
    retailPllLabel,
    appointmentState
  } = data;

  return (
    <div className={styles.calLogTextWrapper}>
      <div className={styles.logtitleSection}>
        <p className={styles.logTitle}>{appointmentState}</p>
        <p className={styles.logTime}>{time}</p>
        <CallLogType type={retailPllLabel} />
      </div>
      <div className={styles.callLogStaticData}>
        <p className={styles.text}>{causeCodeLabel}</p>
        <p className={styles.text}>{onCallDuration}</p>
        <p className={styles.text}>{callFrom}</p>
        <img src={CallLogArrow} alt="" />
        <p className={styles.text}>{callTo}</p>
      </div>
    </div>
  );
};

export default CallLogDetails;
