import React from "react";
import Loader from "../loader";
import NoResults from "../no-results";
import SomethingWentWrong from "../something-went-wrong";
import PaginationLoader from "../../pagination-loader";

const DataInformationMessage = ({
  isLoading,
  error,
  data,
  dataLoading,
  appointmentType
}) => {
  return (
    <>
      {
        isLoading &&
        <Loader />
      }
      {
        !isLoading && !error && data.length === 0 &&
        <NoResults appointmentType={appointmentType}/>
      }
      {
        !isLoading && error &&
        <SomethingWentWrong />
      }
      {
        dataLoading &&
          <PaginationLoader />
      }
    </>
  );
};

export default DataInformationMessage;
