import React, { useState, useEffect } from "react";
import styles from "./check-in-popup.module.css";
import Button from "../shared/button/component";
import InputText from "../shared/input-text/component";
import { POPUP_BUTTONS, ERROR_MESSAGE, RESET_REDUCER_STATE_KEYS, EMAIl_PLACEHOLDER, LOCAL_STORAGE_KEYS, IGNORED_EMAIL_FOR_CHECK_IN, ROLE_KEYS } from "../../utils/app-constants";
import { validateEmail } from "../../utils/validators/email-validator";
import { showToastMessages, getFromLocalStorage, navigateToHomeWithDelay, getUid } from "../../utils/helper";
import Checkbox from "../shared/checkbox/component";
import { AppointmentService } from "../../service";
import { trackLMSEvents } from "../../utils/helpers/tracking-events";
import moment from "moment";
import { getAppointmentPreCheckin } from "../../utils/mocApi";

const CheckInPopup = ({
  onCancelClick,
  appointmentData,
  checkIn,
  checkInAppointmentConnect,
  resetCheckInPopupByKeyConnect,
  resetCheckInPopupConnect,
  history,
  role,
  selectedStore
}) => {

  const { customerName, model, year, customerEmail, secondaryEmail, appointment, make, variant } = appointmentData;
  const { isPosting, message, error } = checkIn;

  const isInvalidEmail = IGNORED_EMAIL_FOR_CHECK_IN.find(item => customerEmail && customerEmail.startsWith(item));

  const [primaryEmail, setPrimaryEmail] = useState(isInvalidEmail ? "" : customerEmail);
  const [newSecondaryEmail, setNewSecondaryEmail] = useState(secondaryEmail);
  const [ignoreEmail, setIgnoreEmail] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [registrationNo, setRegistrationNo] = useState("");
  const [showRegistrationError, setShowRegistrationError] = useState(false);
  const [checked, setChecked] = useState(false);
  const [errorClass, setErrorClass] = useState("");
  // const [preCheckinData, setPreCheckinData] = useState(null);
  const [openCheckInRequired, setOpenCheckInRequired] = useState(false);
  const [link, setLink] = useState("");
  const [showActionSection, setShowActionSection] = useState(false); /* added this state to enable action section only when appropiriate api response has been recived.  */

  useEffect(() => {
    AppointmentService.getAppointmentPreCheckin(appointment)
      .then(resp =>{
        const {data: {detail : {link, openCheckin}}} = resp || {};
        // console.log("response", detail);
        setOpenCheckInRequired(openCheckin);
        setLink(link);
        setShowActionSection(true);
        if(link !== "" && !openCheckInRequired){
          trackLMSEvents("url_shown_on_lms", {
            eventLabel: appointment,
            lsRAID: getUid(),
            Timestamp: moment().format()
          });
        }
      });
    return () => {
      resetCheckInPopupConnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isPosting != null && !isPosting) {
      if (error) {
        showToastMessages(message, false);
        if (message === ERROR_MESSAGE.ALREADY_CHECKED_IN) {
          onCancelClick(true);
          // if (role.toLowerCase() === ROLE_KEYS.HOSTESS) {
          //     resetAndFetchData();
          // } else {
          //     navigateToHomeWithDelay(history);
          // }
        }
      } else {
        showToastMessages(message);
        onCancelClick(true);
        // if (role.toLowerCase() === ROLE_KEYS.HOSTESS) {
        //     resetAndFetchData();
        // }
        // else {
        //     navigateToHomeWithDelay(history);
        // }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPosting]);

  const getStoreId = () => {
    if (role.toLowerCase() === ROLE_KEYS.RM)
      return selectedStore;
    else {
      const localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
      const storeId = localData ? JSON.parse(localData)[LOCAL_STORAGE_KEYS.LMS_STORE_ID] : '';
      if (Array.isArray(storeId)) {
        return storeId && storeId.length > 0 ? storeId[0] : '';
      } else
        return storeId;
    }
  };

  const onCheckInClick = () => {
    const isValid = validateData();
    if (isValid) {
      setShowEmailError(false);
      setShowRegistrationError(false);
      setErrorClass("");
      const storeId = getStoreId();
      const loginData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
      const userEmail = loginData ? JSON.parse(loginData)[LOCAL_STORAGE_KEYS.EMAIL] : '';
      const checkin_data = {
        "appointment_id": appointment,
        "make": make,
        "model": model,
        "variant": variant,
        "year": year,
        "customer_name": customerName,
        "customer_phone": "", // Not getting in data so sent as blank
        "customer_email": ignoreEmail ? "" : primaryEmail,
        "alotted_storeid": storeId,
        "current_store_id": storeId,
        "customer_secondary_email": newSecondaryEmail,
        "registration_no": registrationNo,
        "userEmail": userEmail
      };
      resetCheckInPopupByKeyConnect(RESET_REDUCER_STATE_KEYS.IS_POSTING, true);
      checkInAppointmentConnect({ checkin_data }).then(response => {

        trackLMSEvents("checkin_form_submitted", {
          eventLabel: appointment,
          lsRAID: getUid(),
          Timestamp: moment().format()
        });
      }).catch(error => {
      });
    } else {

    }
  };

  const validateData = () => {
    const isValidEmail = validateCheckInEmail();
    const isRegistrationNoValid = validateRegistrationNumber();
    if (isValidEmail && isRegistrationNoValid) {
      return true;
    }
    else {
      return false;
    }
  };

  const validateCheckInEmail = () => {
    setShowEmailError(false);
    let isValid = true;
    if (!isInvalidEmail) {
      if (!newSecondaryEmail) {
        isValid = true;
      } else if (validateEmail(newSecondaryEmail)) {
        isValid = true;
      } else {
        isValid = false;
      }
    } else if (ignoreEmail) {
      isValid = true;
    } else {
      isValid = validateEmail(primaryEmail) ? true : false;
    }
    if (!isValid) {
      setShowEmailError(true);
      return false;
    }
    else { return true; }
  };

  const validateRegistrationNumber = () => {
    setShowRegistrationError(false);
    setErrorClass("");
    if (!registrationNo.trim()) {
      setShowRegistrationError(true);
      setErrorClass("form-text text-danger");
      return false;
    }
    else if (registrationNo.length < 9 || registrationNo.length > 10) {
      setShowRegistrationError(true);
      setErrorClass("form-text text-danger");
      return false;
    }
    else { return true; }
  };

  const onChangeHandler = (e) => {
    const { target: { value } } = e;
    if (!isInvalidEmail) {
      setNewSecondaryEmail(value);
    } else {
      setPrimaryEmail(value);
    }
  };

  const onRegistrationChangeHandler = (e) => {
    const { target: { value } } = e;
    setShowRegistrationError(false);
    setErrorClass("");
    setRegistrationNo(!!value ? value.toUpperCase() : value);
  };

  const onCheckboxHandler = () => {
    setChecked(!checked);
    setIgnoreEmail(!ignoreEmail);
  };
    
  const onFillInspectionFormClick = () => {
    trackLMSEvents("url_clicked_on_lms", {
      eventLabel: appointment,
      lsRAID: getUid(),
      Timestamp: moment().format()
    });
    window.open(link, "_blank", 'noopener');
  };

  return (
    <div className={styles.checkInPopup}>
      <div className={styles.checkinText}>
        <p className={styles.txt}>{`Kindly ${!isInvalidEmail ? 'confirm' : 'enter'} the primary email ID  to check-in`}</p>
        <p className={styles.userDetail}>{`${customerName} | ${model} | ${year}`}</p>
      </div>
      {
        !isInvalidEmail &&
        <div className={styles.primaryEmail}>
          <span>PRIMARY EMAIL ID</span>
          <p>{customerEmail}</p>
        </div>
      }
      <div className={styles.primaryEmail}>
        <InputText
          onChange={onChangeHandler}
          value={!isInvalidEmail ? newSecondaryEmail || "" : primaryEmail || ""}
          text={!isInvalidEmail ? EMAIl_PLACEHOLDER.SECONDARY : EMAIl_PLACEHOLDER.PRIMARY}
        />
      </div>
      {
        showEmailError &&
        <small key="emailErr" className={`form-text text-danger`} >{ERROR_MESSAGE.EMAIL}</small>
      }
      {
        isInvalidEmail &&
        <div className={styles.checkinForm}>
          <div className={styles.checkbox}>
            <Checkbox onChangeCallback={() => onCheckboxHandler()} checkedStatus={checked}
              id="chk_popup" name="chec_popup" />
            <span>Don’t have an email id</span>
          </div>
        </div>
      }
      <div className={styles.registrationNo}>
        <InputText
          onChange={onRegistrationChangeHandler}
          value={registrationNo}
          text={"REGISTRATION NO."}
          maxLength={10}
        />
      </div>
      {

        <small key="registrationErr" className={`${errorClass}`} >{ERROR_MESSAGE.REGISTRATION_REQUIRED}</small>
      }
          
      {showActionSection && 
      <>
        <div className={styles.ctaButton}>
          <Button classNames="saveCta" ctaText={POPUP_BUTTONS.CHECK_IN} disabled={(!link && !openCheckInRequired) ? (false || isPosting) : true} onClick={onCheckInClick} />
          <Button classNames="cancelCta" ctaText={POPUP_BUTTONS.CANCEL} onClick={() => { onCancelClick(false); }} />
        </div>
        {openCheckInRequired && <p className={styles.text2}>*To continue please ask the customer to fill the pre-inspection form sent on SMS</p>}
        {link !== "" && !openCheckInRequired && <p className={styles.preInspection}>
          <span onClick={onFillInspectionFormClick}>Fill up the Pre-Inspection Form to continue</span>    
        </p>}
        {!openCheckInRequired && <p className={styles.text2}>*Ask the details from the customer</p>}
      </>
      }
    </div>
  );
};

export default CheckInPopup;