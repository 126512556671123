/* eslint-disable import/no-anonymous-default-export */
import {SLIDER_TYPES, NUMBERS} from '../app-constants';
export const STEP = 1000;

export default ({preCheckData={}, pllMargin=0, step = STEP, isRetailOCB=false, isDirectOcb=false}) => {
  let sliderType = SLIDER_TYPES.NA;
  if (isDirectOcb) {
	const {min=0, optimal=0, max=1, priceAcceptances=[]} = preCheckData?.conversion || {};
	const acceptanceRanges = priceAcceptances.map(item => {
		return {
			key: item.key,
			value: item.acceptanceProbability,
			band: item.band,
		};
	});
	return {
		showSlider: true,
		minPrice: min,
		priceAcceptance: acceptanceRanges,
		sliderData: null,
		defaultValues: [optimal],
		domain: [min, max],
		pendingRequest: 0,
		step,
		sliderType: SLIDER_TYPES.SLIDER,
		maxPrice: max,
		optimalPrice: optimal,
	};
  } else if (isRetailOCB) {
	const { conversion } = preCheckData || {};
	const priceAcceptance = (conversion && conversion.price_acceptance) ? conversion.price_acceptance : [];
	const showSlider = (conversion && conversion.flag === 1) && (!!priceAcceptance && priceAcceptance.length > 0);
	const minPrice = !!conversion ? conversion.start_point - conversion.deduction_amount : 0;
	const customMargin = !!priceAcceptance && priceAcceptance.length > 0 ?
			parseFloat((priceAcceptance[priceAcceptance.length - 1] || {}).key || 0) : 0;
	const maxPrice = minPrice + (customMargin > 0 ? customMargin : 0);
	const domain = [minPrice, maxPrice];
	const defaultValues = [minPrice]; 
	const sliderData = null; 

	const {flag, show_limits_flag} = conversion || {};
			
	if (flag === NUMBERS.ONE) {
		sliderType = SLIDER_TYPES.SLIDER;
	} else if (flag === NUMBERS.ZERO && show_limits_flag === NUMBERS.ONE) {
		sliderType = SLIDER_TYPES.TEXTBOX_WITH_LIMIT;
	} else if (flag === NUMBERS.ZERO && show_limits_flag === NUMBERS.ZERO) {
		sliderType = SLIDER_TYPES.TEXTBOX_NO_LIMIT;
	} else {
		sliderType = SLIDER_TYPES.TEXTBOX_NO_LIMIT; // backward compatibility in case no data from DS
	}

	return {
		showSlider,
		minPrice,
		priceAcceptance,
		sliderData,
		defaultValues,
		domain,
		pendingRequest: 0,
		step,
		sliderType
	};
  } else {
	const { ocbData } = preCheckData || {};
	const priceAcceptance = (ocbData && ocbData.price_acceptance) ? ocbData.price_acceptance : [];

	const showSlider = (ocbData && ocbData.flag === 1) && (!!priceAcceptance && priceAcceptance.length > 0);
	// const { total_pending_counts: { pending, total } } = preCheckData;
	const { limit: { usedLimit=0, totalLimit=0 } = {}} = preCheckData || {};
	const pendingRequest = (parseInt(totalLimit) >= 0 && parseInt(usedLimit) >= 0) ? (totalLimit - usedLimit) : 0;

	const minPrice = !!ocbData ? ocbData.start_point + step - pllMargin : 0; //the 1000 have been added on product owner request, ideally should be in backend (just for reminder)
	const minPriceForHigherValue = !!ocbData ? ocbData.start_point - pllMargin: 0;
	const margin = 0;

	const customMargin = !!priceAcceptance && priceAcceptance.length > 0 ?
			parseFloat((priceAcceptance[priceAcceptance.length - 1] || {}).key || 0) : 0;
	const maxPrice = minPriceForHigherValue + (customMargin > 0 ? customMargin : margin);

	const domain = [minPrice, maxPrice];
	const defaultValues = [minPrice]; // Used c24Quote in place of calculatedc24Quote
	const sliderData = null;// currentAppointmentData.conversion.sliderData;

	const {flag, show_limits_flag} = ocbData || {};
			
	if (flag === NUMBERS.ONE) {
		sliderType = SLIDER_TYPES.SLIDER;
	} else if (flag === NUMBERS.ZERO && show_limits_flag === NUMBERS.ONE) {
		sliderType = SLIDER_TYPES.TEXTBOX_WITH_LIMIT;
	} else if (flag === NUMBERS.ZERO && show_limits_flag === NUMBERS.ZERO) {
		sliderType = SLIDER_TYPES.TEXTBOX_NO_LIMIT;
	} else {
		sliderType = SLIDER_TYPES.TEXTBOX_NO_LIMIT; // backward compatibility in case no data from DS
	}

	return {
		showSlider,
		minPrice,
		priceAcceptance,
		sliderData,
		defaultValues,
		domain,
		pendingRequest,
		step,
		sliderType
	};
  }
};