
import React, { Fragment } from "react";
import styles from "./question-list-tab.module.scss";
import GroupIcon from "./images/group.svg";
import CheckIcon from "./images/check.svg";
import InfoCircle from "./images/info_circle.png";
const noIssues = "no_issues";
const QuestionListTab = (props) => {
  const {text, subtext, active=false, checkbox=false, option } = props;
  const renderNoIssues = () => {
    return (<div className={active ? `${styles.choose} ${styles.active}` : styles.choose}>
      <img src={GroupIcon} alt='grp'/> 
      <p className={styles.answerText}>{text}</p>
      {subtext && <p className={styles.subtext}>{subtext}</p>}
    </div>);
  };
  const renderOtherOptions = () => {
    return (
      <div className={!!active ? `${styles.choose} ${styles.active}` : styles.choose}>
        {active ? <img src={InfoCircle} alt='info'/> : <img src={CheckIcon} alt='chk'/>}
        <p className={styles.answerText}>{text}</p>
        {subtext && <p className={styles.subtext}>{subtext}</p>}
      </div>
    );
  };
  return (
    <>
      {checkbox ?
        <>
          {option.key === noIssues ?
            <Fragment>
              {renderNoIssues()}
            </Fragment>
                        : <Fragment>
                          {renderOtherOptions()}
                        </Fragment>
          }
        </>
                : <div className={!!active ? `${styles.tab} ${styles.active}` : styles.tab}>
                  {text}
                  {subtext && <p className={styles.subtext}>{subtext}</p>}
                </div>
      }
    </>
  );
};

export default QuestionListTab;
