import React from "react";
import Information from "../../information/component";
import styles from "./table-header.module.css";
import { HEADER_KEYS, INFO_TEXT } from "../../../utils/app-constants";

const TableHeader = ({ headerKey, headerValue, isSort, onSortClick, sortOrder, sortKey }) => {
  let orderText = sortOrder === "asc" ? "Asc" : "Desc";
  return (
    <th key={headerKey}>
      {!isSort &&
      <p className={styles.tableHeading}>
        {headerValue}
      </p>
      }
      {isSort && sortKey === headerKey && <p>
        {/* {headerValue} <a link="#" onClick={() => onSortClick(headerKey)} className={styles.ascending}></a> */}
        {headerValue} <a link="#" onClick={() => onSortClick(headerKey)} className={sortOrder === "asc" ? `${styles.ascending}` : `${styles.descending}`}></a>
      </p>}
      {isSort && sortKey !== headerKey && <p className={styles.m0}>
        {headerValue} <a link="#" onClick={() => onSortClick(headerKey)} className={styles.ascending}></a>
      </p>}
      {headerKey === HEADER_KEYS.EXOPHONE && <Information text={INFO_TEXT.EXOPHONE} />}

    </th>

  );
};

export default TableHeader;
