import React from "react";
import PropTypes from "prop-types";
import "./tick.css";
import priceFormatter from "../../../utils/helpers/price-formatter";

const Tick = ({ tick, domain }) => {
  return (
    <div>
      <div className="sliderTicks"
        style={{
          left: `${tick.percent}%`
        }}
      />
      <div className='maxMinLabel'>
        <span className='tickLeft'>₹{priceFormatter(domain[0])}</span>
        <span className='tickRight'>₹{priceFormatter(domain[1])}</span>
      </div>
    </div>
  );
};

Tick.propTypes = {
  tick: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired
  }).isRequired,
  count: PropTypes.number.isRequired,
  format: PropTypes.func.isRequired
};

Tick.defaultProps = {
  format: d => d
};

export default Tick;
