import React, { useRef, useEffect, useState } from "react";
import styles from "./lead-appointments-detail.module.scss";
import H3 from "../shared/h3/component";
import LabelWithText from "../shared/label-with-text/component";
import { LEAD_DETAILS_TABS } from "../../utils/app-constants";
import ReasonDealLost from "../reason-deal-lost";
import { isBikeSource, getUid } from "../../utils/helper";
import { Link } from "react-router-dom";
import { getPortalHostUrl } from "../../utils/url-constants";
import {trackLMSEvents} from "../../utils/helpers/tracking-events";
import moment from "moment";
import Button from "../shared/button";
import SellerIntendPopup from "../seller-intend-popup/component";

const LEAD_APPOINTMENT = {
    DEAL_CREATED_DATE: "Deal Created Date",
    CURRENT_APPOINTMENT_DATE: "Current Appointment Date",
    ORIGINAL_APPT_DATE: "Original Appt Date",
    LINK_TO_RESCHEDULE: "Link to reschedule",
    IT_ITS_VERIFIED: "If its a verified",
    STORE_DETAILS: "Store Details",
    PR_AUTOMATION: "Background Check",
    SELLER_INTENT: "Finance Intent"
};

const LeadAppointmentsDetail = ({ selectedTab, data }) => {
    const appointmentDetailRef = useRef();
    const isBike = isBikeSource();
    const [isPopupActive,setIsPopupActive] = useState(false);
    const clickHandler = () =>{
      setIsPopupActive(true);
    };

    const [updatedSellerIntent,setUpdatedSellerIntent] = useState(undefined);

    const { appointmentId, isPRAutoAllowed, appointmentKey, sellerIntent} = data;
    useEffect(() => {
        if (
            selectedTab &&
      selectedTab === LEAD_DETAILS_TABS.APPOINTMENT_DETAIL.key &&
      appointmentDetailRef &&
      appointmentDetailRef.current
        ) {
            appointmentDetailRef.current.scrollIntoView();
        }
    }, [selectedTab]);
    const onOpenPRLink = () => {
		trackLMSEvents("pr_automation_clicked", {
			eventLabel: appointmentId,
			lsRAID: getUid(),
			event: "pr_automation_clicked",
			eventCategory: "background_check",
			eventAction: "background_check",
			Timestamp: moment().format()
		});
        window.open(
            `${getPortalHostUrl()}lms/negoview/${appointmentKey}`,
            "_blank",
            "noopener"
        );
    };

    return (
        <div ref={appointmentDetailRef} className={styles.leadAppointment}>
            {isBike && <ReasonDealLost reason={data.lost_reason} />}
            <H3 text={LEAD_DETAILS_TABS.APPOINTMENT_DETAIL.label} />
            <div className={styles.textWrapper}>
                <div className={styles.detailsLeftWrapper}>
                    <LabelWithText
                        label={LEAD_APPOINTMENT.DEAL_CREATED_DATE}
                        text={data.deal_created_date}
                    />
                </div>
                <div className={styles.detailsRightWrapper}>
                    <LabelWithText
                        label={LEAD_APPOINTMENT.CURRENT_APPOINTMENT_DATE}
                        text={data.current_appt_date}
                    />
                </div>
            </div>
            <div className={styles.textWrapper}>
                <div className={styles.detailsLeftWrapper}>
                    <LabelWithText
                        label={LEAD_APPOINTMENT.ORIGINAL_APPT_DATE}
                        text={data.original_appt_date}
                    />
                </div>
                {!!data?.isMonetized && (
                    <div className={styles.detailsRightWrapper}>
                        <p className={styles.leadMon}>Lead Mon</p>
                    </div>
                )}
            </div>
            {!!isPRAutoAllowed && (
                <div className={styles.textWrapper}>
                    <div className={styles.detailsLeftWrapper}>
                        <LabelWithText label={LEAD_APPOINTMENT.PR_AUTOMATION} text="" />
                        <Link to="#" onClick={onOpenPRLink}>
                            {appointmentId}
                        </Link>
                    </div>
                    {
                        sellerIntent !== undefined && 
                        <div className={styles.detailsRightWrapper}>
                            <div className={styles.sellerIntentWithText}>
                                <LabelWithText
                                    label={LEAD_APPOINTMENT.SELLER_INTENT}
                                    text={
                                        <span>
                                            <span>
                                                {updatedSellerIntent != undefined? updatedSellerIntent : sellerIntent?.[appointmentId]?.consent ?? 'Not available'} &nbsp;
                                            </span>       
                                            <Button
                                                classNames={`blueButton`}
                                                ctaText={(updatedSellerIntent != undefined)||(!!sellerIntent && !!sellerIntent?.[appointmentId]?.consent)?'Update':'Add'} 
                                                onClick={clickHandler} 
                                            />
                                        </span>
                                    }
                                />
                            </div>
                        {
                            isPopupActive && 
                            <SellerIntendPopup
                            year={Number(data?.year)}
                            appointmentId={appointmentId}
                            setIsPopupActive={setIsPopupActive}
                            updatedSellerIntent={updatedSellerIntent}
                            setUpdatedSellerIntent={setUpdatedSellerIntent}
                            details={true}
                            intent={updatedSellerIntent != undefined? updatedSellerIntent : sellerIntent?.[appointmentId]?.consent}
                            />
                        }
                        </div>
                    }
                </div>
            )}
        </div>
    );
};

export default LeadAppointmentsDetail;
