import { createTypes } from "reduxsauce";

export default createTypes(
  `
    RESET_RANGE_BY_KEY
`,
  {
    prefix: "calendarrange/"
  }
);
