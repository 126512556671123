import React, { useState, useEffect, useRef } from "react";
import styles from "./store-search.module.css";
import ButtonLoader from "../../../components/button-loader";
import { useDebounce } from "../../../utils/debaounce";
import { NUMBERS } from "../../../utils/app-constants";
import UnorderedList from "../../../components/shared/unordered-list/component";
import ListItem from "../../../components/shared/list-item/component";
import StoreSearchItem from "../store-search-item";
import { AppointmentService } from "../../../service";

const StoreSearch = ({
  setSelectedStore,
  setIsStoreChange,
  store
}) => {
  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [noRecords, setNoRecords] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [isStoreSearching, setIsStoreSearching] = useState(false);
  const searchRef = useRef(null);
  const searchInputRef = useRef();
  const debouncedSearchTerm = useDebounce(query, 3000);
  const [currentStore, setCurrentStore] = useState(store);

  // useEffect(() => {
  //     !isStoreChange && !isStoreUpdate && setCurrentStore(store);
  // }, [isStoreChange, isStoreUpdate]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  useEffect(() => {
    if (showSearch) {
      searchInputRef.current.focus();
    }
  }, [showSearch]);

  useEffect(() => {
    setIsLoading(false);
    if (query && query.trim().length >= NUMBERS.THREE) {
      setIsLoading(true);
    } else if (data.length > 0) {
      setData([]);
      setIsLoading(false);
    }
  }, [query]);

  useEffect(
    () => {
      // Make sure we have a value (user has entered something in input)
      if (query && query.trim().length >= NUMBERS.THREE) {
        fetchSearchResults();
      }
    },
    [debouncedSearchTerm]
  );

  const handleClickOutside = event => {
    const targetName = event.target.getAttribute('name');
    if (searchRef.current && !searchRef.current.contains(event.target) && targetName !== 'collapsed-search') {
      resetStates();
      // setCurrentStore(store);
      setQuery('');
    }
  };

  const onClickHandler = (item) => {
    if (item) {
      setSelectedStore(item);
      setCurrentStore(item.name);
      setIsStoreChange(item.name !== store);
      setQuery('');
    }
    resetStates();
  };
  const onChangeHandler = (e) => {
    const { target: { value } } = e;
    setQuery(value);
    setNoRecords(false);
    setIsStoreSearching(true);
  };

  const onBlurHandler = e => {
    const { target: { value } } = e;
    console.log({value, currentStore});
    if (!value) {
      setCurrentStore(store);
    }
    setIsStoreSearching(false);
  };

  const fetchSearchResults = () => {
    setIsLoading(true);
    AppointmentService.getStoreStartsWith(query)
      .then(resp => {
        setData(resp.data.detail);
        if (resp.length === NUMBERS.ZERO) {
          setNoRecords(true);
        }
      })
      .catch(() => {
        setData([]);
        setNoRecords(false);
      }).finally(()=>{
        setIsLoading(false);
      });
  };

  const resetStates = () => {
    setData([]);
    setShowSearch(false);
    setNoRecords(false);
    setIsStoreSearching(false);
  };

  return (
    <div ref={searchRef} className={styles.dealerSearchWarpper}>
      <div className={styles.dealerSearch}>
        <input 
          autoComplete="off" 
          ref={searchInputRef} 
          type="text" 
          name="store-search" 
          placeholder="Type min 3 chars to search" 
          onChange={onChangeHandler} 
          value={isStoreSearching ? query : currentStore} 
          onBlur={onBlurHandler}
        />
        {isLoading && <div className={styles.dealerSearchLoader}><ButtonLoader color='#000' /></div>}
        {!isLoading && <button className={styles.searchIcon}></button>}
      </div>
      {
        data.length > 0 &&
        <div className={styles.searchList}>
          <UnorderedList>
            {
              data.map(item => {
                return (
                  <ListItem key={item.idLmsStore} onClick={() => onClickHandler(item)} >
                    <StoreSearchItem item={item} />
                  </ListItem>);
              })
            }
          </UnorderedList>
        </div>
      }
      {
        noRecords &&
        <div className={styles.searchList}>
          <UnorderedList><ListItem classes={styles.noRecords} onClick={onClickHandler} >No Records Found!</ListItem></UnorderedList>
        </div>
      }
    </div>
  );
};

export default StoreSearch;
