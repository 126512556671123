import React, { useState, useEffect, useRef } from "react";
import priceFormatter from "../../utils/helpers/price-formatter";
import styles from "./seller-feedback-toggle.module.scss";
import Close from "../images/newClose.svg";
import { isBikeSource } from "../../utils/helper";

const SellerFeedbackToggle = ({ rowDetails }) => {
  const { sellerFeedback, OnlinefeedbackOption, customerExpectedPrice, OnlineCEPPrice } = rowDetails || {};
  const isBike = isBikeSource();
  const [showFeedBack, setShowFeedBack] = useState(false);
  const menuRef = useRef(null);
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };

  }, []);
  const handleClickOutside = event => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowFeedBack(false);
    }
  };
  return (
    <>
      {(sellerFeedback || OnlinefeedbackOption) && !isBike && 
      <div className={`${styles.sellerFeedback} ${showFeedBack ? styles.active : ''}`} ref={menuRef} onClick={() => setShowFeedBack(!showFeedBack)}>
        <h5>Seller Feeback</h5>
        <div className={styles.feedBackWrapper}>
          <img src={Close} alt="close" className={styles.close}/>
          <p>{sellerFeedback || OnlinefeedbackOption}</p>
          <p><span>CEP:</span> {priceFormatter(customerExpectedPrice || OnlineCEPPrice)}</p>
        </div>
      </div>
      }
    </>
  );
};

export default SellerFeedbackToggle;
