import Types from "./types";
//UPDATE_HEADER_ROW_FILTER_QUERY

const updateHeaderRowFilterQuery = (headerRowFilterQuery) => ({
  type: Types.UPDATE_HEADER_ROW_FILTER_QUERY,
  headerRowFilterQuery
});

export {
  updateHeaderRowFilterQuery
};
