import React, { useState, useEffect } from "react";
import style from "./cj-allocation-status.module.scss";
import Button from "../shared/button/component";
import Popup from "../popup/component";
import CjAllocationStatusPopup from "../../components/cj-allocation-status-popup";
import { getUniqueStoreForCities } from "../../utils/helper";

const CjAllocationStatus = ({ cjAllocationStatus, fetchCjAllocationStatusConnect, homeInspectionFilters, appointments }) => {
  const [allocationStatusOpen, setAllocationStatusOpen] = useState(false);
  const onAllocationStatusOpen = () => {
    setAllocationStatusOpen(true);
  };
  const { hisfilterQuery } = homeInspectionFilters;
  const [showInspectedColum, setShowInspectedColumn] = useState((hisfilterQuery === "&leadDays=1") ? true : false);
  const { totalUnAllocatedCount, cjAllocationStausList } = cjAllocationStatus;
  const [dataList, setDataList] = useState([]);
  const [recordCount, setRecordCount] = useState(0);
  const onAllocationStatusClose = () => {
    setAllocationStatusOpen(false);
  };
  const { unAllocatedCount } = appointments;
  // useEffect(() => {
  //     debugger;
  //     let abc = hisfilterQuery;
  //     let cityStores = getUniqueStoreForCities();
  //     fetchCjAllocationStatusConnect();
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    let filterQuery = "";
    let cityStores = getUniqueStoreForCities();
    filterQuery = "?storeId=" + cityStores;
    if (hisfilterQuery === "init") {
      filterQuery = filterQuery + "&leadDays=2";
    }
    else {
      filterQuery = `${filterQuery}${!!hisfilterQuery ? hisfilterQuery : ''}`;
    }
    fetchCjAllocationStatusConnect(filterQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hisfilterQuery]);
  useEffect(() => {
    if (!!cjAllocationStausList && cjAllocationStausList.length > 0) {
      setDataList(cjAllocationStausList);
      setRecordCount(cjAllocationStausList.length);
    }
    if ((hisfilterQuery === "&leadDays=1")) {
      setShowInspectedColumn(true);
    }
    else {
      setShowInspectedColumn(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(cjAllocationStausList)]);
  return (

    <div className={style.cjtextWrapper}>
      {!!dataList && dataList.length > 0 && <div className={style.cjList}>
        <ul>
          {
            dataList.slice(0, 4).map((item) => {
              return (
                <li>
                  <h3>{item.firstName} {item.lastName}</h3>
                  <p>Allocated: <span>{item.count}</span> </p>
                  {showInspectedColum && <p>Inspected: <span>{item.inspectedCount}</span> </p>}
                </li>
              );

            })
          }

        </ul>
      </div>
      }
      <div className={style.viewpopupWrapper}>
        <div className={style.viewCta}>
          {recordCount > 4 && <Button ctaText={"View All"} classNames="transparentBtn" onClick={onAllocationStatusOpen} />}
          <Popup isOpen={allocationStatusOpen} close={onAllocationStatusClose}>
            <CjAllocationStatusPopup onClose={onAllocationStatusClose} count={unAllocatedCount} />
          </Popup>
        </div>
        <div className={style.notcj}>
          <p>{unAllocatedCount} <span>leads on </span></p>
          <span>which CJ not allocated</span>
        </div>
      </div>
    </div>
  );
};

export default CjAllocationStatus;
