import { createTypes } from "reduxsauce";

export default createTypes(
  `
    FETCH_EVALUATOR_TOKENS
    FETCH_EVALUATOR_TOKENS_SUCCESS
    FETCH_EVALUATOR_TOKENS_FAILURE
    FETCH_RETAIL_VIEW
    FETCH_RETAIL_VIEW_SUCCESS
    FETCH_RETAIL_VIEW_FAILURE
    FETCH_EVALUATOR_VIEW
    FETCH_EVALUATOR_VIEW_SUCCESS
    FETCH_EVALUATOR_VIEW_FAILURE
    RESET_VIEW
    RESET_EVALUATOR_BY_KEY
`,
  {
    prefix: "evaluatorview/"
  }
);
