import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SearchLostLeads from "./component";
import { resetLostLeadSearchByKey } from "./actions";
import { resetSearch } from "../search/actions";

const mapStateToProps = ({
  lostLeadsearch,
  appointments
}) => ({
  lostLeadsearch, //combinedreducer
  appointments //combinedreducer
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  resetLostLeadSearchByKeyConnect: resetLostLeadSearchByKey,
  resetSearchConnect:resetSearch
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SearchLostLeads);
