import React from "react";
import loaderImg from "./images/loader.svg";
import styles from "./loader.module.css";

const Loader = () => {
  return (
    <div className={styles.loader}>
      <img src={loaderImg} alt="loader"/>
    </div>
  );
};

export default Loader;
