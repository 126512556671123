import React from "react";
import { Link } from "react-router-dom";
import styles from "./appointment-link.module.css";
import {trackLMSEvents} from "../../utils/helpers/tracking-events";
import {getUid, isBikeSource} from "../../utils/helper";
const AppointmentLink = ({
  appointmentId,
  trackEvent = false,
  isSaleOnline = false
}) => {
  const isBike = isBikeSource();
  const toLink = isSaleOnline ? `/detail/${appointmentId}?isSaleOnline=${isSaleOnline}` : `/detail/${appointmentId}`;
  const trackReportEvent =()=>{
    if(trackEvent) {
      trackLMSEvents("ls_viewApplicationDetail", {
        eventLabel: appointmentId,
        lsRAID: getUid()
      });
    }
  };

  const onBikeApptClick = () => {
    window.open(toLink,  "_blank", 'noopener');
    trackReportEvent();
  };
    
  return (
    <>
      {isBike ?  <div className={styles.apntmntLink}>
        <Link onClick={onBikeApptClick}>{appointmentId}</Link>
      </div> : <div className={styles.apntmntLink} onClick={trackReportEvent}>
        <Link to= {toLink}>{appointmentId}</Link>
      </div>}
    </>
  );
};

export default AppointmentLink;
