import { PartnersLeadService } from "../../service";
import Types from "./types";

const fetchRaLeads = ({email, pageNo=0, filterQuery='', sort=''}) => dispatch => {
  return new Promise((resolve, reject)=> {
    return PartnersLeadService.getRALeads({userEmail: email,  pageNo: pageNo, filterQuery: filterQuery, sort: sort})
      .then(response => {
        dispatch(fetchLeadsSuccess(response));
        resolve(response);
      })
      .catch(error => {
        dispatch(fetchLeadsFailure(error));
        reject(error);
      });
  });
};

const fetchLeadsSuccess = (data) => ({
  type: Types.FETCH_LEADS_SUCCESS,
  data
});

const fetchLeadsFailure = (error) => ({
  type: Types.FETCH_LEADS_FAILURE,
  error
});

const resetLeads = () => ({
  type: Types.RESET_LEADS
});

const updateLeadsFilterQuery = (query) => ({
  type: Types.UPDATE_LEADS_FILTER_QUERY,
  query
});

export {
  fetchRaLeads,
  resetLeads,
  updateLeadsFilterQuery,
};
