import React from "react";
import PropTypes from "prop-types";
import styles from "./checkbox.module.css";

const Checkbox = ({
  checkedStatus = false,
  id,
  name,
  onClickCallback = () => { },
  onChangeCallback = () => { }
}) => {

  const onClickHandler = (e) => {
    onClickCallback(e);
  };

  const onChangeHandler = (e) => {
    onChangeCallback(e);
  };

  return (
    <div className={styles.checkboxHolder}>
      <input
        onClick={onClickHandler}
        onChange={onChangeHandler}
        type="checkbox"
        id={id}
        name={name}
        checked={checkedStatus}
      />
      <label htmlFor={id} />
    </div>
  );
};

Checkbox.propTypes = {
  onClickCallback: PropTypes.func,
  checkedStatus: PropTypes.bool
};

export default Checkbox;

