import React from "react";
import styles from "./generated-appointment-popup.module.css";
import Button from "../shared/button/component";
import copy from "copy-to-clipboard";
import {navigateToHomeWithDelay, showToastMessages} from "../../utils/helper";

const GeneratedAppointmentPopup = ({
  appointment,
  history
}) => {
  const copyAppointment =()=>{
    copy(`${appointment}`, { format: 'text/plain' });
    // showToastMessages("Copied",true);
    navigateToHomeWithDelay(history);
  };

  return (
    <div className={styles.copyAppointment}>
      <p className={styles.text}>The Appointment ID <strong>{appointment}</strong> is created</p>
      <div className={styles.appointmentButton}>
        <Button ctaText={"Copy Appointment ID"} classNames="blueButton" onClick={copyAppointment}/>
      </div>
    </div>
  );
};

export default GeneratedAppointmentPopup;
