import React, { useEffect, useState, useRef } from "react";
import styles from "./similar-cars.module.scss";
import H2 from "../../components/h2/component";
import Cars from "../../components/images/frontmain_car.jpg";
import carIcon from "../../components/images/nego_car.svg";
import NegoReport from "../../components/images/nego_report.svg";
import Link from "../../components/link";
import { fetchInspectionReport, getFromLocalStorage } from "../../utils/helper";
import InfiniteScroll from 'react-infinite-scroller';
import { NUMBERS } from "../../utils/app-constants";
import priceFormatter from "../../utils/helpers/price-formatter";
import DataInformationMessage from "../../components/shared/data-information-message";
import { APP_SOURCE } from "../../utils/app-constants";
const SimilarCars = ({ match, fetchSimilarCarDetailsConnect, similarCars }) => {
  const { params: { appointmentId } } = match;
  const [carData, setCarData] = useState([]);
  const [carmake, setCarMake] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);

  const scrollRef = useRef();
  const [pageStart] = useState(0);
  const [hasMoreItems, setHasMoreItems] = useState(true);

  const { data, make, model, year, isLoading, error } = similarCars;

  useEffect(() => {
    return () => {

    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const viewReport = (carId) => {
    fetchInspectionReport(appointmentId);
  };
  const loadMoreSimilarCars = (page) => {
    // fetcInspectionListConnect(page);
    setHasMoreItems(false);
    fetchSimilarCarDetailsConnect(appointmentId, page).then(resp => {
      if (!!resp) {
        const { result } = resp;
        // console.log("Total length", detail.length);
        if (result.length === NUMBERS.TWENTY) {
          setHasMoreItems(true);
        }
      }

    });

  };
  return (
    <div className={styles.similarCarsWrapper}>
      {!!make && <H2 text={`Results for previously bought “${make} |${model} | ${year}” `} />}
      <InfiniteScroll
        key={"1"}
        ref={scrollRef}
        className={""}
        pageStart={pageStart}
        loadMore={loadMoreSimilarCars}
        hasMore={hasMoreItems}
      >
        <div className={styles.carsWrapper}>
          {data.length > 0 && data.map(item =>
          // <p>{item.make}</p>
            <div className={styles.carsCard}>
              <div className={styles.carImage}>
                <img src={item.mainImage} alt="cars" />
              </div>
              <div className={styles.similarCarsText}>
                <ul>
                  <li>
                    <div className={styles.carIcons}>
                      <img src={carIcon} alt={APP_SOURCE.CAR} />
                    </div>
                    <p>{item.model} {item.variant} {item.year}</p>
                  </li>
                  <li>
                    <p className={styles.carIconsText}>C24 Quote</p>
                    <p className={styles.price}>{priceFormatter(item.c24Quote, true)}</p>
                  </li>
                  <li>
                    <p className={styles.carIconsTextStore}>Store</p>
                    <p>{item.storeName}</p>
                  </li>
                  <li>
                    <div className={styles.carIcons}><img src={NegoReport} alt={APP_SOURCE.CAR} /></div>
                    <div className={styles.detailsInfo}><Link href="#" onClick={() => viewReport(item.carId)} text={"View Insp. Report"} /></div>
                  </li>
                </ul>
              </div>
            </div>
          )
          }
        </div>
      </InfiniteScroll>
      <DataInformationMessage
        isLoading={isLoading}
        error={error}
        data={data}
        dataLoading={dataLoading}
      />
    </div>
  );
};

export default SimilarCars;
