import Types from "./types";
import { AppointmentService } from "../../service";

const fetchRetailAssociates = (storeId) => dispatch => {
  return AppointmentService.getRetailAssociates(storeId)
    .then(response => {
      const { data: { detail } } = response;
      dispatch(fetchRetailAssociatesSuccess(detail));
      return response;
    })
    .catch(error => dispatch(fetchRetailAssociatesFailure(error)));
};

const fetchRetailAssociatesSuccess = (data) => ({
  type: Types.FETCH_ASSOCIATES_SUCCESS,
  data
});

const fetchRetailAssociatesFailure = (error) => ({
  type: Types.FETCH_ASSOCIATES_FAILURE,
  error
});

const resetAssociates = () => ({
  type: Types.RESET_ASSOCIATES
});

export {
  fetchRetailAssociates,
  resetAssociates
};
