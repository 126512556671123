import React, {useState} from "react";
import styles from "./lead-activity-feed-tabs.module.scss";
import { LEAD_ACTIVITY_FEED } from "../../utils/app-constants";

const LeadActivityFeedTabs = ({
  setActivityTabs
}) => {
  const [selectedTab, SetSelectedTab] = useState('');
  const onClickActivityFeed = (itemText) => {
    setActivityTabs(itemText);
    SetSelectedTab(itemText);
  };
  return (
    <div className={styles.tabs}>
      <ul>
        {
          Object.values(LEAD_ACTIVITY_FEED).map(item => {
            return (
              <li key={item.key} className={item.key === selectedTab ? styles.active : ''} onClick={() => { onClickActivityFeed(item.key); }}>
                {item.label}
              </li>

            );
          })
        }
        {/* className={styles.active} */}
      </ul>
    </div>
  );
};

export default LeadActivityFeedTabs;
