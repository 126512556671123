import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LeadCheckInDeny from "./component";

const mapStateToProps = ({
  hocAccess : {
    apiNoAccess
  }
}) => ({
  apiNoAccess
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LeadCheckInDeny);
