/* eslint-disable import/no-anonymous-default-export */
import { getJavaLeadServiceMetaData } from "../../utils/url-constants";
import { APP_CONSTANTS } from "../common/app-constants";
export default (api) => {
	const createLead = (params) => {
		return new Promise((resolve, reject) => {
			api
				.post(`create-lead?source=${APP_CONSTANTS.SOURCE}`, params)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const postSellonlineQuestion = (params) => {
		return new Promise((resolve, reject) => {
			api
				.post(`sell-online-question`, params)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const updateLeadWithGsDeny = (token, params) => {
		return new Promise((resolve, reject) => {
			api
				.put(`gs/deny/${token}`, params)
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const updateLead = (token, params) => {
		return new Promise((resolve, reject) => {
			api
				.put(
					`update-lead?source=${APP_CONSTANTS.SOURCE}&token=${token}`,
					params
				)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const getLeadDetails = (token) => {
		return new Promise((resolve, reject) => {
			api
				.get(`lead-details/${token}`)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const getAppointmentDetailsByToken = (token) => () => {
		return new Promise((resolve, reject) => {
			api
				.get(`/appointment/token/${token}`)
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const createAppointment = (token, params) => {
		const apiMetaData = getJavaLeadServiceMetaData();
		const headers = {
			headers: {
				Authorization: `Basic ${apiMetaData.key}`,
			},
		};
		return new Promise((resolve, reject) => {
			api
				.post(`appointment?token=${token}&pay=skip`, params, headers)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const rescheduleAppointment = (token, params) => {
		const apiMetaData = getJavaLeadServiceMetaData();
		const headers = {
			headers: {
				Authorization: `Basic ${apiMetaData.key}`,
			},
		};
		return new Promise((resolve, reject) => {
			api
				.put(`appointment/reschedule/${token}`, params, headers)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const sendSMS = (token) => {
		return new Promise((resolve, reject) => {
			api
				.post(`dealer/send-ucm-message/${token}`)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	return {
		postSellonlineQuestion,
		createLead,
		updateLead,
		getLeadDetails,
		updateLeadWithGsDeny,
		getAppointmentDetailsByToken,
		createAppointment,
		sendSMS,
		rescheduleAppointment,
	};
};
