import React, { useEffect, useState, useRef } from "react";
import styles from "./book-appointment-popup.module.scss";
import H2 from "../h2/component";
import InputText from "../shared/input-text/component";
import SelectComponent from "../shared/select/component";
import RadioButton from "../shared/radio-button/component";
import Button from "../shared/button/component";
import { LOCAL_STORAGE_KEYS, NUMBERS, POPUP_BUTTONS, POPUP_HEADING } from "../../utils/app-constants";
import Popup from "../popup";
import { AppointmentService } from "../../service";
import DataInformationMessage from "../shared/data-information-message";
import { getFromLocalStorage, onNumberKeyPress, showToastMessages } from "../../utils/helper";
import CheckInPopup from "../check-in-popup/";
import { validateEmail } from "../../utils/validators/email-validator";
// import { getMocYear, getMocVariant } from "../../utils/mocApi";

const BookAppointmentPopup = ({
  selectedStore,
  history,
  rowDetails,
  onClose,
  setAppointment,
  onAppointmentPopupOpen
}) => {

  const [isLoading, setIsLoading] = useState(false);
  const [appointmentTime, setAppointmentTime] = useState("");
  const [checkInOpen, setCheckInOpen] = useState(false);
  const role = JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA)) ?
        JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA))[LOCAL_STORAGE_KEYS.ROLE] : "";
  const [generatedAppointment, setGeneratedAppointment] = useState(null);
  // const [appointmentData, setAppointmentData] = useState(null);
  const [isValidated, setIsValidated] = useState(true);

  const submitRef = useRef();

  const [customerPhone, setCustomerPhone] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerName, setCustomerName] = useState("");

  const [make, setMake] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [odometer, setOdometer] = useState(null);
  const [awareness, setAwareness] = useState(null);
  const [model, setModel] = useState([]);
  const [year, setYear] = useState([]);
  const [variant, setVariant] = useState([]);
  const [branch, setBranch] = useState([]);
  const [dates, setDates] = useState(null);
  const [slots, setSlots] = useState([]);

  const [makeDropdown, setMakeDropDown] = useState([]);
  const [modelDropDown, setModelDropDown] = useState([]);
  const [yearDropDown, setYearDropDown] = useState([]);
  const [variantDropDown, setVariantDropDown] = useState([]);
  const [odometerDropDown, setOdometerDropDown] = useState([]);
  const [awarenessDropDown, setAwarenessDropDown] = useState([]);
  const [stateDropDown, setStateDropDown] = useState([]);
  const [cityDropDown, setCityDropDown] = useState([]);
  const [branchDropDown, setBranchDropDown] = useState([]);
  const [slotsDropDown, setSlotsDropDown] = useState([]);
  const [datesDropDown, setDatesDropDown] = useState([]);

  const [selectedMake, setSelectedMake] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedVariant, setSelectedVariant] = useState("");
  const [selectedVariantYear, setSelectedVariantYear] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedOdomteter, setSelectedOdometer] = useState("");
  const [selectedAwareness, setSelectedAwareness] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedIsPrepaid, setSelectedIsPrepaid] = useState("");
  const [selectedSlot, setSelectedSlot] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    if (generatedAppointment) {
      // setAppointmentPopup(true);
      setAppointment(generatedAppointment);
      onAppointmentPopupOpen(true);
      onClose();
      // let counter = 0;
      // setInterval(() => {
      //     if(counter < 12) {
      //         setIsLoading(true);
      //         AppointmentService.getCheckInLeads(1, generatedAppointment)
      //             .then(response => {
      //                 if (response.data) {
      //                     const {data: {detail: {result}}} = response;
      //                     const lead = result.find(lead => lead.appointment === generatedAppointment);
      //                     if (lead) {
      //                         setIsLoading(false);
      //                         setAppointmentData(lead);
      //                         clearInterval();
      //                     } else {
      //                         ++counter;
      //                         setIsLoading(true);
      //                     }
      //                 }
      //             })
      //             .catch(error => {
      //                 console.log(error);
      //                 setIsLoading(false);
      //                 ++counter;
      //             });
      //     }
      //     else{
      //         // showToastMessages("Lead not found", false);
      //         clearInterval();
      //         setIsLoading(false);
      //     }
      // }, 5000);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatedAppointment]);

  // useEffect(()=>{
  //     if(appointmentData) {
  //         onCheckInOpen();
  //     }
  // },[appointmentData]);

  useEffect(() => {
    setIsLoading(true);
    fillCustomerDetails();
    checkAppointmentSchedule("Now");
    AppointmentService.getmake()
      .then(response => {
        const { data: { detail } } = response;
        setMake(detail);
        setIsLoading(false);
      }).catch(error => {
        console.log(error);
        //setMake(getMake().detail);
        setIsLoading(false);
      });

    AppointmentService.getOdometer()
      .then(response => {
        const { data: { detail } } = response;
        setOdometer(detail);
        setIsLoading(false);
      }).catch(error => {
        console.log(error);
        // setOdometer(getSourceOfAwareness().odometer);
        // setAwareness(getSourceOfAwareness().awarenessSource);
        setIsLoading(false);
      });

    AppointmentService.getAwareness()
      .then(response => {
        const { data: { detail } } = response;
        setIsLoading(false);
        setAwareness(detail);
      }).catch(error => {
        console.log(error);
        setIsLoading(false);
      });

    AppointmentService.getState()
      .then(response => {
        const { data: { detail } } = response;
        setState(detail);
        setIsLoading(false);
      }).catch(error => {
        console.log(error);
        // setState(getState().detail);
        setIsLoading(false);
      });

    AppointmentService.getCity()
      .then(response => {
        const { data: { detail } } = response;
        setCity(detail);
        setIsLoading(false);
      }).catch(error => {
        console.log(error);
        // setCity(getCity().detail);
        setIsLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (make.length > 0) {
      processMake();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [make]);

  useEffect(() => {
    if (odometer) {
      processOdometer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [odometer]);

  useEffect(() => {
    if (awareness) {
      processAwareness();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [awareness]);

  useEffect(() => {
    if (state.length > 0) {
      processState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (city.length > 0) {
      processCity();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city]);

  useEffect(() => {
    if (branch) {
      processBranch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branch]);

  useEffect(() => {
    if (dates) {
      processDates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates]);

  useEffect(() => {
    if (slots.length > 0) {
      processSlots();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slots]);

  useEffect(() => {
    if (model.length > 0) {
      processModel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  useEffect(() => {
    if (!!year && year.length > 0) {
      processYear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  useEffect(() => {
    if (variant.length > 0) {
      processVariant();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variant]);

  useEffect(() => {
    if (selectedMake) {
      setIsLoading(true);
      AppointmentService.getModel(selectedMake)
        .then(response => {
          const { data: { detail } } = response;
          setModel(detail);
          setIsLoading(false);
        }).catch(error => {
          console.log(error);
          // setModel(getModel(selectedMake).detail);
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMake]);

  useEffect(() => {
    if (selectedModel) {
      setIsLoading(true);
      AppointmentService.getYear(selectedModel).then(response => {
        const { data: { detail } } = response;
        setYear(detail);
        setIsLoading(false);
      }).catch(error => {
        console.log(error);
        // setYear(getYear().detail);
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedModel]);

  useEffect(() => {
    if (selectedYear) {
      // if (year && year[selectedYear]) {
      //     setVariant(year[selectedYear]);
      // }
      // else {
      //     showToastMessages("Year not available for Model", false);
      // }
      setIsLoading(true);
      AppointmentService.getVariant(selectedModel, selectedYear).then(response => {
        const { data: { detail } } = response;
        setVariant(detail);
        setIsLoading(false);
      }).catch(error => {
        console.log(error);
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear]);

  useEffect(() => {
    if (selectedCity) {
      setIsLoading(true);
      AppointmentService.getBranch(selectedCity).then(response => {
        const { data: { detail } } = response;
        setBranch(detail);
        setIsLoading(false);
      }).catch(error => {
        console.log(error);
        setBranch([]);
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCity]);

  useEffect(() => {
    if (selectedBranch) {
      setIsLoading(true);
      AppointmentService.getSlots(selectedBranch).then(response => {
        const { data: { detail } } = response;
        setDates(detail);
        setIsLoading(false);
      }).catch(error => {
        console.log(error);
        // setDates(getSlots().detail);
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBranch]);

  useEffect(() => {
    if (selectedDate) {
      const timeOptions = [];
      const time = [
        "Morning",
        "Afternoon",
        "Evening"
      ];
      if (dates && dates.is_open_slot.toLowerCase() === "yes") {
        const { start_time } = dates;
        // for (let i = 0; i < time.length; i++) {
        //     if (dates["date_slot"][selectedDate]["time"][time[i]]) {
        //         Object.keys(dates["date_slot"][selectedDate]["time"][time[i]]).map(item => {
        timeOptions.push({
          "time_key": start_time,
          "time_value": start_time
        });
        // });
        // }
        // }
      }
      else if (dates && dates.is_open_slot.toLowerCase() === "no") {
        for (let i = 0; i < time.length; i++) {
          if (dates["date_slot"][selectedDate]["time"][time[i]]) {
            Object.keys(dates["date_slot"][selectedDate]["time"][time[i]]).map(item => {
              timeOptions.push({
                "time_key": item,
                "time_value": item
              });
            });
          }
        }
      }
      setSlots(timeOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const onRadioChange = (value) => {
    checkAppointmentSchedule(value);
  };

  const scrollToBottom = () => {
    submitRef.current.scrollIntoView(
      {
        behavior: "smooth"
      }
    );
  };

  const fillCustomerDetails = () => {
    if (rowDetails) {
      const { customerName, customerEmail, phoneNumber } = rowDetails;
      setCustomerName(customerName);
      setCustomerEmail(customerEmail);
      setCustomerPhone(phoneNumber);
    }
  };

  const createResponse = () => {
    const branchObj = branch.find(item => item.location_id === selectedBranch);
    const response = {
      "name": customerName,
      "phone": customerPhone,
      "email": customerEmail,
      "make": {
        "make_id": selectedMake
      },
      "model": {
        "model_id": selectedModel
      },
      "year": selectedYear,
      "variant": {
        "year": selectedYear,
        "variantyear_id": selectedVariantYear,
        "variant_id": selectedVariant
      },
      "branchName": {
        "location_id": selectedBranch,
        "is_prepaid": selectedIsPrepaid
      },
      "currentAppointmentDate": {
        "date_key": selectedDate,
        "date_value": selectedDate
      },
      "appointmentTime": {
        "time_key": selectedSlot,
        "time_value": selectedSlot
      },
      "city": selectedCity,
      "state": selectedState,
      "odometer": selectedOdomteter,
      "sourceAwareness": selectedAwareness,
      "isCC": false,
      "dsa_source": "walk_in",
      latitude: branchObj?.lat,
      longitude: branchObj?.lng,
    };
    return response;
  };

  const checkAppointmentSchedule = (value) => {
    setAppointmentTime(value);
    if (value === "Now") {
      let d = new Date();
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      let year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      setSelectedDate([year, month, day].join('-'));
      setSelectedSlot(new Date().toTimeString().split(" ")[0]);
    }
    else {
      setSelectedSlot("");
      setSelectedDate("");
    }

  };

  const setVariantYearIdCall = (option) => {
    // if (year) {
    //     const variantYear = year[selectedYear].find(yearVariant => yearVariant.variant_id === option);
    //     if (variantYear) {
    //         const { variantyear_id } = variantYear;
    //         setSelectedVariantYear(variantyear_id);
    //     }
    // }
    if (year && selectedYear) {
      let selYearVaraintObj = year.find(x => x.year === selectedYear);
      if (!!selYearVaraintObj) {
        setSelectedVariantYear(selYearVaraintObj.year_id);
      }
    }
  };

  const setIsPrepaidCall = (option) => {
    if (branch) {
      const branchObj = branch.find(item => item.location_id === option);
      if (branchObj) {
        const { is_prepaid } = branchObj;
        setSelectedIsPrepaid(is_prepaid);
      }
    }
  };

  const checkValidation = () => {
    let error = false;
    if (!validateEmail(customerEmail)) {
      setIsValidated(false);
      error = true;
    }
    const inputValues = {
      customerName: {
        key: customerName,
        msg: "Please Enter Customer Name"
      },
      customerEmail: {
        key: customerEmail,
        msg: "Please Enter Customer Email"
      },
      customerPhone: {
        key: customerPhone,
        msg: "Please Enter Phone Number"
      },
      selectedMake: {
        key: selectedMake,
        msg: "Please Select Make"
      },
      selectedModel: {
        key: selectedModel,
        msg: "Please Select Model"
      },
      selectedYear: {
        key: selectedYear,
        msg: "Please Select Year"
      },
      selectedVariant: {
        key: selectedVariant,
        msg: "Please Select Variant"
      },
      selectedCity: {
        key: selectedCity,
        msg: "Please Select City"
      },
      selectedBranch: {
        key: selectedBranch,
        msg: "Please Select Branch"
      },
      selectedDate: {
        key: selectedDate,
        msg: "Please Select Date"
      },
      setSelectedSlot: {
        key: selectedSlot,
        msg: "Please Select Slot"
      },
      selectedState: {
        key: selectedState,
        msg: "Please Select State"
      },
      selectedAwareness: {
        key: selectedAwareness,
        msg: "Please Select Source of Awareness"
      }
    };
    Object.keys(inputValues).map(input => {
      if (!inputValues[input].key) {
        setIsValidated(false);
        error = true;
      }
    });
    return !error;
  };

  const onSubmitLead = () => {
    if (checkValidation()) {
      setIsLoading(true);
      const response = createResponse();
      AppointmentService.bookAppointment(response)
        .then(response => {
          setIsLoading(false);
          const { data: { detail: { appointment } } } = response;
          setGeneratedAppointment(appointment);
        })
        .catch(error => {
          setIsLoading(false);
          const { detail: { message } } = error;
          showToastMessages(message, false);
          throw error;
        });
    }

  };

  const onCheckInOpen = () => {
    setCheckInOpen(true);
  };

  const onCheckInClose = () => {
    setCheckInOpen(false);
  };

  const onMakeChange = (option) => {
    setSelectedMake(option);
    setSelectedModel("");
    setSelectedYear("");
    setSelectedVariant("");
  };
  const onModelChange = (option) => {
    setSelectedModel(option);
    setSelectedYear("");
    setSelectedVariant("");
  };
  const onYearChange = (option) => {
    setSelectedYear(option);
    setSelectedVariant("");
  };

  const onVariantChange = (option) => {
    setSelectedVariant(option);
    setVariantYearIdCall(option);
  };

  const onOdometerChange = (option) => {
    setSelectedOdometer(option);
  };

  const onStateChange = (option) => {
    setSelectedState(option);
  };

  const onCityChange = (option) => {
    setSelectedCity(option);
    setSelectedBranch("");
    checkAppointmentSchedule(appointmentTime);
  };

  const onAwarenessChange = (option) => {
    setSelectedAwareness(option);
  };

  const onBranchChange = (option) => {
    setSelectedBranch(option);
    setIsPrepaidCall(option);
    checkAppointmentSchedule(appointmentTime);
  };

  const onDateChange = (option) => {
    setSelectedDate(option);
    setSelectedSlot("");
  };

  const onSlotChange = (option) => {
    setSelectedSlot(option);
  };

  const processMake = () => {
    // const MakeDropDown = make.map((item)=>{
    const MakeDropDown = make.map((item) => {
      return {
        label: item.make_name,
        value: item.make_id
      };
    });
    setMakeDropDown(MakeDropDown);
  };

  const processOdometer = () => {
    const OdometerDropDown = Object.keys(odometer).map((item) => {
      return {
        label: odometer[item],
        value: item
      };
    });
    setOdometerDropDown(OdometerDropDown);
  };

  const processAwareness = () => {
    const AwarenessDropDown = Object.keys(awareness).map((item) => {
      return {
        label: awareness[item],
        value: item
      };
    });
    setAwarenessDropDown(AwarenessDropDown);
  };

  const processState = () => {
    const StateDropDown = state.map((item) => {
      return {
        label: item.stateName,
        value: item.stateId
      };
    });
    setStateDropDown(StateDropDown);
  };

  const processModel = () => {
    const ModelDropDown = model.map((item) => {
      return {
        label: item.model_name,
        value: item.model_id
      };
    });
    setModelDropDown(ModelDropDown);
  };

  const processBranch = () => {
    const BranchDropDown = branch.map((item) => {
      return {
        label: item.name,
        value: item.location_id
      };
    });
    setBranchDropDown(BranchDropDown);
  };

  const processSlots = () => {
    const SlotsDropDown = slots.map((item) => {
      return {
        label: item.time_key,
        value: item.time_key
      };
    });
    setSlotsDropDown(SlotsDropDown);
  };

  const processDates = () => {
    const { date_slot } = dates;
    if (date_slot) {
      const DatesDropDown = Object.keys(date_slot).map((item) => {
        return {
          label: item,
          value: item
        };
      });
      setDatesDropDown(DatesDropDown);
    }
  };

  const processYear = () => {
    const YearDropDown = year.map((item) => {
      return {
        label: item.year,
        value: item.year
      };
    });

    setYearDropDown(YearDropDown);
  };

  const processCity = () => {
    const CityDropDown = city.map((item) => {
      return {
        label: item.cityName,
        value: item.cityId
      };
    });

    setCityDropDown(CityDropDown);
  };

  const processVariant = () => {
    const VariantDropDown = variant.map((item) => {
      return {
        label: item.variant_display_name,
        value: item.variant_id
      };
    });

    setVariantDropDown(VariantDropDown);
  };

  const onChangeCustomerPhone = (e) => {
    const { target: { value } } = e;
    setCustomerPhone(value);
  };

  const onCustomerEmailChange = (e) => {
    const { target: { value } } = e;
    setCustomerEmail(value);
  };

  const onCustomerNameChange = (e) => {
    const { target: { value } } = e;
    setCustomerName(value);
  };

  const onKeyPressHandler = (e) => {
    onNumberKeyPress(e);
  };

  const phoneNumberLength = (value) => {
    if (!!value && value.length < 10) {
      return false;
    }
    return true;
  };

  return (
    <div id="formPage" className={styles.bookAppointmentWrapper}>
      <H2 text="Book Appointment" />
      <div className={styles.bookAppointmentForm}>
        <div className={styles.leftGridWrapper}>
          <div className={styles.leftGrid}>
            <InputText
              text="Customer Name"
              onChange={onCustomerNameChange}
              value={customerName}
              validationError={!customerName && !isValidated}

            />
          </div>
          <div className={styles.leftGrid}>
            <InputText
              text="Customer Email"
              onChange={onCustomerEmailChange}
              value={customerEmail}
              validationError={!validateEmail(customerEmail) && !isValidated}
            />
          </div>
          <div className={styles.leftGrid}>
            {
              <SelectComponent
                placeholder="Make"
                optionsList={makeDropdown}
                onChange={onMakeChange}
                hasError={!selectedMake && !isValidated}
                isSearchable={true}
              />
            }
          </div>
          <div className={styles.leftGrid}>
            {
              <SelectComponent
                placeholder="Year"
                optionsList={yearDropDown}
                isDisabled={selectedModel ? false : true}
                onChange={onYearChange}
                name={selectedModel}
                hasError={!selectedModel && !isValidated}
                isSearchable={true}
              />
            }
          </div>
          <div className={styles.leftGrid}>
            {
              <SelectComponent
                placeholder="Odometer"
                optionsList={odometerDropDown}
                onChange={onOdometerChange}
                hasError={!selectedOdomteter && !isValidated}
                isSearchable={true}
              />
            }
          </div>
          <div className={styles.leftGrid} onClick={scrollToBottom}>
            {
              <SelectComponent
                placeholder="City"
                optionsList={cityDropDown}
                onChange={onCityChange}
                hasError={!selectedCity && !isValidated}
                isSearchable={true}
              />
            }
          </div>
          <div className={styles.leftGrid} onClick={scrollToBottom}>
            {
              <SelectComponent
                placeholder="Source Of Awareness"
                optionsList={awarenessDropDown}
                onChange={onAwarenessChange}
                hasError={!selectedAwareness && !isValidated}
                isSearchable={true}
              />
            }
          </div>
          <div className={styles.leftGrid}>
            <div className={styles.appointment}>
              <p className={styles.appointmentText}>When to book an appointment?</p>
              <div className={styles.radioText}>
                <RadioButton
                  value="Later"
                  key="Later"
                  id="Later"
                  checkedStatus={appointmentTime === "Later"}
                  onClickCallback={() => { onRadioChange("Later"); }}
                /> <span>Later</span>
              </div>
              <div className={styles.radioText}>
                <RadioButton
                  value="Now"
                  key="Now"
                  id="Now"
                  checkedStatus={appointmentTime === "Now"}
                  onClickCallback={() => { onRadioChange("Now"); }}
                /> <span>Now</span>
              </div>
            </div>
          </div>
          {
            appointmentTime === "Later" && <div className={styles.leftGrid} onClick={scrollToBottom}>
              <SelectComponent
                placeholder="Appointment Dates"
                optionsList={datesDropDown}
                onChange={onDateChange}
                name={selectedBranch}
                isDisabled={selectedBranch ? false : true}
                hasError={!selectedDate && !isValidated}
                isSearchable={true}
              />
            </div>
          }
        </div>
        <div className={styles.rightGridWrapper}>
          <div className={styles.rightGrid}>
            <InputText
              text="Customer Phone"
              onChange={onChangeCustomerPhone}
              value={customerPhone}
              validationError={!phoneNumberLength(customerPhone) && !isValidated}
              maxLength={10}
              onKeyPress={onKeyPressHandler}
            />
          </div>
          <div className={styles.rightGrid}>
            <div className={styles.blankbox}></div>
          </div>
          <div className={styles.rightGrid}>
            {
              <SelectComponent
                placeholder="Model"
                optionsList={modelDropDown}
                isDisabled={selectedMake ? false : true}
                onChange={onModelChange}
                name={selectedMake}
                hasError={!selectedModel && !isValidated}
                isSearchable={true}
              />
            }
          </div>
          <div className={styles.rightGrid}>
            {
              <SelectComponent
                placeholder="Variant"
                optionsList={variantDropDown}
                isDisabled={selectedYear ? false : true}
                onChange={onVariantChange}
                name={selectedYear}
                hasError={!selectedVariant && !isValidated}
                isSearchable={true}
              />
            }
          </div>
          <div className={styles.rightGrid}>
            {
              <SelectComponent
                placeholder="Registration State"
                optionsList={stateDropDown}
                onChange={onStateChange}
                hasError={!selectedState && !isValidated}
                isSearchable={true}
              />
            }
          </div>
          <div className={styles.rightGrid} onClick={scrollToBottom}>
            {
              <SelectComponent
                placeholder="Branch"
                optionsList={branchDropDown}
                onChange={onBranchChange}
                name={selectedCity}
                isDisabled={selectedCity ? false : true}
                hasError={!selectedBranch && !isValidated}
                isSearchable={true}
              />
            }
          </div>
          <div className={styles.appointmentTime}>
            {
              appointmentTime === "Later" && <div className={styles.rightGrid} onClick={scrollToBottom}>
                <SelectComponent
                  placeholder="Appointment Time"
                  optionsList={slotsDropDown}
                  onChange={onSlotChange}
                  name={selectedDate}
                  isDisabled={selectedDate ? false : true}
                  hasError={!selectedSlot && !isValidated}
                  isSearchable={true}
                />
              </div>
            }
          </div>

        </div>
      </div>
      <div ref={submitRef} className={styles.checkinButton}>
        <Button classNames="saveCta" ctaText={POPUP_BUTTONS.SUBMIT} onClick={onSubmitLead} />
      </div>

      <Popup isOpen={checkInOpen} close={onCheckInClose}>
        <CheckInPopup
          history={history}
          onCancelClick={onCheckInClose}
          role={role}
          selectedStore={selectedStore}
        />
      </Popup>

      <DataInformationMessage
        isLoading={isLoading}
        data={[1]}
        error={false}
      />
    </div>
  );
};

export default BookAppointmentPopup;
