import React from "react";
import styles from "./round-arrow-orange.module.css";
import OrangeArrow from "./images/orange-arrow.svg";

const IconRoundArrowOrange = () => {
  return (
    <div className={styles.arrowWrap}>
      <img alt="" src={OrangeArrow} />
    </div>
  );
};

export default IconRoundArrowOrange;
