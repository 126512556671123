import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  userVahanData: {},
  isLoading: true
};

export const fetchVahanDetailsSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    isLoading: true,
    userVahanData: data
  };
};

export const HANDLERS = {
  [Types.FETCH_VAHAN_DETAILS]: fetchVahanDetailsSuccess

};

export default createReducer(INITIAL_STATE, HANDLERS);
