import {NUMBERS} from '../../../utils/app-constants';
const nonFeasibleLocationTypes = ["APPROXIMATE"];

const isSubLocalityApplicable = (addressComponents) => {
  const count = addressComponents.reduce((acc, location) => {
    return location.types && location.types.includes("sublocality") ? acc + 1 : acc;
  }, 0);

  return count >= NUMBERS.TWO ? true : false;
};

export const isLocationFeasibleForInspection = (address) => {
  const { geometry, address_components: addressComponents = [] } = address || {};
  const { location_type: locationType } = geometry || {};
  if (nonFeasibleLocationTypes.includes(locationType)) {
    return isSubLocalityApplicable(addressComponents);
  }
  return true;
};
