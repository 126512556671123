/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from "react";
import styles from "./dealer-details.module.scss";
import H3 from "../shared/h3";
import LabelWithText from "../shared/label-with-text/component";
import Button from "../shared/button";
import { LEAD_DETAILS_TABS, NUMBERS, ERROR_MESSAGE } from "../../utils/app-constants";
import { AppointmentService } from "../../service";
import { trackLMSEvents } from "../../utils/helpers/tracking-events";
import { getUid } from "../../utils/helper";
import { showToastMessages } from "../../utils/helper";
import priceFormatter from "../../utils/helpers/price-formatter";

const DealerDetails = ({
  selectedTab,
  appointmentId
}) => {
  const dealerDetailRef = useRef();
  const [dealerName, setDealerName] = useState("**************");
  const [hvb, setHvb] = useState("**************");
  const [dealerPhone, setDealerPhone] = useState("");
  const [dealerId, setDealerId] = useState("");
  const [showDealerCallOption, setShowDealerCallOption] = useState(false);

  useEffect(() => {
    // getDealerData();
  }, []);
  useEffect(() => {
    if (selectedTab && selectedTab === LEAD_DETAILS_TABS.DEALER_DETAIL.key && dealerDetailRef && dealerDetailRef.current) {
      dealerDetailRef.current.scrollIntoView();
    }
  }, [selectedTab]);

  const getDealerData = () => {
    AppointmentService.getDealerDetail(appointmentId)
      .then(response => {
        const { data: { detail } } = response;
        const { dealerId, dealerName, dealerPhone, bidAmount, auctionCount, showAuction } = detail;
        if (showAuction) {
          setDealerName(dealerName);
          setHvb(bidAmount);
          setDealerPhone(dealerPhone);
          setDealerId(dealerId);
          setShowDealerCallOption(false);
        }
        else {
          if (auctionCount === 1) {
            setDealerName(dealerName);
            setHvb(bidAmount);
            setDealerPhone(dealerPhone);
            setDealerId(dealerId);
            setShowDealerCallOption(true);
          }
          else {
            showToastMessages("Details available only for first auction", false);
          }
        }
      }).catch(error => {
        console.log(error);
        const { detail } = error;
        showToastMessages(detail, false);
      });
  };

  const onGetDealerDetail = () => {
    try {
      trackLMSEvents("ls_getDealerDetail", {
        eventLabel: appointmentId,
        lsRAID: getUid(),
        Timestamp: new Date()
      });
      getDealerData();
    }
    catch (exception) {

    }
  };
  const onDealerConnect = () => {
    trackLMSEvents("ls_callDealer", {
      eventLabel: appointmentId,
      lsRAID: getUid(),
      Timestamp: new Date()
    });
    const payload = {
      "appointment": appointmentId,
      "dealerNumber": dealerPhone,
      "dealerId": dealerId,
    };
    AppointmentService.connectToDealer(payload)
      .then(response => {
        const { data: { detail } } = response;
        showToastMessages(detail);
      }).catch(error => {
        showToastMessages("Unable to connect", false);
      });
  };
  return (
    <div ref={dealerDetailRef} className={styles.leadCarDetailWrapper}>
      <H3 text={"Dealer Details"} />
      <div className={styles.detailsLeftWrapper}>
        {/* <div>
                    <LabelWithText
                        label={"Dealer Name"}
                        text={dealerName}
                    />
                </div> */}
        <div>
          <LabelWithText
            label={"HVB"}
            text={hvb !== "**************" ? priceFormatter(hvb) : hvb}
          />
        </div>
        {dealerPhone === "" &&
        <div className={styles.dealerCta}>
          <Button classNames="transparentBtn" ctaText={"Get Dealer Details"} onClick={() => onGetDealerDetail()} />
        </div>
        }
        {showDealerCallOption &&
        <div className={styles.dealerCta}>
          <Button classNames="transparentBtn" ctaText={"Call Dealer"} onClick={() => onDealerConnect()} />
        </div>
        }
      </div>

    </div>
  );
};

export default DealerDetails;
