import Types from "./types";

const updateFilterQuery = (hisfilterQuery) => ({
  type: Types.UPDATE_FILTER_QUERY,
  hisfilterQuery
});

export {
  updateFilterQuery
};
