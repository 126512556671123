/* eslint-disable no-magic-numbers */
import React from "react";
import Slider from "../slider";
import debounce from "../../../utils/helpers/debounce";
import styles from "./style.module.css";

const PriceRangeSlider = ({
  text = "Price Range",
  min,
  max,
  selectedValue,
  onStepChange,
  step
}) => {

  const [priceRange] = React.useState({ min, max });
  const [priceValue, setPriceValue] = React.useState(selectedValue || max);

  const onUpdateSlider = (val) => {
    setPriceValue(val[0]);
    onStepChange(val[0]);
  };

  const sliderProps = {
    domain: [min, max],
    defaultValues: [selectedValue || max],
    step,
    mode: 1,
    tickCount: 2,
    // eslint-disable-next-line no-magic-numbers
    onUpdate: debounce(onUpdateSlider, 5),
    onChange: (values) => {
      setPriceValue(values[0]);
    },
    handleColor: "orange"
  };

  return (
    <React.Fragment>
      <div className={styles.downpaymentWrapper}>
        <Slider {...sliderProps} />
      </div>
    </React.Fragment>
  );
};

export default PriceRangeSlider;
