import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  charges: null,
  cashbacks: null,
  cepBreaker: null

};

export const fetchCustomerChargesSuccess = (state = INITIAL_STATE, { data }) => {
  const { charges, cashbacks, cepBreaker } = data;
  return { ...state, charges, cashbacks, cepBreaker };
};

export const fetchCustomerOfferFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state };
};

export const HANDLERS = {
  [Types.FETCH_CUSTOMER_CHARGES_SUCCESS]: fetchCustomerChargesSuccess,
  [Types.FETCH_CUSTOMER_CHARGES_FAILURE]: fetchCustomerOfferFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
