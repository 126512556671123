import React from "react";
import Timer from "react-compound-timer";

const CountdownTimer = ({ children, initialTime, direction = "backward", checkpoints }) => {

  return (
    <Timer
      key={initialTime}
      initialTime={initialTime}
      direction={direction}
      formatValue={(value) => `${(value < 10 ? `0${value}` : value)} `}
      checkpoints={checkpoints}
    >
      {
        children
      }
    </Timer>
  );
};

export default CountdownTimer;
