/* eslint-disable no-loop-func */
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import styles from "./user-settings.module.css";
import { getFromLocalStorage } from "../../utils/helper";
import { ROLE_KEYS, LOCAL_STORAGE_KEYS } from "../../utils/app-constants";
import Arrow from "../arrow/component";
// import { useOktaAuth } from '@okta/okta-react';
// import StreamChatInstance from '../../utils/chat/get-stream-chat';
import { useSession, useUser, useDescope } from "@descope/react-sdk";
const UserSettings = ({
  history,
  resetAssociatesConnect,
  // updateChannelSuccessConnect,
  // postLoginSuccessConnect
}) => {
  const { user, isUserLoading } = useUser();
  const { logout } = useDescope();
  const { isAuthenticated, isSessionLoading } = useSession();
	
  const [showDropDownView, setShowDropDownView] = useState(false);
  const menuRef = useRef(null);
  const localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
  const role = localData
        ? JSON.parse(localData)[LOCAL_STORAGE_KEYS.ROLE]
        : "";
  useEffect(() => {
    // getActivechannel();
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowDropDownView(false);
    }
  };

  const onLogout = () => {
    logout();
    resetAssociatesConnect();
    localStorage.clear();
    sessionStorage.clear();
    // resetChannels();
    // StreamChatInstance.closeConnection();
    history.replace("/login");
  };

  const fetchFromLocalData = (key) => {
    const localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
    return localData ? JSON.parse(localData)[key] : "";
  };

  return (
    <div
      className={`${styles.emailWrapper} ${
                showDropDownView ? styles.active : ""
      }`}
      ref={menuRef}>
      <div onClick={() => setShowDropDownView(!showDropDownView)}>
        <div className={styles.emailArrow}>
          <span className={styles.email}>
            {user?.email}
          </span>
          <p>
            <Arrow />
          </p>
        </div>
        {role.toLowerCase() === ROLE_KEYS.HOSTESS && (
          <p className={styles.store}>
            Branch:{" "}
            <span>
              {fetchFromLocalData(LOCAL_STORAGE_KEYS.STORE_NAME)}
            </span>
          </p>
        )}
      </div>
      <div
        className={`${styles.dropDown} ${
                    showDropDownView ? styles.active : ""
        }`}>
        <ul>
          {/* <li>
                        <Link to="#" className={styles.changera}>Change RA</Link>
                    </li> */}
          <li>
            <Link to="/profile" className={styles.profile}>
              Profile
            </Link>
          </li>
          <li onClick={onLogout}>
            <Link to="#" className={styles.logout}>
              Logout
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default UserSettings;
