import React from "react";
import styles from "./ownership-detail.module.css";
import { NUMBERS } from "../../utils/app-constants";

const OwnershipDetail = ({ ownerNumber }) => {

  const getOwner = () => {
    switch (ownerNumber) {
      case NUMBERS.ONE:
        return '1st Owner';
      case NUMBERS.TWO:
        return '2nd Owner';
      case NUMBERS.THREE:
        return '3rd Owner';
      case NUMBERS.FOUR:
        return '4th Owner';
      case NUMBERS.FIVE:
        return '5th Owner';
      default:
        return '';
    }
  };

  return (
    <p className={styles.ownerFlag}>{getOwner()}</p>
  );
};

export default OwnershipDetail;
