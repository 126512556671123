import React, { useEffect, useState } from "react";
import { FILTER_DROPDOWN_SOURCE, SORT_ORDER } from "../../common/app-constants";
import styles from "./lead-sort-filter.module.scss";
import FilterLogo from '../../common/images/Filter.svg';
import SortLogo from '../../common/images/Sort_icon.svg';
const LeadSortFilter = ({
  sortFilter,
  UpdateLeadSortDirectionConnect,
  UpdateLeadFilterQueryConnect,
}) => {
  const {sort='', filter=''} = sortFilter || {};
  const [menuOpen, setMenuOpen] = useState(false);

  const onClickSortOrder = () => {
    let sortOrder = SORT_ORDER.NEWEST_FIRST;
    if (sort === SORT_ORDER.NEWEST_FIRST) {
      sortOrder = SORT_ORDER.OLDEST_FIRST;
    }
    UpdateLeadSortDirectionConnect(sortOrder);
  };

  const onFilterClick = () => {
    setMenuOpen(!menuOpen);
  };

  const onSelectFilter = (filter) => {
    UpdateLeadFilterQueryConnect(filter.value);
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <div className={styles.filter}>
        <div className={styles.sortHeader} onClick={onClickSortOrder}>
          <img src={SortLogo} alt={'sort'}/>
          <span>sort</span>
          <p className={styles.sortInfo}>{sort === SORT_ORDER.OLDEST_FIRST ? 'Oldest First': 'Newest First'}</p>
        </div>
        <div className={styles.sortHeader} onClick={onFilterClick}>
          <img src={FilterLogo} alt={'filter'}/>
          <span>filter</span>
        </div>   
      </div>
      <div className={`${styles.dropDown} ${menuOpen ? styles.active : ''}`}>
        <ul>
          {FILTER_DROPDOWN_SOURCE.map((item, index)=> {
            return (
              <li key={`filter-index-${index}`} onClick={()=> onSelectFilter(item)} className={item.value === filter ? styles.active : ''}>
                {item.label}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default LeadSortFilter;
