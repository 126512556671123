import React, { useEffect, useState, useRef } from "react";
import {
	APPOINTMENT_TYPES_STRING,
	NUMBERS,
	RESET_REDUCER_STATE_KEYS,
	TABLE_COLUMNS,
	ROLE_KEYS,
} from "../../utils/app-constants";
import H2 from "../../components/h2";
import styles from "./appointment-page.module.css";
import Table from "../../components/shared/table";
import TableBody from "../../components/shared/table-body";
import TableRow from "../../components/shared/table-row";
import TableColumn from "../../components/shared/table-column";
import TableHeader from "../../components/shared/table-header";
import { Link } from "react-router-dom";
import {
	getAppointmentTypeFromPathName,
	getTableColumnTypeByKey,
	ColumnTypeMapping,
	getLoginData,
	getUniqueStoreForCities,
  showToastMessages,
} from "../../utils/helper";
import DataInformationMessage from "../../components/shared/data-information-message";
import ReviewInspection from "../../components/review-inspection";
import PaymentProcessingFilter from "../../components/payment-processing-filter";
import Button from "../../components/shared/button";
import HocAccessController from "../../components/hoc-access-controller";
import priceFormatter from "../../utils/helpers/price-formatter";
import { AppointmentService } from "../../service";
import Popup from "../../components/popup";
import popups from "../../utils/popup-constants";
import AllocateCjPopup from "../../components/allocate-cj-popup";
import SoAllocateRAPopup from "../../components/so-allocate-ra-popup/component";

const COLUMN_HEADER = {
  appointmentId: "Appointment ID",
  customerName: " Customer Name", 
  make: "Make", 
  MODEL_YEAR_VAR :"	MODEL/YEAR/VAR",
  exophone: "Exophone", 
  diy_link : "DIY Link",
  allote_cj:'Allocate RA'
};

const AppointmentPage = ({
	location,
	fetchAppointmentsConnect,
	appointments,
	resetAppointmentByKeyConnect,
	reviewInspection,
	resetApiNoAccessConnect,
	cjAllocationStatus,
	fetchCjAllocationStatusConnect,
}) => {
	const {
		appointmentState,
		data,
		isLoading,
		error,
		loadMore,
	} = appointments;
	const [dataLoading, setDataLoading] = useState(false);
	const [renderedData, setRenderedData] = useState([]);
	const [hasMoreItems, setHasMoreItems] = useState(true);
	const [showActions, setShowActions] = useState(true);
	const [currentPopup, setCurrentPopup] = useState({
		popup: "",
		currentRowDetails: {},
	});
	const itemRef = useRef();
	const [lastItemIndex, setLastItemIndex] = useState(9);
	const { pathname } = location;
	const appointmentType = getAppointmentTypeFromPathName(pathname);
	const { reviewInspectionFilterQuery } = reviewInspection;
	const [currentPageNo, setCurrentPageNo] = useState(1);
	const { originalRole = "", isHierarchy } = getLoginData();
	const isLeadMonFilter = reviewInspectionFilterQuery === "LeadMon";
	const isCJM =
		[ROLE_KEYS.CJM, ROLE_KEYS.A2I_HEAD, ROLE_KEYS.FOOTFALL_MANAGER].includes(
			originalRole
		) && isHierarchy;
	const { cjAllocationStausList = [] } = cjAllocationStatus || {};
  const [currentRow,setCurrentRow] = useState();

	useEffect(() => {
    resetApiNoAccessConnect();
		resetAppointmentByKeyConnect(RESET_REDUCER_STATE_KEYS.APPOINTMENT_STATE,appointmentType);
    fetchAppointmentsConnect({
      type: appointmentState,
      page:1,
    }).then((resp) => {
      bindAppointmentsData(resp);
    });
	}, []);

	useEffect(() => {
    setRenderedData(data);
	}, [data]);

	const paginationLoader = (page) => {
		page > 1 ? setDataLoading(true) : setDataLoading(false);
	};

	const updateCjAllocationStatus = () => {
		const cityStores = getUniqueStoreForCities();
		const filterQuery = "?storeId=" + cityStores;
		fetchCjAllocationStatusConnect(filterQuery);
	};

  const resetCurrentPopUp = () => {
		setCurrentPopup({ popup: "", currentRowDetails: {} });
	};

	const loadMoreAppointments = (page) => {
		if (appointmentState && appointmentType === appointmentState) {
			paginationLoader(page);
			setHasMoreItems(false);
      fetchAppointmentsConnect({
        type: appointmentState,
        page,
      }).then((resp) => {
        bindAppointmentsData(resp);
      });
		}
	};

	const bindAppointmentsData = (response) => {
		if (response && response.data) {
      const {
        data: {
          detail: { result },
				},
			} = response;
			if (result.length === NUMBERS.TEN) {
				setHasMoreItems(true);
			}
		}
		setDataLoading(false);
	};

  const getColumn = (key, value, item) => {
    const {appointmentId} = item;
    if(key == 'appointmentId'){
      const toLink = `/detail/${value}`;
      return(
        <>
          <Link to= {toLink}>{value}</Link>
        </>
      );
    }
    if(key == 'diy_link'){
      return(
        <SendDiyLinkComponent
          appointmentId = {appointmentId}
          currentRow={item}
        />
      );
    }
    if(key == 'allote_cj'){
      return(
        <span className={styles.allocate}  onClick={() =>onAllocateOpen(item)}>
          Allocate RA
        </span>
      );
    };
    if(key == 'MODEL_YEAR_VAR'){
      return(
        <span className={styles.modleName} title={item?.model+'/'+item?.variant+'/'+item?.year}>
          {item?.model+'/'+item?.variant+'/'+item?.year}
        </span>
      );
    }
    const tableType = getTableColumnTypeByKey(key);
		switch (tableType) {
			case ColumnTypeMapping.WRAPPED_STRING:
				return <div className={styles.warppedText}>{value}</div>;
      case ColumnTypeMapping.COMPONENT:
			case ColumnTypeMapping.STRING:
				return <span className={styles.modleName}>{value}</span>;
			case ColumnTypeMapping.DASH_STRING:
				return (
					<span className={styles.modleName}>{!!value ? value : "-"}</span>
				);
			case ColumnTypeMapping.LINK:
				return (
					<Link className={styles.dataId} to="#">
						{value}
					</Link>
				);
			case ColumnTypeMapping.PRICE_STRING:
				return <span className={styles.modleName}>{priceFormatter(value)}</span>;
			default:
				break;
		}
	};

	const getPageHeading = () => {
    return <H2 text={'Unallocated Leads'}/>;
	};

	const getColumnHeader = (key,title) => {
    return(
      <TableHeader
        key={key}
        headerKey={title}
        headerValue={title}
      />
    );
	};

	const onLoadMore = () => {
    console.log('It it here');
		let i = currentPageNo;
		setCurrentPageNo(i + 1);
		loadMoreAppointments(i + 1);
	};

	const renderAppointments = () => (
		<div className={styles.appointmentList}>
			<Table>
				{data.length > 0 && (
					<thead>
						<TableRow>
							{
                Object.keys(COLUMN_HEADER).map((item) => {
                  return (
                    <>{getColumnHeader(item,COLUMN_HEADER[item])}</>
                  );
							  })
              }
						</TableRow>
					</thead>
				)}
				<TableBody>
					{!isLoading &&
						!error &&
						data.map((item, index) => (
							<TableRow
								key={`row-${index}`}
								tableRowRef={index === lastItemIndex ? itemRef : null}
              >
								<>
									{Object.keys(COLUMN_HEADER).map((res, index) => {
										return (
											<TableColumn key={`col-${index}`}>
												{getColumn(res,item[res],item)}
											</TableColumn>
										);
									})}
								</>
							</TableRow>
						))}
				</TableBody>
			</Table>
		</div>
	);

  const resetAndFetchNewListing = (key) => {
    setHasMoreItems(true);
    resetAppointmentByKeyConnect(RESET_REDUCER_STATE_KEYS.IS_LOADING, true);
    resetAppointmentByKeyConnect(RESET_REDUCER_STATE_KEYS.DATA, []);
    setCurrentPageNo(1);
    loadMoreAppointments(1);
	};

  const onAllocateOpen = (rowDetails) =>{
    setCurrentRow(rowDetails);
    setCurrentPopup({
      popup: popups.ALLOCATE_CJ,
      currentRowDetails: rowDetails,
    });
  };

	return (
		<HocAccessController>
			<div className={styles.fixedheadingSearchWrapper}>
				<div className={styles.headingSearchWrapper}>
					<div className={styles.titleSection}>
						{appointmentType !== APPOINTMENT_TYPES_STRING.SCHEDULED && (
							<div className={styles.title}>
								{getPageHeading()}
								{appointmentType ===
									APPOINTMENT_TYPES_STRING.PAYMENT_PROCESSING && (
									<PaymentProcessingFilter />
								)}
							</div>
						)}
						{[
							APPOINTMENT_TYPES_STRING.LEAD_MON,
							APPOINTMENT_TYPES_STRING.INSPECTED,
						].includes(appointmentType) && (
							<ReviewInspection appointmentType={appointmentType} />
						)}
					</div>
				</div>
			</div>
      {renderAppointments()}
			<DataInformationMessage
				isLoading={isLoading}
				error={error}
				data={renderedData}
				dataLoading={dataLoading}
			/>
			{loadMore && (
				<div className={styles.loadmore}>
					<Button
						classNames="secondaryCta"
						ctaText={"Load More"}
						onClick={onLoadMore}></Button>
				</div>
			)}
      <Popup isOpen={!!currentPopup.popup} close={resetCurrentPopUp}>
        <SoAllocateRAPopup onClose={resetCurrentPopUp} data={currentRow} resetUpdateDataList={()=>{}}/>
      </Popup>
		</HocAccessController>
	);
};

export default AppointmentPage;

const SendDiyLinkComponent = ({appointmentId,currentRow}) => {
  const [diySMSCounter,setDiySMSCounter] = useState(currentRow?.diyLinkLimit);
  const [isSMSSending,setIsSMSSending] = useState(false);

  const onSendInspectionSMS = () => {
    if(diySMSCounter <= 0){
      showToastMessages('DIY SMS limit is 0',false);
      return;
    }
    setIsSMSSending(true);
    AppointmentService.sendInspectionSMS(appointmentId)
      .then((resp) => {
        const { message, diyLinkLimit: updatedDiyLinkLimit } =
          resp?.detail || {}; //
        setDiySMSCounter(updatedDiyLinkLimit);
        showToastMessages(message);
      })
      .catch((err) => {
        showToastMessages(err?.detail, false);
      })
      .finally(() => {
        setIsSMSSending(false);
        // trackLMSEvents("DIY_RA_Manual_LMS_SMS", {
        //   eventLabel: appointmentId,
        //   lsRAID: getUid(),
        //   Timestamp: new Date(),
        //   eventAction: "Send_DIY_SMS",
        //   eventCategory: "DIY_RA_Manual_LMS_SMS",
        // });
      });
  };

  return(
    <>
      {
        currentRow?.diy>0 && 
        <>
          <button
            key={appointmentId}
            className={styles.sendCta}
            onClick={()=>onSendInspectionSMS(appointmentId)}
            disabled={isSMSSending || diySMSCounter < NUMBERS.ONE}
          >{`Send(${diySMSCounter})`}</button>
          <p
            className={styles.errorText}
          >{`${diySMSCounter} SMS remaining`}</p>
        </>
      }
    </>
  );
};
