import React, { useEffect, useState } from "react";
import Button from "../shared/button/component";
import H2 from "../h2/component";
import styles from "./styles.module.css";
import { AppointmentService } from "../../service";
import Loader from "../shared/loader/component";

const ReportPopup = ({data, close}) => {
  const [reportData, setReportData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(()=>{
    if (!!data?.appointmentId) {
      setIsLoading(true);
      AppointmentService.getDIYInspectionReport(data?.appointmentId)
        .then((resp)=>{
          setReportData(resp?.data?.detail?.reviewData);
        }).catch((err)=>{
          console.log(err);
        }).finally(()=>{
          setIsLoading(false);
        });
    }
  },[data]);

  return (
    <div className={styles.reportPopupWrapper}>
      <H2 text={"Inspection Detail"} />
      {isLoading ? <Loader /> :
      <>
        <ul>
          <li>
            <p></p>
            <span>SELLER</span>
            <span>CJ</span>
          </li>
          {!!reportData && Object.values(reportData).map((item)=> {
            return (
              <li>
                <p>{item?.title}</p>
                <span>{item?.sellerInput}</span>
                <span>{item?.CjInput}</span>
              </li>
            );
          }) }
        </ul>
        <div className={styles.gotIt}>
          <Button
            classNames="blueButton"
            ctaText="Got it"
            onClick={()=> close()} 
          />
        </div>
      </>}
    </div>
  );
};

export default ReportPopup;
