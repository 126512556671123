import React, { useState, useEffect } from "react";
import styles from "./review-inspection-report-popup.module.scss";
import H2 from "../h2/component";
import Button from "../shared/button/component";
import { AppointmentService } from "../../service";
import { showToastMessages,getUid } from "../../utils/helper";
import SelectComponent from "../../components/shared/select";
import RadioButton from "../shared/radio-button";
import {trackLMSEvents} from "../../utils/helpers/tracking-events";

const ReviewInspectionReportPopup = ({ rowDetails, onClose, updatereviewInspectionFilterQueryConnect, updatePendingReviewList, insuranceType }) => {
  const { appointment, customerName, model, year, data, version} = rowDetails;
  const { carCondition, chancesOfachievingTargetPrice, documentCondition, totalImapctOnHBTP, videoUrl, noOfPanels } = data;
  const [selectDuplicateKey, setSelectDuplicateKey] = useState('');
  const [insuranceTypes, setInsuranceTypes] = useState([]);
  const [selectInsuranceType, setSelectInsuranceType] = useState('');
  const [selectInsuranceExpiryDate, setSelectInsuranceExpiryDate] = useState('');
  const [showPanelView, setShowPanelView] = useState(true);
  const [showDocumentViewRequired, setShowDocumentViewRequired] = useState(false);
  const [selectedTab, setSelectedTab] = useState('PANEL');
  const submitReview = (status) => {
    let params = {
      'appointmentId': appointment,
      'status': status,
      'duplicateKey': selectDuplicateKey,
      'insuranceType': selectInsuranceType
    };
    pushDataIntoGA(status);
    AppointmentService.submitReviewInspection(params)
      .then(resp => {
        const { data: { detail } } = resp;
        showToastMessages(detail);
        updatereviewInspectionFilterQueryConnect("pendingreviews");
        updatePendingReviewList();
        onClose();
      })
      .catch(err => {
        const { detail } = err;
        showToastMessages(detail, false);
        onClose();
      });
  };
  const onPanelTabClicked = () => {
    setSelectedTab('PANEL');
    setShowPanelView(true);
  };
  const onDocumentTabClicked = () => {
    setSelectedTab('DOCUMENTS');
    setShowPanelView(false);
  };

  useEffect(()=>{
    if (insuranceTypes.length === 0){
      const insuranceTypes = insuranceType.map(item => {
        return {
          value: item,
          label: item
        };
      });
      setInsuranceTypes(insuranceTypes);
    }
    const insType = !!documentCondition && documentCondition["Insurance Type"];
    const dupKey = !!documentCondition && documentCondition["Duplicate Key"];
    !!dupKey && setSelectDuplicateKey(dupKey);
    !!insType && setSelectInsuranceType(insType);
    const expiryDate = !!documentCondition && documentCondition["Insurance Expiry Date"];
    !!expiryDate && setSelectInsuranceExpiryDate(expiryDate);
        ((!!insType && insType.toLowerCase() === 'insurance expired') 
        || (!!dupKey && dupKey.toLowerCase() === "no")) 
            ? setShowDocumentViewRequired(true) : setShowDocumentViewRequired(false);
        if(!!carCondition && !!carCondition.parameters){
          setShowPanelView(true);
        }else{
          setSelectedTab('DOCUMENTS');
          setShowPanelView(false);
        }
  });

  useEffect(()=>{
    if (selectDuplicateKey.toLowerCase() === "yes"){
      setSelectDuplicateKey("Yes");
    }else{
      setSelectDuplicateKey("No");
    }
  },[selectDuplicateKey]);

  const onRadioButtonClick = (value) => {
    if(value === "yes"){
      setSelectDuplicateKey("Yes");
    }
    else{
      setSelectDuplicateKey("No");
    }   
  };

  const onInsuranceTypes = (value) => {
    setSelectInsuranceType(value);
  };

  const getPanelItemDisplay = (key) =>{
    return (
      <li>
        <span>{carCondition.badPanels[key]}</span>
        <p>{key}</p>
      </li>
    );
  };
    
  const pushDataIntoGA = (item) => {
    let action = 'ReviewInspection_Approve';
    if (item === 'rejected'){
      action = 'ReviewInspection_Review';
    }
    trackLMSEvents("ls_Review", {
      eventLabel: appointment,
      lsRAID: getUid(),
      eventCategory:'Inspected',
      eventAction:action,
      Timestamp: new Date()
    });
  };
  const showDisplay = (key) => {
    const {lowerChancesMessage, message, value} = carCondition.parameters[key];
    return (
      <div className={styles.carPullingMainWrapper}>
        <div className={styles.carPulling}>
          <h3>{key}</h3>
          <p>{key === 'Repaired' ? `${noOfPanels} panels` : !!value ? value : ''}</p>
        </div>
        <p className={styles.carPullingText}>{key === 'Repaired' ? message  : lowerChancesMessage}</p>
      </div>
    );
  };

  return (
    <div className={styles.reviewInspectionReportPopup}>
      <H2 text={"review inspection report"} />
      <p className={styles.txt}> {customerName} | {model} | {year}</p>
      <div className={styles.tabs}>
        <ul>
          {showPanelView && <li onClick={onPanelTabClicked} className={selectedTab === 'PANEL' ? styles.active: ''}>Panels</li>}
          {showDocumentViewRequired && <li onClick={onDocumentTabClicked} className={selectedTab === 'DOCUMENTS' ? styles.active : ''}>Documents</li>}
        </ul>
      </div>
      {selectedTab === 'PANEL' && <div className={styles.carCondition}>
        {version === 'v2' && <p className={styles.achieveingTarget}>{totalImapctOnHBTP}% lower chances of achieveing Target Price due to these factors</p>}
        {version === 'v2' && !!carCondition && !!carCondition.parameters && !!carCondition.parameters["Exhaust Smoke"] && <div className={styles.engineSmokeSection}>
          <div className={styles.engineSmokeText}>
            <div>
              <h3>Engine Smoke</h3>
              <p className={styles.lowerText}>{carCondition.parameters["Exhaust Smoke"].value}</p>
            </div>
            <p className={styles.reported}>{carCondition.parameters["Exhaust Smoke"].lowerChancesMessage}</p>
          </div>
          <div className={styles.engineSmokeVideo}>
            <video width="70%" height="100%" controls>
              <source src={videoUrl} type="video/mp4" />
            </video>
          </div>
        </div>
        }
        {
          version === 'v3' && Object.keys(carCondition.parameters).map((key) => { return showDisplay(key);})
        }
        {!!carCondition && !!carCondition.badPanels && version === 'v2' && <div className={styles.panelsSection}>
          <div className={styles.targetPrice}>
            <h3>Panels</h3>
            <p>{chancesOfachievingTargetPrice}% lower chances of acheiving target price</p>
          </div>
          <div className={styles.panelsList}>
            <ul>
              {Object.keys(carCondition.badPanels).map((item) => {return (getPanelItemDisplay(item));})}
            </ul>
          </div>
        </div>
        }
        {!!carCondition && !!carCondition.parameters && version === 'v2' && !!carCondition.parameters["Car pulling on side"] && <div className={styles.carPullingMainWrapper}>
          <div className={styles.carPulling}>
            <h3>Car Pulling By Side</h3>
            <p>{carCondition.parameters["Car pulling on side"].value}</p>
          </div>
          <p className={styles.carPullingText}>{carCondition.parameters["Car pulling on side"].lowerChancesMessage}</p>
        </div>
        }
      </div>}
      {selectedTab === 'DOCUMENTS' &&
      <>
        <div className={styles.documentsMainWrapper}>
          {!!documentCondition && !!documentCondition["Insurance Type"] && documentCondition["Insurance Type"].toLowerCase() === 'insurance expired' && <div className={styles.dataList}>
            <ul>
              <li>
                <h3>Insurance</h3>
                <p className={styles.greenText}>3% higher chances of acheiving target price</p>
              </li>
              <li>
                <h4>Select Insurance Type</h4>
                <div className={styles.insuranceType}>
                  <SelectComponent
                    optionsList={insuranceTypes}
                    placeholder="select"
                    onChange={onInsuranceTypes}
                    defaultValue= {{label: selectInsuranceType, value: selectInsuranceType}}
                  />
                </div>
              </li>
              <li>
                {!!selectInsuranceExpiryDate && 
                <p className={styles.dateText}>
                  Valid insurance as per vahan
                  Expiry date : {selectInsuranceExpiryDate}
                </p>
                }
              </li>
            </ul>
          </div>
          }
          {!!documentCondition && !!documentCondition["Duplicate Key"] && documentCondition["Duplicate Key"].toLowerCase() === "no" && <div className={styles.dataList}>
            <ul>
              <li>
                <h3>Duplicate Key</h3>
                <p className={styles.greenText}>3% higher chances of acheiving target price</p>
              </li>
              <li>
                <RadioButton text={"Not Available"} id={"no"} key= {"no"} onClickCallback={() => { onRadioButtonClick("no");}} checkedStatus={selectDuplicateKey.toLowerCase() === "no"}/>
              </li>
              <li>
                <RadioButton text={"Available"} id={"yes"} key= {"yes"} onClickCallback={() => { onRadioButtonClick("yes"); }} checkedStatus={selectDuplicateKey.toLowerCase() === "yes"}/>
              </li>
            </ul>
          </div>
          }
        </div>
        <p className={styles.reviewInfo}>
          <span>Kindly remind the seller to bring these documents at the time of Car Delivery</span>
        </p>
      </>
      }
      <div className={styles.ctaWrapper}>
        <Button classNames="blueButton" ctaText={"Approve"} onClick={() => submitReview("approved")} />
        <Button classNames="transparentBtn" ctaText={"Reject Inspection report"} onClick={() => submitReview("rejected")} />
      </div>
    </div>
  );
};

export default ReviewInspectionReportPopup;