import React from "react";
import styles from "./no-results.module.css";
import noResultsFound from "./images/no-results-found.svg";
import noleads from "./images/no-leads-diy.png";
import H2 from "../../h2";
import { APPOINTMENT_TYPES_STRING, ERROR_MESSAGE } from "../../../utils/app-constants";
import Button from "../button/component";
import { useHistory } from "react-router-dom";

const NoResults = ({appointmentType=''}) => {
  const history = useHistory();
  return (
    <div className={styles.errorWrapper}>
      <div className={styles.imgWrapper}>
        {appointmentType === APPOINTMENT_TYPES_STRING.DIY_NEGOTIATION ? <img width="70px" src={noleads} alt={ERROR_MESSAGE.NO_RESULTS_HEADING} /> : <img src={noResultsFound} alt={ERROR_MESSAGE.NO_RESULTS_HEADING} />}
      </div>
      <div className={styles.errorTextWrapper}>
        <H2 text={appointmentType === APPOINTMENT_TYPES_STRING.DIY_NEGOTIATION ? ERROR_MESSAGE.NO_LEADS : ERROR_MESSAGE.NO_RESULTS_HEADING} />
        <p className={styles.text}>{appointmentType === APPOINTMENT_TYPES_STRING.DIY_NEGOTIATION ? ERROR_MESSAGE.NO_LEADS_MESSAGE : ERROR_MESSAGE.NO_RESULTS_CONTENT}</p>
      </div>
      {appointmentType === APPOINTMENT_TYPES_STRING.DIY_NEGOTIATION && <div className={styles.buttonWrapper}>
        <Button ctaText={"Go To In Nego"} classNames="blueButton" onClick={()=> history.push("/negotiation")}/>
      </div>}
    </div>
  );
};

export default NoResults;
