import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  filterQuery: null
};

export const updateFilterQuery = (state = INITIAL_STATE, { filterQuery }) => {
  return { ...state, filterQuery };
};

export const HANDLERS = {
  [Types.UPDATE_FILTER_QUERY]: updateFilterQuery

};

export default createReducer(INITIAL_STATE, HANDLERS);
