import React from "react";
import styles from "./customer-partners-viewed.module.scss";
import Cars from "../../components/images/frontmain_car.jpg";
import ViewCar from "../../components/images/viewCar.svg";
import priceFormatter from "../../utils/helpers/price-formatter";

const CustomerPartnersViewed = ({ customerOffer }) => {
  const { customerOffer: { make, model, year, fuel_type, odometer, front_image } } = customerOffer;
  return (
    <div className={styles.customerPartnersviewed}>
      <div className={styles.customerPartnersCars}>
        <img src={front_image} alt="" />
      </div>
      <div className={styles.carsData}>
        <h3>{make} {model}</h3>
        <div className={styles.carDataList}>
          <ul>
            <li>{year}</li>
            <li>{fuel_type}</li>
            <li>{priceFormatter(odometer, true)}km</li>
          </ul>
        </div>
        {/* <div className={styles.viewCar}>
                    <img src={ViewCar} alt={APP_SOURCE.CAR} />
                    <p>12 Channel Partners Viewed</p>
                </div> */}
      </div>
    </div>
  );
};

export default CustomerPartnersViewed;
