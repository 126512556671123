import React, { useState } from "react";
import { AppointmentService } from "../../service";
import FilterSearch from "../images/search.svg";
import styles from "./store-search.module.scss";

const StoreSearch = ({
  // updatePllStoreListConnect
  setData,
  query,
  setQuery,
  errorMessage,
  setErrorMessage,
  searchInProgress,
  setSearchInProgress,
  setStopSearch
}) => {
  // const countPllLead = 1;
  // const [query, setQuery] = useState("");
  // const [errorMessage, setErrorMessage] = useState("");
  // const [searchInProgress, setSearchInProgress] = useState(false);
  // const [showSearch, setShowSearch] = useState(false);

  const onChangeHandler = (e) => {
    const { target: { value } } = e;
    setQuery(value);
    setErrorMessage("");
    setSearchInProgress(false);
  };

  const onEnterPress = (e) => {
    if (e.key === 'Enter') {
      setShowSearch();
    }
  };

  const setShowSearch = () => {
    AppointmentService.getStoreList()
      .then(response => {
        const { data: { detail } } = response;
        if (!!detail && detail.length > 0) {
          const filteredStores = detail.filter((item) => {
            return !!item.name ? item.name.toLowerCase().includes(query) : [];
          });
          if (!!filteredStores && filteredStores.length > 0) {
            setSearchInProgress(true);
            setData(filteredStores);
          }
          else {
            setErrorMessage("no matching record found");
            setSearchInProgress(false);
          }
        }
      })
      .catch(error => { setErrorMessage("no matching record found"); });
  };

  return (
    <div className={styles.filterSearch}>
      <input type="text" name="store-search" placeholder="STORE-SEARCH" onChange={onChangeHandler} value={query} onKeyPress={(e) => { onEnterPress(e); }} />
      {!searchInProgress && !errorMessage && <button>
        <img src={FilterSearch} alt="search" onClick={() => setShowSearch()} />
      </button>
      }
      {(searchInProgress || errorMessage) && <button className={styles.closeSearch} onClick={() => setStopSearch()}>X</button>}
      {errorMessage && <div className={styles.searchError}>{errorMessage}</div>}
    </div>
  );
};

export default StoreSearch;
