import React, {useState, useRef} from "react";
import Button from "../../components/shared/button";
import Loader from "../../components/shared/loader/component";
import { AppointmentService } from "../../service";
import { showToastMessages } from "../../utils/helper";
import styles from "./styles.module.css";

const CSV_TYPES = {
  RA_CATEGORY: 'raCategory',
  QUOTA_FP: 'quotaFp',
};
const UploadCsv = () => {
  const [csvFileBlob, setCsvFileBlob] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const raCategoryfileRef = useRef();
  const quotaFPfileRef = useRef();

  const onUploadFile = (e) => {
    const { target: { files } } = e;
    if (files && files.length > 0) {
      setCsvFileBlob(files[0]);
    }
  };
  const getErors = (list) => {
    if (!list) {
      return "";
    }
    return list.reduce((acc="", curr) => {
      if (curr.message) {
        return acc.concat(", ", curr.message);
      }
      return acc;
    }, "");
  };
  const uploadFile = (type) => {
    if (!csvFileBlob) {
      showToastMessages(`Please select ${type === CSV_TYPES.RA_CATEGORY ? "RA Category": "Quota FP"} CSV file to upload`, false);
      return;
    }
    let payload = new FormData();
    if (type === CSV_TYPES.RA_CATEGORY) {
      payload.append('uploadCategory', csvFileBlob);
      setIsLoading(true);
      AppointmentService.uploadCSV(payload).then((resp)=>{
        const {success=false, error=[]} = resp?.detail || {};
        if (!!success) {
          showToastMessages("Upload data successfully.");
        } else {
          const message = getErors(error);
          showToastMessages(message.substring(2), false);
        }
      }).catch((err)=>{
        showToastMessages(err.detail || err.message || err.error || err, false);
      }).finally(()=>{
        setCsvFileBlob();
        // raCategoryfileRef.current.value="";
        setIsLoading(false);
      });
    } else if(type === CSV_TYPES.QUOTA_FP) {
      payload.append('uploadQuota', csvFileBlob);
      setIsLoading(true);
      AppointmentService.uploadQuotaFPCSV(payload).then((resp)=>{
        showToastMessages(resp?.detail?.message);
      }).catch((err)=>{
        showToastMessages(err.detail || err.message || err.error || err, false);
      }).finally(()=>{
        setCsvFileBlob();
        // quotaFPfileRef.current.value = "";
        setIsLoading(false);
      });
    }
  };

  return (
    <>
      {isLoading ? <Loader />
                : <div className={styles.uploadCsv}>
                  <ul>
                    <li>
                      <label>RA Category</label>
                      <div>
                        <input type="file" name={CSV_TYPES.RA_CATEGORY} accept=".csv" onChange={(e)=> onUploadFile(e, CSV_TYPES.RA_CATEGORY)} ref={raCategoryfileRef}/>
                        <Button 
                          ctaText={"Upload"}
                          classNames="blueButton"
                          onClick={()=>uploadFile(CSV_TYPES.RA_CATEGORY)} 
                        />
                      </div>
                    </li>
                    <li>
                      <label>Quota Fingerprint</label>
                      <div>
                        <input type="file" name={CSV_TYPES.QUOTA_FP} accept=".csv" onChange={(e)=> onUploadFile(e, CSV_TYPES.QUOTA_FP)} ref={quotaFPfileRef}/>
                        <Button 
                          ctaText={"Upload"}
                          classNames="blueButton"
                          onClick={() => uploadFile(CSV_TYPES.QUOTA_FP)} 
                        />
                      </div>
                    </li>
                  </ul>
                </div>}
    </>
  );
};

export default UploadCsv;
