import { createTypes } from "reduxsauce";

export default createTypes(
  `
    SET_API_NO_ACCESS
    RESET_API_NO_ACCESS
`,
  {
    prefix: "hocAccessController/"
  }
);
