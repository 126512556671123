import React from "react";
import styles from "./information.module.css";

const Information = ({ text }) => {
  return (
    <span className={styles.infoWrapper}>
      <span className={styles.info}>
        <i>!</i>
                
        <span className={styles.infoTextWarpper}>
          <span className={styles.infoText}>{text}</span>
        </span>

      </span>
    </span>
  );
};

export default Information;
