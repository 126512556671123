import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CalendarRange from "./component";
import { resetRangeByKey } from "./actions";
import { updateFilterQuery } from "../negotiation-filter/actions";

const mapStateToProps = ({
  negotiationRange
}) => ({
  negotiationRange
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  resetRangeByKeyConnect: resetRangeByKey,
  updateFilterQueryConnect: updateFilterQuery
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CalendarRange);
