import React from "react";
import styles from "./depreciation-inspection-reports.module.scss";
import {DEPRECIATION_CONSTANTS} from "../../utils/app-constants";
import {getDepreciationTooltip} from "../../utils/helper";

const DepreciationInspectionReports = ({graphData}) => {
  const { data } = graphData;

  return (
    <div className={styles.depreciationInspectionReports}>
      <table cellPadding="0" cellSpacing="0" width="100%">
        <thead>
          <tr>
            <th></th>
            <th>Rating</th>
            <th>% Depreciation</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {
            Object.keys(DEPRECIATION_CONSTANTS).map((type)=>{
              const values = getDepreciationTooltip(DEPRECIATION_CONSTANTS[type],data);
              if(values) {
                const {keyData, rating, depr, range, text} = values;
                return (
                  <tr>
                    <td>{text}</td>
                    <td>
                      {
                                                rating ? rating : ""
                      }
                      {
                                                keyData ? keyData.value : ""
                      }
                    </td>
                    <td>
                      {
                        depr && `${depr}%`
                      }
                    </td>

                    <td>{range}</td>
                  </tr>
                );
              }
            })
          }
        </tbody>
      </table>
    </div>
  );
};

export default DepreciationInspectionReports;
