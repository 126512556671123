import React, { useEffect, useState } from "react";
import H2 from "../h2/component";
import SelectComponent from "../shared/select/component";
import styles from "./marketing-survey.module.scss";
import Button from "../shared/button/component";
import { POPUP_BUTTONS, ROLE_KEYS } from "../../utils/app-constants";
import DataInformationMessage from "../../components/shared/data-information-message";
import { navigateToHomeWithDelay, showToastMessages } from "../../utils/helper";
import { AppointmentService } from "../../service";
const MarketingSurvey = ({
  fetchMarketingQuestionsConnect,
  resetAndFetchData,
  history,
  role,
  onClose,
  appointment
}) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [answers, setAnswers] = useState([]);
  const [visible, setVisible] = useState(0);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (!!data) {
      fetchMarketingQuestionsConnect().then((response) => {
        setData(response);
        setIsLoading(false);
      })
        .catch(error => {
          setIsLoading(false);
          showToastMessages("Error in Fetching Questions", false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectChange = (option, questionid) => {
    if (questionid === 1) {  //FIRST QUESTION
      if (option === 2) {
        setVisible(true);
      }
      else {
        setVisible(false);
      }
    }

    const currentResponse = [...answers];
    let question = currentResponse.find(item => item.questionId === questionid);
    if (question) {
      const index = currentResponse.findIndex(item => item.questionId === questionid);
      question.responseId = option;
      currentResponse[index] = question;
    }
    else {
      currentResponse.push({
        "questionId": questionid,
        "responseId": option
      });
    }
    setAnswers(currentResponse);

  };

  const getResponse = () => {
    if (answers.length > 0) {
      return {
        "appointmentId": appointment,
        "questionResponse": answers
      };
    }
    else {
      return null;
    }
  };

  const onSubmitMarketingQuestion = () => {
    const response = getResponse();
    if (response) {
      setDisabled(true);
      AppointmentService.submitMarketingQuestions(response)
        .then(res => {
          setDisabled(false);
          showToastMessages("Response Saved", true);
          onClose();
          navigateToHomeWithDelay(history);
        })
        .catch(error => {
          setDisabled(false);
          showToastMessages("Error in Submitting", false);
        });
    }
    else {
      showToastMessages("Please answer atleast one question", false);

    }

  };

  const getComponent = (question) => {
    const { answers, isVisible } = question;
    return (
      <div className={!isVisible && !visible ? styles.hidden : styles.show}>
        <div className={styles.InputWrapper}>
          <p className={styles.questionText}>{question.questionText}</p>
          <SelectComponent
            placeholder={question.questionText}
            optionsList={answers.map((item) => {
              return {
                label: item.answerText,
                value: item.answerId
              };
            })}
            onChange={onSelectChange}
            questionId={question.questionId}
          />
        </div>
      </div>
    );
  };

  return (

    <div>
      {isLoading && < DataInformationMessage
        isLoading={isLoading}
      />
      }
      <H2 text="Hi, Thanks for visiting us!" />
      <p className={styles.text}>Please fill this quick form to help us serve you better</p>
      {
        data && data.length > 0 &&
        <>
          <div className={styles.appointmentWrapper}>
            {
              data && data.length > 0 &&
                            data.map(question => {
                              return getComponent(question);
                            })
            }
          </div>
          <div className={styles.checkinButton}>
            <Button classNames="saveCta" ctaText={POPUP_BUTTONS.SUBMIT} onClick={onSubmitMarketingQuestion} disabled={disabled} />
          </div>
        </>}
    </div>

  );
};

export default MarketingSurvey;
