import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import InspectionReportPopup from "./component";
import {fetchDepreciationReports,resetDepreciation} from "../depreciation-reports/actions";

const mapStateToProps = ({
  depreciation
}) => ({depreciation});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchDepreciationReportsConnect:fetchDepreciationReports,
  resetDepreciationConnect:resetDepreciation
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InspectionReportPopup);
