import Types from "./types";

const updateApiNoAccess = (error) => dispatch => {
  if (error.status === 422) {
    dispatch(setApiNoAccess(error));
  }
};

const setApiNoAccess = (data) => ({
  type: Types.SET_API_NO_ACCESS, data
});
const resetApiNoAccess = () => ({
  type: Types.RESET_API_NO_ACCESS
});

export {
  updateApiNoAccess,
  resetApiNoAccess
};
