/* eslint-disable indent */
import React, { useEffect, useState } from "react";
// import Logo from "../logo/component";
import styles from "./inspection-report-popup.module.scss";
import CarSpecifications from "../car-specifications";
import Carousel from "../carousel/component";
import carImagesParser from "../../utils/helpers/car-images-parser";
import Button from "../shared/button/component";
// import firebase from "../../utils/firebase";
import { getTimeDifference, getUid } from "../../utils/helper";
import { NUMBERS, REPORTS_TAB } from "../../utils/app-constants";
import CountdownTimer from "../shared/countdown-timer";
import MinuteTimer from "../shared/minute-timer";
import SecondsTimer from "../shared/seconds-timer";
import DepreciationReports from "../depreciation-reports";
import { trackLMSEvents } from "../../utils/helpers/tracking-events";

// const InspectionReportPopup = ({
//     appointmentData,
//     carData
// }) => {
//     const { appointment, year, make, model, variant, carId, auctionStatus } = appointmentData;
//     const images = carImagesParser(carData)['exteriorImages'];
//     const localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
//     const branch = localData ? JSON.parse(localData)[LOCAL_STORAGE_KEYS.STORE_NAME] : "";
//     const [isSuspended] = useState(auctionStatus === NUMBERS.TWO ? true : false);
//     const [isAuctionOver, setIsAuctionOver] = useState(null);
//     const [biddingEndTime, setBiddingEndTime] = useState(null);
//     const [showAuctionTimer, setShowAuctionTimer] = useState(false);
//
//     useEffect(() => {
//         if (!isSuspended) {
//             const carsDb = firebase.getInstance().database().ref(`carsDetail/${carId}`);
//             carsDb.on('value', (snapshot) => {
//                 const data = snapshot.val();
//                 if (data) {
//                     const { isAuctionOver, timer } = data;
//                     data.hasOwnProperty('isAuctionOver') ? setIsAuctionOver(isAuctionOver) : setIsAuctionOver(null);
//                     if (timer) {
//                         const { biddingEndTime } = timer;
//                         timer.hasOwnProperty('biddingEndTime') ? setBiddingEndTime(biddingEndTime * 1000) : setBiddingEndTime(null);
//                     }
//                     if (isAuctionOver) {
//                         carsDb.off();
//                     }
//                 }
//             });
//             return () => {
//                 carsDb.off();
//             };
//         }
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, []);
//
//     useEffect(() => {
//         if (!isSuspended && isAuctionOver != null && !isAuctionOver && biddingEndTime != null && biddingEndTime) {
//             setShowAuctionTimer(true);
//         } else {
//             setShowAuctionTimer(false);
//         }
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [isAuctionOver, biddingEndTime]);
//
//     const getStatusText = () => {
//         if (isSuspended) {
//             return <p className={styles.suspended}>Suspended</p>;
//         } else if (isAuctionOver != null) {
//             if (isAuctionOver) {
//                 return <p className={styles.auctionOver}>Auction Over</p>;
//             } else {
//                 return <p className={styles.auctionRuning}>Auction Running...</p>;
//             }
//         }
//     };
//
//     const calculateTime = () => {
//         return getTimeDifference(new Date(), new Date(biddingEndTime));
//     };
//
//     return (
//         <div>
//             <Logo src={Logo} alt="logo" />
//             <div className={styles.inspectionWrap}>
//                 <div className={styles.inspReportPopup}>
//                     <Carousel images={images} />
//                     <div className={styles.carDetailsReport}>
//                         <div className={styles.carInfo}>
//                             <p className={styles.modelDetails}>{`${[year, [make, model, variant].join(" ")].join(", ")}`}</p>
//                             <p className={styles.appointmentid}>Appointment ID: {appointment}</p>
//                             <p className={styles.appointmentid}>Location: {branch}</p>
//                             {showAuctionTimer &&
//                                 <p className={styles.appointmentid}>
//                                     <CountdownTimer
//                                         key={biddingEndTime}
//                                         initialTime={calculateTime()}
//                                     >
//                                         <MinuteTimer /> {`Min : `}
//                                         <SecondsTimer /> Sec
//                                     </CountdownTimer>
//                                 </p>
//                             }
//                         </div>
//                         <div>
//                             {
//                                 getStatusText()
//                             }
//                             <Button
//                                 classNames={"greenButton priceButton"}
//                                 ctaText={`₹ **,**,***`} />
//                         </div>
//                     </div>
//                 </div>
//                 <CarSpecifications currentCarDetails={carData} />
//             </div>
//         </div>
//     );
// };
//

const InspectionReportPopup = ({
    appointmentData,
    carData,
    fetchDepreciationReportsConnect,
    depreciation,
    resetDepreciationConnect,
    trackEvent = false
}) => {
    const { appointment, year, make, model, variant, auctionStatus, auctionEndTime, carId } = appointmentData;
    const { data } = depreciation;
    const [currentTab, setCurrentTab] = useState(REPORTS_TAB.inspection);
    const images = carImagesParser(carData)['exteriorImages'];
    // const localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
    const { storeName } = carData;

    useEffect(() => {
        // fetchDepreciationReportsConnect(appointment);  // commented as this is nt required lc-999
        return () => {
            // Reset on unmount
            // resetDepreciationConnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getStatusText = () => {
        switch (auctionStatus) {
            case NUMBERS.ZERO:
                return <p className={styles.auctionOver}>Auction Not Started</p>;
            case NUMBERS.ONE: {
                return <p className={styles.auctionRuning}>Auction Running...</p>;
            }
            case NUMBERS.TWO: {
                return <p className={styles.suspended}>Suspended</p>;
            }
            default: {
                return <></>;
            }

        }
    };

    const calculateTime = () => {
        return getTimeDifference(new Date(), new Date(auctionEndTime));
    };
    const getTimer = () => {
        if (auctionStatus === NUMBERS.ONE) {
            if (auctionEndTime) {
                return (
                  <p className={styles.appointmentid}>
                    <CountdownTimer
                            initialTime={calculateTime()}
                        >
                      <MinuteTimer /> {`Min : `}
                      <SecondsTimer /> Sec
                    </CountdownTimer>
                  </p>);
            }
            else {
                return <>End-Time not received</>;
            }
        }
    };

    const toggleTab = (tab) => {
        setCurrentTab(tab);
        let category = "";
        let eventAction = "";
        if (trackEvent) {
            category = "viewReportButtonFromInspected";
            if (tab === REPORTS_TAB.inspection) {
                eventAction = "View Inspection Report";
            }
            else {
                eventAction = "View Depreciation Report";
            }

            trackLMSEvents(category, {
                eventLabel: appointment,
                eventAction: eventAction,
                lsRAID: getUid()
            });
        }
    };
    const openDepReport = (tab) => {
        // // setCurrentTab(tab);
        // let category = "";
        // let eventAction = "";
        // if (trackEvent) {
        //     eventAction = "View Depreciation Report";
        //     trackLMSEvents(category, {
        //         eventLabel: appointment,
        //         eventAction: eventAction,
        //         lsRAID: getUid()
        //     });
        // }
        // var url = "/dep-report/" + appointment + "?carId=" + carId + "&report=depriciation";
        var url = "/customer-journey/" + appointment + "?showreport= dep&carId=" + carId + "&report=depriciation&isDepReportAvailable=true&pagesource=negotiation";
        window.open(url, "_blank", 'noopener');
    };
    return (
      <div>
        {/* <Logo src={Logo} alt="logo" /> */}
        <div className={styles.reportsTab}>
          <ul>
            <li className={`${currentTab === REPORTS_TAB.inspection ? styles.active : ""}`} onClick={() => { toggleTab(REPORTS_TAB.inspection); }}>{REPORTS_TAB.inspection}</li>
            {
                        // data && <li className={`${currentTab === REPORTS_TAB.depreciation ? styles.active : ""}`} onClick={() => { toggleTab(REPORTS_TAB.depreciation); }}>{REPORTS_TAB.depreciation}</li>
                        // data && <li className={`${currentTab === REPORTS_TAB.depreciation ? styles.active : ""}`} onClick={() => { openDepReport(REPORTS_TAB.depreciation); }}>{REPORTS_TAB.depreciation}</li>
                    }
            {/* {data && <button onClick={openDepReport} >Depriciation Report</button>} */}
          </ul>
        </div>
        {
                currentTab === REPORTS_TAB.inspection && <div className={styles.inspectionWrap}>
                  <div className={styles.inspReportPopup}>
                    <Carousel images={images} />
                    <div className={styles.carDetailsReport}>
                      <div className={styles.carInfo}>
                        <p className={styles.modelDetails}>{`${[year, [make, model, variant].join(" ")].join(", ")}`}</p>
                        <p className={styles.appointmentid}>Appointment ID: {appointment}</p>
                        {
                                    storeName && <p className={styles.appointmentid}>Location: {storeName}</p>
                                }
                        {
                                    getTimer()
                                }
                      </div>
                      <div>
                        {
                                    getStatusText()
                                }
                        <Button
                                    classNames={"greenButton priceButton"}
                                    ctaText={`₹ **,**,***`} />
                      </div>
                    </div>
                  </div>
                  <CarSpecifications currentCarDetails={carData} />
                </div>
            }
        {
                currentTab === REPORTS_TAB.depreciation &&
                <DepreciationReports appointmentId={appointment} trackEvent={true} openTime={new Date()} />
            }
      </div>
    );
};

export default InspectionReportPopup;