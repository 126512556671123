import React from "react";
import styles from "./cjplanningcard.module.scss";
import Nego_user from "../images/nego_user.svg";
import Nego_mail from "../images/mail.svg";
import Location from "../images/upArrow.svg";
import Info from "../images/infoDay.svg";
import SelectComponent from "../shared/select/component";

const CjPlanningCard = () => {
  return (
    <div className={styles.cjplanningCard}>
      <table cellSpacing="0" cellPadding="0" width="100%">
        <thead>
          <tr>
            <th>Evaluator Name</th>
            <th>Assigned Store</th>
            <th>Weekly Off</th>
            <th>
              <div className={styles.infoSection}>
                Next 7 days Availability
                <div className={styles.tooltipSection}>
                  <img src={Info} alt="info" />
                  <div className={styles.infoTool}>
                    <p>It shows availability of the evaluator for next 7 days.</p>
                    <ul className={styles.attendanceInfo}>
                      <li><span></span>Present</li>
                      <li><span className={styles.leave}></span>On Leave</li>
                      <li><span className={styles.weeklyOff}></span>Weekly Off</li>
                    </ul>
                  </div>
                </div>
              </div>
              <ul>
                <li>Mon</li>
                <li>Tue</li>
                <li>Wed</li>
                <li>Thu</li>
                <li>Fri</li>
                <li>Sat</li>
                <li>Sun</li>
              </ul>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <span className={styles.count}>1</span>
              <div className={styles.carDetail}>
                <ul>
                  <li>
                    <div className={styles.detailsIcon}><img src={Nego_user} alt="user" /></div>
                    <div className={styles.detailsInfo}>Dinesh Kumar</div>
                  </li>
                  <li>
                    <div className={styles.detailsIcon}><img src={Nego_mail} alt="mail" /></div>
                    <div className={styles.detailsInfo}>dinesh.kumar3@cars24.com</div>
                  </li>
                </ul>
              </div>
            </td>
            <td>
              <div className={styles.carDetail}>
                <ul>
                  <li>
                    <div className={styles.detailsIcon}><img src={Location} alt="location" /></div>
                    <div className={styles.detailsInfo}>HI - South - Gurgaon</div>
                  </li>
                </ul>
              </div>
            </td>
            <td>
              <SelectComponent
                optionsList={[0]}
                name={'dinesh'}
              />
            </td>
            <td>
              <ul className={styles.attendance}>
                <li></li>
                <li className={styles.leave}></li>
                <li></li>
                <li></li>
                <li></li>
                <li className={styles.weeklyOff}></li>
                <li></li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <span className={styles.count}>1</span>
              <div className={styles.carDetail}>
                <ul>
                  <li>
                    <div className={styles.detailsIcon}><img src={Nego_user} alt="user" /></div>
                    <div className={styles.detailsInfo}>Dinesh Kumar</div>
                  </li>
                  <li>
                    <div className={styles.detailsIcon}><img src={Nego_mail} alt="mail" /></div>
                    <div className={styles.detailsInfo}>dinesh.kumar3@cars24.com</div>
                  </li>
                </ul>
              </div>
            </td>
            <td>
              <div className={styles.carDetail}>
                <ul>
                  <li>
                    <div className={styles.detailsIcon}><img src={Location} alt="location" /></div>
                    <div className={styles.detailsInfo}>HI - South - Gurgaon</div>
                  </li>
                </ul>
              </div>
            </td>
            <td>
              <SelectComponent
                optionsList={[0]}
                name={'dinesh'}
              />
            </td>
            <td>
              <ul className={styles.attendance}>
                <li></li>
                <li className={styles.leave}></li>
                <li></li>
                <li></li>
                <li></li>
                <li className={styles.weeklyOff}></li>
                <li></li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <span className={styles.count}>1</span>
              <div className={styles.carDetail}>
                <ul>
                  <li>
                    <div className={styles.detailsIcon}><img src={Nego_user} alt="user" /></div>
                    <div className={styles.detailsInfo}>Dinesh Kumar</div>
                  </li>
                  <li>
                    <div className={styles.detailsIcon}><img src={Nego_mail} alt="mail" /></div>
                    <div className={styles.detailsInfo}>dinesh.kumar3@cars24.com</div>
                  </li>
                </ul>
              </div>
            </td>
            <td>
              <div className={styles.carDetail}>
                <ul>
                  <li>
                    <div className={styles.detailsIcon}><img src={Location} alt="location" /></div>
                    <div className={styles.detailsInfo}>HI - South - Gurgaon</div>
                  </li>
                </ul>
              </div>
            </td>
            <td>
              <SelectComponent
                optionsList={[0]}
                name={'dinesh'}
              />
            </td>
            <td>
              <ul className={styles.attendance}>
                <li></li>
                <li className={styles.leave}></li>
                <li></li>
                <li></li>
                <li></li>
                <li className={styles.weeklyOff}></li>
                <li></li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <span className={styles.count}>1</span>
              <div className={styles.carDetail}>
                <ul>
                  <li>
                    <div className={styles.detailsIcon}><img src={Nego_user} alt="user" /></div>
                    <div className={styles.detailsInfo}>Dinesh Kumar</div>
                  </li>
                  <li>
                    <div className={styles.detailsIcon}><img src={Nego_mail} alt="mail" /></div>
                    <div className={styles.detailsInfo}>dinesh.kumar3@cars24.com</div>
                  </li>
                </ul>
              </div>
            </td>
            <td>
              <div className={styles.carDetail}>
                <ul>
                  <li>
                    <div className={styles.detailsIcon}><img src={Location} alt="location" /></div>
                    <div className={styles.detailsInfo}>HI - South - Gurgaon</div>
                  </li>
                </ul>
              </div>
            </td>
            <td>
              <SelectComponent
                optionsList={[0]}
                name={'dinesh'}
              />
            </td>
            <td>
              <ul className={styles.attendance}>
                <li></li>
                <li className={styles.leave}></li>
                <li></li>
                <li></li>
                <li></li>
                <li className={styles.weeklyOff}></li>
                <li></li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <div className={styles.carDetail}>
                <ul>
                  <li>
                    <div className={styles.detailsIcon}><img src={Nego_user} alt="user" /></div>
                    <div className={styles.detailsInfo}>Dinesh Kumar</div>
                  </li>
                  <li>
                    <div className={styles.detailsIcon}><img src={Nego_mail} alt="mail" /></div>
                    <div className={styles.detailsInfo}>dinesh.kumar3@cars24.com</div>
                  </li>
                </ul>
              </div>
            </td>
            <td>
              <div className={styles.carDetail}>
                <ul>
                  <li>
                    <div className={styles.detailsIcon}><img src={Location} alt="location" /></div>
                    <div className={styles.detailsInfo}>HI - South - Gurgaon</div>
                  </li>
                </ul>
              </div>
            </td>
            <td>
              {/* <SelectComponent/> */}
            </td>
            <td>
              <ul className={styles.attendance}>
                <li></li>
                <li className={styles.leave}></li>
                <li></li>
                <li></li>
                <li></li>
                <li className={styles.weeklyOff}></li>
                <li></li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <div className={styles.carDetail}>
                <ul>
                  <li>
                    <div className={styles.detailsIcon}><img src={Nego_user} alt="user" /></div>
                    <div className={styles.detailsInfo}>Dinesh Kumar</div>
                  </li>
                  <li>
                    <div className={styles.detailsIcon}><img src={Nego_mail} alt="mail" /></div>
                    <div className={styles.detailsInfo}>dinesh.kumar3@cars24.com</div>
                  </li>
                </ul>
              </div>
            </td>
            <td>
              <div className={styles.carDetail}>
                <ul>
                  <li>
                    <div className={styles.detailsIcon}><img src={Location} alt="location" /></div>
                    <div className={styles.detailsInfo}>HI - South - Gurgaon</div>
                  </li>
                </ul>
              </div>
            </td>
            <td>
              {/* <SelectComponent/> */}
            </td>
            <td>
              <ul className={styles.attendance}>
                <li></li>
                <li className={styles.leave}></li>
                <li></li>
                <li></li>
                <li></li>
                <li className={styles.weeklyOff}></li>
                <li></li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <div className={styles.carDetail}>
                <ul>
                  <li>
                    <div className={styles.detailsIcon}><img src={Nego_user} alt="user" /></div>
                    <div className={styles.detailsInfo}>Dinesh Kumar</div>
                  </li>
                  <li>
                    <div className={styles.detailsIcon}><img src={Nego_mail} alt="mail" /></div>
                    <div className={styles.detailsInfo}>dinesh.kumar3@cars24.com</div>
                  </li>
                </ul>
              </div>
            </td>
            <td>
              <div className={styles.carDetail}>
                <ul>
                  <li>
                    <div className={styles.detailsIcon}><img src={Location} alt="location" /></div>
                    <div className={styles.detailsInfo}>HI - South - Gurgaon</div>
                  </li>
                </ul>
              </div>
            </td>
            <td>
              {/* <SelectComponent/> */}
            </td>
            <td>
              <ul className={styles.attendance}>
                <li></li>
                <li className={styles.leave}></li>
                <li></li>
                <li></li>
                <li></li>
                <li className={styles.weeklyOff}></li>
                <li></li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <div className={styles.carDetail}>
                <ul>
                  <li>
                    <div className={styles.detailsIcon}><img src={Nego_user} alt="user" /></div>
                    <div className={styles.detailsInfo}>Dinesh Kumar</div>
                  </li>
                  <li>
                    <div className={styles.detailsIcon}><img src={Nego_mail} alt="mail" /></div>
                    <div className={styles.detailsInfo}>dinesh.kumar3@cars24.com</div>
                  </li>
                </ul>
              </div>
            </td>
            <td>
              <div className={styles.carDetail}>
                <ul>
                  <li>
                    <div className={styles.detailsIcon}><img src={Location} alt="location" /></div>
                    <div className={styles.detailsInfo}>HI - South - Gurgaon</div>
                  </li>
                </ul>
              </div>
            </td>
            <td>
              {/* <SelectComponent/> */}
            </td>
            <td>
              <ul className={styles.attendance}>
                <li></li>
                <li className={styles.leave}></li>
                <li></li>
                <li></li>
                <li></li>
                <li className={styles.weeklyOff}></li>
                <li></li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <div className={styles.carDetail}>
                <ul>
                  <li>
                    <div className={styles.detailsIcon}><img src={Nego_user} alt="user" /></div>
                    <div className={styles.detailsInfo}>Dinesh Kumar</div>
                  </li>
                  <li>
                    <div className={styles.detailsIcon}><img src={Nego_mail} alt="mail" /></div>
                    <div className={styles.detailsInfo}>dinesh.kumar3@cars24.com</div>
                  </li>
                </ul>
              </div>
            </td>
            <td>
              <div className={styles.carDetail}>
                <ul>
                  <li>
                    <div className={styles.detailsIcon}><img src={Location} alt="location" /></div>
                    <div className={styles.detailsInfo}>HI - South - Gurgaon</div>
                  </li>
                </ul>
              </div>
            </td>
            <td>
              {/* <SelectComponent/> */}
            </td>
            <td>
              <ul className={styles.attendance}>
                <li></li>
                <li className={styles.leave}></li>
                <li></li>
                <li></li>
                <li></li>
                <li className={styles.weeklyOff}></li>
                <li></li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <div className={styles.carDetail}>
                <ul>
                  <li>
                    <div className={styles.detailsIcon}><img src={Nego_user} alt="user" /></div>
                    <div className={styles.detailsInfo}>Dinesh Kumar</div>
                  </li>
                  <li>
                    <div className={styles.detailsIcon}><img src={Nego_mail} alt="mail" /></div>
                    <div className={styles.detailsInfo}>dinesh.kumar3@cars24.com</div>
                  </li>
                </ul>
              </div>
            </td>
            <td>
              <div className={styles.carDetail}>
                <ul>
                  <li>
                    <div className={styles.detailsIcon}><img src={Location} alt="location" /></div>
                    <div className={styles.detailsInfo}>HI - South - Gurgaon</div>
                  </li>
                </ul>
              </div>
            </td>
            <td>
              {/* <SelectComponent/> */}
            </td>
            <td>
              <ul className={styles.attendance}>
                <li></li>
                <li className={styles.leave}></li>
                <li></li>
                <li></li>
                <li></li>
                <li className={styles.weeklyOff}></li>
                <li></li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <div className={styles.carDetail}>
                <ul>
                  <li>
                    <div className={styles.detailsIcon}><img src={Nego_user} alt="user" /></div>
                    <div className={styles.detailsInfo}>Dinesh Kumar</div>
                  </li>
                  <li>
                    <div className={styles.detailsIcon}><img src={Nego_mail} alt="mail" /></div>
                    <div className={styles.detailsInfo}>dinesh.kumar3@cars24.com</div>
                  </li>
                </ul>
              </div>
            </td>
            <td>
              <div className={styles.carDetail}>
                <ul>
                  <li>
                    <div className={styles.detailsIcon}><img src={Location} alt="location" /></div>
                    <div className={styles.detailsInfo}>HI - South - Gurgaon</div>
                  </li>
                </ul>
              </div>
            </td>
            <td>
              {/* <SelectComponent/> */}
            </td>
            <td>
              <ul className={styles.attendance}>
                <li></li>
                <li className={styles.leave}></li>
                <li></li>
                <li></li>
                <li></li>
                <li className={styles.weeklyOff}></li>
                <li></li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <div className={styles.carDetail}>
                <ul>
                  <li>
                    <div className={styles.detailsIcon}><img src={Nego_user} alt="user" /></div>
                    <div className={styles.detailsInfo}>Dinesh Kumar</div>
                  </li>
                  <li>
                    <div className={styles.detailsIcon}><img src={Nego_mail} alt="mail" /></div>
                    <div className={styles.detailsInfo}>dinesh.kumar3@cars24.com</div>
                  </li>
                </ul>
              </div>
            </td>
            <td>
              <div className={styles.carDetail}>
                <ul>
                  <li>
                    <div className={styles.detailsIcon}><img src={Location} alt="location" /></div>
                    <div className={styles.detailsInfo}>HI - South - Gurgaon</div>
                  </li>
                </ul>
              </div>
            </td>
            <td>
              {/* <SelectComponent/> */}
            </td>
            <td>
              <ul className={styles.attendance}>
                <li></li>
                <li className={styles.leave}></li>
                <li></li>
                <li></li>
                <li></li>
                <li className={styles.weeklyOff}></li>
                <li></li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CjPlanningCard;
