import React, { useState, useEffect } from "react";
import styles from "./header.module.css";
import LeadMenu from "../lead-menu";
import LogoSection from "../logo-section";
import UserProfile from "../user-profile/component";
import GlobalSearch from "../global-search";
import LeadCheckInMenu from "../lead-check-in-menu";
import Dashboard from "../dashboard";
import { ROLE_KEYS, LOCAL_STORAGE_KEYS, VALID_ROLES, NUMBERS, HEADER_TAB_TYPES, TAB_KEYS } from "../../utils/app-constants";
import { getFromLocalStorage, getStoreIdForSingleOrDoubleStoreAssignned, saveToLocalStorage, getUid, getHomePageURL } from "../../utils/helper";
import Allocate from "../allocate";
import Notifications from "../notifications";
import { Link } from "react-router-dom";
import Popup from "../popup/component";
import LoginAttendancePopup from "../login-attendance-popup/component";
import { trackLMSEvents } from "../../utils/helpers/tracking-events";
import moment from "moment";
const Header = (props) => {
  const { history, onlyLogoHeader, sidebar} = props;
  const {header = []} = sidebar || {};
  const localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
  const {role='', originalRole='', weekOff=0, email, tab=TAB_KEYS.NONE} = JSON.parse(localData) || {};
  const singleStoreAssignedId = getStoreIdForSingleOrDoubleStoreAssignned();//getStoreIdForSingleStoreAssignned();
  const [isLoginActivityOpen, setIsLoginActivityOpen] = useState(false);
  const [tabType, setTabType] = useState(HEADER_TAB_TYPES.NONE);
  const [selectedTab, setSelectedTab] = useState(HEADER_TAB_TYPES.NONE);
  useEffect(()=>{
	const currentRole = role.toLowerCase().trim();
	let defaultTab = TAB_KEYS.NONE;
	let currentTabType = HEADER_TAB_TYPES.NONE;
    if (!role || (role && !VALID_ROLES.includes(currentRole))) {
      localStorage.clear();
      history.replace('/login');
    }
	if ([ROLE_KEYS.RM, ROLE_KEYS.CORP].includes(currentRole) && [
		ROLE_KEYS.TM,
		ROLE_KEYS.PLL_RH,
		ROLE_KEYS.CITY_HEADS,
		ROLE_KEYS.CORP_EDITOR,
		ROLE_KEYS.CORP_VIEWER,
		ROLE_KEYS.PLL_CH,
	].includes(originalRole)) {
		currentTabType = HEADER_TAB_TYPES.BOTH;
		defaultTab = TAB_KEYS.RETAIL;
	} else if ([ROLE_KEYS.PLL_TM, ROLE_KEYS.PLL_ZM_CENTER, ROLE_KEYS.PLL_RM, ROLE_KEYS.PLL_RA].includes(originalRole)) {
		currentTabType  = HEADER_TAB_TYPES.PLL;
		defaultTab = TAB_KEYS.PLL;
	} else if(ROLE_KEYS.PLL_RA_CENTER === originalRole) {
    currentTabType = HEADER_TAB_TYPES.BOTH;
    defaultTab = TAB_KEYS.PLL;
  }
  else if([ROLE_KEYS.RM].includes(currentRole) && [ROLE_KEYS.TM_NEW].includes(originalRole)){
    defaultTab = TAB_KEYS.RETAIL;
  }

	setTabType(currentTabType);
	setSelectedTab(tab !== TAB_KEYS.NONE ? tab : defaultTab);
  if(tab === TAB_KEYS.NONE){
    let jsonData = JSON.parse(localData);
  	jsonData["tab"] = defaultTab;
    saveToLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA, JSON.stringify(jsonData));  
  }
  },[history, originalRole, role, tab]);
    
  const updatePllUser = (value) => {
	let jsonData = JSON.parse(localData);
    jsonData["isPll"] = [TAB_KEYS.PLL, TAB_KEYS.RT].includes(value);
	jsonData["tab"] = value;
    saveToLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA, JSON.stringify(jsonData));
    history.replace(`/${getHomePageURL()}`);
    window.location.reload();
  };

  const onUpdateLoginActivity =()=> {
    const localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
    const {showWeekoffStatus} = JSON.parse(localData) || {};
    if ([NUMBERS.ZERO, NUMBERS.ONE].includes(showWeekoffStatus)) {
      setIsLoginActivityOpen(true);
      trackLMSEvents("Attendance Toggle Button Clicked", {
        eventLabel: "Attendance Toggle Button Clicked" ,
        eventAction: email,
        eventCategory: 'LMS RA Attendance',
        lsRAID: getUid(),
        event: "Attendance Toggle Button Clicked",
        Timestamp: moment().format()
      });
    } else {
      setIsLoginActivityOpen(false);
    }
  };

  return ((
    <div className={styles.header}>
      <div className={styles.logoSection}>
        <LogoSection />
        {!onlyLogoHeader &&
        <React.Fragment>
          {header && header.map((item)=>{
            switch (item.pKey) {
              case 'Leads':
                return (
                  <LeadMenu {...props} data={item} />
                );
              case 'dashboard':
                return (
                  <Dashboard {...props} data={item}/>
                );
              case 'check-in':
                return (
                  <LeadCheckInMenu {...props} />
                );
              case 'upload-csv':
                return (
                  <div className={styles.uploadCsv}>
                    <Link to="/upload-csv">{item.pText}</Link>
                  </div>
                );
              case 'allocate':
                return (
                  <Allocate {...props} />
                );
              default:
                return (
                  <></>
                );
            }
          })
          }
        </React.Fragment>
        }
      </div>
      <div className={styles.userRightSection}>
        {[ROLE_KEYS.RA_TRA,ROLE_KEYS.RA_CX_OCB].includes(originalRole) &&
        <div>
          <span className={weekOff === NUMBERS.TWO ? styles.inactiveButton: styles.activeButton} onClick={()=> onUpdateLoginActivity()} ><strong> * </strong> {weekOff === NUMBERS.TWO ? `Inactive`: `Active`}</span>
          <Popup isOpen={isLoginActivityOpen} close={()=> setIsLoginActivityOpen(false)} showCloseButton={false} closeOnOutside={false} shouldCloseOnEsc={false}>
            <LoginAttendancePopup onClose={()=> setIsLoginActivityOpen(false)} isAlreadyLogin={true} user={email} weekOff={weekOff}/>
          </Popup>
        </div>
        }
        {tabType !== HEADER_TAB_TYPES.NONE && <React.Fragment>
          <ul className={styles.buttonList}>
            {tabType === HEADER_TAB_TYPES.BOTH && <li
              className={selectedTab === TAB_KEYS.RETAIL ? "activeCta" : "deactiveCta"}
              onClick={()=>updatePllUser(TAB_KEYS.RETAIL)}
            >Retail</li>}
			{[HEADER_TAB_TYPES.BOTH, HEADER_TAB_TYPES.PLL].includes(tabType) && <> 
            <li
              className={selectedTab === TAB_KEYS.PLL ? "activeCta" : "deactiveCta"}
              onClick={()=>updatePllUser(TAB_KEYS.PLL)}
            >Pll</li>
			<li
              className={selectedTab === TAB_KEYS.RT ? "activeCta" : "deactiveCta"}
              onClick={()=>updatePllUser(TAB_KEYS.RT)}
            >RT</li>
			</>}
          </ul>
        </React.Fragment>
        }
        {!onlyLogoHeader && role.toLowerCase() !== ROLE_KEYS.HOSTESS &&
        <GlobalSearch {...props} />
        }
        {!!singleStoreAssignedId && singleStoreAssignedId.length > 0 
                    && singleStoreAssignedId.length < 3 
                    && <Notifications storeId={singleStoreAssignedId} history={history} />}
        <UserProfile  {...props} />
      </div>
    </div>
  )
  );
};

export default Header;