import { APP_CONSTANTS } from "../../common/app-constants";
import { PAGE_TYPE } from "./constants";
export const INITIAL_STATE = {
	date: "",
	time: "",
	phone: "",
	contactName: "",
	pageTitle: APP_CONSTANTS.BOOK_INSPECTION,
	page: PAGE_TYPE.DEALER_LOCATION,
	customAddress: "",
	isReschedule: false,
	isDealerCenter: false,
	isCustom: false,
	address1: "",
	address2: "",
	landmark: "",
	pnsLocationId: "",
	locationId: "",
	zoneId: "",
	lat: "",
	lng: "",
	pincode: "",
};
