import React, { useState, useEffect } from "react";
import Button from "../shared/button/component";
import {ACTIONS_BUTTON} from "../../utils/app-constants";
import Popup from "../popup";
import InspectionReportPopup from "../inspection-report-popup";
import { AppointmentService } from "../../service";
import {getUid, showToastMessages} from "../../utils/helper";
import {trackLMSEvents} from "../../utils/helpers/tracking-events";
// import { fetchInspectionReport } from "../../utils/helper";

const ViewInspectionReport = ({ rowDetails }) => {

  const { carId, appointment } = rowDetails;
  // const { carId } = rowDetails;
  const [open, setOpen] = useState(false);
  const [clickedAppointment, setClickedAppointment] = useState();
  const [carDetails, setCarDetails] = useState();

  useEffect(() => {
    if (clickedAppointment) {
      fetchReport();
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [clickedAppointment]);

  const openPopup = () => {
    trackLMSEvents("viewReportFromInspected", {
      eventLabel: appointment,
      lsRAID: getUid()
    });
    setClickedAppointment(appointment);
    setOpen(true);
  };

  const closePopup = () => {
    setClickedAppointment();
    setOpen(false);
    setCarDetails();
  };

  const fetchReport = () => {
    AppointmentService.getCarData(clickedAppointment)
      .then(resp => {
        const { data: { detail: { data } } } = resp;
        const carData = data[clickedAppointment];
        setCarDetails(carData);
      }).catch(err => {
        const { detail: { message } } = err;
        showToastMessages(message, false);
        closePopup();
      });
  };

  return (
    <>
      {carId &&
      <div>
        <Button ctaText={ACTIONS_BUTTON.VIEW_INSPECTION_REPORT}
          classNames="smallCta"
          onClick={openPopup}
          // onClick={onClickHandler}
          disabled={clickedAppointment === appointment}
        />
      </div>
      }
      {!carId &&
      <span>N/A</span>
      }
      {
        clickedAppointment === appointment && carDetails &&
        <Popup isOpen={open} close={closePopup} closeOnOutside={false}>
          <InspectionReportPopup
            appointmentData={rowDetails}
            carData={carDetails}
            trackEvent={true}
          />
        </Popup>
      }
    </>
  );
};

export default ViewInspectionReport;
