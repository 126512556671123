import React, { useState } from "react";

const Accordion = ({
  children,
  totalItems = 1
}) => {
  const itemsArray = [...new Array(totalItems)].map(() => false);
  const [currentVisibleStates, setCurrentVisibleStates] = useState(
    itemsArray
  );

  const onAccordionClickHandler = ({ index }) => {
    const currentState = itemsArray;
    currentState[index] = !currentVisibleStates[index];
    setCurrentVisibleStates([...currentState]);
  };
  return (
    <ul>
      {children({
        onClick: onAccordionClickHandler,
        currentVisibleStates
      })}
    </ul>
  );
};

const Heading = ({ children }) => {
  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
};

const Body = ({ children }) => {
  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
};
const Content = ({
  children
}) => {
  return (
    <li>
      {children}
    </li>
  );
};

Accordion.Content = Content;
Accordion.Heading = Heading;
Accordion.Body = Body;

export default Accordion;