/* eslint-disable indent */
import React, { useState } from "react";
import Popup from "../popup";
import Button from "../shared/button/component";
import ReviewInspectionReportPopup from "../../components/review-inspection-report-popup";
import {trackLMSEvents} from "../../utils/helpers/tracking-events";
import {getUid} from "../../utils/helper";

const ReviewInspectionReport = ({ rowDetails, updatePendingReviewList, insuranceType }) => {
  const { appointment } = rowDetails;
  const [reviewInspectionOpen, setReviewInspectionOpen] = useState(false);
  
  const onreviewInspectionOpen = () => {
    setReviewInspectionOpen(true);
    trackLMSEvents("ls_Review", {
      eventLabel: appointment,
      lsRAID: getUid(),
      eventCategory:'Inspected',
      eventAction: 'ReviewInspectionCTA',
      Timestamp: new Date()
    });
  };

  const onreviewInspectionClose = () => {
    setReviewInspectionOpen(false);
  };
  // const updatePendingReviewList1 = () => {
  //   updatePendingReviewList();
  // }
  return (
    <>
      <div>
        <Button ctaText={"Review Inspection"} onClick={() => onreviewInspectionOpen()} classNames="smallCta" />
      </div>
      <Popup isOpen={reviewInspectionOpen} close={onreviewInspectionClose}>
        <ReviewInspectionReportPopup onClose={onreviewInspectionClose} rowDetails={rowDetails} updatePendingReviewList={updatePendingReviewList} insuranceType={insuranceType}/>
      </Popup>
    </>
  );
};

export default ReviewInspectionReport;
