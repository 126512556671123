import { createTypes } from "reduxsauce";

export default createTypes(
  `
    FETCH_SIMILARCARS_SUCCESS
    FETCH_SIMILARCARS_FAILURE
`,
  {
    prefix: "similarcars/"
  }
);
