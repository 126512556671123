import * as firebase from "firebase/app";
import "firebase/database";
import { configuration } from "../firebase-constants";

class FirebaseInstance {
  constructor() {
    this.configuration = configuration();
    this.firebase = firebase.initializeApp(this.configuration);
  }
}

export default FirebaseInstance;
