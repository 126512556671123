import React from "react";
import UserSettings from "../user-settings";
import UserImage from "../user-image";

const UserProfile = ( props ) => {
  return (
    <>
      <UserImage />
      <UserSettings {...props} />
    </>
  );
};

export default React.memo(UserProfile);
