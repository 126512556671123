import React, { useEffect, useRef, useState } from "react";
import "./superset-dashboard.css";
import { SupersetService } from "../../service";
import {getSupersetMetaData} from '../../utils/url-constants';
import {embedDashboard} from "@superset-ui/embedded-sdk";
const key='dashboard-superset';
const SupersetDashboard = (props) => {
  const {raEmail, reportId, dashboardId} = props || {};
  const supersetRef = useRef(null);
  const supersetMetaData = getSupersetMetaData();
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    const embedSupersetDashboard = async () => {
      try {
        const element = document.getElementById(key);
      if (!element || !reportId) return;
      supersetRef.current = await embedDashboard({
        id: dashboardId,
        supersetDomain: supersetMetaData.url,
        mountPoint: element,
        fetchGuestToken: async () => {
          const response = await SupersetService.fetchGuestToken({
            user: {
              username: raEmail
            },
            resources: [
              {
                type: 'dashboard',
                id: dashboardId,
              },
            ],
            rls: [],
          });
          return response.data.token;
        },
        dashboardUiConfig: {
          hideChartControls: true,
          hideTitle: true,
          filters: {
            expanded: false,
          },
        },
      });
      const size = await supersetRef.current.getScrollSize();
      if (size.width !== 0) {
        const iframe = document.querySelector("iframe");
        iframe.setAttribute(
          "sandbox",
          "allow-same-origin allow-scripts allow-presentation allow-popups allow-top-navigation"
        );
      }
      } catch(ex) {
        setIsError(true);
      }
    };
    embedSupersetDashboard();
    return () => {
      if (supersetRef && supersetRef.current) {
        supersetRef.current.unmount();
      }
    };
  }, []);

  return (
    <>
      <div id={key} /> 
    </>
);
};

export default SupersetDashboard;
