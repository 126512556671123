import React, { useEffect, useRef, useState } from "react";
import H3 from "../shared/h3/component";
import { Link } from "react-router-dom";
import LabelWithText from "../shared/label-with-text/component";
import styles from "./lead-car-detail.module.scss";
import { LEAD_DETAILS_TABS, APPOINTMENT_TYPES_STRING, DEPRECIATION_CONSTANTS, ROLE_KEYS } from "../../utils/app-constants";
import { fetchInspectionReport, getDepreciationTooltip, getTimeDifference, getUid, showToastMessages, isBikeSource, getLoginData } from "../../utils/helper";
import Popup from "../popup";
import DepreciationReportPopup from "../depreciation-report-popup";
import { trackLMSEvents } from "../../utils/helpers/tracking-events";
import { AppointmentService } from "../../service";
import moment from "moment";
import StoreChangeConfirmationPopup from './store-change-confirmation-popup';
import StoreSearch from "./store-search";
import DiffReportPopup from '../diff-report-popup';
const LEAD_CAR_DETAIL = {
  INSPECTION_REPORT_LINK: 'Inspection Report Link',
  CAR_JOCKEY: 'Car Jockey',
  STORE: 'Store',
  DEPRECIATION_REPORT_LINK: 'Depreciation Report Link',
  LATEST_PR_SUBMITTED_BY: "PR Submitted By",
  PA_NAME: "PA Name",
  INS_DATE: "Inspection Date",
  DIFF_REPORT_LINK: 'Diff Report Link',
};

const LeadCarDetail = ({
  selectedTab,
  data,
  fetchDepreciationReportsConnect,
  resetDepreciationConnect,
  depreciation,
}) => {
  const carDetailRef = useRef();
  const {
    carId,
    store,
    carJockey,
    stateName,
    preStateName,
    appointmentId,
    paName,
    paPhone,
    insPectionDate,
    inspReportUrl
  } = data;
  const { data: depreciationData } = depreciation;
  const [open, setOpen] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [praName, setPraName] = useState("");
  const insDate = !!insPectionDate ? moment(insPectionDate).format('DD/MM/YYYY').toString() : "";
  const isBike = isBikeSource();
  const [selectedStore, setSelectedStore] = useState();
  const [isStoreChange, setIsStoreChange] = useState(false);
  const {originalRole='', isHierarchy} = getLoginData();
  const [isDiffReportOpen, setIsDiffReportOpen] = useState(false);
  const isCJMRole = ROLE_KEYS.CJM === originalRole && isHierarchy;
  useEffect(() => {
    fetchDepreciationReportsConnect(appointmentId);
    AppointmentService.GetOrderDetail(appointmentId)
      .then(resp => {
        const { data: { detail } } = resp;
        if (!!detail && detail.length > 0) {
          if (!!detail[0].lastPurchaseSubmittedBy) {
            setPraName(detail[0].lastPurchaseSubmittedBy);
          }
        }
      }).catch(() => {
        // console.log("error");
        setPraName("");
      });

    return () => {
      // Reset on unmount
      resetDepreciationConnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedTab && selectedTab === LEAD_DETAILS_TABS.CAR_DETAIL.key && carDetailRef && carDetailRef.current) {
      carDetailRef.current.scrollIntoView();
    }
  }, [selectedTab]);

  const sendInspectionReport = () => {
    const data = {
      "appointmentId": appointmentId
    };
    AppointmentService.sendInspectionReport(data).then((resp) => {
      const { data: { detail } } = resp;
      showToastMessages(detail, true);
    }).catch(error => {
      const { detail: { message } } = error;
      showToastMessages(message, false);
    });
  };
  const viewReport = () => {
    let _eventCatName = "Negotiation Report";
    if (stateName === "Inspected") {
      _eventCatName = 'Inspection Report';
    } else if (stateName === "In-negotiation") {
      _eventCatName = 'Negotiation Report';
    }
    trackLMSEvents("viewReportButtonFromLeadDetail", {
      eventLabel: appointmentId,
      eventAction: "View Inspection Report",
      eventCategory: _eventCatName,
      lsRAID: getUid()
    });
    if (isBike) {
      !!inspReportUrl && window.open(inspReportUrl,  "_blank", 'noopener');
    } else {
      fetchInspectionReport(appointmentId);
    }
  };
  const closePopup = () => {
    const { data: { data: priceData } } = depreciationData;
    trackLMSEvents("closeReportFromLeadDetail", {
      eventLabel: appointmentId,
      lsViewTime: Math.floor(getTimeDifference(startTime, new Date()) / 1000),
      lsRAID: getUid(),
      webQuote: getDepreciationTooltip(DEPRECIATION_CONSTANTS.veryGoodMax, priceData).gtmData,
      expectedQuote: getDepreciationTooltip(DEPRECIATION_CONSTANTS.final, priceData).gtmData
    });
    setOpen(false);
  };

  const showReport = () => {
    const notAllowedStates = ['scheduled', 'visited'];
    const isLostLead = stateName.trim().toLowerCase() === APPOINTMENT_TYPES_STRING.LOST;
    const currentState = isLostLead ? preStateName.trim().toLowerCase() : stateName.trim().toLowerCase();
    if (notAllowedStates.includes(currentState))
      return false;
    else
      return true;
  };
  const closeStoreChangePopup = () => {
    setSelectedStore();
    if (isStoreChange) {
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
  };
    
  const onStoreChangeSubmit = () => {
    if (!!selectedStore?.idLmsStore) {
      const params = {
        'appointment': appointmentId,
        'field': 'store',
        'value': selectedStore.idLmsStore
      };
      AppointmentService.updateAppointment(params)
        .then(() => {
          showToastMessages(`Store for Appt ID: ${appointmentId} changed successfully`);
        })
        .catch(err => {
          const { detail } = err;
          showToastMessages(detail, false);
        }).finally(()=> {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
    }
  };

  return (
    <div ref={carDetailRef} className={styles.leadCarDetailWrapper}>
      <H3 text={"Car Details"} />
      <div className={styles.detailsLeftWrapper}>
        <div>
          <LabelWithText
            label={LEAD_CAR_DETAIL.INSPECTION_REPORT_LINK}
            text={`${showReport() ? '' : 'N/A'}`}
          />
          {showReport() && <Link to="#" onClick={viewReport}>View report</Link>}
          <br />
          {showReport() && <Link to="#" onClick={sendInspectionReport}>Send report to customer</Link>}
        </div>
        <div>
          <LabelWithText
            label={LEAD_CAR_DETAIL.CAR_JOCKEY}
            text={carJockey}
          />
        </div>
        {isCJMRole ? 
          <div className={styles.store}>
            <label>{LEAD_CAR_DETAIL.STORE}</label>
            <StoreSearch 
              setSelectedStore={setSelectedStore} 
              store={store} 
              setIsStoreChange={setIsStoreChange}
              isStoreChange={isStoreChange}
            />
          </div> : 
          <div>
            <LabelWithText
              label={LEAD_CAR_DETAIL.STORE}
              text={store}
            />
          </div>
        }
      </div>
      <div className={styles.detailsLeftWrapper}>
        <div>
          <LabelWithText
            label={LEAD_CAR_DETAIL.DEPRECIATION_REPORT_LINK}
            text={`${depreciationData ? '' : 'N/A'}`}
          />
          {/* {depreciationData && <Link to="#" onClick={openPopup}>View Depreciation Report</Link>} */}
          {/* {depreciationData && <Link to={`../dep-report/${appointmentId}?carId=${carId}&report=${stateName}`} target="_blank">View Depreciation Report</Link>} */}
          {depreciationData && <Link to={`../customer-journey/${appointmentId}?showreport= dep&carId=${carId}&report=${stateName}&isDepReportAvailable=true&pagesource=negotiation`} target="_blank">View Depreciation Report</Link>}
        </div>
        <div>
          <LabelWithText
            label={LEAD_CAR_DETAIL.PA_NAME}
            text={paName}
          />
          {!!paPhone && <span> {`(${paPhone})`}</span>}
        </div>
        <div>
          <LabelWithText
            label={LEAD_CAR_DETAIL.LATEST_PR_SUBMITTED_BY}
            text={praName}
          />
        </div>

      </div>
      <div className={styles.detailsLeftWrapper}>

        <div>
          <LabelWithText
            label={LEAD_CAR_DETAIL.INS_DATE}
            text={insDate}
          />
        </div>
        <div>
			<LabelWithText
				label={LEAD_CAR_DETAIL.DIFF_REPORT_LINK}
				text={''}
			/>
			<span className={styles.diffReportLink} onClick={()=> setIsDiffReportOpen(true)}>Price Diff Report</span>
		</div>
      </div>
      {
        depreciationData && <Popup isOpen={open} close={closePopup} closeOnOutside={false}>
          <DepreciationReportPopup showTabs={true} />
        </Popup>
      }
      {isStoreChange && <Popup isOpen={isStoreChange} close={closeStoreChangePopup} closeOnOutside={false}>
        <StoreChangeConfirmationPopup 
          onCancelClick={closeStoreChangePopup} 
          onSubmit={onStoreChangeSubmit} 
          appointmentId={appointmentId}
          storeName={selectedStore?.name}
        />
      </Popup>
      }
	  {isDiffReportOpen && <Popup isOpen={isDiffReportOpen} close={()=> setIsDiffReportOpen(false) } closeOnOutside={false}>
		<DiffReportPopup 
			rowData={data}
			isOpenFromLD={true}
		/>
		</Popup>}
    </div>
  );
};

export default LeadCarDetail;
