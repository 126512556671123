import { createTypes } from "reduxsauce";

export default createTypes(
  `
    FETCH_CANCELLATION
    FETCH_CANCELLATION_SUCCESS
    FETCH_CANCELLATION_FAILURE
    DENY_CHECK_IN_SUCCESS
    DENY_CHECK_IN_FAILURE
    RESET_DENY_CHECK_IN_KEY
`,
  {
    prefix: "checkindenypopup/"
  }
);
