import React, { useEffect, useState } from "react";
import NegotiationList from "../../components/negotiation-list";
import { PAGE_HEADING, RESET_REDUCER_STATE_KEYS, APPOINTMENT_TYPES_STRING, NUMBERS, ROLE_KEYS } from "../../utils/app-constants";
import H2 from "../../components/h2";
import styles from "./negotiation.module.css";
import Search from "../../components/search";
import Filters from "../../components/filters";
import StickyComponent from "../../components/shared/sticky-component";
import CalendarRange from "../../components/calendar-range";
import { isC2CUser, isPllUser, isBikeSource, isLoginType, getAppointmentTypeFromPathName } from "../../utils/helper";
import LostLeadSearch from "../../components/search-lost-leads";
import NegoLeadFilters from "../../components/nego-lead-filters";
import OnOffButton from "../../components/shared/on-off-button";
import NegoTabs from "../../components/nego-tabs/component";

const Negotiation = ({
  resetAppointmentByKeyConnect,
  history,
  sidebar, 
  negotiations,
  location,
  resetApiNoAccessConnect
}) => {
  const { pathname } = location;
  const isC2C = isC2CUser();
  const isPll = isPllUser();
  const isBike = isBikeSource();
  const { pllFilter: { label, filterQuery } } = sidebar;
  let showTopFilter = true;
  if (filterQuery === "accepted" || filterQuery === "rejected") {
    showTopFilter = false;
  }
  const { expiryCount: { pllFilterCount } } = negotiations;
  const appointmentType = getAppointmentTypeFromPathName(pathname);
  const [pageTitle, setPageTitle] = useState(PAGE_HEADING.NEGOTIATION);

  useEffect(() => {
    resetApiNoAccessConnect();
    if (isLoginType(ROLE_KEYS.CJM)) {
      history.push("/cj-execution");
    } else if (isLoginType(ROLE_KEYS.HOSTESS)) {
      history.push("/check-in");
    }
  }, []);
  useEffect(()=>{
    switch (appointmentType) {
      case APPOINTMENT_TYPES_STRING.DIY_NEGOTIATION:
        setPageTitle(PAGE_HEADING.DIY_NEGOTIATION);
        break;
      case APPOINTMENT_TYPES_STRING.CENTRAL_NEGOTIATION:
        setPageTitle(PAGE_HEADING.CENTRAL_NEGOTIATION);
        break;
      case APPOINTMENT_TYPES_STRING.CENTRAL_DIY_NEGOTIATION:
        setPageTitle(PAGE_HEADING.CENTRAL_DIY_NEGOTIATION);
        break;
      default:
        setPageTitle(PAGE_HEADING.NEGOTIATION);
        break;
    }
    resetAppointmentByKeyConnect(RESET_REDUCER_STATE_KEYS.APPOINTMENT_STATE, appointmentType);
  },[appointmentType]);

  return (
    <>
      <StickyComponent topOffset={NUMBERS.MINUS_SIXTY} classes="freezeHeader" >
        <div className={styles.headingSearchWrapper}>
          <div className={styles.title}>
            <H2 text={pageTitle} />
            {isPll && <p>({pllFilterCount}) {label}</p>}
          </div>
          <div className={styles.searchSection}>
            {
              isC2C && <CalendarRange />
            }
            {
              showTopFilter && <Filters appointmentType={appointmentType}/>
            }
            <Search appointmentType={appointmentType}/>
            {
              !isC2C && !isPll && appointmentType === APPOINTMENT_TYPES_STRING.NEGOTIATION && <LostLeadSearch history={history} />
            }
          </div>
          {isBike && <div className={styles.filtertoggleWrapper}>
            <OnOffButton />
            <NegoLeadFilters />
          </div>
          }
          {[APPOINTMENT_TYPES_STRING.DIY_NEGOTIATION, APPOINTMENT_TYPES_STRING.CENTRAL_NEGOTIATION].includes(appointmentType) && <NegoTabs/>}
        </div>
      </StickyComponent>
      <NegotiationList 
        history={history} 
        location={location} 
        appointmentType={appointmentType} 
        isC2C={isC2C}
        isPll={isPll}
      />
    </>
  );
};

export default Negotiation;
