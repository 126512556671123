/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import styles from "./change-log.module.scss";
import ChangeLogImage from "../../components/change-log-image";
import ChangeLogDetails from "../../components/change-log-details";
import { AppointmentService } from "../../service";
// import { getChangeLogMoc, getAppointmentChangeLog } from "../../utils/mocApi";

const ChangeLog = ({ appointmentId }) => {
    const [changeLogData, setChangeLogData] = useState();
    useEffect(() => {
        if (appointmentId) {
            AppointmentService.getChangeLogs(appointmentId)
                .then(resp => {
                    // resp = getAppointmentChangeLog();
                    // debugger;
                    const { data: { detail } } = resp;
                    setChangeLogData(detail);
                })
                .catch(err => {
                    // let resp = getChangeLogMoc();
                    // const { data: { detail } } = resp;
                    // setChangeLogData(detail);
                    // console.log('error', err);
                });
        }
    },
        [appointmentId]);
    return (
      <>
        {changeLogData &&
                changeLogData.map((item, index) => {
                    return (
                      <div className={styles.callLogCard}>
                        <ChangeLogImage imageType={item.org_column} />
                        <ChangeLogDetails data={item} />
                      </div>
                    );
                })
            }
      </>
    );
};

export default ChangeLog;
