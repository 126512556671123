import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  isLoading: true,
  data: null,
  error: null
};

export const fetchLeadDetailsSuccess = (state = INITIAL_STATE, { data }) => {
  return { ...state, isLoading: false, data, error: null };
};

export const fetchLeadDetailsFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, isLoading: false, data: null, error };
};

export const resetLeadDetails = () => {
  return INITIAL_STATE;
};

export const HANDLERS = {
  [Types.FETCH_LEAD_DETAILS_SUCCESS]: fetchLeadDetailsSuccess,
  [Types.FETCH_LEAD_DETAILS_FAILURE]: fetchLeadDetailsFailure,
  [Types.RESET_LEAD_DETAILS]: resetLeadDetails
};

export default createReducer(INITIAL_STATE, HANDLERS);
