import React from "react";
import { NUMBERS } from "../../utils/app-constants";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, LabelList } from "recharts";
import "./exponential-graph.scss";

const ExponentialGraph = ({ graphData }) => {
  const { data } = graphData;
  const createData = () => {
    const { A, B, C } = data;
    let count = NUMBERS.SIXTEEN;
    let x = 0;
    let graphData = [];
    let constantPrice = false;
    let constantPriceValue = "";
    while (count--) {
      let price = Math.ceil(100 * (A * Math.exp(-B * x) + C));
      if (price <= 4 && !constantPrice) {
        constantPrice = true;
        constantPriceValue = 4;
      }
      graphData.push({
        "Years": x,
        "On Road Price": !constantPrice ? price : constantPriceValue
      });

      ++x;
    }
    return graphData;
  };

  const CustomizedAxisTick = (props) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={-10} dy={16} fill="#666" text-anchor="end" font-size="0.9em">{payload.value}%</text>
      </g>
    );
  };

  const renderCustomizedLabel = ({ x, y, value }) => {
    const { vg_range_min, vg_range_max, age } = data;
    const range = `₹${vg_range_min} - ₹${vg_range_max}`;

    const getTextAnchor = () => {
      if (age >= NUMBERS.FOURTEEN) {
        return "end";
      } else {
        return "middle";
      }
    };

    if (value === age) {
      return (
        <g className="rangeWrapper">
          <defs>
            <filter x="0" y="0" width="1" height="1" id="solid">
              <feFlood flood-color="#0037ff" />
              <feComposite in="SourceGraphic" operator="over" />
            </filter>
          </defs>
          <text className="text" filter="url(#solid)" x={x} y={y - 10} fill="#fff" fontSize="15px" textAnchor={getTextAnchor()} dominantBaseline="middle">
            {range}&nbsp;
          </text>
        </g>
      );
    }
  };

  return (
    <div className="areaGraph">
      <AreaChart width={600} height={280} data={createData()}
        margin={{ top: 20, right: 30, left: 20, bottom: 0 }}>
        <CartesianGrid x="Years" vertical={true} />
        <XAxis />
        <YAxis interval={0} tick={<CustomizedAxisTick />} />
        <Area dot={true} type='monotone' dataKey='On Road Price' strokeWidth={1} stroke='#1b92dc' fill="rgba(8, 89, 146, 0.6)" >
          <LabelList dataKey="Years" position="top" content={renderCustomizedLabel} />
        </Area>
      </AreaChart>
      <p className="roadPrice">On Road Price</p>
      <div className="years">No of Years</div>
    </div>
  );

};

export default ExponentialGraph;