import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import styles from "./call-reports.module.css";
import Loader from "../../components/shared/loader";
import { getFromLocalStorage, getUid } from "../../utils/helper";
import { trackLMSEvents } from "../../utils/helpers/tracking-events";
import { EMBED_URL } from "./constant";
import UnauthorizedAccess from "../../components/unauthorized-access/component";
import { LOCAL_STORAGE_KEYS } from "../../utils/app-constants";

const DomoDashboard = ({
  getDomoTokenConnect,
  reportType,
  isAccessAllowed=true,
  userType,
  raEmail
}) => {
  const [isIframeLoading, setIsIframeLoading] = useState(true);
  const [embedData, setEmbedData] = useState({});
  const loginData = JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA) || "null");

  const embedUrl = EMBED_URL[userType];

  const getSrcDoc = () => {
    if (!embedData.embedId) {
      return "";
    } else {
      return `<html>
                        <body>
                            <form id=form action=${embedUrl}/${embedData.embedId} method=post>
                            <input type=hidden name=embedToken value=${embedData.embedToken}>
                            </form>
                            <script>
                            document.getElementById("form").submit();
                            </script>
                        </body>
                    </html>`;
    }
  };

  useEffect(() => {
    trackLMSEvents("domo_missed_call_dashboard_viewed", {
      eventAction: loginData.email,
      lsRAID: getUid(),
      Timestamp: moment().format()
    });

    getDomoTokenConnect({
      raEmail,
      userType,
      reportType
    }).then((response) => {
      setEmbedData(response);
    });
  }, []);

  const onIframeLoad = () => {
    window.setTimeout(() => {
      setIsIframeLoading(false);
    }, 2000);
  };

  return (
    <Fragment>
      {
        !isAccessAllowed && <UnauthorizedAccess/>
      }
      {
        isAccessAllowed && (
          <div className={styles.iframeWrapper}>
            {
              isIframeLoading && <Loader />
            }
            {
              embedData.embedId && <iframe title="missed call dashboard" onLoad={onIframeLoad} frameBorder="0" marginWidth="0" marginHeight="0" width="100%" height="100%" srcDoc={getSrcDoc()}></iframe>
            }
          </div>
        )
      }
    </Fragment>
  );
};

export default DomoDashboard;
