/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import SelectComponent from "../select";
import { APPOINTMENT_TYPES_NUMBER, LOCAL_STORAGE_KEYS } from "../../../utils/app-constants";
import styles from "./table-header-with-control.module.css";
import { getFromLocalStorage } from "../../../utils/helper";
import { AppointmentService } from "../../../service";
import { useDispatch, useSelector } from "react-redux";
import { updateHeaderRowFilterQuery } from "./actions";
// import { getTimeSlotMoq } from "../../../utils/mocApi";
const TableHeaderWithControl = ({ headerKey, headerValue, fetchFollowUpListConnect, followUp, ddValues, appointmentState}) => {
  const dispatch = useDispatch();
  const cjData = useSelector(state => state.cjAllocationStatus);
  const headerRowFilterData = useSelector(state => state.headerRowFilter);
  const { details } = followUp || {};
  const [dispositionList, setDispositionList] = useState([]);
  const [placeHolderText, setPlaceHolderText] = useState("");
  const { headerRowFilterQuery } = headerRowFilterData || {};
    
  useEffect(() => {
    if (headerKey === "followUpMessage") {
      fetchFollowUpListConnect(APPOINTMENT_TYPES_NUMBER.SCHEDULED);
      setPlaceHolderText("Call Result");
    }
    if (headerKey === "timeSlot") {
      AppointmentService.getTimeSlot()
        .then(resp => {
          const { data: { detail: { slots } } } = resp;
          setPlaceHolderText("TimeSlot");
          const dispositions = slots.map(item => { return { value: item.id, label: item.value }; });
          let selectObject = { value: "-1", label: "ALL TimeSlot" };
          dispositions.unshift(selectObject);
          setDispositionList(dispositions);
        })
        .catch(error => {
          console.log(error);
        });
    }
    if (headerKey === "timeSlot1") {
      AppointmentService.getTimeSlot()
        .then(resp => {
          const { data: { detail: { slots } } } = resp;
          setPlaceHolderText("TimeSlot");
          const dispositions = slots.map(item => { return { value: item, label: item }; });
          let selectObject = { value: "-1", label: "ALL TimeSlot" };
          dispositions.unshift(selectObject);
          setDispositionList(dispositions);
        })
        .catch(error => {
          console.log(error);
        });
    }
    if (headerKey === "homeInspectionCj") {
      setPlaceHolderText("Cj");
      const {cjAllocationStausList} = cjData || {};
      if (!!cjAllocationStausList) {
        let evauatorData = cjAllocationStausList.map(item => { return { value: item.idLmsUsers, label: item.email }; });
        let selectObject = { value: "-1", label: "Select CJ" };
        evauatorData.unshift(selectObject);
        setDispositionList(evauatorData);
      }
    }

    if (headerKey === "retailAssociate") {
      setPlaceHolderText("Ra");
      let raDataList = getFromLocalStorage(LOCAL_STORAGE_KEYS.RA_LIST);
      let raData = !!raDataList ? JSON.parse(raDataList) : [];
      raData = raData.map(item => { return { value: item.idLmsUsers, label: item.email }; });
      let selectObject = { value: "-1", label: "Select RA" };
      raData.unshift(selectObject);
      setDispositionList(raData);
    }
        
  }, [headerKey, appointmentState, headerKey]);
    
  useEffect(() => {
    if (headerKey === "followUpMessage") {
      if (details.length > 0) {
        const dispositions = details.map(item => { return { value: item.key, label: item.name }; });
        let selectObject = { value: "-1", label: "ALL CALL RESULTS" };
        dispositions.unshift(selectObject);
        setDispositionList(dispositions);
      }
    }
  }, [details]);

  useEffect(() => {
    if (headerKey === "deliveryPaymentLoans" && !!ddValues && ddValues.length > 0){
      setPlaceHolderText("Delivery Payment and Loans");
      const dispositions = ddValues.map(item => { return { value: item.key, label: item.value }; });
      let selectObject = { value: "-1", label: "Delivery Payment and Loans" };
      dispositions.unshift(selectObject);
      setDispositionList(dispositions);
    }
    else if (headerKey === "paymentStatus" && !!ddValues && ddValues.length > 0){
      setPlaceHolderText(headerValue);
      const dispositions = ddValues.map(item => { return { value: item.key, label: item.value }; });
      let selectObject = { value: "-1", label: headerValue};
      dispositions.unshift(selectObject);
      setDispositionList(dispositions);
    }
    else if (headerKey === "appointmentStatus" && !!ddValues && ddValues.length > 0){
      setPlaceHolderText(headerValue);
      const dispositions = ddValues.map(item => { return { value: item.key, label: item.value }; });
      let selectObject = { value: "-1", label: headerValue};
      dispositions.unshift(selectObject);
      setDispositionList(dispositions);
    }
    else if (headerKey === "slaStatus" && !!ddValues && ddValues.length > 0){
      setPlaceHolderText(headerValue);
      const dispositions = ddValues.map(item => { return { value: item.key, label: item.value }; });
      let selectObject = { value: "-1", label: headerValue};
      dispositions.unshift(selectObject);
      setDispositionList(dispositions);
    } else if (headerKey === "leadAssign" && !!ddValues && ddValues.length > 0)  {
      setPlaceHolderText("LEAD STATUS");
      const dispositions = ddValues.map(item => { return { value: item.key, label: item.value }; });
      setDispositionList(dispositions);
    } else if (headerKey === "leadType" && !!ddValues && ddValues.length > 0)  {
      setPlaceHolderText("LEAD TYPE");
      const dispositions = ddValues.map(item => { return { value: item.key, label: item.value }; });
      setDispositionList(dispositions);
    }
  }, [ddValues]);

  const queryFilterString = () => {
    let queryString = headerRowFilterQuery;
    let queryParam = headerKey;
    if (headerKey === "followUpMessage") {
      queryParam = "callRemarks";
    } else if (headerKey === "leadType") {
      queryParam = "sellOnline";
    } else if (headerKey === "leadAssign") {
      queryParam = "isAssigned";
    }
    if (!!headerRowFilterQuery && headerRowFilterQuery.includes(queryParam)){
      const params = headerRowFilterQuery.split("&");
      if (params.length > 0){
        const remainingFilters = params.filter(item => !item.includes(queryParam));
        const paramString = remainingFilters.join('&');
        queryString = paramString;
      }
    }
    return queryString;
  };

  const onDispositionSelect = (value) => {
    let query = "";
    if (!!value && value !== "-1" ) {
      if (headerKey === "followUpMessage") {
        query = `&callRemarks=${value}`;
      }
      else if (headerKey === "homeInspectionCj") {
        query = `&homeInspectionCj=${value}`;
      }
      else if (headerKey === "retailAssociate") {
        query = `&retailAssociate=${value}`;
      }
      //retailAssociate
      else if (headerKey === "timeSlot") {
        query = `&timeSlot=${value}`;
      }
      //deliveryPaymentLoans
      else if (headerKey === "deliveryPaymentLoans") {
        query = `&deliveryPaymentLoans=${value}`;
      }
      //payment status
      else if (headerKey === "paymentStatus") {
        query = `&paymentStatus=${value}`;
      }
      //payment status
      else if (headerKey === "appointmentStatus") {
        query = `&appointmentStatus=${value}`;
      }
      //slaStatus
      else if (headerKey === "slaStatus") {
        query = `&slaStatus=${value}`;
      }
      //leadType
      else if (headerKey === "leadType") {
        query = `&sellOnline=${value}`;
      }
      //leadAssign
      else if (headerKey === "leadAssign") {
        query = `&isAssigned=${value}`;
      }
    }
    if (query !== headerRowFilterQuery) {
      const customQuery = queryFilterString();
      query = !!customQuery? customQuery + query : query;
      dispatch(updateHeaderRowFilterQuery(query));
    }
  };
  return (
    <th key={headerKey}>
      <div className={styles.callResultWrapper}>
        <SelectComponent placeholder={placeHolderText} name="dispositionStatus"
          onChange={onDispositionSelect}
          optionsList={dispositionList} />
      </div>
    </th>
  );
};

export default TableHeaderWithControl;
