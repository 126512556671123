import Types from "./types";
import { AppointmentService } from "../../service";
import {updateApiNoAccess} from "../hoc-access-controller/actions";
const fetchFollowUpHistory = (appointmentId) => dispatch => {
  dispatch(fetchHistory());
  return AppointmentService.getFollowUpHistory(appointmentId)
    .then(response => {
      const { data: { detail } } = response;
      dispatch(fetchFollowUpHistorySuccess(detail));
    })
    .catch(error => {
      dispatch(updateApiNoAccess(error));
      dispatch(fetchFollowUpHistoryFailure(error));
    });
};

const fetchHistory = () => ({
  type: Types.FETCH_FOLLOW_UP_HISTORY
});

const fetchFollowUpHistorySuccess = (data) => ({
  type: Types.FETCH_FOLLOW_UP_HISTORY_SUCCESS,
  data
});

const fetchFollowUpHistoryFailure = (error) => ({
  type: Types.FETCH_FOLLOW_UP_HISTORY_FAILURE,
  error
});

const resetFollowUpHistory = () => ({
  type: Types.RESET_FOLLOW_UP_HISTORY
}) ;
export {
  fetchFollowUpHistory,
  resetFollowUpHistory
};
