export const ZENDESK_DISPLAY_COLUMNS = [
	{
		"key": "appointmentId",
		"value": "APP. ID",
		"isVisible": "1"
	},
	{
		"key": "ticketId",
		"value": "Ticket ID",
		"isVisible": "1"
	},
	// {
	// 	"key": "escalationNum",
	// 	"value": "Escalation No.",
	// 	"isVisible": "1"
	// },
	{
		"key": "category",
		"value": "Category",
		"isVisible": "1"
	},
	{
		"key": "subcategory",
		"value": "Sub-Category",
		"isVisible": "1"
	},
	{
		"key": "createdDate",
		"value": "Escalation Date",
		"isVisible": "1"
	},
];
