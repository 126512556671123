import React, { useState, useEffect } from "react";
import styles from "./individuals-search.module.scss";
import { BUTTONS_CTA_TEXT, LOCAL_STORAGE_KEYS, LEAD_TYPE } from "../../utils/app-constants";
import InputText from "../shared/input-text/component";
import Button from "../shared/button/component";
import { NUMBERS } from "../../utils/app-constants";
import TableBody from "../shared/table-body/component";
import { saveToLocalStorage } from "../../utils/helper";
import { AppointmentService } from "../../service";

const IndividualsSearch = ({
  isPllUser,
  onSelectionChange,
  showLeadsMenu
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!showLeadsMenu) {
      setSearchQuery("");
      setData();
    }
  }, [showLeadsMenu]);

  const onChangeHandler = (e) => {
    const { target: { value } } = e;
    setSearchQuery(value.trim());
  };

  const onSearchClick = () => {
    if (searchQuery.trim() && searchQuery.trim().length >= NUMBERS.THREE) {
      setIsLoading(true);
      AppointmentService.getStoreAssociateList(searchQuery)
        .then(response => {
          const { data: { detail } } = response;
          setData(detail);
          setIsLoading(false);
        }).catch(error => {
          console.log('Error in fetching store associates list', error);
          setIsLoading(false);
        });
    }
  };

  const onSearchKeyPress = (e) => {
    const { charCode } = e;
    if (charCode === NUMBERS.THIRTEEN) {
      onSearchClick();
    }
  };

  const getStoreCityName = (storeName, cityName) => {
    let _cityName = !!cityName ? cityName.split(',').join(', ') : "";
    if (!!storeName) {
      return storeName + "(" + _cityName + ")";
    }
    else {
      return _cityName;
    }
  };
  const selectIndividual = (individual) => {
    const { idLmsUsers, firstName, lastName } = individual;
    saveToLocalStorage(LOCAL_STORAGE_KEYS.LEADS, LEAD_TYPE.INDIVIDUAL);
    saveToLocalStorage(LOCAL_STORAGE_KEYS.INDIVIDUAL_ID, idLmsUsers);
    saveToLocalStorage(LOCAL_STORAGE_KEYS.INDIVIDUAL_NAME, `${firstName} ${lastName}`);
    onSelectionChange();
  };

  const renderIndividualsGrid = () => (
    <table cellPadding="0" cellSpacing="0" border="0" width="100%">
      {data !== undefined && data.length === 0 &&
      <label>NO RESULTS FOUND</label>
      }
      {data !== undefined && data.length > 0 &&
      <thead>
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th className={styles.cityList}>City</th>
          <th>Action</th>
        </tr>
      </thead>
      }
      <TableBody>
        {
          data !== undefined && data.length > 0 &&
                    data.map(item =>
                      <tr>
                        <td>{item.firstName}</td>
                        <td>{item.lastName}</td>
                        <td className={styles.cityList}>{getStoreCityName(item.name, item.storeCity)}</td>
                        <td>
                          <Button
                            key={item.idLmsUsers}
                            classNames="smallCta"
                            ctaText={BUTTONS_CTA_TEXT.SEE_LEADS}
                            onClick={() => selectIndividual(item)}
                            // onClick={() => selectIndividual(item.idLmsUsers)}
                          />
                        </td>
                      </tr>
                    )
        }
      </TableBody>
    </table>
  );
  return (
    <div>
      <div className={styles.searchRa}>
        <div className={styles.inputBox}>
          <InputText
            name="search"
            placeholder="Search"
            onChange={onChangeHandler}
            onKeyPress={onSearchKeyPress}
            value={searchQuery}
          />
        </div>
        <Button
          classNames="blueButton"
          ctaText={BUTTONS_CTA_TEXT.SEARCH}
          onClick={onSearchClick}
          isLoading={isLoading}
          disabled={isLoading}
        />
      </div>
      <label className={styles.searchAlert}>* minimum three character required for search</label>
      <div className={styles.searchLeads}>
        {renderIndividualsGrid()}
      </div>
    </div>
  );
};

export default IndividualsSearch;
