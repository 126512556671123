import { combineReducers } from 'redux';
import leadProcurementReducer from "../pages/lead-procurement/reducers";
import raLeadsReducer from "../components/ra-leads/reducers";
import leadReducer from '../pages/lead-detail/reducers';
import leadSortFilterReducer from '../components/lead-sort-filter/reducers';
export default combineReducers({
  lead: leadReducer,
  leadProcurementData: leadProcurementReducer,
  raLeads: raLeadsReducer,
  sortFilter: leadSortFilterReducer,
});