import React, { useState } from "react";
import Button from "../shared/button/component";
import { ACTIONS_BUTTON, LEAD_TYPE, ROLE_KEYS } from "../../utils/app-constants";
import { AppointmentService } from "../../service";
import { showToastMessages, dateToFromNow, getLoginData } from "../../utils/helper";
import styles from "./virtual-no.module.scss";
const VirtualNo = ({ rowDetails, }) => {
  let { appointment, exophone, generateFlag, lastCallTime, lastCallStatus, storeType } = rowDetails;
  const [virtualNo, setVirtualNo] = useState(exophone);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(!generateFlag);
  const {originalRole='', isHierarchy} = getLoginData();
  const isGenrateVNAccess = isHierarchy && originalRole === ROLE_KEYS.CJM && storeType === LEAD_TYPE.B2B ? true : storeType !== LEAD_TYPE.B2B;
  const onClickHandler = () => {
    setIsLoading(true);
    setIsSubmitted(true);
    AppointmentService.getVirtualNo(appointment).then(response => {
      const { data: { detail: { exophone } } } = response;
      setVirtualNo(exophone);
    }).catch(error => {
      const { detail: { message } } = error;
      showToastMessages(error?.detail || message, false);
    }).finally(()=>{
      setIsLoading(false);
      setIsSubmitted(!generateFlag);
    });
  };
  const generateNoButton = () => {
    return isGenrateVNAccess && <Button classNames="smallCta" isLoading={isLoading} disabled={isSubmitted} onClick={() => { onClickHandler(); }} ctaText={ACTIONS_BUTTON.GENERATE_NO} />;
  };

  const getCallstatusCss = (callStatus) => {
    let css = "";
    if (callStatus === "Call not connected") {
      css = "callStatusRed";
    }
    else if (callStatus === "Call connected") {
      css = "callStatusGreen";
    }
    else if (callStatus === "Not called") {
      css = "callStatusBlue";
    }
    return css;
  };
  const getViewTime = (callTime) => {
    let viewLastCallTime = "";
    try {
      if (!!callTime) {
        viewLastCallTime = dateToFromNow(callTime);
      }
      return viewLastCallTime;
    }
    catch (e) {
      return "";
    }
  };
  return (
    <>
      {virtualNo ? <span className={styles.vrnumber}>{virtualNo}</span>
                : generateNoButton()
      }
      {virtualNo && <div className={styles.virtualWrapper}>
        <p className={`${styles[getCallstatusCss(lastCallStatus)]}`}>{getViewTime(lastCallTime)}<span>{lastCallStatus}</span></p>
      </div>
      }
    </>
  );
};

export default VirtualNo;