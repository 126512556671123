import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CjAllocationStatusPopup from "./component";
import { fetchCjAllocationStatus } from "../cj-allocation-status/actions";

const mapStateToProps = ({ cjAllocationStatus, homeInspectionFilters }) => ({ cjAllocationStatus, homeInspectionFilters });

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchCjAllocationStatusConnect: fetchCjAllocationStatus
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CjAllocationStatusPopup);
