import React, { useState, useEffect } from "react";
import styles from "./allocate-cj-schedule-popup.module.scss";
import H2 from "../../components/h2";
import RadioButton from "../../components/shared/radio-button";
import Button from "../../components/shared/button";
import { AppointmentService } from "../../service";
import { showToastMessages, getUniqueStoreForCities, isBikeSource, getFromLocalStorage, isCJManager } from "../../utils/helper";
import { NUMBERS, LOCAL_STORAGE_KEYS, ROLE_KEYS } from "../../utils/app-constants";
// import InputText from "../shared/input-text/component";
import Searchicon from "../images/search.svg";
import SelectComponent from "../shared/select/component";
import Calander from "../../components/images/calander.svg";
import TimeSlot from "../../components/images/timeSlot.svg";
import { useSelector } from "react-redux";

const AllocateCjSchedulePopup = ({ 
  onClose, 
  rowDetails, 
  data, 
  updateDataList, 
  homeInspectionFilters, 
  fetchCjAllocationStatusConnect, 
  negotiations, 
  resetUpdateDataList, 
  IsCJAllocate = false, 
  isSaleOnline = false,
  orgRole
}) => {
  const isBike = isBikeSource();
  const isCJM = isCJManager();
  const isNegoCarDetail = !isBike && !isSaleOnline && !IsCJAllocate && !rowDetails;
  // const {assignReasons} = useSelector(state => state.appointments);
  const { appointment, customerAddress, appointmentId, exophone,storeId, homeInspectionCj, retail_associate_email, 
    homeInsDate: {date, time} = {}, onlineApptDate : {date : custAptDate, time: custAptTime} = {}} = 
        IsCJAllocate && !!rowDetails ? rowDetails : data || {};
  const [dataList, setDataList] = useState([]);
  const [totaldataList, setTotalDataList] = useState([]);
  const [selectedCj, setSelectedCj] = useState("");
  const [selectedRA, setSelectedRA] = useState("");
  const [selectedRAEmail, setSelectedRAEmail] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [curAppointmentId, setCurAppointmentId] = useState(appointment || "");
  const [curExophone, setCurExophone] = useState(exophone || "");
  const { hisfilterQuery } = homeInspectionFilters || {};
  // const [showInspectedColum, setShowInspectedColumn] = useState((hisfilterQuery === "&leadDays=1") ? true : false);
  const pageTitleType = !IsCJAllocate ? "RA" : "CJ";
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(!!time || !!custAptTime ? {label: custAptTime || time, value: custAptTime || time} : null);
  const [selectedDateSlot, setSelectedDateSlot] = useState(!!date || !!custAptDate ? {label: custAptDate || date, value: custAptDate || date} : null);
  const [dateSlots, setDateSlots] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  // const [cjReasons, setCjReasons] = useState([]);
  const [cjSlots, setCjSlots] = useState();
  const [key, setKey] = useState(appointment);
  // const [cjReallocateReason, setCjReallocateReason] = useState();
  const [isSubmit, setIsSubmit] = useState(false);

  // useEffect(()=>{
  //     if (isCJM && !!assignReasons && assignReasons.length > 0) {
  //         const reasons = assignReasons.map((item) => { return {label: item, value: item};});
  //         setCjReasons(reasons);
  //     }
  // },[assignReasons]);

  useEffect(()=> {
    if (!IsCJAllocate && isBike){
      const {retail_associates} = JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.BIKE_RA_LIST)) || {};
      const currentRA = retail_associates.find(item => item.email === retail_associate_email);
      retail_associates.unshift(retail_associates.splice(retail_associates.findIndex(item => item.email === retail_associate_email), 1)[0]);
      !!currentRA && setSelectedRA(currentRA.idLmsUsers);
      setDataList(retail_associates);
      setTotalDataList(retail_associates);
    }
        
    if (!!isSaleOnline){
      AppointmentService.getCarCJSlots().then(resp => {
        const {data: {detail}} = resp;
        setCjSlots(detail);
        const dates = detail.map((item) => ({ label: item.date, value: item.date}));
        setDateSlots(dates);
        if (!!date || !!custAptDate) {
          let selectedDate = detail.find((item) => item.date === date || custAptDate);
          if (!selectedDate) {
            selectedDate = detail[0];
          }
          const timeSlots =  selectedDate.slots.map((time) => ({ label: time, value: time}));
          setTimeSlots(timeSlots);
        }
      }).catch(err => {
        console.log("car-cj-slot api error:", err);
      });
    }
    if (isNegoCarDetail && !!storeId && orgRole !== ROLE_KEYS.PLL_ZM_CENTER) {
      AppointmentService.getStoreRAList(storeId).then((resp)=>{
        const { data: { detail } } = resp;
        if (!!detail && detail.length > 0) {
          const currentRA = detail.find(item => item.email === retail_associate_email);
          detail.unshift(detail.splice(detail.findIndex(item => item.email === retail_associate_email), 1)[0]);
          !!currentRA && setSelectedCj(currentRA.idLmsUsers) && setSelectedCj(currentRA.idLmsUsers);;
          setDataList(detail);
          setTotalDataList(detail);
        }
      }).catch(err => {
        console.log("getRaList api error:", err); 
      });
    }
    if (orgRole === ROLE_KEYS.PLL_ZM_CENTER) {
      AppointmentService.getPLLRAList(storeId).then((resp)=>{
        const { detail }  = resp;
        if (!!detail && detail.length > 0) {
          const currentRA = detail.find(item => item.email === retail_associate_email);
          detail.unshift(detail.splice(detail.findIndex(item => item.email === retail_associate_email), 1)[0]);
          !!currentRA && setSelectedCj(currentRA.idLmsUsers  || currentRA.id_lms_users) && setSelectedCj(currentRA.idLmsUsers  || currentRA.id_lms_users);;
          setDataList(detail);
          setTotalDataList(detail);
        }
      }).catch(err => {
        console.log("getRaList api error:", err); 
      });
    }
  },[]);

  useEffect(()=>{
    if (!!negotiations) {
      const { currentAppointmentIndex} = negotiations;
      if (!!currentAppointmentIndex) {
        const currentAppointmentData = negotiations.data[currentAppointmentIndex];
        const {exophone, appointmentId} = currentAppointmentData;
        setCurAppointmentId(appointmentId);
        setKey(appointmentId);
        setCurExophone(exophone);
      }
    }
  }, [negotiations]);

  useEffect(() => {
    setSelectedCj(!!homeInspectionCj ? homeInspectionCj : "");
    IsCJAllocate && AppointmentService.getEvaluatorLeadCountBasedOnAppointment(curAppointmentId || appointment)
      .then(response => {
        const { data: { detail } } = response;
        setDataList(detail);
        setTotalDataList(detail);
      }).catch(() => {
        showToastMessages("Error in fetching cj list");
      });
  }, [homeInspectionCj]);
    
  const onClickAssignEvaluator = (isAllocate) => {
    if (!selectedCj) {
      showToastMessages("Please Select cj", false);
      return;
    } 
    // else if (isCJM && !cjReallocateReason) {
    //     showToastMessages("Please Select Re-allocate reason", false);
    //     return;
    // } 
    else if (!isCJM && !curExophone) {
      const warningMessage = isBike ? "primary" : "virtual";
      showToastMessages(`Please update ${warningMessage} no`, false);
      return;
    } else if (!isCJM && !customerAddress) {
      showToastMessages(`Please update address`, false);
      return;
    }
    let action = isAllocate ? "ALLOCATE" : "DEALLOCATE";
    const params = {
      'appointment': IsCJAllocate ? appointment  : appointmentId,
      'evaluatorId': isAllocate ? selectedCj : "",
      'action': action,
      'customerAddress': !!customerAddress ? customerAddress : "",
      'onlineSlotDate': !!selectedDateSlot ? selectedDateSlot.value : "",
      'onlineTimeSlot': !!selectedTimeSlot ? selectedTimeSlot.value : "", 
      // 'assignReason': cjReallocateReason
    };
    setIsSubmit(true);
    AppointmentService.assignEvaluator(params)
      .then(resp => {
        const { data: { detail } } = resp;
        showToastMessages(detail);
        updateCjAllocationStatus();
        setTimeout(() => {
          updateDataList(appointment);
          onClose();
        }, 2000);
      })
      .catch(err => {
        const { detail } = err;
        showToastMessages(detail, false);
        onClose();
      }).finally(()=>{
        setIsSubmit(false);
      });
        
  };

  const onRAAllocate = () => {
    if (!selectedRA) {
      showToastMessages("Please Select RA", false);
      return;
    }
    if (orgRole !== ROLE_KEYS.PLL_ZM_CENTER) {
      if (!!selectedRA && selectedRA !== ""){
        const params = {
          'appointment': appointmentId,
          'field': 'ra_id',
          'value': selectedRA
        };
        setIsSubmit(true);
        AppointmentService.updateAppointment(params).then(resp => {
          const { data: { detail: { message } } } = resp;
          showToastMessages(message);
          setTimeout(() => {
            resetUpdateDataList(appointmentId);
            onClose();
          }, 2000);
        }).catch(err => {
          const { detail } = err;
          showToastMessages(detail, false);
          resetUpdateDataList(appointmentId);
          onClose();
        }).finally(()=>{
          setIsSubmit(false);
        });
      }
    } else {
      const params = [{
        "pllAssociate": selectedRA,
        "pllAssociateEmail": selectedRAEmail,
        "appointmentList": [appointmentId]
      }];
      setIsSubmit(true);
      AppointmentService.allocateAppointment(params).then(()=>{
        showToastMessages("Leads allocated successfully");
        setTimeout(() => {
          resetUpdateDataList(appointmentId);
          onClose();
        }, 2000);
      }).catch((err)=>{
        showToastMessages(`Error - ${err}`, false);
        resetUpdateDataList(appointmentId);
        onClose();
      }).finally(()=>{
        setIsSubmit(false);
      });
    }
        
  };
  const updateCjAllocationStatus = () => {
    let filterQuery = "";
    let cityStores = getUniqueStoreForCities();
    filterQuery = "?storeId=" + cityStores;
    if (hisfilterQuery === "init") {
      filterQuery = filterQuery + "&leadDays=2";
    }
    else {
      filterQuery = filterQuery + hisfilterQuery;
    }
    !!hisfilterQuery && fetchCjAllocationStatusConnect(filterQuery);
  };
  const onAllocateCjClick = (userId) => {
    if (IsCJAllocate) {
      setSelectedCj(userId);
    } else {
      setSelectedCj(userId);
      setSelectedRA(userId);
    }
  };
  const onAllocateRaClick = (item) => {
    setSelectedRA(item.id);
  };
  const onChangeHandler = (e) => {
    const { target: { value } } = e;
    setSearchQuery(value.trim());
  };

  const onSearchClick = () => {
    if (searchQuery.trim() && searchQuery.trim().length >= NUMBERS.THREE) {
      let newDataList = [...totaldataList].filter((el) => el.email.includes(searchQuery.trim()));
      setDataList(newDataList);

    }
  };
  const onResetSearchClick = () => {
    setSearchQuery("");
    setDataList(totaldataList);
  };
  const onSearchKeyPress = (e) => {
    const { charCode } = e;
    if (charCode === NUMBERS.THIRTEEN) {
      onSearchClick();
    }
  };
  const onDateSelect = (date) => {
    setSelectedDateSlot({label: date, value: date});
    const selectedDateSlots = cjSlots.find((item) => item.date === date);
    const timeSlots =  !!selectedDateSlots && !!selectedDateSlots.slots && selectedDateSlots.slots.map((time) => ({ label: time, value: time}));
    setTimeSlots(timeSlots);
    setSelectedTimeSlot(null);
  };
  const onSlotSelect = (time) => {
    setSelectedTimeSlot({label: time, value: time});
    // setSelectedTimeSlot(time);
  };

  // const onSelectCJReason = (value) => {
  //     setCjReallocateReason(value);
  // };
  return (
    <div className={styles.allocateCjSchedulePopup}>
      <H2 text={`Allocate ${pageTitleType}`} />
      <div>
        <div className={styles.searchWrapper}>
          <div className={styles.search}>
            <input type="text" name="search" placeholder="Search by Email" onChange={onChangeHandler} onKeyPress={onSearchKeyPress} value={searchQuery} />
            <button><img alt="" src={Searchicon} onClick={onSearchClick} /></button>
          </div>
          <a className={styles.searchRest} href="javascript://" onClick={onResetSearchClick}>{"Reset Search"}</a>
        </div>
        <label className={styles.searchAlert}>* minimum three character required for search</label>
      </div>
      {isSaleOnline && <div className={styles.allocatecj}>
        <img src={Calander} alt="Calander" />
        <div className={styles.mr10}>
          <SelectComponent
            key={key}
            name={`cj_date_${selectedDateSlot}`}
            placeholder={"Select Date"}
            optionsList={dateSlots}
            questionId={appointment}
            onChange={onDateSelect}
            defaultValue={selectedDateSlot ? selectedDateSlot : ""}
          />
        </div>
        <img src={TimeSlot} alt="TimeSlot" />
        <div className={styles.mr10}>
          <SelectComponent
            key={key}
            name={`cj_Slot_${selectedTimeSlot}`}
            placeholder={"Select Time Slot"}
            optionsList={timeSlots}
            onChange={onSlotSelect}
            defaultValue={selectedTimeSlot ? selectedTimeSlot : ""}
          />
        </div>
      </div>}
      <div className={styles.cjTable}>
        <table cellPadding="0" cellSpacing="0" width="100%">
          <thead>
            <tr>
              <th>{`${pageTitleType} Name`}</th>
              {isBike && <th>Allocated</th>}
              {/* {showInspectedColum && <th>Inspected</th>} */}
            </tr>
          </thead>
          <tbody>
            {
                            (IsCJAllocate || isNegoCarDetail) ?  dataList.map((item) => {
                              const {idLmsUsers, firstName, lastName, id_lms_users, first_name, last_name} = item;
                              const lmsUserId = idLmsUsers || id_lms_users;
                              const fName = firstName || first_name;
                              const lName = lastName || last_name;
                              return (
                                <tr>
                                  <td>
                                    <div className={styles.radioButton}>
                                      <RadioButton key={lmsUserId} id={lmsUserId} name="allocateCj" checkedStatus={selectedCj === lmsUserId} value={lmsUserId} onClickCallback={() => { onAllocateCjClick(lmsUserId); setSelectedRAEmail(item.email);}} />
                                      <p className={styles.text}><span>{fName} {lName}</span> ({item.email})</p>
                                    </div>
                                  </td>
                                  {IsCJAllocate && <td><span className={styles.text}>{item.count}</span></td>}
                                </tr>
                              );
                            }) : isBike && dataList.map((ra) => {
                              return (
                                <tr>
                                  <td key={`ra_cell_${ra.empCode}`}>
                                    <div className={styles.radioButton}>
                                      <RadioButton key={ra.id} id={ra.id} name={`allocateRA_${ra.email}`} checkedStatus={selectedRA === ra.id} value={ra.id} 
                                        onClickCallback={() => {onAllocateRaClick(ra);}} />
                                      <p className={styles.text}><span>{ra.name}</span> ({ra.email})</p>
                                    </div>
                                  </td>
                                  {isBike && <td><span className={styles.text}>0</span></td>}
                                </tr>
                              );
                            })
            }
          </tbody>
        </table>
      </div>
      {/* {isCJM && <div className={styles.mr10}>
                <SelectComponent
                    key={`cjAllocationReasons`}
                    name={`cjAllocationReasons`}
                    placeholder={"Reason to Re-Allocate (Mandatory)"}
                    optionsList={cjReasons}
                    onChange={onSelectCJReason}
                />
            </div>} */}
      <div className={styles.ctaBox}>
        <Button ctaText={"allocate"} classNames="saveCta" disabled={isSubmit} onClick={() => IsCJAllocate ? onClickAssignEvaluator(true) : onRAAllocate(true)} />
        <Button ctaText={"cancel"} classNames="cancelCta" onClick={onClose} />
      </div>
      {!isBike && !isCJM  && !isNegoCarDetail && <div className={styles.ctaBox}>
        <a href="javascript://" onClick={() => IsCJAllocate ?  onClickAssignEvaluator(false) : onRAAllocate(false)}>De-Allocate</a>
      </div>}
    </div>
  );
};

export default AllocateCjSchedulePopup;
