import React from "react";
import styles from "./dealer-search-item.module.scss";

const DealerSearchItem = ({ item }) => {
  const { centreName, ucmName, userEmail, userId} = item;
  return (
    <>
      <div className={styles.leadInfo}>
        <p className={styles.searchId}>{centreName}</p>
        <span>{ucmName}</span>
      </div>
      <div className={styles.searchButton} key={userId}>
        <p>{`RA ${userEmail}`}</p>
      </div>
    </>
  );
};

export default DealerSearchItem;
