import React from "react";

const TableRow = ({ 
  children,
  tableRowRef =null,
  onRowClick = () => {},
}) => {
  return (
    <tr ref={tableRowRef} onClick={onRowClick} >{children}</tr>
  );
};

export default TableRow;
