import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateFilterQuery } from "../../negotiation-filter/actions";
import OnOffButton from "./component";

const mapStateToProps = ({
  negotiationFilter
}) => ({
  negotiationFilter
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateFilterQueryConnect: updateFilterQuery
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OnOffButton);
