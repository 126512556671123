import React, {useEffect, useState} from "react";
import styles from "./negoPriceOffers.module.scss";
import Info from  "./images/info.svg";
import moment from "moment";

const NegoPriceOffers = (props) => {
  const {data: {offerStages, appointmentId, hi_scheduled_by, home_ins_date, timeslot,pr_created_date}} = props;
  const displayOffers = !!offerStages && offerStages.filter((row)=> !!row.display);
  const getStatus = (index) => {
    if (!!displayOffers) {
      const curItem = displayOffers[index];
      const nextItem = displayOffers[index + 1];
      if (!!curItem && !!curItem.completed) {
        if (displayOffers.length - 1 === index) {
          return styles.active;
        } else {
          if (!!nextItem && !!nextItem.completed) {
            return styles.active;
          } else {
            return styles.doneState;
          }
        }
      } else {
        const prevItem = index > 0 ? displayOffers[index-1] : null;
        if (!!nextItem && !nextItem.completed 
                    && !!prevItem && !!prevItem.completed) {
          return styles.processState;
        } else if (!nextItem && !!prevItem ) {
          if (!!prevItem.completed) {
            return styles.processState;
          } else {
            return styles.pendingState;
          }
        }
      }
    }
  };
  return (
    <>
      {!!displayOffers && <div className={styles.negoPriceOffers}>
        <ul key={`nego-price-${appointmentId}`}>
          {displayOffers.map((item, index)=>{
            return (
              <li key={`key-${index}-${item.key}`} className={getStatus(index)}>
                {item.label}
                {!!item.info && item.key === "RI_SCHEDULED" && <div className={styles.infoWrapper}>
                  <img src={Info} alt="info" />
                  <div className={styles.infoContent}>
                    {!!hi_scheduled_by && <p>{item.label}<span> by {hi_scheduled_by === 1 ? 'Retail' : 'Customer'}</span></p>}
                    {!!home_ins_date && <p>{`${moment(home_ins_date).format('ddd')}, ${moment(home_ins_date).format('Do MMM')}, ${timeslot}`}</p>}
                  </div>
                </div>}
              </li>
            );
          })}
        </ul>
        {!!pr_created_date && <p className={styles.timeinfo}><span >{moment().from(pr_created_date, true)} </span> since PR Generation </p> }
      </div>}
    </>
  );
};

export default NegoPriceOffers;