import React, { useEffect, useState } from "react";
import styles from "./store-list.module.scss";
import { LOCAL_STORAGE_KEYS, LEAD_TYPE } from "../../utils/app-constants";
import Button from "../shared/button/component";
import { saveToLocalStorage, getFromLocalStorage, getUniqueStoreForCities } from "../../utils/helper";
import { AppointmentService } from "../../service";
import Checkbox from "../../components/shared/checkbox/component";
import StoreSearch from "../../components/store-search";
// import { getRAListMoq } from "../../utils/mocApi";

const StoreList = ({
  onSelectionChange,
  showLeadsMenu,
  fetchCjAllocationStatusConnect,
  homeInspectionFilters,
  setSelectedStoresConnect
}) => {
  const selectedStores = getFromLocalStorage(LOCAL_STORAGE_KEYS.SELECTED_STORES) || "";
  const cityStoresList = getFromLocalStorage(LOCAL_STORAGE_KEYS.CITY_STORES_LIST);
  const [data, setData] = useState(cityStoresList ? JSON.parse(cityStoresList) : []);
  const [cityList, setCityList] = useState([]);
  const [newSelectedStores, setNewSelectedStores] = useState(selectedStores.trim() ? selectedStores.split(',') : []);
  const [storesError, setStoresError] = useState(false);
  const { hisfilterQuery } = homeInspectionFilters;
  const [query, setQuery] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [searchInProgress, setSearchInProgress] = useState(false);
  const [checkAll, setCheckAll] = useState(true);

  const setStopSearch = () => {
    setSearchInProgress(false);
    setErrorMessage("");
    setQuery("");
    AppointmentService.getStoreList()
      .then(response => {
        const { data: { detail } } = response;
        if (!!detail && detail.length > 0) {
          setData(detail);
        }
      })
      .catch(error => { 
        console.log({error});
      });
  };

  useEffect(() => {
    if (data.length === 0) {
      AppointmentService.getStoreList()
        .then(response => {
          const { data: { detail } } = response;
          if (!!detail && detail.length > 0) {
            setCJList(detail[0].idLmsStore);
          }
          saveToLocalStorage(LOCAL_STORAGE_KEYS.CITY_STORES_LIST, JSON.stringify(detail));
          setData(detail);
        }).catch(error => {
          console.log('Error in fetching stores list', error);
        });
    }
    const alStore = data.map(item => item.idLmsStore.toString());
    if (alStore.length === newSelectedStores.length) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setCJList = (storeId) => {
    AppointmentService.getEvaluatorList(storeId)
      .then(response => {
        const { data: { detail } } = response;
        saveToLocalStorage(LOCAL_STORAGE_KEYS.CJ_LIST, JSON.stringify(detail));
      }).catch(error => {
        console.log({error});
      });
  };

  useEffect(() => {
    if (!showLeadsMenu) {
      setNewSelectedStores([]);
    } else {
      setNewSelectedStores(selectedStores.trim() ? selectedStores.split(',') : []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLeadsMenu]);

  useEffect(() => {
    if (data) {
      const cities = [...new Set(data.map(item => item.storeCity))];
      setCityList(cities);
    }
  }, [data]);

  const onCityChange = (e, city) => {
    const { target: { checked } } = e;
    const selectedStoresByCity = data.filter(item => item.storeCity === city);
    let currentSelection = newSelectedStores.map(item => item);
    selectedStoresByCity.forEach(item => {
      const storeIdIndex = currentSelection.indexOf(item.idLmsStore.toString());
      if (checked) {
        if (storeIdIndex === -1)
          currentSelection.push(item.idLmsStore.toString());
      } else {
        if (storeIdIndex !== -1)
          currentSelection.splice(storeIdIndex, 1);
      }
    });
    const alStore = data.map(item => item.idLmsStore.toString());
    if (alStore.length === currentSelection.length) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
    setNewSelectedStores(currentSelection);
  };
  const SelectAllStore = (e) => {
    const alStore = data.map(item => item.idLmsStore.toString());
    if (e.target.checked) {
      setCheckAll(true);
      setNewSelectedStores(alStore);
    } else {
      setCheckAll(false);
      setNewSelectedStores([]);
    }
  };
  const onStoreClick = (storeId) => {
    let currentSelection = newSelectedStores.map(item => item);
    const currentStoreIndex = currentSelection.indexOf(storeId.toString());
    if (currentStoreIndex !== -1)
      currentSelection.splice(currentStoreIndex, 1);
    else
      currentSelection.push(storeId.toString());

    const alStore = data.map(item => item.idLmsStore.toString());
    if (alStore.length === currentSelection.length) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
    setNewSelectedStores(currentSelection);
  };

  const getStoreClass = (storeId) => {
    if (newSelectedStores.includes(storeId.toString()))
      return styles.active;
    else
      return "";
  };

  const isCheckedCity = (city) => {
    let isChecked = true;
    const storesByCity = data.filter(item => item.storeCity === city);
    storesByCity.forEach(item => {
      if (!newSelectedStores.includes(item.idLmsStore.toString()))
        isChecked = false;
    });
    return isChecked;
  };

  const renderStores = (city) => {
    const cityStores = data.filter(item => item.storeCity === city);
    return (
      cityStores.map(item =>
        (
          <li
            key={item.idLmsStore}
            className={getStoreClass(item.idLmsStore)}
            onClick={() => onStoreClick(item.idLmsStore)}
          >{item.name}
          </li>
        )
      )
    );
  };

  const onStoresApply = () => {
    if (newSelectedStores.length === 0) {
      setStoresError(true);
    } else {
      if (newSelectedStores.length === 1) {
        if (!!data && data.length > 0) {
          let _store = data.find(id => id.idLmsStore === parseInt(newSelectedStores[0]));
          if (!!_store && _store.type === "locality") {
            AppointmentService.getEvaluatorList(newSelectedStores[0])
              .then(response => {
                const { data: { detail } } = response;
                saveToLocalStorage(LOCAL_STORAGE_KEYS.CJ_LIST, JSON.stringify(detail));
                setStoresError(false);
                saveToLocalStorage(LOCAL_STORAGE_KEYS.LEADS, LEAD_TYPE.STORE);
                saveToLocalStorage(LOCAL_STORAGE_KEYS.SELECTED_STORES, newSelectedStores.join(","));
                onSelectionChange();
                setStopSearch();
              }).catch(error => {
                console.log('Error in fetching stores list', error);
              });
          } else {
            SetValueAfterStoreApply(newSelectedStores);
          }
        }
        else {
          SetValueAfterStoreApply(newSelectedStores);
        }
      }
      else {
        setStoresError(false);
        saveToLocalStorage(LOCAL_STORAGE_KEYS.LEADS, LEAD_TYPE.STORE);
        saveToLocalStorage(LOCAL_STORAGE_KEYS.SELECTED_STORES, newSelectedStores.join(","));
        onSelectionChange();
        setStopSearch();
      }
    }
    getCitiesFromSelectedStore(newSelectedStores);
    setRAList();
    updateCjAllocationStatus();
  };

  const setRAList = () => {
    AppointmentService.getRaList()
      .then(response => {
        const { data: { detail } } = response;
        saveToLocalStorage(LOCAL_STORAGE_KEYS.RA_LIST, JSON.stringify(detail));
      }).catch(error => {
        console.log({error});
        // let response = getRAListMoq();
        // const { data: { detail } } = response;
        // saveToLocalStorage(LOCAL_STORAGE_KEYS.RA_LIST, JSON.stringify(detail));
      });
  };
  const updateCjAllocationStatus = () => {
    let filterQuery = "";
    let cityStores = getUniqueStoreForCities();
    filterQuery = "?storeId=" + cityStores;
    if (hisfilterQuery === "init") {
      filterQuery = filterQuery + "&leadDays=2";
    }
    else {
      filterQuery = !!hisfilterQuery ? `${filterQuery}${hisfilterQuery}` : filterQuery;
    }
    fetchCjAllocationStatusConnect(filterQuery);
  };
  const getCitiesFromSelectedStore = (selectedStores) => {
    let _selStore = [...selectedStores];
    if (!!_selStore && _selStore.length > 0) {
      const _cityStoresList = getFromLocalStorage(LOCAL_STORAGE_KEYS.CITY_STORES_LIST);
      const arrCityStoreList = _cityStoresList ? JSON.parse(_cityStoresList) : [];
      let finalArray = arrCityStoreList.filter((item) => {
        return _selStore.includes(item.idLmsStore.toString());
      });
      saveToLocalStorage(LOCAL_STORAGE_KEYS.SELECTED_CITIES, JSON.stringify(finalArray));
    }
  };
  const SetValueAfterStoreApply = () => {
    setStoresError(false);
    saveToLocalStorage(LOCAL_STORAGE_KEYS.LEADS, LEAD_TYPE.STORE);
    saveToLocalStorage(LOCAL_STORAGE_KEYS.SELECTED_STORES, newSelectedStores.join(","));
    onSelectionChange();
    setStopSearch();
    setSelectedStoresConnect(newSelectedStores.join(","));
  };
  return (
    <div className={styles.storeList}>
      <div className={styles.searchWrapper}>
        <div className={styles.selectAll}>
          <Checkbox
            id={"chkAll"}
            name={`chkAll`}
            onChangeCallback={(e) => SelectAllStore(e)}
            checkedStatus={checkAll}
          />
          <span className={styles.text}>Select All</span>
        </div>
        <div className={styles.storeSearch}>
          <StoreSearch
            setData={setData}
            query={query}
            setQuery={setQuery}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            searchInProgress={searchInProgress}
            setSearchInProgress={setSearchInProgress}
            setStopSearch={setStopSearch}
          />
        </div>
      </div>
      {cityList.map(item =>
        <div key={item}>
          <div className={styles.storeWrapper}>
            <div className={styles.checkboxWrapper}>
              <Checkbox
                id={`chk_${item}`}
                name={`chk_${item}`}
                onChangeCallback={(e) => onCityChange(e, item)}
                checkedStatus={isCheckedCity(item)}
              />
              <span className={styles.storeTitle}>{item}</span>
            </div>
            <ul>
              {
                renderStores(item)
              }
            </ul>
          </div>

        </div>
      )
      }
      <div className={styles.listSaveButton}>
        <Button
          ctaText="Apply"
          classNames="smallCta"
          onClick={onStoresApply}
        />
      </div>
      {
        storesError && <small className="form-text text-danger" >Please select atleast 1 store</small>
      }
    </div>
  );
};

export default StoreList;
