import React,{useState} from "react";
import { ACTIONS_BUTTON, BIKE_ACTIONS_BUTTON } from "../../utils/app-constants";
import Button from "../shared/button/component";
import RescheduleAppointmentPopup from "../../components/reschedule-appointment-popup";
import Popup from "../../components/popup/component";
import { isBikeSource, redirectToUrl } from "../../utils/helper";

const Reschedule = ({ rowDetails }) => {
  const { reschedule_url = "", rescheduleUrl = ""} =  rowDetails || {};
  // console.log("rowDetails", reschedule_url, ", rescheduleUrl", rescheduleUrl);
  const [allocateOpen, setAllocateOpen] = useState(false);
  const isBike = isBikeSource();
  const onAllocateOpen = () => {
    setAllocateOpen(true);
  };
  const onAllocateClose = () => {
    setAllocateOpen(false);
  };
  return (
    <div>
      {
                isBike ? 
                  <Button classNames="secondaryCta bookHi" onClick={() => { redirectToUrl(!!reschedule_url ? reschedule_url : rescheduleUrl); }} ctaText={BIKE_ACTIONS_BUTTON.RESCHEDULE} />
                    :
                  <>
                    <Button classNames="smallCta" ctaText={ACTIONS_BUTTON.RESCHEDULE} onClick={onAllocateOpen} />
                    <Popup isOpen={allocateOpen} close={onAllocateClose}>
                      <RescheduleAppointmentPopup onClose={onAllocateClose} appointmentId={rowDetails.appointment}/>
                    </Popup> 
                  </>
      }    
    </div>
  );
};

export default Reschedule;
