import React, { useState, useEffect } from "react";
import styles from "./negotiation-filter.module.css";
import { BIKE_NEGOTIATION_FILTERS, LOCAL_STORAGE_KEYS, NEGOTIATION_FILTERS, ROLE_KEYS } from "../../utils/app-constants";
import { getFromLocalStorage, getUpdatedFilterCounts, isBikeSource, isC2CUser } from "../../utils/helper";
import { useSelector } from "react-redux";

const NegotiationFilter = ({ 
  updateFilterQueryConnect, 
  negotiations, 
  negotiationFilter, 
  resetRangeByKeyConnect,
  updateWithCustomerCountConnect,
  updateActionRequiredCountConnect
}) => {
  const isBike = isBikeSource();
  const negoFilter = isBike ? BIKE_NEGOTIATION_FILTERS : NEGOTIATION_FILTERS;
  const { expiryCount } = negotiations;
  const { filterQuery } = negotiationFilter;
  const [selectedFilter, setSelectedFilter] = useState(negoFilter.TOTAL.key);
  const [filtersCount, setFiltersCount] = useState(Object.assign({}, expiryCount));
  const negoFilterData = useSelector(state => state.negotiationFilter);
  const {originalRole} = JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA)) || {};
  let isC2c = isC2CUser();
  useEffect(() => {
    return () => {
      updateFilterQueryConnect(null);
    };
  }, []);

  useEffect(() => {
    const slctdFilter = Object.values(negoFilter).find(item => item.key === selectedFilter).key;
    const updateObject = slctdFilter === negoFilter.TOTAL.key ? true : false;
    const newFiltersCount = getUpdatedFilterCounts(filtersCount, expiryCount, slctdFilter, updateObject);
    const {total = 0} = expiryCount || {};
    if (selectedFilter !== negoFilter.TOTAL.key) {
      updateTotalCount(newFiltersCount);
    }
    setFiltersCount(newFiltersCount);
    if (!!negoFilterData) {
      if (!!negoFilterData.withCustomer) {
        updateWithCustomerCountConnect(total);
      } else {
        updateActionRequiredCountConnect(total);
      }
    }
  }, [expiryCount]);

  const updateTotalCount = (newFiltersCount) => {
    let count = 0;
    Object.values(negoFilter).forEach(item => {
      if (item.key !== negoFilter.TOTAL.key) {
        count += newFiltersCount[item.key];
      }
    });
    newFiltersCount[negoFilter.TOTAL.key] = count;
  };

  const onFilterSelect = (selectedKey, selectedValue) => {
    let key = 'expires_in';
    let queryString = filterQuery;
    if (!!queryString && queryString.includes(key)){
      const params = queryString.split("&");
      if (params.length > 0){
        const remainingFilters = params.filter(item => !item.includes(key));
        const paramString = remainingFilters.join('&');
        queryString = paramString;
      }
    }
    queryString = `${!!queryString ? queryString : ''}&${key}=${selectedValue}`;
    setSelectedFilter(selectedKey);
    updateFilterQueryConnect(queryString);
    if (isC2c) {
      const range = {
        from: "",
        to: "",
      };
      resetRangeByKeyConnect("range", range);
    }
        
  };

  return (

    <ul>
      {
        Object.values(negoFilter).map((item) => {
                    
          if (item.key === "pllTwentyPlusDays") {
            if (originalRole === ROLE_KEYS.RA) {
              return (
                <li key={item.key} onClick={() => { onFilterSelect(item.key, item.value); }} className={selectedFilter === item.key ? styles.selected : ''}> {filtersCount[item.key]} {item.label} </li>
              );  
            }
          } else {
            return (
              <li key={item.key} onClick={() => { onFilterSelect(item.key, item.value); }} className={selectedFilter === item.key ? styles.selected : ''}> {filtersCount[item.key]} {item.label} </li>
            );
          }
        })}
    </ul>
  );
};

export default NegotiationFilter;