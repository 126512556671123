import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  data: [],
  error: null
};

export const fetchAssociatesSuccess = (state = INITIAL_STATE,{ data }) => {
  return { ...state, data, error: null};
};

export const fetchAssociatesFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, error, data: []};
};

export const resetAssociates = () => {
  return INITIAL_STATE;
};

export const HANDLERS = {
  [Types.FETCH_ASSOCIATES_SUCCESS]: fetchAssociatesSuccess,
  [Types.FETCH_ASSOCIATES_FAILURE]: fetchAssociatesFailure,
  [Types.RESET_ASSOCIATES]: resetAssociates
};

export default createReducer(INITIAL_STATE, HANDLERS);
