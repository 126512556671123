import React from "react";
import Logo from "../images/logo.svg";
import { Link } from "react-router-dom";
import styles from "./logo-section.module.css";
import { getHomePageByRole } from "../../utils/helper";

const LogoSection = () => {

  return (
    <div className={styles.logo}>
      <Link to={getHomePageByRole()} >
        <img src={Logo} alt="logo" />
      </Link>
    </div>
  );
};

export default LogoSection;
