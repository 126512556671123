import { createTypes } from "reduxsauce";

export default createTypes(
  `
    FETCH_APPOINTMENTS
    FETCH_APPOINTMENTS_SUCCESS
    FETCH_APPOINTMENTS_FAILURE
    SET_APPOINTMENT_STATE
    SEARCH_AND_FILTER_APPOINTMENTS
    REMOVE_SEARCH
    RESET_APPOINTMENTS
    RESET_APPOINTMENT_BY_KEY
    SORT_APPOINTMENTS_BY_KEY
    RESET_SORT_ORDER_BY_KEY
    FETCH_APPOINTMENT_ANNOUNCEMENT_DATA
    PUSH_APPOINTMENT_ANNOUNCEMENT_DATA
`,
  {
    prefix: "appointmentscomponent/"
  }
);
