import React, { useState, useEffect } from "react";
import styles from "./customer-similar-cars-new.module.scss";
import Cars from "../../components/images/frontmain_car.jpg";
import Rupess from "../../components/images/rupess.svg";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AppointmentService } from "../../service";
import { getSimilarTranscatedCarMoq } from "../../utils/mocApi";
import priceFormatter from "../../utils/helpers/price-formatter";
import { trackLMSEvents } from "../../utils/helpers/tracking-events";
import { getUid } from "../../utils/helper";
import highRating from "../../components/images/5star.svg";
import WhiteStar from "../images/whiteStar.svg";
import Below5 from "../../components/images/below5.svg";

const CustomerSimilarCarsNew = ({ customerOffer, carData, slickSlider, componentShow }) => {
  const { customerOffer: { appointmentId } } = customerOffer;
  const [maxYear, setMaxYear] = useState('');
  const [minYear, setMinYear] = useState('');
  const {make, model, variant, cityName } = carData[0];
  const onNextClick = (onClick) => {
    // console.log("Corusal next arrow Click");
    trackEventCall(appointmentId, "Right Arrow");
    onClick();

  };
  const onBackClick = (onClick) => {
    // console.log("Corusal back arrow Click");
    trackEventCall(appointmentId, "Left Arrow");
    onClick();
  };
  const trackEventCall = (appointmentId, eventAction) => {
    trackLMSEvents("similarTransactedCarInputOutputPage", {
      eventLabel: appointmentId,
      lsRAID: getUid(),
      eventAction: eventAction,
    });
  };
  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={() => onNextClick(onClick)}
      />
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        // style={{ ...style, display: "block", background: "green" }}
        onClick={() => onBackClick(onClick)}
      />
    );
  };

  const carouselSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };
  const ownerShipNoAsString = (ownerShipNo) => {
    switch (ownerShipNo) {
      case "1":
        return "1st";
      case "2":
        return " 2nd";
      case "3":
        return "3rd";
      case "4":
        return "4th";
      case "5":
        return "5th";
      case "6":
        return "6th";
      default:
        return "";
    }

  };

  useEffect(() => {
    trackLMSEvents("ls_SimilarCarCount", {
      eventLabel: appointmentId,
      lsRAID: getUid(),
      SimilarCar: carData.length,
      Timestamp: new Date()
    });
    setMaxYear(carData.reduce((max, b) => Math.max(max, b.year), carData[0].year));
    setMinYear(carData.reduce((min, b) => Math.min(min, b.year), carData[0].year));

  }, [carData, appointmentId]);

  const getRatingText = (rating) => {
    let ratingText = Math.round(rating);
    if (rating === 0) {
      ratingText = "N/A";
    }
    return ratingText;
  };

  const getRatingColor = (rating) => {
    if (rating === 0) return "belowHigh";
    if (rating < 3 && rating > 0) return "belowHigh";
    if (rating >= 3 && rating <= 4) return "belowHigh";
    if (rating > 4) return "highRating";
  };

  return (
    <>
      {componentShow && <div className={styles.customerSimilarCars}>
        {/* <h3 className={styles.heading}>Similar <span>{make} {model} {variant},</span> auctioned in <span>{cityName}</span> between <span>{minYear}-{maxYear}</span></h3> */}
        <h3 className={styles.heading}>Similar <span>{make} {model} {variant},</span> auctioned in <span>{cityName}</span> between <span>{"2019"}-{"2020"}</span></h3>
        {!!carData && carData.length > 0 && slickSlider && <div className={styles.carouselWrapper}>
          <Slider {...carouselSettings}>
            {carData.length > 0 && carData.map((item, index) =>
              <div key={index}>
                <div className={styles.carsCard}>
                  <div className={styles.carsCardImage}>
                    <img src={item.url} alt="" />
                  </div>
                  <div className={styles.yoursCarsContent}>
                    <div className={styles.carsSectionDetails}>
                      <p className={styles.carsPrice}><img src={Rupess} alt="rupess" /> {priceFormatter(item.c24Quote, true)}</p>
                      <p className={styles.kmModel}>{priceFormatter(item.odometerReading, true)} km    {item.year} </p>
                      <div className={styles.ratingSection}>
                        <ul>
                          <li>
                            <h4>Engine</h4>
                            <div className={`${styles[getRatingColor(getRatingText(item.engRating))]}`}>
                              <img src={getRatingText(item.engRating) === 5 ? `${highRating}` : `${Below5}`} alt="Rating" />
                              <span className={styles.ratingText}>{item.engRating}</span>
                            </div>
                          </li>
                          <li>
                            <h4>Exterior</h4>
                            {/* <div className={styles.belowHigh}>
                                                            <img src={Below5} alt="4 star" />   
                                                            <span className={styles.below5}>4.0</span>
                                                        </div> */}
                            <div className={`${styles[getRatingColor(getRatingText(item.extRating))]}`}>
                              <img src={getRatingText(item.extRating) === 5 ? `${highRating}` : `${Below5}`} alt="Rating" />
                              <span className={styles.ratingText}>{item.extRating}</span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Slider>
        </div>}
        <div className={styles.staticSlider}>
          {carData.length > 0 && !slickSlider && carData.map((item, index) =>
            <div className={styles.carsCard}>
              <div className={styles.carsCardImage}>
                <img src={item.front_image} alt="" />
              </div>
              <div className={styles.yoursCarsContent}>
                <p className={styles.carsPrice}><img src={Rupess} alt="rupess" /> {priceFormatter(item.bought_price, true)}</p>
                <p>Owner {ownerShipNoAsString(item.ownership_number)}</p>
                <p>Driven: {priceFormatter(item.odometer, true)} km</p>
                <p>Mfg Year: {item.year}</p>
              </div>
            </div>
          )}
        </div>
      </div >
      }
    </>
  );
};

export default CustomerSimilarCarsNew;
