import React, { useState, useEffect } from "react";
import styles from "./vahan-background.module.scss";
import Refresh from "./images/refresh.svg";
import { vahanCheckStatus, rcCheckStatus, businessApprovalStatus, serviceHistoryStatus } from '../../utils/enums';
import ButtonLoader from "../../components/button-loader/index";

const VahanBackground = ({ appointmentId, vahanBackgroundConnect, vahanBackground }) => {

  const [vahanDetail, setVahanDetail] = useState({});
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    vahanBackgroundConnect(appointmentId);
    setLoading(true);
  }, [vahanBackgroundConnect, appointmentId]);

  useEffect(() => {
    let { userVahanData } = vahanBackground;
    setVahanDetail(userVahanData);
    setLoading(false);
  }, [vahanBackground]);

  const refreshVahan = () => {
    vahanBackgroundConnect(appointmentId);
    setLoading(true);
  };

  return (
    <div className={styles.vahanBackground}>
      <div className={styles.documentWrapper}>
        <div className={styles.headingMainWrapper}>
          <div className={styles.headingWrapper}>
            <div className={styles.heading}>
              <h3>Vahan Background Check : </h3>
              <p className={styles[vahanCheckStatus[vahanDetail?.vahan]?.statusColor]}>{vahanCheckStatus[vahanDetail?.vahan]?.label}</p>
              <button onClick={refreshVahan}>
                {!isLoading ? <img src={Refresh} alt="refresh" /> : <p className={styles.activeGreen}>Loading..</p>}
              </button> 
            </div>
            <div className={styles.textList}>
              <ul>
                <li>
                  <p className={vahanDetail?.vahan !== 0 && styles.activeText}>Business approval:</p>
                  {
                                        vahanDetail?.vahan !== 0 ? (
                                          <span className={styles[businessApprovalStatus[vahanDetail?.businessApproval]?.statusColor]}>
                                            {businessApprovalStatus[vahanDetail?.businessApproval]?.label}
                                          </span>
                                        ) : <span>N/A</span>
                  }

                </li>
                <li>
                  <p className={vahanDetail?.vahan !== 0 && styles.activeText}>Service History Check:</p>
                  {
                                        vahanDetail?.vahan !== 0 ? (
                                          <span className={styles[serviceHistoryStatus[vahanDetail?.serviceHistory]?.statusColor]}>
                                            {serviceHistoryStatus[vahanDetail?.serviceHistory]?.label}
                                          </span>
                                        ) : <span>N/A</span>
                  }
                </li>
                <li>
                  <p className={vahanDetail?.vahan !== 0 && styles.activeText}>RC Particular Check:</p>
                  {
                                        vahanDetail?.vahan !== 0 ? (
                                          <span className={styles[rcCheckStatus[vahanDetail?.rcParticular]?.statusColor]}>
                                            {rcCheckStatus[vahanDetail?.rcParticular]?.label}
                                          </span>
                                        ) : <span>N/A</span>
                  }
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.headingWrapper}>
            <div className={styles.heading}>
              <h3>Dealer Advance Payment</h3>
            </div>
            <div className={styles.textList}>
              <ul>
                <li>
                  <p className={vahanDetail?.vahan !== 0 && styles.activeText}>High Value Car:</p>
                  {
                                        vahanDetail?.vahan !== 0 ? (
                                          <span className={vahanDetail?.hvc ? styles.activeGreen : styles.activeRed}>
                                            {vahanDetail?.hvc ? 'Applicable' : 'Not Applicable'}
                                          </span>
                                        ) : <span>N/A</span>
                  }
                </li>
                <li>
                  <p className={vahanDetail?.vahan !== 0 && styles.activeText}>Audit Flag:</p>
                  {
                                        vahanDetail?.vahan !== 0 ? (
                                          <span className={vahanDetail?.fraudAudit ? styles.activeGreen : styles.activeRed}>
                                            {vahanDetail?.fraudAudit ? 'Applicable' : 'Not Applicable'}
                                          </span>
                                        ) : <span>N/A</span>
                  }
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>

    </div>
  );
};

export default VahanBackground;

