import React, { useState, useEffect } from "react";
import H2 from "../h2/component";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from "../shared/button/component";
import RadioButton from "../shared/radio-button/component";
import priceFormatter from "../../utils/helpers/price-formatter";
import { redirectToUrl, showToastMessages, isPllUser, getFromLocalStorage, isBikeSource } from "../../utils/helper";
import styles from "./payment-generate-pr-popup.module.css";
import { AppointmentService } from "../../service";
import SelectComponent from "../shared/select/component";
import { LOCAL_STORAGE_KEYS, NUMBERS } from "../../utils/app-constants";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => {
  return {
    textField: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      fontSize: '10px',
      width: '100%'
    }
  };
});

const PaymentGeneratePrPopup = ({ 
  close, 
  rowDetails, 
  ocbDetails
}) => {
  const { make, c24Quote, calculatedc24Quote, expectedOneClickAmount, currentHB, bestHB, is_pll, 
    appointmentId, isInventoryBuying} = ocbDetails;
  const { customerName, model, year} = rowDetails;
  const [tokenEliteData, setTokenEliteData] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [inputText, setInputText] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [ownerTypeList, setOwnerTypeList] = useState([]);
  const [ownerType, setOwnerType] = useState("");
  const [currentSuggestedC24Quote, setCurrentSuggestedC24Quote] = useState(calculatedc24Quote);
  const classes = useStyles();
  let displayPrice = c24Quote;
  let minimumPrice = Math.round(calculatedc24Quote - (calculatedc24Quote * 0.15));; 
  const history = useHistory();
  const [errorClass, setErrorClass] = useState("");
  const [dealerErrorClass, setDealerErrorClass] = useState("");
  const tokenEliteFlow = false;
  const [spotToken, setSpotToken] = useState("ONTHESPOT");
    
  useEffect(() => {
    AppointmentService.tokenEliteABTest(appointmentId)
      .then(response => {
        const { data: { detail } } = response;
        console.log(detail);
        setTokenEliteData(detail);
      })
      .catch(error => {
        const { detail: data } = error;
        console.log({data});
        setTokenEliteData({});
      });

    AppointmentService.getCheckAndAllowPR(appointmentId).then((resp=>{
      const {data: {detail:{canCreateRaTokenFlow = 0}}} = resp;
      if (canCreateRaTokenFlow === 1) {
        AppointmentService.getOwnerShipType()
          .then(response => {
            const { data: { detail } } = response;
            setOwnerTypeList(detail);
          }).catch(error => {
            console.log(error);
          });
      }
    }));
    setCurrentSuggestedC24Quote(displayPrice);
    setInputText(priceFormatter(displayPrice));
  }, []);

  const showDisplayLowMargin = () => {
    let marginType = tokenEliteData?.marginType || '';
    if (marginType === "Low Margin") {
      return (
        <small className={styles.lowMargin}>Low Margin on this Car.</small>
      );
    }
    else if (marginType === "Negative Margin") {
      return (
        <small className={styles.negative}>Negative Margin on this Car.</small>
      );
    }
  };

  const onChangeText = (e) => {
    setErrorClass("");
    const value = e.target.value && e.target.value.replace(/,/g, '') ? priceFormatter(Number(e.target.value.replace(/,/g, ''))) : "";
    const cPrice = Number(e.target.value.replace(/,/g, ''));
    setCurrentSuggestedC24Quote(cPrice);
    setInputText(value); 
  };
  const onOwnerTypeSelect = (value) => {
    setDealerErrorClass("");
    setOwnerType(value);
  };
  const onSubmitSuggestedQuote = () => {
    setErrorClass("");
    setDealerErrorClass("");
    if (minimumPrice > currentSuggestedC24Quote) {
      setErrorClass("form-text text-danger");
    }
    else if (tokenEliteFlow && ownerType === "") {
      setDealerErrorClass("form-text text-danger");
    }
    else {
      setIsSubmitted(true);
      setisLoading(true);
      const payload = {
        "appointmentId": appointmentId,
        "c24BuyingQuote": currentSuggestedC24Quote
      };

      AppointmentService.updateSuggestedQuote(payload).then(response => {
        const { data: { detail } } = response;
        if (spotToken === "TOKEN") {
          AppointmentService.getPrEnable(appointmentId)
            .then(response => {
              const { detail: { details: { canCreateTokenFlow } } } = response.data;
              if (canCreateTokenFlow && canCreateTokenFlow === 1) {
                prInitiateToken();
              }else{
                showToastMessages("You Can't create token", false);
              }
            }).finally(()=>{
              setIsSubmitted(true);
              setisLoading(true);
            });
        } else if (spotToken === "ONTHESPOT") {
          const params = {
            "appointmentId": appointmentId
          };
          AppointmentService.postOnSpotRedirectionUrl(params)
            .then((resp)=>{
              const {data: {detail, status}} = resp;
              if (status === 200) {
                const {url=''} = detail || {};
                url && redirectToUrl(url);
              } else if (status === 208) {
                showToastMessages(detail, false);
              }
            }).catch(err=>{
              showToastMessages(err?.detail, false);
            }).finally(()=>{
              setIsSubmitted(true);
              setisLoading(true);
            });
        }
      }).catch(error => {
        showToastMessages(error?.detail, false);
      });
    }
  };

  const prInitiateToken = () => {
    const payload = {
      appointmentId: appointmentId
    };
    AppointmentService.prInitiateToken(payload)
      .then(response => {
        const { status } = response.data;
        if (status === 201) {
          history.push({pathname: `/payment-initiate-token/${appointmentId}`, state: rowDetails});
        }
      }).catch(error => {
        const {status} = error;
        if (status === 400) {
          history.push({pathname: `/payment-initiate-token/${appointmentId}`, state: rowDetails});
        };
      });
  };

  const onRadioChange = (customerIntent) => {
    setSpotToken(customerIntent);
  };

  return (
    <div className={styles.popupWrap}>
      <H2 text="GENERATE PR" />
      <ul className={styles.customerDesc}>
        <li>{customerName}</li>
        <li>{`${make} ${model}`}</li>
        <li>{year}</li>
      </ul>
      <div className={styles.textField}>
        <TextField
          label={"Final C24 Quote"}
          className={classes.textField}
          margin="dense"
          variant="outlined"
          onChange={onChangeText}
          value={inputText}
          inputProps={{
            maxLength: 9,
          }}
          disabled={!!isInventoryBuying}
        />
        <small key="info" className={`${errorClass}`}>{`Enter price above Rs ${priceFormatter(minimumPrice)} only.`}</small>
        {showDisplayLowMargin()}
      </div>
      {ownerTypeList && ownerTypeList.length > 0 &&
      <div className={styles.selectOwnership}>
        <SelectComponent
          name={"owner_type"}
          placeholder={"Select Ownership Type"}
          optionsList={ownerTypeList.map((item) => {
            return {
              label: item,
              value: item
            };
          })}
          onChange={onOwnerTypeSelect}
        />
        <small key="info" className={`${dealerErrorClass}`}>{`* Required`}</small>
      </div>
      }

      <div className={styles.getQuote}>
        <div className={styles.radioButton}>
          <RadioButton
            value="TOKEN"
            key="TOKEN"
            id="TOKEN"
            checkedStatus={spotToken === "TOKEN"}
            onClickCallback={() => { onRadioChange("TOKEN"); }}
          />
          <div><p className="mt-3">Token</p></div>
          <RadioButton
            value="ONTHESPOT"
            key="ONTHESPOT"
            id="ONTHESPOT"
            checkedStatus={spotToken === "ONTHESPOT"}
            onClickCallback={() => { onRadioChange("ONTHESPOT"); }}
          />
          <div><p className="mt-3">On Spot</p></div>
        </div>
      </div>
      <div className={styles.sumbmitPR}>
        <Button
          isLoading={isLoading}
          disabled={isSubmitted}
          onClick={() => onSubmitSuggestedQuote()}
          classNames="blueButton"
          ctaText={"Submit"} />
      </div>
    </div>
  );
};

export default PaymentGeneratePrPopup;
