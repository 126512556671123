import React from "react";
import Timer from "react-compound-timer";

const HourTimer = () => {
  return (
    <Timer.Hours />
  );
};

export default HourTimer;
