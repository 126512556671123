import React, { useEffect, useState } from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import Button from "../shared/button";
import styles from "./calendar-pick.module.scss";
import { showToastMessages, getUid } from "../../utils/helper";
import moment from "moment";
import Helmet from "react-helmet";
import { trackLMSEvents } from "../../utils/helpers/tracking-events";

const CalendarPick = ({
  setToDate,
  setFromDate,
  fromDate,
  toDate,
  setToggle
}) => {
  const numberOfMonths = 2;
  const maximumDays = 30;
  const maxRangeDays = 6;
  const [currentFrom, setCurrentFrom] = useState(null);
  const [currentTo, setCurrentTo] = useState(null);
  const libraryStyles = `
         .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
        background-color: #f0f8ff !important;
        color:  #0037ff;
      }
      .Selectable .DayPicker-Day {
        border-radius: 0 !important;
      }
      .Selectable .DayPicker-Day--start {
        border-top-left-radius: 50% !important;
        border-bottom-left-radius: 50% !important;
      }
      .Selectable .DayPicker-Day--end {
        border-top-right-radius: 50% !important;
        border-bottom-right-radius: 50% !important;
      }
      .Selectable .DayPicker-Day--today {
        color: #0037ff;
        font-weight: 800;
      }
        `;
  const [fromTo, setFromTo] = useState({
    from: new Date(fromDate),
    to: new Date(toDate)
  });

  const toggle = () => {
    setToggle(false);
  };

  const submit = () => {
    if (fromTo.from && fromTo.to) {
      setToDate(currentTo);
      setFromDate(currentFrom);
      setEvent(currentFrom, currentTo);
      toggle();
    }
    else if (!fromTo.from) {
      showToastMessages("Please Select From Date", false);
    }
    else if (!fromTo.to) {
      showToastMessages("Please Select To Date", false);
    }

  };
  const setEvent = (fromDate, toDate) => {
    trackLMSEvents("ls_dateRangeSelection", {
      eventAction: "View Inspection Report",
      eventLabel: `${moment(fromDate).format('DD/MM/YYYY').toString()} - ${moment(toDate).format('DD/MM/YYYY').toString()}`,
      lsRAID: getUid()
    });
  };

  useEffect(() => {
    if (fromTo.from && fromTo.to) {
      setCurrentFrom(fromTo.from);
      setCurrentTo(fromTo.to);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromTo]);

  const handleDayClick = (day) => {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const diffDays = Math.round(Math.abs((day - fromTo.from) / oneDay));
    const range = DateUtils.addDayToRange(day, undefined);

    if (diffDays <= maxRangeDays && ((day - fromTo.from) / oneDay) >= 0) { //for forward selection only
      const range = DateUtils.addDayToRange(day, fromTo);
      setFromTo(range);
    }
    else {
      setFromTo(range);
    }
  };

  const { from, to } = fromTo;
  const modifiers = { start: from, end: to };
  return (
    <div className={styles.range}>
      <DayPicker
        className="Selectable"
        numberOfMonths={numberOfMonths}
        selectedDays={[from, { from, to }]}
        modifiers={modifiers}
        onDayClick={handleDayClick}
        month={new Date(new Date().getFullYear(), new Date().getMonth() - 1)}
        disabledDays={[
          {
            after: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
            before: moment(new Date()).subtract(maximumDays, 'days').toDate(),
          },
        ]}
      />
      <Helmet>
        <style>{libraryStyles}</style>
      </Helmet>
      <span className={styles.maxDay}>Max 7 days can be selected</span>
      <div className={styles.submitButton}>
        <Button ctaText={"Apply"} classNames="blueButton" onClick={() => { submit(); }} />
      </div>

    </div>
  );
};
export default CalendarPick;
